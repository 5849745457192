import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getContact } from 'store/v1/contacts/contacts.actions.js';
import { openContactSidebar } from 'store/v1/ui/ui.actions.js';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors.js';

import { ResourceSelector } from 'v1/components/shared';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import uuid from 'uuid/v4';

import './ResourceConnectFormBlock.scss';

export const getAgentData = newResource => {
  let agent = {};
  if (!isEmpty(newResource.emails)) {
    agent['email'] = {
      id: `temp-${uuid()}`,
      value_1: get(newResource.emails, [0, 'value_1']),
      value_2: 'agent'
    };
  }
  if (!isEmpty(newResource.phone_numbers)) {
    agent['phone_number'] = {
      id: `temp-${uuid()}`,
      value_1: get(newResource.phone_numbers, [0, 'value_1']),
      value_2: 'agent'
    };
  }
  if (newResource.organisation_id) {
    agent['organisation_id'] = newResource.organisation_id;
  }
  return agent;
};

// TODO: review this to bring in line with new form stuff
export const ResourceConnectFormBlock = ({
  appearance,
  size,
  value,
  onChange,
  field,
  resourceTypeModel = 'AGENT',
  disabled
}) => {
  const dispatch = useDispatch();

  // STORE
  const contacts = useSelector(state => state.contacts);
  const resourceTypesList = useSelector(state =>
    getResourceTypes(state, { model: resourceTypeModel })
  );
  const resourceTypeIds = map(
    resourceTypesList,
    resourceType => resourceType.id
  );

  // STATE
  const [resource, setResource] = useState();
  const [fetchingResource, setFetchingResource] = useState(false);

  useEffect(() => {
    if (!value) {
      setResource(null);
    } else {
      getResource();
    }
  }, [value, contacts]);

  function getResource() {
    const newResource = get(contacts, ['data', value]);

    if (newResource) {
      setFetchingResource(false);
      setResource(newResource);
    } else {
      fetchResource();
    }
  }

  function fetchResource() {
    if (!fetchingResource && value) {
      dispatch(getContact(value));
      setFetchingResource(true);
    }
  }

  function onResourceSelect(resourceId, newResource) {
    setResource(!resourceId ? null : newResource);

    // TODO: extract out to settings / business logic - Only populate contact data if resource type model is AGENT
    if (newResource && get(newResource, 'resource_type.model') === 'AGENT') {
      const agent = getAgentData(newResource);
      return onChange(field, { resourceId, agent });
    }
    return onChange(field, { resourceId });
  }

  function onBlur(field, newValue) {
    if (value && isEmpty(newValue)) {
      onResourceSelect(null);
    }
  }

  return (
    <div className="ResourceConnectFormBlock">
      <ResourceSelector
        onBlur={onBlur}
        disabled={disabled}
        initialValue={value}
        onSelect={resource => onResourceSelect(resource.id, resource)}
        query={{
          filters: {
            resource_type_id: { eq: resourceTypeIds },
            resource_type_model: { eq: resourceTypeModel }
          }
        }}
        inputStyle={{
          field: field,
          size: 'S',
          appearance: 'underline',
          placeholder: 'Representative',
          overlayAction: () => {
            dispatch(openContactSidebar(resource));
          },
          overlayLabel: get(resource, 'full_name')
        }}
        allowCreateNew
      />
    </div>
  );
};

ResourceConnectFormBlock.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  field: PropTypes.string,
  resourceTypeModel: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ResourceConnectFormBlock;
