import React, { Fragment } from 'react';
import { EventDateRange } from 'v1/components/shared';
import classnames from 'classnames';
import './EventConflict.scss';

const EventConflict = ({
  evt,
  prevEvt,
  title,
  onChange,
  showConflict,
  isDiscared
}) => {
  return (
    <div className={classnames('EventConflict', isDiscared && 'discarded')}>
      {title && <div className="EventConflict-title">{title}</div>}
      <div className="EventConflict-dates">
        <EventDateRange size="S" data={prevEvt} isReadOnly />
        {showConflict && (
          <Fragment>
            <img src="/images/icon_arrow_right_grey.svg" alt="" />
            <EventDateRange
              size="S"
              data={evt}
              onChange={onChange}
              isReadOnly={isDiscared}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

EventConflict.defaultProps = {
  showConflict: true
};

export default EventConflict;
