import classnames from 'classnames';

import { Grid, GridCell, Text } from 'shared';

import styles from './ModalHeader.module.scss';

type ModalHeaderProps = {
  /**
   * The sidebar title
   */
  title: string;
  /**
   * The sidebar description
   */
  description?: string;
};

const ModalHeader = ({
  title,
  description,
}: ModalHeaderProps) => (
  <div className={classnames(styles.ModalHeader)}>
    <Grid direction="column">
      <GridCell>
        <Text size="m">
          {title}
        </Text>
        {description && <Text size="s" color="subtle">{description}</Text>}
      </GridCell>
    </Grid>
  </div>
);

export default ModalHeader;
