import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {
  convertTo12HourTime,
  convertTo24HourTime
} from 'v1/helpers/byType/dateHelper';

import { TextInputGroup } from 'v1/components/shared';

const INITIAL_DEFAULT_TIME = { time: '09:00', unit: 'am' };
const APPEND_INPUT_GROUP_PARAMETERS = {
  valueKey: 'unit',
  options: ['am', 'pm']
};

// TODO: ideally this should display eg '7:00 pm' rather than '07:00 pm'
/**
 * A time input allowing users to enter a time using the 12 hour format.
 * This is a controlled input due to the complexity of having to switch
 * between 24h hour and 12 hour formats. Why? We want to display the time
 * as 12 hour for the user, but the server stores it in 24 hour.
 * @param props
 * @returns {JSX.Element}
 */
const TimeInput12h = ({ value, onChange, onBlur, ...props }) => {
  const [inputTime, setInputTime] = useState(
    value ? convertTo12HourTime(value) : INITIAL_DEFAULT_TIME
  );

  useEffect(() => {
    if (value) {
      setInputTime(convertTo12HourTime(value));
    }
  }, [value]);

  function handleOnChange(updatedValue) {
    // Get values returned from input and format correctly.
    // If undefined / no value default to whats stored in state.
    // By converting to 24hour time here we correctly update the input time
    // first time round rather than waiting for the prop value to update
    const updatedTime = convertTo24HourTime({
      time: get(updatedValue, 'time') || inputTime.time,
      unit: get(updatedValue, 'unit') || inputTime.unit
    });

    setInputTime(convertTo12HourTime(updatedTime));
    onChange && onChange(updatedTime);
  }

  function handleOnBlur(value) {
    onBlur && onBlur(convertTo24HourTime(value));
  }

  return (
    <TextInputGroup
      type="time"
      value={inputTime}
      valueKey="time"
      append={APPEND_INPUT_GROUP_PARAMETERS}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  );
};

TimeInput12h.propTypes = {
  value: PropTypes.string, // 24 Hour Time format: 14:00
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default TimeInput12h;
