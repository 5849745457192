import React from 'react';
import { Capability, FeatureFlag } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';

type IntegrationRestrictedAccessProps = {
  children: React.ReactNode;
};

const IntegrationRestrictedAccess = ({ children }: IntegrationRestrictedAccessProps) => {
  const splashLightAllowed = useRestrictedAccess(FeatureFlag.CUSTOM_SPLASHLIGHT, Capability.CONFIGURE_INTEGRATIONS);
  const shotFlowAllowed = useRestrictedAccess(FeatureFlag.CUSTOM_SHOTFLOW);

  return children && (splashLightAllowed || shotFlowAllowed) ? children : null;
};

export default IntegrationRestrictedAccess;
