import { LengthUnit } from '__types__';

type LengthData = {
  label: string;
  value: LengthUnit;
};

const LENGTHS: LengthData[] = [
  {
    label: 'Inch',
    value: LengthUnit.IN
  },
  {
    label: 'Foot',
    value: LengthUnit.FT
  },
  {
    label: 'Yard',
    value: LengthUnit.YD
  },
  {
    label: 'Millimeter',
    value: LengthUnit.MM
  },
  {
    label: 'Centimeter',
    value: LengthUnit.CM
  },
  {
    label: 'Meter',
    value: LengthUnit.M
  }
];

export default LENGTHS;
