import React from 'react';
import PropTypes from 'prop-types';

import { Form, Field } from 'react-form';

import {
  Fieldset,
  Grid,
  GridCell,
  Label,
  TimeInput12h,
  SingleDateTimeField,
  TextInputField,
  TimezoneSelect
} from 'v1/components/shared';

import { DEFAULT_START_TIME } from 'v1/helpers/byType/dateHelper';

import moment from 'moment-timezone';

const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD');

const CallsheetFormBlock = ({ callsheet = {}, updateCallsheet }) => {
  const { title, from_date, to_date, call_time, timezone } = callsheet;

  return (
    <Form
      defaultValues={{
        title,
        from_date: from_date || DEFAULT_FROM_DATE,
        call_time: call_time || DEFAULT_START_TIME,
        to_date: to_date,
        timezone
      }}
      onChange={updateCallsheet}
      validateOnSubmit
    >
      {formApi => (
        <form onSubmit={formApi.submitForm}>
          <div id="formElement">
            <Fieldset>
              <h3 className="stack-S">Callsheet details</h3>
              <Grid className="form-group">
                <GridCell>
                  <TextInputField
                    label="Title"
                    field="title"
                    placeholder="Title"
                    validate="required"
                    initialFocus
                    removeAutocomplete
                  />
                </GridCell>
              </Grid>
              <Grid gutters="S" className="stack-XS">
                <GridCell className="form-group">
                  <Grid>
                    <GridCell>
                      <Label>
                        {formApi.values.to_date ? 'From date' : 'Date'}
                      </Label>
                    </GridCell>
                    {!formApi.values.to_date && (
                      <GridCell width="auto">
                        <a
                          onClick={() => formApi.setValue('to_date', moment())}
                          className="genericLink genericLink__subtle"
                        >
                          Set date range
                        </a>
                      </GridCell>
                    )}
                  </Grid>
                  <SingleDateTimeField
                    field="from_date"
                    placeholder="MM/DD/YYYY"
                    validate="required"
                    dateFormat="ddd Do MMM"
                    className="emulate-input emulate-input_outline"
                    outputFormat="YYYY-MM-DD"
                    timezone={timezone}
                    timeFormat={false}
                  />
                </GridCell>
                {formApi.values.to_date && (
                  <GridCell>
                    <Grid>
                      <GridCell>
                        <Label>To date</Label>
                      </GridCell>
                      <GridCell width="auto">
                        <a
                          onClick={() => formApi.setValue('to_date', null)}
                          className="genericLink genericLink_subtle"
                        >
                          Remove
                        </a>
                      </GridCell>
                    </Grid>
                    <SingleDateTimeField
                      field="to_date"
                      placeholder="MM/DD/YYYY"
                      validate="required"
                      dateFormat="ddd Do MMM"
                      outputFormat="YYYY-MM-DD"
                      className="emulate-input emulate-input_outline"
                      timezone={timezone}
                      timeFormat={false}
                    />
                  </GridCell>
                )}
                <GridCell className="form-group">
                  <Label>General call time</Label>
                  <Field field="call_time">
                    {fieldApi => (
                      <TimeInput12h
                        onChange={value => {
                          fieldApi.setValue(`${value}:00`);
                        }}
                        value={fieldApi.value}
                      />
                    )}
                  </Field>
                </GridCell>
              </Grid>
              <Grid>
                <GridCell>
                  <Field field="timezone">
                    {fieldApi => (
                      <TimezoneSelect
                        field="timezone"
                        timezone={{
                          timeZoneName: fieldApi.value
                        }}
                        dropdownProps={{
                          offset: {
                            left: 0,
                            top: '15px'
                          }
                        }}
                        onChange={(field, value) => {
                          fieldApi.setValue(value.timeZoneName);
                        }}
                      />
                    )}
                  </Field>
                </GridCell>
              </Grid>
            </Fieldset>
          </div>
        </form>
      )}
    </Form>
  );
};

CallsheetFormBlock.propTypes = {
  callsheet: PropTypes.object,
  updateCallsheet: PropTypes.func
};

export default CallsheetFormBlock;
