import React from 'react';
import PropTypes from 'prop-types';

import { ResourceFilters, SearchInput } from 'v1/components/shared';

const ResourceSearchSidebar = ({ query = {}, onSearch }) => {
  return (
    <div className="ModalWithContactPreview-search">
      <div className="ModalWithContactPreview-search-row stack-S">
        <SearchInput
          onSearch={textQuery => onSearch({ ...query, query: textQuery })}
          query={query}
          inputClassName="small-input"
          debounce={300}
          size="S"
        />
      </div>

      <ResourceFilters
        showMeta={true}
        query={query}
        onChange={onSearch}
        isClearable
      />
    </div>
  );
};

ResourceSearchSidebar.propTypes = {
  query: PropTypes.object,
  onChange: PropTypes.func
};

export default ResourceSearchSidebar;
