import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import Sortable from 'react-sortablejs';
import { get, orderBy } from 'lodash';

import { Capability } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';
import { getRates } from 'store/v1/rates/rates.actions.js';
import TypeContactRate from 'v1/components/shared/byModel/Rate/__types__/TypeContactRate';
import ResourceRatesHeader from './ResourceRatesHeader/ResourceRatesHeader';
import ResourceRatesFooter from './ResourceRatesFooter/ResourceRatesFooter';

import { RateItem } from 'v1/components/shared';

import './ResourceRates.scss';

const DEFAULT_INTERVAL = 'DAILY';

// TODO: reorderability
const ResourceRates = ({ label, value, readOnly, onSave }) => {
  const dispatch = useDispatch();
  const rates = useSelector(state => state.rates);
  const defaultCurrency = useSelector(state =>
    get(state, 'auth.settings.settings.defaults_currency.value')
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState();
  const [values, setValues] = useState([]);

  useEffect(() => {
    !rates.fetched && dispatch(getRates());
  }, []);

  useEffect(() => {
    setValues(orderBy(value, 'order'));
  }, [value]);

  useEffect(() => {
    // If there's no onSave we want to force readOnly mode
    if (!onSave || readOnly) {
      setIsReadOnly(true);
    }
  }, [readOnly, onSave]);

  const toggleIsExpanded = () => setIsExpanded(e => !e);

  const toggleIsEditing = () => setIsEditing(e => !e);

  const handleAddCustomRate = () => {
    setValues(prev => [
      ...prev,
      {
        id: `temp-${v4()}`,
        order: prev.length,
        currency: defaultCurrency,
        interval: DEFAULT_INTERVAL
      }
    ]);
    setIsEditing(true);
  };

  const handleAddRateCard = rateId => {
    // Prevent adding same rate card twice
    if (!values.find(val => val.rate_id === rateId)) {
      setValues(prev => [
        ...prev,
        { id: `temp-${v4()}`, order: prev.length, rate_id: rateId }
      ]);
    }
    setIsEditing(true);
  };

  const handleUpdate = value => {
    setValues(prev =>
      orderBy(
        prev.map(e => (e.id === value.id ? value : e)),
        'order'
      )
    );
  };

  const handleUpdateOrder = newOrder => {
    setValues(prev =>
      orderBy(
        newOrder.map((oldIndex, newIndex) => ({
          ...prev[oldIndex],
          order: newIndex
        })),
        'order'
      )
    );
  };

  const handleRemove = id => {
    setValues(prev => prev.filter(e => e.id !== id));
  };

  const handleSave = () => {
    onSave && onSave(values);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setValues(orderBy(value, 'order'));
    setIsEditing(false);
  };

  return (
    <RestrictedAccess
      capabilitiesAtLeastOne={[Capability.RATE_READ, Capability.RATE_UPDATE]}
    >
      <div className="ResourceRates">
        <ResourceRatesHeader
          label={label}
          rateCount={values.length}
          readOnly={isReadOnly}
          isEditing={isEditing}
          toggleIsEditing={toggleIsEditing}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
        <Sortable
          options={{
            handle: '.RateItem-drag',
            dataIdAttr: 'data-order'
          }}
          onChange={handleUpdateOrder}
        >
          {(!isExpanded && !isEditing && values.length
            ? [values[0]]
            : values
          ).map((rate, i) => (
            <div key={get(rate, 'id')} data-order={i}>
              <RateItem
                rate={rate}
                readOnly={isReadOnly}
                isEditing={isEditing}
                onChange={handleUpdate}
                onRemove={handleRemove}
              />
            </div>
          ))}
        </Sortable>
        <ResourceRatesFooter
          rateCount={values.length}
          readOnly={isReadOnly}
          isEditing={isEditing}
          isExpanded={isExpanded}
          toggleIsExpanded={toggleIsExpanded}
          addCustomRate={handleAddCustomRate}
          addRateCard={handleAddRateCard}
        />
      </div>
    </RestrictedAccess>
  );
};

ResourceRates.props = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(TypeContactRate),
  readOnly: PropTypes.bool,
  onSave: PropTypes.func // Omitting this will force readOnly mode
};

ResourceRates.defaultProps = {
  value: []
};

export default ResourceRates;
