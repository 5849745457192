import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { orderReducer } from 'v1/helpers/misc';

const selectResourceTypesStore = state => state.resource_types;

const selectResourceTypes = createSelector(
  selectResourceTypesStore,
  resourceTypes => resourceTypes.data
);

const selectResourceTypesList = createSelector(
  selectResourceTypesStore,
  store => orderReducer(store, 'order').filter(e => !e.archived)
);

const selectResourceTypeById = createCachedSelector(
  selectResourceTypes,
  (_, id) => id,
  (data, id) => data[id]
)((_, id) => id);

export {
  selectResourceTypesStore,
  selectResourceTypes,
  selectResourceTypesList,
  selectResourceTypeById
};
