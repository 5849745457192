import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { updateAccount, ACCOUNTS_ACTIONS } from 'store/accounts';
import AuthService from 'lib/auth/AuthService';
import useEvent from 'v1/helpers/hooks/useEvent';

import OnboardingViewBase from '../OnboardingViewBase';
import CreateProfilePanel from './CreateProfilePanel';
import { Grid, GridCell, Heading, Text } from 'shared';

const CreateProfile = ({ location }) => {
  const dispatch = useDispatch();
  const [updateComplete, setUpdateComplete] = useState(false);

  useEvent([ACCOUNTS_ACTIONS.updateAccount], {
    onSuccess: () => {
      setUpdateComplete(updateComplete => !updateComplete);
    }
  });

  const onSubmit = data => {
    const token = AuthService.token();
    const id = token.account.id;
    dispatch(updateAccount({ id, data }));
  };

  if (updateComplete) {
    const teamSchema = location.state.teamSchema;
    return (
      <Redirect
        to={{
          pathname: `/app/${teamSchema}/onboarding/completed-registration`,
          state: { teamSchema }
        }}
      />
    );
  }

  return (
    <OnboardingViewBase primary="yellow" secondary="blue">
      <Grid align="center">
        <GridCell>
          <div className="stack-xl" />
          <Heading type="h1" family="serif" stack="m">
            About you
          </Heading>
          <Text color="black" stack="xl">
            Tell us a few things about yourself to get started
          </Text>
          <CreateProfilePanel onSubmit={onSubmit} />
        </GridCell>
      </Grid>
    </OnboardingViewBase>
  );
};

export default CreateProfile;
