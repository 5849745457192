import { SYSTEM_ACTIONS } from 'store/v1/system/system.constants.js';

//import { has, get } from 'lodash';

export const INITIAL_STATE = {
  loading: false,
  error: false,
  system_search_results: {},
  duplicates: [],
  duplicate_pagination: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SYSTEM_ACTIONS.SEARCH_SYSTEM:
    case SYSTEM_ACTIONS.GET_RESOURCE_DUPLICATES:
    case SYSTEM_ACTIONS.MERGE_RESOURCE:
      return {
        ...state,
        error: false,
        loading: action.type
      };
    case SYSTEM_ACTIONS.SEARCH_SYSTEM_SUCCESS:
      return {
        ...state,
        system_search_results: action.result,
        loading: false
      };
    case SYSTEM_ACTIONS.GET_RESOURCE_DUPLICATES_SUCCESS:
      return {
        ...state,
        duplicates: action.result.results,
        duplicate_pagination: action.result.paging,
        loading: false
      };
    case SYSTEM_ACTIONS.MERGE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SYSTEM_ACTIONS.SEARCH_SYSTEM_FAILURE:
    case SYSTEM_ACTIONS.GET_RESOURCE_DUPLICATES_FAILURE:
    case SYSTEM_ACTIONS.MERGE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
