export const RESOURCE_TYPE_ICONS = {
  person: {
    black: 'icon_resource_person_black.svg',
    white: 'icon_resource_person_white.svg',
    colour: 'icon_resource_person_colour.svg'
  },
  location: {
    black: 'icon_resource_location_black.svg',
    white: 'icon_resource_location_white.svg',
    colour: 'icon_resource_location_colour.svg'
  },
  flag: {
    black: 'icon_resource_flag_black.svg',
    white: 'icon_resource_flag_white.svg',
    colour: 'icon_resource_flag_colour.svg'
  },
  park: {
    black: 'icon_resource_park_black.svg',
    white: 'icon_resource_park_white.svg',
    colour: 'icon_resource_park_colour.svg'
  },
  tree: {
    black: 'icon_resource_tree_black.svg',
    white: 'icon_resource_tree_white.svg',
    colour: 'icon_resource_tree_colour.svg'
  },
  chair: {
    black: 'icon_resource_chair_black.svg',
    white: 'icon_resource_chair_white.svg',
    colour: 'icon_resource_chair_colour.svg'
  },
  home: {
    black: 'icon_resource_home_black.svg',
    white: 'icon_resource_home_white.svg',
    colour: 'icon_resource_home_colour.svg'
  },
  school: {
    black: 'icon_resource_school_black.svg',
    white: 'icon_resource_school_white.svg',
    colour: 'icon_resource_school_colour.svg'
  },
  stadium: {
    black: 'icon_resource_stadium_black.svg',
    white: 'icon_resource_stadium_white.svg',
    colour: 'icon_resource_stadium_colour.svg'
  },
  store: {
    black: 'icon_resource_store_black.svg',
    white: 'icon_resource_store_white.svg',
    colour: 'icon_resource_store_colour.svg'
  },
  star: {
    black: 'icon_resource_star_black.svg',
    white: 'icon_resource_star_white.svg',
    colour: 'icon_resource_star_colour.svg'
  },
  default: {
    black: 'icon_resource_person_black.svg',
    white: 'icon_resource_person_white.svg',
    colour: 'icon_resource_person_colour.svg'
  }
};
