import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { selectActiveFeatureFlags } from 'store/teams';
import { selectActiveCapabilities } from 'store/userGroups';

import ProductionDetailsForm from 'v1/components/feature/ProductionComponents/ProductionDetailsForm/ProductionDetailsForm';
import { ProductionTemplateSelect } from 'v1/components/shared';
import { ResourceGroupsEditor } from 'v1/components/shared';
import { CellResourceSlotWithFilters } from 'v1/components/shared';
import { Capability } from '__types__';
import { filterMenuAccess } from 'lib/restrictedAccess';

import {
  PressStud,
  ModalWrapper,
  ModalScrollable,
  ModalContent,
  ModalNavigation,
  Grid,
  Button,
  PageTabs,
  GridCell
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import {
  createProduction,
  updateProduction
} from 'store/v1/productions/productions.actions.js';
import { getProductionTemplates } from 'store/v1/production_templates/production_templates.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { get, assign, chain } from 'lodash';
import moment from 'moment-timezone';
import { v4 } from 'uuid';
import ModalClose from 'modals/layout/ModalClose/ModalClose';

class ProductionSetUpModal extends Component {
  constructor(props) {
    super(props);

    this.app_id = props.auth.app_id;
    this.productionFormApi = {};

    this.state = {
      isCreate: get(props.ui, 'data.create'),
      production: {
        ...this.generateProduction(props),
        master_project_id: null
      },
      submit: {},
      container: get(props.ui, 'data.jumpTo') || 'templates',
      isButtonAction: true
    };
  }
  componentDidMount = () => {
    if (!this.props.production_templates.fetched)
      this.props.getProductionTemplates();
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        if (this.state.isCreate) {
          const redirect = get(this.props.ui, ['data', 'redirect']);
          if (redirect === false) {
            this.props.onRequestClose();
          } else {
            this.props.history.push(
              `/app/${this.app_id}/productions/${nextProps.productions.new.id}`
            );
          }
        } else {
          this.props.displayAlert('success', 'Updated successfully!');
          this.props.onRequestClose();
        }
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', 'Could not create production');
      }
    }
  }
  generateProduction = (props = this.props) => {
    const {
      production = {},
      create,
      startDate,
      endDate
    } = get(props.ui, 'data', {});
    if (!create) return production;
    const defaultProductionType = chain(get(props.production_types, 'data'))
      .filter(p => !p.archived)
      .orderBy('order', 'asc')
      .head()
      .value();
    const production_type_id = get(defaultProductionType, 'id');
    const { resource_slots, ...rest } = production;
    const locationGroupId = `ref-${v4()}`;

    return {
      production_date: {
        start_date: startDate || moment().format('YYYY-MM-DD'),
        end_date: endDate || moment().add(1, 'days').format('YYYY-MM-DD'),
        date_type: 'RECURRING_DEFAULT',
        event_type: 'PRODUCTION'
      },
      budget_currency: get(this.props.auth, [
        'settings',
        'settings',
        'defaults_currency',
        'value'
      ]),
      production_type_id,
      groups: [
        {
          id: locationGroupId,
          name: 'Locations',
          order: 0
        }
      ],
      resource_slots: (resource_slots || []).map(r =>
        r.is_location
          ? { ...r, group_id: rest.groups ? r.group_id : locationGroupId }
          : r
      ),
      ...rest
    };
  };

  formatCurrencyCustomField = customField => ({
    ...customField,
    integer_value: customField.integer_value || null
  });
  formatCustomFields = (customFields = []) => {
    const customFieldDefs = get(this.props.auth, [
      'settings',
      'custom_field_definitions'
    ]);
    const currencyCustomFieldIds = customFieldDefs
      .filter(d => d.data_type === 'CURRENCY')
      .map(d => d.id);
    return customFields.map(field => {
      return currencyCustomFieldIds.includes(field.custom_field_definition_id)
        ? this.formatCurrencyCustomField(field)
        : field;
    });
  };

  handleSubmit = () => {
    let production = this.state.production;

    production = {
      ...production,
      custom_fields: this.formatCustomFields(production.custom_fields)
    };

    this.setState(
      {
        event: this.state.isCreate ? 'CREATE_PRODUCTION' : 'UPDATE_PRODUCTION'
      },
      () => {
        this.state.isCreate
          ? this.props.createProduction(production, this.state.event)
          : this.props.updateProduction(
              production.id,
              production,
              this.state.event
            );
      }
    );
  };

  onProductionGroupsUpdate = (values, navigateTo) => {
    const production = { ...this.state.production };
    const updatedProduction = assign(production, values);
    this.setState(prev => ({
      production: updatedProduction,
      container: navigateTo ? navigateTo : prev.container
    }));
  };
  onProductionUpdate = (values, navigateTo) => {
    const production = { ...this.state.production };
    const updatedProduction = assign(production, values);
    this.setState(
      prev => ({
        production: updatedProduction,
        container: navigateTo ? navigateTo : prev.container
      }),
      () =>
        !this.state.isCreate && this.state.isButtonAction && this.handleSubmit()
    );
  };
  primaryActionHandler = () => {
    const { loading } = this.props.productions;
    switch (this.state.container) {
      case 'templates':
        return {
          onClick: () => {
            this.setState({ container: 'details' });
          },
          className: 'btn btn-secondary',
          children: 'Next'
        };
      case 'details':
        return {
          isLoading: loading === 'UPDATE_PRODUCTION',
          onClick: () => this.submitForm('roles', true),
          className: this.state.isCreate
            ? 'btn btn-secondary'
            : 'btn btn-primary',
          children: this.state.isCreate ? 'Next' : 'Save'
        };
      case 'roles':
        return {
          isLoading:
            loading === 'UPDATE_PRODUCTION' || loading === 'CREATE_PRODUCTION',
          onClick: this.handleSubmit,
          className: 'btn btn-primary',
          children: 'Save'
        };
      default:
        break;
    }
  };

  submitForm = async (container, isButtonAction) => {
    this.setState({ isButtonAction });

    if (
      this.state.container === 'details' &&
      !isEmpty(this.productionFormApi)
    ) {
      await this.productionFormApi.submitForm();

      if (this.productionFormApi.getError()) {
        return false;
      }
    }

    return this.setState({ container });
  };

  renderContent = () => {
    const { loading } = this.props.productions;
    const isLoading =
      loading === 'CREATE_PRODUCTION' || loading === 'UPDATE_PRODUCTION';

    switch (this.state.container) {
      case 'templates':
      default:
        return (
          <ProductionTemplateSelect
            production={this.state.production}
            productionTypes={this.props.production_types}
            productionTemplates={this.props.production_templates}
            onProductionUpdate={this.onProductionUpdate}
            production_types={this.props.production_types}
            isCreate={this.state.isCreate}
            isSubmitting={isLoading}
            statuses={this.props.statuses}
          />
        );
      case 'details':
        return (
          <ProductionDetailsForm
            production={this.state.production}
            onProductionUpdate={this.onProductionUpdate}
            isCreate={this.state.isCreate}
            getApi={getApi => {
              this.productionFormApi = getApi;
            }}
            isSubmitting={isLoading}
          />
        );
      case 'roles':
        return (
          <ResourceGroupsEditor
            locationGroups={['Locations']}
            production={this.state.production}
            groups={get(this.state, 'production.groups')}
            slots={get(this.state, 'production.resource_slots')}
            onChange={({ slots, groups }) => {
              this.onProductionGroupsUpdate({
                resource_slots: slots,
                groups
              });
            }}
            slotComponent={(slot, onChange, onDelete) => {
              return (
                <CellResourceSlotWithFilters
                  key={slot.id}
                  role={slot}
                  onChange={onChange}
                  onDelete={onDelete}
                  showResources={this.state.isCreate}
                  deletePermission={Capability.PRODUCTION_UPDATE}
                />
              );
            }}
          />
        );
    }
  };
  render() {
    const { onRequestClose } = this.props;
    const isProductionCalendar = this.props.location.pathname.includes(
      '/calendar/productions'
    );

    const actionButton = this.primaryActionHandler();
    const templatesTab = {
      capabilities: Capability.CONFIGURE_PRODUCTION_TEMPLATES,
      text: 'Templates',
      container: 'templates'
    };
    const detailsTab = {
      capabilities: Capability.PRODUCTION_UPDATE,
      text: 'Details',
      container: 'details'
    };
    const rolesTab = {
      capabilities: Capability.PRODUCTION_UPDATE,
      text: 'Roles',
      container: 'roles'
    };
    const tabs = this.state.isCreate
      ? [templatesTab, detailsTab, rolesTab]
      : isProductionCalendar
        ? [detailsTab, rolesTab]
        : [detailsTab];

    const productionTitle = this.state.isCreate
      ? 'Create production'
      : 'Edit production';

    return (
      <ModalWrapper>
        <ModalHeader title={productionTitle} />
        <ModalContent>
          <ModalNavigation>
            <Grid gutters="S">
              <GridCell>
                <PageTabs
                  tabs={filterMenuAccess(
                    tabs,
                    this.props.activeFeatureFlags,
                    this.props.activeCapabilities
                  )}
                  activeContainer={this.state.container}
                  onTabSelect={container => this.submitForm(container, false)}
                />
              </GridCell>
              {/* <GridCell width="auto">
                <Button {...actionButton} />
              </GridCell> */}
            </Grid>
          </ModalNavigation>
          <ModalScrollable
            padding={this.state.container === 'templates' ? '0' : 'L'}
          >
            {this.renderContent()}
          </ModalScrollable>
          <ModalNavigation>
            <Grid gutters="S">
              <GridCell>
                {/* <PageTabs
                  tabs={filterMenuAccess(
                    tabs,
                    this.props.activeFeatureFlags,
                    this.props.activeCapabilities
                  )}
                  activeContainer={this.state.container}
                  onTabSelect={container => this.submitForm(container, false)}
                /> */}
              </GridCell>
              <GridCell width="auto">
                <Button {...actionButton} />
              </GridCell>
            </Grid>
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    production_types,
    productions,
    events,
    ui,
    auth,
    production_templates
  } = state;
  return {
    production_types,
    productions,
    events,
    ui,
    auth,
    production_templates,
    activeFeatureFlags: selectActiveFeatureFlags(state),
    activeCapabilities: selectActiveCapabilities(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      displayAlert,
      createProduction,
      updateProduction,
      getProductionTemplates
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductionSetUpModal)
);
