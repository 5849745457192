import React, { Component } from 'react';
//import VideoBlock from 'v1/components/MediaComponents/VideoBlock/VideoBlock';
import { ComingSoonPlaceholder } from 'v1/components/shared';
import { connect } from 'react-redux';
//import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import './TutorialModal.scss';

const TUTORIALS = {
  Shortlists: {
    video: 'asdfasdf',
    article: 'asdfasdfasdf',
    icon: '/images/icon_colour_shortlists.svg',
    title: 'Shortlist Guide'
  },
  Callouts: {
    video: 'asdfasdf',
    article: 'asdfasdfasdf',
    icon: '/images/icon_colour_callouts.svg',
    title: 'Callouts Guide'
  },
  'Black Book': {
    video: 'asdfasdf',
    article: 'asdfasdfasdf',
    icon: '/images/icon_colour_blackbook.svg',
    title: 'Contacts Guide'
  },
  Callsheets: {
    video: 'asdfasdf',
    article: 'asdfasdfasdf',
    icon: '/images/icon_colour_callsheets.svg',
    title: 'Callsheet Guide'
  },
  Productions: {
    video: 'asdfasdf',
    article: 'asdfasdfasdf',
    icon: '/images/icon_colour_productions.svg',
    title: 'Productions Guide'
  },
  Dashboard: {
    video: 'asdfasdf',
    article: 'asdfasdfasdf',
    icon: '/images/icon_colour_dashboard.svg',
    title: 'Dashboard Guide'
  }
};

export class TutorialModal extends Component {
  constructor(props) {
    super(props);

    const feature = get(this.props, 'ui.data.tutorial');

    this.state = {
      tutorial: get(TUTORIALS, feature)
    };
  }
  renderHeader = () => {
    const { title, icon } = this.state.tutorial;

    return (
      <div className="TutorialModal-header text-center">
        <img src={icon} width="50px" alt="" />
        <h3>{title}</h3>
      </div>
    );
  };
  renderVideo = () => {
    return (
      <div className="TutorialModal-video">
        <ComingSoonPlaceholder />
        {/* <VideoBlock
									embed_id="287022923"
									provider="VIMEO"
								/> */}
      </div>
    );
  };
  renderArticles = () => {};
  render() {
    return (
      <div className="TutorialModal SmallModal">
        <span className="PageModal-close" onClick={this.props.onRequestClose}>
          <img src="/images/icon_cross_black.svg" width="12px" alt="" />
        </span>
        {this.renderHeader()}
        {this.renderVideo()}
        {this.renderArticles()}
      </div>
    );
  }
}

function mapStateToProps({ ui }) {
  return { ui };
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators({ openModal }, dispatch);
// }

export default connect(mapStateToProps, null)(TutorialModal);
