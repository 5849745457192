import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { TextInputField } from 'v1/components/shared';
import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { Form } from 'react-form';
import { closeModal, displayAlert } from 'store/v1/ui/ui.actions.js';
import { TAGS_ACTIONS, createTag, updateTag } from 'store/v1/tags';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
//import { validate } from 'v1/helpers/validation';
import { get } from 'lodash';
import { v4 } from 'uuid';

class TagCreateModal extends Component {
  constructor(props) {
    super(props);

    this.app_id = props.auth.app_id;
    this.formApi = {};

    this.state = {
      tag: props.ui.data.tag || {},
      isCreate: props.ui.data.create
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        if (this.state.isCreate) {
          this.props.displayAlert('success', 'Tag Created');
        } else {
          this.props.displayAlert('success', 'Tag Updated');
        }
        this.props.onRequestClose();
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }

  handleSubmit = values =>
    this.setState({ event: v4() }, () => {
      this.state.isCreate
        ? this.props
            .createTag(values)
            .then(unwrapResult)
            .then(_ => {
              this.props.displayAlert('success', 'Created successfully');
              this.props.closeModal();
            })
            .catch(() => {})
        : this.props
            .updateTag({
              id: this.state.tag.id,
              data: values
            })
            .then(unwrapResult)
            .then(_ => {
              this.props.displayAlert('success', 'Updated successfully');
              this.props.closeModal();
            })
            .catch(() => {});
    });

  render() {
    const { loading } = this.props.tags;
    const { tag } = this.state.tag;

    return (
      <ModalWrapper size="S">
        <ModalHeader title="Create tags" />
        <ModalContent>
          <ModalScrollable padding="XL">
            <Form
              onSubmit={this.handleSubmit}
              defaultValues={{
                tag
              }}
              getApi={api => (this.formApi = api)}
              validateOnSubmit
            >
              {formApi => (
                <form onSubmit={formApi.submitForm}>
                  <TextInputField
                    label="Tag Name"
                    field="tag"
                    placeholder="Tag"
                    validate="required"
                    initialFocus
                    removeAutocomplete
                  />
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Done"
              appearance="silent"
              action={this.props.onRequestClose}
            />
            <PressStud
              label={this.state.isCreate ? 'Create Tag' : 'Save changes'}
              appearance="primary"
              isLoading={
                loading === TAGS_ACTIONS.CREATE_TAG ||
                loading === TAGS_ACTIONS.UPDATE_TAG
              }
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ tags, events, ui, auth }) {
  return { tags, events, ui, auth };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createTag,
      updateTag,
      displayAlert,
      closeModal
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TagCreateModal)
);
