import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  ExpenseEditMetaFlyover,
  PressStud,
  RateEditFlyover
} from 'v1/components/shared';
import { Table } from 'shared';

const BookingCostsSummaryTable = ({
  title,
  data,
  defaultCurrency,
  type,
  editableMeta,
  handleAdd,
  handleRemove
}) => {
  const columns = useMemo(
    () => [
      {
        Header: title,
        accessor: 'desc',
        border: true,
        cellPadding: value => (editableMeta ? '0' : value ? 'S' : 'M'),
        width: 120,
        Cell: ({ row: { original } }) =>
          editableMeta ? (
            <ExpenseEditMetaFlyover expense={original.rate} />
          ) : (
            <div className="text-13-600">{original.rate.name}</div>
          )
      },
      {
        id: 'cost',
        border: true,
        cellPadding: '0',
        width: 200,
        accessor: 'rate',
        Cell: ({ value }) => (
          <RateEditFlyover
            rate={value}
            defaultCurrency={defaultCurrency}
            type={type}
          />
        )
      },
      {
        id: 'total',
        border: true,
        cellPadding: 'M',
        width: 82,
        accessor: 'totals.rates',
        Cell: ({ value }) =>
          value ? <div className="text-13-600">{value}</div> : null
      },
      {
        id: 'action',
        width: 40,
        cellPadding: '0',
        canResize: false,
        accessor: 'id',
        Cell: ({ value }) =>
          handleRemove ? (
            <div className="inset-XS">
              <PressStud
                appearance="silent"
                icon="trash"
                action={() => handleRemove(value)}
              />
            </div>
          ) : null
      }
    ],
    [title, type, defaultCurrency, editableMeta, handleRemove]
  );

  return (
    <Table
      cellPadding="S"
      rounded={false}
      flush
      columns={columns}
      data={data}
      onRowAdd={handleAdd}
    />
  );
};

BookingCostsSummaryTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  defaultCurrency: PropTypes.string,
  type: PropTypes.string,
  editableMeta: PropTypes.bool,
  handleAdd: PropTypes.func,
  handleRemove: PropTypes.func
};

export default BookingCostsSummaryTable;
