import React, { useState } from 'react';

import {
  GridCell,
  PressStud,
  ColourPicker,
  TextInput
} from 'v1/components/shared';

const GroupItemCellEditable = ({
  group: initialGroup,
  onUpdateGroup,
  onCancelEdit,
  isLoading
}) => {
  const [group, setGroup] = useState(initialGroup || {});

  function onSaveGroup() {
    onUpdateGroup(group);
  }

  return (
    <>
      <GridCell width="auto">
        <ColourPicker
          selected={group.colour}
          onChange={value => setGroup(prev => ({ ...prev, colour: value }))}
          size="S"
          hideRemove
        />
      </GridCell>
      <GridCell>
        <TextInput
          type="text"
          placeholder="Group Name"
          value={group.name}
          size="S"
          onChange={({ target }) =>
            setGroup(prev => ({ ...prev, name: target.value }))
          }
        />
      </GridCell>
      <GridCell width="auto">
        <PressStud
          label="Cancel"
          appearance="silent"
          size="S"
          action={onCancelEdit}
        />
      </GridCell>
      <GridCell width="auto">
        <PressStud
          label="Save"
          appearance="primary"
          size="S"
          isLoading={isLoading}
          action={onSaveGroup}
        />
      </GridCell>
    </>
  );
};

export default GroupItemCellEditable;
