import React from 'react';
import { useSelector } from 'react-redux';

import { Capability } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';

import {
  ListCell,
  Grid,
  GridCell,
  PressStud,
  ResourceAdvancedSearch
} from 'v1/components/shared';
import { SlotCategoryDropdown } from 'v1/components/shared';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

import './CellResourceSlotWithFilters.scss';

const CellResourceSlotWithFilters = ({
  role,
  onChange,
  onDelete,
  showResources,
  deletePermission = Capability.CONFIGURE_ROLES
}) => {
  const contacts = useSelector(state => state.contacts);
  const handleFilterChange = filters => onChange(role.id, 'query', filters);

  // Get first resource to signify user that they have auto-populated assignment in new production
  function assignedResource() {
    if (!_isEmpty(role.resource_slot_assignments)) {
      const assignment = _get(role.resource_slot_assignments, 0);
      const resource = _get(contacts, ['data', assignment.contact_id]);

      return resource ? resource.full_name : null;
    }
  }
  return (
    <ListCell
      className="CellResourceSlotWithFilters draggable"
      padding="XS"
      data-id={role.id}
    >
      <Grid gutters="S" vcenter className="Parent_hoverListener">
        <GridCell width="auto" className="dragHandle">
          <span className="DragIndicator">
            <img src="/images/icon_drag.svg" alt="" />
          </span>
        </GridCell>
        <GridCell width="1/4">
          <SlotCategoryDropdown
            onSelect={(field, value) => {
              if (field !== 'slot_category_id' && !_has(value, 'id')) {
                onChange(role.id, {
                  slot_category_id: null,
                  slot_category: value
                });
              } else onChange(role.id, field, value);
            }}
            buttonClass="emulate-input emulate-input_underline"
            slot={role}
            type="RESOURCE_SLOT"
          />
        </GridCell>
        <GridCell width="auto">
          <img src="/images/icon_search_light.svg" alt="" />
        </GridCell>
        <GridCell>
          <ResourceAdvancedSearch
            slimline={true}
            showMeta={true}
            query={role.query}
            onChange={query => {
              handleFilterChange(query);
            }}
          />
        </GridCell>
        <GridCell width="auto" className="Child_hoverListener">
          <RestrictedAccess capabilities={deletePermission}>
            <PressStud
              icon="delete"
              dataCy="delete-resource-slot"
              action={() => onDelete(role.id)}
            />
          </RestrictedAccess>
        </GridCell>
      </Grid>
      {showResources && assignedResource() && (
        <Grid
          gutters="S"
          className="CellResourceSlotWithFilters-autoAddResource"
        >
          <GridCell className="text-neutral">
            Will automatically assign {assignedResource()} when the production
            is created
          </GridCell>
        </Grid>
      )}
    </ListCell>
  );
};

CellResourceSlotWithFilters.propTypes = {};

export default CellResourceSlotWithFilters;
