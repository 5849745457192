import PropTypes from 'prop-types';
import React from 'react';
import './ButtonToggleGroup.scss';

// TODO: This component is a toggle, where one option must be selected. ButtonToggle must not be used in isolation
// TODO: Rename component
// TODO: This can currently only properly handle 2 options, due to styling constraints
export const ButtonToggleGroup = ({ value, onChange, children }) => {
  return (
    <div className="ButtonToggleGroup">
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          onClick: () => onChange && onChange(child.props.value),
          selected:
            value !== undefined
              ? value === child.props.value
              : child.props.selected
        });
      })}
    </div>
  );
};

ButtonToggleGroup.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  onChange: PropTypes.func
};

export default ButtonToggleGroup;
