import { omit } from 'lodash';
import {
  Resource_normalized,
  resourceSchema
} from 'v4/entities/resource/resource.types';
import { shortlistActionSchema } from 'v4/entities/shortlist/shortlist.types';
import { z, ZodType } from 'zod';

const shortlistBlockSchema = z.object({
  id: z.number(),
  shortlist_id: z.number(),
  contact_id: z.number(),
  data: z
    .object({
      paragraph: z.string().optional()
    })
    .nullable()
    .optional(),
  group_id: z.number().nullable().optional().or(z.undefined()),
  pins: z
    .array(
      z
        .object({
          id: z.number(),
          url: z.string().optional(),
          file: z.unknown().optional(),
          archived: z.boolean().optional().default(false)
        })
        .passthrough() // todo refine schema for pin
    )
    .transform(x => x.filter(x => !x.archived))
    .transform(x => x.map(x => omit(x, 'archived')))
    .optional(), // optional until we get one, convenient and unified endponit to search for everything
  upvotes: z.array(shortlistActionSchema).optional(), // optional until we get one, convenient and unified endponit to search for everything,
  downvotes: z.array(shortlistActionSchema).optional(), // optional until we get one, convenient and unified endponit to search for everything,
  comments: z.array(shortlistActionSchema).optional(), // optional until we get one, convenient and unified endponit to search for everything,
  archived: z.boolean().default(false),
  show_availability: z.unknown().optional(),
  booking_events: z.array(z.unknown()).optional(), // deprecated field, needed for api migration bridge
  order: z.number().nullable(),
  shortlist: z.unknown().optional() // deprecated field, needed for hotfix
});

const normalizedShortlistBlockSchema = shortlistBlockSchema.omit({
  archived: true
});

export type ShortlistBlock_normalized = Omit<
  z.infer<typeof shortlistBlockSchema>,
  'archived'
>;

// for actual `query`
const createListSubquerySchema = <T extends ZodType>(fieldNameSchema: T) =>
  z.object({
    order_by: z
      .object({
        field: fieldNameSchema,
        direction: z.union([z.literal('asc'), z.literal('desc')])
      })
      .optional()
      .or(z.undefined()),
    filters: z.object({}).optional().or(z.undefined()),
    query: z.string().optional().or(z.undefined()),
    archived: z.boolean().optional().or(z.undefined()),
    p: z.number().optional().or(z.undefined()),
    count: z.number().optional().or(z.undefined())
  });

export const createListQuerySchema = <T>(resourceQuerySchema: ZodType<T>) =>
  // todo think about generalizing it, so it will just have resoruceQuery and shortlistQuery, ie resourceQuery:{resource_id: {in: [1,2,3]}} or shortlistQuery:{shortlist_id: {eq: 3}
  z.union([
    z
      .object({
        resource_id: z.number()
      })
      .strict(),
    z
      .object({
        shortlist_id: z.number(),
        query: createListSubquerySchema(z.string()).optional()
      })
      .strict()
  ]);

export type ListQuery<T> = z.infer<ReturnType<typeof createListQuerySchema<T>>>;

export type AssociationQuery = ListQuery<keyof Resource_normalized>;

const createResponseSchema = <T extends ZodType, Q extends ZodType>(
  resultsSchema: T,
  querySchema: Q
) =>
  z.object({
    results: resultsSchema,
    paging: z.object({
      total: z.number(),
      page: z.number(),
      count_per_page: z.number()
    }),
    query: querySchema
  });

export const associationItemsSchema = z
  .union([
    z.array(shortlistBlockSchema),
    z
      .object({
        shortlist_blocks: z.array(shortlistBlockSchema)
      })
      .transform(x => x.shortlist_blocks)
  ])
  .transform(x => x.filter(x => !x.archived).map(x => omit(x, 'archived')));

export const associationStateSchema = createResponseSchema(
  associationItemsSchema,
  createListSubquerySchema(z.string())
);

export type AssociationState = z.infer<typeof associationStateSchema>;

const guestSchema = z
  .object({
    id: z.string(),
    full_name: z.string().optional()
  })
  .strict();

export const querySchemas = {
  list: {
    type: 'list',
    request: createListQuerySchema(resourceSchema.keyof()),
    response: createResponseSchema(
      associationItemsSchema,
      createListSubquerySchema(z.string())
    )
  },
  search: {
    // todo remove after using new shortlist api
    type: 'search',
    request: z
      .object({
        shortlist_id: z.number(),
        query: createListSubquerySchema(z.string()).optional()
      })
      .strict(),
    response: createResponseSchema(
      z.array(shortlistBlockSchema.shape.contact_id),
      createListSubquerySchema(z.string())
    )
  }
};

export const batchUpdateHttpSchemas = {
  request: z
    .object({
      shortlist_id: z.number(),
      payload: z.array(
        shortlistBlockSchema.partial().and(z.object({ id: z.number() }))
      )
    })
    .strict(),
  response: z.object({
    success: z.literal(true)
  })
};

export type BatchUpdateHttpRequest = z.infer<
  typeof batchUpdateHttpSchemas.request
>;
export type BatchUpdateHttpResponse = z.infer<
  typeof batchUpdateHttpSchemas.response
>;

export const mutationSchemas = {
  create: {
    type: 'create',
    request: z
      .object({
        resource_id: z.number(),
        shortlist_id: z.number(),
        payload: shortlistBlockSchema
          .pick({ pins: true, data: true, order: true })
          .partial()
      })
      .strict(),
    response: normalizedShortlistBlockSchema
  },
  duplicate: {
    // @experimental let's check how convenient is to always accept multiple items for a change (batch by default)
    type: 'duplicate',
    request: z.array(
      z.object({
        association_id: shortlistBlockSchema.shape.id,
        tmpworkaround_existing_shortlist_id: z.number(), // we don't have a way to get block only by it's id
        new_resource_id: z.number(),
        new_shortlist_id: z.number()
      })
    ),
    response: z.array(normalizedShortlistBlockSchema)
  },
  createBatch: {
    type: 'createBatch',
    request: z
      .object({
        resource_ids: z.array(z.number()), // todo createBatch won't be picked-up by canAffectQuery for resourceId-scoped queries
        // todo that's why we should make any optimistic/server reducer aware of the query, and always do all necessary checks in the reducer
        shortlist_id: z.number()
      })
      .strict(),
    response: z.unknown().transform(() => undefined) // entire shortlist block is given, we don't want to process it
  },
  delete: {
    type: 'delete',
    request: z
      .object({
        shortlist_id: z.number(),
        association_id: z.number() // that should be enough to identify the association
      })
      .strict(),
    response: z.object({
      success: z.literal(true)
    })
  },
  deleteBatch: {
    type: 'deleteBatch',
    request: z
      .object({
        association_ids: z.array(z.number()),
        shortlist_id: z.number()
      })
      .strict(),
    response: z.undefined()
  },
  update: {
    type: 'update',
    request: z
      .object({
        shortlist_id: z.number(),
        // resource_id: z.number(), // TODO no needed for reconciliation, it is just update, and resource_id can't change over time // needed for reconciliation / if it can affect the query scoped to resource
        association_id: z.number(), // that should be enough to identify the association
        payload: shortlistBlockSchema
          .pick({ data: true, order: true, pins: true })
          .partial()
      })
      .strict(),
    response: normalizedShortlistBlockSchema
  },
  upvote: {
    type: 'upvote',
    request: z
      .object({
        shortlist_id: z.number(),
        shortlist_public_id: z.string(),
        association_id: z.number(),
        guest: guestSchema.optional()
      })
      .strict(),
    response: normalizedShortlistBlockSchema
  },
  downvote: {
    type: 'downvote',
    request: z
      .object({
        shortlist_id: z.number(),
        shortlist_public_id: z.string(),
        association_id: z.number(),
        guest: guestSchema.optional()
      })
      .strict(),
    response: normalizedShortlistBlockSchema
  },
  comment: {
    type: 'comment',
    request: z
      .object({
        shortlist_id: z.number(),
        shortlist_public_id: z.string(),
        association_id: z.number(),
        comment: z.string(),
        guest: guestSchema.optional()
      })
      .strict(),
    response: normalizedShortlistBlockSchema
  },
  reorder: {
    type: 'reorder',
    request: z.object({
      shortlist_id: z.number(),
      orderedBlockIds: z.array(z.number())
    }),
    response: z.object({ success: z.literal(true) })
  },
  move: {
    type: 'move',
    request: z.object({
      shortlist_id: z.number(),
      activeId: z.number(),
      overId: z.number()
    }),
    response: z.object({ success: z.literal(true) })
  }
} as const;

type InferDeep<T> = {
  [K in keyof T]: T[K] extends z.ZodType<any, any, any>
    ? z.infer<T[K]>
    : T[K] extends object
      ? InferDeep<T[K]>
      : T[K];
};

export type ResourceShortlistAssociationConfig = InferDeep<
  typeof mutationSchemas
>;

export type ResourceShortlistAssociationAction =
  ResourceShortlistAssociationConfig[keyof ResourceShortlistAssociationConfig];

type MappedOmit<T, K> = { [P in keyof T as P extends K ? never : P]: T[P] };

export type ResourceShortlistAssociationMutationPayload = MappedOmit<
  ResourceShortlistAssociationAction,
  'response'
>;

export type ResourceShortlistAssociationActionOf<
  Type extends ResourceShortlistAssociationMutationPayload['type']
> = ResourceShortlistAssociationMutationPayload & { type: Type };
export type ResourceShortlistAssociationRequestOf<
  Type extends
    keyof ResourceShortlistAssociationConfig = keyof ResourceShortlistAssociationConfig
> = ResourceShortlistAssociationConfig[Type]['request'];
export type ResourceShortlistAssociationResponseOf<
  Type extends
    keyof ResourceShortlistAssociationConfig = keyof ResourceShortlistAssociationConfig
> = ResourceShortlistAssociationConfig[Type]['response'];
