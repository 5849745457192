import React, { PropsWithChildren } from 'react';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  FieldValues
} from 'react-hook-form';

type FormProps<ValuesType extends FieldValues> = {
  values: UseFormProps<ValuesType>['defaultValues'];
  onSubmit: SubmitHandler<ValuesType>;
  children:
    | React.ReactNode
    | ((methods: UseFormReturn<ValuesType>) => React.ReactNode);
};

const Form = <ValuesType extends Record<string, any>>({
  values,
  onSubmit,
  children
}: FormProps<ValuesType>) => {
  const methods = useForm<ValuesType>({
    defaultValues: values,
    criteriaMode: 'all'
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  );
};

export default Form;
