import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeModal, displayAlert } from 'store/v1/ui/ui.actions';

import {
  CellProductionItem,
  EmptyGeneric,
  Grid,
  GridCell,
  ListCell,
  ListCellGroup,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud,
  ResourceGroupsEditor,
  SingleDateInput,
  StackedFormSection
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import {
  duplicateCallsheetImpl,
  getGroups,
  getTeamMembers
} from 'store/v1/callsheets/callsheets.selectors';
import { getDatesBetweenTwoDates } from 'v1/helpers/dateHelper';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { callsheets } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';

const CallsheetDuplicateModal = ({ onRequestClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const callsheetId = useSelector(state => get(state.ui, 'data.callsheetId'));
  const originalCallsheetQuery = callsheets.useItem(callsheetId);
  const originalCallsheet = useMemo(
    () => originalCallsheetQuery.data || {},
    [originalCallsheetQuery]
  );

  const { app_id } = useSelector(state => state.auth);

  const [newCallsheet, setNewCallsheet] = useState({
    from_date: originalCallsheet.from_date
  });

  const production = useSelector(state =>
    get(state.productions, ['data', originalCallsheet.production_id])
  );

  const selectorParams = {
    callsheetId,
    callsheet: originalCallsheetQuery.data,
    production_id: production && production.id,
    selectedDate: newCallsheet.from_date
  };

  const teamMembers = useSelector(state =>
    getTeamMembers(state, selectorParams)
  );
  const groups = useSelector(state => getGroups(state, selectorParams));
  const fromDate = newCallsheet.from_date;
  const generatedCallsheet = useMemo(
    () =>
      duplicateCallsheetImpl(
        fromDate,
        production,
        teamMembers,
        groups,
        originalCallsheet
      ),
    [fromDate, production, teamMembers, groups, originalCallsheet]
  );

  const createMutation = callsheets.useCreateMutation(
    payload => {
      dispatch(closeModal());
      history.push(`/app/${app_id}/callsheets/${payload.id}`);
    },
    () => dispatch(displayAlert('error', createMutation.error))
  );

  function onCreateCallsheet() {
    createMutation.mutate({ ...newCallsheet, isClone: true });
  }

  // this is just about lazy initialization of the "form" state
  useEffect(() => {
    setNewCallsheet(generatedCallsheet);
  }, [generatedCallsheet]);

  function updateCallsheet(field, value) {
    setNewCallsheet(prev => ({ ...prev, [field]: value }));
  }

  return (
    <ModalWrapper size="M">
      <ModalHeader title="Duplicate Callsheet" />
      <ModalContent>
        <ModalScrollable>
          <StackedFormSection
            title="Select new date"
            description="Dates with a blue dot highlight the production dates"
          >
            <SingleDateInput
              placeholder="Select date"
              date={newCallsheet.from_date}
              className="emulate-input emulate-input_outline emulate-input_M"
              dateFormat="eee dd MMM"
              highlightDates={
                production
                  ? [
                      {
                        'EventDateRange-highlighter': getDatesBetweenTwoDates(
                          production.production_date
                        )
                      }
                    ]
                  : []
              }
              onChange={({ date }) => {
                updateCallsheet('from_date', date);
              }}
            />
          </StackedFormSection>
          {production && (
            <StackedFormSection
              title="Confirmed production resources"
              description="This list shows you the confirmed bookings that we'll pull into the new callsheet"
            >
              <ListCellGroup>
                <CellProductionItem production={production} />
                {!isEmpty(newCallsheet.team_members) ? (
                  <ListCell>
                    <ResourceGroupsEditor
                      appearance="compact"
                      groups={get(newCallsheet, 'groups')}
                      slots={get(newCallsheet, 'team_members')}
                      slotComponent={slot => {
                        return (
                          <ListCell padding="XS" data-id={slot.id}>
                            <Grid gutters="S" vcenter>
                              <GridCell width="3/12">
                                <span className="text-13-600">
                                  {slot.display_role}
                                </span>
                              </GridCell>
                              <GridCell>{slot.display_name}</GridCell>
                            </Grid>
                          </ListCell>
                        );
                      }}
                    />
                  </ListCell>
                ) : (
                  <ListCell>
                    <EmptyGeneric
                      title="No resources booked for this day"
                      description="There are no resources in the shoot that are booked for this day. Try changing the date or book resources within the shoot."
                    />
                  </ListCell>
                )}
              </ListCellGroup>
            </StackedFormSection>
          )}
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            appearance="silent"
            label="Cancel"
            action={onRequestClose}
          />
          <PressStud
            appearance="primary"
            label="Create callsheet"
            action={onCreateCallsheet}
            isLoading={createMutation.isLoading}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default CallsheetDuplicateModal;
