import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Select.module.scss';

const Select = ({
  className,
  id,
  name,
  value,
  appearance,
  size,
  validity,
  children,
  ...props
}) => {
  // We don't want to allow multiple
  const { multiple, ...rest } = props;

  return (
    <select
      id={id}
      name={name}
      value={value || ''}
      className={classnames(
        styles.Select,
        {
          [styles[`Select_${appearance}`]]: appearance,
          [styles[`Select_${size}`]]: size,
          [styles[`Select_${validity}`]]: validity,
          [styles.Select_empty]: value == null || value === ''
        },
        className
      )}
      {...rest}
    >
      {children}
    </select>
  );
};

Select.propTypes = {
  // Component props
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'silent', 'underline']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  validity: PropTypes.oneOf(['valid', 'invalid']),
  children: PropTypes.node,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array // TODO: string or number
  ])
  // ...props
  // All valid attributes for <select />
  // Keeping in mind React-specific attribute case:
  // https://react-cn.github.io/react/docs/tags-and-attributes.html
};

Select.defaultProps = {
  appearance: 'outline'
};

export default Select;
