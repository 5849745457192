import React from 'react';
import PropTypes from 'prop-types';

import { htmlStringParser } from 'v1/helpers/misc';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import TypeTextInput from 'v1/components/shared/byType/text/TextInput/__types__/TypeTextInput';
import { TextInput } from 'v1/components/shared';

interface UrlInputProps extends Omit<TextInputProps, 'onChange'> {
  showOverlay?: boolean;
  onChange?(value: string | null): void;
}

const UrlInput = ({
  placeholder,
  value,
  showOverlay = true,
  onChange,
  ...props
}: UrlInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(value);
  };

  return (
    <TextInput
      type="url"
      placeholder={placeholder !== undefined ? placeholder : 'URL'}
      value={value}
      overlayLabel={showOverlay ? htmlStringParser(value) : null}
      overlayTooltip={showOverlay ? value : null}
      overlayAction={showOverlay ? htmlStringParser(value) : null}
      spellCheck={false}
      onChange={handleChange}
      {...rest}
    />
  );
};

UrlInput.propTypes = {
  ...TypeTextInput,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showOverlay: PropTypes.bool,
  onChange: PropTypes.func
  // Inherits standard TextInput & HTML input properties
};

export default UrlInput;
