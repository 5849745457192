import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { closeModal } from 'store/v1/ui/ui.actions.js';
import {
  ExpenseReceiptManagement,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  PressStud
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

//import { validate as validator } from 'v1/helpers/validation';

/*function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}*/

const ExpenseReceiptsModal = () => {
  const ui = useSelector(state => state.ui);
  const { expenseId = {}, productionId, budgetExpenseId } = get(ui, 'data', {});

  const dispatch = useDispatch();

  // PROPS AND REFS
  //   const {
  //     id,
  //     name
  //     // model, // TBC
  //     // internal, // TBC
  //     // icon, // TBC
  //     // metastructure // TBC
  //   } = booking_type;

  //   const [formApi, setFormApi] = useState();

  // EFFECTS
  //   useEvent(
  //     [
  //       BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE,
  //       BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE
  //     ],
  //     {
  //       onSuccess: () => {
  //         dispatch(closeModal());
  //         dispatch(displayAlert('success', 'Updated succesfully'));
  //       },
  //       onFailure: () => dispatch(displayAlert('error', error.message))
  //     }
  //   );

  // METHODS
  //   function handleSubmit(values) {
  //     create
  //       ? dispatch(createBookingType(values))
  //       : dispatch(updateBookingType(id, values));
  //   }

  return (
    <ModalWrapper size="M">
      <ModalHeader title="Manage expense receipts" />
      <ModalContent>
        <ModalScrollable padding="L">
          <ExpenseReceiptManagement
            expenseId={expenseId}
            productionId={productionId}
            budgetExpenseId={budgetExpenseId}
          />
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Done"
            appearance="primary"
            action={() => dispatch(closeModal())}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ExpenseReceiptsModal;
