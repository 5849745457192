import { GROUP_ACTIONS } from 'store/v1/groups/groups.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GROUP_ACTIONS.GET_GROUPS:
    case GROUP_ACTIONS.CREATE_GROUP:
    case GROUP_ACTIONS.UPDATE_GROUP:
    case GROUP_ACTIONS.ADD_TO_GROUP:
    case GROUP_ACTIONS.DELETE_GROUP:
    case GROUP_ACTIONS.REORDER_GROUPS:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case GROUP_ACTIONS.GET_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        error: false,
        fetched: true
      };
    case GROUP_ACTIONS.GET_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GROUP_ACTIONS.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        }
      };
    case GROUP_ACTIONS.UPDATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GROUP_ACTIONS.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false,
        error: false
      };
    case GROUP_ACTIONS.CREATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GROUP_ACTIONS.ADD_TO_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case GROUP_ACTIONS.DELETE_GROUP_SUCCESS:
      const { [action.group_id]: item, ...data } = state.data;
      return {
        ...state,
        loading: false,
        data,
        order: state.order.filter(id => id !== action.group_id)
      };
    case GROUP_ACTIONS.REORDER_GROUPS_SUCCESS:
      const updateData = state.data;
      action.result &&
        action.result.map(group => {
          updateData[group.id] = group;
        });
      return {
        ...state,
        data: updateData,
        loading: false
      };
    case GROUP_ACTIONS.DELETE_GROUP_FAILURE:
    case GROUP_ACTIONS.ADD_TO_GROUP_FAILURE:
    case GROUP_ACTIONS.REORDER_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
