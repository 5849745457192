import { keepPreviousData } from '@tanstack/react-query';
import { Resource, Shortlist } from '__types__/index';
import useModal from 'hooks/useModal';
import Modal from 'modals/layout/index';
import React, { useEffect, useRef } from 'react';
import { Button } from 'shared/index';
import { EmptyGeneric } from 'v1/components/shared/index';
import { ShortlistHooks } from 'v4/entities/shortlist/shortlist.hooks';
import { ShortlistListQuery } from 'v4/entities/shortlist/shortlist.types';
import { createSearchPanel } from 'v4/pages/common/SearchPanel/SearchPanel';
import { SearchPanelSelectOption } from 'v4/pages/common/SearchPanel/SearchPanelSelectOption';
import {
  SingleMutation,
  SingleMutationState
} from 'v4/pages/common/single-mutation';
import { OptionsLayout } from 'v4/shared/components/forms/input/primitvies/OptionsLayout';
import { OptionConfig } from 'v4/shared/components/forms/input/types/types';
import { rd } from 'v4/shared/components/remoteData';
import { TailwindRoot } from 'v4/shared/TailwindRoot';

export interface ShortlistBatchAddModalConfig {
  useItems: ShortlistHooks['useList'];
  useMutation: () => SingleMutation<{
    resourceIds: Resource['id'][];
    shortlistId: Shortlist['id'];
  }>;
  useNavigateToShortlist: () => (shortlistId: Shortlist['id']) => void;
}

export interface ShortlistBatchAddModalProps {
  resourceIds: Resource['id'][];
  onAddComplete?: () => void;
}

function useShortlistSearchQuery(query: string): ShortlistListQuery {
  return {
    query,
    order_by:
      query.length === 0
        ? { field: 'updated_at', direction: 'desc' }
        : { field: 'title', direction: 'asc' },
    count: query.length === 0 ? 5 : 15,
    archived: false //todo maybe add some limit
  };
}

export function createShortlistBatchAddModal(
  config: ShortlistBatchAddModalConfig
) {
  const SearchPanel = createSearchPanel<
    OptionConfig<Shortlist['id']>,
    Shortlist['id'],
    {
      // we can define extra props that will be passed to this component, and we accept them in injected hooks as extraProps parameters
      currentMutationState?: {
        id?: Shortlist['id'];
        state: SingleMutationState;
      };
    }
  >({
    useOptions: (query, extraProps) => {
      const shortlists = config.useItems(useShortlistSearchQuery(query), {
        placeholderData: keepPreviousData
      });

      const options = rd.map(shortlists, data =>
        data.results.map(x => ({
          value: x.id,
          label: x.title ?? x.public_title ?? 'unnamed',
          mutationState:
            extraProps.currentMutationState?.id === x.id
              ? extraProps.currentMutationState.state
              : undefined
        }))
      );

      return {
        summary: rd.getOrElse(
          rd.map(options, x => (
            <>{query.length === 0 ? 'Most recent' : `${x.length} result(s)`}</>
          )),
          () => null
        ),
        children: (
          <OptionsLayout
            renderEmpty={() => (
              <EmptyGeneric
                icon="/images/icon_colour_shortlists.svg"
                title="No Shortlists"
                description="Try a different search of create a new shortlist"
              />
            )}
            optionsState={options}
            renderOptions={options =>
              options.map(option => (
                <SearchPanelSelectOption key={option.value} value={option} />
              ))
            }
          />
        )
      };
    },
    idValue: option => option.value,
    displayValue: option => option.label
  });

  return function ShortlistBatchAddModal({
    resourceIds,
    onAddComplete
  }: ShortlistBatchAddModalProps) {
    const { closeModal } = useModal();
    const mutation = config.useMutation();
    const navigateToShortlist = config.useNavigateToShortlist();

    // this way we notify parent about successful mutation that happened in this modal, after user closes it
    const lastMutationStatusRef = useRef(mutation.status);
    lastMutationStatusRef.current = mutation.status;
    useEffect(() => {
      return () => {
        if (lastMutationStatusRef.current === 'success') {
          onAddComplete?.();
        }
      };
    }, [onAddComplete]);

    const [selectedOption, setSelectedOption] = React.useState<OptionConfig<
      Shortlist['id']
    > | null>();

    return (
      <Modal size="xs">
        <Modal.Header
          description={'Select one shortlist to add resources to'}
          title={`Add ${resourceIds.length} resource(s)`}
        />
        <Modal.Content>
          <Modal.Scrollable padding="l">
            <TailwindRoot>
              {mutation.status === 'success' ? (
                <div className="tw-flex tw-flex-col tw-gap-8 tw-items-center">
                  Successfully added {resourceIds.length} resource(s).
                </div>
              ) : (
                <SearchPanel
                  currentMutationState={{
                    id: selectedOption?.value,
                    state: mutation
                  }}
                  autoFocus
                  placeholder="Search for shortlists"
                  value={selectedOption}
                  onChange={option => {
                    if (option && mutation.status === 'idle') {
                      setSelectedOption(option);
                      mutation.mutate({
                        shortlistId: option.value,
                        resourceIds: resourceIds
                      });
                    }
                  }}
                />
              )}
            </TailwindRoot>
          </Modal.Scrollable>
          {mutation.status === 'success' && (
            <Modal.Toolbar>
              <Button
                focus
                appearance="transparent"
                label="View shortlist"
                hasMarginLeft
                action={() => {
                  if (selectedOption?.value) {
                    closeModal();
                    navigateToShortlist(selectedOption.value);
                  }
                }}
              />
            </Modal.Toolbar>
          )}
        </Modal.Content>
      </Modal>
    );
  };
}
