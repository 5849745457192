import React from 'react';
import PropTypes from 'prop-types';

import { Flyover, PressStud } from 'v1/components/shared';
import DropdownMenuItem from './DropdownMenuItem/DropdownMenuItem';

const DropdownMenu = ({ onOpen, onClose, children, ...props }) => {
  const renderItemWithFlyover = item => {
    const { stayOpen, children, ...itemProps } = item.props;
    return (
      <Flyover>
        <Flyover.Trigger>
          {React.createElement(Flyover.Item, {
            ...itemProps,
            stayOpen: true
          })}
        </Flyover.Trigger>
        {children}
      </Flyover>
    );
  };

  const renderItems = () => {
    return React.Children.map(children, child => {
      if (!child) {
        return null;
      }
      return child.props.children
        ? renderItemWithFlyover(child)
        : React.createElement(Flyover.Item, child.props);
    });
  };

  return (
    <Flyover onOpen={onOpen} onClose={onClose}>
      <Flyover.Trigger>
        <PressStud {...props} />
      </Flyover.Trigger>
      <Flyover.Content>{renderItems()}</Flyover.Content>
    </Flyover>
  );
};

DropdownMenu.Item = DropdownMenuItem;

const checkChildren = props => {
  if (!props.children) {
    return new Error(`DropdownMenu must have children`);
  }
  const isValid = React.Children.toArray(props.children).every(
    child => child.type === DropdownMenuItem
  );
  return isValid
    ? null
    : new Error(
        `DropdownMenu children should be components of type DropdownMenuItem only`
      );
};

DropdownMenu.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: checkChildren
  // Rest are spread onto PressStud
};

export default DropdownMenu;
