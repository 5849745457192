import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import TableContext from './TableContext';
import TableGroups from './TableGroups';
import TableRowset from './TableRowset';
import styles from './Table.module.scss';

const TableBody = ({ rows }) => {
  const { getTableProps, groupBy } = useContext(TableContext);

  // TODO: handle 0 rows
  // TODO: isLoading
  return (
    <div {...getTableProps({ className: styles.Body })}>
      {groupBy ? <TableGroups rows={rows} /> : <TableRowset rows={rows} />}
    </div>
  );
};

TableBody.propTypes = {
  rows: PropTypes.array
};

export default TableBody;
