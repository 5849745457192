import {
  calculateProductionBudgetLineSubtotal,
  calculateAgencyFeeTotal,
  calculatePayrollFeeTotal,
  calculateProcessingFeeTotal
} from 'v1/helpers/budgetHelper';

// NOTE: The idea is that this file eventually replaces budgetHelper

const getExpensesTotal = (expenses = []) => {
  return expenses
    .filter(e => !e.archived)
    .reduce((total, item) => {
      const {
        commit_rate_amount: amount,
        commit_rate_quantity: quantity
      } = item;
      if (!amount || !quantity) return total;
      return amount * quantity + total;
    }, 0);
};
const getAgencyFeeTotal = (rateTotal, agencyFee) => {
  return agencyFee ? calculateAgencyFeeTotal(rateTotal, agencyFee) : 0;
};
const getPayrollFeeTotal = (rateTotal, agencyFeeTotal, payrollFee) => {
  return payrollFee
    ? calculatePayrollFeeTotal(rateTotal, agencyFeeTotal, payrollFee)
    : 0;
};
const getProcessingFeeTotal = (subtotal, processingFee) => {
  return processingFee
    ? calculateProcessingFeeTotal(subtotal, processingFee)
    : 0;
};
const getFeesTotal = (agencyFeeTotal, payrollFeeTotal, processingFeeTotal) => {
  return agencyFeeTotal + payrollFeeTotal + processingFeeTotal;
};
// TODO: rename
const getSubtotal = (rateTotal, agencyFeeTotal, payrollFeeTotal) => {
  return calculateProductionBudgetLineSubtotal(
    rateTotal,
    agencyFeeTotal,
    payrollFeeTotal
  );
};
const getTotal = (subtotal, processingFeeTotal, expensesTotal) => {
  return subtotal + processingFeeTotal + expensesTotal;
};
export const getTotals = (rateTotal, fees, expenses) => {
  const agencyFeeTotal = getAgencyFeeTotal(rateTotal, fees.agencyFee);
  const payrollFeeTotal = getPayrollFeeTotal(
    rateTotal,
    agencyFeeTotal,
    fees.payrollFee
  );
  const subtotal = getSubtotal(rateTotal, agencyFeeTotal, payrollFeeTotal);
  const processingFeeTotal = getProcessingFeeTotal(
    subtotal,
    fees.processingFee
  );
  const feesTotal = getFeesTotal(
    agencyFeeTotal,
    payrollFeeTotal,
    processingFeeTotal
  );
  const expensesTotal = getExpensesTotal(expenses);
  const total = getTotal(subtotal, processingFeeTotal, expensesTotal);

  return {
    rateTotal,
    expensesTotal,
    feesTotal,
    total
  };
};
