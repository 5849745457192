import React from 'react';
import KeyboardShortcut from 'v4/shared/components/KeyboardShortcut/KeyboardShortcut';

interface SidebarButtonProps {
  children: React.ReactNode;
  keyboardShortcut?: string;
  icon: React.ReactNode;
};

const SidebarButton = ({ icon, children, keyboardShortcut, ...rest }: SidebarButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a className="tw-rounded-md tw-w-full tw-text-white/80 tw-flex tw-items-center tw-text-left hover:tw-text-white/100 tw-p-2 tw-block tw-rounded-md hover:tw-bg-eggplant-lighter/30 tw-block focus:tw-outline-none focus:tw-text-white/100 focus:tw-ring-2 focus:tw-ring-eggplant-lightest/60 data-[highlighted]:tw-ring-2 data-[highlighted]:tw-ring-eggplant-lightest/60" {...rest}>
      <span className="tw-flex-0 tw-mr-3">{icon}</span>
      <span className="tw-flex-1 tw-truncate">{children}</span>
      {keyboardShortcut && (
        <span className="tw-flex-0 tw-ml-3"><KeyboardShortcut shortcut={keyboardShortcut} /></span>
      )}
    </a>
  );
};

export default SidebarButton;
