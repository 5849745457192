import React, { Component } from 'react';
import Sortable from 'react-sortablejs';
import { Loading } from 'v1/components/shared';
//import FieldTableItem from 'v1/components/SettingsComponents/FieldTableItem/FieldTableItem';
import { EmptyGeneric } from 'v1/components/shared';
import ReactTable from 'react-table-v6';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import 'v1/components/feature/ResourceComponents/Resources/ContactsTable/reactTable.scss';
import './SettingsTable.scss';

export default class SettingsTable extends Component {
  onOrderChange = order =>
    this.props.onOrderChange && this.props.onOrderChange(order);

  generateColumns = () => {
    const { columns, sortable } = this.props;
    return sortable
      ? [
          {
            Header: '',
            accessor: 'handle',
            maxWidth: 30,
            Cell: ({ original }) => {
              if (this.props.omitSortable && this.props.omitSortable(original))
                return null;
              return (
                <div className="SettingsTable-handle">
                  <img src="/images/icon_drag.svg" alt="" />
                </div>
              );
            }
          },
          ...columns
        ]
      : [
          {
            Header: '',
            width: 30
          },
          ...columns
        ];
  };

  render() {
    const { data, emptyState = {}, loading, isSortable = false } = this.props;
    const columns = this.generateColumns();

    if (loading) return <Loading />;

    return (
      <div className={classnames('SettingsTable', this.props.className)}>
        {this.props.label && <label>{this.props.label}</label>}
        {!isEmpty(data) ? (
          <ReactTable
            data={data}
            columns={columns}
            sortable={isSortable}
            resizable={false}
            //loading={false} // TODO
            showPagination={data.length > 200}
            showPageSizeOptions={false}
            minRows={0}
            defaultPageSize={200}
            TbodyComponent={({ children }) => {
              return (
                <ReactTable.defaultProps.TbodyComponent>
                  <Sortable
                    options={{
                      handle: '.SettingsTable-handle',
                      animation: 0,
                      ghostClass: 'PlaceholderDrop'
                    }}
                    onChange={this.onOrderChange}
                    disabled={!this.props.sortable}
                  >
                    {children}
                  </Sortable>
                </ReactTable.defaultProps.TbodyComponent>
              );
            }}
            TrGroupComponent={({ children, rowInfo }) => {
              if (rowInfo) {
                const sortableID = this.props.getSortableID
                  ? this.props.getSortableID(rowInfo.original)
                  : null;
                return (
                  <ReactTable.defaultProps.TrGroupComponent
                    data-id={sortableID}
                    className=".SettingsTable-fixed"
                  >
                    {children}
                  </ReactTable.defaultProps.TrGroupComponent>
                );
              }
              return (
                <ReactTable.defaultProps.TrComponent>
                  {children}
                </ReactTable.defaultProps.TrComponent>
              );
            }}
            getTrGroupProps={(state, rowInfo) => ({ rowInfo })}
          />
        ) : (
          <EmptyGeneric
            icon={emptyState.icon || '/images/icon_colour_genericEmpty.svg'}
            iconWidth="50px"
            title={emptyState.title || 'Empty'}
            description={emptyState.description || 'No Results'}
          />
        )}
      </div>
    );
  }
}
