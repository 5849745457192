import { decodeJwt } from 'jose';
import Api from 'lib/api/Api';

class Authentication extends Api {
  /**
   * Authenticate using the local strategy
   * @async
   * @param {string} username
   * @param {string} password
   * @returns {Promise.<{tokenString: string, token: Object}>}
   */
  static async localAuthentication(username, password) {
    const response = await this.post(`${this.baseApiUrl}/auth/local`).send({
      username,
      password
    });
    const { data, resetPassword } = response.body;
    return {
      tokenString: data,
      token: decodeJwt(data),
      resetPassword
    };
  }

  /**
   * Get tenant authentication methods
   * @async
   * @returns {Promise.<{name: string, auth_methods: []string}>}
   */
  static async getTenantAuthMethods() {
    const { body } = await this.get(`${this.baseApiUrl}/auth/methods`).send();
    return body.data;
  }

  /**
   * Update an existing authentication method
   * @param {string} methodId
   * @param {Object} method
   * @async
   * @returns {Promise}
   */
  static async updateAuthMethod(methodId, method) {
    const { body } = await this.put(
      `${this.baseApiUrl}/auth/methods/${methodId}`
    ).send(method);
    return body;
  }

  /**
   * Update password for currently authenticated user. Only for accounts which have
   * a local authentication user.
   * @param {string} tokenString
   * @param {string} password
   * @async
   * @returns {Promise}
   */
  static async changePassword(tokenString, password) {
    const { body } = await this.put(
      `${this.baseApiUrl}/auth/local/change_password`
    ).send({ password });
    return body;
  }

  /**
   * Initiate the recover password process. This process emails a recovery link to the user.
   * @param {string} username
   * @async
   * @returns {Promise}
   */
  static async recoverPassword(username) {
    const { body } = await this.post(
      `${this.baseApiUrl}/auth/local/recover_password`
    ).send({ username });
    return body;
  }

  /**
   * Finish the recovery process. This endpoint will also create a session.
   * @param {string} recoveryToken Recovery token
   * @param {string} password New user password
   * @async
   * @returns {Promise}
   */
  static async recoverPasswordCallback(recoveryToken, password) {
    const response = await this.post(
      `${this.baseApiUrl}/auth/local/recover_password/callback`
    ).send({ token: recoveryToken, password });

    const { data } = response.body;
    return {
      tokenString: data,
      token: decodeJwt(data)
    };
  }

  /**
   * Get workspaces. This function is used during the authentication process to populate the session.
   * @async
   * @param {string} tokenString
   * @returns {Promise.<{name: string, auth_methods: []string}>}
   */
  static async getWorkspaces(tokenString) {
    const { body } = await this.get(`${this.baseApiUrl}/auth/workspaces`)
      .set({
        Authorization: tokenString
      })
      .send();
    return body;
  }

  /**
   * Logout from Atellio. Support all authentication methods.
   * @async
   * @param {string} tokenString
   * @returns {Promise.<{name: string, auth_methods: []string}>}
   */
  static async logout(tokenString) {
    const { body } = await this.get(`${this.baseApiUrl}/auth/logout`)
      .set({
        Authorization: tokenString
      })
      .send();
    return body.data;
  }
}

export default Authentication;
