import { makeFileURL } from 'lib/s3/s3Helper';
import { Cloudinary } from '@cloudinary/url-gen';
import { limitFit } from '@cloudinary/url-gen/actions/resize';

export const IMAGE_QUALITY_OPTIONS = {
  small: { width: 200, quality: 80 },
  medium: { width: 400, quality: 60 },
  large: { width: 2000, quality: 90 },
  full: { quality: 100 }
};

export const getImageSettingsQuery = ({ width, height, quality, format }) => {
  let query = `?quality=${quality}`;
  if (width) query += `&width=${width}`;
  if (height) query += `&height=${height}`;
  if (format) query += `&format=${format}`;
  return query;
};

/**
 * Returns an image URL with image transforms to perform from the file
 * @param {{id: int, url: string}} file File object
 * @param {{width: number, height: number, quality: number, format: string}} settings
 * @return {string}
 */
export const getResizedImageFileURL = (
  file,
  settings = { ...IMAGE_QUALITY_OPTIONS.medium, format: 'jpeg' }
) => {
  const fileURL = makeFileURL(file);

  return `${fileURL}${getImageSettingsQuery(settings)}`;
};

/**
 * Returns an image URL with image transforms to perform
 * @param {String} fileURL File URL
 * @param {{width: number, height: number, quality: number, format: string}} settings Image settings
 * @return {string}
 */
export const getResizedImageURL = (
  fileURL,
  settings = { ...IMAGE_QUALITY_OPTIONS.medium, format: 'jpeg' }
) => {
  return `${fileURL}${getImageSettingsQuery(settings)}`;
};

/**
 * Returns an image URL width cloudinary transforms to perform
 * @param {String} imageUrl
 * @param {{width?: number, height?: number, quality?: number, format?: string}} settings Image settings
 * @return {string}
 */
export function convertImageFileWithCloudinary(
  url,
  settings = IMAGE_QUALITY_OPTIONS.medium
) {
  if (!url) return '';

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'atellio-development'
    }
  });

  const image = cld.image(url).setDeliveryType('fetch');
  const withOptionalHeight = (api, height) =>
    height === undefined ? api : api.height(height);

  return withOptionalHeight(
    image
      .format(settings.format || 'auto')
      .quality(settings.quality || 'auto')
      .resize(limitFit(settings.width || '400', settings.height))
      .toURL()
  );
}
