import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

import { createBuildPublicUrl } from 'v1/helpers/misc';
import { DEPRECATED_DropdownButton } from 'v1/components/shared';
import './PublicLinkStatus.scss';

export function createPublicLinkStatus(getLocation) {
  const buildPublicUrl = createBuildPublicUrl(getLocation);
  return class PublicLinkStatus extends Component {
    constructor(props) {
      super(props);

      this.state = {};
    }
    renderOptions = () => {
      const { status, options } = this.props;

      return (
        <DEPRECATED_DropdownButton
          label={status.text}
          buttonClass="PublicLinkStatus-button"
          className="PublicLinkStatus-dropdown"
          offset={{ top: '38px', right: 0 }}
          links={options}
          hideArrow
        />
      );
    };
    renderStatus = () => {
      const { status } = this.props;

      return (
        <div
          className={classnames(
            { 'PublicLinkStatus-status_active': status.active },
            ['PublicLinkStatus-status']
          )}
        >
          <span className="PublicLinkStatus-status-label">{status.text}</span>
        </div>
      );
    };
    renderLink = () => {
      const { status, url, destination } = this.props;

      return (
        <div
          className={classnames(
            {
              'PublicLinkStatus-linkContainer_disabled': !status.active
            },
            ['PublicLinkStatus-linkContainer']
          )}
        >
          <a
            data-tip="Open link in new window"
            href={buildPublicUrl(url, destination)}
            target="_blank"
            rel="noopener noreferrer"
            className="PublicLinkStatus-linkContainer-link trunc"
          >
            {buildPublicUrl(url, destination, true)}
          </a>
          <CopyToClipboard
            text={buildPublicUrl(url, destination)}
            onCopy={() => this.setState({ copied: true })}
          >
            <span data-for="copyButton" data-tip className="copyLinkButton">
              <img src="/images/icon_new_window.svg" alt="" />
            </span>
          </CopyToClipboard>
        </div>
      );
    };
    render() {
      const { options } = this.props;

      return (
        <div className="PublicLinkStatus">
          {options && this.renderStatus()}
          {this.renderLink()}
          <ReactTooltip
            id="copyButton"
            place="bottom"
            effect="solid"
            getContent={() => (this.state.copied ? 'Copied!' : 'Copy Link')}
          />
        </div>
      );
    }
  };
}

export const PublicLinkStatus = createPublicLinkStatus(() => window.location);

PublicLinkStatus.propTypes = {
  status: PropTypes.shape({
    text: PropTypes.string,
    active: PropTypes.bool
  }).isRequired,
  options: PropTypes.array, // TODO: array of 'links' as passed to DropdownButton - used?
  url: PropTypes.string,
  destination: PropTypes.string,
  previewOnly: PropTypes.any // TODO: used?
};

export default PublicLinkStatus;
