import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyGeneric } from 'v1/components/shared';

import styles from './ContactDetailsRoute.module.scss';

const ContactDetailsRoute = () => {
  const { request } = useSelector(state => state.availabilities);
  const { companyName } = request;

  return (
    <section className={styles['ContactDetailsRoute']}>
      <EmptyGeneric
        className={styles['ContactDetailsRoute__emptyGeneric']}
        title={'Coming Soon'}
        icon={'/images/pancake_construction.png'}
        iconWidth={'50px'}
      />
      <h2 className={styles['ContactDetailsRoute__description']}>
        Tell {companyName} when your contact details have changed
      </h2>
      <h3 className={styles['ContactDetailsRoute__details']}>
        Submit new contact information and perform other useful actions through
        this portal.
      </h3>
    </section>
  );
};

export default ContactDetailsRoute;
