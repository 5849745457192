import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import { CALLOUT_ACTIONS } from 'store/v1/callouts/callouts.constants.js';
import Account from 'lib/auth/Account';
import LocalStorage from 'lib/storage/LocalStorage';

export const INITIAL_STATE = {
  auth0_id: null,
  app_id: null,
  settings: {},
  loggedIn: false,
  loading: false,
  error: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOAD_AUTH:
    case AUTH_ACTIONS.CREATE_FIELDGROUP:
    case AUTH_ACTIONS.DELETE_FIELDGROUP:
    case AUTH_ACTIONS.UPDATE_FIELDGROUP:
    case AUTH_ACTIONS.CREATE_FIELD:
    case AUTH_ACTIONS.DELETE_FIELD:
    case AUTH_ACTIONS.UPDATE_FIELD:
    case AUTH_ACTIONS.UPDATE_SETTINGS:
    case AUTH_ACTIONS.UPDATE_FIELD_ORDER:
    case AUTH_ACTIONS.UPDATE_PASSWORD:
    case AUTH_ACTIONS.UPDATE_COMPANY:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AUTH_ACTIONS.LOAD_AUTH_FAILURE:
      return {
        ...INITIAL_STATE,
        error: action.error
      };

    case AUTH_ACTIONS.LOAD_AUTH_SUCCESS: {
      let results = action.result;
      const token = action.token;

      const workspace = Account.resolveFirstWorkspace();
      const app_id = workspace.db_schema;

      // {AT-952} We used to insert account into local storage, we no longer
      // do this. So this remains here to remove legacy local storage entries.
      LocalStorage.collection('account').remove();

      const {
        booking_types,
        expense_types,
        production_types,
        resource_types,
        shortlist_types,
        tenant,
        users,
        ...settings
      } = results;
      return {
        auth0_id: token.auth0_id,
        app_id,
        settings: settings,
        loggedIn: true,
        loading: false
      };
    }
    case AUTH_ACTIONS.REQUEST_DEMO_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case AUTH_ACTIONS.CREATE_FIELDGROUP_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          custom_fieldgroup_definitions: [
            ...state.settings.custom_fieldgroup_definitions,
            action.result.custom_fieldgroup_definition
          ]
        },
        loading: false
      };
    case AUTH_ACTIONS.UPDATE_FIELDGROUP_SUCCESS: {
      const custom_fieldgroup_definitions = state.settings.custom_fieldgroup_definitions.map(
        def => (def.id === action.id ? action.result : def)
      );
      return {
        ...state,
        settings: {
          ...state.settings,
          custom_fieldgroup_definitions
        },
        loading: false
      };
    }
    case AUTH_ACTIONS.DELETE_FIELDGROUP_SUCCESS: {
      const custom_fieldgroup_definitions = state.settings.custom_fieldgroup_definitions.map(
        def => (def.id === action.id ? { ...def, archived: true } : def)
      );
      return {
        ...state,
        settings: {
          ...state.settings,
          custom_fieldgroup_definitions
        },
        loading: false
      };
    }

    case AUTH_ACTIONS.CREATE_FIELD_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          custom_field_definitions: [
            ...state.settings.custom_field_definitions,
            action.result.custom_field
          ]
        },
        loading: false
      };
    case AUTH_ACTIONS.UPDATE_FIELD_SUCCESS:
      const custom_field_definitions = state.settings.custom_field_definitions.map(
        def => (def.id === action.id ? action.result : def)
      );
      return {
        ...state,
        settings: {
          ...state.settings,
          custom_field_definitions
        },
        loading: false
      };
    case AUTH_ACTIONS.DELETE_FIELD_SUCCESS: {
      const custom_field_definitions = state.settings.custom_field_definitions.map(
        def => (def.id === action.id ? { ...def, archived: true } : def)
      );
      return {
        ...state,
        settings: {
          ...state.settings,
          custom_field_definitions
        },
        loading: false
      };
    }
    case AUTH_ACTIONS.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          settings: {
            ...state.settings.settings,
            [action.field_key]: action.result
          }
        },
        loading: false
      };
    case AUTH_ACTIONS.UPDATE_FIELD_ORDER_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          metastructures: [action.result]
        },
        loading: false
      };

    case AUTH_ACTIONS.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case 'PUBLIC_SETTINGS_READY': // bridge for public settings for tanstack-query migrated elements
      if (state.loggedIn) return state;
      return {
        ...state,
        settings: {
          ...state.settings,
          settings: action.payload
        }
      };

    case SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST_SUCCESS:
    case CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT_SUCCESS:
      if (state.loggedIn) return state;

      return {
        ...state,
        settings: {
          settings: action.result.settings,
          custom_field_definitions: action.result.custom_field_definitions
        }
      };
    case AUTH_ACTIONS.UPDATE_PASSWORD_SUCCESS:
    case AUTH_ACTIONS.UPDATE_PASSWORD_FAILURE:
    case AUTH_ACTIONS.UPDATE_COMPANY_FAILURE:
    case AUTH_ACTIONS.UPDATE_SETTINGS_FAILURE:
    case AUTH_ACTIONS.UPDATE_FIELD_ORDER_FAILURE:
    case AUTH_ACTIONS.DELETE_FIELDGROUP_FAILURE:
    case AUTH_ACTIONS.DELETE_FIELD_FAILURE:
    case AUTH_ACTIONS.UPDATE_FIELDGROUP_FAILURE:
    case AUTH_ACTIONS.CREATE_FIELDGROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || action.result.error
      };
    default:
      return state;
  }
};
