import Tracker from './Tracker';

export default class FileTracker extends Tracker {
  /**
   * Receive a Mixpanel event and decide whether the tracker should process it
   * @param {MixpanelEvent} event
   */
  track(event) {
    switch (event.action) {
      default:
        return;
    }
  }
}
