export default [
  {
    id: 0,
    name: '💁🏽‍♂️️ Basic callout',
    description: 'A basic form to capture contact information',
    template_data: {
      mappings: [
        {
          key: 'full_name',
          type: 'SHORT_TEXT',
          label: 'Full name',
          maps_to: 'full_name',
          required: true,
          description: '',
          order: 0
        },
        {
          key: 'emails',
          type: 'EMAIL',
          label: 'Email',
          maps_to: 'emails',
          required: true,
          description: '',
          order: 1
        },
        {
          key: 'phone_number',
          type: 'PHONE_NUMBER',
          label: 'Phone number',
          maps_to: 'phone_numbers',
          required: false,
          description: '',
          order: 2
        },
        {
          key: 'websites',
          type: 'URL',
          label: 'Website link (URL)',
          maps_to: 'websites',
          required: true,
          description: '',
          order: 3
        },
        {
          key: 'locations',
          type: 'LOCATION',
          label: 'Address',
          maps_to: 'locations',
          required: false,
          description: 'Search for and select an address below',
          order: 4
        }
      ]
    }
  },
  {
    id: 1,
    name: '📸 Portfolio callout',
    description: 'A form to capture contact information and samples of work',
    template_data: {
      mappings: [
        {
          key: 'full_name',
          type: 'SHORT_TEXT',
          label: 'Full name',
          maps_to: 'full_name',
          required: true,
          description: '',
          order: 0
        },
        {
          key: 'emails',
          type: 'EMAIL',
          label: 'Email',
          maps_to: 'emails',
          required: true,
          description: '',
          order: 1
        },
        {
          key: 'phone_number',
          type: 'PHONE_NUMBER',
          label: 'Phone number',
          maps_to: 'phone_numbers',
          required: false,
          description: '',
          order: 2
        },
        {
          key: 'websites',
          type: 'URL',
          label: 'Website',
          maps_to: 'websites',
          required: true,
          description: '',
          order: 3
        },
        {
          key: 'locations',
          type: 'LOCATION',
          label: 'Location',
          maps_to: 'locations',
          required: false,
          description: 'Where are you based?',
          order: 4
        },
        {
          key: 'files',
          type: 'FILES',
          label: 'Upload samples',
          maps_to: 'files',
          required: false,
          description: 'Upload at least three samples of work below',
          order: 5
        }
      ]
    }
  },
  {
    id: 2,
    name: '⭐️ Model callout',
    description: 'A form to capture model information for casting',
    template_data: {
      mappings: [
        {
          key: 'full_name',
          type: 'SHORT_TEXT',
          label: 'Full name',
          maps_to: 'full_name',
          required: true,
          description: '',
          order: 0
        },
        {
          key: 'emails',
          type: 'EMAIL',
          label: 'Email',
          maps_to: 'emails',
          required: true,
          description: 'Please enter your personal / agents email address',
          order: 1
        },
        {
          key: 'phone_number',
          type: 'PHONE_NUMBER',
          label: 'Phone number',
          maps_to: 'phone_numbers',
          required: false,
          description: 'Please enter your personal / agents phone number',
          order: 2
        },
        {
          key: 'websites',
          type: 'URL',
          label: 'Website link (URL)',
          maps_to: 'websites',
          required: true,
          description: 'A link to your portfolio',
          order: 3
        },
        {
          key: 'social_instagram',
          type: 'SOCIAL',
          label: 'Instagram',
          maps_to: 'social_instagram',
          required: false,
          description:
            'If you have a relevant Instagram account please enter the handle below',
          order: 4
        },
        {
          key: 'locations',
          type: 'LOCATION',
          label: 'Location',
          maps_to: 'locations',
          required: false,
          description: 'Where you are based?',
          order: 5
        },
        {
          key: 'files',
          type: 'FILES',
          label: 'Portfolio Images',
          maps_to: 'files',
          required: false,
          description: 'Upload at least 3 images of previous work',
          order: 6
        }
      ]
    }
  }
];
