import { RateInterval } from '__types__';

type RateIntervalData = { label: string; value: RateInterval };

const BaseRateIntervals: { [key in RateInterval]: RateIntervalData } = {
  [RateInterval.Daily]: {
    label: 'Daily',
    value: RateInterval.Daily
  },
  [RateInterval.Hourly]: {
    label: 'Hourly',
    value: RateInterval.Hourly
  },
  [RateInterval.Fixed]: {
    label: 'Fixed',
    value: RateInterval.Fixed
  },
  [RateInterval.Item]: {
    label: 'Per Item',
    value: RateInterval.Item
  }
};

const { [RateInterval.Item]: x, ...ResourceRateIntervals } = BaseRateIntervals;
const ExpenseRateIntervals = BaseRateIntervals;

export { ResourceRateIntervals, ExpenseRateIntervals };
