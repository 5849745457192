import PropTypes from 'prop-types';
import React from 'react';

import { Grid, GridCell, ListCell, Status } from 'v1/components/shared';

import { getFormattedEventDates } from 'v1/helpers/byModel/EventHelper';

import classnames from 'classnames';
import './CellProductionItem.scss';

const CellProductionItem = ({
  production,
  onSelect,
  layout = {},
  hideStatus
}) => {
  function onClick() {
    onSelect && onSelect(production);
  }

  return (
    <ListCell
      className={classnames([
        'CellProductionItem',
        layout.className,
        'Parent_hoverListener'
      ])}
      padding="XS"
      onClick={onSelect ? onClick : null}
    >
      <Grid vcenter gutters="M">
        <GridCell width="1/3" className="CellProductionItem-title">
          <span
            className="ProductionColourBar"
            style={{ backgroundColor: production.color }}
          />
          <span className="trunc">
            {production.icon} {production.code} {production.title}
          </span>
        </GridCell>
        <GridCell className="text-13-600 trunc">
          {getFormattedEventDates(production.production_date)}
        </GridCell>
        {!hideStatus && (
          <GridCell width="auto" className="text-right">
            <Status statusId={production.status_id} />
          </GridCell>
        )}
        {onSelect && (
          <GridCell width="auto" className="text-right Child_hoverListener">
            <img width="12px" src="/images/arrow_right.svg" alt="" />
          </GridCell>
        )}
      </Grid>
    </ListCell>
  );
};

CellProductionItem.propTypes = {
  layout: PropTypes.object,
  hideStatus: PropTypes.bool
};

export default CellProductionItem;
