import YellowEmbellishment from './embellishment-yellow.svg';
import BlueEmbellishment from './embellishment-blue.svg';
import OrangeEmbellishment from './embellishment-orange.svg';

const embellishments = [
  {
    colour: 'yellow',
    element: YellowEmbellishment
  },
  {
    colour: 'blue',
    element: BlueEmbellishment
  },
  {
    colour: 'orange',
    element: OrangeEmbellishment
  }
];

const getEmbellishmentByColour = colour => {
  const embellishment = embellishments.find(
    embellishment => embellishment.colour === colour
  );

  return embellishment?.element;
};

export default getEmbellishmentByColour;
