import { DropdownMenu } from '@easle/ui/dropdown-menu';
import React, { cloneElement, ReactElement } from 'react';
import { Account } from '../../../__types__';
import {
  ItemPrimitive,
  ItemPrimitiveIcon
} from '../../../v5/primitives/ItemPrimitive';
import { PopoverLayout } from '../../../v5/primitives/PopoverLayout';
import { PopoverPrimitive } from '../../../v5/primitives/PopoverPrimitive';
import { SearchPrimitive } from '../../../v5/primitives/SearchPrimitive';
import Avatar from '../../shared/components/Avatar/Avatar';

export type UserVM = {
  id: string;
  profileImage?: string;
  name: string;
  initials: string;
};

export interface UserPickerConfig {
  useUsers: (query: string) => UserVM[];
  usePlaceholder?: () => string;
}
export interface UserPickerProps {
  children: ReactElement;
  onSelect: (userId: Account['id']) => void;
  tooltip?: string;
}
export function createUserPicker(config: UserPickerConfig) {
  return function UserPicker(props: UserPickerProps) {
    const placeholder = config.usePlaceholder?.() ?? 'Search…';
    return (
      <DropdownMenu>
        <DropdownMenu.Trigger>
          {cloneElement(props.children, {
            'data-tip': props.tooltip
          })}
        </DropdownMenu.Trigger>
        <DropdownMenu.Popover>
          <PopoverPrimitive layout="fit-content" className="tw-max-h-[300px]">
            <PopoverLayout
              searchSlot={
                <DropdownMenu.Search>
                  <SearchPrimitive placeholder={placeholder} />
                </DropdownMenu.Search>
              }
            >
              <DropdownMenu.SearchList useOptions={config.useUsers}>
                {users =>
                  users.length ? (
                    users.map(user => (
                      <DropdownMenu.Item
                        key={user.id}
                        label={user.name}
                        onClick={() => {
                          props.onSelect(user.id);
                        }}
                      >
                        <ItemPrimitive>
                          <ItemPrimitiveIcon>
                            <Avatar
                              src={user.profileImage}
                              className="tw-bg-white"
                              fallback={user.initials}
                            />
                          </ItemPrimitiveIcon>
                          {user.name}
                        </ItemPrimitive>
                      </DropdownMenu.Item>
                    ))
                  ) : (
                    <div className="tw-text-gray-600 tw-px-1 tw-text-sm tw-py-2">
                      No matches
                    </div>
                  )
                }
              </DropdownMenu.SearchList>
            </PopoverLayout>
          </PopoverPrimitive>
        </DropdownMenu.Popover>
      </DropdownMenu>
    );
  };
}
