import React from 'react';
import classnames from 'classnames';

import styles from './LoadingIcon.module.scss';

type LoadingIconProps = {
  isDarkMode?: boolean;
  className?: string;
};

const LoadingIcon = ({ isDarkMode, className }: LoadingIconProps) => (
  <div
    className={classnames(
      styles.LoadingIcon,
      {
        [styles.darkMode]: isDarkMode
      },
      className
    )}
  />
);

export default LoadingIcon;
