import {
  convertToWholeUnit,
  currencyFormatter
} from 'v1/helpers/currencyHelper';

/**
 * Calculate agency fee. The agency fee is a percentage of the rate total.
 * @param {number} rateTotal
 * @param {number} agencyFee
 * @returns {number}
 */
export const calculateAgencyFeeTotal = (rateTotal, agencyFee) =>
  rateTotal * (agencyFee / 100);

/**
 * Calculate the payroll fee. The payroll fee is a percentage of result of rate
 * total minus the agency fee total.
 * @param {number} rateTotal
 * @param {number} agencyFeeTotal
 * @param {number} payrollFee
 * @returns {number}
 */
export const calculatePayrollFeeTotal = (
  rateTotal,
  agencyFeeTotal,
  payrollFee
) => {
  if (!rateTotal) {
    return 0;
  }
  return (rateTotal - agencyFeeTotal) * (payrollFee / 100);
};

/**
 * Calculate processing fee. The processing fee is a percentage of the sub total.
 * @param {number} subtotal
 * @param {number} processingFee
 * @returns {number}
 */
export const calculateProcessingFeeTotal = (subtotal, processingFee) =>
  subtotal * (processingFee / 100);

/**
 * Line subtotal is made up of the rate, agency fee, and payroll fee.
 * The processing fee is applied to the line subtotal, giving us the line total
 * @param {number} rateTotal
 * @param {number} agencyFeeTotal
 * @param {number} payrollFeeTotal
 * @returns {number}
 */
export const calculateProductionBudgetLineSubtotal = (
  rateTotal,
  agencyFeeTotal,
  payrollFeeTotal
) => rateTotal + agencyFeeTotal + payrollFeeTotal;

export const formatRateCondition = (interval, quantity, includeFor = true) => {
  const plural = quantity === 1 ? '' : 's';
  const count = quantity || 0;
  switch (interval) {
    case 'HOURLY':
      return `/ hour${includeFor ? ` for ${count} hour${plural}` : ''}`;
    case 'ITEM':
      return `/ item${includeFor ? ` for ${count} item${plural}` : ''}`;
    case 'FIXED':
      return `fixed`;
    case 'DAILY':
    default:
      return `/ day${includeFor ? ` for ${count} day${plural}` : ''}`;
  }
};

export const formatRateSummary = (rate, defaultCurrency, includeFor) => {
  if (!rate || !rate.amount) {
    return 'No rate set';
  }
  const { currency, amount, interval, quantity } = rate;
  const cost = currencyFormatter(null, currency || defaultCurrency).format(
    convertToWholeUnit(amount)
  );
  const condition = formatRateCondition(interval, quantity, includeFor);
  return `${cost} ${condition}`;
};
