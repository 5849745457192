import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Grid,
  GridCell,
  RadioGroup,
  Radio,
  TextInput,
  Label,
  TimeInput12h
} from 'v1/components/shared';

import { DURATION_TYPES } from 'v1/helpers/byModel/EventHelper';

import styles from './EventHoursInputOptions.module.scss';

const EventHoursInputOptions = ({
  handleChange,
  durationType,
  estimatedDailyHours,
  estimatedDailyMinutes,
  estimatedStartTime,
  estimatedEndTime
}) => (
  <RadioGroup
    name="event-hours"
    onChange={({ target }) =>
      handleChange('date_type', target.value, durationType)
    }
    value={durationType}
  >
    <Radio
      className={classnames({
        [styles['EventHoursInputOptions--active']]:
          durationType === DURATION_TYPES.RECURRING_DEFAULT
      })}
      id="duration-type-default"
      name="duration-type-default"
      label="All day (8h)"
      value={DURATION_TYPES.RECURRING_DEFAULT}
    />

    <Radio
      className={classnames({
        [styles['EventHoursInputOptions--active']]:
          durationType === DURATION_TYPES.RECURRING_HOURS
      })}
      id="duration-type-recurring-hours"
      name="duration-type-recurring-hours"
      label="Hours per day"
      value={DURATION_TYPES.RECURRING_HOURS}
    />
    <>
      {durationType === 'RECURRING_HOURS' && (
        <div
          className={classnames([
            styles['EventHoursInputOptions__row'],
            'slimline',
            {
              [styles['EventHoursInputOptions--active']]:
                durationType === DURATION_TYPES.RECURRING_HOURS
            }
          ])}
        >
          <Grid gutters="S">
            <GridCell>
              <TextInput
                name="estimated_daily_hours"
                type="number"
                label="Hours"
                value={estimatedDailyHours || ''}
                min={0}
                max={24}
                size="S"
                placeholder="Hours"
                onChange={({ target }) =>
                  handleChange('estimated_daily_hours', parseInt(target.value))
                }
              />
            </GridCell>
            <GridCell>
              <TextInput
                name="estimated_daily_minutes"
                type="number"
                label="Minutes"
                value={estimatedDailyMinutes || ''}
                min={0}
                max={59}
                step={10}
                size="S"
                placeholder="Minutes"
                onChange={({ target }) =>
                  handleChange(
                    'estimated_daily_minutes',
                    parseInt(target.value)
                  )
                }
              />
            </GridCell>
          </Grid>
        </div>
      )}
    </>

    <Radio
      className={classnames({
        [styles['EventHoursInputOptions--active']]:
          durationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS
      })}
      id="duration-type-recurring-specific-hours"
      name="duration-type-recurring-specific-hours"
      label="Specific times"
      value={DURATION_TYPES.RECURRING_SPECIFIC_HOURS}
    />
    <>
      {durationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS && (
        <div
          className={classnames([
            styles['EventHoursInputOptions__row'],
            'slimline',
            {
              [styles['EventHoursInputOptions--active']]:
                durationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS
            }
          ])}
        >
          <Grid gutters="S">
            <GridCell>
              <Label>From</Label>
              <TimeInput12h
                size="S"
                onChange={value =>
                  handleChange('estimated_start_time', `${value}:00`)
                }
                value={estimatedStartTime}
              />
            </GridCell>
            <GridCell>
              <Label>To</Label>
              <TimeInput12h
                size="S"
                onChange={value =>
                  handleChange('estimated_end_time', `${value}:00`)
                }
                value={estimatedEndTime}
              />
            </GridCell>
          </Grid>
        </div>
      )}
    </>
  </RadioGroup>
);

EventHoursInputOptions.propTypes = {
  handleChange: PropTypes.func,
  dateType: PropTypes.oneOf([
    DURATION_TYPES.RECURRING_DEFAULT,
    DURATION_TYPES.RECURRING_HOURS,
    DURATION_TYPES.RECURRING_SPECIFIC_HOURS
  ]),
  estimatedDailyHours: PropTypes.string,
  estimatedDailyMinutes: PropTypes.string,
  estimatedStartTime: PropTypes.string,
  estimatedEndTime: PropTypes.string
};

export default EventHoursInputOptions;
