import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TableContext from './TableContext';
import styles from './Table.module.scss';

const TableCell = ({ parent, cell }) => {
  const { isLoading, frozenColumns, subRows } = useContext(TableContext);

  // GROUPING

  const getSubRowIndex = () => {
    if (!subRows) {
      return -1;
    }
    if (Array.isArray(subRows)) {
      const idIndex = subRows.indexOf(cell.column.id);
      return idIndex >= 0 ? idIndex : subRows.indexOf(cell.column.rowSpan);
    }
    return subRows === cell.column.id || subRows === cell.column.rowSpan
      ? 0
      : -1;
  };

  const subRowIndex = getSubRowIndex();

  const getActiveCell = (p, r) => {
    if (!p) {
      return cell;
    }
    if (r.index !== 0) {
      return null;
    }
    if (subRowIndex === r.depth - 1 || cell.column.index < 0) {
      return p.cells.find(pCell => pCell.column.id === cell.column.id);
    }
    return getActiveCell(p.parent, p);
  };

  const getIsLastRow = (p, r) => {
    if (!p) {
      return true;
    }
    if (p.subRows.length !== r.index + 1) {
      return false;
    }
    if (subRowIndex === r.depth - 1) {
      return p.subRows.length === r.index + 1;
    }
    return getIsLastRow(p.parent, p);
  };

  const isSharedColumn =
    cell.column.index < 0 ? true : parent && subRows ? subRowIndex >= 0 : false;
  const isIndexCell = cell.column.id === '_index';
  const activeCell = isSharedColumn ? getActiveCell(parent, cell.row) : cell;

  const hideBorder = isSharedColumn && !getIsLastRow(parent, cell.row);

  const cellPadding =
    typeof cell.column.cellPadding === 'function'
      ? cell.column.cellPadding(cell.value, cell.row.original)
      : cell.column.cellPadding;

  const isEditable =
    typeof cell.column.isEditable === 'function'
      ? cell.column.isEditable(cell.value, cell.row.original)
      : cell.column.isEditable;

  const props = cell.getCellProps([
    {
      className: classnames([
        styles.Cell,
        cell.column.className,
        {
          [styles.Cell_bordered]: cell.column.border === true && !isIndexCell,
          [styles.Cell_noborder]: cell.column.border === false || isIndexCell,
          [styles.Cell_sticky]: cell.column.index < frozenColumns,
          [styles.Cell_sticky_last]: cell.column.index === frozenColumns - 1,
          [styles.Cell_editable]: isEditable,
          [styles.Cell_rowspan]: hideBorder,
          [styles.align_C]: !isLoading && cell.column.align === 'center',
          [styles.align_R]: !isLoading && cell.column.align === 'right',
          [styles.valign_C]: !isLoading && cell.column.valign === 'center',
          [styles.valign_B]: !isLoading && cell.column.valign === 'bottom',
          [styles.no_padding]: cellPadding === '0' || cellPadding === 0,
          [styles.S]: cellPadding === 'S',
          [styles.M]: cellPadding === 'M',
          [styles.L]: cellPadding === 'L'
        }
      ]),
      style: {
        left: cell.column.index < frozenColumns ? cell.column.totalLeft : null
      }
    }
  ]);

  return <div {...props}>{activeCell && activeCell.render('Cell')}</div>;
};

TableCell.propTypes = {
  parent: PropTypes.object,
  cell: PropTypes.object
};

export default TableCell;
