import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Rect from '@reach/rect';

import FlyoverContext from '../FlyoverContext';
import styles from './FlyoverTrigger.module.scss';

const FlyoverTrigger = ({ className, children }) => {
  const { isOpen, toggleFlyover, setTriggerRect } = useContext(FlyoverContext);

  const onClick = event => {
    event.stopPropagation();
    toggleFlyover();
  };

  return (
    <Rect observe={isOpen} onChange={setTriggerRect}>
      {({ ref: rectRef }) => (
        <div
          className={classnames(styles.Trigger, className)}
          ref={rectRef}
          onClick={onClick}
        >
          {children}
        </div>
      )}
    </Rect>
  );
};

FlyoverTrigger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default FlyoverTrigger;
