import React from 'react';
import PropTypes from 'prop-types';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Grid,
  GridCell,
  Status
} from 'v1/components/shared';

import classnames from 'classnames';
import get from 'lodash/get';

import './StatusSelector.scss';

const StatusSelector = ({
  className,
  selectedStatus,
  onSelect,
  statusGroups,
  appearance = 'default',
  size
}) => {
  return (
    <>
      <Popover>
        <PopoverTrigger
          className={classnames(
            'clickable',
            {
              btn: appearance === 'silent',
              [`StatusSelector-btn_default`]: appearance === 'default',
              [`StatusSelector-btn-size_${size}`]: size
            },
            'StatusSelector-btn',
            className
          )}
        >
          <Grid vcenter gutters="S">
            <GridCell width="auto">
              <span className="text-11-700-eggplant">Status</span>
            </GridCell>
            <GridCell width="auto">
              {selectedStatus.id ? (
                <Status statusId={selectedStatus.id} />
              ) : (
                'Select Status'
              )}
            </GridCell>
            {appearance !== 'silent' && (
              <GridCell width="auto">
                <img
                  src="/images/icon_arrow_down_gray.svg"
                  alt=""
                  width="12px"
                />
              </GridCell>
            )}
          </Grid>
        </PopoverTrigger>

        <PopoverContent className="">
          {({ closePopover }) => (
            <div className="StatusSelector-Content">
              {statusGroups.map((statusGroup, i) => {
                return (
                  <div
                    className="StatusSelector-Group stack-M"
                    key={`${statusGroup.type}-${i}`}
                  >
                    <h4 className="StatusSelector-Header">
                      {statusGroup.label}
                    </h4>
                    {statusGroup.statuses.map(status => (
                      <Grid
                        className={classnames(
                          {
                            'StatusSelector-Item__clickable':
                              statusGroup.type === 'USER' &&
                              status.id !== selectedStatus.id,
                            'StatusSelector-Item__selected':
                              status.id === selectedStatus.id
                          },
                          'StatusSelector-Item'
                        )}
                        key={status.id}
                        onClick={
                          statusGroup.type === 'USER' &&
                          status.id !== selectedStatus.id
                            ? () => {
                                onSelect(status);
                                closePopover();
                              }
                            : null
                        }
                        gutters="S"
                        vcenter
                      >
                        <GridCell>
                          {statusGroup.descriptions ? (
                            <span className="text-11-700-eggplant">
                              {get(
                                statusGroup.descriptions,
                                status.status_type
                              )}
                            </span>
                          ) : (
                            <span className="text-11-700-eggplant">
                              {status.id !== selectedStatus.id
                                ? `SET TO`
                                : `SELECTED`}
                            </span>
                          )}
                        </GridCell>
                        <GridCell width="auto">
                          <Status statusId={status.id} />
                        </GridCell>
                      </Grid>
                    ))}
                  </div>
                );
              })}
            </div>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

StatusSelector.propTypes = {
  className: PropTypes.string,
  statusGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf(['USER', 'AUTOMATED']).isRequired, // 'USER': Clickable statuses that a user can set. 'AUTOMATED': Statuses automatically set by the system.
      statuses: PropTypes.array.isRequired, // Filtered statuses associated to that group
      description: PropTypes.object // If descriptions exists it replaces the label alongside each status
    })
  ).isRequired,
  selectedStatus: PropTypes.object, // [Status] - the selected status
  onSelect: PropTypes.func.isRequired,
  appearance: PropTypes.oneOf(['silent', 'default']),
  size: PropTypes.oneOf(['S', 'M', 'L'])
};

StatusSelector.defaultProps = {
  selectedStatus: {},
  statuses: [],
  size: 'M'
};

export default StatusSelector;
