import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { DATA_EXPORTS_ACTIONS } from 'store/v1/data_export_templates/data_export_templates.constants.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import {
  Grid,
  GridCell,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import DataExportsForm from './DataExportsForm/DataExportsForm';

const DataExportsModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const uiData = useSelector(state => state.ui.data);
  const templateId = get(uiData, 'id');
  const loading = useSelector(state => state.data_export_templates.loading);
  const [formApi, setFormApi] = useState();

  const canUpdateDataExports = useRestrictedAccess(
    undefined,
    Capability.CONFIGURE_REPORT_TEMPLATES,
    undefined
  );

  useEvent(DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE, {
    onSuccess: () => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Template created successfully'));
    },
    onFailure: () => dispatch(displayAlert('error', 'Error creating template'))
  });

  useEvent(DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE, {
    onSuccess: () => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Template updated successfully'));
    },
    onFailure: () => dispatch(displayAlert('error', 'Error updating template'))
  });

  const onSaveClick = () => {
    formApi.submitForm();
  };

  return (
    <ModalWrapper size="M">
      <ModalHeader title="Create report template" />
      <ModalContent>
        <ModalScrollable>
          <DataExportsForm
            getApi={setFormApi}
            disabled={!canUpdateDataExports}
          />
        </ModalScrollable>
        <ModalNavigation>
          <Grid>
            <div className="spacer"></div>
            <GridCell width="auto">
              <PressStud label="Cancel" action={onRequestClose} />
              {canUpdateDataExports && (
                <PressStud
                  appearance="primary"
                  label={`${templateId ? 'Update' : 'Create'} Report Template`}
                  isLoading={loading}
                  action={onSaveClick}
                />
              )}
            </GridCell>
          </Grid>
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DataExportsModal;
