import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { find, get } from 'lodash';

import { Flyover, Grid, GridCell } from 'v1/components/shared';

import TableContext from './TableContext';
import styles from './Table.module.scss';

const TableHeader = ({ groupId, row }) => {
  const {
    isLoading,
    headerGroups,
    frozenColumns,
    groupBy,
    groupOptions,
    prepareRow
  } = useContext(TableContext);

  const groupRow = find(
    get(row, 'subRows', []),
    row => get(row, 'original._type') === 'GROUP'
  );
  const groupName = get(groupRow, 'original._name');

  row && prepareRow(row);

  // GROUPING
  const renderExpand = () => (
    <GridCell width="auto" onClick={() => row.toggleRowExpanded()}>
      <img
        className={classnames(styles.GroupExpandIcon, 'clickable', {
          'rotate-90': row.isExpanded
        })}
        src="/images/icon_chevron_eggplant_lighter.svg"
        alt="Expand group"
      />
    </GridCell>
  );

  // GROUPING
  const renderOptions = () => {
    const flyoverWidth = groupOptions.find(opt => opt.description)
      ? 'S'
      : 'content';
    return (
      <Flyover>
        <Flyover.Trigger>
          <img
            className="clickable"
            src="/images/icon_ellipsis.svg"
            alt="Group options"
          />
        </Flyover.Trigger>
        <Flyover.Content width={flyoverWidth}>
          {groupOptions.map(option => (
            <Flyover.Item key={option.id} value={groupId} {...option} />
          ))}
        </Flyover.Content>
      </Flyover>
    );
  };

  // SORTING
  const renderSortIcon = column => (
    <div
      className={classnames([
        styles.SortIcon,
        {
          [styles.SortIcon_active]: column.isSorted
        }
      ])}
    >
      {column.isSortedDesc ? (
        <img src="/images/icon_column_sort_desc.svg" alt="" />
      ) : (
        <img src="/images/icon_column_sort_asc.svg" alt="" />
      )}
    </div>
  );

  const renderColumnHeader = column => {
    const rowGrouped = row && row.isGrouped;
    const groupCollapsed = rowGrouped && !row.isExpanded;
    const isIndexCell = column.id === '_index';
    const isGroupedCell = groupBy && column.index === 0;

    const sortingProps = column.isSortable
      ? column.getSortByToggleProps({
          title: column.isSortedDesc ? 'Sort descending' : 'Sort ascending',
          ...(isLoading ? { onClick: undefined } : {})
        })
      : {};

    const props = column.getHeaderProps([
      // SORTING
      sortingProps,
      // Standard
      {
        className: classnames([
          styles.Cell,
          column.className,
          {
            [styles.GroupCell]: isGroupedCell,
            [styles.Cell_bordered]:
              column.border === true && !groupCollapsed && !isIndexCell,
            [styles.Cell_noborder]:
              groupCollapsed || isIndexCell || column.border === false,
            [styles.Cell_sticky]: column.index < frozenColumns,
            [styles.Cell_sticky_last]: column.index === frozenColumns - 1,
            [styles.align_C]: column.align === 'center',
            [styles.align_R]: column.align === 'right'
          }
        ]),
        style: {
          left: column.index < frozenColumns ? column.totalLeft : null
        }
      }
    ]);

    return (
      <div {...props}>
        {isGroupedCell ? (
          <div>
            <Grid direction="row" gutters="S">
              <GridCell>
                <Grid gutters="S">
                  <GridCell>
                    <div className="trunc">{groupName}</div>
                  </GridCell>
                  {renderExpand()}
                </Grid>
              </GridCell>
              {groupOptions && (
                <GridCell width="auto">{renderOptions()}</GridCell>
              )}
            </Grid>
          </div>
        ) : isIndexCell ? (
          <>{row.index + 1}.0</>
        ) : (
          column.render('Header')
        )}
        {column.isSortable && renderSortIcon(column)}
      </div>
    );
  };

  return (
    <div className={styles.Header}>
      {headerGroups.map(headerGroup => (
        <div
          {...headerGroup.getHeaderGroupProps({
            className: styles.Row
          })}
        >
          {headerGroup.headers.map(column => renderColumnHeader(column))}
        </div>
      ))}
    </div>
  );
};

TableHeader.propTypes = {
  row: PropTypes.object
};

export default TableHeader;
