import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const TASK_LISTS_ACTIONS = mapValues(
  mirror({
    GET_TASK_LIST: null,
    GET_TASK_LIST_SUCCESS: null,
    GET_TASK_LIST_FAILURE: null,

    CREATE_TASK_LIST: null,
    CREATE_TASK_LIST_SUCCESS: null,
    CREATE_TASK_LIST_FAILURE: null,

    CREATE_TASK_WITHIN_TASK_LIST: null,
    CREATE_TASK_WITHIN_TASK_LIST_SUCCESS: null,
    CREATE_TASK_WITHIN_TASK_LIST_FAILURE: null,

    ASSIGN_TASK_LIST_TO_BOOKING: null,
    ASSIGN_TASK_LIST_TO_BOOKING_SUCCESS: null,
    ASSIGN_TASK_LIST_TO_BOOKING_FAILURE: null,

    ORDER_TASKS_WITHIN_TASK_LIST: null,
    ORDER_TASKS_WITHIN_TASK_LIST_SUCCESS: null,
    ORDER_TASKS_WITHIN_TASK_LIST_FAILURE: null
  }),
  value => value
);
