import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { gets3url, gets3PublicUrl } from 'lib/s3/gets3url';
import { Circle } from 'rc-progress';
import PropTypes from 'prop-types';
import sanitize from 'sanitize-filename';
import Papa from 'papaparse';
import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';
import classnames from 'classnames';

import './GenericFileUploader.scss';

class GenericFileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s3_signed_upload_url: null,
      s3_url: this.props.value || null,
      file: null,
      uploading: false,
      file_name: null,
      complete: this.props.value && this.props.value.length,
      progress: 0,
      error: null
    };

    this.onFileDrop = this.onFileDrop.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.callAction = this.callAction.bind(this);
  }

  onUploadProgress(prog) {
    this.setState({
      uploading: true,
      progress: prog,
      error: null
    });
  }

  onUploadError(err) {
    this.setState({
      error: err,
      uploading: false,
      progres: 0
    });
  }

  onUploadFinish(finalUrl) {
    this.setState(
      {
        uploading: false,
        complete: true,
        progress: 0,
        s3_url: finalUrl
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(
            this.state.s3_url,
            this.state.file_name,
            this.state.file
          );
        }
        this.setState({ file: null });
      }
    );
  }

  parseCSVData = file => {
    let parsedCSV;
    let that = this;

    if (!file) return;

    const CSV = Papa.parse(file, {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: function (results) {
        parsedCSV = results;
        return that.props.onCSVUpload(parsedCSV);
      }
    });
  };

  onFileDrop(files) {
    const file = files[0];

    this.setState({ uploading: true });

    this.props.onCSVUpload && this.parseCSVData(file);

    this.getS3UploadUrlForFile(file, (error, signedUploadUrl) => {
      if (error) return;

      this.setState(
        {
          s3_signed_upload_url: signedUploadUrl,
          file
        },
        () => {
          this.uploadFileToS3({
            uploadTo: this.state.s3_signed_upload_url,
            file: this.state.file,
            onComplete: this.onUploadFinish,
            onError: this.onUploadError,
            onProgress: this.onUploadProgress
          });
        }
      );
    });
  }

  getS3UploadUrlForFile(file, callback) {
    if (!file) return;

    let filenamesplit = sanitize(file.name);
    filenamesplit = file.name;
    let extension = filenamesplit.split('.').pop();
    extension = extension.toLowerCase();
    extension =
      extension === 'jpeg' || extension === 'JPEG' ? 'jpg' : extension;

    if (this.props.public) {
      gets3PublicUrl({
        content_type: file.type ? file.type : 'application/*',
        extension: `${extension}`,
        file_name: file.name,
        bucket: this.props.bucket
      })
        .then(url => {
          if (url) {
            callback(null, url);
          } else {
            callback({ message: 'URL FAILED TO RETURN. SOZ.' }, null);
          }
        })
        .catch(error => {
          callback({ message: 'URL FAILED TO RETURN. SOZ.', error }, null);
        });
    } else {
      gets3url({
        content_type: file.type ? file.type : 'application/*',
        extension: `${extension}`,
        file_name: file.name,
        bucket: this.props.bucket
      })
        .then(url => {
          if (url) {
            callback(null, url);
          } else {
            callback({ message: 'URL FAILED TO RETURN. SOZ.' }, null);
          }
        })
        .catch(error => {
          callback({ message: 'URL FAILED TO RETURN. SOZ.', error }, null);
        });
    }
  }

  callAction() {
    this.props.action(this.state.s3_url);
  }

  createCORSRequest(method, url) {
    let xhr = new window.XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else if (typeof window.XDomainRequest !== 'undefined') {
      xhr = new window.XDomainRequest();
      xhr.open(method, url);
    } else {
      xhr = null;
    }
    return xhr;
  }

  uploadFileToS3(config) {
    const file = config.file;
    const s3Path = config.uploadTo;
    const type = config.file.type ? config.file.type : 'application/*';
    const fileName = config.file.name;

    this.setState({ file_name: fileName });

    const xhr = this.createCORSRequest('PUT', s3Path);
    if (!xhr) return 'CORS not supported';

    const onloadDefault = () => {
      return xhr.responseText;
    };
    const onerrorDefault = {};

    xhr.onload = config.onLoad || onloadDefault;

    xhr.onerror = config.onError || onerrorDefault;

    xhr.upload.addEventListener('progress', evt => {
      if (evt.lengthComputable) {
        if (config.onProgress) {
          config.onProgress((evt.loaded / evt.total) * 100);
        }
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (config.onComplete) {
            config.onComplete(s3Path.split('?')[0]);
          }
        }
      }
    };

    // if (type.substr(0, 6) === 'image/') disposition = 'inline';

    xhr.setRequestHeader('Content-Type', type);
    xhr.setRequestHeader('x-amz-acl', 'public-read');

    xhr.withCredentials = true;

    xhr.send(file);

    return false;
  }
  dropRejected() {
    return alert('Maximum file upload size is 3MB');
  }
  renderProgressBar() {
    if (this.state.uploading) {
      return (
        <span className={`showProgress ${this.props.label ? 'hasLabel' : ''}`}>
          <Circle
            percent={this.state.progress}
            strokeWidth="8"
            strokeColor={
              this.props.dark ? SYSTEM_COLOURS.BLACK : SYSTEM_COLOURS.WHITE
            }
          />
        </span>
      );
    }
    return false;
  }
  render() {
    const {
      style,
      accepts,
      activeClassName,
      maxSize,
      label,
      withIcon,
      showStripes,
      className
    } = this.props;
    return (
      <Dropzone
        multiple={false}
        onDrop={this.onFileDrop}
        accept={accepts ? accepts : null}
        className={classnames({ uploading: this.state.uploading }, [
          'GenericFileUploader',
          className
        ])}
        activeClassName={activeClassName}
        onDropRejected={this.dropRejected}
        maxSize={maxSize ? maxSize : 100000000}
        style={style}
      >
        {showStripes && (
          <div
            className={classnames({ active: this.state.uploading }, [
              'stripes'
            ])}
          />
        )}
        <div className="GenericFileUploader-content">
          {this.renderProgressBar()}
          {!this.state.uploading && withIcon && (
            <img
              src="/images/icon_type_upload_cloud.svg"
              className="GenericFileUploader-icon"
              alt=""
            />
          )}
          {!this.state.uploading && label && (
            <div className="GenericFileUploader-label">{label}</div>
          )}
        </div>
      </Dropzone>
    );
  }
}

GenericFileUploader.propTypes = {
  // Function
  value: PropTypes.object, // TODO: object shape
  public: PropTypes.bool,
  bucket: PropTypes.string,
  accepts: PropTypes.string, // File extension
  maxSize: PropTypes.number, // In bytes
  imageOnly: PropTypes.bool, // TODO: used?

  // Presentation
  label: PropTypes.any.isRequired, // TODO: string or object?
  name: PropTypes.string, // TODO: used?
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  style: PropTypes.any, // TODO: type
  dark: PropTypes.bool,
  withIcon: PropTypes.bool,
  showStripes: PropTypes.bool,

  // Action
  onChange: PropTypes.func,
  onCSVUpload: PropTypes.func,
  action: PropTypes.func
};

export default GenericFileUploader;
