import v4 from 'uuid';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _find from 'lodash/find';
import { uniq } from 'lodash';

export const PRODUCTION_STATUS = [
  {
    status: 'OPEN',
    label: 'Open',
    description: 'Production open ready to be resourced',
    icon: '/images/icon_production_status_open.svg',
    color: '#EBF3F3',
    order: 0
  },
  {
    status: 'RESOURCING',
    label: 'Resourcing',
    description: 'Team has begun booking resources for this production',
    icon: '/images/icon_production_status_resourcing.svg',
    color: '#4BD9D8',
    order: 1
  },
  {
    status: 'READY',
    label: 'Ready',
    description: 'All crew members have been booked',
    icon: '/images/icon_production_status_ready.svg',
    color: '#FFCB03',
    order: 2
  },
  {
    status: 'ACTIVE',
    label: 'Active',
    description: 'Production is underway',
    icon: '/images/icon_production_status_active.svg',
    color: '#D83BF2',
    order: 3
  },
  {
    status: 'COMPLETE',
    label: 'Complete',
    description: 'Production wrapped and complete!',
    icon: '/images/icon_production_status_complete.svg',
    color: '#29C86F',
    order: 4
  }
];

export const getActiveStatus = status =>
  PRODUCTION_STATUS.find(p => p.status === status) || PRODUCTION_STATUS[0];

export const getUniqueCodePreview = (data = {}) => {
  const { nodes = [], with_dash } = data;
  return nodes.reduce(
    (acc, node, i) =>
      acc +
      (i > 0 && with_dash ? '-' : '') +
      (node.type === 'incr'
        ? '123'
        : node.type === 'date'
        ? '08-2019'
        : node.type === 'text' && node.value
        ? node.value
        : ''),
    ''
  );
};

/**
 *
 * @param {Object} production
 * @param {String} idField
 * @returns {Array} array of contact_id integers included in a productions resource_slots
 */
export const getProductionResourceIds = (
  production,
  idField = 'contact_id'
) => {
  if (!production) return [];
  const assignments = (production.resource_slots || []).reduce(
    (result, slot) =>
      result.concat(
        slot.resource_slot_assignments
          .filter(assignment => assignment[idField])
          .map(assignment => assignment[idField])
      ),
    []
  );
  return uniq(assignments);
};

// TODO: This whole system needs a re-think

export function generateProductionFromTemplate(template, production = {}) {
  let groupId = `ref-${v4()}`;

  function generateGroups() {
    const templateGroups = _get(template, ['default_groups', 'groups'], []);
    const hasLocationGroup = templateGroups.find(
      group => group.is_location || group.name === 'Locations'
    );
    if (hasLocationGroup && hasLocationGroup.id) {
      groupId = hasLocationGroup.id;
      return templateGroups;
    }

    const locationGroup = {
      id: groupId,
      order: _size(templateGroups),
      is_location: true,
      name: 'Locations'
    };
    return templateGroups.concat(locationGroup);
  }

  function generateRoles() {
    let templateRoles = _get(template, 'default_resource_slots.roles', []);
    const presetRoles = (production.resource_slots || []).map(resource_slot => {
      if (
        resource_slot.is_location &&
        resource_slot.resource_slot_assignments &&
        resource_slot.resource_slot_assignments.length
      ) {
        const templateRoleLocation = _find(
          templateRoles,
          r => r.is_location === true
        );
        return {
          ...resource_slot,
          group_id: groupId,
          order: _get(templateRoleLocation, 'order'),
          slot_category_id: _get(templateRoleLocation, 'slot_category_id')
        };
      }
      return resource_slot;
    });
    // Filter duplicates
    templateRoles = templateRoles.filter(
      r => !presetRoles.find(pr => pr.slot_category_id == r.slot_category_id)
    );
    return [...templateRoles, ...presetRoles];
  }

  return {
    production_template_id: template.id,
    production_type_id: template.production_type_id,
    title:
      _get(template, ['default_values', 'title', 'value']) ||
      _get(template, ['default_values', 'title']),
    code:
      _get(template, ['default_values', 'code', 'value']) ||
      _get(template, ['default_values', 'code']),
    color:
      _get(template, ['default_values', 'color', 'value']) ||
      _get(template, ['default_values', 'color']),
    icon:
      _get(template, ['default_values', 'icon', 'value']) ||
      _get(template, ['default_values', 'icon']),
    custom_fields:
      _get(template, ['default_values', 'custom_fields', 'value']) ||
      _get(template, ['default_values', 'custom_fields']),
    custom_fieldgroups:
      _get(template, ['default_values', 'custom_fieldgroups', 'value']) ||
      _get(template, ['default_values', 'custom_fieldgroups']),
    groups: generateGroups(),
    resource_slots: generateRoles(),
    budget_expenses: _get(template, [
      'default_budget_expenses',
      'budget_expenses'
    ])
  };
}
