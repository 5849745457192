import React from 'react';
import PropTypes from 'prop-types';

import RateSelectorContent from './RateSelectorContent/RateSelectorContent';
import { Popover, PopoverTrigger, PopoverContent } from 'v1/components/shared';

import './RateSelector.scss';

const RateSelector = ({
  label,
  labelClass,
  resourceId,
  assignments,
  showRateCards,
  onSelect
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <div className={labelClass || 'btn genericLink'}>
          {label || 'Select rate'}
        </div>
      </PopoverTrigger>
      <PopoverContent className="RateSelector">
        {({ closePopover }) => (
          <RateSelectorContent
            resourceId={resourceId}
            assignments={assignments}
            showRateCards={showRateCards}
            closePopover={closePopover}
            onSelect={onSelect}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

RateSelector.props = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  resourceId: PropTypes.number,
  assignments: PropTypes.arrayOf(PropTypes.object), // booking.resource_slot_assignments - For budgeted rates
  showRateCards: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};

export default RateSelector;
