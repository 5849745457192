import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const TASKS_ACTIONS = mapValues(
  mirror({
    CREATE_TASK: null,
    CREATE_TASK_SUCCESS: null,
    CREATE_TASK_FAILURE: null,

    DELETE_TASK: null,
    DELETE_TASK_SUCCESS: null,
    DELETE_TASK_FAILURE: null,

    UPDATE_TASK: null,
    UPDATE_TASK_SUCCESS: null,
    UPDATE_TASK_FAILURE: null
  }),
  value => value
);
