import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

//const prefix = '@@systemActions';

export const SYSTEM_ACTIONS = mapValues(
  mirror({
    SEARCH_SYSTEM: null,
    SEARCH_SYSTEM_SUCCESS: null,
    SEARCH_SYSTEM_FAILURE: null,

    GET_RESOURCE_DUPLICATES: null,
    GET_RESOURCE_DUPLICATES_SUCCESS: null,
    GET_RESOURCE_DUPLICATES_FAILURE: null,

    MERGE_RESOURCE: null,
    MERGE_RESOURCE_SUCCESS: null,
    MERGE_RESOURCE_FAILURE: null
  }),
  value => value
);
