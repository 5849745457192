import React from 'react';
import classnames from 'classnames';

import styles from './GridCell.module.scss';

export const GRIDCELL_VALIGNS = ['top', 'center', 'bottom', 'stretch'] as const;
export const GRIDCELL_WIDTHS = [
  'default',
  'fixed',
  'fill',
  '1/12',
  '1/6',
  '2/12',
  '1/4',
  '3/12',
  '1/3',
  '4/12',
  '5/12',
  '1/2',
  '6/12',
  '7/12',
  '2/3',
  '8/12',
  '3/4',
  '9/12',
  '5/6',
  '10/12',
  '11/12',
  '12/12',
  '1'
] as const;

type GridCellProps = {
  /**
   * Vertical alignment of this grid cell
   */
  valign?: typeof GRIDCELL_VALIGNS[number];
  /**
   * Width of this grid cell
   */
  width?: typeof GRIDCELL_WIDTHS[number];
  /**
   * Padding for this grid cell
   */
  padding?: CSSSize;
  hpadding?: CSSSize;
  vpadding?: CSSSize;
  onClick?(): void;
  children?: React.ReactNode;
};

const GridCell = ({
  valign,
  width = 'default',
  padding,
  hpadding,
  vpadding,
  onClick,
  children
}: GridCellProps) => (
  <div
    className={classnames(styles.GridCell, {
      [styles.clickable]: onClick,
      [styles[`valign_${valign}`]]: valign,
      [styles[`padding_${padding}`]]: padding,
      [styles[`hpadding_${hpadding}`]]: hpadding,
      [styles[`vpadding_${vpadding}`]]: vpadding,
      [styles[`width_${width}`]]: width !== 'default'
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

export default GridCell;
