import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import {
  PressStud,
  Grid,
  GridCell,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper
} from 'v1/components/shared';
import ResourceEventsForm from './ResourceEventsForm/ResourceEventsForm';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

/*

INSTRUCTIONS FOR USE
====

As with other modals, pass in an object as the second parameter of openModal to supply the data.
eg: openModal('ResourceEventsModal', { bookingId })

ALLOWED PROPERTIES:
⭐️ bookingId int - The id of the existing booking
⭐️ resourceId int - The id of the resource to create a booking for. This will auto-handle any resource-related information, including rates.
⭐️ resourceSlotAssignment {} - an existing assignment (eg from a production) to create a booking for
⭐️ resourceSlotId int - The id of the production resource slot to create an assignment for
⭐️ event {} - A standard Event-style object to use as the basis for new dates. You can pass in as many Event properties as you like here, and form will figure out what to use where and what to ignore
⭐️ statusId int - The id of the status to be set within a new booking if not default

POPULATION LOGIC ACCORDING TO SUPPLIED PROPERTIES:
-
✅ No properties - Create a new blank booking
-
✅ bookingId only - Update an existing booking
✅ resourceId only - Create a new booking for given resource
⛔️ resourceSlotAssignment only - requires event as well
✅ resourceSlotId only - Create a new booking for the resource slot
✅ event only - Create a new booking for given dates
-
⛔️ bookingId & resourceId - resourceId is superfluous -> Equivalent of passing bookingId only
⛔️ bookingId & resourceSlotAssignment - resourceSlotAssignment is superfluous -> Equivalent of passing bookingId only
✅ bookingId & resourceSlotId - Add an assignment to an existing booking, using default dates
⛔️ bookingId & event - event is superfluous -> Equivalent of passing bookingId only
⛔️ bookingId & resourceSlotId & event - event is superfluous
-
⛔️ resourceId & resourceSlotAssignment - resourceId is superfluous -> Equivalent of passing resourceSlotAssignment only
✅ resourceId & resourceSlotId - Create a new booking for the given resource & the given resource slot
✅ resourceId & event - Create a new booking for given resource with given dates (use production.production_date)
✅ resourceId & resourceSlotId & event - event should be the associated production dates (use production.production_date) // TODO: optimise this
-
⛔️ resourceSlotAssignment & resourceSlotId - resourceSlotId is superfluous -> Equivalent of passing resourceSlotAssignment only
✅ resourceSlotAssignment & event - Create a new booking for the existing assignment, with the given dates
-
⛔️ resourceSlotId & event - event is superfluous

PLEASE THINK CAREFULLY ABOUT THE LOGIC BEFORE MAKING ALTERATIONS

*/

const ResourceEventsModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const bookings = useSelector(state => state.bookings);
  const [formApi, setFormApi] = useState();

  const canUpdateBookings = useRestrictedAccess(
    undefined,
    Capability.BOOKING_UPDATE,
    undefined
  );
  const canUpdateResources = useRestrictedAccess(
    undefined,
    Capability.RESOURCE_UPDATE,
    undefined
  );

  useEvent([BOOKING_ACTIONS.CREATE_BOOKING, BOOKING_ACTIONS.UPDATE_BOOKING], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Booking Updated'));
      onRequestClose();
    },
    onFailure: () => {
      if (get(bookings, ['error', 'status']) === 409) {
        return dispatch(
          openModal('BookingConflictModal', {
            method: formApi.getValue('id') ? 'UPDATE' : 'CREATE',
            resourceId: formApi.getValue('contact_id')
          })
        );
      }
      dispatch(displayAlert('error', 'Update Failed'));
    }
  });

  const onSaveClick = () => {
    formApi.submitForm();
  };

  return (
    <ModalWrapper size="L">
      <ModalHeader title="Create booking / availability" />
      <ModalContent>
        <ModalScrollable>
          <ResourceEventsForm
            getApi={setFormApi}
            disabled={!canUpdateBookings || !canUpdateResources}
          />
        </ModalScrollable>
        <ModalNavigation>
          <Grid>
            <div className="spacer"></div>
            <GridCell width="auto">
              <PressStud
                label="Cancel"
                appearance="silent"
                action={onRequestClose}
              />
              {canUpdateBookings && canUpdateResources && (
                <PressStud
                  label="Save"
                  appearance="primary"
                  action={onSaveClick}
                />
              )}
            </GridCell>
          </Grid>
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ResourceEventsModal;
