import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const CALLOUT_ACTIONS = mapValues(
  mirror({
    GET_CALLOUTS: null,
    GET_CALLOUTS_SUCCESS: null,
    GET_CALLOUTS_FAILURE: null,

    GET_CALLOUT: null,
    GET_CALLOUT_SUCCESS: null,
    GET_CALLOUT_FAILURE: null,

    GET_CALLOUT_ASSOCIATIONS: null,
    GET_CALLOUT_ASSOCIATIONS_SUCCESS: null,
    GET_CALLOUT_ASSOCIATIONS_FAILURE: null,

    GET_PUBLIC_CALLOUT: null,
    GET_PUBLIC_CALLOUT_SUCCESS: null,
    GET_PUBLIC_CALLOUT_FAILURE: null,

    CREATE_CALLOUT: null,
    CREATE_CALLOUT_SUCCESS: null,
    CREATE_CALLOUT_FAILURE: null,

    SUBMIT_APPLICANT: null,
    SUBMIT_APPLICANT_SUCCESS: null,
    SUBMIT_APPLICANT_FAILURE: null,

    UPDATE_CALLOUT: null,
    UPDATE_CALLOUT_SUCCESS: null,
    UPDATE_CALLOUT_FAILURE: null,

    DELETE_CALLOUT: null,
    DELETE_CALLOUT_SUCCESS: null,
    DELETE_CALLOUT_FAILURE: null,

    DELETE_APPLICANT: null,
    DELETE_APPLICANT_SUCCESS: null,
    DELETE_APPLICANT_FAILURE: null
  }),
  value => value
);
