import {
  createContext,
  HTMLAttributes,
  RefObject,
  useContext,
  useRef
} from 'react';

const ScrollParentContext = createContext<
  RefObject<HTMLDivElement> | undefined
>(undefined);

export interface ScrollParentProps extends HTMLAttributes<HTMLDivElement> {}

/**
 * Renders a div with a ref that can be used to perform virtual scrolling behavior
 * Currently it supports only `div` elements
 * To support more, you would need to create generic props with 'as' property (like in React Router Link)
 * @param children
 * @param rest
 * @constructor
 */
export function ScrollParent({ children, ...rest }: ScrollParentProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <ScrollParentContext.Provider value={ref}>
      <div ref={ref} {...rest}>
        {children}
      </div>
    </ScrollParentContext.Provider>
  );
}

export function useScrollParent() {
  const value = useContext(ScrollParentContext);
  if (!value) {
    throw new Error('ScrollParentContext is undefined');
  }
  return value;
}
