import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthService from 'lib/auth/AuthService';

import './ErrorBlock.scss';
import PressStud from '~/v1/components/shared/buttons/PressStud/PressStud';
import GridCell from '~/v1/components/shared/layout/Grid/GridCell/GridCell';
import Grid from '~/v1/components/shared/layout/Grid/Grid';

const ERROR_DEFAULT = {
  status: 404,
  title: "Sorry, we can't find what you're looking for",
  descriptions:
    'Check the link is correct or make sure you are logged in. If you are still having issues we are here to help.'
};

// TODO: Extract to shared
const ContactSupportButton = () => (
  <PressStud
    label="Contact Support"
    action="mailto:support@atellio.zendesk.com"
  />
);
const LogOutButton = () => {
  const handleLogout = () => {
    AuthService.logout();
  };

  return (
    <PressStud label="Log out" appearance="secondary" action={handleLogout} />
  );
};

// TODO: Refactor : really easy to refactor
class ErrorBlock extends Component {
  renderErrorLabel = error => {
    switch (error.status) {
      case 404:
        return "Sorry, we can't find what you're looking for";
      case 403:
        return "Sorry, you're not allowed to be here";
      case 401:
        return 'You have been logged out';
      default:
        return 'Something has gone wrong';
    }
  };

  renderErrorDescription = error => {
    switch (error.status) {
      case 404:
        return 'Check the link is correct or make sure you are logged in. If you are still having issues we are here to help!';
      case 403:
        return 'This page is restricted or unavailable to you. If you think you should be allowed here please contact us.';
      case 401:
        return 'You do not have access to this page so you have been logged out. If you think something has gone wrong, please contact us.';
      default:
        return "We've been notified about the issue. Please contact us for further support and we can let you know when this has been resolved.";
    }
  };

  handleLogout = () => {
    AuthService.logout();
  };

  renderAction = error => {
    switch (error.status) {
      case 404:
        return (
          <Grid gutters="S" align="center">
            <GridCell width="auto">
              <ContactSupportButton />
            </GridCell>
            <GridCell width="auto">
              {/* TODO: Extract to shared */}
              <PressStud
                label="Take me home"
                appearance="secondary"
                action="/"
              />
            </GridCell>
          </Grid>
        );
      case 403:
      case 401:
      default:
        return (
          <Grid gutters="S" align="center">
            <GridCell width="auto">
              <ContactSupportButton />
            </GridCell>
            <GridCell width="auto">
              <LogOutButton />
            </GridCell>
          </Grid>
        );
    }
  };

  render() {
    const error = this.props.error || ERROR_DEFAULT;

    return (
      <div
        className={`ErrorBlock text-center ${
          this.props.fullpage ? 'fullpage' : ''
        }`}
      >
        <span className="ErrorBlock-image">
          <img
            src="/images/icon_colour_error_playful.svg"
            alt=""
            width="30px"
          />
        </span>
        <br />
        <h2>{error.title || this.renderErrorLabel(error)}</h2>
        <p>{error.description || this.renderErrorDescription(error)}</p>
        {this.renderAction(error)}
        <a href="/" className="ErrorBlock-stitchMark">
          <img src="/images/logo_black.svg" width="100px" alt="" />
        </a>
      </div>
    );
  }
}

ErrorBlock.propTypes = {
  error: PropTypes.any, // Error
  fullpage: PropTypes.bool
};

export default ErrorBlock;
