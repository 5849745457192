import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Api from 'lib/api/Api';

import { openModal } from 'store/v1/ui/ui.actions.js';
import { Dropdown, MenuItem, MenuLink } from 'v1/components/shared';

// TODO: this was ripped out of BudgetSidebar and needs bringing up to date
const ProductionBudgetOptions = ({ productionId }) => {
  const dispatch = useDispatch();
  const productions = useSelector(store => store.productions);
  const production = get(productions, ['data', productionId], {});

  const makeExportURL = section => {
    return Api.formatUrl(
      `/productions/${productionId}/budgets/${section}/export`
    );
  };

  return (
    <Dropdown
      buttonClass="btn btn-square btn-small btn-default"
      buttonIcon={<img src="/images/icon_options.svg" alt="" />}
    >
      <MenuItem
        onSelect={() => {
          dispatch(openModal('BudgetSettingsModal', { production }));
        }}
      >
        <span className="MenuItem-icon">
          <img src="/images/icon_edit.svg" alt="" />
        </span>
        <span className="MenuItem-label">Budget settings</span>
      </MenuItem>

      <MenuLink href={makeExportURL('estimated')} target="_blank">
        <span className="MenuItem-icon">
          <img src="/images/icon_file_download.svg" alt="" />
        </span>
        <span className="MenuItem-label">Download estimated budget</span>
      </MenuLink>

      <MenuLink href={makeExportURL('committed')} target="_blank">
        <span className="MenuItem-icon">
          <img src="/images/icon_file_download.svg" alt="" />
        </span>
        <span className="MenuItem-label">Download committed budget</span>
      </MenuLink>

      <MenuLink href={makeExportURL('actuals')} target="_blank">
        <span className="MenuItem-icon">
          <img src="/images/icon_file_download.svg" alt="" />
        </span>
        <span className="MenuItem-label">Download actuals budget</span>
      </MenuLink>
    </Dropdown>
  );
};

ProductionBudgetOptions.propTypes = {
  productionId: PropTypes.number
};

export default ProductionBudgetOptions;
