import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  openBookingSidebar,
  openContactSidebar
} from 'store/v1/ui/ui.actions.js';
import { Status } from 'v1/components/shared';

import './BookingPill.scss';

const BookingPill = ({ assignment }) => {
  const dispatch = useDispatch();
  const resources = useSelector(state => state.contacts);
  const bookings = useSelector(state => state.bookings);
  const resource = resources.data[assignment.contact_id];
  const booking = bookings.data[assignment.booking_id];

  const onClick = event => {
    event.stopPropagation();
    booking
      ? dispatch(openBookingSidebar(booking))
      : dispatch(openContactSidebar(resource));
  };

  // TODO: this is a major thing: we need to make sure we've loaded all the resources we need
  return (
    <div className="BookingPill ContactName-SidebarHint" onClick={onClick}>
      {booking && <Status statusId={booking.status_id} appearance="compact" />}
      {resource && resource.full_name}
      <img
        data-tip="Quick View Contact"
        className="ContactName-SidebarHint-icon"
        src="/images/icon_contact_sidebar_hint.svg"
        alt=""
      />
    </div>
  );
};

BookingPill.props = {
  assignment: PropTypes.object // A ResourceSlotAssignment object, from production.resource_slots.resource_slot_assignments
};

export default BookingPill;
