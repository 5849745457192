import { DURATION_TYPES } from 'v1/helpers/byModel/EventHelper';

export const buildLocalAvailability = availability => ({
  startDate: new Date(availability.startDate).toISOString(),
  endDate: new Date(availability.endDate).toISOString(),
  statusId: availability.statusId,
  status: availability.status,
  dateType: availability.dateType || DURATION_TYPES.RECURRING_DEFAULT,
  estimatedDailyHours: availability.estimatedDailyHours,
  estimatedDailyMinutes: availability.estimatedDailyMinutes,
  estimatedStartTime: availability.estimatedStartTime,
  estimatedEndTime: availability.estimatedEndTime
});

export const buildSystemAvailability = availability => ({
  startDate: new Date(availability.startDate).toISOString(),
  endDate: new Date(availability.endDate).toISOString(),
  status: availability.status,
  availabilityId: availability.availabilityId,
  eventId: availability.eventId,
  dateType: availability.dateType,
  estimatedDailyHours: availability.estimatedDailyHours,
  estimatedDailyMinutes: availability.estimatedDailyMinutes,
  estimatedStartTime: availability.estimatedStartTime,
  estimatedEndTime: availability.estimatedEndTime
});
