import React from 'react';

import './ModalNavigation.scss';

const ModalNavigation = ({ children }) => {
  return <div className="ModalNavigation">{children}</div>;
};

ModalNavigation.propTypes = {};

export default ModalNavigation;
