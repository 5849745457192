import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ApplicantReviewCarousel from 'v1/components/feature/CalloutComponents/Callout/ApplicantReviewCarousel/ApplicantReviewCarousel';
import ApplicantReviewActions from 'v1/components/feature/CalloutComponents/Callout/ApplicantReviewActions/ApplicantReviewActions';
import { ModalWrapper, ModalContent } from 'v1/components/shared';

import {
  applicantsFilters,
  getApplicantName
} from 'v1/helpers/byModel/applicantHelper';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { chain, debounce } from 'lodash';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import './ApplicantReviewModal.scss';
import { checkContactDuplicates } from 'store/v1/contacts/contacts.actions';

const ApplicantReviewModal = () => {
  const dispatch = useDispatch();
  const { calloudId, applicantId, filter } = useSelector(
    state => state.ui.data
  );

  const callout = useSelector(state =>
    get(state.callouts, ['data', calloudId], {})
  );

  const applicants = useSelector(state =>
    chain(
      get(state.callouts, ['associations_data', calloudId, 'applicants'], [])
    )
      .filter(applicantsFilters[filter])
      .value()
  );

  const [selectedApplicantIndex, setSelectedApplicantIndex] = useState(
    findIndex(applicants, a => a.id === applicantId)
  );

  useEffect(() => {
    const applicantData = get(applicants, [selectedApplicantIndex, 'data'], {});
    const fullName = getApplicantName(applicantData);
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[1];
    dispatch(
      checkContactDuplicates({
        first_name: firstName,
        last_name: lastName
      })
    );
  }, [selectedApplicantIndex]);

  useEffect(() => {
    // If applicants list has changed and selectedApplicant is empty select next available applicant
    if (!isEmpty(applicants) && isEmpty(applicants[selectedApplicantIndex])) {
      setSelectedApplicantIndex(0);
    }
  }, [applicants]);

  function onNavigate(direction) {
    let newIndex = 0;
    if (direction === 'LEFT') {
      newIndex =
        selectedApplicantIndex === 0
          ? applicants.length - 1
          : selectedApplicantIndex - 1;
    }
    if (direction === 'RIGHT') {
      newIndex =
        selectedApplicantIndex === applicants.length - 1
          ? 0
          : selectedApplicantIndex + 1;
    }
    setSelectedApplicantIndex(newIndex);
  }

  return (
    <ModalWrapper className="ApplicantReviewModal" size="M">
      <ModalHeader title="Review applicants" />
      <ModalContent>
        <ApplicantReviewCarousel
          selectedApplicant={applicants[selectedApplicantIndex]}
          callout={callout}
        />
        <ApplicantReviewActions
          applicants={applicants}
          selectedApplicant={applicants[selectedApplicantIndex]}
          callout={callout}
          onNavigate={onNavigate}
        />
      </ModalContent>
    </ModalWrapper>
  );
};

export default ApplicantReviewModal;
