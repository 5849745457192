import { TASK_LISTS_ACTIONS } from 'store/task_lists/task_lists.constants.js';

import get from 'lodash/get';

export const INITIAL_STATE = {
  data: {},
  newTaskList: {},
  paging: {},
  order: [],
  loading: false
};

export default function reducer(state = INITIAL_STATE, action) {
  const result = get(action, 'result.data', {});

  switch (action.type) {
    case TASK_LISTS_ACTIONS.CREATE_TASK_LIST:
    case TASK_LISTS_ACTIONS.GET_TASK_LIST:
    case TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST:
    case TASK_LISTS_ACTIONS.ASSIGN_TASK_LIST_TO_BOOKING:
    case TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case TASK_LISTS_ACTIONS.CREATE_TASK_LIST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [result.id]: result },
        order: [...state.order, result.id],
        newTaskList: result,
        loading: false
      };
    case TASK_LISTS_ACTIONS.GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [result.taskListId]: {
            ...result,
            tasks: result.tasks ? result.tasks.map(task => task.taskId) : []
          }
        },
        order: [...state.order, result.id], // CHECK IF IT EXISTS
        paging: action.result.paging,
        loading: false
      };
    case TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.taskListId]: {
            ...state.data[action.taskListId],
            tasks: [...state.data[action.taskListId].tasks, result.taskId]
          }
        },
        order: [...state.order, result.taskId],
        loading: false
      };
    case TASK_LISTS_ACTIONS.GET_TASK_LIST_FAILURE:
    case TASK_LISTS_ACTIONS.CREATE_TASK_LIST_FAILURE:
    case TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_FAILURE:
    case TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
