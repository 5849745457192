// Libraries
import get from 'lodash/get';
import React from 'react';

// Components
import { Dropdown, MenuItem, UserCircleImage } from 'v1/components/shared';
import ResourceFieldsDisplay from '../../../ResourceFieldsDisplay/ResourceFieldsDisplay';
import './ShortlistOrderItem.scss';

const ShortlistOrderItem = ({
  block,
  index,
  lastOrder,
  updateOrder,
  default_fields,
  shortlistFieldMapper
}) => {
  return (
    <div className="ShortlistOrderItem">
      <div className="ShortlistOrderItem-index">{index + 1}</div>
      <div className="ShortlistOrderItem-icon">
        <img src="/images/icon_drag.svg" alt="Order" />
      </div>
      <div className="ShortlistOrderItem-user">
        <UserCircleImage
          src={get(block, 'contact.profile_picture')}
          size="small"
          className="extra-small"
          style={{ width: '50px', height: '50px', maxWidth: '50px' }}
          initials={{
            first_name: get(block, 'contact.first_name'),
            last_name: get(block, 'contact.last_name')
          }}
        />
        <p className="trunc">{get(block, 'contact.full_name')}</p>
      </div>
      <div className="ShortlistOrderItem-note">
        <span>{get(block, 'data.paragraph', 'n/a')}</span>
      </div>
      <ResourceFieldsDisplay
        hideLabel
        fields={default_fields}
        fieldMapper={shortlistFieldMapper}
        resource={block.contact}
        unwrapped
      />
      <div className="ShortlistOrderItem-actions">
        <Dropdown
          buttonIcon={<img src={`/images/icon_options.svg`} alt="" />}
          buttonClass="btn btn-default btn-small capitalise"
        >
          {index > 0 ? (
            <MenuItem onSelect={() => updateOrder(index, 0)}>
              <img src="/images/icon_arrow_top.svg" alt="Top" />
              <span className="MenuItem-label">Send to top</span>
            </MenuItem>
          ) : null}
          {index !== lastOrder ? (
            <MenuItem onSelect={() => updateOrder(index, lastOrder)}>
              <img src="/images/icon_arrow_bottom.svg" alt="Bottom" />
              <span className="MenuItem-label">Send to bottom</span>
            </MenuItem>
          ) : null}
        </Dropdown>
      </div>
    </div>
  );
};

export default ShortlistOrderItem;
