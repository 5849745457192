import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const EXPENSES_ACTIONS = mapValues(
  mirror({
    GET_EXPENSES: null,
    GET_EXPENSES_SUCCESS: null,
    GET_EXPENSES_FAILURE: null,

    CREATE_EXPENSE: null,
    CREATE_EXPENSE_SUCCESS: null,
    CREATE_EXPENSE_FAILURE: null,

    DELETE_EXPENSE: null,
    DELETE_EXPENSE_SUCCESS: null,
    DELETE_EXPENSE_FAILURE: null,

    UPDATE_EXPENSE: null,
    UPDATE_EXPENSE_SUCCESS: null,
    UPDATE_EXPENSE_FAILURE: null,

    CREATE_EXPENSE_RECEIPT: null,
    CREATE_EXPENSE_RECEIPT_SUCCESS: null,
    CREATE_EXPENSE_RECEIPT_FAILURE: null,

    UPDATE_EXPENSE_RECEIPT: null,
    UPDATE_EXPENSE_RECEIPT_SUCCESS: null,
    UPDATE_EXPENSE_RECEIPT_FAILURE: null,

    DELETE_EXPENSE_RECEIPT: null,
    DELETE_EXPENSE_RECEIPT_SUCCESS: null,
    DELETE_EXPENSE_RECEIPT_FAILURE: null
  }),
  value => value
);
