import { z } from 'zod';

export const eventSchemas = {
  shortlist: {
    updated: z.object({
      type: z.literal('SHORTLIST_UPDATED'),
      payload: z.object({
        shortlistId: z.coerce.number()
      })
    })
  },
  booking: {
    updated: z.object({
      type: z.literal('BOOKING_UPDATED'),
      payload: z.object({
        id: z.coerce.number()
      })
    })
  }
};
