import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  SearchField,
  Grid,
  GridCell,
  Checkbox,
  Label
} from 'v1/components/shared';
import { getStatuses } from 'store/v1/statuses/statuses.selectors.js';
import {
  getProductionTypes,
  getProductionTemplates
} from 'store/v1/productions/productions.selectors.js';
import { selectTagsStore, selectTagsList } from 'store/v1/tags';
import { selectActiveAccountId } from 'store/session';
import { selectAccountsList } from 'store/accounts';
import * as entityTypes from 'v1/helpers/entityTypes';
import { isValue } from 'v1/helpers/misc';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

import './ProductionFilters.scss';

const ProductionFilters = ({ query, onFilterChange }) => {
  const customFieldDefinitions = useSelector(
    state => state.auth.settings.custom_field_definitions
  );

  const statuses = useSelector(state =>
    getStatuses(state, { entity_type: entityTypes.PRODUCTION })
  );

  const cancelledStatus = useSelector(state =>
    getStatuses(state, {
      entity_type: entityTypes.PRODUCTION,
      status_type: 'CANCELLED'
    })
  );

  function isHidingCancelledProductions() {
    const filteredStatuses = _get(query, 'filters.status_id.eq', []);
    return (
      filteredStatuses.length &&
      !filteredStatuses.includes(cancelledStatus[0]?.id)
    );
  }

  const tagsStore = useSelector(selectTagsStore);
  const tagsList = useSelector(selectTagsList);

  const productionTypeList = useSelector(getProductionTypes);
  const productionTypeOptions = useMemo(
    () => productionTypeList.map(i => ({ value: i.id, name: i.name })),
    [productionTypeList]
  );

  const productionTemplateList = useSelector(getProductionTemplates);
  const productionTemplateOptions = useMemo(
    () => productionTemplateList.map(i => ({ value: i.id, name: i.name })),
    [productionTemplateList]
  );

  const activeUserId = useSelector(selectActiveAccountId);
  const userList = useSelector(selectAccountsList);
  const userOptions = useMemo(
    () =>
      userList.map(({ id, displayName }) => {
        const name = id === activeUserId ? `${displayName} (me)` : displayName;
        return { value: id, name };
      }),
    [activeUserId, userList]
  );

  const productionTypes = useSelector(state => state.production_types);
  const activeProductionTypes = _get(
    query,
    'filters.production_type_id.eq',
    []
  ).map(productionTypeId => {
    return productionTypes.data[productionTypeId];
  });

  const productionCustomFields = [];

  activeProductionTypes &&
    activeProductionTypes.length &&
    activeProductionTypes.map(activeProductionType => {
      let productionTypesField =
        _get(activeProductionType, 'metastructure.fields') ||
        _get(activeProductionType, 'metastructure.field_order', []);

      return productionTypesField.map(field => {
        if (
          !productionCustomFields.find(
            f =>
              typeof f == 'object' &&
              f.custom_field_definition_id === field.custom_field_definition_id
          )
        ) {
          productionCustomFields.push(field);
        }
      });
    });

  const handleUpdate = (key, method, value, fieldType) => {
    let prevFilters = _get(query, 'filters', {});

    if (key === '$location') {
      const locationIDs = Array.isArray(value) ? value.map(id => id) : [];
      return onFilterChange({
        filters: {
          ...prevFilters,
          [key]: {
            [method]: locationIDs
          }
        }
      });
    }

    if (isValue(value)) {
      let filter = { [method]: value };
      if (fieldType) filter.custom_field_definition_type = fieldType;
      return onFilterChange({ filters: { ...prevFilters, [key]: filter } });
    }

    onFilterChange({ filters: _omit(prevFilters, key) });
  };

  function getCustomFieldObject(id) {
    const definition = customFieldDefinitions.find(d => d.id === id);
    return definition
      ? {
          key: `custom_${definition.id}`,
          label: definition.name,
          data_type: definition.data_type,
          custom_field_definition_type: definition.data_type,
          items: definition.options
        }
      : null;
  }

  return (
    <div className="ProductionFilters">
      <SearchField
        field={{
          key: 'status_id',
          label: 'Status',
          data_type: 'MULTI_SELECT',
          items: statuses.map(s => ({ value: s.id, name: s.name }))
        }}
        fieldKey="status_id"
        onUpdate={handleUpdate}
        query={query}
        placeholder="Add one or more statuses"
      />
      <Grid cellPadding="XS" align="spaced" gutters="s">
        <GridCell>
          <Label label="Hide cancelled productions">
            Hide cancelled productions
          </Label>
        </GridCell>
        <GridCell width="auto">
          {/* @ts-ignore - v1 component */}
          <Checkbox
            checked={isHidingCancelledProductions()}
            onChange={() => {
              if (isHidingCancelledProductions()) {
                handleUpdate('status_id', 'eq', null);
              } else {
                const filteredStatuses = statuses.length
                  ? statuses.filter(s => s.status_type !== 'CANCELLED')
                  : [];
                const statusIDs = filteredStatuses.length
                  ? filteredStatuses.map(s => s.id)
                  : [];
                handleUpdate('status_id', 'eq', statusIDs);
              }
            }}
          />
        </GridCell>
      </Grid>
      <SearchField
        field={{
          key: 'tags',
          label: 'Tags',
          data_type: 'TAG_SELECT',
          tagSuggestions: tagsList
        }}
        fieldKey="tags"
        onUpdate={handleUpdate}
        query={query}
        tags={tagsStore}
      />
      <SearchField
        field={{
          key: 'production_type_id',
          label: 'Production Type',
          data_type: 'MULTI_SELECT',
          items: productionTypeOptions
        }}
        fieldKey="production_type_id"
        onUpdate={handleUpdate}
        query={query}
        placeholder="Add one or more production types"
      />
      <SearchField
        field={{
          key: 'production_template_id',
          label: 'Production Template',
          data_type: 'SINGLE_SELECT',
          items: productionTemplateOptions
        }}
        fieldKey="production_template_id"
        onUpdate={handleUpdate}
        query={query}
      />
      <SearchField
        field={{
          key: 'budget_amount',
          label: 'Budget',
          data_type: 'NUMBER'
        }}
        fieldKey="budget_amount"
        onUpdate={handleUpdate}
        query={query}
      />
      <SearchField
        field={{
          key: '$location',
          label: 'Location',
          data_type: 'RESOURCE_SELECT'
        }}
        fieldKey="$location"
        onUpdate={handleUpdate}
        query={query}
      />
      <SearchField
        field={{
          key: 'user_id',
          label: 'Owner',
          data_type: 'SINGLE_SELECT',
          items: userOptions
        }}
        fieldKey="user_id"
        onUpdate={handleUpdate}
        query={query}
      />
      {productionCustomFields &&
        productionCustomFields
          .filter(field => field.type === 'CUSTOM_FIELD')
          .map((field, index) => {
            const obj = getCustomFieldObject(field.custom_field_definition_id);
            return obj ? (
              <SearchField
                key={index}
                field={obj}
                fieldKey={`custom_${field.custom_field_definition_id}`}
                onUpdate={handleUpdate}
                query={query}
                isCustom
              />
            ) : null;
          })}
    </div>
  );
};

ProductionFilters.propTypes = {
  query: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func // (query)
};

// ProductionFilters {};

export default ProductionFilters;
