import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyGeneric } from 'v1/components/shared';

import styles from './BookingsRoute.module.scss';

const BookingsRoute = () => {
  const { request } = useSelector(state => state.availabilities);
  const { companyName } = request;

  return (
    <section className={styles['BookingsRoute']}>
      <EmptyGeneric
        className={styles['BookingsRoute__emptyGeneric']}
        title={'Coming Soon'}
        icon={'/images/pancake_construction.png'}
        iconWidth={'50px'}
      />
      <h2 className={styles['BookingsRoute__description']}>
        View your active and previous bookings with {companyName}
      </h2>
      <h3 className={styles['BookingsRoute__details']}>
        Keep track of confirmed bookings and follow statuses across invoice and
        payments.
      </h3>
    </section>
  );
};
export default BookingsRoute;
