import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Grid, GridCell } from 'v1/components/shared';

const AutocompleteSelectItem = ({
  item,
  index,
  selectedItems,
  parentLabel,
  isSearchActive,
  onSelect
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSelected = selectedItems.find(s =>
    item.key ? s.key === item.key : s === item
  );

  useEffect(() => {
    setIsExpanded(isSearchActive);
  }, [isSearchActive]);

  return (
    <div
      className={classnames('AutocompleteSelectItem-wrapper', {
        'AutocompleteSelectItem-group': item.children
      })}
    >
      <div
        key={`${item.id}-${index}`}
        className={classnames('AutocompleteSelectItem', {
          AutocompleteSelectItem_disabled: isSelected
        })}
        onClick={
          // prettier-ignore
          item.children
            ? () => setIsExpanded(prev => !prev)
            : !isSelected
              ? () => onSelect({
                ...item,
                parentLabel 
              })
              : undefined
        }
      >
        <Grid cellPadding="S" className="Parent_hoverListener">
          <GridCell>
            <strong>{item.name}</strong>{' '}
            <span className="text-neutral text-small">
              {item.custom_field_definitions ? 'FIELD_GROUP' : item.data_type}
            </span>
          </GridCell>
          {item.children && (
            <GridCell width="auto">
              {isExpanded ? (
                <img
                  src="/images/icon_arrow_down_gray.svg"
                  className="flip"
                  alt=""
                />
              ) : (
                <img src="/images/icon_arrow_down_gray.svg" alt="" />
              )}
            </GridCell>
          )}
          {!item.children && (
            <GridCell className="Child_hoverListener" width="auto">
              {!isSelected && (
                <img
                  src="/images/icon_add_black.svg"
                  style={{ opacity: 0.52 }} // TODO: icons: temp fix for icon color
                  width="12px"
                  alt=""
                />
              )}
            </GridCell>
          )}
        </Grid>
      </div>
      {item.children &&
        isExpanded &&
        item.children.map((child, i) => (
          <AutocompleteSelectItem
            key={child.key || child}
            item={child}
            index={i}
            selectedItems={selectedItems}
            parentLabel={[...parentLabel, item.name]}
            isSearchActive={isSearchActive}
            onSelect={onSelect}
          />
        ))}
    </div>
  );
};

AutocompleteSelectItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // TODO: check if we still need to allow for strings
  index: PropTypes.number,
  selectedItems: PropTypes.array,
  parentLabel: PropTypes.array,
  isSearchActive: PropTypes.bool,
  onSelect: PropTypes.func
};

AutocompleteSelectItem.defaultProps = {
  parentLabel: []
};

export default AutocompleteSelectItem;
