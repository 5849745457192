import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import getEmbellishmentByColour from './Embellishment';

import styles from './OnboardingViewBase.module.scss';

const OnboardingViewBase = ({ primary, secondary, children }) => {
  const embellishmentPrimary = getEmbellishmentByColour(primary);
  const embellishmentSecondary = getEmbellishmentByColour(secondary);
  return (
    <div className={styles['OnboardingViewBase']}>
      {embellishmentPrimary && (
        <img
          src={embellishmentPrimary}
          className={classnames([
            styles['Embellishment'],
            styles['Embellishment--top-left']
          ])}
          alt=""
        />
      )}
      {embellishmentSecondary && (
        <img
          src={embellishmentSecondary}
          className={classnames([
            styles['Embellishment'],
            styles['Embellishment--bottom-right']
          ])}
          alt=""
        />
      )}
      {children}
    </div>
  );
};

OnboardingViewBase.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  children: PropTypes.any
};

export default OnboardingViewBase;
