import { AVAILABILITY_REQUEST_ACTIONS } from 'store/v1/availability_requests/availability_requests.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AVAILABILITY_REQUEST_ACTIONS.GET_AVAILABILITY_REQUESTS:
    case AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AVAILABILITY_REQUEST_ACTIONS.GET_AVAILABILITY_REQUESTS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        fetched: true
      };
    case AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case AVAILABILITY_REQUEST_ACTIONS.GET_AVAILABILITY_REQUESTS_FAILURE:
    case AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
