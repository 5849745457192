import React from 'react';
import { SaveStatus, SystemLink, SystemUser } from 'v1/components/shared';

import './PageHeader.scss';
import { ConnectedOwnerPicker } from '~/v4/pages/users/UserPicker.owner.connected';
import { systemUserClasses } from '~/v1/components/shared/byModel/User/SystemUser/systemUserPickerClasses';

const PageHeader = props => {
  const renderOwner = () => {
    const { user_id } = props.meta;

    if (user_id) {
      return (
        <div className="PageHeader-meta">
          <div className="PageHeader-meta-user">
            {props.onOwnerSelect ? (
              // todo disconnect: turn PageHeader into factory and create a new component with given configuration
              <ConnectedOwnerPicker
                onSelect={props.onOwnerSelect}
                tooltip={props.ownerTooltip}
              >
                <SystemUser
                  tabIndex={0}
                  id={user_id}
                  includeImage
                  className={systemUserClasses}
                />
              </ConnectedOwnerPicker>
            ) : (
              <SystemUser id={user_id} includeImage />
            )}
          </div>
        </div>
      );
    }
  };

  const {
    backLink,
    //backLabel,
    title,
    //subtitle,
    meta,
    children,
    updatingStatus,
    beta
  } = props;

  return (
    <div className="PageHeader">
      <div className="PageHeader-mainTitle">
        {backLink && (
          <SystemLink to={backLink} className="btn btn-link btn-square">
            <img
              src="/images/icon_arrow_right_gray.svg"
              className="flip"
              width="10px"
              alt=""
            />
          </SystemLink>
        )}
        <div className="PageHeader-mainTitle-title">
          <h3 className="trunc" title={title}>
            {title}
            {beta && <span className="PageHeader-beta">Beta</span>}
          </h3>
          {updatingStatus && (
            <SaveStatus
              loading={updatingStatus.isUpdating}
              updated={updatingStatus.updated}
            />
          )}
        </div>
      </div>
      {meta && renderOwner()}
      <div className="PageHeader-buttons text-right">{children}</div>
    </div>
  );
};

export default PageHeader;
