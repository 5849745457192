import React, { Component } from 'react';
//import { Button } from 'v1/components/shared';

import './DevelopmentDisclaimerModal.scss';

export default class DevelopmentDisclaimerModal extends Component {
  render() {
    return (
      <div className="DevelopmentDisclaimerModal MiniModal">
        <span className="PageModal-close" onClick={this.props.onRequestClose}>
          <img src="/images/icon_cross_black.svg" width="12px" alt="" />
        </span>
        <div className="Modal-content">
          <h1>🛠</h1>
          <h2>Sandbox environment</h2>
          <br />
          <h3>
            You can use this sandbox environment to experiment with new features
            safe in the knowledge that the data is completely isolated from your
            production account.
          </h3>
          <br />
          <h3>
            We typically release new features to this environment{' '}
            <span style={{ whiteSpace: 'nowrap' }}>1&ndash;2</span> weeks before
            making them live on your production account. This gives you the
            chance to familiarise yourself with the updates before they become
            available to all of your users.
          </h3>
          <br />
          <h3>
            If you have any feedback or spot any issues when using this
            environment, please let us know.
          </h3>
          <a
            href="https://atellio.atlassian.net/servicedesk/customer/portals"
            className="btn btn-primary withMargin"
            target="_blank"
            rel="noreferrer"
          >
            Send feedback
          </a>
        </div>
      </div>
    );
  }
}
