import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BookingConflictModal from 'v1/components/modals/BookingModals/BookingConflictModal/BookingConflictModal';
import BookingTypeCreateModal from 'v1/components/modals/BookingModals/BookingTypeCreateModal/BookingTypeCreateModal';
import ResourceEventsModal from 'v1/components/modals/BookingModals/ResourceEventsModal/ResourceEventsModal';
import ApplicantSubmittedModal from 'v1/components/modals/CalloutModals/ApplicantSubmittedModal/ApplicantSubmittedModal';
import CalloutCreateModal from 'v1/components/modals/CalloutModals/CalloutCreateModal/CalloutCreateModal';
import ApplicantReviewModal from 'v1/components/modals/CalloutModals/ApplicantReviewModal/ApplicantReviewModal';
import CallsheetAddRecipientModal from 'v1/components/modals/CallsheetModals/CallsheetAddRecipientModal/CallsheetAddRecipientModal';
import CallsheetCreateModal from 'v1/components/modals/CallsheetModals/CallsheetCreateModal/CallsheetCreateModal';
import CallsheetDuplicateModal from 'v1/components/modals/CallsheetModals/CallsheetDuplicateModal/CallsheetDuplicateModal';
import CallsheetTeamNoteModal from 'v1/components/modals/CallsheetModals/CallsheetTeamNoteModal/CallsheetTeamNoteModal';
import FieldAddModal from 'v1/components/modals/CustomFieldModals/FieldAddModal/FieldAddModal';
import FieldCreateModal from 'v1/components/modals/CustomFieldModals/FieldCreateModal/FieldCreateModal';
import FieldGroupCreateModal from 'v1/components/modals/CustomFieldModals/FieldGroupCreateModal/FieldGroupCreateModal';
import ExpenseCreateModal from 'v1/components/modals/ExpenseModals/ExpenseCreateModal/ExpenseCreateModal';
import ExpenseReceiptsModal from 'v1/components/modals/ExpenseModals/ExpenseReceiptsModal/ExpenseReceiptsModal';
import ExpenseTypeCreateModal from 'v1/components/modals/ExpenseModals/ExpenseTypeCreateModal/ExpenseTypeCreateModal';
import DataExportsModal from 'v1/components/modals/ExportModals/DataExportsModal/DataExportsModal';
import AlertModal from 'v1/components/modals/GeneralModals/AlertModal/AlertModal';
import ConfirmModal from 'v1/components/modals/GeneralModals/ConfirmModal/ConfirmModal';
import ConfirmationModal from 'v1/components/modals/GeneralModals/ConfirmationModal/ConfirmationModal';
import SuccessFeedbackModal from 'v1/components/modals/GeneralModals/SuccessFeedbackModal/SuccessFeedbackModal';
import DevelopmentDisclaimerModal from 'v1/components/modals/GeneralModals/DevelopmentDisclaimerModal/DevelopmentDisclaimerModal';
import TutorialModal from 'v1/components/modals/GeneralModals/TutorialModal/TutorialModal';
import MediaFocusModal from 'v1/components/modals/MediaModals/MediaFocusModal/MediaFocusModal';
import BudgetSettingsModal from 'v1/components/modals/ProductionModals/BudgetSettingsModal/BudgetSettingsModal';
import EventModal from 'v1/components/modals/ProductionModals/EventModal/EventModal';
import ProductionConflictsModal from 'v1/components/modals/ProductionModals/ProductionConflictsModal/ProductionConflictsModal';
import ProductionMoveModal from 'v1/components/modals/ProductionModals/ProductionMoveModal/ProductionMoveModal';
import ProductionSetUpModal from 'v1/components/modals/ProductionModals/ProductionSetUpModal/ProductionSetUpModal';
import ProductionTemplateCreateModal from 'v1/components/modals/ProductionModals/ProductionTemplateCreateModal/ProductionTemplateCreateModal';
import ProductionTypeCreateModal from 'v1/components/modals/ProductionModals/ProductionTypeCreateModal/ProductionTypeCreateModal';
import ProductionGroupModal from 'v1/components/modals/ProductionModals/ProductionGroupModal/ProductionGroupModal';
import RateCreateModal from 'v1/components/modals/RateModals/RateCreateModal/RateCreateModal';
import AddFromBlackbookModal from 'v1/components/modals/ResourceModals/AddFromBlackbookModal/AddFromBlackbookModal';
import RequestAvailabilityModal from 'v1/components/modals/ResourceModals/RequestAvailabilityModal/RequestAvailabilityModal';
import BatchEditModal from 'v1/components/modals/ResourceModals/BatchEditModal/BatchEditModal';
import MergeResourcesModal from 'v1/components/modals/ResourceModals/MergeResourcesModal/MergeResourcesModal';
import ResourceCreateModal from 'v1/components/modals/ResourceModals/ResourceCreateModal/ResourceCreateModal';
import ResourceTypeCreateModal from 'v1/components/modals/ResourceModals/ResourceTypeCreateModal/ResourceTypeCreateModal';
import ResourceTypeGroupsModal from 'v1/components/modals/ResourceModals/ResourceTypeGroupsModal/ResourceTypeGroupsModal';
import SetGuestNameModal from 'v1/components/modals/ShortlistModals/SetGuestNameModal/SetGuestNameModal';
import ShortlistCreateModal from 'v1/components/modals/ShortlistModals/ShortlistCreateModal/ShortlistCreateModal';
import ShortlistMediaModal from 'v1/components/modals/ShortlistModals/ShortlistMediaModal/ShortlistMediaModal';
import ShortlistPublishModal from 'v1/components/modals/ShortlistModals/ShortlistPublishModal/ShortlistPublishModal';
import SlotCategoryCreateModal from 'v1/components/modals/SlotCategoryModals/SlotCategoryCreateModal/SlotCategoryCreateModal';
import StatusModal from 'v1/components/modals/StatusModals/StatusModal/StatusModal';
import TagCreateModal from 'v1/components/modals/TagModals/TagCreateModal/TagCreateModal';
import { ShareCallsheetModal } from 'v1/components/modals/CallsheetModals/ShareCallsheetModal/ShareCallsheetModal';
import ShortlistUpdatedWarningModal from 'v1/components/modals/ShortlistModals/ShortlistUpdatedWarningModal/ShortlistUpdatedWarningModal';

import Modal from 'react-modal';
import { closeModal } from 'store/v1/ui/ui.actions.js';

import './Modals.scss';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(20, 13, 92, 0.5)',
    zIndex: 99
  },
  content: {
    position: 'relative',
    top: null,
    left: null,
    right: null,
    bottom: null,
    background: '#12121',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    maxWidth: null,
    padding: '0px 15px',
    border: 'none',
    borderRadius: 'none',
    margin: '0 auto',
    height: '100%',
    width: '100%',
    zIndex: 99,
    display: 'block'
  }
};

class ModalHandler extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.location.pathname !== nextProps.location.pathname &&
      this.props.ui.modalIsOpen
    ) {
      this.props.closeModal();
    }
  }

  render() {
    let openStatus = this.props.ui.modalIsOpen;
    const currentModal = this.props.ui.currentModal;
    const theme = customStyles;

    const modals = {
      ShortlistCreateModal,
      AlertModal,
      ConfirmModal,
      ConfirmationModal,
      SuccessFeedbackModal,
      CallsheetDuplicateModal,
      ProductionSetUpModal,
      BatchEditModal,
      CalloutCreateModal,
      ApplicantReviewModal,
      ResourceCreateModal,
      ApplicantSubmittedModal,
      ResourceTypeGroupsModal,
      ShortlistMediaModal,
      AddFromBlackbookModal,
      RequestAvailabilityModal,
      MediaFocusModal,
      CallsheetCreateModal,
      CallsheetTeamNoteModal,
      ShortlistPublishModal,
      CallsheetAddRecipientModal,
      TutorialModal,
      TagCreateModal,
      FieldCreateModal,
      FieldGroupCreateModal,
      FieldAddModal,
      RateCreateModal,
      ResourceTypeCreateModal,
      ProductionTypeCreateModal,
      ResourceEventsModal,
      SetGuestNameModal,
      BudgetSettingsModal,
      DevelopmentDisclaimerModal,
      MergeResourcesModal,
      BookingTypeCreateModal,
      EventModal,
      ExpenseTypeCreateModal,
      ProductionTemplateCreateModal,
      ProductionConflictsModal,
      ProductionMoveModal,
      StatusModal,
      ExpenseCreateModal,
      SlotCategoryCreateModal,
      ExpenseReceiptsModal,
      DataExportsModal,
      BookingConflictModal,
      ProductionGroupModal,
      ShareCallsheetModal,
      ShortlistUpdatedWarningModal
    };
    const CurrentModal = modals[currentModal];
    if (!CurrentModal) openStatus = false;

    return (
      <Modal
        className="legacy-style-root"
        isOpen={openStatus}
        style={
          CurrentModal?.noOverflow
            ? {
                ...theme,
                content: {
                  ...theme.content,
                  overflowY: 'visible'
                }
              }
            : theme
        }
        onRequestClose={this.props.closeModal}
        closeTimeoutMS={400}
        contentLabel=""
        ariaHideApp={false}
      >
        {openStatus ? (
          <CurrentModal onRequestClose={this.props.closeModal} />
        ) : null}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalHandler)
);
