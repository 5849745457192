//import PropTypes from 'prop-types';
import React from 'react';

import './ModalContent.scss';

const ModalContent = ({ children }) => {
  return <div className="ModalContent">{children}</div>;
};

ModalContent.propTypes = {};

export default ModalContent;
