import { UI_ACTIONS } from 'store/v1/ui/ui.constants.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';

const INITIAL_STATE = {
  modalIsOpen: false,
  walkthroughIsOpen: false,
  currentModal: null,
  currentWalkthrough: null,
  modalCloseAction: null,
  data: {},
  alert: {},
  sidebarCollapsed: false,
  showContactSidebar: false,
  showProductionSidebar: false,
  showBookingSidebar: false,
  selectedContact: {},
  selectedProduction: {},
  selectedBooking: {}
};

export default (state = INITIAL_STATE, action) => {
  if (action.type.endsWith('/rejected')) {
    return {
      ...state,
      alert: {
        alertType: 'error',
        body: action.error || 'Something went wrong',
        id: action.meta.requestId
      }
    };
  }

  switch (action.type) {
    case UI_ACTIONS.OPEN_MODAL:
      return {
        ...state,
        modalIsOpen: true,
        currentModal: action.modalType,
        data: action.data,
        modalCloseAction: action.modalCloseAction
      };
    case UI_ACTIONS.OPEN_WALKTHROUGH:
      return {
        ...state,
        walkthroughIsOpen: true,
        currentWalkthrough: action.section
      };
    case UI_ACTIONS.CLOSE_WALKTHROUGH:
      return {
        ...state,
        walkthroughIsOpen: false,
        currentWalkthrough: null
      };
    case UI_ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        modalIsOpen: false,
        walkthroughIsOpen: false,
        currentModal: null,
        currentWalkthrough: null,
        modalCloseAction: null,
        data: {}
      };
    case UI_ACTIONS.DISPLAY_ALERT:
      return {
        ...state,
        alert: action.alert
      };
    case UI_ACTIONS.HIDE_ALERT:
      return {
        ...state,
        alert: {}
      };
    case UI_ACTIONS.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarCollapsed: action.sidebarCollapsed
      };
    case UI_ACTIONS.OPEN_CONTACT_SIDEBAR:
      return {
        ...state,
        showContactSidebar: true,
        // showProductionSidebar: false,
        selectedContact: action.selectedContact
      };
    case UI_ACTIONS.CLOSE_CONTACT_SIDEBAR:
      return {
        ...state,
        showContactSidebar: false,
        selectedContact: {}
      };
    case UI_ACTIONS.OPEN_PRODUCTION_SIDEBAR:
      return {
        ...state,
        showProductionSidebar: true,
        // showContactSidebar: false,
        selectedProduction: action.selectedProduction,
        productionSidebarOptions: action.options
      };
    case UI_ACTIONS.OPEN_BOOKING_SIDEBAR:
      return {
        ...state,
        showBookingSidebar: true,
        showContactSidebar: false,
        selectedBooking: action.selectedBooking,
        bookingSidebarOptions: action.options
      };
    case UI_ACTIONS.CLOSE_BOOKING_SIDEBAR:
    case UI_ACTIONS.CLOSE_PRODUCTION_SIDEBAR:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_SUCCESS:
      return {
        ...state,
        showProductionSidebar: false,
        showBookingSidebar: false,
        selectedProduction: {},
        selectedBooking: {},
        productionSidebarOptions: {},
        bookingSidebarOptions: {}
      };
    default:
      return state;
  }
};
