import { useEffect, useState } from 'react';
import AuthenticationAPI from 'lib/auth/authentication';

/**
 * Fetch tenant and their enabled authentication methods
 * @param [initialState=null]
 * @returns {Object}
 */
const useFetchAuthMethods = (initialState = null) => {
  const [methods, setMethods] = useState(initialState);

  useEffect(() => {
    async function fetch() {
      try {
        const methods = await AuthenticationAPI.getTenantAuthMethods();
        setMethods(methods);
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, []);

  return methods;
};

export default useFetchAuthMethods;
