import { isEqual, omit } from 'lodash';
import { Booking } from '../../../__types__';
import {
  BatchingImpossibleError,
  createBatcher,
  withFallback
} from '../common/batcher';
import { ListQuery, ListResponse } from '../common/common.crud.types';

export function createBookingsListBatcher(
  listQueryApi: (
    query: ListQuery<Booking>,
    signal: AbortSignal
  ) => Promise<ListResponse<Booking, ListQuery<Booking>>>
) {
  const batcher = createBatcher<
    ListQuery<Booking>, // single query is a list query
    ListResponse<Booking, ListQuery<Booking>>, // batch response is a list of bookings
    ListResponse<Booking, ListQuery<Booking>> // single response is a filtered list of bookings
  >(
    async (queries, signal) => {
      const batchableQueryParams = queries.map(query => {
        const queryWithoutId = {
          ...query,
          filters: {
            ...query.filters,
            contact_id: omit(query.filters?.contact_id, 'eq')
          }
        };
        return queryWithoutId;
      });

      const allBatchableQueryParamsAreEqual = batchableQueryParams.every(
        (query, index, array) => isEqual(query, array[0])
      );
      if (allBatchableQueryParamsAreEqual) {
        // collect ids
        const ids = queries.map(query => query.filters?.contact_id?.eq).flat();

        return await listQueryApi(
          {
            ...batchableQueryParams[0],
            filters: {
              ...batchableQueryParams[0].filters,
              contact_id: {
                eq: ids
              }
            }
          },
          signal
        );
      } else {
        throw new BatchingImpossibleError(
          'Not all batchable queries are equal'
        );
      }
    },
    (result, payload) => {
      const forContact = payload.filters?.contact_id?.eq;
      const ids = Array.isArray(forContact) ? forContact : [forContact];

      const data = result.results.filter(item => ids.includes(item.contact_id));

      return {
        query: payload,
        paging: result.paging, // todo this does not make sense but sure it's a hack
        results: data
      };
    }
  );

  return withFallback(batcher, ({ payload, signal }) => {
    return listQueryApi(payload, signal);
  });
}
