import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-form';
import { get } from 'lodash';
import { v4 } from 'uuid';

import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { TextAreaField } from 'v1/components/shared';
import { PressStud } from 'v1/components/shared';

const NotesForm = ({ entityId, events, createNote, displayAlert }) => {
  const [formApi, setFormApi] = useState();
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const status = get(events, event);

  useEffect(() => {
    if (!status) return;
    if (status === EVENT_ACTIONS.SUCCESS) {
      displayAlert('success', 'Updated');
      formApi.resetAll();
      setIsLoading(false);
    } else if (status !== EVENT_ACTIONS.PROCESSING) {
      displayAlert('error', status.message);
    }
  }, [status, formApi, displayAlert]);

  const handleSubmit = data => {
    const eventId = v4();
    setIsLoading(true);
    setEvent(eventId);
    createNote(data, entityId, eventId);
  };

  return (
    <div className="Notes-form">
      <div className="Notes-form-noteInput">
        <Form onSubmit={handleSubmit} getApi={api => setFormApi(api)}>
          {formApi => (
            <form onSubmit={formApi.submitForm}>
              <TextAreaField
                field="note"
                id="note"
                placeholder="Write a note"
                rows="3"
                initialFocus
              />
              {formApi.values.note && (
                <PressStud
                  label="Add Note"
                  appearance="primary"
                  className="Notes-form-noteInput-submit" // TODO
                  isLoading={isLoading}
                  action="submit"
                />
              )}
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

NotesForm.propTypes = {
  entityId: PropTypes.number,
  events: PropTypes.object,
  createNote: PropTypes.func,
  displayAlert: PropTypes.func
};

export default NotesForm;
