import { pick } from 'lodash';
import moment from 'moment-timezone';
import { Callsheet, CallsheetRecipient } from '../../../__types__';

export function getCreatePayload(data: any) {
  const teamMembers =
    data.team_members &&
    data.team_members.map(team_member => {
      let groupId = team_member.group_id;
      if (team_member.group_id && team_member.group_id.includes('ref-')) {
        groupId = {
          '#ref': `group-${team_member.group_id.replace('ref-', '')}`
        };
        team_member.group = groupId;
        delete team_member.group_id;
      }
      if (team_member.id.includes('temp')) {
        delete team_member.id;
      }
      return {
        ...team_member,
        ...{ recipient: { contact_id: team_member.contact_id } }
      };
    });
  const groups =
    data.groups &&
    data.groups.map(group => {
      let groupId;
      if (group.id.includes('ref')) {
        groupId = group.id.replace('ref-', '');
        delete group.id;
      }
      return {
        ...group,
        '#id': `group-${groupId}`
      };
    });
  const externalRecipients: string[] = [];
  data.recipients &&
    data.recipients.map(recipient => {
      if (!recipient.contact_id) {
        return externalRecipients.push(
          pick(recipient, ['alt_email_address']) as unknown as string
        );
      }
    });
  const callsheet = {
    ...data,
    team_members: teamMembers,
    groups: groups,
    recipients: externalRecipients,
    isClone: data.isClone || false
  };

  callsheet.from_date = callsheet.from_date.includes('T')
    ? callsheet.from_date.split('T')[0]
    : callsheet.from_date;

  callsheet.to_date =
    callsheet.to_date && callsheet.to_date.includes('T')
      ? callsheet.to_date.split('T')[0]
      : callsheet.to_date;
  return callsheet as Callsheet; // todo add converter types or simplify the app
}

export function getUpdatePayload(data, id) {
  const { team_members, groups } = data;

  function pickTeamMemberData(team_member) {
    return pick(team_member, [
      'call_time',
      'callsheet_id',
      'contact_details_visible',
      'contact_id',
      'display_email',
      'display_name',
      'display_phone_number',
      'display_profile_picture',
      'display_role',
      'display_note',
      'group_id',
      'id',
      'note',
      'order'
    ]);
  }

  function cleanContact(contact) {
    return pick(contact, ['id', 'role', 'email', 'full_name', 'phone_number']);
  }

  function formatTeamMember(team_member) {
    const momentCallTime = moment(team_member.call_time);
    return {
      ...team_member,
      call_time: momentCallTime.isValid() // TODO: Sanity call_time check to comply with Atellio 1.2.0 new call_time format
        ? momentCallTime.format('HH:mm:ss')
        : team_member.call_time,
      recipient: team_member.id
        ? team_member.recipient
        : {
            contact_id: team_member.contact_id,
            callsheet_id: id
          }
    };
  }

  function pickCallsheetData(callsheet) {
    const formatGroups =
      groups &&
      groups.map(group => {
        if (typeof group.id === 'string' && group.id.includes('ref-')) {
          delete group.id;
        }
        return group;
      });

    callsheet.groups = formatGroups;

    // We need to make sure that the from and to dates do not contain the time component
    // This is an issue with how the database formats and returns the field
    // and this needs to be rectified in v2

    callsheet.from_date = callsheet.from_date.includes('T')
      ? callsheet.from_date.split('T')[0]
      : callsheet.from_date;

    callsheet.to_date =
      callsheet.to_date && callsheet.to_date.includes('T')
        ? callsheet.to_date.split('T')[0]
        : callsheet.to_date;

    const filteredCallsheet = pick(callsheet, [
      'attachments',
      'description',
      'groups',
      'locations',
      'note',
      'production_date',
      'call_time',
      'from_date',
      'to_date',
      'published',
      'schedule',
      'status',
      'timezone',
      'title',
      'billing',
      'social',
      'hard_drive',
      'client_logo',
      'user_id'
    ]);

    return {
      ...filteredCallsheet,
      points_of_contact: callsheet.points_of_contact?.map(cleanContact) ?? []
    };
  }

  const payload = {
    ...pickCallsheetData(data),
    team_members: team_members
      ? team_members.map(pickTeamMemberData).map(formatTeamMember)
      : null
  };
  return payload;
}

export function getUpdateRecipientsPayload(recipients: CallsheetRecipient[]) {
  function pickRecipientData(recipient) {
    return pick(recipient, [
      'alt_email_address',
      'alt_name',
      'callsheet_id',
      'contact_id',
      'id'
    ]);
  }

  const payload = {
    recipients: recipients ? recipients.map(pickRecipientData) : null
  };
  return payload;
}
