import React from 'react';
import PropTypes from 'prop-types';

import { BookingCostsSummary, Flyover } from 'v1/components/shared';

const BookingCostsFlyover = ({
  className,
  expenseId,
  bookingId,
  slot,
  children
}) => {
  return (
    <Flyover>
      <Flyover.Trigger className={className}>{children}</Flyover.Trigger>
      <Flyover.Content
        width="trigger"
        clearance="flush"
        // TODO: forcePosition
      >
        {({ closeFlyover }) => (
          <BookingCostsSummary
            expenseId={expenseId}
            bookingId={bookingId}
            slot={slot}
            onAddBookingExpense={closeFlyover}
          />
        )}
      </Flyover.Content>
    </Flyover>
  );
};

BookingCostsFlyover.propTypes = {
  className: PropTypes.string,
  expenseId: PropTypes.number,
  bookingId: PropTypes.number,
  slot: PropTypes.object,
  children: PropTypes.any
};

export default BookingCostsFlyover;
