import React from 'react';

import { Form, Field } from 'react-form';

import {
  StackedFormSection,
  Grid,
  GridCell,
  Label,
  TextAreaField,
  Fieldset,
  Legend,
  SingleDateInput
} from 'v1/components/shared';

import moment from 'moment';

// TODO: Date inputs are one step behind

const RequestAvailabilityDetailsForm = ({ request, onChange }) => {
  return (
    <div className="RequestAvailabilityModal">
      <Form
        defaultValues={request}
        onChange={formApi => onChange(formApi.values)}
        validateOnSubmit
      >
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <Fieldset>
              <Legend className="form-legend">
                <h3>Enter Details</h3>
              </Legend>
              <StackedFormSection
                title="Requested dates"
                description="Resources will be guided to fill in availability between these dates"
              >
                <Grid gutters="S">
                  <GridCell>
                    <Label>From</Label>
                    <Field field="startTimestampUTC">
                      {fieldApi => (
                        <SingleDateInput
                          placeholder="Select start date"
                          dateFormat="eee dd MMM"
                          date={fieldApi.value ? fieldApi.value : ''}
                          className="emulate-input emulate-input_outline emulate-input_M"
                          startDate={formApi.values.startTimestampUTC}
                          endDate={formApi.values.endTimestampUTC}
                          onChange={({ date }) => {
                            if (
                              moment(date).diff(
                                formApi.values.endTimestampUTC,
                                'days'
                              ) > 0
                            ) {
                              formApi.setValue('endTimestampUTC', date);
                            }
                            fieldApi.setValue(date);
                          }}
                          selectsStart
                        />
                      )}
                    </Field>
                  </GridCell>
                  <GridCell>
                    <Label>To</Label>
                    <Field field="endTimestampUTC">
                      {fieldApi => (
                        <SingleDateInput
                          placeholder="Select end date"
                          dateFormat="eee dd MMM"
                          date={fieldApi.value ? fieldApi.value : ''}
                          className="emulate-input emulate-input_outline emulate-input_M"
                          startDate={formApi.values.startTimestampUTC}
                          endDate={formApi.values.endTimestampUTC}
                          minDate={moment(
                            formApi.values.startTimestampUTC
                          ).toDate()}
                          onChange={({ date }) => {
                            fieldApi.setValue(date);
                          }}
                          selectsEnd
                        />
                      )}
                    </Field>
                  </GridCell>
                </Grid>
              </StackedFormSection>
              <StackedFormSection
                title="Add message"
                description="Write a message that will be attached within the email"
              >
                <TextAreaField field="message" placeholder="Start writing..." />
              </StackedFormSection>
            </Fieldset>
          </form>
        )}
      </Form>
    </div>
  );
};

export default RequestAvailabilityDetailsForm;
