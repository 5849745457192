import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
//import { Button } from 'v1/components/shared';
import useEvent from 'v1/helpers/hooks/useEvent';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';

import { PublicLinkStatus } from 'v1/components/shared';
import classnames from 'classnames';
import Toggle from 'react-toggle';
import './PublishingActionBox.scss';

const PublishingActionBox = props => {
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.shortlists) || {};

  // const [loaded, setLoaded] = useState(false);

  // TODO: Remove: ?
  // Fetch public shortlist as it has shortlist.user
  // useEffect(() => {
  //   props.shortlist &&
  //     dispatch(
  //       getPublicShortlist(
  //         props.shortlist.public_id,
  //         props.shortlist.private_password
  //       )
  //     ); // NOTE: Have to send the password if the shortlist has one otherwise we don't get all the fields for the PDF generator
  // }, []);

  useEvent(SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST, {
    onSuccess: () => {}, //setLoaded(true),
    onFailure: () => dispatch(displayAlert('error', error.message))
  });

  const renderURL = activeState => {
    const { url, destination, isPublished } = props;
    return (
      <div className="PublishingActionBox-publishedLink">
        <PublicLinkStatus
          className="stack-XS"
          url={url}
          destination={destination}
          status={{
            active: isPublished
          }}
        />
        {/* TODO: Remove: ? */}
        {/* <Button
									onClick={activeState.action}
									className="PublishingActionBox-unpublishButton"
								>
									Unpublish
								</Button> */}

        {/* props.shortlist && loaded && (
          <ShortlistPdfButton id={props.shortlist.id} />
        ) */}
      </div>
    );
  };

  // TODO: Remove: ?
  const renderButton = activeState => {
    //const { updating } = props;
    return;
    /*return (
      <Button
        className="btn btn-primary btn-fill"
        onClick={activeState.action}
        isLoading={updating}
      >
        Publish
      </Button>
    );*/
  };

  const { isPublished, publishedState, unpublishedState } = props;
  const activeState = isPublished ? publishedState : unpublishedState;

  return (
    <div className="PublishingActionBox">
      <div className="PublishingActionBox-header">
        <span
          className={classnames({ published: isPublished }, [
            'PublishingActionBox-icon'
          ])}
        >
          <img
            className="icon"
            width="15px"
            alt=""
            src={
              isPublished
                ? '/images/icon_tick_white.svg'
                : '/images/icon_draft.svg'
            }
          />
        </span>
        <div className="PublishingActionBox-header-toggle">
          <span className="PublishingActionBox-header-toggle-label">
            Published
          </span>
          <span className="PublishingActionBox-header-toggle-btn">
            <Toggle
              defaultChecked={isPublished}
              onChange={e => {
                //const checked = e.target.checked;
                activeState.action();
              }}
              icons={false}
            />
          </span>
        </div>
      </div>
      <h3>{activeState.title}</h3>
      <p>{activeState.description}</p>
      {isPublished ? renderURL(activeState) : renderButton(activeState)}
    </div>
  );
};

const activeStateShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.func
});

PublishingActionBox.propTypes = {
  url: PropTypes.string, // TODO: as passed to PublicLinkStatus
  destination: PropTypes.string, // TODO: as passed to PublicLinkStatus
  isPublished: PropTypes.bool,
  updating: PropTypes.bool,
  publishedState: activeStateShape, // TODO: if isPublished then publishedState is required
  unpublishedState: activeStateShape, // TODO: if !isPublished then unpublishedState is required
  shortlist: PropTypes.any
};

export default PublishingActionBox;
