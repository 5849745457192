import React from 'react';
import PropTypes from 'prop-types';
import UsernamePassword from './UsernamePassword';
import RedirectSAML from './RedirectSAML';
import RedirectAuth0 from './RedirectAuth0';
import RedirectGoogle from './RedirectGoogle';
import LineSeparator from './LineSeparator';

import { ErrorMessage } from 'v1/components/shared';

const LoginMethods = ({ organisation, methods, className }) => {
  const activeMethods = methods.filter(({ enabled }) => enabled);
  const isSAML = activeMethods.some(({ id }) => id === 'saml');
  const isAuth0 = activeMethods.some(({ id }) => id === 'auth0');
  const isGoogle = activeMethods.some(({ id }) => id === 'google');
  const isLocal = activeMethods.some(({ id }) => id === 'local');
  return (
    <div className={className}>
      {isSAML && <RedirectSAML organisation={organisation} />}
      {isAuth0 && <RedirectAuth0 />}
      {isGoogle && <RedirectGoogle />}
      {isLocal && activeMethods.length > 1 ? (
        <LineSeparator label="or" />
      ) : null}
      {isLocal && <UsernamePassword />}
      {activeMethods.length === 0 ? (
        <ErrorMessage>Opps! There are no configured login methods</ErrorMessage>
      ) : null}
    </div>
  );
};

LoginMethods.defaultProps = {
  methods: []
};

LoginMethods.propTypes = {
  methods: PropTypes.array.isRequired,
  organisation: PropTypes.string,
  className: PropTypes.string
};

export default LoginMethods;
