import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get } from 'lodash';

import {
  CellBookingItem,
  CellProductionItem,
  ErrorMessage,
  Grid,
  GridCell,
  Label,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud,
  RateInput,
  SlotCategoryDropdown,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import {
  createExpense,
  updateExpense
} from 'store/v1/expenses/expenses.actions.js';

import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { EXPENSES_ACTIONS } from 'store/v1/expenses/expenses.constants.js';

import useEvent from 'v1/helpers/hooks/useEvent';
import { validate as validator } from 'v1/helpers/validation';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { EntityType } from '__types__';

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const ExpenseCreateModal = () => {
  // REDUX
  const ui = useSelector(state => state.ui);
  const auth = useSelector(state => state.auth);

  const {
    expense = {},
    create,
    production = {},
    booking = {}
  } = get(ui, 'data', {});

  const expenses = useSelector(state => state.expenses);

  const { loading, error } = expenses || {};

  const dispatch = useDispatch();

  // DEFAULTS
  const defaultCurrency = get(auth, [
    'settings',
    'settings',
    'defaults_currency',
    'value'
  ]);

  // PROPS AND REFS
  const {
    id,
    commit_vendor, // TBD in API
    commit_rate_amount,
    commit_rate_currency,
    commit_rate_quantity,
    commit_rate_interval,
    category_id,
    production_budget_expense_id,
    booking_id,
    slot_category_id
  } = expense;
  const budget_expenses = get(production, 'budget_expenses', []);

  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent([EXPENSES_ACTIONS.CREATE_EXPENSE, EXPENSES_ACTIONS.UPDATE_EXPENSE], {
    onSuccess: () => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Updated succesfully'));
    },
    onFailure: () => dispatch(displayAlert('error', error.message))
  });

  // METHODS
  function handleSubmit(values) {
    create
      ? dispatch(createExpense(values))
      : dispatch(updateExpense(id, values));
  }

  return (
    <ModalWrapper size="M">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} expense`} />
      <ModalContent>
        <ModalScrollable padding="XL">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              id,
              commit_vendor,
              commit_rate_amount,
              commit_rate_currency,
              commit_rate_quantity: commit_rate_quantity || 1,
              commit_rate_interval,
              production_budget_expense_id,
              category_id,
              booking_id: booking_id || booking.id,
              slot_category_id
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form data-cy="expense-create-form" onSubmit={formApi.submitForm}>
                <Grid>
                  <GridCell>
                    <Field
                      field="slot_category_id"
                      validate={value => handleValidate(value, 'required')}
                    >
                      {({ fieldName: name, value, error, setValue }) => (
                        <div className="stack-M">
                          <Label htmlFor={name}>Expense Category</Label>
                          <SlotCategoryDropdown
                            type="EXPENSE"
                            onSelect={(field, value) => {
                              formApi.setValue(field, value);
                              const budgetExpense = find(
                                budget_expenses,
                                b_e => b_e.slot_category_id === value
                              );
                              if (budgetExpense) {
                                formApi.setValue(
                                  'production_budget_expense_id',
                                  budgetExpense.id
                                );
                              }
                            }}
                            slot={formApi.values}
                            buttonClass="btn btn-default btn-fill"
                          />
                          {error && (
                            <ErrorMessage>This field is required</ErrorMessage>
                          )}
                        </div>
                      )}
                    </Field>
                  </GridCell>
                </Grid>
                <Grid className="stack-M">
                  <GridCell>
                    <Field
                      field="commit_vendor"
                      validate={value => handleValidate(value, 'required')}
                    >
                      {({ fieldName: name, value, error, setValue }) => (
                        <div>
                          <Label htmlFor={name}>Expense Name</Label>
                          <TextInput
                            name={name}
                            value={value}
                            placeholder="Name"
                            onChange={({ target }) => setValue(target.value)}
                            validity={error ? 'invalid' : null}
                            required
                            autoComplete="off"
                          />
                          {error && (
                            <ErrorMessage>This field is required</ErrorMessage>
                          )}
                        </div>
                      )}
                    </Field>
                  </GridCell>
                </Grid>
                <Grid gutters="S" className="stack-M">
                  <GridCell width="2/3">
                    <div className="stack-M">
                      <Label>Rate</Label>
                      <RateInput
                        placeholder="Rate"
                        defaultCurrency={defaultCurrency}
                        entityType={EntityType.EXPENSE}
                        value={{
                          currency: formApi.getValue('commit_rate_currency'),
                          amount: formApi.getValue('commit_rate_amount'),
                          interval: formApi.getValue('commit_rate_interval')
                        }}
                        onBlur={val => {
                          formApi.setValue(
                            'commit_rate_currency',
                            val.currency
                          );
                          formApi.setValue('commit_rate_amount', val.amount);
                          formApi.setValue(
                            'commit_rate_interval',
                            val.interval
                          );
                        }}
                      />
                    </div>
                  </GridCell>
                  <GridCell>
                    <Field
                      field="commit_rate_quantity"
                      validate={value => handleValidate(value, 'required')}
                    >
                      {({ fieldName: name, value, error, setValue }) => (
                        <Fragment>
                          <Label htmlFor={name}>Quantity</Label>
                          <TextInput
                            field="commit_rate_quantity"
                            value={value != null ? value : ''}
                            placeholder="Qty"
                            type="number"
                            min={0}
                            onChange={({ target }) =>
                              setValue(parseFloat(target.value))
                            }
                          />
                        </Fragment>
                      )}
                    </Field>
                  </GridCell>
                </Grid>
                {!isEmpty(production) && (
                  <Grid className="stack-M">
                    <GridCell>
                      <Label>Attached Production</Label>
                      <CellProductionItem production={production} hideStatus />
                    </GridCell>
                  </Grid>
                )}
                {!isEmpty(booking) && (
                  <Grid>
                    <GridCell>
                      <Label>Attached Booking</Label>
                      <CellBookingItem booking={booking} />
                    </GridCell>
                  </Grid>
                )}
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Expense' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === EXPENSES_ACTIONS.CREATE_EXPENSE ||
              loading === EXPENSES_ACTIONS.UPDATE_EXPENSE
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ExpenseCreateModal;
