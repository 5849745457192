import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isArray from 'lodash/isArray';
import {
  IntegrationDto,
  ShotflowInstanceDto,
  ShotflowIntegrationInstances
} from 'dtos/integration.dto';
import {
  configureIntegration,
  getIntegrations,
  createShotflowInstance,
  updateShotflowInstance,
  deleteShotflowInstance
} from 'store/integrations/integrations.actions';
import { IntegrationID } from '__types__';

export type IntegrationsStoreData = Record<string, IntegrationDto>;

export type IntegrationsStore = {
  data: IntegrationsStoreData;
  fetched?: boolean;
  loading?: boolean | string;
  error?: any;
};

export const INITIAL_STATE: IntegrationsStore = {
  data: {},
  fetched: false,
  loading: false
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        getIntegrations.fulfilled,
        (state, { payload }: PayloadAction<IntegrationDto[]>) => {
          state.data = payload.reduce(
            (data: IntegrationsStoreData, item: IntegrationDto) => ({
              ...data,
              [item.id]: item.id !== IntegrationID.SHOTFLOW ? item : {
                ...item,
                ...{
                  configuration: {
                    instances: isArray(item.configuration?.instances) ? item.configuration?.instances.reduce((
                      instances: ShotflowIntegrationInstances,
                      instance: ShotflowInstanceDto
                    ) => ({
                      ...instances,
                      [instance.id]: instance
                    }), {}) : {}
                  }
                }
              }
            }),
            {}
          );
          state.fetched = true;
        }
      )
      .addCase(
        configureIntegration.fulfilled,
        (state, { payload }: PayloadAction<IntegrationDto>) => {
          state.data[payload.id] = payload;
        }
      )
      .addCase(
        createShotflowInstance.fulfilled,
        (state, {payload}: PayloadAction<ShotflowInstanceDto>) => {
          state.data[IntegrationID.SHOTFLOW] = {
            ...state.data[IntegrationID.SHOTFLOW],
            configuration: {
              instances: {
                ...state.data[IntegrationID.SHOTFLOW].configuration?.instances,
                [payload.id]: payload
              }
            }
          };
        }
      )
      .addCase(
        updateShotflowInstance.fulfilled,
        (state, {payload}: PayloadAction<ShotflowInstanceDto>) => {
          state.data[IntegrationID.SHOTFLOW] = {
            ...state.data[IntegrationID.SHOTFLOW],
            configuration: {
              instances: {
                ...state.data[IntegrationID.SHOTFLOW].configuration?.instances,
                [payload.id]: payload
              }
            }
          };
        }
      )
      .addCase(
        deleteShotflowInstance.fulfilled,
        (state, {meta: {arg}}) => {
            delete state.data[IntegrationID.SHOTFLOW]?.configuration?.instances[arg];
        })
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default integrationsSlice.reducer;
