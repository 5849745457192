import { useMemo } from 'react';
import { get } from 'lodash';

export function useSelectedContacts(ui, pointers, callsheetQuery, contacts) {
  return useMemo(() => {
    const destination = get(ui, 'data.destination');
    let selectedContacts = [];
    switch (destination) {
      case 'PRODUCTION_TEAM_MEMBER_OPTIONS':
        const assignments = get(
          pointers,
          'resource_slot.resource_slot_assignments'
        );
        assignments &&
          assignments.map(assignment => {
            selectedContacts.push(assignment.contact_id);
          });
        break;
      case 'SHORTLIST_SHORTLIST_BLOCKS':
        const blocks = get(pointers, 'shortlist.shortlist_blocks');

        blocks &&
          blocks
            .filter(b => !b.archived)
            .map(block => {
              selectedContacts.push(block.contact_id);
            });
        break;
      case 'CALLSHEET_TEAM_MEMBERS':
        const teamMembers = callsheetQuery.data?.team_members;
        teamMembers &&
          teamMembers.map(teamMember => {
            selectedContacts.push(teamMember.contact_id);
          });
        break;
      case 'CONTACT_CONNECT':
        const representedContacts = get(
          contacts,
          ['related_contacts', get(pointers, 'contact.id')],
          []
        );

        representedContacts.map(id => selectedContacts.push(id));
        break;
      default:
    }
    return selectedContacts;
  }, [ui, callsheetQuery.data, pointers, contacts]);
}
