import React, { Fragment, useState } from 'react';
//import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  PressStud,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  EventDateRange
} from 'v1/components/shared';
import { TextInputField } from 'v1/components/shared';
import { ColourPicker } from 'v1/components/shared';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import {
  createProductionEvent,
  updateProductionEvent,
  createResourceSlotEvent,
  updateResourceSlotEvent
} from 'store/v1/productions/productions.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { Form } from 'react-form';
import { getDatesBetweenTwoDates } from 'v1/helpers/dateHelper';
import useEvent from 'v1/helpers/hooks/useEvent';
import { get } from 'lodash';

import './EventModal.scss';

const EventModal = ({ onRequestClose }) => {
  // STATE
  const ui = useSelector(state => state.ui);
  const productions = useSelector(state => state.productions);
  const dispatch = useDispatch();

  const isCreate = get(ui, 'data.isCreate');
  const productionId = get(ui, 'data.data.production_id');

  const [formApi, setFormApi] = useState();
  const [evt, setEvt] = useState(get(ui, 'data.event') || {});

  const highlightedDates = getDatesBetweenTwoDates(
    get(productions, ['data', productionId, 'production_date'])
  );
  // EFFECTS
  useEvent(
    [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT,
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT,
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT,
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Event Updated'));
        onRequestClose();
      },
      onFailure: () => {
        dispatch(displayAlert('error', 'Update Failed'));
      }
    }
  );

  function handleSubmit(data) {
    const { /*contact_id,*/ production_id, resource_slot_id } =
      get(ui, 'data.data') || {};

    switch (evt.event_type) {
      case 'PRODUCTION_SCHEDULE':
      case 'PRODUCTION_MILESTONE':
        return isCreate
          ? dispatch(createProductionEvent(data, production_id))
          : dispatch(updateProductionEvent(evt.id, data, production_id));
      case 'RESOURCE_SLOT':
        return isCreate
          ? dispatch(
              createResourceSlotEvent(data, resource_slot_id, production_id)
            )
          : dispatch(
              updateResourceSlotEvent(
                evt.id,
                data,
                resource_slot_id,
                production_id
              )
            );
      default:
        return null;
    }
  }

  return (
    <ModalWrapper size="S">
      <ModalContent>
        <ModalScrollable padding="XL">
          <Form
            onSubmit={handleSubmit}
            defaultValues={evt}
            getApi={setFormApi}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <div className="form-group">
                  <span className="form-label">Dates</span>
                  <EventDateRange
                    data={formApi.values}
                    eventType={evt.event_type}
                    onChange={value => formApi.setValue('', value)}
                    highlightDates={[
                      {
                        'EventDateRange-highlighter': highlightedDates
                      }
                    ]}
                  />
                </div>
                <div className="form-group">
                  <TextInputField
                    label="Note"
                    field="note"
                    placeholder="Add a note"
                    removeAutocomplete
                  />
                </div>
                {(evt.event_type === 'PRODUCTION_SCHEDULE' ||
                  evt.event_type === 'PRODUCTION_MILESTONE') && (
                  <Fragment>
                    <span className="form-label">Color</span>
                    <ColourPicker
                      label="Select color"
                      selected={formApi.values.status || ''}
                      onChange={selected =>
                        formApi.setValue('status', selected)
                      }
                    />
                  </Fragment>
                )}
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            action={onRequestClose}
          />
          <PressStud
            label={isCreate ? 'Create' : 'Save'}
            appearance="primary"
            isLoading={
              productions.loading === 'CREATE_PRODUCTION_EVENT' ||
              productions.loading === 'UPDATE_PRODUCTION_EVENT' ||
              productions.loading === 'DELETE_PRODUCTION_EVENT' ||
              productions.loading === 'CREATE_RESOURCE_SLOT_EVENT' ||
              productions.loading === 'UPDATE_RESOURCE_SLOT_EVENT' ||
              productions.loading === 'DELETE_RESOURCE_SLOT_EVENT'
            }
            action={formApi && formApi.submitForm}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default EventModal;
