import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { orderReducer } from 'v1/helpers/misc';
import { Label, Select } from 'v1/components/shared';

/**
 * @desc A selector for resource types
 */
const ResourceTypeSelector = ({
  label,
  appearance,
  size,
  name,
  value,
  allowNull,
  onChange
}) => {
  const resourceTypes = useSelector(state => state.resource_types);
  const resourceTypesList = orderReducer(resourceTypes).filter(
    t => !t.archived
  );

  const getDefaultValue = _ => {
    // prettier-ignore
    return allowNull
      ? ''
      : get(resourceTypesList, [0, 'id'], '');
  };

  useEffect(() => {
    !value && onChange(getDefaultValue());
  }, []);

  return (
    <div>
      {(label === undefined || label !== null) && (
        <Label htmlFor={name}>
          {label !== undefined ? label : 'Resource type'}
        </Label>
      )}
      <Select
        appearance={appearance}
        size={size}
        name={name}
        value={value}
        onChange={({ target }) => onChange(parseInt(target.value))}
        autoComplete="off"
      >
        {allowNull && (
          <option value={''} key={'null'}>
            {typeof allowNull === 'boolean' ? 'All resource types' : allowNull}
          </option>
        )}
        {resourceTypesList.map(opt => (
          <option value={opt.id} key={opt.id}>
            {opt.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

ResourceTypeSelector.props = {
  appearance: PropTypes.oneOf(['outline', 'underline', 'silent']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  // Set to true if it can be null, eg if you want to select "All"
  // Set to a string for true with a custom label
  allowNull: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired
};

export default ResourceTypeSelector;
