import classNames from 'classnames';
import { PropsWithChildren } from 'react';

const sidebarWidth = "220px";

const SidebarRoot = ({ children, className, ...rest }: PropsWithChildren & { className?: string }) => {
  return (
    <div style={{ width: sidebarWidth }} className={classNames("tw-fixed tw-flex tw-flex-col tw-left-0 tw-top-0 tw-h-screen tw-bg-eggplant tw-text-white/80", className)} {...rest}>
      {children}
    </div>
  );
};

export default SidebarRoot;
