import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';

import {
  TextInputField,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation
} from 'v1/components/shared';
import { Form } from 'react-form';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { initiateGuestSession, updateGuest } from 'lib/auth/guestSystem';
import { PressStud } from 'v1/components/shared';

import './SetGuestNameModal.scss';

class SetGuestNameModal extends Component {
  constructor(props) {
    super(props);

    this.formApi = {};

    this.state = {
      query: { order_by: { field: 'created_at', direction: 'desc' } }
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        this.props.onRequestClose();
        this.props.displayAlert('success', 'Successfully created');
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }
  handleCreate = values => {
    let guest = initiateGuestSession();

    guest.full_name = values.full_name;

    updateGuest(guest);

    this.props.displayAlert('success', 'Guest name set');
    setTimeout(() => {
      this.props.onRequestClose();
    }, 100);
  };
  render() {
    return (
      <ModalWrapper size="XS">
        <ModalContent>
          <ModalScrollable padding="L">
            <img src="/images/icon_colour_wave.svg" width="40px" alt="" />
            <h2>Set a guest name</h2>
            <p>
              You need to tell the shortlist owner who you are before you can
              vote or comment on people in the shortlist
            </p>
            <Form
              onSubmit={this.handleCreate}
              getApi={api => (this.formApi = api)}
              validateOnSubmit
            >
              {formApi => (
                <form submit={formApi.submit} className="SetGuestNameModal">
                  <TextInputField
                    label="Your name"
                    field="full_name"
                    validate="required"
                    initialFocus
                  />
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Save Name"
              appearance="primary"
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ events }) {
  return { events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ displayAlert }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetGuestNameModal)
);
