import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectActiveAccount } from 'store/accounts';
import {
  Fieldset,
  Legend,
  StackedFormSection,
  ResourceBadgeIcon
} from 'v1/components/shared';

import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import ReactTooltip from 'react-tooltip';

import './RequestAvailabilityConfirm.scss';

const PLACEHOLDER_RECIPIENT = {
  full_name: 'Tony Stark'
};

const RequestAvailabilityConfirm = ({ request = {} }) => {
  const user = useSelector(selectActiveAccount);
  const contacts = useSelector(state => state.contacts);
  const companyName = useSelector(state =>
    get(state.auth, 'settings.settings.public_company_name.value')
  );
  const recipients = map(request.resourceIDs, id =>
    get(contacts, ['data', id])
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const exampleRecipient = recipients ? recipients[0] : PLACEHOLDER_RECIPIENT;
  return (
    <div className="RequestAvailabilityConfirm">
      <Fieldset>
        <Legend className="form-legend">
          <h3>Confirm before you send</h3>
        </Legend>
        <StackedFormSection
          title="Email preview"
          description="Confirm the request we’ll be sending out"
        >
          <div className="EmailPreview">
            <div className="EmailHeader">
              <img
                src="/images/atellio_brand_logomark.svg"
                alt="logo"
                width="30px"
              />
            </div>
            <div className="EmailBody">
              <p>
                Hey <strong>{exampleRecipient.full_name}</strong>
              </p>
              <p>
                <strong>{user.displayName}</strong> at{' '}
                <strong>{companyName}</strong> wants to know your availability
                between{' '}
                <strong>
                  {moment(request.startTimestampUTC).format('ddd DD MMM')} -{' '}
                  {moment(request.endTimestampUTC).format('ddd DD MMM')}
                </strong>
                .
              </p>
              <hr />
              {request.message && (
                <>
                  <p>
                    <strong>Message from {user.displayName}</strong>
                  </p>
                  <p>{request.message}</p>
                  <hr />
                </>
              )}
              <p>
                Click on the link below to access a portal to share your
                availability. This URL will be available for 3 days before
                expiring.
              </p>
              <div className="btn btn-primary disabled">Share Availability</div>
            </div>
          </div>
        </StackedFormSection>
        <StackedFormSection title="Recipients">
          <div className="RequestAvailabilityConfirm-recipientsList">
            {recipients.map(recipient => (
              <ResourceBadgeIcon
                key={recipient.id}
                tooltip={recipient.full_name}
                className="RequestAvailabilityConfirm-recipientsList-recipient"
                resource={recipient}
              />
            ))}
          </div>
        </StackedFormSection>
      </Fieldset>
    </div>
  );
};

RequestAvailabilityConfirm.propTypes = {
  request: PropTypes.object.isRequired
};

export default RequestAvailabilityConfirm;
