import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get } from 'lodash';

import {
  PressStud,
  ErrorMessage,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import {
  createProductionType,
  updateProductionType
} from 'store/v1/production_types/production_types.actions.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { PRODUCTION_TYPES_ACTIONS } from 'store/v1/production_types/production_types.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import { handleValidate } from 'v1/helpers/validation';
import { getIntegrations } from '../../../../../store/integrations';

const ProductionTypeCreateModal = () => {
  // REDUX
  const ui = useSelector(state => state.ui);
  const { production_type = {}, create } = get(ui, 'data', {});

  const production_types = useSelector(state => state.production_types);
  const { loading, error } = production_types || {};

  const dispatch = useDispatch();

  // PROPS AND REFS
  const { id, name } = production_type;

  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent(
    [
      PRODUCTION_TYPES_ACTIONS.CREATE_PRODUCTION_TYPE,
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE
    ],
    {
      onSuccess: () => {
        dispatch(closeModal());
        dispatch(displayAlert('success', 'Updated succesfully'));
      },
      onFailure: () => dispatch(displayAlert('error', error.message))
    }
  );

  // METHODS
  function handleSubmit(values) {
    const action = create
      ? createProductionType(values)
      : updateProductionType(id, values);
    dispatch(action).finally(() => {
      dispatch(getIntegrations());
    });
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} production type`} />
      <ModalContent>
        <ModalScrollable padding="XL">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              name
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form
                data-cy="production-type-create-form"
                onSubmit={formApi.submitForm}
              >
                <Field
                  field="name"
                  validate={value => handleValidate(value, 'required')}
                >
                  {({ fieldName: name, value, error, setValue }) => (
                    <div className="stack-M">
                      <Label htmlFor={name}>Production Type Name</Label>
                      <TextInput
                        id={name}
                        name={name}
                        value={value}
                        placeholder="Name"
                        onChange={({ target }) => setValue(target.value)}
                        validity={error ? 'invalid' : null}
                        required
                        autoComplete="off"
                        autoFocus
                      />
                      {error && (
                        <ErrorMessage>This field is required</ErrorMessage>
                      )}
                    </div>
                  )}
                </Field>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Production type' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === PRODUCTION_TYPES_ACTIONS.CREATE_PRODUCTION_TYPE ||
              loading === PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ProductionTypeCreateModal;
