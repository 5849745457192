import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const EVENT_ACTIONS = mapValues(
  mirror({
    SUCCESS: null,
    PROCESSING: null,
    FAILURE: null,
    WIPE_EVENT: null
  }),
  value => value
);
