import React, { Component } from 'react';
import { Masonry } from 'v1/components/shared';
import { EmptyGeneric } from 'v1/components/shared';
import { Loading } from 'v1/components/shared';
import DEPRECATED_TagInput from 'v1/components/legacy/deprecated/DEPRECATED_TagInput/DEPRECATED_TagInput';
import { PinItem, Grid, GridCell } from 'v1/components/shared';
import { MultiMediaUploader } from 'v1/components/shared';
import { isEmpty, size, chain } from 'lodash';
import { cleanUrl } from 'v1/helpers/misc';
import classnames from 'classnames';

import './MediaFromProfilePins.scss';

export default class MediaFromProfilePins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProfileTab: 'pins',
      suggestibleTags: this.buildSuggestibleTags(props),
      tags: []
    };
  }
  shouldComponentUpdate = nextProps => {
    if (!this.props.contact && nextProps.contact) return true;
    if (size(this.props.pins) !== size(nextProps.pins)) {
      this.setState({ suggestibleTags: this.buildSuggestibleTags(nextProps) });
      return true;
    }
    return false;
  };
  buildSuggestibleTags = props => {
    const pins = props.pins;

    if (!isEmpty(pins)) {
      const suggestibleTags = [];
      pins
        .filter(p => !p.archived)
        .map(p => p.tags && p.tags.map(t => suggestibleTags.push(t.tag)));
      return suggestibleTags;
    }
    return [];
  };
  filterPins = pins => {
    const tags = this.state.tags;
    let filteredPins = [];
    pins = chain(pins)
      .filter(p => !p.archived)
      .orderBy(['order', 'asc'])
      .value();
    pins.map(pin => {
      const tagsArray = pin.tags.map(t => t.tag);
      const isMatch = tags.every(i => tagsArray.includes(i));
      return isMatch ? filteredPins.push(pin) : null;
    });
    return filteredPins;
  };

  handleUpload = (path, data) => {
    const { contact } = this.props;
    let toSubmit;

    switch (path) {
      case 'URL':
        let pin_type = 'external_link';
        switch (data.provider) {
          case 'YOUTUBE':
            pin_type = 'youtube/video';
            break;
          case 'VIMEO':
            pin_type = 'vimeo/video';
            break;
          case 'IMAGE':
            pin_type = 'external_image_link';
            break;
          case 'EXTERNAL_URL':
          default:
            pin_type = 'external_link';
        }
        toSubmit = { url: data.url, pin_type };
        break;
      case 'FILE':
        toSubmit = {
          url: data.url,
          pin_type: 'file',
          file_id: data.file_id
        };
        break;
      case 'GOOGLE':
        toSubmit = {
          type: `google/${data.mimeType}`,
          file: {
            url: data.url,
            size: data.sizeBytes,
            name: data.name,
            mime_type: data.mimeType,
            type: 'google'
          }
        };
        break;
      default:
        return;
    }
    this.props.createContactPin(toSubmit, contact.id);
  };
  renderSearch = () => {
    return (
      <GridCell>
        <DEPRECATED_TagInput
          tags={this.state.tags || []}
          placeholder="Search tagged pins"
          inputClassname="form-control small-input"
          suggestions={this.state.suggestibleTags}
          onChange={tags => this.setState({ tags })}
        />
      </GridCell>
    );
  };
  renderAdd = () => {
    const { contacts } = this.props;
    return (
      <GridCell width="auto">
        <MultiMediaUploader
          title="Add Pins"
          buttonLabel="Add Pins"
          buttonIcon="/images/icon_add_white.svg"
          buttonClass="btn btn-primary btn-small"
          uploadMethods={['computer', 'url']}
          onUpload={this.handleUpload}
          onFileCompleted={file => this.handleUpload('FILE', file)}
          uploading={contacts.loading === 'CREATE_CONTACT_PIN'}
          secure={false}
        />
      </GridCell>
    );
  };

  render() {
    const { contact, pins, app_id } = this.props;

    if (!contact) return <Loading />;

    return (
      <div className="MediaFromProfilePins">
        <Grid gutters="XS">
          {!isEmpty(pins) && this.renderSearch()}
          {this.renderAdd()}
        </Grid>
        {!isEmpty(pins) ? (
          <Masonry
            ref={c => {
              this.masonry = this.masonry || c.masonry;
            }}
            className="MediaFromProfilePins-items"
            disableImagesLoaded={false}
            updateOnEachImageLoad
          >
            {this.filterPins(pins).map((pin, index) => (
              <div
                key={`pin-${pin.id}-${index}`}
                className={classnames('MediaFromProfilePins-item')}
              >
                <div
                  data-tip="Add to gallery"
                  className="Button-add"
                  onClick={() => this.props.onSelect(pin)}
                >
                  <img src="/images/icon_add_white.svg" width="15px" alt="" />
                </div>
                <PinItem
                  pin={pin}
                  settings={{
                    video: { showPreview: true, disableControls: true }
                  }}
                />
              </div>
            ))}
          </Masonry>
        ) : (
          <EmptyGeneric
            icon="/images/icon_colour_pins.svg"
            iconWidth="30px"
            title={`Nothing Pinned on ${contact.first_name}'s Profile`}
            description="You can build a pinboard within a persons profile page or clicking 'Add Pins'"
            action={{
              text: `Go to Profile`,
              className: 'btn btn-default',
              href: `/app/${app_id}/contacts/${contact.id}`,
              target: '_blank'
            }}
          />
        )}
      </div>
    );
  }
}
