// Libraries
import get from 'lodash/get';
import React, { useEffect } from 'react';
import arrayMove from 'array-move';

// Components
import ShortlistOrderItem from './components/ShortlistOrderItem';
import { Dropdown, ListSortable, MenuItem } from 'v1/components/shared';
import './ShortlistOrderList.scss';
import { SortableItem } from 'v1/components/shared/layout/ListSortable';

const SHORTLIST_SORTS = [
  { field: 'upvotes', label: 'By Most Upvotes' },
  { field: 'full_name', label: 'Alphabetically', direction: 'asc' },
  { field: 'order', label: 'User Order' }
];

const ShortlistOrderList = ({
  query,
  shortlist,
  sortedData,
  filterData,
  onSetQuery,
  orderedBlocks,
  setOrderedBlocks,
  shortlistFieldMapper
}) => {
  useEffect(() => {
    onSetQuery({ filters: {} }).then(() => setOrderedBlocks(filterData()));
  }, []);

  const updateOrder = (oldId, newId) => {
    const oldIndex = orderedBlocks.findIndex(block => block.id === oldId);
    const newIndex = orderedBlocks.findIndex(block => block.id === newId);

    return setOrderedBlocks(arrayMove(orderedBlocks, oldIndex, newIndex));
  };
  const handleSetQuery = q =>
    onSetQuery({ ...query, ...q }).then(() => setOrderedBlocks(sortedData()));
  const getSortOptions = () => {
    if (!shortlistFieldMapper) {
      return SHORTLIST_SORTS;
    }
    let options = [];

    shortlist.default_fields.map(field => {
      if (shortlistFieldMapper[field]) {
        options.push({
          field,
          label: get(shortlistFieldMapper, [field, 'label'])
        });
      }
    });
    return [...SHORTLIST_SORTS, ...options];
  };

  return (
    <div className="ShortlistOrderView-container">
      <Dropdown
        buttonClass="btn btn-default btn-small"
        buttonLabel="Sort order by field"
        buttonExpandIcon
      >
        {getSortOptions().map((param, index) => (
          <MenuItem
            key={index}
            className="DropdownButton-option"
            onSelect={() => handleSetQuery({ order_by: param })}
          >
            <span className="DropdownButton-option-label">{param.label}</span>
          </MenuItem>
        ))}
      </Dropdown>
      <div className="ShortlistOrderView-list">
        <div className="ShortlistOrderView-header">
          <div className="ShortlistOrderView-header-item">Name</div>
          <div className="ShortlistOrderView-header-item">Note</div>
          {shortlist.default_fields.map((field, i) => {
            if (shortlistFieldMapper[field]) {
              return (
                <div className="ShortlistOrderView-header-item" key={i}>
                  {shortlistFieldMapper[field].label}
                </div>
              );
            }
          })}
        </div>
        <ListSortable
          onMove={updateOrder}
          items={orderedBlocks.map(block => block.id)}
        >
          {orderedBlocks.map((block, key) => (
            <SortableItem id={block.id} key={block.id}>
              <ShortlistOrderItem
                key={key}
                block={block}
                index={key}
                updateOrder={updateOrder}
                lastOrder={orderedBlocks.length - 1}
                default_fields={shortlist.default_fields}
                shortlistFieldMapper={shortlistFieldMapper}
              />
            </SortableItem>
          ))}
        </ListSortable>
      </div>
    </div>
  );
};

export default ShortlistOrderList;
