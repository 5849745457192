import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Capability } from '__types__';
import { openContactSidebar, openModal } from 'store/v1/ui/ui.actions.js';

import {
  UserCircleImage,
  Button,
  PressStud,
  ResourceStatusGetter,
  ResourceStatusDisplay
} from 'v1/components/shared';
import { BlockParagraph } from 'v1/components/shared';
import { MediaCarousel } from 'v1/components/shared';
import ResourceFieldsDisplay from '../ResourceFieldsDisplay/ResourceFieldsDisplay';
import { EmptyGeneric } from 'v1/components/shared';

import { renderName } from 'v1/helpers/contactHelpers';
import { get, isEmpty, filter } from 'lodash';
import classnames from 'classnames';
import './ShortlistPresentContactItem.scss';

export const ShortlistPresentContactItem = ({
  block = {},
  blockSelected,
  onDataChange,
  onSelect,
  shortlist_field_mapper,
  default_fields,
  openMediaModal,
  editable,
  sidebarDisabled
}) => {
  const dispatch = useDispatch();
  const shortlists = useSelector(state => state.shortlists);
  const resources = useSelector(state => state.contacts);
  const contact = get(resources, ['data', block.contact_id], block.contact);

  function onChange(data) {
    onDataChange({ ...block, data }, 'UPDATE');
  }
  function onSelectHandler() {
    return onSelect(block);
  }
  function renderHeader(block) {
    return (
      <div className="ShortlistPresentContactItem-header">
        <UserCircleImage
          src={contact.profile_picture}
          className="ShortlistPresentContactItem-header-image"
          contact_id={contact.id}
          initials={{
            first_name: contact.first_name,
            last_name: contact.last_name
          }}
        />
        <div className="ShortlistPresentContactItem-header-details">
          <h2
            onClick={() => {
              if (!sidebarDisabled) {
                dispatch(openContactSidebar(contact));
              }
            }}
            className="ContactName-SidebarHint"
          >
            {renderName(contact)}
            {editable && !sidebarDisabled && (
              <img
                data-tip="Quick View Contact"
                className="ContactName-SidebarHint-icon"
                src="/images/icon_contact_sidebar_hint.svg"
                alt=""
              />
            )}
          </h2>
          {contact.role && <h5>{contact.role}</h5>}
        </div>
        <div className="ShortlistPresentContactItem-header-selector">
          <PressStud
            label={blockSelected ? 'Selected' : 'Select'}
            icon={blockSelected && 'tick'}
            appearance={blockSelected ? 'primary' : ''}
            action={onSelectHandler}
          />
        </div>
      </div>
    );
  }
  function renderNote() {
    return (
      <div className="ShortlistPresentContactItem-note">
        <span className="form-label">Note</span>
        <BlockParagraph
          data={block.data}
          contact={contact}
          onChange={onChange}
        />
      </div>
    );
  }
  function renderGallery() {
    const pins = get(block, 'pins');
    const formattedPins = filter(pins, p => !p.archived);

    if (!isEmpty(formattedPins)) {
      return (
        <div className="ShortlistPresentContactItem-gallery">
          <Button
            dataCy="shortlist-item-add-gallery"
            onClick={() => {
              openMediaModal(block);
            }}
            className="ShortlistPresentContactItem-gallery-editButton"
          >
            Edit Gallery
          </Button>
          <MediaCarousel media={pins} PROVIDER="PINS" layout="INLINE" />
        </div>
      );
    }
    return (
      <EmptyGeneric
        title="Empty Gallery"
        description="Add media for this contact"
        className="PublicShortlistContactItem-gallery-empty"
        icon="/images/icon_colour_media.svg"
        action={{
          dataCy: 'shortlist-item-add-gallery',
          text: 'Fill Gallery',
          className: 'btn btn-primary',
          capability: Capability.RESOURCE_UPDATE,
          onClick: () => {
            openMediaModal(block);
          }
        }}
        onClick={() => {
          openMediaModal(block);
        }}
      />
    );
  }
  function renderContactInfo() {
    return (
      <div className="ShortlistPresentContactItem-section">
        <ResourceFieldsDisplay
          fields={default_fields}
          fieldMapper={shortlist_field_mapper}
          resource={contact}
        />
      </div>
    );
  }
  function renderAvailability(block) {
    const shortlist = get(shortlists, ['data', block.shortlist_id], {});

    if (shortlist.show_availability) {
      const publicBooking = !isEmpty(block.booking_events)
        ? block.booking_events.map(booking => ({
            status_id: get(booking, 'booking_status_id'),
            events: [booking]
          }))
        : [];
      return (
        <div className="ShortlistPresentContactItem-section">
          <ResourceStatusGetter
            resourceId={get(block, 'contact_id')}
            dateRange={shortlist.show_availability}
          >
            {({ bookings = [] }) => {
              const renderBookings = bookings.length ? bookings : publicBooking;
              return renderBookings.length ? (
                renderBookings.map(booking => (
                  <div
                    className="stack-XS"
                    onClick={() =>
                      editable &&
                      dispatch(
                        openModal('ResourceEventsModal', {
                          bookingId: booking.id
                        })
                      )
                    }
                  >
                    <ResourceStatusDisplay
                      booking={booking || publicBooking}
                      hideAssignments
                    />
                  </div>
                ))
              ) : editable ? (
                <span
                  onClick={() =>
                    dispatch(
                      openModal('ResourceEventsModal', {
                        resourceId: block.contact_id,
                        event: shortlist.show_availability
                      })
                    )
                  }
                  className="btn btn-default btn-small"
                >
                  Set availability
                </span>
              ) : (
                <span />
              );
            }}
          </ResourceStatusGetter>
        </div>
      );
    }
  }

  if (!contact) {
    return (
      <EmptyGeneric
        icon="/images/icon_colour_team.svg"
        title="Contact Delete"
        description="This contact has been deleted. You can remove this block on the left"
      />
    );
  }
  return (
    <div
      className={classnames({ selected: blockSelected }, [
        'ShortlistPresentContactItem'
      ])}
    >
      {renderHeader(block)}
      {renderGallery(block)}
      {renderAvailability(block)}
      {renderContactInfo(block)}
      {renderNote(block)}
    </div>
  );
};

export default ShortlistPresentContactItem;
