import { Capability, FeatureFlag } from '__types__';
import useRestrictedAccess from './useRestrictedAccess';

type RestrictedAccessProps = {
  flag?: FeatureFlag | null;
  capabilities?: Capability | Capability[] | null;
  capabilitiesAtLeastOne?: Capability[] | null;
  children: React.ReactNode;
};

const RestrictedAccess = ({
  flag,
  capabilities,
  capabilitiesAtLeastOne,
  children
}: RestrictedAccessProps) => {
  const allowed = useRestrictedAccess(
    flag,
    capabilities,
    capabilitiesAtLeastOne
  );

  return children && allowed ? children : null;
};

export default RestrictedAccess;
