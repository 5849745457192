import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/v1/ui/ui.actions.js';
import {
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud
} from 'v1/components/shared';

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.ui.data);

  const close = () => dispatch(closeModal());

  const onCancel = () => {
    data.onCancel && data.onCancel();
    close();
  };

  const onConfirm = () => {
    data.onConfirm && data.onConfirm();
    close();
  };

  return (
    <ModalWrapper size="XS">
      <ModalContent>
        <ModalScrollable padding="L">
          <span className="text-11-700-eggplant">CONFIRM</span>
          {data.title && <h2 className="stack-S">{data.title}</h2>}
          {data.description && (
            <div className="text-16-600-eggplant-lighter">
              {data.description}
            </div>
          )}
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={onCancel} />
          <PressStud
            label={data.confirmLabel || 'Confirm'}
            appearance="danger"
            action={onConfirm}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

/**
 * Faux PropTypes (for our weird modals)
 *
 * title: PropTypes.string, // Big text
 * description: PropTypes.string, // Little text
 * confirmLabel: PropTypes.string, // Confirm button label
 * onConfirm: PropTypes.func, // Confirm action
 * onCancel: PropTypes.func // Cancel action
 *
 */

export default ConfirmModal;
