import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { v4 } from 'uuid';

import './Files.scss';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import FilesList from './FilesList/FilesList';
import { MultiMediaUploader } from 'v1/components/shared';
//import { Loading } from 'v1/components/shared';

const Files = ({
  disabled,
  className,
  entityId,
  contentName,
  contentType,
  createDocument,
  deleteDocument,
  documents,
  events,
  displayAlert
}) => {
  const [event, setEvent] = useState(null);
  const status = get(events, event);

  useEffect(() => {
    if (!status) return;
    if (status === EVENT_ACTIONS.SUCCESS) {
      displayAlert('success', 'Updated');
    } else if (status !== EVENT_ACTIONS.PROCESSING) {
      displayAlert('error', status.message);
    }
  }, [status, displayAlert]);

  const handleCreateDocument = file => {
    const { file_id, url } = file;
    const eventId = v4();
    setEvent(eventId);
    createDocument({ file_id, url }, entityId, eventId);
  };

  return (
    <div className={classnames('Files', className)}>
      {!disabled && (
        <MultiMediaUploader
          secure={true}
          title="Upload Documents"
          uploadMethods={['computer']}
          onFileCompleted={handleCreateDocument}
          buttonLabel="UPLOAD DOCUMENTS"
          buttonClass="ContactPinned-create" // TODO: css class
        />
      )}
      <FilesList
        entityId={entityId}
        contentName={contentName}
        contentType={contentType}
        documents={documents}
        events={events}
        deleteDocument={deleteDocument}
        displayAlert={displayAlert}
      />
    </div>
  );
};

Files.defaultProps = {
  disabled: false
};

Files.propTypes = {
  className: PropTypes.string,
  entityId: PropTypes.number, // The id of the content you're adding files to
  entityType: PropTypes.string, // Data type to fetch associations from, eg 'contacts'
  contentName: PropTypes.string, // User-facing copy - The name of the content you're commenting on - e.g. 'Scott'
  contentType: PropTypes.string, // User-facing copy - The type of the content you're commenting on - e.g. 'contact'
  createDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  disabled: PropTypes.bool
};

export default Files;
