import { Resource } from '__types__/index';
import useModal from 'hooks/useModal';
import React from 'react';
import { ShortlistBatchAddModal } from 'v4/pages/shortlists/batchAddModal/ShortlistBatchAddModal.connected';

export function useShortlistBatchAdd() {
  const modals = useModal();
  return (resourceIds: Resource['id'][], onAddComplete?: () => void) => {
    modals.openModal(() => (
      <ShortlistBatchAddModal
        resourceIds={resourceIds}
        onAddComplete={onAddComplete}
      />
    ));
  };
}
