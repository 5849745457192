export enum USER_GROUPS_ACTIONS {
  getTenantUserGroups = 'userGroups/getTenantUserGroups',
  getTeamUserGroups = 'userGroups/getTeamUserGroups',
  getUserGroup = 'userGroups/getUserGroup',
  createUserGroup = 'userGroups/createUserGroup',
  updateUserGroup = 'userGroups/updateUserGroup',
  archiveUserGroup = 'userGroups/archiveUserGroup',
  addCapabilityToUserGroup = 'userGroups/addCapabilityToUserGroup',
  removeCapabilityFromUserGroup = 'userGroups/removeCapabilityFromUserGroup'
}
