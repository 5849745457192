import React from 'react';
import PropTypes from 'prop-types';

import { Files } from 'v1/components/shared';
import {
  createProductionDocument,
  deleteProductionDocument
} from 'store/v1/productions/productions.actions.js';

const ProductionSidebarDrawerFiles = ({
  productionId,
  productionTitle,
  disabled
}) => (
  <div className="ProductionSidebarDrawerFiles SidebarDrawer-section">
    <h3>Files</h3>
    <Files
      entityId={productionId}
      entityType="productions"
      contentName={productionTitle}
      contentType="production"
      createDocument={createProductionDocument}
      deleteDocument={deleteProductionDocument}
      disabled={disabled}
    />
  </div>
);

ProductionSidebarDrawerFiles.propTypes = {
  productionId: PropTypes.number,
  productionTitle: PropTypes.string
};

export default ProductionSidebarDrawerFiles;
