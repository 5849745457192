export const PATHS = {
  CALENDAR:
    'M14.0625 1.875H12.1875V0H9.375V1.875H5.625V0H2.8125V1.875H0.9375C0.42 1.875 0 2.295 0 2.8125V14.0625C0 14.58 0.42 15 0.9375 15H14.0625C14.58 15 15 14.58 15 14.0625V2.8125C15 2.295 14.58 1.875 14.0625 1.875ZM13.125 13.125H1.875V6.5625H13.125V13.125Z',
  LOCK:
    'M8.67188 5.15625C8.67188 4.84545 8.54841 4.54738 8.32864 4.32761C8.10887 4.10784 7.8108 3.98438 7.5 3.98438C7.1892 3.98438 6.89113 4.10784 6.67136 4.32761C6.45159 4.54738 6.32812 4.84545 6.32812 5.15625V6.5625H8.67188V5.15625Z M13.2797 0.950646L7.65469 0.0131456C7.55225 -0.00373082 7.44775 -0.00373082 7.34531 0.0131456L1.72031 0.950646C1.50158 0.987239 1.3029 1.10019 1.15957 1.26943C1.01625 1.43867 0.937565 1.65325 0.9375 1.87502V8.43752C0.9375 10.178 1.6289 11.8472 2.85961 13.0779C4.09032 14.3086 5.75952 15 7.5 15C9.24048 15 10.9097 14.3086 12.1404 13.0779C13.3711 11.8472 14.0625 10.178 14.0625 8.43752V1.87502C14.0624 1.65325 13.9837 1.43867 13.8404 1.26943C13.6971 1.10019 13.4984 0.987239 13.2797 0.950646ZM11.25 10.3125C11.25 10.5612 11.1512 10.7996 10.9754 10.9754C10.7996 11.1512 10.5611 11.25 10.3125 11.25H4.6875C4.43886 11.25 4.2004 11.1512 4.02459 10.9754C3.84877 10.7996 3.75 10.5612 3.75 10.3125V7.50002C3.75 7.25138 3.84877 7.01292 4.02459 6.83711C4.2004 6.66129 4.43886 6.56252 4.6875 6.56252H4.92188V5.15627C4.92188 4.47251 5.1935 3.81675 5.67699 3.33326C6.16048 2.84977 6.81624 2.57815 7.5 2.57815C8.18376 2.57815 8.83952 2.84977 9.32301 3.33326C9.8065 3.81675 10.0781 4.47251 10.0781 5.15627V6.56252H10.3125C10.5611 6.56252 10.7996 6.66129 10.9754 6.83711C11.1512 7.01292 11.25 7.25138 11.25 7.50002V10.3125Z',
  BOOKING:
    'M10.3125 15C10.0639 14.9999 9.82543 14.9011 9.64966 14.7253L7.77466 12.8503L9.10028 11.5247L10.3125 12.7368L13.3997 9.64966L14.7253 10.9753L10.9753 14.7253C10.7995 14.9011 10.5611 14.9999 10.3125 15Z M6.5625 13.125H1.875V4.6875H13.125V8.4375H15V2.8125C15 2.56386 14.9012 2.3254 14.7254 2.14959C14.5496 1.97377 14.3111 1.875 14.0625 1.875H12.1875V0H10.3125V1.875H8.4375V0H6.5625V1.875H4.6875V0H2.8125V1.875H0.9375C0.68886 1.875 0.450403 1.97377 0.274587 2.14959C0.0987721 2.3254 0 2.56386 0 2.8125L0 14.0625C0 14.3111 0.0987721 14.5496 0.274587 14.7254C0.450403 14.9012 0.68886 15 0.9375 15H6.5625V13.125Z',
  DETAILS:
    'M11.3125 0H1V15H11.3125C12.3438 15 13.1875 14.1562 13.1875 13.125V1.875C13.1875 0.84375 12.3438 0 11.3125 0ZM10.375 6.5625H3.8125V2.8125H10.375V6.5625Z',
  CHEVRON_RIGHT:
    'M6.20048 1.35482C6.66896 1.80985 11.2462 6.61571 11.2462 6.61571C11.4972 6.86004 11.6228 7.18058 11.6228 7.49888C11.6228 7.81942 11.4972 8.13996 11.2462 8.38205C11.2462 8.38205 6.66896 13.1879 6.20048 13.6452C5.732 14.1025 4.88918 14.1338 4.38932 13.6452C3.88946 13.1588 3.85135 12.4773 4.38932 11.8788L8.58996 7.49888L4.38932 3.11891C3.85135 2.52042 3.88946 1.84124 4.38932 1.35482C4.88918 0.866166 5.732 0.897548 6.20048 1.35482Z'
};

export const getIconPath = pathKey =>
  PATHS[Object.keys(PATHS).find(key => key === pathKey)];
