import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import {
  PressStud,
  ErrorMessage,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput,
  Select
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { ColourPicker } from 'v1/components/shared';
import {
  createStatus,
  updateStatus
} from 'store/v1/statuses/statuses.actions.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { STATUSES_ACTIONS } from 'store/v1/statuses/statuses.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { validate as validator } from 'v1/helpers/validation';
import * as entityTypes from 'v1/helpers/entityTypes';
import { get } from 'lodash';

import './StatusModal.scss';

const STATUS_OPTIONS = {
  [entityTypes.BOOKING]: [
    'AVAILABLE',
    'UNAVAILABLE',
    'HOLD',
    'RELEASED',
    'PENCILLED',
    'CONFIRMED',
    'CANCELLED'
  ],
  [entityTypes.PRODUCTION]: [
    'OPEN',
    'RESOURCING',
    'READY',
    'ACTIVE',
    'COMPLETE'
  ]
};

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const StatusModal = () => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  const statuses = useSelector(state => state.statuses);
  const { status = {}, create } = get(ui, 'data', {});

  const [formApi, setFormApi] = useState();

  useEvent([STATUSES_ACTIONS.CREATE_STATUS, STATUSES_ACTIONS.UPDATE_STATUS], {
    onSuccess: () => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Updated succesfully'));
    },
    onFailure: () => dispatch(displayAlert('error', 'An error has occurred'))
  });

  // METHODS
  function handleSubmit(values) {
    create
      ? dispatch(createStatus(values))
      : dispatch(updateStatus(values.id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} status`} />
      <ModalContent>
        <ModalScrollable>
          <div className="StatusModal">
            <Form
              onSubmit={handleSubmit}
              defaultValues={{
                ...status,
                colour: status.colour || '#eaf3f3',
                status_type:
                  status.status_type || STATUS_OPTIONS[status.entity_type][0]
              }}
              getApi={api => setFormApi(api)}
              validateOnSubmit
            >
              {formApi => (
                <form
                  data-cy="status-create-form"
                  onSubmit={formApi.submitForm}
                >
                  <Field
                    field="name"
                    validate={value => handleValidate(value, 'required')}
                  >
                    {({ fieldName: name, value, error, setValue }) => (
                      <div className="stack-M">
                        <Label htmlFor={name}>Status Name</Label>
                        <TextInput
                          name={name}
                          value={value}
                          placeholder="Name"
                          onChange={({ target }) => setValue(target.value)}
                          validity={error ? 'invalid' : null}
                          required
                          autoComplete="off"
                          autoFocus
                        />
                        {error && (
                          <ErrorMessage>This field is required</ErrorMessage>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field field="status_type">
                    {({ fieldName: name, value, setValue }) => (
                      <Fragment>
                        <Label htmlFor={name}>Status Type</Label>
                        <Select
                          name={name}
                          value={value || ''}
                          onChange={({ target }) => setValue(target.value)}
                          autoComplete="off"
                        >
                          {STATUS_OPTIONS[status.entity_type].map(opt => (
                            <option value={opt} key={opt}>
                              {opt.toLowerCase()}
                            </option>
                          ))}
                        </Select>
                      </Fragment>
                    )}
                  </Field>
                  <Field field="colour">
                    {({ fieldName: name, value, setValue }) => (
                      <div className="StatusModal-color">
                        <Label htmlFor={name}>Color</Label>
                        <ColourPicker
                          label="Select color"
                          selected={value}
                          onChange={selected => setValue(selected)}
                          hideRemove
                        />
                      </div>
                    )}
                  </Field>
                </form>
              )}
            </Form>
          </div>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Status' : 'Save changes'}
            appearance="primary"
            isLoading={statuses.loading}
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default StatusModal;
