import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ProductionSidebarDrawerResources.scss';

import ProductionSidebarDrawerResourceSingle from './ProductionSidebarDrawerResourceSingle/ProductionSidebarDrawerResourceSingle';
import { ResourceSlotList } from 'v1/components/shared';

import find from 'lodash/find';

const ProductionSidebarDrawerResources = ({ production, defaultSlotId }) => {
  const [activeSlotId, setActiveSlot] = useState(null);
  const activeSlot = find(
    production.resource_slots,
    r => r.id === activeSlotId
  );

  const onSlotClick = slot => setActiveSlot(slot.id);
  const onBackClick = _ => setActiveSlot(null);

  useEffect(() => {
    if (activeSlotId && activeSlot && activeSlot.production_id != production.id)
      setActiveSlot(null);
  }, [production.id]);

  useEffect(() => {
    if (defaultSlotId) setActiveSlot(defaultSlotId);
  }, [defaultSlotId]);

  return (
    <div className="ProductionSidebarDrawerResources SidebarDrawer-section">
      {activeSlot ? (
        <ProductionSidebarDrawerResourceSingle
          slot={activeSlot}
          onBackClick={onBackClick}
          production={production}
        />
      ) : (
        <ResourceSlotList
          layout="stacked"
          headingStyle="label"
          production={production}
          onSlotClick={onSlotClick}
        />
      )}
    </div>
  );
};

ProductionSidebarDrawerResources.propTypes = {
  production: PropTypes.object // TODO: type
};

export default ProductionSidebarDrawerResources;
