import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import Account from 'lib/auth/Account';
import useEvent from 'v1/helpers/hooks/useEvent';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions';
import {
  BookingConfirmationMenuItem,
  Flyover,
  PressStud
} from 'v1/components/shared';

const BookingActions = ({ bookingId }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const workspace = Account.resolveFirstWorkspace() || {};
  const bookings = useSelector(state => state.bookings);

  // TODO: duplicated
  const onConfirmSuccess = () => {
    dispatch(
      openModal('SuccessFeedbackModal', {
        title: `Booking confirmation sent!`,
        description: `You have successfully sent the booking confirmation email.`,
        actionLabel: 'Done',
        icon: '/images/icon_colour_send.svg'
      })
    );
  };

  // TODO: duplicated
  const onNoConfirmTemplate = () => {
    dispatch(
      openModal('AlertModal', {
        title: 'No booking confirmation template',
        description:
          'You cannot send a booking confirmation without a booking confirmation template. Please add a confirmation template to continue.',
        actionLabel: 'Go to confirmation template',
        onAction: () =>
          history.replace(
            `/app/${workspace.db_schema}/settings/booking_confirmation_template`
          )
      })
    );
  };

  // TODO: temp: moved this from BookingConfirmationMenuItem - needs to be here because the flyover is closed and it doesn't run
  useEvent([BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION], {
    onSuccess: onConfirmSuccess,
    onFailure: () => {
      get(bookings.error, 'status') === 400
        ? onNoConfirmTemplate()
        : dispatch(displayAlert('error', 'Failed to send booking update'));
    }
  });

  return (
    <Flyover>
      <Flyover.Trigger>
        <PressStud icon="lightning" />
      </Flyover.Trigger>
      <Flyover.Content>
        {({ closeFlyover }) => (
          <BookingConfirmationMenuItem
            bookingId={bookingId}
            closeFlyover={closeFlyover}
          />
        )}
      </Flyover.Content>
    </Flyover>
  );
};

BookingActions.propTypes = {
  bookingId: PropTypes.number
};

export default BookingActions;
