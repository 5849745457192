import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const CHECKINS_ACTIONS = mapValues(
  mirror({
    CREATE_CHECKIN: null,
    CREATE_CHECKIN_SUCCESS: null,
    CREATE_CHECKIN_FAILURE: null,

    UPDATE_CHECKIN: null,
    UPDATE_CHECKIN_SUCCESS: null,
    UPDATE_CHECKIN_FAILURE: null,

    DELETE_CHECKIN: null,
    DELETE_CHECKIN_SUCCESS: null,
    DELETE_CHECKIN_FAILURE: null,

    GET_FRONT_DESK_BOOKINGS: null,
    GET_FRONT_DESK_BOOKINGS_SUCCESS: null,
    GET_FRONT_DESK_BOOKINGS_FAILURE: null
  }),
  value => value
);
