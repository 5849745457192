import { cf } from '@easle/component-factory';
import classNames from 'classnames';

export type Layout = 'fixed-size' | 'fit-content';

export const PopoverPrimitive = cf.div<{
  layout?: Layout;
}>(
  {
    className: ({ layout = 'fixed-size', className }) =>
      classNames(
        'tw-z-10 tw-flex tw-flex-col tw-items-stretch tw-overflow-auto tw-shadow-md tw-rounded-lg tw-bg-white tw-border tw-border-border-muted',
        'dark:tw-bg-gray-800 dark:tw-border-gray-700 dark:tw-text-gray-400',
        'focus:tw-outline-none',
        layout === 'fixed-size' && 'tw-h-[400px] tw-w-[250px]',
        className
      )
  },
  ['layout']
);
