import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/v1/auth/auth.actions.js';
import mergeProps from './mergeProps';
import mergeStateStore from './mergeStateStore';

export default function withAuth(WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        loadAuth: PropTypes.func,
        requestAuthMethods: PropTypes.func,
        updateAuthMethod: PropTypes.func
      }),
      stateStore: PropTypes.shape({
        auth: PropTypes.shape({
          auth0_id: PropTypes.string,
          app_id: PropTypes.string,
          settings: PropTypes.object,
          loggedIn: PropTypes.bool,
          loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
          error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
        }),
        authMethods: PropTypes.shape({
          fetching: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          name: PropTypes.string,
          data: PropTypes.array,
          error: PropTypes.object
        })
      })
    };
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = state =>
    mergeStateStore(state, {
      auth: state.auth,
      authMethods: state.auth_methods
    });
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        ...actions
      },
      dispatch
    )
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
