/**
 * Mixpanel actions that can be tracked in Atellio
 */
export class MixActions {
  static USER_SIGN_IN = 'User Signed In';
  static USER_SIGN_OUT = 'User Signed Out';
  static USER_PROFILE_CHANGE = 'User Profile Change';
  static USER_PASSWORD_CHANGE = 'User Password Change';

  static PRODUCTION_ARCHIVE = 'Archive Production';
  static PRODUCTION_UNARCHIVE = 'Un-Archive Production';

  static CALLOUT_PREVIEW = 'Preview Callout';
  static CALLOUT_CANCEL_PREVIEW = 'Cancel Callout Preview';
  static CALLOUT_PUBLISH = 'Publish Callout';
  static CALLOUT_UNPUBLISH = 'Un-Publish Callout';
  static CALLOUT_PUBLIC_OPEN = 'Public Callout Open';
  static CALLOUT_PUBLIC_SUBMIT = 'Public Callout Submit';
  static CALLOUT_ARCHIVE = 'Archive Callout';
  static CALLOUT_UNARCHIVE = 'Un-Archive Callout';

  static CALLSHEET_ARCHIVE = 'Archive Callsheet';
  static CALLSHEET_UNARCHIVE = 'Un-Archive Callsheet';
  static CALLSHEET_SEND = 'Send Callsheet';

  static SHORTLIST_ARCHIVE = 'Archive Shortlist';
  static SHORTLIST_UNARCHIVE = 'Un-Archive Shortlist';

  static BOOKING_ARCHIVE = 'Archive Booking';
  static BOOKING_UNARCHIVE = 'Un-Archive Booking';

  // The Resource Archived is handle in the backend. This is because
  // archiving a resource in the frontend actually calls the DELETE endpoint
  // while other archiving functions simply PUT with archived = true.
  // Technically this will be handled by the backend as an update.
  static RESOURCE_UNARCHIVE = 'Un-Archive Resource';

  static EXPAND_TOPBAR_NEW_DROPDOWN = 'Expand Top Bar New Dropdown';
  static CLICK_TOPBAR_NEW_DROPDOWN_OPTION = 'Click Top Bar New Option';
  static CLICK_SIDEBAR_SETTINGS_LINK = 'Click Sidebar Settings Link';
  static CLICK_TOPBAR_WHATS_NEW_BUTTON = 'Click Top Bar Whats New Button';
  static EXPAND_TOPBAR_HELP_BUTTON = 'Expand Top Bar Help Dropdown';
  static SEARCH_GLOBAL = 'Global Search';
  static CLICK_SEARCH_GLOBAL_RESULT = 'Click Global Search Result';
  static SEARCH_MODULE = 'Module Search';
}
