//import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CellProductionItem } from 'v1/components/shared';
import { CoreSearchPanel } from 'v1/components/shared';
import {
  Grid,
  GridCell,
  PopoverContent,
  Popover,
  PopoverTrigger,
  PressStud,
  ResourceSlotList
} from 'v1/components/shared';
import isEmpty from 'lodash/isEmpty';
import { createNewEventObject } from 'v1/helpers/byModel/EventHelper';
import v4 from 'uuid';
import './AssignRoleSelector.scss';

const AssignRoleSelector = ({ assignments, onSelect }) => {
  const [production, setProduction] = useState(null);

  const onSlotClick = slot => {
    const resource_slot_id = slot.id;
    const newAssignment = {
      id: `temp-${v4()}`,
      resource_slot_id,
      events: [
        createNewEventObject({
          ...production.production_date,
          event_type: 'RESOURCE_SLOT_ASSIGNMENT'
        })
      ]
    };
    onSelect && onSelect(newAssignment);
    setProduction(null);
  };
  function renderResourceSlots(closePopover) {
    return (
      <div className="AssignRoleSelector-roleSelect">
        <Grid className="stack-S" vcenter gutters="S">
          <GridCell width="auto">
            <PressStud
              icon="arrowLeftGray"
              appearance="silent"
              action={() => setProduction(null)}
            />
          </GridCell>
          <GridCell>
            <h5>
              <strong>{production.title || production.code}</strong> Assign Role
            </h5>
          </GridCell>
        </Grid>
        <ResourceSlotList
          layout="stacked"
          headingStyle="label"
          production={production}
          onSlotClick={slot => {
            onSlotClick(slot);
            return closePopover();
          }}
        />
      </div>
    );
  }
  function renderProductionSelector() {
    return (
      <CoreSearchPanel
        selectedStore="productions"
        selected={production}
        layout={{
          className: 'AssignRoleSelector-productionSelector'
        }}
        cellComponent={item => (
          <CellProductionItem
            key={item.id}
            production={item}
            onSelect={production => setProduction(production)}
          />
        )}
      />
    );
  }
  return (
    <Popover className="AssignRoleSelector">
      <PopoverTrigger className="AssignRoleSelector-button">
        <PressStud
          label={`Assign to ${
            !isEmpty(assignments) ? 'another' : ''
          } production`}
          icon="addBlack"
          size="S"
        />
      </PopoverTrigger>
      <PopoverContent className="AssignRoleSelector-window inset-M">
        {({ closePopover }) =>
          production
            ? renderResourceSlots(closePopover)
            : renderProductionSelector()
        }
      </PopoverContent>
    </Popover>
  );
};

AssignRoleSelector.propTypes = {};

export default AssignRoleSelector;
