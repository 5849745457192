import React, { Component } from 'react';
//import { UserCircleImage } from 'v1/components/shared';
//import ReactTooltip from 'react-tooltip';
import './MagicBlockSectionHeader.scss';

export default class MagicBlockSectionHeader extends Component {
  render() {
    const { /*name, bio,*/ url /*profilePhoto*/ } = this.props;

    return (
      <div className="MagicBlockSectionHeader">
        <div className="MagicBlock-preHeader vertical-align">
          <img src={this.props.icon} alt="" />
          <span className={`MagicBlock-label ${this.props.provider}`}>
            <strong>{this.props.provider}</strong>
            {url ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <i>{url.replace('https://', '').replace('http://', '')}</i>
              </a>
            ) : null}
          </span>
        </div>
        {/* <div className="MagicBlock-section MagicBlock-providerHeader">
						<div className="MagicBlock-providerHeader-profileImage">
							<UserCircleImage src={profilePhoto} initials="" className="MagicBlock-providerHeader-profileImage" />
						</div>
						<div className="MagicBlock-providerHeader-profileContent">
							<h5>{name}</h5>
							<p>{bio}</p>
						</div>
						<a href={url} target="_blank" data-tip={`Go to ${this.props.provider}`} className="MagicBlock-providerHeader-providerLink"><img src="/images/icon_arrow_top_right.svg" alt="" /></a>
					</div> */}
      </div>
    );
  }
}
