import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import filter from 'lodash/filter';

import { findBookingWithinDateRange } from 'v1/helpers/byModel/BookingHelper';

// Returns valid bookings - either the one associated with the passed in assignment, or the most relevant one
// TODO this should be refactored to use tanstack query ASAP
export const ResourceStatusGetter = ({
  resourceId,
  assignment,
  dateRange,
  children
}) => {
  const statuses = useSelector(state => state.statuses);
  const bookingsStore = useSelector(state => state.bookings);
  const resourceBookings = filter(
    get(bookingsStore, 'data'),
    b => b.contact_id === resourceId
  );

  const getLatestBooking = _ => {
    const validResourceBookings = findBookingWithinDateRange(
      dateRange,
      resourceBookings
    );
    return validResourceBookings;
  };

  const getBookingFromAssignment = _ => [
    get(bookingsStore, ['data', get(assignment, 'booking_id')])
  ];

  const bookings = assignment ? getBookingFromAssignment() : getLatestBooking();

  return typeof children === 'function' ? (
    children({ bookings })
  ) : (
    <>{children}</>
  );
};

ResourceStatusGetter.propTypes = {
  resourceId: PropTypes.number,
  assignment: PropTypes.object,
  dateRange: PropTypes.object, // [Event] - eg production.production_date
  children: PropTypes.any.isRequired
};

export default ResourceStatusGetter;
