import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getContact } from 'store/v1/contacts/contacts.actions.js';

// COMPONENTS
// TODO: fix import to shared (breaks test)
import SystemLink from 'v1/components/shared/general/SystemLink/SystemLink';

// HELPERS
import { renderResourceGroups } from 'v1/helpers/contactHelpers';

// IMPORTS
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './ResourceMeta.scss';

const ORGANISATION_LABEL = 'Org';

export const ResourceMeta = ({
  size,
  resource = {},
  hideRole,
  hideRepresentation
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);

  const {
    role,
    groups = [],
    organisation,
    organisation_id,
    resource_type
  } = resource;

  const id = organisation_id;
  const memoGetRepresentation = useCallback(getRepresentationResource, [
    resource
  ]);

  useEffect(() => {
    if (!organisation && organisation_id) {
      memoGetRepresentation();
    }
  }, []);

  function getRepresentationResource() {
    if (!get(contacts, ['data', id])) {
      dispatch(getContact(id));
    }
  }

  function renderRepresentation() {
    if (hideRepresentation) return;
    const representationResource =
      organisation || get(contacts, ['data', organisation_id]);
    if (representationResource) {
      // Get organisation label
      const coreFieldDefinitions = get(
        resource_type,
        'metastructure.fields',
        []
      );
      const organisationDefinition = coreFieldDefinitions.find(
        definition => definition.name === 'organisation_id'
      );
      const label = get(organisationDefinition, 'label', ORGANISATION_LABEL);
      return (
        <div>
          <span className="ResourceMeta-resourceRepresentation">
            {label}
            {': '}
            <SystemLink
              to={`/contacts/${get(representationResource, 'id')}`}
              target="_blank"
              className="genericLink"
              title={`Represented by ${get(
                representationResource,
                'full_name'
              )}`}
            >
              {get(representationResource, 'full_name')}
            </SystemLink>
          </span>
        </div>
      );
    }
    return false;
  }

  function renderRole() {
    if (hideRole) return;
    return (
      <>
        <span>{role}</span>
      </>
    );
  }

  if (size === 'L') {
    return (
      <h3>
        {renderRole()}
        {renderRepresentation()}
      </h3>
    );
  }

  return (
    <span className="ResourceMeta">
      {renderRole()}
      {renderRepresentation()}
    </span>
  );
};

ResourceMeta.propTypes = {
  size: PropTypes.oneOf(['L']),
  resource: PropTypes.object.isRequired,
  hideRole: PropTypes.bool,
  hideRepresentation: PropTypes.bool
};

export default ResourceMeta;
