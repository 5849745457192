import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';

export function wipeEvent(event_id) {
  return function (dispatch) {
    dispatch({
      type: EVENT_ACTIONS.WIPE_EVENT,
      event_id
    });
  };
}
