import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';

import FormBuilder from 'v1/components/FormBuilder/FormBuilder';
import { Grid, GridCell, Label, TextInputGroup } from 'v1/components/shared';

import './ProductionDetailsForm.scss';
import { selectProductionTemplates } from 'store/v1/productions/productions.selectors';
import { selectIntegrationById } from 'store/integrations';
import { IntegrationID, FeatureFlag } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';

export const ProductionDetailsForm = ({
  production,
  isCreate,
  getApi,
  onProductionUpdate
}) => {
  const auth = useSelector(state => state.auth);
  const productionTypes = useSelector(state => state.production_types);
  const templates = useSelector(selectProductionTemplates);
  const template = production.production_template_id
    ? templates.find(({ id }) => id === production.production_template_id)
    : null;
  const shotFlowIntegration = useSelector(state =>
    selectIntegrationById(state, IntegrationID.SHOTFLOW)
  );

  const selectedProductionMetastructure =
    get(
      productionTypes,
      [
        'data',
        get(production, 'production_type_id'),
        'metastructure',
        'fields'
      ],
      []
    ) ||
    get(
      productionTypes,
      ['data', get(productionTypes, ['order', 0]), 'metastructure', 'fields'],
      []
    );

  const defaultTimezone = get(
    auth,
    'settings.settings.defaults_timezone.value'
  );
  const timezone = production.timezone || defaultTimezone;
  const now = moment().toDate();

  const fieldOverrides = {
    color: {
      width: '1/3',
      className: 'ProductionSpecific-colorThing'
    },
    icon: {
      width: '1/3'
    },
    code: {
      width: '1/3'
    },
    title: {
      autoFocus: isCreate,
      placeholder: 'Production Title',
      label: 'Title'
    },
    production_date: {
      defaultData: {
        start_date: moment.tz(now, timezone).format('YYYY-MM-DD'),
        end_date: moment.tz(now, timezone).add(1, 'days').format('YYYY-MM-DD'),
        date_type: 'RECURRING_DEFAULT',
        event_type: 'PRODUCTION'
      },
      eventType: 'PRODUCTION',
      timezone
    },
    budget_currency: {
      width: '1/2'
    }
  };

  return (
    <FormBuilder
      data={production}
      fields={selectedProductionMetastructure}
      onSubmit={values => {
        onProductionUpdate(values);
      }}
      getApi={api => {
        getApi && getApi(api);
      }}
    >
      {({ renderCoreField, renderCustomFields }) => (
        <div className="ProductionDetailsForm">
          <Grid className="stack-S">
            <GridCell width="2/12">
              <Label size="L">Details</Label>
            </GridCell>
            <GridCell className="FormBuilder-FormGroup">
              <Grid gutters="S" className="stack-S">
                <GridCell>
                  {renderCoreField('code', fieldOverrides.code)}
                </GridCell>
                <GridCell>
                  {renderCoreField('icon', fieldOverrides.icon)}
                </GridCell>
                <GridCell>
                  {renderCoreField('color', fieldOverrides.color)}
                </GridCell>
              </Grid>
              <Grid className="stack-S">
                <GridCell>
                  {renderCoreField('title', fieldOverrides.title)}
                </GridCell>
              </Grid>
              <Grid>
                <GridCell>
                  {renderCoreField('description', fieldOverrides.description)}
                </GridCell>
              </Grid>
            </GridCell>
          </Grid>
          <Grid className="stack-S">
            <GridCell width="2/12">
              <Label size="L">Dates</Label>
            </GridCell>
            <GridCell className="FormBuilder-FormGroup">
              <Grid className="stack-S">
                <GridCell>
                  {renderCoreField(
                    'production_date',
                    fieldOverrides.production_date
                  )}
                </GridCell>
              </Grid>
              <Grid>
                <GridCell>
                  {renderCoreField('timezone', fieldOverrides.timezone)}
                </GridCell>
              </Grid>
            </GridCell>
          </Grid>

          <RestrictedAccess flag={FeatureFlag.CUSTOM_SHOTFLOW}>
            {shotFlowIntegration &&
              shotFlowIntegration.enabled &&
              template &&
              template.shotflow_instance && (
                <Grid className="stack-S">
                  <GridCell width="2/12">
                    <Label size="L">Integration</Label>
                  </GridCell>
                  <GridCell className="FormBuilder-FormGroup">
                    <Grid className="stack-S">
                      <GridCell>
                        <Label size="M">Shotflow Instance</Label>
                        <TextInputGroup
                          disabled
                          value={template.shotflow_instance.name}
                          append={{ icon: '/images/icon_lock.svg' }}
                          className="stack-XS"
                        />
                        <p className="text-neutral">
                          This can only be changed within the template
                        </p>
                      </GridCell>
                    </Grid>
                  </GridCell>
                </Grid>
              )}
          </RestrictedAccess>

          <Grid className="stack-S">
            <GridCell width="2/12">
              <Label size="L">Additional</Label>
            </GridCell>
            <GridCell className="FormBuilder-FormGroup">
              {renderCustomFields({ fieldOverrides })}
            </GridCell>
          </Grid>
          <Grid className="stack-S">
            <GridCell width="2/12">
              <Label size="L">Budget</Label>
            </GridCell>
            <GridCell className="FormBuilder-FormGroup">
              {renderCoreField(
                'budget_currency',
                fieldOverrides.budget_currency
              )}
            </GridCell>
          </Grid>
        </div>
      )}
    </FormBuilder>
  );
};

ProductionDetailsForm.propTypes = {
  production: PropTypes.object,
  onProductionUpdate: PropTypes.func
};

export default ProductionDetailsForm;
