import PropTypes from 'prop-types';
import TypeRate from './TypeRate';

// TODO: archived and standard stuff
const TypeContactRate = PropTypes.shape({
  ...TypeRate,
  contact_id: PropTypes.number,
  order: PropTypes.number,
  rate_id: PropTypes.number
});

export default TypeContactRate;
