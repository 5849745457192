import { SLOT_CATEGORIES_ACTIONS } from 'store/v1/slot_categories/slot_categories.constants.js';

const token = true;

export const createSlotCategory = data => {
  return {
    types: [
      SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY,
      SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY_SUCCESS,
      SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY_FAILURE
    ],
    promise: client => client.post('/slot_categories', { data, token })
  };
};

export const updateSlotCategory = (slot_category_id, data, event_id = null) => {
  return {
    types: [
      SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY,
      SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY_SUCCESS,
      SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY_FAILURE
    ],
    promise: client =>
      client.put(`/slot_categories/${slot_category_id}`, { data, token }),
    slot_category_id
  };
};

export const deleteSlotCategory = slot_category_id => {
  return {
    types: [
      SLOT_CATEGORIES_ACTIONS.DELETE_SLOT_CATEGORY,
      SLOT_CATEGORIES_ACTIONS.DELETE_SLOT_CATEGORY_SUCCESS,
      SLOT_CATEGORIES_ACTIONS.DELETE_SLOT_CATEGORY_FAILURE
    ],
    promise: client =>
      client.del(`/slot_categories/${slot_category_id}`, { token }),
    slot_category_id
  };
};
