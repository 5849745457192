import {
  AssociationQuery,
  AssociationState,
  mutationSchemas
} from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { queryClient } from 'v4/migration/queryClientSingleton';
import { shortlistInternals } from 'v4/entities/shortlist/shortlist.hooks';

export const createDeleteBatchEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi
) =>
  defineEntityEffect({
    type: 'deleteBatch',
    requestSchema: mutationSchemas.deleteBatch.request,
    responseSchema: mutationSchemas.deleteBatch.response,
    useQueryFn: () => useApi().deleteBatch
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        // todo think about pagination, for now we don't care about it (previous implementation was loading all blocks among the shortlist endpoint anyway...)

        const filteredResults = state.results.filter(
          x => !request.association_ids.includes(x.id)
        );
        const numRemoved = state.results.length - filteredResults.length;

        return {
          ...state,
          paging: {
            ...state.paging,
            total: state.paging.total - numRemoved
          },
          results: filteredResults
        };
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      // @ts-ignore
      shortlistInternals.invalidateDetails(queryClient, query?.shortlist_id);
      return {
        state: config.optimisticReconciler(state, request, query),
        shouldInvalidate: true // something can come from next page if we are paginated
      };
    })
    .build();
