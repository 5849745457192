import React, { Component } from 'react';
import { FileUploader } from 'v1/components/shared';
import { MiniLoader } from 'v1/components/shared';

import './MediaFromFile.scss';

export default class MediaFromFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    };
  }
  createPin = file => {
    const { onSelect } = this.props;
    const pin = {
      url: file.url,
      pin_type: 'file',
      file_id: file.file_id,
      id: `temp-${file.id}`,
      file: {
        mime_type: file.raw.type,
        name: file.raw.name
      }
    };
    onSelect(pin);
  };
  render() {
    return (
      <div className="MediaFromFile">
        <h3>Add from your computer</h3>
        <FileUploader
          className="MediaFromFile-uploader"
          renderContent={() => 'Drag files here or click upload'}
          renderLoading={() => <MiniLoader isLoading={true} />}
          accepts="image/*"
          onFileCompleted={file => this.createPin(file)}
          secure={false}
        />
      </div>
    );
  }
}
