// @ts-nocheck
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { wipeEvent } from 'store/v1/events/events.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';

const useEvent = (
  actionTypes : string[],
  {
    onProcessing,
    onSuccess,
    onFailure,
    onProcessingCompare = 'some',
    onSuccessCompare = 'some',
    onFailureCompare = 'some'
  } : any
) => {
  // Prevent main effect to be executed on mount
  const isInitialMount = useRef(true);

  const events = useSelector(state => state.events);
  const dispatch = useDispatch();

  const _actionTypes =
    typeof actionTypes === 'string' ? [actionTypes] : actionTypes;
  const dependencies = _actionTypes.map(actionType => events[actionType]);

  useEffect(() => {
    return () => wipeEvents();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (
        _actionTypes[onProcessingCompare](
          actionType => events[actionType] === EVENT_ACTIONS.PROCESSING
        )
      ) {
        if (onProcessing) onProcessing();
      }

      if (
        _actionTypes[onSuccessCompare](
          actionType => events[actionType] === EVENT_ACTIONS.SUCCESS
        )
      ) {
        if (onSuccess) onSuccess();
        return () => wipeEvents();
      }

      if (
        _actionTypes[onFailureCompare](actionType => {
          const payload = events[actionType];
          const isError = payload && (payload.error || payload.message);
          return payload === EVENT_ACTIONS.FAILURE || isError
        })
      ) {
        if (onFailure) onFailure();
        return () => wipeEvents();
      }
    }
  }, dependencies);

  function wipeEvents() {
    const keys = _actionTypes.filter(
      actionType =>
        events[actionType] == EVENT_ACTIONS.SUCCESS ||
        events[actionType] == EVENT_ACTIONS.FAILURE
    );
    if (keys && keys.length) return dispatch(wipeEvent(keys));
  }
};

export default useEvent;
