import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

//const prefix = '@@batchActions';

export const BATCH_ACTIONS = mapValues(
  mirror({
    BATCH_EXPORT: null,
    BATCH_EXPORT_SUCCESS: null,
    BATCH_EXPORT_FAILURE: null,

    CSV_IMPORT_REQUEST: null,
    CSV_IMPORT_REQUEST_SUCCESS: null,
    CSV_IMPORT_REQUEST_FAILURE: null
  }),
  value => value
);
