import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { Form } from 'react-form';
import { withRouter } from 'react-router-dom';

import { getTeamAccounts } from 'store/accounts';
import { getTeamUserGroups } from 'store/userGroups';
import { getAccountTeams } from 'store/teams';

import { ErrorMessage, PressStud, TextInputField } from 'v1/components/shared';

import Authentication from 'lib/auth/authentication';
import Account from 'lib/auth/Account';
import AuthService from 'lib/auth/AuthService';
import {
  hasCharacterLength,
  hasNumbers,
  hasUpperCase
} from 'lib/auth/passwordValidation';
import withAuth from 'v1/hocs/withAuth';
import PasswordRequirementList from 'v1/components/feature/AuthComponents/PasswordRequirementList';
import { getIntegrations } from 'store/integrations';

const RecoverPasswordCallbackForm = ({
  recoveryToken,
  actions,
  history,
  className
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async form => {
    try {
      const { password, passwordConfirm } = form;

      if (password !== passwordConfirm) {
        setErrorMessage('Passwords do not match');
        return;
      }

      // Passwords must meet the requirements
      if (!hasUpperCase(password)) {
        setErrorMessage('Password must have at least 1 upper case letter');
        return;
      }
      if (!hasCharacterLength(password, 8)) {
        setErrorMessage('Password must have at least 8 characters');
        return;
      }
      if (!hasNumbers(password)) {
        setErrorMessage('Password must have at least 1 number');
        return;
      }

      const {
        token,
        tokenString
      } = await Authentication.recoverPasswordCallback(recoveryToken, password);
      if (!token) {
        setErrorMessage('Something went wrong');
        return;
      }

      token.workspaces = await AuthService.fetchAccountWorkspaces(tokenString);
      token.tokenString = tokenString;
      AuthService.token(token);

      actions.loadAuth(token, token.tokenString, null);
      dispatch(getTeamAccounts());
      dispatch(getTeamUserGroups());
      dispatch(getAccountTeams());
      dispatch(getIntegrations());

      // TODO: Allow the user to select which workspace to navigate to. For now, get the first workspace.
      const workspace = Account.resolveFirstWorkspace();
      if (!workspace) {
        setErrorMessage('Account does not belong to a workspace');
        return;
      }

      history.replace(`/app/${workspace.db_schema}/dashboard`);
    } catch (err) {
      const message =
        get(err, 'response.body.error.message') || 'Something went wrong';
      setErrorMessage(message);
    }
  };

  return (
    <div className={classNames('OnboardingForm FormBlock', className)}>
      <div className="FormBlock-header">
        <h2>
          <strong>Recover your password</strong>
        </h2>
        <h3>It happens to the best of us!</h3>
      </div>
      <div className="FormBlock-content">
        <div className={classNames('row LoginFormGroup', className)}>
          <div className="col-sm-12">
            <Form onSubmit={handleSubmit}>
              {formApi => (
                <form onSubmit={formApi.submitForm}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="LoginFormLabel">
                          Create a new password:
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <TextInputField
                          type="password"
                          field="password"
                          placeholder="New password"
                          className="LoginFormInput"
                        />
                      </div>
                      <div className="form-group">
                        <TextInputField
                          type="password"
                          field="passwordConfirm"
                          placeholder="Confirm new password"
                          className="LoginFormInput"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <PasswordRequirementList
                          password={formApi.values.password}
                        />
                      </div>
                    </div>

                    {errorMessage ? (
                      <div className="col-sm-12">
                        <div className="form-group">
                          <ErrorMessage>{errorMessage}</ErrorMessage>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-sm-12">
                      <div className="form-group">
                        <PressStud
                          label="Recover my account"
                          appearance="primary"
                          action="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

RecoverPasswordCallbackForm.defaultProps = {};

RecoverPasswordCallbackForm.propTypes = {
  recoveryToken: PropTypes.string.isRequired,
  actions: PropTypes.object,
  history: PropTypes.object,
  className: PropTypes.string
};

export default withRouter(withAuth(RecoverPasswordCallbackForm));
