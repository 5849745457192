import React from 'react';
import PropTypes from 'prop-types';

import {
  createContactNote,
  deleteContactNote
} from 'store/v1/contacts/contacts.actions.js';

import { Notes } from 'v1/components/shared';

/**
 * @desc `Notes` wrapped for Resources
 */
const ResourceNotes = ({ className, resource, disabled }) => (
  <Notes
    className={className}
    entityId={resource.id}
    entityType="contacts"
    contentType="contact"
    contentName={resource.first_name}
    createNote={createContactNote}
    deleteNote={deleteContactNote}
    disabled={disabled}
  />
);

ResourceNotes.props = {
  className: PropTypes.string,
  resource: PropTypes.object,
  disabled: PropTypes.bool
};

export default ResourceNotes;
