import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Env from 'lib/env/Env';
import FlyoverContext from './FlyoverContext';
import FlyoverPortal from './FlyoverPortal/FlyoverPortal';

import FlyoverAccordion from './FlyoverAccordion/FlyoverAccordion';
import FlyoverContent from './FlyoverContent/FlyoverContent';
import FlyoverFooter from './FlyoverFooter/FlyoverFooter';
import FlyoverGroup from './FlyoverGroup/FlyoverGroup';
import FlyoverHeader from './FlyoverHeader/FlyoverHeader';
import FlyoverHelper from './FlyoverHelper/FlyoverHelper';
import FlyoverItem from './FlyoverItem/FlyoverItem';
import FlyoverTab from './FlyoverTab/FlyoverTab';
import FlyoverTrigger from './FlyoverTrigger/FlyoverTrigger';

const Flyover = ({ onOpen, onClose, children }) => {
  const [config, setConfig] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [triggerRect, setTriggerRect] = useState({});

  const openFlyover = () => {
    setIsOpen(true);
    onOpen && onOpen();
  };

  const closeFlyover = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const toggleFlyover = () => {
    isOpen ? closeFlyover() : openFlyover();
  };

  const initialValue = {
    config,
    setConfig,
    isOpen,
    activeTab,
    setActiveTab,
    triggerRect,
    setTriggerRect,
    toggleFlyover,
    openFlyover,
    closeFlyover
  };

  const contents = children.reduce((acc, child) => {
    if (child.type === FlyoverTrigger) {
      return { ...acc, trigger: child };
    }
    if (child.type === FlyoverHelper) {
      return { ...acc, helper: child };
    }
    if (child.type === FlyoverContent) {
      return { ...acc, content: child };
    }
    return acc;
  }, {});

  if (Env.isDev) {
    !contents.trigger &&
      console.error('Warning: `<Flyover>` should contain `<Flyover.Trigger>`');
    !contents.content &&
      console.error('Warning: `<Flyover>` should contain `<Flyover.Content>`');
  }

  return (
    <FlyoverContext.Provider value={initialValue}>
      {contents.trigger}
      <FlyoverPortal hasHelper={!!contents.helper}>
        {contents.helper}
        {contents.content}
      </FlyoverPortal>
    </FlyoverContext.Provider>
  );
};

Flyover.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.any
};

Flyover.Accordion = FlyoverAccordion;
Flyover.Content = FlyoverContent;
Flyover.Footer = FlyoverFooter;
Flyover.Group = FlyoverGroup;
Flyover.Header = FlyoverHeader;
Flyover.Helper = FlyoverHelper;
Flyover.Item = FlyoverItem;
Flyover.Tab = FlyoverTab;
Flyover.Trigger = FlyoverTrigger;

export default Flyover;
