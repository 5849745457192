import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const RATES_ACTIONS = mapValues(
  mirror({
    GET_RATES: null,
    GET_RATES_SUCCESS: null,
    GET_RATES_FAILURE: null,

    CREATE_RATE: null,
    CREATE_RATE_SUCCESS: null,
    CREATE_RATE_FAILURE: null,

    DELETE_RATE: null,
    DELETE_RATE_SUCCESS: null,
    DELETE_RATE_FAILURE: null,

    UPDATE_RATE: null,
    UPDATE_RATE_SUCCESS: null,
    UPDATE_RATE_FAILURE: null
  }),
  value => value
);
