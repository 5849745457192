import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Radio.scss';

const Radio = ({
  className,
  id,
  name,
  value,
  checked,
  onChange,
  onBlur,
  label,
  description,
  validity,
  ...props
}) => (
  <div className={classnames('Radio', className)} {...props}>
    {label && (
      <label htmlFor={id} className="Radio-label">
        {label}
      </label>
    )}
    <input
      name={name}
      id={id}
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      className="Radio-input"
    />
    {description && <p className="Radio-description">{description}</p>}
  </div>
);

Radio.propTypes = {
  // Component props
  className: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.string,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  // ...props
  // All valid attributes for <input type="checkbox" />
  // Keeping in mind React-specific attribute case:
  // https://react-cn.github.io/react/docs/tags-and-attributes.html
};

export default Radio;
