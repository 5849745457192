import { AVAILABILITY_REQUEST_ACTIONS } from 'store/v1/availability_requests/availability_requests.constants.js';

const token = true;
const isV2 = true;

export const getAvailabilityRequests = app_id => {
  return {
    types: [
      AVAILABILITY_REQUEST_ACTIONS.GET_AVAILABILITY_REQUESTS,
      AVAILABILITY_REQUEST_ACTIONS.GET_AVAILABILITY_REQUESTS_SUCCESS,
      AVAILABILITY_REQUEST_ACTIONS.GET_AVAILABILITY_REQUESTS_FAILURE
    ],
    promise: client =>
      client.get(`/resource-portal/teams/${app_id}/availability-requests`, {
        token,
        isV2
      })
  };
};

export const createAvailabilityRequest = request => {
  return {
    types: [
      AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST,
      AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST_SUCCESS,
      AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST_FAILURE
    ],
    promise: client =>
      client.post(`/resource-portal/teams/:app_id:/availability-requests`, {
        data: request,
        token,
        isV2
      })
  };
};
