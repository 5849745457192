import { Capability } from '__types__/index';
import PropTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import './ListCell.scss';

export interface ListCellProps extends HTMLAttributes<HTMLDivElement> {
  boxShadow?: number; // TODO: currently only accepts 1, but will be different levels
  padding?: 0 | 'XS' | 'S' | 'M' | 'L';
  borders?: boolean;
  rounded?: boolean;
  capabilities?: Capability[];
}

const ListCell = ({
  capabilities,
  className,
  boxShadow,
  padding,
  children,
  onClick,
  borders,
  rounded,
  ...rest
}: ListCellProps) => {
  let canClick = useRestrictedAccess(undefined, capabilities, undefined);

  canClick = capabilities ? canClick : true;
  let listCell_className = {
    [`box-shadow-${boxShadow}`]: boxShadow,
    [`ListCell_padding-${padding}`]: padding,
    [`rounded`]: rounded,
    borders: borders,
    ListCell_clickable: canClick && onClick
  };

  return (
    <div
      className={classnames('ListCell', listCell_className, className)}
      onClick={canClick ? onClick : undefined}
      {...rest}
    >
      {children}
    </div>
  );
};

ListCell.propTypes = {
  className: PropTypes.string,
  boxShadow: PropTypes.number, // TODO: currently only accepts 1, but will be different levels
  padding: PropTypes.oneOf([undefined, null, 0, 'XS', 'S', 'M', 'L']),
  children: PropTypes.any,
  onClick: PropTypes.func,
  borders: PropTypes.bool,
  rounded: PropTypes.bool
};

ListCell.defaultProps = {
  padding: 'S',
  borders: true,
  rounded: true
};

export default ListCell;
