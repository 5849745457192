import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './ProductionSidebarDrawerTabs.scss';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import {
  makeGetProductionNotesCount,
  makeGetProductionFilesCount
} from 'store/v1/productions/productions.selectors.js';
import { PageTabs } from 'v1/components/shared';
import ProductionSidebarDrawerResources from '../ProductionSidebarDrawerResources/ProductionSidebarDrawerResources';
import ProductionSidebarDrawerNotes from '../ProductionSidebarDrawerNotes/ProductionSidebarDrawerNotes';
import ProductionSidebarDrawerFiles from '../ProductionSidebarDrawerFiles/ProductionSidebarDrawerFiles';

const ProductionSidebarDrawerTabs = ({ production, options }) => {
  const [activeTab, setActiveTab] = useState('RESOURCES');
  const [defaultSlotId, setDefaultSlotId] = useState(options.selectedSlotId);

  useEffect(() => {
    if (activeTab !== 'RESOURCES' && defaultSlotId) setDefaultSlotId(null);
  }, [activeTab]);

  useEffect(() => {
    if (options.selectedSlotId) setDefaultSlotId(options.selectedSlotId);
  }, [options.selectedSlotId]);

  const canUpdate = useRestrictedAccess(
    undefined,
    Capability.PRODUCTION_UPDATE
  );

  const resourceCount = production.resource_slots.reduce(
    (acc, slot) => acc + slot.resource_slot_assignments.length,
    0
  );

  const getProductionNotesCount = useMemo(makeGetProductionNotesCount, []);
  const notesCount = useSelector(state =>
    getProductionNotesCount(state, { production_id: production.id })
  );
  const getProductionFilesCount = useMemo(makeGetProductionFilesCount, []);
  const filesCount = useSelector(state =>
    getProductionFilesCount(state, { production_id: production.id })
  );

  return (
    <div className="ProductionSidebarDrawerTabs">
      <PageTabs
        tabs={[
          {
            text: `Resources (${resourceCount})`,
            container: 'RESOURCES'
          },
          {
            text: `Notes (${notesCount})`,
            container: 'NOTES'
          },
          {
            text: `Files (${filesCount})`,
            container: 'FILES'
          }
        ]}
        activeContainer={activeTab}
        onTabSelect={tab => setActiveTab(tab)}
        borderBottom
      />
      <div className="ProductionSidebarDrawerTabs-container">
        {activeTab === 'RESOURCES' && (
          <ProductionSidebarDrawerResources
            production={production}
            defaultSlotId={defaultSlotId}
          />
        )}
        {activeTab === 'NOTES' && (
          <ProductionSidebarDrawerNotes
            productionId={production.id}
            productionTitle={production.title}
            disabled={!canUpdate}
          />
        )}
        {activeTab === 'FILES' && (
          <ProductionSidebarDrawerFiles
            productionId={production.id}
            productionTitle={production.title}
            disabled={!canUpdate}
          />
        )}
      </div>
    </div>
  );
};

ProductionSidebarDrawerTabs.propTypes = {
  production: PropTypes.object // TODO: type
};

export default ProductionSidebarDrawerTabs;
