import mixpanel from 'mixpanel-browser';
import MixpanelEvent from './MixpanelEvent';
import Account from 'lib/auth/Account';

class Mixpanel {
  /**
   * Array of Mixpanel event trackers
   * @type {Object[]}
   * @private
   */
  _trackers = [];

  initialise(token) {
    if (!token) {
      console.warn(
        'skipping initialising mixpanel as the ID token environment was not been provided'
      );
      return;
    }
    mixpanel.init(token);
  }

  /**
   * Return the mixpanel client
   * @return {Object|null}
   */
  get client() {
    return mixpanel;
  }

  /**
   * Register a new event tracker
   * @param {Object} tracker
   */
  withTracker(tracker) {
    this._trackers.push(tracker);
  }

  /**
   * Keep track of events for the authenticated user
   * @param accountId
   */
  identify(accountId) {
    this.client.identify(accountId);
  }

  /**
   * Track a given action by creating a mixpanel event and dispatching the event to
   * all registered trackers.
   * @param {string} action
   * @param {Object} [properties={}]
   */
  track(action, properties = {}) {
    const event = new MixpanelEvent(action, {
      tenantId: Account.tenantId(),
      workspaceId: Account.resolveFirstWorkspaceId(),
      accountId: Account.accountId(),
      ...properties
    });
    this._trackers.forEach(tracker => tracker.track(event));
  }
}

export default new Mixpanel();
