import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const SLOT_CATEGORIES_ACTIONS = mapValues(
  mirror({
    CREATE_SLOT_CATEGORY: null,
    CREATE_SLOT_CATEGORY_SUCCESS: null,
    CREATE_SLOT_CATEGORY_FAILURE: null,

    DELETE_SLOT_CATEGORY: null,
    DELETE_SLOT_CATEGORY_SUCCESS: null,
    DELETE_SLOT_CATEGORY_FAILURE: null,

    UPDATE_SLOT_CATEGORY: null,
    UPDATE_SLOT_CATEGORY_SUCCESS: null,
    UPDATE_SLOT_CATEGORY_FAILURE: null,

    UPDATE_SLOT_CATEGORIES_ORDER: null,
    UPDATE_SLOT_CATEGORIES_ORDER_SUCCESS: null,
    UPDATE_SLOT_CATEGORIES_ORDER_FAILURE: null
  }),
  value => value
);
