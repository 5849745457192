import React from 'react';

import { Grid, GridCell, ListCell, PressStud } from 'v1/components/shared';
import { SlotCategoryDropdown } from 'v1/components/shared';

const CellBudgetExpense = ({ item, onChange, onDelete }) => {
  return (
    <ListCell
      className="CellBudgetExpense draggable"
      padding="XS"
      data-id={item.id}
    >
      <Grid gutters="S" vcenter className="Parent_hoverListener">
        <GridCell width="auto">
          <span className="DragIndicator">
            <img src="/images/icon_drag.svg" alt="" />
          </span>
        </GridCell>
        <GridCell>
          <SlotCategoryDropdown
            onSelect={(field, value) => {
              onChange(item.id, field, value);
            }}
            type="EXPENSE"
            buttonClass="emulate-input emulate-input_underline"
            slot={item}
          />
        </GridCell>
        <GridCell width="auto" className="Child_hoverListener">
          <PressStud icon="delete" action={() => onDelete(item.id)} />
        </GridCell>
      </Grid>
    </ListCell>
  );
};

CellBudgetExpense.propTypes = {};

export default CellBudgetExpense;
