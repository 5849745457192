import classNames from 'classnames';

export function SelectOptionError({ message }: { message?: string }) {
  return (
    <div
      className={classNames(
        'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-text-red-600'
      )}
    >
      {message ?? 'Error'}
    </div>
  );
}
