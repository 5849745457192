import { Shortlist, Shortlist_normalized } from '__types__/index';
import { createShortlistUpdatePayload } from 'v4/entities/shortlist/shortlist.converters';
import { ApiClient } from '../../../lib/api/ApiClient.types';
import { ListResponse } from '../common/common.crud.types';
import { ShortlistListQuery } from './shortlist.types';

export function createShortlistsApi(client: ApiClient, token = true) {
  return {
    list: async (query: object) => {
      return client.post<
        ListResponse<Shortlist_normalized, ShortlistListQuery>
      >('/search/shortlists', { data: query, token });
    },
    detail: async (id: Shortlist['id']) => {
      return client.get<Shortlist_normalized>(`/shortlists/${id}`, { token });
    },
    detailUpdate: async (id: Shortlist['id'], data: Partial<Shortlist>) => {
      const convertedPayload = createShortlistUpdatePayload(data);
      return client.put<Shortlist>(`/shortlists/${id}`, {
        data: convertedPayload,
        token
      });
    }
  };
}

export type ShortlistsApi = ReturnType<typeof createShortlistsApi>;
