import React from 'react';
import PropTypes from 'prop-types';

import { RateSelector } from 'v1/components/shared';

const ResourceRatesFooter = ({
  rateCount,
  readOnly,
  isEditing,
  isExpanded,
  toggleIsExpanded,
  addCustomRate,
  addRateCard
}) => {
  return (
    <div className="ResourceRatesFooter">
      {!readOnly && (isEditing || !rateCount) && (
        <div className="inline">
          <a className="genericLink underline" onClick={addCustomRate}>
            Add custom rate
          </a>
          {/* TODO: we're gonna need a bigger interpunct */}
          <span className="ResourceRates-interpunct text-11-700-eggplant">
            ·
          </span>
          <RateSelector
            label="Add rate card"
            labelClass="btn genericLink underline"
            showRateCards
            onSelect={rate => addRateCard(rate.id)}
          />
        </div>
      )}
      {!isEditing && rateCount > 1 && (
        <>
          {isExpanded ? (
            <a
              className="genericLink genericLink__subtle underline"
              onClick={toggleIsExpanded}
            >
              Collapse rates
            </a>
          ) : (
            <a
              className="genericLink genericLink__subtle underline"
              onClick={toggleIsExpanded}
            >
              Show all rates
            </a>
          )}
        </>
      )}
    </div>
  );
};

ResourceRatesFooter.props = {
  rateCount: PropTypes.number,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  isExpanded: PropTypes.bool,
  toggleIsExpanded: PropTypes.func,
  addRate: PropTypes.func,
  addRateCard: PropTypes.func
};

export default ResourceRatesFooter;
