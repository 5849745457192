import {
  AssociationQuery,
  AssociationState,
  mutationSchemas,
  ShortlistBlock_normalized
} from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { ListQuery, ListResponse } from 'v4/entities/common/common.crud.types';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { Resource_normalized } from 'v4/entities/resource/resource.types';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { canBlockAffectAssociation } from './resource__shortlist.reducer.helpers';

export const createReorderEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi,
  useEnsureList: () => (
    query: AssociationQuery
  ) => Promise<
    ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
  >
) => {
  return defineEntityEffect({
    type: 'reorder',
    requestSchema: mutationSchemas.reorder.request,
    responseSchema: mutationSchemas.reorder.response,
    useQueryFn: () => {
      const api = useApi();
      const ensureList = useEnsureList();
      return async (request, signal) => {
        const allBlocks = await ensureList({
          shortlist_id: request.shortlist_id
        });

        const blockUpdates = request.orderedBlockIds
          .map(associationId => {
            const block = allBlocks.results.find(
              block => block.id === associationId
            );
            return {
              associationId,
              oldOrder: block?.order,
              newOrder: request.orderedBlockIds.indexOf(associationId)
            };
          })
          .filter(({ oldOrder, newOrder }) => oldOrder !== newOrder)
          .filter(({ oldOrder }) => oldOrder !== -1);

        if (blockUpdates.length) {
          return await api.updateBatch(
            {
              shortlist_id: request.shortlist_id,
              payload: blockUpdates.map(({ associationId, newOrder }) => ({
                id: associationId,
                order: newOrder
              }))
            },
            signal
          );
        }
        return { success: true };
      };
    }
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        if (canBlockAffectAssociation(query, undefined, request.shortlist_id)) {
          // reordering happens on shortlist view, so we don't optimistically touch other views
          // order is not relevant when displaying blocks from multiple shortlists (ie resource's view of associated shortlists)
          return {
            ...state,
            results: state.results.map(block => ({
              ...block,
              order: request.orderedBlockIds.indexOf(block.id)
            }))
          };
        }
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      return {
        state: config.optimisticReconciler(state, request, query),
        shouldInvalidate: false
      };
    })
    .build();
};
