import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field, PasswordInput } from 'shared';
import { FieldProps } from '../../../form/Field/FieldProps';

type PasswordInputProps = {
  placeholder: string;
};

const ConfirmPasswordField = ({
  name,
  label,
  size,
  placeholder
}: FieldProps & PasswordInputProps) => {
  const { getValues } = useFormContext();

  const confirmedPasswordRules = {
    validate: {
      matching: (value: string) => value === getValues().password
    }
  };

  const confirmedPasswordErrors = {
    matching: 'Password fields do not match'
  };
  return (
    <Field
      label={label}
      name={name}
      rules={confirmedPasswordRules}
      errors={confirmedPasswordErrors}
      size={size}
    >
      <PasswordInput placeholder={placeholder} />
    </Field>
  );
};

export default ConfirmPasswordField;
