import { TASKS_ACTIONS } from 'store/tasks/tasks.constants.js';
import { omit } from 'lodash';

const token = true;
const isV2 = true;

export const createTask = task => {
  const toSubmit = omit(task, ['taskId']);
  return {
    types: [
      TASKS_ACTIONS.UPDATE_TASK,
      TASKS_ACTIONS.UPDATE_TASK_SUCCESS,
      TASKS_ACTIONS.UPDATE_TASK_FAILURE
    ],
    promise: client =>
      client.post(`/core/teams/:app_id:/tasks`, {
        data: toSubmit,
        token,
        isV2
      }),
    task
  };
};

export const updateTask = (taskId, task) => {
  const toSubmit = omit(task, ['taskId']);
  return {
    types: [
      TASKS_ACTIONS.UPDATE_TASK,
      TASKS_ACTIONS.UPDATE_TASK_SUCCESS,
      TASKS_ACTIONS.UPDATE_TASK_FAILURE
    ],
    promise: client =>
      client.patch(`/core/teams/:app_id:/tasks/${taskId}`, {
        data: toSubmit,
        token,
        isV2
      }),
    task
  };
};

export const deleteTask = taskId => {
  return {
    types: [
      TASKS_ACTIONS.DELETE_TASK,
      TASKS_ACTIONS.DELETE_TASK_SUCCESS,
      TASKS_ACTIONS.DELETE_TASK_FAILURE
    ],
    promise: client =>
      client.del(`/core/teams/:app_id:/tasks/${taskId}`, { token, isV2 }),
    taskId
  };
};
