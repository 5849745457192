import { createSelector } from 'reselect';
import _keys from 'lodash/keys';
import _orderBy from 'lodash/orderBy';
import { get } from 'lodash';

export const getBookingStore = state => state.bookings;

const getBooking = (state, props = {}) =>
  state.bookings.data[get(props, 'bookingId')];

export const makeGetBooking = () =>
  createSelector([getBooking], booking => booking);

// TODO: cloned from selectors/productions
export const getBookingTypes = createSelector(
  state => state.booking_types.data,
  (_, { archived = false } = { archived: false }) => archived,
  (data, archived) => {
    const types = _keys(data).reduce((result, id) => {
      if (!archived && data[id].archived) return result;
      return result.concat(data[id]);
    }, []);
    return _orderBy(types, ['order', 'id'], ['asc', 'asc']);
  }
);
