import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isEmpty, isNil } from 'lodash';

import TypeContactRate from 'v1/components/shared/byModel/Rate/__types__/TypeContactRate';
import TypeRate from 'v1/components/shared/byModel/Rate/__types__/TypeRate';
import { Grid, GridCell } from 'v1/components/shared';
import RateItemEditable from './RateItemEditable/RateItemEditable';
import RateItemStatic from './RateItemStatic/RateItemStatic';

import './RateItem.scss';

const RateItem = ({
  rate,
  readOnly,
  isEditing,
  inset,
  onClick,
  onChange,
  onRemove
}) => {
  const rateCards = useSelector(state => state.rates.data);
  const isRateCard = !isNil(get(rate, 'rate_id'));

  // Choose which rate data gets displayed
  const displayRate = isRateCard ? get(rateCards, rate.rate_id) : rate;

  const handleClick = () => {
    if (onClick && !isEditing) {
      onClick(displayRate);
    }
  };

  if (!displayRate) {
    return null;
  }

  return (
    <Grid
      className={classnames('RateItem', {
        'stack-XS': !inset,
        RateItem_inset: inset,
        clickable: handleClick
      })}
      onClick={handleClick}
    >
      {!readOnly && isEditing && (
        <GridCell width="auto">
          <div className="RateItem-drag">
            <img src="/images/icon_drag.svg" alt="" />
          </div>
        </GridCell>
      )}
      <GridCell>
        {!readOnly && ((isEditing && !isRateCard) || isEmpty(displayRate)) ? (
          <RateItemEditable
            rate={displayRate}
            onChange={onChange}
            onRemove={onRemove}
          />
        ) : (
          <RateItemStatic
            contactRateId={rate.id}
            rate={displayRate}
            isRateCard={isRateCard}
            readOnly={readOnly}
            isEditing={isEditing}
            onRemove={onRemove}
          />
        )}
      </GridCell>
    </Grid>
  );
};

RateItem.props = {
  rate: PropTypes.oneOf([TypeContactRate, TypeRate]).isRequired,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  inset: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

RateItem.defaultProps = {
  isEditing: false
};

export default RateItem;
