import React from 'react';
import PropTypes from 'prop-types';

// REDUX
import { useDispatch } from 'react-redux';
import { openContactSidebar } from 'store/v1/ui/ui.actions.js';

//COMPONENTS
import { SystemLink } from 'v1/components/shared';
import { ResourceMeta } from 'v1/components/shared';
import { Grid, ResourceBadgeIcon, GridCell } from 'v1/components/shared';

// IMPORTS
import classnames from 'classnames';

import './ResourceTitle.scss';

const ResourceTitle = ({
  className,
  truncate,
  resource = {},
  navigateToMethod,
  onSelect,
  hideRole
}) => {
  const dispatch = useDispatch();
  function renderButtonContent() {
    return (
      <span
        className={classnames('ResourceTitle-name text-13-700', {
          trunc: truncate
        })}
      >
        {resource.full_name}
        <br />
      </span>
    );
  }
  function renderPageRoute() {
    return (
      <SystemLink to={`/contacts/${resource.id}`}>
        {renderButtonContent()}
      </SystemLink>
    );
  }
  function renderSidebarRoute() {
    return (
      <div
        onClick={() => dispatch(openContactSidebar(resource))}
        className="ContactName-SidebarHint"
      >
        {renderButtonContent()}
        <img
          data-tip="Quick View Contact"
          className="ContactName-SidebarHint-icon"
          src="/images/icon_contact_sidebar_hint.svg"
          alt=""
        />
      </div>
    );
  }
  function renderFunctionRoute() {
    return (
      <div onClick={() => onSelect(resource)}>{renderButtonContent()}</div>
    );
  }
  function renderName() {
    switch (navigateToMethod) {
      case 'PAGE':
        return renderPageRoute();
      case 'SIDEBAR':
        return renderSidebarRoute();
      case 'FUNCTION':
        return renderFunctionRoute();
      default:
        return renderButtonContent();
    }
  }
  return (
    <Grid vcenter className={classnames('ResourceTitle', className)}>
      <GridCell width="auto">
        <ResourceBadgeIcon resource={resource} />
      </GridCell>
      <GridCell>
        <div className="ResourceTitle-titles">
          {renderName()}
          <ResourceMeta hideRole={hideRole} resource={resource} />
        </div>
      </GridCell>
    </Grid>
  );
};

ResourceTitle.propTypes = {
  className: PropTypes.string,
  truncate: PropTypes.bool,
  resource: PropTypes.object,
  navigateToMethod: PropTypes.oneOf(['PAGE', 'SIDEBAR', 'FUNCTION', false]),
  onSelect: PropTypes.func
};

ResourceTitle.defaultProps = {
  truncate: true,
  navigateToMethod: 'PAGE'
};

export default ResourceTitle;
