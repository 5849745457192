import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { ListQuery, ListResponse } from '../../common/common.crud.types';
import { Resource_normalized } from '../../resource/resource.types';
import { ResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { canBlockAffectAssociation } from './resource__shortlist.reducer.helpers';
import {
  AssociationQuery,
  AssociationState,
  mutationSchemas,
  ShortlistBlock_normalized
} from './resource__shortlist.schemas';

export const createDuplicateEffect = (
  useApi: () => ResourceShortlistAssociationEffectsApi,
  useEnsureList: () => (
    query: AssociationQuery
  ) => Promise<
    ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
  >
) =>
  defineEntityEffect({
    type: 'duplicate',
    requestSchema: mutationSchemas.duplicate.request,
    responseSchema: mutationSchemas.duplicate.response,
    useQueryFn: () => {
      const api = useApi();
      return async (request, signal) => {
        return api.duplicateBatch(request, signal);
      };
    }
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => state => {
        // we don't provide optimistic update for duplication because we don't have synchronous way to get block from the cache at the moment
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      const affectingRequests = request.filter(item =>
        canBlockAffectAssociation(
          query,
          item.new_resource_id,
          item.new_shortlist_id
        )
      );

      const processOne = (
        state: AssociationState,
        item: (typeof response)[0]
      ) => {
        const shouldAddAtBeginning = 'resource_id' in query; // todo replace with generic sorting once backend supports it in generic way
        return {
          ...state,
          paging: {
            ...state.paging,
            total: state.paging.total + 1
          },
          results: shouldAddAtBeginning
            ? [item, ...state.results]
            : [...state.results, item]
        };
      };
      return {
        state: response.reduce((state, item) => processOne(state, item), state),
        shouldInvalidate: true // backend can have some race conditions, so make sure queries are invalidated
      };
    })
    .build();
