import { Callsheet } from '../../../__types__';
import { ApiClient } from '../../../lib/api/ApiClient.types';
import {
  getCreatePayload,
  getUpdatePayload,
  getUpdateRecipientsPayload
} from './callsheets.converters';
import {
  AllApiResponse,
  DetailConfirmPayload,
  DetailPublicPayload,
  SendPayload,
  TeamMemberCreatePayload,
  UpdateRecipientsPayload
} from './callsheets.types';

export function createCallsheetsApi(client: ApiClient, token = true) {
  const getPublicQueryString = query => {
    const params = Object.keys(query);
    return params
      ? `?${params
          .filter(key => query[key])
          .map(key => `${key}=${query[key]}`)
          .join('&')}`
      : '';
  };
  return {
    async list(query: object, signal?: AbortSignal) {
      return client.post<AllApiResponse>(`/search/callsheets`, {
        data: query,
        token
      });
    },
    async item(id: number, signal?: AbortSignal) {
      return client.get<Callsheet>(`/callsheets/${id}`, { token });
    },
    async itemPublic(payload: DetailPublicPayload, signal?: AbortSignal) {
      const queryString = getPublicQueryString(payload.query);
      return client.get<{ public_settings: object; callsheet: Callsheet }>(
        `/public/callsheets/${payload.id}${queryString}`,
        { token }
      );
    },
    async confirm(payload: DetailConfirmPayload, signal?: AbortSignal) {
      const queryString = getPublicQueryString(payload.query);
      return client.put(
        `/notifications/callsheets/${payload.id}/${payload.notificationId}/confirm${queryString}`,
        {
          data: {},
          token
        }
      );
    },
    async create(payload: Callsheet, signal?: AbortSignal): Promise<Callsheet> {
      const data = getCreatePayload(payload);
      return client.post<Callsheet>(`/callsheets`, { data, token });
    },
    async update(payload: Callsheet, signal?: AbortSignal): Promise<Callsheet> {
      const data = getUpdatePayload(payload, payload.id);
      return client.put<Callsheet>(`/callsheets/${payload.id}`, {
        data,
        token
      });
    },
    async send(payload: SendPayload, signal?: AbortSignal) {
      return client.put(`/notifications/callsheets/${payload.id}`, {
        data: {
          recipient_ids: payload.recipient_ids,
          general_note: payload.note
        },
        token
      });
    },
    async delete(id: Callsheet['id'], signal?: AbortSignal) {
      return client.put(`/callsheets/${id}`, {
        data: { archived: true },
        token
      });
    },
    async unarchive(id: Callsheet['id'], signal?: AbortSignal) {
      return client.put(`/callsheets/${id}`, {
        data: { archived: false },
        token
      });
    },
    async addTeamMember(
      { id, data }: TeamMemberCreatePayload,
      signal?: AbortSignal
    ) {
      return client.post(`/callsheets/${id}/associations/team_members`, {
        data,
        token
      });
    },
    async updateRecipients(
      { id, data }: UpdateRecipientsPayload,
      signal?: AbortSignal
    ) {
      const payload = getUpdateRecipientsPayload(data);
      return client.put(`/callsheets/${id}`, { data: payload, token });
    }
  };
}

// todo this should be moved away from particular (HTTP) implementation
export type CallsheetsApi = ReturnType<typeof createCallsheetsApi>;
