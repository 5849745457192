import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LineSeparator = ({ label, className }) => (
  <div className={classNames('LoginFormSeparator', className)}>
    {label ? <label>{label}</label> : null}
  </div>
);

LineSeparator.defaultProps = {};

LineSeparator.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
};

export default LineSeparator;
