export const FILE_ICONS_BLACK = {
  csv: 'icon_file_document.svg',
  pdf: 'icon_file_pdf.svg',
  jpg: 'icon_file_image.svg',
  jpeg: 'icon_file_image.svg',
  png: 'icon_file_image.svg',
  gif: 'icon_file_image.svg',
  google: 'icon_file_document.svg',
  mp4: 'icon_file_document.svg',
  mov: 'icon_file_document.svg',
  default: 'icon_file_document.svg'
};

export const FILE_ICONS_WHITE = {
  csv: 'icon_file_document.svg',
  pdf: 'icon_file_pdf.svg',
  jpg: 'icon_file_image.svg',
  jpeg: 'icon_file_image.svg',
  png: 'icon_file_image.svg',
  gif: 'icon_file_image.svg',
  google: 'icon_file_document.svg',
  mp4: 'icon_file_document.svg',
  mov: 'icon_file_document.svg',
  default: 'icon_file_document.svg'
};

export function renderIcon(name, type) {
  const ICONS = type === 'light' ? FILE_ICONS_WHITE : FILE_ICONS_BLACK;

  let extension = name ? name.split('.').pop().toLowerCase() : 'default';
  let icon = ICONS[extension] ? ICONS[extension] : ICONS['default'];
  return `/images/${icon}`;
}

export function isImageFromType(type) {
  switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/heic':
    case 'image/tif':
    case 'image/avif':
    case 'image/bmp':
      return true;
    default:
      return false;
  }
}

export const parseFileType = file => {
  if (!file) return;
  switch (file.mime_type) {
    case 'text/csv':
      return 'FILE';
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/heic':
    case 'image/tif':
    case 'image/tiff':
    case 'image/avif':
    case 'image/bmp':
    case 'application/pdf':
      return 'IMAGE';
    case 'video/mp4':
    case 'video/mov':
    case 'video/mkv':
    case 'video/mpeg':
    case 'video/ogv':
    case 'video/webm':
    case 'video/wmv':
    case 'video/avi':
    case 'video/quicktime':
    case 'video/x-ms-wmv':
      return 'VIDEO';
    default:
      return 'FILE';
  }
};
