import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { updateProduction } from 'store/v1/productions/productions.actions';
import { getStatuses } from 'store/v1/statuses/statuses.selectors.js';
import { getProductionStatus } from 'store/v1/productions/productions.selectors.js';

import { StatusSelector } from 'v1/components/shared';

import * as entityTypes from 'v1/helpers/entityTypes';

const AUTOMATED_PRODUCTION_STATUSES = ['READY', 'ACTIVE', 'ALERT'];
const AUTOMATED_STATUS_DESCRIPTIONS = {
  READY: 'Production is fully resourced',
  ACTIVE: 'Production is ready and has begun shooting',
  ALERT: 'Production is at risk of not being ready on time'
};

const ProductionStatusSelector = ({ production = {}, size }) => {
  const dispatch = useDispatch();
  const productionStatus = useSelector(state =>
    getProductionStatus(state, production)
  );

  const statuses = useSelector(state =>
    getStatuses(state, { entity_type: entityTypes.PRODUCTION })
  );

  function onUpdateProductionStatus(status) {
    dispatch(updateProduction(production.id, { status_id: status.id }));
  }

  const statusGroups = [
    {
      label: 'User Actions',
      type: 'USER',
      statuses: statuses.filter(
        s => !AUTOMATED_PRODUCTION_STATUSES.includes(s.status_type)
      )
    },
    {
      label: 'Automated Actions',
      type: 'AUTOMATED',
      descriptions: AUTOMATED_STATUS_DESCRIPTIONS,
      statuses: statuses.filter(s =>
        AUTOMATED_PRODUCTION_STATUSES.includes(s.status_type)
      )
    }
  ];

  return (
    <StatusSelector
      statusGroups={statusGroups}
      statuses={statuses}
      selectedStatus={productionStatus}
      onSelect={onUpdateProductionStatus}
      size={size}
    />
  );
};

ProductionStatusSelector.propTypes = {
  production: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['S', 'M', 'L'])
};

export default ProductionStatusSelector;
