/**
 * @deprecated use RemoteData2 instead
 *
 * This one appears a bit difficult, when typed so strictly.
 */
export type RemoteData<T> =
  | ({ status: 'pending' } & (
      | { data: undefined; isPlaceholderData: false }
      | { data: T; isPlaceholderData: true }
    ))
  | ({ status: 'error'; error: Error } & (
      | { data: undefined; isPlaceholderData: false }
      | { data: T; isPlaceholderData: true }
    ))
  | {
      status: 'success';
      data: T;
      // state in which we show data during refetching
      isPlaceholderData: false;
    };

export type RemoteData2<T> =
  | { status: 'pending'; isFetching: boolean; refetch: () => void }
  | { status: 'error'; error: Error; isFetching: boolean; refetch: () => void }
  | {
      status: 'success';
      data: T;
      isPlaceholderData: boolean;
      isFetching: boolean;
      refetch: () => void;
    };

export const rd = {
  map<T, U>(remoteData: RemoteData2<T>, f: (data: T) => U): RemoteData2<U> {
    switch (remoteData.status) {
      case 'pending':
        return remoteData;
      case 'error':
        return remoteData;
      case 'success':
        return { ...remoteData, data: f(remoteData.data) };
    }
  },
  getOrElse<T, U>(remoteData: RemoteData2<T>, f: () => U): T | U {
    switch (remoteData.status) {
      case 'pending':
      case 'error':
        return f();
      case 'success':
        return remoteData.data;
    }
  },
  then<T, U>(first: RemoteData2<T>, second: RemoteData2<U>): RemoteData2<U> {
    switch (first.status) {
      case 'pending':
      case 'error':
        return first;
      case 'success':
        return second;
    }
  }
};
