import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Notes.scss';
import NotesList from './NotesList/NotesList';
import NotesForm from './NotesForm/NotesForm';

const Notes = ({
  className,
  entityId,
  contentName,
  contentType,
  createNote,
  deleteNote,
  notes,
  events,
  displayAlert,
  disabled
}) => {
  const displayAlertCallback = useCallback(() => displayAlert, [displayAlert]);
  return (
    <div className={classnames('Notes', className)}>
      {!disabled && (
        <NotesForm
          entityId={entityId}
          events={events}
          createNote={createNote}
          displayAlert={displayAlertCallback}
        />
      )}
      <NotesList
        entityId={entityId}
        contentName={contentName}
        contentType={contentType}
        notes={notes}
        events={events}
        deleteNote={deleteNote}
        displayAlert={displayAlertCallback}
        disabled={disabled}
      />
    </div>
  );
};

Notes.propTypes = {
  className: PropTypes.string,
  entityId: PropTypes.number, // The id of the content you're commenting on
  entityType: PropTypes.string, // Data type to fetch associations from, eg 'contacts'
  contentName: PropTypes.string, // User-facing copy - The name of the content you're commenting on - e.g. 'Scott'
  contentType: PropTypes.string, // User-facing copy - The type of the content you're commenting on - e.g. 'contact'
  createNote: PropTypes.func, // The correct corresponding redux action
  deleteNote: PropTypes.func, // The correct corresponding redux action
  disabled: PropTypes.bool
};

export default Notes;
