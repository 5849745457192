import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ComingSoonPlaceholder.scss';

class ComingSoonPlaceholder extends Component {
  render() {
    return (
      <div className="ComingSoonPlaceholder">
        <img src="/images/icon_colour_coming_soon.svg" width="50px" alt="" />
        <br />
        <br />
        <h3>
          {this.props.title ? this.props.title : 'Feature in development'}
        </h3>
        <p>
          {this.props.description
            ? this.props.description
            : 'We’re rolling out these feature so this is not quite ready yet. If you have any questions about this feature get in touch!'}
        </p>
        <a href="mailto:support@stitchhelp.zendesk.com" className="genericLink">
          Get in touch
        </a>
      </div>
    );
  }
}

ComingSoonPlaceholder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default ComingSoonPlaceholder;
