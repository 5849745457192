export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => async action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { promise, types, ...rest } = action;
    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    try {
      const body = await promise(client);
      return next({ ...rest, result: body, type: SUCCESS });
    } catch (err) {
      console.error(err);
      return next({ ...rest, error: err, type: FAILURE });
    }
  };
}
