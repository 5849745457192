import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { decodeJwt } from 'jose';
import { withRouter } from 'react-router-dom';
import { Loading } from 'v1/components/shared';
import AuthService from 'lib/auth/AuthService';
import Api from 'lib/auth/authentication';
import Account from 'lib/auth/Account';
import { Mixpanel, MixActions } from 'lib/mixpanel';
import withAuth from 'v1/hocs/withAuth';

import { getTeamAccounts } from 'store/accounts';
import { getAccountTeams } from 'store/teams';
import { getTeamUserGroups } from 'store/userGroups';

export class AuthHandler extends Component {
  getFirstWorkspace = () => {
    const { history } = this.props;
    const workspace = Account.resolveFirstWorkspace();
    if (!workspace) {
      console.log('failed to get first workspace from auth token');
      return history.replace('/unauthorized');
    }
    return workspace;
  };

  async componentDidMount() {
    const { actions, history } = this.props;
    console.log('authenticating');

    // If we are already authenticated, redirect to default workspace dashboard
    if (AuthService.isAuthenticated()) {
      console.log('user already logged in, redirecting to dashboard');
      const workspace = this.getFirstWorkspace();
      return history.replace(`/app/${workspace.db_schema}/dashboard`);
    }

    const cookies = new Cookies();
    const cookieName = 'AtellioAccessToken';
    const tokenString = cookies.get(cookieName);
    if (!tokenString) {
      console.log('access cookie not found, redirecting to login');
      return history.replace('/login');
    }

    // Remove cookie, no longer needed.
    cookies.remove(cookieName);

    // Fetch the workspaces
    const { data: workspaces } = await Api.getWorkspaces(tokenString);
    if (workspaces.length === 0) {
      console.log('account does not belong to any workspace');
      return history.replace('/unauthorized');
    }

    const token = {
      ...decodeJwt(tokenString),
      workspaces,
      tokenString
    };

    AuthService.token(token);

    actions.loadAuth(token, token.tokenString, null);
    this.props.getTeamAccounts();
    this.props.getTeamUserGroups();
    this.props.getAccountTeams();

    Mixpanel.track(MixActions.USER_SIGN_IN, {
      email: token.account.email,
      authenticationMethod: token.authMethod
    });

    const workspace = this.getFirstWorkspace();
    history.replace(`/app/${workspace.db_schema}/dashboard`);
  }
  render() {
    return (
      <Loading text="Stitching your account together" className="pageCenter" />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getTeamAccounts, getAccountTeams, getTeamUserGroups },
    dispatch
  );
}

export default withRouter(
  connect(null, mapDispatchToProps)(withRouter(withAuth(AuthHandler)))
);
