import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UserCircleImage } from 'v1/components/shared';
import { PressStud } from 'v1/components/shared';
import ResourceFieldsDisplay from '../ResourceFieldsDisplay/ResourceFieldsDisplay';
import {
  MediaCarousel,
  EmptyGeneric,
  ResourceStatusGetter,
  ResourceStatusDisplay
} from 'v1/components/shared';
import ShortlistVotes from '../ShortlistVotes/ShortlistVotes';

import {
  upvoteShortlistBlock,
  downvoteShortlistBlock
} from 'store/v1/shortlists/shortlists.actions.js';
import { selectActiveAccountId } from 'store/session';
import { openModal } from 'store/v1/ui/ui.actions.js';

import { get, isEmpty, size, filter } from 'lodash';
import Text from 'react-format-text';
import { v4 } from 'uuid';
import './PublicShortlistContactItem.scss';

export class PublicShortlistContactItem extends Component {
  onUpvote = () => {
    const { shortlist, isGuest, user, block } = this.props;
    const { id, public_id } = shortlist;
    const guest = isGuest ? user : null;

    if (guest && !guest.full_name) {
      return this.props.openModal('SetGuestNameModal');
    }

    const toSubmit = { shortlist_block_id: block.id, guest };

    this.setState({ event: v4() }, () => {
      this.props.upvoteShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  onDownvote = () => {
    const { id, public_id } = get(this.props, 'shortlist');
    const blockID = get(this.props, 'block.id');

    const guest = this.props.isGuest ? get(this.props, 'user') : null;

    if (this.props.isGuest && !guest.full_name) {
      return this.props.openModal('SetGuestNameModal');
    }

    const toSubmit = { shortlist_block_id: blockID, guest };

    this.setState({ event: v4() }, () => {
      this.props.downvoteShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  renderHeader = block => {
    const { contact } = block;
    return (
      <div className="PublicShortlistContactItem-header">
        <UserCircleImage
          src={contact.profile_picture}
          className="PublicShortlistContactItem-header-image"
          contact_id={contact.id}
          initials={{
            first_name: contact.first_name,
            last_name: contact.last_name
          }}
        />
        <div className="PublicShortlistContactItem-header-details">
          <h2>
            {contact.first_name || ''} {contact.last_name || ''}
          </h2>
          {contact.role && <h5>{contact.role}</h5>}
        </div>
        {this.renderFeedback(block)}
      </div>
    );
  };
  renderFeedback = block => {
    const {
      enableVoting,
      enableCommenting,
      showFeedback,
      expandBlock
    } = this.props;
    const { loading } = this.props.shortlists;
    const commentCount = size(block.comments);

    // NOTE: this is pretty much duped in ShortlistContactItemGrid
    if (enableVoting || enableCommenting) {
      return (
        <div className="ShortlistPresentContactItem-header-actions">
          {enableVoting && (
            <ShortlistVotes
              block={block}
              user={this.props.user}
              isGuest={!this.props.activeAccountId}
              onUpvote={this.onUpvote}
              onDownvote={this.onDownvote}
              loading={loading}
              showFeedback={showFeedback}
            />
          )}
          {enableCommenting && (
            <span className="ShortlistPresentContactItem-header-actions-item">
              <PressStud
                label={showFeedback ? commentCount : null}
                icon="comments"
                size="S"
                action={() => expandBlock()}
              />
            </span>
          )}
        </div>
      );
    }
  };
  renderNote = block => {
    if (get(block, 'data.paragraph')) {
      return (
        <div className="PublicShortlistContactItem-section">
          <div className="form-label">Note</div>
          <p>
            <Text>{get(block, 'data.paragraph')}</Text>
          </p>
        </div>
      );
    }
  };
  renderGallery = block => {
    const pins = get(block, 'pins');
    const formattedPins = filter(pins, p => !p.archived);

    if (!isEmpty(formattedPins)) {
      return (
        <div className="PublicShortlistContactItem-gallery">
          <MediaCarousel
            media={formattedPins}
            PROVIDER="PINS"
            layout="INLINE"
          />
        </div>
      );
    }
  };
  renderContactInfo = block => {
    const { default_fields, shortlist_field_mapper } = this.props;
    return (
      <div className="PublicShortlistContactItem-section">
        <ResourceFieldsDisplay
          fields={default_fields}
          fieldMapper={shortlist_field_mapper}
          resource={block.contact}
        />
      </div>
    );
  };
  renderAvailability = block => {
    const shortlist = get(this.props, 'shortlist', {});
    if (shortlist.show_availability) {
      const publicBooking = !isEmpty(block.booking_events)
        ? block.booking_events.map(booking => ({
            status_id: get(booking, 'booking_status_id'),
            events: [booking]
          }))
        : [];
      return (
        <div className="PublicShortlistContactItem-section">
          <ResourceStatusGetter
            resourceId={get(block, 'contact_id')}
            dateRange={shortlist.show_availability}
          >
            {({ bookings = [] }) => {
              const renderBookings = bookings.length ? bookings : publicBooking;

              return renderBookings.length ? (
                renderBookings.map(booking => (
                  <ResourceStatusDisplay
                    booking={booking || publicBooking}
                    hideAssignments
                  />
                ))
              ) : (
                <span />
              );
            }}
          </ResourceStatusGetter>
        </div>
      );
    }
  };
  render() {
    const { block } = this.props;
    if (!block.contact) {
      return (
        <EmptyGeneric
          icon="/images/icon_colour_team.svg"
          title="Contact Delete"
          description="This contact has been deleted. You can remove this block on the left"
        />
      );
    }
    return (
      <div className="PublicShortlistContactItem">
        {this.renderHeader(block)}
        {this.renderGallery(block)}
        {this.renderAvailability(block)}
        {this.renderContactInfo(block)}
        {this.renderNote(block)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAccountId: selectActiveAccountId(state),
    shortlists: state.shortlists,
    contacts: state.contacts,
    events: state.events
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      upvoteShortlistBlock,
      downvoteShortlistBlock,
      openModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicShortlistContactItem);
