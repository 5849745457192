import { STATUSES_ACTIONS } from 'store/v1/statuses/statuses.constants.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STATUSES_ACTIONS.GET_STATUSES:
    case STATUSES_ACTIONS.CREATE_STATUS:
    case STATUSES_ACTIONS.UPDATE_STATUS:
    case STATUSES_ACTIONS.UPDATE_STATUSES_ORDER:
    case STATUSES_ACTIONS.DELETE_STATUS:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AUTH_ACTIONS.LOAD_AUTH_SUCCESS: {
      const { statuses = [] } = action.result;
      return {
        ...state,
        data: statuses.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: statuses.map(item => item.id),
        loading: false,
        fetched: true
      };
    }
    case STATUSES_ACTIONS.GET_STATUSES_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        fetched: true
      };
    case SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST_SUCCESS:
      if (action.result.statuses) {
        return {
          ...state,
          data: action.result.statuses.reduce(
            (data, item) => ({ ...data, [item.id]: item }),
            {}
          ),
          order: action.result.statuses.map(item => item.id),
          paging: action.result.paging,
          loading: false,
          fetched: true
        };
      }
      return state;
    case STATUSES_ACTIONS.UPDATE_STATUSES_ORDER_SUCCESS:
      return {
        ...state,
        data: action.result.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        order: action.result.map(item => item.id),
        loading: false
      };
    case STATUSES_ACTIONS.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        loading: false
      };
    case STATUSES_ACTIONS.CREATE_STATUS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case STATUSES_ACTIONS.DELETE_STATUS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            archived: true
          }
        },
        order: state.order.filter(id => id !== action.id),
        loading: false
      };
    case STATUSES_ACTIONS.GET_STATUSES_FAILURE:
    case STATUSES_ACTIONS.UPDATE_STATUS_FAILURE:
    case STATUSES_ACTIONS.CREATE_STATUS_FAILURE:
    case STATUSES_ACTIONS.DELETE_STATUS_FAILURE:
    case STATUSES_ACTIONS.UPDATE_STATUSES_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
