import {
  hasCharacterLength,
  hasNumbers,
  hasUpperCase
} from 'lib/auth/passwordValidation';
import React from 'react';
import PasswordInput, {
  PasswordInputProps
} from 'shared/byModel/Text/PasswordInput/PasswordInput';
import RestrictedInput from 'shared/inputs/RestrictedInput/RestrictedInput';

type UserPasswordInputProps = PasswordInputProps;

const UserPasswordInput = ({
  isRestricted,
  ...restProps
}: UserPasswordInputProps) => {
  if (isRestricted) {
    return <RestrictedInput {...restProps} />;
  }

  return <PasswordInput {...restProps} />;
};

UserPasswordInput.rules = {
  validate: {
    hasMinimumLength: (value: string) => hasCharacterLength(value, 8),
    hasUpperCase: (value: string) => hasUpperCase(value),
    hasNumber: (value: string) => hasNumbers(value)
  }
};

UserPasswordInput.errors = {
  hasMinimumLength: 'Your password should have at least 8 characters',
  hasUpperCase: 'Your password should have at least 1 upper case character',
  hasNumber: 'Your password should have at least 1 number'
};

export default UserPasswordInput;
