import { STORAGE_PROVIDER_ACTIONS } from 'store/v1/storage_providers/storage_providers.constants.js';

const token = true;

export function getStorageProviders(event_id = null) {
  return {
    types: [
      STORAGE_PROVIDER_ACTIONS.GET_STORAGE_PROVIDERS,
      STORAGE_PROVIDER_ACTIONS.GET_STORAGE_PROVIDERS_SUCCESS,
      STORAGE_PROVIDER_ACTIONS.GET_STORAGE_PROVIDERS_FAILURE
    ],
    promise: client => client.get('/storage/providers', { token }),
    event_id
  };
}

export function createStorageProvider(data, event_id = null) {
  return {
    types: [
      STORAGE_PROVIDER_ACTIONS.CREATE_STORAGE_PROVIDER,
      STORAGE_PROVIDER_ACTIONS.CREATE_STORAGE_PROVIDER_SUCCESS,
      STORAGE_PROVIDER_ACTIONS.CREATE_STORAGE_PROVIDER_FAILURE
    ],
    promise: client => client.post('/storage/providers', { token, data }),
    event_id
  };
}

export function disableStorageProvider(id, event_id = null) {
  return {
    types: [
      STORAGE_PROVIDER_ACTIONS.DISABLE_STORAGE_PROVIDER,
      STORAGE_PROVIDER_ACTIONS.DISABLE_STORAGE_PROVIDER_SUCCESS,
      STORAGE_PROVIDER_ACTIONS.DISABLE_STORAGE_PROVIDER_FAILURE
    ],
    promise: client => client.del(`/storage/providers/${id}`, { token, id }),
    event_id
  };
}
