import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _get from 'lodash/get';
import _sum from 'lodash/sum';
import './STable.scss';

const STable = ({ data, columns, column, ...props }) => {
  const rowMinWidth = useMemo(
    () => _sum(columns.map(c => c.minWidth || column.minWidth)),
    [columns, column.minWidth]
  );

  const [sortBy, setSortBy] = useState(null);

  function renderTableCell(item, col) {
    if (col.accessor)
      return typeof col.accessor === 'function'
        ? col.accessor(item)
        : _get(item, col.accessor, '');
    if (col.Cell)
      return typeof col.Cell === 'function' ? col.Cell(item) : col.Cell;
    return null;
  }

  return (
    <div
      className={classnames('STable', props.className)}
      role="table"
      style={props.style}
    >
      <div
        className="STable-tableHeader"
        role="rowgroup"
        style={{ minWidth: rowMinWidth }}
      >
        <div className="STable-row" role="row">
          {columns.map((col, index) => {
            const colProps = { ...column, ...col };
            return (
              <div
                key={index}
                className={classnames(
                  'STable-header',
                  colProps.headerClassName,
                  {
                    sortable: col.sortable,
                    sortedAsc:
                      sortBy &&
                      sortBy.column_id === col.id &&
                      sortBy.direction === 'asc',
                    sortedDesc:
                      sortBy &&
                      sortBy.column_id === col.id &&
                      sortBy.direction === 'desc'
                  }
                )}
                role="columnheader"
                style={{
                  width: colProps.width,
                  minWidth: colProps.minWidth,
                  ...colProps.headerStyle
                }}
                onClick={() => {
                  if (!col.sortable) return;
                  if (
                    sortBy == null ||
                    (sortBy && sortBy.column_id !== col.id)
                  ) {
                    setSortBy({ column_id: col.id, direction: 'asc' });
                    props.onSortChange(col.id, 'asc');
                  } else if (
                    sortBy.column_id === col.id &&
                    sortBy.direction === 'asc'
                  ) {
                    setSortBy({ column_id: col.id, direction: 'desc' });
                    props.onSortChange(col.id, 'desc');
                  } else {
                    setSortBy(null);
                    props.onSortChange(col.id, null);
                  }
                }}
              >
                {typeof colProps.Header === 'function' ? (
                  <colProps.Header />
                ) : (
                  colProps.Header
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="STable-tableBody"
        role="rowgroup"
        style={{ minWidth: rowMinWidth }}
      >
        {data.length > 0 &&
          data.map((item, index) => (
            <div
              key={index}
              className={classnames('STable-row', {
                even: index % 2 === 0,
                odd: index % 2 !== 0
              })}
              role="row"
            >
              {columns.map((col, i) => {
                const colProps = { ...column, ...col };

                return (
                  <div
                    key={i}
                    className={classnames('STable-cell', colProps.className)}
                    role="cell"
                    style={{
                      width: colProps.width,
                      minWidth: colProps.minWidth,
                      ...colProps.style
                    }}
                  >
                    {renderTableCell(item, colProps)}
                  </div>
                );
              })}
            </div>
          ))}
        {data.length === 0 && !props.isLoading && (
          <div className="STable-empty">{props.emptyMessage}</div>
        )}
      </div>
    </div>
  );
};

STable.defaultProps = {
  data: [],
  columns: [],
  style: {},
  isLoading: false,
  emptyMessage: 'No items found',
  // Default global column
  column: {
    minWidth: 100,
    // Cells only
    className: '',
    style: {},
    // Headers only
    headerClassName: '',
    headerStyle: {}
  },
  onSortChange: () => {}
};

STable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  emptyMessage: PropTypes.string,
  onSortChange: PropTypes.func
};

export default STable;
