import { PRODUCTION_TEMPLATES_ACTIONS } from 'store/v1/production_templates/production_templates.constants.js';
//import { pick } from 'lodash';

const token = true;

export const getProductionTemplates = (event_id = null) => {
  return {
    types: [
      PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATES,
      PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATES_SUCCESS,
      PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATES_FAILURE
    ],
    promise: client => client.get(`/production_templates`, { token }),
    event_id
  };
};

export const getProductionTemplate = (id, event_id = null) => {
  return {
    types: [
      PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATE,
      PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATE_SUCCESS,
      PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATE_FAILURE
    ],
    promise: client => client.get(`/production_templates/${id}`, { token }),
    event_id
  };
};

export const createProductionTemplate = (data, event_id = null) => {
  return {
    types: [
      PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE,
      PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE_SUCCESS,
      PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE_FAILURE
    ],
    promise: client => client.post(`/production_templates`, { data, token }),
    event_id
  };
};

export const updateProductionTemplate = (id, data, event_id = null) => {
  let toSubmit = data;
  if (!data.default_resource_slots) {
    delete toSubmit.default_resource_slots;
  }
  if (!data.default_budget_expenses) {
    delete toSubmit.default_budget_expenses;
  }
  return {
    types: [
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE,
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_SUCCESS,
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_FAILURE
    ],
    promise: client =>
      client.put(`/production_templates/${id}`, { data: toSubmit, token }),
    event_id
  };
};

export const updateProductionTemplateOrder = (data, event_id = null) => {
  const payload =
    data &&
    data.map(template => ({
      id: template.id,
      order: template.order
    }));
  return {
    types: [
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_ORDER,
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_ORDER_SUCCESS,
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_ORDER_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/production_templates`, {
        data: { reorder: payload },
        token
      }),
    event_id
  };
};

export const deleteProductionTemplate = (id, event_id = null) => {
  return {
    types: [
      PRODUCTION_TEMPLATES_ACTIONS.DELETE_PRODUCTION_TEMPLATE,
      PRODUCTION_TEMPLATES_ACTIONS.DELETE_PRODUCTION_TEMPLATE_SUCCESS,
      PRODUCTION_TEMPLATES_ACTIONS.DELETE_PRODUCTION_TEMPLATE_FAILURE
    ],
    promise: client => client.del(`/production_templates/${id}`, { token }),
    event_id,
    id
  };
};
