import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { SingleCheckbox } from 'v1/components/shared';
import {
  PressStud,
  Dropdown,
  ErrorMessage,
  Label,
  MenuItem,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { Form, Field } from 'react-form';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import {
  createResourceType,
  updateResourceType
} from 'store/v1/resource_types/resource_types.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { RESOURCE_TYPES_ACTIONS } from 'store/v1/resource_types/resource_types.constants.js';

import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';
import { friendlyStringFromConst } from 'v1/helpers/misc';
import RESOURCE_CONFIG from 'v1/helpers/resourceTypeHelper';
import { handleValidate } from 'v1/helpers/validation';
import { get } from 'lodash';

import { MultipleUserGroupSelector } from '../../../../../shared';

class ResourceTypeCreateModal extends Component {
  constructor(props) {
    super(props);

    this.app_id = props.auth.app_id;

    this.state = {
      resourceType: props.ui.data.resource_type || {},
      isCreate: props.ui.data.create
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const eventName = this.state.isCreate
      ? RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE
      : RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE;

    const currStatus = get(this.props.events, eventName);
    const nextStatus = get(nextProps.events, eventName);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        if (this.state.isCreate) {
          this.props.onRequestClose();
          this.props.displayAlert('success', 'Resource Type Created');
        } else {
          this.props.onRequestClose();
          this.props.displayAlert('success', 'Resource Type Updated');
        }
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }

  handleSubmit = values => {
    this.state.isCreate
      ? this.props.createResourceType(
          values,
          RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE
        )
      : this.props.updateResourceType(
          this.state.resourceType.id,
          values,
          RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE
        );
  };

  render() {
    const { loading } = this.props.resource_types;
    const { name, model, internal, icon, metastructure, restricted_groups } =
      this.state.resourceType;

    return (
      <ModalWrapper size="S">
        <ModalHeader title="Create resource type" />
        <ModalContent>
          <ModalScrollable className="overflow-visible">
            <Form
              onSubmit={this.handleSubmit}
              defaultValues={{
                name,
                model: model || 'INDIVIDUAL',
                internal,
                icon: icon || 'person',
                metastructure,
                restricted_groups: restricted_groups || []
              }}
              getApi={api => {
                this.formApi = api;
              }}
              validateOnSubmit
            >
              {formApi => (
                <form
                  data-cy="resource-type-create-form"
                  onSubmit={formApi.submitForm}
                >
                  <div className="form-group">
                    <span className="form-label">Resource Icon</span>
                    <Field field="icon">
                      {fieldApi => {
                        return (
                          <Dropdown
                            buttonLabel={fieldApi.value || 'Choose Icon'}
                            buttonIcon={
                              <img
                                src={`/images/${
                                  RESOURCE_TYPE_ICONS[
                                    fieldApi.value || 'default'
                                  ].black
                                }`}
                                alt=""
                              />
                            }
                            buttonClass="btn btn-default capitalise"
                          >
                            {Object.keys(RESOURCE_TYPE_ICONS).map(icon => (
                              <MenuItem
                                onSelect={() => fieldApi.setValue(icon)}
                              >
                                <span className="MenuItem-icon">
                                  <img
                                    src={`/images/${RESOURCE_TYPE_ICONS[icon].black}`}
                                    alt=""
                                  />
                                </span>
                                <span className="MenuItem-label">{icon}</span>
                              </MenuItem>
                            ))}
                          </Dropdown>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="form-group">
                    <Field
                      field="name"
                      validate={value => handleValidate(value, 'required')}
                    >
                      {({ fieldName: name, value, error, setValue }) => (
                        <div className="stack-M">
                          <Label htmlFor={name}>Resource Type Name</Label>
                          <TextInput
                            id={name}
                            name={name}
                            value={value}
                            placeholder="Name"
                            onChange={({ target }) => setValue(target.value)}
                            validity={error ? 'invalid' : null}
                            required
                            autoComplete="off"
                            autoFocus
                          />
                          {error && (
                            <ErrorMessage>This field is required</ErrorMessage>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <span className="form-label">Mechanism</span>
                    <p className="form-description">
                      The mechanism helps the system figure out what to display
                      and where to display this resource type.
                    </p>
                    <Field field="model">
                      {fieldApi => {
                        return (
                          <Dropdown
                            buttonLabel={friendlyStringFromConst(
                              fieldApi.value || 'Select mechanism'
                            )}
                            buttonClass="form-control capitalise"
                          >
                            {Object.keys(RESOURCE_CONFIG).map(resourceModel => (
                              <MenuItem
                                onSelect={() => {
                                  fieldApi.setValue(resourceModel);
                                  formApi.setValue('metastructure', {
                                    field_order:
                                      RESOURCE_CONFIG[resourceModel]
                                        .defaultFields
                                  });
                                }}
                              >
                                {friendlyStringFromConst(resourceModel)}
                              </MenuItem>
                            ))}
                          </Dropdown>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="form-group position-relative z-2">
                    <span className="form-label">Restrict Access</span>
                    <p className="form-description">
                      Select user groups to restrict access for.
                    </p>
                    <Field field="restricted_groups">
                      {fieldApi => (
                        <MultipleUserGroupSelector
                          selectedOptions={fieldApi.value}
                          onChange={selected =>
                            fieldApi.setValue(
                              selected.map(({ value }) => value)
                            )
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <span className="form-label">Internal</span>
                    <Field field="internal">
                      {fieldApi => (
                        <SingleCheckbox
                          checked={fieldApi.value}
                          onChange={checked => fieldApi.setValue(checked)}
                        />
                      )}
                    </Field>
                  </div>
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Cancel"
              appearance="silent"
              action={this.props.onRequestClose}
            />
            <PressStud
              label={
                this.state.isCreate ? 'Create Resource Type' : 'Save changes'
              }
              appearance="primary"
              isLoading={
                loading === 'CREATE_RESOURCE_TYPE' ||
                loading === 'UPDATE_RESOURCE_TYPE'
              }
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ auth, resource_types, events, ui }) {
  return { auth, resource_types, events, ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createResourceType,
      updateResourceType,
      displayAlert,
      openModal
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResourceTypeCreateModal)
);
