import React from 'react';
import classnames from 'classnames';

import styles from './FieldMessage.module.scss';

export type FieldMessageProps = {
  type?: 'danger' | 'warning' | 'info' | 'danger';
  message: string;
};

const FieldMessage = ({ type = 'danger', message }: FieldMessageProps) => (
  <div className={classnames(styles.FieldMessage, styles[type])}>{message}</div>
);

export default FieldMessage;
