import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { get } from 'lodash';

import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { closeModal } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import ResourcePane from 'v1/components/feature/ResourceComponents/ResourcePane/ResourcePane';
import {
  ButtonWithDropdown,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud
} from 'v1/components/shared';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

const ResourceCreateModal = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { app_id } = useSelector(state => state.auth);
  const loadedResource = useSelector(state => state.ui.data);

  // Default resource object if no resource is loaded
  const resourceTypes = useSelector(getResourceTypes);
  const defaultResource = { resource_type_id: get(resourceTypes, [0, 'id']) };

  const { loading, new: newResource } = useSelector(state => state.contacts);
  const [formApi, setFormApi] = useState();
  const [resource, setResource] = useState(loadedResource || defaultResource);
  const [resourceKey, setResourceKey] = useState(v4());
  const [closeOnSubmit, setCloseOnSubmit] = useState(true);
  const [goToProfile, setGoToProfile] = useState(false);

  const submitForm = () => {
    formApi.submitForm();
  };

  const submitAndClose = () => {
    setCloseOnSubmit(true);
    submitForm();
  };

  const submitAndAddNew = () => {
    setCloseOnSubmit(false);
    submitForm();
  };

  const saveAndGoToProfile = () => {
    setCloseOnSubmit(true);
    setGoToProfile(true);
    submitForm();
  };

  useEvent(CONTACTS_ACTIONS.CREATE_CONTACT, {
    onSuccess: () => {
      if (goToProfile) {
        const newId = get(newResource, 'id');
        newId && history.push(`/app/${app_id}/contacts/${newId}`);
      }
      if (closeOnSubmit) {
        dispatch(closeModal());
      } else {
        setResource(prev => ({
          resource_type_id: prev.resource_type_id
        }));
        setResourceKey(v4());
      }
    }
  });

  return (
    <ModalWrapper size="S">
      <ModalHeader title="Create resource" />
      <ModalContent>
        <ModalScrollable padding="S">
          <ResourcePane
            resource={resource}
            resourceKey={resourceKey}
            autosave={false}
            hideTabs
            setFormApi={setFormApi}
          />
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Save & Add Another"
            isLoading={loading === CONTACTS_ACTIONS.CREATE_CONTACT}
            action={submitAndAddNew}
          />

          <ButtonWithDropdown
            className="btn btn-primary"
            arrowShade="light"
            isLoading={loading === CONTACTS_ACTIONS.CREATE_CONTACT}
            actions={[
              {
                text: 'Save',
                action: submitAndClose
              },
              {
                text: 'Save & go to profile',
                icon: '/images/icon_arrow_top_right.svg',
                action: saveAndGoToProfile
              }
            ]}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

ResourceCreateModal.props = {};

export default ResourceCreateModal;
