import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';

import './MiniLoader.scss';

const MiniLoader = ({ isLoading, light, children }) =>
  isLoading ? (
    <ReactLoading
      className="MiniLoader"
      type="spin"
      color={light ? SYSTEM_COLOURS.WHITE : SYSTEM_COLOURS.BLACK}
      height={15}
      width={15}
    />
  ) : (
    children
  );

MiniLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  light: PropTypes.bool,
  children: PropTypes.any
};

export default MiniLoader;
