import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, size } from 'lodash';

import { selectAccounts } from 'store/accounts';
import { ContactItemCompact, EmptyGeneric } from 'v1/components/shared';
import { NoteItem } from 'v1/components/shared';
import './ShortlistFeedbackItemGrid.scss';

const ShortlistFeedbackItemGrid = ({ block, expandBlock }) => {
  const users = useSelector(selectAccounts);

  const renderVoteAuthors = votes => {
    if (!votes) return;
    return votes.map(vote => {
      const user = get(users, vote.user_id);
      const isGuest = vote.guest;
      if (isGuest) {
        return (
          <span key={vote.id} className="ShortlistFeedbackItemGrid-voter">
            {get(vote, 'guest.full_name') || 'Anonymous'}
          </span>
        );
      } else {
        return (
          <span key={vote.id} className="ShortlistFeedbackItemGrid-voter">
            {user ? user.displayName : ''}
          </span>
        );
      }
    });
  };

  // TODO: these could be the same compoonent tbf
  const renderUpvotes = _ => {
    const upvoteCount = size(block.upvotes);
    return (
      <div className="ShortlistFeedbackItemGrid-section">
        <div className="ShortlistFeedbackItemGrid-sectionHeader">
          <span className="ShortlistFeedbackItemGrid-sectionHeader-icon">
            <img src="/images/icon_upvote_colour.svg" alt="" />
          </span>
          <span className="ShortlistFeedbackItemGrid-sectionHeader-label">
            {upvoteCount} Upvote{upvoteCount > 1 && 's'}
          </span>
        </div>
        <div className="ShortlistFeedbackItemGrid-sectionContent">
          {renderVoteAuthors(block.upvotes)}
        </div>
      </div>
    );
  };

  const renderDownvotes = _ => {
    const downvoteCount = size(block.downvotes);
    return (
      <div className="ShortlistFeedbackItemGrid-section">
        <div className="ShortlistFeedbackItemGrid-sectionHeader">
          <span className="ShortlistFeedbackItemGrid-sectionHeader-icon">
            <img src="/images/icon_downvote_colour.svg" alt="" />
          </span>
          <span className="ShortlistFeedbackItemGrid-sectionHeader-label">
            {downvoteCount} Downvote{downvoteCount > 1 && 's'}
          </span>
        </div>
        <div className="ShortlistFeedbackItemGrid-sectionContent">
          {renderVoteAuthors(block.downvotes)}
        </div>
      </div>
    );
  };

  const renderComments = _ => {
    const commentCount = size(block.comments);
    return (
      <div className="ShortlistFeedbackItemGrid-section">
        <div className="ShortlistFeedbackItemGrid-sectionHeader">
          <span className="ShortlistFeedbackItemGrid-sectionHeader-icon">
            <img src="/images/icon_comments.svg" alt="" />
          </span>
          <span className="ShortlistFeedbackItemGrid-sectionHeader-label">
            {commentCount} Comment{commentCount > 1 && 's'}
          </span>
        </div>
        {block.comments &&
          block.comments.map((comment, index) => (
            <NoteItem
              key={index}
              note={{
                ...comment,
                note: comment.value,
                user: comment.guest || comment.user,
                user_id: comment.guest ? null : comment.user_id
              }}
              isGuestUser={comment.guest}
            />
          ))}
      </div>
    );
  };

  if (!block.contact) {
    return (
      <EmptyGeneric
        icon="/images/icon_colour_team.svg"
        title="Contact Delete"
        description="This contact has been deleted"
      />
    );
  }

  return (
    <div key={block.id} className="ShortlistFeedbackItemGrid">
      <div className="ShortlistFeedbackItemGrid-inner">
        <div className="ShortlistFeedbackItemGrid-scroll-fixed">
          <div className="ShortlistFeedbackItemGrid-name">
            <ContactItemCompact
              contact={block.contact}
              action={{
                className: 'btn btn-small btn-default',
                label: 'Expand',
                onClick: expandBlock
              }}
              previewMethod={'SIDEBAR'}
              hideResourceMeta
            />
          </div>
        </div>
        <div className="ShortlistFeedbackItemGrid-scroll">
          {renderUpvotes()}
          {renderDownvotes()}
          {renderComments()}
        </div>
      </div>
    </div>
  );
};

ShortlistFeedbackItemGrid.props = {
  block: PropTypes.any,
  expandBlock: PropTypes.func
};

export default ShortlistFeedbackItemGrid;
