import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { closeContactSidebar } from 'store/v1/ui/ui.actions.js';

import SidebarDraw from 'v1/components/frame/SidebarDraw/SidebarDraw';
import ResourcePane from 'v1/components/feature/ResourceComponents/ResourcePane/ResourcePane';
import { Loading } from 'v1/components/shared';

const ResourceSidebarDrawer = () => {
  const dispatch = useDispatch();
  const { showContactSidebar, selectedContact } = useSelector(
    state => state.ui
  );

  const closeSidebar = () => dispatch(closeContactSidebar());

  return (
    <SidebarDraw isOpen={showContactSidebar} closeSidebar={closeSidebar}>
      {!isEmpty(selectedContact) ? (
        <ResourcePane resource={selectedContact} />
      ) : (
        <Loading />
      )}
    </SidebarDraw>
  );
};

ResourceSidebarDrawer.propTypes = {};

export default ResourceSidebarDrawer;
