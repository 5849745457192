import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import Account from 'lib/auth/Account';
import useEvent from 'v1/helpers/hooks/useEvent';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants';
import { deleteResourceSlotAssignment } from 'store/v1/productions/productions.actions.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';

import {
  BookingConfirmationMenuItem,
  Flyover,
  PressStud
} from 'v1/components/shared';

const ResourceSlotAssignmentOptions = ({ productionId, assignment }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const bookingsError = useSelector(state => state.bookings.error);
  const workspace = Account.resolveFirstWorkspace() || {};

  const showMenu = useRestrictedAccess(null, null, [
    Capability.PRODUCTION_READ,
    Capability.PRODUCTION_UPDATE
  ]);

  const showRemove = useRestrictedAccess(null, null, [
    Capability.PRODUCTION_UPDATE
  ]);

  // TODO: duplicated
  const onConfirmSuccess = () => {
    dispatch(
      openModal('SuccessFeedbackModal', {
        title: `Booking confirmation sent!`,
        description: `You have successfully sent the booking confirmation email.`,
        actionLabel: 'Done',
        icon: '/images/icon_colour_send.svg'
      })
    );
  };

  // TODO: duplicated
  const onNoConfirmTemplate = () => {
    dispatch(
      openModal('AlertModal', {
        title: 'No booking confirmation template',
        description:
          'You cannot send a booking confirmation without a booking confirmation template. Please add a confirmation template to continue.',
        actionLabel: 'Go to confirmation template',
        onAction: () =>
          history.replace(
            `/app/${workspace.db_schema}/settings/booking_confirmation_template`
          )
      })
    );
  };

  useEvent(PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT, {
    onSuccess: () => dispatch(displayAlert('success', 'Assignment removed')),
    onFailure: () => dispatch(displayAlert('error', 'Something went wrong'))
  });

  // TODO: temp: moved this from BookingConfirmationMenuItem - needs to be here because the flyover is closed and it doesn't run
  useEvent([BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION], {
    onSuccess: onConfirmSuccess,
    onFailure: () => {
      get(bookingsError, 'status') === 400
        ? onNoConfirmTemplate()
        : dispatch(displayAlert('error', 'Failed to send booking update'));
    }
  });

  if (!showMenu) {
    return null;
  }

  const onResourceSlotAssignmentRemove = () => {
    dispatch(
      openModal('ConfirmModal', {
        title: 'Are you sure you want to remove this assignment?',
        confirmLabel: 'Confirm delete',
        onConfirm: () =>
          dispatch(
            deleteResourceSlotAssignment({
              production_id: productionId,
              resource_slot_id: assignment.resource_slot_id,
              resource_slot_assignment_id: assignment.id
            })
          )
      })
    );
  };

  return (
    <Flyover>
      <Flyover.Trigger>
        <PressStud icon="ellipsis" appearance="silent" />
      </Flyover.Trigger>
      <Flyover.Content>
        {({ closeFlyover }) => (
          <>
            <BookingConfirmationMenuItem
              bookingId={assignment.booking_id}
              closeFlyover={closeFlyover}
            />
            {showRemove && (
              <Flyover.Item
                icon="/images/icon_delete.svg"
                title="Remove resource"
                onClick={() => onResourceSlotAssignmentRemove(assignment.id)}
              />
            )}
          </>
        )}
      </Flyover.Content>
    </Flyover>
  );
};

ResourceSlotAssignmentOptions.propTypes = {
  productionId: PropTypes.number,
  assignment: PropTypes.object
};

export default ResourceSlotAssignmentOptions;
