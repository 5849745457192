import React from 'react';
import { useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import ResourcePortal from './ResourcePortal';
import { isTokenAlive, decodeAndReadToken } from 'lib/auth/tokenOperations';
import AuthService from 'lib/auth/AuthService';
import useGetTokenFromSearchQueryParams from 'v1/helpers/hooks/useGetTokenFromSearchQueryParams';

const routesConfig = pathname => [
  {
    route: 'calendar',
    label: 'View calendar',
    to: `${pathname}/calendar`,
    isLocked: false,
    iconPathKey: 'CALENDAR'
  },
  {
    route: 'bookings',
    label: 'View bookings',
    to: `${pathname}/bookings`,
    isLocked: true,
    iconPathKey: 'BOOKING'
  },
  {
    route: 'details',
    label: 'Change Contact details',
    to: `${pathname}/details`,
    isLocked: true,
    iconPathKey: 'DETAILS'
  }
];

const getRouteByRouteName = (routeName, routesConfig) =>
  routesConfig.find(route => route.route === routeName);

export const RESOURCE_PORTAL = 'atellioresourceportal';

const ResourcePortalRoute = () => {
  const cookies = new Cookies();
  let currentToken = '';

  // extract a token parameter from search query parameters
  const tokenFromQuery = useGetTokenFromSearchQueryParams('token');

  // url is of the form /app/:team_schema/resource_portal
  const match = useRouteMatch();
  const routesConfiguration = routesConfig(match.url);

  const location = useLocation();
  const nestedRouteFromPathname = routesConfiguration.find(route =>
    location.pathname.includes(route.route)
  );

  const defaultRootName =
    (nestedRouteFromPathname && nestedRouteFromPathname.route) || 'calendar';
  const defaultRoute = getRouteByRouteName(
    defaultRootName,
    routesConfiguration
  );

  // a token query exists
  if (tokenFromQuery !== null) {
    // set the token as a cookie
    cookies.set(RESOURCE_PORTAL, tokenFromQuery);
    currentToken = tokenFromQuery;
  } else {
    // a token query does not exist, check if a cookie exists
    const tokenFromCookie = cookies.get(RESOURCE_PORTAL);

    if (tokenFromCookie !== null) {
      currentToken = tokenFromCookie;
    }
  }

  const renderPortal = currentToken => {
    if (!currentToken) {
      return <Redirect to="/unauthorized" />;
    }

    const { token, bearerToken } = decodeAndReadToken(currentToken);
    const isAlive = isTokenAlive(token);

    if (isAlive) {
      return (
        <ResourcePortal
          routesConfiguration={routesConfiguration}
          defaultRoute={defaultRoute}
          token={token}
          bearerToken={bearerToken}
        />
      );
    } else {
      AuthService.logout();
      return <Redirect to="/login" />;
    }
  };

  return renderPortal(currentToken);
};

export default ResourcePortalRoute;
