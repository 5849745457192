import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { findIndex, get } from 'lodash';

import TableContext from './TableContext';
import { Flyover } from 'v1/components/shared';
import styles from './Table.module.scss';

const TableRowAdd = ({ groupId, isGroup }) => {
  const {
    isLoading,
    cellPadding,
    frozenColumns,
    visibleColumns,
    onRowAdd,
    onGroupAdd
  } = useContext(TableContext);

  const utilityColCount = findIndex(visibleColumns, col => col.index >= 0) || 0;
  const lastColumn = get(visibleColumns, utilityColCount + frozenColumns, {});
  const width = lastColumn.totalLeft;

  if (isLoading || !onRowAdd) {
    return null;
  }

  const handleClick = isGroup
    ? onGroupAdd
    : Array.isArray(onRowAdd)
    ? null
    : () => onRowAdd(groupId);

  const renderInner = () => (
    <div
      className={classnames(styles.Cell, {
        clickable: isGroup || (!isGroup && !Array.isArray(onRowAdd))
      })}
      onClick={handleClick}
    >
      <img
        className={styles.AddIcon}
        src="/images/icon_add_eggplant.svg"
        alt={`Add a new ${isGroup ? 'group' : 'item'}`}
      />
      <span className={styles.AddText}>New {isGroup ? 'group' : 'item'}</span>
    </div>
  );

  return (
    <div
      className={classnames(styles.Add, [
        {
          [styles.Row]: !isGroup,
          [styles.no_padding]: cellPadding === '0' || cellPadding === 0,
          [styles.S]: cellPadding === 'S',
          [styles.L]: cellPadding === 'L'
        }
      ])}
    >
      <div
        className={classnames(styles.AddWrapper, {
          [styles.Cell_sticky_last]: frozenColumns
        })}
        style={frozenColumns ? { width } : null}
      >
        {!isGroup && Array.isArray(onRowAdd) ? (
          <Flyover>
            <Flyover.Trigger>{renderInner()}</Flyover.Trigger>
            <Flyover.Content
              width={onRowAdd.find(opt => opt.description) ? 'S' : 'content'}
              clearance="flush"
            >
              {onRowAdd.map(option => (
                <Flyover.Item key={option.id} value={groupId} {...option} />
              ))}
            </Flyover.Content>
          </Flyover>
        ) : (
          renderInner()
        )}
      </div>
    </div>
  );
};

TableRowAdd.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isGroup: PropTypes.bool
};

export default TableRowAdd;
