export enum ACCOUNTS_ACTIONS {
  getTenantAccounts = 'accounts/getTenantAccounts',
  getTeamAccounts = 'accounts/getTeamAccounts',
  inviteAccount = 'accounts/inviteAccount',
  updateAccount = 'accounts/updateAccount',
  archiveAccount = 'accounts/archiveAccount',
  unarchiveAccount = 'accounts/unarchiveAccount',
  addAccountToUserGroup = 'accounts/addAccountToUserGroup',
  removeAccountFromUserGroup = 'accounts/removeAccountFromUserGroup'
}
