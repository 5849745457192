import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 * Get incrementers from the workspace settings redux state
 */
export const getIncrementers = createSelector(
  state => state.auth.settings,
  (settings = {}) => {
    if (!settings || !Array.isArray(settings.incrementers)) {
      return [];
    }
    return settings.incrementers;
  }
);

export const selectAppId = state => state.auth.app_id;

export const selectDefaultCurrency = state =>
  get(state, 'auth.settings.settings.defaults_currency.value');

export const selectBrandImage = state =>
  get(state, 'auth.settings.settings.defaults_brand_image.value');

export const getPublicBrandImage = state =>
  get(state, 'auth.settings.settings.public_brand_image.value');

export const getPublicCompanyName = state =>
  get(state, 'auth.settings.settings.public_company_name.value');
