import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Legend.scss';

const Legend = ({ className, hidden, children, ...props }) => {
  return (
    <legend
      className={classnames('Legend', { 'visually-hidden': hidden }, className)}
      {...props}
    >
      {children}
    </legend>
  );
};

Legend.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool, // Hide visually but retain semantics in conjunction with form input
  children: PropTypes.node // Can take a string, func, element etc.
};

export default Legend;
