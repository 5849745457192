import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Capability, FeatureFlag } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';

// REDUX
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import {
  searchContacts,
  updateContact
} from 'store/v1/contacts/contacts.actions.js';
import {
  createShortlistBlock,
  getShortlist,
  updateShortlist
} from 'store/v1/shortlists/shortlists.actions.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import {
  createBooking,
  updateBooking
} from 'store/v1/bookings/bookings.actions.js';
import { selectResourceTypesList } from 'store/v1/resource_types/resource_types.selectors';

// COMPONENTS
import AfbbmResourcePreview from './AfbbmResourcePreview/AfbbmResourcePreview';
import {
  ButtonWithDropdown,
  Dropdown,
  EmptyGeneric,
  Grid,
  GridCell,
  ListCell,
  ListCellGroup,
  Loading,
  MenuItem,
  PressStud,
  ResourceSearchSidebar,
  ResourceStatusDisplay,
  ResourceStatusGetter,
  ResourceTitle
} from 'v1/components/shared';

// INTERNAL HELPERS
import useEvent from 'v1/helpers/hooks/useEvent';
import useGetContactEvents from 'v1/helpers/hooks/useGetContactEvents';
import { orderReducer } from 'v1/helpers/misc';
import {
  createNewEventObject,
  getEventEnd,
  getEventStart
} from 'v1/helpers/byModel/EventHelper';
import { createBookingObject } from 'v1/helpers/byModel/BookingHelper';

// IMPORTS
import { filter, find, get, isEmpty, map, omit, orderBy, size } from 'lodash';
import classnames from 'classnames';
import './AddFromBlackbookModal.scss';
import { callsheets as callsheetHooks } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';
import { useSelectedContacts } from './AddFromBlackbookModal.hooks';

const AddFromBlackbookModal = ({ onRequestClose, ...props }) => {
  // STORE
  const dispatch = useDispatch();
  const bookingTypes = useSelector(state => state.booking_types);
  const store = useSelector(state => state);
  const ui = useSelector(state => state.ui);
  const contacts = useSelector(state => state.contacts);
  const shortlists = useSelector(state => state.shortlists);
  const productions = useSelector(state => state.productions);
  const resourceTypes = useSelector(selectResourceTypesList);
  const contactEvents = get(contacts, 'events_data');
  const statuses = useSelector(state => state.statuses);

  // STATE
  const [contactsList, setContactsList] = useState();
  const [closeOnActionsComplete, setCloseOnActionsComplete] = useState();
  const pointers = useMemo(() => {
    const data = {};
    const pointers = get(ui, 'data.dataPointers');
    pointers &&
      pointers.map(pointer => {
        data[pointer.label] = get(store, pointer.pointTo) ?? {};
        // we don't query store, we need only id to use query hook
        data[pointer.label].__migrate__pointer_id = pointer.pointTo[2];
      });
    return data;
  }, [ui, store]);

  const [hasMorePages, setHasMorePages] = useState();
  const [fetching, setFetching] = useState(false);
  const [itemOrder, setItemOrder] = useState();
  const [activeResourceType, setActiveResourceType] =
    useState(setResourceType());
  const [selectedResource, setSelectedResource] = useState();

  function getLocalStorageQuery() {
    const qs = localStorage['Stitch.Query.ResourceModal'];
    try {
      return JSON.parse(atob(qs));
    } catch (e) {
      return null;
    }
  }

  const [query, setQuery] = useState(setInitialQuery());

  function setInitialQuery() {
    const presetQuery = get(ui, 'data.initialQuery');
    const locallyStoredQuery = getLocalStorageQuery();
    const defaultQuery = {
      filters: {
        resource_type_id: { eq: activeResourceType.id }
      },
      order_by: { field: 'full_name', direction: 'asc' }
    };

    if (presetQuery) {
      return {
        ...presetQuery,
        order_by: { field: 'full_name', direction: 'asc' }
      };
    }
    if (locallyStoredQuery) {
      return locallyStoredQuery;
    }
    return defaultQuery;
  }

  function setLocalStorageQuery() {
    const localStorageQuery = omit(query, 'query');
    localStorage['Stitch.Query.ResourceModal'] = btoa(
      JSON.stringify(localStorageQuery)
    );
  }

  const callsheetQuery = callsheetHooks.useItem(
    pointers.callsheet?.__migrate__pointer_id
  );

  const updateMutation = callsheetHooks.useUpdateMutation(
    () => {
      // on update
    },
    e => {
      // on error
      dispatch(displayAlert('error', 'Cannot update callsheet: ' + e));
    }
  );

  const addTeamMemberMutation = callsheetHooks.useAddTeamMemberMutation(
    () => {
      // on update
    },
    e => {
      // on error
      dispatch(displayAlert('error', 'Cannot add team member: ' + e));
    }
  );

  const selectedContacts = useSelectedContacts(
    ui,
    pointers,
    callsheetQuery,
    contacts
  );

  function onSearch(query, page) {
    setLocalStorageQuery();
    dispatch(searchContacts(query, page));
  }

  const closeResourcePreview = () => setSelectedResource(null);

  const getConfirmedStatus = () => {
    return find(
      get(statuses, 'data'),
      s => !s.archived && s.status_type === 'CONFIRMED'
    );
  };

  // GET CONTACT EVENTS
  // We get the contact events if we are passing through a production date, or if the user has selected the appropriate queries within the sidebar.

  const getStartDateQuery =
    get(query, 'filters.availability.from_date') ||
    (get(pointers, 'production') &&
      getEventStart(get(pointers, 'production.production_date')));
  const getEndDateQuery =
    get(query, 'filters.availability.from_date') ||
    (get(pointers, 'production') &&
      getEventEnd(get(pointers, 'production.production_date')));

  useGetContactEvents({
    contactIds: map(orderReducer(contacts), c => c.id),
    start_date: getStartDateQuery,
    end_date: getEndDateQuery,
    load: [fetching, query, contacts.order]
  });

  const handleSearch = useCallback(onSearch, [query]);

  useEffect(() => {
    setHasMorePages(updatePageCount(props));
    setFetching(false);
  }, []);

  useEffect(() => {
    handleSearch(query);
  }, [query]);

  useEffect(() => {
    setContactsList(orderReducer(contacts));
  }, [dispatch, contacts, contactEvents]);

  useEffect(() => {
    const shortlistId = pointers.shortlist?.__migrate__pointer_id;
    dispatch(getShortlist(shortlistId)); // migration bridge
  }, []);

  function setResourceType(id) {
    const resourceType = find(resourceTypes, r => r.id == id);
    return resourceType || resourceTypes[0];
  }

  useEvent(
    [
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT,
      BOOKING_ACTIONS.CREATE_BOOKING,
      BOOKING_ACTIONS.UPDATE_BOOKING
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Success'));
        if (closeOnActionsComplete) {
          onRequestClose();
        }
      }
    }
  );
  useEvent([CONTACTS_ACTIONS.GET_CONTACTS], {
    onSuccess: () => {
      setHasMorePages(updatePageCount(props));
      setFetching(false);
    }
  });

  function handleInfiniteScroll(e) {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 50 &&
      !fetching &&
      hasMorePages
    )
      loadMore();
  }

  function updatePageCount() {
    const { count_per_page, page, total } = contacts.paging;
    return total > parseInt(count_per_page) * parseInt(page);
  }
  function loadMore() {
    const page = parseInt(contacts.paging.page) + 1;
    if (!fetching) {
      setFetching(true);
      handleSearch({ ...query, p: page }, page);
    }
  }

  // ACTIONS
  // Below are the list of add, remove and book actions that are triggered depending on the destination of the resource. The logic for selection the destination can be found below under buildButtonActions()

  function removeResourceFromShortlist(contact) {
    let shortlist = get(pointers, 'shortlist');
    let shortlistInStore = get(shortlists, ['data', shortlist.id]);
    let shortlist_blocks = shortlistInStore.shortlist_blocks;

    shortlist_blocks = filter(
      shortlist_blocks,
      s => s.contact_id !== contact.id
    );
    shortlist.shortlist_blocks = shortlist_blocks;

    return dispatch(updateShortlist(shortlist.id, shortlist));
  }

  function removeResourceFromCallsheet(contact) {
    if (callsheetQuery.isSuccess) {
      const callsheet = callsheetQuery.data;
      const { team_members } = callsheet;
      const updatedCallsheet = {
        ...callsheet,
        team_members: team_members
          .filter(teamMember => teamMember.contact_id !== contact.id)
          .map((teamMember, index) => ({
            ...teamMember,
            order: index
          }))
      };
      // todo this should be done on a row component so we can handle parallel mutations
      updateMutation.mutate(updatedCallsheet);
    }
  }

  function addResourceRelationship(contact) {
    const representingContact = get(pointers, 'contact');
    if (get(representingContact, 'resource_type.model') === 'AGENT') {
      return dispatch(
        updateContact(contact.id, {
          ...contact,
          represented_by_id: get(representingContact, 'id')
        })
      );
    }
    if (get(representingContact, 'resource_type.model') === 'ORGANIZATION') {
      return dispatch(
        updateContact(contact.id, {
          ...contact,
          organisation_id: get(representingContact, 'id')
        })
      );
    }
  }

  function addResourceToShortlist(contact) {
    let shortlist = get(pointers, 'shortlist');
    let shortlistInStore = get(shortlists, ['data', shortlist.id]); // We need the shortlist in the store to get the latest shortlist block size to update the order

    let shortlist_blocks = shortlistInStore.shortlist_blocks;

    const newBlock = {
      contact_id: contact.id,
      contact,
      data: {},
      // todo candidate to migrate to myResourceShortlistAssociations effects - it will handle proper order in the payload
      order: size(filter(shortlist_blocks, b => !b.archived)) // TODO: archived blocks should not exist. Ignore archived blocks for ordering
    };
    return dispatch(
      createShortlistBlock({ shortlist_id: shortlist.id }, newBlock)
    );
  }

  function addResourceToCallsheet(contact) {
    const callsheet = callsheetQuery.data;
    const group_id = get(ui, ['data', 'callsheetGroupId']);
    const call_time = get(callsheet, 'call_time');
    let order = 0;

    if (itemOrder == null) {
      let initialOrder = size(
        filter(callsheet.team_members, t => t.group_id == group_id)
      );
      setItemOrder(initialOrder);
      order = initialOrder;
    } else {
      order = itemOrder + 1;
      setItemOrder(order);
    }
    const { id: callsheetID } = callsheet;
    const {
      id: contact_id,
      full_name,
      emails = [],
      groups,
      role,
      profile_picture,
      phone_numbers = []
    } = contact;

    const orderedEmails = orderBy(emails, 'id'); // Ensure we default add the first email added

    const teamMember = {
      contact_id,
      display_name: full_name,
      display_email: get(orderedEmails, '0.value_1'),
      display_role: get(groups, [0, 'name'], role),
      display_profile_picture: profile_picture,
      display_phone_number: get(phone_numbers, '0.value_1'),
      contact_details_visible: true,
      order,
      call_time,
      group_id,
      recipient: {
        contact_id,
        callsheet_id: callsheetID
      }
    };

    addTeamMemberMutation.mutate({
      id: callsheetID,
      data: teamMember
    });
  }

  function removeContact(contact) {
    const destination = get(ui, 'data.destination');

    // REMOVE SELECTED CONTACT FROM SELECTION INDEX
    // TODO handle optimistic operations in a action-reducer-way
    // setSelectedContacts(prev => prev.filter(c => c !== contact.id));

    // TRIGGER APPROPRIATE ACTION
    switch (destination) {
      case 'SHORTLIST_SHORTLIST_BLOCKS':
        return removeResourceFromShortlist(contact);
      case 'CALLSHEET_TEAM_MEMBERS':
        return removeResourceFromCallsheet(contact);
      default:
        return false;
    }
  }

  function addContact(contact) {
    const destination = get(ui, 'data.destination');

    // ADD SELECTED CONTACT TO SELECTION INDEX
    selectedContacts.push(contact.id);
    // todo optimistic operation will be done in special state
    // setSelectedContacts(selectedContacts);

    switch (destination) {
      case 'CONTACT_CONNECT':
        return addResourceRelationship(contact);
      case 'SHORTLIST_SHORTLIST_BLOCKS':
        return addResourceToShortlist(contact);
      case 'CALLSHEET_TEAM_MEMBERS':
        return addResourceToCallsheet(contact);
      default:
        return false;
    }
  }

  function updateBookingWithAssignment(booking, contact, slot, production) {
    const confirmedStatus = getConfirmedStatus();
    const existingAssignments = get(booking, 'resource_slot_assignments', []);
    return {
      ...booking,
      resource_slot_assignments: [
        ...existingAssignments,
        {
          contact_id: contact.id,
          resource_slot_id: slot.id,
          events: [
            createNewEventObject({
              ...production.production_date,
              event_type: 'RESOURCE_SLOT_ASSIGNMENT'
            })
          ]
        }
      ],
      status_id: confirmedStatus.id
    };
  }

  function autoUpdateAndAssignBooking(contact, booking) {
    const resourceSlot = get(pointers, 'resource_slot', {});
    const productionId = get(pointers, 'production.id');
    const production = productionId && get(productions, ['data', productionId]);

    setCloseOnActionsComplete(true);

    return dispatch(
      updateBooking(
        booking.id,
        updateBookingWithAssignment(booking, contact, resourceSlot, production)
      )
    );
  }

  function autoCreateAndAssignBooking(contact, booking) {
    const resourceSlot = get(pointers, 'resource_slot', {});
    const productionId = get(pointers, 'production.id');
    const production = productionId && get(productions, ['data', productionId]);

    const newBooking = createBookingObject(bookingTypes, {
      event: get(production, 'production_date', null),
      resource: contact,
      slot: resourceSlot,
      status: getConfirmedStatus()
    });

    setCloseOnActionsComplete(true);

    dispatch(createBooking(newBooking));
  }

  function goToCreateNewBooking(contact, overlappingBooking) {
    const resourceSlot = get(pointers, 'resource_slot', {});
    const productionId = get(pointers, 'production.id');
    const production = productionId && get(productions, ['data', productionId]);

    dispatch(
      openModal('ResourceEventsModal', {
        resourceId: contact.id,
        resourceSlotId: resourceSlot.id,
        event: production.production_date,
        resourceSlotAssignmentEvent: createNewEventObject({
          ...production.production_date,
          event_type: 'RESOURCE_SLOT_ASSIGNMENT'
        }),
        statusId: overlappingBooking ? overlappingBooking.status_id : null
      })
    );
  }

  function goToExistingBooking(bookingId) {
    const resourceSlot = get(pointers, 'resource_slot', {});
    const productionId = get(pointers, 'production.id');
    const production = productionId && get(productions, ['data', productionId]);
    dispatch(
      openModal('ResourceEventsModal', {
        bookingId: bookingId,
        resourceSlotId: resourceSlot.id,
        resourceSlotAssignmentEvent: createNewEventObject({
          ...production.production_date,
          event_type: 'RESOURCE_SLOT_ASSIGNMENT'
        })
      })
    );
  }

  // END OF ACTIONS

  function buildButtonActions(isItemSelected, contact, bookings = []) {
    const booking = bookings[0];
    let actions = [];
    const destination = get(ui, 'data.destination');

    switch (destination) {
      case 'SHORTLIST_SHORTLIST_BLOCKS':
      case 'CONTACT_CONNECT':
      case 'CALLSHEET_TEAM_MEMBERS':
      default:
        isItemSelected
          ? actions.push({
              text: 'Remove',
              action: () => removeContact(contact)
            })
          : actions.push({
              text: 'Add',
              action: () => addContact(contact)
            });
        break;
      case 'PRODUCTION_TEAM_MEMBER_OPTIONS':
        actions.push({
          text: 'Assign',
          action: () => {
            if (
              booking &&
              booking.resource_slot_assignments &&
              booking.resource_slot_assignments.length
            ) {
              goToExistingBooking(booking.id);
            } else {
              goToCreateNewBooking(contact, booking);
            }
          }
        });
        actions.push({
          text: 'Assign & confirm',
          description:
            'Automatically creates a booking, assigns the resource and sets status to confirmed.',
          icon: '/images/icon_tick_green.svg',
          action: () => {
            if (
              booking &&
              booking.resource_slot_assignments &&
              booking.resource_slot_assignments.length
            ) {
              autoUpdateAndAssignBooking(contact, booking);
            } else {
              autoCreateAndAssignBooking(contact, booking);
            }
          }
        });
        actions.push({
          text: 'Assign & edit',
          description:
            'Creates booking, assigns the resource and navigates to booking for editing',
          icon: '/images/icon_new_booking.svg',
          action: () => {
            if (
              booking &&
              booking.resource_slot_assignments &&
              booking.resource_slot_assignments.length
            ) {
              goToExistingBooking(booking.id);
            } else {
              goToCreateNewBooking(contact);
            }
          }
        });
        break;
    }

    return actions;
  }

  function renderHeader() {
    return (
      <div className="ModalWithContactPreview-header">
        <div className="ModalWithContactPreview-header-title">
          <h3>Assign resources</h3>
        </div>
        <div className="ModalWithContactPreview-header-actions">
          {(!selectedResource || selectedResource.id) && (
            <PressStud
              label="Done"
              appearance="secondary"
              dataCy="close-modal"
              action={onRequestClose}
            />
          )}
        </div>
      </div>
    );
  }

  function renderResults() {
    const { loading } = contacts;

    if (loading === 'GET_CONTACTS' && !fetching) return <Loading />;

    if (!isEmpty(contactsList)) {
      return (
        <div
          className="AddFromBlackbookModal-results"
          onScroll={handleInfiniteScroll}
        >
          <ListCellGroup>
            {contactsList.map(contact => {
              const isItemSelected = selectedContacts.includes(contact.id);
              return (
                <ListCell key={contact.id}>
                  <Grid vcenter>
                    <GridCell>
                      <ResourceTitle
                        resource={contact}
                        navigateToMethod="FUNCTION"
                        onSelect={() => setSelectedResource(contact)}
                        includeMeta
                      />
                    </GridCell>
                    <GridCell>
                      <ResourceStatusGetter
                        resourceId={get(contact, 'id')}
                        dateRange={
                          get(query, 'filters.availability.to_date')
                            ? {
                                start_date: get(
                                  query,
                                  'filters.availability.from_date'
                                ),
                                end_date: get(
                                  query,
                                  'filters.availability.to_date'
                                ),
                                date_type: 'RECURRING_DEFAULT'
                              }
                            : get(pointers, 'production.production_date')
                        }
                      >
                        {({ bookings }) =>
                          !isEmpty(bookings) &&
                          bookings.map(booking => (
                            <div className="inset-XS">
                              <ResourceStatusDisplay booking={booking} />
                            </div>
                          ))
                        }
                      </ResourceStatusGetter>
                    </GridCell>
                    <GridCell width="auto">
                      <ResourceStatusGetter
                        resourceId={get(contact, 'id')}
                        dateRange={
                          get(query, 'filters.availability.to_date')
                            ? {
                                start_date: get(
                                  query,
                                  'filters.availability.from_date'
                                ),
                                end_date: get(
                                  query,
                                  'filters.availability.to_date'
                                ),
                                date_type: 'RECURRING_DEFAULT'
                              }
                            : get(pointers, 'production.production_date')
                        }
                      >
                        {({ bookings }) => (
                          <ButtonWithDropdown
                            className={classnames([
                              'btn',
                              'btn-small',
                              isItemSelected ? 'btn-default' : 'btn-primary'
                            ])}
                            arrowShade={isItemSelected ? 'dark' : 'light'}
                            actions={buildButtonActions(
                              isItemSelected,
                              contact,
                              bookings
                            )}
                            stopPropagation
                          />
                        )}
                      </ResourceStatusGetter>
                    </GridCell>
                  </Grid>
                </ListCell>
              );
            })}
          </ListCellGroup>
          {!hasMorePages && (
            <span className="AddFromBlackbookModal-results-end text-center">
              <span role="img" aria-label="Duck">
                🦆
              </span>
              <br />
              No more results
            </span>
          )}
        </div>
      );
    }
    return (
      <div className="AddFromBlackbookModal-results">
        <EmptyGeneric
          icon="/images/icon_colour_team.svg"
          iconWidth="50px"
          title="No Resources Found"
          description="Try a different search or add more resources"
        />
      </div>
    );
  }
  function renderSearchMeta() {
    const totalResults = get(contacts, 'paging.total');

    return (
      <div className="AddFromBlackbookModal-meta">
        <div className="AddFromBlackbookModal-meta-selectAction">
          <Grid vcenter gutters="S">
            <GridCell width="auto">
              <h5>{totalResults} Results</h5>
            </GridCell>
            <GridCell>
              <Dropdown
                buttonLabel={
                  get(query.order_by, 'field') === 'full_name'
                    ? 'Sort alphabetically'
                    : 'Sort last created'
                }
                buttonIcon={<img src="/images/icon_sort_asc.svg" alt="" />}
                buttonClass="btn btn-default btn-small"
              >
                <MenuItem
                  onSelect={() => {
                    setQuery({
                      ...query,
                      order_by: { field: 'full_name', direction: 'asc' }
                    });
                  }}
                >
                  <span className="MenuItem-label">Sort alphabetically</span>
                </MenuItem>
                <MenuItem
                  onSelect={() => {
                    setQuery({
                      ...query,
                      order_by: { field: 'created_at', direction: 'desc' }
                    });
                  }}
                >
                  <span className="MenuItem-label">Sort by last created</span>
                </MenuItem>
              </Dropdown>
            </GridCell>
          </Grid>
        </div>
        <RestrictedAccess
          flag={FeatureFlag.RESOURCES}
          capabilities={Capability.RESOURCE_CREATE}
        >
          <PressStud
            label="New resource"
            icon="addBlack"
            size="S"
            action={() =>
              setSelectedResource({
                resource_type_id: get(
                  query,
                  'filters.resource_type_id.eq',
                  activeResourceType.id
                )
              })
            }
          />
        </RestrictedAccess>
      </div>
    );
  }

  const { loading } = contacts;
  return (
    <div className="AddFromBlackbookModal ModalWithContactPreview">
      {renderHeader()}
      <div className="ModalWithContactPreview-inner">
        <div className="ModalWithContactPreview-leftSidebar">
          <ResourceSearchSidebar
            query={query}
            onSearch={updatedQuery => {
              setQuery({
                ...updatedQuery,
                filters: get(updatedQuery, 'filters'),
                query: get(updatedQuery, 'query')
              });
            }}
          />
        </div>
        <div className="ModalWithContactPreview-main">
          {renderSearchMeta()}
          {renderResults()}
          {loading === 'GET_CONTACTS' && fetching && <Loading />}
        </div>
        {selectedResource && (
          <AfbbmResourcePreview
            resource={selectedResource}
            closeResourcePreview={closeResourcePreview}
          />
        )}
      </div>
    </div>
  );
};

export default AddFromBlackbookModal;
