import React, { Component } from 'react';
//import { Loading } from 'v1/components/shared';
//import { cleanUrl } from 'v1/helpers/misc';
//import Img from 'react-image';
import classnames from 'classnames';
//import ReactLoading from 'react-loading';
import InstagramEmbed from 'react-instagram-embed';

export default class PinProviderEmbed extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  // shouldComponentUpdate = (nextProps) => {
  //   return false;
  // }
  render() {
    const { pin } = this.props;

    return (
      <div
        className={classnames(
          { error: this.state.error, loaded: this.state.loaded },
          ['PinProviderEmbed']
        )}
      >
        <InstagramEmbed
          url={pin.url}
          hideCaption={true}
          containerTagName="div"
          maxWidth={400}
          protocol=""
          injectScript
          onAfterRender={() => {
            this.setState({ loaded: true });
          }}
        />
      </div>
    );
  }
}
