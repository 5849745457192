export { CapabilityEnum as Capability } from 'dtos/constants/capability.enum';

export { FeatureFlagEnum as FeatureFlag } from 'dtos/constants/feature-flag.enum';

export enum AreaUnit {
  SQ_IN = 'sq_in',
  SQ_FT = 'sq_ft',
  SQ_YD = 'sq_yd',
  ACRE = 'acre',
  SQ_CM = 'sq_cm',
  SQ_M = 'sq_m',
  HA = 'ha'
}

export enum LengthUnit {
  IN = 'in',
  FT = 'ft',
  YD = 'yd',
  MM = 'mm',
  CM = 'cm',
  M = 'm'
}

export enum MassUnit {
  OZ = 'oz',
  LB = 'lb',
  ST = 'st',
  MG = 'ng',
  G = 'g',
  KG = 'kg',
  T = 't'
}

export const MeasurementUnit = {
  ...LengthUnit,
  ...MassUnit,
  ...AreaUnit
};
export type MeasurementUnit = LengthUnit | MassUnit | AreaUnit;

export enum RateInterval {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Fixed = 'FIXED',
  Item = 'ITEM'
}

export enum IntegrationID {
  DOCUSIGN = 'docusign',
  NETSUITE = 'netsuite',
  SHOTFLOW = 'shotflow'
}

export enum SystemSettingKeys {
  PUBLIC_BRAND_IMAGE = 'public_brand_image',
  PUBLIC_COMPANY_NAME = 'public_company_name',
  PUBLIC_TEAM_NAME = 'public_team_name',
  DOCUSIGN_TEMPLATE_ID = 'docusign_template_id',
  NETSUITE_URL = 'netsuite_url',
  NETSUITE_REALM = 'netsuite_realm',
  NETSUITE_CONSUMER_KEY = 'netsuite_consumer_key',
  NETSUITE_CONSUMER_SECRET = 'netsuite_consumer_secret',
  NETSUITE_TOKEN_KEY = 'netsuite_token_key',
  NETSUITE_TOKEN_SECRET = 'netsuite_token_secret'
}
