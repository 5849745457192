import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { identity, isEmpty, isEqual, pickBy, size } from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions';
import {
  deleteShortlistBlock,
  updateShortlist
} from 'store/v1/shortlists/shortlists.actions.js';

import { ConfirmModal } from 'modals';
import useModal from 'hooks/useModal';

import { createNewEventObject } from 'v1/helpers/byModel/EventHelper';

import {
  Dropdown,
  EventDateRange,
  Grid,
  GridCell,
  Label,
  MenuItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PressStud,
  ResourceAdvancedSearch
} from 'v1/components/shared';
import RosterSearch from 'v1/components/feature/ResourceComponents/Resources/RosterSearch/RosterSearch';
import ShortlistFieldManager from '../ShortlistFieldManager/ShortlistFieldManager';
import './ShortlistToolbar.scss';
import { duplicateShortlistBlockPins } from 'v1/helpers/byModel/shortlistDuplicateHelper';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants';
import useEvent from 'v1/helpers/hooks/useEvent';
import { useShortlistBatchDuplicate } from 'v4/entities/common/hooks/useShortlistBatchDuplicate';

const SHORTLIST_VIEW_OPTIONS = [
  { label: 'Grid', value: 'GRID', icon: 'icon_shortlist_grid' },
  { label: 'Compact', value: 'COMPACT', icon: 'icon_shortlists_compact' },
  { label: 'Page', value: 'PAGE', icon: 'icon_shortlist_page' }
];

const ShortlistToolbar = ({
  shortlist = {},
  isPublic,
  selection = {},
  shortlistFieldMapper,
  resourceType,
  query,
  onShortlistSubmit,
  onSetQuery,
  hasSelectedAll,
  onSelectAll,
  onSaveNewUserOrder,
  orderEnabled,
  setOrderEnabled,
  showOrderButton = false
}) => {
  const { openModal: openv2Modal } = useModal();
  const shortlistBatchAdd = useShortlistBatchDuplicate();
  const [resourceFieldsQuery, setResourceFieldsQuery] = useState({ query: '' });

  const [deletingBlockIndex, setDeletingBlockIndex] = useState(0);
  const [shortlistBlocksToDeleteIds, setShortlistBlocksToDeleteIds] = useState(
    []
  );
  const [shortlistBlocksToDelete, setShortlistBlocksToDelete] = useState({});

  const dispatch = useDispatch();
  const canUpdate = useRestrictedAccess(
    null,
    Capability.SHORTLIST_UPDATE,
    null
  );
  const viewOption = SHORTLIST_VIEW_OPTIONS.find(
    o => o.value === (shortlist.layout || 'GRID')
  );

  function onAddSelectedToShortlist() {
    const shortlistBlocks = Object.keys(selection).map(key => selection[key]);
    shortlistBatchAdd(shortlistBlocks.map(block => block.contact_id));
  }

  function requestAvailabilityFromSelected() {
    const resourceIds = Object.keys(selection).map(key => {
      const block = selection[key];
      if (block.contact_id) {
        return block.contact_id;
      }
      return false;
    });

    dispatch(
      openModal('RequestAvailabilityModal', {
        resourceIds
      })
    );
  }

  function onNewShortlistFromSelected() {
    const newBlocks = [];

    Object.keys(selection).map((key, index) => {
      const block = selection[key];
      return newBlocks.push({
        order: block.order,
        contact_id: block.contact_id,
        data: block.data,
        pins: duplicateShortlistBlockPins(block.pins)
      });
    });

    const newShortlist = {
      title: `${shortlist.title} (Copy)`,
      description: shortlist.description,
      shortlist_blocks: newBlocks,
      layout: shortlist.layout
    };
    return dispatch(
      openModal('ShortlistCreateModal', {
        create: true,
        shortlist: newShortlist,
        modalTitle: 'Save as new shortlist'
      })
    );
  }

  useEvent([SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK], {
    onSuccess: () => {
      if (deletingBlockIndex < shortlistBlocksToDeleteIds.length - 1) {
        const newIndex = deletingBlockIndex + 1;
        setDeletingBlockIndex(newIndex);
      } else {
        setShortlistBlocksToDeleteIds([]);
        setShortlistBlocksToDelete({});
        dispatch(displayAlert('success', 'Selected resources removed'));
      }
    }
  });

  useEffect(() => {
    if (shortlistBlocksToDeleteIds.length) {
      handleDeleteShortlistBlock();
    }
  }, [deletingBlockIndex, shortlistBlocksToDeleteIds]);

  function handleDeleteShortlistBlock() {
    dispatch(
      deleteShortlistBlock({
        shortlist_id: shortlist.id,
        shortlist_block_id:
          shortlistBlocksToDelete[
            shortlistBlocksToDeleteIds[deletingBlockIndex]
          ].id,
        contact_id:
          shortlistBlocksToDelete[
            shortlistBlocksToDeleteIds[deletingBlockIndex]
          ]?.contact_id
      })
    );
  }

  function batchDeleteShortlistBlocks() {
    setShortlistBlocksToDelete(selection);
    setShortlistBlocksToDeleteIds(
      Object.keys(selection).map(id => parseInt(id))
    );
    setDeletingBlockIndex(0);
  }

  function onRemoveSelected() {
    openv2Modal(() => (
      <ConfirmModal
        title={`Are you sure you want to remove ${
          size(selection) > 1
            ? `these ${size(selection)} resources`
            : 'this resource'
        } from the shortlist?`}
        confirmLabel="Confirm Remove"
        onConfirm={batchDeleteShortlistBlocks}
      />
    ));
  }

  const saveFilters = () => {
    dispatch(updateShortlist(shortlist.id, { default_resources_query: query }));
  };

  const handleSetQuery = q => {
    onSetQuery({ ...query, ...q });
  };

  const renderSearch = () => (
    <GridCell>
      <Grid gutters="XS">
        <GridCell width="auto">
          <RosterSearch
            smallView
            placeholder="Search shortlist"
            onSearch={handleSetQuery}
            query={query.query}
            hideSuggester
          />
        </GridCell>
        <GridCell width="auto">
          <ResourceAdvancedSearch
            slimline
            slimlineButtonLabel="Filters"
            resourceTypesModel={resourceType}
            query={query}
            onChange={handleSetQuery}
          />
        </GridCell>
        {!isPublic && canUpdate && (
          <GridCell width="auto">
            <PressStud
              icon={
                isEqual(
                  pickBy(query, identity),
                  shortlist.default_resources_query
                )
                  ? 'starred'
                  : 'star'
              }
              action={saveFilters}
            />
          </GridCell>
        )}
      </Grid>
    </GridCell>
  );

  const renderActions = () => (
    <GridCell>
      {!isEmpty(selection) ? (
        <Grid align="right" gutters="XS">
          <GridCell className="text-13-700" padding="XS" width="auto">
            {size(selection)} Selected
          </GridCell>
          <GridCell width="auto">
            <PressStud
              label={hasSelectedAll ? 'Unselect All' : 'Select All'}
              size="S"
              action={onSelectAll}
            />
          </GridCell>
          <GridCell width="auto">
            <Dropdown
              buttonClass="btn btn-secondary btn-small"
              buttonLabel="Actions"
              buttonIcon={
                <img src="/images/icon_actions_white.svg" alt="" width="12px" />
              }
            >
              <MenuItem onSelect={onAddSelectedToShortlist}>
                <span className="MenuItem-icon">
                  <img src="/images/icon_contact_shortlists.svg" alt="" />
                </span>
                <span className="MenuItem-label">
                  Add to existing shortlist
                </span>
              </MenuItem>
              <MenuItem onSelect={onNewShortlistFromSelected}>
                <span className="MenuItem-icon">
                  <img src="/images/icon_duplicate.svg" alt="" />
                </span>
                <span className="MenuItem-label">Add to new shortlist</span>
              </MenuItem>
              <MenuItem onSelect={requestAvailabilityFromSelected}>
                <span className="MenuItem-icon">
                  <img src="/images/icon_availability_request.svg" alt="" />
                </span>
                <span className="MenuItem-label">Request availability</span>
              </MenuItem>
              <MenuItem onSelect={onRemoveSelected}>
                <span className="MenuItem-icon">
                  <img src="/images/icon_delete.svg" alt="" />
                </span>
                <span className="MenuItem-label">Remove selected</span>
              </MenuItem>
            </Dropdown>
          </GridCell>
        </Grid>
      ) : (
        <Grid align="right" gutters="XS">
          <GridCell width="auto">
            <PressStud
              label="Add Resources"
              icon="addWhite"
              appearance="primary"
              size="S"
              action={() => {
                dispatch(
                  openModal('AddFromBlackbookModal', {
                    destination: 'SHORTLIST_SHORTLIST_BLOCKS',
                    store: 'shortlists',
                    dataPointers: [
                      {
                        label: 'shortlist',
                        pointTo: ['shortlists', 'data', shortlist.id]
                      }
                    ]
                  })
                );
              }}
            />
          </GridCell>
          <GridCell width="auto">
            <Popover>
              <PopoverTrigger>
                <PressStud label="Display settings" icon="show" size="S" />
              </PopoverTrigger>
              <PopoverContent className="ShortlistPresentView-detailsPopover">
                <div className="ShortlistPresentView-details-section inset-S">
                  <Grid vcenter>
                    <GridCell className="text-13-600">
                      Show availability
                    </GridCell>
                    <GridCell width="auto">
                      <Toggle
                        defaultChecked={shortlist.show_availability}
                        onChange={e => {
                          const checked = e.target.checked;
                          if (checked) {
                            onShortlistSubmit({
                              show_availability: createNewEventObject({
                                event_type: 'SHORTLIST_DATE'
                              })
                            });
                          } else {
                            onShortlistSubmit({ show_availability: null });
                          }
                        }}
                        icons={false}
                      />
                    </GridCell>
                  </Grid>
                  {shortlist.show_availability && (
                    <EventDateRange
                      size="S"
                      eventType="SHORTLIST"
                      data={shortlist.show_availability}
                      onChange={event =>
                        onShortlistSubmit({ show_availability: event })
                      }
                    />
                  )}
                </div>
                <div className="ShortlistPresentView-details-section inset-S">
                  <Label>Show resource fields</Label>
                  <RosterSearch
                    smallView
                    placeholder="Search resource fields"
                    onSearch={setResourceFieldsQuery}
                    query={resourceFieldsQuery.query}
                    hideSuggester
                  />
                  <ShortlistFieldManager
                    onSubmit={onShortlistSubmit}
                    default_fields={shortlist.default_fields}
                    shortlist_field_mapper={shortlistFieldMapper}
                    query={resourceFieldsQuery.query}
                  />
                </div>
              </PopoverContent>
            </Popover>
          </GridCell>
          <GridCell width="auto">
            <Dropdown
              buttonLabel={
                shortlist.layout ? shortlist.layout.toLowerCase() : 'Layout'
              }
              buttonIcon={<img src={`/images/${viewOption.icon}.svg`} alt="" />}
              buttonClass="btn btn-default btn-small capitalise"
            >
              {SHORTLIST_VIEW_OPTIONS.map(option => (
                <MenuItem
                  key={option.value}
                  onSelect={() => onShortlistSubmit({ layout: option.value })}
                >
                  <span className="MenuItem-icon">
                    <img src={`/images/${option.icon}.svg`} alt="" />
                  </span>
                  <span className="MenuItem-label">{option.label}</span>
                </MenuItem>
              ))}
            </Dropdown>
          </GridCell>
          {showOrderButton &&
          shortlist.shortlist_blocks.filter(({ archived }) => !archived)
            .length > 1 ? (
            <GridCell width="auto">
              <PressStud
                label="Order"
                size="S"
                action={() => setOrderEnabled(true)}
                icon="order"
              />
            </GridCell>
          ) : null}
        </Grid>
      )}
    </GridCell>
  );

  const renderOrder = () => (
    <div className="OrderAlert">
      <div className="OrderAlert-wrapper">
        <img src="/images/icon_order_white.svg" alt="Order" />
        <div>
          <h5>Editing Order</h5>
          <p>Re-order the list below and save when you’re done</p>
        </div>
        <div className="OrderAlert-buttons">
          <PressStud
            label="Cancel"
            size="S"
            action={() => setOrderEnabled(false)}
            appearance="outline"
          />
          <PressStud
            label="Save Order"
            appearance="primary"
            size="S"
            action={onSaveNewUserOrder}
            icon="tick"
          />
        </div>
      </div>
    </div>
  );

  return orderEnabled ? (
    <Grid cellPadding="XS">{renderOrder()}</Grid>
  ) : (
    <Grid cellPadding="XS">
      {renderSearch()}
      {!isPublic && canUpdate && renderActions()}
    </Grid>
  );
};

ShortlistToolbar.propTypes = {
  shortlist: PropTypes.object,
  isPublic: PropTypes.bool,
  selection: PropTypes.object,
  query: PropTypes.object,
  shortlistFieldMapper: PropTypes.object.isRequired,
  onShortlistSubmit: PropTypes.func,
  onSetQuery: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  hasSelectedAll: PropTypes.bool
};

export default ShortlistToolbar;
