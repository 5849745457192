import React from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from 'store/v1/ui/ui.actions.js';

const ModalClose = () => {
  const dispatch = useDispatch();
  const close = () => dispatch(closeModal());

  return (
    <span className="PageModal-close" onClick={close}>
      <img src="/images/icon_cross_black.svg" width="12px" alt="Close modal" />
    </span>
  );
};

ModalClose.props = {};

export default ModalClose;
