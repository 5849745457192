import { UI_ACTIONS } from 'store/v1/ui/ui.constants';

import uuid from 'uuid/v4';

const closeModal = () => {
  return { type: UI_ACTIONS.CLOSE_MODAL };
};

const openModal = (modalType, data) => {
  return { type: UI_ACTIONS.OPEN_MODAL, modalType, data };
};

const openContactSidebar = selectedContact => {
  return { type: UI_ACTIONS.OPEN_CONTACT_SIDEBAR, selectedContact };
};

const closeContactSidebar = () => {
  return { type: UI_ACTIONS.CLOSE_CONTACT_SIDEBAR };
};

const openProductionSidebar = (selectedProduction, options = {}) => {
  return {
    type: UI_ACTIONS.OPEN_PRODUCTION_SIDEBAR,
    selectedProduction,
    options
  };
};

const closeProductionSidebar = () => {
  return { type: UI_ACTIONS.CLOSE_PRODUCTION_SIDEBAR };
};

const openBookingSidebar = (selectedBooking, options = {}) => {
  return { type: UI_ACTIONS.OPEN_BOOKING_SIDEBAR, selectedBooking, options };
};

const closeBookingSidebar = () => {
  return { type: UI_ACTIONS.CLOSE_BOOKING_SIDEBAR };
};

const displayAlert = (alertType, body, data) => {
  return function (dispatch) {
    dispatch({
      type: UI_ACTIONS.DISPLAY_ALERT,
      alert: {
        alertType,
        body,
        data,
        id: uuid()
      }
    });
  };
};

const toggleSidebar = state => {
  return function (dispatch) {
    dispatch({
      type: UI_ACTIONS.TOGGLE_SIDEBAR,
      sidebarCollapsed: state
    });
  };
};

// UNUSED
// const hideAlert = alert => {
//   return function (dispatch) {
//     dispatch({
//       type: UI_ACTIONS.HIDE_ALERT
//     });
//   };
// };

export {
  closeModal,
  openModal,
  displayAlert,
  toggleSidebar,
  openContactSidebar,
  closeContactSidebar,
  openProductionSidebar,
  closeProductionSidebar,
  openBookingSidebar,
  closeBookingSidebar
};
