import React, { useContext } from 'react';

import TableContext from '../TableContext';
import TableFilters from './TableFilters/TableFilters';
import TablePagination from './TablePagination/TablePagination';
import { Grid, GridCell, SearchInput } from 'v1/components/shared';

const TableActions = () => {
  const {
    resultCount,
    showTotal,
    // PAGINATION
    showPagination,
    // FILTERING
    showSearch,
    _query,
    setGlobalFilter,
    showFilters,
    filterOptions
  } = useContext(TableContext);

  return (
    <Grid className="stack-S" gutters="XS">
      {(showSearch || showFilters || showPagination || showTotal) &&
        showTotal !== false && (
          <GridCell padding="XS" vcenter width="auto">
            <div className="text-13-600">
              {resultCount} Result{resultCount === 1 ? '' : 's'}
            </div>
          </GridCell>
        )}
      {showSearch && (
        <GridCell width="auto">
          <SearchInput size="S" query={_query} onChange={setGlobalFilter} />
        </GridCell>
      )}
      {showFilters && filterOptions.length > 0 && (
        <GridCell width="auto">
          <TableFilters />
        </GridCell>
      )}
      {showPagination && (
        <GridCell width="auto">
          <TablePagination />
        </GridCell>
      )}
    </Grid>
  );
};

TableActions.propTypes = {};

export default TableActions;
