import { BOOKING_ACTIONS } from './bookings.constants.js';
import { formatBooking } from './bookings.formatter.js';
import { pick, omit, get } from 'lodash';

const token = true;

export function getBookings(query, event_id = BOOKING_ACTIONS.GET_BOOKINGS) {
  return {
    types: [
      BOOKING_ACTIONS.GET_BOOKINGS,
      BOOKING_ACTIONS.GET_BOOKINGS_SUCCESS,
      BOOKING_ACTIONS.GET_BOOKINGS_FAILURE
    ],
    promise: client => client.post('/search/bookings', { data: query, token }),
    event_id
  };
}

export function getBookingsOnly(
  query,
  event_id = BOOKING_ACTIONS.GET_BOOKINGS_ONLY
) {
  // TODO: this is a quick fix
  // TODO: duplicated
  const bookingStatusTypes = ['CONFIRMED', 'PENCILLED', 'CANCELLED', 'EDITING'];
  const statusTypes = new Set([
    ...get(query, 'filters.status_type', []),
    ...bookingStatusTypes
  ]);
  const bookingQuery = {
    ...query,
    filters: {
      ...query.filters,
      status_type: Array.from(statusTypes)
    }
  };
  return {
    types: [
      BOOKING_ACTIONS.GET_BOOKINGS_ONLY,
      BOOKING_ACTIONS.GET_BOOKINGS_ONLY_SUCCESS,
      BOOKING_ACTIONS.GET_BOOKINGS_ONLY_FAILURE
    ],
    promise: client =>
      client.post('/search/bookings', { data: bookingQuery, token }),
    event_id
  };
}

export function getBatchBookings(
  ids,
  hydrateContactStore,
  event_id = BOOKING_ACTIONS.GET_BATCH_BOOKINGS
) {
  return {
    types: [
      BOOKING_ACTIONS.GET_BATCH_BOOKINGS,
      BOOKING_ACTIONS.GET_BATCH_BOOKINGS_SUCCESS,
      BOOKING_ACTIONS.GET_BATCH_BOOKINGS_FAILURE
    ],
    promise: client =>
      client.post(`/batch/bookings/search`, {
        token,
        data: {
          ids
        }
      }),
    event_id,
    hydrateContactStore
  };
}

export function getBooking(id, event_id = BOOKING_ACTIONS.GET_BOOKING) {
  return {
    types: [
      BOOKING_ACTIONS.GET_BOOKING,
      BOOKING_ACTIONS.GET_BOOKING_SUCCESS,
      BOOKING_ACTIONS.GET_BOOKING_FAILURE
    ],
    promise: client => client.get(`/bookings/${id}`, { token }),
    event_id
  };
}

export function createBooking(booking) {
  const data = formatBooking(booking);
  return {
    types: [
      BOOKING_ACTIONS.CREATE_BOOKING,
      BOOKING_ACTIONS.CREATE_BOOKING_SUCCESS,
      BOOKING_ACTIONS.CREATE_BOOKING_FAILURE
    ],
    promise: async client => {
      try {
        return await client.post('/bookings', { data, token });
      } catch (err) {
        err.booking = booking;
        throw err;
      }
    }
  };
}

export function updateBooking(id, booking, event_id = null) {
  const data = formatBooking(booking);

  return {
    types: [
      BOOKING_ACTIONS.UPDATE_BOOKING,
      BOOKING_ACTIONS.UPDATE_BOOKING_SUCCESS,
      BOOKING_ACTIONS.UPDATE_BOOKING_FAILURE
    ],
    promise: async client => {
      try {
        return await client.put(`/bookings/${id}`, { data, token });
      } catch (err) {
        err.booking = booking;
        throw err;
      }
    },
    event_id,
    assignmentsRemoved: booking.assignmentsRemoved
  };
}

export function deleteBooking(booking_id, contact_id) {
  return {
    types: [
      BOOKING_ACTIONS.DELETE_BOOKING,
      BOOKING_ACTIONS.DELETE_BOOKING_SUCCESS,
      BOOKING_ACTIONS.DELETE_BOOKING_FAILURE
    ],
    promise: client =>
      client.put(`/bookings/${booking_id}`, {
        data: { archived: true },
        token
      }),
    contact_id,
    booking_id
  };
}

export function unarchiveBooking(booking_id, contact_id) {
  return {
    types: [
      BOOKING_ACTIONS.UPDATE_BOOKING,
      BOOKING_ACTIONS.UPDATE_BOOKING_SUCCESS,
      BOOKING_ACTIONS.UPDATE_BOOKING_FAILURE
    ],
    promise: client =>
      client.put(`/bookings/${booking_id}`, {
        data: { archived: false },
        token
      }),
    contact_id,
    booking_id
  };
}

export function createInvoice({ booking_id }, invoice, event_id = null) {
  if (
    invoice.id &&
    typeof invoice.id === 'string' &&
    invoice.id.startsWith('temp-')
  )
    delete invoice.id;
  invoice = pick(invoice, [
    'archived',
    'document_data',
    'document_type',
    'file',
    'file_id',
    'id',
    'order',
    'status'
  ]);
  return {
    types: [
      BOOKING_ACTIONS.CREATE_INVOICES,
      BOOKING_ACTIONS.CREATE_INVOICES_SUCCESS,
      BOOKING_ACTIONS.CREATE_INVOICES_FAILURE
    ],
    promise: client =>
      client.post(`/bookings/${booking_id}/associations/invoices`, {
        data: invoice,
        token
      }),
    booking_id
  };
}

export function updateInvoice({ booking_id, invoice_id }, data) {
  data = omit(data, 'currency');
  return {
    types: [
      BOOKING_ACTIONS.UPDATE_INVOICE,
      BOOKING_ACTIONS.UPDATE_INVOICE_SUCCESS,
      BOOKING_ACTIONS.UPDATE_INVOICE_FAILURE
    ],
    promise: client =>
      client.put(
        `/bookings/${booking_id}/associations/invoices/${invoice_id}`,
        {
          data,
          token
        }
      ),
    booking_id,
    invoice_id
  };
}

export function deleteInvoice({ booking_id, invoice_id }, data) {
  return {
    types: [
      BOOKING_ACTIONS.DELETE_INVOICE,
      BOOKING_ACTIONS.DELETE_INVOICE_SUCCESS,
      BOOKING_ACTIONS.DELETE_INVOICE_FAILURE
    ],
    promise: client =>
      client.del(
        `/bookings/${booking_id}/associations/invoices/${invoice_id}`,
        {
          data,
          token
        }
      ),
    booking_id,
    invoice_id
  };
}

export function sendBookingConfirmation(booking_id, event_id = null) {
  return {
    types: [
      BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION,
      BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION_SUCCESS,
      BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION_FAILURE
    ],
    promise: client =>
      client.post(`/bookings/${booking_id}/send_booking_confirmation`, {
        token
      }),
    event_id
  };
}
