import {
  Menu,
  MenuContent,
  MenuItem,
  MenuItemGroup,
  MenuItemGroupLabel,
  MenuPositioner,
  MenuTrigger,
  Portal
} from '@ark-ui/react';
import { Icon } from 'shared';
import SidebarButton from './SidebarButton';
import SidebarSeparator from './SidebarSeparator';
import { Capability, FeatureFlag, ResourceType } from '__types__';
import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';

import { useRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import Separated from 'v4/shared/components/Separated';
import { SplashlightDocuSignModal } from 'modals';

export interface SidebarGlobalCreateConfig {
  useResourceTypes: () => ResourceType[];
  useV1Modal: () => (modal: string, data?: any) => void;
  useV2Modal: () => (modal: React.FC) => void;
  useRestrictedAccess: typeof useRestrictedAccessCallback;
  useDocusignIntegration: () => any;
}

export function createSidebarGlobalCreate(config: SidebarGlobalCreateConfig) {
  return function SidebarGlobalCreate() {
    const resourceTypes = config.useResourceTypes();
    const can = config.useRestrictedAccess();
    const openV1Modal = config.useV1Modal();
    const openV2Modal = config.useV2Modal();
    const docusignIntegration = config.useDocusignIntegration();

    const resolveActions = ({ value }) => {
      if (value.includes('resourceType')) {
        const resourceTypeId = parseInt(value.split('-')[1]);
        return openV1Modal('ResourceCreateModal', { resource_type_id: resourceTypeId });
      }

      switch (value) {
        case 'new-production':
          return openV1Modal('ProductionSetUpModal', { create: true });
        case 'new-shortlist':
          return openV1Modal('ShortlistCreateModal', { create: true });
        case 'new-booking':
          return openV1Modal('ResourceEventsModal');
        case 'new-callsheet':
          return openV1Modal('CallsheetCreateModal', { create: true });
        case 'new-callout':
          return openV1Modal('CalloutCreateModal', { create: true });
        case 'new-docusign-request':
          return openV2Modal(() => <SplashlightDocuSignModal />);
        default:
          return;
      }
    }
    const shouldShowCreateResourcesGroup = (resourceTypes?.length ?? 0) !== 0 && can(FeatureFlag.RESOURCES, Capability.RESOURCE_CREATE);
    const shouldShowCreateEntitiesGroup = can(FeatureFlag.PRODUCTIONS, Capability.PRODUCTION_CREATE) || can(FeatureFlag.SHORTLISTS, Capability.SHORTLIST_CREATE) || can(FeatureFlag.BOOKINGS, Capability.BOOKING_CREATE) || can(FeatureFlag.CALLSHEETS, Capability.CALLSHEET_CREATE) || can(FeatureFlag.CALLOUTS, Capability.CALLOUT_CREATE);
    return (
      <Menu
        closeOnSelect
        positioning={{ gutter: 8, placement: 'right-end', sameWidth: true }}
        onSelect={resolveActions}
      >
        <MenuTrigger className="tw-text-left tw-flex tw-bg-gradient-to-r tw-from-eggplant-lighter/40 tw-shadow-sm tw-to-eggplant tw-w-full tw-text-white/80 tw-items-center tw-block tw-rounded-md tw-border-1 tw-border-eggplant-lightest/40 tw-p-2 tw-text-eggplant-lightest tw-ring-1 hover:tw-ring-eggplant-lightest/60 focus:tw-ring-2 tw-ring-eggplant-lightest/40">
          <span className="tw-flex-0 tw-mr-2">
            <Icon name="add" />
          </span>
          <span className="tw-flex-1 tw-truncate">Create</span>
        </MenuTrigger>
        <Portal>
          <MenuPositioner className="!tw-z-10 tw-shadow-xl">
            <MenuContent className="tw-bg-eggplant tw-w-full tw-w-[200px] tw-max-h-[65vh] tw-overflow-y-auto tw-border-1 tw-border-solid tw-border-eggplant-lighter/60 tw-text-white tw-p-4 tw-rounded-lg">

              <Separated divider={<SidebarSeparator className="tw-my-2" />}>
                <Separated.Group>
                  {shouldShowCreateResourcesGroup &&
                    <MenuItemGroup id="create-resources" className="tw-mb-2">
                      <MenuItemGroupLabel
                        htmlFor="resources"
                        className="tw-text-eggplant-lightest tw-text-base tw-w-full"
                      >
                        <div className="tw-flex">
                          <div className="tw-truncate tw-flex-1">New resource</div>
                        </div>
                      </MenuItemGroupLabel>
                      {resourceTypes.map(resourceType => (
                        <MenuItem asChild id={`resourceType-${resourceType.id}`}>
                          <SidebarButton
                            icon={
                              // todo: Icons need revisiting when we have a proper icon set for users to choose from
                              <img
                                src={`/images/${RESOURCE_TYPE_ICONS[resourceType.icon].white}`}
                                alt={resourceType.name}
                                width="12px"
                                className="tw-opacity-60"
                              />
                            }
                          >
                            {resourceType.name}
                          </SidebarButton>
                        </MenuItem>
                      ))}
                    </MenuItemGroup>
                  }
                </Separated.Group>

                <Separated.Group>
                  {shouldShowCreateEntitiesGroup &&
                    <MenuItemGroup id="create-entities">
                      <MenuItemGroupLabel
                        htmlFor="new-entities"
                        className="tw-text-eggplant-lightest tw-text-base tw-w-full"
                      >
                        <div className="tw-flex">
                          <div className="tw-truncate tw-flex-1">New</div>
                        </div>
                      </MenuItemGroupLabel>
                      {can(FeatureFlag.PRODUCTIONS, Capability.PRODUCTION_CREATE) && (
                        <MenuItem asChild id="new-production">
                          <SidebarButton keyboardShortcut="P" icon={<Icon name="v4_project" />}>Production</SidebarButton>
                        </MenuItem>
                      )}
                      {can(FeatureFlag.SHORTLISTS, Capability.SHORTLIST_CREATE) && (
                        <MenuItem asChild id="new-shortlist">
                          <SidebarButton keyboardShortcut="S" icon={<Icon name="v4_shortlist" />}>Shortlist</SidebarButton>
                        </MenuItem>
                      )}
                      {can(FeatureFlag.BOOKINGS, Capability.BOOKING_CREATE) && (
                        <MenuItem asChild id="new-booking">
                          <SidebarButton keyboardShortcut="B" icon={<Icon name="v4_booking" />}>Booking</SidebarButton>
                        </MenuItem>
                      )}
                      {can(FeatureFlag.CALLSHEETS, Capability.CALLSHEET_CREATE) && (
                        <MenuItem asChild id="new-callsheet">
                          <SidebarButton keyboardShortcut="C" icon={<Icon name="v4_callsheet" />}>Callsheet</SidebarButton>
                        </MenuItem>
                      )}
                      {can(FeatureFlag.CALLOUTS, Capability.CALLOUT_CREATE) && (
                        <MenuItem asChild id="new-callout">
                          <SidebarButton keyboardShortcut="O" icon={<Icon name="v4_callout" />}>Callout</SidebarButton>
                        </MenuItem>
                      )}
                      {can(
                        FeatureFlag.CUSTOM_SPLASHLIGHT,
                        Capability.DOCUSIGN_SEND,
                        null
                      ) && docusignIntegration?.enabled && (
                          <MenuItem asChild id="new-docusign-request">
                            <SidebarButton keyboardShortcut="O" icon={<Icon name="v4_callsheet" />}>Send DocuSign Request</SidebarButton>
                          </MenuItem>
                        )}
                    </MenuItemGroup>
                  }
                </Separated.Group>

              </Separated>

            </MenuContent>
          </MenuPositioner>
        </Portal>
      </Menu>
    );
  };

}