import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Portal } from 'shared';
import flyoverPositioner from '../flyoverPositioner';
import FlyoverContext from '../FlyoverContext';
import styles from './FlyoverPortal.module.scss';

const FlyoverPortal = ({ hasHelper = false, children }) => {
  const { config, isOpen, closeFlyover, triggerRect } = useContext(
    FlyoverContext
  );

  if (!isOpen) {
    return null;
  }

  const getPosition = contentRect =>
    flyoverPositioner(
      triggerRect,
      contentRect,
      hasHelper,
      ...Object.values(config)
    );

  return (
    <Portal
      className={styles.FlyoverPortal}
      position={getPosition}
      onClickOutside={closeFlyover}
    >
      {children}
    </Portal>
  );
};

FlyoverPortal.propTypes = {
  hasHelper: PropTypes.bool,
  children: PropTypes.any
};

export default FlyoverPortal;
