import React, { Component } from 'react';
import { VideoPlayer } from 'v1/components/shared';
import { isEmpty, size, get, chain } from 'lodash';
import { cleanUrl, isImageFromURL, isVideoFromURL } from 'v1/helpers/misc';
import { LazyImage } from 'v1/components/shared';
import { convertImageFileWithCloudinary } from 'lib/media/imageHelper';
import InstagramEmbed from 'react-instagram-embed';
import classnames from 'classnames';

import { makeFileURL } from 'lib/s3/s3Helper';
import './MediaCarousel.scss';
import {
  convertVideoFileWithCloudinary,
  getVideoCoverImage
} from 'lib/media/videoHelper';
import { TailwindRoot } from '~/v4/shared/TailwindRoot';

export class MediaCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: props.media || [],
      previousMediaSize: size(props.media),
      assetDimensions: []
    };
  }
  componentDidMount = () => {
    this.setState({ loaded: true });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (size(this.props.media) !== size(nextProps.media)) {
      this.setState({ media: nextProps.media });
    }
  }
  renderMediaType = (media, index) => {
    const { layout } = this.props;
    let embed_id, mediaItem;
    if (isEmpty(media)) return;
    switch (media.pin_type) {
      case 'youtube/video':
      case 'vimeo/video':
      case 'video/mp4':
      case 'video/mov':
      case 'video/mkv':
      case 'video/mpeg':
      case 'video/ogv':
      case 'video/webm':
      case 'video/wmv':
      case 'video/avi':
      case 'video/quicktime':
      case 'video/x-ms-wmv':
        embed_id = media.url;
        const coverImage = getVideoCoverImage(embed_id);
        const videoUrl = media.file
          ? convertVideoFileWithCloudinary(embed_id)
          : embed_id;
        mediaItem = (
          <VideoPlayer
            className={classnames(['MediaCarousel-video', layout])}
            key={index}
            src={videoUrl}
            coverImage={getVideoCoverImage(media.url)}
            settings={{
              showPreview: true,
              responsive: layout === 'INLINE',
              disableControls: true
            }}
          />
        );
        break;
      case 'instagram/image':
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/webp':
      case 'image/heic':
      case 'image/tif':
      case 'image/avif':
      case 'image/bmp':
        const pinFile = get(media, 'file', null);
        const url = pinFile ? makeFileURL(pinFile) : media.url;
        const resizedUrl = convertImageFileWithCloudinary(media.url);
        mediaItem =
          layout === 'FILL' ? (
            <span
              className="MediaCarousel-item-imageFill"
              style={{ backgroundImage: `url('${resizedUrl}')` }}
            />
          ) : (
            <LazyImage
              key={index}
              src={[resizedUrl, url]}
              width="100%"
              alt=""
            />
          );
        break;
      case 'file':
        const formattedMedia = {
          pin_type: get(media, 'file.mime_type'),
          url: makeFileURL(media.file),
          file: media.file
        };
        return this.renderMediaType(formattedMedia, index);
      case 'external_link':
        if (media.file) {
          const formattedMedia = {
            pin_type: get(media, 'file.mime_type'),
            url: makeFileURL(media.file),
            file: media.file
          };
          return this.renderMediaType(formattedMedia, index);
        }
        const pinURL = media.url;
        if (isImageFromURL(pinURL)) {
          let extension = cleanUrl(pinURL).split('.').pop();
          extension = extension.toLowerCase();
          const formattedMedia = {
            pin_type: `image/${extension}`,
            url: pinURL
          };
          return this.renderMediaType(formattedMedia, index);
        }
        if (isVideoFromURL(pinURL)) {
          let extension = cleanUrl(pinURL).split('.').pop();
          extension = extension.toLowerCase();
          const formattedMedia = {
            pin_type: `video/${extension}`,
            url: pinURL
          };
          return this.renderMediaType(formattedMedia, index);
        }
        if (pinURL.includes('instagram.com/p/')) {
          mediaItem = (
            <InstagramEmbed
              url={media.url}
              hideCaption={true}
              containerTagName="div"
              maxWidth={320}
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
          );
        }
        break;
      default:
        break;
    }
    return (
      <span
        className={classnames(['MediaCarousel-item', layout])}
        key={index}
        name={`media-${index}`}
      >
        {mediaItem ?? (
          <TailwindRoot className="tw-flex tw-min-h-[300px] tw-items-center tw-justify-center tw-text-sm">
            <div className="tw-py-2 tw-px-4 tw-inline-block tw-rounded-full tw-bg-gray-500 tw-text-gray-300">
              Preview unavailable
            </div>
          </TailwindRoot>
        )}
      </span>
    );
  };
  renderSlider() {
    const { media, layout } = this.props;
    let galleryList = chain(media)
      .filter(p => !p.archived)
      .orderBy(['order', 'asc'])
      .value();
    return (
      <div className={classnames(['MediaCarousel-carousel', layout])}>
        {!isEmpty(galleryList) &&
          galleryList.map((item, index) => this.renderMediaType(item, index))}
      </div>
    );
  }
  render() {
    const { /*media,*/ layout } = this.props;

    return (
      <div className={classnames(['MediaCarousel', layout])}>
        {this.renderSlider()}
      </div>
    );
  }
}

export default MediaCarousel;
