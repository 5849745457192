import React from 'react';
import classnames from 'classnames';

import { RestrictedInput } from 'shared';
import {
  InputMetaProps,
  InputStylingProps
} from 'shared/inputs/__types__/Inputs';
import useInputClasses from 'shared/inputs/useInputClasses';

import styles from './Inputster.module.scss';

type InputsterProps = InputMetaProps &
  InputStylingProps & {
    value?: string | number;
    onClick?: () => void;
  };

const Inputster = (props: InputsterProps) => {
  const inputClasses = useInputClasses(props);
  const { value, placeholder, isDisabled, isRestricted, onClick } = props;

  const showValue = value !== null && typeof value !== 'undefined';

  if (isRestricted) {
    return <RestrictedInput {...props} />;
  }

  const handleClick = () => {
    !isDisabled && onClick && onClick();
  };

  return (
    <div
      className={classnames(inputClasses, styles.Inputster, {
        [styles.isDisabled]: isDisabled,
        [styles.Placeholder]: !showValue
      })}
      onClick={handleClick}
    >
      <div className={styles.Inner}>{showValue ? value : placeholder}</div>
    </div>
  );
};

export default Inputster;
