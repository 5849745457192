export const currencyFormatter = (locale, currency) => {
  // locale not yet in API, so can come through as `null`
  // fall back to defaults internally here
  return new Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    currency: currency || 'USD'
  });
};

export const convertToLowestDenomination = (amount = 0) => {
  // If it's a whole number eg. 100 we just need to * by 100
  if (Number.isInteger(amount)) {
    return amount * 100;
  }

  // If it has a decimal place then we need to convert it to a string to split abd do the * 100 calculation to the whole number.
  // Let's assume that it's always a . and never a , - and that there's only one (for now)
  const amountToString = amount.toString();
  const splitString = amountToString.split('.');
  return parseInt(splitString[0]) * 100 + parseInt(splitString[1]);
};

export const convertToWholeUnit = (amount = 0) => amount / 100;

export const displayCurrency = (amount, currency) => {
  let value = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD'
  }).format(convertToWholeUnit(amount));
  return value;
};

export const shortenInterval = interval => {
  switch (interval) {
    case 'DAILY':
      interval = 'day';
      break;
    case 'HOURLY':
      interval = 'hour';
      break;
    default:
      break;
  }
  return interval;
};

export const formatCurrency = (value, curr) => {
  const val = convertToWholeUnit(value);
  return val ? currencyFormatter(null, curr).format(val) : null;
};
