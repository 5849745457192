import React, { forwardRef, useState } from 'react';
import ReactPlayer from 'react-player';
import classnames from 'classnames';

import './VideoPlayer.scss';
import LazyImage from 'v1/components/shared/general/LazyImage/LazyImage';

interface VideoPlayerProps {
  coverImage?: string | string[];
  src: string;
  className?: string;
  settings?: {
    disableControls?: boolean;
    width?: string;
    height?: string;
    autoPlay?: boolean;
  };
}

const PLAYER_STYLES = {
  background: 'radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)',
  borderRadius: '64px',
  width: '64px',
  height: '64px',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // for initial load
  opacity: '0'
} as const;

const ICON_STYLES = {
  borderStyle: 'solid',
  borderWidth: '16px 0px 16px 26px',
  borderColor: 'transparent transparent transparent white',
  marginLeft: '7px'
} as const;

const PlayerShadow = forwardRef<HTMLDivElement>((props, ref) => (
  <div className="react-player__shadow" style={PLAYER_STYLES} ref={ref}>
    <div className="react-player__play-icon" style={ICON_STYLES}></div>
  </div>
));

function VideoPlayer({
  src,
  coverImage,
  className,
  settings = {}
}: VideoPlayerProps) {
  const playIconRef = React.useRef<HTMLDivElement>(null);
  const handleLoad = () => {
    if (playIconRef.current) {
      playIconRef.current.style.opacity = '1';
    }
  };

  return (
    <div
      className={classnames(
        { disableControls: settings.disableControls },
        'VideoPlayer',
        className
      )}
    >
      <ReactPlayer
        url={src}
        className="video-player"
        width={settings.width || '100%'}
        height={settings.height || '360px'}
        playing={settings.autoPlay}
        light={
          settings?.autoPlay
            ? undefined
            : coverImage && <LazyImage onLoad={handleLoad} src={coverImage} />
        }
        playIcon={<PlayerShadow ref={playIconRef} />}
        config={{
          youtube: {
            playerVars: {
              controls: !settings.disableControls
            }
          },
          vimeo: {
            playerOptions: {
              controls: !settings.disableControls
            }
          },
          file: {
            attributes: {
              poster: <div>cover{coverImage || null}</div>,
              preload: 'metadata'
            }
          }
        }}
        controls={!settings.disableControls}
      />
    </div>
  );
}

export default VideoPlayer;
