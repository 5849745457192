import {
  Menu,
  MenuContent,
  MenuItem,
  MenuPositioner,
  MenuTrigger,
  Portal,
} from '@ark-ui/react'
import { Icon } from 'shared';
import SidebarButton from './SidebarButton';

import { IntercomAPI } from 'react-intercom/dist/lib/react-intercom';

const feedbackPortalUrl = "https://atellio.atlassian.net/servicedesk/customer/portals";

const SidebarHelpMenu = () => {

  const resolveActions = ({ value }) => {
    switch (value) {
      case 'intercom':
        return IntercomAPI('show');
      case 'feedback':
        return window.open(feedbackPortalUrl, '_blank');
      default:
        return;
    }
  }

  return (
    <Menu closeOnSelect onSelect={resolveActions} positioning={{ gutter: 8, placement: 'right-end', sameWidth: true }}>
      <MenuTrigger className="tw-rounded-md tw-w-full tw-flex tw-items-center tw-text-left tw-p-2 tw-block hover:tw-bg-eggplant-lighter/60 hover:tw-text-white/100 tw-block focus:tw-outline-none focus:tw-ring-2 tw-ring-eggplant-lightest/60">
        <span className="tw-flex-0 tw-mr-3"><Icon name="v4_support" /></span>
        <span className="tw-flex-1 tw-truncate">Help & Support</span>
      </MenuTrigger>
      <Portal>
        <MenuPositioner className="!tw-z-10 tw-shadow-xl">
          <MenuContent className="tw-bg-eggplant tw-border-1 tw-border-solid tw-border-eggplant-lighter/60 tw-text-white tw-p-4 tw-rounded-lg tw-border-eggplant-light">
            <MenuItem asChild id="intercom">
              <SidebarButton icon={<Icon name="v4_comment" />}>Talk to our team</SidebarButton>
            </MenuItem>
            <MenuItem asChild id="feedback">
              <SidebarButton icon={<Icon name="v4_support" />} target="_blank">Send feedback</SidebarButton>
            </MenuItem>
          </MenuContent>
        </MenuPositioner>
      </Portal>
    </Menu>
  );
};

export default SidebarHelpMenu;
