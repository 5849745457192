import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBatchBookings } from 'store/v1/bookings/bookings.actions.js';
import { getBatchContactEvents } from 'store/v1/contacts/contacts.actions.js';

import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import _map from 'lodash/map';

function useGetContactEvents({ contactIds, start_date, end_date, load = [] }) {
  const dispatch = useDispatch();
  const contactEvents = useSelector(state => state.contacts.events_data);
  const bookings = useSelector(state => state.bookings);

  useEvent([CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS], {
    onSuccess: () => {
      getBookingForContactEvents();
    }
  });

  useEffect(() => {
    if (start_date && end_date && !_isEmpty(contactIds)) {
      dispatch(getBatchContactEvents(start_date, end_date, contactIds));
    }
  }, load);

  function getBookings(ids) {
    dispatch(getBatchBookings(ids));
  }

  function checkIfBookingExists(id) {
    return _has(bookings, ['data', id]);
  }
  function getBookingForContactEvents() {
    let requiredBookingIds = [];
    contactIds.map(id =>
      _map(_get(contactEvents, id), event => {
        if (!checkIfBookingExists(event.entity_id)) {
          requiredBookingIds.push(event.entity_id);
        }
      })
    );
    if (!_isEmpty(requiredBookingIds)) {
      getBookings(requiredBookingIds);
    }
  }
}

export default useGetContactEvents;
