import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Checkbox.scss';

const Checkbox = ({
  className,
  id,
  name,
  checked,
  onChange,
  onBlur,
  label,
  description,
  validity,
  size,
  disabled,
  ...props
}) => (
  <label
    className={classnames({ [`Checkbox_${size}`]: size }, [
      'Checkbox',
      className
    ])}
    htmlFor={id}
    {...props}
  >
    {label && <span className="Checkbox-label">{label}</span>}
    <input
      name={name}
      id={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      className="Checkbox-input"
      disabled={disabled}
    />
    {description && <p className="Checkbox-description">{description}</p>}
  </label>
);

Checkbox.propTypes = {
  // Component props
  size: PropTypes.oneOf(['S', 'M', 'L']),
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  // ...props
  // All valid attributes for <input type="checkbox" />
  // Keeping in mind React-specific attribute case:
  // https://react-cn.github.io/react/docs/tags-and-attributes.html
};

export default Checkbox;
