import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, size, chain, find } from 'lodash';

import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import {
  createGroup,
  updateGroup,
  reorderGroup
} from 'store/v1/groups/groups.actions.js';
import { GROUP_ACTIONS } from 'store/v1/groups/groups.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { orderReducer } from 'v1/helpers/misc';
import {
  Grid,
  GridCell,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation,
  PressStud,
  EmptyGeneric,
  GroupItemCellEditable,
  GroupItemCell,
  ListCell
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import Sortable from 'react-sortablejs';

import './ResourceTypeGroupsModal.scss';

const NEW_GROUP = {
  colour: '#eaeaea' // From Variables $system_white-base
};

const ResourceTypeGroupsModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const activeResourceType = useSelector(
    state => state.ui.data.activeResourceType
  );
  const groups = useSelector(state => state.groups);
  const [groupsList, setGroupsList] = useState();
  const [showNewGroupField, setShowNewGroupField] = useState(false);

  // TODO: (revamp) : review this as it doesn't look right even though it matches the old
  useEffect(() => {
    setGroupsList(
      chain(orderReducer(groups))
        .filter(
          g => !g.archived && parseInt(g.entity_id) === activeResourceType.id
        )
        .orderBy(['order', 'asc'])
        .value()
    );
  }, [groups, activeResourceType.id]);

  useEvent(
    [
      GROUP_ACTIONS.UPDATE_GROUP,
      GROUP_ACTIONS.ADD_TO_GROUP,
      GROUP_ACTIONS.REMOVE_FROM_GROUP,
      GROUP_ACTIONS.REORDER_GROUPS
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Group updated'));
      },
      onFailure: () => dispatch(displayAlert('error', groups.error.message))
    }
  );

  useEvent([GROUP_ACTIONS.CREATE_GROUP], {
    onSuccess: () => {
      setShowNewGroupField(false);
      dispatch(displayAlert('success', 'Group created'));
    },
    onFailure: () =>
      dispatch(displayAlert('error', 'Failed to create new group'))
  });

  const onDeleteGroup = group => {
    dispatch(
      openModal('ConfirmationModal', {
        title: `Are you sure you want to remove the group ${group.name}?`,
        text: "Contacts within this group won't be removed",
        button: 'Confirm Delete',
        action: 'DELETE_GROUP',
        group_id: group.id,
        group_name: group.name
      })
    );
  };

  const onUpdateGroup = group => {
    dispatch(updateGroup(group.id, group));
  };

  const onCreateGroup = group => {
    dispatch(createGroup({ ...group, entity_id: activeResourceType.id }));
  };

  const onUpdateGroupOrder = order => {
    const updatedGroupsList = order.map((id, index) => {
      let group = find(groupsList, g => g.id === parseFloat(id));
      group.order = index;
      return group;
    });
    setGroupsList(updatedGroupsList);
    dispatch(reorderGroup(updatedGroupsList));
  };

  const renderGroupList = _ => {
    if (!isEmpty(groupsList)) {
      return (
        <Grid direction="column">
          <h3>
            {size(groupsList)} Group{size(groupsList) > 1 && 's'}
          </h3>
          <Sortable
            options={{
              group: 'groups',
              draggable: '.GroupItemCell',
              animation: 150
            }}
            className="ListCellGroup stack-XS"
            onChange={onUpdateGroupOrder}
          >
            {groupsList.map(group => (
              <GroupItemCell
                key={group.id}
                group={group}
                onDeleteGroup={onDeleteGroup}
                onUpdateGroup={onUpdateGroup}
                isLoading={groups.loading === 'UPDATE_GROUP'}
              />
            ))}
          </Sortable>
          {renderNewGroup()}
        </Grid>
      );
    }
    return (
      <div className="ResourceTypeGroupsModal-groupList text-center">
        <EmptyGeneric
          icon="/images/icon_colour_team.svg"
          title="No groups"
          description="Create a group to segment your workforce"
        />
        {renderNewGroup()}
      </div>
    );
  };

  const renderNewGroup = _ =>
    showNewGroupField ? (
      <ListCell padding="XS">
        <Grid vcenter gutters="XS">
          <GroupItemCellEditable
            group={NEW_GROUP}
            onUpdateGroup={onCreateGroup}
            onCancelEdit={() => setShowNewGroupField(false)}
            isLoading={groups.loading === 'CREATE_GROUP'}
          />
        </Grid>
      </ListCell>
    ) : (
      <ListCell onClick={() => setShowNewGroupField(true)}>
        <Grid vcenter gutters="XS">
          <GridCell width="auto">
            <img src="/images/icon_add_black.svg" alt="" width="13px" />
          </GridCell>
          <GridCell className="text-13-600">New Group</GridCell>
        </Grid>
      </ListCell>
    );

  return (
    <ModalWrapper size="S">
      <ModalHeader title="Manage resource groups" />
      <ModalContent>
        <ModalScrollable>{renderGroupList()}</ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Done"
            appearance="primary"
            action={onRequestClose}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

ResourceTypeGroupsModal.props = {
  onRequestClose: PropTypes.func
};

export default ResourceTypeGroupsModal;
