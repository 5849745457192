import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { find, get } from 'lodash';

import { Capability } from '__types__';
import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';
import {
  getResourceTypes,
  getDefaultResourceType
} from 'store/v1/contacts/contacts.selectors.js';

import { MenuItem, RestrictedDropdown } from 'v1/components/shared';

import styles from './ResourceTypeSelectorDisplay.module.scss';

/**
 * @desc A 'display' version of a selector for resource types, for displaying alongside a resource.
 */
const ResourceTypeSelectorDisplay = ({
  label = null,
  size,
  resourceTypeId,
  disabled,
  onChange
}) => {
  const resourceTypes = useSelector(state => getResourceTypes(state));
  const defaultResourceType = useSelector(getDefaultResourceType);
  const resourceType =
    find(resourceTypes, r => r.id === resourceTypeId) || defaultResourceType;

  const handleChange = id => {
    onChange && onChange(id);
  };

  useEffect(() => {
    if (!resourceTypeId) {
      handleChange(get(resourceType, 'id'));
    }
  }, []);

  return (
    <div className={styles.ResourceTypeSelectorDisplay}>
      <RestrictedDropdown
        showLabel
        capabilitiesAtLeastOne={[Capability.RESOURCE_UPDATE]}
        buttonLabel={
          <>
            {label && `${label} `}
            <span className={styles.ResourceType}>
              {get(resourceType, 'name') || 'Resource Type'}
            </span>
          </>
        }
        buttonClass={classnames([
          styles.Selector,
          {
            [styles[`Selector_${size}`]]: size
          }
        ])}
        disabled={disabled}
      >
        {resourceTypes.map(resourceType => (
          <MenuItem
            key={resourceType.id}
            capabilities={Capability.RESOURCE_UPDATE}
            onSelect={() => handleChange(resourceType.id)}
          >
            <span className="MenuItem-icon">
              <img
                src={`/images/${
                  RESOURCE_TYPE_ICONS[resourceType.icon || 'default'].black
                }`}
                alt=""
              />
            </span>
            <span className="MenuItem-label">{resourceType.name}</span>
          </MenuItem>
        ))}
      </RestrictedDropdown>
    </div>
  );
};

ResourceTypeSelectorDisplay.props = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['S', 'L']),
  resourceTypeId: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default ResourceTypeSelectorDisplay;
