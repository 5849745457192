import Tracker from 'lib/mixpanel/trackers/Tracker';
import { MixActions } from './actions';

export default class AuthTracker extends Tracker {
  /**
   * Receive a Mixpanel event and decide whether the tracker should process it
   * @param {MixpanelEvent} event
   */
  track(event) {
    switch (event.action) {
      case MixActions.USER_SIGN_IN:
        return this._trackUserSignin(event);
      case MixActions.USER_SIGN_OUT:
        return this._trackUserSignout(event);
      case MixActions.USER_PROFILE_CHANGE:
        return this._trackProfileChange(event);
      case MixActions.USER_PASSWORD_CHANGE:
        return this._trackChangePassword(event);
      default:
        return;
    }
  }

  /**
   * Track a user sign in
   * @param {MixpanelEvent} event
   * @private
   */
  _trackUserSignin(event) {
    this.sendEvent(event.action, event, {
      ...event.properties
    });
  }

  /**
   * Track a user sign out
   * @param {MixpanelEvent} event
   * @private
   */
  _trackUserSignout(event) {
    this.sendEvent(event.action, event, {
      ...event.properties
    });
    this.mixpanelClient.reset();
  }

  /**
   * Track a user changing their profile details
   * @param {MixpanelEvent} event
   * @private
   */
  _trackProfileChange(event) {
    this.mixpanelClient.people.set({
      $email: event.properties.email,
      $first_name: event.properties.firstName,
      $last_name: event.properties.lastName,
      $avatar: event.properties.profileImage
    });
    this.sendEvent(event.action, event, {
      ...event.properties
    });
  }

  /**
   * Track a user changing their password
   * @param {MixpanelEvent} event
   * @private
   */
  _trackChangePassword(event) {
    this.sendEvent(event.action, event, {
      ...event.properties
    });
  }
}
