import { EXPENSES_ACTIONS } from 'store/v1/expenses/expenses.constants.js';
import { pick, omit } from 'lodash';

const token = true;

// UNUSED
// export const getExpenses = () => {
//   return {
//     types: [
//       EXPENSES_ACTIONS.GET_EXPENSES,
//       EXPENSES_ACTIONS.GET_EXPENSES_SUCCESS,
//       EXPENSES_ACTIONS.GET_EXPENSES_FAILURE
//     ],
//     promise: client => client.get('/expenses', { token })
//   };
// };

export const getBookingExpenses = (bookingIds = []) => {
  return {
    types: [
      EXPENSES_ACTIONS.GET_EXPENSES,
      EXPENSES_ACTIONS.GET_EXPENSES_SUCCESS,
      EXPENSES_ACTIONS.GET_EXPENSES_FAILURE
    ],
    promise: client =>
      client.get(`/bookings/${bookingIds.toString()}/associations/expenses`, {
        token
      }),
    topUpData: true
  };
};

export const createExpense = data => {
  return {
    types: [
      EXPENSES_ACTIONS.CREATE_EXPENSE,
      EXPENSES_ACTIONS.CREATE_EXPENSE_SUCCESS,
      EXPENSES_ACTIONS.CREATE_EXPENSE_FAILURE
    ],
    promise: client => client.post('/expenses', { data, token })
  };
};

export const updateExpense = (id, data) => {
  const payload = pick(data, [
    'id',
    'commit_vendor',
    'commit_rate_amount',
    'commit_rate_currency',
    'commit_rate_quantity',
    'commit_rate_interval'
  ]);
  return {
    types: [
      EXPENSES_ACTIONS.UPDATE_EXPENSE,
      EXPENSES_ACTIONS.UPDATE_EXPENSE_SUCCESS,
      EXPENSES_ACTIONS.UPDATE_EXPENSE_FAILURE
    ],
    promise: client =>
      client.put(`/expenses/${id}`, {
        data: payload,
        token
      })
  };
};

export const deleteExpense = id => {
  return {
    types: [
      EXPENSES_ACTIONS.DELETE_EXPENSE,
      EXPENSES_ACTIONS.DELETE_EXPENSE_SUCCESS,
      EXPENSES_ACTIONS.DELETE_EXPENSE_FAILURE
    ],
    promise: client => client.del(`/expenses/${id}`, { token }),
    id
  };
};

export function createExpenseReceipt({ expense_item_id }, receipt) {
  if (
    receipt.id &&
    typeof receipt.id === 'string' &&
    receipt.id.startsWith('temp-')
  )
    delete receipt.id;
  receipt = pick(receipt, [
    'archived',
    'document_data',
    'document_type',
    'file',
    'file_id',
    'id',
    'order',
    'status'
  ]);
  return {
    types: [
      EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT,
      EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT_SUCCESS,
      EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT_FAILURE
    ],
    promise: client =>
      client.post(`/expenses/${expense_item_id}/associations/receipts`, {
        data: receipt,
        token
      }),
    expense_item_id
  };
}

export function updateExpenseReceipt({ expense_item_id, receipt_id }, data) {
  data = omit(data, 'currency'); // TODO: Address problem in core route and remove currency from receipt
  return {
    types: [
      EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT,
      EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT_SUCCESS,
      EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT_FAILURE
    ],
    promise: client =>
      client.put(
        `/expenses/${expense_item_id}/associations/receipts/${receipt_id}`,
        {
          data,
          token
        }
      ),
    expense_item_id,
    receipt_id
  };
}

export function deleteExpenseReceipt({ expense_item_id, receipt_id }, data) {
  return {
    types: [
      EXPENSES_ACTIONS.DELETE_EXPENSE_RECEIPT,
      EXPENSES_ACTIONS.DELETE_EXPENSE_RECEIPT_SUCCESS,
      EXPENSES_ACTIONS.DELETE_EXPENSE_RECEIPT_FAILURE
    ],
    promise: client =>
      client.del(
        `/expenses/${expense_item_id}/associations/receipts/${receipt_id}`,
        {
          data,
          token
        }
      ),
    expense_item_id,
    receipt_id
  };
}
