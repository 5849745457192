// Libraries
import React, { useEffect } from 'react';
import classnames from 'classnames';

// Components
import { ListVirtualized } from 'v1/components/shared';

// Styles
import './MasonryVirtualized.scss';
import { useScrollParent } from '~/v4/pages/common/ScrollParent';

const BREAK_POINTS = [500, 860, 1200];

export default class MasonryVirtualized extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: 1
    };

    this.onResize = this.onResize.bind(this);
    this.setMasonry = element => {
      this.masonry = element;
    };
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  };

  getColumns(w) {
    return (
      BREAK_POINTS.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, BREAK_POINTS.length) + 1
    );
  }

  onResize() {
    const columns = this.getColumns(this.masonry.offsetWidth);
    if (columns !== this.state.columns) {
      this.setState({ columns: columns });
    }
  }

  renderRows() {
    const { children } = this.props;
    const { columns } = this.state;

    return Array(Math.ceil(children.length / columns))
      .fill(null)
      .map((v, i) => {
        const row = children.slice(i * columns, i * columns + columns);

        return (
          <div className="Masonry__row" key={i}>
            {row.map((item, key) => (
              <div
                className="Masonry__item"
                key={key}
                style={{
                  width: 100 / columns + '%'
                }}
              >
                {item}
              </div>
            ))}
          </div>
        );
      });
  }

  render() {
    return (
      <div
        className={classnames(['Masonry', this.props.className])}
        ref={this.setMasonry}
      >
        <ListVirtualized scrollElement={this.props.scrollElement}>
          {this.renderRows()}
        </ListVirtualized>
      </div>
    );
  }
}

// This component gets scroll parent from the context
// Reason: it's not a business logic or state things, and scroll parent pollutes the props
// It's more like a layout/theme thing that should be freely lifted across dom tree regardless of the business logic
// use <ScrollParent> to render a div element with scroll parent context
export function ContextualMasonryVirtualized(props) {
  const scrollParent = useScrollParent();
  const [scrollElement, setScrollElement] = React.useState(null);
  // we need to make sure that MasonryVirtualized is rendered with present scrollElement
  useEffect(() => {
    setScrollElement(scrollParent.current);
  }, []);
  return <MasonryVirtualized {...props} scrollElement={scrollElement} />;
}
