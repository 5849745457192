import React, { Component } from 'react';
import { EmptyGeneric } from 'v1/components/shared';
import { LinkUploader } from 'v1/components/shared';
import v4 from 'uuid';

import { cleanUrl } from 'v1/helpers/misc';
import './MediaFromUrl.scss';

export default class MediaFromUrl extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  createPin = (url, provider) => {
    const { onSelect } = this.props;
    let pin_type = 'external_file';

    switch (provider) {
      case 'YOUTUBE':
        pin_type = 'youtube/video';
        break;
      case 'VIMEO':
        pin_type = 'vimeo/video';
        break;
      case 'IMAGE':
        let cleanedUrl = cleanUrl(url);
        const ext = cleanedUrl.split('.').pop();
        pin_type = `image/${ext}`;
        break;
      default:
        break;
    }
    const pin = {
      url: url,
      id: `temp-${v4()}`,
      pin_type
    };
    onSelect(pin);
  };
  renderMedia() {
    const { provider, url } = this.state;

    if (!provider) {
      return (
        <EmptyGeneric
          title="Link Preview"
          description="Valid links will display the media here"
        />
      );
    }
    switch (provider) {
      case 'IMAGE':
        return <img src={url} alt="" />;
      case 'YOUTUBE':
        const youtube_id = url.split('v=').pop();
        return (
          <iframe
            title="YouTube"
            src={`https://www.youtube.com/embed/${youtube_id}`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        );
      case 'VIMEO':
        const vimeo_id = url.split('/').pop();
        return (
          <iframe
            title="Vimeo"
            src={`https://player.vimeo.com/video/${vimeo_id}`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        );
      default:
        return null;
    }
  }
  render() {
    return (
      <div className="MediaFromUrl">
        <h3>Add from media link (URL)</h3>
        <p>
          Paste links from <strong>YouTube</strong>, <strong>Vimeo</strong>, and{' '}
          <strong>Images</strong> you find across the web.
        </p>
        <LinkUploader
          uploading={this.props.sendingURL}
          placeholder="http://"
          onLinkUpload={({ provider, files }) => {
            this.setState({ provider: null, url: null }, () =>
              this.createPin(files[0], provider)
            );
          }}
          onChange={({ provider, files }) =>
            this.setState({ provider, url: files && files[0] })
          }
          submitText="Add to gallery"
          testLink
        />
        <div className="MediaFromUrl-media">{this.renderMedia()}</div>
      </div>
    );
  }
}
