import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clickOutside from 'v1/helpers/clickOutsideDecorator';
import classnames from 'classnames';
import './DropdownButton.scss';

import DEPRECATED_DropdownContent from './DropdownContent/DropdownContent';

class DEPRECATED_DropdownButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
    this.onClickOutside = this.onClickOutside.bind(this);
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (!this.props.closeDropdown && nextProps.closeDropdown) {
      this.setState({ show: false }, () => {
        this.props.resetDropdown && this.props.resetDropdown();
      });
    }
  };
  onClickOutside() {
    if (this.state.show) {
      return this.setState({ show: false });
    }
  }
  renderDropdownContent() {
    const { offset = { top: '38px' } } = this.props;
    return (
      <DEPRECATED_DropdownContent
        className={this.props.DropdownContainerClass}
        linkClassName={this.props.linkClassName}
        offset={offset}
        links={this.props.links}
      >
        {this.props.children}
      </DEPRECATED_DropdownContent>
    );
  }
  renderButton = () => {
    const { customButton } = this.props;
    if (customButton) {
      return customButton();
    }
    return (
      <div
        className={classnames([
          'DropdownButton-button',
          this.props.buttonClass
        ])}
        data-tip={this.props.dataTip}
        data-place={this.props.dataPlace}
      >
        <span className="inline">
          {this.props.icon ? (
            <span className="DropdownButton-button-icon">
              <img src={this.props.icon} alt="" />
            </span>
          ) : null}
          {this.props.label ? (
            <span className="DropdownButton-button-label">
              {this.props.label}
            </span>
          ) : null}
          {!this.props.hideArrow ? (
            <img
              src={
                this.props.dark
                  ? '/images/icon_arrow_down.svg'
                  : '/images/icon_arrow_down_white.svg'
              }
              width="10px"
              alt=""
            />
          ) : null}
        </span>
      </div>
    );
  };
  render() {
    return (
      <div
        className={classnames(['DropdownButton-wrapper', this.props.className])}
        onClick={e => {
          e.stopPropagation();
          this.setState({ show: !this.state.show });
        }}
      >
        {this.renderButton()}
        {this.state.show ? this.renderDropdownContent() : null}
      </div>
    );
  }
}

DEPRECATED_DropdownButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  links: PropTypes.array,
  offset: PropTypes.object,
  linkClassName: PropTypes.string,
  DropdownContainerClass: PropTypes.string,
  disableOnClickOutside: PropTypes.bool,
  label: PropTypes.any
};

export default clickOutside(DEPRECATED_DropdownButton);
