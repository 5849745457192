import React, { Component } from 'react';

import { TextAreaInput } from 'v1/components/shared';

import { get } from 'lodash';

import './BlockParagraph.scss';

export default class BlockParagraph extends Component {
  onChange = (field, value) => {
    const paragraph = value;
    const data = this.props.data || {};
    this.props.onChange({ ...data, paragraph }, this.props.index);
  };
  renderNoteForm = () => {
    const { first_name } = this.props.contact;

    return (
      <div className="BlockEditable">
        <TextAreaInput
          field="paragraph"
          placeholder={`Write a bit about ${first_name}`}
          value={get(this.props, 'data.paragraph')}
          className="BlockInput BlockParagraph-Input"
          rows={2}
          onBlur={this.onChange}
        />
      </div>
    );
  };
  render() {
    return <div className="BlockParagraph">{this.renderNoteForm()}</div>;
  }
}
