import {
  Menu,
  MenuContent,
  MenuItem,
  MenuItemGroup,
  MenuItemGroupLabel,
  MenuPositioner,
  MenuTrigger,
  Portal
} from '@ark-ui/react';
import { Icon } from 'shared';
import Avatar from 'v4/shared/components/Avatar/Avatar';

import { Account } from '__types__';
import { parseInitials } from 'v1/helpers/misc';
import SidebarButton from './SidebarButton';
import { useHistory } from 'react-router-dom';
import { withinSettings } from 'v4/core/appRoutes';

interface SidebarAccountDropdownProps {
  brandImage?: string;
  companyName: string;
  appId: string;
  account?: Account;
  onLogout?: () => void;
}

const SidebarAccountDropdown = ({
  brandImage,
  companyName,
  account,
  onLogout
}: SidebarAccountDropdownProps) => {
  const history = useHistory();
  const to = withinSettings();

  const resolveActions = ({ value }) => {
    switch (value) {
      case 'settings':
        return history.push(to.settings.home());
      case 'account-settings':
        return history.push(to.settings.account.details());
      case 'logout':
        return onLogout && onLogout();
      default:
        return;
    }
  }

  return (
    <Menu
      closeOnSelect
      positioning={{ gutter: 8, placement: 'right-end', sameWidth: true }}
      onSelect={resolveActions}
    >
      <MenuTrigger className="tw-rounded-md tw-w-full tw-flex tw-items-center tw-text-left tw-p-2 tw-block hover:tw-bg-eggplant-lighter/30 hover:tw-text-white/100 tw-block focus:tw-outline-none focus:tw-ring-2 tw-ring-eggplant-lightest/60">
        <span className="tw-flex-0 tw-mr-3">
          {brandImage ? (
            <Avatar
              src={brandImage}
              fallback={parseInitials(companyName)}
              size="sm"
            />
          ) : (
            <Icon name="star" />
          )}
        </span>
        <span className="tw-flex-1 tw-truncate">{companyName}</span>
      </MenuTrigger>
      <Portal>
        <MenuPositioner className="!tw-z-10 tw-shadow-xl">
          <MenuContent className="tw-bg-eggplant tw-border-1 tw-border-solid tw-border-eggplant-lighter/60 tw-text-white tw-p-4 tw-rounded-lg">
            <MenuItemGroup id="account-settings" className="tw-mb-2">
              <MenuItemGroupLabel
                htmlFor="accounts"
                className="tw-text-eggplant-lightest tw-text-base"
              >
                <div className="tw-truncate">{companyName}</div>
              </MenuItemGroupLabel>
              <MenuItem asChild id="settings">
                <SidebarButton
                  icon={<Icon name="v4_cog" />}
                >
                  Settings
                </SidebarButton>
              </MenuItem>
            </MenuItemGroup>
            {account && (
              <MenuItemGroup id="account-settings">
                <MenuItemGroupLabel
                  htmlFor="accounts"
                  className="tw-text-eggplant-lightest tw-text-base"
                >
                  Your account
                </MenuItemGroupLabel>
                <MenuItem asChild id="account-settings">
                  <SidebarButton
                    icon={
                      <Avatar
                        src={account.profileImage}
                        fallback={parseInitials(
                          account?.firstName,
                          account?.lastName,
                          account?.email
                        )}
                        size="sm"
                        className="tw-bg-white/40"
                      />
                    }
                  >
                    {account.displayName}
                  </SidebarButton>
                </MenuItem>
                <MenuItem asChild id="logout">
                  <SidebarButton icon={<Icon name="v4_logout" />} onClick={onLogout}>Log out</SidebarButton>
                </MenuItem>
              </MenuItemGroup>
            )}
          </MenuContent>
        </MenuPositioner>
      </Portal>
    </Menu>
  );
};

export default SidebarAccountDropdown;
