import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'v1/components/shared';
import { Dropdown, MenuItem } from 'v1/components/shared';

import classnames from 'classnames';
import './ButtonWithDropdown.scss';

export default class ButtonWithDropdown extends Component {
  renderDropdown = () => {
    const { actions = [], arrowShade } = this.props;
    actions.shift();
    return (
      <Dropdown
        buttonIcon={
          arrowShade === 'dark' ? (
            <img src="/images/icon_arrow_down_gray.svg" alt="" />
          ) : (
            <img src="/images/icon_arrow_down_white.svg" alt="" />
          )
        }
        buttonClass="ButtonWithDropdown-dropdown"
      >
        {actions.map((link, index) => (
          <MenuItem key={`${index}-${link.text}`} onSelect={link.action}>
            <span className="MenuItem-icon">
              {link.icon && <img src={link.icon} alt="" />}
            </span>
            <span className="MenuItem-label">{link.text}</span>
          </MenuItem>
        ))}
      </Dropdown>
    );
  };
  renderPrimaryButton = () => {
    const { loading, actions, disabled } = this.props;
    const primaryButton = actions[0];

    return (
      <Button
        onClick={primaryButton.action}
        className="ButtonWithDropdown-primaryButton"
        loading={loading}
        disabled={disabled}
        stopPropagation
      >
        {primaryButton.text}
      </Button>
    );
  };
  render() {
    const { actions, disabled, loading } = this.props;
    return (
      <div
        className={classnames([
          'ButtonWithDropdown',
          this.props.className,
          { disabled: disabled || loading }
        ])}
      >
        {this.renderPrimaryButton()}
        {actions.length > 1 && this.renderDropdown()}
      </div>
    );
  }
}

ButtonWithDropdown.propTypes = {
  className: PropTypes.string, // Uses button classes - 'btn btn-default' etc
  actions: PropTypes.array.isRequired,
  /* TODO: actions[0] is required, and is a primary button with shape:
			{
				action
				text
			}
		*/
  // TODO: rest is array of 'links' as passed to DropdownButton
  arrowShade: PropTypes.string, // 'dark', else defaults to white
  // TODO: the following (loading, disabled, mixpanel) are passed to Button, so duplicate fttb
  loading: PropTypes.any, // TODO: type - passed to button but not used in Button?
  disabled: PropTypes.bool,
  mixpanel: PropTypes.any // TODO: type
};
