import { createAsyncThunk } from '@reduxjs/toolkit';
import { TeamDto } from 'dtos/team.dto';
import { SuccessDto } from 'dtos/success.dto';
import { TEAMS_ACTIONS } from './teams.constants';
import ApiClient from 'lib/api/ApiClient';

const isV2 = true;
const token = true;
const client = new ApiClient() as any;

const getAccountTeams = createAsyncThunk(
  TEAMS_ACTIONS.getAccountTeams,
  async () => {
    const { data } = await client.get('/auth/account/teams', {
      isV2,
      token
    });
    return data as TeamDto[];
  }
);

const getTeam = createAsyncThunk(
  TEAMS_ACTIONS.getTeam,
  async (teamSchema: string) => {
    const { data } = await client.get(`/auth/teams/${teamSchema}`, {
      isV2,
      token
    });
    return data as TeamDto;
  }
);

const assignUserGroupToTeam = createAsyncThunk(
  TEAMS_ACTIONS.assignUserGroupToTeam,
  async (id: string) =>
    (await client.post(`/auth/teams/:app_id:/user-groups/${id}`, {
      isV2,
      token
    })) as SuccessDto
);

export { getAccountTeams, getTeam, assignUserGroupToTeam };
