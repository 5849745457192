const sanitisePassword = password => {
  // Ensure a string is always returned
  if (typeof password !== 'string') {
    return '';
  }
  return password;
};

export const hasUpperCase = password =>
  /[A-Z]/.test(sanitisePassword(password));

export const hasNumbers = password => /[0-9]/.test(sanitisePassword(password));

export const hasCharacterLength = (password, len) =>
  sanitisePassword(password).length >= len;
