import React, { useRef, useState } from 'react';

import { ResourceSelector } from 'v1/components/shared/byModel';
import { SingleCheckbox } from 'v1/components/shared/form';

import './ResourceFilter.scss';

const ResourceFilter = ({
  param,
  index,
  filter,
  filterChanged,
  removeFilter
}) => {
  const containerRef = useRef(null);
  const [showResourceSelector, setShowResourceSelector] = useState(
    !!filter?.eq
  );
  const [value, setValue] = useState({
    eq: filter?.eq
  });

  const handleRangeClicked = () => {
    if (showResourceSelector) {
      setShowResourceSelector(false);
      setValue();
      removeFilter();
    } else {
      setShowResourceSelector(true);
    }
  };

  return (
    <div className="ResourceFilter" ref={containerRef}>
      <div
        key={index}
        className="DropdownButton-option"
        onClick={handleRangeClicked}
      >
        <SingleCheckbox
          className="GenericSearch-filter-checkbox DropdownButton-option-icon"
          onChange={handleRangeClicked}
          checked={showResourceSelector}
        />
        <span className="DropdownButton-option-label">{param.label}</span>
      </div>
      {showResourceSelector && (
        <div className="ResourceFilter-content">
          <div className="form-label">Resource</div>
          <ResourceSelector
            initialValue={value}
            onSelect={contact => {
              filterChanged({
                ...param,
                value: contact.id
              });
            }}
            inputStyle={{
              size: 'S',
              placeholder: 'Search resources'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ResourceFilter;
