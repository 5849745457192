import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { openModal } from 'store/v1/ui/ui.actions.js';
import {
  PressStud,
  TextInput,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Grid,
  GridCell
} from 'v1/components/shared';

import classnames from 'classnames';
import './ResourceGroupHeaderEditor.scss';

export class ResourceGroupHeaderEditor extends Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: false };
  }
  onChange = (field, value) => {
    const { onChange, group } = this.props;
    onChange && onChange(group.id, field, value);
  };
  onToggleCollapse = () => {
    this.setState(prev => ({ collapsed: !prev.collapsed }));
  };

  onDeleteGroup = groupId => {
    if (this.props.production) {
      // TODO: this is duplicated from ResourcingTable
      const canDelete =
        !get(this.props.production, 'resource_slots', []).some(
          slot => slot.group_id === groupId && !slot.archived
        ) &&
        !get(this.props.production, 'budget_expenses', []).some(
          slot => slot.group_id === groupId && !slot.archived
        );
      canDelete
        ? this.props.onDelete(groupId)
        : this.props.openModal('AlertModal', {
            title: 'This group is not empty',
            description:
              'You cannot delete a group if there are roles and expenses within it. Either move these into another group or delete them to continue.'
          });
    } else {
      this.props.onDelete(groupId);
    }
  };
  renderTitle = () => {
    const { group } = this.props;
    return (
      <TextInput
        appearance="silent"
        size="L"
        value={group.name}
        name={`${group.id}-group-name`}
        placeholder="Group name"
        onChange={e => {
          this.onChange('name', e.target.value);
        }}
        initialFocus
        removeAutocomplete
      />
    );
  };
  render() {
    const {
      group,
      children,
      onDelete,
      onChange,
      className,
      isLocked
    } = this.props;

    return (
      <Grid
        className={classnames({ draggable: !isLocked }, [
          'ResourceGroupHeaderEditor',
          className
        ])}
        key={group.id}
        data-id={group.id}
        direction="column"
      >
        {!onChange ? (
          <Grid className="ResourceGroupHeaderEditor-header">
            <h3>{group.name}</h3>
          </Grid>
        ) : (
          <Grid className="ResourceGroupHeaderEditor-header Parent_hoverListener">
            <GridCell width="auto" padding="XS" className="dragHandle">
              <span className="DragIndicator">
                <img src="/images/icon_drag.svg" alt="" />
              </span>
            </GridCell>
            <GridCell width="5/12" padding="XS">
              {this.renderTitle()}
            </GridCell>
            <GridCell></GridCell>
            <GridCell padding="XS" width="auto" className="Child_hoverListener">
              <Popover>
                <PopoverTrigger>
                  <PressStud icon="delete" appearance="silent" size="S" />
                </PopoverTrigger>
                <PopoverContent className="inset-M text-center">
                  <h5 className="text-13-700">Are you sure?</h5>
                  <p>This will remove all slots associated with this group</p>
                  <PressStud
                    label="Delete"
                    appearance="danger"
                    size="S"
                    action={() => onDelete && this.onDeleteGroup(group.id)}
                  />
                </PopoverContent>
              </Popover>
            </GridCell>
          </Grid>
        )}
        {!this.state.collapsed && children}
      </Grid>
    );
  }
}
ResourceGroupHeaderEditor.propTypes = {
  children: PropTypes.any,
  production: PropTypes.object,
  group: PropTypes.object,
  resources: PropTypes.array
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal }, dispatch);
}

export default connect(null, mapDispatchToProps)(ResourceGroupHeaderEditor);
