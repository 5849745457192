import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { isUndefined, isNull } from 'lodash';
import classnames from 'classnames';
import './SingleDateTimeInput.scss';

export class SingleDateTimeInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }
  componentDidMount = () => {
    this.setState({ loaded: true }); // Required for server side rendering as DatePicker needs .window
  };
  onChange = value => {
    const { timezone, onTimestampUpdate, outputFormat } = this.props;
    if (!onTimestampUpdate || typeof onTimestampUpdate !== 'function') return;
    //if (!value) return onTimestampUpdate(null);
    if (isUndefined(value) || isNull(value)) {
      onTimestampUpdate(value);
    } else {
      const updatedTimestamp = timezone
        ? moment.tz(value, timezone || moment().tz())
        : value;

      onTimestampUpdate(updatedTimestamp.format(outputFormat));
    }
  };

  onFocus = () => this.setState({ focused: true }, this.props.onFocus);

  onBlur = () => this.setState({ focused: false }, this.props.onBlur);

  render() {
    const {
      id,
      label,
      timestamp,
      timezone,
      inputDateFormat,
      timeIntervals,
      isClearable,
      placeholder,
      className,
      showOptional,
      error,
      updateOnBlur,
      ...restProps
    } = this.props;

    const timestampDate = moment(timestamp);

    return this.state.loaded && timestamp ? (
      <div className={classnames(['SingleDateTimeInput'], { isClearable })}>
        {this.props.label ? (
          <label htmlFor={id} className={classNames(['form-label'])}>
            {label}
            {showOptional ? <i>optional</i> : null}
          </label>
        ) : null}
        <div className="field-dropdown">
          <Datetime
            value={timestampDate}
            input
            onChange={value => this.onChange(value)}
            timeConstraints={{
              minutes: {
                step: 15
              }
            }}
            inputProps={{
              className
            }}
            onBlur={value => updateOnBlur && this.onChange(value)}
            {...restProps}
          />
        </div>
        {error ? <span className="formErrorLabel">{error}</span> : null}
        {isClearable && (
          <button
            type="button"
            className="btn-reset SingleDateTimeInput-clear SingleDateTimeInput-clear__remove"
            onClick={() => this.onChange(null)}
            data-tip="Remove time"
          >
            <img src="/images/icon_cross_black.svg" width="8px" alt="" />
          </button>
        )}
      </div>
    ) : (
      <button
        type="button"
        className="btn-reset SingleDateTimeInput-clear SingleDateTimeInput-clear__add genericLink"
        onClick={() => this.onChange(timestampDate)}
      >
        Add time
      </button>
    );
  }
}

// NOTE: wrapper for react-datepicker
SingleDateTimeInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.any, // TODO: type
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  showOptional: PropTypes.bool,
  error: PropTypes.bool,
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputDateFormat: PropTypes.string,
  timestamp: PropTypes.string,
  timezone: PropTypes.string, // E.g. 'Europe/London'
  timeIntervals: PropTypes.number,
  onTimestampUpdate: PropTypes.func, // TODO: arguments
  onFocus: PropTypes.func, // TODO: arguments
  onBlur: PropTypes.func // TODO: arguments
};

SingleDateTimeInput.defaultProps = {
  outputFormat: 'YYYY-MM-DDTHH:mm:ss.000Z',
  dateFormat: 'YYYY-MM-DD',
  timeIntervals: 15
};

export default SingleDateTimeInput;
