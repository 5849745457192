import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Env from 'lib/env/Env';

const RedirectAuth0 = ({ label, href, organisation, className }) => {
  return (
    <div className={classNames('row LoginFormGroup', className)}>
      <div className="col-sm-12">
        <a href={href} className="btn btn-default LoginButton">
          {label} {organisation}
        </a>
      </div>
    </div>
  );
};

RedirectAuth0.defaultProps = {
  label: 'Log in with',
  organisation: 'Auth0',
  href: `${Env.apiURL}/auth/auth0`
};

RedirectAuth0.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  organisation: PropTypes.string,
  className: PropTypes.string
};

export default RedirectAuth0;
