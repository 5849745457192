import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const STATUSES_ACTIONS = mapValues(
  mirror({
    GET_STATUSES: null,
    GET_STATUSES_SUCCESS: null,
    GET_STATUSES_FAILURE: null,

    CREATE_STATUS: null,
    CREATE_STATUS_SUCCESS: null,
    CREATE_STATUS_FAILURE: null,

    DELETE_STATUS: null,
    DELETE_STATUS_SUCCESS: null,
    DELETE_STATUS_FAILURE: null,

    UPDATE_STATUS: null,
    UPDATE_STATUS_SUCCESS: null,
    UPDATE_STATUS_FAILURE: null,

    UPDATE_STATUSES_ORDER: null,
    UPDATE_STATUSES_ORDER_SUCCESS: null,
    UPDATE_STATUSES_ORDER_FAILURE: null
  }),
  value => value
);
