import React, { useContext } from 'react';

import { Icon } from 'shared';
import ModalContext from 'modals/ModalContext';

import styles from './ModalClose.module.scss';

const ModalClose = () => {
  const { cancelModal } = useContext(ModalContext);

  return (
    <div
      className={styles.ModalClose}
      onClick={cancelModal}
      data-id="modal-close-button"
    >
      <Icon name="cross" size="l" />
    </div>
  );
};

export default ModalClose;
