import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Label.scss';

type LabelProps = {
  className?: string;
  htmlFor?: string;
  appearance?: 'subtle';
  hidden?: boolean;
  children: React.ReactNode;
  size: 'M' | 'L';
};

const Label = ({
  className,
  htmlFor,
  appearance,
  hidden,
  children,
  size,
  ...props
}: LabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classnames(
        'Label',
        {
          [`Label_${appearance}`]: appearance,
          'visually-hidden': hidden,
          [`Label_size-${size}`]: size
        },
        className
      )}
      {...props}
    >
      {children}
    </label>
  );
};

Label.propTypes = {
  // Component props
  className: PropTypes.string,
  hidden: PropTypes.bool, // Hide visually but retain semantics in conjunction with form input
  appearance: PropTypes.oneOf(['subtle']), // Any others?
  children: PropTypes.node, // Can take a string, func, element etc.
  size: PropTypes.oneOf(['M', 'L']),
  // HTML attributes
  htmlFor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // Used to reference id of respective form input (should be .isRequired)
  // ...props
  // All valid attributes for <label />
  // Keeping in mind React-specific attribute case:
  // https://react-cn.github.io/react/docs/tags-and-attributes.html
};

Label.defaultProps = {
  size: 'M'
};

export default Label;
