import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './GenericUnavailable.scss';

class GenericUnavailable extends Component {
  render() {
    const { title, subtitle = null, children } = this.props;
    return (
      <div className="GenericUnavailable">
        <div className="GenericUnavailable-content">
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
          {children}
        </div>
      </div>
    );
  }
}

GenericUnavailable.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.any
};

export default GenericUnavailable;
