import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkPrimaryProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  to: string;
}

const SidebarLinkPrimary = ({ children, to, icon }: SidebarLinkPrimaryProps) => {
  return (
    <NavLink to={to} className={(isActive) => classNames("tw-rounded-md tw-w-full tw-text-white/80 tw-flex tw-items-center tw-text-left hover:tw-text-white/100 tw-p-2 tw-block tw-rounded-md hover:tw-bg-eggplant-lighter/30 tw-block focus:tw-outline-none focus:tw-text-white/100 focus:tw-ring-2 focus:tw-ring-eggplant-lightest/60", { "tw-bg-eggplant-lighter/60 tw-text-white/100": isActive })}>
      <span className="tw-flex-0 tw-mr-3">{icon}</span>
      <span className="tw-flex-1 tw-truncate">{children}</span>
    </NavLink>
  );
};

export default SidebarLinkPrimary;
