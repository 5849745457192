import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import ValidationErrorLevel from 'lib/errors/ValidationErrorLevel';
import { EventDateRange } from 'v1/components/shared';
import {
  PressStud,
  Grid,
  GridCell,
  ListCell,
  Label
} from 'v1/components/shared';
import { getProduction } from 'store/v1/productions/productions.actions.js';
import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';

import {
  getEventDates,
  getFormattedEventDates
} from 'v1/helpers/byModel/EventHelper';
import { getAssignmentError } from 'v1/helpers/bookingHelper';
import { SystemLink } from 'v1/components/shared';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import './BookingAssignmentItem.scss';

const moment = extendMoment(Moment);

const BookingAssignmentItem = ({
  assignment,
  onDateRangeChange,
  bookingEvents = [],
  disabled,
  onRemoveAssignment
}) => {
  const productions = useSelector(state => state.productions);
  const getSlotName = useSelector(makeGetSlotName);
  const dispatch = useDispatch();

  const production_id =
    assignment.resource_slot && assignment.resource_slot.production_id;

  const hasSameDatesAsBooking = useMemo(() => {
    return _get(assignment, 'events', []).every(e => {
      const [start, end] = getEventDates(e);
      return bookingEvents.find(ev => {
        const [bookingStart, bookingEnd] = getEventDates(ev);
        return (
          moment(bookingStart).isSame(start, 'day') &&
          moment(bookingEnd).isSame(end, 'day')
        );
      });
    });
  }, [bookingEvents, assignment]);

  const [showSpecificDates, setShowSpecificDates] = useState(
    !hasSameDatesAsBooking
  );

  useEffect(() => {
    // Need to get the associated production if not already fetched
    if (production_id && !productions.data[production_id])
      dispatch(getProduction(production_id));
  }, []);

  // If assignment is new, we have not access to production_id
  // TODO: Break out into it's own selector to be reused
  const production = production_id
    ? productions.data[production_id]
    : _keys(productions.data).reduce((result, production_id) => {
        const slot =
          productions.data[production_id] &&
          productions.data[production_id].resource_slots.find(
            r => r.id === assignment.resource_slot_id
          );
        return slot ? productions.data[production_id] : result;
      }, {});
  const resource_slot =
    production &&
    production.resource_slots &&
    production.resource_slots.find(r => r.id === assignment.resource_slot_id);

  const errorMessage = useMemo(
    () =>
      getAssignmentError(
        assignment,
        { events: bookingEvents },
        productions.data,
        production_id || _get(production, 'id')
      ),
    [assignment, bookingEvents, production_id, production, productions.data]
  );

  const renderValidationResult = () => {
    if (!errorMessage) {
      return null;
    }

    const errLevel = errorMessage.level;
    const className = classNames('BookingAssignmentItem-validation', {
      'validation-info': errLevel === ValidationErrorLevel.INFO,
      'validation-warning': errLevel === ValidationErrorLevel.WARN,
      'validation-critical': errLevel === ValidationErrorLevel.CRITICAL
    });

    let iconURL;
    switch (errLevel) {
      case ValidationErrorLevel.INFO:
        iconURL = '/images/icon-warning-bubblegum.svg';
        break;
      case ValidationErrorLevel.WARN:
        iconURL = '/images/icon-warning-sun.svg';
        break;
      case ValidationErrorLevel.CRITICAL:
        iconURL = '/images/icon-warning-rose.svg';
        break;
      default:
        break;
    }

    return (
      <ListCell padding={0}>
        <div className={className}>
          <img src={iconURL} className="validation-icon" alt="" />
          <span>{errorMessage.message}</span>
        </div>
      </ListCell>
    );
  };

  return (
    <>
      <ListCell padding={0} className="BookingAssignmentItem">
        <div className="BookingAssignmentItem-header">
          <div
            className="BookingAssignmentItem-color"
            style={{ backgroundColor: _get(production, 'color') || '#d6e0e5' }}
          />
          <div className="BookingAssignmentItem-production">
            <div className="BookingAssignmentItem-description">
              <span className="text-13-700">{getSlotName(resource_slot)}</span>{' '}
              for{' '}
              <SystemLink
                to={`/productions/${_get(production, 'id')}`}
                className="genericLink text-13-700"
              >
                {_get(production, 'code')} {_get(production, 'title')}
              </SystemLink>
            </div>
            <div className="BookingAssignmentItem-dates text-13-600-eggplant">
              {getFormattedEventDates(_get(production, 'production_date'))}
            </div>
          </div>
          <div className="BookingAssignmentItem-delete">
            <PressStud
              tooltip="Unassign from booking"
              icon="delete"
              appearance="silent"
              action={onRemoveAssignment}
            />
          </div>
        </div>
        <div>
          <Grid>
            <GridCell width="2/12" className="inset-S">
              <Label size="M">Assignment Dates</Label>
            </GridCell>
            <GridCell className="BookingAssignmentItem-input">
              {showSpecificDates ? (
                <EventDateRange
                  size="S"
                  data={_get(assignment, 'events', []).sort((a, b) => {
                    if (!a.id || typeof a.id === 'string') return 1;
                    if (!b.id || typeof a.id === 'string') return -1;
                    return a.id >= b.id ? 1 : -1;
                  })}
                  allowEmpty={false}
                  onChange={onDateRangeChange}
                  eventType="RESOURCE_SLOT_ASSIGNMENT"
                  labelStart="From"
                  labelEnd="Till"
                  isReadOnly={disabled}
                  // limits={{
                  //   minDate: moment(assignmentMinDate).toDate(),
                  //   maxDate: moment(assignmentMaxDate).toDate()
                  // }}
                  // filterDate={date => {
                  //   if (!production) return true;
                  //   return [production.production_date, ...bookingEvents].every(e => {
                  //     const [start, end] = getEventDates(e);
                  //     return moment.range(start, end).contains(moment(date));
                  //   });
                  // }}
                />
              ) : (
                <Grid gutters="M" vcenter>
                  <GridCell className="text-neutral">
                    Assigned to entire booking
                    <br />
                    <PressStud
                      label={
                        showSpecificDates
                          ? 'Use booking dates'
                          : 'Set specific time'
                      }
                      size="S"
                      action={() => setShowSpecificDates(prev => !prev)}
                    />
                  </GridCell>
                </Grid>
              )}
            </GridCell>
          </Grid>
        </div>
      </ListCell>
      {renderValidationResult()}
    </>
  );
};

BookingAssignmentItem.propTypes = {
  assignment: PropTypes.object.isRequired,
  onDateRangeChange: PropTypes.func
};

export default BookingAssignmentItem;
