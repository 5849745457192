import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContactSuggesterItem from './ContactSuggesterItem/ContactSuggesterItem';
import MagicBlockSectionHeader from 'v1/components/feature/ResourceComponents/Resource/Pins/MagicBlocks/Layout/MagicBlockSectionHeader/MagicBlockSectionHeader';
import { EmptyGeneric } from 'v1/components/shared';
import Pinnable from 'v1/components/feature/ResourceComponents/Resource/Pins/Pinnable/Pinnable';
import { Loading } from 'v1/components/shared';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import { htmlStringParser } from 'v1/helpers/misc';
import {
  updateContact,
  getSocialData
} from 'store/v1/contacts/contacts.actions.js';

import Masonry from 'react-masonry-component';
import { isEmpty, set, omit } from 'lodash';

import './WebsiteSuggester.scss';

const masonryOptions = {
  transitionDuration: 200
};

const MEDIA_LIMIT = 30;

export class WebsiteSuggester extends Component {
  constructor(props) {
    super(props);

    this.contact_id = parseInt(props.match.params.id);

    this.state = {
      websites: this.getContactWebsites(),
      submit: {}
    };
  }

  componentDidMount() {
    this.getContactWebsites().forEach(website => {
      if (!this.contact_id || !website.value_1) {
        // No-op
        return;
      }
      return this.props.getSocialData(
        this.contact_id,
        website.value_1,
        'website'
      );
    });
  }

  componentDidUpdate() {
    // Check if contacts socials changes, if so reload data;
    let websites = this.getContactWebsites();
    websites.forEach(website => {
      if (!this.contact_id || !website.value_1) {
        // no-op
        return;
      }
      const block = this.props.contacts.socials_data[
        `website.${website.value_1}`
      ];
      if (!block) {
        this.props.getSocialData(this.contact_id, website.value_1, 'website');
      }
    });
  }

  getContactWebsites = props =>
    this.props.contacts.data[this.contact_id].websites || [];

  onUpdate = (field, value, method) => {
    // prettier-ignore
    this.setState(prev => {
      const name = Array.isArray(field) ? field[0] : field;
      const arr = prev.submit[name] || [];
      switch (method) {
        case undefined:
        case null:
          return set(prev, `submit.${name}`, value);
        case 'CREATE': {
          const v = { ...value, _method: 'create' };
          return set(prev, `submit.${name}`, [...arr, v]);
        }
        case 'UPDATE': {
          if (value.id.toString().includes('temp')) {
            const v = { ...value, _method: 'create' };
            if (isEmpty(arr)) return set(prev, `submit.${name}`, [...arr, v]);
            return set(prev, `submit.${name}`, arr.map(i => (i.id === v.id ? v : i)));
          }
          const v = { ...value, _method: 'update', _find: { id: value.id } };
          return arr.find(i => i.id === value.id)
            ? set(prev, `submit.${name}`, arr.map(i => (i.id === value.id ? v : i)))
            : set(prev, `submit.${name}`, [...arr, v]);
        }
        case 'DELETE': {
          if (value.id && value.id.toString().includes('temp')) {
            return set(prev, `submit.${name}`, arr.filter(i => i.id !== value.id));
          }
          return omit(prev, `submit.${name}`)
        }
        default:
      }
    });
    // prettier-ignore-end
  };
  renderSuggestionItem = (field, data, index) => {
    return (
      <ContactSuggesterItem
        key={field + index}
        onSuggestUpdate={this.onUpdate}
        field={field}
        value={data}
        contact={this.props.contact}
      />
    );
  };
  renderSocials = data => {
    const dataList =
      data &&
      data.map((data, index) => {
        return this.renderSuggestionItem('socials', data, index);
      });

    return (
      <div className="WebsiteSuggester-suggestion-group">
        <div className="WebsiteSuggester-suggestion-groupHeader inline capitalise">
          <img src={`/images/icon_contact_socials.svg`} alt="" /> Socials found
        </div>
        {dataList}
      </div>
    );
  };
  renderEmails = data => {
    const dataList =
      data &&
      data.map((data, index) => {
        return this.renderSuggestionItem('emails', data, index);
      });

    return (
      <div className="WebsiteSuggester-suggestion-group">
        <div className="WebsiteSuggester-suggestion-groupHeader inline capitalise">
          <img src={`/images/icon_contact_email.svg`} alt="" /> Emails found
        </div>
        {dataList}
      </div>
    );
  };
  renderMediaItem = (media, index) => {
    let extension = media.split('.').pop();
    extension = extension.toLowerCase();
    if (extension.indexOf('?') > -1) extension = extension.split('?')[0];
    let item;
    switch (extension) {
      case 'svg':
        item = (
          <img
            key={index}
            src={media}
            style={{ width: 15, height: 15 }}
            alt=""
          />
        );
        break;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        item = <img key={index} src={media} width="100%" alt="" />;
        break;
      default:
        item = <img key={index} src={media} width="100%" alt="" />;
    }
    if (item) {
      return (
        <Pinnable
          key={index}
          className="WebsiteSuggester-media-item"
          contactID={this.contact_id}
          toPin={media}
        >
          {item}
        </Pinnable>
      );
    }
  };
  renderMedias = data => {
    const mediaItems =
      data &&
      data.map((media, index) => {
        if (index > MEDIA_LIMIT) return null;
        return this.renderMediaItem(media, index);
      });

    if (!isEmpty(data)) {
      return (
        <div className="WebsiteSuggester-media">
          <Masonry
            className={'WebsiteSuggester-gallery'} // default ''
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad
          >
            {mediaItems}
          </Masonry>
        </div>
      );
    }
  };
  renderDataType = website => {
    const block = this.props.contacts.socials_data[
      `website.${website.value_1}`
    ];

    if (!block) return null;
    if (block.loading) return <Loading key={website.value_1} />;

    if (!isEmpty(block.medias)) {
      return (
        <div className="WebsiteSuggester-types">
          {!isEmpty(block.medias) ? this.renderMedias(block.medias) : null}
        </div>
      );
    }
    return (
      <EmptyGeneric
        icon="/images/icon_colour_error_playful.svg"
        iconWidth="30px"
        title="Cannot find content on this website"
        description="All websites are built differently and so we sometimes have trouble finding media within them. If this website has media please send us a message with the website link so we can improve this feature."
      />
    );
  };

  renderResult = (website, index) => {
    const contact = this.props.contacts.data[this.contact_id];
    return (
      <div key={website.value_1} className="WebsiteSuggester-block">
        <MagicBlockSectionHeader
          provider="Website"
          icon="/images/icon_website.svg"
          name={
            contact.first_name ? `${contact.first_name}'s Website` : 'Website'
          }
          url={htmlStringParser(website.value_1)}
        />
        {this.renderDataType(website)}
      </div>
    );
  };

  render() {
    return (
      <div className="WebsiteSuggester">
        {this.getContactWebsites().length ? (
          this.getContactWebsites().map(this.renderResult)
        ) : (
          <EmptyGeneric
            icon="/images/loading_rings.svg"
            iconWidth="50px"
            title="No websites found"
            description="We find relevant data in a contacts website so you can quickly add examples of work and contact details into their profiles. Note because every website is different this may take some time."
          />
        )}
      </div>
    );
  }
}

function mapStateToProps({ contacts, events }) {
  return { contacts, events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { updateContact, displayAlert, getSocialData, openModal },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteSuggester);
