import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CalendarRoute from './CalendarRoute/CalendarRoute';
import BookingsRoute from './BookingsRoute/BookingsRoute';
import ContactDetailsRoute from './ContactDetailsRoute/ContactDetailsRoute';
import styles from './ResourcePortalRouter.module.scss';

const routeComponents = {
  calendar: CalendarRoute,
  bookings: BookingsRoute,
  details: ContactDetailsRoute
};

const ResourcePortalRouter = ({ routesConfig, defaultRoute }) => (
  <div className={styles['ResourcePortalRouter']}>
    <Switch>
      {routesConfig.map((routeConfig, index) => {
        const Component = routeComponents[routeConfig.route];
        return (
          <Route
            exact
            key={`${routeConfig.route}-${index}`}
            path={routeConfig.to}
            render={props => <Component {...props} />}
          />
        );
      })}
    </Switch>
    <Redirect to={`${defaultRoute.to}`} />
  </div>
);

export default ResourcePortalRouter;
