import { RESOURCE_TYPES_ACTIONS } from 'store/v1/resource_types/resource_types.constants.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';
import _orderBy from 'lodash/orderBy';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

const getOrder = types =>
  _orderBy(types, ['order', 'id'], ['asc', 'asc']).map(item => item.id);

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESOURCE_TYPES_ACTIONS.GET_RESOURCE_TYPES:
    case RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE:
    case RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE:
    case RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_ORDER:
    case RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AUTH_ACTIONS.LOAD_AUTH_SUCCESS: {
      const { resource_types = [] } = action.result;
      return {
        ...state,
        data: resource_types.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: getOrder(resource_types),
        loading: false,
        fetched: true
      };
    }
    case RESOURCE_TYPES_ACTIONS.GET_RESOURCE_TYPES_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: getOrder(action.result.results),
        paging: action.result.paging,
        loading: false,
        fetched: true
      };
    case RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_ORDER_SUCCESS:
      return {
        ...state,
        data: action.result.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: getOrder(action.result),
        loading: false
      };
    case RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        loading: false
      };
    case RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            archived: true
          }
        },
        order: state.order.filter(id => id !== action.id),
        loading: false
      };
    case RESOURCE_TYPES_ACTIONS.GET_RESOURCE_TYPES_FAILURE:
    case RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_FAILURE:
    case RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE_FAILURE:
    case RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE_FAILURE:
    case RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
