import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FileItem } from 'v1/components/shared';
//import { GenericFileUploader } from 'v1/components/shared';

import './ContractFileItem.scss';

class ContractFileItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contract: props.value
    };
  }
  onFileRemove = () => {
    const { onRemove, field } = this.props;

    return onRemove ? onRemove(field) : false;
  };
  render() {
    const { contract } = this.state;

    return (
      <div className="ContractFileItem">
        <FileItem file={contract.file} onDelete={this.onFileRemove} />
      </div>
    );
  }
}

ContractFileItem.propTypes = {
  field: PropTypes.string,
  value: PropTypes.shape({
    file: PropTypes.any
  }), // TODO: type
  onRemove: PropTypes.func // TODO: arguments
};

export default ContractFileItem;
