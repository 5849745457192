import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { decodeJwt } from 'jose';

import AuthService from 'lib/auth/AuthService';
import {
  registerAccount,
  deleteToken,
  selectRegisterAccountLoading,
  selectRegisterAccountToken
} from 'store/onboarding';
import { isTokenAlive, decodeAndReadToken } from 'lib/auth/tokenOperations';
import { ONBOARDING } from '../UserOnboarding';

import OnboardingViewBase from '../OnboardingViewBase';
import RegisterUserPanel from './RegisterUserPanel';
import { Grid, GridCell, Heading, Text } from 'shared';

const CreateAccount = () => {
  const [safeToRedirect, setSafeToRedirect] = useState(false);
  //redux data
  const isLoading = useSelector(selectRegisterAccountLoading);
  const upgradeToken = useSelector(selectRegisterAccountToken);

  useEffect(() => {
    if (upgradeToken.length > 0) {
      async function buildToken() {
        const token = decodeJwt(upgradeToken);
        token.tokenString = upgradeToken;
        token.workspaces = await AuthService.fetchAccountWorkspaces(
          token.tokenString
        );
        AuthService.token(token);
      }
      buildToken().then(_ => {
        dispatch(deleteToken());
        setSafeToRedirect(safeToRedirect => !safeToRedirect);
      });
    }
  }, [upgradeToken]);

  const dispatch = useDispatch();
  const cookies = new Cookies();
  const queryToken = cookies.get(ONBOARDING);
  const isAlive = isTokenAlive(queryToken);

  if (!isAlive) return <Redirect to="/login" />;

  const { token, bearerToken } = decodeAndReadToken(queryToken);

  const teamSchema = token.teamSchema;
  const invitedEmail = token.invited.invitedEmail;
  const invitedBy = token.invited.invitedByFirstName;

  if (safeToRedirect) {
    return (
      <Redirect
        to={{
          pathname: `/app/${teamSchema}/onboarding/create-profile`,
          state: { teamSchema }
        }}
      />
    );
  }

  const onSubmit = formData => {
    const data = { ...formData, authMethod: 'local' };
    dispatch(registerAccount({ data, teamSchema, token: bearerToken }));
  };

  return (
    <OnboardingViewBase primary="yellow" secondary="blue">
      <Grid align="center">
        <GridCell>
          <div className="stack-xl" />
          <Heading type="h1" family="serif" stack="m">
            Hello there
          </Heading>
          <Text color="black" stack="xl">
            {invitedBy} at {teamSchema} has invited you to join this workspace
          </Text>
          <RegisterUserPanel
            onSubmit={onSubmit}
            invitedEmail={invitedEmail}
            isLoading={isLoading}
          />
        </GridCell>
      </Grid>
    </OnboardingViewBase>
  );
};

export default CreateAccount;
