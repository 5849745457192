import { Resource } from '__types__/index';
import { produce } from 'immer';
import { set } from 'lodash';
import { ResourceEffect } from 'v4/entities/resource/resource.effect.types';

export const resourceEffectReducer = (
  state: Resource,
  action: ResourceEffect
): Resource => {
  if (action.type === 'update') {
    return {
      ...state,
      ...action.payload
    };
  }
  if (action.type === 'deepUpdate') {
    return produce(state, draft => {
      action.payload.forEach(({ fieldPath, fieldValue }) => {
        set(draft, fieldPath, fieldValue);
      });
    });
  }
  return state;
};
