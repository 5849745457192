import {
  AssociationQuery,
  AssociationState,
  mutationSchemas
} from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';

export const createCommentEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi
) =>
  defineEntityEffect({
    type: 'comment',
    requestSchema: mutationSchemas.comment.request,
    responseSchema: mutationSchemas.comment.response,
    useQueryFn: () => useApi().comment
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        // todo add optimistic voting, should insert action with proper user/guest
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response) => {
      return {
        state: {
          ...state,
          results: state.results.map(item =>
            item.id === request.association_id ? { ...item, ...response } : item
          )
        },
        shouldInvalidate: false
      };
    })
    .build();
