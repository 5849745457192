import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GenericSearch } from 'v1/components/shared';
import { EmptyGeneric } from 'v1/components/shared';
import { Loading } from 'v1/components/shared';
import { ListCellGroup, Grid, GridCell } from 'v1/components/shared';

import { Capability } from '__types__';
import { quickSearchProductions } from 'store/v1/productions/productions.actions.js';
import { openModal } from 'store/v1/ui/ui.actions.js';

import { orderReducer } from 'v1/helpers/misc';
import { chain, isEmpty } from 'lodash';
import v4 from 'uuid';

import './CoreSearchPanel.scss';

const STORE_METHODS = {
  productions: {
    loading: 'QUICK_SEARCH_PRODUCTIONS'
  }
};

export class CoreSearchPanel extends Component {
  onSearchChange = query => {
    const { selectedStore } = this.props;
    this.setState({ event: v4() }, () => {
      switch (selectedStore) {
        case 'productions':
          return this.props.quickSearchProductions(query);
        default:
          break;
      }
    });
  };
  renderHeader = () => {
    const { selectedStore } = this.props;
    return (
      <Grid vcenter spread className="stack-S">
        <GridCell className="text-13-600">
          Search {selectedStore || ''}
        </GridCell>
        <GridCell width="auto">
          <GenericSearch
            searchStore={selectedStore}
            onChange={this.onSearchChange}
            placeholder="Search..."
            // paging={paging}
            searchOnLoad
          />
        </GridCell>
      </Grid>
    );
  };
  renderResults = () => {
    const { selectedStore, cellComponent } = this.props;
    const method = STORE_METHODS[selectedStore];
    const store = this.props[selectedStore];
    const data = orderReducer(store, 'quick_search_results');

    if (store.loading === method.loading) return <Loading />;

    if (!isEmpty(data)) {
      const data__list = chain(data)
        .filter(d => d && !d.archived)
        .orderBy(['order', 'asc'])
        .value();

      return (
        <ListCellGroup className="CoreSearchPanel-results">
          {data__list.map(item => cellComponent(item))}
        </ListCellGroup>
      );
    }
    return (
      <EmptyGeneric
        icon="/images/icon_colour_productions.svg"
        title="No Productions Found"
        action={{
          capability: Capability.PRODUCTION_CREATE,
          text: 'Create Production',
          className: 'btn btn-primary',
          onClick: () => {
            this.props.openModal('ProductionSetUpModal', { create: true });
          }
        }}
      />
    );
  };
  render() {
    return (
      <Grid direction="column" wrap className="CoreSearchPanel">
        {this.renderHeader()}
        {this.renderResults()}
      </Grid>
    );
  }
}

CoreSearchPanel.propTypes = {
  onSelect: PropTypes.object,
  layout: PropTypes.object,
  options: PropTypes.object,
  store: PropTypes.string,
  title: PropTypes.string
};

function mapStateToProps({ callsheets, productions, events, ui }) {
  return { callsheets, productions, events, ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      quickSearchProductions,
      openModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreSearchPanel);
