const RESOURCE_CONFIG = {
  INDIVIDUAL: {
    defaultFields: [
      'full_name',
      'role',
      'locations',
      'rates',
      'tags',
      'emails',
      'websites',
      'socials',
      'phone_numbers',
      'groups'
    ],
    resourcePageLayout: {
      displayTabs: [
        'pinboard',
        'calendar',
        'bookings',
        'notes',
        'documents',
        'feeds'
      ],
      showSingleNameField: false,
      showRepresentedBy: false,
      showOrganisation: true,
      showAvailabilitySummary: true,
      showShortlistSummary: true,
      showBookingSummary: true
    },
    resourceTableColumns: [
      {
        label: 'Location',
        key: 'locations',
        type: 'locations'
      },
      {
        label: 'Groups',
        key: 'groups',
        type: 'group'
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tag'
      },
      {
        label: 'Website',
        key: 'websites.0.value_1',
        type: 'website'
      }
    ]
  },
  ORGANIZATION: {
    defaultFields: [
      'full_name',
      'role',
      'tags',
      'rates',
      'emails',
      'websites',
      'socials',
      'phone_numbers',
      'locations',
      'groups'
    ],
    resourcePageLayout: {
      displayTabs: ['people', 'calendar', 'notes', 'documents', 'pinboard'],
      showSingleNameField: true,
      showRepresentedBy: false,
      showOrganisation: false,
      showAvailabilitySummary: true,
      showShortlistSummary: true,
      showBookingSummary: true
    },
    resourceTableColumns: [
      {
        label: 'Location',
        key: 'locations',
        type: 'locations'
      },
      {
        label: 'Groups',
        key: 'groups',
        type: 'group'
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tag'
      },
      {
        label: 'Website',
        key: 'websites.0.value_1',
        type: 'website'
      }
    ]
  },
  LOCATION: {
    defaultFields: [
      'full_name',
      'locations',
      'rates',
      'tags',
      'emails',
      'websites',
      'phone_numbers',
      'groups'
    ],
    resourcePageLayout: {
      displayTabs: ['pinboard', 'calendar', 'notes', 'documents', 'feeds'],
      showSingleNameField: true,
      showRepresentedBy: false,
      showOrganisation: false,
      showAvailabilitySummary: true,
      showShortlistSummary: true,
      showBookingSummary: true
    },
    resourceTableColumns: [
      {
        label: 'Groups',
        key: 'groups',
        type: 'group'
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tag'
      },
      {
        label: 'Location',
        key: 'locations',
        type: 'locations'
      }
    ]
  },
  ITEM: {
    defaultFields: [
      'full_name',
      'rates',
      'tags',
      'emails',
      'websites',
      'phone_numbers',
      'groups'
    ],
    resourcePageLayout: {
      displayTabs: ['pinboard', 'calendar', 'notes', 'documents'],
      showSingleNameField: true,
      showRepresentedBy: false,
      showOrganisation: true,
      showAvailabilitySummary: true,
      showBookingSummary: true
    },
    resourceTableColumns: [
      {
        label: 'Groups',
        key: 'groups',
        type: 'group'
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tag'
      },
      {
        label: 'Location',
        key: 'locations',
        type: 'locations'
      }
    ]
  },
  TALENT: {
    defaultFields: [
      'full_name',
      'role',
      'represented_by_id',
      'locations',
      'rates',
      'tags',
      'emails',
      'websites',
      'socials',
      'phone_numbers',
      'groups'
    ],
    resourcePageLayout: {
      displayTabs: [
        'pinboard',
        'calendar',
        'bookings',
        'feeds',
        'notes',
        'documents'
      ],
      showSingleNameField: false,
      showRepresentedBy: true,
      showOrganisation: false,
      showAvailabilitySummary: true,
      showBookingSummary: true
    },
    resourceTableColumns: [
      {
        label: 'Groups',
        key: 'groups',
        type: 'group'
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tag'
      },
      {
        label: 'Location',
        key: 'locations',
        type: 'locations'
      }
    ]
  },
  AGENT: {
    defaultFields: [
      'full_name',
      'organisation_id',
      'tags',
      'emails',
      'websites',
      'phone_numbers',
      'groups'
    ],
    resourcePageLayout: {
      displayTabs: ['people', 'calendar', 'notes', 'documents'],
      showSingleNameField: false,
      showRepresentedBy: false,
      showOrganisation: true,
      showAvailabilitySummary: false,
      showBookingSummary: false
    },
    resourceTableColumns: [
      {
        label: 'Groups',
        key: 'groups',
        type: 'group'
      },
      {
        label: 'Location',
        key: 'locations',
        type: 'locations'
      },
      {
        label: 'Website',
        key: 'websites.0.value_1',
        type: 'website'
      },
      {
        label: 'Tags',
        key: 'tags',
        type: 'tag'
      }
    ]
  }
};

export default RESOURCE_CONFIG;
