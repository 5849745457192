import React, { Component } from 'react';

import PinImage from './PinComponents/PinImage';
import PinFile from './PinComponents/PinFile';
import PinLabel from './PinComponents/PinLabel';
import PinVideo from './PinComponents/PinVideo';
import PinWebLink from './PinComponents/PinWebLink';
import PinProviderEmbed from './PinComponents/PinProviderEmbed';
import PinProviderFile from './PinComponents/PinProviderFile';
import PinTweet from './PinComponents/PinTweet';

import { isImageFromURL, isVideoFromURL } from 'v1/helpers/misc';
import { parseFileType } from 'lib/media/files';
import classnames from 'classnames';
import { get } from 'lodash';

import './PinItem.scss';

export class PinItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClick = () => {
    const { pin } = this.props;

    this.props.onExpand && this.props.onExpand(pin);
  };
  onDeletePin = id => {
    this.setState({ isDeleting: true }, () => {
      this.props.onDeletePin(id);
    });
  };
  onSetAsProfilePicture = pin => this.props.onSetAsProfilePicture(pin);
  parseExternalLink = pin => {
    const url = get(pin, 'file.url') || pin.url;
    if (isImageFromURL(url)) {
      return 'IMAGE';
    }
    if (isVideoFromURL(url)) {
      return 'VIDEO';
    }
    if (url.includes('instagram.com/p/')) {
      return 'INSTAGRAM_EMBED';
    }
    return 'WEB_LINK';
  };
  renderContainer = (component, pin) => {
    const { boundTo, settings = {} } = this.props;
    switch (component) {
      case 'IMAGE':
        return (
          <PinImage
            key={pin.id}
            pin={pin}
            boundTo={boundTo}
            settings={settings.image}
          />
        );
      case 'FILE':
        return <PinFile key={pin.id} pin={pin} />;
      case 'VIDEO':
        return (
          <PinVideo key={pin.id} pin={pin} videoSettings={settings.video} />
        );
      case 'WEB_LINK':
        return <PinWebLink key={pin.id} pin={pin} />;
      case 'PROVIDER_FILE':
        return <PinProviderFile key={pin.id} pin={pin} />;
      case 'INSTAGRAM_EMBED':
        return <PinProviderEmbed key={pin.id} pin={pin} />;
      case 'TWITTER':
        return <PinTweet key={pin.id} pin={pin} />;
      default:
        return (
          <div className="PinItem-graphic">
            <span className="PinItem-graphic-icon">
              <img src="/images/icon_file_document.svg" alt="" />
            </span>
            <span className="PinWebLink-link">Unknown File</span>
          </div>
        );
    }
  };
  typeHandler = pin => {
    let component;
    if (pin.file) {
      component = parseFileType(pin.file);
    } else {
      switch (pin.pin_type) {
        case 'github/repo':
        case 'medium/post':
        case 'external_link':
          component = this.parseExternalLink(pin);
          break;
        case 'file':
          component = parseFileType(pin.file);
          break;
        case 'youtube/video':
        case 'vimeo/video':
          component = 'VIDEO';
          break;
        case 'instagram/image':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
        case 'image/gif':
        case 'application/pdf':
          component = 'IMAGE';
          break;
        case 'google/application/vnd.google-apps.presentation':
        case 'google/application/vnd.google-apps.document':
        case 'google/application/vnd.google-apps.spreadsheet':
        case 'google/application/vnd.google-apps.file':
        case 'google/application/vnd.google-apps.folder':
        case 'google/application/vnd.google-apps.audio':
        case 'google/application/vnd.google-apps.drawing':
        case 'google/application/vnd.google-apps.photo':
        case 'google/application/vnd.google-apps.site':
        case 'google/application/vnd.google-apps.unknown':
          component = 'PROVIDER_FILE';
          break;
        case 'twitter/tweet':
          component = 'TWITTER';
          break;
        default:
          component = 'UNKNOWN';
      }
    }

    return component;
  };
  render() {
    const { pin, onExpand, labelOptions = {} } = this.props;
    return (
      <div
        className={classnames(
          { deleting: this.state.isDeleting, expandable: onExpand },
          ['PinItem', this.props.className]
        )}
        onClick={this.onClick}
      >
        <div className="PinItem-inner">
          {this.renderContainer(this.typeHandler(pin), pin)}
          {labelOptions.visible && (
            <PinLabel
              pin={pin}
              type={this.typeHandler(pin)}
              user={this.props.user}
              onDeletePin={this.onDeletePin}
              onSetAsProfilePicture={this.onSetAsProfilePicture}
              options={labelOptions}
            />
          )}
        </div>
      </div>
    );
  }
}

PinItem.propTypes = {};

export default PinItem;
