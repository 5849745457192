import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GROUP_ACTIONS } from 'store/v1/groups/groups.constants';

import {
  Grid,
  GridCell,
  ListCell,
  MenuItem,
  Dropdown
} from 'v1/components/shared';
import GroupItemCellEditable from '../GroupItemCellEditable/GroupItemCellEditable';

import useEvent from 'v1/helpers/hooks/useEvent';

import './GroupItemCell.scss';

const GroupItemCell = ({ group, onUpdateGroup, onDeleteGroup, isLoading }) => {
  const [isEditing, setIsEditing] = useState(false);

  useEvent([GROUP_ACTIONS.UPDATE_GROUP], {
    onSuccess: () => {
      setIsEditing(false);
    }
  });

  return (
    <ListCell className="GroupItemCell" padding="XS" data-id={group.id}>
      <Grid key={group.id} gutters="XS" vcenter>
        {isEditing ? (
          <GroupItemCellEditable
            group={group}
            onUpdateGroup={onUpdateGroup}
            onCancelEdit={() => setIsEditing(false)}
            isLoading={isLoading}
          />
        ) : (
          <>
            <GridCell width="auto">
              <span className="DragIndicator">
                <img src="/images/icon_drag.svg" alt="" />
              </span>
            </GridCell>
            <GridCell width="auto">
              <div
                style={{ backgroundColor: group.colour }}
                className="GroupItemCell-colour"
              />
            </GridCell>
            <GridCell className="text-13-700">{group.name}</GridCell>
            <GridCell width="auto">
              <Dropdown
                buttonIcon={<img src="/images/icon_options.svg" alt="" />}
                buttonClass="btn btn-link btn-square btn-small"
              >
                <MenuItem onSelect={() => setIsEditing(true)}>
                  <div className="MenuItem-icon">
                    <img src="/images/icon_edit.svg" alt="" />
                  </div>
                  <div className="MenuItem-label">Edit</div>
                </MenuItem>

                <MenuItem onSelect={() => onDeleteGroup(group)}>
                  <div className="MenuItem-icon">
                    <img src="/images/icon_delete.svg" alt="" />
                  </div>
                  <div className="MenuItem-label">Delete</div>
                </MenuItem>
              </Dropdown>
            </GridCell>
          </>
        )}
      </Grid>
    </ListCell>
  );
};

GroupItemCell.propTypes = {
  Group: PropTypes.object.isRequired,
  onUpdateGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  isLoading: PropTypes.bool
};

export default GroupItemCell;
