import io from 'socket.io-client';
import AuthService from 'lib/auth/AuthService';
import Env from 'lib/env/Env';

/**
 * Create websocket client
 * @throws
 */
const createClient = () => {
  const token = AuthService.token();

  if (!token) {
    throw new Error('Socket client needs a token');
  }

  if (!Env.apiURL) {
    throw new Error('Socket client needs a API URL');
  }

  // NOTE Easier to use new URL() and origin but not supported in IE11
  const matches = Env.apiURL.match(/(https?:\/\/.*?)\//);

  if (matches.length === 0) {
    throw new Error('The API endpoint is not valid');
  }

  const client = io.connect(matches[0], {
    path: '/web/v1/ws',
    transports: ['websocket']
  });

  client.on('connecting', () => {
    console.log('connecting over websocket');
  });

  client.on('connect', () => {
    console.log('connected over websocket');
  });

  client.on('disconnect', () => {
    console.log('disconnected from websocket');
  });

  if (Env.isDev) {
    client.on('EVENT', event => {
      const occurred = new Date(event.occurred);
      const time = `${occurred.toLocaleTimeString()}.${occurred.getMilliseconds()}`;
      console.groupCollapsed(
        `%c websocket event %c${event.type} %c@ ${time}`,
        'color: gray; font-weight:lighter',
        'color: black',
        'color: gray; font-weight:lighter'
      );
      console.log(event);
      console.groupEnd();
    });
  }

  return client;
};

export default createClient;
