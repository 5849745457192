import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BlockParagraph } from 'v1/components/shared';
import {
  PressStud,
  ResourceStatusDisplay,
  ResourceStatusGetter
} from 'v1/components/shared';
import { MediaCarousel } from 'v1/components/shared';

import ResourceFieldsDisplay from '../ResourceFieldsDisplay/ResourceFieldsDisplay';
import {
  EmptyGeneric,
  Popover,
  PopoverTrigger,
  PopoverContent
} from 'v1/components/shared';

import ShortlistVotes from '../ShortlistVotes/ShortlistVotes';
import { renderName } from 'v1/helpers/contactHelpers';
import classnames from 'classnames';
import {
  openModal,
  openContactSidebar,
  openBookingSidebar
} from 'store/v1/ui/ui.actions.js';

import { Capability } from '__types__';
import { selectActiveAccountId } from 'store/session';
import {
  upvoteShortlistBlock,
  downvoteShortlistBlock
} from 'store/v1/shortlists/shortlists.actions.js';

import { get, isEmpty, filter, size } from 'lodash';
import Text from 'react-format-text';
import { v4 } from 'uuid';
import './ShortlistContactItemGrid.scss';

// TODO: Really thirsty for a functional component update.

export class ShortlistContactItemGrid extends Component {
  shouldComponentUpdate(nextProps) {
    // RENDER ALL BLOCKS IF THE SHORTLIST HAS UPDATED
    // This is required if we're editing the display field and we want the grid to
    // add the appropriate field.
    if (
      get(nextProps, 'shortlists.lastShortlistIDUpdated') ===
      nextProps.shortlist.id
    ) {
      return true;
    }
    // RERENDER BLOCK IF IT HAS BEEN SELECTED
    // To ensure the block reflects the select / unselect trigger it needs to be rerendered
    if (this.props.blockSelected !== nextProps.blockSelected) {
      return true;
    }
    // RERENDER IF THE SHORTLIST BLOCK HAS UPDATED
    // This is required for external updated such as editing the gallery of the shortlist block.
    if (
      get(nextProps, 'block.id') ===
      get(nextProps, 'shortlists.lastShortlistBlockIDUpdated')
    ) {
      return true;
    }
    // RERENDER IF THE CONTACT HAS UPDATED
    // This is required if a user is editing the resource field and that field should
    // update the display fields on the shortlist block.
    if (
      get(nextProps, 'block.contact_id') ===
      get(nextProps, 'contacts.lastContactIDUpdated')
    ) {
      return true;
    }
    return false;
  }
  onChange = data => {
    this.props.onDataChange({ ...this.props.block, data }, 'UPDATE');
  };
  onUpvote = () => {
    const { id, public_id } = get(this.props, 'shortlist');
    const blockID = get(this.props, 'block.id');

    const guest = this.props.isGuest ? get(this.props, 'user') : null;

    if (this.props.isGuest && !guest.full_name) {
      return this.props.openModal('SetGuestNameModal');
    }

    const toSubmit = { shortlist_block_id: blockID, guest };

    this.setState({ event: v4() }, () => {
      this.props.upvoteShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  onDownvote = () => {
    const { id, public_id } = get(this.props, 'shortlist');
    const blockID = get(this.props, 'block.id');

    const guest = this.props.isGuest ? get(this.props, 'user') : null;

    if (this.props.isGuest && !guest.full_name) {
      return this.props.openModal('SetGuestNameModal');
    }

    const toSubmit = { shortlist_block_id: blockID, guest };

    this.setState({ event: v4() }, () => {
      this.props.downvoteShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  renderName = block => {
    const { onPreviewContact, editable, sidebarDisabled } = this.props;
    const contact = get(
      this.props.contacts,
      ['data', block.contact_id],
      block.contact
    );
    return (
      <div className="ShortlistContactItemGrid-name">
        <h3
          onClick={() => {
            if (!sidebarDisabled) {
              onPreviewContact && this.props.openContactSidebar(contact);
            }
          }}
          className="ContactName-SidebarHint"
        >
          {renderName(contact)}
          {editable && !sidebarDisabled && (
            <img
              data-tip="Quick View Contact"
              className="ContactName-SidebarHint-icon"
              src="/images/icon_contact_sidebar_hint.svg"
              alt=""
            />
          )}
        </h3>
      </div>
    );
  };
  renderDetails = block => {
    const { default_fields, shortlist_field_mapper } = this.props;
    const contact = get(
      this.props.contacts,
      ['data', block.contact_id],
      block.contact
    );
    return (
      <div className="ShortlistContactItemGrid-section">
        <ResourceFieldsDisplay
          fields={default_fields}
          fieldMapper={shortlist_field_mapper}
          resource={contact}
          layout={{
            width: 'TWO_COLUMNS'
          }}
        />
      </div>
    );
  };
  renderAvailability(block) {
    const { editable } = this.props;
    const shortlist = get(
      this.props.shortlists,
      ['data', block.shortlist_id],
      {}
    );

    if (shortlist.show_availability) {
      const publicBooking = !isEmpty(block.booking_events)
        ? block.booking_events.map(booking => ({
            status_id: get(booking, 'booking_status_id'),
            events: [booking]
          }))
        : [];
      return (
        <div className="ShortlistContactItemGrid-section">
          <ResourceStatusGetter
            resourceId={get(block, 'contact_id')}
            dateRange={shortlist.show_availability}
          >
            {({ bookings = [] }) => {
              const renderBookings = bookings.length ? bookings : publicBooking;
              return renderBookings.length ? (
                renderBookings.map(booking => (
                  <div
                    className="inset-XS clickable"
                    onClick={() =>
                      editable && this.props.openBookingSidebar(booking)
                    }
                  >
                    <ResourceStatusDisplay
                      booking={booking || publicBooking}
                      trimDates={shortlist.show_availability}
                      hideAssignments
                    />
                  </div>
                ))
              ) : editable ? (
                <span
                  onClick={() =>
                    this.props.openModal('ResourceEventsModal', {
                      resourceId: block.contact_id,
                      event: shortlist.show_availability
                    })
                  }
                  className="btn btn-default btn-small"
                >
                  Set availability
                </span>
              ) : (
                <span />
              );
            }}
          </ResourceStatusGetter>
        </div>
      );
    }
  }
  renderGallery = block => {
    const { openMediaModal, expandBlock } = this.props;
    const pins = get(block, 'pins');
    const formattedPins = filter(pins, p => !p.archived);
    if (!isEmpty(formattedPins)) {
      return (
        <div
          className={classnames({ expandable: expandBlock }, [
            'ShortlistContactItemGrid-gallery'
          ])}
          onClick={() => {
            expandBlock && expandBlock();
          }}
        >
          <MediaCarousel media={pins} PROVIDER="PINS" layout="FIT" />
          {openMediaModal && (
            <span
              onClick={e => {
                e.stopPropagation();
                openMediaModal(block);
              }}
              className="ShortlistContactItemGrid-gallery-editBtn"
            >
              Edit Gallery
            </span>
          )}
        </div>
      );
    }
    if (openMediaModal) {
      return (
        <div className="EmptyGallery-wrapper">
          <EmptyGeneric
            title="Empty gallery"
            description="Add media for this contact"
            className="ShortlistContactItemGrid-gallery-empty"
            icon="/images/icon_colour_media.svg"
            action={{
              text: 'Add media',
              className: 'btn btn-secondary',
              capability: Capability.RESOURCE_UPDATE,
              onClick: () => {
                openMediaModal(block);
              }
            }}
            onClick={() => {
              openMediaModal(block);
            }}
          />
        </div>
      );
    }
    return (
      <div className="EmptyGallery-wrapper">
        <EmptyGeneric
          title="Empty Gallery"
          className="ShortlistContactItemGrid-gallery-empty"
          icon="/images/icon_colour_media.svg"
        />
      </div>
    );
  };
  renderMetaInformation = () => {
    const { loading } = this.props.shortlists;
    const { block, expandBlock, enableCommenting, enableVoting, showFeedback } =
      this.props;
    const commentCount = size(block.comments);

    if (!enableVoting && !enableCommenting) return;

    return (
      <div className="ShortlistContactItemGrid-metaInformation">
        <div className="ShortlistContactItemGrid-metaInformation-left">
          {enableVoting && (
            <ShortlistVotes
              block={block}
              user={this.props.user}
              isGuest={!this.props.activeAccountId}
              onUpvote={this.onUpvote}
              onDownvote={this.onDownvote}
              loading={loading}
              showFeedback={showFeedback}
            />
          )}
        </div>
        <div className="ShortlistContactItemGrid-metaInformation-right">
          {enableCommenting && (
            <span className="ShortlistContactItemGrid-metaInformation-item">
              <PressStud
                label={showFeedback ? commentCount : null}
                icon="comments"
                size="S"
                action={() => {
                  expandBlock();
                }}
              />
            </span>
          )}
        </div>
      </div>
    );
  };
  renderNote = block => {
    const { editable } = this.props;

    if (editable) {
      return (
        <div className="ShortlistContactItemGrid-note">
          <BlockParagraph
            data={block.data}
            contact={block.contact}
            onChange={this.onChange}
          />
        </div>
      );
    }
    return (
      <Popover>
        <PopoverTrigger>
          <div className="ShortlistContactItemGrid-note">
            <p className="MultiLineFadeOverlay">
              <Text>{get(block, 'data.paragraph') || ''}</Text>
            </p>
          </div>
        </PopoverTrigger>
        <PopoverContent
          method="OVERLAY"
          className="ShortlistContactItemGrid-note-popover inset-M"
        >
          <Text>{get(block, 'data.paragraph') || ''}</Text>
        </PopoverContent>
      </Popover>
    );
  };
  renderSelector = block => {
    return (
      <div
        className="ShortlistContactItemGrid-selector"
        onClick={e => {
          e.stopPropagation();
          this.setState(
            {
              blockSelected: !this.props.blockSelected
            },
            () => {
              this.props.onSelect(block);
            }
          );
        }}
      >
        <span
          className="ShortlistContactItemGrid-selector-checkbox"
          data-tip="Select"
        />
      </div>
    );
  };
  render() {
    const { block, editable } = this.props;
    if (!block.contact) {
      return (
        <EmptyGeneric
          icon="/images/icon_colour_team.svg"
          title="Contact Delete"
          description="This contact has been deleted. You can remove this block on the left"
        />
      );
    }
    return (
      <div className="ShortlistContactItemGrid">
        <div
          className={classnames(['ShortlistContactItemGrid-inner'], {
            selected: this.props.blockSelected,
            editable: editable
          })}
        >
          {editable && this.renderSelector(block)}
          {this.renderGallery(block)}
          {this.renderName(block)}
          {this.renderAvailability(block)}
          {this.renderDetails(block)}
          {this.renderNote(block)}
          {this.renderMetaInformation(block)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAccountId: selectActiveAccountId(state),
    shortlists: state.shortlists,
    contacts: state.contacts,
    events: state.events,
    bookings: state.bookings,
    rates: state.rates
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      upvoteShortlistBlock,
      downvoteShortlistBlock,
      openModal,
      openContactSidebar,
      openBookingSidebar
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortlistContactItemGrid);
