import React from 'react';
import PropTypes from 'prop-types';

import { displayCurrency } from 'v1/helpers/currencyHelper';
import TypeContactRate from 'v1/components/shared/byModel/Rate/__types__/TypeContactRate';
import TypeRate from 'v1/components/shared/byModel/Rate/__types__/TypeRate';
import { Grid, GridCell } from 'v1/components/shared';

const DEFAULT_INTERVAL = 'DAILY'; // TODO: make a const somewhere

const RateItemStatic = ({
  contactRateId,
  rate,
  isRateCard,
  readOnly,
  isEditing,
  onRemove
}) => (
  <div className="RateItemStatic">
    <Grid>
      <GridCell width="1/4" className="right-S">
        <div className="text-13-700 stack-XS">{rate.name}</div>
        {!readOnly && isRateCard && isEditing && onRemove && (
          <div
            className="RateItem-remove text-13-600-eggplant"
            onClick={() => onRemove(contactRateId)}
          >
            Remove
          </div>
        )}
      </GridCell>
      <GridCell>
        {rate.amount && (
          <div className="text-13-700">
            {displayCurrency(rate.amount, rate.currency)} /{' '}
            {(rate.interval || DEFAULT_INTERVAL).toLowerCase()}
          </div>
        )}
        {rate.agency_fee && (
          <div className="text-13-700-eggplant-base">
            + {rate.agency_fee}% agency fee
          </div>
        )}
        {rate.payroll_fee && (
          <div className="text-13-700-eggplant-base">
            + {rate.payroll_fee}% payroll fee
          </div>
        )}
        {rate.processing_fee && (
          <div className="text-13-700-eggplant-base">
            + {rate.processing_fee}% processing fee
          </div>
        )}
        <div className="text-13-600-eggplant it">{rate.description}</div>
      </GridCell>
      <GridCell width="1/12" className="align-right">
        {isRateCard && (
          <div data-tip="This is a Rate Card - You can edit rate cards in settings">
            <img src="/images/icon_money.svg" alt="" />
          </div>
        )}
      </GridCell>
    </Grid>
  </div>
);

RateItemStatic.props = {
  contactRateId: PropTypes.number,
  rate: PropTypes.oneOf([TypeContactRate, TypeRate]).isRequired,
  isRateCard: PropTypes.bool,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  onRemove: PropTypes.func
};

RateItemStatic.defaultProps = {
  isEditing: false
};

export default RateItemStatic;
