import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TypeTextInput from 'v1/components/shared/byType/text/TextInput/__types__/TypeTextInput';
import { TextInput } from 'v1/components/shared';
import { isBrowserSafari } from 'v1/helpers/misc';

import './TimeInput.scss';

const TimeInput = ({ className, onChange, ...props }) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const handleChange = ({ target: { value } }) => {
    onChange && onChange(value);
  };

  return (
    <TextInput
      type="time"
      removeAutocomplete
      onChange={handleChange}
      className={classnames('TimeInput', className)}
      usePolyfill={isBrowserSafari()}
      {...rest}
    />
  );
};

TimeInput.propTypes = {
  ...TypeTextInput,
  value: PropTypes.string, // 24 Hour Time format: 14:00
  onChange: PropTypes.func
};

export default TimeInput;
