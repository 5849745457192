import React from 'react';
import PropTypes from 'prop-types';

import styles from './FlyoverHelper.module.scss';

const FlyoverHelper = ({ title, children }) => (
  <div className={styles.FlyoverHelper}>
    {title && <div className="stack-S text-16-600-white">{title}</div>}
    {children && <div className="text-13-600-white">{children}</div>}
  </div>
);

FlyoverHelper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default FlyoverHelper;
