import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const PUBLIC_SETTINGS_ACTIONS = mapValues(
  mirror({
    GET_PUBLIC_SETTINGS: null,
    GET_PUBLIC_SETTINGS_SUCCESS: null,
    GET_PUBLIC_SETTINGS_FAILURE: null
  }),
  value => value
);
