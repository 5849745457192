import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DEPRECATED_DropdownContentLinks = ({ links, linkClassName }) => (
  <>
    {links.map((link, index) => {
      const props = { link, linkClassName };
      return (
        <React.Fragment key={index}>
          {link.isSeparator ? (
            <div className="DropdownButton-separator" />
          ) : link.route ? (
            <LinkRoute {...props} />
          ) : link.action ? (
            <LinkAction {...props} />
          ) : link.download ? (
            <LinkDownload {...props} />
          ) : link.href ? (
            <LinkHref {...props} />
          ) : null}
        </React.Fragment>
      );
    })}
  </>
);

const LinkRoute = ({ link, linkClassName }) => (
  <Link
    to={link.route}
    key={link.text}
    className={classnames(['DropdownButton-option', linkClassName])}
  >
    {link.icon && (
      <span className="DropdownButton-option-icon">
        <img className="icon" src={link.icon} alt="" />
      </span>
    )}

    {link.text && (
      <span className="DropdownButton-option-label">{link.text}</span>
    )}
  </Link>
);

const LinkAction = ({ link, linkClassName }) => (
  <span
    key={link.text}
    className={classnames(['DropdownButton-option', linkClassName])}
    onClick={link.action && link.action}
  >
    {link.icon && (
      <span className="DropdownButton-option-icon">
        <img src={link.icon} alt="" />
      </span>
    )}

    {link.text && (
      <span className="DropdownButton-option-label">{link.text}</span>
    )}
  </span>
);

const LinkDownload = ({ link, linkClassName }) => (
  <a
    href={link.download}
    key={link.text}
    className={classnames(['DropdownButton-option', linkClassName])}
    download
    target={link.newWindow ? '_blank' : null}
    rel={link.newWindow ? 'noopener noreferrer' : null}
  >
    {link.icon && (
      <span className="DropdownButton-option-icon">
        <img src={link.icon} alt="" />
      </span>
    )}

    {link.text && (
      <span className="DropdownButton-option-label">{link.text}</span>
    )}
  </a>
);

const LinkHref = ({ link, linkClassName }) => (
  <a
    href={link.href}
    key={link.text}
    className={classnames(['DropdownButton-option', linkClassName])}
    target="_blank"
    rel="noopener noreferrer"
  >
    {link.icon && (
      <span className="DropdownButton-option-icon">
        <img src={link.icon} alt="" />
      </span>
    )}

    {link.text && (
      <span className="DropdownButton-option-label">{link.text}</span>
    )}
  </a>
);

DEPRECATED_DropdownContentLinks.propTypes = {
  linkClassName: PropTypes.string,
  links: PropTypes.array, // TODO: type
  onClick: PropTypes.func
};

export default DEPRECATED_DropdownContentLinks;
