import validator from 'validator';
//import isEmpty from 'lodash/isEmpty';

const PASSWORD_REGEX = new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$');
const ALPHA_CHARS_ONLY_REGEX = new RegExp('^[a-zA-Z0-9]+$');
const ACCOUNT_NUMBER_US_REGEX = new RegExp('([0-9])');
const ACCOUNT_NUMBER_UK_REGEX = new RegExp('^([0-9]){8}$');
const SORT_CODE_REGEX = new RegExp('^([0-9]){6}$');
const TRANSFER_CODE_REGEX = new RegExp('^([0-9]){9}$');
const EU_VAT_REGEX = new RegExp(
  '^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'
);

export const VALIDATION_FAILED_MESSAGE =
  'Please check your fields. Some maybe empty or invalid';

export const VALIDATOR_MESSAGES = {
  required: 'This field is required',
  url: 'Not a valid link',
  email: 'Not a valid email address',
  phone_number: 'Not a valid phone number'
};

function validateType(v, type) {
  switch (type) {
    case 'required':
      return !!v;
    case 'url':
      return validator.isURL(v);
    case 'email':
      return validator.isEmail(v);
    case 'phone_number':
      return v && validator.isMobilePhone(v, 'any');
    case 'month':
      return validator.isInt(v, { min: 1, max: 12 });
    case 'day':
      return validator.isInt(v, { min: 1, max: 31 });
    case 'year':
      return validator.isInt(v, { min: 1930, max: 2010 });
    case 'password':
      return PASSWORD_REGEX.test(v.toString());
    case 'account_number_us':
      return ACCOUNT_NUMBER_US_REGEX.test(v.toString());
    case 'account_number_uk':
      return ACCOUNT_NUMBER_UK_REGEX.test(v.toString());
    case 'sort_code':
      return SORT_CODE_REGEX.test(v.toString());
    case 'transfer_code':
      return TRANSFER_CODE_REGEX.test(v.toString());
    case 'alpha_chars_only':
      return ALPHA_CHARS_ONLY_REGEX.test(v);
    case 'vat':
      return v && validator.matches(v.toString(), EU_VAT_REGEX);
    default:
      return true;
  }
}

export function validate(v, t) {
  let types = t;

  if (!types) return null;
  if (!Array.isArray(types)) types = [types];

  if (!v) {
    return types.includes('required') ? VALIDATOR_MESSAGES.required : null;
  }

  for (const type of types) {
    if (!validateType(v, type)) return VALIDATOR_MESSAGES[type];
  }

  return null;
}

export function handleValidate(value, validateString = '') {
  if (validateString) return validate(value, validateString.split(','));
}
