import React, { Component } from 'react';
import { EmptyGeneric, MasonrySortable, PinItem } from 'v1/components/shared';
import { chain, get, isEmpty, size } from 'lodash';
import classnames from 'classnames';

import './ShortlistGallery.scss';
import { MasonrySortableItem } from 'v1/components/shared/layout/MasonrySortable/MasonrySortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';

export default class ShortlistGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderHeader = () => {
    const gallery = get(this.props, 'gallery');
    const galleryList = chain(gallery)
      .filter(p => !p.archived)
      .orderBy(['order', 'asc'])
      .value();

    return (
      <div className="ShortlistGallery-header">
        <h5>IN GALLERY ({size(galleryList)})</h5>
      </div>
    );
  };
  render() {
    const { gallery } = this.props;
    const galleryList = chain(gallery)
      .filter(p => !p.archived)
      .orderBy(['order', 'asc'])
      .value();

    return (
      <div className="ShortlistGallery">
        {this.renderHeader()}
        {!isEmpty(gallery) ? (
          <div className="ShortlistGallery-content">
            <MasonrySortable
              dragOverlay={() => (
                <div className="ShortlistGallery-dragOverlay" />
              )}
              className={classnames(['PinnedListContainer-masonry'])}
              items={galleryList.map((pin, index) => pin.id)}
              onMove={(active, over) => {
                this.props.handleUpdateOrder(
                  galleryList.findIndex(p => p.id === active),
                  galleryList.findIndex(p => p.id === over)
                );
              }}
              modifiers={[restrictToFirstScrollableAncestor]}
            >
              {galleryList.map((pin, index) => (
                <MasonrySortableItem id={pin.id} key={pin.id}>
                  <PinItem
                    className="ShortlistGallery-item"
                    key={`${index}-${pin.id}`}
                    index={index}
                    pin={pin}
                    onDeletePin={() => {
                      this.props.onRemove(pin);
                    }}
                    deleting={this.props.deleting}
                    onExpand={this.props.onExpand}
                    onSetAsProfilePicture={this.props.onSetAsProfilePicture}
                    settings={{
                      video: { showPreview: true, disableControls: true }
                    }}
                    labelOptions={{
                      visible: true,
                      includeProfileImageButton: false
                    }}
                  />
                </MasonrySortableItem>
              ))}
            </MasonrySortable>
          </div>
        ) : (
          <EmptyGeneric
            title="Empty Gallery"
            className="ShortlistGallery-empty"
            description="Add media from the sources on the left to add to their gallery"
          />
        )}
      </div>
    );
  }
}
