import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get } from 'lodash';

import {
  PressStud,
  ErrorMessage,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput,
  Checkbox
} from 'v1/components/shared';

import {
  createBookingType,
  updateBookingType
} from 'store/v1/booking_types/booking_types.actions.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { BOOKING_TYPES_ACTIONS } from 'store/v1/booking_types/booking_types.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import { validate as validator } from 'v1/helpers/validation';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const BookingTypeCreateModal = () => {
  // REDUX
  const ui = useSelector(state => state.ui);
  const { booking_type = {}, create } = get(ui, 'data', {});

  const booking_types = useSelector(state => state.booking_types);
  const { loading, error } = booking_types || {};

  const dispatch = useDispatch();

  // PROPS AND REFS
  const { id, name, include_weekends_default } = booking_type;

  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent(
    [
      BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE,
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE
    ],
    {
      onSuccess: () => {
        dispatch(closeModal());
        dispatch(displayAlert('success', 'Updated successfully'));
      },
      onFailure: () => dispatch(displayAlert('error', error.message))
    }
  );

  // METHODS
  function handleSubmit(values) {
    create
      ? dispatch(createBookingType(values))
      : dispatch(updateBookingType(id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} Booking Type`} />
      <ModalContent>
        <ModalScrollable padding="XL">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              name,
              include_weekends_default
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form
                data-cy="booking-type-create-form"
                onSubmit={formApi.submitForm}
              >
                <Field
                  field="name"
                  validate={value => handleValidate(value, 'required')}
                >
                  {({ fieldName: name, value, error, setValue }) => (
                    <div className="stack-M">
                      <Label htmlFor={name}>Booking Type Name</Label>
                      <TextInput
                        name={name}
                        value={value}
                        placeholder="Name"
                        onChange={({ target }) => setValue(target.value)}
                        validity={error ? 'invalid' : null}
                        required
                        autoComplete="off"
                        autoFocus
                      />
                      {error && (
                        <ErrorMessage>This field is required</ErrorMessage>
                      )}
                    </div>
                  )}
                </Field>
                <div className="stack-M">
                  <Field field="include_weekends_default">
                    {fieldApi => (
                      <Checkbox
                        label="Include Weekend in booking dates by default"
                        name="include_weekends_default"
                        id="include_weekends_default"
                        description="If bookings are by default including weekends then
                        select this box."
                        checked={fieldApi.value}
                        onChange={({ target }) =>
                          fieldApi.setValue(target.checked)
                        }
                      />
                    )}
                  </Field>
                </div>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Booking type' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE ||
              loading === BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default BookingTypeCreateModal;
