import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button, Grid, GridCell, UserCircleImage } from 'v1/components/shared';
import { SystemLink } from 'v1/components/shared';
import { ResourceMeta, ResourceContactInfoMeta } from 'v1/components/shared';

import { openContactSidebar } from 'store/v1/ui/ui.actions.js';

import { renderName } from 'v1/helpers/contactHelpers';
import classnames from 'classnames';
import './ContactItemCompact.scss';

export class ContactItemCompact extends Component {
  openContactSidebar = e => {
    e.stopPropagation();
    const { contact = {} } = this.props;
    this.props.openContactSidebar(contact);
  };
  renderAction = () => {
    const { action } = this.props;

    return (
      <Button type="button" {...action}>
        {action.label}
      </Button>
    );
  };
  renderNameRoute = () => {
    const { contact = {}, previewMethod } = this.props;
    const { id } = contact;

    switch (previewMethod) {
      case 'NONE':
        return <span className="text-13-700">{renderName(contact)}</span>;
      case 'SIDEBAR':
        return (
          <span
            onClick={this.openContactSidebar}
            className="link ContactName-SidebarHint"
          >
            {renderName(contact)}
            <img
              className="ContactName-SidebarHint-icon"
              src="/images/icon_contact_sidebar_hint.svg"
              alt=""
            />
          </span>
        );
      case 'PAGE':
      default:
        return (
          <SystemLink
            to={`/contacts/${id}`}
            onClick={e => {
              e.stopPropagation();
            }}
            className="link"
            target={this.props.newWindow ? '_blank' : null}
            data-cy="resource-link"
          >
            {renderName(contact)}
          </SystemLink>
        );
    }
  };
  render() {
    const {
      contact = {},
      action,
      className,
      size,
      hideResourceMeta,
      showResourceContact,
      onClear
    } = this.props;
    const { first_name, last_name, profile_picture, id, groups } = contact;

    return (
      <Grid
        className={classnames(['ContactItemCompact', className])}
        gutters="S"
      >
        <GridCell width="auto">
          <UserCircleImage
            src={profile_picture}
            initials={{ first_name, last_name }}
            contact_id={id}
            className={classnames([size || 'default'])}
            colours={groups && groups.map(group => group.colour)}
          />
        </GridCell>
        <GridCell className="ContactItemCompact-details">
          <div className="ContactItemCompact-clearable">
            {this.renderNameRoute()}
            {typeof onClear === 'function' && (
              <img
                src="/images/icon_close_grey.svg"
                className="ContactItemCompact-clear"
                alt=""
                onClick={onClear}
              />
            )}
          </div>
          {/* TODO: hideResourceMeta is a temporary fix for looping getContact issue on bookings */}
          {!hideResourceMeta && <ResourceMeta resource={contact} />}{' '}
          {showResourceContact && (
            <ResourceContactInfoMeta resource={contact} />
          )}
        </GridCell>
        {action && <GridCell width="auto">{this.renderAction()}</GridCell>}
      </Grid>
    );
  }
}

ContactItemCompact.propTypes = {
  contact: PropTypes.object.isRequired,
  action: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.string,
  previewMethod: PropTypes.oneOf(['SIDEBAR', 'PAGE', 'NONE']),
  showResourceContact: PropTypes.bool,
  hideResourceMeta: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openContactSidebar
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ContactItemCompact);
