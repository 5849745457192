import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import useEvent from 'v1/helpers/hooks/useEvent';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';

import BookResourceFlyoverResourceContents from './BookResourceFlyoverResource/BookResourceFlyoverResourceContents';
import BookResourceFlyoverResourceFooter from './BookResourceFlyoverResource/BookResourceFlyoverResourceFooter';
import BookResourceFlyoverBooking from './BookResourceFlyoverBooking/BookResourceFlyoverBooking';
import { Flyover } from 'v1/components/shared';

const BookResourceFlyover = ({ className, slot, children }) => {
  const dispatch = useDispatch();
  const slotCategories = useSelector(state => state.slot_categories.data);
  const slotCategory = get(slotCategories, [slot.slot_category_id], {});
  const error = useSelector(state => state.bookings.error);

  const resources = useSelector(state => state.productions.data);
  const [resourceId, setResourceId] = useState();
  const resource = get(resources, resourceId, {});

  const onFlyoverClose = () => setResourceId(null);

  useEvent([BOOKING_ACTIONS.CREATE_BOOKING], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Booking Created'));
    },
    onFailure: () => {
      if (get(error, 'status') === 409) {
        return dispatch(
          openModal('BookingConflictModal', {
            method: 'UPDATE',
            resourceId
          })
        );
      }
      dispatch(displayAlert('error', 'Booking update failed'));
    }
  });

  return (
    <Flyover onClose={onFlyoverClose}>
      <Flyover.Trigger className={className}>{children}</Flyover.Trigger>
      <Flyover.Content
        width="trigger"
        clearance="flush"
        height="fixed"
        // TODO: forcePosition
      >
        {({ closeFlyover }) => {
          return !resourceId ? (
            <>
              <Flyover.Header
                title="Resources"
                icon="/images/icon_resources_pear.svg"
              />
              <BookResourceFlyoverResourceContents
                slotQuery={slot.query}
                setResourceId={setResourceId}
              />
              <Flyover.Footer>
                <BookResourceFlyoverResourceFooter
                  slot={slot}
                  closeFlyover={closeFlyover}
                />
              </Flyover.Footer>
            </>
          ) : (
            <>
              <Flyover.Header
                title="Booking"
                icon="/images/icon_booking_bubblegum.svg"
              />
              <BookResourceFlyoverBooking
                slot={slot}
                resourceId={resourceId}
                closeFlyover={closeFlyover}
              />
            </>
          );
        }}
      </Flyover.Content>
      <Flyover.Helper
        title={`Assign to ${
          resource.full_name || slotCategory.name || 'resource'
        }`}
      >
        <p>Quickly assign and book a resource</p>
      </Flyover.Helper>
    </Flyover>
  );
};

BookResourceFlyover.propTypes = {
  className: PropTypes.string,
  slot: PropTypes.object,
  children: PropTypes.any
};

export default BookResourceFlyover;
