import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import { filter, findIndex, get } from 'lodash';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  blocks_order: null,
  new: {},
  loading: false,
  lastShortlistBlockIDUpdated: {},
  lastShortlistIDUpdated: {},
  shortlistsUpdatedAt: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  let shortlist_blocks,
    blockIndex,
    pins,
    /*votes,*/
    comments,
    upvotes,
    downvotes;
  switch (action.type) {
    case SHORTLISTS_ACTIONS.GET_SHORTLISTS:
    case SHORTLISTS_ACTIONS.GET_SHORTLIST:
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST:
    case SHORTLISTS_ACTIONS.UPDATE_SHORTLIST:
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST:
    case SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST:
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK:
    case SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK:
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK:
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_PIN:
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_PIN:
    case SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST:
    case SHORTLISTS_ACTIONS.UPVOTE_SHORTLIST_BLOCK:
    case SHORTLISTS_ACTIONS.DOWNVOTE_SHORTLIST_BLOCK:
    case SHORTLISTS_ACTIONS.COMMENT_SHORTLIST_BLOCK:
    case SHORTLISTS_ACTIONS.BATCH_ADD_SHORTLIST:
    case SHORTLISTS_ACTIONS.SEARCH_SHORTLIST_BLOCKS:
      return {
        ...state,
        loading: action.type,
        lastShortlistIDUpdated: {},
        lastShortlistBlockIDUpdated: {},
        error: false
      };
    case SHORTLISTS_ACTIONS.GET_SHORTLISTS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({
            ...data,
            [item.id]: { ...item, restricted: true }
          }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        blocks_order: null,
        paging: action.result.paging,
        loading: false
      };
    case SHORTLISTS_ACTIONS.GET_SHORTLISTS_FAILURE:
    case SHORTLISTS_ACTIONS.GET_SHORTLIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        order: [action.result.id, ...state.order],
        new: action.result
      };
    case SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST_SUCCESS:
      if (action.result.shortlist === undefined) {
        return state; // migration bridge
      }
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.shortlist.id]: action.result.shortlist
        }
      };
    case SHORTLISTS_ACTIONS.GET_SHORTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        lastShortlistIDUpdated: action.result.id
      };
    case SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_SUCCESS:
    case SHORTLISTS_ACTIONS.BATCH_ADD_SHORTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        lastShortlistIDUpdated: action.result.id
      };
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_PIN_SUCCESS:
      shortlist_blocks = get(state.data, [
        action.shortlist_id,
        'shortlist_blocks'
      ]);
      blockIndex = findIndex(shortlist_blocks, b => b.id === action.result.id);
      shortlist_blocks[blockIndex] = {
        ...shortlist_blocks[blockIndex],
        pins: [...shortlist_blocks[blockIndex].pins, action.result]
      };
      return {
        ...state,
        data: {
          [action.shortlist_id]: {
            ...state.data[action.shortlist_id],
            shortlist_blocks: shortlist_blocks
          }
        },
        loading: false
      };
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_PIN_SUCCESS:
      shortlist_blocks = get(state.data, [
        action.shortlist_id,
        'shortlist_blocks'
      ]);
      blockIndex = findIndex(shortlist_blocks, b => b.id === action.result.id);
      pins = filter(
        shortlist_blocks[blockIndex].pins,
        p => p.id !== action.pin_id
      );

      shortlist_blocks[blockIndex] = {
        ...shortlist_blocks[blockIndex],
        pins: pins
      };
      return {
        ...state,
        data: {
          [action.shortlist_id]: {
            ...state.data[action.shortlist_id],
            shortlist_blocks: shortlist_blocks
          }
        },
        loading: false
      };
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_SUCCESS:
      shortlist_blocks = get(state.data, [
        action.shortlist_id,
        'shortlist_blocks'
      ]);
      blockIndex = findIndex(shortlist_blocks, b => b.id === action.result.id);

      if (blockIndex !== -1) {
        shortlist_blocks[blockIndex] = action.result;
      } else {
        shortlist_blocks.push(action.result);
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.shortlist_id]: {
            ...state.data[action.shortlist_id],
            shortlist_blocks
          }
        },
        loading: false
      };
    case SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK_SUCCESS:
      shortlist_blocks = get(state.data, [
        action.shortlist_id,
        'shortlist_blocks'
      ]);
      blockIndex = findIndex(shortlist_blocks, b => b.id === action.result.id);
      shortlist_blocks[blockIndex] = action.result;
      return {
        ...state,
        data: {
          ...state.data,
          [action.shortlist_id]: {
            ...state.data[action.shortlist_id],
            shortlist_blocks: shortlist_blocks
          }
        },
        lastShortlistBlockIDUpdated: get(action, 'result.id'),
        loading: false
      };
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_SUCCESS:
      shortlist_blocks = get(state.data, [
        action.shortlist_id,
        'shortlist_blocks'
      ]);
      blockIndex = findIndex(
        shortlist_blocks,
        b => b.id === action.shortlist_block_id
      );

      if (blockIndex !== -1) {
        shortlist_blocks[blockIndex] = {
          ...shortlist_blocks[blockIndex],
          archived: true
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.shortlist_id]: {
            ...state.data[action.shortlist_id],
            shortlist_blocks: shortlist_blocks
          }
        },
        loading: false
      };
    case SHORTLISTS_ACTIONS.UPVOTE_SHORTLIST_BLOCK_SUCCESS:
    case SHORTLISTS_ACTIONS.DOWNVOTE_SHORTLIST_BLOCK_SUCCESS:
    case SHORTLISTS_ACTIONS.COMMENT_SHORTLIST_BLOCK_SUCCESS:
      shortlist_blocks = get(state.data, [
        action.shortlist_id,
        'shortlist_blocks'
      ]);
      blockIndex = findIndex(shortlist_blocks, s => s.id === action.result.id);

      if (shortlist_blocks) {
        // todo mutating data! do not do this!
        shortlist_blocks[blockIndex].upvotes = action.result.upvotes;
        shortlist_blocks[blockIndex].downvotes = action.result.downvotes;
        shortlist_blocks[blockIndex].comments = action.result.comments;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.shortlist_id]: {
            ...state.data[action.shortlist_id],
            shortlist_blocks
          }
        },
        lastShortlistBlockIDUpdated: get(action, 'result.id'),
        loading: false
      };
    case SHORTLISTS_ACTIONS.SEARCH_SHORTLIST_BLOCKS_SUCCESS:
      return {
        ...state,
        blocks_order: action.result.results,
        loading: false
      };
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        new: action.result
      };
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_SUCCESS:
      const { [action.result.id]: item, ...data } = state.data;
      return {
        ...state,
        loading: false,
        data,
        order: state.order.filter(i => i !== action.result.id)
      };
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_FAILURE:
    case SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_FAILURE:
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_FAILURE:
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_FAILURE:
    case SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK_FAILURE:
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_FAILURE:
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_PIN_FAILURE:
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_PIN_FAILURE:
    case SHORTLISTS_ACTIONS.GET_PUBLIC_SHORTLIST_FAILURE:
    case SHORTLISTS_ACTIONS.UPVOTE_SHORTLIST_BLOCK_FAILURE:
    case SHORTLISTS_ACTIONS.DOWNVOTE_SHORTLIST_BLOCK_FAILURE:
    case SHORTLISTS_ACTIONS.COMMENT_SHORTLIST_BLOCK_FAILURE:
    case SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST_FAILURE:
    case SHORTLISTS_ACTIONS.BATCH_ADD_SHORTLIST_FAILURE:
    case SHORTLISTS_ACTIONS.SEARCH_SHORTLIST_BLOCKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result ? action.result.error : action.error
      };
    case SHORTLISTS_ACTIONS.SET_SHORTLIST_UPDATED_AT:
      return {
        ...state,
        shortlistsUpdatedAt: {
          ...state.shortlistsUpdatedAt,
          [action.shortlistId]: action.updatedAt
        }
      };
    default:
      return state;
  }
}
