import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const RESOURCE_TYPES_ACTIONS = mapValues(
  mirror({
    GET_RESOURCE_TYPES: null,
    GET_RESOURCE_TYPES_SUCCESS: null,
    GET_RESOURCE_TYPES_FAILURE: null,

    CREATE_RESOURCE_TYPE: null,
    CREATE_RESOURCE_TYPE_SUCCESS: null,
    CREATE_RESOURCE_TYPE_FAILURE: null,

    DELETE_RESOURCE_TYPE: null,
    DELETE_RESOURCE_TYPE_SUCCESS: null,
    DELETE_RESOURCE_TYPE_FAILURE: null,

    UPDATE_RESOURCE_TYPE: null,
    UPDATE_RESOURCE_TYPE_SUCCESS: null,
    UPDATE_RESOURCE_TYPE_FAILURE: null,

    UPDATE_RESOURCE_TYPE_ORDER: null,
    UPDATE_RESOURCE_TYPE_ORDER_SUCCESS: null,
    UPDATE_RESOURCE_TYPE_ORDER_FAILURE: null
  }),
  value => value
);
