import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CheckboxInput.scss';

class CheckBoxInput extends Component {
  handleChange = value =>
    this.props.onChange && this.props.onChange(this.props.field, value);

  renderCheckboxDecision = () => {
    const isBoolean = typeof this.props.value === 'boolean';

    return (
      <div className="CheckboxInput-toggle">
        <div
          className={classnames({
            'CheckboxInput-toggle-item': true,
            active:
              isBoolean && !this.props.value && this.props.value !== undefined
          })}
          onClick={() => {
            this.handleChange(false);
          }}
        >
          {this.props.decision1Label || 'No'}
        </div>
        <div
          className={classnames({
            'CheckboxInput-toggle-item': true,
            active: isBoolean && this.props.value
          })}
          onClick={() => {
            this.handleChange(true);
          }}
        >
          {this.props.decision2Label || 'Yes'}
        </div>
      </div>
    );
  };
  render() {
    const { error, className, label, field, children } = this.props;
    return (
      <div
        className={classnames({ invalid: error }, ['CheckboxInput', className])}
      >
        {label ? (
          <label htmlFor={field} className="form-label-checkbox">
            {label}
          </label>
        ) : (
          children || null
        )}
        {this.renderCheckboxDecision()}
      </div>
    );
  }
}

CheckBoxInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  decision1Label: PropTypes.string,
  decision2Label: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.any, // TODO: type
  children: PropTypes.any, // TODO: type
  onChange: PropTypes.func // TODO: arguments
};

export default CheckBoxInput;
