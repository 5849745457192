import { createSlice } from '@reduxjs/toolkit';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants';
import AccountLib from 'lib/auth/Account';

export type SessionStore = {
  accountId: string | null;
  teamId: string | null;
};

export const INITIAL_STATE: SessionStore = {
  accountId: null,
  teamId: null
};

const sessionSlice = createSlice({
  name: 'account',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(AUTH_ACTIONS.LOAD_AUTH_SUCCESS, state => {
      state.accountId = AccountLib.accountId();
      state.teamId = AccountLib.resolveFirstWorkspaceId();
    });
  }
});

export default sessionSlice.reducer;
