import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty, filter, orderBy } from 'lodash';

import { openModal } from 'store/v1/ui/ui.actions.js';
import { searchShortlistBlocks } from 'store/v1/shortlists/shortlists.actions.js';
import { Button, EmptyGeneric } from 'v1/components/shared';
import ShortlistFeedbackItemGrid from '../ShortlistFeedbackItemGrid/ShortlistFeedbackItemGrid';
import ShortlistFeedbackView from '../ShortlistFeedbackView/ShortlistFeedbackView';
import ShortlistToolbar from '../ShortlistToolbar/ShortlistToolbar';
import { MasonryVirtualized } from 'v1/components/shared';
import { Dropdown, MenuItem } from 'v1/components/shared';
import './ShortlistFeedback.scss';

const ShortlistFeedback = ({
  shortlist,
  shortlistFieldMapper,
  resourceType
}) => {
  const dispatch = useDispatch();
  const shortlists = useSelector(state => state.shortlists);
  const [query, setQuery] = useState(
    shortlist.default_resources_query || {
      filters: resourceType
        ? {
            resource_type_id: {
              eq: resourceType.id
            }
          }
        : {}
    }
  );
  const shortlistFeedback = useRef();

  useEffect(() => {
    !shortlist.default_resources_query &&
      setQuery(prev => ({
        ...prev,
        filters: resourceType
          ? {
              ...prev.filters,
              resource_type_id: {
                eq: resourceType.id
              }
            }
          : { ...prev.filters }
      }));
  }, [shortlist, resourceType]);

  const filterData = () => {
    const activeBlocks = filter(shortlist.shortlist_blocks, b => !b.archived);
    const querySort = get(query, ['order_by', 'field']);
    const useUserOrder =
      !querySort || querySort === 'order' || !shortlists.blocks_order;

    const filtered = shortlists.blocks_order
      ? shortlists.blocks_order.reduce((acc, id) => {
          const block = activeBlocks.find(b => b.contact_id === id);
          return block ? [...acc, block] : acc;
        }, [])
      : activeBlocks;

    if (querySort === 'upvotes') {
      return filtered.sort((a, b) => b.upvotes?.length - a.upvotes?.length);
    }
    if (querySort === 'downvotes') {
      return filtered.sort((a, b) => b.downvotes?.length - a.downvotes?.length);
    }
    if (useUserOrder) {
      return orderBy(filtered, ['order', 'asc']);
    }
    return filtered;
  };

  const onQueryChange = query => {
    setQuery(query);
    dispatch(searchShortlistBlocks(shortlist.id, query));
  };

  const orderByVotes = field => {
    setQuery({ ...query, order_by: { field, direction: 'desc' } });
  };

  // TODO: (revamp) : review
  const renderBlocks = _ => {
    const sortedBlocks = filterData(shortlist.shortlist_blocks);

    const listView =
      sortedBlocks &&
      sortedBlocks.map(block => (
        <ShortlistFeedbackItemGrid
          key={block.id}
          block={block}
          shortlist={shortlist}
          expandBlock={() => {
            dispatch(
              openModal('MediaFocusModal', {
                mediaType: 'SHORTLIST_BLOCKS',
                mediaSelected: block,
                mediaList: sortedBlocks,
                mediaSidebar: (selected, navigateBlock) => {
                  return (
                    <ShortlistFeedbackView
                      block={selected}
                      shortlist={shortlist}
                      navigateBlock={navigateBlock}
                      //shortlist_field_mapper={this.props.shortlist_field_mapper} // TODO: review - not passed in atm?
                    />
                  );
                },
                emptyGalleryComponent: selected => {
                  return (
                    <div className="ShortlistEmptyGallery">
                      <h5>Empty Gallery</h5>
                    </div>
                  );
                }
              })
            );
          }}
        />
      ));

    if (!isEmpty(sortedBlocks)) {
      return (
        <MasonryVirtualized
          className="ShortlistPresentView-list ShortlistPresentView-masonry"
          scrollElement={shortlistFeedback.current}
        >
          {listView}
        </MasonryVirtualized>
      );
    }
    return (
      <EmptyGeneric
        icon="/images/icon_colour_notes.svg"
        title="No feedback received"
        description="Feedback collected from external stakeholders will appear here"
      />
    );
  };

  const renderSortOptions = () => {
    return (
      <Dropdown
        buttonClass="btn btn-small btn-default"
        buttonLabel={`Sort by ${query.order_by?.field || 'user order'}`}
        listExpand
      >
        <MenuItem
          className="DropdownButton-option"
          onSelect={() => orderByVotes('upvotes')}
        >
          Upvotes
        </MenuItem>
        <MenuItem
          className="DropdownButton-option"
          onSelect={() => orderByVotes('downvotes')}
        >
          Downvotes
        </MenuItem>
        <MenuItem
          className="DropdownButton-option"
          onSelect={() => orderByVotes(null)}
        >
          User order
        </MenuItem>
      </Dropdown>
    );
  };

  return (
    <div className="ShortlistFeedback GRID" ref={shortlistFeedback}>
      <div className="ShortlistFeedback-scroll">
        <div className="ShortlistFeedback-header">
          <h2>Feedback Results</h2>
          <h5>{shortlist.public_title || shortlist.title}</h5>
        </div>
        <div className="ShortlistPresentView-search">
          <ShortlistToolbar
            shortlist={shortlist}
            shortlistFieldMapper={shortlistFieldMapper}
            resourceType={resourceType}
            query={query}
            onSetQuery={onQueryChange}
          />
        </div>
        <div className="ShortlistFeedback-tools">{renderSortOptions()}</div>
        {renderBlocks()}
      </div>
    </div>
  );
};

ShortlistFeedback.props = {
  shortlist: PropTypes.object,
  shortlistFieldMapper: PropTypes.array
};
export default ShortlistFeedback;
