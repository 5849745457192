import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TextInputGroupOptions from './TextInputGroupOptions/TextInputGroupOptions';

import styles from '../TextInputGroup.module.scss';

const TextInputGroupPend = ({
  type,
  appearance,
  size,
  value,
  pend,
  disabled,
  onChange
}) => {
  if (pend.options) {
    return (
      <TextInputGroupOptions
        aria-label={pend.valueKey}
        appearance={appearance}
        size={size}
        type={type}
        value={value[pend.valueKey] || ''}
        options={pend.options}
        disabled={disabled}
        onChange={val => onChange(pend.valueKey, val)}
      />
    );
  }

  return (
    <div
      className={classnames([
        styles.Pend,
        styles[`Pend_${type}`],
        'emulate-input',
        `emulate-input_${appearance}`,
        `emulate-input_${size}`,
        {
          [styles.Pend_icon]: pend.icon,
          [styles.Pend_text]: pend.text,
          clickable: !disabled && pend.onClick,
          disabled: disabled || !pend.onClick,
          [styles.clickable]: !disabled && pend.onClick,
          [styles.nonclickable]: disabled || !pend.onClick
        }
      ])}
      onClick={!disabled ? pend.onClick : undefined}
    >
      {pend.icon && <img className={styles.Icon} src={pend.icon} alt="" />}
      <span className={styles.Text}>{pend.text}</span>
    </div>
  );
};

export const pendType = PropTypes.shape({
  icon: PropTypes.string,
  text: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  onClick: PropTypes.func
});

TextInputGroupPend.propTypes = {
  type: PropTypes.oneOf(['prepend', 'append']),
  appearance: PropTypes.oneOf(['outline', 'underline', 'silent']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  value: PropTypes.any,
  disabled: PropTypes.bool,
  pend: pendType,
  onChange: PropTypes.func
};

export default TextInputGroupPend;
