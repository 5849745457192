import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Capability } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';

import {
  Grid,
  GridCell,
  Label,
  RateInput,
  RateSelector,
  RateAgencyFee,
  RateProcessingFee,
  RatePayrollFee
} from 'v1/components/shared';
import CostSummary from './CostSummary/CostSummary';

export const BookingRateInput = ({
  className,
  contactName,
  contactId,
  rate,
  agencyFee,
  payrollFee,
  processingFee,
  onChange,
  bookingDates,
  assignments
}) => {
  return (
    <RestrictedAccess
      capabilitiesAtLeastOne={[Capability.RATE_READ, Capability.RATE_UPDATE]}
    >
      <div className={classnames(['BookingRateInput', className])}>
        <Grid gutters="M">
          <GridCell width="5/12">
            <Grid>
              <GridCell>
                <Label>Rate</Label>
              </GridCell>
              <GridCell width="auto">
                <RateSelector
                  label="Add rate"
                  labelClass="genericLink edit"
                  resourceId={contactId}
                  assignments={assignments}
                  showRateCards
                  onSelect={rate => onChange('rate', rate)}
                />
              </GridCell>
            </Grid>
            <RateInput
              className="stack-S"
              size="S"
              value={rate}
              onBlur={value => onChange('rate', value)}
            />
            <RateAgencyFee
              value={agencyFee}
              className="stack-S"
              onChange={value => onChange('rate_agency_fee', value)}
            />
            <RatePayrollFee
              value={payrollFee}
              className="stack-S"
              onChange={value => onChange('rate_payroll_fee', value)}
            />
            <RateProcessingFee
              value={processingFee}
              className="stack-S"
              onChange={value => onChange('rate_processing_fee', value)}
            />
          </GridCell>
          <GridCell width="7/12">
            <Label>Cost summary</Label>
            <CostSummary
              bookingDates={bookingDates}
              contactName={contactName}
              rate={rate}
              agencyFee={agencyFee}
              payrollFee={payrollFee}
              processingFee={processingFee}
            />
          </GridCell>
        </Grid>
      </div>
    </RestrictedAccess>
  );
};

BookingRateInput.propTypes = {
  className: PropTypes.string,
  contactName: PropTypes.string,
  rate: PropTypes.shape({
    currency: PropTypes.any, // TODO
    amount: PropTypes.any, // TODO
    rate_interval: PropTypes.oneOf(['DAILY', 'HOURLY', 'FIXED'])
  }),
  agencyFee: PropTypes.any,
  bookingDates: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default BookingRateInput;
