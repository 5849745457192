import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './PageTabs.scss';

/**
 * @deprecated - Use `Tabs` instead
 */
const PageTabs = ({
  tabs = [],
  activeContainer,
  className,
  onTabSelect,
  appearance,
  borderBottom,
  flush
}) => {
  const renderTabs = () => {
    return tabs.map((tab, index) => {
      return (
        <span
          key={`${tab.text}-${index}`}
          className={classnames(
            {
              active: tab.container === activeContainer,
              [`PageTabs_${appearance}`]: appearance,
              [`PageTabs_flush`]: flush
            },
            ['PageTabs-tab']
          )}
          onClick={() => {
            onTabSelect(tab.container);
          }}
        >
          {tab.text}
        </span>
      );
    });
  };
  return (
    <div
      className={classnames([
        'PageTabs',
        className,
        { [`PageTabs_borderBottom`]: borderBottom }
      ])}
    >
      {renderTabs()}
    </div>
  );
};

PageTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTabSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['BASIC', 'DEFAULT']),
  flush: PropTypes.bool,
  borderBottom: PropTypes.bool
};

export default PageTabs;
