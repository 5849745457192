import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get, orderBy } from 'lodash';

import { openModal } from 'store/v1/ui/ui.actions.js';
import { createBooking } from 'store/v1/bookings/bookings.actions.js';

import { createBookingObject } from 'v1/helpers/byModel/BookingHelper';
import { getResourceSlotRate } from 'v1/helpers/byModel/ResourceSlotHelper';
import { createNewEventObject } from 'v1/helpers/byModel/EventHelper';

import BookResourceFlyoverBookingContents from './BookResourceFlyoverBookingContents';
import BookResourceFlyoverBookingFooter from './BookResourceFlyoverBookingFooter';
import { Flyover } from 'v1/components/shared';
import { resourceFieldsContainsRate } from 'store/v1/contacts/contacts.selectors';

const BookResourceFlyoverBooking = ({ slot, resourceId, closeFlyover }) => {
  const dispatch = useDispatch();
  const bookingTypes = useSelector(state => state.booking_types);
  const resources = useSelector(state => state.contacts.data);
  const resource = get(resources, [resourceId], {});
  const resourceFieldHasRate = useSelector(state =>
    resourceFieldsContainsRate(state, resource.resource_type_id)
  );
  const [event, setEvent] = useState();

  const getInitialRate = () => {
    if (resourceFieldHasRate) {
      const estRate = getResourceSlotRate(slot);
      if (estRate.amount) {
        return estRate;
      }
      const resourceRate = orderBy(resource.rates, 'order').find(
        r => !r.archived
      );
      if (resourceRate) {
        return resourceRate.rate || resourceRate;
      }
    }
    return {};
  };

  const [rate, setRate] = useState(getInitialRate());

  const getBookingRate = () => {
    return rate
      ? {
          rate_currency: rate.currency,
          rate_amount: rate.amount,
          rate_interval: rate.interval || 'DAILY'
        }
      : null;
  };

  // TODO: this is mostly ripped out of ResourceStatusActions
  const saveBooking = status => {
    const newBooking = {
      ...createBookingObject(bookingTypes, {
        event,
        resource,
        slot,
        status
      }),
      ...getBookingRate()
    };

    dispatch(createBooking(newBooking));
    closeFlyover();
  };

  const openCreateBooking = () => {
    dispatch(
      openModal('ResourceEventsModal', {
        resourceSlotId: slot.id,
        resourceId,
        event,
        resourceSlotAssignmentEvent: createNewEventObject({
          ...event,
          event_type: 'RESOURCE_SLOT_ASSIGNMENT'
        }),
        rate
      })
    );
    closeFlyover();
  };

  return (
    <>
      <BookResourceFlyoverBookingContents
        slot={slot}
        event={event}
        setEvent={setEvent}
        rate={rate}
        setRate={setRate}
        openCreateBooking={openCreateBooking}
      />
      <Flyover.Footer>
        <BookResourceFlyoverBookingFooter saveBooking={saveBooking} />
      </Flyover.Footer>
    </>
  );
};

BookResourceFlyoverBooking.propTypes = {
  slot: PropTypes.object,
  resourceId: PropTypes.number,
  closeFlyover: PropTypes.func
};

export default BookResourceFlyoverBooking;
