import { useRef } from 'react';

export type SingleMutationState =
  | { status: 'idle' }
  | { status: 'pending' }
  | { status: 'success' }
  | { status: 'error'; error: Error };

// todo think about cancel
export type SingleMutation<Payload> =
  | { status: 'idle'; mutate: (payload: Payload) => void }
  | { status: 'pending'; variables: Payload }
  | {
      status: 'success';
      variables: Payload;
      mutate: (payload: Payload) => void;
    }
  | {
      status: 'error';
      variables: Payload;
      error: Error;
      mutate: (payload: Payload) => void;
    };

export type UseMutation<MutationPayload> =
  () => SingleMutation<MutationPayload>;

export const isMutationInProgress = <M extends SingleMutationState>(
  mutation: M
): mutation is Extract<M, { status: 'pending' }> =>
  mutation.status === 'pending';

export const isMutationExecutable = <M extends SingleMutationState>(
  mutation: M
): mutation is Extract<M, { mutate: unknown }> => 'mutate' in mutation;

export const isMutationIdle = <M extends SingleMutationState>(
  mutation: M
): mutation is Extract<M, { status: 'idle' }> => mutation.status === 'idle';

export const isMutationSuccess = <M extends SingleMutationState>(
  mutation: M
): mutation is Extract<M, { status: 'success' }> =>
  mutation.status === 'success';

export const isMutationInError = <M extends SingleMutationState>(
  mutation: M
): mutation is Extract<M, { status: 'error' }> => mutation.status === 'error';

export const useDerivedMutation = <OldPayload, NewPayload>(
  mutation: SingleMutation<OldPayload>,
  derive: (oldPayload: NewPayload) => OldPayload
): SingleMutation<NewPayload> => {
  const lastPayload = useRef<NewPayload>();
  const derivedMutation = {
    ...mutation,
    mutate: payload => {
      lastPayload.current = payload;
      if (!('mutate' in mutation)) {
        throw new Error('Derived mutation not avaiable');
      }
      mutation.mutate(derive(payload));
    }
  };
  if (derivedMutation.status !== 'idle') {
    return {
      ...derivedMutation,
      variables: lastPayload.current!
    };
  }
  return derivedMutation;
};
