import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_ACTIONS } from 'store/integrations/integrations.constants';
import ApiClient from 'lib/api/ApiClient';
import { SendDocusignRequestDto } from 'dtos/send-docusign-request.dto';
import {IntegrationDto, ShotflowInstanceDto} from 'dtos/integration.dto';

const isV2 = false;
const token = true;
const client = new ApiClient() as any;

const getIntegrations = createAsyncThunk(
  INTEGRATION_ACTIONS.getIntegrations,
  async () => {
    const response = await client.get('/integrations', {
      data: null,
      isV2,
      token
    });
    return response.data;
  }
);

const configureIntegration = createAsyncThunk(
  INTEGRATION_ACTIONS.configureIntegration,
  async (data: IntegrationDto) => {
    const response = await client.post('/integrations', {
      data,
      isV2,
      token
    });
    return response.data;
  }
);

const sendDocuSignRequest = createAsyncThunk(
  INTEGRATION_ACTIONS.sendDocuSignRequest,
  async (data: SendDocusignRequestDto) => {
    return await client.post('/integrations/docusign/send_envelope', {
      data,
      isV2,
      token
    });
  }
);

const createShotflowInstance = createAsyncThunk(
    INTEGRATION_ACTIONS.createShotflowInstance,
    async (data: ShotflowInstanceDto) => await client.post('/integrations/shotflow', {
      data,
      isV2,
      token
    })
);

const updateShotflowInstance = createAsyncThunk(
    INTEGRATION_ACTIONS.updateShotflowInstance,
    async (data: ShotflowInstanceDto) => await client.put(`/integrations/shotflow/${data.id}`, {
      data,
      isV2,
      token
    })
);

const deleteShotflowInstance = createAsyncThunk(
    INTEGRATION_ACTIONS.deleteShotflowInstance,
    async (id: number) => await client.del(`/integrations/shotflow/${id}`, {
      isV2,
      token
    })
);

export {
    getIntegrations,
    configureIntegration,
    sendDocuSignRequest,
    createShotflowInstance,
    updateShotflowInstance,
    deleteShotflowInstance
};
