import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';

import './Grid.scss';

interface GridProps {
  gutters?: 'XS' | 'S' | 'M' | 'L';
  vgutters?: 'XS' | 'S' | 'M' | 'L';
  wrap?: boolean;
  align?: 'left' | 'right' | 'center';
  spread?: boolean;
  direction?: 'row' | 'column';
  cellPadding?: 'XS' | 'S' | 'M' | 'L';
  padEnds?: boolean;
  vcenter?: boolean;
  valign?: 'bottom' | 'center';
  className?: string;
  children?: ReactNode;
  collapseMobile?: boolean;
}

const Grid = ({
  gutters,
  vgutters,
  wrap,
  align,
  spread,
  direction = 'row',
  cellPadding,
  padEnds,
  vcenter,
  valign,
  className,
  children,
  collapseMobile,
  ...restProps
}: GridProps) => {
  // TODO: this is a quick fix to convert vcenter into valign without rolling out
  const verticalAlign = vcenter ? 'center' : valign;

  return (
    <div
      className={classnames(
        'Grid',
        {
          [`Grid_gutters-${gutters}`]: gutters,
          [`Grid_vgutters-${vgutters}`]: vgutters,
          [`Grid_cellPadding-${cellPadding}`]: cellPadding,
          Grid_padEnds: padEnds,
          [`Grid_${direction}`]: direction,
          [`Grid_v${verticalAlign}`]: verticalAlign,
          Grid_wrap: wrap,
          Grid_spread: spread,
          'Grid_align-C': align === 'center',
          'Grid_align-R': align === 'right',
          Grid_collapsed: collapseMobile
        },
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Grid;
