import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import './DateRangeInput.scss';

import { SingleDateInput } from 'v1/components/shared';

const DateRangeInput = ({
  className,
  appearance,
  size,
  hideSpacer,
  inputClassname,
  labels,
  disabled,
  dateFormat,
  startDate,
  endDate,
  children,
  onChange,
  minDate,
  maxDate,
  placeholder,
  placeholderStart,
  placeholderEnd,
  ...rest
}) => {
  const onInputChange = (type, value) => onChange(type, value.date);
  const setMinimumDate = moment(startDate).toDate();

  return (
    <div
      className={classnames([
        'DateRangeInput',
        `input-${appearance}`,
        { [`DateRangeInput-${size}`]: size },
        { 'DateRangeInput-shortDate': dateFormat.length <= 10 },
        { 'text-13-700': size === 'S' },
        { 'emulate-h3': size === 'M' },
        className
      ])}
    >
      <SingleDateInput
        label={labels ? labels.start : null}
        size={size}
        dateFormat={dateFormat}
        date={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={value => onInputChange('start', value)}
        selectsStart
        disabled={disabled}
        className={inputClassname}
        minDate={minDate}
        maxDate={maxDate}
        placeholder={placeholderStart || placeholder}
        {...rest}
      >
        {children}
      </SingleDateInput>
      {size !== 'XS' && (
        <div
          className={classnames({ [`DateRangeInput-spacer_${size}`]: size }, [
            'DateRangeInput-spacer'
          ])}
        ></div>
      )}
      <SingleDateInput
        label={labels ? labels.end : null}
        size={size}
        dateFormat={dateFormat}
        date={endDate}
        startDate={startDate}
        endDate={endDate}
        disabled={disabled}
        onChange={value => onInputChange('end', value)}
        selectsEnd
        className={inputClassname}
        minDate={setMinimumDate || minDate}
        maxDate={maxDate}
        placeholder={placeholderEnd || placeholder}
        {...rest}
      >
        {children}
      </SingleDateInput>
    </div>
  );
};

DateRangeInput.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'underline', 'silent']),
  size: PropTypes.oneOf(['XS', 'S', 'M']),
  labels: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }),
  disabled: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired, // E.g. 'MM-dd-yyyy'
  startDate: PropTypes.any, // TODO: type
  endDate: PropTypes.any, // TODO: type
  children: PropTypes.any,
  onChange: PropTypes.func
};

DateRangeInput.defaultProps = {
  appearance: 'outline',
  size: 'M',
  dateFormat: 'MM-dd-yyyy'
};

export default DateRangeInput;
