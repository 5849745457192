import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Account from 'lib/auth/Account';
import useEvent from 'v1/helpers/hooks/useEvent';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants';
import { sendBookingConfirmation } from 'store/v1/bookings/bookings.actions';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions';
import { Flyover } from 'v1/components/shared';

const BookingConfirmationMenuItem = ({ bookingId, closeFlyover }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const workspace = Account.resolveFirstWorkspace() || {};
  // TODO: selectors: this is duplicated between BookingActions & BookingSidebarDrawerHeader
  const bookings = useSelector(state => state.bookings);
  const resources = useSelector(state => state.contacts.data);
  const booking = get(bookings, ['data', bookingId]);
  const resourceId = get(booking, 'contact_id');
  const resource = get(resources, resourceId, {});

  const hasEmail =
    resource.emails &&
    resource.emails.length > 0 &&
    resource.emails.some(email => email.value_1);

  // TODO: duplicated
  const onConfirmSuccess = () => {
    dispatch(
      openModal('SuccessFeedbackModal', {
        title: `Booking confirmation sent!`,
        description: `You have successfully sent the booking confirmation email.`,
        actionLabel: 'Done',
        icon: '/images/icon_colour_send.svg'
      })
    );
  };

  // TODO: duplicated
  const onNoConfirmTemplate = () => {
    dispatch(
      openModal('AlertModal', {
        title: 'No booking confirmation template',
        description:
          'You cannot send a booking confirmation without a booking confirmation template. Please add a confirmation template to continue.',
        actionLabel: 'Go to confirmation template',
        onAction: () =>
          history.replace(
            `/app/${workspace.db_schema}/settings/booking_confirmation_template`
          )
      })
    );
  };

  // TODO: this doesn't run because the flyover is closed - moved to BookingActions etc but need to handle better
  useEvent([BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION], {
    onSuccess: onConfirmSuccess,
    onFailure: () => {
      get(bookings.error, 'status') === 400
        ? onNoConfirmTemplate()
        : dispatch(displayAlert('error', 'Failed to send booking update'));
    }
  });

  const onClick = () => {
    hasEmail
      ? dispatch(sendBookingConfirmation(bookingId))
      : dispatch(
          openModal('AlertModal', {
            title: 'This resource has no email address',
            description:
              'You cannot send a booking confirmation to a resource without an email address. Please add an email address to the resource to continue.'
          })
        );
    closeFlyover();
  };

  return (
    <Flyover.Item
      icon="/images/icon_send.svg"
      title="Send booking update"
      onClick={onClick}
    />
  );
};

BookingConfirmationMenuItem.propTypes = {
  bookingId: PropTypes.number.isRequired,
  closeFlyover: PropTypes.func.isRequired
};

export default BookingConfirmationMenuItem;
