import React, { useContext } from 'react';
import { get, omit } from 'lodash';

import TableContext from '../../TableContext';
import { Dropdown, MenuItem, SingleCheckbox } from 'v1/components/shared';

// TODO: this is a cleaned-up version of GenericSearch and needs migrating to the new Popover
const TableFilters = () => {
  const { _query, _setQuery, filterOptions } = useContext(TableContext);
  const filters = _query.filters || {};

  const isActive = option => get(filters, [option.field, 'id']) === option.id;

  const handleChange = option => {
    const updatedFilters = isActive(option)
      ? omit(filters, option.field)
      : {
          ...filters,
          [option.field]: {
            [option.condition]: option.value,
            type: option.type,
            id: option.id
          }
        };
    _setQuery(prev => ({ ...prev, filters: updatedFilters }));
  };

  return (
    <Dropdown
      buttonClass="btn btn-default btn-small"
      buttonIcon={<img src="/images/icon_shape.svg" alt="" />}
      buttonLabel="Filter"
    >
      {filterOptions.map(option => (
        <MenuItem key={option.id} onSelect={() => handleChange(option)}>
          <SingleCheckbox
            checked={isActive(option)}
            onChange={() => handleChange(option)}
          />
          <span className="DropdownButton-option-label">{option.label}</span>
        </MenuItem>
      ))}
    </Dropdown>
  );
};

TableFilters.propTypes = {};

export default TableFilters;
