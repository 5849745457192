import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

const prefix = '@@storageProvidersActions';

export const STORAGE_PROVIDER_ACTIONS = mapValues(
  mirror({
    CREATE_STORAGE_PROVIDER: null,
    CREATE_STORAGE_PROVIDER_SUCCESS: null,
    CREATE_STORAGE_PROVIDER_FAILURE: null,

    DISABLE_STORAGE_PROVIDER: null,
    DISABLE_STORAGE_PROVIDER_SUCCESS: null,
    DISABLE_STORAGE_PROVIDER_FAILURE: null,

    GET_STORAGE_PROVIDERS: null,
    GET_STORAGE_PROVIDERS_SUCCESS: null,
    GET_STORAGE_PROVIDERS_FAILURE: null
  }),
  value => `${prefix}/${value}`
);
