import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';

import Env from 'lib/env/Env';
import { Flyover, Grid, GridCell } from 'v1/components/shared';
import FlyoverContext from '../FlyoverContext';

import styles from './FlyoverHeader.module.scss';

const FlyoverHeader = ({
  icon,
  title,
  actionLabel,
  action,
  activeTab: currentTab,
  tabs
}) => {
  if (Env.isDev && ((actionLabel && !action) || (!actionLabel && action))) {
    console.error(
      'Invalid props on `FlyoverHeader`: `actionLabel` & `action` must be used together.'
    );
  }

  const { activeTab, setActiveTab } = useContext(FlyoverContext);

  useEffect(() => setActiveTab(currentTab || get(tabs, [0, 'value'])), [
    currentTab,
    tabs,
    setActiveTab
  ]);

  const renderActionTrigger = () => (
    <Grid gutters="S">
      <GridCell>{actionLabel}</GridCell>
      <GridCell width="auto">
        <img className="rotate-90" src="/images/icon_chevron.svg" alt="" />
      </GridCell>
    </Grid>
  );

  const renderAction = () => {
    return (
      <GridCell
        width="auto"
        className="text-13-600-eggplant-lighter clickable"
        onClick={typeof action === 'function' ? action : null}
      >
        {React.isValidElement(action) ? (
          <Flyover>
            <Flyover.Trigger>{renderActionTrigger()}</Flyover.Trigger>
            {action}
          </Flyover>
        ) : (
          renderActionTrigger()
        )}
      </GridCell>
    );
  };

  const renderStandard = () => (
    <Grid cellPadding="S" spread vcenter>
      <GridCell width="auto" className="text-11-700">
        <Grid gutters="S" valign="bottom">
          {icon && (
            <GridCell>
              <img width="15" src={icon} alt="" />
            </GridCell>
          )}
          <GridCell width="auto">{title}</GridCell>
        </Grid>
      </GridCell>
      {actionLabel && action && renderAction()}
    </Grid>
  );

  const renderTabs = () => (
    <Grid gutters="S">
      {tabs.map(tab => (
        <GridCell
          key={tab.value}
          width="auto"
          className={classnames('text-11-700 clickable', {
            [styles.Tab_active]: tab.value === activeTab
          })}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </GridCell>
      ))}
    </Grid>
  );

  return (
    <div className={classnames(styles.FlyoverHeader, { 'inset-S': tabs })}>
      {tabs ? renderTabs() : renderStandard()}
    </div>
  );
};

FlyoverHeader.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  actionLabel: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};

export default FlyoverHeader;
