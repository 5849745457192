import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { v4 } from 'uuid';

import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { EmptyGeneric } from 'v1/components/shared';
import NoteItem from '../NoteItem/NoteItem';

const NotesList = ({
  contentName,
  entityId,
  contentType,
  notes,
  events,
  deleteNote,
  displayAlert,
  disabled
}) => {
  const [event, setEvent] = useState(null);
  const status = get(events, event);

  useEffect(() => {
    if (!status) return;
    if (status === EVENT_ACTIONS.SUCCESS) {
      displayAlert('success', 'Updated');
    } else if (status !== EVENT_ACTIONS.PROCESSING) {
      displayAlert('error', status.message);
    }
  }, [status, displayAlert]);

  const handleDelete = id => {
    const newEvent = v4();
    setEvent(newEvent);
    deleteNote(id, entityId, newEvent);
  };

  return (
    <div className="Notes-body">
      {notes && notes.length ? (
        notes
          .filter(n => !n.archived)
          .map(note => (
            <NoteItem
              key={note.id}
              note={note}
              onDeleteNote={handleDelete}
              disabled={disabled}
            />
          ))
      ) : (
        <EmptyGeneric
          icon="/images/icon_colour_contact_notes.svg"
          iconWidth="50px"
          title="No Notes"
          description={`Write notes about ${
            contentName || 'this ' + contentType
          }`}
        />
      )}
    </div>
  );
};

NotesList.propTypes = {
  entityId: PropTypes.number,
  contentName: PropTypes.string,
  contentType: PropTypes.string,
  notes: PropTypes.array, // TODO: type - note
  events: PropTypes.object,
  handleDelete: PropTypes.func,
  displayAlert: PropTypes.func
};

export default NotesList;
