import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-form';
import classnames from 'classnames';
import {
  get,
  find,
  findIndex,
  flatten,
  isEmpty,
  isEqual,
  omit,
  orderBy
} from 'lodash';
import Env from 'lib/env/Env';
import { Fieldset, Legend, PressStud, Grid } from 'v1/components/shared';
import CoreFields from './FormBuilderCoreFields';
import CustomFields from './FormBuilderCustomFields';
import { useEffect } from 'react';
import { CustomFieldType } from '__types__';

const FormBuilder = ({
  data,
  fields,
  options,
  onChange,
  onSubmit,
  autoSave,
  children,
  disabled,
  getApi,
  shouldIgnoreRequired,
  ...props
}) => {
  let [form, setForm] = useState();
  // It thinks fields changes every time & I haven't worked out why
  // So let's store fields internally and lets do our own deep comparison
  let [_fields, setFields] = useState(fields);

  if (Env.isDev && isEmpty(fields))
    console.warn(
      'FormBuilder must be provided with `fields` metastructure array'
    );

  useEffect(() => {
    // Checking if fields actually changed with a deep comparison
    if (!isEqual(fields, _fields)) {
      setFields(fields);
    }
  }, [fields, _fields]);

  // REDUX
  const settings = useSelector(state => get(state, 'auth.settings', {}));
  const {
    custom_field_definitions = [],
    custom_fieldgroup_definitions = []
  } = settings;

  // DATA helpers
  function getFieldKeyFromNameString(name) {
    return name.split('.').pop();
  }
  function getCoreFieldByName(name) {
    return find(_fields, field => field.name === name);
  }
  function getCustomFieldById(id) {
    return find(custom_field_definitions, field => field.id === id);
  }
  function getCustomGroupById(id) {
    return find(
      custom_fieldgroup_definitions,
      fieldgroup => fieldgroup.id === id
    );
  }

  // PROPS
  const customFields = _fields
    .filter(field => field && field.active && field.type === 'CUSTOM_FIELD')
    .map(field => getCustomFieldById(field.custom_field_definition_id))
    .filter(field => field);
  const customFieldGroups = _fields
    .filter(
      field => field && field.active && field.type === 'CUSTOM_FIELD_GROUP'
    )
    .map(field => getCustomGroupById(field.custom_fieldgroup_definition_id))
    .filter(field => field);
  const customKey =
    options && options.customFieldsKey ? options.customFieldsKey : null;
  let defaultValues = { ...data };

  // Make sure object exists for custom nesting
  if (customKey) {
    defaultValues[customKey] = defaultValues[customKey] || {};
    defaultValues[customKey].custom_fields =
      defaultValues[customKey].custom_fields || [];
    defaultValues[customKey].custom_fieldgroups =
      defaultValues[customKey].custom_fieldgroups || [];
  }

  // Check each custom field has a value
  const defaultCustomValues = getCustomDefaultValues(customFields);

  // Update defaultValues to use the newly set values
  if (customKey) {
    defaultValues[customKey].custom_fields = defaultCustomValues;
  } else {
    defaultValues.custom_fields = defaultCustomValues;
  }

  // Check each custom fieldgroup has a value
  const defaultCustomFieldGroupValues = getCustomFieldGroupDefaultValues(
    customFieldGroups
  );

  // Update defaultValues to use the newly set values
  if (customKey) {
    defaultValues[customKey].custom_fieldgroups = defaultCustomFieldGroupValues;
  } else {
    defaultValues.custom_fieldgroups = defaultCustomFieldGroupValues;
  }

  // Set ref source for custom fields
  const customFieldsSource = customKey
    ? defaultValues[customKey].custom_fields
    : get(defaultValues, 'custom_fields', []);
  /*const customFieldGroupsSource = customKey
    ? defaultValues[customKey].custom_fieldgroups
    : get(defaultValues, 'custom_fieldgroups', []);*/

  useEffect(() => {
    form && form.setAllValues(defaultValues);
  }, [data, _fields]);

  // FORM helpers

  function getCustomDefaultValues(customFields = []) {
    // Use existing custom field data from Array in place
    // Otherwise we'll create a record for it
    let source = customKey
      ? defaultValues[customKey].custom_fields
      : get(defaultValues, 'custom_fields', []);

    return customFields.map(
      field =>
        source.find(c => c.custom_field_definition_id === field.id) || {
          custom_field_definition_id: field.id
        }
    );
  }

  function getCustomFieldGroupDefaultValues(customFieldGroups = []) {
    // Use existing custom field data from Array in place
    // Otherwise we'll create a record for it
    let source = customKey
      ? defaultValues[customKey].custom_fieldgroups
      : get(defaultValues, 'custom_fieldgroups', []);

    return flatten(
      customFieldGroups.map(group => {
        const g = source.filter(
          c => c.custom_fieldgroup_definition_id === group.id
        ) || [
          {
            custom_fieldgroup_definition_id: group.id
            //order: 0
          }
        ];

        return g.map(item => ({
          ...item,
          custom_fields:
            item.custom_fields && item.custom_fields.length
              ? item.custom_fields
              : group.custom_field_definitions.map(field => ({
                  custom_field_definition_id: field.id
                }))
        }));
      })
    );
  }

  // RENDERERS — these functions could probably be broken down a little
  function renderCoreFieldByDefinition(field, options, formApi) {
    const { name, required, label } = field;
    const fieldKey = getFieldKeyFromNameString(name);
    const { className, width, ...restOptions } = options;

    const CoreField = get(CoreFields, fieldKey);
    if (!CoreField) {
      if (Env.isDev)
        console.warn(`FormBuilder has no CORE_FIELD "${fieldKey}" to render`);
      return null;
    }

    return (
      <div
        id={`FormBuilder-field-${fieldKey}`} // To give UI a unique hook for styling
        className={classnames(
          'FormBuilder-field',
          {
            [`GridCell GridCell_${width}`]: width
          },
          className
        )}
        key={name}
      >
        <Suspense fallback={<></>}>
          <CoreField
            name={name}
            required={required ? 'required' : null}
            autoSave={autoSave}
            formApi={formApi}
            disabled={disabled}
            label={label}
            {...restOptions}
          />
        </Suspense>
      </div>
    );
  }

  function renderCustomFieldByDefinition(field, options, formApi, fieldKey) {
    const { className = '', width = '1', ...restOptions } = options;
    // Custom fields are either rendered by field data with a referential
    // custom_field_definition_id (when rendered individually), or will come
    // through as complete field definitions when being rendered as part
    // of a custom_fieldgroup's custom_field_definitions array. For the
    // former, we do a lookup to get complete field data. The latter we
    // can just pass through as is. Fields as part of a group will already
    // include an id and all necessary field information.

    const isFromFieldGroup = field.id;
    const fieldData = isFromFieldGroup
      ? field
      : getCustomFieldById(field.custom_field_definition_id);

    if (!isFromFieldGroup) {
      const customFieldIndex = findIndex(
        customFieldsSource,
        c => c.custom_field_definition_id === field.custom_field_definition_id
      );

      fieldKey =
        customFieldIndex >= 0 ? `custom_fields[${customFieldIndex}]` : null; // Unknown indicies won't render
    }

    const customKeyString = customKey ? `${customKey}.` : '';
    fieldKey = customKeyString + fieldKey;

    if (!fieldData) return null;

    const {
      id,
      name,
      data_type,
      required,
      description,
      options: fieldOptions
    } = fieldData;

    // Conditionally pass additional props, based on specifc data_type needs
    let customTypeProps = {};
    if (data_type === 'BOOLEAN') {
      customTypeProps.description = description; // This doesn't exist yet, but could in future?
    }
    if (data_type === 'PERCENTAGE' || data_type === 'NUMBER') {
      customTypeProps.data_type = data_type;
    }
    if (
      [
        CustomFieldType.SINGLE_SELECT,
        CustomFieldType.MULTI_SELECT,
        CustomFieldType.NETSUITE_SUBCLASS
      ].indexOf(data_type) !== -1
    ) {
      customTypeProps.options = fieldOptions;
    }

    const CustomField = CustomFields[data_type];
    if (!CustomField) {
      if (Env.isDev)
        console.warn(
          `FormBuilder has no CUSTOM_FIELD "${data_type}" to render`
        );
      return null;
    }

    // TODO: check ids are unique, etc - check console output
    return (
      <div
        id={`FormBuilder-field-${id}`} // to give UI a unique hook for styling
        className={classnames(
          'FormBuilder-field_custom',
          {
            [`GridCell GridCell_${width}`]: width
          },
          'stack-S',
          className
        )}
        key={fieldKey}
      >
        <Suspense fallback={<></>}>
          <CustomField
            id={fieldKey}
            label={name}
            name={fieldKey}
            custom_field_definition_id={id}
            value={formApi.getValue(fieldKey) || {}}
            {...customTypeProps}
            required={required && !shouldIgnoreRequired ? 'required' : null}
            autoSave={autoSave}
            formApi={formApi}
            {...restOptions}
          />
        </Suspense>
      </div>
    );
  }

  function renderCustomGroupByDefinition(group, options, formApi) {
    const groupData = getCustomGroupById(group.custom_fieldgroup_definition_id);

    if (!groupData) return null;

    const groupFieldKey = customKey
      ? `${customKey}.custom_fieldgroups`
      : 'custom_fieldgroups';

    let instances = formApi.getValue(groupFieldKey).reduce((arr, g, i) => {
      if (
        g.custom_fieldgroup_definition_id ===
          group.custom_fieldgroup_definition_id &&
        !g.archived
      ) {
        return [...arr, i];
      }
      return arr;
    }, []);

    const { name, custom_field_definitions = [], is_array } = groupData;
    const customFieldDefinitionsSorted = orderBy(
      custom_field_definitions,
      'order'
    );

    const groupDef = customFieldGroups.find(
      g => g.id === group.custom_fieldgroup_definition_id
    );

    const createNewFieldgroup = () => ({
      custom_fieldgroup_definition_id: group.custom_fieldgroup_definition_id,
      custom_fields: groupDef.custom_field_definitions.map(field => ({
        custom_field_definition_id: field.id
      }))
      //order: lastInstance && lastInstance.order ? lastInstance.order + 1 : 1
    });

    if (!is_array && !instances.length) {
      formApi.setValue(groupFieldKey, [
        ...formApi.getValue(groupFieldKey),
        createNewFieldgroup()
      ]);
    }

    // TODO: sort out the order stuff
    const anotherOne = _ => {
      /*let lastInstance;
      if (instances.length) {
        const customKeyString = customKey ? `${customKey}.` : '';
        const groupKey =
          customKeyString +
          `custom_fieldgroups[${instances[instances.length - 1]}]`;
        lastInstance = formApi.getValue(groupKey);
      }*/

      formApi.setValue(groupFieldKey, [
        ...formApi.getValue(groupFieldKey),
        createNewFieldgroup()
      ]);
      if (autoSave && formApi.submitForm) {
        formApi.submitForm();
      }
    };

    // TODO: currently this doesn't mark the custom fields as archived, only the custom fieldgroup
    // TODO: weird flash here to do with a bunch of state stuff - needs fixing - looks bad but isn't bad
    const removeFieldGroup = index => {
      formApi.setValue(
        groupFieldKey,
        formApi.getValue(groupFieldKey).filter((g, i) => i !== index)
      );
      if (autoSave && formApi.submitForm) {
        formApi.submitForm();
      }
    };

    const renderFieldGroup = customFieldGroupIndex => (
      <Grid
        wrap
        className="FormBuilder-fieldGroupItem Parent_hoverListener"
        key={customFieldGroupIndex}
      >
        <>
          {customFieldDefinitionsSorted.map(field => {
            const customFieldIndex = formApi
              .getValue(groupFieldKey)
              [customFieldGroupIndex].custom_fields.findIndex(
                f => f.custom_field_definition_id === field.id
              );
            const fieldKey =
              customFieldGroupIndex >= 0
                ? `custom_fieldgroups[${customFieldGroupIndex}].custom_fields[${customFieldIndex}]`
                : null; // Unknown indicies won't render
            return renderCustomFieldByDefinition(
              field,
              options,
              formApi,
              fieldKey
            );
          })}

          <button
            className="genericLink danger Child_hoverListener"
            onClick={() => removeFieldGroup(customFieldGroupIndex)}
          >
            Delete
          </button>
        </>
      </Grid>
    );

    return (
      <div className="GridCell GridCell_1">
        <Fieldset className="form-fieldset Parent_hoverListener">
          <Legend className="form-legend">{name}</Legend>
          {instances.map(customFieldGroupIndex =>
            renderFieldGroup(customFieldGroupIndex)
          )}
          {is_array && (
            <div className="stack-S">
              <PressStud label={`Add ${name}`} size="S" action={anotherOne} />
            </div>
          )}
        </Fieldset>
      </div>
    );
  }

  // API
  function renderCoreField(name, options, formApi) {
    if (!name || typeof name !== 'string')
      return console.error(
        'renderCoreField() requires a `name` (String) argument'
      );
    const fieldKey = getFieldKeyFromNameString(name);
    const field = getCoreFieldByName(fieldKey);
    if (Env.isDev && !field)
      console.warn(`Could not find CORE_FIELD "${fieldKey}"`);
    if (!get(field, 'active')) return null;
    // Ensure field retains key as specified in `name` arg, not key from fields definition
    const fieldNamed = {
      ...field,
      name
    };
    return renderCoreFieldByDefinition(fieldNamed, options, formApi);
  }

  function renderCustomFields(formFields = _fields, options, formApi) {
    const customFields = formFields.filter(
      field =>
        (field.type === 'CUSTOM_FIELD' ||
          field.type === 'CUSTOM_FIELD_GROUP') &&
        field.active
    );

    if (isEmpty(customFields)) return null;
    return renderForm(customFields, options, formApi);
  }

  function renderForm(formFields = _fields, options, formApi) {
    const fieldRenderers = {
      CORE_FIELD: renderCoreFieldByDefinition,
      CUSTOM_FIELD: renderCustomFieldByDefinition,
      CUSTOM_FIELD_GROUP: renderCustomGroupByDefinition
    };
    return (
      <div className="Grid Grid_gutters-M Grid_wrap">
        {formFields
          .filter(field => field && field.active)
          .map(field => {
            const { type, name } = field;
            const fieldRenderer = fieldRenderers[type];

            // Bail out if no recognised field renderer
            if (!fieldRenderer) return <span />;

            // CORE_FIELD may have been provided field-specific override values.
            // Merge them and remove fieldOverrides prop before passing to renderCoreField.
            // Include width default, as renderForm renders fields in Grid.
            const { fieldOverrides, width = '1', ...restOptions } = options;
            const fieldOverride = get(fieldOverrides, name, {});
            const mergedOptions = { width, ...restOptions, ...fieldOverride };

            return fieldRenderer(
              field,
              omit(mergedOptions, 'fieldOverrides'),
              formApi
            );
          })}
      </div>
    );
  }

  return (
    <Form
      getApi={api => {
        getApi ? getApi(api) : setForm(api);
      }}
      defaultValues={defaultValues}
      onChange={formData => onChange && onChange(formData)}
      onSubmit={values => onSubmit && onSubmit(values)}
      validateOnSubmit
      {...props}
    >
      {/* Function as child gives us access to FormBuilder methods */}
      {formApi => {
        return (
          children &&
          typeof children === 'function' &&
          children({
            // Render all CORE_FIELDS, CUSTOM_FIELDS and CUSTOM_FIELD_GROUPS
            renderForm: (options = {}) => renderForm(_fields, options, formApi),
            // Render individual CORE_FIELD by name
            renderCoreField: (name, options = {}) =>
              renderCoreField(name, options, formApi),
            // Render all CUSTOM_FIELDS and CUSTOM_FIELD_GROUPS
            renderCustomFields: (options = {}) =>
              renderCustomFields(_fields, options, formApi),
            ...formApi
          })
        );
      }}
    </Form>
  );
};

FormBuilder.propTypes = {
  data: PropTypes.object.isRequired, // Data from thing, to seed form defaultProps
  fields: PropTypes.array.isRequired, // metastructure.fields Array from Core Structure Type
  onChange: PropTypes.func,
  onSubmit: PropTypes.func, // Submit handler for form, useful for calling API actions, etc.
  autoSave: PropTypes.bool, // Trigger form submit onChange/onBlur of all fields
  children: PropTypes.func
};

export default FormBuilder;
