import { QueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { eventSchemas } from 'v4/core/events.schema';
import { SocketEvent } from 'v4/core/useSocket';
import { resourceShortlistAssociationInternals } from 'v4/entities/associations/resource__shortlist/resource__shortlist.hooks';
import { resourceShortlistKeys } from 'v4/entities/associations/resource__shortlist/resource__shortlist.keys';
import { associationItemsSchema } from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { createSimpleListResponse } from 'v4/entities/common/common.crud.types';
import { parseWithDecoratedError } from 'v4/entities/common/parseWithDecoratedError';
import { shortlistInternals } from 'v4/entities/shortlist/shortlist.hooks';
import { z } from 'zod';

export function createResourceShortlistAssociationCacheReconciler(
  queryClient: QueryClient
) {
  const cache = queryClient.getQueryCache();

  const isMatchingQueryKey = (
    parentKey: readonly any[],
    childKey: readonly any[]
  ) => {
    const childKeyCut = childKey.slice(0, parentKey.length);
    return isEqual(parentKey, childKeyCut);
  };
  // or maybe we should not update the cache, but move all association-related mutations here?
  // we should normalize so blocks are in separate place in the cache
  // the association query is just returning set of ids

  cache.subscribe(event => {
    // now, subscribe to various queries updates, and populate association cache
    if (event.type === 'updated' || event.type === 'added') {
      if (
        isMatchingQueryKey(
          shortlistInternals.keys.details(),
          event.query.queryKey
        ) &&
        event.query.state.data !== undefined
      ) {
        const shortlistId = parseWithDecoratedError(
          z.object({ id: z.number().optional() }),
          event.query.state.data
        ).id;
        if (shortlistId === undefined) {
          return; // it's possible that shortlist is not authorized, so we don't have id
        }
        const result = associationItemsSchema.safeParse(event.query.state.data);
        if (result.success) {
          resourceShortlistAssociationInternals.setQueryData(
            queryClient,
            {
              shortlist_id: shortlistId
            },
            createSimpleListResponse(result.data)
          );
        } else {
          // console.log('Failed to parse shortlist', result.error);
        }
        // else noop - current API is very edge-cased and we don't want to handle all possible writes to the cache, like unauthorized shortlist
      }
    }
  });
}

export function processEventForResourceShortlistAssociations(
  event: SocketEvent,
  client: QueryClient
) {
  if (['SHORTLIST_UPDATED'].includes(event.type)) {
    // when new block is added, or removed - all queries should be invalidated, that are related to the shortlist or resource
    const typedEvent = eventSchemas.shortlist.updated.parse(event);

    // remove all unused association queries
    client.removeQueries({
      type: 'inactive',
      queryKey: resourceShortlistKeys.all()
    });

    // invalidate queries that we know can be affected by the update - shortlist
    resourceShortlistAssociationInternals.invalidateQueries(client, {
      shortlist_id: typedEvent.payload.shortlistId
    });

    // invalidate queries that we know can be affected by the update - resource
    // resourceShortlistAssociationInternals.invalidateQueries(client, {
    //   resource_id: typedEvent.payload.resourceId // unfortunately, we don't have resource id in the event
    // });
  }
}
