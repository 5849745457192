import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RegisterAccountDto } from 'dtos/register-account.dto';
import { REGISTER_ACTIONS } from './register.constants';
import ApiClient from 'lib/api/ApiClient';

const isV2 = true;

const client = new ApiClient() as any;

const registerAccount = createAsyncThunk(
  REGISTER_ACTIONS.REGISTER_ACCOUNT,
  async ({
    data,
    teamSchema,
    token
  }: {
    data: RegisterAccountDto;
    teamSchema: string;
    token: string;
  }) =>
    (await client.post(`/auth/teams/${teamSchema}/register`, {
      data,
      token,
      isV2
    })) as { data: string }
);

const deleteToken = createAction(REGISTER_ACTIONS.DELETE_TOKEN);

export { registerAccount, deleteToken };
