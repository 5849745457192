import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontFamily: 'Nunito',
    fontSize: '14pt',
  },
  section: {
    margin: 10,
    marginBottom: 0,
    padding: 20,
    paddingBottom: 0,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerAvatarContainer: {
    flex: 0,
    minWidth: 150,
    width: 150,
    height: 'auto',
    backgroundColor: '#edf3f6',
  },
  headerContentContainer: {
    paddingLeft: 20,
    flex: 1
  },
  heading: {
    fontSize: 30,
  },
  headerAvatarImage: {
    width: 150,
    height: 'auto',
  },
  headerAvatarImagePlaceholder: {
    width: 150,
    height: 150,
  },
  fieldItem: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#edf3f6',
  },
  fieldItemLabel: {
    flex: 1,
    paddingVertical: 5
  },
  fieldItemValue: {
    flex: 2,
    padding: 5
  },
  galleryContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  galleryItem: {
    width: '33%',
    marginRight: 5,
    marginBottom: 10,
  },
  galleryImage: {
    width: '100%',
  }
});

export default styles;