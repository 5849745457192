import React from 'react';
import {
  Button,
  Field,
  Form,
  Grid,
  GridCell,
  Heading,
  Panel,
  UserEmailInput,
  UserPasswordInput,
  ConfirmPasswordField
} from 'shared';

const RegisterUserPanel = ({ onSubmit, invitedEmail, isLoading }) => {
  const handleSubmit = data => {
    onSubmit && onSubmit(data);
  };

  const values = {
    email: invitedEmail,
    password: '',
    confirmedPassword: ''
  };

  return (
    <Panel>
      <Form values={values} onSubmit={handleSubmit}>
        <Grid direction="column">
          <GridCell>
            <Heading color="black" type="h2" stack="m">
              Create Account
            </Heading>
            <div className="stack-m">
              <Field
                label="Email"
                name="email"
                description="Email you were invited on"
                size="l"
              >
                <UserEmailInput isDisabled />
              </Field>
            </div>
            <div className="stack-m">
              <Field label="Enter a password" name="password" size="l">
                <UserPasswordInput placeholder="Make it secure" />
              </Field>
            </div>
            <div className="stack-m">
              <ConfirmPasswordField
                label="Confirm your password"
                name="confirmedPassword"
                size="l"
                placeholder="Enter password"
              />
            </div>
          </GridCell>
          <Grid align="right">
            <GridCell>
              <Button
                label="Continue"
                appearance="primary"
                action="submit"
                isLoading={isLoading}
              ></Button>
            </GridCell>
          </Grid>
        </Grid>
      </Form>
    </Panel>
  );
};

export default RegisterUserPanel;
