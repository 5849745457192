import React, { ReactNode } from 'react';
import { Menu, MenuList, MenuButton } from '@reach/menu-button';
import '@reach/menu-button/styles.css';

import classnames from 'classnames';
import './Dropdown.scss';

interface DropdownProps {
  buttonLabel: string;
  buttonIcon?: ReactNode;
  buttonExpandIcon?: ReactNode;
  children: ReactNode;
  className?: string;
  buttonClass?: string;
  buttonStyle?: React.CSSProperties;
  buttonContent?: ReactNode;
  listPadding?: '0' | 'XS' | 'S' | 'M' | 'L'; // Change to your specific types
  listExpand?: boolean;
  disabled?: boolean;
}

export const Dropdown = ({
  buttonLabel,
  buttonIcon,
  buttonExpandIcon,
  children,
  className,
  buttonClass,
  buttonStyle,
  buttonContent,
  listPadding,
  listExpand,
  disabled
}: DropdownProps) => {
  if (disabled) {
    return (
      <Menu className={classnames(['Dropdown'])}>
        <div className={classnames([buttonClass, 'inline'])}>{buttonLabel}</div>
      </Menu>
    );
  }
  return (
    <Menu className={classnames(['Dropdown', className])}>
      <MenuButton
        data-cy="dropdown-button"
        className={classnames([buttonClass, 'inline'])}
        style={buttonStyle || {}}
        onClick={event => event.stopPropagation()}
        title={buttonLabel}
      >
        {buttonIcon && <span className="MenuButton-icon">{buttonIcon}</span>}
        {buttonLabel && <span className="MenuButton-label">{buttonLabel}</span>}
        {buttonContent && !buttonIcon && !buttonLabel ? buttonContent : null}
        {buttonExpandIcon && (
          <img
            src="/images/icon_arrow_down_gray.svg"
            className="MenuButton-expand-icon"
            alt=""
            width="12px"
          />
        )}
      </MenuButton>
      <MenuList
        className={classnames(
          {
            [`MenuList_padding-${listPadding}`]: listPadding,
            MenuList_expand: listExpand
          },
          ['Dropdown-linksContainer', 'scale-in-center']
        )}
        data-cy="dropdown-list"
        onClick={event => event.stopPropagation()}
      >
        {children}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
