import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CurrencyInput,
  PressStud,
  Label,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { updateProduction } from 'store/v1/productions/productions.actions.js';

import { Form } from 'react-form';
import { get } from 'lodash';

export const BudgetSettingsModal = ({ onRequestClose }) => {
  let getFormApi = {};
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  const productions = useSelector(state => state.productions);
  const production = get(ui, ['data', 'production']);

  useEvent([PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Budget settings updated'));
      onRequestClose();
    },
    onFailure: () => {
      dispatch(displayAlert('error', 'Update Failed'));
    }
  });
  function handleSubmit(values) {
    const production_id = get(production, 'id');

    dispatch(updateProduction(production_id, values));
  }
  return (
    <ModalWrapper size="S">
      <ModalHeader title="Manage budget" />
      <ModalContent>
        <ModalScrollable padding="L">
          <Form
            onSubmit={handleSubmit}
            defaultValues={production}
            getApi={api => (getFormApi = api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <Label>Production budget</Label>
                <CurrencyInput
                  placeholder="Enter a budget"
                  value={{
                    currency: formApi.values.budget_currency,
                    amount: formApi.values.budget_amount
                  }}
                  onBlur={value => {
                    formApi.setValue('budget_currency', value.currency);
                    formApi.setValue('budget_amount', value.amount);
                  }}
                />
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            hasMarginRight
            action={onRequestClose}
          />
          <PressStud
            label="Save"
            appearance="primary"
            isLoading={productions.loading === 'UPDATE_PRODUCTION'}
            action={() => getFormApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default BudgetSettingsModal;
