import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';

import TableContext from './TableContext';
import TableCell from './TableCell';
import styles from './Table.module.scss';

const TableRow = ({ parent, row }) => {
  const { cellPadding, prepareRow, ExpanderComponent } = useContext(
    TableContext
  );

  prepareRow(row);

  const { key, role, ...props } = row.getRowProps();

  return (
    <div
      key={key}
      role={role}
      data-id={get(row, 'original.id')}
      className={classnames(styles.Row, {
        [styles.no_padding]: cellPadding === '0' || cellPadding === 0,
        [styles.S]: cellPadding === 'S',
        [styles.L]: cellPadding === 'L'
      })}
    >
      <div {...props}>
        {row.cells.map(cell => (
          <TableCell
            key={`${row.id}-${cell.column.id}`}
            parent={parent}
            cell={cell}
          />
        ))}
      </div>
      {row.isExpanded && ExpanderComponent && ExpanderComponent(row.original)}
    </div>
  );
};

TableRow.propTypes = {
  parent: PropTypes.object,
  row: PropTypes.object
};

export default TableRow;
