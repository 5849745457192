import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, chain } from 'lodash';

import { orderReducer } from 'v1/helpers/misc';
import { formatRateSummary } from 'v1/helpers/budgetHelper';
import { getFormattedEventDates } from 'v1/helpers/byModel/EventHelper';

import {
  EventDateRange,
  Flyover,
  Grid,
  GridCell,
  RateInput
} from 'v1/components/shared';

const BookResourceFlyoverBookingContents = ({
  slot,
  event,
  setEvent,
  rate,
  setRate,
  openCreateBooking
}) => {
  const productions = useSelector(state => state.productions.data);
  const production = get(productions, [slot.production_id], {});
  const bookingTypes = useSelector(state => state.booking_types);
  const bookingTypesList = chain(orderReducer(bookingTypes))
    .filter(b => !b.archived)
    .orderBy(['order', 'asc'])
    .value();
  const bookingType = get(bookingTypesList, ['0'], null);

  useEffect(
    () =>
      setEvent({
        ...production.production_date,
        include_weekends: bookingType?.include_weekends_default // Grab system booking default and inlude weekend
      }),
    [production.production_date, setEvent]
  );

  return (
    <>
      <Flyover.Accordion
        icon="/images/icon_calendar_eggplant_lightest.svg"
        title="Dates"
        description={event && getFormattedEventDates(event)}
      >
        <div className="stack-XS"></div>
        <EventDateRange
          appearance="outline"
          size="XS"
          data={event}
          allowEmpty={false}
          eventType="BOOKING"
          onChange={setEvent}
        />
      </Flyover.Accordion>

      <Flyover.Accordion
        icon="/images/icon_rate.svg"
        title="Rate"
        description={formatRateSummary(rate, production.budget_currency, false)}
      >
        <div
          style={{ marginTop: '10px' }} // TODO
        >
          <RateInput
            value={{
              currency: rate.currency,
              amount: rate.amount,
              interval: rate.interval
            }}
            defaultCurrency={production.budget_currency}
            onBlur={setRate}
          />
        </div>
      </Flyover.Accordion>

      {/* TODO: is this abstractable ? */}
      <Grid
        className="clickable"
        cellPadding="S"
        spread
        onClick={openCreateBooking}
      >
        <GridCell className="text-13-600-eggplant-lighter" width="auto">
          Open full booking
        </GridCell>
        <GridCell width="auto">
          <img
            src="/images/icon_expand_arrows.svg"
            alt=""
            style={{ marginTop: '-4px', marginRight: '3px' }}
          />
        </GridCell>
      </Grid>
    </>
  );
};

BookResourceFlyoverBookingContents.propTypes = {
  slot: PropTypes.object,
  event: PropTypes.object,
  setEvent: PropTypes.func,
  rate: PropTypes.object,
  setRate: PropTypes.func,
  openCreateBooking: PropTypes.func
};

export default BookResourceFlyoverBookingContents;
