import React, { useState } from 'react';
// TODO: convert to nanoid as soon as issue is resolved: https://github.com/ai/nanoid/issues/205
import { v4 } from 'uuid';

import { Portal } from 'shared';
import ModalContext from './ModalContext';

import styles from './ModalProvider.module.scss';

type ModalProviderProps = {
  children: React.ReactNode;
};

type Modal = {
  id: UniqueId;
  Component: React.FC;
  customCancel?(): void;
  customClose?(): void;
};

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modals, setModals] = useState<Modal[]>([]);

  const closeModal = (): void => {
    setModals(prev => {
      // The top modal is always the active one of course
      const activeModal = modals[modals.length - 1];
      activeModal.customClose && activeModal.customClose();
      return prev.slice(0, prev.length - 1);
    });
  };

  const cancelModal = (): void => {
    // The top modal is always the active one of course
    const activeModal = modals[modals.length - 1];
    activeModal.customCancel && activeModal.customCancel();
    closeModal();
  };

  const closeAllModals = (): void => {
    setModals(prev => {
      [...prev]
        .reverse()
        .forEach(modal => modal.customClose && modal.customClose());
      return [];
    });
  };

  const cancelAllModals = (): void => {
    setModals(prev => {
      [...prev].reverse().forEach(modal => {
        modal.customCancel && modal.customCancel();
        modal.customClose && modal.customClose();
      });
      return [];
    });
  };

  const openModal = (Component: React.FC): void => {
    setModals(prev => [...prev, { id: v4(), Component }]);
  };

  const setModalProperty = (key: keyof Modal, property: any): void => {
    setModals(prev =>
      prev.map((modal, i) =>
        i === prev.length - 1 ? { ...modal, [key]: property } : modal
      )
    );
  };

  const setCustomClose = (customClose: () => void): void => {
    setModalProperty('customClose', customClose);
  };

  const setCustomCancel = (customCancel: () => void): void => {
    setModalProperty('customCancel', customCancel);
  };

  return (
    <ModalContext.Provider
      value={{
        setCustomClose,
        setCustomCancel,
        openModal,
        cancelModal,
        closeModal,
        cancelAllModals,
        closeAllModals
      }}
    >
      {children}
      {modals.length > 0 && (
        <>
          {modals.map(modal => (
            <Portal
              key={modal.id}
              freezePage
              background={styles.overlay}
              position={{ top: styles.top }}
              onClickOutside={cancelModal}
            >
              <modal.Component />
            </Portal>
          ))}
        </>
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
