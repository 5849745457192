import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserGroupDto } from 'dtos/user-group.dto';
import { SuccessDto } from 'dtos/success.dto';
import { CreateUserGroupDto } from 'dtos/create-user-group.dto';
import { UpdateUserGroupDto } from 'dtos/update-user-group.dto';
import { USER_GROUPS_ACTIONS } from './userGroups.constants';
import ApiClient from 'lib/api/ApiClient';
import { Capability } from '__types__';

const isV2 = true;
const token = true;
const client = new ApiClient() as any;

const getTenantUserGroups = createAsyncThunk(
  USER_GROUPS_ACTIONS.getTenantUserGroups,
  async () => {
    const { data } = await client.get('/auth/tenant/user-groups', {
      isV2,
      token
    });
    return data as UserGroupDto[];
  }
);

const getTeamUserGroups = createAsyncThunk(
  USER_GROUPS_ACTIONS.getTeamUserGroups,
  async () => {
    const { data } = await client.get('/auth/teams/:app_id:/user-groups', {
      isV2,
      token
    });
    return data as UserGroupDto[];
  }
);

const getUserGroup = createAsyncThunk(
  USER_GROUPS_ACTIONS.getUserGroup,
  async (id: string) => {
    const { data } = await client.get(`/auth/user-groups/${id}`, {
      isV2,
      token
    });
    return data as UserGroupDto;
  }
);

const createUserGroup = createAsyncThunk(
  USER_GROUPS_ACTIONS.createUserGroup,
  async (data: CreateUserGroupDto) => {
    const res = await client.post('/auth/user-groups', {
      data,
      isV2,
      token
    });
    return res.data as UserGroupDto;
  }
);

const updateUserGroup = createAsyncThunk(
  USER_GROUPS_ACTIONS.updateUserGroup,
  async (
    { id, data }: { id: string; data: UpdateUserGroupDto },
    { rejectWithValue }
  ) => {
    const res = (await client.patch(`/auth/user-groups/${id}`, {
      data,
      isV2,
      token
    })) as SuccessDto;
    return res.data === 'success' ? data : rejectWithValue(null);
  }
);

const archiveUserGroup = createAsyncThunk(
  USER_GROUPS_ACTIONS.archiveUserGroup,
  async (id: string) =>
    (await client.del(`/auth/user-groups/${id}`, {
      isV2,
      token
    })) as SuccessDto
);

const addCapabilityToUserGroup = createAsyncThunk(
  USER_GROUPS_ACTIONS.addCapabilityToUserGroup,
  async ({
    userGroupId,
    capability
  }: {
    userGroupId: string;
    capability: Capability;
  }) =>
    (await client.post(
      `/auth/user-groups/${userGroupId}/capabilities/${capability}`,
      {
        isV2,
        token
      }
    )) as SuccessDto
);

const removeCapabilityFromUserGroup = createAsyncThunk(
  USER_GROUPS_ACTIONS.removeCapabilityFromUserGroup,
  async ({
    userGroupId,
    capability
  }: {
    userGroupId: string;
    capability: Capability;
  }) =>
    (await client.del(
      `/auth/user-groups/${userGroupId}/capabilities/${capability}`,
      {
        isV2,
        token
      }
    )) as SuccessDto
);

export {
  getTenantUserGroups,
  getTeamUserGroups,
  getUserGroup,
  createUserGroup,
  updateUserGroup,
  archiveUserGroup,
  addCapabilityToUserGroup,
  removeCapabilityFromUserGroup
};
