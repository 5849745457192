import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Capability, FeatureFlag } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';
import {
  searchContacts,
  createContact
} from 'store/v1/contacts/contacts.actions.js';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Loading,
  EmptyGeneric,
  ContactItemCompact,
  ListCellGroup,
  ListCell,
  PressStud,
  TextInput
} from 'v1/components/shared';

import { createNameFromString } from 'v1/helpers/contactHelpers';

import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import classnames from 'classnames';
import { selectResourceTypeById } from 'store/v1/resource_types/resource_types.selectors';

export const ResourceSelector = ({
  query: initialQuery = {},
  inputStyle = {},
  className,
  onSelect,
  disabled,
  initialValue,
  allowCreateNew,
  onBlur
}) => {
  const dispatch = useDispatch();

  // STORE
  const contacts = useSelector(state => state.contacts);

  // STATE
  const [query, setQuery] = useState(initialQuery);
  const [value, setValue] = useState(initialValue);
  const resourceTypeId = get(query, 'filters.resource_type_id.eq.0');
  const resourceType = useSelector(state =>
    selectResourceTypeById(state, resourceTypeId)
  );

  useEffect(() => {
    onSearch(query);
  }, [query]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onSearch = useCallback(debounce(onSearchContacts, 300), []);

  function onCreateResource() {
    const fullName = get(query, 'query');
    let first_name = fullName,
      last_name;

    // Only split name for people, not organisations
    if (
      ['ORGANIZATION', 'LOCATION', 'ITEM', 'AGENCY'].indexOf(
        resourceType.model
      ) === -1
    ) {
      const names = createNameFromString(fullName);
      first_name = names.first_name;
      last_name = names.last_name;
    }

    const toSubmit = {
      first_name,
      last_name,
      resource_type_id: resourceTypeId // TODO: We need to handle cases where there are multiple resource_types with the same model.
    };

    // TODO: should default to getDefaultResourceType

    dispatch(createContact(toSubmit, null, 'POPOVER_RESOURCE_RESULTS'));
  }

  function onSelectResource(resource) {
    setValue(resource);
    if (typeof onSelect === 'function') {
      onSelect(resource);
    }
  }

  function onSearchContacts(newQuery) {
    dispatch(searchContacts(newQuery, null, null, 'POPOVER_RESOURCE_RESULTS'));
  }

  function handleBlur(field, value) {
    onBlur && onBlur(field, value);
  }

  function onQueryChange(event) {
    setQuery(query => {
      return {
        ...query,
        query: get(event, 'target.value')
      };
    });
  }

  /**
   * handleClearInput will clear the assigned relationship
   */
  const handleClearInput = e => {
    e.stopPropagation();
    onBlur && onBlur(null);
  };

  function onResetQuery() {
    onSearch(query);
  }

  /**
   * Returns the name of the contact or an empty string if it could not be resolved
   * @param {Object|number} contact Contact object or contact ID
   * @return {string}
   */
  function resolveResourceName(contact) {
    if (typeof contact === 'string') {
      // TODO: Remove support for string value
      // This is to support the way this component used to work. However, it does not make sense to pass the resource
      // name as the value for this component. This is because the component fetches contacts and then manages the
      // value state itself. The parent component only manages the initial value...
      return contact;
    }
    if (typeof contact === 'number') {
      return get(contacts, ['data', contact, 'full_name'], '');
    }
    return get(contact, 'full_name', '');
  }

  const resolveResource = resourceID => {
    return get(contacts, ['data', resourceID], null);
  };

  // TODO: Break out into cleaner component
  function renderInput() {
    const resource = resolveResource(value);
    return resource ? (
      <ContactItemCompact
        className="stack-XS"
        hideResourceMeta
        showResourceContact
        contact={resource}
        previewMethod="SIDEBAR"
        onClear={handleClearInput}
      />
    ) : (
      <TextInput
        id="ResourceSelector-input"
        disabled={disabled}
        value={resolveResourceName(value)}
        onBlur={handleBlur}
        onFocus={onResetQuery}
        onChange={onQueryChange}
        highlightOnFocus
        autoComplete="off"
        {...inputStyle}
      />
    );
  }

  function renderResults(closePopover) {
    const resultIds = get(
      contacts,
      ['local_store', 'POPOVER_RESOURCE_RESULTS'],
      []
    );
    const resource_filteredList = map(resultIds, id =>
      get(contacts, ['data', id])
    );

    if (contacts.loading === 'POPOVER_RESOURCE_RESULTS') {
      return <Loading />;
    }

    if (!isEmpty(resource_filteredList)) {
      return (
        <>
          <ListCellGroup className="stack-XS">
            {map(resource_filteredList, (contact, index) => (
              <ListCell key={contact.id}>
                <ContactItemCompact
                  key={`result-${index}`}
                  className="ResourceSelector-results-item"
                  contact={contact}
                  size="small"
                  newWindow
                  action={{
                    label: 'Select',
                    className: 'btn btn-primary btn-small',
                    onClick: () => {
                      onSelectResource(contact);
                      return closePopover();
                    }
                  }}
                />
              </ListCell>
            ))}
          </ListCellGroup>
        </>
      );
    } else {
      return (
        <EmptyGeneric
          icon="/images/icon_colour_search.svg"
          title={
            !isEmpty(query.query)
              ? 'No resources found'
              : 'Enter a name to search'
          }
        />
      );
    }
  }

  return (
    <Popover className={classnames(['ResourceSelector', className])}>
      <PopoverTrigger>{renderInput()}</PopoverTrigger>
      <PopoverContent>
        {({ closePopover }) => (
          <div>
            {renderResults(closePopover)}
            {get(query, 'query') && allowCreateNew && (
              <RestrictedAccess
                flag={FeatureFlag.RESOURCES}
                capabilities={Capability.RESOURCE_CREATE}
              >
                <PressStud
                  label={`Add new resource "${query.query}" to ${resourceType.name}`}
                  size="full"
                  action={onCreateResource}
                />
              </RestrictedAccess>
            )}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

ResourceSelector.propTypes = {
  query: PropTypes.object,
  inputStyle: PropTypes.object,
  className: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  onSelect: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  allowCreateNew: PropTypes.bool
};

export default ResourceSelector;
