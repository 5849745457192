import classNames from 'classnames';
import { HTMLProps } from 'react';

export function DimmedContainer({
  className,
  shouldDim,
  ...rest
}: { shouldDim: boolean } & HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'tw-transition tw-duration-500 tw-ease-out',
        {
          'tw-blur-[1px] tw-opacity-80 tw-ease-in': shouldDim
        },
        className
      )}
      {...rest}
    />
  );
}
