import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PressStud,
  ModalContent,
  ModalScrollable,
  ModalWrapper,
  Grid,
  GridCell,
  SystemLink
} from 'v1/components/shared';

import _get from 'lodash/get';
import {
  createBooking,
  updateBooking
} from 'store/v1/bookings/bookings.actions';
import { get } from 'lodash';
import ModalNavigation from 'v1/components/modals/layout/ModalNavigation/ModalNavigation';

/*

INSTRUCTIONS FOR USE
====

As with other modals, pass in an object as the second parameter of openModal to supply the data.
eg: openModal('BookingConflictModal', { method })

ALLOWED PROPERTIES:
⭐️ method 'CREATE' OR 'UPDATE' - state whether the action was a 'CREATE' or an 'UPDATE'
⭐️ resourceId int - The id of the resource to create a booking for. This will provide a link for the user to navigate to that resources calendar.

*/

const BookingConflictModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  const resourceId = _get(ui, 'data.resourceId', '');
  const error = useSelector(state => state.bookings.error);
  const booking = get(error, 'booking', null);

  const handleForceCreate = () => {
    if (booking) {
      const newBooking = {
        ...booking,
        force: true
      };
      const action = booking.id
        ? updateBooking(booking.id, newBooking)
        : createBooking(newBooking);
      dispatch(action);
    }
    onRequestClose();
  };

  return (
    booking && (
      <ModalWrapper size="XS">
        <ModalContent>
          <ModalScrollable padding="L">
            <img
              src="/images/icon_colour_event_conflict.svg"
              width="45px"
              alt=""
            />
            <h2>Booking conflict</h2>
            <h3 className="stack-L">
              We have found an existing booking that overlaps the one you are
              trying to {booking.id ? 'update' : 'create'}. You can continue to{' '}
              {booking.id ? 'update' : 'create'} this booking or view the
              conflicting booking within the resource calendar.
            </h3>
          </ModalScrollable>
          <ModalNavigation>
            <SystemLink
              to={
                resourceId ? `/contacts/${resourceId}` : '/calendar/resources'
              }
              className="btn btn-default"
              target="_blank"
            >
              View resource calendar
            </SystemLink>
            <PressStud
              label={`${booking.id ? 'Update' : 'Create'} booking anyway`}
              appearance="secondary"
              action={handleForceCreate}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    )
  );
};

export default BookingConflictModal;
