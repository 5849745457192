import { RESOURCE_TYPES_ACTIONS } from 'store/v1/resource_types/resource_types.constants.js';
import { PRODUCTION_TYPES_ACTIONS } from 'store/v1/production_types/production_types.constants.js';
import { BOOKING_TYPES_ACTIONS } from 'store/v1/booking_types/booking_types.constants.js';
import { EXPENSE_TYPES_ACTIONS } from 'store/v1/expense_types/expense_types.constants.js';

import { updateResourceType } from 'store/v1/resource_types/resource_types.actions.js';
import { updateProductionType } from 'store/v1/production_types/production_types.actions.js';
import { updateBookingType } from 'store/v1/booking_types/booking_types.actions.js';
import { updateExpenseType } from 'store/v1/expense_types/expense_types.actions.js';
import { FeatureFlag } from '__types__';

// The Redux-related props here are a bit of a workaround
// given each core structure needs its own specific actions, event
// types, etc. This allows us to keep views (e.g. FieldsStructureType)
// generic, given the layout functionality is identical no matter
// which core structure you're viewing/updating. The alternative
// would be to look at duplicating core structure type-specific
// views, which seems a shame.

export const CORE_STRUCTURES = {
  resource_types: {
    flag: FeatureFlag.RESOURCES,
    name: 'Resources',
    slug: 'resource_types',
    updateAction: updateResourceType,
    UPDATE_EVENT: RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE
  },
  production_types: {
    flag: FeatureFlag.PRODUCTIONS,
    name: 'Productions',
    slug: 'production_types',
    updateAction: updateProductionType,
    UPDATE_EVENT: PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE
  },
  booking_types: {
    flag: FeatureFlag.BOOKINGS,
    name: 'Bookings',
    slug: 'booking_types',
    updateAction: updateBookingType,
    UPDATE_EVENT: BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE
  },
  expense_types: {
    name: 'Expenses',
    slug: 'expense_types',
    updateAction: updateExpenseType,
    UPDATE_EVENT: EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE
  }
};
