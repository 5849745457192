import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import Text from 'react-format-text';
import { isEmpty, filter, get, orderBy } from 'lodash';
import ShortlistContactItemGrid from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/ShortlistContactItemGrid/ShortlistContactItemGrid';
import PublicShortlistContactItem from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/PublicShortlistContactItem/PublicShortlistContactItem';
import ShortlistFeedbackView from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/ShortlistFeedbackView/ShortlistFeedbackView';
import ShortlistToolbar from '../../ShortlistPresentView/ShortlistToolbar/ShortlistToolbar';
import { EmptyGeneric } from 'v1/components/shared';
import { MasonryVirtualized, ListVirtualized } from 'v1/components/shared';
import { openModal } from 'store/v1/ui/ui.actions.js';
import { searchShortlistBlocks } from 'store/v1/shortlists/shortlists.actions.js';
import AuthService from 'lib/auth/AuthService';
import Account from 'lib/auth/Account';
import { SHORTLIST_RESOURCE_MAX_COUNT } from 'v1/containers/Routes/Shortlist/Shortlist';
import './PublicShortlistPresentView.scss';
import ShortlistContactItemCompact from '../../ShortlistPresentView/ShortlistContactItemCompact/ShortlistContactItemCompact';

// TODO: class to Function component

export class PublicShortlistPresentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: this.props.shortlist.default_resources_query || {
        filters: {}
      }
    };
  }

  componentDidMount() {
    // When shortlist exists rebuild tooltip
    ReactTooltip.rebuild();

    this.props.shortlist.default_resources_query &&
      this.props.searchShortlistBlocks(this.props.shortlist.id, {
        ...this.props.shortlist.default_resources_query,
        count: SHORTLIST_RESOURCE_MAX_COUNT
      });
  }

  filterData = () => {
    const activeBlocks = filter(
      this.props.shortlist.shortlist_blocks,
      b => !b.archived
    );
    const querySort = get(this.state.query, ['order_by', 'field']);
    const useUserOrder =
      !querySort ||
      querySort === 'order' ||
      !this.props.shortlists.blocks_order;

    const filtered = this.props.shortlists.blocks_order
      ? this.props.shortlists.blocks_order.reduce((acc, id) => {
          const block = activeBlocks.find(b => b.contact_id === id);
          return block ? [...acc, block] : acc;
        }, [])
      : activeBlocks;

    if (querySort === 'upvotes') {
      return filtered.sort((a, b) => b.upvotes?.length - a.upvotes?.length);
    }
    if (useUserOrder) {
      return orderBy(filtered, ['order', 'asc']);
    }
    return filtered;
  };

  onQueryChange = query => {
    this.setState({ query });
    this.props.searchShortlistBlocks(this.props.shortlist.id, {
      ...query,
      count: SHORTLIST_RESOURCE_MAX_COUNT
    });
  };

  renderHeader = shortlist => {
    const { public_title, public_description, title } = shortlist;
    const logo =
      get(this.props.auth, 'settings.settings.public_brand_image.value') ||
      get(this.props.auth, 'settings.settings.public_brand_image');
    const companyName =
      get(this.props.auth, 'settings.settings.public_company_name.value') ||
      get(this.props.auth, 'settings.settings.public_company_name');
    return (
      <div className="PublicShortlistPresentView-header">
        {logo ? (
          <span className="PublicShortlistPresentView-header-companyLogo">
            <img src={logo} alt="" />
          </span>
        ) : null}
        <br />
        <h2>{public_title || title}</h2>
        {companyName && (
          <span className="PublicShortlistPresentView-companyName">
            Shortlist by {companyName}
          </span>
        )}
        {public_description ? (
          <h3 className="PublicShortlistPresentView-header-description">
            <Text>{public_description}</Text>
          </h3>
        ) : null}
      </div>
    );
  };

  openBlockModal = (shortlist, block, sortedBlocks) => {
    return () => {
      this.props.openModal('MediaFocusModal', {
        mediaType: 'SHORTLIST_BLOCKS',
        mediaSelected: block,
        mediaList: sortedBlocks,
        mediaSidebar: (selected, navigateBlock) => {
          return (
            <ShortlistFeedbackView
              block={selected}
              shortlist={shortlist}
              navigateBlock={navigateBlock}
              shortlist_field_mapper={this.props.shortlist_field_mapper}
            />
          );
        },
        emptyGalleryComponent: selected => {
          return (
            <div className="ShortlistEmptyGallery">
              <h5>Empty Gallery</h5>
            </div>
          );
        }
      });
    };
  };

  renderBlocks = shortlist => {
    const {
      shortlist_blocks,
      default_fields,
      enable_voting,
      enable_commenting,
      layout,
      public_feedback
    } = shortlist;
    const sortedBlocks = this.filterData(shortlist_blocks);
    const isGuest = !AuthService.isAuthenticated();
    const user = isGuest ? this.props.user : Account.account();

    const listView =
      sortedBlocks &&
      sortedBlocks.map(block => {
        switch (layout) {
          case 'GRID':
            return (
              <ShortlistContactItemGrid
                key={block.id}
                user={user}
                isGuest={isGuest}
                block={block}
                default_fields={this.props.shortlist.default_fields}
                enableVoting={enable_voting}
                enableCommenting={enable_commenting}
                showFeedback={public_feedback}
                shortlist_field_mapper={this.props.shortlist_field_mapper}
                shortlist={shortlist}
                expandBlock={this.openBlockModal(
                  shortlist,
                  block,
                  sortedBlocks
                )}
              />
            );
          case 'COMPACT':
            return (
              <ShortlistContactItemCompact
                key={block.id}
                block={block}
                default_fields={this.props.shortlist.default_fields}
                shortlist_field_mapper={this.props.shortlist_field_mapper}
                shortlist={shortlist}
              />
            );
          default:
            return (
              <PublicShortlistContactItem
                block={block}
                default_fields={default_fields}
                shortlist_field_mapper={this.props.shortlist_field_mapper}
                user={user}
                isGuest={isGuest}
                key={block.id}
                enableVoting={enable_voting}
                enableCommenting={enable_commenting}
                shortlist={shortlist}
                showFeedback={public_feedback}
                expandBlock={this.openBlockModal(
                  shortlist,
                  block,
                  sortedBlocks
                )}
              />
            );
        }
      });
    if (!isEmpty(sortedBlocks)) {
      return layout === 'GRID' || layout === 'COMPACT' ? (
        <MasonryVirtualized
          className="ShortlistPresentView-list ShortlistPresentView-masonry"
          scrollElement={this.props.shortlistPresentView}
        >
          {listView}
        </MasonryVirtualized>
      ) : (
        <div className="ShortlistPresentView-list">
          <ListVirtualized scrollElement={this.props.shortlistPresentView}>
            {listView}
          </ListVirtualized>
        </div>
      );
    }
    return (
      <EmptyGeneric
        icon="/images/icon_colour_team.svg"
        title="No one shortlisted"
        description="Build your shortlist by selecting resources to present to stakeholders"
      />
    );
  };
  renderToolbar = () => (
    <div className="ShortlistPresentView-search">
      <ShortlistToolbar
        isPublic
        shortlist={this.props.shortlist}
        shortlistFieldMapper={this.props.shortlist_field_mapper}
        query={this.state.query}
        onSetQuery={this.onQueryChange}
      />
    </div>
  );
  renderGridView = shortlist => {
    return (
      <div className="PublicShortlistPresentView GRID">
        {this.renderHeader(shortlist)}
        {this.renderToolbar()}
        {this.renderBlocks(shortlist)}
      </div>
    );
  };
  render() {
    const { shortlist } = this.props;
    if (shortlist.layout === 'GRID' || shortlist.layout === 'COMPACT') {
      return this.renderGridView(shortlist);
    }
    return (
      <div className="PublicShortlistPresentView">
        {this.renderHeader(shortlist)}
        {this.renderToolbar()}
        {this.renderBlocks(shortlist)}
      </div>
    );
  }
}

function mapStateToProps({ auth, shortlists }) {
  return { auth, shortlists };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal, searchShortlistBlocks }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicShortlistPresentView);
