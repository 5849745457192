import { connect } from 'react-redux';
import { get } from 'lodash';

import Files from './Files.component';
import { displayAlert } from 'store/v1/ui/ui.actions.js';

const mapStateToProps = (state, ownProps) => {
  return {
    documents: get(state, [
      ownProps.entityType,
      'associations_data',
      ownProps.entityId,
      'documents'
    ]),
    events: state.events
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createDocument: (data, entityId, event) =>
      dispatch(ownProps.createDocument(data, entityId, event)),
    deleteDocument: (documentId, entityId, event) =>
      dispatch(ownProps.deleteDocument(documentId, entityId, event)),
    displayAlert: (type, data) => dispatch(displayAlert(type, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);
