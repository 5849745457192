import React from 'react';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import { Controller, useFormContext } from 'react-hook-form';
import { get, merge } from 'lodash';

import { Label } from 'shared';
import { FieldProps } from './FieldProps';
import FieldMessage from './FieldMessage/FieldMessage';

import styles from './Field.module.scss';

const Field = ({
  name,
  appearance = 'default',
  align = 'left',
  size = 'm',
  layout = 'stack',
  label,
  description,
  isRequired,
  rules,
  errors = {},
  children
}: FieldProps) => {
  const form = useFormContext();
  const id = nanoid();
  const labelSize = size === 'l' ? 'm' : 's';
  const labelLayout = layout === 'inline' ? 'vertical' : 'horizontal';
  const input = React.Children.only(children);

  const renderInput = () => {
    if (name && form) {
      return (
        <Controller
          name={name}
          rules={merge({ required: isRequired }, input.type.rules, rules)}
          render={({field:{ value, onChange }}) => {
            return React.cloneElement(input, {
              id,
              value,
              isValid: !form.formState.errors[name],
              onChange
            });
          }}
        />
      );
    }

    return React.cloneElement(React.Children.only(children), { id });
  };

  const renderErrors = () => {
    if (!name) {
      return null;
    }
    const fieldErrors = Object.keys(
      get(form, ['formState', 'errors', ...name.split('.'), 'types'], [])
    );
    const messages = { ...get(input, ['type', 'errors']), ...errors };

    return fieldErrors.map(error => {
      const message = get(
        messages,
        error,
        error === 'required'
          ? 'This field is required'
          : 'There is an error with this field'
      );
      return <FieldMessage key={error} message={message} />;
    });
  };

  return (
    <div
      className={classnames(styles.Field, styles[`Field_${size}`], {
        [styles.Field_compact]: appearance === 'compact',
        [styles.Field_inline]: layout === 'inline'
      })}
    >
      <div className={styles.Meta}>
        <Label
          appearance={appearance}
          size={labelSize}
          align={align}
          layout={labelLayout}
          htmlFor={id}
          label={label}
          isRequired={isRequired}
        >
          {description}
        </Label>
      </div>
      {renderInput()}
      {renderErrors()}
    </div>
  );
};

export default Field;
