import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Capability, FeatureFlag } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import * as entityTypes from 'v1/helpers/entityTypes';
import CORE_FIELDS from 'v1/helpers/consts/CORE_FIELDS';
import { sortFieldsAlphabetically } from 'v1/helpers/misc';
import { getBookingTypes } from 'store/v1/bookings/bookings.selectors.js';
import { getExpenseTypes } from 'store/v1/expenses/expenses.selectors.js';
import { getProductionTypes } from 'store/v1/productions/productions.selectors.js';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors.js';

import { FieldListSelection, Label } from 'v1/components/shared';

const CHECKIN_FIELDS = {
  key: 'checkins',
  name: 'Checkins',
  children: [
    { key: 'checkins.booking_date', name: 'Booking Date' },
    { key: 'checkins.checked_in_timestamp', name: 'Checked In' },
    { key: 'checkins.checked_out_timestamp', name: 'Checked Out' },
    { key: 'checkins.status', name: 'Status' },
    { key: 'checkins.badge_number', name: 'Badge Number' },
    { key: 'checkins.user_id', name: 'Checked In By' }
  ]
};

const getFieldEntityType = field => {
  const fieldSplit = field.key.split('.');
  const fieldKey = fieldSplit[fieldSplit.length - 1];
  switch (fieldKey) {
    case 'resources':
    case 'resource':
      return entityTypes.CONTACT;
    case 'productions':
    case 'production':
      return entityTypes.PRODUCTION;
    case 'bookings':
    case 'booking':
      return entityTypes.BOOKING;
    case 'expenses':
    case 'expense':
      return entityTypes.EXPENSE;
    default:
      return null;
  }
};

const getCustomFields = (
  fields,
  entityType,
  entityTypeType,
  types,
  customFieldDefinitions,
  customFieldgroupDefinitions
) => {
  let customFields = [];
  let customFieldgroups = [];

  if (entityType) {
    const entityTypeTypes = types[entityType] || [];
    const custom = entityTypeTypes.reduce(
      (acc, type) => {
        (entityTypeType ? type.id === entityTypeType : true) &&
          !type.archived &&
          type.metastructure &&
          type.metastructure.fields &&
          type.metastructure.fields.map(field => {
            if (field.active) {
              if (
                field.type === 'CUSTOM_FIELD' &&
                field.custom_field_definition_id
              ) {
                let def = customFieldDefinitions.find(
                  f => f.id === field.custom_field_definition_id
                );
                def &&
                  !def.archived &&
                  acc.custom_fields.add(field.custom_field_definition_id);
              }
              if (
                field.type === 'CUSTOM_FIELD_GROUP' &&
                field.custom_fieldgroup_definition_id
              ) {
                let def = customFieldgroupDefinitions.find(
                  f => f.id === field.custom_fieldgroup_definition_id
                );
                def &&
                  !def.archived &&
                  acc.custom_fieldgroups.add(
                    field.custom_fieldgroup_definition_id
                  );
              }
            }
            return acc;
          }, []);
        return acc;
      },
      { custom_fields: new Set(), custom_fieldgroups: new Set() }
    );
    customFields = Array.from(custom.custom_fields).map(e => {
      const customField = customFieldDefinitions.find(f => f.id === e);
      return { key: `custom_${customField.id}`, name: customField.name };
    });
    customFieldgroups = Array.from(custom.custom_fieldgroups).map(e => {
      const customFieldgroup = customFieldgroupDefinitions.find(
        f => f.id === e
      );
      return {
        key: `custom_fieldgroup_${customFieldgroup.id}`,
        name: customFieldgroup.name,
        children: customFieldgroup.custom_field_definitions.map(cf => ({
          key: `custom_fieldgroup_${customFieldgroup.id}.custom_${cf.id}`,
          name: cf.name
        }))
      };
    });
  }

  // Get the field lists for any nested entities
  const mappedFields = fields.map(field =>
    field.children
      ? {
          ...field,
          children: getCustomFields(
            field.children,
            getFieldEntityType(field),
            null,
            types,
            customFieldDefinitions,
            customFieldgroupDefinitions
          )
        }
      : field
  );
  return [...mappedFields, ...customFields, ...customFieldgroups];
};

const getAllFields = (
  fields,
  entityType,
  entityTypeType,
  types,
  customFieldDefinitions,
  customFieldgroupDefinitions,
  allowCheckins
) => {
  const checkinFields =
    allowCheckins && entityType === entityTypes.BOOKING ? [CHECKIN_FIELDS] : [];
  const allFields = [
    ...getCustomFields(
      fields,
      entityType,
      entityTypeType,
      types,
      customFieldDefinitions,
      customFieldgroupDefinitions
    ),
    ...checkinFields
  ];
  return sortFieldsAlphabetically(allFields);
};

const DataExportsFormSectionContentFields = ({ formApi }) => {
  const customFieldDefinitions = useSelector(
    state => state.auth.settings.custom_field_definitions
  );
  const customFieldgroupDefinitions = useSelector(
    state => state.auth.settings.custom_fieldgroup_definitions
  );
  const bookingTypes = useSelector(getBookingTypes);
  const expenseTypes = useSelector(getExpenseTypes);
  const productionTypes = useSelector(getProductionTypes);
  const resourceTypes = useSelector(getResourceTypes);
  const [fieldList, setFieldList] = useState([]);
  const entityType = formApi.getValue('entity_type');
  const [entityTypeType, setEntityTypeType] = useState(null);
  const bookingType = formApi.getValue('query.filters.booking_type_id.eq');
  const productionType = formApi.getValue(
    'query.filters.production_type_id.eq'
  );
  const resourceType = formApi.getValue('query.filters.resource_type_id.eq');

  const getEntityTypeType = useCallback(
    _ => {
      // prettier-ignore
      return entityType === entityTypes.PRODUCTION
      ? productionType
    : entityType === entityTypes.CONTACT
      ? resourceType
    : entityType === entityTypes.BOOKING
      ? bookingType
      : null;
    },
    [entityType, productionType, resourceType, bookingType]
  );

  const allowCheckins = useRestrictedAccess(
    FeatureFlag.NIKECUSTOM_FRONT_DESK,
    Capability.CONFIGURE_CUSTOM_NIKE_FRONT_DESK,
    undefined
  );

  const getFieldList = useCallback(
    _ =>
      getAllFields(
        [...CORE_FIELDS[entityType], ...CORE_FIELDS.global],
        entityType,
        entityTypeType,
        {
          [entityTypes.CONTACT]: resourceTypes,
          [entityTypes.PRODUCTION]: productionTypes,
          [entityTypes.BOOKING]: bookingTypes,
          [entityTypes.EXPENSE]: expenseTypes
        },
        customFieldDefinitions,
        customFieldgroupDefinitions,
        allowCheckins
      ),
    [
      entityType,
      entityTypeType,
      resourceTypes,
      productionTypes,
      bookingTypes,
      expenseTypes,
      customFieldDefinitions,
      customFieldgroupDefinitions,
      allowCheckins
    ]
  );

  useEffect(() => {
    setEntityTypeType(getEntityTypeType());
  }, [bookingType, productionType, resourceType, getEntityTypeType]);

  useEffect(() => {
    setFieldList(getFieldList());
  }, [entityType, entityTypeType, getFieldList]);

  return (
    <div className="stack-S">
      <Label htmlFor="fields">Fields</Label>
      <FieldListSelection
        fields={Array.from(fieldList)}
        defaultSelection={formApi.getValue('fields')}
        onChange={value => formApi.setValue('fields', value)}
      />
    </div>
  );
};

export default DataExportsFormSectionContentFields;
