type IdOrSlug = number | `:${string}`;

/**
 * Utility to generate routes within an app
 * @param appId
 * @param teamId for future use when we have multiple studios
 */
export const withinApp = (appId: string, teamId?: string) => {
  const to = {
    appRoot: () => `/app`,
    teamRoot: () => `${to.appRoot()}/${appId}`,
    dashboard: () => `${to.teamRoot()}/dashboard`,
    resources: {
      home: () => `${to.teamRoot()}/resources`,
      item: (resourceId: IdOrSlug = ':resourceId') =>
        `${to.resources.home()}/${resourceId}`
    },
    calendar: {
      home: () => `${to.teamRoot()}/calendar`,
      resources: {
        home: () => `${to.calendar.home()}/resources`
      },
      productions: {
        home: () => `${to.calendar.home()}/productions`
      }
    },
    callouts: {
      home: () => `${to.teamRoot()}/callouts`
    },
    callsheets: {
      home: () => `${to.teamRoot()}/callsheets`
    },
    shortlists: {
      home: () => `${to.teamRoot()}/shortlists`,
      detail: (shortlistId: IdOrSlug = ':shortlistId') => `${to.shortlists.home()}/${shortlistId}`
    },
    productions: {
      home: () => `${to.teamRoot()}/productions`
    },
    bookings: {
      home: () => `${to.teamRoot()}/bookings`
    },
    frontdesk: {
      home: () => `${to.teamRoot()}/frontdesk`
    },
    reconciliations: {
      home: () => `${to.teamRoot()}/reconciliations`
    },
    reporting: {
      home: () => `${to.teamRoot()}/reporting`
    }
  };
  return to;
};

/**
 * Utility to generate settings routes within app
 * @param teamId required for team specific settings
 */
export const withinSettings = (teamId: string = ':team_id') => {
  const to = {
    appRoot: () => `/app`,
    settings: {
      home: () => `${to.appRoot()}/settings`,
      account: {
        home: () => `${to.settings.home()}/account`,
        details: () => `${to.settings.account.home()}/details`,
        security: () => `${to.settings.account.home()}/security`
      },
      organization: {
        workspace: () => `${to.settings.home()}/organization/workspace`,
        workspaceSection: () =>
          `${to.settings.home()}/organization/workspace/:section`,
        users: () => `${to.settings.home()}/organization/users`,
        roles: () => `${to.settings.home()}/organization/roles`,
        integrations: () => `${to.settings.home()}/organization/integrations`,
        auth: () => `${to.settings.home()}/organization/auth`
      },
      team: {
        home: () => `${to.settings.home()}/team/${teamId}`,
        publicProfile: () => `${to.settings.team.home()}/public-profile`,
        publicProfileSection: () =>
          `${to.settings.team.home()}/public-profile/:section`,
        fields: () => `${to.settings.team.home()}/fields`,
        fieldsStructure: () => `${to.settings.team.home()}/fields/:structure`,
        fieldsStructureId: () =>
          `${to.settings.team.home()}/fields/:structure/:id`,
        statuses: () => `${to.settings.team.home()}/statuses`,
        tags: () => `${to.settings.team.home()}/tags`,
        resourceTypes: () => `${to.settings.team.home()}/resource-types`,
        rateCards: () => `${to.settings.team.home()}/rate-cards`,
        productionTypes: () => `${to.settings.team.home()}/production-types`,
        productionTemplates: () =>
          `${to.settings.team.home()}/production-templates`,
        productionTemplatesId: () =>
          `${to.settings.team.home()}/production-templates/:id`,
        callsheetTemplates: () =>
          `${to.settings.team.home()}/callsheet-templates`,
        expenseTypes: () => `${to.settings.team.home()}/expense-types`,
        slotCategories: () => `${to.settings.team.home()}/slot-categories`,
        alerts: () => `${to.settings.team.home()}/alerts`,
        bookingTypes: () => `${to.settings.team.home()}/booking-types`,
        confirmationTemplates: () =>
          `${to.settings.team.home()}/confirmation-templates`,
        imports: () => `${to.settings.team.home()}/imports`,
        importsId: () => `${to.settings.team.home()}/imports/:id`,
        duplicates: () => `${to.settings.team.home()}/duplicates`,
        arrivalBoard: () => `${to.settings.team.home()}/arrival-board`
      }
    }
  };
  return to;
};
