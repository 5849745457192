import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { RootStore } from 'store/reducer';

const selectTeamsStore = (state: RootStore) => state.teams;

const selectTeamsAreFetched = createSelector(
  selectTeamsStore,
  teams => teams.fetched
);

const selectTeams = createSelector(selectTeamsStore, teams => teams.data);

const selectTeamsList = createSelector(selectTeams, data =>
  Object.values(data)
);

const selectTeamById = createCachedSelector(
  selectTeams,
  (_: RootStore, id: string) => id,
  (data, id) => data[id]
)((_, id) => id);

const selectActiveTeam = createSelector(selectTeamsList, teams => teams[0]);

const selectActiveFeatureFlags = createSelector(selectActiveTeam, team =>
  team ? team.featureFlags : []
);

export {
  selectTeamsStore,
  selectTeamsAreFetched,
  selectTeams,
  selectTeamsList,
  selectTeamById,
  selectActiveTeam,
  selectActiveFeatureFlags
};
