import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FlyoverContext from '../FlyoverContext';

const FlyoverTab = ({ tab, children }) => {
  const { activeTab } = useContext(FlyoverContext);

  if (tab !== activeTab) {
    return null;
  }

  return <>{children}</>;
};

FlyoverTab.propTypes = {
  tab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.any
};

export default FlyoverTab;
