import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';

export const INITIAL_STATE = {
  fetching: null,
  error: null,
  name: null,
  methods: []
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_ACTIONS.GET_AUTH_METHODS_REQUEST:
      return {
        fetching: action.type,
        error: null,
        name: '',
        methods: []
      };
    case AUTH_ACTIONS.GET_AUTH_METHODS_SUCCESS:
      return {
        fetching: false,
        error: null,
        name: action.result.name,
        methods: action.result.auth_methods
      };
    case AUTH_ACTIONS.GET_AUTH_METHODS_FAILURE:
      return {
        fetching: false,
        error: action.error,
        name: '',
        methods: []
      };
    case AUTH_ACTIONS.UPDATE_AUTH_METHOD_REQUEST:
      return {
        ...state,
        fetching: action.type
      };
    case AUTH_ACTIONS.UPDATE_AUTH_METHOD_SUCCESS:
      return {
        ...state,
        fetching: null,
        methods: state.methods.map(method =>
          method.id === action.payload.id ? action.payload : method
        )
      };
    case AUTH_ACTIONS.UPDATE_AUTH_METHOD_FAILURE:
      return {
        ...state,
        fetching: null
      };
    default:
      return state;
  }
}
