import PropTypes from 'prop-types';
import React from 'react';

import './AccountPageHeader.scss';

const AccountPageHeader = ({ label, title, description }) => (
  <div className="AccountPageHeader">
    {label && <p className="AccountPageHeader-subtitle">{label}</p>}
    {title && <h1 className="AccountPageHeader-title emulate-h2">{title}</h1>}
    {description && <p>{description}</p>}
  </div>
);

AccountPageHeader.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default AccountPageHeader;
