import React from 'react';
import PropTypes from 'prop-types';

import { PinItem, EmptyGeneric } from 'v1/components/shared';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import './ApplicantCarousel.scss';

const ApplicantCarousel = ({ files, appearance, height }) => {
  function renderItems(files) {
    const list = files.map(pin => {
      return (
        <PinItem
          key={pin.id}
          className="ApplicantCarousel-pinItem"
          pin={pin}
          settings={{
            video: { showPreview: true, height: '250px' }
          }}
        />
      );
    });

    return (
      <div className="ApplicantCarousel-scrollContainer" style={{ height }}>
        {list}
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className="ApplicantCarousel-empty">
        <EmptyGeneric
          title="Empty"
          description="Applicant did not submit files"
          icon="/images/icon_colour_media.svg"
        />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        { [`ApplicantCarousel-appearance_${appearance}`]: appearance },
        'ApplicantCarousel'
      )}
      style={{
        height
      }}
    >
      {!isEmpty(files) ? renderItems(files) : renderEmpty()}
    </div>
  );
};

ApplicantCarousel.defaultProps = {
  appearance: 'fill',
  height: 250
};

ApplicantCarousel.propTypes = {
  files: PropTypes.array,
  appearance: PropTypes.oneOf(['fill', 'inline']),
  height: PropTypes.number
};

export default ApplicantCarousel;
