import { TailwindRoot } from "v4/shared/TailwindRoot";

const KeyboardShortcut = ({ shortcut, ...rest }: { shortcut: string }) => {
  return (
    <TailwindRoot>
      <kbd className="tw-px-2 tw-py-1 tw-rounded-md tw-color-white tw-bg-white/10 tw-text-xs tw-pointer-events-none" {...rest}>
        {shortcut}
      </kbd>
    </TailwindRoot>
  );
};

export default KeyboardShortcut;
