import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Sortable from 'react-sortablejs';

import { useSelector, useDispatch } from 'react-redux';
import { displayAlert } from 'store/v1/ui/ui.actions';
import { updateTask, deleteTask } from 'store/tasks/tasks.actions';
import {
  getTaskList,
  createTaskList,
  assignTaskListToBooking,
  createTaskWithinTaskList,
  orderTasksWithinTaskList
} from 'store/task_lists/task_lists.actions';
import { TASKS_ACTIONS } from 'store/tasks/tasks.constants';
import { TASK_LISTS_ACTIONS } from 'store/task_lists/task_lists.constants';

import { Grid, GridCell, ListCell, Loading } from 'v1/components/shared';
import TaskListItem from './TaskListItem/TaskListItem';

import useEvent from 'v1/helpers/hooks/useEvent';

import size from 'lodash/size';
import find from 'lodash/find';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import v4 from 'uuid';

const TaskList = ({ taskListId, bookingId, resourceId }) => {
  const dispatch = useDispatch();

  const taskLists = useSelector(state => state.task_lists);
  const tasks = useSelector(state => state.tasks);

  const [filteredTasks, setFilteredTasks] = useState(getTasksFromTaskId());

  function getTasksFromTaskId() {
    const taskList = get(taskLists, ['data', taskListId]);
    if (!taskList) return [];
    let filteredTasks = taskList.tasks.reduce((acc, taskId) => {
      const taskFromStore = get(tasks, ['data', taskId]);
      if (taskFromStore) {
        return [...acc, taskFromStore];
      }
      return acc;
    }, []);
    return orderBy(filteredTasks, 'order');
  }

  useEvent(
    [
      TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST,
      TASK_LISTS_ACTIONS.GET_TASK_LIST,
      TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST,
      TASKS_ACTIONS.UPDATE_TASK,
      TASKS_ACTIONS.DELETE_TASK
    ],
    {
      onSuccess: () => {
        setFilteredTasks(getTasksFromTaskId());
      }
    }
  );

  useEvent([TASKS_ACTIONS.UPDATE_TASK], {
    onFailure: () => {
      dispatch(displayAlert('success', 'Task failed to update'));
    }
  });

  useEvent([TASK_LISTS_ACTIONS.CREATE_TASK_LIST], {
    onSuccess: () => {
      const newTaskListId = get(taskLists, 'newTaskList.taskListId');
      dispatch(
        assignTaskListToBooking({
          taskListId: newTaskListId,
          bookingId
        })
      );
    }
  });

  useEffect(() => {
    if (!taskListId) {
      dispatch(createTaskList({ title: `Tasks` })); // Server expects a title even though we don't currently display it. Need to remove.
    } else {
      dispatch(getTaskList(taskListId));
    }
  }, [taskListId, dispatch]);

  function onOrderChange(data) {
    const representationOrder = data.map((taskId, order) => ({
      taskId: parseInt(taskId),
      order
    }));
    setFilteredTasks(prev => {
      return data.map((taskId, order) => {
        const task = find(prev, p => p.taskId == taskId); // TODO: turn into int
        return { ...task, order };
      });
    });

    dispatch(orderTasksWithinTaskList({ taskListId, representationOrder }));
  }

  function onCreateTask() {
    const task = {
      title: '',
      description: '',
      status: 'OPEN',
      taskId: `temp-${v4()}`,
      assignToResourceIds: [resourceId],
      order: size(filteredTasks)
    };
    dispatch(createTaskWithinTaskList({ task, taskListId }));
  }
  function onDeleteTask(task) {
    dispatch(deleteTask(task.taskId));
  }
  function onUpdateTask(task) {
    dispatch(updateTask(task.taskId, task));
  }

  if (taskLists.loading === 'GET_TASK_LIST') return <Loading />;

  return (
    <div className="TaskList">
      <Sortable
        options={{
          handle: '.DragIndicator',
          animation: 0,
          ghostClass: 'PlaceholderDrop',
          draggable: '.draggable'
        }}
        className="ListCellGroup"
        onChange={onOrderChange}
      >
        {filteredTasks &&
          filteredTasks.map(task => (
            <TaskListItem
              key={task.taskId}
              task={task}
              onDeleteTask={onDeleteTask}
              onUpdateTask={onUpdateTask}
            />
          ))}
        <ListCell key="task-add" onClick={onCreateTask}>
          <Grid gutters="S" vcenter>
            <GridCell width="auto">
              <img src="/images/icon_add_black.svg" width="12px" alt="icon" />
            </GridCell>
            <GridCell>Add Task</GridCell>
          </Grid>
        </ListCell>
      </Sortable>
    </div>
  );
};

TaskList.propTypes = {
  taskListId: PropTypes.number,
  bookingId: PropTypes.number,
  resourceId: PropTypes.number
};

export default TaskList;
