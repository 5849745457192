import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Textarea.scss';

const Textarea = ({
  className,
  id,
  name,
  value,
  appearance,
  size,
  validity,
  children,
  ...props
}) => (
  <textarea
    id={id}
    name={name}
    value={value || ''}
    className={classnames(
      'Textarea',
      { [`Textarea_${appearance}`]: appearance },
      { [`Textarea_${size}`]: size },
      { [`Textarea_${validity}`]: validity },
      className
    )}
    {...props}
  />
);

Textarea.propTypes = {
  // Component props
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'underline', 'silent']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  validity: PropTypes.oneOf(['valid', 'invalid']),
  children: PropTypes.node,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  type: PropTypes.string
  // ...props
  // All valid attributes for <textarea />
  // Keeping in mind React-specific attribute case:
  // https://react-cn.github.io/react/docs/tags-and-attributes.html
};

Textarea.defaultProps = {
  value: '',
  appearance: 'outline'
};

export default Textarea;
