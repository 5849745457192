import classNames from 'classnames';
import { PropsWithChildren } from 'react';

const SidebarSection = ({ children, className }: PropsWithChildren & { className?: string }) => {
  return (
    <div className={classNames("tw-px-4 tw-my-2.5", className)}>
      {children}
    </div>
  );
};

export default SidebarSection;
