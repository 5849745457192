import { cf } from '@easle/component-factory';
import classNames from 'classnames';

export const SearchPrimitive = cf.input({
  className: classNames(
    'tw-w-full tw-border tw-border-gray-500 tw-rounded tw-text-sm tw-text-gray-900 tw-placeholder-gray-500',
    'focus:tw-outline-none focus:tw-ring-0 focus:tw-border-gray-500',
    'tw-p-lg'
  )
});
