import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const ARRIVALS_ACTIONS = mapValues(
  mirror({
    GET_ARRIVALS: null,
    GET_ARRIVALS_SUCCESS: null,
    GET_ARRIVALS_FAILURE: null
  }),
  value => value
);
