import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getPublicSettings } from 'store/v1/public_settings/public_settings.actions.js';
import { getAvailabilityRequest } from 'store/availabilities/availabilities.actions.js';
import { closeBookingSidebar } from 'store/v1/ui/ui.actions.js';

import ResourcePortalHeader from './ResourcePortalHeader/ResourcePortalHeader';
import ResourcePortalMenu from './ResourcePortalMenu/ResourcePortalMenu';
import ResourcePortalRouter from './ResourcePortalRouter/ResourcePortalRouter';
import ResourcePortalBookingSidebar from './ResourcePortalBookingSidebar/ResourcePortalBookingSidebar';

import styles from './ResourcePortal.module.scss';

const ResourcePortal = ({
  routesConfiguration,
  defaultRoute,
  token,
  bearerToken
}) => {
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.public_settings);
  const { showBookingSidebar, selectedBooking } = useSelector(
    state => state.ui
  );
  const { publicBrandImage, publicCompanyName, publicTeamName } = settings;
  const { request } = useSelector(state => state.availabilities);
  const { resourceFullName } = request;

  useEffect(() => {
    const { teamSchema, availabilityRequestId } = token;
    dispatch(getPublicSettings(teamSchema));
    dispatch(
      getAvailabilityRequest(teamSchema, availabilityRequestId, bearerToken)
    );
  }, []);

  return (
    <div className={styles['ResourcePortal']}>
      <ResourcePortalHeader
        imagePath={publicBrandImage}
        companyName={publicCompanyName}
        teamName={publicTeamName}
      />
      <div className={styles['ResourcePortal__content']}>
        <ResourcePortalMenu
          routesConfig={routesConfiguration}
          name={resourceFullName}
        />
        <ResourcePortalRouter
          routesConfig={routesConfiguration}
          defaultRoute={defaultRoute}
        />
        <ResourcePortalBookingSidebar
          isOpen={showBookingSidebar}
          closeSidebar={() => dispatch(closeBookingSidebar())}
          booking={selectedBooking}
        />
      </div>
    </div>
  );
};

ResourcePortal.props = {
  routesConfiguration: PropTypes.object,
  defaultRoute: PropTypes.object,
  token: PropTypes.object
};

export default ResourcePortal;
