import React from 'react';
import PropTypes from 'prop-types';
import Text from 'react-format-text';

import { ListCellGroup, ListCell, Grid, GridCell } from 'v1/components/shared';

const ResourcePortalTaskList = ({ taskList = {} }) => {
  const { tasks = [] } = taskList;

  return (
    <ListCellGroup>
      {tasks.map(task => (
        <ListCell>
          <Grid>
            <GridCell className="text-13-600">{task.title}</GridCell>
          </Grid>
          <Grid>
            <GridCell>
              {task.description && <Text>{task.description}</Text>}
            </GridCell>
          </Grid>
        </ListCell>
      ))}
    </ListCellGroup>
  );
};

ResourcePortalTaskList.propTypes = {
  taskList: PropTypes.object
};

export default ResourcePortalTaskList;
