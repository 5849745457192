import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Dropdown, Grid, GridCell, TextInput } from 'v1/components/shared';
import { MenuItem } from '@reach/menu-button';

import { TIMEZONES } from 'v1/helpers/DropdownDataSets';
import { filter, isEmpty, get, find } from 'lodash';
import './TimezoneSelect.scss';

export class TimezoneSelect extends Component {
  constructor(props) {
    super(props);

    const settingsTimezone = get(
      props.auth,
      'settings.settings.defaults_timezone.setting'
    );

    const systemTimezone = find(
      TIMEZONES,
      t => t.timeZoneName === settingsTimezone
    );

    const selectedTimezone = find(
      TIMEZONES,
      t => t.timeZoneName === get(props.timezone, 'timeZoneName')
    );
    this.state = {
      timezone: selectedTimezone || systemTimezone
    };
  }
  onSelect = timezone => {
    const { onChange, field } = this.props;

    this.setState({ timezone }, () => {
      onChange && onChange(field, timezone);
    });
  };
  filteredTimezones = (field, value) => {
    let timezones = TIMEZONES;
    let query = get(this.state, 'query') || '';

    if (!isEmpty(query)) {
      timezones = filter(timezones, t => {
        const name = t.name.toLowerCase();
        return name.includes(query.toLowerCase());
      });
    }

    return timezones;
  };
  renderTimezoneList = () => {
    const timezones = this.filteredTimezones();
    const timezoneList = timezones.map((timezone, index) => {
      return (
        <MenuItem
          key={index}
          className="TimezoneSelect-item"
          onSelect={() => {
            this.onSelect(timezone);
          }}
        >
          <h5>{timezone.name}</h5>
          <span className="TimezoneSelect-item-meta">
            {timezone.country} &middot; {timezone.timeZoneName}
          </span>
        </MenuItem>
      );
    });

    return timezoneList;
  };
  render() {
    const { buttonClass } = this.props;
    const timezone = get(this.state, 'timezone.timeZoneName');
    return (
      <div className="TimezoneSelect">
        <Dropdown
          buttonClass={buttonClass}
          buttonLabel={`Timezone: ${timezone}`}
        >
          <Grid className="sticky">
            <GridCell padding="XS">
              <form>
                <TextInput
                  placeholder="Search City"
                  value={this.state.query}
                  onChange={({ target }) =>
                    this.setState({ query: target.value })
                  }
                  appearance="outline"
                  size="S"
                  autoComplete="off"
                />
              </form>
            </GridCell>
          </Grid>
          {this.renderTimezoneList()}
        </Dropdown>
      </div>
    );
  }
}

TimezoneSelect.propTypes = {
  field: PropTypes.string,
  auth: PropTypes.object, // TODO: object shape
  timezone: PropTypes.object, // TODO: object shape
  defaultTimezone: PropTypes.any, // TODO: type
  dropdownProps: PropTypes.object, // TODO: object shape
  onChange: PropTypes.func // TODO: arguments
};

TimezoneSelect.defaultProps = {
  buttonClass: 'genericLink'
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, null)(TimezoneSelect);
