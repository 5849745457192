// Libraries
import React, { useEffect, useMemo, useRef } from 'react';
import CellMeasurer, {
  CellMeasurerCache
} from 'react-virtualized/dist/commonjs/CellMeasurer';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import { useScrollParent } from '~/v4/pages/common/ScrollParent';
import ResizeObserver from 'rc-resize-observer';

const ListVirtualized = ({ children, scrollElement }) => {
  const windowScrollerRef = useRef();
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 1500 // Estimated max row height by default to prevent list jumping on update
      }),
    []
  );

  useEffect(() => {
    if (windowScrollerRef.current) {
      cache.clearAll(); // Clear all cached params
      windowScrollerRef.current.updatePosition(); // Recalculate and update block position
    }
  }, [scrollElement]);

  return (
    <WindowScroller
      scrollElement={scrollElement || window} // 'window' by default if scrollElement empty
      ref={windowScrollerRef}
    >
      {({ height, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              autoHeight
              overscanRowCount={0}
              height={height || 500} // 500 by default if something went wrong with WindowScroller
              width={width}
              rowCount={children.length}
              rowHeight={cache.rowHeight}
              scrollTop={scrollTop}
              deferredMeasurementCache={cache}
              rowRenderer={({ index, key, parent, style }) => (
                <CellMeasurer
                  cache={cache}
                  index={index}
                  key={key}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ measure, registerChild }) => (
                    <div ref={registerChild} style={style}>
                      <ResizeObserver onResize={measure}>
                        {children[index]}
                      </ResizeObserver>
                    </div>
                  )}
                </CellMeasurer>
              )}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

export default ListVirtualized;

// This component gets scroll parent from the context
// Reason: it's not a business logic or state things, and scroll parent pollutes the props
// It's more like a layout/theme thing that should be freely lifted across dom tree regardless of the business logic
// use <ScrollParent> to render a div element with scroll parent context
export function ContextualListVirtualized(props) {
  const scrollParent = useScrollParent();
  const [scrollElement, setScrollElement] = React.useState(null);
  // we need to make sure that ListVirtualized is rendered with present scrollElement
  useEffect(() => {
    setScrollElement(scrollParent.current);
  }, []);
  return <ListVirtualized {...props} scrollElement={scrollElement} />;
}
