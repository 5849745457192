import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  PressStud,
  ErrorMessage,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import {
  createProductionTemplate,
  updateProductionTemplate
} from 'store/v1/production_templates/production_templates.actions.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { PRODUCTION_TEMPLATES_ACTIONS } from 'store/v1/production_templates/production_templates.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { selectIntegrationById } from 'store/integrations';
import { IntegrationID, FeatureFlag } from '__types__';
import { validate as validator } from 'v1/helpers/validation';
import v4 from 'uuid';
import { useRestrictedAccess } from 'lib/restrictedAccess';

import SYSTEM_COLOURS from '../../../../helpers/consts/SYSTEM_COLOURS';

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const ProductionTemplateCreateModal = ({ history }) => {
  // REDUX
  const ui = useSelector(state => state.ui);
  const { production_template = {}, create } = get(ui, 'data', {});

  const production_templates = useSelector(state => state.production_templates);
  const { loading, error } = production_templates || {};

  const dispatch = useDispatch();
  // const toSettings = withinSettings()

  const shotFlowAllowed = useRestrictedAccess(FeatureFlag.CUSTOM_SHOTFLOW);
  const shotFlowIntegration = useSelector(state =>
    selectIntegrationById(state, IntegrationID.SHOTFLOW)
  );
  const shotFlowInstances = shotFlowIntegration?.configuration?.instances
    ? Object.values(shotFlowIntegration.configuration.instances)
    : [];

  // PROPS AND REFS
  const { id, name } = production_template;

  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent(
    [
      PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE,
      PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE
    ],
    {
      onSuccess: () => {
        history.push(`production-templates/${production_templates.new.id}`);
        dispatch(closeModal());
        dispatch(displayAlert('success', 'Updated succesfully'));
      },
      onFailure: () => dispatch(displayAlert('error', error.message))
    }
  );

  // METHODS
  function handleSubmit(values) {
    const production_type_id = get(ui, ['data', 'productionTypeId']);

    const defaultShotFlowInstance =
      shotFlowAllowed && shotFlowInstances.length > 0
        ? shotFlowInstances[0]
        : null;
    const shotflow_instance_id = defaultShotFlowInstance
      ? defaultShotFlowInstance.id
      : null;

    create
      ? dispatch(
          createProductionTemplate({
            name: values.name,
            production_type_id,
            default_groups: {
              groups: [
                {
                  id: `ref-${v4()}`,
                  name: 'Locations',
                  order: 0
                },
                {
                  id: `ref-${v4()}`,
                  name: 'Crew',
                  order: 1
                }
              ]
            },
            default_values: {
              color: SYSTEM_COLOURS.GREY
            },
            shotflow_instance_id
          })
        )
      : dispatch(updateProductionTemplate(id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader
        title={`${create ? 'Create' : 'Edit'} production template`}
      />
      <ModalContent>
        <ModalScrollable>
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              name
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <Field
                  field="name"
                  validate={value => handleValidate(value, 'required')}
                >
                  {({ fieldName: name, value, error, setValue }) => (
                    <div className="stack-M">
                      <Label htmlFor={name}>Template name</Label>
                      <TextInput
                        name={name}
                        value={value}
                        placeholder="Name"
                        onChange={({ target }) => setValue(target.value)}
                        validity={error ? 'invalid' : null}
                        required
                        autoComplete="off"
                        autoFocus
                      />
                      {error && (
                        <ErrorMessage>This field is required</ErrorMessage>
                      )}
                    </div>
                  )}
                </Field>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Production Template' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading ===
                PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE ||
              loading ===
                PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default withRouter(ProductionTemplateCreateModal);
