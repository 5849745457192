import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { get, size, chain, isEmpty } from 'lodash';

import { EXPENSES_ACTIONS } from 'store/v1/expenses/expenses.constants.js';
import {
  createExpenseReceipt,
  updateExpenseReceipt,
  deleteExpenseReceipt
} from 'store/v1/expenses/expenses.actions.js';
import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import {
  Grid,
  GridCell,
  ListCellGroup,
  ListCell,
  PressStud,
  ProductionBudgetInvoiceItem
} from 'v1/components/shared';

const ExpenseReceiptManagement = ({
  expenseId,
  productionId,
  budgetExpenseId
}) => {
  const dispatch = useDispatch();
  const productions = useSelector(state => state.productions);
  const production = get(productions, ['data', productionId], {});
  const getSlotName = useSelector(makeGetSlotName);
  const expenses = useSelector(state => state.expenses.data);
  const getExpenseFromProduction = () => {
    const budgetExpense =
      get(production, 'budget_expenses', []).find(
        be => be.id === budgetExpenseId
      ) || {};
    return (
      get(budgetExpense, 'expenses', []).find(e => e.id === expenseId) || {}
    );
  };
  const { error } = productions;

  const [expense, setExpense] = useState({});
  const [receipts, setReceipts] = useState([]);

  useEffect(() => {
    setExpense(get(expenses, expenseId) || getExpenseFromProduction());
  }, []);

  useEffect(() => {
    setReceipts(get(expense, ['receipts']), []);
  }, [expense]);

  const receiptList = chain(receipts)
    .filter(i => !i.archived)
    .orderBy(['order', 'asc'])
    .value();

  useEvent(
    [
      EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT,
      EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT,
      EXPENSES_ACTIONS.DELETE_EXPENSE_RECEIPT
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Updated succesfully'));
      },
      onFailure: () => dispatch(displayAlert('error', error.message))
    }
  );

  const onUpdateReceipt = receipt => {
    dispatch(
      updateExpenseReceipt(
        { expense_item_id: expense.id, receipt_id: receipt.id },
        receipt
      )
    );
  };

  const onCreateReceipt = receipt => {
    dispatch(createExpenseReceipt({ expense_item_id: expense.id }, receipt));
  };

  const onNewReceipt = () => {
    const newReceipt = {
      id: `temp-${v4()}`,
      order: size(receiptList),
      document_type: 'RECEIPT'
    };
    setReceipts([...receiptList, newReceipt]);
  };

  const onRemoveReceipt = receipt => {
    const updatedReceipts = receiptList.filter(i => i.id != receipt.id);
    setReceipts(updatedReceipts);
    if (typeof receipt.id === 'number') {
      dispatch(
        deleteExpenseReceipt({
          expense_item_id: expense.id,
          receipt_id: receipt.id
        })
      );
    }
  };

  return (
    <div className="ExpenseReceiptManagement stack-M">
      <h5 className="text-13-600">
        {getSlotName(expense)}{' '}
        {expense.commit_vendor && ` - ${expense.commit_vendor}`}
      </h5>
      <ListCellGroup className="stack-XS">
        {!isEmpty(receiptList) ? (
          receiptList.map(receipt => (
            <ProductionBudgetInvoiceItem
              key={`receipts-${receipt.id}`}
              invoice={receipt}
              onRemoveInvoice={onRemoveReceipt}
              onUpdateInvoice={onUpdateReceipt}
              onCreateInvoice={onCreateReceipt}
            />
          ))
        ) : (
          <ListCell>
            <Grid>
              <GridCell className="text-13-600-eggplant">No Receipts</GridCell>
            </Grid>
          </ListCell>
        )}
      </ListCellGroup>
      <PressStud
        label="New Receipt"
        icon="addBlack"
        size="S"
        action={onNewReceipt}
      />
    </div>
  );
};

ExpenseReceiptManagement.propTypes = {
  expenseId: PropTypes.number.isRequired,
  // Use these if it's a production expense and it's not in the expenses store
  productionId: PropTypes.number,
  budgetExpenseId: PropTypes.number
};

export default ExpenseReceiptManagement;
