import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { updateBooking } from 'store/v1/bookings/bookings.actions';
import {
  getBookingsStatuses,
  getBookingStatuses,
  getAvailabilityStatuses
} from 'store/v1/statuses/statuses.selectors.js';

import { StatusSelector } from 'v1/components/shared';

const BookingStatusSelector = ({
  className,
  bookingId,
  statusId,
  appearance,
  size
}) => {
  const dispatch = useDispatch();
  const statuses = useSelector(state => getBookingsStatuses(state));
  const bookingStatuses = useSelector(state => getBookingStatuses(state));
  const availabilityStatuses = useSelector(state =>
    getAvailabilityStatuses(state)
  );
  const status = statuses.find(s => s.id === statusId);

  const onUpdate = status => {
    dispatch(updateBooking(bookingId, { status_id: status.id }));
  };

  const statusGroups = [
    {
      label: 'Booking',
      type: 'USER',
      statuses: bookingStatuses
    },
    {
      label: 'Availability',
      type: 'USER',
      statuses: availabilityStatuses
    }
  ];

  return (
    <StatusSelector
      className={className}
      statusGroups={statusGroups}
      selectedStatus={status}
      onSelect={onUpdate}
      size={size}
      appearance={appearance}
    />
  );
};

BookingStatusSelector.propTypes = {
  className: PropTypes.string,
  bookingId: PropTypes.number.isRequired,
  statusId: PropTypes.number,
  appearance: PropTypes.oneOf(['silent', 'default']),
  size: PropTypes.oneOf(['S', 'M', 'L'])
};

export default BookingStatusSelector;
