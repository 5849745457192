import React from 'react';
import classnames from 'classnames';

import styles from './Heading.module.scss';

type HeadingProps = {
  type?: 'h1' | 'h2' | 'h3' | 'h4';
  family?: 'sans' | 'serif';
  stack?: CSSSize;
  reverse?: boolean;
  children: React.ReactNode;
};

// Semantic, styled headings
const Heading = ({
  type = 'h3',
  family,
  stack,
  reverse,
  children
}: HeadingProps) =>
  React.createElement(
    type,
    {
      className: classnames(styles[type], {
        [styles['serif']]: family === 'serif',
        [styles[`stack_${stack}`]]: stack,
        [styles.reverse]: reverse
      })
    },
    children
  );

export default Heading;
