import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import chain from 'lodash';

import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';
import { makeGetProductionFromResourceSlotId } from 'store/v1/productions/productions.selectors.js';
import { orderReducer } from 'v1/helpers/misc';

import { PopoverSection, RateItem, EmptyGeneric } from 'v1/components/shared';

const RateSelectorItem = ({ rate, onClick }) => (
  <RateItem
    key={get(rate, 'id')}
    rate={rate}
    readOnly={true}
    inset={true}
    onClick={onClick}
  />
);

const RateSelectorContent = ({
  resourceId,
  assignments = [],
  showRateCards,
  closePopover,
  onSelect
}) => {
  const rates = useSelector(state => state.rates);
  // const rateCards =   filter(orderReducer(rates), r => !r.archived);
  const rateCards = chain(orderReducer(rates))
    .orderBy(['name', 'asc'])
    .filter(r => !r.archived)
    .value();

  const resources = useSelector(state => state.contacts.data);
  const resource = get(resources, resourceId);
  const resourceRates = get(resource, 'rates', []);
  const getSlotName = useSelector(makeGetSlotName);

  // TODO: ripped out of old RateSourceSelector - clean up
  // We do not have access to resource_slot object without getting the production on new bookings.
  const getProductionFromResourceSlotId = useMemo(
    makeGetProductionFromResourceSlotId,
    []
  );
  const selectedProductions = useSelector(state =>
    getProductionFromResourceSlotId(state, { assignments })
  );
  const getResourceSlotFromProductionList = resourceSlotId => {
    let selectedResourceSlot = {};
    selectedProductions.map(production => {
      production.resource_slots.map(slot =>
        slot.id === resourceSlotId ? (selectedResourceSlot = slot) : null
      );
    });
    return selectedResourceSlot;
  };

  const budgetedRates = assignments.reduce((acc, assignment) => {
    const slot = get(
      assignment,
      'resource_slot',
      getResourceSlotFromProductionList(assignment.resource_slot_id)
    );
    const rateAmount = get(slot, 'estimate_rate_amount');
    if (rateAmount) {
      acc.push({
        slot_id: get(slot, 'id'),
        id: get(slot, 'estimate_rate_id'),
        name: getSlotName(slot),
        amount: get(slot, 'estimate_rate_amount'),
        currency: get(slot, 'estimate_rate_currency'),
        interval: get(slot, 'estimate_rate_interval'),
        quantity: get(slot, 'estimate_rate_quantity'),
        agency_fee: get(slot, 'estimate_rate_agency_fee'),
        payroll_fee: get(slot, 'estimate_rate_payroll_fee'),
        processing_fee: get(slot, 'estimate_rate_processing_fee')
      });
    }
    return acc;
  }, []);

  const handleSelect = rate => {
    onSelect(rate);
    closePopover();
  };

  return (
    <>
      {resourceRates.length > 0 && (
        <PopoverSection title="Resource Rates">
          {resourceRates.map(rate => (
            <RateSelectorItem
              key={rate.id}
              rate={rate}
              onClick={handleSelect}
            />
          ))}
        </PopoverSection>
      )}
      {budgetedRates.length > 0 && (
        <PopoverSection title="Budgeted Rates">
          {budgetedRates.map(rate => (
            <RateSelectorItem
              key={rate.slot_id}
              rate={rate}
              onClick={handleSelect}
            />
          ))}
        </PopoverSection>
      )}
      {showRateCards && (
        <>
          {rateCards.length > 0 ? (
            <PopoverSection title="Rate Cards">
              {rateCards.map(rate => (
                <RateSelectorItem
                  key={rate.id}
                  rate={rate}
                  onClick={handleSelect}
                />
              ))}
            </PopoverSection>
          ) : (
            <EmptyGeneric
              title="No Rate Cards"
              description="Define rate cards in settings to standardise rates for your company"
              icon="/images/icon_colour_rates.svg"
              action={{
                text: 'Go to rate settings',
                className: 'btn btn-default btn-small',
                systemLink: '/settings/rates'
              }}
            />
          )}
        </>
      )}
    </>
  );
};

RateSelectorContent.props = {
  resourceId: PropTypes.number,
  assignments: PropTypes.arrayOf(PropTypes.object), // booking.resource_slot_assignments - For budgeted rates
  showRateCards: PropTypes.bool,
  closePopover: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default RateSelectorContent;
