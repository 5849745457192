import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import './PageSidebar.scss';

class PageSidebar extends Component {
  render() {
    return (
      <div
        className={classnames(
          { collapsed: this.props.ui.sidebarCollapsed === true },
          ['PageSidebar']
        )}
        id={this.props.id}
      >
        {this.props.onSidebarToggle ? (
          <span
            className="PageSidebar-close"
            onClick={this.props.onSidebarToggle}
          >
            <img src="/images/icon_cross_black.svg" width="12px" alt="" />
          </span>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps({ ui }) {
  return { ui };
}

export default connect(mapStateToProps, null)(PageSidebar);
