import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { pick, omit } from 'lodash';
import {
  formatProduction,
  formatResourceSlot
} from './productions.formatter.js';
const token = true;

export function getProductions(query, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.GET_PRODUCTIONS,
      PRODUCTIONS_ACTIONS.GET_PRODUCTIONS_SUCCESS,
      PRODUCTIONS_ACTIONS.GET_PRODUCTIONS_FAILURE
    ],
    promise: client =>
      client.post(`/search/productions`, { data: query, token }),
    event_id
  };
}

export function getProductionCalendar(query) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR,
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR_SUCCESS,
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR_FAILURE
    ],
    promise: client =>
      client.post(`/productions/calendar`, { data: query, token })
  };
}

export function quickSearchProductions(query, p, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.QUICK_SEARCH_PRODUCTIONS,
      PRODUCTIONS_ACTIONS.QUICK_SEARCH_PRODUCTIONS_SUCCESS,
      PRODUCTIONS_ACTIONS.QUICK_SEARCH_PRODUCTIONS_FAILURE
    ],
    promise: client =>
      client.post('/search/productions', { data: query, token }),
    p,
    event_id
  };
}

export function getBatchProductions(ids = [], event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.GET_BATCH_PRODUCTIONS,
      PRODUCTIONS_ACTIONS.GET_BATCH_PRODUCTIONS_SUCCESS,
      PRODUCTIONS_ACTIONS.GET_BATCH_PRODUCTIONS_FAILURE
    ],
    promise: client =>
      client.post(`/batch/productions/search`, {
        token,
        data: {
          ids
        }
      }),
    event_id
  };
}

export function getProduction(id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.GET_PRODUCTION,
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_SUCCESS,
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_FAILURE
    ],
    promise: client => client.get(`/productions/${id}`, { token }),
    event_id
  };
}

export function getProductionAssociations(production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_ASSOCIATIONS,
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_ASSOCIATIONS_SUCCESS,
      PRODUCTIONS_ACTIONS.GET_PRODUCTION_ASSOCIATIONS_FAILURE
    ],
    promise: client =>
      client.get(`/productions/${production_id}/associations`, { token }),
    event_id,
    production_id
  };
}

export function createProduction(data, event_id = null) {
  const toSubmit = formatProduction(data);
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_FAILURE
    ],
    promise: client => client.post(`/productions`, { data: toSubmit, token }),
    event_id
  };
}

export function updateProduction(id, data, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_FAILURE
    ],
    promise: client =>
      client.put(`/productions/${id}`, { data: formatProduction(data), token }),
    event_id
  };
}

export function updateResourceSlotOrder({ production_id }, resource_slots) {
  const toSubmit = resource_slots.map(slot => ({
    id: slot.id,
    order: slot.order || 0,
    group_id: slot.group_id
  }));
  return {
    types: [
      PRODUCTIONS_ACTIONS.REORDER_RESOURCE_SLOTS,
      PRODUCTIONS_ACTIONS.REORDER_RESOURCE_SLOTS_SUCCESS,
      PRODUCTIONS_ACTIONS.REORDER_RESOURCE_SLOTS_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/resource_slots`, {
        data: { reorder: toSubmit },
        token
      }),
    production_id
  };
}

export function deleteProduction(id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_FAILURE
    ],
    promise: client =>
      client.put(`/productions/${id}`, { data: { archived: true }, token }),
    event_id
  };
}

export function unarchiveProduction(id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.UNARCHIVE_PRODUCTION,
      PRODUCTIONS_ACTIONS.UNARCHIVE_PRODUCTION_SUCCESS,
      PRODUCTIONS_ACTIONS.UNARCHIVE_PRODUCTION_FAILURE
    ],
    promise: client =>
      client.put(`/productions/${id}`, { data: { archived: false }, token }),
    event_id
  };
}

export function createProductionNote(data, production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_NOTE,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_NOTE_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_NOTE_FAILURE
    ],
    promise: client =>
      client.post(`/productions/${production_id}/associations/notes`, {
        token,
        data
      }),
    event_id,
    production_id
  };
}

export function deleteProductionNote(id, production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_NOTE,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_NOTE_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_NOTE_FAILURE
    ],
    promise: client =>
      client.del(`/productions/${production_id}/associations/notes/${id}`, {
        token
      }),
    event_id,
    production_id,
    association_id: id
  };
}

export function createProductionDocument(data, production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_DOCUMENT,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_DOCUMENT_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_DOCUMENT_FAILURE
    ],
    promise: client =>
      client.post(`/productions/${production_id}/associations/files`, {
        token,
        data
      }),
    event_id,
    production_id
  };
}

export function deleteProductionDocument(id, production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_DOCUMENT,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_DOCUMENT_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_DOCUMENT_FAILURE
    ],
    promise: client =>
      client.del(`/productions/${production_id}/associations/files/${id}`, {
        token
      }),
    event_id,
    production_id,
    association_id: id
  };
}

// UNUSED
// export function updateInvoice(
//   { production_id, team_member_id, assignment_id, booking_id, invoice_id },
//   data,
//   event_id = null
// ) {
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.UPDATE_INVOICE,
//       PRODUCTIONS_ACTIONS.UPDATE_INVOICE_SUCCESS,
//       PRODUCTIONS_ACTIONS.UPDATE_INVOICE_FAILURE
//     ],
//     promise: client =>
//       client.put(
//         `/bookings/${booking_id}/associations/invoices/${invoice_id}`,
//         {
//           data,
//           token
//         }
//       ),
//     production_id,
//     team_member_id,
//     assignment_id,
//     invoice_id,
//     event_id
//   };
// }

export const createProductionGroup = group => {
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP_FAILURE
    ],
    promise: client =>
      client.post('/groups/productions', { data: group, token })
  };
};

export const updateProductionGroup = (id, group) => {
  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP_FAILURE
    ],
    promise: client => client.put(`/groups/${id}`, { data: group, token })
  };
};

export const deleteProductionGroup = (productionId, groupId) => {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_GROUP,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_GROUP_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_GROUP_FAILURE
    ],
    promise: client => client.del(`/groups/${groupId}`, { token }),
    productionId,
    groupId
  };
};

export const reorderProductionGroups = ({ productionId }, groups) => {
  const toSubmit =
    groups && groups.map(group => ({ id: group.id, order: group.order }));
  return {
    types: [
      PRODUCTIONS_ACTIONS.REORDER_PRODUCTION_GROUPS,
      PRODUCTIONS_ACTIONS.REORDER_PRODUCTION_GROUPS_SUCCESS,
      PRODUCTIONS_ACTIONS.REORDER_PRODUCTION_GROUPS_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/groups`, { data: { reorder: toSubmit }, token }),
    productionId
  };
};

export function addBudgetExpense({ production_id }, data) {
  const toSubmit = omit(data, ['id']);
  return {
    types: [
      PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE,
      PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_SUCCESS,
      PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_FAILURE
    ],
    promise: client =>
      client.post(
        `/productions/${production_id}/associations/budget_expenses`,
        {
          data: toSubmit,
          token
        }
      ),
    production_id
  };
}

export function deleteBudgetExpense(
  { production_id, budget_expense_id },
  event_id = null
) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_BUDGET_EXPENSE,
      PRODUCTIONS_ACTIONS.DELETE_BUDGET_EXPENSE_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_BUDGET_EXPENSE_FAILURE
    ],
    promise: client =>
      client.del(
        `/productions/${production_id}/associations/budget_expenses/${budget_expense_id}`,
        {
          token
        }
      ),
    production_id,
    budget_expense_id,
    event_id
  };
}

export function orderBudgetExpenses({ production_id }, data, event_id = null) {
  const toSubmit =
    data &&
    data.map(({ id, order, group_id }) => ({
      id,
      order: order || 0,
      group_id
    }));
  return {
    types: [
      PRODUCTIONS_ACTIONS.ORDER_BUDGET_EXPENSES,
      PRODUCTIONS_ACTIONS.ORDER_BUDGET_EXPENSES_SUCCESS,
      PRODUCTIONS_ACTIONS.ORDER_BUDGET_EXPENSES_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/budget_expenses`, {
        data: { reorder: toSubmit },
        token
      }),
    production_id,
    event_id
  };
}

// UNUSED
// export function addBudgetExpensesItem(
//   { production_id, budget_expense_id },
//   data,
//   event_id = null
// ) {
//   const toSubmit = omit(data, ['id']);
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_ITEM,
//       PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_ITEM_SUCCESS,
//       PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_ITEM_FAILURE
//     ],
//     promise: client =>
//       client.post(
//         `/budget_expenses/${budget_expense_id}/associations/expenses`,
//         {
//           data: toSubmit,
//           token
//         }
//       ),
//     production_id,
//     budget_expense_id,
//     event_id
//   };
// }

// UNUSED
// export function updateBudgetExpensesItem(
//   { production_id, budget_expense_id, budget_expense_item_id },
//   data,
//   event_id = null
// ) {
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.UPDATE_BUDGET_EXPENSE_ITEM,
//       PRODUCTIONS_ACTIONS.UPDATE_BUDGET_EXPENSE_ITEM_SUCCESS,
//       PRODUCTIONS_ACTIONS.UPDATE_BUDGET_EXPENSE_ITEM_FAILURE
//     ],
//     promise: client =>
//       client.put(
//         `/budget_expenses/${budget_expense_id}/associations/expenses/${budget_expense_item_id}`,
//         {
//           data,
//           token
//         }
//       ),
//     production_id,
//     event_id
//   };
// }

export function updateBudgetExpense(
  { production_id, budget_expense_id },
  data,
  event_id = null
) {
  const payload = pick(data, [
    'archived',
    'description',
    'estimate_rate_amount',
    'estimate_rate_currency',
    'estimate_rate_interval',
    'estimate_rate_quantity',
    data.slot_category_id ? 'slot_category_id' : 'slot_category',
    'group_id',
    'id',
    'name',
    'order',
    'production_id',
    'include_in_calculation',
    'note'
  ]);

  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE_FAILURE
    ],
    promise: client =>
      client.put(
        `/productions/${production_id}/associations/budget_expenses/${budget_expense_id}`,
        {
          data: payload,
          token
        }
      ),
    production_id,
    budget_expense_id,
    event_id
  };
}

// UNUSED
// export function createTeamMemberOption(
//   { production_id, team_member_id },
//   contact_id,
//   event_id = null
// ) {
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.CREATE_TEAM_MEMBER_OPTION,
//       PRODUCTIONS_ACTIONS.CREATE_TEAM_MEMBER_OPTION_SUCCESS,
//       PRODUCTIONS_ACTIONS.CREATE_TEAM_MEMBER_OPTION_FAILURE
//     ],
//     promise: client =>
//       client.post(`/production_team_members/${team_member_id}/options`, {
//         data: { contact_id },
//         token
//       }),
//     event_id,
//     production_id,
//     team_member_id
//   };
// }

export function createResourceSlot({ production_id }, data, event_id) {
  const toSubmit = formatResourceSlot(data);
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT,
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_FAILURE
    ],
    promise: client =>
      client.post(`/productions/${production_id}/associations/resource_slots`, {
        data: toSubmit,
        token
      }),
    production_id,
    event_id
  };
}

export function updateResourceSlot(
  { production_id, resource_slot_id },
  data,
  event_id
) {
  const toSubmit = formatResourceSlot(data);
  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT,
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_FAILURE
    ],
    promise: client =>
      client.put(
        `/productions/${production_id}/associations/resource_slots/${resource_slot_id}`,
        {
          data: toSubmit,
          token
        }
      ),
    production_id,
    resource_slot_id,
    event_id
  };
}

export function deleteResourceSlot(
  { production_id, resource_slot_id },
  event_id
) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT,
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_FAILURE
    ],
    promise: client =>
      client.del(`/resource_slots/${resource_slot_id}`, {
        token
      }),
    production_id,
    resource_slot_id,
    event_id
  };
}

// UNUSED
// export function createResourceSlotAssignment(
//   { production_id, resource_slot_id },
//   { contact_id, booking_id, events },
//   event_id = null
// ) {
//   let resourceSlotAssignmentData = { contact_id, booking_id, events };
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT,
//       PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS,
//       PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT_FAILURE
//     ],
//     promise: client =>
//       client.post(
//         `/resource_slots/${resource_slot_id}/associations/resource_slot_assignments`,
//         {
//           data: resourceSlotAssignmentData,
//           token
//         }
//       ),
//     event_id,
//     production_id,
//     resource_slot_id
//   };
// }

// UNUSED
// export function updateTeamMemberAssignment(
//   { production_id, team_member_id, assignment_id },
//   { contact_id, booking, status, archived },
//   event_id = null
// ) {
//   if (booking && booking.contracts) {
//     let contracts = booking.contracts.map(c => {
//       let contract = pick(c, [
//         'id',
//         'file_id',
//         'url',
//         'document_type',
//         'archived'
//       ]);
//       contract = omit(contract, 'file');

//       return contract;
//     });
//     booking.contracts = contracts;
//   }
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.UPDATE_TEAM_MEMBER_ASSIGNMENT,
//       PRODUCTIONS_ACTIONS.UPDATE_TEAM_MEMBER_ASSIGNMENT_SUCCESS,
//       PRODUCTIONS_ACTIONS.UPDATE_TEAM_MEMBER_ASSIGNMENT_FAILURE
//     ],
//     promise: client =>
//       client.put(
//         `/production_team_members/${team_member_id}/associations/assignments/${assignment_id}`,
//         {
//           data: { contact_id, booking, status, archived },
//           token
//         }
//       ),
//     event_id,
//     production_id,
//     team_member_id,
//     assignment_id
//   };
// }

export function deleteResourceSlotAssignment({
  production_id,
  resource_slot_id,
  resource_slot_assignment_id,
  booking_id
}) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT,
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS,
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT_FAILURE
    ],
    promise: client =>
      client.del(`/resource_slot_assignments/${resource_slot_assignment_id}`, {
        data: { archived: true },
        token
      }),
    production_id,
    resource_slot_id,
    resource_slot_assignment_id,
    booking_id
  };
}

export function createResourceSlotEvent(
  data,
  resource_slot_id,
  production_id,
  event_id = null
) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT,
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT_FAILURE
    ],
    promise: client =>
      client.post(`/resource_slots/${resource_slot_id}/associations/events`, {
        data,
        token
      }),
    event_id,
    resource_slot_id,
    production_id
  };
}

export function updateResourceSlotEvent(
  id,
  data,
  resource_slot_id,
  production_id,
  event_id = null
) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT,
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT_FAILURE
    ],
    promise: client =>
      client.put(
        `/resource_slots/${resource_slot_id}/associations/events/${id}`,
        {
          data,
          token
        }
      ),
    event_id,
    resource_slot_id,
    production_id
  };
}

// UNUSED
// export function deleteResourceSlotEvent(
//   id,
//   resource_slot_id,
//   production_id,
//   event_id = null
// ) {
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT,
//       PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT_SUCCESS,
//       PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT_FAILURE
//     ],
//     promise: client =>
//       client.del(
//         `/resource_slots/${resource_slot_id}/associations/events/${id}`,
//         {
//           token
//         }
//       ),
//     event_id,
//     resource_slot_id,
//     production_id
//   };
// }

export function createProductionEvent(data, production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT_SUCCESS,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT_FAILURE
    ],
    promise: client =>
      client.post(`/productions/${production_id}/associations/events`, {
        data,
        token
      }),
    event_id,
    production_id
  };
}

export function updateProductionEvent(
  id,
  data,
  production_id,
  event_id = null
) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT_FAILURE
    ],
    promise: client =>
      client.put(`/productions/${production_id}/associations/events/${id}`, {
        data,
        token
      }),
    event_id,
    production_id
  };
}

// UNUSED
// export function deleteProductionEvent(id, production_id, event_id = null) {
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT,
//       PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT_SUCCESS,
//       PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT_FAILURE
//     ],
//     promise: client =>
//       client.del(`/productions/${production_id}/associations/events/${id}`, {
//         token
//       }),
//     event_id,
//     productoin_event_id: id,
//     production_id
//   };
// }

// UNUSED
// export function createReceipt(
//   { budget_expense_id, production_id, expense_item_id },
//   receipt,
//   event_id = null
// ) {
//   if (
//     receipt.id &&
//     typeof receipt.id === 'string' &&
//     receipt.id.startsWith('temp-')
//   )
//     delete receipt.id;
//   receipt = pick(receipt, [
//     'archived',
//     'document_data',
//     'document_type',
//     'file',
//     'file_id',
//     'id',
//     'order',
//     'status'
//   ]);
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.CREATE_RECEIPT,
//       PRODUCTIONS_ACTIONS.CREATE_RECEIPT_SUCCESS,
//       PRODUCTIONS_ACTIONS.CREATE_RECEIPT_FAILURE
//     ],
//     promise: client =>
//       client.post(`/expenses/${expense_item_id}/associations/receipts`, {
//         data: receipt,
//         token
//       }),
//     budget_expense_id,
//     production_id,
//     expense_item_id
//   };
// }

// UNUSED
// export function deleteReceipt(
//   { production_id, budget_expense_id, expense_item_id, receipt_id },
//   data
// ) {
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.DELETE_RECEIPT,
//       PRODUCTIONS_ACTIONS.DELETE_RECEIPT_SUCCESS,
//       PRODUCTIONS_ACTIONS.DELETE_RECEIPT_FAILURE
//     ],
//     promise: client =>
//       client.del(
//         `/expenses/${expense_item_id}/associations/receipts/${receipt_id}`,
//         {
//           data,
//           token
//         }
//       ),
//     expense_item_id,
//     receipt_id,
//     production_id,
//     budget_expense_id
//   };
// }

// UNUSED
// export function updateReceipt(
//   { production_id, budget_expense_id, expense_item_id, receipt_id },
//   data
// ) {
//   data = omit(data, 'currency');
//   return {
//     types: [
//       PRODUCTIONS_ACTIONS.UPDATE_RECEIPT,
//       PRODUCTIONS_ACTIONS.UPDATE_RECEIPT_SUCCESS,
//       PRODUCTIONS_ACTIONS.UPDATE_RECEIPT_FAILURE
//     ],
//     promise: client =>
//       client.put(
//         `/expenses/${expense_item_id}/associations/receipts/${receipt_id}`,
//         {
//           data,
//           token
//         }
//       ),
//     production_id,
//     budget_expense_id,
//     expense_item_id,
//     receipt_id
//   };
// }

export function updateProductionConflicts(
  data,
  production_id,
  event_id = null
) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS,
      PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS_SUCCESS,
      PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS_FAILURE
    ],
    promise: client =>
      client.put(`/productions/${production_id}/conflicts`, {
        token,
        data
      }),
    event_id,
    production_id
  };
}

export function sendBookingConfirmations(production_id, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.SEND_PRODUCTION_BOOKING_CONFIRMATIONS,
      PRODUCTIONS_ACTIONS.SEND_PRODUCTION_BOOKING_CONFIRMATIONS_SUCCESS,
      PRODUCTIONS_ACTIONS.SEND_PRODUCTION_BOOKING_CONFIRMATIONS_FAILURE
    ],
    promise: client =>
      client.post(`/productions/${production_id}/send_booking_confirmation`, {
        token
      }),
    event_id
  };
}

export function moveProduction(production_id, data, event_id = null) {
  return {
    types: [
      PRODUCTIONS_ACTIONS.MOVE_PRODUCTION,
      PRODUCTIONS_ACTIONS.MOVE_PRODUCTION_SUCCESS,
      PRODUCTIONS_ACTIONS.MOVE_PRODUCTION_FAILURE
    ],
    promise: client =>
      client.post(`/productions/${production_id}/move`, {
        token,
        data
      }),
    event_id,
    production_id
  };
}
