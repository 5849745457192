import React from 'react';
import classnames from 'classnames';

import styles from './Label.module.scss';

type LabelProps = {
  /**
   * The visual style of the label
   */
  appearance?: 'default' | 'compact';
  /**
   * The sizing of the label
   */
  size?: 's' | 'm';
  /**
   * The horizontal alignment of the label
   */
  align?: 'left' | 'right';
  /**
   * The flow direction of the label
   */
  layout?: 'horizontal' | 'vertical';
  /**
   * Show the 'Required' text
   */
  isRequired?: boolean;
  /**
   * The unique id, for linking to an input
   */
  htmlFor?: UniqueId;
  /**
   * The label title
   */
  label: string;
  /**
   * The description
   */
  children?: React.ReactNode;
};

const Label = ({
  appearance = 'default',
  size = 's',
  align = 'left',
  layout = 'horizontal',
  htmlFor,
  label,
  children,
  isRequired
}: LabelProps) => {
  const stackLabel = layout === 'vertical' && (isRequired || children);
  const stackRequired = layout === 'vertical' && children;

  return (
    <div
      className={classnames(styles.Wrapper, {
        [styles.Wrapper_m]: size === 'm',
        [styles.Wrapper_right]: align === 'right',
        [styles.Wrapper_compact]: appearance === 'compact',
        [styles.Wrapper_horizontal]: layout === 'horizontal'
      })}
    >
      <div className={styles.Title}>
        <label
          htmlFor={htmlFor}
          className={classnames(styles.Label, {
            [styles.Label_stacked]: stackLabel
          })}
        >
          {label}
        </label>
        {isRequired && (
          <div
            className={classnames(styles.Required, {
              [styles.Required_stacked]: stackRequired
            })}
          >
            Required
          </div>
        )}
      </div>
      {children && <div className={styles.Description}>{children}</div>}
    </div>
  );
};

export default Label;
