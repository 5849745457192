import { CHECKINS_ACTIONS } from 'store/v1/checkins/checkins.constants.js';

export const INITIAL_STATE = {
  list: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHECKINS_ACTIONS.GET_FRONT_DESK_BOOKINGS:
    case CHECKINS_ACTIONS.CREATE_CHECKIN:
    case CHECKINS_ACTIONS.UPDATE_CHECKIN:
    case CHECKINS_ACTIONS.DELETE_CHECKIN:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case CHECKINS_ACTIONS.GET_FRONT_DESK_BOOKINGS_SUCCESS:
      return {
        ...state,
        list: action.result,
        loading: false,
        fetched: true
      };
    case CHECKINS_ACTIONS.UPDATE_CHECKIN_SUCCESS:
      return {
        ...state,
        list: state.list.map(item =>
          item.checkin && item.checkin.id == action.result.id
            ? { ...item, checkin: action.result }
            : item
        ),
        loading: false
      };
    case CHECKINS_ACTIONS.CREATE_CHECKIN_SUCCESS:
      return {
        ...state,
        list: state.list.map(item =>
          item.booking_id == action.result.booking_id
            ? { ...item, checkin: action.result }
            : item
        ),
        loading: false
      };
    case CHECKINS_ACTIONS.GET_FRONT_DESK_BOOKINGS_FAILURE:
    case CHECKINS_ACTIONS.UPDATE_CHECKIN_FAILURE:
    case CHECKINS_ACTIONS.CREATE_CHECKIN_FAILURE:
    case CHECKINS_ACTIONS.DELETE_CHECKIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
