import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FlyoverContext from '../FlyoverContext';
import FlyoverContentContents from './FlyoverContentContents';

import styles from './FlyoverContent.module.scss';

const FlyoverContent = ({
  className,
  width = 'content',
  height = 'auto',
  position = 'bottom',
  clearance = 'spaced',
  forcePosition = false,
  filterable,
  onSelect,
  children
}) => {
  const { setConfig, isOpen, closeFlyover, triggerRect } = useContext(
    FlyoverContext
  );

  useEffect(() => {
    setConfig({ position, clearance, forcePosition });
  }, [position, clearance, forcePosition, setConfig]);

  if (!isOpen) {
    return null;
  }

  const getStyles = () => {
    if (width === 'trigger') {
      return {
        width: triggerRect.width
      };
    }
    return null;
  };

  return (
    <div
      className={classnames(styles.FlyoverContent, {
        [styles[`FlyoverContent_${width}`]]:
          width && width !== 'trigger' && width !== 'content',
        [styles.FlyoverContent_fixedHeight]: height === 'fixed',
        [styles.FlyoverContent_fullHeight]: height === 'full',
        [styles.FlyoverContent_open]: isOpen
      })}
      style={getStyles()}
    >
      <FlyoverContentContents
        className={className}
        filterable={filterable}
        onSelect={onSelect}
      >
        {typeof children === 'function' ? children({ closeFlyover }) : children}
      </FlyoverContentContents>
    </div>
  );
};

FlyoverContent.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOf(['S', 'M', 'L', 'content', 'trigger']),
  height: PropTypes.oneOf(['auto', 'fixed', 'full']),
  position: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  clearance: PropTypes.oneOf(['spaced', 'flush', 'overlay']),
  forcePosition: PropTypes.bool,
  filterable: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.any
};

export default FlyoverContent;
