import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './SidebarDraw.scss';

const SIDEBAR_ANIMATE_DURATION = 500;

export const SidebarDraw = ({
  isOpen,
  closeSidebar,
  positionFor,
  borderColor,
  size,
  className,
  zIndex,
  children,
  sidebarHasOpened
}) => {
  useEffect(() => {
    if (isOpen && sidebarHasOpened) {
      setTimeout(() => sidebarHasOpened(), SIDEBAR_ANIMATE_DURATION);
    }
  }, [isOpen]);

  return (
    <div
      className={classnames([
        'SidebarDraw',
        { show: isOpen },
        {
          'SidebarDraw-wide': size === 'wide',
          [`SidebarDraw-positionFor_${positionFor}`]: positionFor
        },
        className
      ])}
      style={{
        borderLeftColor: borderColor,
        zIndex: zIndex
      }}
    >
      {isOpen && (
        <>
          <div className="SidebarDrawer-close">
            <span
              onClick={closeSidebar}
              className="SidebarDrawer-close-action closePanel"
              style={{ backgroundColor: borderColor }}
            >
              <img
                src="/images/icon_arrow_white.svg"
                width="13px"
                className="rotate-90"
                alt=""
              />
            </span>
          </div>
          {children}
        </>
      )}
    </div>
  );
};

SidebarDraw.defaultProps = {
  positionFor: 'platform'
};

SidebarDraw.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  borderColor: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'wide']),
  closeSidebar: PropTypes.func, // Will be an action from 'store/v1/ui/ui.actions.js' // TODO: needs refactoring
  children: PropTypes.any,
  positionFor: PropTypes.oneOf(['platform', 'portal'])
};

export default SidebarDraw;
