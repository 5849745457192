import {
  AssociationQuery,
  AssociationState,
  mutationSchemas
} from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';

export const createDeleteEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi
) =>
  defineEntityEffect({
    type: 'delete',
    requestSchema: mutationSchemas.delete.request,
    responseSchema: mutationSchemas.delete.response,
    useQueryFn: () => useApi().delete
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        // todo think about pagination, for now we don't care about it (previous implementation was loading all blocks among the shortlist endpoint anyway...)
        if (state.results.find(x => x.id === request.association_id)) {
          return {
            ...state,
            paging: {
              ...state.paging,
              total: state.paging.total - 1
            },
            results: state.results.filter(x => x.id !== request.association_id)
          };
        }
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      return {
        state: config.optimisticReconciler(state, request, query),
        shouldInvalidate: true // something can come from next page if we are paginated
      };
    })
    .build();
