import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { Shortlist, Shortlist_normalized } from '../../../../../__types__';
import { ApiClient } from '../../../../../lib/api/ApiClient.types';
import { SHORTLIST_RESOURCE_MAX_COUNT } from '../../../../../v1/containers/Routes/Shortlist/Shortlist';
import { ListQuery, ListResponse } from '../../../common/common.crud.types';
import { parseWithDecoratedError } from '../../../common/parseWithDecoratedError';
import { Resource_normalized } from '../../../resource/resource.types';
import { shortlistInternals } from '../../../shortlist/shortlist.hooks';
import { resourceShortlistKeys } from '../resource__shortlist.keys';
import {
  associationItemsSchema,
  AssociationQuery,
  querySchemas,
  ShortlistBlock_normalized
} from '../resource__shortlist.schemas';

export const createUseResourceShortlistAssociationQueryApi = (
  client: ApiClient,
  useEnsureShortlist: () => (
    id: Shortlist['id']
  ) => Promise<Shortlist_normalized>
) => {
  return () => {
    const queryClient = useQueryClient();
    const ensureShortlist = useEnsureShortlist();

    return {
      list: async (
        query: AssociationQuery
      ): Promise<
        ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
      > => {
        if ('shortlist_id' in query) {
          if (
            queryClient.getQueryState(resourceShortlistKeys.list(query))
              ?.isInvalidated
          ) {
            // this is a hack, we steal data from the shortlist query but it contains hidden association data that HAS TO BE refetched
            // this is needed since we have dependent queries with hidden data (blocks)
            // the proper way is to have a separate query for the blocks and shortlist
            queryClient.invalidateQueries({
              queryKey: shortlistInternals.keys.detail(query.shortlist_id)
            });
          }
          const response = await ensureShortlist(query.shortlist_id);

          const items = parseWithDecoratedError(
            associationItemsSchema,
            response
          );

          return {
            results: items,
            paging: {
              total: items.length,
              page: 0,
              count_per_page: items.length
            },
            query: query.query ?? {}
          };
        }
        if ('resource_id' in query) {
          const response = await client.get(
            `/contacts/${query.resource_id}/associations`,
            {
              token: true
            }
          );

          const shortlistHolderSchema = z
            .object({
              shortlist_blocks: z.array(
                z
                  .object({
                    shortlist: z.object({}).passthrough()
                  })
                  .transform(x => x.shortlist)
              )
            })
            .transform(x => x.shortlist_blocks);

          const extractedShortlist = parseWithDecoratedError(
            shortlistHolderSchema,
            response
          );

          extractedShortlist.forEach(shortlist => {
            shortlistInternals.updateCacheWithItem(
              queryClient,
              shortlist as Shortlist_normalized
            );
          });

          const results = parseWithDecoratedError(
            associationItemsSchema,
            response
          );
          return {
            results: results,
            paging: {
              total: results.length,
              page: 0,
              count_per_page: results.length
            },
            query: {}
          };
        }
        throw new Error('Invalid query');
      },
      search: async ({
        shortlist_id,
        query
      }: z.infer<typeof querySchemas.search.request>) => {
        const tweakedQuery = query ?? {};
        tweakedQuery.count ??= SHORTLIST_RESOURCE_MAX_COUNT;
        tweakedQuery.filters ??= {};
        const result = await client.post(
          `/shortlists/${shortlist_id}/contacts/search`,
          { data: tweakedQuery }
        ); // no token
        return parseWithDecoratedError(querySchemas.search.response, result);
      }
    };
  };
};

export type UseResourceShortlistAssociationQueryApi = ReturnType<
  typeof createUseResourceShortlistAssociationQueryApi
>;

export type ResourceShortlistAssociationQueryApi =
  ReturnType<UseResourceShortlistAssociationQueryApi>;
