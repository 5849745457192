//
// Helpers for the Resource/Contact object
//

import get from 'lodash/get';

/////////////////////////////////

/*
 *  GETTERS
 *  =======
 */

/**
 * @function getResourceDefaultRate
 * Returns default ContactRate for a resource
 *
 * @param {Object} resource - [Resource] - The resource
 * @returns {Object} - [ContactRate]
 */
export const getResourceDefaultRate = resource => {
  return get(resource, ['rates', 0]);
};
