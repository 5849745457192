import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FlyoverFooter.module.scss';

const FlyoverFooter = ({ className, children }) => (
  <div className={classnames(styles.FlyoverFooter, className)}>{children}</div>
);

FlyoverFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default FlyoverFooter;
