import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get } from 'lodash';

import {
  PressStud,
  ErrorMessage,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import {
  createExpenseType,
  updateExpenseType
} from 'store/v1/expense_types/expense_types.actions.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { EXPENSE_TYPES_ACTIONS } from 'store/v1/expense_types/expense_types.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import { validate as validator } from 'v1/helpers/validation';

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const ExpenseTypeCreateModal = () => {
  // REDUX
  const ui = useSelector(state => state.ui);
  const { expense_type = {}, create } = get(ui, 'data', {});

  const expense_types = useSelector(state => state.expense_types);
  const { loading, error } = expense_types || {};

  const dispatch = useDispatch();

  // PROPS AND REFS
  const {
    id,
    name
    // model, // TBC
    // internal, // TBC
    // icon, // TBC
    // metastructure // TBC
  } = expense_type;

  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent(
    [
      EXPENSE_TYPES_ACTIONS.CREATE_EXPENSE_TYPE,
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE
    ],
    {
      onSuccess: () => {
        dispatch(closeModal());
        dispatch(displayAlert('success', 'Updated succesfully'));
      },
      onFailure: () => dispatch(displayAlert('error', error))
    }
  );

  // METHODS
  function handleSubmit(values) {
    create
      ? dispatch(createExpenseType(values))
      : dispatch(updateExpenseType(id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} expense type`} />
      <ModalContent>
        <ModalScrollable>
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              name
              // model: model || "INDIVIDUAL",
              // internal,
              // icon: icon || "person",
              // metastructure
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form
                data-cy="expense-type-create-form"
                onSubmit={formApi.submitForm}
              >
                <Field
                  field="name"
                  validate={value => handleValidate(value, 'required')}
                >
                  {({ fieldName: name, value, error, setValue }) => (
                    <div className="stack-M">
                      <Label htmlFor={name}>Expense Type Name</Label>
                      <TextInput
                        name={name}
                        value={value}
                        placeholder="Name"
                        onChange={({ target }) => setValue(target.value)}
                        validity={error ? 'invalid' : null}
                        required
                        autoComplete="off"
                        autoFocus
                      />
                      {error && (
                        <ErrorMessage>This field is required</ErrorMessage>
                      )}
                    </div>
                  )}
                </Field>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Expense type' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === EXPENSE_TYPES_ACTIONS.CREATE_EXPENSE_TYPE ||
              loading === EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ExpenseTypeCreateModal;
