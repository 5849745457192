import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateContact } from 'store/v1/contacts/contacts.actions';

import { TextInput, PressStud, Label } from 'v1/components/shared';

const ResourceEmailAdd = ({ resource }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.contacts);
  const [email, setEmail] = useState();

  function onSaveEmail() {
    dispatch(
      updateContact(resource.id, { ...resource, emails: [{ value_1: email }] })
    );
  }

  return (
    <div className="ResourceEmailAdd">
      <div className="stack-XS">
        <Label>Resource email</Label>
        <TextInput
          size="S"
          id="email"
          placeholder="Enter email"
          name="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          required={true}
          autoComplete="off"
        />
      </div>
      <PressStud
        label="Save email"
        size="S"
        isLoading={loading === 'UPDATE_CONTACT'}
        appearance="primary"
        action={onSaveEmail}
      />
    </div>
  );
};

export default ResourceEmailAdd;
