import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, find, map, has } from 'lodash';
import classnames from 'classnames';
import { convertToWholeUnit } from 'v1/helpers/currencyHelper';
import { isoToFriendlyDate } from 'v1/helpers/byType/dateHelper';
import './SearchFilterItem.scss';

export class SearchFilterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customObject: props.customObject
    };
  }
  onRemove = () => this.props.onRemove(this.props.fieldKey, null, null);
  renderKey = () => {
    const { customObject } = this.state;
    const { fieldKey } = this.props;
    if (customObject) {
      return customObject.name;
    }
    if (fieldKey === 'resource_type_id') return 'Type';
    return fieldKey;
  };
  renderMethodLabel = (methods, filter) => {
    const { fieldKey } = this.props;
    return Object.keys(methods).map(method => {
      if (filter[method]) {
        let value;
        switch (fieldKey) {
          case 'rates':
            value = parseInt(convertToWholeUnit(filter[method]));
            break;
          case 'tags':
            value = filter[method].join(', ');
            break;
          case 'groups':
            value = filter[method]
              .map(group => get(this.props.groups, ['data', group, 'name']))
              .join(', ');
            break;
          default:
            value = filter[method];
        }
        return `${methods[method]} ${value}`;
      } else {
        return null;
      }
    });
  };

  renderValue = () => {
    const { customObject } = this.state;
    const { filter, fieldKey } = this.props;
    let value;

    if (fieldKey === 'resource_type_id') {
      return get(this.props.resourceTypes, ['data', filter.eq, 'name']);
    }

    if (fieldKey === 'availability') {
      return isoToFriendlyDate(
        { date1: filter.from_date, date2: filter.to_date },
        'DEFAULT_RANGE',
        'SHORT'
      );
    }

    if (fieldKey === 'tags') {
      const method = has(filter, 'match') ? 'match' : 'any';
      return (value = map(get(filter, method), t =>
        typeof t === 'string' ? t : t.tag
      )).join(', ');
    }

    switch (filter.custom_field_definition_type) {
      case 'SINGLE_SELECT':
        if (filter && filter.eq && Array.isArray(filter.eq)) {
          value = filter.eq.map(i => i).join(', ');
        }
        if (filter && filter.eq && typeof filter.eq === 'string') {
          value = filter.eq;
        }
        break;
      case 'CURRENCY':
        value = this.renderMethodLabel(
          { eq: 'Equals', gte: 'Greater than', lte: 'Less than' },
          filter
        );
        break;
      case 'NUMBER':
        value = this.renderMethodLabel(
          { eq: 'Equals', gte: 'Greater than', lte: 'Less than' },
          filter
        );
        break;
      case 'MULTI_SELECT':
        const method = has(filter, 'match') ? 'match' : 'any';
        const selected = filter[method].map(v => {
          // TODO: we need to decide on the type of v to avoid type checks like the one below
          let value;

          if (typeof v === 'string') {
            value = v;
          } else if (typeof v === 'object') {
            value = v.name;
          } else {
            value = v.tag;
          }

          const option = find(customObject.options, o => o.value === value); // TODO: Need to make cleaner currently listening to tag input with .tag
          return option && option.value;
        });
        value = selected.join(', ');
        break;
      default:
        value = this.renderMethodLabel(
          {
            eq: '',
            gte: 'Greater than',
            lte: 'Less than',
            match: ''
          },
          filter
        );
    }

    return value;
  };
  render() {
    return (
      <div
        className={classnames(['SearchFilterItem', 'Parent_hoverListener'], {
          slimline: this.props.slimline
        })}
      >
        <span className="SearchFilterItem-key">{this.renderKey()}</span>
        <span className="SearchFilterItem-value">{this.renderValue()}</span>
        {!this.props.lockedFields.includes(this.props.fieldKey) && (
          <span
            className="SearchFilterItem-remove Child_hoverListener"
            onClick={this.onRemove}
          >
            <img src="/images/icon_cross.svg" width="10px" alt="" />
          </span>
        )}
      </div>
    );
  }
}

SearchFilterItem.defaultProps = {
  lockedFields: []
};

SearchFilterItem.propTypes = {
  slimline: PropTypes.bool,
  filter: PropTypes.object.isRequired, // TODO: properties: custom_field_definition_type, ?
  fieldKey: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  customObject: PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.array
  }),
  lockedFields: PropTypes.array
};

const mapStateToProps = state => {
  return {
    resourceTypes: state.resource_types,
    groups: state.groups
  };
};

export default connect(mapStateToProps, null)(SearchFilterItem);
