import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { createContactPin } from 'store/v1/contacts/contacts.actions.js';

import MediaFromProfilePins from './MediaFromProfilePins/MediaFromProfilePins';

import get from 'lodash/get';
import './MediaFromProfile.scss';

const MediaFromProfile = ({ onSelect, contact_id }) => {
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);
  const appId = useSelector(state => state.auth.app_id);

  return (
    <div className="MediaFromProfile">
      <div className="MediaFromProfile-content">
        <MediaFromProfilePins
          contacts={contacts}
          contact={get(contacts, ['data', contact_id])}
          pins={get(contacts, ['associations_data', contact_id, 'pins'])}
          onSelect={onSelect}
          app_id={appId}
          createContactPin={data =>
            dispatch(createContactPin(data, contact_id))
          }
        />
      </div>
    </div>
  );
};

MediaFromProfile.propTypes = {
  onSelect: PropTypes.func.isRequired,
  contact_id: PropTypes.number.isRequired
};

export default MediaFromProfile;
