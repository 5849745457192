import { ReactNode } from 'react';
import Loading from 'v1/components/shared/placeholders/Loading/Loading';
import { DimmedContainer } from 'v4/shared/components/forms/input/primitvies/DimmedContainer';
import { SelectOptionEmpty } from 'v4/shared/components/forms/input/primitvies/SelectOptionEmpty';
import { SelectOptionError } from 'v4/shared/components/forms/input/primitvies/SelectOptionError';
import { RemoteData2 } from 'v4/shared/components/remoteData';

export interface OptionsLayoutProps<T> {
  optionsState: RemoteData2<T[]>;
  renderEmpty?: () => ReactNode;
  renderError?: (error: Error) => ReactNode;
  renderLoading?: () => ReactNode;
  renderOptions: (options: T[]) => ReactNode;
}

const defaultRenderers = {
  renderEmpty: () => <SelectOptionEmpty />,
  renderError: (error: Error) => <SelectOptionError message={error.message} />,
  renderLoading: () => <Loading />
};

export function OptionsLayout<T>({
  optionsState,
  renderEmpty = defaultRenderers.renderEmpty,
  renderError = defaultRenderers.renderError,
  renderLoading = defaultRenderers.renderLoading,
  renderOptions
}: OptionsLayoutProps<T>) {
  if (optionsState.status === 'pending') return renderLoading();

  if (optionsState.status === 'error')
    return (
      <DimmedContainer shouldDim={false}>
        {renderError(optionsState.error)}
      </DimmedContainer>
    );

  return (
    <DimmedContainer shouldDim={optionsState.isPlaceholderData}>
      {optionsState.data.length === 0
        ? renderEmpty()
        : renderOptions(optionsState.data)}
    </DimmedContainer>
  );
}
