import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCell, TextInputGroup } from 'v1/components/shared';

const HeightValue = ({ unitLabel, min, max, onChange, ...props }) => {
  const handleChange = value => {
    const parsedValue = parseInt(value) || 0;
    // Max & min properties directly on the text input do not seem to behave as expected.
    // Therefore, I have added this logic to introduce numerical boundaries.
    if (typeof max === 'number' && parsedValue > max) {
      return;
    }
    if (typeof min === 'number' && parsedValue < min) {
      return;
    }
    onChange(parsedValue);
  };
  return (
    <TextInputGroup
      type="number"
      append={{ text: unitLabel }}
      onChange={handleChange}
      {...props}
    />
  );
};

/**
 * A height input allows the user to input a value of height in feet and inches.
 * @param props
 * @returns {JSX.Element}
 */
const HeightInput = ({
  value,
  field,
  disabled,
  removeAutocomplete,
  appearance,
  size,
  onChange,
  onBlur
}) => {
  let feetValue = null,
    inchValue = null;

  // Convert the height from total inches into feet + inches.
  if (typeof value === 'number') {
    feetValue = Math.floor(value / 12);
    inchValue = value - feetValue * 12;
  }

  const handleChange = (fieldName, fieldValue) => {
    // Convert the feet + inches into inches only.
    let inches = null;
    switch (fieldName) {
      case 'feet':
        inches = fieldValue * 12 + inchValue;
        break;
      case 'inch':
        inches = feetValue * 12 + fieldValue;
        break;
      default:
        console.error(`unexpected height input name: ${fieldName}`);
    }

    onChange(field, inches);
  };

  return (
    <Grid gutters="M">
      <GridCell>
        <HeightValue
          unitLabel="Ft"
          placeholder="Enter feet"
          value={feetValue}
          min={0}
          onBlur={onBlur}
          onChange={value => handleChange('feet', value)}
          disabled={disabled}
          removeAutocomplete={removeAutocomplete}
          appearance={appearance}
          size={size}
        />
      </GridCell>
      <GridCell>
        <HeightValue
          unitLabel="In"
          placeholder="Enter inches"
          value={inchValue}
          max={12}
          min={0}
          onBlur={onBlur}
          onChange={value => handleChange('inch', value)}
          disabled={disabled}
          removeAutocomplete={removeAutocomplete}
          appearance={appearance}
          size={size}
        />
      </GridCell>
    </Grid>
  );
};

HeightInput.defaultProps = {
  appearance: 'outline',
  removeDefaultStyle: true,
  removeAutocomplete: true
};

HeightInput.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  appearance: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  removeAutocomplete: PropTypes.bool
};

export default HeightInput;
