import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// ACTIONS
import { searchContacts } from 'store/v1/contacts/contacts.actions.js';

// COMPONENTS
import { ListCellGroup, Loading } from 'v1/components/shared';

// LIBRARIES
import { get, map } from 'lodash';

const ResourcesList = ({
  query,
  filterResources = [],
  children,
  localStore
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);

  // NOTE: LOCAL STORE
  // In order for local search within components to not freak out if a search is done somewhere else we need
  // to segment out the id's of those results and pick out the results from the data store.
  // TODO: Create a unified system across our other local store set ups.

  const resultIds = get(contacts, ['local_store', localStore], []);

  useEffect(() => {
    if (query) {
      dispatch(searchContacts(query, null, null, localStore));
    }
  }, [query, dispatch, localStore]);

  function renderResourceResults() {
    const resourcefilteredIds = resultIds.filter(
      id => !filterResources.includes(id)
    );
    const resource_filteredList = map(resourcefilteredIds, id =>
      get(contacts, ['data', id])
    );
    return children({ resourcesResults: resource_filteredList });
  }
  if (contacts.loading === localStore) return <Loading />;
  return <ListCellGroup>{renderResourceResults()}</ListCellGroup>;
};

ResourcesList.propTypes = {
  query: PropTypes.object
};

export default ResourcesList;
