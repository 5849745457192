import superagent from 'superagent';
import Env from 'lib/env/Env';
import Account from 'lib/auth/Account';
import AuthService from 'lib/auth/AuthService';

const sessionIdName = 'X-Session-Id';

const methods = ['get', 'post', 'put', 'patch', 'del'];

export default class ApiClient {
  constructor() {
    this.app_id = null;

    methods.forEach(method => {
      this[method] = (
        path,
        { params, data, token, isV2 = false, signal } = {}
      ) =>
        new Promise((resolve, reject) => {
          if (signal) {
            signal.addEventListener('abort', () => {
              request.abort();
            });
          }
          const request = superagent[method](this.formatUrl(path, isV2));
          if (window.sessionId && !isV2) {
            request.set(sessionIdName, window.sessionId);
          }
          if (params) {
            request.query(params);
          }
          if (data) {
            request.send(data);
          }

          if (token) {
            if (typeof token === 'string') {
              if (isV2) {
                request.set('Authorization', `Bearer ${token}`);
              } else {
                request.set('Authorization', token);
              }
            } else {
              const token = AuthService.token();
              if (!token || !token.tokenString) {
                console.error(
                  'expected non-empty token or tokenString for request header'
                );
              } else {
                if (isV2) {
                  request.set('Authorization', `Bearer ${token.tokenString}`);
                } else {
                  request.set('Authorization', token.tokenString);
                }
              }
            }
          }

          request.end((err, { body } = {}) => {
            if (err) {
              const error = err ? body || err : body;
              error.status = err.status;

              // TODO: Determine a less aggressive approach with logout here
              if (error.status === 401) {
                return AuthService.logout();
              }

              reject(error);
            }
            resolve(body);
          });
        });
    });
  }

  formatUrl = (path, isV2) => {
    if (path.startsWith('http')) return path;

    let dbSchema = this.app_id;

    // If the app ID (db schema) has not been set, use the workspace DB schema
    if (!dbSchema) {
      const workspace = Account.resolveFirstWorkspace();
      if (workspace) {
        dbSchema = workspace.db_schema;
      }
    }

    // if the app ID (db schema) has not been set, the user must not be logged
    // in. This happens on public pages e.g. callsheet.
    if (!dbSchema) {
      const { pathname } = window.location;
      dbSchema = this.app_id || pathname.split('/')[2];
    }

    if (!dbSchema) {
      return path;
    }

    let formattedUrl = '';
    if (isV2) {
      formattedUrl = `${Env.apiV2URL}${path}`;
      formattedUrl = formattedUrl.replace(':app_id:', dbSchema);
    } else {
      formattedUrl = `${Env.workspacesApiURL}/${dbSchema}${path}`;
    }
    return formattedUrl;
  };

  setAppId = app_id => (this.app_id = app_id);
}
