import { createSelector } from 'reselect';

import {
  buildLocalAvailability,
  buildSystemAvailability
} from './availabilities.builder.js';

const deleteableAvailabilities = state => state.availabilities.deleted;
const localAvailabilities = state => state.availabilities.local;
const systemAvailabilities = state => state.availabilities.availabilities;

export const getDeleteableAvailabilitiesIds = createSelector(
  deleteableAvailabilities,
  availabilities =>
    availabilities.map(availability => ({
      availabilityID: availability.availabilityId
    }))
);

export const getLocalAvailabilities = createSelector(
  localAvailabilities,
  availabilities =>
    availabilities.map(availability => buildLocalAvailability(availability))
);

export const getUpdatedSystemAvailabilities = createSelector(
  systemAvailabilities,
  availabilities =>
    availabilities
      .filter(availability => availability.dirty === true)
      .map(availability => buildSystemAvailability(availability))
);

export const getCreateOrDeleteAvailabilities = createSelector(
  getDeleteableAvailabilitiesIds,
  getLocalAvailabilities,
  getUpdatedSystemAvailabilities,
  (deleteableAvailabilities, localAvailabilities, systemAvailabilities) => ({
    ...(localAvailabilities && { create: localAvailabilities }),
    ...(systemAvailabilities && { update: systemAvailabilities }),
    ...(deleteableAvailabilities && { delete: deleteableAvailabilities })
  })
);
