import React from 'react';
import PropTypes from 'prop-types';

import {
  createContactDocument,
  deleteContactDocument
} from 'store/v1/contacts/contacts.actions.js';

import { Files } from 'v1/components/shared';

/**
 * @desc `Files` wrapped for Resources
 */
const ResourceFiles = ({ className, resource = {}, disabled }) => (
  <Files
    className={className}
    entityId={resource.id}
    entityType="contacts"
    contentType="contact"
    contentName={resource.first_name}
    createDocument={createContactDocument}
    deleteDocument={deleteContactDocument}
    disabled={disabled}
  />
);

ResourceFiles.props = {
  className: PropTypes.string,
  resource: PropTypes.object,
  disabled: PropTypes.bool
};

export default ResourceFiles;
