import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ErrorMessage.scss';

const ErrorMessage = ({ className, children, appearance, ...props }) => {
  return (
    <p
      className={classnames(
        { [`ErrorMessage_appearance-${appearance}`]: appearance },
        'ErrorMessage',
        className
      )}
      {...props}
    >
      {children}
    </p>
  );
};

ErrorMessage.defaultProps = {
  appearance: 'text'
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  appearance: PropTypes.oneOf(['block', 'text'])
};

export default ErrorMessage;
