import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  GridCell,
  UserCircleImage,
  ButtonToggle,
  ButtonToggleGroup
} from 'v1/components/shared';
import EventConflict from '../EventConflict/EventConflict';
import { getBookingError } from 'v1/helpers/bookingHelper';
import { parseInitials } from 'v1/helpers/misc';
import classnames from 'classnames';
import _keyBy from 'lodash/keyBy';

import './BookingConflict.scss';

const BookingConflict = ({
  prevBooking,
  newBooking,
  contact,
  production,
  allowCancellation,
  onChange
}) => {
  const productions = useSelector(state => state.productions);

  const prevAssignmentsMap = _keyBy(
    prevBooking.resource_slot_assignments,
    'id'
  );
  const bookingError = getBookingError(newBooking, {
    ...productions.data,
    [production.id]: production
  });

  const [
    productionAssignments,
    otherAssignments
  ] = newBooking.resource_slot_assignments.reduce(
    (result, assignment) => {
      return assignment.resource_slot.production_id === production.id
        ? [result[0].concat(assignment), result[1]]
        : [result[0], result[1].concat(assignment)];
    },
    [[], []]
  );

  return (
    <div
      className={classnames(
        'BookingConflict',
        newBooking._action === 'DELETE' && 'discarded'
      )}
    >
      <Grid gutters="S" vcenter spread>
        <GridCell className="BookingConflict-contact" width="initial">
          <UserCircleImage
            src={null}
            className="BookingConflict-image"
            initials={parseInitials(contact.full_name)}
            contact_id={contact.id}
          />
          {contact.full_name}
        </GridCell>
        <GridCell width="initial">
          <ButtonToggleGroup
            onChange={_action => onChange({ ...newBooking, _action })}
            value={newBooking._action}
          >
            <ButtonToggle
              value="UPDATE"
              label="Update"
              icon="/images/icon_tick_white.svg"
            />
            {allowCancellation && (
              <ButtonToggle
                value="DELETE"
                label="Delete"
                icon="/images/icon_tick_white.svg"
              />
            )}
          </ButtonToggleGroup>
        </GridCell>
      </Grid>
      {bookingError && newBooking._action !== 'DELETE' && (
        <Grid className="BookingConflict-error">
          <GridCell width="initial">
            <img src="/images/icon-warning.svg" width="10px" alt="" />
          </GridCell>
          <GridCell width="initial">
            Seems like some assignments dates aren't included in the booking or
            production.
          </GridCell>
        </Grid>
      )}
      <hr />
      <h3>Booking</h3>
      <EventConflict
        evt={newBooking.events}
        prevEvt={prevBooking.events}
        onChange={events => onChange({ ...newBooking, events })}
        isDiscared={newBooking._action === 'DELETE'}
      />
      {productionAssignments.length > 0 && <h3>Production Assignments</h3>}
      {productionAssignments.map(assignment => (
        <div className="BookingConflict-assignment" key={assignment.id}>
          <EventConflict
            evt={assignment.events}
            prevEvt={prevAssignmentsMap[assignment.id].events}
            showConflict
            onChange={events =>
              onChange({
                ...newBooking,
                resource_slot_assignments: newBooking.resource_slot_assignments.map(
                  a => (a.id == assignment.id ? { ...a, events } : a)
                )
              })
            }
            isDiscared={
              newBooking._action === 'DELETE' || assignment._action === 'DELETE'
            }
          />
          <ButtonToggleGroup
            onChange={_action =>
              onChange({
                ...newBooking,
                resource_slot_assignments: newBooking.resource_slot_assignments.map(
                  a => (a.id == assignment.id ? { ...a, _action } : a)
                )
              })
            }
            value={assignment._action}
          >
            <ButtonToggle value="UPDATE" label="Update" />
            {allowCancellation === true && (
              <ButtonToggle value="DELETE" label="Delete" />
            )}
          </ButtonToggleGroup>
        </div>
      ))}

      {otherAssignments.length > 0 && <h3>Other assignments</h3>}
      {otherAssignments.map(assignment => (
        <div className="BookingConflict-assignment" key={assignment.id}>
          <EventConflict
            evt={assignment.events}
            prevEvt={assignment.events}
            showConflict={false}
            isDiscared={
              newBooking._action === 'DELETE' || assignment._action === 'DELETE'
            }
          />
        </div>
      ))}
    </div>
  );
};

export default BookingConflict;
