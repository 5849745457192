import React, { useEffect, useContext } from 'react';
import classnames from 'classnames';

import ModalContext from 'modals/ModalContext';
import ModalClose from './ModalClose/ModalClose';
import ModalContent from './ModalContent/ModalContent';
import ModalScrollable from './ModalScrollable/ModalScrollable';
import ModalHeader from './ModalHeader/ModalHeader';
import ModalToolbar from './ModalToolbar/ModalToolbar';

import styles from './Modal.module.scss';

type ModalProps = {
  size?: 'xs' | 's' | 'm' | 'l';
  hideClose?: boolean;
  onCancel?(): void;
  onClose?(): void;
  children?: React.ReactNode;
};

type ModalSubComponents = {
  Content: typeof ModalContent;
  Scrollable: typeof ModalScrollable;
  Header: typeof ModalHeader;
  Toolbar: typeof ModalToolbar;
};

const Modal: React.FC<ModalProps> & ModalSubComponents = ({
  size = 'm',
  hideClose,
  onCancel,
  onClose,
  children
}: ModalProps) => {
  const { setCustomCancel, setCustomClose } = useContext(ModalContext);

  useEffect(() => {
    onCancel && setCustomCancel(onCancel);
    onClose && setCustomClose(onClose);
  }, []);

  return (
    <div className={classnames(styles.Modal, [styles[size]])}>
      {!hideClose && <ModalClose />}
      {children}
    </div>
  );
};

Modal.Content = ModalContent;
Modal.Scrollable = ModalScrollable;
Modal.Header = ModalHeader;
Modal.Toolbar = ModalToolbar;

export default Modal;
