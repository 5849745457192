import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

//const prefix = '@@ui';

export const UI_ACTIONS = mapValues(
  mirror({
    OPEN_MODAL: null,
    CLOSE_MODAL: null,

    OPEN_WALKTHROUGH: null,
    CLOSE_WALKTHROUGH: null,

    DISPLAY_ALERT: null,
    HIDE_ALERT: null,

    TOGGLE_SIDEBAR: null,

    OPEN_CONTACT_SIDEBAR: null,
    CLOSE_CONTACT_SIDEBAR: null,

    OPEN_PRODUCTION_SIDEBAR: null,
    CLOSE_PRODUCTION_SIDEBAR: null,

    OPEN_BOOKING_SIDEBAR: null,
    CLOSE_BOOKING_SIDEBAR: null
  }),
  value => value
);
