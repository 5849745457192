import PropTypes from 'prop-types';

import TypeFormComponent from 'v1/components/shared/__types__/TypeFormComponent';

const TypeTextInput = {
  ...TypeFormComponent,
  // Component props
  align: PropTypes.oneOf(['left', 'center', 'right']),
  validity: PropTypes.oneOf(['valid', 'invalid']),
  removeAutocomplete: PropTypes.bool,
  initialFocus: PropTypes.bool,
  highlightOnFocus: PropTypes.bool,
  overlayLabel: PropTypes.string,
  overlayTooltip: PropTypes.string,
  overlayAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string
  // ...props
  // All valid attributes for <input /> excluding type="checkbox|radio"
  // Keeping in mind React-specific attribute case:
  // https://react-cn.github.io/react/docs/tags-and-attributes.html
};

export default TypeTextInput;
