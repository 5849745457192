import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import clickOutside from 'v1/helpers/clickOutsideDecorator';
import './EmojiInput.scss';

export class EmojiInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || ''
    };
  }
  onRemoveEmoji = () => {
    this.setState(
      {
        selected: null,
        showPicker: false
      },
      () => {
        this.props.onChange(null, this.props.field);
      }
    );
  };
  onClickOutside() {
    if (this.state.showPicker) {
      return this.setState({ showPicker: false });
    }
  }
  onEmojiSelect = selected => {
    this.setState(
      {
        selected: selected.native,
        showPicker: false
      },
      () => {
        this.props.onChange(selected.native, this.props.field);
      }
    );
  };
  renderSelectedEmoji = () => {
    return (
      <div
        onClick={() => {
          this.setState({ showPicker: !this.state.showPicker });
        }}
        className="EmojiInput-selected"
      >
        {this.state.selected}
      </div>
    );
  };
  renderEmojiPicker = () => {
    if (this.state.showPicker) {
      return (
        <div className="EmojiInput-picker">
          <Picker onSelect={this.onEmojiSelect} set="emojione" />
        </div>
      );
    }
  };
  renderRemoveEmoji = () => (
    <span className="EmojiInput-remove" onClick={this.onRemoveEmoji}>
      Remove
    </span>
  );
  renderLabel = label =>
    label && <span className="form-label">{label || ''}</span>;
  render() {
    const { label } = this.props;
    return (
      <div className="EmojiInput">
        {this.renderSelectedEmoji()}
        {this.state.selected
          ? this.renderRemoveEmoji()
          : this.renderLabel(label)}
        {this.renderEmojiPicker()}
      </div>
    );
  }
}

EmojiInput.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired // TODO: arguments
};

export default clickOutside(EmojiInput);
