/**
 * @function convertInchesToFeet
 * Converts inch value to feet and inches
 *
 * @param {Number} value - [Value] - The value in inches
 */
export function convertInchesToFeet(value) {
  if (!value) return '';
  let feetValue = null,
    inchValue = null;

  // Convert the height from total inches into feet + inches.
  if (typeof value === 'number') {
    feetValue = Math.floor(value / 12);
    inchValue = value - feetValue * 12;
  }
  return { feetValue, inchValue };
}

/**
 * @function displayHeight
 * Converts height value into text display
 *
 * @param {Number} value - [Value] - inches amount
 */
export function displayHeight(value) {
  const heightValue = convertInchesToFeet(value);
  return `${heightValue.feetValue ? `${heightValue.feetValue}ft` : '--'} ${
    heightValue.inchValue ? `${heightValue.inchValue}in` : ''
  }`;
}

/**
 * @function displayMeasurement
 * Converts measurement object into text display
 *
 * @param {Object} value - [Value] - {amount: NUMBER, unit: STRING}
 */
export function displayMeasurement(value) {
  if (!value || !value.amount) return '';
  return `${value.amount}${value.unit}`;
}

/**
 * @function checkDimensionValueExists
 * Checks the validity of a dimension value by the amount and unit
 *
 * @param {Object} value - [Value] - {amount: NUMBER, unit: STRING}
 * @returns
 */
export function checkDimensionValueExists(value) {
  return value && (value.amount || typeof value.amount === 'number');
}

/**
 * @function displayDimension
 * Converts 3D or 2D Dimension value into text display
 *
 * @param {String} value - [Value] - Stringified object that needs to be parsed back into JSON
 */
export function displayDimension(value) {
  if (!value) return '';
  const values = JSON.parse(value || '{}');

  return Object.values(values)
    .filter(v => checkDimensionValueExists(v || {}))
    .map(v => `${v.amount}${v.unit || ''}`)
    .join(' by ');
}
