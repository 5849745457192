import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import { GROUP_ACTIONS } from 'store/v1/groups/groups.constants.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import { TAGS_ACTIONS } from 'store/v1/tags';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import {
  cloneDeep,
  merge,
  uniq,
  findIndex,
  get,
  find,
  keys,
  filter,
  isEmpty,
  setWith
} from 'lodash';
import fuzzy from 'v1/helpers/fuzzy';

// TODO: This contacts store needs a huge clean.

export const INITIAL_STATE = {
  data: {},
  related_contacts: {},
  associations_data: {},
  events_data: {},
  paging: {},
  order: [],
  locations_order: [],
  local_store: {},
  quick_search_results: [],
  query: { order_by: { field: 'created_at', direction: 'desc' } },
  duplicates: [],
  new: {},
  socials_data: {},
  suggested_data: [],
  suggestions: {},
  field_suggestions: {},
  groups: {},
  url_scrape_data: {},
  loading: false,
  forceContactHydrate: false,
  lastContactIDUpdated: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTACTS_ACTIONS.GET_CONTACTS:
    case CONTACTS_ACTIONS.GET_SEARCH_SUGGESTIONS:
    case CONTACTS_ACTIONS.GET_CONTACT:
    case CONTACTS_ACTIONS.GET_BATCH_CONTACTS:
    case CONTACTS_ACTIONS.GET_CONTACT_ASSOCIATIONS:
    case CONTACTS_ACTIONS.GET_CONTACT_REPRESENTEES:
    case CONTACTS_ACTIONS.GET_CONTACT_PINS:
    case CONTACTS_ACTIONS.GET_CONTACT_SCRAPE:
    case CONTACTS_ACTIONS.GET_CONTACT_EVENTS:
    case CONTACTS_ACTIONS.GET_GROUPS:
    case CONTACTS_ACTIONS.CREATE_CONTACT:
    case CONTACTS_ACTIONS.CREATE_CONTACT_NOTE:
    case CONTACTS_ACTIONS.CREATE_CONTACT_DOCUMENT:
    case CONTACTS_ACTIONS.CREATE_CONTACT_AVAILABILITY:
    case CONTACTS_ACTIONS.CREATE_CONTACT_PIN:
    case CONTACTS_ACTIONS.UPDATE_CONTACT_PIN:
    case CONTACTS_ACTIONS.UPDATE_CONTACT_PINS:
    case CONTACTS_ACTIONS.CREATE_BULK_CONTACTS:
    case CONTACTS_ACTIONS.CREATE_GROUP:
    case CONTACTS_ACTIONS.UPDATE_CONTACT:
    case CONTACTS_ACTIONS.UPDATE_GDPR_BASIS:
    case CONTACTS_ACTIONS.UPDATE_GDPR_REQUESTED_DATES:
    case CONTACTS_ACTIONS.ARCHIVE_CONTACT:
    case CONTACTS_ACTIONS.ARCHIVE_CONTACT_BATCH:
    case CONTACTS_ACTIONS.UNARCHIVE_CONTACT:
    case CONTACTS_ACTIONS.DELETE_GDPR_CONTACT:
    case CONTACTS_ACTIONS.DELETE_CONTACT_NOTE:
    case CONTACTS_ACTIONS.DELETE_CONTACT_DOCUMENT:
    case CONTACTS_ACTIONS.DELETE_CONTACT_AVAILABILITY:
    case CONTACTS_ACTIONS.DELETE_CONTACT_PIN:
    case CONTACTS_ACTIONS.DOWNLOAD_GDPR_DATA:
    case CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS:
    case CONTACTS_ACTIONS.GET_CONTACTS_QUICK:
    case CONTACTS_ACTIONS.CHECK_CONTACT_DUPLICATES:
    case CONTACTS_ACTIONS.BATCH_GET_AVAILABILITY:
      return {
        ...state,
        loading: action.localStore || action.type, // TODO: We need an elegant way to handle loading states outside redux types. This conditional temporarily allows us to assign a unique string so the relevant component can listen to the loading state.
        error: false,
        forceContactHydrate: false,
        suggestions:
          action.type === 'GET_SEARCH_SUGGESTIONS' ? [] : state.suggestions
      };
    case CONTACTS_ACTIONS.GET_CONTACTS_SUCCESS:
      let order = [...state.order];
      let locations_order = [...state.locations_order];
      const ids = action.result.results.map(item => item.id);

      if (action.use === 'locations')
        locations_order =
          action.p > 0 ? uniq(locations_order.concat(ids)) : ids;
      else order = action.p > 0 ? uniq(order.concat(ids)) : ids;

      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        order: action.localStore ? state.order : order,
        locations_order,
        local_store: action.localStore
          ? {
              ...state.local_store,
              [action.localStore]: ids
            }
          : state.local_store,
        paging: action.result.paging,
        loading: false,
        error: false
      };
    case CONTACTS_ACTIONS.GET_CONTACTS_QUICK_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        quick_search_results: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        error: false
      };
    case AUTH_ACTIONS.LOAD_AUTH_SUCCESS:
      const activeResources = get(action.result, ['resource_types']);
      const activeResource = find(activeResources, r => r.order === 0);
      return {
        ...state,
        query: activeResource
          ? setWith(
              cloneDeep(state.query),
              'filters.resource_type_id.eq',
              activeResource.id,
              cloneDeep
            )
          : state.query
      };
    case BOOKING_ACTIONS.GET_BATCH_BOOKINGS_SUCCESS:
      if (!action.hydrateContactStore) return state; // Only hydrate contacts store if explicitly asked

      const bookings = action.result.data || [];
      let loadedContacts = {};

      bookings.map(booking => {
        loadedContacts = {
          ...loadedContacts,
          [booking.contact_id]: booking.contact
        };
      });

      return {
        ...state,
        data: { ...state.data, ...loadedContacts },
        loading: false
      };
    case CONTACTS_ACTIONS.GET_BATCH_CONTACTS_SUCCESS:
      return {
        ...state,
        data: action.result.data.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        loading: false
      };
    case CONTACTS_ACTIONS.BATCH_GET_AVAILABILITY_SUCCESS:
      return {
        ...state,
        associations_data: state.associations_data
      };
    case CONTACTS_ACTIONS.GET_SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        suggestions: {
          ...action.result,
          skills: fuzzy
            .filter(action.query, state.field_suggestions.skills || [])
            .map(e => ({ skill: e.string })),
          tags: fuzzy
            .filter(action.query, state.field_suggestions.tags || [])
            .map(e => ({ tag: e.string }))
        }
      };
    case CONTACTS_ACTIONS.CHECK_CONTACT_DUPLICATES_SUCCESS:
      return {
        ...state,
        duplicates: get(action, 'result.results'),
        loading: false
      };
    case CONTACTS_ACTIONS.CLEAR_CONTACT_DUPLICATES:
      return {
        ...state,
        duplicates: [],
        loading: false
      };
    case CONTACTS_ACTIONS.GET_CONTACT_SUCCESS:
    case CONTACTS_ACTIONS.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        lastContactIDUpdated: get(action, 'result.id'),
        error: false,
        forceContactHydrate: action.forceContactHydrate
      };

    case CONTACTS_ACTIONS.GET_CONTACT_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associations_data: {
          ...state.associations_data,
          [action.contact_id]: action.result
        },
        loading: false
      };
    case SHORTLISTS_ACTIONS.DELETE_SHORTLIST_BLOCK_SUCCESS: {
      let shortlist_blocks = get(state, [
        'associations_data',
        action.contact_id,
        'shortlist_blocks'
      ]);
      let updatedShortlistBlocks = filter(
        shortlist_blocks,
        b => b.id != action.shortlist_block_id
      );
      return {
        ...state,
        associations_data: {
          ...state.associations_data,
          [action.contact_id]: {
            ...state.associations_data[action.contact_id],
            shortlist_blocks: updatedShortlistBlocks
          }
        }
      };
    }
    case SHORTLISTS_ACTIONS.CREATE_SHORTLIST_BLOCK_SUCCESS: {
      if (!isEmpty(state.associations_data[action.result.contact_id])) {
        return {
          ...state,
          associations_data: {
            ...state.associations_data,
            [action.result.contact_id]: {
              ...state.associations_data[action.result.contact_id],
              shortlist_blocks: [
                { ...action.result, shortlist: action.shortlist },
                ...state.associations_data[action.result.contact_id]
                  .shortlist_blocks
              ]
            }
          }
        };
      }
      return state;
    }
    case CONTACTS_ACTIONS.GET_CONTACT_REPRESENTEES_SUCCESS:
      const relatedContacts = action.result.results.reduce(
        (data, item) => ({ ...data, [item.id]: item }),
        {}
      );
      return {
        ...state,
        related_contacts: {
          ...state.related_contacts,
          [action.contact_id]: action.result.results.map(r => r.id)
        },
        data: merge({}, state.data, relatedContacts),
        loading: false
      };
    case CONTACTS_ACTIONS.GET_CONTACT_PINS_SUCCESS:
    case CONTACTS_ACTIONS.CREATE_CONTACT_NOTE_SUCCESS:
    case CONTACTS_ACTIONS.CREATE_CONTACT_PIN_SUCCESS:
    case CONTACTS_ACTIONS.CREATE_CONTACT_DOCUMENT_SUCCESS:
    case CONTACTS_ACTIONS.CREATE_CONTACT_AVAILABILITY_SUCCESS: {
      const selector = {
        CREATE_CONTACT_NOTE_SUCCESS: 'notes',
        CREATE_CONTACT_PIN_SUCCESS: 'pins',
        CREATE_CONTACT_DOCUMENT_SUCCESS: 'documents',
        CREATE_CONTACT_AVAILABILITY_SUCCESS: 'availability_requests'
      }[action.type];

      const list = [
        action.result,
        ...state.associations_data[action.contact_id][selector]
      ];

      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.contact_id}.${selector}`,
          list,
          cloneDeep
        )
      };
    }
    case CONTACTS_ACTIONS.GET_CONTACT_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events_data: {
          ...state.events_data,
          [action.contact_id]: action.result.events.filter(e => !e.archived)
        }
      };
    case CONTACTS_ACTIONS.UPDATE_CONTACT_PINS_SUCCESS:
      const existingPins = get(state.associations_data, [
        action.contact_id,
        'pins'
      ]);
      const updatedPins = action.result.map(pin => {
        const resultPin = find(existingPins, p => p.id === pin.id);
        return { ...resultPin, order: pin.order };
      });
      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.contact_id}.pins`,
          updatedPins,
          cloneDeep
        )
      };
    case CONTACTS_ACTIONS.UPDATE_CONTACT_PIN_SUCCESS:
      let pins = state.associations_data[action.contact_id].pins ?? [];
      const pinIndex = findIndex(pins, p => p.id === action.pin_id);

      pins[pinIndex] = action.result;
      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.contact_id}.pins`,
          pins,
          cloneDeep
        )
      };
    case CONTACTS_ACTIONS.DELETE_CONTACT_NOTE_SUCCESS:
    case CONTACTS_ACTIONS.DELETE_CONTACT_PIN_SUCCESS:
    case CONTACTS_ACTIONS.DELETE_CONTACT_DOCUMENT_SUCCESS:
    case CONTACTS_ACTIONS.DELETE_CONTACT_AVAILABILITY_SUCCESS: {
      const selector = {
        DELETE_CONTACT_NOTE_SUCCESS: 'notes',
        DELETE_CONTACT_PIN_SUCCESS: 'pins',
        DELETE_CONTACT_DOCUMENT_SUCCESS: 'documents',
        DELETE_CONTACT_AVAILABILITY_SUCCESS: 'availability_requests'
      }[action.type];

      const list = state.associations_data[action.contact_id][selector].filter(
        n => n.id !== action.association_id
      );

      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.contact_id}.${selector}`,
          list,
          cloneDeep
        ),
        error: false
      };
    }

    case CONTACTS_ACTIONS.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        local_store: action.localStore
          ? {
              ...state.local_store,
              [action.localStore]: [
                ...state.local_store[action.localStore],
                action.result.id
              ]
            }
          : state.local_store,
        duplicates: [],
        order: [action.result.id, ...state.order],
        new: action.result,
        error: false
      };
    case CONTACTS_ACTIONS.ARCHIVE_CONTACT_SUCCESS:
      const { [action.contact_id]: item, ...data } = state.data;
      return {
        ...state,
        loading: false,
        data,
        order: state.order.filter(i => i !== action.contact_id),
        error: false
      };
    case CONTACTS_ACTIONS.ARCHIVE_CONTACT_BATCH_SUCCESS:
      const omits = action.contact_ids;
      const updatedData = omits.reduce((acc, val) => {
        const { [val]: v, ...rest } = acc;
        return rest;
      }, state.data);
      return {
        ...state,
        loading: false,
        data: updatedData,
        order: state.order.filter(id => !omits.includes(id)),
        error: false
      };
    case CONTACTS_ACTIONS.UNARCHIVE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        order: [action.result.id, ...state.order],
        error: false
      };
    case CONTACTS_ACTIONS.GET_CONTACT_SCRAPE_SUCCESS:
      return {
        ...state,
        loading: false,
        url_scrape_data: {
          ...state.url_scrape_data,
          [action.contact_id]: action.result
        }
      };

    case CONTACTS_ACTIONS.GET_SOCIAL_DATA:
      return {
        ...state,
        socials_data: {
          ...state.socials_data,
          [`${action.provider}.${action.handle}`]: {
            loading: true
          }
        }
      };
    case CONTACTS_ACTIONS.GET_SOCIAL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        socials_data: {
          ...state.socials_data,
          [`${action.provider}.${action.handle}`]: {
            ...action.result.data,
            provider: action.provider
          }
        }
      };
    case CONTACTS_ACTIONS.GET_SOCIAL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        socials_data: {
          ...state.socials_data,
          [`${action.provider}.${action.handle}`]: {
            error: action.error
          }
        }
      };

    case CONTACTS_ACTIONS.SET_ONBOARD_DATA:
      return {
        ...state,
        onboard_csv_data: action.data,
        onboard_csv_url: action.url
      };

    case CONTACTS_ACTIONS.CREATE_BULK_CONTACTS_SUCCESS:
    case CONTACTS_ACTIONS.DELETE_GDPR_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case CONTACTS_ACTIONS.UPDATE_GDPR_BASIS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.contact_id]: {
            ...state.data[action.contact_id],
            gdpr: action.result.gdpr
          }
        },
        loading: false,
        error: false
      };
    case CONTACTS_ACTIONS.UPDATE_GDPR_REQUESTED_DATES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.contact_id]: {
            ...state.data[action.contact_id],
            gdpr: action.result.gdpr
          }
        },
        loading: false,
        error: false
      };
    case CONTACTS_ACTIONS.DOWNLOAD_GDPR_DATA_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case CONTACTS_ACTIONS.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.result,
        loading: false
      };
    case CONTACTS_ACTIONS.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: { groups: action.result },
        loading: false
      };

    /*case CONTACTS_ACTIONS.CREATE_CONTACT_EVENT_SUCCESS: {
      let events = get(state.events_data, [action.contact_id]) || [];
      events = events.concat(action.result);
      return {
        ...state,
        events_data: set(state.events_data, `${action.contact_id}`, events),
        loading: false
      };
    }*/
    /*case CONTACTS_ACTIONS.UPDATE_CONTACT_EVENT_SUCCESS: {
      const events = state.events_data[action.contact_id].map(e =>
        e.id == action.result.id ? action.result : e
      );
      return {
        ...state,
        events_data: set(state.events_data, `${action.contact_id}`, events),
        loading: false
      };
    }*/
    /*case CONTACTS_ACTIONS.DELETE_CONTACT_EVENT_SUCCESS: {
      const events = state.events_data[action.contact_id].filter(
        e => e.id != action.contact_event_id
      );
      return {
        ...state,
        events_data: set(state.events_data, `${action.contact_id}`, events),
        loading: false
      };
    }*/

    case BOOKING_ACTIONS.CREATE_BOOKING_SUCCESS:
    case BOOKING_ACTIONS.UPDATE_BOOKING_SUCCESS: {
      const bookingEvent = get(action.result, 'events[0]');
      if (!bookingEvent) return state;
      let found = false;
      const temp_events = (
        state.events_data[action.result.contact_id] || []
      ).map(e => {
        if (e.id === bookingEvent.id) {
          found = true;
          return bookingEvent;
        }
        return e;
      });
      const events = found ? temp_events : temp_events.concat(bookingEvent);
      return {
        ...state,
        events_data: {
          ...state.events_data,
          [action.result.contact_id]: events
        },
        loading: false
      };
    }
    case BOOKING_ACTIONS.DELETE_BOOKING_SUCCESS: {
      const updatedEvents = filter(
        get(state.events_data, [action.contact_id]),
        event => event.entity_id !== action.booking_id
      );
      return {
        ...state,
        events_data: {
          ...state.events_data,
          [action.result.contact_id]: updatedEvents
        },
        loading: false
      };
    }
    case GROUP_ACTIONS.ADD_TO_GROUP_SUCCESS:
      if (action.action === 'remove') {
        if (action.contact_ids) {
          const data = action.contact_ids.reduce((acc, val) => {
            const { [val]: v, ...rest } = acc;
            return rest;
          }, state.data);
          return {
            ...state,
            data,
            order: state.order.filter(i => !action.contact_ids.includes(i))
          };
        }
        return { ...state, data: {}, order: [] };
      }
      return state;
    case TAGS_ACTIONS.GET_SUGGESTED_FIELD_SUCCESS: {
      return {
        ...state,
        field_suggestions: {
          ...state.field_suggestions,
          [action.key]: action.result.suggestions.filter(s => s)
        }
      };
    }
    case CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS_SUCCESS: {
      return {
        ...state,
        events_data: keys(action.result.events).reduce((result, contact_id) => {
          if (!result[contact_id])
            return {
              ...result,
              [contact_id]: action.result.events[contact_id]
            };
          const contact_events = action.result.events[contact_id];
          // (res, event) => {
          //   const found = res.find(e => e.id === event.id);
          //   return found ? res : res.concat(event);
          // },
          // result[contact_id]
          // );
          return {
            ...result,
            [contact_id]: contact_events
          };
        }, state.events_data),
        loading: false
      };
    }
    case CONTACTS_ACTIONS.GET_CONTACTS_FAILURE:
    case CONTACTS_ACTIONS.GET_CONTACT_FAILURE:
    case CONTACTS_ACTIONS.GET_GROUPS_FAILURE:
    case CONTACTS_ACTIONS.GET_BATCH_CONTACTS_FAILURE:
    case CONTACTS_ACTIONS.GET_CONTACT_EVENTS_SUCCESS_FAILURE:
    case CONTACTS_ACTIONS.GET_SEARCH_SUGGESTIONS_FAILURE:
    case CONTACTS_ACTIONS.GET_CONTACT_SCRAPE_FAILURE:
    case CONTACTS_ACTIONS.CREATE_CONTACT_FAILURE:
    case CONTACTS_ACTIONS.CREATE_NOTE_FAILURE:
    case CONTACTS_ACTIONS.CREATE_BULK_CONTACTS_FAILURE:
    case CONTACTS_ACTIONS.CREATE_GROUP_FAILURE:
    case CONTACTS_ACTIONS.CREATE_CONTACT_EVENT_FAILURE:
    case CONTACTS_ACTIONS.UPDATE_CONTACT_FAILURE:
    case CONTACTS_ACTIONS.UPDATE_GDPR_BASIS_FAILURE:
    case CONTACTS_ACTIONS.UPDATE_GDPR_REQUESTED_DATES_FAILURE:
    case CONTACTS_ACTIONS.ARCHIVE_CONTACT_FAILURE:
    case CONTACTS_ACTIONS.ARCHIVE_CONTACT_BATCH_FAILURE:
    case CONTACTS_ACTIONS.UNARCHIVE_CONTACT_FAILURE:
    case CONTACTS_ACTIONS.DELETE_GDPR_CONTACT_FAILURE:
    case CONTACTS_ACTIONS.DELETE_CONTACT_NOTE_FAILURE:
    case CONTACTS_ACTIONS.DELETE_CONTACT_PIN_FAILURE:
    case CONTACTS_ACTIONS.DELETE_CONTACT_DOCUMENT_FAILURE:
    case CONTACTS_ACTIONS.DELETE_CONTACT_AVAILABILITY_FAILURE:
    case CONTACTS_ACTIONS.DOWNLOAD_GDPR_DATA_FAILURE:
    case CONTACTS_ACTIONS.UPDATE_CONTACT_PIN_FAILURE:
    case CONTACTS_ACTIONS.UPDATE_CONTACT_PINS_FAILURE:
    case CONTACTS_ACTIONS.GET_CONTACT_REPRESENTEES_FAILURE:
    case CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS_FAILURE:
    case CONTACTS_ACTIONS.GET_CONTACTS_QUICK_FAILURE:
    case CONTACTS_ACTIONS.CHECK_CONTACT_DUPLICATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR_SUCCESS: {
      return {
        ...state,
        data: action.result.contacts.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        events_data: keys(action.result.events).reduce((result, contact_id) => {
          if (!result[contact_id]) {
            return {
              ...result,
              [contact_id]: action.result.events[contact_id]
            };
          }
          return {
            ...result,
            [contact_id]: action.result.events[contact_id]
          };
        }, state.events_data)
      };
    }
    default:
      return state;
  }
}
