import superagent from 'superagent';
import AuthService from 'lib/auth/AuthService';
import Env from 'lib/env/Env';

class Api {
  /**
   * Perform GET request
   * @param {string} url
   * @param {{params, data, token}} [options]
   * @returns {Request}
   */
  static get(url, options) {
    const request = superagent
      .get(this.formatUrl(url))
      .set(this.getRequestHeaders());
    if (options) {
      Api._applyOptions(request, options);
    }
    return request;
  }

  /**
   * Perform POST request
   * @param {string} url
   * @param {{params, data, token}} [options]
   * @returns {Request}
   */
  static post(url, options) {
    const request = superagent
      .post(this.formatUrl(url))
      .set(this.getRequestHeaders());
    if (options) {
      Api._applyOptions(request, options);
    }
    return request;
  }

  /**
   * Perform PUT request
   * @param {string} url
   * @param {{params, data, token}} [options]
   * @returns {Request}
   */
  static put(url, options) {
    const request = superagent
      .put(this.formatUrl(url))
      .set(this.getRequestHeaders());
    if (options) {
      Api._applyOptions(request, options);
    }
    return request;
  }

  /**
   * Perform DELETE request
   * @param {string} url
   * @param {{params, data, token}} [options]
   * @returns {Request}
   */
  static delete(url, options) {
    const request = superagent
      .delete(this.formatUrl(url))
      .set(this.getRequestHeaders());
    if (options) {
      Api._applyOptions(request, options);
    }
    return request;
  }

  /**
   * Alias for delete
   * @param {string} url
   * @param {{params, data, token}} [options]
   * @deprecated
   */
  static del(url, options) {
    return Api.delete(url, options);
  }

  /**
   * Apply request options, such as setting the body and query params
   * @param request
   * @param options
   * @private
   */
  static _applyOptions(request, options) {
    if (options.params) {
      request.query(options.params);
    }
    if (options.data) {
      return request.send(options.data);
    }
    return request;
  }

  /**
   * Format a URL for a request
   * @param {string} url
   * @returns {string}
   */
  static formatUrl(url) {
    if (url.startsWith('http')) {
      return url;
    }

    // Extract workspace schema from window location
    const { pathname } = window.location;
    const dbSchema = pathname.split('/')[2];
    if (!dbSchema) {
      return url;
    }

    return `${Api.workspaceApiUrl}/${dbSchema}` + url;
  }

  /**
   * Get request headers, include Authorization if the user is authenticated.
   * @param request
   */
  static getRequestHeaders(request) {
    let headers = {
      Accept: 'application/json'
    };

    if (AuthService.isAuthenticated()) {
      headers.Authorization = AuthService.token().tokenString;
    }

    return headers;
  }

  /**
   * Return the workspace API URL
   * @returns {string}
   */
  static get workspaceApiUrl() {
    return `${Api.baseApiUrl}/workspaces`;
  }

  /**
   * Return the base API URL
   * @returns {string}
   */
  static get baseApiUrl() {
    return Env.apiURL;
  }
}

export default Api;
