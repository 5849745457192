import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const EXPENSE_TYPES_ACTIONS = mapValues(
  mirror({
    GET_EXPENSE_TYPES: null,
    GET_EXPENSE_TYPES_SUCCESS: null,
    GET_EXPENSE_TYPES_FAILURE: null,

    CREATE_EXPENSE_TYPE: null,
    CREATE_EXPENSE_TYPE_SUCCESS: null,
    CREATE_EXPENSE_TYPE_FAILURE: null,

    DELETE_EXPENSE_TYPE: null,
    DELETE_EXPENSE_TYPE_SUCCESS: null,
    DELETE_EXPENSE_TYPE_FAILURE: null,

    UPDATE_EXPENSE_TYPE: null,
    UPDATE_EXPENSE_TYPE_SUCCESS: null,
    UPDATE_EXPENSE_TYPE_FAILURE: null,

    UPDATE_EXPENSE_TYPE_ORDER: null,
    UPDATE_EXPENSE_TYPE_ORDER_SUCCESS: null,
    UPDATE_EXPENSE_TYPE_ORDER_FAILURE: null
  }),
  value => value
);
