import activity from './icon_activity.svg?react';
import add from './icon_add.svg?react';
import alarm from './icon_alarm.svg?react';
import alert from './icon_alert.svg?react';
import allList from './icon_all_list.svg?react';
import arrowLeft from './icon_arrow_left.svg?react';
import arrowRight from './icon_arrow_right.svg?react';
import arrowTopRight from './icon_arrow_top_right.svg?react';
import availabilityRequest from './icon_availability_request.svg?react';
import bell from './icon_bell.svg?react';
import between from './icon_between.svg?react';
import book from './icon_book.svg?react';
import booking from './icon_booking.svg?react';
import bookmark from './icon_bookmark.svg?react';
import calculateDisabled from './icon_calculate_disabled.svg?react';
import calculateEnabled from './icon_calculate_enabled.svg?react';
import calendar from './icon_calendar.svg?react';
import chevronDown from './icon_chevron_down.svg?react';
import chevronLeft from './icon_chevron_left.svg?react';
import chevronRight from './icon_chevron_right.svg?react';
import chevronUp from './icon_chevron_up.svg?react';
import clock from './icon_clock.svg?react';
import cog from './icon_cog.svg?react';
import columnSort from './icon_column_sort.svg?react';
import comments from './icon_comments.svg?react';
import cross from './icon_cross.svg?react';
import dataDownload from './icon_data_download.svg?react';
import document from './icon_document.svg?react';
import drag from './icon_drag.svg?react';
import edit from './icon_edit.svg?react';
import equals from './icon_equals.svg?react';
import expand from './icon_expand.svg?react';
import expense from './icon_expense.svg?react';
import eyeHide from './icon_eye_hide.svg?react';
import eye from './icon_eye.svg?react';
import filter from './icon_filter.svg?react';
import folder from './icon_folder.svg?react';
import friendlyMessage from './icon_friendly_message.svg?react';
import hamburgerLong from './icon_hamburger_long.svg?react';
import hamburgerShort from './icon_hamburger_short.svg?react';
import helpBook from './icon_help_book.svg?react';
import help from './icon_help.svg?react';
import increment from './icon_increment.svg?react';
import individual from './icon_individual.svg?react';
import information from './icon_information.svg?react';
import invoice from './icon_invoice.svg?react';
import lightning from './icon_lightning.svg?react';
import lock from './icon_lock.svg?react';
import logout from './icon_logout.svg?react';
import masonry from './icon_masonry.svg?react';
import note from './icon_note.svg?react';
import options from './icon_options.svg?react';
import paperclip from './icon_paperclip.svg?react';
import pin from './icon_pin.svg?react';
import project from './icon_project.svg?react';
import receipt from './icon_receipt.svg?react';
import report from './icon_report.svg?react';
import search from './icon_search.svg?react';
import secure from './icon_secure.svg?react';
import send from './icon_send.svg?react';
import sidebarLong from './icon_sidebar_long.svg?react';
import sidebarNarrow from './icon_sidebar_narrow.svg?react';
import starOutline from './icon_star_outline.svg?react';
import star from './icon_star.svg?react';
import sync from './icon_sync.svg?react';
import table from './icon_table.svg?react';
import text from './icon_text.svg?react';
import thumbsDown from './icon_thumbs_down.svg?react';
import thumbsUp from './icon_thumbs_up.svg?react';
import tick from './icon_tick.svg?react';
import timeAdd from './icon_time_add.svg?react';
import timeRemove from './icon_time_remove.svg?react';
import trash from './icon_trash.svg?react';
import userGroup from './icon_user_group.svg?react';
import sortAsc from './icon_sort_asc.svg?react';
import sortDesc from './icon_sort_desc.svg?react';

// v4 icons -
import v4_booking from './v4/icon_booking.svg?react';
import v4_calendar from './v4/icon_calendar.svg?react';
import v4_callsheet from './v4/icon_callsheet.svg?react';
import v4_home from './v4/icon_home.svg?react';
import v4_project from './v4/icon_project.svg?react';
import v4_resource from './v4/icon_resource.svg?react';
import v4_shortlist from './v4/icon_shortlist.svg?react';
import v4_frontdesk from './v4/icon_frontdesk.svg?react';
import v4_callout from './v4/icon_callout.svg?react';
import v4_report from './v4/icon_report.svg?react';
import v4_reconciliation from './v4/icon_reconciliation.svg?react';
import v4_support from './v4/icon_support.svg?react';
import v4_helpbook from './v4/icon_helpbook.svg?react';
import v4_cog from './v4/icon_cog.svg?react';
import v4_comment from './v4/icon_comment.svg?react';
import v4_individual from './v4/icon_individual.svg?react';
import v4_security from './v4/icon_security.svg?react';
import v4_house from './v4/icon_house.svg?react';
import v4_users from './v4/icon_users.svg?react';
import v4_roles from './v4/icon_roles.svg?react';
import v4_integration from './v4/icon_integration.svg?react';
import v4_logout from './v4/icon_logout.svg?react';
import v4_search from './v4/icon_search.svg?react';

const Icons: {
  [key in SVGIconName]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  activity,
  add,
  alarm,
  alert,
  allList,
  arrowLeft,
  arrowRight,
  arrowTopRight,
  availabilityRequest,
  bell,
  between,
  book,
  booking,
  bookmark,
  calculateDisabled,
  calculateEnabled,
  calendar,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  clock,
  cog,
  columnSort,
  comments,
  cross,
  dataDownload,
  document,
  drag,
  edit,
  equals,
  expand,
  expense,
  eyeHide,
  eye,
  filter,
  folder,
  friendlyMessage,
  hamburgerLong,
  hamburgerShort,
  helpBook,
  help,
  increment,
  individual,
  information,
  invoice,
  lightning,
  lock,
  logout,
  masonry,
  note,
  options,
  paperclip,
  pin,
  project,
  receipt,
  report,
  search,
  secure,
  send,
  sidebarLong,
  sidebarNarrow,
  starOutline,
  star,
  sync,
  table,
  text,
  thumbsDown,
  thumbsUp,
  tick,
  timeAdd,
  timeRemove,
  trash,
  userGroup,
  sortAsc,
  sortDesc,

  v4_booking,
  v4_calendar,
  v4_callsheet,
  v4_home,
  v4_project,
  v4_resource,
  v4_shortlist,
  v4_frontdesk,
  v4_callout,
  v4_report,
  v4_reconciliation,
  v4_support,
  v4_helpbook,
  v4_cog,
  v4_comment,
  v4_individual,
  v4_security,
  v4_house,
  v4_users,
  v4_roles,
  v4_integration,
  v4_logout,
  v4_search
};

export default Icons;
