import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, GridCell } from 'v1/components/shared';
import { PressStud, TextInput } from 'v1/components/shared';

import classnames from 'classnames';
import './SingleListAdd.scss';

class SingleListAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSelected: false
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.isCreating && !nextProps.isCreating) {
      this.setState({ new_list_name: '', hasSelected: false });
    }
  };
  handleSubmit = () => {
    if (!this.state.new_list_name) return;
    this.props.onSubmit(this.state.new_list_name);
  };
  renderForm = () => {
    return (
      <Grid vcenter gutters="S">
        <GridCell>
          <TextInput
            placeholder={this.props.placeholder}
            value={this.state.new_list_name || ''}
            initialFocus
            onChange={({ target: { value } }) => {
              this.setState({ new_list_name: value });
            }}
          />
        </GridCell>
        <GridCell width="auto">
          <PressStud
            label="Cancel"
            appearance="silent"
            action={() => this.setState({ hasSelected: false })}
          />
        </GridCell>
        <GridCell width="auto">
          <PressStud
            label="Save"
            appearance="primary"
            isLoading={this.props.isCreating}
            action={this.handleSubmit}
          />
        </GridCell>
      </Grid>
    );
  };
  renderButton = () => {
    return (
      <PressStud
        label={this.props.buttonLabel || 'Add new'}
        icon="addBlack"
        size="S"
        action={() => {
          this.setState({ hasSelected: true });
        }}
      />
    );
  };
  render() {
    return (
      <div className={classnames(['SingleListAdd', this.props.className])}>
        {this.state.hasSelected ? this.renderForm() : this.renderButton()}
      </div>
    );
  }
}

SingleListAdd.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  isCreating: PropTypes.bool,
  onSubmit: PropTypes.func // TODO: arguments
};

export default SingleListAdd;
