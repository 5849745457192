import { Component } from 'react';
import classnames from 'classnames';
import './Page.scss';

export default class Page extends Component {
  render() {
    return (
      <div
        className={`${this.props.className || ''} Page`}
        id={this.props.id}
        style={this.props.style}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className={classnames({ flush: this.props.flush }, [
                this.props.wide ? 'col-sm-12' : 'col-sm-10 col-sm-offset-1'
              ])}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
