import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

const AVAILABILITIES_ACTIONS = mapValues(
  mirror({
    ADD_LOCAL_AVAILABILITY: null,
    UPDATE_LOCAL_AVAILABILITY: null,
    UPDATE_LOCAL_AVAILABILITY_WITH_DURATION_DETAILS: null,
    DELETE_LOCAL_AVAILABILITY: null,

    GET_AVAILABILITY_REQUEST: null,
    GET_AVAILABILITY_REQUEST_SUCCESS: null,
    GET_AVAILABILITY_REQUEST_FAILURE: null,

    GET_AVAILABILITIES: null,
    GET_AVAILABILITIES_SUCCESS: null,
    GET_AVAILABILITIES_FAILURE: null,

    CREATE_AVAILABILITIES: null,
    CREATE_AVAILABILITIES_SUCCESS: null,
    CREATE_AVAILABILITIES_FAILURE: null,

    LOG_CREATE_AVAILABILITIES: null,
    LOG_CREATE_AVAILABILITIES_SUCCESS: null,
    LOG_CREATE_AVAILABILITIES_FAILURE: null,

    GET_AVAILABILITIES_BOOKINGS: null,
    GET_AVAILABILITIES_BOOKINGS_SUCCESS: null,
    GET_AVAILABILITIES_BOOKINGS_FAILURE: null
  }),
  value => value
);

export default AVAILABILITIES_ACTIONS;
