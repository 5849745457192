import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TypeFormComponent from 'v1/components/shared/__types__/TypeFormComponent';

import {
  CurrencyAmountInput,
  EmailInput,
  PhoneInput,
  Label,
  NumberInput,
  TextInput,
  UrlInput,
  TimeInput
} from 'v1/components/shared';
import TextInputGroupPend, {
  pendType
} from './TextInputGroupPend/TextInputGroupPend';

import styles from './TextInputGroup.module.scss';

type TextInputGroupType =
  | 'currency'
  | 'email'
  | 'number'
  | 'phone'
  | 'text'
  | 'time'
  | 'url';

// TODO
type Pend = {
  icon?: string;
  text?: string;
  valueKey?: string;
  options?: string[] | { label: string; value: string }[];
  onClick?(): any;
};

export type TextInputGroupProps = {
  // Meta
  className?: string;
  type: TextInputGroupType;
  label?: string;
  disabled?: boolean;
  inputDisabled?: boolean;
  showOptional?: boolean;
  // Styling
  appearance: 'outline' | 'underline' | 'silent';
  size: 'S' | 'M' | 'L';
  // Data
  valueKey: string;
  value: { [key: string]: any };
  prepend?: Pend;
  append?: Pend;
  onChange?(value: any): void;
  onBlur?(value: any, key:string): void;
  onFocus?(): void;
};

const getInputComponent = (type: TextInputGroupType) => {
  switch (type) {
    // TODO: date
    case 'email':
      return EmailInput;
    case 'number':
      return NumberInput;
    case 'currency':
      return CurrencyAmountInput;
    case 'phone':
      return PhoneInput;
    case 'url':
      return UrlInput;
    case 'time':
      return TimeInput;
    case 'text':
    default:
      return TextInput;
  }
};

// TODO: tighten up types - very rough
const TextInputGroup = ({
  className,
  label,
  type,
  valueKey,
  value,
  appearance = 'outline',
  size = 'M',
  prepend,
  append,
  showOptional,
  disabled,
  inputDisabled,
  onChange,
  onFocus,
  onBlur,
  ...props
}: TextInputGroupProps) => {
  const InputComponent = getInputComponent(type);
  const inputValue = valueKey ? value[valueKey] : value;

  const handleFocus = () => {
    onFocus && onFocus();
  };

  const getNewValue = (key: string, val: any) => {
    return key ? { ...value, [key]: val } : val;
  };

  const handleChange = (key: string, val: any) => {
    onChange && onChange(getNewValue(key, val));
  };

  const handleBlur = (key: string, val: any) => {
    onBlur && onBlur(getNewValue(key, val), key);
  };

  const handlePendChange = (key: string, val: any) => {
    handleChange(key, val);
    handleBlur(key, val);
  };

  const renderPend = (pendType: string, pend: Pend) => (
    <TextInputGroupPend
      type={pendType}
      appearance={appearance}
      size={size}
      pend={pend}
      value={value}
      disabled={disabled}
      onChange={handlePendChange}
    />
  );

  return (
    <div
      className={classnames([
        styles.TextInputGroup,
        styles[`TextInputGroup_${appearance}`],
        styles[`TextInputGroup_${size}`],
        {
          [styles.TextInputGroup_withPrepend]: prepend,
          [styles.TextInputGroup_withPrepend_options]:
            prepend && prepend.options,
          [styles.TextInputGroup_withAppend]: append,
          [styles.TextInputGroup_withAppend_options]: append && append.options
        },
        className
      ])}
    >
      {label && (
        <Label>
          {label}
          {showOptional && <i>optional</i>}
        </Label>
      )}
      <div className={styles.Wrapper}>
        {prepend && renderPend('prepend', prepend)}
        <InputComponent
          className={styles.Input}
          label={null}
          appearance={appearance}
          size={size}
          value={inputValue}
          disabled={disabled || inputDisabled}
          onFocus={handleFocus}
          onBlur={(val: any) => handleBlur(valueKey, val)}
          onChange={(val: any) => handleChange(valueKey, val)}
          {...props}
        />
        {append && renderPend('append', append)}
      </div>
    </div>
  );
};

TextInputGroup.propTypes = {
  ...TypeFormComponent,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    /*'date',*/
    'email',
    'number',
    'currency',
    'phone',
    'url',
    'time',
    'text'
  ]),
  valueKey: PropTypes.string,
  value: PropTypes.any,
  showOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  prepend: pendType,
  append: pendType,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
  // ...props - Inherits props from the input component
};

export default TextInputGroup;
