import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-form';

import { TextInputField } from 'v1/components/shared';
import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';

import './CallsheetAddRecipientModal.scss';
import { callsheets } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';

class CallsheetAddRecipientModal extends Component {
  constructor(props) {
    super(props);

    this.formApi = {};

    this.state = {
      callsheet: props.ui.data.callsheet || {},
      fields: [
        {
          name: 'recipient',
          value: ''
        }
      ]
    };
  }

  handleSubmit = (values = {}) => {
    const { callsheet } = this.state;
    // collect all recipient values, filter out empties + format
    const additionalRecipients = Object.values(values)
      .filter(recipient => recipient)
      .map(recipientEmail => ({
        callsheet_id: callsheet.id,
        alt_email_address: recipientEmail.trim() // sanitize?
      }));
    // probably need a check here to omit any duplicates
    // if they already exist in the callsheet's recipients array

    if (!additionalRecipients.length) return;

    this.props.updateRecipientsMutation.mutate(
      {
        id: this.state.callsheet.id,
        data: [...callsheet.recipients, ...additionalRecipients]
      },
      {
        onSuccess: () => {
          this.props.displayAlert('success', 'Recipients added');
          this.props.onRequestClose();
        },
        onError: error => {
          this.props.displayAlert('error', error);
        }
      }
    );
  };

  addRecipientField = () => {
    this.setState(state => ({
      fields: [
        ...state.fields,
        {
          name: `recipient${state.fields.length}`,
          value: ''
        }
      ]
    }));
  };

  renderRecipientFields = (fields = []) => {
    if (!fields) return;
    return fields.map((field, index) => {
      return (
        <div key={`recipient-${index}`} className="form-group_tight">
          <TextInputField
            label={index === 0 ? 'Recipients' : null}
            field={field.name}
            validate="email"
            placeholder="someone@company.co"
            initialFocus={index === 0 ? true : null}
          />
        </div>
      );
    });
  };

  render() {
    const { onRequestClose } = this.props;

    return (
      <ModalWrapper>
        <ModalHeader title="Add external recipients" />
        <ModalContent>
          <ModalScrollable>
            <Form
              onSubmit={this.handleSubmit}
              defaultValues={{
                recipient: ''
              }}
              getApi={api => (this.formApi = api)}
              validateOnSubmit
            >
              {formApi => (
                <form
                  onSubmit={formApi.submitForm}
                  id="callsheet-recipients-form"
                >
                  <div className="form-group">
                    {this.renderRecipientFields(this.state.fields)}
                  </div>
                  <PressStud
                    label="Add Email"
                    icon="addBlack"
                    size="S"
                    action={this.addRecipientField}
                  />
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Cancel"
              appearance="silent"
              hasMarginRight
              action={onRequestClose}
            />
            <PressStud
              label="Add"
              appearance="primary"
              isLoading={this.props.updateRecipientsMutation.isLoading}
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ ui }) {
  return { ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayAlert,
      openModal
    },
    dispatch
  );
}

const withQueries = Component =>
  function Wrapper(props) {
    const updateRecipientsMutation = callsheets.useUpdateRecipientsMutation();

    return (
      <Component
        {...props}
        updateRecipientsMutation={updateRecipientsMutation}
      />
    );
  };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withQueries(CallsheetAddRecipientModal))
);
