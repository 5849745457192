//
// Handy functional helpers for frequently used date manipulation and formatting
//

import moment from 'moment-timezone';

// TODO: document
export function formatEvent(evt) {
  // Adjusts start_timestamp and end_timestamp if start_date or end_date change
  if (evt.date_type === 'INCLUSIVE_HOURS') {
    const start_time = moment(evt.start_timestamp).format('hh:mm:ss a');
    const start_timestamp = evt.start_timestamp
      ? moment(evt.start_date).format()
      : moment(evt.start_date + ' ' + start_time).format();
    const end_time = moment(evt.end_timestamp).format('hh:mm:ss a');
    const end_timestamp = evt.end_timestamp
      ? moment(evt.end_date).format()
      : moment(evt.end_date + ' ' + end_time).format();
    return { ...evt, start_timestamp, end_timestamp };
  }

  return { ...evt, start_timestamp: null, end_timestamp: null };
}

// TODO: refactor : split out and generalise - getDatesBetweenTwoDates & getDatesDuringEvent ?
export function getDatesBetweenTwoDates(event = {}) {
  const startDate =
    event.date_type === 'INCLUSIVE_HOURS'
      ? event.start_timestamp
      : event.start_date;
  const endDate =
    event.date_type === 'INCLUSIVE_HOURS'
      ? event.end_timestamp
      : event.end_date;

  if (!startDate || !endDate) return [];
  var dates = [];

  var currDate = moment(startDate).subtract(1, 'days').startOf('day');
  var lastDate = moment(endDate).add(1, 'days').startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
}
