import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import Env from 'lib/env/Env';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true }, () => {
      return Env.nodeEnv !== 'production'
        ? this.props.displayAlert('debug', error)
        : false;
    });
  }

  render() {
    return this.props.children;
  }
}

ErrorHandler.propTypes = {
  displayAlert: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ displayAlert }, dispatch);
}

export default connect(null, mapDispatchToProps)(ErrorHandler);
