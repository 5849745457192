import React from 'react';
import {
  Button,
  Field,
  Form,
  Grid,
  GridCell,
  Heading,
  Panel,
  TextInput
} from 'shared';

const CreateProfilePanel = ({ onSubmit }) => {
  const handleSubmit = data => {
    onSubmit && onSubmit(data);
  };

  const defaultValues = {
    firstName: '',
    lastName: ''
  };

  return (
    <Panel>
      <Form values={defaultValues} onSubmit={handleSubmit}>
        <Grid direction="column">
          <GridCell>
            <Heading color="black" type="h2" stack="m">
              Create profile
            </Heading>
            <div className="stack-m">
              <Field label="First Name" name="firstName" size="l" isRequired>
                <TextInput placeholder="First name" value="" />
              </Field>
            </div>
            <div className="stack-m">
              <Field label="Last Name" name="lastName" size="l" isRequired>
                <TextInput placeholder="Last name" value="" />
              </Field>
            </div>
          </GridCell>
          <Grid align="right">
            <GridCell>
              <Button
                label="Continue"
                appearance="primary"
                action="submit"
              ></Button>
            </GridCell>
          </Grid>
        </Grid>
      </Form>
    </Panel>
  );
};

export default CreateProfilePanel;
