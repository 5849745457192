import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  TextInputField,
  RateAgencyFee,
  RateInput,
  RatePayrollFee,
  RateProcessingFee,
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalNavigation,
  ModalScrollable
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { Form } from 'react-form';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { createRate, updateRate } from 'store/v1/rates/rates.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { get } from 'lodash';
import { v4 } from 'uuid';

class RateCreateModal extends Component {
  constructor(props) {
    super(props);

    this.app_id = props.auth.app_id;

    this.state = {
      rate: props.ui.data.rate || {},
      isCreate: props.ui.data.create
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        if (this.state.isCreate) {
          this.props.onRequestClose();
          this.props.displayAlert('success', 'Rate Created');
        } else {
          this.props.onRequestClose();
          this.props.displayAlert('success', 'Rate Updated');
        }
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }

  handleSubmit = values =>
    this.setState({ event: v4() }, () => {
      this.state.isCreate
        ? this.props.createRate(values, this.state.event)
        : this.props.updateRate(
            { rate_id: this.state.rate.id },
            values,
            this.state.event
          );
    });

  render() {
    const { loading } = this.props.rates;
    const {
      name,
      amount,
      interval,
      currency,
      agency_fee,
      payroll_fee,
      processing_fee
    } = this.state.rate;
    const defaultSystemCurrency = get(
      this.props.auth,
      'settings.settings.defaults_currency.value'
    );

    return (
      <ModalWrapper size="S">
        <ModalHeader
          title={this.state.isCreate ? 'Create rate card' : 'Edit rate card'}
        />
        <ModalContent>
          <ModalScrollable>
            <Form
              onSubmit={this.handleSubmit}
              defaultValues={{
                name,
                amount,
                interval: interval || 'DAILY',
                currency: currency || defaultSystemCurrency,
                agency_fee,
                payroll_fee,
                processing_fee
              }}
              getApi={api => {
                this.formApi = api;
              }}
              validateOnSubmit
            >
              {formApi => (
                <form onSubmit={formApi.submitForm}>
                  <div className="form-group">
                    <TextInputField
                      label="Rate Name"
                      field="name"
                      placeholder="Name"
                      validate="required"
                      initialFocus
                      removeAutocomplete
                    />
                  </div>
                  <div className="form-group">
                    <RateInput
                      label="Rate"
                      placeholder="Rate"
                      className="stack-S"
                      value={{
                        amount: formApi.values.amount,
                        currency: formApi.values.currency,
                        interval: formApi.values.interval
                      }}
                      onBlur={value => {
                        formApi.setValue('amount', value.amount);
                        formApi.setValue('currency', value.currency);
                        formApi.setValue('interval', value.interval);
                      }}
                    />
                    <RateAgencyFee
                      value={formApi.values.agency_fee}
                      className="stack-S"
                      onChange={value => formApi.setValue('agency_fee', value)}
                    />
                    <RatePayrollFee
                      value={formApi.values.payroll_fee}
                      className="stack-S"
                      onChange={value => formApi.setValue('payroll_fee', value)}
                    />
                    <RateProcessingFee
                      value={formApi.values.processing_fee}
                      className="stack-S"
                      onChange={value =>
                        formApi.setValue('processing_fee', value)
                      }
                    />
                  </div>
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Cancel"
              appearance="silent"
              action={this.props.onRequestClose}
            />
            <PressStud
              label={this.state.isCreate ? 'Create Rate' : 'Save changes'}
              appearance="primary"
              isLoading={loading === 'CREATE_RATE' || loading === 'UPDATE_RATE'}
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ auth, rates, events, ui }) {
  return { auth, rates, events, ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createRate,
      updateRate,
      displayAlert
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RateCreateModal)
);
