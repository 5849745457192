import React, { Component } from 'react';
import { Tweet } from 'react-twitter-widgets';

export default class PinTweet extends Component {
  render() {
    const { url } = this.props.pin;
    let tweetID = url.split('/status/');

    if (tweetID) {
      return (
        <div className="PinTweet">
          <Tweet tweetId={tweetID[1]} />
        </div>
      );
    }
    return <div className="PinItem-error"></div>;
  }
}
