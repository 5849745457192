import { Account } from '__types__';
import { AccountDto } from 'dtos/account.dto';

/**
 * Decides what to display as a user's "name", based on what's available from firstName, lastName and email.
 * Defaults to email if no names are available
 */
export const getAccountDisplayName = (
  account: Account | AccountDto
): string => {
  return account.firstName && account.lastName
    ? [account.firstName, account.lastName].join(' ')
    : account.firstName || account.lastName || account.email;
};
