import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './DropdownContent.scss';

import DEPRECATED_DropdownContentLinks from './DropdownContentLinks/DropdownContentLinks';

const DEPRECATED_DropdownContent = ({
  className,
  offset,
  links,
  linkClassName,
  children
}) => (
  <div
    className={classnames(['DropdownButton-linksContainer', className])}
    onClick={e => e.stopPropagation()}
    style={
      offset && {
        right: offset.right,
        left: offset.left,
        top: offset.top,
        bottom: offset.bottom
      }
    }
  >
    {links ? (
      <DEPRECATED_DropdownContentLinks
        links={links}
        linkClassName={linkClassName}
      />
    ) : (
      children
    )}
  </div>
);

DEPRECATED_DropdownContent.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.shape({
    bottom: PropTypes.any, // TODO: types - string or number?
    left: PropTypes.any,
    right: PropTypes.any,
    top: PropTypes.any
  }),
  links: PropTypes.array, // TODO: types
  children: PropTypes.any
};

export default DEPRECATED_DropdownContent;
