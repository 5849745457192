import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import {
  deleteBooking,
  unarchiveBooking
} from 'store/v1/bookings/bookings.actions.js';
import {
  displayAlert,
  openModal,
  closeBookingSidebar
} from 'store/v1/ui/ui.actions.js';

import { Mixpanel, MixActions } from 'lib/mixpanel';
import useEvent from 'v1/helpers/hooks/useEvent';

import { DropdownMenu } from 'v1/components/shared';

const BookingOptions = ({ bookingId, appearance }) => {
  const dispatch = useDispatch();
  const bookings = useSelector(state => state.bookings.data);
  const booking = get(bookings, bookingId);

  const showMenu = useRestrictedAccess(null, null, [
    Capability.BOOKING_UPDATE,
    Capability.BOOKING_ARCHIVE
  ]);

  const hasDeletePermission = useRestrictedAccess(null, null, [
    Capability.BOOKING_ARCHIVE
  ]);

  useEvent([BOOKING_ACTIONS.UNARCHIVE_BOOKING], {
    onSuccess: _ => {
      dispatch(displayAlert('success', 'Booking unarchived'));
      Mixpanel.track(MixActions.BOOKING_UNARCHIVE, {
        bookingId
      });
    },
    onFailure: _ => dispatch(displayAlert('error', 'Something went wrong'))
  });

  useEvent([BOOKING_ACTIONS.DELETE_BOOKING], {
    onSuccess: _ => {
      dispatch(closeBookingSidebar());
      dispatch(displayAlert('success', 'Booking archived'));
      Mixpanel.track(MixActions.BOOKING_ARCHIVE, {
        bookingId
      });
    },
    onFailure: _ => dispatch(displayAlert('error', 'Something went wrong'))
  });

  if (!showMenu || !booking) {
    return null;
  }

  const onArchive = () => {
    dispatch(
      openModal('ConfirmModal', {
        title: 'Are you sure you want to archive this booking?',
        description:
          'You will not lose any data but you will no longer see this booking within the list',
        confirmLabel: 'Archive booking',
        onConfirm: () => dispatch(deleteBooking(bookingId))
      })
    );
  };

  const onUnarchive = () => {
    dispatch(
      openModal('ConfirmModal', {
        title: 'Are you sure you want to unarchive this booking?',
        description:
          'This booking will reappear within the booking list as well as within the resources calendar',
        confirmLabel: 'Unarchive booking',
        onConfirm: () => dispatch(unarchiveBooking(bookingId))
      })
    );
  };

  return (
    <DropdownMenu icon="ellipsis" appearance={appearance}>
      {booking && !booking.archived && (
        <DropdownMenu.Item
          icon="/images/icon_delete.svg"
          title="Archive booking"
          onClick={onArchive}
        />
      )}
      {hasDeletePermission && booking && booking.archived && (
        <DropdownMenu.Item
          icon="/images/icon_unarchive.svg"
          title="Unarchive booking"
          onClick={onUnarchive}
        />
      )}
    </DropdownMenu>
  );
};

BookingOptions.propTypes = {
  bookingId: PropTypes.number,
  appearance: PropTypes.string // From PressStud
};

export default BookingOptions;
