import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import {
  cloneDeep,
  set,
  get,
  findIndex,
  filter,
  merge,
  map,
  find,
  setWith
} from 'lodash';

export const INITIAL_STATE = {
  associations_data: {},
  quick_search_results: [],
  data: {},
  paging: {},
  order: [],
  new: {},
  error: null,
  loading: false,
  delete_id: null
};

export default function reducer(state = INITIAL_STATE, action) {
  let resource_slots,
    resource_slot_index,
    teamMemberOptions,
    resource_slot_assignments,
    BudgetExpenseIndex,
    targetBudgetExpenses,
    BudgetExpenseItem,
    previousReceipts,
    updatedReceipts,
    updatedBudgetExpenses,
    teamMemberAssignmentIndex;
  switch (action.type) {
    case PRODUCTIONS_ACTIONS.GET_PRODUCTIONS:
    case PRODUCTIONS_ACTIONS.GET_BATCH_PRODUCTIONS:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_ASSOCIATIONS:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_DOCUMENT:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION:
    case PRODUCTIONS_ACTIONS.UNARCHIVE_PRODUCTION:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTIONS_NOTE:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTIONS_DOCUMENT:
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT:
    case PRODUCTIONS_ACTIONS.UPDATE_INVOICE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_TEAM_MEMBER_BATCH:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_TEAM_MEMBER:
    case PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_GROUP:
    case PRODUCTIONS_ACTIONS.REORDER_PRODUCTION_GROUPS:
    case PRODUCTIONS_ACTIONS.CREATE_TEAM_MEMBER_OPTION:
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT:
    case PRODUCTIONS_ACTIONS.UPDATE_TEAM_MEMBER_ASSIGNMENT:
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT:
    case PRODUCTIONS_ACTIONS.BATCH_UPDATE_INVOICES:
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT:
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT:
    case PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT:
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_NOTE:
    case PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE:
    case PRODUCTIONS_ACTIONS.DELETE_BUDGET_EXPENSE:
    case PRODUCTIONS_ACTIONS.ORDER_BUDGET_EXPENSE:
    case PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_ITEM:
    case PRODUCTIONS_ACTIONS.CREATE_RECEIPT:
    case PRODUCTIONS_ACTIONS.UPDATE_RECEIPT:
    case PRODUCTIONS_ACTIONS.DELETE_RECEIPT:
    case PRODUCTIONS_ACTIONS.QUICK_SEARCH_PRODUCTIONS:
    case PRODUCTIONS_ACTIONS.REORDER_RESOURCE_SLOTS:
    case PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS:
    case PRODUCTIONS_ACTIONS.MOVE_PRODUCTION:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case PRODUCTIONS_ACTIONS.GET_PRODUCTIONS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false
      };
    case PRODUCTIONS_ACTIONS.QUICK_SEARCH_PRODUCTIONS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        quick_search_results: action.result.results.map(item => item.id),
        loading: false
      };
    case PRODUCTIONS_ACTIONS.GET_BATCH_PRODUCTIONS_SUCCESS:
      const batchProductionResults = get(action, 'result.data', []);
      return {
        ...state,
        data: batchProductionResults.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        loading: false
      };
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associations_data: {
          ...state.associations_data,
          [action.production_id]: action.result
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_SUCCESS:
    case PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS_SUCCESS:
    case PRODUCTIONS_ACTIONS.MOVE_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        order:
          state.order.indexOf(action.result.id) === -1
            ? [...state.order, get(action, 'result.id')]
            : state.order
      };
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [get(action, 'result.id')]: get(action, 'result')
        },
        order: [...state.order, get(action, 'result.id')],
        new: action.result
      };
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_NOTE_SUCCESS:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_DOCUMENT_SUCCESS: {
      const selector = {
        CREATE_PRODUCTION_NOTE_SUCCESS: 'notes',
        CREATE_PRODUCTION_DOCUMENT_SUCCESS: 'documents'
      }[action.type];
      const list = [
        action.result,
        ...state.associations_data[action.production_id][selector]
      ];
      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.production_id}.${selector}`,
          list,
          cloneDeep
        )
      };
    }
    case PRODUCTIONS_ACTIONS.BATCH_UPDATE_INVOICES_SUCCESS:
      return {
        ...state
      };
    case PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: [
              ...state.data[action.production_id].budget_expenses,
              action.result
            ]
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.DELETE_BUDGET_EXPENSE_SUCCESS:
      const budget_expenses = filter(
        get(state.data, [action.production_id, 'budget_expenses']),
        b_e => b_e.id !== action.budget_expense_id
      );
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.ORDER_BUDGET_EXPENSES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: action.result
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_ITEM_SUCCESS:
      let budgetExpenses = get(state.data, [
        action.production_id,
        'budget_expenses'
      ]);
      const budgetExpenseIndex = findIndex(
        budgetExpenses,
        b_e => b_e.id === action.budget_expense_id
      );
      const existingExpenseItems = get(
        budgetExpenses,
        [budgetExpenseIndex, 'expenses'],
        []
      );

      budgetExpenses[budgetExpenseIndex] = {
        ...budgetExpenses[budgetExpenseIndex],
        expenses: [...existingExpenseItems, action.result]
      };
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: budgetExpenses
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.REORDER_RESOURCE_SLOTS_SUCCESS:
      const updatedResourceSlots = map(
        get(
          state,
          ['data', action.production_id, 'resource_slots'],
          resource => {
            const newResource = find(action.result, r => r.id === resource.id);
            return {
              ...resource,
              group_id: newResource.group_id,
              order: newResource.order
            };
          }
        )
      );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots: updatedResourceSlots
          }
        }
      };
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        }
      };
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_SUCCESS:
    case PRODUCTIONS_ACTIONS.UNARCHIVE_PRODUCTION_SUCCESS:
      const { [action.result.id]: item, ...data } = state.data;
      return {
        ...state,
        data,
        order: state.order.filter(i => i !== action.result.id),
        loading: false,
        delete_id: action.result.id
      };
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT_SUCCESS: {
      const production = state.data[action.production_id];
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.production_id]: {
            ...production,
            events: production.events
              ? [...production.events, action.result]
              : [action.result]
          }
        }
      };
    }
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT_SUCCESS: {
      const production = state.data[action.production_id];
      const events = (get(production, 'events') || []).map(e =>
        e.id === action.result.id ? action.result : e
      );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.production_id]: {
            ...production,
            events
          }
        }
      };
    }
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT_SUCCESS: {
      const production = state.data[action.production_id];
      const events = (get(production, 'events') || []).filter(
        e => e.id === action.production_event_id
      );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.production_id]: {
            ...production,
            events
          }
        }
      };
    }
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_NOTE_SUCCESS:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_DOCUMENT_SUCCESS: {
      const selector = {
        DELETE_PRODUCTION_NOTE_SUCCESS: 'notes',
        DELETE_PRODUCTION_DOCUMENT_SUCCESS: 'documents'
      }[action.type];

      const list = state.associations_data[action.production_id][
        selector
      ].filter(n => n.id != action.association_id);

      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.production_id}.${selector}`,
          list,
          cloneDeep
        ),
        error: false
      };
    }
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);
      resource_slot_index = findIndex(
        resource_slots,
        t => t.id === action.resource_slot_id
      );
      resource_slot_assignments = filter(
        get(resource_slots, [resource_slot_index, 'resource_slot_assignments']),
        a => a.id !== action.resource_slot_assignment_id
      );

      resource_slots[
        resource_slot_index
      ].resource_slot_assignments = resource_slot_assignments;
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_TEAM_MEMBER_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);
      resource_slot_index = findIndex(
        resource_slots,
        t => t.id === action.team_member_id
      );
      resource_slots[resource_slot_index] = action.result.team_member;

      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.CREATE_TEAM_MEMBER_OPTION_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);
      resource_slot_index = findIndex(
        resource_slots,
        t => t.id === action.team_member_id
      );

      teamMemberOptions = resource_slots[resource_slot_index].options;
      teamMemberOptions.push(action.result.option);
      resource_slots[resource_slot_index].options = teamMemberOptions;
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };

    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);
      resource_slot_index = findIndex(
        resource_slots,
        t => t.id === action.resource_slot_id
      );
      resource_slot_assignments = get(
        resource_slots,
        `${resource_slot_index}.resource_slot_assignments`
      );
      resource_slot_assignments.push(action.result);
      resource_slots[
        resource_slot_index
      ].resource_slot_assignments = resource_slot_assignments;
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.CREATE_RECEIPT_SUCCESS:
      targetBudgetExpenses = get(state, [
        'data',
        action.production_id,
        'budget_expenses'
      ]);

      // Indexes
      BudgetExpenseIndex = findIndex(
        targetBudgetExpenses,
        b => b.id === action.budget_expense_id
      );
      BudgetExpenseItem = findIndex(
        get(targetBudgetExpenses, [BudgetExpenseIndex, 'expenses']),
        b_e_i => b_e_i.id === action.expense_item_id
      );

      previousReceipts = get(targetBudgetExpenses, [
        BudgetExpenseIndex,
        'expenses',
        BudgetExpenseItem,
        'receipts'
      ]);

      updatedReceipts = [...previousReceipts, action.result];

      // TODO: mutating lodash function
      updatedBudgetExpenses = set(
        targetBudgetExpenses,
        [BudgetExpenseIndex, 'expenses', BudgetExpenseItem, 'receipts'],
        updatedReceipts
      );

      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: updatedBudgetExpenses
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.UPDATE_RECEIPT_SUCCESS:
      targetBudgetExpenses = get(state, [
        'data',
        action.production_id,
        'budget_expenses'
      ]);
      // Indexes
      BudgetExpenseIndex = findIndex(
        targetBudgetExpenses,
        b => b.id === action.budget_expense_id
      );
      BudgetExpenseItem = findIndex(
        get(targetBudgetExpenses, [BudgetExpenseIndex, 'expenses']),
        b_e_i => b_e_i.id === action.expense_item_id
      );
      updatedReceipts = map(
        get(targetBudgetExpenses, [
          BudgetExpenseIndex,
          'expenses',
          BudgetExpenseItem,
          'receipts'
        ]),
        r => {
          if (r.id != action.result.id) return r;
          return action.result;
        }
      );
      // TODO: mutating lodash function
      updatedBudgetExpenses = set(
        targetBudgetExpenses,
        [BudgetExpenseIndex, 'expenses', BudgetExpenseItem, 'receipts'],
        updatedReceipts
      );
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: updatedBudgetExpenses
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.DELETE_RECEIPT_SUCCESS:
      targetBudgetExpenses = get(state, [
        'data',
        action.production_id,
        'budget_expenses'
      ]);
      // Indexes
      BudgetExpenseIndex = findIndex(
        targetBudgetExpenses,
        b => b.id === action.budget_expense_id
      );
      BudgetExpenseItem = findIndex(
        get(targetBudgetExpenses, [BudgetExpenseIndex, 'expenses']),
        b_e_i => b_e_i.id === action.expense_item_id
      );
      updatedReceipts = filter(
        get(targetBudgetExpenses, [
          BudgetExpenseIndex,
          'expenses',
          BudgetExpenseItem,
          'receipts'
        ]),
        r => r.id != action.receipt_id
      );
      // TODO: mutating lodash function
      updatedBudgetExpenses = set(
        targetBudgetExpenses,
        [BudgetExpenseIndex, 'expenses', BudgetExpenseItem, 'receipts'],
        updatedReceipts
      );
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: updatedBudgetExpenses
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.UPDATE_TEAM_MEMBER_ASSIGNMENT_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);
      resource_slot_index = findIndex(
        resource_slots,
        t => t.id === action.team_member_id
      );
      resource_slot_assignments = get(
        resource_slots,
        `${resource_slot_index}.assignments`
      );
      teamMemberAssignmentIndex = findIndex(resource_slot_assignments, {
        id: action.assignment_id
      });
      if (teamMemberAssignmentIndex > -1)
        resource_slot_assignments[teamMemberAssignmentIndex] = action.result;
      else {
        resource_slot_assignments.push(action.result);
      }
      resource_slots[
        resource_slot_index
      ].assignments = resource_slot_assignments;
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);

      resource_slots = filter(
        resource_slots,
        r => r.id !== action.resource_slot_id
      );
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);

      resource_slots = [...resource_slots, action.result];

      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_SUCCESS:
      resource_slots = get(state.data, [
        action.production_id,
        'resource_slots'
      ]);

      resource_slots = map(resource_slots, r =>
        r.id === action.result.id ? action.result : r
      );
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT_SUCCESS: {
      const production = state.data[action.production_id];

      return {
        ...state,
        data: {
          ...state.data,
          [production.id]: {
            ...production,
            resource_slots: production.resource_slots.map(t => {
              if (t.id != action.resource_slot_id) return t;

              return {
                ...t,
                events: t.events.concat(action.result)
              };
            })
          }
        },
        loading: false
      };
    }
    case PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT_SUCCESS: {
      const production = state.data[action.production_id];

      return {
        ...state,
        data: {
          ...state.data,
          [production.id]: {
            ...production,
            resource_slots: production.resource_slots.map(t => {
              if (t.id != action.resource_slot_id) return t;

              return {
                ...t,
                events: t.events.map(s =>
                  s.id == action.result.id ? action.result : s
                )
              };
            })
          }
        },
        loading: false
      };
    }
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT_SUCCESS: {
      const production = state.data[action.production_id];

      return {
        ...state,
        data: {
          ...state.data,
          [production.id]: {
            ...production,
            resource_slots: production.resource_slots.map(t => {
              if (t.id != action.resource_slot_id) return t;

              return {
                ...t,
                events: t.events.filter(
                  s => s.id != action.resource_slot_event_id
                )
              };
            })
          }
        },
        loading: false
      };
    }
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_TEAM_MEMBER_BATCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            resource_slots: state.data[action.production_id].resource_slots.map(
              t => {
                const found = action.result.results.find(i => i.id == t.id);
                return found ? merge(t, found) : t;
              }
            )
          }
        }
      };
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.entity_id]: {
            ...state.data[action.result.entity_id],
            groups: [
              ...state.data[action.result.entity_id].groups,
              action.result
            ]
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.entity_id]: {
            ...state.data[action.result.entity_id],
            groups: state.data[action.result.entity_id].groups.map(group =>
              group.id === action.result.id ? action.result : group
            )
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_GROUP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.productionId]: {
            ...state.data[action.productionId],
            groups: state.data[action.productionId].groups.filter(
              group => group.id !== action.groupId
            )
          }
        },
        loading: false
      };
    case PRODUCTIONS_ACTIONS.REORDER_PRODUCTION_GROUPS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.productionId]: {
            ...state.data[action.productionId],
            groups: action.result
          }
        },
        loading: false
      };
    case BOOKING_ACTIONS.UPDATE_BOOKING_SUCCESS:
    case BOOKING_ACTIONS.CREATE_BOOKING_SUCCESS: {
      // Sync booking.resource_slot_assignments assignments with
      // production.resource_slot.resource_slot_assignments

      if (
        !action.assignmentsRemoved &&
        !action.result.resource_slot_assignments
      ) {
        // Bail out there are no resource_slot_assignments and this is just a general booking update
        return state;
      }

      const removedUpdates = action.assignmentsRemoved
        ? action.assignmentsRemoved.reduce(
            (result, { resource_slot, ...assignment }) => {
              const { production_id } = resource_slot;
              const production = state.data[production_id];

              // Producton has not been fetch already, don't worry about it
              if (!production) return result;

              const productionUpdated = {
                ...production,
                resource_slots: production.resource_slots.map(slot => {
                  if (slot.id != resource_slot.id) return slot;
                  const removedIndex = slot.resource_slot_assignments.findIndex(
                    a => a.id == assignment.id
                  );

                  let resource_slot_assignments = [];

                  if (removedIndex >= 0) {
                    resource_slot_assignments = slot.resource_slot_assignments;
                    resource_slot_assignments.splice(removedIndex, 1);
                  }

                  return { ...slot, resource_slot_assignments };
                })
              };

              return { ...result, [production_id]: productionUpdated };
            },
            {}
          )
        : {};

      const updates = action.result.resource_slot_assignments.reduce(
        (result, { resource_slot, ...assignment }) => {
          const { production_id } = resource_slot;
          const production = result[production_id] || state.data[production_id];

          // Producton has not been fetch already, don't worry about it
          if (!production) return result;

          const productionUpdated = {
            ...production,
            resource_slots: production.resource_slots.map(slot => {
              if (slot.id != resource_slot.id) return slot;
              const index = slot.resource_slot_assignments.findIndex(
                a => a.id == assignment.id
              );

              const resource_slot_assignments =
                index < 0
                  ? slot.resource_slot_assignments.concat(assignment)
                  : slot.resource_slot_assignments.map((a, i) =>
                      i == index ? assignment : a
                    );

              return { ...slot, resource_slot_assignments };
            })
          };

          return { ...result, [production_id]: productionUpdated };
        },
        {}
      );

      return {
        ...state,
        data: { ...state.data, ...updates, ...removedUpdates }
      };
    }

    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_FAILURE:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTIONS_FAILURE:
    case PRODUCTIONS_ACTIONS.GET_BATCH_PRODUCTIONS_FAILURE:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_FAILURE:
    case PRODUCTIONS_ACTIONS.CREATE_TEAM_MEMBER_OPTION_FAILURE:
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_TEAM_MEMBER_ASSIGNMENT_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_TEAM_MEMBER_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_TEAM_MEMBER_BATCH_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_FAILURE:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_FAILURE:
    case PRODUCTIONS_ACTIONS.UNARCHIVE_PRODUCTION_FAILURE:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_EVENT_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT_FAILURE:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_EVENT_FAILURE:
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_EVENT_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_EVENT_FAILURE:
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_EVENT_FAILURE:
    case PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP_FAILURE:
    case PRODUCTIONS_ACTIONS.DELETE_PRODUCTION_GROUP_FAILURE:
    case PRODUCTIONS_ACTIONS.REORDER_PRODUCTION_GROUPS_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_INVOICE_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE_FAILURE:
    case PRODUCTIONS_ACTIONS.BATCH_UPDATE_INVOICES_FAILURE:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_ASSOCIATIONS_FAILURE:
    case PRODUCTIONS_ACTIONS.ADD_BUDGET_EXPENSE_FAILURE:
    case PRODUCTIONS_ACTIONS.ORDER_BUDGET_EXPENSES_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_RESOURCE_SLOT_FAILURE:
    case PRODUCTIONS_ACTIONS.REORDER_RESOURCE_SLOTS_FAILURE:
    case PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_FAILURE:
    case PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS_FAILURE:
    case PRODUCTIONS_ACTIONS.MOVE_PRODUCTION_FAILURE:
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR_FAILURE:
    case PRODUCTIONS_ACTIONS.DELETE_BUDGET_EXPENSE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.production_id]: {
            ...state.data[action.production_id],
            budget_expenses: state.data[
              action.production_id
            ].budget_expenses.map(b_e =>
              b_e.id == action.result.id ? action.result : b_e
            )
          }
        }
      };
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR_SUCCESS:
      return {
        ...state,
        data: action.result.productions.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.productions.map(item => item.id),
        loading: false
      };
    default:
      return state;
  }
}
