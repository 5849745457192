import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import { getProduction } from 'store/v1/productions/productions.actions.js';
import { closeModal, displayAlert } from 'store/v1/ui/ui.actions.js';
import { generateCallsheetFromProduction } from 'store/v1/callsheets/callsheets.selectors.js';

import CallsheetFormBlock from './CallsheetFormBlock/CallsheetFormBlock';

import {
  Button,
  CellProductionItem,
  CoreSearchPanel,
  EmptyGeneric,
  Fieldset,
  Grid,
  GridCell,
  ListCell,
  ListCellGroup,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PageTabs,
  PressStud,
  ResourceGroupsEditor
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { generateBlankCallsheet } from 'v1/helpers/byModel/CallsheetHelper';

import './CallsheetCreateModal.scss';
import { callsheets } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';

const CallsheetCreateModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const ui = useSelector(state => state.ui);
  const productions = useSelector(state => state.productions);

  const [production, setProduction] = useState(get(ui, ['data', 'production']));
  const [callsheet, setCallsheet] = useState({});
  const [container, setContainer] = useState(
    isEmpty(callsheet) ? 'production' : 'details'
  );

  const creationMutation = callsheets.useCreateMutation(
    response => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Updated succesfully'));
      history.push(`/app/${auth.app_id}/callsheets/${response.id}`);
    },
    error => dispatch(displayAlert('error', error))
  );

  const callsheetFromProduction = useSelector(state =>
    production
      ? generateCallsheetFromProduction(state, {
          production_id: production.id,
          selectedDate: callsheet && callsheet.from_date,
          callTime: callsheet && callsheet.call_time
        })
      : callsheet
  );

  useEffect(() => {
    let activeProduction = get(ui, 'data.production');
    if (activeProduction) {
      setProduction(productions.data[activeProduction.id]);
      setCallsheet(callsheetFromProduction);
    }
  }, []);

  useEffect(() => {
    setCallsheet(callsheetFromProduction);
  }, [production, callsheetFromProduction]);

  useEffect(() => {
    if (production) {
      setContainer('details');
    }
  }, [production]);

  function onCreateBlankCallsheet() {
    setCallsheet(generateBlankCallsheet());
    setProduction(null);
    setContainer('details');
  }

  const onSelectProduction = production => {
    setProduction(production);
    dispatch(getProduction(production.id));
  };

  const handleSubmit = () => {
    creationMutation.mutate(callsheet);
  };

  const primaryActionHandler = () => {
    switch (container) {
      case 'production':
        return {
          onClick: () => {
            setContainer('details');
          },
          className: 'btn btn-secondary',
          children: 'Next'
        };
      case 'details':
        return {
          onClick: handleSubmit,
          className: 'btn btn-primary',
          children: 'Save',
          isLoading: creationMutation.isLoading
        };
      default:
        break;
    }
  };

  const updateCallsheet = ({ values }) => {
    setCallsheet(prev => ({ ...prev, ...values }));
  };

  const renderContent = () => {
    switch (container) {
      case 'details':
        return (
          <div>
            <CallsheetFormBlock
              callsheet={callsheet}
              updateCallsheet={updateCallsheet}
              production={production}
            />
            {production && (
              <div>
                <h3>Linked Production</h3>
                <p className="text-neutral">
                  Resources from this production will be auto-populated into the
                  callsheet
                </p>
                <ListCellGroup>
                  <CellProductionItem production={production} />
                  {!isEmpty(callsheet.team_members) ? (
                    <ListCell>
                      <ResourceGroupsEditor
                        appearance="compact"
                        groups={get(callsheet, 'groups')}
                        slots={get(callsheet, 'team_members')}
                        slotComponent={slot => {
                          return (
                            <ListCell
                              key={slot.id}
                              padding="XS"
                              data-id={slot.id}
                            >
                              <Grid gutters="S" vcenter>
                                <GridCell width="3/12">
                                  <span className="text-13-600">
                                    {slot.display_role}
                                  </span>
                                </GridCell>
                                <GridCell>{slot.display_name}</GridCell>
                                {slot.display_purchase_order_number && (
                                  <GridCell>
                                    {slot.display_purchase_order_number || ''}
                                  </GridCell>
                                )}
                              </Grid>
                            </ListCell>
                          );
                        }}
                      />
                    </ListCell>
                  ) : (
                    <ListCell>
                      <EmptyGeneric
                        title="No resources assigned on this day"
                        description="There are no resources in the shoot that are booked for this day. Try changing the date or book resources within the shoot."
                      />
                    </ListCell>
                  )}
                </ListCellGroup>
              </div>
            )}
          </div>
        );
      case 'production':
        return (
          <div>
            <Fieldset>
              <Grid vcenter>
                <GridCell>
                  <h3>Create from production</h3>
                  <p className="text-neutral">
                    Auto-populate your callsheet from an existing production
                    below or start from scratch
                  </p>
                </GridCell>
                <GridCell width="auto">
                  <PressStud
                    label="Start from scratch"
                    size="S"
                    dataCy="button-start-from-scratch"
                    action={onCreateBlankCallsheet}
                  />
                </GridCell>
              </Grid>
            </Fieldset>
            <CoreSearchPanel
              selectedStore="productions"
              selected={production}
              layout={{
                className: 'CallsheetCreateModal-productionSelector'
              }}
              cellComponent={item => (
                <CellProductionItem
                  key={item.id}
                  production={item}
                  onSelect={onSelectProduction}
                />
              )}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <ModalWrapper size="M" className="CallsheetCreateModal">
      <ModalHeader title="Create callsheet" />
      <ModalContent>
        <ModalNavigation>
          <Grid gutters="S">
            <GridCell>
              <PageTabs
                tabs={[
                  {
                    text: 'Select Production',
                    container: 'production'
                  },
                  {
                    text: 'Enter Details',
                    container: 'details'
                  }
                ]}
                activeContainer={container}
                onTabSelect={container => setContainer(container)}
              />
            </GridCell>
          </Grid>
        </ModalNavigation>
        <ModalScrollable>{renderContent()}</ModalScrollable>
        <ModalNavigation>
          <Grid gutters="S">
            <GridCell />

            <GridCell width="auto">
              <Button {...primaryActionHandler()} />
            </GridCell>
          </Grid>
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default CallsheetCreateModal;
