import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation,
  Grid,
  GridCell
} from 'v1/components/shared';

import { getShortlist } from 'store/v1/shortlists/shortlists.actions';

const ShortlistUpdatedWarningModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  const shortlistId = get(ui, 'data.shortlistId');

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    dispatch(getShortlist(shortlistId)).then(() => {
      setLoading(false);
      onRequestClose();
    });
  };

  return (
    <ModalWrapper size="XS" hideClose>
      <ModalContent>
        <ModalScrollable padding="L">
          <Grid direction="column">
            <GridCell>
              <p className="text-11-600-eggplant">INFO</p>
            </GridCell>
            <GridCell>
              <p className="text-22-600">
                There are new changes in this shortlist
              </p>
            </GridCell>
            <GridCell>
              <div className="text-16-600">
                Another user may have made edits to the shortlist while you were
                working on it. Get the latest changes below.
              </div>
            </GridCell>
          </Grid>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Get latest changes"
            appearance="primary"
            isLoading={loading}
            action={handleSubmit}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ShortlistUpdatedWarningModal;
