import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Capability } from '__types__';
import useEvent from 'v1/helpers/hooks/useEvent';
import { EXPENSES_ACTIONS } from 'store/v1/expenses/expenses.constants.js';
import { deleteExpense } from 'store/v1/expenses/expenses.actions.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';

import { MenuItem, RestrictedDropdown } from 'v1/components/shared';

// TODO: should use new flyover
// NOTE: This is for Expenses that are associated with a Production but not a Booking
const ProductionExpenseOptions = ({ expenseId }) => {
  const dispatch = useDispatch();

  useEvent(EXPENSES_ACTIONS.DELETE_EXPENSE, {
    onSuccess: () => dispatch(displayAlert('success', 'Expense removed')),
    onFailure: () => dispatch(displayAlert('error', 'Something went wrong'))
  });

  const onRemoveExpense = () => {
    dispatch(
      openModal('ConfirmModal', {
        title: 'Are you sure you want to delete this expense?',
        confirmLabel: 'Confirm delete',
        onConfirm: () => dispatch(deleteExpense(expenseId))
      })
    );
  };

  return (
    <RestrictedDropdown
      capabilitiesAtLeastOne={[
        Capability.PRODUCTION_READ,
        Capability.PRODUCTION_UPDATE
      ]}
      buttonIcon={<img src="/images/icon_ellipsis.svg" alt="" />}
      buttonClass="btn btn-link btn-small btn-square"
    >
      <MenuItem
        capabilities={Capability.PRODUCTION_UPDATE}
        onSelect={onRemoveExpense}
      >
        <span className="MenuItem-icon">
          <img src="/images/icon_delete.svg" alt="" />
        </span>
        <span className="MenuItem-label">Remove expense</span>
      </MenuItem>
    </RestrictedDropdown>
  );
};

ProductionExpenseOptions.propTypes = {
  expenseId: PropTypes.number
};

export default ProductionExpenseOptions;
