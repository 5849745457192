// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import Select, { ActionMeta } from 'react-select';

// Utils
import { selectUserGroupsList } from 'store/userGroups';
import { customStyles } from './customStyles';

type OptionType = {
  value: string;
  label: string;
};

type MultipleUserGroupSelectorProps = {
    selectedOptions: string[];
    onChange: (option: readonly OptionType[], actionMeta: ActionMeta<OptionType>) => {};
    className?: string;
};

const MultipleUserGroupSelector = ({className, selectedOptions, onChange}: MultipleUserGroupSelectorProps) => {
    const userGroups = useSelector(selectUserGroupsList);
    const getMappedOptions = userGroups.map(({id: value, name: label}) => ({value, label}));

    return (
        <Select
            isMulti
            value={getMappedOptions.filter(({value}) => selectedOptions.includes(value))}
            className={className}
            styles={customStyles}
            onChange={onChange}
            options={getMappedOptions}
            captureMenuScroll={false}
        />
    );
};

export default MultipleUserGroupSelector;
