import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Capability, FeatureFlag } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';
import {
  searchContacts,
  createContact
} from 'store/v1/contacts/contacts.actions.js';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Loading,
  EmptyGeneric,
  ContactItemCompact,
  ListCellGroup,
  ListCell,
  PressStud,
  TextInput
} from 'v1/components/shared';

import { createNameFromString } from 'v1/helpers/contactHelpers';

import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import classnames from 'classnames';

export const MultiResourceSelector = ({
  query: initialQuery = {},
  inputStyle = {},
  className,
  onSelect,
  disabled,
  initialValue = [],
  allowCreateNew,
  onBlur
}) => {
  const dispatch = useDispatch();

  // STORE
  const contacts = useSelector(state => state.contacts);

  // STATE
  const [query, setQuery] = useState(initialQuery);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    onSearch(query);
  }, [query]);

  const onSearch = useCallback(debounce(onSearchContacts, 300), []);

  function onCreateResource() {
    const { first_name, last_name } = createNameFromString(get(query, 'query'));

    const toSubmit = {
      first_name,
      last_name,
      resource_type_id: get(query, 'filters.resource_type_id.eq.0') // TODO: We need to handle cases where there are multiple resource_types with the same model.
    };
    // TODO: should default to getDefaultResourceType
    dispatch(createContact(toSubmit, null, 'POPOVER_RESOURCE_RESULTS'));
  }

  function onSelectResource(resource) {
    setValue(prev => [...prev, resource.id]);
    if (typeof onSelect === 'function') {
      onSelect([...value, resource.id]);
    }
  }

  function onSearchContacts(newQuery) {
    dispatch(searchContacts(newQuery, null, null, 'POPOVER_RESOURCE_RESULTS'));
  }

  function handleBlur(field, value) {
    onBlur && onBlur(field, value);
  }

  function onQueryChange(event) {
    setQuery(query => {
      return {
        ...query,
        query: get(event, 'target.value')
      };
    });
  }

  /**
   * handleClearInput will clear the assigned relationship
   */
  const handleClearInput = id => {
    const updatedValues = value;
    const index = updatedValues.indexOf(id);
    if (index > -1) {
      updatedValues.splice(index, 1);
    }
    setValue(updatedValues);
    if (typeof onSelect === 'function') {
      onSelect(updatedValues);
    }
  };

  function onResetQuery() {
    onSearch(query);
  }

  /**
   * Returns the name of the contact or an empty string if it could not be resolved
   * @param {Object|number} contact Contact object or contact ID
   * @return {string}
   */
  function resolveResourceName(contact) {
    if (typeof contact === 'string') {
      // TODO: Remove support for string value
      // This is to support the way this component used to work. However, it does not make sense to pass the resource
      // name as the value for this component. This is because the component fetches contacts and then manages the
      // value state itself. The parent component only manages the initial value...
      return contact;
    }
    if (typeof contact === 'number') {
      return get(contacts, ['data', contact, 'full_name'], '');
    }
    return get(contact, 'full_name', '');
  }

  const resolveResource = resourceID => {
    return get(contacts, ['data', resourceID], null);
  };

  function renderSelection() {
    return (
      value &&
      value.map(id => {
        const resource = resolveResource(id);
        if (resource) {
          return (
            <ContactItemCompact
              className="stack-XS"
              size="small"
              hideResourceMeta
              showResourceContact
              contact={resource}
              previewMethod="SIDEBAR"
              onClear={() => handleClearInput(id)}
            />
          );
        }
        return false;
      })
    );
  }

  function renderInput() {
    return (
      <TextInput
        id="MultiResourceSelector-input"
        disabled={disabled}
        value={resolveResourceName(value)}
        onBlur={handleBlur}
        onFocus={onResetQuery}
        onChange={onQueryChange}
        highlightOnFocus
        removeAutocomplete
        {...inputStyle}
      />
    );
  }

  function renderResults(closePopover) {
    const resultIds = get(
      contacts,
      ['local_store', 'POPOVER_RESOURCE_RESULTS'],
      []
    );
    const resource_filteredList = map(resultIds, id =>
      get(contacts, ['data', id])
    );

    if (contacts.loading === 'POPOVER_RESOURCE_RESULTS') {
      return <Loading />;
    }

    if (!isEmpty(resource_filteredList)) {
      return (
        <>
          <ListCellGroup className="stack-XS">
            {map(resource_filteredList, (contact, index) => (
              <ListCell key={contact.id}>
                <ContactItemCompact
                  key={`result-${index}`}
                  className="MultiResourceSelector-results-item"
                  contact={contact}
                  size="small"
                  newWindow
                  action={{
                    label: 'Select',
                    className: 'btn btn-primary btn-small',
                    onClick: () => {
                      onSelectResource(contact);
                      return closePopover();
                    }
                  }}
                />
              </ListCell>
            ))}
          </ListCellGroup>
        </>
      );
    } else {
      return (
        <EmptyGeneric
          icon="/images/icon_colour_search.svg"
          title={
            !isEmpty(query.query)
              ? 'No resources found'
              : 'Enter a name to search'
          }
        />
      );
    }
  }

  return (
    <>
      {renderSelection()}
      <Popover className={classnames(['MultiResourceSelector', className])}>
        <PopoverTrigger>{renderInput()}</PopoverTrigger>
        <PopoverContent>
          {({ closePopover }) => (
            <>
              {renderResults(closePopover)}
              {get(query, 'query') && allowCreateNew && (
                <RestrictedAccess
                  flag={FeatureFlag.RESOURCES}
                  capabilities={Capability.RESOURCE_CREATE}
                >
                  <PressStud
                    label={`Add "${query.query}" as a new resource`}
                    size="full"
                    action={onCreateResource}
                  />
                </RestrictedAccess>
              )}
            </>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

MultiResourceSelector.propTypes = {
  query: PropTypes.object,
  inputStyle: PropTypes.object,
  className: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  onSelect: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  allowCreateNew: PropTypes.bool
};

export default MultiResourceSelector;
