import { Capability, FeatureFlag } from '__types__';
import Account from 'lib/auth/Account';

export const hasFlag = (
  featureFlags: FeatureFlag[], // From selectActiveFeatureFlags selector
  requiredFlag: FeatureFlag
): boolean => {
  return !featureFlags ? false : featureFlags.includes(requiredFlag);
};

export const hasFlagOneOf = (
  featureFlags: FeatureFlag[], // From selectActiveFeatureFlags selector
  requiredFlagsAtLeastOne: FeatureFlag[]
): boolean => {
  if (requiredFlagsAtLeastOne.length > 0) {
    for (let i = 0; i < requiredFlagsAtLeastOne.length; i++) {
      if (hasFlag(featureFlags, requiredFlagsAtLeastOne[i])) {
        return true;
      }
    }
  }
  return false;
};

export function createHasCapability(isSuperUser: () => boolean) {
  return (
    capabilities: Partial<Record<Capability, boolean>>, // From selectActiveCapabilities selector
    requiredCapability: Capability
  ): boolean => {
    if (isSuperUser()) {
      return true;
    }
    return !!capabilities[requiredCapability];
  };
}

export const NO_CAPABILITIES = Symbol('NO_CAPABILITIES');

export function createCheckOverallAccess(isSuperUser: () => boolean) {
  const hasCapability = createHasCapability(isSuperUser);
  return (
    requiredFlag: FeatureFlag | null = null,
    requiredCapabilities:
      | Capability
      | Capability[]
      | null
      | typeof NO_CAPABILITIES = null,
    requiredCapabilitiesAtLeastOne: Capability[] | null = null,
    activeFeatureFlags: FeatureFlag[], // From selectActiveFeatureFlags selector
    activeCapabilities: Partial<Record<Capability, boolean>> // From selectActiveCapabilities selector
  ): boolean => {
    const featureEnabled = requiredFlag
      ? hasFlag(activeFeatureFlags, requiredFlag)
      : true;

    if (requiredCapabilities === NO_CAPABILITIES) {
      return featureEnabled;
    }

    if (!featureEnabled) {
      return false;
    }

    if (isSuperUser()) {
      return true;
    }

    if (typeof requiredCapabilities === 'string') {
      requiredCapabilities = [requiredCapabilities];
    }

    if (
      Array.isArray(requiredCapabilities) &&
      requiredCapabilities.length > 0
    ) {
      let isGranted = true;
      for (let i = 0; i < requiredCapabilities.length; i++) {
        isGranted =
          isGranted &&
          !!hasCapability(activeCapabilities, requiredCapabilities[i]);
      }
      return isGranted;
    }

    if (
      Array.isArray(requiredCapabilitiesAtLeastOne) &&
      requiredCapabilitiesAtLeastOne.length > 0
    ) {
      for (let i = 0; i < requiredCapabilitiesAtLeastOne.length; i++) {
        if (
          hasCapability(activeCapabilities, requiredCapabilitiesAtLeastOne[i])
        ) {
          return true;
        }
      }
    }

    return false;
  };
}

export const hasCapability = createHasCapability(Account.isSuperUser);
export const checkOverallAccess = createCheckOverallAccess(Account.isSuperUser);
