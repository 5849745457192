import { BOOKING_TYPES_ACTIONS } from 'store/v1/booking_types/booking_types.constants.js';
import { pick } from 'lodash';

const token = true;

// UNUSED
export const getBookingTypes = (event_id = null) => {
  return {
    types: [
      BOOKING_TYPES_ACTIONS.GET_BOOKING_TYPES,
      BOOKING_TYPES_ACTIONS.GET_BOOKING_TYPES_SUCCESS,
      BOOKING_TYPES_ACTIONS.GET_BOOKING_TYPES_FAILURE
    ],
    promise: client => client.get('/booking_types', { token }),
    event_id
  };
};

export const createBookingType = (data, event_id = null) => {
  return {
    types: [
      BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE,
      BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE_SUCCESS,
      BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE_FAILURE
    ],
    promise: client => client.post('/booking_types', { data, token }),
    event_id
  };
};

export const updateBookingType = (id, data, event_id = null) => {
  const payload = pick(data, [
    'id',
    'name',
    'order',
    'metastructure',
    'include_weekends_default'
  ]);
  return {
    types: [
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE,
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_SUCCESS,
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_FAILURE
    ],
    promise: client =>
      client.put(`/booking_types/${id}`, {
        data: payload,
        token
      }),
    event_id
  };
};

export const updateBookingTypeOrder = (data, event_id = null) => {
  const payload =
    data &&
    data.map(bookingType => ({
      id: bookingType.id,
      order: bookingType.order
    }));
  return {
    types: [
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_ORDER,
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_ORDER_SUCCESS,
      BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_ORDER_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/booking_types`, {
        data: { reorder: payload },
        token
      }),
    event_id
  };
};

export const deleteBookingType = (id, event_id = null) => {
  return {
    types: [
      BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE,
      BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE_SUCCESS,
      BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE_FAILURE
    ],
    promise: client => client.del(`/booking_types/${id}`, { token }),
    event_id,
    id
  };
};
