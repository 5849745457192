import { appendDefaultStartTimeToDate } from 'v1/helpers/byType/dateHelper';
import { getEventStart } from 'v1/helpers/byModel/EventHelper';
import { DEFAULT_START_TIME } from 'v1/helpers/byType/dateHelper';

import moment from 'moment';
import get from 'lodash/get';
import omit from 'lodash/omit';

/**
 * @function generateBlankCallsheet
 * Returns a blank callsheet object with the required fields to submit
 *
 */
export function generateBlankCallsheet() {
  return {
    from_date: moment().format('YYYY-MM-DD'),
    production_date: moment().format(), // TODO: Server expects a value even though we do not need this if the callsheet is not linked to a production...
    timezone: moment().tz(),
    call_time: DEFAULT_START_TIME,
    locations: [],
    schedule: [],
    team_members: []
  };
}

/**
 * @function generateCallsheetTitleFromProduction
 * Returns a string to populate the callsheet title from production details
 *
 * @param {Object} production
 */
export function generateCallsheetTitleFromProduction(production) {
  return `${get(production, 'code') ? get(production, 'code') + ' - ' : ''}${
    get(production, 'title') || ''
  }`;
}

export const bookingConfirmedCheck = (bookingId, { bookings, statuses }) => {
  const booking = get(bookings, ['data', bookingId], {});
  const status = get(statuses, ['data', booking.status_id], {});

  return booking && status && status.status_type === 'CONFIRMED';
};

/**
 * @function generateCallsheetProductionDate
 * Gets production date event object and gives a production_date for callsheet
 * Currently required to create callsheet but questionable if required in the future.
 *
 * @param {Object} production
 */
export function generateCallsheetProductionDate(production) {
  const { production_date } = production;
  if (!production_date) return;
  if ((production_date.date_type = 'RECURRING_DEFAULT')) {
    const date = production_date.start_date;
    return appendDefaultStartTimeToDate(date);
  }
  if ((production_date.date_type = 'INCLUSIVE_HOURS')) {
    return production_date.start_timestamp;
  }
}

/**
 * @function generateCallsheetFromDate
 * Creates a from_date from the production start date
 *
 * @param {Object} production
 */

export function generateCallsheetFromDate(production) {
  if (!production) return;
  const { production_date } = production;
  return moment(getEventStart(production_date)).format('YYYY-MM-DD');
}

/**
 * @function sanitiseCallsheetDates
 *
 * @param {Object} callsheet - [Callsheet] - The callsheet
 */

export function sanitiseCallsheetDates(callsheet) {
  const fromDate = callsheet.from_date.split('T');
  const toDate = callsheet.to_date ? callsheet.to_date.split('T') : null;
  return {
    ...callsheet,
    from_date: fromDate[0],
    to_date: toDate ? toDate[0] : callsheet.to_date
  };
}

// TODO: Change logic to omit
export const CALLSHEET_FORBIDDEN_CREATE_FIELDS = [
  'archived',
  'created_at',
  'created_mechanism',
  'enable_notifications',
  'guid',
  'legacy_user_id',
  'published_at',
  'updated_at',
  'user_id',
  'id'
];
export function removeCallsheetSystemFields(callsheet) {
  if (!callsheet) return {};
  return omit(callsheet, CALLSHEET_FORBIDDEN_CREATE_FIELDS);
}
