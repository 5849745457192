import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import {
  PressStud,
  TextInput,
  CurrencyInput,
  RestrictedDropdown,
  MenuItem,
  Status,
  Grid,
  GridCell,
  ListCell,
  Select,
  FileUploader,
  FileItem
} from 'v1/components/shared';

import { Capability } from '__types__';
import {
  convertToWholeUnit,
  currencyFormatter
} from 'v1/helpers/currencyHelper';
import useEvent from 'v1/helpers/hooks/useEvent';
import { EXPENSES_ACTIONS } from 'store/v1/expenses/expenses.constants.js';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import { INVOICE_STATUS } from 'v1/helpers/DropdownDataSets';

import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import './ProductionBudgetInvoiceItem.scss';

export const ProductionBudgetInvoiceItem = ({
  invoice: initialInvoice,
  onCreateInvoice,
  onUpdateInvoice,
  onRemoveInvoice
}) => {
  const { loading } = useSelector(state => state.bookings);
  const defaultCurrency = useSelector(state =>
    get(state, 'auth.settings.settings.defaults_currency.value')
  );
  const isNew =
    typeof initialInvoice.id === 'string' && initialInvoice.id.includes('temp');
  const [isEditing, setEditable] = useState(isNew);
  const [invoice, setInvoice] = useState(initialInvoice);
  const [tempFile, setTempFile] = useState(null);

  useEvent(
    [
      EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT,
      EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT,
      BOOKING_ACTIONS.CREATE_INVOICES,
      BOOKING_ACTIONS.UPDATE_INVOICE
    ],
    {
      onSuccess: () => {
        setEditable(false);
      }
    }
  );
  const onChange = (field, value) => {
    setInvoice(prev => set(field, value, prev));
  };

  const onCancel = () => {
    if (isNew) {
      onRemoveInvoice(invoice, true);
      return;
    }

    setInvoice(initialInvoice);
    setEditable(false);
  };

  const renderFile = file => {
    const renderFile = !isEmpty(file) ? file : tempFile;
    if (renderFile) {
      return <FileItem size="S" file={renderFile} layout="LINK" />;
    }
    return <span className="text-neutral">No file attached</span>;
  };

  const renderFileHandler = (file_id, file = {}) => {
    if (file_id) {
      return renderFile(file);
    } else {
      return (
        <FileUploader
          className="btn btn-default btn-small ProductionBudgetInvoiceItem-upload"
          renderContent={() => 'Upload a file'}
          onFileCompleted={file => {
            setTempFile(file);
            onChange('file_id', file.file_id);
          }}
          secure
        />
      );
    }
  };

  const { budget_locale, file_id, file, status } = invoice;
  const document_data = invoice.document_data ? invoice.document_data : {};

  return (
    <ListCell padding="XS">
      <Grid gutters="XS" vcenter>
        <GridCell>
          {isEditing ? (
            <TextInput
              size="S"
              field="document_data.reference"
              name="reference"
              value={document_data.reference}
              onChange={e => {
                onChange('document_data.reference', e.target.value);
              }}
              placeholder="Reference"
              validate="required"
              autoComplete="off"
            />
          ) : (
            <span className="inset-S">{document_data.reference}</span>
          )}
        </GridCell>
        <GridCell>
          {isEditing ? renderFileHandler(file_id, file) : renderFile(file)}
        </GridCell>
        <GridCell>
          {isEditing ? (
            <CurrencyInput
              size="S"
              defaultCurrency={defaultCurrency}
              value={{
                amount: document_data.amount,
                currency: document_data.currency
              }}
              onBlur={value =>
                onChange('document_data', {
                  ...get(invoice, 'document_data', {}),
                  ...value
                })
              }
            />
          ) : (
            <span>
              {currencyFormatter(
                budget_locale,
                get(invoice, 'document_data.currency')
              ).format(convertToWholeUnit(document_data.amount))}
            </span>
          )}
        </GridCell>
        <GridCell>
          {isEditing ? (
            <>
              <Select
                size="S"
                value={status}
                onChange={({ target: { value } }) => onChange('status', value)}
              >
                <option value="">Status</option>
                {INVOICE_STATUS.map(status => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </>
          ) : (
            <Status statusId={status} />
          )}
        </GridCell>
        {isEditing ? (
          <>
            <GridCell width="auto">
              <PressStud size="S" label="Cancel" action={onCancel} />
            </GridCell>
            <GridCell width="auto">
              <PressStud
                size="S"
                label="Save"
                appearance="primary"
                isLoading={
                  loading === EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT ||
                  loading === EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT ||
                  loading === BOOKING_ACTIONS.CREATE_INVOICES ||
                  loading === BOOKING_ACTIONS.UPDATE_INVOICE
                }
                action={() => {
                  isNew ? onCreateInvoice(invoice) : onUpdateInvoice(invoice);
                }}
              />
            </GridCell>
          </>
        ) : (
          <GridCell width="auto">
            <RestrictedDropdown
              capabilitiesAtLeastOne={[Capability.CONFIGURE_PRODUCTION_BUDGET]}
              buttonClass="btn btn-link btn-square"
              buttonIcon={<img src="/images/icon_options.svg" alt="" />}
            >
              <MenuItem
                capabilities={Capability.CONFIGURE_PRODUCTION_BUDGET}
                onSelect={() => {
                  setEditable(true);
                }}
              >
                <span className="MenuItem-icon">
                  <img src="/images/icon_edit.svg" alt="" />
                </span>
                <span className="MenuItem-label">Edit</span>
              </MenuItem>
              <MenuItem
                capabilities={Capability.CONFIGURE_PRODUCTION_BUDGET}
                onSelect={() => {
                  onRemoveInvoice(invoice);
                }}
              >
                <span className="MenuItem-icon">
                  <img src="/images/icon_delete.svg" alt="" />
                </span>
                <span className="MenuItem-label">Delete</span>
              </MenuItem>
            </RestrictedDropdown>
          </GridCell>
        )}
      </Grid>
    </ListCell>
  );
};

export default ProductionBudgetInvoiceItem;
