const CONTACT = 1;
const CALLOUT = 2;
const APPLICANT = 3;
const SHORTLIST = 4;
const CALLSHEET = 5;
const PRODUCTION = 6;
const CUSTOM_FIELD = 7;
const CUSTOM_FIELD_DEFINITION = 8;
const FILE = 9;
const DOCUMENT = 10;
const PIN = 11;
const GROUP = 12;
const TAG = 13;
const NOTE = 14;
const RATE = 15;
const USER = 16;
const RESOURCE_TYPE = 17;
const LOCATION = 18;
const SHORTLIST_BLOCK = 19;
const SHORTLIST_ACTION = 20;
const CALLSHEET_TEAM_MEMBER = 21;
const CALLSHEET_RECIPIENT = 22;
const NOTIFICATION = 23;
const RESOURCE_SLOT_ASSIGNMENT = 24;
const RESOURCE_SLOT = 25;
const SCHEDULE_ITEM = 26;
const BOOKING = 27;
const BUDGET_EXPENSE = 28;
const IMPORT = 29;
const EVENT = 30;
const CUSTOM_FIELDGROUP = 31;
const CUSTOM_FIELDGROUP_DEFINITION = 32;
const BOOKING_TYPE = 33;
const EXPENSE_TYPE = 34;
const PRODUCTION_TYPE = 35;
const SHORTLIST_TYPE = 36;
const EXPENSE = 37;
const PRODUCTION_TEMPLATE = 38;
const STATUS = 39;

function nameToEntityType(name) {
  switch (name) {
    case 'contacts':
      return CONTACT;
    case 'productions':
    case 'production':
      return PRODUCTION;
    case 'events':
    case 'availability':
      return EVENT;
    case 'bookings':
    case 'booking':
      return BOOKING;
    case 'resource_types':
      return RESOURCE_TYPE;
    default:
      console.error('nameToEntityType name not supported');
      return '';
  }
}

function entityTypeToName(entityType) {
  switch (entityType) {
    case CONTACT:
      return 'contacts';
    case CALLOUT:
      return 'callouts';
    case APPLICANT:
      return 'applicants';
    case SHORTLIST:
      return 'shortlists';
    case CALLSHEET:
      return 'callsheets';
    case PRODUCTION:
      return 'productions';
    case CUSTOM_FIELD:
      return 'custom_fields';
    case CUSTOM_FIELD_DEFINITION:
      return 'custom_field_definitions';
    case CUSTOM_FIELDGROUP:
      return 'custom_fieldgroups';
    case CUSTOM_FIELDGROUP_DEFINITION:
      return 'custom_fieldgroup_definitions';
    case FILE:
      return 'files';
    case DOCUMENT:
      return 'documents';
    case PIN:
      return 'pins';
    case GROUP:
      return 'groups';
    case TAG:
      return 'tags';
    case NOTE:
      return 'notes';
    case RATE:
      return 'rates';
    case USER:
      return 'users';
    case RESOURCE_TYPE:
      return 'resource_types';
    case LOCATION:
      return 'locations';
    case SHORTLIST_BLOCK:
      return 'shortlist_blocks';
    case SHORTLIST_ACTION:
      return 'shortlist_actions';
    case CALLSHEET_TEAM_MEMBER:
      return 'callsheet_team_members';
    case CALLSHEET_RECIPIENT:
      return 'callsheet_recipients';
    case NOTIFICATION:
      return 'notifications';
    case RESOURCE_SLOT_ASSIGNMENT:
      return 'assignments';
    case RESOURCE_SLOT:
      return 'resource_slots';
    case BUDGET_EXPENSE:
      return 'budget_expense';
    case IMPORT:
      return 'imports';
    case EVENT:
      return 'events';
    case BOOKING_TYPE:
      return 'booking_types';
    case EXPENSE_TYPE:
      return 'expense_types';
    case PRODUCTION_TYPE:
      return 'production_types';
    case SHORTLIST_TYPE:
      return 'shortlist_types';
    case EXPENSE:
      return 'expenses';
    case PRODUCTION_TEMPLATE:
      return 'production_templates';
    case STATUS:
      return 'statuses';
    default:
      console.error('entityTypeToName entity type not supported');
      return '';
  }
}

const entityTypeToLabel = entityType => {
  switch (entityType) {
    case CONTACT:
      return 'Resources';
    case CALLOUT:
      return 'Callouts';
    case SHORTLIST:
      return 'Shortlists';
    case CALLSHEET:
      return 'Callsheets';
    case PRODUCTION:
      return 'Productions';
    case BOOKING:
      return 'Bookings';
    default:
      return null;
  }
};

export {
  nameToEntityType,
  entityTypeToName,
  entityTypeToLabel,
  CONTACT,
  CALLOUT,
  APPLICANT,
  SHORTLIST,
  CALLSHEET,
  PRODUCTION,
  CUSTOM_FIELD,
  CUSTOM_FIELD_DEFINITION,
  CUSTOM_FIELDGROUP,
  CUSTOM_FIELDGROUP_DEFINITION,
  FILE,
  DOCUMENT,
  PIN,
  GROUP,
  TAG,
  NOTE,
  RATE,
  USER,
  RESOURCE_TYPE,
  LOCATION,
  SHORTLIST_BLOCK,
  SHORTLIST_ACTION,
  CALLSHEET_TEAM_MEMBER,
  CALLSHEET_RECIPIENT,
  NOTIFICATION,
  RESOURCE_SLOT,
  RESOURCE_SLOT_ASSIGNMENT,
  BOOKING,
  SCHEDULE_ITEM,
  BUDGET_EXPENSE,
  IMPORT,
  EVENT,
  BOOKING_TYPE,
  EXPENSE_TYPE,
  PRODUCTION_TYPE,
  SHORTLIST_TYPE,
  EXPENSE,
  STATUS
};
