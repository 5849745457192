import { RATES_ACTIONS } from 'store/v1/rates/rates.constants.js';

const token = true;

export const getRates = (event_id = null) => {
  return {
    types: [
      RATES_ACTIONS.GET_RATES,
      RATES_ACTIONS.GET_RATES_SUCCESS,
      RATES_ACTIONS.GET_RATES_FAILURE
    ],
    promise: client => client.get('/rates', { token }),
    event_id
  };
};

export const createRate = (data, event_id = null) => {
  return {
    types: [
      RATES_ACTIONS.CREATE_RATE,
      RATES_ACTIONS.CREATE_RATE_SUCCESS,
      RATES_ACTIONS.CREATE_RATE_FAILURE
    ],
    promise: client => client.post('/rates', { data, token }),
    event_id
  };
};

export const updateRate = ({ rate_id }, data, event_id = null) => {
  return {
    types: [
      RATES_ACTIONS.UPDATE_RATE,
      RATES_ACTIONS.UPDATE_RATE_SUCCESS,
      RATES_ACTIONS.UPDATE_RATE_FAILURE
    ],
    promise: client => client.put(`/rates/${rate_id}`, { data, token }),
    event_id,
    rate_id
  };
};

export const deleteRate = (id, event_id = null) => {
  return {
    types: [
      RATES_ACTIONS.DELETE_RATE,
      RATES_ACTIONS.DELETE_RATE_SUCCESS,
      RATES_ACTIONS.DELETE_RATE_FAILURE
    ],
    promise: client => client.del(`/rates/${id}`, { token }),
    event_id,
    rate_id: id
  };
};
