import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  ListCell,
  Grid,
  GridCell,
  Checkbox,
  Textarea,
  TextInput,
  PressStud,
  Label
} from 'v1/components/shared';
import { isTempId } from 'v1/helpers/misc';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import './TaskListItem.scss';

const TaskListItem = ({ task: initialTask, onDeleteTask, onUpdateTask }) => {
  const [task, setTask] = useState(initialTask);
  const [isExpanded, setIsExpanded] = useState(isEmpty(initialTask.title));
  const { title, description, status, id } = task;

  const onDebounceUpdateTask = useCallback(
    debounce(updatedTask => onUpdateTask(updatedTask), 500),
    []
  );

  function onUpdate(field, value) {
    const updatedTask = { ...task, [field]: value };
    setTask(updatedTask);
    onDebounceUpdateTask(updatedTask);
  }

  return (
    <ListCell
      className="TaskListItem draggable"
      padding="XS"
      data-id={task.taskId}
    >
      <Grid gutters="XS">
        <GridCell width="auto">
          <span className="DragIndicator">
            <img src="/images/icon_drag.svg" alt="" />
          </span>
        </GridCell>
        <GridCell className="TaskListItem-status" width="auto">
          <Checkbox
            size="L"
            name="status"
            id="status"
            checked={status === 'COMPLETED'}
            className="stack-0"
            onChange={({ target }) =>
              onUpdate('status', target.checked ? 'COMPLETED' : 'OPEN')
            }
          />
        </GridCell>
        <GridCell>
          <Grid className="Parent_hoverListener">
            <GridCell>
              <TextInput
                className=""
                label="TaskListItem-label"
                placeholder="Task title"
                size="M"
                value={title}
                appearance="silent"
                onChange={({ target }) => onUpdate('title', target.value)}
                initialFocus={isTempId(id)}
              />
            </GridCell>
            <GridCell width="auto">
              <PressStud
                icon={isExpanded ? 'arrowUpGray' : 'arrowDownGray'}
                action={() => setIsExpanded(prev => !prev)}
                appearance="silent"
              />
            </GridCell>
            <GridCell className="Child_hoverListener" width="auto">
              <PressStud
                icon="delete"
                appearance="silent"
                action={() => onDeleteTask(task)}
              />
            </GridCell>
          </Grid>
          {isExpanded && (
            <Grid className="TaskListItem-expanded">
              <GridCell width="2/12">
                <Label>Description</Label>
              </GridCell>
              <GridCell>
                <Textarea
                  name="description"
                  className="TaskListItem-description"
                  value={description}
                  placeholder="Task description"
                  onChange={({ target }) =>
                    onUpdate('description', target.value)
                  }
                  onBlur={() => {}}
                  appearance="silent"
                  autoComplete="off"
                  rows={3}
                />
              </GridCell>
            </Grid>
          )}
        </GridCell>
      </Grid>
    </ListCell>
  );
};

TaskListItem.propTypes = {
  task: PropTypes.object.isRequired,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func
};

export default TaskListItem;
