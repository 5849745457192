import { createContext } from 'react';

type ModalContextProperties = {
  setCustomCancel: (customCancel: () => void) => void;
  setCustomClose: (customClose: () => void) => void;
  openModal: (component: React.FC) => void;
  cancelModal: () => void;
  closeModal: () => void;
  cancelAllModals: () => void;
  closeAllModals: () => void;
};

const ModalContext = createContext<ModalContextProperties>({
  setCustomCancel: () => {},
  setCustomClose: () => {},
  openModal: () => {},
  cancelModal: () => {},
  closeModal: () => {},
  cancelAllModals: () => {},
  closeAllModals: () => {}
});

ModalContext.displayName = 'ModalContext';

export default ModalContext;
