import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, find, chain, size } from 'lodash';

import { selectActiveAccountId } from 'store/session';
import { getBookingsStatuses } from 'store/v1/statuses/statuses.selectors.js';
import {
  getBooking,
  createBooking,
  updateBooking
} from 'store/v1/bookings/bookings.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { createNewEventObject } from 'v1/helpers/byModel/EventHelper';
import { getResourceDefaultRate } from 'v1/helpers/byModel/ResourceHelper';
import { getDataFromContactRate } from 'v1/helpers/byModel/RateHelper';
import { runBookingChecks, getDefaultStatus } from 'v1/helpers/bookingHelper';
import { orderReducer } from 'v1/helpers/misc';

import FormBuilder from 'v1/components/FormBuilder/FormBuilder';
import { Grid, GridCell, StackedFormSection } from 'v1/components/shared';

import './ResourceEventsForm.scss';
import { getContact } from '~/store/v1/contacts/contacts.actions';

/*

IMPORTANT
====

Please see ResourceEventsModal for instructions for use, and a breakdown of the logic of this form.

Please be very careful when making alterations.

*/

const ResourceEventsForm = ({ disabled, getApi }) => {
  const dispatch = useDispatch();
  const userID = useSelector(selectActiveAccountId);
  const bookings = useSelector(state => state.bookings);
  const bookingTypes = useSelector(state => state.booking_types);
  const productions = useSelector(state => state.productions);
  const resources = useSelector(state => state.contacts);
  const statuses = useSelector(getBookingsStatuses);
  const ui = useSelector(state => state.ui);
  const defaultCurrency = useSelector(state =>
    get(state, 'auth.settings.settings.defaults_currency.value')
  );

  const bookingId = get(ui, 'data.bookingId');
  const data = get(ui, 'data');
  const resourceSlotId = get(data, 'resourceSlotId');
  const [bookingData, setBookingData] = useState();
  const [expanded, setExpanded] = useState([]);

  const defaultStatus = getDefaultStatus(get(data, 'statusId'), statuses);

  const defaultStatusId = get(defaultStatus, 'id');
  const bookingTypesList = chain(orderReducer(bookingTypes))
    .filter(b => !b.archived)
    .orderBy(['order', 'asc'])
    .value();
  const bookingType = get(bookingTypesList, ['0'], null);
  const bookingTypeId = get(bookingType, 'id', null);
  const createNewBookingData = _ => {
    const baseEvent = get(data, 'event');

    const newEvent = baseEvent
      ? createNewEventObject({
          event_type: 'BOOKING',
          timezone: baseEvent.timezone,
          date_type: baseEvent.date_type,
          start_date: baseEvent.start_date || baseEvent.end_date,
          end_date: baseEvent.end_date || baseEvent.start_date,
          start_timestamp: baseEvent.start_timestamp || baseEvent.end_timestamp,
          end_timestamp: baseEvent.end_timestamp || baseEvent.start_timestamp,
          estimated_start_time: baseEvent.estimated_start_time,
          estimated_end_time: baseEvent.estimated_end_time,
          include_weekends: bookingType.include_weekends_default
        })
      : createNewEventObject({
          event_type: 'BOOKING',
          include_weekends: bookingType.include_weekends_default
        });

    const resourceSlotAssignment =
      get(data, 'resourceSlotAssignment') ||
      (resourceSlotId
        ? {
            resource_slot_id: resourceSlotId,
            contact_id: get(data, 'resourceId'),
            events: [
              {
                ...data.resourceSlotAssignmentEvent,
                include_weekends: bookingType.include_weekends_default
              }
            ]
          }
        : null);
    const resourceId =
      get(resourceSlotAssignment, 'contact_id') || get(data, 'resourceId');
    const resource = get(resources, ['data', resourceId]);

    const contactRate = get(data, 'rate')
      ? null
      : getResourceDefaultRate(resource);
    const rateData = get(data, 'rate', getDataFromContactRate(contactRate));

    // TODO: should this use createBookingObject ?
    return {
      status_id: defaultStatusId,
      booking_type_id: bookingTypeId, // TODO: do we need this? should it be selectable?
      contact_id: resourceId,
      contact: resource, // TODO: remove as soon as the contact_id core field allows it
      user_id: userID,
      contact_rate_id: get(contactRate, 'id'),
      rate_amount: get(rateData, 'amount'),
      rate_currency: get(rateData, 'currency')
        ? get(rateData, 'currency')
        : defaultCurrency,
      rate_interval: get(rateData, 'interval')
        ? get(rateData, 'interval')
        : 'DAILY', // TODO: review how we handle defaults
      rate_agency_fee: get(rateData, 'agency_fee'),
      rate_payroll_fee: get(rateData, 'payroll_fee'),
      rate_processing_fee: get(rateData, 'processing_fee'),
      events: [newEvent],
      resource_slot_assignments: resourceSlotAssignment
        ? [resourceSlotAssignment]
        : []
    };
  };

  const createExistingBookingData = useCallback(
    booking => {
      const resourceSlotAssignment = resourceSlotId
        ? {
            resource_slot_id: resourceSlotId,
            contact_id: get(booking, 'contact_id'),
            events: [get(data, 'resourceSlotAssignmentEvent')]
          }
        : null;
      const events = get(data, 'events');

      return {
        ...booking,
        events: events || booking.events,
        resource_slot_assignments: resourceSlotAssignment
          ? [...booking.resource_slot_assignments, resourceSlotAssignment]
          : booking.resource_slot_assignments
      };
    },
    [data]
  );

  const onSubmit = booking => {
    try {
      runBookingChecks(booking, productions.data);
      booking.id
        ? dispatch(updateBooking(booking.id, booking))
        : dispatch(createBooking(booking));
    } catch (err) {
      dispatch(displayAlert('error', err));
    }
  };

  useEffect(() => {
    !bookingId && setBookingData(createNewBookingData());
  }, []);

  useEffect(() => {
    if (!bookingData && bookingId !== undefined) {
      const booking = get(bookings, ['data', bookingId]);
      booking && setBookingData(createExistingBookingData(booking));
    }
  }, [bookingId, bookings, bookingData, createExistingBookingData]);

  useEffect(() => {
    bookingId && !bookingData && dispatch(getBooking(bookingId));
  }, []);

  const resourceId = get(data, 'resourceId');
  const resource = get(resources, ['data', resourceId]);

  const formApi = useRef();

  useEffect(() => {
    if (!resource) {
      dispatch(getContact(resourceId));
    } else {
      formApi.current?.setValue('contact', resource);
    }
  }, [!!resource]);

  const bookingFields = useMemo(() => {
    return [
      { name: 'contact_id', type: 'CORE_FIELD', active: true },
      { name: 'events', type: 'CORE_FIELD', active: true },
      { name: 'expenses', type: 'CORE_FIELD', active: true },
      { name: 'resource_slot_assignments', type: 'CORE_FIELD', active: true },
      { name: 'status_id', type: 'CORE_FIELD', active: true },
      ...get(bookingTypes, `data.${bookingTypeId}.metastructure.fields`, [])
    ];
  }, [bookingTypes, bookingTypeId]);

  const fieldOverrides = {
    events: {
      eventType: 'BOOKING'
    },
    status_id: {
      statuses,
      label: null
    },
    note: {
      label: null
    }
  };

  // TODO: make this neater, maybe include the logic or something. Anyway include it in the abstracted stacked form
  const FormSectionExpander = ({ id, label }) => (
    <GridCell
      className="FormBuilder-FormGroup stack-S clickable"
      padding="M"
      onClick={() => setExpanded(e => [...e, id])}
    >
      <span className="text-16-600-eggplant">{label}</span>
    </GridCell>
  );

  // TODO: abstract out into a standard generic stacked form
  return (
    <>
      {bookingData && (
        <FormBuilder
          data={bookingData}
          fields={bookingFields}
          onSubmit={onSubmit}
          getApi={api => {
            formApi.current = api;
            getApi(api);
          }}
          disabled={disabled}
        >
          {({ values, renderCoreField, renderCustomFields }) => (
            <>
              <div className="ResourceEventsForm">
                <StackedFormSection title="Resource">
                  {renderCoreField('contact_id')}
                </StackedFormSection>
                <StackedFormSection title="Status">
                  <Grid>
                    <GridCell width="4/12">
                      {renderCoreField('status_id', fieldOverrides.status_id)}
                    </GridCell>
                  </Grid>
                </StackedFormSection>
                <StackedFormSection title="Dates">
                  {renderCoreField('events', fieldOverrides.events)}
                </StackedFormSection>
                {size(values.resource_slot_assignments) ||
                expanded.includes('resource_slot_assignments') ? (
                  <>
                    <StackedFormSection title="Assignments">
                      {renderCoreField('resource_slot_assignments')}
                    </StackedFormSection>
                  </>
                ) : (
                  <FormSectionExpander
                    id="resource_slot_assignments"
                    label="Add assignment"
                  />
                )}
                {values.rate_amount || expanded.includes('rate') ? (
                  <StackedFormSection title="Rate">
                    {renderCoreField('rate', { resourceSlotId })}
                  </StackedFormSection>
                ) : (
                  <FormSectionExpander id="rate" label="Add rate" />
                )}
                {size(values.expenses) || expanded.includes('expenses') ? (
                  <StackedFormSection title="Expenses">
                    {renderCoreField('expenses', { quickCreate: true })}
                  </StackedFormSection>
                ) : (
                  <FormSectionExpander id="expenses" label="Add expenses" />
                )}
                {size(values.contracts) || expanded.includes('contracts') ? (
                  <StackedFormSection title="Documents">
                    {renderCoreField('contracts')}
                  </StackedFormSection>
                ) : (
                  <FormSectionExpander id="contracts" label="Add documents" />
                )}
                {values.note || expanded.includes('note') ? (
                  <StackedFormSection title="Note">
                    {renderCoreField('note', fieldOverrides.note)}
                  </StackedFormSection>
                ) : (
                  <FormSectionExpander id="note" label="Add note" />
                )}
                {/* TODO: Bookings : STYLES : style labels properly to match */}
                {expanded.includes('additional') ? (
                  <StackedFormSection title="Additional">
                    {renderCustomFields()}
                  </StackedFormSection>
                ) : (
                  <FormSectionExpander id="additional" label="Additional" />
                )}
              </div>
            </>
          )}
        </FormBuilder>
      )}
    </>
  );
};

export default ResourceEventsForm;
