import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { RootStore } from 'store/reducer';
import { selectActiveAccount } from 'store/accounts';
import { Capability, UserGroup } from '__types__';

const selectUserGroupsStore = (state: RootStore) => state.userGroups;

const selectUserGroupsAreLoading = createSelector(
  selectUserGroupsStore,
  userGroups => userGroups.loading
);

const selectUserGroupsAreFetched = createSelector(
  selectUserGroupsStore,
  userGroups => userGroups.fetched
);

const selectNewUserGroup = createSelector(
  selectUserGroupsStore,
  userGroups => userGroups.new
);

const selectUserGroups = createSelector(
  selectUserGroupsStore,
  userGroups => userGroups.data
);

const selectUserGroupsList = createSelector(selectUserGroups, data =>
  Object.values(data).filter(e => !e.dateArchived)
);

const selectUserGroupById = createCachedSelector(
  selectUserGroups,
  (_: RootStore, id: string) => id,
  (data, id) => data[id]
)((_, id) => id);

const selectActiveUserGroups = createSelector(
  state => selectActiveAccount(state), // Bypass circular dependency 😅
  selectUserGroups,
  (account, userGroups) => {
    return account
      ? account.userGroupIds.reduce<UserGroup[]>((acc, id) => {
          const userGroup = userGroups[id];
          return userGroup && !userGroup.dateArchived
            ? [...acc, userGroup]
            : acc;
        }, [])
      : [];
  }
);

const selectActiveCapabilities = createSelector(
  selectActiveUserGroups,
  userGroups => {
    return userGroups.reduce<Partial<Record<Capability, boolean>>>(
      (acc, userGroup) => {
        userGroup.capabilities &&
          userGroup.capabilities.forEach(
            capability => (acc = { ...acc, [capability]: true })
          );
        return acc;
      },
      {}
    );
  }
);

export {
  selectUserGroupsStore,
  selectUserGroupsAreLoading,
  selectUserGroupsAreFetched,
  selectNewUserGroup,
  selectUserGroups,
  selectUserGroupsList,
  selectUserGroupById,
  selectActiveUserGroups,
  selectActiveCapabilities
};
