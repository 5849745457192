import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { openModal } from 'store/v1/ui/ui.actions.js';

import {
  UserCircleImage,
  MiniLoader,
  FileUploader
} from 'v1/components/shared';
import { RestrictedAccess } from 'lib/restrictedAccess';

import classnames from 'classnames';
import './ProfileImageUploader.scss';

export const ProfileImageUploader = ({
  contact = {},
  src,
  onUpload,
  field,
  label,
  onRemoveImage,
  className,
  capability,
  hideLabel,
  uploadContent
}) => {
  const dispatch = useDispatch();

  function renderPicture() {
    return (
      <UserCircleImage
        className="ProfileImageUploader-image default"
        src={src}
        contact_id={contact.id}
        initials={{
          first_name: contact.first_name,
          last_name: contact.last_name
        }}
        colours={contact.groups && contact.groups.map(group => group.colour)}
      />
    );
  }

  function renderUploader() {
    return (
      <FileUploader
        className="ProfileImageUploader-FileUploader"
        uploaderClass="ProfileImageUploader-FileUploader-uploader"
        renderContent={() => uploadContent || 'Upload'}
        renderLoading={() => (
          <div className="ProfileImageUploader-FileUploader-loading">
            <MiniLoader isLoading={true} />
          </div>
        )}
        onFileCompleted={file => onUpload(field, file.url)}
        secure={false}
      />
    );
  }

  function renderLabel() {
    if (hideLabel) return null;
    return (
      <div className="ProfileImageUploader-label">
        {src && onRemoveImage ? (
          <span
            onClick={() => onRemoveImage(field, null)}
            className="ProfileImageUploader-label_remove"
          >
            Remove
          </span>
        ) : (
          <FileUploader
            renderContent={() => label || 'Upload Image'}
            uploaderClass="ProfileImageUploader-label-uploaderLabel"
            renderLoading={() => <span>Uploading...</span>}
            onFileCompleted={file => onUpload(field, file.url)}
            secure={false}
          />
        )}
      </div>
    );
  }

  function renderExpander() {
    return (
      <span
        className="ProfileImageUploader-FileUploader"
        onClick={() => {
          dispatch(
            openModal('MediaFocusModal', {
              mediaType: 'PINS',
              mediaSelected: { url: src, pin_type: 'image/jpeg' },
              mediaList: [{ url: src, pin_type: 'image/jpeg' }]
            })
          );
        }}
      >
        Expand
      </span>
    );
  }

  return (
    <div className={classnames(['ProfileImageUploader', className])}>
      <div className="ProfileImageUploader-image">
        {renderPicture()}
        <RestrictedAccess capabilities={capability}>
          {src ? renderExpander() : renderUploader()}
        </RestrictedAccess>
      </div>
      <RestrictedAccess capabilities={capability}>
        {renderLabel()}
      </RestrictedAccess>
    </div>
  );
};

ProfileImageUploader.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  src: PropTypes.string,
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  onUpload: PropTypes.func, // TODO: arguments
  onRemoveImage: PropTypes.func, // TODO: arguments
  hideLabel: PropTypes.bool,
  uploadContent: PropTypes.string
};

export default ProfileImageUploader;
