import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { entityTypeToLabel } from 'v1/helpers/entityTypes';

import { Label, Select } from 'v1/components/shared';

/**
 * @desc A selector for entity types
 */
const EntityTypeSelector = ({ name, value, entityTypes, onChange }) => {
  const getDefaultValue = _ => {
    return get(entityTypes, [0], '');
  };

  useEffect(() => {
    !value && onChange(getDefaultValue());
  }, []);

  return (
    <div>
      <Label htmlFor={name}>Component</Label>
      <Select
        name={name}
        value={value}
        onChange={({ target }) => onChange(parseInt(target.value))}
        autoComplete="off"
      >
        {entityTypes.map(type => (
          <option value={type} key={type}>
            {entityTypeToLabel(type)}
          </option>
        ))}
      </Select>
    </div>
  );
};

EntityTypeSelector.props = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  types: PropTypes.arrayOf(PropTypes.number).isRequired, // Array of entity ids
  onChange: PropTypes.func.isRequired
};

export default EntityTypeSelector;
