import _pick from 'lodash/pick';
import _keys from 'lodash/keys';
import _omit from 'lodash/omit';

export function formatProduction(production) {
  return _keys(production).reduce((result, key) => {
    switch (key) {
      case 'budget_expenses': {
        if (production.id || !production.budget_expenses) return result;
        const budget_expenses = production.budget_expenses.map(slot => {
          let s = { ...slot };
          if (
            s.group_id &&
            typeof s.group_id === 'string' &&
            s.group_id.includes('ref-')
          )
            s.group = { '#ref': `group-${s.group_id.replace('ref-', '')}` };
          if (typeof s.id === 'string' && s.id.includes('temp')) delete s.id;
          return _pick(s, [
            'archived',
            'description',
            'estimate_rate_amount',
            'estimate_rate_currency',
            'estimate_rate_interval',
            'estimate_rate_quantity',
            s.group ? 'group' : 'group_id',
            'id',
            'name',
            'order',
            'production_id',
            'slot_category_id',
            'slot_category'
          ]);
        });
        return { ...result, budget_expenses };
      }

      case 'resource_slots': {
        if (!production.resource_slots) return result;
        const resource_slots = production.resource_slots.map(slot => {
          let s = { ...slot };
          if (
            s.group_id &&
            typeof s.group_id === 'string' &&
            s.group_id.includes('ref-')
          )
            s.group = { '#ref': `group-${s.group_id.replace('ref-', '')}` };
          if (typeof s.id === 'string' && s.id.includes('temp')) delete s.id;

          s.is_location ? (s.is_location = true) : (s.is_location = false);

          // Checks if resource_slot_assignments has a new booking and omits unwanted fields
          if (s.resource_slot_assignments) {
            s.resource_slot_assignments.map((assignment, index) => {
              s.resource_slot_assignments[index] = assignment.booking
                ? {
                    ...assignment,
                    booking: _omit(assignment.booking, [
                      'contact',
                      'resource_slot_assignments'
                    ])
                  }
                : assignment;
            });
          }

          return _pick(
            s,
            [
              'id',
              'color',
              s.slot_category_id ? 'slot_category_id' : 'slot_category',
              'order',
              s.group ? 'group' : 'group_id',
              'is_location',
              'query',
              'resource_slot_assignments',
              'estimate_rate_amount',
              'estimate_rate_currency',
              'estimate_rate_interval',
              'estimate_rate_quantity',
              'estimate_rate_agency_fee',
              'estimate_rate_payroll_fee',
              'estimate_rate_processing_fee'
            ].filter(
              i => (i === 'resource_slot_assignments' ? !production.id : true) // If it's a create, send resource_slot_assignments
            )
          );
        });
        return { ...result, resource_slots };
      }

      case 'groups': {
        if (!production.groups) return result;
        const groups = production.groups.map(group => {
          let g = { ...group };
          if (typeof g.id === 'string' && g.id.includes('ref')) {
            g['#id'] = `group-${g.id.replace('ref-', '')}`;
            delete g.id;
          }
          return _pick(g, 'order', 'id', 'name', '#id', 'archived');
        });
        return { ...result, groups };
      }
      case 'code':
        return !production.id ? { ...result, [key]: production[key] } : result;
      case 'title':
      case 'description':
      case 'start_date':
      case 'end_date':
      case 'budget_amount':
      case 'budget_currency':
      case 'icon':
      case 'color':
      case 'production_date':
      case 'custom_fields':
      case 'custom_fieldgroups':
      case 'production_type_id':
      case 'production_template_id':
      case 'user_id':
      case 'status':
      case 'status_id':
      case 'tags':
      case 'timezone':
        return { ...result, [key]: production[key] };
      default:
        return result;
    }
  }, {});
}

export function formatResourceSlot(resource_slot) {
  return _keys(resource_slot).reduce((result, key) => {
    switch (key) {
      case 'is_location':
        return { ...result, [key]: resource_slot[key] ? true : false };
      case 'order':
      case 'name':
      case 'group_id':
      case 'color':
      case 'note':
      case 'query':
      case 'estimate_rate_amount':
      case 'estimate_rate_currency':
      case 'estimate_rate_interval':
      case 'estimate_rate_quantity':
      case 'estimate_rate_agency_fee':
      case 'estimate_rate_payroll_fee':
      case 'estimate_rate_processing_fee':
      case 'include_in_calculation':
      case 'slot_category_id':
        return { ...result, [key]: resource_slot[key] };
      case 'slot_category':
        return resource_slot.slot_category_id
          ? result
          : { ...result, [key]: resource_slot[key] };
      default:
        return result;
    }
  }, {});
}
