import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Grid, GridCell } from 'v1/components/shared';
import FlyoverGroup from '../FlyoverGroup/FlyoverGroup';
import styles from './FlyoverAccordion.module.scss';

const FlyoverAccordion = ({
  icon,
  title,
  description,
  isExpanded,
  children
}) => {
  const [isOpen, setIsOpen] = useState(isExpanded);

  const toggleIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <FlyoverGroup>
      <div className="inset-M">
        <Grid>
          {icon && (
            <GridCell width="auto">
              <img
                className={classnames('right-S', styles.Icon)}
                src={icon}
                alt=""
              />
            </GridCell>
          )}
          <GridCell>
            <Grid gutters="S" className="clickable" onClick={toggleIsOpen}>
              {title && (
                <GridCell
                  className="text-13-600-eggplant-lighter"
                  width={description ? '1/4' : null}
                >
                  {title}
                </GridCell>
              )}
              {description && (
                <GridCell className="text-13-700">{description}</GridCell>
              )}
              <GridCell width="auto">
                <img
                  className={classnames({
                    'rotate-90': !isOpen,
                    'rotate-anti-90': isOpen
                  })}
                  src="/images/icon_chevron.svg"
                  alt=""
                />
              </GridCell>
            </Grid>
            {isOpen && <div>{children}</div>}
          </GridCell>
        </Grid>
      </div>
    </FlyoverGroup>
  );
};

FlyoverAccordion.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isExpanded: PropTypes.bool,
  children: PropTypes.any
};

export default FlyoverAccordion;
