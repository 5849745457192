import React, { Component } from 'react';
import { DEPRECATED_DropdownButton } from 'v1/components/shared';
import { TagGroup } from 'v1/components/shared';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { makeFileURL } from 'lib/s3/s3Helper';
import './PinComponents.scss';

export default class PinLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  renderName = pin => {
    let url = pin.url
      ? pin.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
      : '';
    if (pin.file) {
      return pin.file.name ? pin.file.name : pin.pin_type;
    }
    return url || 'Untitled';
  };
  getUrl = pin => {
    if (pin.file) {
      return makeFileURL(pin.file);
    }
    if (pin.url) {
      return pin.url;
    }
  };
  renderActions = pin => {
    const { type, options = {} } = this.props;
    let OPTIONS = [];

    switch (type) {
      case 'IMAGE':
        OPTIONS = [
          {
            text: 'Download',
            icon: '/images/icon_file_download.svg',
            download: this.getUrl(pin)
          },
          {
            text: 'Delete Pin',
            icon: '/images/icon_delete.svg',
            action: () => {
              this.setState({ deleting: true }, () => {
                this.props.onDeletePin(pin.id);
              });
            }
          }
        ];
        if (options.includeProfileImageButton) {
          OPTIONS.unshift({
            text: 'Set as profile picture',
            icon: '/images/icon_profile_picture.svg',
            action: () => {
              this.props.onSetAsProfilePicture(pin);
            }
          });
        }
        break;
      case 'FILE':
      case 'VIDEO':
        OPTIONS = [
          {
            text: 'Download',
            icon: '/images/icon_file_download.svg',
            download: this.getUrl(pin)
          },
          {
            text: 'Delete Pin',
            icon: '/images/icon_delete.svg',
            action: () => {
              this.setState({ deleting: true }, () => {
                this.props.onDeletePin(pin.id);
              });
            }
          }
        ];
        break;
      case 'WEB_LINK':
      case 'PROVIDER_FILE':
      default:
        OPTIONS = [
          {
            text: 'Go to link',
            icon: '/images/icon_new_window.svg',
            href: this.getUrl(pin)
          },
          {
            text: 'Delete Pin',
            icon: '/images/icon_delete.svg',
            action: () => {
              this.setState({ deleting: true }, () => {
                this.props.onDeletePin(pin.id);
              });
            }
          }
        ];
        break;
    }

    return (
      <div className="PinLabel-actions">
        <DEPRECATED_DropdownButton
          icon="/images/icon_options_white.svg"
          offset={{
            bottom: '0px',
            top: 'auto',
            right: '0px'
          }}
          className="PinAction-btn"
          hideArrow
          links={OPTIONS}
        />
      </div>
    );
  };
  renderMeta = (user, pin) => {
    if (user) {
      return (
        <span className="PinLabel-meta">
          {user.first_name} pinned {moment(pin.created_at).fromNow()}
        </span>
      );
    }
  };
  renderTags = tags => {
    return (
      <div className="PinLabel-tags">
        <TagGroup tags={tags} tagType="tag" limit={2} darkMode />
      </div>
    );
  };
  render() {
    const { pin /*user*/ } = this.props;
    return (
      <div className="PinLabel">
        {!isEmpty(pin.tags) && this.renderTags(pin.tags)}
        <div className="Pinlabel-details">
          <div className="PinLabel-details-owner">
            {this.state.deleting ? (
              'Deleting...'
            ) : (
              <a
                href={this.getUrl(pin)}
                className="Pinlabel-name"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.renderName(pin)}
              </a>
            )}
          </div>
          <div className="Pinlabel-actions">{this.renderActions(pin)}</div>
        </div>
      </div>
    );
  }
}
