import {
  DndContext,
  DragOverlay,
  Modifier,
  MouseSensor as LibMouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  SortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classnames from 'classnames';
import React, { MouseEvent, ReactNode, useState } from 'react';
import { SortableItemProp } from '../ListSortable';
import './MasonrySortable.scss';

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        if (event.target instanceof HTMLElement) {
          return event.target.closest('[data-no-dnd]') === null;
        }
        return true;
      }
    }
  ];
}

export function MasonrySortableItem({ id, children }: SortableItemProp) {
  const { setNodeRef, transform, transition, listeners, active } = useSortable({
    id
  });

  return (
    <div
      className="MasonrySortable-item noselect"
      ref={setNodeRef}
      style={{
        transform: transform
          ? CSS.Transform.toString({
              ...transform,
              scaleX: 1,
              scaleY: 1
            })
          : undefined,
        transition: active ? transition : undefined, // this is a trick to wait with animation for Tanstack Query mutation that happens next frame
        pointerEvents: active ? 'none' : undefined
      }}
      {...listeners}
    >
      {children}
    </div>
  );
}

interface MasonrySortableProps<T extends UniqueIdentifier> {
  children: ReactNode;
  className?: string;
  onMove: (active: T, over: T) => void;
  items: T[];
  disableDnD?: boolean;
  modifiers?: Modifier[];
  dragOverlay?: (id: T) => ReactNode;
  strategy?: SortingStrategy;
}

export function MasonrySortable<T extends UniqueIdentifier>({
  children,
  className,
  onMove,
  items,
  disableDnD,
  modifiers,
  dragOverlay,
  strategy = rectSortingStrategy
}: MasonrySortableProps<T>) {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 30 }
    })
  );
  const [activeId, setActiveId] = useState(null);
  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd() {
    setActiveId(null);
  }
  return (
    <div className={classnames(['MasonrySortable', className])}>
      <DndContext
        onDragStart={handleDragStart}
        sensors={sensors}
        onDragEnd={({ over, active }) => {
          onMove(active.id as T, over?.id as T);
          handleDragEnd();
        }}
        cancelDrop={() =>
          // this is a trick to wait with animation for Tanstack Query mutation that happens next frame
          new Promise(resolve => setTimeout(() => resolve(false), 0))
        }
        modifiers={modifiers}
      >
        <SortableContext
          disabled={disableDnD}
          items={items}
          strategy={strategy}
        >
          {children}
        </SortableContext>
        {dragOverlay && (
          <DragOverlay modifiers={modifiers}>
            {activeId ? dragOverlay(activeId as T) : null}
          </DragOverlay>
        )}
      </DndContext>
    </div>
  );
}

export default MasonrySortable;
