import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { registerAccount, deleteToken } from './register.actions';

export type RegisterStore = {
  token: string;
  loading?: boolean | string;
  error?: any;
};

export const INITIAL_STATE: RegisterStore = {
  token: '',
  loading: false
};

const registerSlice = createSlice({
  name: 'register',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        registerAccount.fulfilled,
        (state, action: PayloadAction<{ data: string }>) => {
          state.token = action.payload.data;
        }
      )
      .addCase(deleteToken, state => {
        state.token = '';
      })
      // Generic
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default registerSlice.reducer;
