import ApiClient from 'lib/api/ApiClient';

export function gets3url(data) {
  const apiClient = new ApiClient();
  return apiClient
    .post('https://stitchapi.eu.ngrok.io/web/v1/workspaces/test/files', {
      data,
      token: true
    })
    .then(resp => {
      return { url: resp.url, file_id: resp.file_id };
    });
}

export function gets3PublicUrl({ extension, content_type, bucket, timestamp }) {
  const apiClient = new ApiClient();
  return apiClient
    .put('/s3/s3/issue_public', {
      params: { content_type, extension, bucket, _t: timestamp }
    })
    .then(resp => {
      return { url: resp.url };
    });
}

export function readPrivateS3File(file_id) {
  const apiClient = new ApiClient();

  return apiClient
    .get(
      `https://stitchapi.eu.ngrok.io/web/v1/workspaces/test/files/${file_id}`,
      { token: true }
    )
    .then(resp => resp.url);
}
