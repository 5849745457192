import React from 'react';
import PropTypes from 'prop-types';

import { PressStud, TextInput } from 'v1/components/shared';

import './CustomDropdownItems.scss';

// TODO: this could probs be a MultiInput
const CustomDropdownItems = ({ items, onChange }) => {
  const handleAdd = () => {
    onChange([...items, '']);
  };

  const handleRemove = index => {
    onChange(items.filter((el, i) => i !== index));
  };

  const handleChange = ({ target: { value } }, index) => {
    onChange(
      items.map((item, i) =>
        i === index ? { value: value, order: index } : item
      )
    );
  };

  return (
    <div className="CustomDropdownItems">
      <div className="CustomDropdownItems-list">
        {items &&
          items.map((item, index) => (
            <div className="CustomDropdownItems-item" key={index}>
              <TextInput
                placeholder="Option name"
                value={item.value}
                onChange={e => handleChange(e, index)}
              />
              <div className="CustomDropdownItems-remove">
                <span onClick={() => handleRemove(index)}>
                  <img src="/images/icon_delete.svg" width="12px" alt="" />
                </span>
              </div>
            </div>
          ))}
      </div>
      <PressStud
        label="Add Option"
        icon="addBlack"
        size="S"
        action={handleAdd}
      />
    </div>
  );
};

CustomDropdownItems.props = {
  items: PropTypes.array,
  onChange: PropTypes.func
};

export default CustomDropdownItems;
