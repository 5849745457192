import React, { useContext } from 'react';
import classnames from 'classnames';

import TableContext from '../../TableContext';
import styles from './TablePagination.module.scss';

// TODO: this needs reviewing at a later date and migrating to a generic ButtonGroup component
const TablePagination = () => {
  const { paging } = useContext(TableContext);
  return (
    <div className={styles.Pager}>
      <button
        type="button"
        title="Previous page"
        className={classnames([
          styles.Previous,
          'btn btn-default btn-square btn-small'
        ])}
        onClick={() => paging.previousPage()}
        disabled={!paging.canPreviousPage}
      >
        {paging.canPreviousPage ? (
          <img src="/images/icon_pager_arrow_left.svg" alt="" />
        ) : (
          <img src="/images/icon_pager_arrow_left_disabled.svg" alt="" />
        )}
      </button>
      <div className={classnames([styles.Pages, 'btn btn-default btn-small'])}>
        {`${paging.pageIndex + 1} / ${paging.pageOptions.length || 1}`}
      </div>
      <button
        type="button"
        title="Next page"
        className={classnames([
          styles.Next,
          'btn btn-default btn-square btn-small'
        ])}
        onClick={() => paging.nextPage()}
        disabled={!paging.canNextPage}
      >
        {paging.canNextPage ? (
          <img src="/images/icon_pager_arrow_right.svg" alt="" />
        ) : (
          <img src="/images/icon_pager_arrow_right_disabled.svg" alt="" />
        )}
      </button>
    </div>
  );
};

TablePagination.propTypes = {};

export default TablePagination;
