import PropTypes from 'prop-types';

// TODO: archived and standard stuff
const TypeRate = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.number,
  agency_fee: PropTypes.number,
  payroll_fee: PropTypes.number,
  processing_fee: PropTypes.number,
  interval: PropTypes.oneOf(['DAILY', 'HOURLY', 'FIXED'])
});

export default TypeRate;
