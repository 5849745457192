import { createAsyncThunk } from '@reduxjs/toolkit';
import { CollectionDto } from 'dtos/collection.dto';
import { CreateCollectionDto } from 'dtos/create-collection.dto';
import { SuccessDto } from 'dtos/success.dto';
import { UpdateCollectionDto } from 'dtos/update-collection.dto';
import { COLLECTIONS_ACTIONS } from './collections.constants';
import ApiClient from 'lib/api/ApiClient';

const isV2 = true;
const token = true;
const client = new ApiClient() as any;

const getTenantCollections = createAsyncThunk(
  COLLECTIONS_ACTIONS.getTenantCollections,
  async () => {
    const { data } = await client.get('/auth/tenant/collections', {
      isV2,
      token
    });
    return data as CollectionDto[];
  }
);

const getCollection = createAsyncThunk(
  COLLECTIONS_ACTIONS.getCollection,
  async (id: string) => {
    const { data } = await client.get(`/auth/collections/${id}`, {
      isV2,
      token
    });
    return data as CollectionDto;
  }
);

const createCollection = createAsyncThunk(
  COLLECTIONS_ACTIONS.createCollection,
  async (data: CreateCollectionDto) => {
    const res = await client.post('/auth/collections', {
      data,
      isV2,
      token
    });
    return res.data as CollectionDto;
  }
);

const updateCollection = createAsyncThunk(
  COLLECTIONS_ACTIONS.updateCollection,
  async (
    { id, data }: { id: string; data: UpdateCollectionDto },
    { rejectWithValue }
  ) => {
    const res = (await client.patch(`/auth/collections/${id}`, {
      data,
      isV2,
      token
    })) as SuccessDto;
    return res.data === 'success' ? data : rejectWithValue(null);
  }
);

const archiveCollection = createAsyncThunk(
  COLLECTIONS_ACTIONS.archiveCollection,
  async (id: string) =>
    (await client.del(`/auth/collections/${id}`, {
      isV2,
      token
    })) as SuccessDto
);

export {
  getTenantCollections,
  getCollection,
  createCollection,
  updateCollection,
  archiveCollection
};
