import { QueryClient } from '@tanstack/react-query';
import { eventSchemas } from 'v4/core/events.schema';
import { SocketEvent } from 'v4/core/useSocket';
import { shortlistInternals } from 'v4/entities/shortlist/shortlist.hooks';

export function processEventForShortlists(
  event: SocketEvent,
  client: QueryClient
) {
  if (event.type === 'SHORTLIST_UPDATED') {
    const typedEvent = eventSchemas.shortlist.updated.parse(event);
    // remove all unused shortlist queries
    client.removeQueries({
      type: 'inactive',
      queryKey: shortlistInternals.keys.all()
    });
    // invalidate shortlist list queries, they might be affected by the update
    client.invalidateQueries({
      queryKey: shortlistInternals.keys.lists()
    });
    // invalidate shortlist detail query
    client.invalidateQueries({
      queryKey: shortlistInternals.keys.detail(typedEvent.payload.shortlistId)
    });
  }
  return event;
}
