import moment from 'moment-timezone';
import { INVOICE_STATUS } from './DropdownDataSets';

export const CONDITIONS = {
  eq: 'eq',
  s_with: 's_with',
  gte: 'gte',
  lte: 'lte',
  one: 'one',
  all: 'all',
  between: 'between'
};

export function generateCallsheetsSearchParams(accountId) {
  return {
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Belongs to me',
        value: accountId
      },
      {
        id: 2,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Published only',
        value: true
      },
      {
        id: 3,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Drafts only',
        value: false
      },
      {
        id: 4,
        type: 'date',
        condition: CONDITIONS.gte,
        field: 'created_at',
        label: 'Last week',
        value: moment().subtract(7, 'days').format('YYYY-MM-DD')
      }
    ],
    sorts: [
      { field: 'created_at', label: 'Created' },
      { field: 'updated_at', label: 'Updated' }
    ]
  };
}

export function generateResourceBookingSearchParams(accountId) {
  return {
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Created by me',
        value: accountId
      }
    ],
    sorts: [
      { field: 'created_at', label: 'Created' },
      { field: 'updated_at', label: 'Updated' }
    ]
  };
}

export function generateBookingSearchParams(accountId) {
  return {
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Created by me',
        value: accountId
      },
      {
        id: 2,
        type: 'contact',
        condition: CONDITIONS.eq,
        field: 'contact_id',
        label: 'Resource',
        value: false
      }
    ],
    sorts: [
      { field: 'created_at', label: 'Created' },
      { field: 'updated_at', label: 'Updated' }
    ]
  };
}

export function generateShortlistSearchParams(accountId) {
  return {
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Belongs to me',
        value: accountId
      },
      {
        id: 2,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Published only',
        value: true
      },
      {
        id: 3,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Drafts only',
        value: false
      },
      {
        id: 4,
        type: 'date',
        condition: CONDITIONS.gte,
        field: 'created_at',
        label: 'Last week',
        value: moment().subtract(7, 'days').format('YYYY-MM-DD')
      },
      {
        id: 5,
        type: 'archived',
        condition: CONDITIONS.eq,
        field: 'archived',
        label: 'Show archived',
        value: true
      }
    ],
    sorts: [
      { field: 'created_at', label: 'Created' },
      { field: 'updated_at', label: 'Updated' }
    ]
  };
}

export function generateProductionSearchParams(accountId) {
  return {
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Belongs to me',
        value: accountId
      },
      {
        id: 2,
        type: 'date',
        condition: CONDITIONS.gte,
        field: 'created_at',
        label: 'Last week',
        value: moment().subtract(7, 'days').format('YYYY-MM-DD')
      },
      {
        id: 3,
        type: 'date_between',
        condition: CONDITIONS.between,
        field: 'production_dates',
        label: 'Date range',
        value: false
      }
    ],
    sorts: [
      { field: 'created_at', label: 'Created' },
      {
        field: "attributes->>'production_start_date'",
        label: 'Production Date'
      }
    ]
  };
}

export function generateApplicantSearchParams() {
  return {
    sorts: [{ field: 'created_at', label: 'Applied Date' }]
  };
}

export function generateCalendarSearch(accountId) {
  return {
    sorts: [
      { field: 'created_at', label: 'Next Up' },
      { field: 'updated_at', label: 'Last Updated' }
    ],
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Shoot Type',
        value: accountId
      },
      {
        id: 2,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Stills Only',
        value: true
      },
      {
        id: 3,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'On Figure Only',
        value: false
      },
      {
        id: 4,
        type: 'date',
        condition: CONDITIONS.gte,
        field: 'created_at',
        label: 'This Week Only',
        value: moment().subtract(7, 'days').format('YYYY-MM-DD')
      }
    ]
  };
}

export function generateCalloutSearchParams(accountId) {
  return {
    filters: [
      {
        id: 1,
        type: 'string',
        condition: CONDITIONS.eq,
        field: 'user_id',
        label: 'Belongs to me',
        value: accountId
      },
      {
        id: 2,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Published only',
        value: true
      },
      {
        id: 3,
        type: 'boolean',
        condition: CONDITIONS.eq,
        field: 'published',
        label: 'Drafts only',
        value: false
      },
      {
        id: 4,
        type: 'date',
        condition: CONDITIONS.gte,
        field: 'created_at',
        label: 'Last week',
        value: moment().subtract(7, 'days').format('YYYY-MM-DD')
      }
    ],
    sorts: [
      { field: 'created_at', label: 'Created' },
      { field: 'updated_at', label: 'Updated' }
    ]
  };
}

export function generateReconciliationSearchParams() {
  return {
    filters: INVOICE_STATUS.map((status, index) => ({
      id: index + 1,
      type: 'string',
      condition: CONDITIONS.eq,
      field: 'invoice_status',
      label: status.label,
      value: status.value
    })),
    sorts: [
      { field: 'updated_at', label: 'Updated' },
      { field: 'booking_status', label: 'Booking Status' }
    ]
  };
}
