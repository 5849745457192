import { chain } from 'lodash';

export function duplicateShortlist(shortlist) {
  const unarchivedShortlistBlocks = chain(shortlist.shortlist_blocks)
    .filter(block => !block.archived)
    .value();
  const newShortlist = {
    layout: shortlist.layout,
    default_fields: shortlist.default_fields,
    default_resources_query: shortlist.default_resources_query,
    description: shortlist.description,
    enable_commenting: shortlist.enable_commenting,
    enable_voting: shortlist.enable_voting,
    private_password: shortlist.private_password,
    public: false,
    public_description: shortlist.public_description,
    public_title: shortlist.public_title,
    published: false,
    shortlist_blocks: duplicateShortlistBlocks(unarchivedShortlistBlocks),
    show_availability: shortlist.show_availability,
    status: 'BUILDING',
    title: `${shortlist.title} (Copy)`
  };

  return newShortlist;
}

export function duplicateShortlistBlocks(
  shortlistBlocks = [],
  startBlockOrderFrom
) {
  let newBlockOrder = startBlockOrderFrom || 0;
  return shortlistBlocks.map(shortlistBlock => {
    const newShortlistBlock = {
      contact_id: shortlistBlock.contact_id,
      order:
        startBlockOrderFrom !== undefined
          ? newBlockOrder
          : shortlistBlock.order,
      pins: duplicateShortlistBlockPins(shortlistBlock.pins),
      data: shortlistBlock.data
    };
    newBlockOrder++;
    return newShortlistBlock;
  });
}

export function duplicateShortlistBlockPins(pins = []) {
  let newPins = [];

  const formattedPing = chain(pins)
    .filter(p => !p.archived)
    .orderBy(['order', 'asc'])
    .value();

  formattedPing.map((pin, index) => {
    const newPin = {
      order: index,
      pin_type: pin.pin_type,
      url: pin.url,
      file_id: pin.file_id
    };

    newPins.push(newPin);
  });

  return newPins;
}
