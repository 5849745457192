import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { Capability } from '__types__';
import { openModal } from 'store/v1/ui/ui.actions.js';

import { MenuItem, RestrictedDropdown } from 'v1/components/shared';
import useModal from 'hooks/useModal';
import GenerateResourcePDFModal from 'modals/ResourceModals/GenerateResourcePDFModal/GenerateResourcePDFModal';

const ResourceOptions = ({ resourceId }) => {
  const dispatch = useDispatch();
  const resources = useSelector(state => state.contacts);
  const resource = get(resources, ['data', resourceId], {});

  const { openModal: openNewModal } = useModal();

  const openGeneratePDFModal = () =>
    openNewModal(() => <GenerateResourcePDFModal resourceId={resource.id} />);

  const confirmUnarchive = () => {
    const options = {
      title: 'Are you sure you want to unarchive this resource?',
      button: 'Unarchive Resource',
      action: 'UNARCHIVE_CONTACT',
      contact_id: resourceId
    };
    dispatch(openModal('ConfirmationModal', options));
  };

  const confirmArchive = () => {
    const options = {
      title: 'Are you sure you want to archive this resource?',
      text: 'You cannot undo this action',
      button: 'Archive Resource',
      action: 'DELETE_CONTACT',
      contact_id: resourceId
    };
    dispatch(openModal('ConfirmationModal', options));
  };

  return (
    <RestrictedDropdown
      capabilitiesAtLeastOne={[
        Capability.RESOURCE_ARCHIVE,
        Capability.RESOURCE_UPDATE
      ]}
      buttonIcon={<img src="/images/icon_options.svg" alt="" />}
      buttonClass="btn btn-default btn-square btn-small"
    >
      <MenuItem onSelect={openGeneratePDFModal}>
        <span className="MenuItem-icon">
          <img src="/images/icon_file_pdf.svg" alt="" />
        </span>
        <span className="MenuItem-label">Generate PDF</span>
      </MenuItem>
      <MenuItem
        capabilities={Capability.RESOURCE_UPDATE}
        onSelect={() => {
          dispatch(
            openModal('RequestAvailabilityModal', {
              resourceIds: [resourceId]
            })
          );
        }}
      >
        <span className="MenuItem-icon">
          <img src="/images/icon_availability_request.svg" alt="" />
        </span>
        <span className="MenuItem-label">Request availability</span>
      </MenuItem>
      <MenuItem
        capabilities={Capability.RESOURCE_UPDATE}
        onSelect={() => {
          dispatch(
            openModal('ResourceEventsModal', {
              resourceId
            })
          );
        }}
      >
        <span className="MenuItem-icon">
          <img src="/images/icon_availability.svg" alt="" />
        </span>
        <span className="MenuItem-label">Add availability / booking</span>
      </MenuItem>
      {resource.archived ? (
        <MenuItem
          capabilities={Capability.RESOURCE_UPDATE}
          onSelect={confirmUnarchive}
        >
          <span className="MenuItem-icon">
            <img src="/images/icon_delete.svg" alt="" />
          </span>
          <span className="MenuItem-label">Unarchive contact</span>
        </MenuItem>
      ) : (
        <></>
      )}
      {!resource.archived ? (
        <MenuItem
          capabilities={Capability.RESOURCE_ARCHIVE}
          onSelect={confirmArchive}
        >
          <span className="MenuItem-icon">
            <img src="/images/icon_delete.svg" alt="" />
          </span>
          <span className="MenuItem-label">Archive contact</span>
        </MenuItem>
      ) : (
        // This is to stop a hissy fit
        <></>
      )}
    </RestrictedDropdown>
  );
};

ResourceOptions.props = {};

export default ResourceOptions;
