import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import './GridCell.scss';

interface GridCellProps {
  className?: string;
  vcenter?: boolean;
  padding?: 0 | '0' | 'XS' | 'S' | 'M' | 'L';
  width?:
    | 'auto'
    | 'initial'
    | '1/12'
    | '1/6'
    | '2/12'
    | '1/4'
    | '3/12'
    | '1/3'
    | '4/12'
    | '5/12'
    | '1/2'
    | '6/12'
    | '7/12'
    | '2/3'
    | '8/12'
    | '3/4'
    | '9/12'
    | '5/6'
    | '10/12'
    | '11/12'
    | '12/12'
    | '1';
  children?: ReactNode;
}

const GridCell = ({
  className,
  vcenter,
  padding,
  width,
  children,
  ...restProps
}: GridCellProps) => {
  return (
    <div
      className={classnames(
        'GridCell',
        { [`GridCell_${width}`]: width },
        { [`GridCell_padding-${padding}`]: padding },
        { GridCell_vcenter: vcenter },
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default GridCell;
