
import { Capability, Team } from '__types__';
import { UseRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import { Button, Icon } from 'shared';
import { selectTeamsList } from 'store/teams';
import IntegrationRestrictedAccess from 'v1/components/feature/AccountSettingsComponents/AccountSettingsIntegrations/IntegrationRestrictedAccess/IntegrationRestrictedAccess';
import ScrollableContainer from 'v4/shared/components/ScrollableContainer/ScrollableContainer';
import { withinSettings } from '../appRoutes';
import SidebarLinkPrimary from './components/SidebarLinkPrimary';
import SidebarRoot from './components/SidebarRoot';
import SidebarSection from './components/SidebarSection';
import SidebarSectionLabel from './components/SidebarSectionLabel';
import SidebarSeparator from './components/SidebarSeparator';
import TeamSettings from './components/TeamSettings';
import { useSelector } from 'react-redux';
import { getPublicCompanyName, selectAppId } from 'store/v1/auth/auth.selectors';

export interface SidebarSettingsConfig {
  useRestrictedAccess: UseRestrictedAccessCallback;
  useTeams: () => ReturnType<typeof selectTeamsList>;
}

export function createSettingsSidebar(config: SidebarSettingsConfig) {
  return function SettingsSidebar() {
    // todo: Teams temporarily locked to one active team
    // todo: While we do not support multi-teams we lock the team list to the current team
    // const teams = config.useTeams();
    const app_id = useSelector(selectAppId);
    const companyName = useSelector(getPublicCompanyName)
    const lockedTeam: Partial<Team> = {
      dbSchema: app_id,
      name: companyName,
      id: app_id,
    }
    // End Locked Team

    const to = withinSettings();
    const can = config.useRestrictedAccess();

    return (
      <SidebarRoot>
        <SidebarSection>
          <div className="tw-flex tw-items-center">
            <div className="tw-flex-0 tw-mr-1">
              <Button
                action="/"
                appearance="sidebar"
                size="s"
                icon="arrowLeft"
              />
            </div>
            <div className="tw-flex-1 tw-truncate">Settings</div>
          </div>
        </SidebarSection>
        <SidebarSeparator />
        <ScrollableContainer rgbColor="20, 13, 92">
          <SidebarSection>
            <SidebarSectionLabel>Your account</SidebarSectionLabel>
            <SidebarLinkPrimary
              to={to.settings.account.details()}
              icon={<Icon name="v4_individual" color="white" />}
            >
              Personal account
            </SidebarLinkPrimary>
            <SidebarLinkPrimary
              to={to.settings.account.security()}
              icon={<Icon name="v4_security" color="white" />}
            >
              Security
            </SidebarLinkPrimary>
          </SidebarSection>

          <SidebarSeparator />

          <SidebarSection>
            <SidebarSectionLabel>Organization</SidebarSectionLabel>
            {can(null, Capability.CONFIGURE_WORKSPACE) && (
              <SidebarLinkPrimary
                to={to.settings.organization.workspace()}
                icon={<Icon name="v4_house" color="white" />}
              >
                Workspace
              </SidebarLinkPrimary>
            )}
            {can(null, Capability.CONFIGURE_TEAM) && (
              <SidebarLinkPrimary
                to={to.settings.organization.users()}
                icon={<Icon name="v4_users" color="white" />}
              >
                Users
              </SidebarLinkPrimary>
            )}
            {can(null, Capability.CONFIGURE_ROLES) && (
              <SidebarLinkPrimary
                to={to.settings.organization.roles()}
                icon={<Icon name="v4_roles" color="white" />}
              >
                Roles
              </SidebarLinkPrimary>
            )}
            {can(null, Capability.CONFIGURE_AUTHENTICATION_METHODS) && (
              <SidebarLinkPrimary
                to={to.settings.organization.auth()}
                icon={<Icon name="v4_security" color="white" />}
              >
                Authentication methods
              </SidebarLinkPrimary>
            )}
            <IntegrationRestrictedAccess>
              <SidebarLinkPrimary
                to={to.settings.organization.integrations()}
                icon={<Icon name="v4_integration" color="white" />}
              >
                Integrations
              </SidebarLinkPrimary>
            </IntegrationRestrictedAccess>
          </SidebarSection>

          <SidebarSeparator />

          <SidebarSection>
            <SidebarSectionLabel>Teams</SidebarSectionLabel>
            {/* {teams.map(team => ( */}
            <TeamSettings
              key={lockedTeam.id}
              team={lockedTeam}
              can={can}
            />
            {/* ))} */}
          </SidebarSection>
        </ScrollableContainer>
      </SidebarRoot>
    );
  };
}
