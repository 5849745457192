import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const getMatchingString = (item = '', keyToMatch) => {
  return keyToMatch && typeof item === 'object' // get value from object by key
    ? get(item, keyToMatch, '')
    : item; // otherwise return string as is
};

const getMatches = (items, keyToMatch, query) =>
  items.reduce((acc, item) => {
    if (item.children) {
      const childMatches = getMatches(item.children, keyToMatch, query);
      childMatches.length && acc.push({ ...item, children: childMatches });
    } else {
      getMatchingString(item, keyToMatch)
        .toLowerCase()
        .includes(query.toLowerCase()) && acc.push(item);
    }
    return acc;
  }, []);

const useAutocomplete = ({ defaultQuery, data, keyToMatch }) => {
  const [query, setQuery] = useState(defaultQuery);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    if (query === '' || query === undefined) return setMatches(data);
    const matchingItems = getMatches(data, keyToMatch, query);
    setMatches(matchingItems);
  }, [query, data, keyToMatch]);

  return { query, setQuery, matches };
};

useAutocomplete.propTypes = {
  defaultQuery: PropTypes.string,
  data: PropTypes.array,
  keyToMatch: PropTypes.string
};

useAutocomplete.defaultProps = {
  defaultQuery: '',
  data: []
};

export default useAutocomplete;
