//
// Helpers for the Expense object
//

import { get } from 'lodash';
import { formatCurrency } from 'v1/helpers/currencyHelper';

// TODO: expense & PBE both have slot_category_id and one is stale
export const getExpenseRate = expense => {
  const name =
    expense.commit_rate_amount && expense.commit_rate_interval
      ? 'Expense rate'
      : 'No rate set';

  return {
    id: expense.id,
    name,
    desc: expense.commit_vendor,
    currency: expense.commit_rate_currency,
    amount: expense.commit_rate_amount,
    interval: expense.commit_rate_interval,
    quantity: expense.commit_rate_quantity,
    slotCategoryId: expense.slot_category_id,
    productionBudgetExpenseId: expense.production_budget_expense_id
  };
};

const getExpenseRateTotal = rate => {
  return rate.amount && rate.quantity ? rate.amount * rate.quantity : 0;
};

const getExpenseTotals = rate => {
  const rateTotal = getExpenseRateTotal(rate);
  return {
    rates: rateTotal,
    total: rateTotal
  };
};

export const getExpenseTotalsFormatted = (expense, currency) => {
  if (!expense) {
    return {};
  }
  const rate = getExpenseRate(expense);
  const totals = getExpenseTotals(rate);
  return {
    rates: formatCurrency(totals.rates || 0, rate.currency || currency),
    total: formatCurrency(totals.total || 0, rate.currency || currency),
    totalRaw: totals.total || 0
  };
};

// TODO: some duplication between ExpenseHelper & BookingHelper here
const incrStatus = (acc, status) => {
  if (!status || status === 'AWAITING_APPROVAL') {
    return { ...acc, awaiting: acc.awaiting + 1 };
  }
  if (status === 'APPROVED') {
    return { ...acc, approved: acc.approved + 1 };
  }
  if (status === 'PAID') {
    return { ...acc, paid: acc.paid + 1 };
  }
  return {};
};

export const getExpenseDocumentsSummary = (receipts = []) => {
  return receipts.reduce(
    (acc, doc) => {
      if (doc.archived) {
        return acc;
      }
      const docTotal =
        doc.status === 'DECLINED' ? 0 : get(doc, 'document_data.amount', 0);
      return {
        ...acc,
        ...incrStatus(acc, doc.status),
        receipts: acc.receipts + 1,
        total: acc.total + docTotal
      };
    },
    {
      invoices: 0,
      receipts: 0,
      awaiting: 0,
      approved: 0,
      paid: 0,
      total: 0
    }
  );
};
