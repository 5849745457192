import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Env from 'lib/env/Env';

const RedirectGoogle = ({ href, className }) => {
  return (
    <div className={classNames('row LoginFormGroup', className)}>
      <div className="col-sm-12">
        <a href={href} className="btn btn-default LoginButton">
          Sign in with Google
        </a>
      </div>
    </div>
  );
};

RedirectGoogle.defaultProps = {
  href: `${Env.apiURL}/auth/google`
};

RedirectGoogle.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string
};

export default RedirectGoogle;
