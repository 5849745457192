import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, GridCell, ListCell, ListCellGroup } from 'v1/components/shared';
import { SingleListAdd } from 'v1/components/shared';

import classnames from 'classnames';
import './ListSelect.scss';

class ListSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selected: props.selected || [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.selected) return;

    if (nextProps.selected.length !== this.state.selected.length)
      this.setState({ selected: nextProps.selected });
  }

  onClick = id => {
    const beingSelected = !this.state.selected.includes(id);
    if (this.props.mode === 'multi') {
      return this.setState(
        prev => {
          const selected = beingSelected
            ? [...prev.selected, id]
            : prev.selected.filter(i => i !== id);
          return { selected };
        },
        () => this.props.onSelectChange(id, beingSelected)
      );
    }
    if (this.props.mode === 'single') {
      this.setState({ selected: [id] }, () => {
        this.props.onSelectChange(this.state.selected[0]);
      });
    }
  };

  isItemDisabled = item => {
    return this.props.disabled && this.props.disabled.includes(item.id);
  };

  render() {
    return (
      <>
        <ListCellGroup>
          {this.props.list &&
            this.props.list.map(item => (
              <ListCell
                key={item.id}
                className={classnames({
                  'ListSelect-section-list-item': true,
                  active: this.state.selected.includes(item.id)
                })}
                onClick={
                  this.isItemDisabled(item)
                    ? null
                    : e => {
                        e.stopPropagation();
                        this.onClick(item.id);
                      }
                }
                padding={this.props.cellPadding}
              >
                <Grid vcenter gutters="XS">
                  {item.icon && (
                    <GridCell width="auto">
                      <img src={item.icon} alt="icon" />
                    </GridCell>
                  )}
                  <GridCell>
                    <span className="text-13-600">{item.name}</span>
                    {item.description && (
                      <div className="text-13-600-eggplant">
                        {item.description}
                      </div>
                    )}
                  </GridCell>
                  <GridCell width="auto">
                    {this.isItemDisabled(item) ? (
                      <div className="text-11-700-eggplant inset-XS">
                        {this.props.disabledReason}
                      </div>
                    ) : (
                      <span className="ListSelect-section-list-item-selected">
                        {this.state.selected.includes(item.id) &&
                        this.props.mode === 'multi' ? (
                          <img
                            src="/images/icon_white_tick.svg"
                            width="14px"
                            alt=""
                          />
                        ) : null}
                      </span>
                    )}
                  </GridCell>
                </Grid>
              </ListCell>
            ))}
        </ListCellGroup>
        {!this.props.hideCreate ? (
          <SingleListAdd
            placeholder={this.props.placeholder}
            onSubmit={this.props.onListItemCreate}
            isCreating={this.props.isCreating}
            buttonLabel={this.props.buttonLabel}
          />
        ) : null}
      </>
    );
  }
}

ListSelect.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  mode: PropTypes.oneOf(['multi', 'single']).isRequired,
  onSelectChange: PropTypes.func.isRequired, // TODO: arguments
  hideCreate: PropTypes.bool,
  // TODO: these are passed to SingleListAdd
  placeholder: PropTypes.string,
  onListItemCreate: PropTypes.func, // TODO: required if hideCreate
  isCreating: PropTypes.bool,
  buttonLabel: PropTypes.string
};

export default ListSelect;
