import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import withAuth from 'v1/hocs/withAuth';
import useFetchAuthMethods from 'lib/auth/useFetchAuthMethods';
import { Loading } from 'v1/components/shared';
import LoginMethods from './components/LoginMethods';
import './LoginForm.scss';

const LoginForm = ({ className }) => {
  const data = useFetchAuthMethods();
  return (
    <div className={classNames('OnboardingForm FormBlock', className)}>
      <div className="FormBlock-header">
        <h2>
          <strong>Welcome to Atellio!</strong>
        </h2>
        {data && data.name ? <h3>Sign in to {data.name}</h3> : null}
      </div>
      <div className="FormBlock-content">
        {data ? (
          <LoginMethods organisation={data.name} methods={data.auth_methods} />
        ) : (
          <Loading text="Stitching your environment" />
        )}
      </div>
      {data && (
        <div>
          <h5>
            Are you a freelancer at{' '}
            {data && data.name ? data.name : 'this company'}?
          </h5>
          <span className="text-neutral">
            If you need access to your calendar, please message your point of
            contact at {data && data.name ? data.name : 'this company'}.
          </span>
        </div>
      )}
    </div>
  );
};

LoginForm.defaultProps = {};

LoginForm.propTypes = {
  className: PropTypes.string
};

export default withRouter(withAuth(LoginForm));
