import SocketContext from 'lib/websockets/SocketContext';
import { useContext, useEffect } from 'react';

interface Socket {
  connected: boolean;
  on: (event: string, eventListener: (data: unknown) => void) => void;
  off: (event: string, eventListener: (data: unknown) => void) => void;
}

export type SocketEvent =
  | {
      type: 'SHORTLIST_UPDATED';
      payload: {
        shortlistId: number | string;
        updated_at: string;
        sessionId: string;
      };
      occurred: string;
    }
  | {
      type: never;
      payload: {
        sessionId: string;
      };
    };

export const useSocketEvent = (eventListener: (event: SocketEvent) => void) => {
  const socket = useContext(SocketContext) as unknown as Socket;

  useEffect(() => {
    if (!socket) {
      console.error('Socket client not set in context');
      return () => {};
    }

    socket.on('EVENT', eventListener as (data: unknown) => void);

    return () => {
      socket.off('EVENT', eventListener as (data: unknown) => void);
    };
  }, [socket, eventListener]);
};
