import { createSelector } from 'reselect';
import { get } from 'lodash';
import { orderReducer } from 'v1/helpers/misc';

export const getCustomFieldDefinitions = createSelector(
  [state => state.auth.settings.custom_field_definitions],
  definitions => definitions
);

export const getCustomFieldGroupDefinitions = createSelector(
  [state => state.auth.settings.custom_fieldgroup_definitions],
  definitions => definitions
);

const getProductionFields = (state, props) => {
  //const productionType = get(state.production_types, ["data", props.typeId]);
  // const productionType = state.resource_types.settings.production_types.find(type => type.id === props.typeId);
  const productionType = Object.values(state.production_types.data).find(
    type => type.id === props.typeId
  );
  return productionType ? productionType.metastructure.fields : [];
};

export const makeGetProductionFields = () =>
  createSelector([getProductionFields], fields => fields);

const getBookingFields = (state, props) => {
  const bookingTypes = orderReducer(state.booking_types);
  const bookingType = bookingTypes.find(type => type.id === props.typeId);
  return bookingType ? bookingType.metastructure.fields : [];
};

export const makeGetBookingFields = () =>
  createSelector([getBookingFields], fields => fields);

const getResourceFields = (state, props) => {
  const resourceType = get(state, ['resource_types', 'data', props.typeId]);
  const fields = get(resourceType, ['metastructure', 'fields'], []);
  return fields.filter(field => field.active);
};
export const makeGetResourceFields = () =>
  createSelector([getResourceFields], fields => fields);
