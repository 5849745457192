import Lookup from 'constants/Lookup';

class MoveAssignmentPolicy extends Lookup {
  /**
   * Move the assignment to whichever booking exists on the new assignment day. This can be the same booking
   * if that booking exists on the new assignment day. If multiple bookings exist on the new day, it will prioritise
   * either the existing booking or the first to be returned by the database. If no booking exists, the assignment
   * will be removed.
   * @type {string}
   */
  CHANGE_BOOKING = 'CHANGE_BOOKING';

  /**
   * Extend the booking if the assignment moves outside the existing booking dates. If a booking conflict occurs,
   * it will be ignored. This will add an event to the booking which means that the moved assignment is still within
   * the booking.
   * @type {string}
   */
  EXTEND_BOOKING = 'EXTEND_BOOKING';

  /**
   * Move the booking by the same number of days the assignment will be moved. If a booking conflict occurs,
   * it will be ignored.
   * @type {string}
   */
  MOVE_EXISTING_BOOKING = 'MOVE_EXISTING_BOOKING';

  /**
   * Remove the assignment if it moves outside its existing booking dates.
   * @type {string}
   */
  REMOVE_ASSIGNMENT = 'REMOVE_ASSIGNMENT';

  constructor() {
    super();
    super.items = [
      {
        value: this.CHANGE_BOOKING,
        label: 'Change Bookings',
        description:
          'Move assignments to bookings that exist on the new production dates'
      },
      {
        value: this.MOVE_EXISTING_BOOKING,
        label: 'Move Bookings',
        description: 'Move all bookings in production to new production dates'
      },
      {
        value: this.EXTEND_BOOKING,
        label: 'Extend Bookings',
        description:
          'Extend bookings if the assignment moves outside its booking dates'
      },
      {
        value: this.REMOVE_ASSIGNMENT,
        label: 'Remove Assignments',
        description: 'Remove assignments that fall outside their bookings dates'
      }
    ];
  }
}

export default new MoveAssignmentPolicy();
