import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './ButtonToggle.scss';

export const ButtonToggle = ({
  value,
  label,
  icon,
  selected,
  onClick,
  width
}) => {
  return (
    <div
      className={classnames('ButtonToggle', selected && 'active')}
      style={{ width: width || 'auto' }}
      onClick={e => {
        e.stopPropagation();
        onClick && onClick(value);
      }}
    >
      {label && <span>{label}</span>}
      {icon && <img src={icon} alt="" />}
    </div>
  );
};

ButtonToggle.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  label: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.number
};

export default ButtonToggle;
