import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PressStud } from 'v1/components/shared';
import { renderIcon, isImageFromType } from 'lib/media/files';
import { get } from 'lodash';
import classnames from 'classnames';
import './FileUploadingItem.scss';

export class FileUploadingItem extends Component {
  renderPreview = file => {
    const raw = get(file, 'raw');
    let toDisplay;
    if (isImageFromType(raw.type)) {
      toDisplay = (
        <img
          src={raw.preview}
          className="FileUploadingItem-previewImage"
          alt=""
        />
      );
    } else {
      toDisplay = (
        <img
          src={renderIcon(raw.name)}
          className="FileUploadingItem-icon"
          width="13px"
          alt=""
        />
      );
    }
    return <div className="FileUploadingItem-preview">{toDisplay}</div>;
  };
  renderProgressBar = file => {
    return (
      <span
        className="FileUploadingItem-progressBar"
        style={{ width: `${file.progress}%` }}
      />
    );
  };
  renderProgress = file => {
    const name = get(file, 'raw.name');

    return (
      <div className="FileUploadingItem-progress">
        <h5 className="trunc">{name || 'Untitled File'}</h5>
        {file.complete || file.error ? (
          <span
            className={classnames(
              {
                'FileUploadingItem-progress-statusLabel_success': file.complete,
                'FileUploadingItem-progress-statusLabel_error': file.error
              },
              ['FileUploadingItem-progress-statusLabel']
            )}
          >
            {file.error ? 'FAILED' : 'COMPLETE'}
          </span>
        ) : (
          this.renderProgressBar(file)
        )}
      </div>
    );
  };
  renderActions = file => {
    const { action } = this.props;

    // TODO: Think action is unused..
    if (action) {
      return (
        <div className="FileUploadingItem-actions">
          <PressStud
            label={action.text}
            isDisabled={!file.success}
            action={() => action.onClick(file)}
          />
        </div>
      );
    }
  };
  render() {
    const { file } = this.props;

    return (
      <div className="FileUploadingItem">
        {this.renderPreview(file)}
        {this.renderProgress(file)}
        {this.renderActions(file)}
      </div>
    );
  }
}

FileUploadingItem.propTypes = {
  file: PropTypes.any, // TODO: type
  action: PropTypes.shape({
    className: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func // TODO: arguments
  })
};

export default FileUploadingItem;
