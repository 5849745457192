import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sortable from 'react-sortablejs';

import TableContext from './TableContext';
import TableHeader from './TableHeader';
import TableRowset from './TableRowset';
import TableRowAdd from './TableRowAdd';
import styles from './Table.module.scss';

const groupPrefix = 'group-';

const TableGroups = ({ rows }) => {
  const { isLoading, onGroupAdd, onGroupOrderChange } = useContext(
    TableContext
  );

  const renderGroup = group => {
    const groupId = group.groupByVal.substring(1);
    return (
      <div key={group.id} role="rowgroup" data-id={`${groupPrefix}${groupId}`}>
        <TableHeader groupId={groupId} row={group} />
        {group.isExpanded && (
          <TableRowset groupId={groupId} rows={group.subRows} />
        )}
      </div>
    );
  };

  const renderGroupset = () => {
    const values = rows.reduce((acc, row) => {
      if (row.isGrouped && row.depth === 0) {
        return [...acc, renderGroup(row)];
      }
      return acc;
    }, []);

    return (
      <>
        {values}
        {!isLoading && onGroupAdd && (
          <div className={classnames(styles.Footer)}>
            <TableRowAdd isGroup />
          </div>
        )}
      </>
    );
  };

  const renderSortableGroups = () => (
    <Sortable
      options={{
        group: 'groups',
        draggable: '[role=rowgroup]',
        handle: '.TableHandle',
        animation: 150,
        ghostClass: styles.ghostClass,
        dragClass: styles.dragClass
      }}
      onChange={order =>
        onGroupOrderChange(order.map(o => o.substring(groupPrefix.length)))
      }
    >
      {renderGroupset()}
    </Sortable>
  );

  return onGroupOrderChange ? renderSortableGroups() : renderGroupset();
};

TableGroups.propTypes = {
  rows: PropTypes.array
};

export default TableGroups;
