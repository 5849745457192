import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { UserCircleImage } from 'v1/components/shared';
import { selectAccountById } from 'store/accounts';

import './SystemUser.scss';

const ANONYMOUS_USER = {
  firstName: 'Anonymous'
};

export const SystemUser = forwardRef(
  (
    { id, className, includeImage, isGuestUser, user: propUser, ...rest },
    ref
  ) => {
    const account = useSelector(state =>
      id ? selectAccountById(state, id) : null
    );
    const user = propUser || account || ANONYMOUS_USER;
    return (
      <div
        className={classnames(['SystemUser', className])}
        {...rest}
        ref={ref}
      >
        {includeImage && (
          <div className="SystemUser-profileImage">
            <UserCircleImage
              src={user.profileImage}
              className="small"
              contact_id={user.id}
              initials={{
                first_name:
                  user.firstName ||
                  user.full_name ||
                  user.email_address ||
                  user.email,
                last_name: user.lastName || ''
              }}
            />
          </div>
        )}
        <div className="SystemUser-details">
          {user.firstName || user.full_name || user.email_address || user.email}{' '}
          {user.lastName || ''}
          {isGuestUser && <i>Guest</i>}
        </div>
      </div>
    );
  }
);

// TODO
SystemUser.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  includeImage: PropTypes.bool
};

export default SystemUser;
