import { GROUP_ACTIONS } from 'store/v1/groups/groups.constants.js';
import pick from 'lodash/pick';

const token = true;

export const getGroups = (event_id = null) => {
  return {
    types: [
      GROUP_ACTIONS.GET_GROUPS,
      GROUP_ACTIONS.GET_GROUPS_SUCCESS,
      GROUP_ACTIONS.GET_GROUPS_FAILURE
    ],
    promise: client => client.get('/groups/resource_types', { token }),
    event_id
  };
};

export const createGroup = (data, event_id = null) => {
  return {
    types: [
      GROUP_ACTIONS.CREATE_GROUP,
      GROUP_ACTIONS.CREATE_GROUP_SUCCESS,
      GROUP_ACTIONS.CREATE_GROUP_FAILURE
    ],
    promise: client => client.post('/groups/resource_types', { data, token }),
    event_id
  };
};

export const updateGroup = (id, group, event_id = null) => {
  const toSubmit = pick(group, ['name', 'colour']);
  return {
    types: [
      GROUP_ACTIONS.UPDATE_GROUP,
      GROUP_ACTIONS.UPDATE_GROUP_SUCCESS,
      GROUP_ACTIONS.UPDATE_GROUP_FAILURE
    ],
    promise: client => client.put(`/groups/${id}`, { data: toSubmit, token }),
    event_id
  };
};

export const addToGroup = (id, data, action, event_id = null) => {
  return {
    types: [
      GROUP_ACTIONS.ADD_TO_GROUP,
      GROUP_ACTIONS.ADD_TO_GROUP_SUCCESS,
      GROUP_ACTIONS.ADD_TO_GROUP_FAILURE
    ],
    promise: client =>
      client.put(`/groups/${id}/contacts/${action}`, {
        data,
        token
      }),
    event_id,
    action,
    contact_ids: data.ids
  };
};

// UNUSED
// export const removeFromGroup = (id, data, event_id = null) => {
//   return {
//     types: [
//       GROUP_ACTIONS.REMOVE_FROM_GROUP,
//       GROUP_ACTIONS.REMOVE_FROM_GROUP_SUCCESS,
//       GROUP_ACTIONS.REMOVE_FROM_GROUP_FAILURE
//     ],
//     promise: client => client.put(`/groups/${id}/remove`, { data, token }),
//     event_id
//   };
// };

export const deleteGroup = (id, event_id = null) => {
  return {
    types: [
      GROUP_ACTIONS.DELETE_GROUP,
      GROUP_ACTIONS.DELETE_GROUP_SUCCESS,
      GROUP_ACTIONS.DELETE_GROUP_FAILURE
    ],
    promise: client => client.del(`/groups/${id}`, { token }),
    event_id,
    group_id: id
  };
};

export const reorderGroup = (groups, event_id = null) => {
  const toSubmit =
    groups && groups.map(group => ({ id: group.id, order: group.order }));
  return {
    types: [
      GROUP_ACTIONS.REORDER_GROUPS,
      GROUP_ACTIONS.REORDER_GROUPS_SUCCESS,
      GROUP_ACTIONS.REORDER_GROUPS_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/groups`, { data: { reorder: toSubmit }, token }),
    event_id
  };
};
