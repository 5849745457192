import uuid from 'uuid';
//import validator from 'validator';
import { get, isEmpty, merge, sortBy } from 'lodash';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { bg } from 'date-fns/locale';
const moment = extendMoment(Moment);

const platforms = {
  twitter: 'twitter.com/',
  instagram: 'instagram.com/',
  behance: 'behance.net/',
  vimeo: 'vimeo.com/',
  dribbble: 'dribbble.com/',
  youtube: 'youtube.com/user/',
  linkedin: 'linkedin.com/in/',
  github: 'github.com/',
  angellist: 'angel.co/',
  vsco: 'vsco.co/',
  soundcloud: 'soundcloud.com',
  spotify: 'spotify.com/artist/',
  facebook: 'facebook.com/',
  medium: 'medium.com/@'
};

export function isTempId(id) {
  return typeof id === 'string' && id.includes('temp');
}

export function stringReplace(string, find, replace) {
  if (!string || typeof string !== 'string') return '';
  return string.replace(new RegExp(find, 'g'), replace);
}

export function friendlyStringFromConst(string) {
  if (!string || typeof string !== 'string') return string;
  const [firstChar, ...rest] = [...string]; // Surely a more elegant way of achieving this...
  return stringReplace(
    `${firstChar.toUpperCase()}${rest.join('').toLowerCase()}`,
    '_',
    ' '
  );
}

/**
 * Capitalise the first character of a string
 * @param {string} text
 * @returns {string}
 */
export function capitalizeFirstLetter(text = '') {
  if (typeof text !== 'string') {
    console.warn('invalid argument type supplied, expected string');
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function generateCode(prefix) {
  return `${prefix || '00'}-${moment().format('MMYY')}`;
}

export function truncateString(string, length) {
  if (string && string.length > length)
    return string.substring(0, length) + '...';
  else return string;
}

export function createBuildPublicUrl(getLocation) {
  return function buildPublicUrl(public_id, destination, prettier) {
    if (!public_id) return '';
    const id = public_id ? public_id.toLowerCase() : '';

    const { pathname, origin } = getLocation();
    if (pathname.indexOf('/app') === 0) {
      const app_id = pathname.split('/')[2];
      return `${origin}/app/${app_id}/p/${destination}/${id}`;
    }
    return '';
  };
}
export const buildPublicUrl = createBuildPublicUrl(() => window.location);

export function cleanUrl(url) {
  return url && url.split(/[?#]/)[0];
}

/**
 * Returns true if the URL points to an image file
 * @param {string} url
 * @return {boolean}
 */
export function isImageFromURL(url) {
  if (typeof url !== 'string') {
    return false;
  }
  const regex =
    /^((https?|ftp):)?\/\/.*(jpeg|jpg|png|gif|bmp|avif|heic|webp|tif)/;
  return regex.test(url.toLowerCase());
}

export function isVideoFromURL(url) {
  if (!url || typeof url !== 'string') return false;
  return url
    .toLowerCase()
    .match(/.(mp4|mov|mkv|mpeg|ogv|webm|wmv|avi|quicktime)$/i);
}

export function customTemplatesContactMap(customTemplate, contact) {
  const customFields = [];
  //const contactCustomFields = contact.customs;

  Object.keys(customTemplate).forEach(key => {
    const custom = {};
    custom.key = key;
    custom.type = customTemplate[key].type;
    customFields.push(custom);
  });
  merge(contact, customTemplate);
}

export function socialLinkCleaner(link) {
  let ret = link;
  Object.keys(platforms).forEach(platform => {
    if (link.indexOf(platforms[platform]) > -1) {
      let handle = link.substring(
        link.indexOf(platforms[platform]) + platforms[platform].length
      );
      handle = handle.split(/\/|\?|#/)[0];
      ret = handle;
    }
  });
  ret = ret.replace('@', '');
  return ret;
}

export function orderReducer(state = {}, selector) {
  const order = selector || 'order';
  if (Array.isArray(state[order])) {
    return state[order].map(i => state.data[i]);
  }
  return [];
}

export function orderByText(array, direction = 'asc', accessor = null) {
  return array.sort((a, b) => {
    let textA = '',
      textB = '';
    if (accessor && typeof accessor === 'function') {
      textA = accessor(a);
      textB = accessor(b);
    } else if (accessor && typeof accessor === 'string') {
      textA = get(a, accessor, '');
      textB = get(b, accessor, '');
    } else {
      textA = a;
      textB = b;
    }
    if (textA.toLowerCase) textA = textA.toLowerCase();
    if (textB.toLowerCase) textB = textB.toLowerCase();

    if (direction === 'asc') return textA < textB ? -1 : textA > textB ? 1 : 0;
    return textA < textB ? 1 : textA > textB ? -1 : 0;
  });
}

export function orderByDate(
  array,
  direction = 'asc',
  accessor = null,
  dateDiff = 'd',
  subOrder
) {
  return array.sort((a, b) => {
    var dateA = accessor ? get(a, accessor, undefined) : a;
    var dateB = accessor ? get(b, accessor, undefined) : b;
    const diff = moment(dateA).diff(dateB, dateDiff);
    if (diff === 0) return subOrder ? subOrder(a, b, direction) : 0;
    if (direction === 'desc') return diff > 0 ? -1 : diff < 0 ? 1 : 0;
    return diff > 0 ? 1 : diff < 0 ? -1 : 0;
  });
}

export function orderByKey(array, key) {
  return [...array].sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function objectToOrderedList(obj, _attr) {
  const reverse = _attr && _attr.startsWith('-');
  const attr = reverse ? _attr.slice(1) : _attr;
  if (!obj) return [];
  const listy = Object.keys(obj).map(key => merge({}, obj[key], { _key: key }));
  const array = sortBy(
    listy,
    el => el[typeof attr !== 'undefined' ? attr : 'order']
  );
  return reverse ? array.reverse() : array;
}

export function htmlStringParser(html) {
  const httpString = 'http://';
  const httpsString = 'https://';
  if (!html) return '';
  if (
    html.substr(0, httpString.length).toLowerCase() !== httpString &&
    html.substr(0, httpsString.length).toLowerCase() !== httpsString
  ) {
    html = httpString + html;
  }
  return html;
}

export function prettifyURL(url) {
  if (!url || typeof url !== 'string') return;
  url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

  return url;
}

export function scrollToTop() {
  const element = document.getElementsByClassName('Page')[0];
  const c = element.scrollTop;

  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    element.scrollTo(0, c - c / 6);
  }
}

export function cloudfrontifyImage(link, w, h, q) {
  if (!link) return null;
  const RESIZE_URL =
    'https://iu804ranfa.execute-api.eu-west-1.amazonaws.com/dev';
  const S3_BUCKET = 'https://easle-imagery.s3-eu-west-1.amazonaws.com';
  const width = w;
  const height = h;
  const quality = q || 80;
  if (link.indexOf(S3_BUCKET) > -1 && link.indexOf('.gif') === -1) {
    return `${link.replace(S3_BUCKET, RESIZE_URL)}?w=${width}&q=${quality}${
      height ? `&h=${height}` : ''
    }`;
  }
  return link;
}

export function mergeArrays(array) {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
}

export function parseLocation(location) {
  const tags = location.gmaps.address_components.map(ac => ac.long_name);
  const location_tags = tags.filter((item, pos) => tags.indexOf(item) === pos);

  const query_location = {
    location_name: location.label,
    location_coordinates: {
      lat: location.location.lat,
      lon: location.location.lng
    },
    location_tags
  };

  return query_location;
}
export function cleanHtml(text) {
  const tempDiv = document.createElement('DIV');
  tempDiv.innerHTML = text;
  return tempDiv.innerText;
}
export function newID() {
  return uuid.v4();
}
// export function isVowel(string) {
//   let vowels: [Character] = ["a","e","i","o","u"]
//   return vowels.contains(!string.lowercaseString.characters.first)
// }
/**
 * [getEventStatusForStore description]
 * grunt helper...
 * @param  {string} event_id [the uuid we're looking for]
 * @param  {object} store    [either state.events or nextPops.events]
 * @return {string}          [event actions constant]
 */
export function getEventStatusForStore(event_id, store) {
  if (!event_id || !store) return null;
  return store[event_id] || null;
}

export function inputError(error) {
  if (error) {
    return error;
  }
}

export function getFullName({ first_name, last_name } = {}) {
  if (first_name && last_name) return first_name + ' ' + last_name;
  return first_name || last_name || '';
}

export function parseInitials(first_name, last_name, full_name) {
  if (first_name && last_name) return `${first_name[0]}${last_name[0]}`;
  if (full_name) {
    const split = full_name.replace(/\s\s+/g, ' ').split(' ');
    return `${split[0]}${split[1]}`;
  }
  if (first_name) return first_name.substr(0, 2);

  return null;
}

export function isValue(value) {
  if ([undefined, null, ''].includes(value)) return false;
  if (typeof value === 'object' && isEmpty(value)) return false;

  return true;
}

export function pickForegroundColour(bgColor) {
  if (!bgColor) return '#212121';
  // Helper function to convert hex to RGB
  function hexToRgb(hex) {
    // Remove the hash if it exists
    hex = hex.replace(/^#/, '');

    // Calculate the RGB values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }

  // Helper function to calculate perceived brightness
  function getBrightness(r, g, b) {
    return (r * 299 + g * 587 + b * 114) / 1000;
  }

  let r, g, b;

  // Check if input is a hex or RGB value
  if (bgColor.startsWith('#')) {
    [r, g, b] = hexToRgb(bgColor);
  } else if (bgColor.startsWith('rgb(')) {
    const matches = bgColor.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (matches) {
      r = parseInt(matches[1]);
      g = parseInt(matches[2]);
      b = parseInt(matches[3]);
    }
  }

  // Calculate brightness
  const brightness = getBrightness(r, g, b);

  // Decide the text color based on brightness
  if (brightness > 128) {
    return '#212121'; // Black text for light backgrounds
  } else {
    return '#f1f1f1'; // White text for dark backgrounds
  }
}

export function checkOverlapping(event, events) {
  moment.tz.setDefault('Europe/London');
  const eventRange = moment.range(
    moment(event.start).format(), // 00:00:00
    moment(event.end).endOf('day') // 23:59:59,999
  );

  return events.reduce((result, item) => {
    const range = moment.range(
      moment(item.start).format(),
      moment(item.end).endOf('day')
    );
    return range.overlaps(eventRange, { adjacent: true }) ? range : result;
  }, null);
}

// Convert array to an object keyed by a property
export const getKeyed = (arr, property) =>
  arr.reduce((acc, obj) => {
    let key = obj[property];
    acc[key] = acc[key] || [];
    acc[key].push(obj);
    return acc;
  }, {});

// Sorts a nested fieldlist for eg FieldListSelection
// TODO: clean up
export const sortFieldsAlphabetically = array =>
  array
    .sort((a, b) =>
      get(a, 'name', '')
        .toLowerCase()
        .localeCompare(get(b, 'name', '').toLowerCase())
    )
    .map(e => {
      return e.children
        ? { ...e, children: sortFieldsAlphabetically(e.children) }
        : e;
    });

/**
 * Checks if the current browser is Safari
 * @returns {Boolean}
 */
export const isBrowserSafari = () => {
  const safariRegex = /^((?!chrome|android).)*safari/i;
  return safariRegex.test(navigator.userAgent);
};

/**
 * Gets a random number between 1000 and 4000
 * @returns
 */
export function getRandomNumberBetween1000And7000() {
  return Math.floor(Math.random() * (7000 - 1000 + 1)) + 1000;
}
