import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const PRODUCTION_TYPES_ACTIONS = mapValues(
  mirror({
    GET_PRODUCTION_TYPES: null,
    GET_PRODUCTION_TYPES_SUCCESS: null,
    GET_PRODUCTION_TYPES_FAILURE: null,

    CREATE_PRODUCTION_TYPE: null,
    CREATE_PRODUCTION_TYPE_SUCCESS: null,
    CREATE_PRODUCTION_TYPE_FAILURE: null,

    DELETE_PRODUCTION_TYPE: null,
    DELETE_PRODUCTION_TYPE_SUCCESS: null,
    DELETE_PRODUCTION_TYPE_FAILURE: null,

    UPDATE_PRODUCTION_TYPE: null,
    UPDATE_PRODUCTION_TYPE_SUCCESS: null,
    UPDATE_PRODUCTION_TYPE_FAILURE: null,

    UPDATE_PRODUCTION_TYPE_ORDER: null,
    UPDATE_PRODUCTION_TYPE_ORDER_SUCCESS: null,
    UPDATE_PRODUCTION_TYPE_ORDER_FAILURE: null
  }),
  value => value
);
