import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import arrayMove from 'array-move';
import {
  get,
  debounce,
  isEmpty,
  chain,
  map,
  find,
  uniq,
  filter,
  omit
} from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { getBatchContacts } from 'store/v1/contacts/contacts.actions.js';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors.js';
import {
  getShortlist,
  updateShortlist,
  updateShortlistBlock,
  searchShortlistBlocks
} from 'store/v1/shortlists/shortlists.actions.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';

import { duplicateShortlist } from 'v1/helpers/byModel/shortlistDuplicateHelper.js';

import useSocketEvent from 'hooks/useSocketEvent';
import useEvent from 'v1/helpers/hooks/useEvent';
import useGetContactEvents from 'v1/helpers/hooks/useGetContactEvents';
import {
  generateShortlistsFieldMapper,
  getAllResourceTypeFields
} from 'v1/helpers/shortlistFieldHelper';

import ShortlistPublishSettings from 'v1/components/feature/ShortlistComponents/Shortlist/ShortlistPublishSettings/ShortlistPublishSettings';
import ShortlistFeedback from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/ShortlistFeedback/ShortlistFeedback';
import ShortlistPresentView from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/ShortlistPresentView';
import PublicShortlistPresentView from 'v1/components/feature/ShortlistComponents/PublicShortlist/PublicShortlistPresentView/PublicShortlistPresentView';
import {
  Loading,
  PageTabsWithSteps,
  ErrorBlock,
  PublicLinkStatus,
  PageHeader,
  Page,
  RestrictedDropdown,
  MenuItem
} from 'v1/components/shared';

import { SOCKET_EVENTS } from 'lib/websockets';

import './Shortlist.scss';
import { setShortlistUpdatedAt } from '../../../../store/v1/shortlists/shortlists.actions';

export const SHORTLIST_RESOURCE_MAX_COUNT = 999;

const Shortlist = ({ shortlist_id, history = {} }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  // REDUX
  const shortlists = useSelector(state => state.shortlists);
  const ui = useSelector(state => state.ui);
  const params = useParams();
  const custom_field_definitions = useSelector(state =>
    get(state.auth, 'settings.custom_field_definitions')
  );
  const shortlistId = params.id;

  // REFS
  const shortlistPresentView = useRef();

  // STATE
  // const [shortlistId, setShortlistId] = useState(params.id);
  const [shortlist, setShortlist] = useState(
    get(shortlists, ['data', shortlistId], {})
  );
  const [blocks, setBlocks] = useState(
    get(shortlists, ['data', shortlistId, 'shortlist_blocks'], [])
  );
  const storeShortlist = get(shortlists, ['data', shortlistId]);

  const [activeContainer, setActiveContainer] = useState(shortlist.status);
  const shortlistResourceIds = useMemo(
    () => map(blocks, block => block.contact_id),
    [blocks]
  );
  const reloadContactEvents = useMemo(() => blocks.length, [blocks]);

  const resourceTypeIds = uniq(
    map(
      blocks.filter(b => !b.archived),
      'contact.resource_type_id'
    )
  );

  const resourceTypes = useSelector(state => {
    if (!resourceTypeIds.length) {
      const talentResourceTypes = getResourceTypes(state, { model: 'TALENT' });
      return [get(talentResourceTypes, [0], get(getResourceTypes(state), [0]))];
    }

    return resourceTypeIds.map(typeId =>
      find(getResourceTypes(state), r => r.id === typeId)
    );
  });

  const resourceTypeFields = getAllResourceTypeFields(
    filter(resourceTypes, Boolean)
  );
  const shortlist_field_mapper = generateShortlistsFieldMapper(
    resourceTypeFields,
    custom_field_definitions
  );

  useGetContactEvents({
    contactIds: shortlistResourceIds,
    start_date: get(shortlist, 'show_availability.start_date'),
    end_date: get(shortlist, 'show_availability.end_date'),
    load: [reloadContactEvents, shortlist.show_availability]
  });

  useEffect(() => {
    shortlist.default_resources_query &&
      dispatch(
        searchShortlistBlocks(shortlist.id, {
          ...shortlist.default_resources_query,
          count: SHORTLIST_RESOURCE_MAX_COUNT
        })
      );
  }, [dispatch, shortlist]);

  useEffect(() => {
    dispatch(getShortlist(shortlistId));
  }, []);

  useEffect(() => {
    if (storeShortlist) {
      setShortlist(storeShortlist);
      setBlocks(storeShortlist.shortlist_blocks);
      setActiveContainer(storeShortlist.status);
    }
  }, [dispatch, storeShortlist, shortlistId, ui.modalIsOpen]);

  useSocketEvent(
    ({ payload }) => {
      if (
        payload.shortlistId &&
        +payload.shortlistId === +shortlistId &&
        payload.sessionId !== window.sessionId
      ) {
        dispatch(
          setShortlistUpdatedAt(payload.shortlistId, payload.updated_at)
        );
      }
    },
    [SOCKET_EVENTS.SHORTLIST_UPDATED]
  );

  function returnShortlistBlockIds(field = 'id') {
    return uniq(map(blocks, b => b.contact[field]).filter(Boolean));
  }

  function getShortlistContacts() {
    const contactIds = returnShortlistBlockIds();
    const agentIds = returnShortlistBlockIds('represented_by_id');
    const organisationIds = returnShortlistBlockIds('organisation_id');
    const ids = [...contactIds, ...agentIds, ...organisationIds];
    if (!isEmpty(ids)) {
      dispatch(getBatchContacts(ids));
    }
  }

  useEffect(() => {
    if (blocks.length) {
      getShortlistContacts();
    }
  }, [reloadContactEvents]);

  useEvent(
    [SHORTLISTS_ACTIONS.UPDATE_SHORTLIST, SHORTLISTS_ACTIONS.GET_SHORTLIST],
    {
      onFailure: () => dispatch(displayAlert('error', 'An error accourred'))
    }
  );

  useEvent([SHORTLISTS_ACTIONS.DUPLICATE_SHORTLIST], {
    onSuccess: () => {
      const id = get(shortlists, 'new.id');
      history.push(`/app/${auth.app_id}/shortlists/${id}`);
      dispatch(
        openModal('ShortlistCreateModal', {
          shortlist: get(shortlists, 'new')
        })
      );
    },
    onFailure: () => dispatch(displayAlert('error', 'An error occurred'))
  });

  useEvent([SHORTLISTS_ACTIONS.CREATE_SHORTLIST], {
    onSuccess: () => {
      const newShortlist = get(shortlists, 'new');
      setShortlist(newShortlist);
    }
  });

  const canUpdate = useRestrictedAccess(
    null,
    Capability.SHORTLIST_UPDATE,
    null
  );

  function onDuplicateShortlist() {
    const newShortlist = duplicateShortlist(shortlist);

    return dispatch(
      openModal('ShortlistCreateModal', {
        create: true,
        shortlist: newShortlist,
        modalTitle: 'Duplicate shortlist'
      })
    );
  }

  // update shortlist
  const handleSubmit = useCallback(
    debounce(data => {
      dispatch(updateShortlist(shortlistId, data));
    }, 500),
    [shortlistId]
  );

  function handleShortlistSave(data, disabledDebounce) {
    const updatedShortlist = { ...shortlist, ...data };
    setShortlist(updatedShortlist);

    handleSubmit(omit(updatedShortlist, ['shortlist_blocks']));
  }

  function handleShortlistBlocksSave(shortlistBlocks) {
    const updatedShortlist = {
      ...shortlist,
      shortlist_blocks: shortlistBlocks
    };

    setShortlist({ ...shortlist, shortlist_blocks: shortlistBlocks });
    handleSubmit(updatedShortlist);
  }

  // update block
  const onUpdateShortlistBlock = useCallback(
    debounce(block => {
      dispatch(
        updateShortlistBlock(
          {
            shortlist_id: shortlistId,
            shortlist_block_id: block.id
          },
          block
        )
      );
    }, 500),
    []
  );

  function handleBlockUpdate(block) {
    const updatedBlocks = blocks.map(b => (b.id === block.id ? block : b));
    const updatedShortlist = { ...shortlist, shortlist_blocks: blocks };
    setShortlist(updatedShortlist);
    setBlocks(updatedBlocks);
    onUpdateShortlistBlock(block);
  }

  function handleUpdateOrder(oldIndex, newIndex, newOrderedBlocks) {
    let updatedBlocks = blocks;

    // if new orderedBlocks are coming through they are already ordered in the correct way
    // if oldIndex, newIndex then we use arrayMove to re-order
    if (newOrderedBlocks) {
      updatedBlocks = chain(newOrderedBlocks)
        .filter(b => !b.archived)
        .value();
    } else {
      updatedBlocks = chain(blocks)
        .orderBy(['order', 'asc'])
        .filter(b => !b.archived)
        .value();
      updatedBlocks = arrayMove(updatedBlocks, oldIndex, newIndex);
    }
    // Reset the order values using the new ordered array's index
    updatedBlocks = updatedBlocks.map((block, index) => ({
      ...block,
      order: index
    }));
    // Update state and submit update
    setBlocks(updatedBlocks);
    handleShortlistBlocksSave(updatedBlocks);
    // handleShortlistSave({ shortlist_blocks: updatedBlocks });
  }

  function renderShortlist(state, canUpdate) {
    if (state === 'EDIT' && canUpdate) {
      return (
        <ShortlistPresentView
          blocks={blocks}
          updating={
            shortlists.loading === 'UPDATE_SHORTLIST' ||
            shortlists.loading === 'CREATE_SHORTLIST' ||
            shortlists.loading === 'GET_SHORTLIST' ||
            shortlists.loading === 'CREATE_SHORTLIST_BLOCK' ||
            shortlists.loading === 'DELETE_SHORTLIST_BLOCK'
          }
          shortlist={shortlist}
          onDataChange={handleBlockUpdate}
          onSubmit={handleShortlistSave}
          handleUpdateOrder={handleUpdateOrder}
          shortlist_field_mapper={shortlist_field_mapper}
          ui={ui}
          onPreviewContact
        />
      );
    }
    return (
      <div
        className="ShortlistPresentView"
        style={{
          backgroundColor: get(
            auth,
            `settings.settings.public_platform_colour.value`
          )
        }}
        ref={shortlistPresentView}
      >
        <PublicShortlistPresentView
          shortlist={shortlist}
          shortlist_field_mapper={shortlist_field_mapper}
          presentMode="PREVIEW"
          shortlistPresentView={shortlistPresentView.current}
        />
      </div>
    );
  }
  function renderContainer(shortlist, canUpdate) {
    switch (activeContainer) {
      case 'BUILDING':
      default:
        return (
          <div className="ShortlistContentContainer">
            {renderShortlist('EDIT', canUpdate)}
          </div>
        );
      case 'BUILD_COMPLETE':
        return (
          <div className="ShortlistContentContainer">
            {renderShortlist(undefined, canUpdate)}
          </div>
        );
      case 'PUBLISHED':
        return (
          <div className="ShortlistContentContainer">
            <ShortlistPublishSettings
              onSubmit={handleShortlistSave}
              shortlist={shortlist}
              loading={shortlists.loading}
            />
            {renderShortlist(undefined, canUpdate)}
          </div>
        );
      case 'FEEDBACK':
        return (
          <div className="ShortlistContentContainer">
            <ShortlistFeedback
              blocks={blocks}
              shortlist={shortlist}
              shortlistFieldMapper={shortlist_field_mapper}
              // onPreviewContact={onPreviewContact}
            />
          </div>
        );
    }
  }
  function renderHeader() {
    return (
      <PageHeader
        title={shortlist.title}
        subtitle={shortlist.description}
        meta={{
          user_id: shortlist.user_id,
          created_at: shortlist.created_at,
          updated_at: shortlist.updated_at
        }}
        backLink="/shortlists"
        updatingStatus={{
          isUpdating: shortlists.loading,
          updated: shortlist.updated_at
        }}
      >
        <div className="PageHeader-actions">
          <PublicLinkStatus
            url={shortlist.public_id}
            destination="shortlists"
            status={{
              active: shortlist.published,
              text: shortlist.published ? 'Published' : 'Draft'
            }}
            options={[
              {
                text: 'Publish Shortlist',
                action: () => {
                  handleShortlistSave({
                    published: true
                  });
                }
              },
              {
                text: 'Unpublish Shortlist',
                action: () => {
                  handleShortlistSave({
                    published: false
                  });
                }
              }
            ]}
          />
          <RestrictedDropdown
            capabilitiesAtLeastOne={[
              Capability.SHORTLIST_UPDATE,
              Capability.SHORTLIST_CREATE,
              Capability.SHORTLIST_ARCHIVE
            ]}
            buttonIcon={<img src="/images/icon_options.svg" alt="" />}
            buttonClass="btn btn-link btn-small shortlist-options-button"
          >
            <MenuItem
              capabilities={Capability.SHORTLIST_UPDATE}
              onSelect={() => {
                dispatch(
                  openModal('ShortlistCreateModal', {
                    shortlist
                  })
                );
              }}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_edit.svg" alt="" />
              </span>
              <span className="MenuItem-label">Edit</span>
            </MenuItem>
            <MenuItem
              capabilities={Capability.SHORTLIST_CREATE}
              onSelect={onDuplicateShortlist}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_duplicate.svg" alt="" />
              </span>
              <span className="MenuItem-label">Duplicate Shortlist</span>
            </MenuItem>
            <MenuItem
              capabilities={Capability.SHORTLIST_ARCHIVE}
              onSelect={() => {
                const options = {
                  title: 'Are you sure you want to archive this shortlist?',
                  text: 'You will not lose any data',
                  button: 'Archive shortlist',
                  action: 'DELETE_SHORTLIST',
                  shortlist_id: shortlist.id
                };
                dispatch(openModal('ConfirmationModal', options));
              }}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_delete.svg" alt="" />
              </span>
              <span className="MenuItem-label">Archive</span>
            </MenuItem>
          </RestrictedDropdown>
        </div>
      </PageHeader>
    );
  }
  function renderPageTabs() {
    return (
      <PageTabsWithSteps
        steps={[
          {
            text: 'Build',
            container: 'BUILDING',
            nextAction: {
              onClick: () => {
                setActiveContainer('BUILD_COMPLETE');
                handleShortlistSave({
                  status: 'BUILD_COMPLETE'
                });
              },
              className: 'btn btn-primary',
              label: 'Preview',
              arrowRight: true
            }
          },
          {
            text: 'Preview',
            container: 'BUILD_COMPLETE',
            nextAction: {
              onClick: () => {
                dispatch(openModal('ShortlistPublishModal', { shortlist }));
              },
              className: 'btn btn-primary',
              label: 'Publish',
              arrowRight: true
            },
            previousAction: {
              onClick: () => {
                setActiveContainer('BUILDING');
                handleShortlistSave({
                  status: 'BUILDING'
                });
              },
              isLoading: shortlists.updating,
              className: 'btn btn-default',
              label: 'Back to edit',
              dark: true
            }
          },
          {
            text: 'Publish',
            container: 'PUBLISHED',
            previousAction: {
              onClick: () => {
                setActiveContainer('BUILDING');
                handleShortlistSave({
                  status: 'BUILDING'
                });
              },
              className: 'btn btn-default',
              isLoading: shortlists.updating,
              label: 'Back to edit',
              dark: true
            },
            nextAction: {
              onClick: () => {
                setActiveContainer('FEEDBACK');
                handleShortlistSave({
                  status: 'FEEDBACK'
                });
              },
              className: 'btn btn-primary',
              label: 'View Feedback',
              arrowRight: true
            }
          },
          {
            text: 'Feedback',
            container: 'FEEDBACK',
            previousAction: {
              onClick: () => {
                setActiveContainer('PUBLISHED');
                handleShortlistSave({
                  status: 'PUBLISHED'
                });
              },
              isLoading: shortlists.updating,
              className: 'btn btn-default',
              label: 'Back to publish',
              dark: true
            }
          }
        ]}
        onTabSelect={tab => {
          setActiveContainer(tab);
          handleShortlistSave({
            status: tab
          });
        }}
        activeContainer={activeContainer}
      />
    );
  }

  if (isEmpty(shortlist) && shortlists.loading === 'GET_SHORTLIST')
    return <Loading className="pageCenter" />;

  if (isEmpty(shortlist) && shortlists.loading !== 'GET_SHORTLIST')
    return <ErrorBlock />;

  return (
    <Page className="fixed" flush wide>
      {renderHeader(shortlist)}
      {canUpdate && renderPageTabs(shortlist)}
      <div className="ShortlistPageWrapper">
        {renderContainer(shortlist, canUpdate)}
      </div>
    </Page>
  );
};

export default withRouter(Shortlist);
