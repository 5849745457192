import { DATA_EXPORTS_ACTIONS } from 'store/v1/data_export_templates/data_export_templates.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  new: {},
  file: {},
  loading: false
};

const parseDataExport = e => ({
  ...e,
  fields: JSON.parse(e.fields),
  query: JSON.parse(e.query)
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DATA_EXPORTS_ACTIONS.GET_DATA_EXPORT_TEMPLATES:
    case DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE:
    case DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE:
    case DATA_EXPORTS_ACTIONS.DELETE_DATA_EXPORT_TEMPLATE:
    case DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case DATA_EXPORTS_ACTIONS.GET_DATA_EXPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: parseDataExport(item) }),
          state.data
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false
      };
    case DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: parseDataExport(action.result)
        },
        order: [action.result.id, ...state.order],
        new: action.result
      };
    case DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: parseDataExport(action.result)
        }
      };
    case DATA_EXPORTS_ACTIONS.DELETE_DATA_EXPORT_TEMPLATE_SUCCESS:
      const { [action.templateId]: item, ...data } = state.data;
      return {
        ...state,
        data,
        order: state.order.filter(id => id !== action.templateId),
        loading: false
      };
    case DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.result.data
      };
    case DATA_EXPORTS_ACTIONS.GET_DATA_EXPORT_TEMPLATES_FAILURE:
    case DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE_FAILURE:
    case DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_FAILURE:
    case DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
