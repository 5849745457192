import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  GridCell,
  Label,
  EventDateRange,
  Status
} from 'v1/components/shared';
import ResourcePortalTaskList from 'features/ResourcePortal/ResourcePortalTaskList/ResourcePortalTaskList';
import SidebarDraw from 'v1/components/frame/SidebarDraw/SidebarDraw';
import Text from 'react-format-text';

import './ResourcePortalBookingSidebar.scss';

const ResourcePortalBookingSidebar = ({
  isOpen,
  booking = {},
  closeSidebar
}) => {
  const { events = [], note, status = {}, taskList } = booking;

  // TODO: Needed to do this to convert new events object into v1 event objects so our ui component can handle it
  const formattedEvents = events.map(event => ({
    start_date: event.startDate,
    end_date: event.endDate,
    date_type: event.dateType
  }));

  return (
    <SidebarDraw
      className="ProductionSidebarDrawer"
      isOpen={isOpen}
      closeSidebar={closeSidebar}
      borderColor={status.colour}
      positionFor="portal"
      size="wide"
    >
      <div className="ResourcePortalBooking SidebarDrawer-inner">
        <div className="SidebarDrawerHeader SidebarDrawer-section">
          <Grid vcenter>
            <GridCell>
              <h3>Booking</h3>
            </GridCell>
            <GridCell width="auto">
              <Status statusId={status.id} />
            </GridCell>
          </Grid>
        </div>
        <Grid className="SidebarDrawer-section">
          <GridCell width="2/12">
            <Label size="L">Dates</Label>
          </GridCell>
          <GridCell>
            <EventDateRange isReadOnly data={formattedEvents} />
          </GridCell>
        </Grid>
        <Grid className="SidebarDrawer-section">
          <GridCell width="2/12">
            <Label size="L">Tasks</Label>
          </GridCell>
          <GridCell>
            <ResourcePortalTaskList taskList={taskList} />
          </GridCell>
        </Grid>
        <Grid className="SidebarDrawer-section ResourcePortalBookingSidebar-notes">
          <GridCell width="2/12">
            <Label size="L">Note</Label>
          </GridCell>
          <GridCell>
            {note ? (
              <Text>{note}</Text>
            ) : (
              <span className="text-13-600-eggplant">No note left</span>
            )}
          </GridCell>
        </Grid>
        {false && (
          <Grid className="SidebarDrawer-section disabled">
            <GridCell width="2/12">
              <Label size="L">Files</Label>
            </GridCell>
            <GridCell>
              <h3>Coming Soon</h3>
            </GridCell>
          </Grid>
        )}
      </div>
    </SidebarDraw>
  );
};

ResourcePortalBookingSidebar.defaultProps = {
  isOpen: false
};

ResourcePortalBookingSidebar.propTypes = {
  isOpen: PropTypes.bool,
  booking: PropTypes.object.isRequired,
  closeSidebar: PropTypes.func.isRequired
};

export default ResourcePortalBookingSidebar;
