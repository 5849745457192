import {
  AssociationQuery,
  AssociationState,
  mutationSchemas
} from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { canBlockAffectAssociation } from './resource__shortlist.reducer.helpers';

export const createCreateBatchEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi
) =>
  defineEntityEffect({
    type: 'createBatch',
    requestSchema: mutationSchemas.createBatch.request,
    responseSchema: mutationSchemas.createBatch.response,
    useQueryFn: () => useApi().createBatch
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        // // todo optimistic update can be performed only if batch create response contains all the resources
        // // otherwise we can't switch from optimistic to real data without fetching these resources
        // const affectingResources = request.resource_ids.filter(resourceId =>
        //   canBlockAffectAssociation(query, resourceId, request.shortlist_id)
        // );
        // if (affectingResources.length === 0) {
        //   return state;
        // }
        // const phantomIdStartsFrom =
        //   max(state.results.map(item => item.id)) ?? 0;
        // return {
        //   ...state,
        //   paging: {
        //     ...state.paging,
        //     total: state.paging.total + affectingResources.length
        //   },
        //   results: [
        //     ...state.results,
        //     ...affectingResources.map((resource, index) => ({
        //       id: phantomIdStartsFrom + index + 100,
        //       contact_id: resource,
        //       data: {},
        //       comments: [],
        //       pins: [],
        //       downvotes: [],
        //       upvotes: [],
        //       shortlist_id: request.shortlist_id,
        //       group_id: null,
        //       order: null
        //     }))
        //   ]
        // };
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      const affectingResources = request.resource_ids.filter(resourceId =>
        canBlockAffectAssociation(query, resourceId, request.shortlist_id)
      );

      return {
        state,
        shouldInvalidate: affectingResources.length > 0
      };
    })
    .build();
