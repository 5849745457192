import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-form';

import {
  Button,
  EmptyGeneric,
  NoteItem,
  PressStud,
  TextAreaField,
  TextInput,
  UserCircleImage
} from 'v1/components/shared';

import ResourceFieldsDisplay from '../ResourceFieldsDisplay/ResourceFieldsDisplay';
import ShortlistVotes from '../ShortlistVotes/ShortlistVotes';

import {
  upvoteShortlistBlock,
  commentShortlistBlock,
  downvoteShortlistBlock,
  getShortlist
} from 'store/v1/shortlists/shortlists.actions.js';
import { selectActiveAccount } from 'store/accounts';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import Text from 'react-format-text';
import { isEmpty, get, size, findIndex, filter, chain } from 'lodash';
import { v4 } from 'uuid';
import { getGuest, updateGuest } from 'lib/auth/guestSystem';
import classnames from 'classnames';
import './ShortlistFeedbackView.scss';

export class ShortlistFeedbackView extends Component {
  constructor(props) {
    super(props);

    const user = !isEmpty(props.activeAccount)
      ? props.activeAccount
      : getGuest();

    this.state = {
      block: props.block,
      shortlist: props.shortlist,
      shortlist_blocks: chain(get(props.shortlist, 'shortlist_blocks'))
        .filter(b => !b.archived)
        .orderBy(['order', 'asc'])
        .value(),
      isGuest: isEmpty(props.activeAccount),
      guestNameSet: !isEmpty(user.full_name),
      user
    };
  }

  componentDidMount() {
    this.props.getShortlist(this.props.shortlist.id); // migration bridge
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.state.block.id !== nextProps.block.id) {
      this.setState({ block: nextProps.block });
    }
    if (
      this.props.shortlists.loading === 'COMMENT_SHORTLIST_BLOCK' &&
      !nextProps.shortlists.loading
    ) {
      this.noteFormApi.resetAll();
    }
  };
  navigateShortlist = dest => {
    const { block } = this.state;
    let shortlist_blocks = get(this.state, 'shortlist_blocks');
    // shortlist_blocks = filter(shortlist_blocks, b => !b.archived);
    const shortlistBlockLength = size(shortlist_blocks);
    let blockIndex = findIndex(shortlist_blocks, s => s.id === block.id);

    if (dest === 'PREVIOUS') {
      if (blockIndex === 0) {
        blockIndex = shortlistBlockLength - 1;
      } else {
        blockIndex--;
      }
    }
    if (dest === 'NEXT') {
      if (blockIndex === shortlistBlockLength - 1) {
        blockIndex = 0;
      } else {
        blockIndex++;
      }
    }

    const newBlock = shortlist_blocks[blockIndex];

    this.props.navigateBlock(newBlock);
  };
  onUpvote = () => {
    const { id, public_id } = get(this.state, 'shortlist');
    const blockID = get(this.state, 'block.id');
    const guest = this.state.isGuest ? get(this.state, 'user') : null;
    const toSubmit = { shortlist_block_id: blockID, guest };

    this.setState({ event: v4() }, () => {
      this.props.upvoteShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  onDownvote = () => {
    const { id, public_id } = get(this.state, 'shortlist');
    const blockID = get(this.state, 'block.id');
    const guest = this.state.isGuest ? get(this.state, 'user') : null;
    const toSubmit = { shortlist_block_id: blockID, guest };

    this.setState({ event: v4() }, () => {
      this.props.downvoteShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  handleCommentSubmit = values => {
    const { id, public_id } = get(this.state, 'shortlist');
    const blockID = get(this.state, 'block.id');
    let guest = this.state.isGuest ? get(this.state, 'user') : null;

    const toSubmit = {
      shortlist_block_id: blockID,
      value: values.comment,
      guest
    };

    this.setState({ event: v4() }, () => {
      this.props.commentShortlistBlock(
        { shortlist_public_id: public_id, shortlist_id: id },
        toSubmit,
        this.state.event
      );
    });
  };
  renderCollapsibleDetailsField = () => {
    const { shortlist_field_mapper } = this.props;
    const default_fields = get(this.state, 'shortlist.default_fields');
    const block = get(this.state, 'block');

    if (default_fields && shortlist_field_mapper) {
      return (
        <div className="ShortlistFeedbackView-header-details">
          <ResourceFieldsDisplay
            fields={default_fields}
            fieldMapper={shortlist_field_mapper}
            resource={block.contact}
            layout={{
              width: 'TWO_COLUMNS'
            }}
          />
          <div className="ShortlistFeedbackView-header-details-note">
            <p>
              <Text>{get(block, 'data.paragraph') || ''}</Text>
            </p>
          </div>
        </div>
      );
    }
  };
  renderHeader = () => {
    const { enable_commenting } = this.props.shortlist;
    const contact = get(this.state, 'block.contact');

    if (contact) {
      return (
        <div className="ShortlistFeedbackView-header">
          <div className="ShortlistFeedbackView-header-talent stack-S">
            <UserCircleImage
              src={contact.profile_picture}
              className="ShortlistFeedbackView-header-talent-image"
              contact_id={contact.id}
              initials={{
                first_name: contact.first_name,
                last_name: contact.last_name
              }}
            />
            <div className="ShortlistFeedbackView-header-talent-details">
              <h3 className="trunc">
                {contact.first_name || ''} {contact.last_name || ''}
              </h3>
              <span>{contact.role}</span>
            </div>
            {enable_commenting && (
              <span
                onClick={() => {
                  this.setState(prev => ({
                    showDetails: !prev.showDetails
                  }));
                }}
                className="ShortlistFeedbackView-header-detailsToggle"
              >
                {this.state.showDetails ? 'Hide' : 'Details'}
              </span>
            )}
          </div>
          {(!enable_commenting || this.state.showDetails) &&
            this.renderCollapsibleDetailsField()}
        </div>
      );
    }
  };
  renderCommentList = () => {
    const { public_feedback } = this.props.shortlist;
    let comments = get(this.state, 'block.comments');
    comments = public_feedback
      ? comments
      : filter(
          comments,
          c =>
            (c.guest && c.guest.id === this.state.user.id) ||
            (!this.state.isGuest && c.user_id === this.state.user.id)
        );

    const commentList =
      !isEmpty(comments) &&
      comments.map((comment, index) => {
        return (
          <NoteItem
            key={index}
            note={{
              ...comment,
              note: comment.value,
              user: comment.guest || comment.user,
              user_id: comment.guest ? null : comment.user_id
            }}
            isGuestUser={comment.guest}
          />
        );
      });

    if (!isEmpty(commentList)) {
      return (
        <div className="ShortlistFeedbackView-commentList">{commentList}</div>
      );
    }
    return (
      <div className="ShortlistFeedbackView-commentList">
        <EmptyGeneric
          icon="/images/icon_colour_notes.svg"
          iconWidth="50px"
          title="No Comments"
          description="Start a conversation about this person with team members"
        />
      </div>
    );
  };
  renderCommentInput = () => {
    const { loading } = this.props.shortlists;
    return (
      <Form
        onSubmit={this.handleCommentSubmit}
        defaultValues={{
          guestFullName: this.state.isGuest
            ? get(this.state, 'user.full_name')
            : null
        }}
        getApi={api => {
          this.noteFormApi = api;
        }}
      >
        {formApi => (
          <form
            className={classnames(
              {
                disabled:
                  this.state.isGuest && isEmpty(this.state.user.full_name)
              },
              ['ShortlistFeedbackView-commentInput']
            )}
            onSubmit={formApi.submitForm}
          >
            <TextAreaField
              field="comment"
              id="comment"
              placeholder="Write a comment"
              rows="1"
            />
            {formApi.values.comment && (
              <PressStud
                label="Add Comment"
                appearance="primary"
                size="full"
                isDisabled={!formApi.values.comment}
                isLoading={loading === 'COMMENT_SHORTLIST_BLOCK'}
                action="submit"
              />
            )}
          </form>
        )}
      </Form>
    );
  };
  renderVotes = () => {
    const { loading, public_feedback } = this.props.shortlists;

    return (
      <div
        className={classnames(
          {
            disabled: this.state.isGuest && isEmpty(this.state.user.full_name)
          },
          ['ShortlistFeedbackView-upvotes']
        )}
      >
        <ShortlistVotes
          block={this.state.block}
          user={this.state.user}
          isGuest={this.state.isGuest}
          onUpvote={this.onUpvote}
          onDownvote={this.onDownvote}
          loading={loading}
          showFeedback={public_feedback}
        />
      </div>
    );
  };
  renderComments = () => {
    const { published /*public_feedback*/ } = this.props.shortlist;
    /*const hideFeedback = (
      <div className="ShortlistFeedbackView-commentList">
        <EmptyGeneric
          icon="/images/icon_colour_notes.svg"
          title="Comments are sent to shortlist owner"
        />
      </div>
    );*/
    if (!published) {
      return (
        <EmptyGeneric
          title="Draft"
          description="Votes and comments are disabled when the shortlist is not published"
        />
      );
    }
    return (
      <div className="ShortlistFeedbackView-comments">
        {this.renderCommentList()}
        {this.renderCommentInput()}
      </div>
    );
  };
  shortlistNavigation = () => {
    const { block } = this.state;
    let shortlist_blocks = get(this.state, 'shortlist_blocks');
    // .filter(b => !b.archived)
    // .orderBy(['order', 'asc'])
    // .value();
    let blockIndex = findIndex(shortlist_blocks, s => s.id === block.id);

    return (
      <div className="ShortlistFeedbackView-navigation">
        <PressStud
          icon="arrowLeft"
          action={() => this.navigateShortlist('PREVIOUS')}
        />
        <PressStud
          icon="arrowRight"
          action={() => this.navigateShortlist('NEXT')}
        />
        <span className="ShortlistFeedbackView-navigation-navigationLabel">
          SHORTLISTED {`${blockIndex + 1} OF ${size(shortlist_blocks)}`}
        </span>
      </div>
    );
  };
  renderGuestInput = () => {
    if (!this.state.isGuest) return;
    if (this.state.isGuest && !this.state.guestNameSet) {
      return (
        <div className="ShortlistFeedbackView-guestNotice">
          <h3>Guest Feedback</h3>
          <p>Enter your name below to leave feedback</p>
          <div className="stack-M">
            <TextInput
              placeholder="Your name"
              value={get(this.state, ['user', 'full_name'])}
              onChange={({ target: { value } }) => {
                this.setState(prev => ({
                  user: { ...prev.user, full_name: value }
                }));
              }}
            />
          </div>
          <PressStud
            label="Save Changes"
            appearance="primary"
            size="S"
            action={() => {
              this.setState({ guestNameSet: true }, () => {
                updateGuest(this.state.user);
              });
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="ShortlistFeedbackView-guestNotice stack-M">
          <h5>You are {get(this.state.user, 'full_name')}</h5>{' '}
          <Button
            type="button"
            className="genericLink"
            onClick={() => {
              this.setState(prev => ({
                guestNameSet: false
              }));
            }}
          >
            Change
          </Button>
        </div>
      );
    }
  };
  renderFeedbackTools = () => {
    const { published, enable_commenting, enable_voting } =
      this.props.shortlist;

    return (
      <Fragment>
        {published &&
          (enable_voting || enable_commenting) &&
          this.renderGuestInput()}
        {published && enable_voting && this.renderVotes()}
        {published && enable_commenting && this.renderComments()}
      </Fragment>
    );
  };
  render() {
    return (
      <div className="ShortlistFeedbackView">
        {this.shortlistNavigation()}
        {this.renderHeader()}
        {this.renderFeedbackTools()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAccount: selectActiveAccount(state),
    shortlists: state.shortlists,
    events: state.events
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayAlert,
      upvoteShortlistBlock,
      downvoteShortlistBlock,
      commentShortlistBlock,
      getShortlist
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortlistFeedbackView);
