import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  RateAgencyFee,
  RateProcessingFee,
  RatePayrollFee
} from 'v1/components/shared';
import { Table } from 'shared';

const BookingCostsSummaryFeesTable = ({ data, onChange }) => {
  const tableData = useMemo(
    () => [
      {
        id: 'agency_fee',
        Component: () => (
          <RateAgencyFee
            value={data.agencyFee}
            className="stack-S"
            onChange={value => onChange('rate_agency_fee', value)}
          />
        )
      },
      {
        id: 'payroll_fee',
        Component: () => (
          <RatePayrollFee
            value={data.payrollFee}
            className="stack-S"
            onChange={value => onChange('rate_payroll_fee', value)}
          />
        )
      },
      {
        id: 'processing_fee',
        Component: () => (
          <RateProcessingFee
            value={data.processingFee}
            className="stack-S"
            onChange={value => onChange('rate_processing_fee', value)}
          />
        )
      }
    ],
    [data, onChange]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Fees',
        accessor: 'Component',
        Cell: ({ value }) => value()
      }
    ],
    []
  );

  return (
    <Table
      cellPadding="S"
      rounded={false}
      flush
      columns={columns}
      data={tableData}
    />
  );
};

BookingCostsSummaryFeesTable.propTypes = {
  data: PropTypes.object,
  total: PropTypes.string,
  onChange: PropTypes.func
};

export default BookingCostsSummaryFeesTable;
