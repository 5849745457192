import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Grid, GridCell, Label } from 'v1/components/shared';

const ResourceRatesHeader = ({
  label,
  rateCount,
  readOnly,
  isEditing,
  toggleIsEditing,
  handleSave,
  handleCancel
}) => {
  const displayLabel = label !== undefined ? label : 'Rates';
  return (
    <div
      className={classnames([
        'ResourceRatesHeader',
        { 'ResourceRatesHeader-border': rateCount > 0 }
      ])}
    >
      <Grid spread>
        <GridCell width="auto">
          {displayLabel && (
            <Label>
              Rates
              {rateCount > 1 && <> ({rateCount})</>}
            </Label>
          )}
        </GridCell>
        {!readOnly && (
          <GridCell width="auto">
            {isEditing && (
              <div className="inline">
                <a className="genericLink underline" onClick={handleCancel}>
                  Cancel
                </a>
                {/* TODO: we're gonna need a bigger interpunct */}
                <span className="ResourceRates-interpunct text-11-700-eggplant">
                  ·
                </span>
                <a className="genericLink underline" onClick={handleSave}>
                  Save changes
                </a>
              </div>
            )}
            {!isEditing && rateCount > 0 && (
              <a
                className="genericLink genericLink__subtle underline"
                onClick={toggleIsEditing}
              >
                Edit rates
              </a>
            )}
          </GridCell>
        )}
      </Grid>
    </div>
  );
};

ResourceRatesHeader.props = {
  rateCount: PropTypes.number,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  toggleIsEditing: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func
};

export default ResourceRatesHeader;
