import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get, setWith } from 'lodash';

import { createContact } from 'store/v1/contacts/contacts.actions.js';
import { getDefaultResourceType } from 'store/v1/contacts/contacts.selectors.js';

import { createNameFromString } from 'v1/helpers/contactHelpers';

import {
  Grid,
  GridCell,
  ResourcesList,
  ResourceTitle,
  ResourceTypeSelector,
  SearchInput
} from 'v1/components/shared';

const LOCAL_CONTACT_STORE = 'RESOURCE_SLOT_FLYOVER';

const BookResourceFlyoverResourceContents = ({ slotQuery, setResourceId }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(slotQuery || {});
  const resourceTypes = useSelector(state => state.resource_types.data);
  const defaultResourceType = useSelector(getDefaultResourceType);
  const activeResourceTypeId = get(
    query,
    'filters.resource_type_id.eq',
    defaultResourceType.id
  );
  const activeResourceType = get(resourceTypes, activeResourceTypeId);

  useEffect(() => setQuery(slotQuery || {}), [slotQuery]);

  const onSearchChange = value => setQuery(prev => ({ ...prev, query: value }));

  const onResourceTypeChange = value =>
    setQuery(prev => {
      if (value) {
        return setWith({ ...prev }, 'filters.resource_type_id.eq', value);
      }
      const { filters: { resource_type_id, ...f } = {}, ...q } = prev;
      return { ...q, filters: f };
    });

  // TODO: this is ripped out of ResourceSelector
  const onCreateResource = () => {
    const { first_name, last_name } = createNameFromString(get(query, 'query'));
    const toSubmit = {
      first_name,
      last_name,
      resource_type_id: activeResourceTypeId
    };
    dispatch(createContact(toSubmit, null, LOCAL_CONTACT_STORE));
  };

  const onAssignResource = ({ id }) => setResourceId(id);

  const renderSearch = () => (
    <Grid className="stack-S" gutters="XS">
      <GridCell>
        <SearchInput
          placeholder="Quick search"
          size="S"
          query={query}
          debounce={300}
          onSearch={onSearchChange}
        />
      </GridCell>
      <GridCell width="1/3">
        <ResourceTypeSelector
          size="S"
          label={null}
          allowNull="All"
          value={get(query, 'filters.resource_type_id.eq')}
          onChange={onResourceTypeChange}
        />
      </GridCell>
    </Grid>
  );

  const renderMatches = () => (
    <>
      <div className="text-13-600 stack-XS">Potential matches</div>
      <ResourcesList query={query} localStore={LOCAL_CONTACT_STORE}>
        {({ resourcesResults = [] }) =>
          resourcesResults.map(resource => (
            <ResourceTitle
              key={`matches-${resource.id}`}
              className="stack-XS"
              resource={resource}
              navigateToMethod="FUNCTION"
              onSelect={onAssignResource}
            />
          ))
        }
      </ResourcesList>
    </>
  );

  const renderCreateResource = () => (
    <Grid className="clickable" cellPadding="S" onClick={onCreateResource}>
      <GridCell width="auto">
        <img
          src="/images/icon_add_eggplant_lighter.svg"
          alt=""
          style={{ marginTop: '-3px' }} // TODO: alignment
        />
      </GridCell>
      <GridCell
        className="text-13-700-eggplant-lighter"
        style={{ marginLeft: '-4px' }} // TODO: alignment
      >
        Create & assign "{query.query}" as a new{' '}
        {activeResourceType ? activeResourceType.name : 'resource'}
      </GridCell>
    </Grid>
  );

  return (
    <div className="inset-S">
      {renderSearch()}
      {renderMatches()}
      {query.query && renderCreateResource()}
    </div>
  );
};

BookResourceFlyoverResourceContents.propTypes = {
  slotQuery: PropTypes.object,
  setResourceId: PropTypes.func
};

export default BookResourceFlyoverResourceContents;
