import * as entityTypes from 'v1/helpers/entityTypes';

const resourceBaseFields = [
  { key: 'first_name', name: 'First Name' },
  { key: 'last_name', name: 'Last Name' },
  { key: 'nick_name', name: 'Nickname' },
  { key: 'full_name', name: 'Full Name' },
  { key: 'role', name: 'Role' },
  //["address", "Address"], // TODO: check how this is handled
  { key: 'import_id', name: 'Import ID' },
  { key: 'contact_type', name: 'Contact Type' },
  { key: 'resource_type_id', name: 'Resource Type' },
  { key: 'represented_by_id', name: 'Represented By' },
  //['organisation_id', name: 'Organisation'},
  { key: 'profile_picture', name: 'Profile Picture' },
  { key: 'rates', name: 'Rates' },
  { key: 'tags', name: 'Tags' },
  { key: 'groups', name: 'Groups' },
  { key: 'locations', name: 'Locations' },
  { key: 'emails', name: 'Emails' },
  { key: 'websites', name: 'Websites' },
  { key: 'phone_numbers', name: 'Phone Numbers' },
  { key: 'socials', name: 'Socials' }
];

const productionBaseFields = [
  { key: 'code', name: 'Code' },
  { key: 'icon', name: 'Icon' },
  { key: 'title', name: 'Title' },
  { key: 'production_type_id', name: 'Production Type' },
  { key: 'production_template_id', name: 'Production Template' },
  { key: 'production_date', name: 'Production Date' },
  { key: 'shotflow_instance', name: 'Shotflow Instance' },
  { key: 'status_id', name: 'Status' },
  { key: 'description', name: 'Description' },
  { key: 'color', name: 'Color' },
  { key: 'timezone', name: 'Timezone' },
  { key: 'tags', name: 'Tags' },
  { key: 'events', name: 'Schedule' },
  { key: 'production_locations', name: 'Locations' },
  { key: 'budget_amount', name: 'Budget Amount' },
  { key: 'estimated_total', name: 'Estimated Total' },
  { key: 'actual_total', name: 'Actual Total' },
  {
    key: 'budget_expenses',
    name: 'Budget Expenses',
    children: [
      // TODO: these probably need better labels/nesting tbh
      { key: 'budget_expenses.name', name: 'Name' },
      { key: 'budget_expenses.description', name: 'Description' },
      { key: 'budget_expenses.estimate', name: 'Estimate' },
      { key: 'budget_expenses.archived', name: 'Archived' },
      { key: 'budget_expenses.group_id', name: 'Group' },
      { key: 'budget_expenses.slot_category_id', name: 'Category' },
      {
        key: 'budget_expenses.expenses',
        name: 'Expenses',
        children: [
          { key: 'budget_expenses.expenses.booking_id', name: 'Booking' },
          {
            key: 'budget_expenses.expenses.booking.resource_id',
            name: 'Resource'
          },
          { key: 'budget_expenses.expenses.commit_vendor', name: 'Vendor' },
          { key: 'budget_expenses.expenses.commit', name: 'Committed' },
          { key: 'budget_expenses.expenses.created_at', name: 'Created At' },
          { key: 'budget_expenses.expenses.updated_at', name: 'Updated At' },
          {
            key: 'budget_expenses.expenses.slot_category_id',
            name: 'Category'
          },
          { key: 'budget_expenses.expenses.receipts', name: 'Receipts' }
        ]
      }
    ]
  },
  { key: 'resourcing_at', name: 'Resourcing At' },
  { key: 'ready_at', name: 'Ready At' },
  { key: 'completed_at', name: 'Completed At' },
  { key: 'cancelled_at', name: 'Cancelled At' }
];

const bookingBaseFields = [
  { key: 'booking_type_id', name: 'Booking Type' },
  { key: 'status_id', name: 'Status' },
  { key: 'rate', name: 'Rate' },
  { key: 'events', name: 'Booking Dates' },
  { key: 'note', name: 'Note' },
  { key: 'contracts', name: 'Contracts' },
  { key: 'invoices', name: 'Invoices' },
  { key: 'submitted_date', name: 'Submitted Date' },
  { key: 'agreed_date', name: 'Agreed Date' },
  { key: 'pencilled_at', name: 'Pencilled At' },
  { key: 'confirmed_at', name: 'Confirmed At' },
  { key: 'cancelled_at', name: 'Cancelled At' }
];

const CORE_FIELDS = {
  global: [
    { key: 'created_at', name: 'Created At' },
    { key: 'updated_at', name: 'Updated At' },
    { key: 'archived', name: 'Archived' },
    { key: 'user_id', name: 'Owner' }
  ],
  // RESOURCES
  [entityTypes.CONTACT]: [
    ...resourceBaseFields,
    {
      key: 'bookings',
      name: 'Bookings',
      children: bookingBaseFields.map(f => ({
        key: `bookings.${f.key}`,
        name: f.name
      }))
    }
  ],
  // CALLOUTS
  [entityTypes.CALLOUT]: [
    { key: 'public_id', name: 'Public ID' },
    { key: 'status', name: 'Status' },
    { key: 'title', name: 'Title' },
    { key: 'description', name: 'Description' },
    { key: 'public_title', name: 'Public Title' },
    { key: 'public_description', name: 'Public Description' },
    { key: 'confirmation_text', name: 'Confirmation Text' },
    { key: 'enable_notifications', name: 'Enable Notifications' },
    { key: 'published', name: 'Published' },
    { key: 'deadline', name: 'Deadline' },
    //['mappings', name: 'Mappings'},
    { key: 'published_at', name: 'Published At' }
  ],
  // SHORTLISTS
  [entityTypes.SHORTLIST]: [
    { key: 'public_id', name: 'Public ID' },
    { key: 'status', name: 'Status' },
    { key: 'title', name: 'Title' },
    { key: 'description', name: 'Description' },
    { key: 'public_title', name: 'Public Title' },
    { key: 'public_description', name: 'Public Description' },
    { key: 'public', name: 'Public' },
    { key: 'expanded', name: 'Expanded' },
    { key: 'enable_notifications', name: 'Enable Notifications' },
    { key: 'published', name: 'Published' },
    { key: 'public_feedback', name: 'Public Feedback' },
    { key: 'layout', name: 'Layout' },
    { key: 'enable_voting', name: 'Enable Voting' },
    { key: 'enable_commenting', name: 'Enable Commenting' },
    //['groups', name: 'Groups'}, // UNUSED ? But should be the same as resources
    //["default_fields", name: 'Default Fields'},
    //["shortlist_type_id", name: 'Shortlist Type'},
    //['show_availability', name: 'Show Availability'},
    //['shortlist_blocks', name: 'Shortlist Blocks'},
    { key: 'published_at', name: 'Published At' }
  ],
  // CALLSHEETS
  [entityTypes.CALLSHEET]: [
    { key: 'public_id', name: 'Public ID' },
    { key: 'status', name: 'Status' },
    { key: 'title', name: 'Title' },
    { key: 'description', name: 'Description' },
    { key: 'note', name: 'Note' },
    { key: 'billing', name: 'Billing' },
    { key: 'social', name: 'Social' },
    { key: 'hard_drive', name: 'Hard Drive' },
    { key: 'timezone', name: 'Timezone' },
    { key: 'public', name: 'Public' },
    { key: 'published', name: 'Published' },
    { key: 'enable_notifications', name: 'Enable Notifications' },
    //["production_date", name: 'Production Date'},
    { key: 'from_date', name: 'From Date' },
    { key: 'to_date', name: 'To Date' },
    { key: 'call_time', name: 'Call Time' },
    //["points_of_contact","Points of Contact"],
    //["locations","Locations"],
    //["schedule","Schedule"],
    //["production_id","Production"],
    //["team_members","Team Members"],
    //["recipients","Recipients"],
    //["attachments","Attachments"]
    { key: 'published_at', name: 'Published At' }
  ],
  // PRODUCTIONS
  [entityTypes.PRODUCTION]: [
    ...productionBaseFields,
    {
      key: 'resource_slots',
      name: 'Resource Slots',
      children: [
        { key: 'resource_slots.group_id', name: 'Group' },
        { key: 'resource_slots.slot_category_id', name: 'Category' },
        {
          key: 'resource_slots.resource_slot_assignments.events',
          name: 'Events'
        },
        {
          key: 'resource_slots.resource_slot_assignments.resource',
          name: 'Resource',
          children: resourceBaseFields.map(f => ({
            ...f,
            key: `resource_slots.resource_slot_assignments.resource.${f.key}`
          }))
        },
        {
          key: 'resource_slots.booking',
          name: 'Booking',
          children: bookingBaseFields.map(f => ({
            ...f,
            key: `resource_slots.resource_slot_assignments.booking.${f.key}`
          }))
        }
      ]
    }
  ],
  // BOOKINGS
  [entityTypes.BOOKING]: [
    ...bookingBaseFields,
    {
      key: 'resource',
      name: 'Resource',
      children: resourceBaseFields.map(f => ({
        key: `resource.${f.key}`,
        name: f.name
      }))
    },
    {
      key: 'resource_slot_assignments',
      name: 'Resource Slots',
      children: [
        {
          key: 'resource_slot_assignments.resource_slot.group_id',
          name: 'Group'
        },
        {
          key: 'resource_slot_assignments.resource_slot.slot_category_id',
          name: 'Category'
        },
        { key: 'resource_slot_assignments.events', name: 'Events' },
        {
          key: 'resource_slot_assignments.resource_slot.production',
          name: 'Production',
          children: productionBaseFields.map(f => ({
            ...f,
            key: `resource_slot_assignments.resource_slot.production.${f.key}`
          }))
        }
      ]
    }
  ]
};
export default CORE_FIELDS;
