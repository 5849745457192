import { Cloudinary } from '@cloudinary/url-gen';

export function convertVideoFileWithCloudinary(url: string) {
  if (!url) return '';

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'atellio-development'
    }
  });

  const video = cld.video(url).setDeliveryType('fetch');

  return video.format('mp4').toURL();
}

export function getVideoCoverImage(url) {
  if (!url) return '';

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'atellio-development'
    }
  });

  const regexPatterns = {
    youtube: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)/,
    hulu: /^(https?:\/\/)?(www\.)?hulu\.com/,
    vimeo: /^(https?:\/\/)?(www\.)?vimeo\.com/,
    animoto: /^(https?:\/\/)?(www\.)?animoto\.com/,
    worldstarhiphop: /^(https?:\/\/)?(www\.)?worldstarhiphop\.com/,
    dailymotion: /^(https?:\/\/)?(www\.)?dailymotion\.com/
  };

  const deliveryType = Object.entries(regexPatterns).find(([type, regex]) =>
    regex.test(url)
  )?.[0];

  const video = deliveryType
    ? cld.image(url).setDeliveryType(deliveryType)
    : cld.video(url).setDeliveryType('fetch');

  return video.format('jpg').quality('auto').toURL();
}
