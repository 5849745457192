import React, { Component } from 'react';
import classnames from 'classnames';
import { makeFileURL } from 'lib/s3/s3Helper';
import './PinComponents.scss';

export default class PinFile extends Component {
  renderExtension = (mime_type, name) => {
    const extension = mime_type ? mime_type.split('/').pop() : 'File';
    return (
      <div className={classnames(['PinItem-graphic', extension])}>
        {extension}
        <br />
        <span className="PinWebLink-link">{name}</span>
      </div>
    );
  };

  render() {
    const { file } = this.props.pin;
    const pinFileURL = makeFileURL(file) || this.props.pin.url;
    return (
      <a
        href={pinFileURL}
        target="_blank"
        rel="noopener noreferrer"
        className="PinFile"
      >
        {this.renderExtension(file.mime_type, file.name)}
      </a>
    );
  }
}
