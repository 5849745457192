import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _debounce from 'lodash/debounce';
import get from 'lodash/get';

import { TextInput } from 'v1/components/shared';

import './SearchInput.scss';

// TODO: this could have a "clear" button
const SearchInput = ({
  className,
  inputClassName,
  query,
  placeholder,
  name,
  size,
  appearance,
  initialFocus,
  onChange,
  onSearch,
  debounce
}) => {
  // STATE
  const [searchQuery, setSearchQuery] = useState(get(query, 'query', ''));

  // EFFECTS
  useEffect(() => {
    setSearchQuery(query.query);
  }, [query.query, setSearchQuery]);

  // FUNCTIONS
  // This is required to update the debounce function if the query object changes in its parent component
  const onSearchDebounced = useCallback(_debounce(onSearch, debounce), [query]);

  const onInputChange = value => {
    setSearchQuery(value);
    onChange(value);
    debounce ? onSearchDebounced(value) : onSearch(value);
  };

  return (
    <div className={classnames('SearchInput', className)}>
      <img src="/images/icon_search.svg" className="SearchInput-icon" alt="" />
      <TextInput
        name={name || 'query'}
        className={classnames('SearchInput-input', inputClassName)}
        appearance={appearance}
        size={size}
        field="query"
        value={searchQuery}
        onChange={e => onInputChange(e.target.value)}
        placeholder={placeholder}
        removeAutocomplete={true}
        initialFocus={initialFocus}
        highlightOnFocus
        tabIndex={1}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  query: '',
  placeholder: 'Search...',
  debounce: 0,
  onChange: () => {},
  onSearch: () => {}
};

SearchInput.propTypes = {
  query: PropTypes.object, // Full query object
  className: PropTypes.string,
  size: PropTypes.oneOf(['S', 'M', 'L']),
  appearance: PropTypes.any, // TODO
  initialFocus: PropTypes.bool,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  debounce: PropTypes.number, // TODO: can we change this to a boolean with a standard debounce?
  onChange: PropTypes.func.isRequired, // TODO: this is never used?
  onQueryChange: PropTypes.any
};

export default SearchInput;
