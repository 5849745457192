import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { PressStud } from 'v1/components/shared';
import { Loading } from 'v1/components/shared';
import ProductionSidebarDrawer from 'v1/components/feature/ProductionComponents/ProductionSidebarDrawer/ProductionSidebarDrawer';
import BookingSidebarDrawer from 'v1/components/feature/BookingComponents/BookingSidebarDrawer/BookingSidebarDrawer';
import ResourceSidebarDrawer from 'v1/components/feature/ResourceComponents/ResourceSidebarDrawer/ResourceSidebarDrawer';

import { hasCapability, hasFlag } from 'lib/restrictedAccess';
import { selectActiveFeatureFlags } from 'store/teams';
import { selectActiveCapabilities } from 'store/userGroups';
import {
  toggleSidebar,
  closeContactSidebar,
  closeProductionSidebar,
  closeBookingSidebar
} from 'store/v1/ui/ui.actions.js';
import AuthService from 'lib/auth/AuthService';
import LocalStorage from 'lib/storage/LocalStorage';
import LogoutOptions from 'lib/auth/LogoutOptions';
import Account from 'lib/auth/Account';
import mobile from 'is-mobile';
import classnames from 'classnames';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import './PrivateRoute.scss';
import Sidebar from 'v4/core/sidebar/Sidebar.connected';
import { TailwindRoot } from '~/v4/shared/TailwindRoot';
import SettingsSidebar from 'v4/core/sidebar/SettingsSidebar.connected';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.sessionTimeout = null; // timeout for token renewal
    this.state = {
      isMobile: mobile(),
      authToken: AuthService.token()
    };
  }

  componentDidMount() {
    if (this.isSidebarCollapsed()) {
      this.props.toggleSidebar(true);
    }
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (this.props.location.pathname !== nextProps.location.pathname) {
      if (this.props.ui.showContactSidebar) this.props.closeContactSidebar();
      if (this.props.ui.showProductionSidebar)
        this.props.closeProductionSidebar();
    }
    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        if (nextProps.auth.error) {
          this.props.history.replace('/logindenied');
        }
      }
    }
    if (this.props.ui.sidebarCollapsed !== nextProps.ui.sidebarCollapsed) {
      if (nextProps.ui.sidebarCollapsed) {
        LocalStorage.collection('settings').insert({
          type: 'sidebarCollapsed'
        });
      } else {
        LocalStorage.collection('settings').remove({
          type: 'sidebarCollapsed'
        });
      }
    }
  };

  /**
   * Returns true if the sidebar should be collapsed
   * @returns {boolean}
   */
  isSidebarCollapsed = () => {
    return !!LocalStorage.collection('settings').findOne({
      type: 'sidebarCollapsed'
    });
  };

  componentWillUnmount() {
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
    }
  }

  renderMobileDisclaimer = () => {
    if (window.location.pathname.includes('/dashboard')) {
      return (
        <div className="MobileDisclaimer">
          <h3>We've noticed you're on your phone</h3>
          <p>
            Atellio is best experienced on desktop or laptop. Here's a little
            preview, but be sure to check out the site when you're back at your
            desk. Our mobile experience is coming soon.
          </p>
          <PressStud
            label="Got it, thanks"
            action={() => this.setState({ isMobile: false })}
          />
        </div>
      );
    }
  };

  renderBookingSidebar = () => {
    const { ui } = this.props;
    const { showBookingSidebar, selectedBooking, bookingSidebarOptions } = ui;

    return (
      <BookingSidebarDrawer
        isOpen={showBookingSidebar}
        closeSidebar={this.props.closeBookingSidebar}
        booking={selectedBooking}
        options={bookingSidebarOptions}
      />
    );
  };

  renderProductionSidebar = () => {
    const {
      showProductionSidebar,
      selectedProduction,
      productionSidebarOptions
    } = this.props.ui;

    return (
      <ProductionSidebarDrawer
        isOpen={showProductionSidebar}
        closeSidebar={this.props.closeProductionSidebar}
        production={selectedProduction}
        options={productionSidebarOptions}
      />
    );
  };

  renderRoute = routeProps => {
    const {
      component: RouteComponent,
      fullpage,
      flag,
      capabilities,
      auth
    } = this.props;

    if (!auth.loggedIn && this.state.authToken) {
      return <Loading className="pageCenter" />;
    }

    // Redirect if the user is not logged in
    if (!AuthService.isAuthenticated()) {
      console.log('user not logged in, redirecting to login');
      const options = new LogoutOptions();
      options.clearPreferences = false;
      AuthService.logout(options);
      return <Redirect to="/login" />;
    }

    const workspaces = Account.workspaces();
    if (workspaces.length === 0) {
      console.log('account does not belong to any workspace');
      return <Redirect to="/unauthorized" />;
    }

    // Select the workspace specified in the URL otherwise the first workspace available
    const urlAppID = routeProps.match.params.app_id;
    const workspace = Account.resolveWorkspace(urlAppID) || workspaces[0];
    // TODO: Ensure user cannot see a workspace which they arent a part of
    // if (!workspace) {
    //   console.log(`workspace not found: ${urlAppID}`);
    //   return <Redirect to="/unauthorized" />;
    // }

    const workspaceSchema = workspace.db_schema;

    // Ensure that the user workspace has the required feature flag if the route specifies a flag requirement
    if (flag && !hasFlag(this.props.activeFeatureFlags, flag)) {
      console.log(`workspace does not have feature flag: ${flag}`);
      return <Redirect to="/unauthorized" />;
    }

    // If the user is super user, skip role and capabilities checking
    const isSuperUser = Account.isSuperUser();
    if (!isSuperUser) {
      // If the route specifies a required capability, check that the role has it
      if (capabilities) {
        let capabilitiesToCheck = Array.isArray(capabilities)
          ? capabilities
          : [capabilities];
        for (let i = 0; i < capabilitiesToCheck.length; i++) {
          const ok = !!hasCapability(
            this.props.activeCapabilities,
            capabilitiesToCheck[i]
          );
          if (!ok) {
            console.log(
              `user does not have permission required ${capabilitiesToCheck[i]}`
            );
            return <Redirect to="/unauthorized" />;
          }
        }
      }
    }

    if (!RouteComponent) {
      return <Redirect to={`/app/${workspaceSchema}/dashboard`} />;
    }
    return fullpage ? (
      <Suspense fallback={<Loading />}>
        <RouteComponent {...routeProps} />
      </Suspense>
    ) : (
      <div className="PrivateRoute">
        <TailwindRoot>
          <Switch>
            <Route path="/app/settings" component={SettingsSidebar} />
            <Route component={Sidebar} />
          </Switch>
        </TailwindRoot>
        <div
          className={classnames(
            { collapsed: this.props.ui.sidebarCollapsed === true },
            ['Route']
          )}
        >
          <Suspense fallback={<Loading />}>
            <RouteComponent {...routeProps} />
          </Suspense>
        </div>
        {this.renderProductionSidebar()}
        {this.renderBookingSidebar()}
        <ResourceSidebarDrawer />
        {this.state.isMobile && this.renderMobileDisclaimer()}
      </div>
    );
  };

  render() {
    const { component: RouteComponent, fullpage, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }
}

function mapStateToProps(state) {
  const { auth, ui, events } = state;
  return {
    auth,
    activeFeatureFlags: selectActiveFeatureFlags(state),
    activeCapabilities: selectActiveCapabilities(state),
    ui,
    events
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleSidebar,
      closeContactSidebar,
      closeProductionSidebar,
      closeBookingSidebar
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
