import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { orderBy } from 'lodash';

import { RootStore } from 'store/reducer';

const selectTagsStore = (state: RootStore) => state.tags;

const selectTags = createSelector(selectTagsStore, tags => tags.data);

// Tags are always ordered alphabetically
const selectTagsList = createSelector(selectTags, data =>
  orderBy(
    Object.keys(data).map(key => data[key as any]),
    'tag'
  )
);

const selectTagById = createCachedSelector(
  selectTags,
  (_: RootStore, id: number) => id,
  (data, id) => data[id]
)((_, id) => id);

export { selectTagsStore, selectTags, selectTagsList, selectTagById };
