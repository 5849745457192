import React, { Component } from 'react';

import classNames from 'classnames';
import { merge, isEmpty } from 'lodash';
import { v4 } from 'uuid';

import './ContactSuggesterItem.scss';

export default class ContactSuggesterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false
    };
  }
  componentDidMount() {
    this.setState({ saved: this.checkAdded() });
  }
  checkAdded = () => {
    try {
      switch (this.props.field) {
        case 'websites':
          return !!this.props.contact.websites.find(i => {
            const regex = /http[s]?:|\/|www?.|\?(.*)/g;
            return (
              this.props.value.toLowerCase().replace(regex, '') ==
              i.website.toLowerCase().replace(regex, '')
            );
          });
        case 'emails':
          return !!this.props.contact.emails.find(
            i => i.email.toLowerCase() === this.props.value.toLowerCase()
          );
        case 'profile_picture':
          return (
            this.props.contact.profile_picture.toLowerCase() ==
            this.props.value.toLowerCase()
          );
        case 'phone_numbers':
          return !!this.props.contact.phone_number.find(
            i => i.phone_number.toLowerCase() === this.props.value.toLowerCase()
          );
        case 'socials':
          return !!this.props.contact.socials.find(
            i =>
              i.handle.toLowerCase() ===
                this.props.value.handle.toLowerCase() &&
              i.provider.toLowerCase() ==
                this.props.value.provider.toLowerCase()
          );
        default:
          return !isEmpty(this.props.contact[this.props.field]);
      }
    } catch (err) {
      return false;
    }
  };
  onAdd = () => {
    let method = this.props.type === 'ARRAY' ? 'CREATE' : null;
    let value;
    switch (this.props.field) {
      case 'websites':
        value = { id: `temp-${v4()}`, website: this.props.value };
        method = 'CREATE';
        break;
      case 'emails':
        value = { id: `temp-${v4()}`, email: this.props.value };
        method = 'CREATE';
        break;
      case 'phone_numbers':
        value = { id: `temp-${v4()}`, phone_number: this.props.value };
        method = 'CREATE';
        break;
      case 'socials':
        value = merge({ id: `temp-${v4()}` }, this.props.value);
        method = 'CREATE';
        break;
      default:
        value = this.props.value;
    }
    this.setState({ adding: true, value }, () => {
      this.props.onSuggestUpdate(this.props.field, value, method);
    });
  };
  onRemove = () => {
    this.setState({ adding: false }, () => {
      this.props.onSuggestUpdate(this.props.field, this.state.value, 'DELETE');
    });
  };
  renderState = () => {
    if (this.state.saved) {
      return (
        <span className="ContactSuggester-suggestion-item-action-saved">
          Saved
        </span>
      );
    }
    if (this.state.adding) {
      return (
        <span className="ContactSuggester-suggestion-item-action-remove">
          Added
        </span>
      );
    }
    return (
      <span className="ContactSuggester-suggestion-item-action-add">
        <img src="/images/icon_default_plus.svg" width="10px" alt="" />
      </span>
    );
  };
  renderValueLabel = () => {
    const { value, field } = this.props;

    switch (field) {
      case 'socials':
        return value.handle;
      case 'profile_picture':
        return <img src={value} width="100px" alt="" />;
      default:
        return value;
    }
  };
  renderFieldLabel = () => {
    const { value, field } = this.props;

    switch (field) {
      case 'websites':
        return 'website';
      case 'socials':
        return value.provider;
      default:
        return field;
    }
  };
  render() {
    return (
      <div
        className={classNames([
          'ContactSuggesterItem ContactSuggester-suggestion-item',
          { selected: this.state.adding, saved: this.state.saved }
        ])}
        onClick={() => {
          if (this.state.saved) return null;
          !this.state.adding ? this.onAdd() : this.onRemove();
        }}
      >
        <div className="ContactSuggester-suggestion-item-content">
          <span className="label capitalise">{this.renderFieldLabel()}</span>
          <span className="value">{this.renderValueLabel()}</span>
        </div>
        <div className="ContactSuggester-suggestion-item-action">
          {this.renderState()}
        </div>
      </div>
    );
  }
}
