import React from 'react';
import classnames from 'classnames';

import Icons from 'svgs/icons';
import styles from './Icon.module.scss';

// Exported for Storybook
export const ICON_SIZES = ['s', 'm', 'l'] as const;

type IconProps = {
  /**
   * The name of the icon
   */
  name: SVGIconName;
  /**
   * The color of the icon. Defaults to the inherited text color
   * @default Inherited from text color
   */
  color?: CSSColor;
  /**
   * The size of the icon
   */
  size?: typeof ICON_SIZES[number];
};

const Icon = ({ name, color, size = 'm' }: IconProps) => {
  const SVG = Icons[name];
  return (
    <SVG
      className={classnames(styles.Icon, {
        [styles[`${color}`]]: color,
        [styles[size]]: size !== 'm'
      })}
    />
  );
};

export default Icon;
