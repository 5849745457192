import { formatEvent } from 'v1/helpers/dateHelper';
import { getEventDates } from 'v1/helpers/byModel/EventHelper';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const INITIAL_CONFLICTS_OBJECT = {
  production_events: [],
  resource_slots_events: [],
  bookings: [],
  isEmpty: true,
  hasFailed: false
};

// See https://github.com/Easle/Satie/issues/565
export function getProductionConflicts(production, prevProduction, bookings) {
  if (_isEmpty(production) || _isEmpty(prevProduction))
    throw Error('Production not provided');
  if (_isEmpty(bookings)) throw Error('Bookings not provided');

  // Object containing all production events, resource slot events and bookings
  // (including nested assignemnt/events) that have been effected by the
  // production date change
  const conflicts = { ...INITIAL_CONFLICTS_OBJECT };

  // Some quick date helpers
  const f = 'YYYY-MM-DD';
  const eq = (d1, d2) => moment(d1).isSame(d2, 'd');
  const gt = (d1, d2) => moment(d1).diff(d2, 'd') > 0;
  const lt = (d1, d2) => moment(d1).diff(d2, 'd') < 0;
  const gte = (d1, d2) => moment(d1).diff(d2, 'd') >= 0;
  const lte = (d1, d2) => moment(d1).diff(d2, 'd') <= 0;
  const ndays = (s, e) => moment(e).diff(s, 'd');
  const offset = (d1, d2) => moment(d1).diff(d2, 'd');
  const add = (d, l) => moment(d).add(l, 'd').format(f);
  // const contains = (s1, e1, s2, e2) => lte(s1, s2) && gte(e1, e2);
  const format = d => moment(d).format(f);

  // Current and previous production dates
  const [start, end] = getEventDates(production.production_date);
  const [prevStart, prevEnd] = getEventDates(prevProduction.production_date);

  // Production date has been reduced on the left or right.
  function getAdjustedEvent(evt) {
    const [evtStart, evtEnd] = getEventDates(evt);

    // Event is not overflowing neither left or right
    // if (!lt(evtStart, start) && !gt(evtEnd, end)) return newEvt;

    if (lt(evtEnd, start) || gt(evtStart, end))
      // Event is outside new production date
      // Set action property as "DELETE"
      return formatEvent({
        ...evt,
        start_date: format(evtStart),
        end_date: format(evtEnd),
        _action: 'DELETE'
      });
    else if (lt(evtStart, start) && gte(evtEnd, start) && lte(evtEnd, end))
      // Event start outside production but end inside
      // Just cut the beginning to fit the start of production
      return formatEvent({
        ...evt,
        start_date: format(start),
        end_date: format(evtEnd),
        _action: 'UPDATE'
      });
    else if (gte(evtStart, start) && lte(evtStart, end) && gt(evtEnd, end))
      // Event start in production but end outside
      // Just cut the end to fit the end of production
      return formatEvent({
        ...evt,
        start_date: format(evtStart),
        end_date: format(end),
        _action: 'UPDATE'
      });

    return { ...evt, _action: null };
  }

  /*
    Offset event dates by the number of days the production has been offsetted
    (Eg when a D&D hs been performed)
  */
  function getOffsettedEvent(evt) {
    const productionOffset = offset(start, prevStart);
    const [evtStart, evtEnd] = getEventDates(evt);
    return {
      ...evt,
      start_date: add(evtStart, productionOffset),
      end_date: add(evtEnd, productionOffset),
      _action: 'UPDATE'
    };
  }

  // Algorithm used to adjust production events
  // See https://github.com/Easle/Satie/issues/565 for different options
  // let adjustToProduction = () => {};

  // Production date hasn't been changed
  if (eq(start, prevStart) && eq(end, prevEnd)) return conflicts;
  // Production date has been expanded on left (no internal events are affected)
  if (lte(start, prevStart) && eq(end, prevEnd)) return conflicts;
  // Production date has been expanded on right (no internal events are affected)
  if (gte(end, prevEnd) && eq(start, prevStart)) return conflicts;

  // Has production date been offsetted? (Drag&Drop)
  const isOffset = ndays(start, end) === ndays(prevStart, prevEnd);

  /*
    Checks all the events within a booking and change them accordingly (see adjust functions),
    returning  a new booking object with updated events and a new action property for
    events, assignment and production.
    Example object: {
      action: 'UPDATE',
      resource_slot_assignments: [
        {
          _action: 'UPDATE',
          events: [{ ...e, _action: 'UPDATE' }, { ...e, _action: 'DELETE' }]
        },
        {
          _action: 'DELETE',
          events: [{ ...e, _action: 'DELETE' }, { ...e, _action: 'DELETE' }]
        },
        {
          _action: null,
          events: [{ ...e, _action: null }, { ...e, _action: null }]
        }
      ]
    };

    The new "_action" property can have the following values on events:
     null: no changes applied
     UPDATE: event has new dates (clipped)
     DELETE: event still has previous date but shold be deleted (out of production)

    On assignments (calculated programmatically from each "_action" value of
    the assignemnt events):
     null: no changes applied on the assignment events array
     UPDATE: some changes applied to the assignment event array
     DELETE: all assigment events have action == DELETE

     On booking, same as assignment:
     null: no changes applied on the resource_slot_assignments array
     UPDATE: some changes applied to resource_slot_assignments array
     UPDATE: all resource_slot_assignments have action == DELETE

   */
  function getFormattedBooking(booking) {
    // See above on how action is calculated for assignments & bookings
    function calculateAction(arr) {
      if (arr.every(e => e._action === 'DELETE')) return 'DELETE';
      if (arr.every(e => e._action === null)) return null;
      return 'UPDATE';
    }

    let newBookingEvents = [..._get(booking, 'events', [])];

    let getEventFn = getAdjustedEvent;
    if (isOffset) {
      // We need to check wheter the booking contains assignemnts from other
      // productions. If so, we can't safely perform an offset of the events
      // as it might conflicts with others productions. Let the user decide later
      const hasOtherProductions = booking.resource_slot_assignments.find(
        assigment => assigment.resource_slot.production_id !== production.id
      );
      if (!hasOtherProductions) {
        getEventFn = getOffsettedEvent;
        newBookingEvents = booking.events.map(getOffsettedEvent);
      }
    }

    const newAssignments = booking.resource_slot_assignments.map(assignment => {
      if (assignment.resource_slot.production_id === production.id) {
        // Get all events adjusted with "action" property
        const newEvents = assignment.events.map(getEventFn);
        return {
          ...assignment,
          events: newEvents,
          _action: calculateAction(newEvents)
        };
      }
      // If assignment is from a different produciton, no changes needed
      return assignment;
    });
    return {
      ...booking,
      events: newBookingEvents,
      resource_slot_assignments: newAssignments,
      _action: calculateAction(newAssignments)
    };
  }

  // Adjust production events (event_type = PRODUCTION_SCHEDULE, PRODUCTION_MILESTONE)
  conflicts.production_events = production.events.reduce((result, e) => {
    const newEvent = isOffset ? getOffsettedEvent(e) : getAdjustedEvent(e);
    // If action object is null, no changes has been applied
    return newEvent._action ? result.concat(newEvent) : result;
  }, conflicts.production_events);

  production.resource_slots.forEach(resource_slot => {
    // Adjust resource slots events (event_type = RESOURCE_SLOT)
    conflicts.resource_slots_events = resource_slot.events.reduce(
      (result, e) => {
        const newEvent = isOffset ? getOffsettedEvent(e) : getAdjustedEvent(e);
        return newEvent._action ? result.concat(newEvent) : result;
      },
      conflicts.resource_slots_events
    );

    // Get all bookings and analyse each separately
    const formattedBookings = resource_slot.resource_slot_assignments.reduce(
      (result, { booking_id }) => {
        // Booking data (bookings reducer) is needed as it might contains eventual
        // other assignments from other productions
        if (booking_id && !bookings.data[booking_id])
          throw Error('Booking data not found');
        // Safe check
        if (!booking_id || (booking_id && !bookings.data[booking_id]))
          return result;
        // Don't duplicate booking
        if (result.find(b => b.id == booking_id)) return result;

        const formattedBooking = getFormattedBooking(bookings.data[booking_id]);

        return formattedBooking._action
          ? result.concat(formattedBooking)
          : result;
      },
      []
    );
    conflicts.bookings = conflicts.bookings.concat(formattedBookings);
  });

  return {
    ...conflicts,
    isEmpty:
      conflicts.production_events.length === 0 &&
      conflicts.resource_slots_events.length === 0 &&
      conflicts.bookings.length === 0
  };
}

// Wrapper around above function. Handles error catching.
export function tryAndGetProductionConflicts(...params) {
  try {
    return getProductionConflicts(...params);
  } catch (err) {
    console.error(
      'The following error has occurred while generating production conflicts.'
    );
    console.error(err);
    return { ...INITIAL_CONFLICTS_OBJECT, hasFailed: true };
  }
}
