import { TASKS_ACTIONS } from 'store/tasks/tasks.constants.js';
import { TASK_LISTS_ACTIONS } from 'store/task_lists/task_lists.constants.js';

import get from 'lodash/get';

export const INITIAL_STATE = {
  data: {},
  newTaskList: {},
  paging: {},
  order: [],
  loading: false
};

export default function reducer(state = INITIAL_STATE, action) {
  const result = get(action, 'result.data', {});

  switch (action.type) {
    case TASKS_ACTIONS.CREATE_TASK:
    case TASKS_ACTIONS.UPDATE_TASK:
    case TASKS_ACTIONS.DELETE_TASK:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case TASK_LISTS_ACTIONS.GET_TASK_LIST_SUCCESS:
      let updatedData = { ...state.data };

      // Get tasks from task list response object and add those tasks into the data store
      const tasksFromTaskList = result.tasks || [];

      tasksFromTaskList.map(task => {
        updatedData = {
          ...updatedData,
          [task.taskId]: task
        };
      });

      return {
        ...state,
        data: updatedData,
        order: Object.keys(updatedData),
        loading: false
      };
    case TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [result.taskId]: { ...action.task, taskId: result.taskId }
        },
        order: [...state.order, result.taskId],
        loading: false
      };
    case TASKS_ACTIONS.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.task.taskId]: action.task
        },
        loading: false
      };
    case TASKS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [result.taskId]: {
            ...action.task,
            id: result.taskId,
            taskListId: action.taskListId
          }
        },
        order: [...state.order, result.taskId],
        loading: false
      };
    case TASKS_ACTIONS.CREATE_TASK_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case TASKS_ACTIONS.DELETE_TASK_SUCCESS:
      const { [action.taskId]: item, ...data } = state.data;
      return {
        ...state,
        data,
        order: state.order.filter(id => id !== action.taskId),
        loading: false
      };
    case TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST_SUCCESS:
      let updatedTasks = state.data;
      action.representationOrder.map(item => {
        const taskInStore = updatedTasks[item.taskId];
        if (taskInStore) {
          updatedTasks[item.taskId] = {
            ...taskInStore,
            order: item.order
          };
        }
      });
      return {
        ...state,
        data: updatedTasks,
        loading: false
      };
    case TASKS_ACTIONS.GET_TASKS_FROM_TASK_LIST_FAILURE:
    case TASKS_ACTIONS.UPDATE_TASK_FAILURE:
    case TASKS_ACTIONS.CREATE_TASK_FAILURE:
    case TASKS_ACTIONS.DELETE_TASK_FAILURE:
    case TASKS_ACTIONS.CREATE_TASK_LIST_FAILURE:
    case TASKS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
