import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paging } from '__types__';
import { Tag } from '__types__/v1';
import { getTags, createTag, updateTag, deleteTag } from './tags.actions';

export type TagsStoreData = { [key: number]: Tag };

export type TagsStore = {
  data: TagsStoreData;
  order: number[];
  paging?: Paging;
  fetched?: boolean;
  loading?: boolean | string;
  // TODO: TS: any
  error?: any;
};

export const INITIAL_STATE: TagsStore = {
  data: {},
  paging: {},
  order: [],
  fetched: false,
  loading: false
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        getTags.fulfilled,
        (
          state,
          action: PayloadAction<{
            results: Tag[];
            paging: Paging;
          }>
        ) => {
          const { payload } = action;
          state.data = payload.results.reduce(
            (data: TagsStoreData, item: Tag) => ({ ...data, [item.id]: item }),
            {}
          );
          state.order = payload.results.map((item: Tag) => item.id);
          state.paging = payload.paging;
          state.fetched = true;
        }
      )
      .addCase(
        createTag.fulfilled,
        (state, { payload }: PayloadAction<Tag>) => {
          state.data[payload.id] = payload;
          state.order.push(payload.id);
        }
      )
      .addCase(
        updateTag.fulfilled,
        (state, { payload }: PayloadAction<Tag>) => {
          state.data[payload.id] = payload;
        }
      )
      .addCase(
        deleteTag.fulfilled,
        (
          state,
          { meta: { arg } }: PayloadAction<{ success: boolean }, any, any>
        ) => {
          delete state.data[arg];
          state.order = state.order.filter(i => i !== arg);
          if (state.paging && state.paging.total) {
            state.paging.total--;
          }
        }
      )

      // Generic
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default tagsSlice.reducer;
