import React from 'react';
import PropTypes from 'prop-types';

import { Notes } from 'v1/components/shared';
import {
  createProductionNote,
  deleteProductionNote
} from 'store/v1/productions/productions.actions.js';

const ProductionSidebarDrawerNotes = ({
  productionId,
  productionTitle,
  disabled
}) => (
  <div className="ProductionSidebarDrawerNotes SidebarDrawer-section">
    <h3>Notes</h3>
    <Notes
      entityId={productionId}
      entityType="productions"
      contentName={productionTitle}
      contentType="production"
      createNote={createProductionNote}
      deleteNote={deleteProductionNote}
      disabled={disabled}
    />
  </div>
);

ProductionSidebarDrawerNotes.propTypes = {
  productionId: PropTypes.number,
  productionTitle: PropTypes.string
};

export default ProductionSidebarDrawerNotes;
