import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Button } from 'v1/components/shared';
import { ButtonWithDropdown } from 'v1/components/shared';
import { SystemLink } from 'v1/components/shared';
import { ResourceTitle, Grid, GridCell, ListCell } from 'v1/components/shared';

// IMPORTS
import classnames from 'classnames';

import './CellResource.scss';

const CellResource = ({
  item = {},
  onCellClick,
  actions,
  className,
  dragRef,
  isItemSelected
}) => {
  function onCellClickHandler() {
    onCellClick && onCellClick(item);
  }
  function renderName() {
    return (
      <GridCell>
        <ResourceTitle resource={item} navigateToMethod="SIDEBAR" />
      </GridCell>
    );
  }
  function renderAction() {
    if (!actions) return;

    if (actions.length > 1) {
      return (
        <GridCell width="auto">
          <ButtonWithDropdown
            className={classnames([
              'btn',
              'btn-small',
              isItemSelected ? 'btn-default' : 'btn-primary'
            ])}
            arrowShade={isItemSelected ? 'dark' : 'light'}
            actions={actions}
            stopPropagation
          />
        </GridCell>
      );
    }
    return (
      <GridCell width="auto">
        {actions[0].action && (
          <Button
            dataCy="add-resource"
            className={classnames([
              'btn',
              'btn-small',
              isItemSelected ? 'btn-default' : 'btn-primary'
            ])}
            onClick={() => {
              actions[0].action(item);
            }}
            stopPropagation
          >
            {actions[0].text}
          </Button>
        )}
        {actions[0].link && (
          <SystemLink
            className={classnames(['btn', 'btn-default', 'btn-small'])}
            to={actions[0].link}
            target={actions[0].target}
            stopPropagation
          >
            {actions[0].text}
          </SystemLink>
        )}
      </GridCell>
    );
  }
  function renderDrag() {
    return (
      <GridCell className="CellResource-drag" data-tip="Drag to Order">
        <img src="/images/icon_drag.svg" alt="" />
      </GridCell>
    );
  }
  return (
    <ListCell
      className={classnames(['CellResource', className], {
        draggable: dragRef,
        previewClickable: onCellClick
      })}
      data-id={dragRef}
      onClick={onCellClick && onCellClickHandler}
    >
      <Grid vcenter>
        {dragRef && renderDrag()}
        {renderName()}
        {renderAction()}
      </Grid>
    </ListCell>
  );
};

CellResource.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  dragRef: PropTypes.string,
  onCellClick: PropTypes.func,
  isItemSelected: PropTypes.bool,
  actions: PropTypes.array,
  showContextualActions: PropTypes.bool,
  options: PropTypes.object,
  assignmentRelations: PropTypes.object
};

export default CellResource;
