import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router';
import map from 'v1/helpers/alertErrors';
import classnames from 'classnames';
import { values } from 'lodash';
import './Alert.scss';

class Alert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: {},
      isEntering: false,
      isExisting: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.alert.id !== nextProps.alert.id && nextProps.alert.id) {
      this.setState({ alert: nextProps.alert, isEntering: false }, () => {
        this.displayAlert();
      });
    }
  }

  displayAlert = () => {
    this.setState({ isEntering: true }, () => {
      setTimeout(this.wipeAlert, 3400);
    });
  };
  renderIcon = alertType => {
    switch (alertType) {
      case 'success':
        return (
          <img
            className="Alert-icon"
            src="/images/icon_tick_small.svg"
            alt=""
          />
        );
      case 'error':
        return (
          <img
            className="Alert-icon"
            src="/images/icon_cross_small.svg"
            alt=""
          />
        );
      default:
        return (
          <img
            className="Alert-icon"
            src="/images/icon_alert_small.svg"
            alt=""
          />
        );
    }
  };

  wipeAlert = () => this.setState({ isEntering: false });

  renderMessage() {
    const body = this.state.alert.body;
    if (typeof body === 'string') return map(body) || body;
    if (body && body.message) return map(body.message) || body.message;
    if (body && body.Message && body.errors) {
      const errs = values(body.errors);
      if (errs && errs.length) return errs[0];
    }
    return 'Sorry something has gone wrong';
  }
  render() {
    const { alertType, body } = this.state.alert;

    return (
      <div
        className={classnames(
          {
            collapsed: this.props.sidebarCollapsed,
            enter: this.state.isEntering
          },
          ['Alert']
        )}
      >
        <div className={`Alert-alert-container ${alertType}`}>
          {body ? this.renderMessage() : null}
          {body ? this.renderIcon(alertType) : null}
        </div>
      </div>
    );
  }
}

Alert.propTypes = {
  alert: PropTypes.shape({
    id: PropTypes.any,
    alertType: PropTypes.string,
    body: PropTypes.any // TODO: type
  }).isRequired,
  sidebarCollapsed: PropTypes.bool
};

export default Alert;
