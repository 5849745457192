import React, {
  Children,
  Fragment,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode
} from 'react';

type GroupElement = ReactElement<GroupProps, typeof Group>;

interface SeparatedProps {
  children: GroupElement | GroupElement[];
  divider: ReactElement;
}

function SeparatedParent({ children, divider }: SeparatedProps) {
  const result: ReactNode[] = [];

  Children.forEach(children, (group, index) => {
    if (isValidElement(group) && group.type === Group) {
      const groupChildren = Children.toArray(group.props.children);
      const groupFiltered = groupChildren.filter(isValidElement);
      if (result.length !== 0 && groupFiltered.length > 0) {
        result.push(<Fragment key={index + '-divider'}>{divider}</Fragment>);
      }
      if (groupFiltered.length > 0) {
        result.push(<Fragment key={index + '-group'}>{group}</Fragment>);
      }
    } else {
      throw new Error(
        'Separated component must only contain Separated.Group components'
      );
    }
  });

  return result;
}

export type GroupProps = PropsWithChildren;

function Group(props: GroupProps) {
  return props.children;
}

const Separated = Object.assign(SeparatedParent, { Group });

export default Separated;
