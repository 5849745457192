import React from 'react';
import PropTypes from 'prop-types';

import TypeTextInput from 'v1/components/shared/byType/text/TextInput/__types__/TypeTextInput';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import { TextInput } from 'v1/components/shared';

interface NumberInputProps extends Omit<TextInputProps, 'onChange' | 'onBlur'> {
  onChange?(value: number | null): void;
  onBlur?(value: number | null): void;
}

const NumberInput = ({ onBlur, onChange, ...props }: NumberInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const parseValue = (value: string | null): number | null => {
    return value ? parseFloat(value) : null;
  };

  const handleBlur = (value: string): void => {
    onBlur && onBlur(parseValue(value));
  };

  const handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(parseValue(value));
  };

  return (
    <TextInput
      type="number"
      removeAutocomplete
      onBlur={handleBlur}
      onChange={handleChange}
      {...rest}
    />
  );
};

NumberInput.propTypes = {
  ...TypeTextInput,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
  // Inherits standard TextInput & HTML input properties, eg step, min, max, etc
};

export default NumberInput;
