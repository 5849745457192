/**
 * A mixpanel event is used to track events happening within Atellio that should be sent
 * to Mixpanel
 */
class MixpanelEvent {
  /**
   * Mixpanel action name
   * @type {string|null}
   */
  action = null;

  /**
   * The ID of the tenant which generated the event
   * @type {string|null}
   */
  tenantId = null;

  /**
   * Workspace ID for which the event originated from
   * @type {string|null}
   */
  workspaceId = null;

  /**
   * The ID of the account which generated the event
   * @type {string|null}
   */
  accountId = null;

  /**
   * The timestamp at which the event occurred
   * @type {string|Date|null}
   */
  dateCreated = null;

  /**
   * Mixpanel event data, also known as properties
   * @type {Object}
   */
  properties = {};

  /**
   * Create a new mixpanel event
   * @param {string} action
   * @param {Object} properties
   */
  constructor(action, properties = {}) {
    this.action = action;
    const {
      tenantId,
      workspaceId,
      accountId,
      dateCreated,
      ...rest
    } = properties;
    if (tenantId) {
      this.tenantId = properties.tenantId;
    }
    if (workspaceId) {
      this.workspaceId = properties.workspaceId;
    }
    if (accountId) {
      this.accountId = properties.accountId;
    }
    if (dateCreated) {
      this.dateCreated = properties.dateCreated;
    } else {
      this.dateCreated = new Date();
    }
    this.properties = rest;
  }
}

export default MixpanelEvent;
