import { Shortlist_normalized } from '__types__/index';
import { ShortlistEffect } from 'v4/entities/shortlist/shortlist.effect.types';

export const shortlistEffectReducer = (
  state: Shortlist_normalized,
  action: ShortlistEffect
): Shortlist_normalized => {
  if (action.type === 'update') {
    return {
      ...state,
      ...action.payload
    };
  }
  return state;
};
