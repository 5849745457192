//
// Helpers for the ProductionBudgetExpense object
//

import { formatCurrency } from 'v1/helpers/currencyHelper';

export const getExpenseSlotRate = slot => ({
  id: slot.id,
  name: 'Estimated rate',
  currency: slot.estimate_rate_currency,
  amount: slot.estimate_rate_amount,
  interval: slot.estimate_rate_interval,
  quantity: slot.estimate_rate_quantity,
  productionId: slot.production_id
});

// TODO: this is duplicated from ResourceSlotHelper
const getExpenseSlotRateTotal = rate => {
  return rate.amount && rate.quantity ? rate.amount * rate.quantity : 0;
};

const getExpenseSlotTotals = rate => {
  const estRateTotal = getExpenseSlotRateTotal(rate);
  return {
    rates: estRateTotal,
    total: estRateTotal
  };
};

export const getExpenseSlotTotalsFormatted = (slot, currency) => {
  const estRate = getExpenseSlotRate(slot);
  const estTotals = getExpenseSlotTotals(estRate);
  return {
    rates: formatCurrency(estTotals.rates, estRate.currency || currency),
    total: formatCurrency(estTotals.total, estRate.currency || currency),
    totalRaw: estTotals.total || 0
  };
};
