import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Account from 'lib/auth/Account';
import { RestrictedAccess } from 'lib/restrictedAccess';
import { Grid, GridCell } from 'v1/components/shared';

import styles from './Tabs.module.scss';

const Tabs = ({ tabs = [], activeTab, onSelect }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const workspace = Account.resolveFirstWorkspace() || {};
  const workspaceSchema = workspace.db_schema;

  const isActive = tab => {
    return (
      (tab.target && pathname.includes(tab.target)) ||
      (tab.id && activeTab === tab.id)
    );
  };

  const handleClick = tab => {
    onSelect && onSelect(tab.id);
    tab.target && history.push(`/app/${workspaceSchema}${tab.target}`);
  };

  const renderTab = tab => (
    <GridCell width="auto" onClick={() => handleClick(tab)}>
      <div
        className={classnames(styles.Tab, {
          [styles.Tab_active]: isActive(tab)
        })}
      >
        {tab.label}
        {tab.pill && (
          <div className={classnames('pill', styles.Pill)}>{tab.pill}</div>
        )}
      </div>
    </GridCell>
  );

  return (
    <Grid gutters="M" className="fullHeight scroll-x">
      {tabs.map((tab, index) => (
        <Fragment
          key={tab.id || (typeof tab.label === 'string' ? tab.label : index)}
        >
          {tab.flag || tab.capabilities ? (
            <RestrictedAccess flag={tab.flag} capabilities={tab.capabilities}>
              {renderTab(tab)}
            </RestrictedAccess>
          ) : (
            renderTab(tab)
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      pill: PropTypes.string, // Eg 'Beta'
      target: PropTypes.string, // Link destination, eg '/productions/4/summary'
      flag: PropTypes.string, // FeatureFlag.WHATEVER
      capabilities: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]) // Capability.WHATEVER
    })
  ).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func // Returns tab.id
};

export default Tabs;
