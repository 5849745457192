import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountDto } from 'dtos/account.dto';
import { Account } from '__types__';
import {
  getTenantAccounts,
  getTeamAccounts,
  updateAccount,
  archiveAccount,
  unarchiveAccount,
  addAccountToUserGroup,
  removeAccountFromUserGroup
} from './accounts.actions';
import { getAccountDisplayName } from './accounts.helpers';

export type AccountsStoreData = Record<string, Account>;

export type AccountsStore = {
  data: AccountsStoreData;
  // order: number[]; // TODO
  // paging?: Paging; // TODO
  fetched?: boolean;
  loading?: boolean | string; // TODO
  error?: any; // TODO
};

export const INITIAL_STATE: AccountsStore = {
  data: {},
  // paging: {},
  // order: [],
  fetched: false,
  loading: false
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(
        getTenantAccounts.fulfilled,
        (state, { payload }: PayloadAction<any[]>) => {
          state.data = payload.reduce(
            (data: AccountsStoreData, item: AccountDto) => ({
              ...data,
              [item.id]: { ...item, displayName: getAccountDisplayName(item) }
            }),
            {}
          );
          state.fetched = true;
        }
      )

      .addCase(
        getTeamAccounts.fulfilled,
        (state, { payload }: PayloadAction<any[]>) => {
          state.data = payload.reduce(
            (data: AccountsStoreData, item: AccountDto) => ({
              ...data,
              [item.id]: { ...item, displayName: getAccountDisplayName(item) }
            }),
            {}
          );
          state.fetched = true;
        }
      )

      .addCase(updateAccount.fulfilled, (state, { meta: { arg } }) => {
        const updatedAccount = {
          ...state.data[arg.id],
          ...arg.data,
          isAdmin:
            arg.data.isSuperUser !== undefined
              ? arg.data.isSuperUser
              : state.data[arg.id]?.isAdmin || false
        };
        state.data[arg.id] = {
          ...updatedAccount,
          displayName: getAccountDisplayName(updatedAccount)
        };
      })

      .addCase(archiveAccount.fulfilled, (state, { meta: { arg } }) => {
        state.data[arg].dateArchived = new Date();
      })

      .addCase(unarchiveAccount.fulfilled, (state, { meta: { arg } }) => {
        delete state.data[arg].dateArchived;
      })

      .addCase(addAccountToUserGroup.fulfilled, (state, { meta: { arg } }) => {
        state.data[arg.accountId].userGroupIds = [
          ...state.data[arg.accountId].userGroupIds,
          arg.userGroupId
        ];
      })

      .addCase(
        removeAccountFromUserGroup.fulfilled,
        (state, { meta: { arg } }) => {
          state.data[arg.accountId].userGroupIds = state.data[
            arg.accountId
          ].userGroupIds.filter(e => e !== arg.userGroupId);
        }
      )

      // Generic
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default accountsSlice.reducer;
