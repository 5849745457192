import { PRODUCTION_TEMPLATES_ACTIONS } from 'store/v1/production_templates/production_templates.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATES:
    case PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATE:
    case PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE:
    case PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE:
    case PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_ORDER:
    case PRODUCTION_TEMPLATES_ACTIONS.DELETE_PRODUCTION_TEMPLATE:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        fetched: true
      };
    case PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_ORDER_SUCCESS:
      return {
        ...state,
        data: action.result.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        order: action.result.map(item => item.id),
        loading: false
      };
    case PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        }
      };
    case PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        loading: false
      };
    case PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false,
        new: action.result
      };
    case PRODUCTION_TEMPLATES_ACTIONS.DELETE_PRODUCTION_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            archived: true
          }
        },
        order: state.order.filter(id => id !== action.id),
        loading: false
      };
    case PRODUCTION_TEMPLATES_ACTIONS.GET_PRODUCTION_TEMPLATES_FAILURE:
    case PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_FAILURE:
    case PRODUCTION_TEMPLATES_ACTIONS.CREATE_PRODUCTION_TEMPLATE_FAILURE:
    case PRODUCTION_TEMPLATES_ACTIONS.DELETE_PRODUCTION_TEMPLATE_FAILURE:
    case PRODUCTION_TEMPLATES_ACTIONS.UPDATE_PRODUCTION_TEMPLATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
