import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { clearContactDuplicates } from 'store/v1/contacts/contacts.actions.js';
import { CellResource, Grid, GridCell, PressStud } from 'v1/components/shared';

import styles from './ResourceDuplicates.module.scss';

const ResourceDuplicates = () => {
  const dispatch = useDispatch();
  const duplicates = useSelector(state => state.contacts.duplicates);
  const [showDuplicates, setShowDuplicates] = useState(false);

  useEffect(() => {
    setShowDuplicates(!isEmpty(duplicates));
  }, [duplicates]);

  // We don't want duplicates hanging around in the store when we close a modal
  useEffect(() => {
    return () => {
      dispatch(clearContactDuplicates());
    };
  }, []);

  const hideDuplicates = () => setShowDuplicates(false);

  if (!showDuplicates) {
    return null;
  }

  return (
    <div className={styles.ResourceDuplicates}>
      <div className={styles.details}>
        <Grid className="stack-XS">
          <GridCell>
            <h3>
              <img
                src="/images/icon_warning_black.svg"
                className={styles.icon}
                alt=""
              />
              Potential duplicate found
            </h3>
          </GridCell>
          <GridCell width="auto">
            <PressStud
              label="Dismiss"
              appearance="silent"
              size="S"
              action={hideDuplicates}
            />
          </GridCell>
        </Grid>
        {duplicates.map(resource => (
          <CellResource
            key={resource.id}
            item={resource}
            className={styles.resource}
            actions={[
              {
                text: 'View Profile',
                link: `/contacts/${resource.id}`,
                target: '_blank'
              }
            ]}
          />
        ))}
      </div>
    </div>
  );
};

ResourceDuplicates.props = {};

export default ResourceDuplicates;
