import React from 'react';
import PropTypes from 'prop-types';
import { length } from 'v1/helpers/measurements';
import MeasurementInput from '../MeasurementInput/MeasurementInput';
import './Dimension3DInput.scss';

/**
 * A dimension 3D input allows the user to input X, Y, and Z measurements.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Dimension3DInput = ({
  value,
  disabled,
  field,
  units,
  appearance,
  size,
  className,
  removeAutocomplete,
  onBlur,
  onChange
}) => {
  // Each measurement input expects an object value containing fields: amount and unit.
  // Before we render, we must parse the dimension JSON string into the three measurements.
  const values = JSON.parse(value || '{}');
  const xValue = values.x || {};
  const yValue = values.y || {};
  const zValue = values.z || {};

  // handleChange will convert the dimension value objects back into a JSON string for storage
  const handleChange = (fieldName, value) => {
    onChange(
      fieldName,
      JSON.stringify({
        x: xValue,
        y: yValue,
        z: zValue,
        [fieldName]: value
      })
    );
  };

  return (
    <div className={className}>
      <MeasurementInput
        disabled={disabled}
        field={field}
        value={xValue}
        onBlur={onBlur}
        units={units}
        onChange={value => handleChange('x', value)}
        appearance={appearance}
        size={size}
        removeAutocomplete={removeAutocomplete}
      />
      <span className="Dimension3DInput-separator">by</span>
      <MeasurementInput
        disabled={disabled}
        field={field}
        value={yValue}
        onBlur={onBlur}
        units={units}
        onChange={value => handleChange('y', value)}
        appearance={appearance}
        size={size}
        removeAutocomplete={removeAutocomplete}
      />
      <span className="Dimension3DInput-separator">by</span>
      <MeasurementInput
        disabled={disabled}
        field={field}
        value={zValue}
        onBlur={onBlur}
        units={units}
        onChange={value => handleChange('z', value)}
        appearance={appearance}
        size={size}
        removeAutocomplete={removeAutocomplete}
      />
    </div>
  );
};

Dimension3DInput.defaultProps = {
  units: [...length]
};

Dimension3DInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  appearance: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  units: PropTypes.array,
  removeAutocomplete: PropTypes.bool
};

export default Dimension3DInput;
