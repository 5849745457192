import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';

import ResourceFieldItem from '../ResourceFieldItem/ResourceFieldItem';
import { selectResourceTypeById } from 'store/v1/resource_types/resource_types.selectors';

const ResourceFieldsDisplay = ({
  layout = {},
  fieldMapper = {},
  fields = [],
  resource = {},
  hideLabel = false,
  unwrapped = false
}) => {
  const storeResource = useSelector(state =>
    get(state, ['contacts', resource.id])
  );
  const _resource = storeResource || resource;
  const resourceType = useSelector(state =>
    _resource.resource_type_id
      ? selectResourceTypeById(state, _resource.resource_type_id)
      : null
  );

  const renderItems = () =>
    fields
      .filter(
        field =>
          fieldMapper[field] &&
          (!resourceType ||
            !field.includes('custom') ||
            find(
              resourceType.metastructure.fields,
              f => field === `custom_${f.custom_field_definition_id}`
            ))
      )
      .map(field => {
        return (
          <ResourceFieldItem
            key={field}
            field={field}
            resource={_resource}
            fieldMapper={fieldMapper}
            layout={layout}
            hideLabel={hideLabel}
          />
        );
      });

  return unwrapped ? (
    renderItems()
  ) : (
    <div className="ResourceFieldsDisplay">{renderItems()}</div>
  );
};

ResourceFieldsDisplay.propTypes = {
  layout: PropTypes.shape({
    containerClass: PropTypes.string,
    width: PropTypes.string,
    fieldOverides: PropTypes.array
  }),
  resource: PropTypes.object,
  options: PropTypes.shape({}),
  fields: PropTypes.array,
  fieldMapper: PropTypes.object
};

export default ResourceFieldsDisplay;
