export default class Tracker {
  /**
   * The mixpanel client
   */
  mixpanelClient;

  /**
   *
   * @param {Object} mixpanelClient
   */
  constructor(mixpanelClient) {
    if (!mixpanelClient) {
      throw new Error('expected non-null mixpanel client');
    }
    this.mixpanelClient = mixpanelClient;
  }

  /**
   * Send a mixpanel event to mixpanel
   * @param {string} action
   * @param {MixpanelEvent} event
   * @param {Object} properties
   */
  sendEvent(action, event, properties) {
    const { tenantId, workspaceId, accountId } = event;
    this.mixpanelClient.track(action, {
      tenantId: tenantId,
      workspaceId: workspaceId,
      accountId: accountId,
      ...properties
    });
  }
}
