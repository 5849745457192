import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ListCellGroup,
  ListCell,
  Grid,
  GridCell,
  EmptyGeneric,
  ResourceDuplicates
} from 'v1/components/shared';

import {
  getApplicantName,
  renderApplicantFieldValue
} from 'v1/helpers/byModel/applicantHelper';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import classnames from 'classnames';

import './ApplicantReviewCarousel.scss';

const ApplicantReviewCarousel = ({ selectedApplicant = {}, callout }) => {
  const { mappings = [] } = callout;

  const [applicant, setApplicant] = useState({});
  const { data: applicantData } = applicant;

  const [isAnimating, setIsAnimating] = useState('animateForwardOut');

  useEffect(() => {
    if (!isEmpty(selectedApplicant)) {
      if (selectedApplicant.id > applicant.id) {
        animateForward();
      } else {
        animateBackward();
      }
      setTimeout(() => setApplicant(selectedApplicant), 300);
    }
  }, [selectedApplicant]);

  function animateForward() {
    setIsAnimating('animateForwardOut');
    setTimeout(() => {
      setIsAnimating('animateForwardIn');
    }, 300);
    setTimeout(() => {
      setIsAnimating('');
    }, 600);
  }

  function animateBackward() {
    setIsAnimating('animateForwardIn');
    setTimeout(() => {
      setIsAnimating('animateForwardOut');
    }, 300);
    setTimeout(() => {
      setIsAnimating('');
    }, 600);
  }

  return (
    <div className={classnames('ApplicantReviewCarousel', isAnimating)}>
      {!isEmpty(selectedApplicant) ? (
        <>
          <Grid className="ApplicantReviewCarousel-header inset-M">
            <GridCell className="text-center">
              <div className="text-11-600-eggplant">
                Applicant #{applicant.id} · Submitted{' '}
                {moment(applicant.created_at).fromNow()}
              </div>
              <h2>{getApplicantName(applicantData)}</h2>
            </GridCell>
          </Grid>
          <Grid direction="column" className="ApplicantReviewCarousel-content">
            <GridCell>
              {isAnimating === '' && <ResourceDuplicates />}
              <ListCellGroup className="ApplicantReviewCarousel-applicant">
                {mappings.map(map => (
                  <ListCell key={map.id}>
                    <Grid>
                      <GridCell width="3/12" className="text-13-600-eggplant">
                        {map.label}
                      </GridCell>
                      <GridCell className="text-13-700">
                        {renderApplicantFieldValue(
                          map.type,
                          get(applicantData, [map.id])
                        )}
                      </GridCell>
                    </Grid>
                  </ListCell>
                ))}
              </ListCellGroup>
            </GridCell>
          </Grid>
        </>
      ) : (
        <EmptyGeneric
          className="ApplicantReviewCarousel-empty"
          icon="/images/icon_colour_genericEmpty.svg"
          title="No more applicants"
          description="All reviewed"
        />
      )}
    </div>
  );
};

ApplicantReviewCarousel.propTypes = {
  selectedApplicant: PropTypes.object,
  callout: PropTypes.object.isRequired
};

export default ApplicantReviewCarousel;
