import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProductionStatusSelector } from 'v1/components/shared';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Capability } from '__types__';
import {
  RestrictedDropdown,
  MenuItem,
  MenuLink,
  Grid,
  GridCell
} from 'v1/components/shared';

import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import { getProductionResourceIds } from 'v1/helpers/productionHelper';
import ReactTooltip from 'react-tooltip';

import './ProductionSidebarDrawerHeader.scss';
import { useShortlistBatchAdd } from '~/v4/entities/common/hooks/useShortlistBatchAdd';

const ProductionSidebarDrawerHeader = ({ production, renderCoreField }) => {
  const [codeCopyState, setCodeCopyState] = useState();
  const { id, icon, code, title } = production;

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const batchAddToShortlist = useShortlistBatchAdd();

  useEffect(() => {
    ReactTooltip.rebuild();
    if (codeCopyState) {
      dispatch(displayAlert('success', 'Production code copied'));
      setTimeout(() => {
        setCodeCopyState(false);
      }, 500);
    }
  }, [codeCopyState]);

  return (
    <div className="SidebarDrawerHeader">
      <Grid vcenter gutters="S">
        {icon && (
          <GridCell width="auto" className="ProductionSidebarDrawerHeader-icon">
            <div className="ProductionSidebarDrawerHeader-icon">{icon}</div>
          </GridCell>
        )}
        <GridCell className="BookingSidebarDrawerHeader-main">
          <div className="ProductionSidebarDrawerHeader-title">
            <CopyToClipboard
              text={code || id}
              onCopy={() => setCodeCopyState(true)}
            >
              <span
                data-tip="Click to copy production id"
                className="ProductionSidebarDrawerHeader-id"
              >
                {code || id}
              </span>
            </CopyToClipboard>
            {code && !title ? (
              <h2>{code}</h2>
            ) : (
              renderCoreField('title', {
                appearance: 'silent',
                size: 'S',
                label: null
              })
            )}
          </div>
        </GridCell>
        <GridCell width="auto">
          <ProductionStatusSelector production={production} />
        </GridCell>
        <GridCell width="auto">
          <RestrictedDropdown
            capabilitiesAtLeastOne={[
              Capability.PRODUCTION_READ,
              Capability.PRODUCTION_UPDATE
            ]}
            buttonClass="SidebarDrawer-options btn btn-square btn-default"
            buttonIcon={<img src="/images/icon_options.svg" alt="" />}
          >
            <MenuLink
              capabilities={Capability.PRODUCTION_READ}
              as={Link}
              to={`/app/${auth.app_id}/productions/${id}`}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_new_window.svg" alt="" />
              </span>
              <span className="MenuItem-label">Go to full view</span>
            </MenuLink>
            <MenuItem
              capabilities={Capability.SHORTLIST_UPDATE}
              onSelect={() => {
                batchAddToShortlist(getProductionResourceIds(production));
              }}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_sidebar_shortlist.svg" alt="" />
              </span>
              <span className="MenuItem-label">Batch add to shortlist</span>
            </MenuItem>
            <MenuItem
              capabilities={Capability.PRODUCTION_UPDATE}
              onSelect={() => {
                dispatch(
                  openModal('ProductionSetUpModal', {
                    production,
                    jumpTo: 'details'
                  })
                );
              }}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_edit.svg" alt="" />
              </span>
              <span className="MenuItem-label">Edit Production</span>
            </MenuItem>
            <MenuItem
              capabilities={Capability.PRODUCTION_ARCHIVE}
              onSelect={() => {
                const options = {
                  title: `Are you sure you want to archive this production?`,
                  text: '',
                  button: 'Confirm Archive',
                  action: 'DELETE_PRODUCTION',
                  production_id: production.id,
                  stopRedirect: true
                };
                dispatch(openModal('ConfirmationModal', options));
              }}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_delete.svg" alt="" />
              </span>
              <span className="MenuItem-label">Archive Production</span>
            </MenuItem>
          </RestrictedDropdown>
        </GridCell>
      </Grid>
    </div>
  );
};

ProductionSidebarDrawerHeader.propTypes = {
  production: PropTypes.shape(), // TODO: type
  renderCoreField: PropTypes.func
};

export default ProductionSidebarDrawerHeader;
