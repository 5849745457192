import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FileUploader } from 'v1/components/shared';
import { MiniLoader } from 'v1/components/shared';
import Papa from 'papaparse';

import './CSVUploadContainer.scss';

class CSVUploadContainer extends Component {
  render() {
    return (
      <div className="CSVUploadContainer">
        <FileUploader
          renderContent={() => 'Click or drag to upload'}
          renderLoading={() => (
            <div className="ProfileImageUploader-FileUploader-loading">
              <MiniLoader isLoading={true} />
            </div>
          )}
          onFileCompleted={file => {
            Papa.parse(file.raw, {
              header: true,
              download: true,
              dynamicTyping: true,
              complete: results =>
                this.props.onUploadComplete(file, results.data)
            });
          }}
          accepts="text/csv"
          secure
        />
      </div>
    );
  }
}

CSVUploadContainer.propTypes = {
  onUploadComplete: PropTypes.func // TODO: arguments
};

export default CSVUploadContainer;
