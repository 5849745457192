import { get, isEmpty, omit } from 'lodash';
import { AssociationQuery } from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { NestedPath } from 'v4/entities/common/Path';

const removeIfEmpty = <T extends Record<string, unknown>>(
  obj: T,
  key: NestedPath<T>
) => {
  if (isEmpty(get(obj, key))) {
    return omit(obj, key) as T; // we lie here a bit, otherwise we should create a generic that will remove nested keys
  }
  return obj;
};
const normalizeAssociationQuery = (query: AssociationQuery | undefined) => {
  let q = query;
  if (!q) {
    return q;
  }
  q = removeIfEmpty(q, 'query.filters');
  q = removeIfEmpty(q, 'query.order_by');
  q = removeIfEmpty(q, 'query.query');
  q = removeIfEmpty(q, 'query');
  return q;
};
export const resourceShortlistKeys = {
  all: () => ['resource_shortlist'] as const,
  lists: () => [...resourceShortlistKeys.all(), 'lists'] as const,
  list: (query: AssociationQuery | undefined) => {
    return [
      ...resourceShortlistKeys.lists(),
      normalizeAssociationQuery(query)
    ] as const;
  }
};
