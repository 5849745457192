import { connect } from 'react-redux';
import { get } from 'lodash';

import Notes from './Notes.component';
import { displayAlert } from 'store/v1/ui/ui.actions.js';

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    notes: get(state, [
      ownProps.entityType,
      'associations_data',
      ownProps.entityId,
      'notes'
    ]),
    events: state.events
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createNote: (data, entityId, event) =>
      dispatch(ownProps.createNote(data, entityId, event)),
    deleteNote: (noteId, entityId, event) =>
      dispatch(ownProps.deleteNote(noteId, entityId, event)),
    displayAlert: (type, data) => dispatch(displayAlert(type, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
