import { TASK_LISTS_ACTIONS } from 'store/task_lists/task_lists.constants.js';
import { omit } from 'lodash';

const token = true;
const isV2 = true;

export const getTaskList = taskListId => {
  return {
    types: [
      TASK_LISTS_ACTIONS.GET_TASK_LIST,
      TASK_LISTS_ACTIONS.GET_TASK_LIST_SUCCESS,
      TASK_LISTS_ACTIONS.GET_TASK_LIST_FAILURE
    ],
    promise: client =>
      client.get(`/core/teams/:app_id:/task-lists/${taskListId}`, {
        isV2,
        token
      })
  };
};

export const createTaskList = taskList => {
  return {
    types: [
      TASK_LISTS_ACTIONS.CREATE_TASK_LIST,
      TASK_LISTS_ACTIONS.CREATE_TASK_LIST_SUCCESS,
      TASK_LISTS_ACTIONS.CREATE_TASK_LIST_FAILURE
    ],
    promise: client =>
      client.post(`/core/teams/:app_id:/task-lists`, {
        data: taskList,
        token,
        isV2
      })
  };
};

export const createTaskWithinTaskList = ({ task, taskListId }) => {
  const toSubmit = (task = omit(task, 'id'));
  return {
    types: [
      TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST,
      TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_SUCCESS,
      TASK_LISTS_ACTIONS.CREATE_TASK_WITHIN_TASK_LIST_FAILURE
    ],
    promise: client =>
      client.post(`/core/teams/:app_id:/task-lists/${taskListId}/tasks`, {
        data: toSubmit,
        token,
        isV2
      }),
    task,
    taskListId
  };
};

export const assignTaskListToBooking = ({ taskListId, bookingId }) => {
  return {
    types: [
      TASK_LISTS_ACTIONS.ASSIGN_TASK_LIST_TO_BOOKING,
      TASK_LISTS_ACTIONS.ASSIGN_TASK_LIST_TO_BOOKING_SUCCESS,
      TASK_LISTS_ACTIONS.ASSIGN_TASK_LIST_TO_BOOKING_FAILURE
    ],
    promise: client =>
      client.put(`/core/teams/:app_id:/bookings/${bookingId}/task-list`, {
        data: { taskListId },
        token,
        isV2
      }),
    taskListId,
    bookingId
  };
};

export const orderTasksWithinTaskList = ({
  taskListId,
  representationOrder
}) => {
  return {
    types: [
      TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST,
      TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST_SUCCESS,
      TASK_LISTS_ACTIONS.ORDER_TASKS_WITHIN_TASK_LIST_FAILURE
    ],
    promise: client =>
      client.put(`/core/teams/:app_id:/task-lists/${taskListId}/order`, {
        data: { representationOrder },
        token,
        isV2
      }),
    representationOrder
  };
};
