import { Icon } from 'shared';
import KeyboardShortcut from 'v4/shared/components/KeyboardShortcut/KeyboardShortcut';

const CommandbarButton = () => {
  if (window.CommandBar !== undefined) {
    return (
      <div className="tw-relative tw-mb-2.5">
        <button
          onClick={() => window.CommandBar.open()}
          placeholder="Search or type a command"
          className="tw-text-left tw-flex tw-w-full tw-text-white/80 tw-items-center tw-block tw-rounded-md tw-border-1 tw-border-eggplant-lightest tw-p-2 tw-pr-14 tw-text-eggplant-lightest tw-ring-1 hover:tw-ring-eggplant-lightest/60 focus:tw-ring-2 tw-ring-eggplant-lightest/40"
        >
          <div className="tw-mr-2">
            <Icon name="v4_search" />
          </div>
          Search
        </button>
        <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-py-2 tw-pr-2 tw-pointer-events-none">
          <KeyboardShortcut shortcut="⌘ K" />
        </div>
      </div>
    );
  }
  return null;
};

export default CommandbarButton;
