import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { find, get, orderBy } from 'lodash';

import CustomDropdownItems from './CustomDropdownItems/CustomDropdownItems';
import {
  PressStud,
  Checkbox,
  Dropdown,
  ErrorMessage,
  Label,
  MenuItem,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput,
  Grid,
  GridCell
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { createField, updateField } from 'store/v1/auth/auth.actions.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';
import { selectActiveFeatureFlags } from 'store/teams';

import useEvent from 'v1/helpers/hooks/useEvent';
import { validate as validator } from 'v1/helpers/validation';

import { CORE_STRUCTURES } from 'v1/helpers/coreStructures';
import { CUSTOM_FIELD_TYPES } from 'v1/helpers/DropdownDataSets';
import { hasFlag } from 'lib/restrictedAccess';
import { CustomFieldType } from '__types__';

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const FieldCreateModal = () => {
  // REDUX
  const featureFlags = useSelector(selectActiveFeatureFlags);
  const auth = useSelector(state => state.auth);
  const { loading, error, settings = {} } = auth || {};

  const ui = useSelector(state => state.ui);
  const { data: uiData = {}, modalIsOpen } = ui || {};
  const {
    create,
    core_structure,
    core_structure_type_id,
    field = {},
    updateAction
  } = uiData;

  // Get core structure types creating field via relevant Structure Type Add field
  const structure_types = useSelector(state => state[core_structure]);

  const isAddingFieldToCoreStructure =
    create && core_structure_type_id !== undefined;
  const targetCoreStructureName = get(
    CORE_STRUCTURES,
    `${core_structure}.name`
  );
  const targetCoreStructureTypeName = get(
    structure_types,
    `data[${core_structure_type_id}]name`
  );

  const dispatch = useDispatch();

  // PROPS
  const {
    name = '',
    data_type = '',
    options = [],
    required = false,
    filterable = false
  } = field;

  // STATE
  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent([AUTH_ACTIONS.CREATE_FIELD, AUTH_ACTIONS.UPDATE_FIELD], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Updated succesfully'));
      // Add to respective core structure type on successful create
      if (isAddingFieldToCoreStructure) {
        addFieldToMetastructure();
      } else if (modalIsOpen) {
        dispatch(closeModal());
      }
    },
    onFailure: () => dispatch(displayAlert('error', error.message))
  });

  // METHODS
  function handleSubmit(values) {
    const { id } = field;
    if (create && id === undefined) {
      return dispatch(createField(values));
    }
    return dispatch(updateField(id, values));
  }

  function addFieldToMetastructure() {
    const coreStructureType = get(
      structure_types,
      `data.${core_structure_type_id}`
    );
    if (!coreStructureType || !updateAction) return;

    // This is a little bit funky. When creating a new field via a Core
    // Structure Type, we need to carry out the same Create
    // flow as when creating via Custom Fields section. In addition,
    // the desired UX requires us to also add that newly created
    // field to its respective Core Structure Type (based on where 'Add Field'
    // was launched from). This is provided via `core_structure_type_id`
    // and `updateAction` being passed along as ui.data for this modal.

    // We can only infer which was the newly created custom_field_definition
    // by taking the newest (highest `id`) and appending
    // that to the intended Core Structure Type's metastructure.

    // Not ideal, but the only option pending getting responses
    // back from successful actions.
    const customFieldDefinitionsSorted = orderBy(
      settings.custom_field_definitions,
      'id'
    );
    const newField = customFieldDefinitionsSorted.pop();
    if (!newField) return;
    const updatedStructureType = {
      ...coreStructureType,
      metastructure: {
        ...coreStructureType.metastructure,
        fields: [
          ...(coreStructureType.metastructure.fields || []),
          {
            active: true,
            custom_field_definition_id: newField.id,
            default: '',
            locked: false,
            required: false,
            type: 'CUSTOM_FIELD'
          }
        ]
      }
    };
    dispatch(updateAction(core_structure_type_id, updatedStructureType));
    dispatch(closeModal());
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} field`} />
      <ModalContent>
        <ModalScrollable>
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              name,
              data_type,
              options,
              required,
              filterable
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <div className="stack-M">
                  <Field
                    field="name"
                    validate={value => handleValidate(value, 'required')}
                  >
                    {({ name, value, error, setValue }) => (
                      <Fragment>
                        <Label htmlFor={name}>Field name</Label>
                        <TextInput
                          id={name}
                          name="name"
                          value={value}
                          placeholder="Name"
                          validity={error ? 'invalid' : null}
                          onChange={({ target }) => {
                            setValue(target.value);
                          }}
                          autoComplete="off"
                        />
                        {error && (
                          <ErrorMessage>This field is required</ErrorMessage>
                        )}
                      </Fragment>
                    )}
                  </Field>
                </div>

                <div className="stack-M">
                  <Field field="data_type">
                    {fieldApi => {
                      const selectedType = find(
                        CUSTOM_FIELD_TYPES,
                        c => c.value === fieldApi.value
                      );
                      return (
                        <Fragment>
                          <Label>Field type</Label>
                          <Dropdown
                            buttonLabel={
                              (selectedType && selectedType.label) ||
                              'Select type'
                            }
                            buttonClass="btn btn-default btn-fill"
                          >
                            {CUSTOM_FIELD_TYPES.filter((type, i) => {
                              return type.featureFlag
                                ? hasFlag(featureFlags, type.featureFlag)
                                : true;
                            }).map((type, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  className=""
                                  onSelect={() => fieldApi.setValue(type.value)}
                                >
                                  <Grid gutters="S">
                                    <GridCell width="auto">
                                      <img src={type.icon} alt="" />
                                    </GridCell>
                                    <GridCell className="inset-XS">
                                      <h5 className="text-13-700">
                                        {type.label}
                                      </h5>
                                      <span>{type.description}</span>
                                    </GridCell>
                                  </Grid>
                                </MenuItem>
                              );
                            })}
                          </Dropdown>
                        </Fragment>
                      );
                    }}
                  </Field>
                </div>

                {[
                  CustomFieldType.SINGLE_SELECT,
                  CustomFieldType.MULTI_SELECT,
                  CustomFieldType.NETSUITE_SUBCLASS
                ].indexOf(formApi.values.data_type) !== -1 && (
                  <div className="stack-M">
                    <Field
                      field="options"
                      defaultValue={['', '']}
                      validate={items => {
                        const empties = items.filter(i => !i);
                        return empties.length ? 'Empty fields' : null;
                      }}
                    >
                      {fieldApi => (
                        <Fragment>
                          <Label>Options List</Label>
                          <CustomDropdownItems
                            items={orderBy(fieldApi.value, 'order')}
                            onChange={items => fieldApi.setValue(items)}
                          />
                        </Fragment>
                      )}
                    </Field>
                  </div>
                )}

                <div className="stack-M">
                  <Field field="required">
                    {fieldApi => (
                      <Checkbox
                        label="Is required"
                        name="required"
                        id="is-required"
                        description="Check if this field must be filled in to save the form"
                        checked={fieldApi.value}
                        onChange={({ target }) =>
                          fieldApi.setValue(target.checked)
                        }
                      />
                    )}
                  </Field>
                </div>

                <div className="stack-M">
                  <Field field="filterable">
                    {fieldApi => (
                      <Checkbox
                        label="Filterable"
                        name="filterable"
                        id="is-filterable"
                        description="Can be searched"
                        checked={fieldApi.value}
                        onChange={({ target }) =>
                          fieldApi.setValue(target.checked)
                        }
                      />
                    )}
                  </Field>
                </div>

                <hr />

                {isAddingFieldToCoreStructure &&
                  targetCoreStructureName &&
                  targetCoreStructureTypeName && (
                    <p>
                      This field will be added to{' '}
                      <strong>
                        {targetCoreStructureName}: {targetCoreStructureTypeName}
                      </strong>
                    </p>
                  )}
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Field' : 'Save changes'}
            appearance="primary"
            isLoading={loading}
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default FieldCreateModal;
