import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Grid, GridCell, BookingPill } from 'v1/components/shared';

import isEmpty from 'lodash/isEmpty';
import './BookingPillGroup.scss';

// TODO: sort order by status (ie, booked first)
const BookingPillGroup = ({ assignments, align }) => (
  <Grid
    className={classnames(['BookingPillGroup', `BookingPillGroup-${align}`])}
    vcenter
    wrap
  >
    {!isEmpty(assignments) ? (
      assignments.map(assignment => (
        <GridCell key={assignment.id} width="auto">
          <BookingPill key={assignment.id} assignment={assignment} />
        </GridCell>
      ))
    ) : (
      <span className="inset-XS text-neutral">No resources assigned</span>
    )}
  </Grid>
);

BookingPillGroup.props = {
  assignments: PropTypes.arrayOf(PropTypes.object), // A ResourceSlotAssignment object, from production.resource_slots.resource_slot_assignments
  align: PropTypes.oneOf(['left', 'right'])
};

BookingPillGroup.defaultProps = {
  align: 'left'
};

export default BookingPillGroup;
