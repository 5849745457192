import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import MoveAssignmentPolicy from 'constants/MoveAssignmentPolicy';
import useEvent from 'v1/helpers/hooks/useEvent';
import { closeModal, displayAlert } from 'store/v1/ui/ui.actions';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants';
import { selectModalData } from 'store/v1/ui/ui.selectors';
import { moveProduction } from 'store/v1/productions/productions.actions';
import { getProductionsLoading } from 'store/v1/productions/productions.selectors';
import {
  ModalContent,
  ModalScrollable,
  PressStud,
  ModalNavigation,
  EventDateRange,
  Label,
  MenuItem,
  Dropdown
} from 'v1/components/shared';
import './ProductionMoveModal.scss';

const ProductionMoveModalContent = ({ production, onClose }) => {
  const dispatch = useDispatch();
  const [formApi, setFormApi] = useState();
  const modalData = useSelector(selectModalData);
  const loading = useSelector(getProductionsLoading);

  useEvent([PRODUCTIONS_ACTIONS.MOVE_PRODUCTION], {
    onSuccess: () => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Production moved successfully'));
    },
    onFailure: () => dispatch(displayAlert('error', 'An error has occurred'))
  });

  const handleSubmit = values => {
    dispatch(
      moveProduction(production.id, {
        start: modalData.productionStartDate,
        end: modalData.productionEndDate,
        movePolicy: values.movePolicy
      })
    );
  };

  return (
    production && (
      <ModalContent>
        <ModalScrollable>
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              movePolicy: MoveAssignmentPolicy.CHANGE_BOOKING
            }}
            getApi={api => setFormApi(api)}
          >
            {() => (
              <>
                <h3>
                  Move <b>{production.title}</b> to new dates
                </h3>
                <div className="ProductionMoveModal-dates">
                  <Field field="dates">
                    {({ fieldName: name }) => (
                      <>
                        <Label htmlFor={name}>Production Dates</Label>
                        <div className="ProductionMoveModal-dates-input">
                          <EventDateRange
                            isReadOnly
                            size="S"
                            data={production.production_date}
                          />
                          <img src="/images/icon_arrow_right_grey.svg" alt="" />
                          <EventDateRange
                            isReadOnly
                            size="S"
                            data={{
                              ...production.production_date,
                              start_date: modalData.productionStartDate,
                              end_date: modalData.productionEndDate
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Field>
                </div>
                <div className="ProductionMoveModal-policy-input">
                  <Field field="movePolicy">
                    {({ fieldName: name, value, setValue }) => (
                      <>
                        <Label htmlFor={name}>Production Move Method</Label>
                        <Dropdown
                          buttonLabel={MoveAssignmentPolicy.resolveItemLabel(
                            value
                          )}
                          buttonClass={classNames(
                            'btn btn-default btn-small reverse'
                          )}
                          buttonIcon={
                            <img
                              src="/images/icon_arrow_down_dark.svg"
                              width="12px"
                              alt=""
                            />
                          }
                        >
                          {MoveAssignmentPolicy.items.map(policy => (
                            <MenuItem onSelect={() => setValue(policy.value)}>
                              <span className="MenuItem-label">
                                {policy.label}
                                <span className="ProductionMoveModal-policy-description">
                                  {policy.description}
                                </span>
                              </span>
                            </MenuItem>
                          ))}
                        </Dropdown>
                      </>
                    )}
                  </Field>
                </div>
              </>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" appearance="silent" action={onClose} />
          <PressStud
            label="Confirm"
            appearance="primary"
            isLoading={loading === PRODUCTIONS_ACTIONS.MOVE_PRODUCTION}
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    )
  );
};

export default ProductionMoveModalContent;
