import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';
import clickOutside from 'v1/helpers/clickOutsideDecorator';

import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';
import classnames from 'classnames';
import './ColourPicker.scss';

const SYSTEM_COLOURS_HEX = [
  '#05003E',
  '#140D5C',
  '#271E81',
  '#443AA8',
  '#776DD9',
  '#EE4D2F',
  '#EB6148',
  '#EF7964',
  '#F09D8E',
  '#F3C1B8',
  '#17B3C8',
  '#36C0D3',
  '#63CFDD',
  '#8FD7E1',
  '#CAEAEF',
  '#15B85D',
  '#1DCD6A',
  '#3BDF82',
  '#78E8A9',
  '#BEF3D5',
  '#FFC100',
  '#FFD143',
  '#FFDF7D',
  '#FFE9A6',
  '#FFF5D8',
  '#F7F7F7',
  '#FCFCFC'
];

export class ColourPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colour: props.selected || SYSTEM_COLOURS.WHITE
    };
  }
  onRemove = () => {
    this.setState({ colour: null }, () => {
      this.props.onChange(null);
    });
  };
  onClickOutside() {
    if (this.state.showPicker) {
      return this.setState({ showPicker: false });
    }
  }
  renderPicker = () => {
    return (
      <div className="ColourPicker-dropdown">
        <GithubPicker
          triangle="hide"
          width="100%"
          color={this.state.colour}
          colors={SYSTEM_COLOURS_HEX}
          onChange={color => {
            this.setState({
              colour: color.hex,
              showPicker: false
            });
            this.props.onChange(color.hex);
          }}
        />
      </div>
    );
  };
  renderRemove = () => {
    if (this.props.hideRemove) return null;

    return (
      <span onClick={this.onRemove} className="ColourPicker-remove">
        Remove
      </span>
    );
  };
  renderLabel = () => {
    const { label } = this.props;

    return label && <span className="form-label">{label}</span>;
  };
  render() {
    const { showPicker, colour } = this.state;
    const { size } = this.props;

    return (
      <div className="ColourPicker">
        <span
          onClick={() => this.setState({ showPicker: !showPicker })}
          className={classnames(['ColourPicker-colour'], {
            [`ColourPicker_${size}`]: size
          })}
          style={{ backgroundColor: colour, borderColor: colour }}
        />
        {colour ? this.renderRemove() : this.renderLabel()}
        {showPicker && this.renderPicker()}
      </div>
    );
  }
}

// NOTE: component is wrapper for GithubPicker
ColourPicker.propTypes = {
  selected: PropTypes.any, // TODO: color, but not sure of type
  onChange: PropTypes.func, // TODO: arguments
  hideRemove: PropTypes.bool,
  size: PropTypes.oneOf(['S', 'M', 'L'])
};

export default clickOutside(ColourPicker);
