//
// Helpers for the ResourceSlot object
//

import { formatCurrency } from 'v1/helpers/currencyHelper';
import { getTotals } from 'v1/helpers/byType/rateHelper';

export const getResourceSlotRate = slot => {
  return {
    isBookingRate: false,
    id: slot.id,
    name: 'Estimated rate',
    currency: slot.estimate_rate_currency,
    amount: slot.estimate_rate_amount,
    interval: slot.estimate_rate_interval,
    quantity: slot.estimate_rate_quantity,
    productionId: slot.production_id
  };
};

export const getResourceSlotFees = slot => {
  if (!slot) {
    return null;
  }
  return {
    agencyFee: slot.estimate_rate_agency_fee,
    payrollFee: slot.estimate_rate_payroll_fee,
    processingFee: slot.estimate_rate_processing_fee
  };
};

const getResourceSlotRateTotal = rate => {
  return rate.amount && rate.quantity ? rate.amount * rate.quantity : 0;
};

const getResourceSlotTotals = (estRate, slot) => {
  const estFees = getResourceSlotFees(slot);
  const estRateTotal = getResourceSlotRateTotal(estRate);
  const estTotals = getTotals(estRateTotal, estFees);
  return estTotals;
};

export const getResourceSlotTotalsFormatted = (slot, currency) => {
  const estRate = getResourceSlotRate(slot);
  const estTotals = getResourceSlotTotals(estRate, slot);
  return {
    rates: formatCurrency(estTotals.rateTotal, estRate.currency || currency),
    fees: formatCurrency(estTotals.feesTotal, currency),
    expenses: formatCurrency(estTotals.expensesTotal, currency),
    total: formatCurrency(estTotals.total, estRate.currency || currency),
    totalRaw: estTotals.total || 0
  };
};
