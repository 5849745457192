import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface SandboxIndicatorProps {
  onClick: () => void;
}

export function SandboxIndicator({ onClick }: SandboxIndicatorProps) {
  return (
    <button
      className="tw-flex tw-w-full focus:tw-ring-white/20 focus:tw-ring-2 tw-flex-row tw-items-center tw-gap-1.5 tw-bg-orange-400 hover:tw-bg-orange-500  tw-text-gray-900 tw-py-2 tw-px-1 tw-rounded-md tw-text-md tw-cursor-pointer"
      onClick={onClick}
    >
      <ExclamationTriangleIcon className="tw-h-6 tw-w-6" />
      Sandbox environment
    </button>
  );
}
