import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Fieldset, Legend } from 'v1/components/shared';

const RadioGroup = ({
  className,
  id,
  name,
  legend,
  value,
  onChange,
  children,
  ...props
}) => (
  <Fieldset id={id} className={classnames('RadioGroup', className)} {...props}>
    {legend && <Legend className="RadioGroup-legend">{legend}</Legend>}
    {children &&
      React.Children.map(children, child => {
        return React.cloneElement(child, {
          name,
          checked: child.props.value === value,
          onChange: event => onChange(event)
        });
      })}
  </Fieldset>
);

RadioGroup.propTypes = {
  // Component props
  className: PropTypes.string,
  legend: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  // ...props
};

export default RadioGroup;
