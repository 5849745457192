export enum EntityType {
  CONTACT = 1,
  CALLOUT = 2,
  APPLICANT = 3,
  SHORTLIST = 4,
  CALLSHEET = 5,
  PRODUCTION = 6,
  CUSTOM_FIELD = 7,
  CUSTOM_FIELD_DEFINITION = 8,
  FILE = 9,
  DOCUMENT = 10,
  PIN = 11,
  GROUP = 12,
  TAG = 13,
  NOTE = 14,
  RATE = 15,
  USER = 16,
  RESOURCE_TYPE = 17,
  LOCATION = 18,
  SHORTLIST_BLOCK = 19,
  SHORTLIST_ACTION = 20,
  CALLSHEET_TEAM_MEMBER = 21,
  CALLSHEET_RECIPIENT = 22,
  NOTIFICATION = 23,
  RESOURCE_SLOT_ASSIGNMENT = 24,
  RESOURCE_SLOT = 25,
  SCHEDULE_ITEM = 26,
  BOOKING = 27,
  BUDGET_EXPENSE = 28,
  IMPORT = 29,
  EVENT = 30,
  CUSTOM_FIELDGROUP = 31,
  CUSTOM_FIELDGROUP_DEFINITION = 32,
  BOOKING_TYPE = 33,
  EXPENSE_TYPE = 34,
  PRODUCTION_TYPE = 35,
  SHORTLIST_TYPE = 36,
  EXPENSE = 37,
  PRODUCTION_TEMPLATE = 38,
  STATUS = 39,
  SLOT_CATEGORY = 40,
  CHECKIN = 41,
  DATA_EXPORT_TEMPLATE = 42,
  CONTACT_RATE = 43
}
