import { createSelector } from 'reselect';
import _keys from 'lodash/keys';
import _orderBy from 'lodash/orderBy';

// TODO: cloned from selectors/productions
export const getExpenseTypes = createSelector(
  state => state.expense_types.data,
  (_, { archived = false } = { archived: false }) => archived,
  (data, archived) => {
    const types = _keys(data).reduce((result, id) => {
      if (!archived && data[id].archived) return result;
      return result.concat(data[id]);
    }, []);
    return _orderBy(types, ['order', 'id'], ['asc', 'asc']);
  }
);

export const getExpenses = state => state.expenses.data;

export const getExpensesList = createSelector([getExpenses], expenses =>
  Object.values(expenses)
);

export const getExpensesByBookingId = createSelector(
  [getExpensesList],
  expenses => {
    return (expenses || []).reduce((acc, expense) => {
      if (!expense.archived) {
        return {
          ...acc,
          [expense.booking_id]: acc[expense.booking_id]
            ? [...acc[expense.booking_id], expense]
            : [expense]
        };
      }
      return acc;
    }, {});
  }
);

export const makeGetExpensesByBookingId = () =>
  createSelector([getExpensesByBookingId], values => values);
