import React from 'react';

import styles from './ModalToolbar.module.scss';

type ModalToolbarProps = {
  children: React.ReactNode;
};

const ModalToolbar = ({ children }: ModalToolbarProps) => (
  <div className={styles.ModalToolbar}>{children}</div>
);

export default ModalToolbar;
