import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Grid, GridCell } from 'v1/components/shared';
import styles from './FlyoverGroup.module.scss';

const FlyoverGroup = ({ title, isExpandable, isExpanded, children }) => {
  const [isOpen, setIsOpen] = useState(isExpanded);

  const toggleIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className={styles.FlyoverGroup}>
      {title && (
        <Grid
          className={classnames(styles.Header, 'clickable')}
          spread
          vcenter
          onClick={isExpandable && toggleIsOpen}
        >
          <GridCell
            width="auto"
            className={classnames('text-11-700-eggplant-lighter', styles.Title)}
          >
            {title}
          </GridCell>
          {isExpandable && (
            <GridCell width="auto">
              <img
                className={classnames({
                  'rotate-90': !isOpen,
                  'rotate-anti-90': isOpen
                })}
                src="/images/icon_chevron.svg"
                alt=""
              />
            </GridCell>
          )}
        </Grid>
      )}
      {(!isExpandable || (isExpandable && isOpen)) && children}
    </div>
  );
};

FlyoverGroup.propTypes = {
  title: PropTypes.string,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  children: PropTypes.any
};

export default FlyoverGroup;
