import { QueryClient } from '@tanstack/react-query';
import { Resource, Shortlist, ShortlistBlock } from '__types__/index';
import { Middleware } from 'redux';
import { resourceShortlistAssociationInternals } from 'v4/entities/associations/resource__shortlist/resource__shortlist.hooks';
import { bookingKeys } from 'v4/entities/booking/booking.hooks';
import { resourceInternals } from 'v4/entities/resource/resource.hooks';
import { shortlistInternals } from 'v4/entities/shortlist/shortlist.hooks';

type MigrationAction =
  | {
      type: 'UPDATE_CONTACT_SUCCESS';
      result: Resource;
    }
  | {
      type: 'UPDATE_SHORTLIST_SUCCESS';
      result: Shortlist;
    }
  | {
      type: 'DELETE_SHORTLIST_SUCCESS';
      result: Shortlist;
    }
  | {
      type: 'UPDATE_SHORTLIST_BLOCK_SUCCESS';
      result: ShortlistBlock;
    }
  | {
      type: 'COMMENT_SHORTLIST_BLOCK_SUCCESS';
      result: ShortlistBlock;
    }
  | {
      type: 'GET_PUBLIC_SHORTLIST_SUCCESS';
      result: { shortlist: Shortlist };
    }
  | {
      type: 'CREATE_SHORTLIST_BLOCK_SUCCESS';
      shortlist_id: Shortlist['id'];
    }
  | {
      type: 'CREATE_BOOKING_SUCCESS';
    }
  | {
      type: 'UPDATE_BOOKING_SUCCESS';
    }
  | {
      type: 'DELETE_BOOKING_SUCCESS';
    }
  | {
      type: never;
    };

export function createQueryCacheReduxMiddleware(
  client: QueryClient
): Middleware {
  return store => next => (action: MigrationAction) => {
    switch (action.type) {
      case 'UPDATE_CONTACT_SUCCESS':
        resourceInternals.invalidateDetails(client, action.result.id);
        resourceInternals.invalidateLists(client);
        break;
      case 'UPDATE_SHORTLIST_SUCCESS':
        shortlistInternals.updateCacheWithItem(client, action.result);
        resourceShortlistAssociationInternals.invalidateQueries(client, {
          shortlist_id: action.result.id
        });
        break;
      case 'GET_PUBLIC_SHORTLIST_SUCCESS':
        shortlistInternals.updateCacheWithItem(client, {
          ...action.result.shortlist,
          published: true
        });
        if (action.result.shortlist.id !== undefined) {
          resourceShortlistAssociationInternals.invalidateQueries(client, {
            shortlist_id: action.result.shortlist.id
          });
        }
        break;
      case 'DELETE_SHORTLIST_SUCCESS':
        shortlistInternals.invalidateDetails(client, action.result.id);
        shortlistInternals.invalidateLists(client);
        resourceShortlistAssociationInternals.invalidateQueries(client, {
          shortlist_id: action.result.id
        });
        break;
      case 'UPDATE_SHORTLIST_BLOCK_SUCCESS':
      case 'COMMENT_SHORTLIST_BLOCK_SUCCESS':
        if (action.result.shortlist_id !== undefined) {
          shortlistInternals.invalidateDetails(
            client,
            action.result.shortlist_id
          );
          shortlistInternals.invalidateLists(client);
          resourceShortlistAssociationInternals.invalidateQueries(client, {
            shortlist_id: action.result.id
          });
        }
        break;
      case 'CREATE_SHORTLIST_BLOCK_SUCCESS':
        shortlistInternals.invalidateDetails(client, action.shortlist_id);
        shortlistInternals.invalidateLists(client);
        resourceShortlistAssociationInternals.invalidateQueries(client, {
          shortlist_id: action.shortlist_id
        });
        // todo also invalidate associations for given resource, if possible
        // resourceShortlistAssociationInternals.invalidateQueries(client, {
        //   resource_id: action.contact_id
        // });
        break;
      case 'UPDATE_BOOKING_SUCCESS':
      case 'CREATE_BOOKING_SUCCESS':
      case 'DELETE_BOOKING_SUCCESS':
        client.removeQueries({
          type: 'inactive',
          queryKey: bookingKeys.all()
        });
        client.invalidateQueries({
          queryKey: bookingKeys.all()
        });
      default:
        // Handle any other action types or simply return next(action)
        break;
    }

    return next(action);
  };
}
