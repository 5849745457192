import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { findIndex, get } from 'lodash';

import { openModal } from 'store/v1/ui/ui.actions.js';

import { Grid, GridCell } from 'v1/components/shared';

const BookResourceFlyoverResourceFooter = ({ slot, closeFlyover }) => {
  const dispatch = useDispatch();

  const productions = useSelector(state => state.productions.data);
  const production = get(productions, [slot.production_id], {});

  // TODO: we're using the slot query here and not the flyover query, so if you type in text it won't retain it in the modal
  const openBlackbookModal = () => {
    // TODO: ripped out of ProductionBudgetTableTeamCommitted
    const data = {
      destination: 'PRODUCTION_TEAM_MEMBER_OPTIONS',
      store: 'productions',
      initialQuery: slot.query || {},
      dataPointers: [
        {
          label: 'production',
          pointTo: ['productions', 'data', production.id]
        },
        {
          label: 'resource_slot',
          pointTo: [
            'productions',
            'data',
            production.id,
            'resource_slots',
            findIndex(production.resource_slots, r => r.id === slot.id)
          ]
        }
      ],
      production,
      actions: ['PRODUCTION_BOOK_RESOURCE_SLOT']
    };
    dispatch(openModal('AddFromBlackbookModal', data));
    closeFlyover();
  };

  // TODO: is this abstractable ?
  return (
    <Grid
      className="clickable"
      cellPadding="S"
      spread
      onClick={openBlackbookModal}
    >
      <GridCell className="text-13-600-eggplant-lighter" width="auto">
        Open full search
      </GridCell>
      <GridCell width="auto">
        <img src="/images/icon_expand_arrows.svg" alt="" />
      </GridCell>
    </Grid>
  );
};

BookResourceFlyoverResourceFooter.propTypes = {
  slot: PropTypes.object,
  closeFlyover: PropTypes.func
};

export default BookResourceFlyoverResourceFooter;
