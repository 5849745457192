import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown, MenuItem } from 'v1/components/shared';
import './StatusSelectorMultiple.scss';

const StatusSelectorMultiple = ({
  statuses,
  onStatusChange,
  activeStatuses
}) => {
  const onStatusClick = statusId => {
    statusId
      ? activeStatuses.find(s => s === statusId)
        ? onStatusChange(prev => prev.filter(s => s !== statusId))
        : onStatusChange(prev => [...prev, statusId])
      : onStatusChange(statuses.map(s => s.id));
  };
  return (
    <Dropdown
      key="booking-status"
      buttonClass="btn btn-default btn-small btn-fill"
      buttonLabel={'Filter Status'}
    >
      <MenuItem key={`availability-all`} onSelect={onStatusClick}>
        <span className="MenuItem-label">Show All</span>
      </MenuItem>
      {statuses
        .filter(s => !s.archived)
        .map(status => (
          <MenuItem
            className="inline"
            key={`status-${status.id}`}
            onSelect={() => onStatusClick(status.id)}
          >
            <span
              className="StatusSelectorMultiple-status-colour"
              style={{ backgroundColor: status.colour }}
            />
            <span className="MenuItem-label">{status.name}</span>
            <span className="StatusSelectorMultiple-status-active">
              {activeStatuses.includes(status.id) && (
                <img src="/images/icon_tick_black.svg" alt="" />
              )}
            </span>
          </MenuItem>
        ))}
    </Dropdown>
  );
};

StatusSelectorMultiple.propTypes = {
  statuses: PropTypes.array.isRequired,
  onStatusClick: PropTypes.func.isRequired,
  activeStatuses: PropTypes.array
};

export default StatusSelectorMultiple;
