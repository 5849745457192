import React from 'react';

import { EmailInputProps } from 'shared/byModel/Text/EmailInput/EmailInput';
import { EmailInput, RestrictedInput } from 'shared';

type UserEmailInputProps = EmailInputProps;

// TODO
const UserEmailInput = ({
  isRestricted,
  ...restProps
}: UserEmailInputProps) => {
  if (isRestricted) {
    return <RestrictedInput {...restProps} />;
  }

  return <EmailInput {...restProps} />;
};

UserEmailInput.rules = {
  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
};

UserEmailInput.errors = {
  pattern: 'This is not a valid email address'
};

export default UserEmailInput;
