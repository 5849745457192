import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './ModalScrollable.scss';

const ModalScrollable = ({
  children,
  padding = 'L',
  className,
  isContentCentered
}) => {
  return (
    <div
      className={classnames(
        {
          [`ModalScrollable_padding-${padding}`]: padding,
          ModalScrollable_contentCentered: isContentCentered
        },
        ['ModalScrollable', className]
      )}
    >
      {children}
    </div>
  );
};

ModalScrollable.propTypes = {
  padding: PropTypes.oneOf(['0', 'S', 'M', 'L', 'XL']),
  className: PropTypes.string,
  isContentCentered: PropTypes.bool
};

export default ModalScrollable;
