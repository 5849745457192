import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/v1/ui/ui.actions.js';
import {
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud
} from 'v1/components/shared';

const AlertModal = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.ui.data);

  const close = () => dispatch(closeModal());

  const onOk = () => {
    data.onOk && data.onOk();
    close();
  };

  const onAction = () => {
    data.onAction && data.onAction();
    close();
  };

  return (
    <ModalWrapper size="XS">
      <ModalContent>
        <ModalScrollable padding="L">
          <span className="text-11-700-eggplant">ALERT</span>
          {data.title && <h2 className="stack-S">{data.title}</h2>}
          {data.description && (
            <div className="text-16-600-eggplant-lighter">
              {data.description}
            </div>
          )}
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label={data.okLabel || 'Got it'} action={onOk} />
          {data.onAction && data.actionLabel && (
            <PressStud
              label={data.actionLabel}
              appearance="primary"
              action={onAction}
            />
          )}
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

/**
 * Faux PropTypes (for our weird modals)
 *
 * title: PropTypes.string, // Big text
 * description: PropTypes.string, // Little text
 * okLabel: PropTypes.string, // Normal "Got it" button label
 * actionLabel: PropTypes.string, // Extra "Do something" button label
 * onOk: PropTypes.func, // Normal "Got it" button action
 * onAction: PropTypes.func // Extra "Do something" button label
 *
 */

export default AlertModal;
