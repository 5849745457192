import React, { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Loading } from 'v1/components/shared';
import Account from 'lib/auth/Account';
import { configureIntegration } from 'store/integrations';
import { displayAlert } from 'store/v1/ui/ui.actions';
import { IntegrationID } from '__types__';

const DocuSignCallback = ({ history }) => {
  const dispatch = useDispatch();

  const getFirstWorkspace = () => {
    const workspace = Account.resolveFirstWorkspace();
    if (!workspace) {
      console.log('failed to get first workspace from auth token');
      return history.replace('/unauthorized');
    }
    return workspace;
  };

  useEffect(() => {
    const workspace = getFirstWorkspace();
    dispatch(
      configureIntegration({
        id: IntegrationID.DOCUSIGN,
        enabled: true
      })
    )
      .then(unwrapResult)
      .then(_ => {
        dispatch(displayAlert('success', 'Enabled DocuSign integration'));
      })
      .finally(() => {
        history.replace(`/app/${workspace.db_schema}/settings/integrations`);
      });
  }, [dispatch]);

  return (
    <Loading text="Enabling DocuSign integration" className="pageCenter" />
  );
};

export default DocuSignCallback;
