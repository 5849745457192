import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

//const prefix = '@@contactsActions';

export const CONTACTS_ACTIONS = mapValues(
  mirror({
    GET_CONTACTS: null,
    GET_CONTACTS_SUCCESS: null,
    GET_CONTACTS_FAILURE: null,

    GET_CONTACTS_QUICK: null,
    GET_CONTACTS_QUICK_SUCCESS: null,
    GET_CONTACTS_QUICK_FAILURE: null,

    GET_CONTACT: null,
    GET_CONTACT_SUCCESS: null,
    GET_CONTACT_FAILURE: null,

    CREATE_CONTACT: null,
    CREATE_CONTACT_SUCCESS: null,
    CREATE_CONTACT_FAILURE: null,

    GET_BATCH_CONTACTS: null,
    GET_BATCH_CONTACTS_SUCCESS: null,
    GET_BATCH_CONTACTS_FAILURE: null,

    CHECK_CONTACT_DUPLICATES: null,
    CHECK_CONTACT_DUPLICATES_SUCCESS: null,
    CHECK_CONTACT_DUPLICATES_FAILURE: null,

    CLEAR_CONTACT_DUPLICATES: null,

    GET_CONTACT_ASSOCIATIONS: null,
    GET_CONTACT_ASSOCIATIONS_SUCCESS: null,
    GET_CONTACT_ASSOCIATIONS_FAILURE: null,

    GET_CONTACT_REPRESENTEES: null,
    GET_CONTACT_REPRESENTEES_SUCCESS: null,
    GET_CONTACT_REPRESENTEES_FAILURE: null,

    //GET_CONTACT_CONTACT_AVAILABILITY: null,
    //GET_CONTACT_CONTACT_AVAILABILITY_SUCCESS: null,
    //GET_CONTACT_CONTACT_AVAILABILITY_FAILURE: null,

    CREATE_CONTACT_NOTE: null,
    CREATE_CONTACT_NOTE_SUCCESS: null,
    CREATE_CONTACT_NOTE_FAILURE: null,

    DELETE_CONTACT_NOTE: null,
    DELETE_CONTACT_NOTE_SUCCESS: null,
    DELETE_CONTACT_NOTE_FAILURE: null,

    CREATE_CONTACT_DOCUMENT: null,
    CREATE_CONTACT_DOCUMENT_SUCCESS: null,
    CREATE_CONTACT_DOCUMENT_FAILURE: null,

    DELETE_CONTACT_DOCUMENT: null,
    DELETE_CONTACT_DOCUMENT_SUCCESS: null,
    DELETE_CONTACT_DOCUMENT_FAILURE: null,

    CREATE_CONTACT_PIN: null,
    CREATE_CONTACT_PIN_SUCCESS: null,
    CREATE_CONTACT_PIN_FAILURE: null,

    UPDATE_CONTACT_PIN: null,
    UPDATE_CONTACT_PIN_SUCCESS: null,
    UPDATE_CONTACT_PIN_FAILURE: null,

    UPDATE_CONTACT_PINS: null,
    UPDATE_CONTACT_PINS_SUCCESS: null,
    UPDATE_CONTACT_PINS_FAILURE: null,

    DELETE_CONTACT_PIN: null,
    DELETE_CONTACT_PIN_SUCCESS: null,
    DELETE_CONTACT_PIN_FAILURE: null,

    //CREATE_CONTACT_AVAILABILITY: null,
    //CREATE_CONTACT_AVAILABILITY_SUCCESS: null,
    //CREATE_CONTACT_AVAILABILITY_FAILURE: null,

    //DELETE_CONTACT_AVAILABILITY: null,
    //DELETE_CONTACT_AVAILABILITY_SUCCESS: null,
    //DELETE_CONTACT_AVAILABILITY_FAILURE: null,

    GET_CONTACT_PINS: null,
    GET_CONTACT_PINS_SUCCESS: null,
    GET_CONTACT_PINS_FAILURE: null,

    GET_SOCIAL_DATA: null,
    GET_SOCIAL_DATA_SUCCESS: null,
    GET_SOCIAL_DATA_FAILURE: null,

    GET_CONTACT_SCRAPE: null,
    GET_CONTACT_SCRAPE_SUCCESS: null,
    GET_CONTACT_SCRAPE_FAILURE: null,

    CREATE_BULK_CONTACTS: null,
    CREATE_BULK_CONTACTS_SUCCESS: null,
    CREATE_BULK_CONTACTS_FAILURE: null,

    UPDATE_CONTACT: null,
    UPDATE_CONTACT_SUCCESS: null,
    UPDATE_CONTACT_FAILURE: null,

    ARCHIVE_CONTACT: null,
    ARCHIVE_CONTACT_SUCCESS: null,
    ARCHIVE_CONTACT_FAILURE: null,

    ARCHIVE_CONTACT_BATCH: null,
    ARCHIVE_CONTACT_BATCH_SUCCESS: null,
    ARCHIVE_CONTACT_BATCH_FAILURE: null,

    UNARCHIVE_CONTACT: null,
    UNARCHIVE_CONTACT_SUCCESS: null,
    UNARCHIVE_CONTACT_FAILURE: null,

    UPDATE_GDPR_BASIS: null,
    UPDATE_GDPR_BASIS_SUCCESS: null,
    UPDATE_GDPR_BASIS_FAILURE: null,

    UPDATE_GDPR_REQUESTED_DATES: null,
    UPDATE_GDPR_REQUESTED_DATES_SUCCESS: null,
    UPDATE_GDPR_REQUESTED_DATES_FAILURE: null,

    DOWNLOAD_GDPR_DATA: null,
    DOWNLOAD_GDPR_DATA_SUCCESS: null,
    DOWNLOAD_GDPR_DATA_FAILURE: null,

    DELETE_GDPR_CONTACT: null,
    DELETE_GDPR_CONTACT_SUCCESS: null,
    DELETE_GDPR_CONTACT_FAILURE: null,

    GET_SEARCH_SUGGESTIONS: null,
    GET_SEARCH_SUGGESTIONS_SUCCESS: null,
    GET_SEARCH_SUGGESTIONS_FAILURE: null,

    GET_GROUPS: null,
    GET_GROUPS_SUCCESS: null,
    GET_GROUPS_FAILURE: null,

    CREATE_GROUP: null,
    CREATE_GROUP_SUCCESS: null,
    CREATE_GROUP_FAILURE: null,

    SET_ONBOARD_DATA: null,

    GET_CONTACT_SHORTLISTS: null,
    GET_CONTACT_SHORTLISTS_SUCCESS: null,
    GET_CONTACT_SHORTLISTS_FAILURE: null,

    //GET_CONTACT_EVENTS: null,
    //GET_CONTACT_EVENTS_SUCCESS: null,
    //GET_CONTACT_EVENTS_FAILURE: null,

    //CREATE_CONTACT_EVENT: null,
    //CREATE_CONTACT_EVENT_SUCCESS: null,
    //CREATE_CONTACT_EVENT_FAILURE: null,

    //UPDATE_CONTACT_EVENT: null,
    //UPDATE_CONTACT_EVENT_SUCCESS: null,
    //UPDATE_CONTACT_EVENT_FAILURE: null,

    //DELETE_CONTACT_EVENT: null,
    //DELETE_CONTACT_EVENT_SUCCESS: null,
    //DELETE_CONTACT_EVENT_FAILURE: null,

    GET_BATCH_CONTACTS_EVENTS: null,
    GET_BATCH_CONTACTS_EVENTS_SUCCESS: null,
    GET_BATCH_CONTACTS_EVENTS_FAILURE: null

    //BATCH_GET_AVAILABILITY: null,
    //BATCH_GET_AVAILABILITY_SUCCESS: null,
    //BATCH_GET_AVAILABILITY_FAILURE: null
  }),
  value => value
);
