import React from 'react';
import { getFormattedEventDates } from 'v1/helpers/byModel/EventHelper';
import { isEmpty, get } from 'lodash';

export function selectColourFromID(id) {
  const contact_id = id || 3;

  let colours = ['#595e86', '#f56d7d', '#78e8a9', '#ffda4c', '#7ae9e8'];
  let colour_id = parseInt(contact_id) % colours.length;

  return colours[colour_id];
}

/**
 * @function renderResourceGroups
 *
 * @param {array} groups - Array of groups to render into a string
 */
export function renderResourceGroups(groups = []) {
  if (!Array.isArray(groups)) {
    return [];
  }
  return groups.map((group, index) => {
    if (group.name) {
      return `${group.name}${index < groups.length - 1 ? ', ' : ''}`;
    } else {
      return ``;
    }
  });
}

/**
 * @function createNameFromString
 * Output first_name and last_name from a string with spaces
 *
 * @param {string} name - The (event) object to create an event from
 */
export function createNameFromString(name = '') {
  name = name.split(' ');

  let first_name = name && name.length ? get(name, [0], '') : '';
  let last_name = '';

  // Build last name from remaining spaces after first name
  if (name && name.length > 1) {
    name.shift();
    last_name = name.join(' ');
  }

  return { first_name, last_name };
}

export function renderName(contact = {}) {
  if (contact.nick_name) {
    return contact.nick_name;
  }
  return `${contact.first_name || ''} ${contact.last_name || ''}`;
}

// TODO: Bookings : POST : check where this is used & convert to component
export function renderLatestAvailabilityString(
  current_availability = {},
  status = {}
) {
  const statusLabel = status ? (
    <span
      style={{ color: status.colour }}
      className={'AvailabilityStatusString capitalise'}
    >
      {status.name}
    </span>
  ) : (
    ''
  );
  return (
    <span>
      {statusLabel} {getFormattedEventDates(current_availability)}
    </span>
  );
}

// Takes an array of events, finds the latest date that one was updated, filters the events by the ones that were updated on that date, then grabs the first one
export function selectLatestContactEventStatus(events) {
  if (isEmpty(events)) return null;

  let latestDate = new Date(
    Math.max.apply(
      null,
      events.map(function (e) {
        return new Date(e.updated_at);
      })
    )
  );
  let date = latestDate ? latestDate.toISOString() : '';
  let event = events.filter(e => e.updated_at == date);
  return event ? event[0] : events[0];
}

// TODO: move to general - just quickly duped from above
// TODO: check
export function selectLastUpdated(items = []) {
  if (isEmpty(items)) return null;
  const latestDate = new Date(
    Math.max.apply(
      null,
      items.map(e => new Date(e.updated_at))
    )
  );
  const item = items.find(e => e.updated_at === latestDate.toISOString());
  return item || items[0];
}
