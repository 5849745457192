import { Resource, Shortlist_normalized } from '__types__/index';
import { AssociationQuery } from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';

export function canBlockAffectAssociation(
  query: AssociationQuery,
  resource_id?: Resource['id'],
  shortlist_id?: Shortlist_normalized['id']
) {
  if ('resource_id' in query && resource_id !== undefined) {
    // if query is scoped to particular resource, then we should check if it's the same resource
    return query.resource_id === resource_id;
  }
  if ('shortlist_id' in query && shortlist_id !== undefined) {
    // if query is scoped to particular shortlist, then we should check if it's the same shortlist
    return query.shortlist_id === shortlist_id;
  }
  // if query is not scoped to anything, then we can expect that it can affect that association
  return true;
}
