import React from 'react';
import LoginForm from 'v1/components/feature/AuthComponents/LoginForm/LoginForm';

const Login = () => {
  return (
    <div className="Onboard FullSplitContainer">
      <div className="FullSplitContainer-section withForm">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
      <div className="FullSplitContainer-section backgroundImage darkTheme hidden-xs">
        <div className="FullSplitContainer-tease text-center">
          <img
            className="logomark"
            src="/images/atellio_brand_logomark.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

export default Login;
