import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FlyoverItem.module.scss';

const FlyoverItem = ({
  icon,
  title,
  description,
  disabled,
  onClick,
  children
}) => {
  const onKeyPress = event => {
    if (event.key === 'Enter') {
      onClick && onClick();
    }
  };

  return (
    <div
      className={classnames(styles.FlyoverItem, {
        clickable: onClick,
        [styles.FlyoverItem_disabled]: disabled,
        [styles.FlyoverItem_withIcon]: !children && icon
      })}
      role="menuitem"
      onClick={onClick}
      onKeyPress={onKeyPress}
      tabIndex={1}
    >
      {children || (
        <>
          {icon && (
            <div className={styles.Icon}>
              <img src={icon} alt="" />
            </div>
          )}
          {(title || description) && (
            <div>
              {title && <div className="text-13-600">{title}</div>}
              {description && (
                <div className="text-13-600-eggplant-lighter">
                  {description}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

FlyoverItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element // Internal use only
  ]),
  description: PropTypes.string,
  stayOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any
};

export default FlyoverItem;
