import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  CUSTOM_WALMART_RELATIVE_DATES,
  RELATIVE_DATES
} from 'v1/helpers/byType/dateHelper';
import { Label, Select } from 'v1/components/shared';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { FeatureFlag } from '__types__';

const DEFAULT_VALUE = 'LAST_30';

/*
 * NOTE: This can easily be extended to allow custom options for each selector instance,
 * by abstracting out the options, and passing in an array of consts
 */
// TODO: This needs to use a stylised selector, see designs
/**
 * @desc A selector for relative date ranges
 */
const RelativeDateSelector = ({ name, value, allowNull, onChange }) => {
  const isWalmart = useRestrictedAccess(FeatureFlag.CUSTOM_WALMART, null, null);
  const getDefaultValue = _ => {
    // prettier-ignore
    return allowNull
      ? ''
      : DEFAULT_VALUE;
  };

  useEffect(() => {
    !value && onChange(getDefaultValue());
  }, []);

  const dateOptions = useMemo(() => {
    if (isWalmart) {
      return {
        ...RELATIVE_DATES,
        ...CUSTOM_WALMART_RELATIVE_DATES
      };
    }
    return RELATIVE_DATES;
  }, [isWalmart]);

  return (
    <div>
      <Label htmlFor={name}>Select range</Label>
      <Select
        name={name}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        autoComplete="off"
      >
        {allowNull && (
          <option value={''} key={'null'}>
            All dates
          </option>
        )}
        {Object.keys(dateOptions).map(opt => (
          <option value={opt} key={opt}>
            {dateOptions[opt]}
          </option>
        ))}
      </Select>
    </div>
  );
};

RelativeDateSelector.props = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf(Object.keys(RELATIVE_DATES)).isRequired,
  allowNull: PropTypes.bool, // Set to true if it can be null, eg if you want to select "All"
  onChange: PropTypes.func.isRequired
};

export default RelativeDateSelector;
