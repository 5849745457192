import { useState } from 'react';

import { omit } from 'lodash';
import { v4 } from 'uuid';

// Hook helper map array items to object with uuid keys
function mapItemsToObject(items = []) {
  return Object.assign({}, ...items.map(item => ({ [v4()]: item })));
}

// TEMP flesh out generic collection hook?
// Could be a nice way of managing sets of things
// where one/many + add/remove needed, without
// being tied to specific use case?
function useSelection(selectedItems = []) {
  const defaultState = mapItemsToObject(selectedItems.filter(item => item));
  const [items, setItems] = useState(defaultState);

  function addItem(item = {}) {
    setItems(items => ({
      ...items,
      [v4()]: item
    }));
  }

  function removeItem(key) {
    setItems(items => omit(items, key));
  }

  function updateItems(items = []) {
    setItems(mapItemsToObject(items));
  }

  return { items, updateItems, addItem, removeItem };
}

export default useSelection;
