import React from 'react';

import {
  InputMetaProps,
  InputStylingProps
} from 'shared/inputs/__types__/Inputs';
import { RestrictedInput, TextInput } from 'shared';

export type EmailInputProps = InputMetaProps &
  InputStylingProps & {
    value?: string | null;
    onChange?(value: string): void;
    onBlur?(value: string): void;
  };

const EmailInput = ({
  value,
  isRestricted,
  onChange,
  onBlur,
  ...restProps
}: EmailInputProps) => {
  if (isRestricted) {
    return <RestrictedInput {...restProps} />;
  }

  return (
    <TextInput
      type="email"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default EmailInput;
