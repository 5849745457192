import { createSelector } from 'reselect';
import { keys, filter, orderBy } from 'lodash';

import * as entityTypes from 'v1/helpers/entityTypes';

import {
  isAvailabilityStatus,
  isBookingStatus,
  isProductionStatus
} from 'v1/helpers/byModel/StatusHelper';

export const getStatusStore = state => state.statuses;

export const getStatuses = createSelector(
  state => state.statuses.data,
  (_, { archived = false } = { archived: false }) => archived,
  (_, { entity_type = null } = { entity_type: null }) => entity_type,
  (_, { status_type = null } = { status_type: null }) => status_type,
  (data, archived, entity_type, status_type) => {
    const types = keys(data).reduce((result, id) => {
      if (!archived && data[id].archived) return result;
      if (entity_type && data[id].entity_type !== entity_type) return result;
      if (status_type && data[id].status_type !== status_type) return result;
      return result.concat(data[id]);
    }, []);
    return orderBy(types, ['order', 'id'], ['asc', 'asc']);
  }
);

// TODO: rename
// Includes archived statuses
export const getAllStatuses = createSelector(
  state => state.statuses.data,
  statuses =>
    orderBy(statuses, ['entity_type', 'order', 'id'], ['asc', 'asc', 'asc'])
);

// TODO: rename
// Excludes archived statuses
export const getValidStatuses = createSelector(
  state => state.statuses.data,
  statuses => filter(statuses, s => s.archived === false),
  statuses =>
    orderBy(statuses, ['entity_type', 'order', 'id'], ['asc', 'asc', 'asc'])
);

// TODO: this could be abstracted much better
// TODO: rename to differentiate from below
// TODO: This one differentiates by entity_type
export const getBookingsStatuses = createSelector(
  [getValidStatuses],
  statuses => filter(statuses, s => s.entity_type === entityTypes.BOOKING)
);

// TODO: These ones differentiates by status_type
// TODO: some of these could be a factory
export const getAvailabilityStatuses = createSelector(
  [getValidStatuses],
  statuses => filter(statuses, s => isAvailabilityStatus(s))
);

export const getAvailabilityStatusIds = createSelector(
  [getAvailabilityStatuses],
  statuses => statuses.map(s => s.id)
);

export const getBookingStatuses = createSelector(
  [getBookingsStatuses],
  statuses => filter(statuses, s => isBookingStatus(s))
);

export const getBookingStatusIds = createSelector(
  [getBookingStatuses],
  statuses => statuses.map(s => s.id)
);

export const getProductionStatuses = createSelector(
  [getValidStatuses],
  statuses => filter(statuses, s => isProductionStatus(s))
);

export const getProductionStatusIds = createSelector(
  [getProductionStatuses],
  statuses => statuses.map(s => s.id)
);
