import { useQueryClient } from '@tanstack/react-query';
import { findIndex } from 'lodash';
import orderBy from 'lodash/orderBy';
import {
  AssociationQuery,
  AssociationState,
  mutationSchemas,
  ResourceShortlistAssociationRequestOf,
  ShortlistBlock_normalized
} from 'v4/entities/associations/resource__shortlist/resource__shortlist.schemas';
import { ListQuery, ListResponse } from 'v4/entities/common/common.crud.types';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { Resource_normalized } from 'v4/entities/resource/resource.types';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { canBlockAffectAssociation } from './resource__shortlist.reducer.helpers';

export const createMoveEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi,
  useEnsureList: () => (
    query: AssociationQuery
  ) => Promise<
    ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
  >
) => {
  return defineEntityEffect({
    type: 'move',
    requestSchema: mutationSchemas.move.request,
    responseSchema: mutationSchemas.move.response,
    useQueryFn: () => {
      const client = useQueryClient();
      const api = useApi();
      const ensureList = useEnsureList();
      return async (request, signal) => {
        const blocks = await ensureList({
          shortlist_id: request.shortlist_id
        });
        return api.updateBatch(
          {
            shortlist_id: request.shortlist_id,
            payload: getMoveUpdatePayloads(blocks.results, request)
              .filter(
                block =>
                  block.order !==
                  blocks.results.find(item => item.id === block.id)?.order
              )
              .filter(b => b.order !== -1)
          },
          signal
        );
      };
    }
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        if (canBlockAffectAssociation(query, undefined, request.shortlist_id)) {
          // reordering happens on shortlist view, so we don't optimistically touch other views
          // order is not relevant when displaying blocks from multiple shortlists (ie resource's view of associated shortlists)

          const updates = getMoveUpdatePayloads(state.results, request);

          return {
            ...state,
            results: state.results.map(block => {
              const found = updates.find(item => item.id === block.id);
              if (found) {
                return {
                  ...block,
                  ...found
                };
              }
              return block;
            })
          };
        }
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      return {
        state: config.optimisticReconciler(state, request, query),
        shouldInvalidate: true
      };
    })
    .build();
};

const getMoveUpdatePayloads = (
  blocks: ShortlistBlock_normalized[],
  request: ResourceShortlistAssociationRequestOf<'move'>
) => {
  const orderedBlocks = orderBy(blocks, 'order', 'asc'); // no guarantee that blocks are ordered by 'order' field
  const { activeId, overId } = request;
  const activeBlockIndex = findIndex(orderedBlocks, { id: activeId });
  const overBlockIndex = findIndex(orderedBlocks, { id: overId });

  if (activeBlockIndex === -1 || overBlockIndex === -1) {
    throw new Error(
      `Block with id ${activeId} or ${overId} not found the view ${request.shortlist_id}`
    );
  }

  const [movedBlock] = orderedBlocks.splice(activeBlockIndex, 1);
  orderedBlocks.splice(overBlockIndex, 0, movedBlock);

  return orderedBlocks.map(block => ({
    id: block.id,
    order: orderedBlocks.indexOf(block)
  }));
};
