import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useEvent from 'v1/helpers/hooks/useEvent';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { deleteResourceSlot } from 'store/v1/productions/productions.actions.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';

import { MenuItem, RestrictedDropdown } from 'v1/components/shared';
import { Capability } from '__types__';

// TODO: should use new flyover
const ResourceSlotOptions = ({ slot }) => {
  const dispatch = useDispatch();

  useEvent(PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT, {
    onSuccess: () => dispatch(displayAlert('success', 'Role removed')),
    onFailure: () => dispatch(displayAlert('error', 'Something went wrong'))
  });

  const onSlotDelete = () => {
    const canDelete =
      !slot.resource_slot_assignments ||
      !slot.resource_slot_assignments.length ||
      !slot.resource_slot_assignments.some(assignment => !assignment.archived);

    canDelete
      ? dispatch(
          openModal('ConfirmModal', {
            title: 'Are you sure you want to delete this role?',
            confirmLabel: 'Confirm delete',
            onConfirm: () =>
              dispatch(
                deleteResourceSlot({
                  production_id: slot.production_id,
                  resource_slot_id: slot.id
                })
              )
          })
        )
      : dispatch(
          openModal('AlertModal', {
            title: 'Cannot delete role if a resource is assigned',
            description:
              'A role cannot be removed if it has a resource assigned to it. You’ll need to remove the resource from the role before you can delete it.'
          })
        );
  };

  return (
    <RestrictedDropdown
      capabilitiesAtLeastOne={[
        Capability.PRODUCTION_READ,
        Capability.PRODUCTION_UPDATE
      ]}
      buttonIcon={<img src="/images/icon_ellipsis.svg" alt="" />}
      buttonClass="btn btn-link btn-small btn-square"
    >
      <MenuItem
        capabilities={Capability.PRODUCTION_UPDATE}
        onSelect={onSlotDelete}
      >
        <span className="MenuItem-icon">
          <img src="/images/icon_delete.svg" alt="" />
        </span>
        <span className="MenuItem-label">Remove role</span>
      </MenuItem>
    </RestrictedDropdown>
  );
};

ResourceSlotOptions.propTypes = {
  slot: PropTypes.object
};

export default ResourceSlotOptions;
