import { Combobox, ComboboxOptionProps } from '@headlessui/react';
import classNames from 'classnames';

export function SearchPanelSelectOptionBase<T>({
  className,
  ...rest
}: ComboboxOptionProps<'div', T>) {
  return (
    <Combobox.Option
      className={bag =>
        classNames(
          'tw-border tw-border-1 tw-p-3 tw-flex tw-gap-2 tw-items-center tw-align-middle tw-cursor-pointer',
          'first-of-type:tw-rounded-t-md last-of-type:tw-rounded-b-md [&:not(:last-child)]:tw-border-b-0',
          {
            'tw-bg-gray-100': bag.active
          },
          typeof className === 'function' ? className(bag) : className
        )
      }
      {...rest}
    />
  );
}
