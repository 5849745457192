import React from 'react';
import PropTypes from 'prop-types';
import './CostSummary.scss';
import {
  currencyFormatter,
  convertToWholeUnit
} from 'v1/helpers/currencyHelper';
import { ResourceRateIntervals } from 'v1/helpers/DropdownDataSets';
import {
  getBillableUnitCount,
  calculateBookingSubtotal
} from 'v1/helpers/byModel/BookingHelper';
import { getFormattedEventDates } from 'v1/helpers/byModel/EventHelper';
import { Grid, GridCell } from 'v1/components/shared';
import _sumBy from 'lodash/sumBy';
import {
  calculateAgencyFeeTotal,
  calculatePayrollFeeTotal,
  calculateProcessingFeeTotal,
  calculateProductionBudgetLineSubtotal
} from 'v1/helpers/budgetHelper';

// Get label for displaying billable unit count
export const getIntervalCountLabel = (event, rate) => {
  if (rate.rate_interval === 'DAILY') return 'days';
  if (event.date_type === 'RECURRING_DEFAULT') return 'hours (@ 8 hours / day)';
  return 'hours';
};

const CostSummary = ({
  contactName,
  rate,
  agencyFee,
  payrollFee,
  processingFee,
  bookingDates
}) => {
  const formattedRate = {
    ...rate,
    rate_interval: rate.interval
  };
  const interval = ResourceRateIntervals[formattedRate.rate_interval];

  const rateTotal =
    rate.interval === 'FIXED'
      ? rate.amount
      : _sumBy(bookingDates, e => calculateBookingSubtotal(e, formattedRate));
  const agencyFeeTotal = agencyFee
    ? calculateAgencyFeeTotal(rateTotal, agencyFee)
    : null;
  const payrollFeeTotal = payrollFee
    ? calculatePayrollFeeTotal(rateTotal, agencyFeeTotal, payrollFee)
    : null;
  const subTotal = calculateProductionBudgetLineSubtotal(
    rateTotal,
    agencyFeeTotal,
    payrollFeeTotal
  );
  const processingFeeTotal = processingFee
    ? calculateProcessingFeeTotal(subTotal, processingFee)
    : null;
  const total = subTotal + processingFeeTotal;

  return (
    <div className="CostSummary">
      <Grid cellPadding="S" spread>
        <GridCell>{contactName && `${contactName}'s`} Rate</GridCell>
        <GridCell>
          {currencyFormatter(null, formattedRate.currency).format(
            convertToWholeUnit(formattedRate.amount)
          )}{' '}
          {interval && interval.label}
        </GridCell>
      </Grid>

      {formattedRate.rate_interval !== 'FIXED' &&
        bookingDates.map((evt, index) => (
          <div className="CostSummary-event" key={index}>
            {bookingDates.length > 1 && (
              <span className="text-13-600-eggplant">
                {getFormattedEventDates(evt)}
              </span>
            )}
            <Grid spread>
              <GridCell>
                Total {getIntervalCountLabel(evt, formattedRate)}
              </GridCell>
              <GridCell>{getBillableUnitCount(evt, formattedRate)}</GridCell>
            </Grid>
          </div>
        ))}
      {!!rateTotal && (
        <Grid cellPadding="S" spread>
          <GridCell>Resource fee</GridCell>
          <GridCell>
            {currencyFormatter(null, formattedRate.currency).format(
              convertToWholeUnit(rateTotal || 0)
            )}
          </GridCell>
        </Grid>
      )}
      {!!agencyFee && (
        <Grid cellPadding="S" spread>
          <GridCell>Agency fee ({agencyFee}%)</GridCell>
          <GridCell>
            {currencyFormatter(null, formattedRate.currency).format(
              convertToWholeUnit(agencyFeeTotal || 0)
            )}
          </GridCell>
        </Grid>
      )}
      {!!payrollFee && (
        <Grid cellPadding="S" spread>
          <GridCell>Payroll fee ({payrollFee}%)</GridCell>
          <GridCell>
            {currencyFormatter(null, formattedRate.currency).format(
              convertToWholeUnit(payrollFeeTotal || 0)
            )}
          </GridCell>
        </Grid>
      )}
      <Grid className="CostSummary-subtotal" cellPadding="S" spread>
        <GridCell>Subtotal</GridCell>
        <GridCell>
          {currencyFormatter(null, formattedRate.currency).format(
            convertToWholeUnit(subTotal || 0)
          )}
        </GridCell>
      </Grid>
      {!!processingFee && (
        <Grid cellPadding="S" spread>
          <GridCell>Processing fee ({processingFee}%)</GridCell>
          <GridCell>
            {currencyFormatter(null, formattedRate.currency).format(
              convertToWholeUnit(processingFeeTotal || 0)
            )}
          </GridCell>
        </Grid>
      )}
      <Grid className="CostSummary-total" cellPadding="S" spread>
        <GridCell>Estimated total</GridCell>
        <GridCell>
          {currencyFormatter(null, formattedRate.currency).format(
            convertToWholeUnit(total || 0)
          )}
        </GridCell>
      </Grid>
    </div>
  );
};

CostSummary.propTypes = {
  contactName: PropTypes.string,
  rate: PropTypes.shape({
    currency: PropTypes.any, // TODO
    amount: PropTypes.any, // TODO
    interval: PropTypes.oneOf(['DAILY', 'HOURLY', 'FIXED'])
  }),
  agencyFee: PropTypes.any
};

export default CostSummary;
