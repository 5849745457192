import {
  convertVideoFileWithCloudinary,
  getVideoCoverImage
} from 'lib/media/videoHelper';
import { Component } from 'react';
import { VideoPlayer } from 'v1/components/shared';

export default class PinVideo extends Component {
  render() {
    const { pin, videoSettings } = this.props;

    const type = pin.pin_type === 'file' ? pin.file.mime_type : pin.type;
    const videoUrl =
      pin.pin_type === 'file'
        ? convertVideoFileWithCloudinary(pin.url)
        : pin.url;
    const coverImage = getVideoCoverImage(pin.url);

    return (
      <div className="PinVideo">
        <VideoPlayer
          settings={videoSettings}
          src={videoUrl}
          type={type}
          coverImage={coverImage}
        />
      </div>
    );
  }
}
