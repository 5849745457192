import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Currencies } from 'v1/helpers/DropdownDataSets';
import { selectDefaultCurrency } from 'store/v1/auth/auth.selectors';

import TypeFormComponent from 'v1/components/shared/__types__/TypeFormComponent';
import { TextInputGroupProps } from 'v1/components/shared/byType/text/TextInputGroup/TextInputGroup';
import { TextInputGroup } from 'v1/components/shared';

type CurrencyInputPropsValue = {
  currency: Currency | null;
  amount: CurrencyAmount | null;
};

type CurrencyInputReturnValue = {
  currency: Currency;
  amount: CurrencyAmount | null;
};

interface CurrencyInputProps
  extends Omit<TextInputGroupProps, 'value' | 'onChange' | 'onBlur'> {
  defaultCurrency?: Currency;
  value: CurrencyInputPropsValue;
  onChange?(value: CurrencyInputReturnValue): void;
  onBlur?(value: CurrencyInputReturnValue): void;
}

// Backup in case there's no global default currency
const systemDefaultCurrency = 'USD';

const CurrencyInput = ({
  defaultCurrency,
  value = { currency: null, amount: null },
  onChange,
  onBlur,
  ...rest
}: CurrencyInputProps) => {
  const globalDefaultCurrency = useSelector(selectDefaultCurrency);
  const [defaultCurr, setDefaultCurr] = useState<string>(systemDefaultCurrency);

  useEffect(() => {
    defaultCurrency
      ? setDefaultCurr(defaultCurrency)
      : globalDefaultCurrency
      ? setDefaultCurr(globalDefaultCurrency)
      : setDefaultCurr(systemDefaultCurrency);
  }, [defaultCurrency, globalDefaultCurrency]);

  const prepValue = (v: CurrencyInputPropsValue): CurrencyInputReturnValue => {
    return { currency: v.currency || defaultCurr, amount: v.amount || null };
  };

  const handleChange = (value: CurrencyInputReturnValue): void => {
    onChange && onChange(value);
  };

  const handleBlur = (value: CurrencyInputReturnValue): void => {
    onBlur && onBlur(value);
  };

  return (
    <TextInputGroup
      {...rest}
      type="currency"
      value={prepValue(value)}
      valueKey="amount"
      prepend={{
        valueKey: 'currency',
        options: Currencies.map(c => ({
          value: c.code,
          label: c.symbol
        }))
      }}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

CurrencyInput.propTypes = {
  ...TypeFormComponent,
  defaultCurrency: PropTypes.string,
  value: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number
  }),
  label: PropTypes.string,
  showOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func
};

export default CurrencyInput;
