import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-form';

import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants';
import {
  createShortlist,
  updateShortlist
} from 'store/v1/shortlists/shortlists.actions.js';

import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation,
  TextInputField
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import useEvent from 'v1/helpers/hooks/useEvent';

import './ShortlistCreateModal.scss';

const ShortlistCreateModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { app_id } = useSelector(state => state.auth);
  const { data = {} } = useSelector(state => state.ui);
  const shortlists = useSelector(state => state.shortlists);

  const [formApi, setFormApi] = useState({});
  const isCreate = data.create;
  const [shortlist, setShortlist] = useState(data.shortlist || {});

  const { loading } = shortlists;
  const { title } = shortlist;

  useEvent(
    [SHORTLISTS_ACTIONS.CREATE_SHORTLIST, SHORTLISTS_ACTIONS.UPDATE_SHORTLIST],
    {
      onSuccess: () => {
        formApi.resetAll();

        if (isCreate) {
          const id = shortlists.new.id;
          history.push(`/app/${app_id}/shortlists/${id}`);
        }
        onRequestClose();
      },
      onFailure: () => dispatch(displayAlert('error', shortlists.error.message))
    }
  );

  function handleSubmit(values) {
    isCreate
      ? dispatch(createShortlist(values))
      : dispatch(updateShortlist(shortlist.id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={isCreate ? 'Create shortlist' : 'Edit shortlist'} />
      <ModalContent>
        <ModalScrollable padding="L">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              title,
              ...(isCreate ? shortlist : {})
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm} id="shortlist-create-form">
                <TextInputField
                  label="Title"
                  field="title"
                  placeholder="Shortlist title"
                  validate="required"
                  initialFocus
                  removeAutocomplete
                />
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            hasMarginRight
            action={onRequestClose}
          />
          <PressStud
            label={isCreate ? 'Create' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === 'CREATE_SHORTLIST' || loading === 'UPDATE_SHORTLIST'
            }
            dataCy="save"
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ShortlistCreateModal;
