import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { RootStore } from 'store/reducer';
import { selectActiveAccountId } from 'store/session';
import { selectUserGroups } from 'store/userGroups';

const selectAccountsStore = (state: RootStore) => state.accounts;

const selectAccountsAreLoading = createSelector(
  selectAccountsStore,
  accounts => accounts.loading
);

const selectAccountsAreFetched = createSelector(
  selectAccountsStore,
  accounts => accounts.fetched
);

const selectAccounts = createSelector(
  selectAccountsStore,
  accounts => accounts.data
);

const selectAccountsList = createSelector(
  selectAccounts,
  (_: RootStore, options: { active?: boolean } = { active: undefined }) =>
    options,
  (data, { active }) => {
    const accounts = Object.values(data);
    const filteredAccounts = active
      ? accounts.filter(account => !account.dateArchived)
      : accounts;
    return filteredAccounts;
  }
);

const selectAccountById = createCachedSelector(
  selectAccounts,
  (_: RootStore, id: string) => id,
  (data, id) => data[id]
)((_, id) => id);

const selectActiveAccount = createSelector(
  state => state,
  selectActiveAccountId,
  (state, id) => (id ? selectAccountById(state, id) : null)
);

const selectAllAccountsByUserGroup = createSelector(
  state => selectUserGroups(state),
  selectAccountsList,
  (userGroups, accounts) => {
    const userGroupsKeyed: Record<string, string[]> = Object.keys(
      userGroups
    ).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
    return accounts.reduce((acc, account) => {
      account.userGroupIds.forEach(userGroupId => {
        if (acc[userGroupId]) {
          acc = {
            ...acc,
            [userGroupId]: [...acc[userGroupId], account.id]
          };
        }
      });
      return acc;
    }, userGroupsKeyed);
  }
);

const selectAccountsByUserGroupId = createCachedSelector(
  selectAccountsList,
  (_: RootStore, userGroupId: string) => userGroupId,
  (accounts, userGroupId) =>
    accounts.filter(account => account.userGroupIds.includes(userGroupId))
)((_, userGroupId) => userGroupId);

export {
  selectAccountsStore,
  selectAccountsAreLoading,
  selectAccountsAreFetched,
  selectAccounts,
  selectAccountsList,
  selectAccountById,
  selectActiveAccount,
  selectAllAccountsByUserGroup,
  selectAccountsByUserGroupId
};
