import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import Autosuggest from 'react-autosuggest';
import './SuggestedTextInput.scss';

class SuggestedTextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      selected: false,
      value: '',
      suggestions: [],
      availableSuggestions: props.suggestions
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
    if (this.props.suggestions.length !== nextProps.suggestions.length) {
      this.setState({ suggestions: nextProps.suggestions });
    }
  };
  onChange = (event, { newValue }) => {
    this.setState(
      {
        selected: false,
        value: newValue
      },
      () => {
        this.props.onChange(this.props.field, this.state.value);
      }
    );
  };

  onFocus = () => {
    this.setState({ focused: true, selected: false }, () => {
      if (this.props.suggestions.length) {
        return this.updateSuggestions(this.state.value);
      }
      this.props.getSuggestions();
    });
  };

  onBlur = () => this.setState({ focused: false });

  updateSuggestions = input => {
    return this.setState(
      {
        suggestions: this.props.suggestions.filter(item =>
          item.toLowerCase().includes(input.trim().toLowerCase())
        )
      },
      this.forceUpdate
    );
  };
  getSuggestionValue = suggestion => suggestion;

  renderSuggestion = suggestion => <div>{suggestion}</div>;

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (!this.state.focused || this.state.selected) return null;
    return (
      <div {...containerProps}>
        {(() => {
          if (this.props.fetching) {
            // if (this.state.suggestions.length) {
            return (
              <div className="SuggestedTextInput-loading">
                <ReactLoading
                  type="spin"
                  color="#190026"
                  height={15}
                  width={15}
                />
              </div>
            );
          }
          if (this.state.suggestions.length) return children;
          return <p className="SuggestedTextInput-empty">Nothing found</p>;
        })()}
      </div>
    );
  };

  shouldRenderSuggestions = () => true;

  onSuggestionsFetchRequested = ({ value, reason }) =>
    this.updateSuggestions(value, reason);

  onSuggestionsClearRequested = () => {
    this.setState({ selected: true });
  };

  onEnter = event => {
    if (!this.props.onEnter) return;

    event.preventDefault();
    if (event.keyCode === 13) {
      this.props.onEnter();
    }
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: this.props.placeholder,
      value: value || '',
      onChange: this.onChange,
      className: this.props.className,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyUp: this.onEnter
    };

    const theme = {
      container: 'SuggestedTextInput',
      suggestionsContainer: 'SuggestedTextInput-dropdownContainer',
      suggestionsContainerOpen: 'SuggestedTextInput-dropdownContainer-open',
      suggestionsList: 'SuggestedTextInput-dropdownContainer-list',
      suggestion: 'SuggestedTextInput-dropdownContainer-item',
      suggestionHighlighted:
        'SuggestedTextInput-dropdownContainer-item-highlighted'
    };

    return (
      <Autosuggest
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        highlightFirstSuggestion
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        inputProps={inputProps}
        theme={theme}
        className="TextInput"
      />
    );
  }
}

// NOTE: wrapper for react-autosuggest
SuggestedTextInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  fetching: PropTypes.bool,
  suggestions: PropTypes.array, // TODO: array type
  onChange: PropTypes.func, // TODO: arguments
  onEnter: PropTypes.func, // TODO: arguments
  getSuggestions: PropTypes.func // TODO: arguments
};

export default SuggestedTextInput;
