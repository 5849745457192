const HELPER_WIDTH = 130;
const HELPER_SPACER = 10;

const SPACER = 5;
const HELPER = parseInt(HELPER_WIDTH) + parseInt(HELPER_SPACER);

// Positions
const TOP = 'top';
const BOTTOM = 'bottom';
const LEFT = 'left';
const RIGHT = 'right';

// Clearances
const SPACED = 'spaced';
const FLUSH = 'flush';
const OVERLAY = 'overlay';

const flyoverPositioner = (
  trigger,
  content,
  hasHelper,
  position = BOTTOM,
  clearance = SPACED,
  forcePosition = false
) => {
  const leftLimit = 0;
  const rightLimit = window.innerWidth;
  const topLimit = 0;
  const bottomLimit = window.innerHeight;

  const isHorizontalPosition = position === RIGHT || position === LEFT;
  const isVerticalPosition = position === BOTTOM || position === TOP;

  const isTriggerOffscreenHorizontal =
    trigger.left < leftLimit || trigger.right > rightLimit;

  // X CALCULATION

  const calcX = pos => {
    if (pos === RIGHT) {
      return trigger.right;
    }
    if (pos === LEFT) {
      return trigger.left - content.width;
    }

    const baseX = trigger.left;

    return hasHelper ? baseX - HELPER : baseX;
  };

  const getXLimit = pos =>
    pos === LEFT ? leftLimit : rightLimit - content.width;

  const checkXOverlap = (pos, x) =>
    pos === LEFT ? x < leftLimit : x + content.width > rightLimit;

  const getXOpp = pos => (pos === LEFT ? RIGHT : LEFT);

  const getX = () => {
    const x = calcX(position, clearance);

    if (
      (isHorizontalPosition && forcePosition) ||
      isTriggerOffscreenHorizontal
    ) {
      return x;
    }

    if (checkXOverlap(position, x)) {
      if (isVerticalPosition) {
        return getXLimit(position);
      }

      const opp = getXOpp(position);
      const oppX = calcX(opp);

      if (!checkXOverlap(opp, oppX)) return oppX;
    }

    return x;
  };

  // Y CALCULATION

  const yCalc = {
    [TOP]: {
      [SPACED]: () => trigger.top - content.height - SPACER,
      [FLUSH]: () => trigger.top - content.height,
      [OVERLAY]: () => trigger.bottom - content.height
    },
    [BOTTOM]: {
      [SPACED]: () => trigger.bottom + SPACER,
      [FLUSH]: () => trigger.bottom,
      [OVERLAY]: () => trigger.top
    }
  };

  const calcY = (pos, clear = clearance) => yCalc[pos][clear]();

  const checkYOverlap = (pos, y) =>
    pos === TOP ? y < topLimit : y + content.height > bottomLimit;

  const getYOpp = pos => (pos === TOP ? BOTTOM : TOP);

  const getY = () => {
    const pos = isHorizontalPosition ? BOTTOM : position;
    const clear = isHorizontalPosition ? OVERLAY : clearance;

    const y = calcY(pos, clear);

    if (!(isVerticalPosition && forcePosition) && checkYOverlap(pos, y)) {
      const opp = getYOpp(pos);
      const oppY = calcY(opp, clear);
      if (!checkYOverlap(opp, oppY)) return oppY;
    }

    return y;
  };

  // RETURN

  const x = getX();
  const y = getY();

  if (isNaN(x) || isNaN(y)) {
    return {
      display: 'none'
    };
  }

  return {
    left: getX(),
    top: getY()
  };
};

export default flyoverPositioner;
