import { Capability, FeatureFlag, Team } from '__types__';
import { Can } from 'lib/restrictedAccess/useRestrictedAccess';
import { useState } from 'react';
import { parseInitials } from 'v1/helpers/misc';
import { withinSettings } from 'v4/core/appRoutes';
import Avatar from 'v4/shared/components/Avatar/Avatar';
import Separated from '../../../shared/components/Separated';
import SidebarAccordion from './SidebarAccordion';
import SidebarLinkSecondary from './SidebarLinkSecondary';
import SidebarSeparator from './SidebarSeparator';

export type TeamSettingsProps = {
  team: Partial<Team>; // todo: teams model will change in the future
  can: Can;
};
const TeamSettings = ({ team, can }: TeamSettingsProps) => {
  const { dbSchema, name } = team;
  const to = withinSettings(dbSchema);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <SidebarAccordion
      onToggle={() => {
        setIsExpanded(prev => !prev);
      }}
      isExpanded={isExpanded}
      icon={
        <Avatar
          // src={undefined} todo: We currently do not have a dedicated team image
          fallback={parseInitials(name)}
          size="sm"
          className="tw-bg-white/40"
        />
      }
      label={name}
    >
      <Separated divider={<SidebarSeparator className="tw-my-2" />}>
        <Separated.Group>
          {can(null, Capability.CONFIGURE_WORKSPACE) && (
            <SidebarLinkSecondary to={to.settings.team.publicProfile()}>
              Public profile
            </SidebarLinkSecondary>
          )}
          {can(null, Capability.CONFIGURE_FIELDS) && (
            <SidebarLinkSecondary to={to.settings.team.fields()}>
              Fields
            </SidebarLinkSecondary>
          )}
          {can(null, Capability.CONFIGURE_TAGS) && (
            <SidebarLinkSecondary to={to.settings.team.tags()}>
              Tags
            </SidebarLinkSecondary>
          )}
          {can(null, Capability.CONFIGURE_STATUSES) && (
            <SidebarLinkSecondary to={to.settings.team.statuses()}>
              Statuses
            </SidebarLinkSecondary>
          )}
        </Separated.Group>
        <Separated.Group>
          {can(FeatureFlag.RESOURCES, Capability.CONFIGURE_RESOURCE_TYPES) && (
            <SidebarLinkSecondary to={to.settings.team.resourceTypes()}>
              Resource types
            </SidebarLinkSecondary>
          )}
          {can(FeatureFlag.RESOURCES, Capability.CONFIGURE_RATE_CARDS) && (
            <SidebarLinkSecondary to={to.settings.team.rateCards()}>
              Rate cards
            </SidebarLinkSecondary>
          )}
        </Separated.Group>
        <Separated.Group>
          {can(
            FeatureFlag.PRODUCTIONS,
            Capability.CONFIGURE_PRODUCTION_TYPES
          ) && (
              <SidebarLinkSecondary to={to.settings.team.productionTypes()}>
                Production types
              </SidebarLinkSecondary>
            )}
          {can(
            FeatureFlag.PRODUCTIONS,
            Capability.CONFIGURE_PRODUCTION_TEMPLATES
          ) && (
              <SidebarLinkSecondary
                to={to.settings.team.productionTemplates()}
              >
                Production templates
              </SidebarLinkSecondary>
            )}
          {can(
            FeatureFlag.CALLSHEETS,
            Capability.CONFIGURE_CALLSHEET_TEMPLATES
          ) && (
              <SidebarLinkSecondary
                to={to.settings.team.callsheetTemplates()}
              >
                Callsheet templates
              </SidebarLinkSecondary>
            )}
          {can(FeatureFlag.BOOKINGS, Capability.CONFIGURE_EXPENSE_TYPES) && (
            <SidebarLinkSecondary to={to.settings.team.expenseTypes()}>
              Expense types
            </SidebarLinkSecondary>
          )}
          {can(
            FeatureFlag.PRODUCTIONS,
            Capability.CONFIGURE_SLOT_CATEGORIES
          ) && (
              <SidebarLinkSecondary to={to.settings.team.slotCategories()}>
                Slot categories
              </SidebarLinkSecondary>
            )}
          {can(FeatureFlag.PRODUCTIONS, Capability.CONFIGURE_ALERTS) && (
            <SidebarLinkSecondary to={to.settings.team.alerts()}>
              Alerts
            </SidebarLinkSecondary>
          )}
        </Separated.Group>
        <Separated.Group>
          {can(FeatureFlag.BOOKINGS, Capability.CONFIGURE_BOOKING_TYPES) && (
            <SidebarLinkSecondary to={to.settings.team.bookingTypes()}>
              Booking types
            </SidebarLinkSecondary>
          )}
          {can(
            FeatureFlag.BOOKING_CONFIRMATION,
            Capability.CONFIGURE_BOOKING_CONFIRMATION_TEMPLATES
          ) && (
              <SidebarLinkSecondary
                to={to.settings.team.confirmationTemplates()}
              >
                Confirmation templates
              </SidebarLinkSecondary>
            )}
        </Separated.Group>
        <Separated.Group>
          {can(FeatureFlag.RESOURCES, Capability.MANAGE_DATA_IMPORTS) && (
            <SidebarLinkSecondary to={to.settings.team.imports()}>
              Imports
            </SidebarLinkSecondary>
          )}
          {can(
            FeatureFlag.RESOURCES,
            Capability.MANAGE_RESOURCE_DUPLICATES
          ) && (
              <SidebarLinkSecondary to={to.settings.team.duplicates()}>
                Duplicates
              </SidebarLinkSecondary>
            )}
        </Separated.Group>
        <Separated.Group>
          {can(
            FeatureFlag.NIKECUSTOM_ARRIVAL_BOARD,
            Capability.CONFIGURE_CUSTOM_NIKE_ARRIVAL_BOARD
          ) && (
              <SidebarLinkSecondary to={to.settings.team.arrivalBoard()}>
                Arrival board
              </SidebarLinkSecondary>
            )}
        </Separated.Group>
      </Separated>
    </SidebarAccordion>
  );
};

export default TeamSettings;
