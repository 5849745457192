import { v4 } from 'uuid';

export interface Guest {
  full_name: string | null;
  id: string;
}

// small react-enabled store to listen to guest changes
export const guestUpdateEvent = {
  listeners: [] as Array<() => void>,
  addListener: (listener: () => void) => {
    guestUpdateEvent.listeners.push(listener);
    return () => {
      guestUpdateEvent.listeners = guestUpdateEvent.listeners.filter(
        l => l !== listener
      );
    };
  },
  counter: 0,
  trigger: () => {
    guestUpdateEvent.counter++;
    guestUpdateEvent.listeners.forEach(l => l());
  }
};

export function updateGuest(guest: Guest) {
  localStorage['Stitch.Guest'] = JSON.stringify(guest);
  guestUpdateEvent.trigger();
}

export function getGuest(): Guest | undefined {
  return JSON.parse(localStorage['Stitch.Guest']);
}

export function initiateGuestSession(): Guest {
  if (localStorage['Stitch.Guest']) {
    return JSON.parse(localStorage['Stitch.Guest']);
  } else {
    const newGuest = {
      full_name: null,
      id: v4()
    };
    localStorage['Stitch.Guest'] = JSON.stringify(newGuest);
    guestUpdateEvent.trigger();
    return newGuest;
  }
}

export function deleteGuest() {
  localStorage.removeItem('Stitch.Guest');
}
