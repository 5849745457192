import PropTypes from 'prop-types';

import { ModalClose } from 'v1/components/shared';

import classnames from 'classnames';

import './ModalWrapper.scss';

const ModalWrapper = ({ size, children, className, hideClose }) => {
  return (
    <div
      className={classnames(
        {
          [`Modal_size_${size}`]: size
        },
        ['ModalWrapper', className]
      )}
    >
      {!hideClose && <ModalClose />}
      {children}
    </div>
  );
};

ModalWrapper.propTypes = {
  size: PropTypes.oneOf(['XS', 'S', 'M', 'L', 'XL']),
  className: PropTypes.string,
  hideClose: PropTypes.bool
};

ModalWrapper.defaultProps = {
  size: 'M'
};

export default ModalWrapper;
