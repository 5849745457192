import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const CONTACT_IMPORTS_ACTIONS = mapValues(
  mirror({
    GET_CONTACT_IMPORTS: null,
    GET_CONTACT_IMPORTS_SUCCESS: null,
    GET_CONTACT_IMPORTS_FAILURE: null,

    CREATE_CONTACT_IMPORT: null,
    CREATE_CONTACT_IMPORT_SUCCESS: null,
    CREATE_CONTACT_IMPORT_FAILURE: null,

    UPDATE_CONTACT_IMPORT: null,
    UPDATE_CONTACT_IMPORT_SUCCESS: null,
    UPDATE_CONTACT_IMPORT_FAILURE: null
  }),
  value => value
);
