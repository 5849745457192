import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { Form } from 'react-form';

import { ErrorMessage, PressStud, TextInputField } from 'v1/components/shared';
import Authentication from 'lib/auth/authentication';

const Status = {
  INITIAL: 1,
  SENDING: 2,
  SUCCESS: 3,
  FAILED: 4
};

const RecoverPasswordForm = ({ className }) => {
  const [status, setStatus] = useState(Status.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async form => {
    try {
      setStatus(Status.SENDING);

      const { username } = form;
      // Validate form
      if (!username) {
        return setErrorMessage('Email address cannot be empty');
      }

      await Authentication.recoverPassword(username);
      setStatus(Status.SUCCESS);
    } catch (err) {
      console.error(err);
      const message =
        get(err, 'response.body.error.message') || 'Something went wrong';
      setStatus(Status.FAILED);
      setErrorMessage(message);
    }
  };

  return (
    <div className={classNames('OnboardingForm FormBlock', className)}>
      <div className="FormBlock-header">
        <h2>
          <strong>Recover your password</strong>
        </h2>
        <h3>It happens to the best of us!</h3>
      </div>
      <div className="FormBlock-content">
        <div className={classNames('row LoginFormGroup', className)}>
          <div className="col-sm-12">
            {status === Status.SUCCESS ? (
              <div className="row">
                <div className="col-sm-12">
                  <p className="text-success LoginFormSuccess">
                    Great! We've emailed you some instructions to recover your
                    account. Please check your email (including the spam
                    folder!)
                  </p>
                </div>
              </div>
            ) : (
              <Form onSubmit={handleSubmit}>
                {formApi => (
                  <form onSubmit={formApi.submitForm}>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="LoginFormLabel">
                            Enter your email address:
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <TextInputField
                            field="username"
                            placeholder="you@example.com"
                            className="LoginFormInput"
                          />
                        </div>
                      </div>
                      {errorMessage && (
                        <div className="col-sm-12">
                          <div className="form-group">
                            <ErrorMessage>{errorMessage}</ErrorMessage>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-12">
                        <div className="form-group">
                          <PressStud
                            label="Recover my account"
                            appearance="primary"
                            action="submit"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RecoverPasswordForm.defaultProps = {};

RecoverPasswordForm.propTypes = {
  className: PropTypes.string
};

export default RecoverPasswordForm;
