import { Event } from '__types__/index';
import mirror from 'key-mirror';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';

// @ts-expect-error TODO after migration to TypeScript, it complains about missing default export in vite. f
// fixing it by doing import * from 'moment' doesn't work either.
// maybe this would be resolved after bumping moment
// for now, we bring original version from before TS migration
const moment = extendMoment(Moment);

/////////////////////////////////

/*
 *  OBJECTS
 *  =======
 */

/**
 * @function createNewEventObject
 * Create an event from an object
 *
 * @param {Object} undefined - The (event) object to create an event from
 */
export function createNewEventObject({
  timezone = moment().tz(),
  event_type,
  date_type = 'RECURRING_DEFAULT',
  start_date,
  end_date,
  start_timestamp,
  end_timestamp,
  estimated_start_time,
  estimated_end_time,
  include_weekends
}: Partial<Event> & Pick<Event, 'event_type'>): Omit<
  Event,
  'id' | 'user_id' | 'created_at' | 'updated_at' | 'created_mechanism'
> {
  return {
    start_date: start_date
      ? moment(start_date).format('YYYY-MM-DD')
      : !start_timestamp
      ? moment().format('YYYY-MM-DD')
      : (null as unknown as undefined), // TODO: ts migration issue: null vs undefined
    end_date: end_date
      ? moment(end_date).format('YYYY-MM-DD')
      : !end_timestamp
      ? moment().format('YYYY-MM-DD')
      : (null as unknown as undefined), // TODO: ts migration issue: null vs undefined
    timezone,
    date_type,
    event_type,
    start_timestamp: start_timestamp
      ? (moment(start_timestamp).format() as unknown as undefined) // todo migration issue: Date vs string
      : (null as unknown as undefined), // todo migration issue: null vs undefined
    end_timestamp: end_timestamp
      ? (moment(end_timestamp).format() as unknown as undefined) // todo migration issue: Date vs string
      : (null as unknown as undefined), // todo migration issue: null vs undefined
    estimated_start_time: estimated_start_time,
    estimated_end_time: estimated_end_time,
    include_weekends
  };
}

/////////////////////////////////

/*
 *  FORMATTING
 *  ==========
 */

// TODO: refactor : split out hour functions better (see getFormattedEventHours as well)
/**
 * @function getFormattedEventDates
 * Formats the date range of an event as a string
 * eg RECURRING_DEFAULT/default: 'Mon 1st Jan - Fri 5th Jan'
 * eg INCLUSIVE_HOURS: 'Mon 1st Jan 09:00 - Fri 5th Jan 17:00'
 * eg RECURRING_HOURS: 'Mon 1st Jan - Fri 5th Jan 8h 30m'
 * eg RECURRING_SPECIFIC_HOURS: 'Mon 1st Jan - Fri 5th Jan 09:00 AM - 17:00 PM / daily'
 *
 * @param {Object} event - [Event] - The event to format
 * @param {string} defaultTimezone - The timezone
 */
// TODO: can we fix this to only show one date if they're the same?
// TODO: can we fix this to not use prettier, so we have clearer formatting?
export function getFormattedEventDates(
  event: Event,
  defaultTimezone: string | undefined = moment().tz(),
  trimDate?: { start_date: string; end_date: string }
) {
  if (!event || !event.date_type) return '';
  let timezone = (event.timezone || defaultTimezone) as string; // todo migration issue: string vs undefined
  let startDate = event.start_date;
  let endDate = event.end_date;

  // Trimdate
  // If we are showing availability between two dates we want to trim the full event if
  // the dates expand outside the original date query.
  // For example, if we are showing availability between 1st - 3rd of Nov, and a resource is
  // availability between 1st - 8th Nov, we need to trim the end date so it only shown availability
  // up to the 3rd.

  if (trimDate) {
    if (moment(event.start_date).isSameOrBefore(trimDate.start_date)) {
      startDate = trimDate.start_date;
    }
    if (moment(event.end_date).isSameOrAfter(trimDate.end_date)) {
      endDate = trimDate.end_date;
    }
  }

  const isEndDateSameDay = (start, end) => moment(end).isSame(start, 'day');

  switch (event.date_type) {
    case 'RECURRING_DEFAULT':
    default:
      return (
        `${moment.tz(startDate, timezone).format('ddd Do MMM')}` +
        (isEndDateSameDay(startDate, endDate)
          ? ''
          : ` - ${moment.tz(endDate, timezone).format('ddd Do MMM')}`)
      );
    case 'INCLUSIVE_HOURS':
      return (
        `${moment
          .tz(event.start_timestamp, timezone)
          .format('ddd Do MMM HH:mm')}` +
        (isEndDateSameDay(event.start_timestamp, event.end_timestamp)
          ? ` - ${moment.tz(event.end_timestamp, timezone).format('HH:mm')}`
          : `${moment
              .tz(event.end_timestamp, timezone)
              .format('ddd Do MMM HH:mm')}`)
      );
    case 'RECURRING_HOURS':
      return (
        `${moment.tz(startDate, timezone).format('ddd Do MMM')}` +
        (isEndDateSameDay(startDate, endDate)
          ? ''
          : ` - ${moment.tz(endDate, timezone).format('ddd Do MMM')}`) +
        ` (${event.estimated_daily_hours || 0}h ${
          event.estimated_daily_minutes
            ? ` - ${event.estimated_daily_minutes}m `
            : ''
        } daily)`
      );
    case 'RECURRING_SPECIFIC_HOURS':
      return (
        `${moment.tz(startDate, timezone).format('ddd Do MMM')}` +
        (isEndDateSameDay(startDate, endDate)
          ? ''
          : ` - ${moment.tz(endDate, timezone).format('ddd Do MMM')}`) +
        `
          ${getFormattedEventHours(event, timezone)}
          `
      );
  }
}

// TODO: check hours format for RECURRING_SPECIFIC_HOURS - 'HH' with 'a' looks wrong ?
// TODO: refactor : Review this as some include dates, and RECURRING_HOURS looks like a strange format
/**
 * @function getFormattedEventHours
 * Formats the hours of an event as a string
 * eg RECURRING_DEFAULT/default: '(All Day)'
 * eg INCLUSIVE_HOURS: 'Mon 1st Jan 09:00 - Fri 5th Jan 17:00'
 * eg RECURRING_HOURS: '(8h 30m daily)'
 * eg RECURRING_SPECIFIC_HOURS: '09:00 AM - 17:00 PM / daily'
 *
 * @param {Object} event - [Event] - The event to format
 * @param {string} defaultTimezone - The timezone
 */
// TODO: handle defaults for null values
export function getFormattedEventHours(
  event: Event,
  defaultTimezone = moment().tz()
) {
  if (!event || !event.date_type) return '';
  let timezone = (event.timezone || defaultTimezone) as string; // todo migration issue: string vs undefined
  switch (event.date_type) {
    case 'RECURRING_DEFAULT':
    default:
      return '(All Day)';
    case 'INCLUSIVE_HOURS':
      return `${moment
        .tz(event.start_timestamp, timezone)
        .format('ddd Do MMM HH:mm')} - ${moment
        .tz(event.end_timestamp, timezone)
        .format('ddd Do MMM HH:mm')}`;
    case 'RECURRING_HOURS':
      return `(${event.estimated_daily_hours}h ${
        event.estimated_daily_minutes
          ? ` ${event.estimated_daily_minutes}m `
          : ''
      } daily)`;
    case 'RECURRING_SPECIFIC_HOURS':
      return `
          (${
            event.estimated_start_time
              ? moment(`2000-01-01T${event.estimated_start_time}`).format(
                  'h:mma'
                )
              : '?'
          } - ${
        event.estimated_end_time
          ? moment(`2000-01-01T${event.estimated_end_time}`).format('h:mma')
          : '?'
      })
          `;
  }
}

/////////////////////////////////

/*
 *  GETTERS
 *  =======
 */

/**
 * @function getEventStart
 * Returns the start date of any event, irrespective of event type.
 * Format is either date or timestamp.
 *
 * @param {Object} event - [Event] - The event
 */
export const getEventStart = event => {
  return event.date_type === 'INCLUSIVE_HOURS'
    ? event.start_timestamp
    : event.start_date;
};

/**
 * @function getEventEnd
 * Returns the end date of any event, irrespective of event type.
 * Format is either date or timestamp.
 *
 * @param {Object} event - [Event] - The event
 */
export const getEventEnd = event => {
  return event.date_type === 'INCLUSIVE_HOURS'
    ? event.end_timestamp
    : event.end_date;
};

/**
 * @function getEventDates
 * Returns the start and end date of any event, irrespective of event type.
 * Format is either date or timestamp.
 *
 * @param {Object} event - [Event] - The event
 */
export function getEventDates(evt) {
  const start =
    evt.date_type === 'INCLUSIVE_HOURS' ? evt.start_timestamp : evt.start_date;
  const end =
    evt.date_type === 'INCLUSIVE_HOURS' ? evt.end_timestamp : evt.end_date;
  return [start, end];
}

/////////////////////////////////

/*
 *  CALCULATION
 *  ===========
 */

/**
 * @function getEventDuration
 * Calculates count of days between the start and end of an event, inclusive of end date
 * eg 01/01/20 - 05/01/20 returns 5
 *
 * @param {Object} event - [Event] - The event
 */
export const getEventDuration = event => {
  if (!event || !event.date_type) return '';

  let duration, from_date, to_date;

  switch (event.date_type) {
    case 'RECURRING_DEFAULT':
    case 'RECURRING_HOURS':
    case 'RECURRING_SPECIFIC_HOURS':
    default:
      from_date = event.start_date;
      to_date = event.end_date;
      break;
    case 'INCLUSIVE_HOURS':
      from_date = event.start_timestamp;
      to_date = event.end_timestamp;
  }

  // TODO: check this comparison works for INCLUSIVE_HOURS
  if (from_date === to_date) {
    duration = 1;
  } else {
    const starts = moment(from_date);
    const ends = moment(to_date);
    duration = ends.diff(starts, 'days') + 1;
  }
  return parseFloat(duration);
};

/**
 * @function getEventDurationFormatted
 * Calculates count of days between the start and end of an event, inclusive of end date,
 * and formats it as a string with ' day(s)' appended
 * eg 01/01/20 - 05/01/20 returns '5 days'
 *
 * @param {Object} event - [Event] - The event
 */
export const getEventDurationFormatted = event => {
  const duration = getEventDuration(event);
  return duration && `${duration} day${duration > 1 ? 's' : ''}`;
};

/**
 * @function calculateEventWeekdayCount
 * Calculate the count of weekdays in an event duration
 * eg an event from Wed-Tue returns 5
 *
 * @param {Object} event - [Event] - The event
 */
export const calculateEventWeekdayCount = event => {
  const to_date =
    event.date_type === 'INCLUSIVE_HOURS'
      ? event.end_timestamp
      : event.end_date;
  const allDaysCount = getEventDuration(event) as number; // todo migration issue: number vs string
  const weeksCount = Math.floor(allDaysCount / 7);
  const remainder = allDaysCount - weeksCount * 7;
  let weekendDayCount = weeksCount * 2;
  for (let i = 0; i < remainder; i++) {
    let dow = moment(to_date).subtract(i, 'days').isoWeekday();
    if (dow === 6 || dow === 7) weekendDayCount++;
  }
  return allDaysCount - weekendDayCount;
};

/**
 * @function getWorkedDayCount
 * Get count of worked days between the start and end of an event (eg for bookings)
 * Includes or excludes weekends appropriately
 *
 * @param {Object} event - [Event] - The event
 */
export const getWorkedDayCount = event =>
  event.date_type === 'INCLUSIVE_HOURS' || event.include_weekends
    ? getEventDuration(event) // COUNT ALL DAYS
    : calculateEventWeekdayCount(event); // COUNT WEEKDAYS

/////////////////////////////////

/*
 *  FIND
 *  ===========
 */

/**
 * @function findEventWithinDateRange
 *
 * @param {Object} events - [Event] - The event
 * @param {Object} event - [Event] - The event
 */
// TODO: Check listed params
// Finds the events from eventsList that fall within-ish the range of the selectedEvent's start and end
export function findEventWithinDateRange(selectedEvent, eventList = []) {
  if (isEmpty(selectedEvent)) return;
  const selectedEventDates = getEventDates(selectedEvent);
  const selectedEventRange = moment.range(
    selectedEventDates[0],
    selectedEventDates[1]
  );

  return eventList.filter(e => {
    const e_dates = getEventDates(e);
    const e_range = moment.range(e_dates[0], e_dates[1]);

    return e_range.overlaps(selectedEventRange, { adjacent: true });
  });
}

/*
 *  CHECKS
 *  ===========
 */
/**
 * @function checkEventExistsWithinEvents
 * Loops through events array and checks if any of the events match the exact start / end time of compared event.
 *
 * @param {Array} events - [Event] - The events array to check against
 * @param {Array} event - [Event] - The event
 */

export function checkEventExistsWithinEvents(events = [], event = {}) {
  if (!events.length || !event) return false;

  let isSameDay = false;
  const eventStartDate = moment(getEventStart(event));
  const eventEndDate = moment(getEventEnd(event));

  events.map(e => {
    const e_StartDate = moment(getEventStart(e));
    const e_EndDate = moment(getEventEnd(e));
    if (
      eventStartDate.isSame(e_StartDate, 'day') &&
      eventEndDate.isSame(e_EndDate, 'day')
    )
      isSameDay = true;
  });
  return isSameDay;
}

/**
 * DURATIONS
 * =========
 */
export const DURATION_TYPES = mapValues(
  mirror({
    RECURRING_DEFAULT: null,
    RECURRING_HOURS: null,
    RECURRING_SPECIFIC_HOURS: null,
    INCLUSIVE_HOURS: null
  }),
  value => value
);

export const DURATION_DEFAULTS = {
  ESTIMATED_DAILY_HOURS: 8,
  ESTIMATED_DAILY_MINUTES: 0,
  ESTIMATED_START_TIME: '09:00:00',
  ESTIMATED_END_TIME: '17:00:00'
};
