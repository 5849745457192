import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useGetContactEvents from 'v1/helpers/hooks/useGetContactEvents';
import useEvent from 'v1/helpers/hooks/useEvent';

import { openModal } from 'store/v1/ui/ui.actions.js';
import { updateResourceSlot } from 'store/v1/productions/productions.actions.js';
import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { getStatuses } from 'store/v1/statuses/statuses.selectors.js';
import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';

import {
  Grid,
  GridCell,
  ListCellGroup,
  PressStud,
  ResourceAdvancedSearch,
  TextInput,
  ListCell,
  ResourceStatusActions,
  ResourceStatusGetter,
  ResourceStatusDisplay,
  ResourceTitle,
  EmptyGeneric,
  ResourcesList
} from 'v1/components/shared';

import * as entityTypes from 'v1/helpers/entityTypes';
import { getEventStart, getEventEnd } from 'v1/helpers/byModel/EventHelper';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import map from 'lodash/map';

import './ProductionSidebarDrawerResourceSingle.scss';

const LOCAL_CONTACT_STORE = 'PRODUCTION_SIDEBAR';

const ProductionSidebarDrawerResourceSingle = ({
  slot: initialSlot,
  onBackClick,
  production
}) => {
  const dispatch = useDispatch();

  // STORE
  const bookingStatuses = useSelector(state =>
    getStatuses(state, { entity_type: entityTypes.BOOKING })
  );
  const statuses = useSelector(state => state.statuses);
  const bookings = useSelector(state => state.bookings);
  const contacts = useSelector(state => state.contacts);
  const resources = useSelector(state => state.contacts);
  const getSlotName = useSelector(makeGetSlotName);

  // STATE
  const [slot, setSlot] = useState(initialSlot);
  const [note, setNote] = useState(get(initialSlot, 'note', ''));

  // FUNCTION -- GETTING CONTACT EVENTS
  // This can be streamlined as it's a bit loopy
  // 1. Build resourceId array to get events for those resources
  // 2. Get dates to query between (production date)
  // 3. Send this to our useContactEvent Hook to fetch batch and bookings

  const start_date = getEventStart(get(production, 'production_date'));
  const end_date = getEventEnd(get(production, 'production_date'));

  const assignments_list = get(slot, 'resource_slot_assignments', []);
  const assignedResourceIds = assignments_list.map(a => a.contact_id);

  const assignmentContactIds = useCallback(
    () => map(assignments_list, a => a.contact_id),
    [assignments_list]
  );
  const contactResultIds = useCallback(
    () =>
      map(get(contacts, ['local_store', LOCAL_CONTACT_STORE]), id =>
        get(contacts, ['data', id, 'id'])
      ),
    [contacts.local_store]
  );

  const contactEventstoFetch = assignmentContactIds().concat(
    contactResultIds()
  );

  useGetContactEvents({
    contactIds: contactEventstoFetch,
    start_date,
    end_date,
    load: [contactResultIds, slot.id]
  });

  ////

  const queryWithAvailablity = useMemo(
    () => buildResourceSlotQuery(slot.query),
    [slot.query]
  );

  useEvent(
    [
      CONTACTS_ACTIONS.CREATE_CONTACT_EVENT,
      CONTACTS_ACTIONS.UPDATE_CONTACT_EVENT,
      PRODUCTIONS_ACTIONS.CREATE_RESOURCE_SLOT_ASSIGNMENT,
      PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT
    ],
    {
      onSuccess: () => {
        setSlot(initialSlot);
      }
    }
  );

  useEffect(() => {
    if (initialSlot && slot && initialSlot.id != slot.id) {
      setSlot(initialSlot);
      setNote(get(initialSlot, 'note', ''));
    }
  }, [initialSlot]);

  useEffect(() => {
    setSlot(initialSlot);
  }, [bookings.data]);

  const handleUpdateResourceSlotDebounced = debounce(
    handleUpdateResourceSlot,
    500
  );

  function handleUpdateResourceSlot(data) {
    setSlot({ ...slot, ...data });
    dispatch(
      updateResourceSlot(
        { resource_slot_id: slot.id, production_id: production.id },
        { ...slot, ...data }
      )
    );
  }

  function buildAvailabilityStatusQuery() {
    // TODO: Abstract out? We use it within ResourceMetaFilters also
    // TODO: This is currently hardcoded business logic. We want to filter out Unavailable statuses because the best match section shouldn't show anyone unavailable
    return bookingStatuses.reduce((acc, status) => {
      if (status.status_type !== 'UNAVAILABLE') {
        acc.push(status.id);
      }
      return acc;
    }, []);
  }
  function buildResourceSlotQuery(slotQuery) {
    if (isEmpty(slotQuery)) return;
    // TODO: Break out business logic - If location we want to show all locations regardless of availability. We also want to order alphabetically.
    if (slot.is_location) {
      return {
        ...slot.query,
        order_by: { field: 'full_name', direction: 'asc' }
      };
    }
    let availability = {
      status: buildAvailabilityStatusQuery(),
      from_date: start_date,
      to_date: end_date
    };
    let filters = { ...slotQuery.filters, availability };
    return {
      ...slotQuery,
      filters,
      order_by: { field: 'availability', direction: 'asc' }
    };
  }
  function openBlackbookModal() {
    dispatch(
      openModal('AddFromBlackbookModal', {
        destination: 'PRODUCTION_TEAM_MEMBER_OPTIONS',
        store: 'productions',
        initialQuery: initialSlot.query,
        dataPointers: [
          {
            label: 'production',
            pointTo: ['productions', 'data', production.id]
          },
          {
            label: 'resource_slot',
            pointTo: [
              'productions',
              'data',
              production.id,
              'resource_slots',
              findIndex(production.resource_slots, r => r.id === slot.id)
            ]
          }
        ],
        actions: ['PRODUCTION_BOOK_RESOURCE_SLOT']
      })
    );
  }
  return (
    <div className="ProductionSidebarDrawerResourceSingle">
      <Grid gutters="S" vcenter className="stack-S">
        <GridCell width="auto">
          <PressStud icon="arrowLeftGray" action={onBackClick} />
        </GridCell>
        <GridCell width="auto">
          <h3>{getSlotName(slot)}</h3>
        </GridCell>
        <GridCell>
          <ResourceAdvancedSearch
            slimline={true}
            showMeta={true}
            query={slot.query || {}}
            onChange={query => {
              handleUpdateResourceSlot({ query });
            }}
          />
        </GridCell>
      </Grid>
      <p className="text-11-700 fix-margin">Note</p>
      <TextInput
        value={note}
        onBlur={value => {
          setNote(value);
          handleUpdateResourceSlotDebounced({ note: value });
        }}
        // onChange={({ target }) => {
        // }}
        placeholder="Add a note..."
      />
      <p className="text-11-700">
        ASSIGNED TO THIS PRODUCTION ({assignments_list.length})
      </p>
      {!isEmpty(assignments_list) ? (
        <ListCellGroup className="stack-S">
          {assignments_list.length &&
            assignments_list.map(assignment => (
              <ListCell key={`assigned-${assignment.contact_id}`}>
                <Grid vcenter>
                  <GridCell width="1/3">
                    <ResourceTitle
                      resource={get(resources, [
                        'data',
                        get(assignment, 'contact_id')
                      ])}
                      navigateToMethod="SIDEBAR"
                    />
                  </GridCell>
                  <GridCell>
                    <ResourceStatusGetter
                      assignment={assignment}
                      dateRange={production.production_date}
                    >
                      {({ bookings }) =>
                        !isEmpty(bookings) &&
                        bookings.map(booking => (
                          <Grid className="inset-XS" vcenter>
                            <GridCell width="1/2">
                              <ResourceStatusDisplay
                                booking={booking}
                                assignment={assignment}
                              />
                            </GridCell>
                            <GridCell width="1/2" className="text-right">
                              <ResourceStatusActions
                                booking={booking}
                                production={production}
                                resource={get(resources, [
                                  'data',
                                  get(assignment, 'contact_id')
                                ])}
                                slot={slot}
                                resourceSlotAssignment={assignment}
                              />
                            </GridCell>
                          </Grid>
                        ))
                      }
                    </ResourceStatusGetter>
                  </GridCell>
                </Grid>
              </ListCell>
            ))}
        </ListCellGroup>
      ) : (
        <div className="ProductionSidebarDrawerResourceSingle-emptyResources inset-M stack-M text-center">
          No resources assigned
        </div>
      )}
      {!isEmpty(slot.query) ? (
        <>
          <Grid vcenter className="stack-S">
            <GridCell>
              <span className="text-11-700">BEST MATCHES</span>
            </GridCell>
            <GridCell width="auto">
              <PressStud
                label="Search all resources"
                icon="search"
                size="S"
                action={openBlackbookModal}
              />
            </GridCell>
          </Grid>
          <ResourcesList
            filterResources={assignedResourceIds}
            query={queryWithAvailablity}
            localStore={LOCAL_CONTACT_STORE}
          >
            {({ resourcesResults = [] }) => {
              if (!isEmpty(resourcesResults)) {
                return resourcesResults.map(resource => (
                  <ResourceStatusGetter
                    resourceId={get(resource, 'id')}
                    dateRange={production.production_date}
                  >
                    {({ bookings = [] }) => {
                      // If there is just one availability and its status is 'UNAVAILABLE'
                      // then we can assume that we want to filter this out of the "Best Matches" list
                      const status = statuses.data[bookings[0]?.status_id];
                      if (status?.status_type !== 'UNAVAILABLE') {
                        return (
                          <ListCell key={`matches-${resource.id}`}>
                            <Grid vcenter>
                              <GridCell width="1/3">
                                <ResourceTitle
                                  resource={resource}
                                  navigateToMethod="SIDEBAR"
                                />
                              </GridCell>
                              <GridCell>
                                {!isEmpty(bookings) ? (
                                  bookings.map(booking => (
                                    <Grid className="inset-XS" vcenter>
                                      <GridCell width="1/2">
                                        <ResourceStatusDisplay
                                          booking={booking}
                                        />
                                      </GridCell>
                                      <GridCell
                                        width="1/2"
                                        className="text-right"
                                      >
                                        <ResourceStatusActions
                                          booking={booking}
                                          production={production}
                                          slot={slot}
                                          resource={resource}
                                        />
                                      </GridCell>
                                    </Grid>
                                  ))
                                ) : (
                                  <Grid className="inset-XS" vcenter>
                                    <GridCell className="text-right">
                                      <ResourceStatusActions
                                        production={production}
                                        slot={slot}
                                        resource={resource}
                                      />
                                    </GridCell>
                                  </Grid>
                                )}
                              </GridCell>
                            </Grid>
                          </ListCell>
                        );
                      } else {
                        return <span />;
                      }
                    }}
                  </ResourceStatusGetter>
                ));
              } else {
                return (
                  <EmptyGeneric
                    icon="/images/icon_colour_team.svg"
                    title="Can't find resources with availability"
                    action={{
                      text: 'Search & assign resources',
                      className: 'btn btn-primary btn-small',
                      onClick: openBlackbookModal
                    }}
                  />
                );
              }
            }}
          </ResourcesList>
        </>
      ) : (
        <EmptyGeneric
          icon="/images/icon_colour_team.svg"
          title="Assign resource to this role"
          action={{
            text: 'Search & assign resources',
            className: 'btn btn-primary btn-small',
            onClick: openBlackbookModal
          }}
        />
      )}
    </div>
  );
};

ProductionSidebarDrawerResourceSingle.propTypes = {
  slot: PropTypes.object, // One item from production.resource_slots
  onBackClick: PropTypes.func
};

export default ProductionSidebarDrawerResourceSingle;
