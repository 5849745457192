import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';

import FlyoverContext from '../FlyoverContext';
import FlyoverHeader from '../FlyoverHeader/FlyoverHeader';
import FlyoverItem from '../FlyoverItem/FlyoverItem';
import FlyoverFooter from '../FlyoverFooter/FlyoverFooter';
import { SearchInput } from 'v1/components/shared';
import styles from './FlyoverContent.module.scss';

const FlyoverContentContents = ({
  className,
  filterable,
  onSelect,
  children
}) => {
  const { closeFlyover } = useContext(FlyoverContext);
  const [query, setQuery] = useState('');
  let header, footer;
  let contents = [];

  const renderFilter = () => (
    <div className="inset-XS">
      <SearchInput
        size="S"
        value={{ value: query }}
        initialFocus
        tabIndex={1}
        onChange={setQuery}
      />
    </div>
  );

  const renderItem = item => {
    const handleClose = () => {
      !item.props.stayOpen && closeFlyover();
    };

    if (item.props.onClick) {
      return !item.props.disabled
        ? React.cloneElement(item, {
            onClick: () => {
              item.props.onClick(item.props.value);
              handleClose();
            }
          })
        : item;
    }

    const handleClick =
      onSelect && !item.props.disabled
        ? () => {
            onSelect(item.props.value);
            handleClose();
          }
        : null;

    if (!query) {
      return React.cloneElement(item, {
        onClick: handleClick
      });
    }

    const filterValue = get(item, ['props', 'filterValue']);
    if (get(item, ['props', 'filterValue']) !== undefined) {
      const phraseIndex = filterValue
        .toLocaleLowerCase()
        .indexOf(query.toLocaleLowerCase());

      if (query && (!filterValue || phraseIndex < 0)) {
        return null;
      }

      return React.cloneElement(item, {
        onClick: handleClick
      });
    }

    const title = get(item, ['props', 'title'], '');
    const phraseIndex = title
      .toLocaleLowerCase()
      .indexOf(query.toLocaleLowerCase());

    if (query && (!title || phraseIndex < 0)) {
      return null;
    }

    const formattedTitle = (
      <>
        {title.substr(0, phraseIndex)}
        <span className="extra-bold">
          {title.substr(phraseIndex, query.length)}
        </span>
        {title.substr(phraseIndex + query.length)}
      </>
    );

    return React.cloneElement(item, {
      title: formattedTitle,
      onClick: handleClick
    });
  };

  const renderContents = contents => {
    return React.Children.map(contents, e => {
      if (get(e, 'type') === FlyoverItem) {
        return renderItem(e);
      }
      if (get(e, ['props', 'children'])) {
        return React.cloneElement(e, {
          children: renderContents(e.props.children)
        });
      }
      return e;
    });
  };

  const getInner = e =>
    e.type === React.Fragment ? getInner(e.props.children) : e;

  const processChildren = e => {
    if (!e) {
    } else if (e.type === FlyoverHeader) {
      header = e;
    } else if (e.type === FlyoverFooter) {
      footer = e;
      return;
    } else {
      contents = [...contents, e];
    }
  };

  const inner = getInner(children);

  React.Children.forEach(inner, processChildren);

  return (
    <>
      {header}
      {filterable && renderFilter()}
      <div className={classnames(styles.Contents, className)}>
        {renderContents(contents)}
      </div>
      {footer}
    </>
  );
};

FlyoverContentContents.propTypes = {
  className: PropTypes.string,
  filterable: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.any
};

export default FlyoverContentContents;
