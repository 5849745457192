import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Icon } from 'v1/components/shared';

import Color from 'color';
import classnames from 'classnames';
import get from 'lodash/get';

import { GLYPHS } from 'v1/helpers/icons';
import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';
import './Status.scss';

const INTERNAL_SYSTEM_STATUSES = {
  PAID: {
    name: 'Paid',
    colour: '#15b85d'
  },
  AWAITING_APPROVAL: {
    name: 'Awaiting Approval',
    colour: '#ffcb03'
  },
  APPROVED: {
    name: 'Approved',
    colour: '#4bd9d8'
  },
  DECLINED: {
    name: 'Declined',
    colour: '#f23b50'
  },
  PUBLISHED: {
    name: 'Published',
    colour: '#15b85d'
  },
  DRAFT: {
    name: 'Draft',
    colour: '#f6f6f6' // $system_white-lighter
  },
  SHARE: {
    name: 'Ready',
    colour: '#4bd9d8'
  },
  BUILDING: {
    name: 'Draft',
    colour: '#f6f6f6' // $system_white-lighter
  },
  UNKNOWN: {
    name: 'N/A',
    colour: '#F1F1F1' // $system_white-lighter
  }
};

const STATUSES_WITH_ICONS = {
  CONFIRMED: GLYPHS.TICK,
  COMPLETE: GLYPHS.TICK
};

export const Status = ({ statusId, appearance }) => {
  const storeStatuses = useSelector(state => state.statuses);
  let status;

  if (typeof statusId === 'number') {
    status = get(storeStatuses, ['data', statusId]);
  }
  if (typeof statusId === 'string') {
    status = get(INTERNAL_SYSTEM_STATUSES, statusId);
  }
  if (statusId === null || statusId === undefined) {
    status = get(INTERNAL_SYSTEM_STATUSES, 'UNKNOWN');
  }

  function renderBackgroundColour() {
    // IF HAS ICON AND IS COMPACT
    if (STATUSES_WITH_ICONS[status.status_type] && appearance === 'compact') {
      return 'transparent';
    }
    return status.colour;
  }

  function renderIconColour() {
    if (STATUSES_WITH_ICONS[status.status_type] && appearance === 'compact') {
      return status.colour;
    }
    return Color(status.colour).isDark()
      ? SYSTEM_COLOURS.WHITE
      : SYSTEM_COLOURS.BLACK;
  }

  if (status) {
    return (
      <span
        data-id="Status"
        className={classnames(
          {
            Status_isDark: Color(status.colour).isDark(),
            [`Status_${appearance}`]: appearance
          },
          'Status'
        )}
        style={{
          backgroundColor: renderBackgroundColour(status)
        }}
      >
        {STATUSES_WITH_ICONS[status.status_type] && (
          <Icon
            icon={STATUSES_WITH_ICONS[status.status_type]}
            color={renderIconColour()}
            size={13}
            className="Status-icon"
          />
        )}
        {/* )} */}
        {appearance === 'expanded' && status.name}
      </span>
    );
  }
  return '';
};

Status.defaultProps = {
  appearance: 'expanded'
};

Status.propTypes = {
  statusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  appearance: PropTypes.oneOf(['compact', 'expanded'])
};

export default Status;
