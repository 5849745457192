import React, { Component } from 'react';

import { Dropdown } from 'v1/components/shared';
import { MenuItem } from '@reach/menu-button';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Pagination.scss';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.paging && nextProps.paging.page) {
      if (this.state.currentPage !== nextProps.paging.page) {
        this.setState({ currentPage: nextProps.paging.page });
      }
    }
  }

  changePage = page => {
    const page_count = Math.ceil(
      this.props.paging.total / this.props.paging.count_per_page
    );
    if (page > page_count || page === 0) return;
    this.setState({ currentPage: page }, () => this.props.onPageChange(page));
  };

  renderNumberedPages(totalPages) {
    const pages = Array.from(new Array(totalPages), (val, index) => index + 1);

    return pages.map(page => {
      return (
        <MenuItem
          className={classnames(
            { active: page.toString() === this.state.currentPage.toString() },
            ['Pagination-dropdown-item']
          )}
          onSelect={this.changePage.bind(null, page)}
          key={page}
        >
          {page}
        </MenuItem>
      );
    });
  }
  renderCurrentPage = page_count => {
    return (
      <Dropdown
        buttonLabel={`Page ${this.state.currentPage} / ${page_count}`}
        buttonClass="Pagination-currentPage"
      >
        {this.renderNumberedPages(page_count)}
      </Dropdown>
    );
  };
  renderPagination() {
    const { total, count_per_page } = this.props.paging;
    const { currentPage } = this.state;
    const page_count = Math.ceil(total / count_per_page);

    if (total <= count_per_page) {
      return null;
    }

    return (
      <div className={classnames(['Pagination', this.props.className])}>
        <a
          className={`Pagination-btn ${currentPage !== 1 ? '' : 'disabled'}`}
          onClick={this.changePage.bind(null, parseInt(currentPage) - 1)}
        >
          <img src="/images/arrow_page_right.svg" className="flip" alt="" />
        </a>
        {this.renderCurrentPage(page_count)}
        <a
          className={`Pagination-btn ${
            currentPage < page_count ? '' : 'disabled'
          }`}
          onClick={this.changePage.bind(null, parseInt(currentPage) + 1)}
        >
          <img src="/images/arrow_page_right.svg" alt="" />
        </a>
      </div>
    );
  }

  render() {
    if (this.props.paging && this.props.paging.total) {
      return this.renderPagination();
    }
    return null;
  }
}

Pagination.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.shape({
    total: PropTypes.number,
    count_per_page: PropTypes.number,
    page: PropTypes.number // Defaults to 1
  }).isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
