import {
  DndContext,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { PropsWithChildren, ReactNode } from 'react';

export type SortableItemProp = PropsWithChildren<{ id: UniqueIdentifier }>;

export function SortableItem({ id, children }: SortableItemProp) {
  const { setNodeRef, transform, transition, listeners, active } = useSortable({
    id
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition
      }}
      {...listeners}
    >
      {children}
    </div>
  );
}

const ListSortable = <T extends UniqueIdentifier>({
  children,
  onMove,
  items
}: {
  children: ReactNode;
  onMove: (active: T, onto: T) => void;
  items: T[];
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 1 }
    })
  );

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      sensors={sensors}
      onDragEnd={({ over, active }) => {
        if (over) {
          onMove(active.id as T, over.id as T);
        }
      }}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={items}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default ListSortable;
