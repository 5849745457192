import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCalloutFieldMappings } from 'store/v1/callouts/callouts.selectors';

import { displayAlert, openModal } from 'store/v1/ui/ui.actions';
import { createContact } from 'store/v1/contacts/contacts.actions';
import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  ListCellGroup,
  ListCell,
  PressStud,
  Grid,
  GridCell
} from 'v1/components/shared';

import { generateResourceFromApplicant } from 'v1/helpers/byModel/applicantHelper';
import useEvent from 'v1/helpers/hooks/useEvent';

import isEmpty from 'lodash/isEmpty';

const CalloutReviewActionsPopover = ({ callout, selectedApplicant = {} }) => {
  const dispatch = useDispatch();

  const [actionSelected, setActionSelected] = useState('OPTIONS_VIEW');
  const { resource_type_id: resourceTypeId } = callout;

  useEvent(CONTACTS_ACTIONS.CREATE_CONTACT, {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Resource created'));
    }
  });

  const calloutFieldMapper = useSelector(state =>
    getCalloutFieldMappings(state, resourceTypeId)
  );

  function generateResource() {
    if (!isEmpty(selectedApplicant)) {
      return generateResourceFromApplicant(
        selectedApplicant,
        callout,
        calloutFieldMapper
      );
    }
  }

  function onAddasResource() {
    const resource = generateResource();
    if (resource) {
      return dispatch(createContact(resource));
    }
  }
  return (
    <Popover>
      <PopoverTrigger disabled={isEmpty(selectedApplicant)}>
        <PressStud
          icon="addWhite"
          label="Add"
          appearance="primary"
          isDisabled={isEmpty(selectedApplicant)}
          action={() => setActionSelected('OPTIONS')}
          size="S"
        />
      </PopoverTrigger>
      <PopoverContent className="ApplicantReviewActions-popover inset-S">
        {({ closePopover }) => {
          switch (actionSelected) {
            case 'OPTIONS_VIEW':
            default:
              return (
                <>
                  <h3>Add as new resource</h3>
                  <div className="text-13-600-eggplant stack-S">
                    Create a new resource with this applicant
                  </div>
                  <ListCellGroup className="ApplicantReviewActions-actionButtons">
                    <ListCell
                      onClick={() => {
                        closePopover();
                        onAddasResource();
                      }}
                    >
                      <Grid gutters="S">
                        <GridCell width="auto">
                          <img
                            src="/images/icon_sidebar_contacts_individual_colour.svg"
                            width="15px"
                            alt=""
                          />
                        </GridCell>
                        <GridCell>
                          <h5 className="text-13-700">Quick Create</h5>
                          <div className="text-13-600-eggplant">
                            Automatically create a new resource with applicant
                            details
                          </div>
                        </GridCell>
                      </Grid>
                    </ListCell>
                    <ListCell
                      onClick={() => {
                        closePopover();
                        dispatch(
                          openModal('ResourceCreateModal', generateResource())
                        );
                      }}
                    >
                      <Grid gutters="S">
                        <GridCell width="auto">
                          <img
                            src="/images/icon_sidebar_contacts_individual_colour.svg"
                            width="15px"
                            alt=""
                          />
                        </GridCell>
                        <GridCell>
                          <h5 className="text-13-700">
                            Edit & Create New Resource
                          </h5>
                          <div className="text-13-600-eggplant">
                            Open up a new resource panel to edit the new
                            resource before saving
                          </div>
                        </GridCell>
                      </Grid>
                    </ListCell>
                  </ListCellGroup>
                </>
              );
          }
        }}
      </PopoverContent>
    </Popover>
  );
};

export default CalloutReviewActionsPopover;
