import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Sortable from 'react-sortablejs';

import ResourceGroupHeaderEditor from './ResourceGroupHeaderEditor/ResourceGroupHeaderEditor';
import { Grid, ListCell, GridCell } from 'v1/components/shared';
import { PressStud } from 'v1/components/shared';

import classnames from 'classnames';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import size from 'lodash/size';
import set from 'lodash/set';
import { chain, isEmpty } from 'lodash';
import v4 from 'uuid';

export const ResourceGroupsEditor = ({
  production,
  slots: initialSlots,
  groups: initialGroups,
  onChange,
  slotComponent,
  onSlotCreate,
  labelSlotAdd,
  labelGroupAdd,
  appearance,
  locationGroups = []
}) => {
  const slots = initialSlots || [];
  const groups = initialGroups || [];
  const existingLocationGroups = filter(groups, group =>
    locationGroups.includes(group.name)
  );

  function onUpdate(updatedSlots, updatedGroups) {
    onChange &&
      onChange({
        slots: updatedSlots || slots,
        groups: updatedGroups || groups
      });
  }
  function onResourceSlotChange(slotId, field, value) {
    const newSlots = (slots || []).map(s => {
      if (slotId !== s.id) return s;
      return field === 'object' ? { ...s, ...value } : { ...s, [field]: value };
    });
    onUpdate(newSlots, null);
  }
  function onResourceSlotDelete(slotId) {
    const updatedSlots = filter(slots, l => l.id != slotId);

    onUpdate(updatedSlots, null);
  }
  function onResourceGroupDelete(groupId) {
    const updatedGroups = filter(groups, g => g.id != groupId);
    const updatedSlots = filter(slots, r => r.group_id != groupId);

    onUpdate(updatedSlots, updatedGroups);
  }
  function onResourceGroupAdd() {
    const newGroup = {
      id: `ref-${v4()}`,
      name: '',
      order: size(groups)
    };

    onUpdate(null, [...groups, newGroup]);
  }
  function onResourceSlotAdd(groupId) {
    const isLocationSlot = find(
      existingLocationGroups,
      group => group.id === groupId
    );
    const slotsInSameGroup = filter(slots, slot => slot.group_id === groupId);

    if (onSlotCreate) return onSlotCreate(groupId);
    const newSlot = {
      display_role: '',
      id: `temp-${v4()}`,
      name: '',
      group_id: groupId,
      is_location: !isEmpty(isLocationSlot),
      order: size(slotsInSameGroup)
    };

    onUpdate([...slots, newSlot], null);
  }
  function onGroupChange(groupId, field, value) {
    const groupIndex = findIndex(groups, g => g.id === groupId);
    const updatedGroups = set([...groups], [groupIndex, field], value);

    onUpdate(null, updatedGroups);
  }
  function onUpdateGroupOrder(order) {
    const updatedGroups = order.map((id, index) => {
      let group = find(groups, g => g.id == id);
      group.order = index;
      return group;
    });

    onUpdate(null, updatedGroups);
  }
  function onUpdateResourceSlotOrder(groupId, order) {
    const updatedSlots = slots.map(slot => {
      // TODO: We can't use === because of our temp-id's vs int Id's need to clean this up
      const index = order.findIndex(orderId => orderId == slot.id);
      const isLocationSlot = find(
        existingLocationGroups,
        group => group.id === groupId
      );
      if (index === -1) return slot;
      return {
        ...slot,
        group_id: groupId,
        order: index,
        is_location: !isEmpty(isLocationSlot)
      };
    });

    onUpdate(updatedSlots, null);
  }
  const renderResourceSlotAdd = groupId => {
    return (
      <ListCell
        datacy="add-slot"
        onClick={() => {
          onResourceSlotAdd(groupId);
        }}
      >
        <Grid vcenter gutters="S">
          <GridCell width="auto">
            <img
              src="/images/icon_add_black.svg"
              width="12px"
              className="icon"
              alt=""
            />
          </GridCell>
          <GridCell>{labelSlotAdd}</GridCell>
        </Grid>
      </ListCell>
    );
  };
  const renderResourceGroupAdd = () => {
    return (
      <PressStud
        label={labelGroupAdd}
        icon="addBlack"
        size="S"
        action={onResourceGroupAdd}
      />
    );
  };
  const renderGroupedResources = groupId => {
    const filteredResourceSlots = chain(slots)
      .filter(l => l.group_id == groupId)
      .orderBy(['order', 'id'], ['asc', 'asc'])
      .value();
    return (
      <Fragment>
        <Sortable
          options={{
            group: 'lists',
            draggable: onChange ? '.draggable' : '',
            animation: 150,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            disable: !onChange,
            handle: '.dragHandle'
          }}
          className="ListCellGroup stack-XS"
          onChange={order => {
            onUpdateResourceSlotOrder(groupId, order);
          }}
        >
          {filteredResourceSlots &&
            filteredResourceSlots.map(role =>
              slotComponent(role, onResourceSlotChange, onResourceSlotDelete)
            )}
          {onChange && renderResourceSlotAdd(groupId)}
        </Sortable>
      </Fragment>
    );
  };
  const renderGroups = () => {
    const groupsList = chain(groups)
      .filter(g => !g.archived)
      .orderBy(['order', 'asc'])
      .value();
    return (
      <Fragment>
        <Sortable
          options={{
            group: 'groups',
            draggable: onChange ? '.draggable' : '',
            animation: 150,
            disable: !onChange,
            handle: '.dragHandle'
          }}
          onChange={onUpdateGroupOrder}
        >
          {groupsList &&
            groupsList.map((group, index) => (
              <ResourceGroupHeaderEditor
                key={`${group.id}-${index}`}
                production={production}
                group={group}
                onDelete={onResourceGroupDelete}
                onChange={onChange ? onGroupChange : null}
                className="Parent_hoverListener"
                // isLocked={locationGroups.includes(group.name)}
              >
                {renderGroupedResources(group.id)}
              </ResourceGroupHeaderEditor>
            ))}
        </Sortable>
        {onChange && renderResourceGroupAdd()}
      </Fragment>
    );
  };
  return (
    <div
      className={classnames(
        {
          editable: onChange,
          [`ResourceGroupsEditor_${appearance}`]: appearance
        },
        ['ResourceGroupsEditor']
      )}
    >
      {renderGroups()}
    </div>
  );
};

ResourceGroupsEditor.defaultProps = {
  labelSlotAdd: 'Add slot',
  labelGroupAdd: 'Add group',
  appearance: 'default'
};
ResourceGroupsEditor.propTypes = {
  production: PropTypes.object,
  groups: PropTypes.array,
  resourceSlots: PropTypes.array,
  options: PropTypes.object,
  layout: PropTypes.object,
  onChange: PropTypes.func,
  appearance: PropTypes.oneOf(['compact', 'default']),
  labelGroupAdd: PropTypes.string,
  labelSlotAdd: PropTypes.string
};

export default ResourceGroupsEditor;
