import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useSelector } from 'react-redux';

import { Dropdown, MenuItem, TextInput } from 'v1/components/shared';

import {
  makeGetSlotCategories,
  makeGetSlotName
} from 'store/v1/slot_categories/slot_categories.selectors.js';
import useAutocomplete from 'v1/helpers/hooks/useAutocomplete';
import { selectAppId } from 'store/v1/auth/auth.selectors';

import { filter, isEmpty, orderBy } from 'lodash';
import { withinSettings } from 'v4/core/appRoutes';
import { Link } from 'react-router-dom';

const SlotCategoryDropdown = ({
  slot,
  type,
  onSelect,
  buttonClass,
  filterCategories
}) => {
  const getSlotCategories = useMemo(makeGetSlotCategories, []);
  const slotCategoriesList = useSelector(state =>
    getSlotCategories(state, { type })
  );

  const getSlotName = useSelector(makeGetSlotName);

  const slotCategoriesListMemo = useMemo(
    () => slotCategoriesList,
    [slotCategoriesList]
  );

  const app_id = useSelector(selectAppId);
  const to = withinSettings(app_id);

  const {
    query = '',
    setQuery,
    matches
  } = useAutocomplete({
    data: slotCategoriesListMemo,
    keyToMatch: 'name'
  });
  let filteredMatches = filterCategories
    ? filter(matches, e => filterCategories.includes(e.id))
    : matches;

  filteredMatches = orderBy(filteredMatches, ['name', 'asc']);

  function renderEmptyResults() {
    return (
      <div className="inset-M">
        <h5 className="text-13-700 stack-S">No categories named "{query}"</h5>
        <p>
          You can configure your categories within{' '}
          <Link
            to={to.settings.team.slotCategories()}
            className="genericLink"
            target="_blank"
          >
            Settings
            <img
              className="genericLink-arrow"
              src="/images/arrow_right.svg"
              width="10px"
              alt=""
            />
          </Link>
        </p>
      </div>
    );
  }

  return (
    <Dropdown
      buttonLabel={
        getSlotName(slot, type) ||
        (type === 'EXPENSE' ? 'Select expense' : 'Select role')
      }
      buttonClass={buttonClass}
      listPadding="0"
    >
      <div className="AutocompleteSelect-searchInput">
        <TextInput
          name="query"
          value={query}
          onChange={({ target }) => setQuery(target.value)}
          placeholder="Search categories"
          autoComplete="off"
          initialFocus
          size="S"
        />
      </div>
      {filteredMatches.map((item, index) => {
        const { name, id } = item;
        return (
          <MenuItem
            key={`${id}-${index}`}
            className={classnames('AutocompleteSelect-item')}
            onSelect={() => onSelect('slot_category_id', id)}
          >
            <strong>{name}</strong>
          </MenuItem>
        );
      })}
      {isEmpty(matches) && renderEmptyResults()}
      <MenuItem onSelect={() => {}} className="visually-hidden" />
    </Dropdown>
  );
};

SlotCategoryDropdown.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  filterCategories: PropTypes.array,
  type: PropTypes.oneOf(['RESOURCE_SLOT', 'EXPENSE']).isRequired
};

SlotCategoryDropdown.defaultProps = {
  data: [],
  onSelect: () => {}
};

export default SlotCategoryDropdown;
