import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './CalendarPen.module.scss';

const CalendarPen = ({ label, colour, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={classnames([
      styles['CalendarPen'],
      { [styles['CalendarPen--active']]: isActive },
      'clickable'
    ])}
  >
    <span
      className={styles['CalendarPen__colour']}
      style={{ backgroundColor: colour }}
    />
    <span className="text-13-600">{label}</span>
  </button>
);

CalendarPen.propTypes = {
  label: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

export default CalendarPen;
