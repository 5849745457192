import React from 'react';

import {
  InputMetaProps,
  InputStylingProps
} from 'shared/inputs/__types__/Inputs';
import { RestrictedInput, TextInput } from 'shared';

export type PasswordInputProps = InputMetaProps &
  InputStylingProps & {
    value?: string | null;
    onChange?(value: string): void;
    onBlur?(value: string): void;
  };

// TODO
const PasswordInput = ({
  value,
  isRestricted,
  onChange,
  onBlur,
  ...restProps
}: PasswordInputProps) => {
  if (isRestricted) {
    return <RestrictedInput {...restProps} />;
  }

  return (
    <TextInput
      type="password"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default PasswordInput;
