import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PressStud, Loading } from 'v1/components/shared';

import {
  getStorageProviders,
  createStorageProvider
} from 'store/v1/storage_providers/storage_providers.actions.js';
import Env from 'lib/env/Env';

import { get, values } from 'lodash';
import { v4 } from 'uuid';
import classnames from 'classnames';

import './GoogleDriveUploader.scss';

export class GoogleDriveUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drive_token: null
    };
  }
  componentDidMount() {
    this.props.getStorageProviders();
    window.gapi.load('auth');
    window.gapi.load('picker');
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.drive_token) {
      const providers = values(nextProps.storage_providers.data);
      if (providers && providers.length) {
        const p = providers.find(p => p.service === 'GOOGLE');
        const isFirstTime =
          !this.state.drive_token &&
          this.props.storage_providers.updating &&
          !nextProps.storage_providers.updating;

        this.setState({ drive_token: get(p, 'access_token') }, () => {
          if (isFirstTime) this.openDrivePicker();
        });
      }
    }
  }
  authorizeAccess = () => {
    window.gapi.auth2.authorize(
      {
        clientId: Env.googleClientID,
        scope: 'email https://www.googleapis.com/auth/drive.readonly',
        response_type: 'code',
        access_type: 'offline',
        prompt: 'consent'
      },
      res => {
        if (res.error) {
          console.log(res.error);
          return;
        }
        this.setState({ event: v4() }, () =>
          this.props.createStorageProvider(
            {
              pathway: 'GOOGLE',
              data: { code: res.code }
            },
            this.state.event
          )
        );
      }
    );
  };
  openDrivePicker = () => {
    if (!this.state.drive_token) return this.authorizeAccess();

    const view = new window.google.picker.View(
      window.google.picker.ViewId.DOCS
    );
    const picker = new window.google.picker.PickerBuilder()
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId('essential-truth-204623')
      .setOAuthToken(this.state.drive_token)
      .addView(view)
      .addView(new window.google.picker.DocsUploadView())
      .setDeveloperKey('AIzaSyDCHGWNMf_amIX5AOe-SQatR4S6dFI9nrw')
      .setCallback(this.pickerCallback)
      .build();
    picker.setVisible(true);
  };
  pickerCallback = data => {
    if (
      data[window.google.picker.Response.ACTION] ==
      window.google.picker.Action.PICKED
    ) {
      const file = data[window.google.picker.Response.DOCUMENTS][0];
      this.props.onSelect(file);
      window.google.picker.setVisible(false);
    }
  };
  renderExplainer = () => {
    const { fetching } = this.props.storage_providers;
    if (fetching) {
      return <Loading text="Checking Authorisation" />;
    }
    if (this.state.drive_token) {
      return (
        <div className="GoogleDriveUploader-explainer">
          <h5>Google Drive Connected</h5>
          <p>Click below to upload</p>
          <PressStud label="Open Google Drive" action={this.openDrivePicker} />
        </div>
      );
    }
    return (
      <div className="GoogleDriveUploader-explainer">
        <h5>Connect Google Drive</h5>
        <p>
          Authorise Atellio to add files directly from your Google Drive account
        </p>
        <PressStud label="Connect Google Drive" action={this.openDrivePicker} />
      </div>
    );
  };
  render() {
    return (
      <div
        className={classnames(['GoogleDriveUploader', this.props.className])}
      >
        {this.renderExplainer()}
      </div>
    );
  }
}

function mapStateToProps({ storage_providers }) {
  return { storage_providers };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getStorageProviders, createStorageProvider },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleDriveUploader);
