import { PUBLIC_SETTINGS_ACTIONS } from 'store/v1/public_settings/public_settings.constants.js';
import { get } from 'lodash';

export const INITIAL_STATE = {
  settings: {},
  availabilityStatuses: [],
  bookingStatuses: [],
  loading: false,
  error: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PUBLIC_SETTINGS_ACTIONS.GET_PUBLIC_SETTINGS:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case PUBLIC_SETTINGS_ACTIONS.GET_PUBLIC_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: get(action, 'result.data.customData', {}),
        availabilityStatuses: get(
          action,
          'result.data.availabilityStatuses',
          {}
        ),
        bookingStatuses: get(action, 'result.data.bookingStatuses', []),
        loading: false
      };
    case PUBLIC_SETTINGS_ACTIONS.GET_PUBLIC_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
