import { useContext } from 'react';

import ModalContext from 'modals/ModalContext';

const useModal = () => {
  const {
    openModal,
    cancelModal,
    closeModal,
    cancelAllModals,
    closeAllModals
  } = useContext(ModalContext);
  return {
    openModal,
    cancelModal,
    closeModal,
    cancelAllModals,
    closeAllModals
  };
};

export default useModal;
