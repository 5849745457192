import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithContext as ReactTags } from 'react-tag-input';
import { v4 } from 'uuid';

import './DEPRECATED_TagInput.scss';

const KEY_CODES = {
  comma: 188,
  enter: 13
};

const MAX_TAG_LENGTH = 40;
const DELIMITERS = [KEY_CODES.comma, KEY_CODES.enter];

class DEPRECATED_TagInput extends Component {
  getSuggestions = () =>
    this.props.getSuggestions && this.props.getSuggestions(this.props.field);

  renderSuggestion = query => {
    return <div>{query}</div>;
  };
  render() {
    const tags = this.props.tags || [];

    return (
      <ReactTags
        readOnly={this.props.disabled}
        tags={tags.map(text => ({ id: v4(), text }))}
        renderSuggestion={this.renderSuggestion}
        suggestions={this.props.suggestions || []}
        handleDelete={index => {
          this.props.onChange(tags.filter((t, i) => index !== i));
        }}
        handleInputFocus={this.getSuggestions}
        handleAddition={val => this.props.onChange([...tags, val])}
        placeholder={this.props.placeholder}
        autofocus={false}
        classNames={{
          tagInput: 'TagInput',
          tagInputField: `TagInput-input ${this.props.inputClassname}`,
          selected: `TagInput-selected ${
            this.props.className ? this.props.className : ''
          }`,
          tag: 'TagInput-tag',
          remove: 'TagInput-removeButton',
          suggestions: 'TagInput-suggestions',
          activeSuggestion: 'TagInput-suggestions-active'
        }}
        delimiters={DELIMITERS}
        allowDeleteFromEmptyInput={false}
        minQueryLength={0}
        maxLength={MAX_TAG_LENGTH}
      />
    );
  }
}

// NOTE: wrapper for react-tag-input
DEPRECATED_TagInput.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool, // TODO
  field: PropTypes.any, // TODO
  tags: PropTypes.array, // TODO: array type - suspect strings
  suggestions: PropTypes.array, // TODO: array type - suspect strings
  onChange: PropTypes.func, // TODO: arguments
  getSuggestions: PropTypes.func // TODO: arguments
};

export default DEPRECATED_TagInput;
