import Tracker from './Tracker';
import { MixActions } from './actions';

export default class ResourceTracker extends Tracker {
  /**
   * Receive a Mixpanel event and decide whether the tracker should process it
   * @param {MixpanelEvent} event
   */
  track(event) {
    switch (event.action) {
      case MixActions.RESOURCE_UNARCHIVE:
        return this._onEvent(event);
      default:
        return;
    }
  }

  /**
   * Track a lifecycle event
   * @param {MixpanelEvent} event
   * @private
   */
  _onEvent(event) {
    const { resourceId, ...rest } = event.properties;
    this.sendEvent(event.action, event, {
      resourceId: resourceId,
      ...rest
    });
  }
}
