import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';

import { updateExpense } from 'store/v1/expenses/expenses.actions.js';

import { Flyover, Grid, GridCell, TextInput } from 'v1/components/shared';

const ExpenseEditMetaFlyover = ({ expense, position = 'left' }) => {
  const dispatch = useDispatch();
  const slotCategories = useSelector(state => state.slot_categories.data);
  const name = get(slotCategories, [expense.slotCategoryId, 'name']);

  /**
   * TODO:
   * We can't do this stably, because of the ProductionBudgetExpense/Expense issue
   * Either we update the PBE, and with it ALL EXPENSES
   * Or we update the Expense, and lose the PBE associatation
   * Because we have a stale slot_category_id on the Expense
   */
  // const onSlotCategoryChange = (expenseId, budgetExpenseId, value) => {
  // dispatch(
  //   updateBudgetExpense(
  //     { production_id: production.id, budget_expense_id: budgetExpenseId },
  //     { slot_category_id: value }
  //   )
  // );
  // dispatch(updateExpense(expenseId, { slot_category_id: value }));
  // };

  const onExpenseVendorChange = useCallback(
    debounce(
      (expenseId, value) =>
        dispatch(updateExpense(expenseId, { commit_vendor: value })),
      300
    ),
    [dispatch]
  );

  return (
    <Flyover>
      <Flyover.Trigger>
        <div className="inset-S">
          <div className="text-13-700">{name}</div>
          <div className="text-13-600-eggplant-lighter">{expense.desc}</div>
        </div>
      </Flyover.Trigger>
      <Flyover.Content position={position}>
        <div className="inset-S">
          <Grid className="stack-S" vcenter>
            <GridCell className="text-13-600-eggplant" width="5/12">
              Category
            </GridCell>
            <GridCell className="text-13-600">
              {name}
              {/* <SlotCategoryDropdown
                  disabled
                  type="EXPENSE"
                  slot={expenseSlotCategory}
                  buttonClass="emulate-input emulate-input_underline"
                  onSelect={(_, value) =>
                    onSlotCategoryChange(id, productionBudgetExpenseId, value)
                  }
                /> */}
            </GridCell>
          </Grid>
          <Grid vcenter>
            <GridCell className="text-13-600-eggplant" width="5/12">
              Name
            </GridCell>
            <GridCell>
              <TextInput
                appearance="underline"
                size="S"
                value={expense.desc}
                onBlur={value => onExpenseVendorChange(expense.id, value)}
              />
            </GridCell>
          </Grid>
        </div>
      </Flyover.Content>
    </Flyover>
  );
};

ExpenseEditMetaFlyover.propTypes = {
  expense: PropTypes.object,
  position: PropTypes.string
};

export default ExpenseEditMetaFlyover;
