import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TagGroup.scss';

class TagGroup extends Component {
  renderTags = tags => {
    return tags.map((tag, index) => {
      if (this.props.limit && index > this.props.limit) return null;
      return (
        <span
          className={classnames({ darkMode: this.props.darkMode }, ['tagItem'])}
          key={index}
        >
          {tag}
        </span>
      );
    });
  };
  renderTagsWithType = tags => {
    return tags.map((tag, index) => {
      if (this.props.limit && index > this.props.limit) return null;
      return (
        <span
          className={classnames({ darkMode: this.props.darkMode }, ['tagItem'])}
          key={index}
        >
          {tag[this.props.tagType]}
        </span>
      );
    });
  };
  render() {
    const tags = this.props.tags || [];
    const type = typeof tags[0];

    return (
      <div className={`TagGroup ${this.props.className}`}>
        {this.props.label ? <h5>{this.props.label}</h5> : null}
        {type === 'string'
          ? this.renderTags(tags)
          : this.renderTagsWithType(tags)}
      </div>
    );
  }
}

TagGroup.propTypes = {
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  label: PropTypes.string,
  limit: PropTypes.number, // Max no of tags to display
  tagType: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  )
};

export default TagGroup;
