import { createSelector } from 'reselect';

import { getBookingStore } from 'store/v1/bookings/bookings.selectors.js';
import { getStatusStore } from 'store/v1/statuses/statuses.selectors.js';
import { getExpensesByBookingId } from 'store/v1/expenses/expenses.selectors.js';

import {
  getEventDates,
  getEventStart,
  getEventEnd
} from 'v1/helpers/byModel/EventHelper';

import { formatCurrency } from 'v1/helpers/currencyHelper';
import { bookingConfirmedCheck } from 'v1/helpers/byModel/CallsheetHelper';
import { getExpenseSlotTotalsFormatted } from 'v1/helpers/byModel/ProductionBudgetExpenseHelper';
import {
  getExpenseTotalsFormatted,
  getExpenseDocumentsSummary
} from 'v1/helpers/byModel/ExpenseHelper';
import { getResourceSlotTotalsFormatted } from 'v1/helpers/byModel/ResourceSlotHelper';
import {
  getBookingTotalsFormatted,
  getBookingDocumentsSummary
} from 'v1/helpers/byModel/BookingHelper';

import { get, find, keys, orderBy, has, filter, isEmpty } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getKeyed } from 'v1/helpers/misc';

const moment = extendMoment(Moment);

export const getProductionTypes = createSelector(
  state => state.production_types.data,
  (_, { archived = false } = { archived: false }) => archived,
  (data, archived) => {
    const types = keys(data).reduce((result, id) => {
      if (!archived && data[id].archived) return result;
      return result.concat(data[id]);
    }, []);
    return orderBy(types, ['order', 'id'], ['asc', 'asc']);
  }
);

export const getProductionsLoading = createSelector(
  state => state.productions,
  state => state.loading
);

export const getProductionByID = ID =>
  createSelector(
    state => state.productions,
    state => state.data[ID] || null
  );

export const getProductionTemplates = createSelector(
  state => state.production_templates.data,
  (_, { archived = false } = { archived: false }) => archived,
  (data, archived) => {
    const types = keys(data).reduce((result, id) => {
      if (!archived && data[id].archived) return result;
      return result.concat(data[id]);
    }, []);
    return orderBy(types, ['order', 'id'], ['asc', 'asc']);
  }
);

export const getProduction = (state, props = {}) =>
  state.productions.data[
    get(props, 'production_id') || get(props, 'callsheet.production_id')
  ];

export const makeGetProduction = () =>
  createSelector([getProduction], production => production);

export const makeGetResourceSlotsByGroup = () =>
  createSelector(getProduction, production =>
    getKeyed(production.resource_slots, 'group_id')
  );

const getProductionNotes = (state, props) =>
  get(state, [
    'productions',
    'associations_data',
    props.production_id,
    'notes'
  ]);

export const makeGetProductionNotes = () =>
  createSelector([getProductionNotes], notes => notes);

export const makeGetProductionNotesCount = () =>
  createSelector([getProductionNotes], notes => (notes ? notes.length : '0'));

const getProductionFiles = (state, props) =>
  get(state, [
    'productions',
    'associations_data',
    props.production_id,
    'documents'
  ]);

export const makeGetProductionFiles = () =>
  createSelector([getProductionFiles], files => files);

export const makeGetProductionFilesCount = () =>
  createSelector([getProductionFiles], files => (files ? files.length : '0'));

export const selectProductionTemplates = createSelector(
  state => state,
  (_, { archived = false } = { archived: false }) => archived,
  (_, { production_type_id = null } = { production_type_id: null }) =>
    production_type_id,
  (state, archived, production_type_id) => {
    const production_templates = filter(
      state.production_templates.data,
      t => archived || !t.archived
    );
    const production_types = getProductionTypes(state);

    let selectedTemplates = [];

    if (production_type_id) {
      selectedTemplates = orderBy(
        filter(
          production_templates,
          t => t.production_type_id === production_type_id
        ),
        ['order', 'id'],
        ['asc', 'asc']
      );
    } else {
      selectedTemplates = production_types.reduce(
        (templateResults, productionType) => {
          let productionTypeTemplates = orderBy(
            filter(
              production_templates,
              t => t.production_type_id === productionType.id
            ),
            ['order', 'id'],
            ['asc', 'asc']
          );
          return templateResults.concat(productionTypeTemplates);
        },
        []
      );
    }
    return selectedTemplates;
  }
);

const getProductionFromResourceSlotId = (state, props) => {
  const productions = state.productions;
  const selectedProductions = props.assignments.map(assignment => {
    return keys(productions.data).reduce((result, production_id) => {
      const slot =
        productions.data[production_id] &&
        productions.data[production_id].resource_slots.find(
          r => r.id === assignment.resource_slot_id
        );
      return slot ? productions.data[production_id] : result;
    }, {});
  });

  return selectedProductions;
};

export const makeGetProductionFromResourceSlotId = () =>
  createSelector(
    [getProductionFromResourceSlotId],
    resource_slot => resource_slot
  );

const getContacts = state => state.contacts.data;

export const makeGetProductionLocations = createSelector(
  [getContacts, getProduction],
  (contacts, production) => {
    let resourceIds = new Set();

    get(production, 'resource_slots', []).forEach(slot => {
      if (slot.is_location) {
        get(slot, 'resource_slot_assignments', []).forEach(a =>
          resourceIds.add(a.contact_id)
        );
      }
    });

    const resources = Array.from(resourceIds).reduce((acc, id) => {
      if (has(contacts, id)) {
        acc.push(get(contacts, id));
      }
      return acc;
    }, []);

    return resources;
  }
);

export const getProductionStatus = (state, production) => {
  const alertDayCount = get(
    state.auth,
    'settings.settings.role_missing_days.value'
  );
  let status = state.statuses.data[get(production, 'status_id')] || {};

  // ALERT LOGIC
  // If the production is set as 'OPEN' OR 'RESOURCING' we should check the value set of role_missing_days
  // to ensure that the dates of the production do not fall within the threshold of the risk alert with the present date
  // If it does, then show our ALERT status
  // TODO: These should really be on the back-end
  if (status.status_type === 'OPEN' || status.status_type === 'RESOURCING') {
    const [start, end] = getEventDates(get(production, 'production_date', {}));

    if (
      moment(start).diff(moment(), 'd') < alertDayCount &&
      moment(end).diff(moment(), 'd') >= 0
    ) {
      const newStatus = find(
        state.statuses.data,
        s => s.status_type === 'ALERT'
      );
      // Check we found an alert status, the customer does not necessarily have
      // an alert status available.
      if (newStatus) {
        status = newStatus;
      }
    }
  }
  // ACTIVE LOGIC
  // If the production is set to 'READY' check if the present date falls within the production dates
  // If so show the 'ACTIVE' status
  // TODO: These should really be on the back-end
  if (status.status_type === 'READY') {
    const [start, end] = getEventDates(get(production, 'production_date', {}));

    if (moment().isBetween(start, end, 'day', '[]'))
      status = find(state.statuses.data, s => s.status_type === 'ACTIVE');
  }
  return status;
};

/**
 * @function getProductionGroups
 * Returns the productions groups that are not archived
 *
 * @param {Object} production - The production to get the production groups
 *
 */

export const getProductionGroups = createSelector(
  [getProduction],
  production => {
    if (production && production.groups) {
      return orderBy(
        filter(production.groups, group => !group.archived),
        'order'
      );
    }
    return [];
  }
);

/**
 * @function getConfirmedSlots
 * Loops through each of the available resource_slot_assignments within resource_slots in a production and
 * checks the bookings are confirmed. If they are confirmed then they are returned.
 *
 * @param {Date} date - The selected date so we can check if the booking is confirmed on that date.
 * @param {Object} production - The production to get the resource_slot and resource_slot_assignments
 * @param {Object} Bookings - The bookings store to find the relevant bookings
 * @param {Object} Statuses - The status store to find the status_id with status_type === 'CONFIRMED'
 *
 */

export const getConfirmedSlots = createSelector(
  [
    (_, { selectedDate = null } = { selectedDate: null }) => selectedDate,
    getProduction,
    getBookingStore,
    getStatusStore
  ],
  (date, production, bookings, statuses) => {
    if (!production || !date) return [];
    let slots = orderBy(production.resource_slots, 'order');
    slots = slots.reduce((arr, resourceSlot) => {
      let validAssignments = resourceSlot.resource_slot_assignments.filter(
        assignment => {
          const confirmed = bookingConfirmedCheck(assignment.booking_id, {
            bookings,
            statuses
          });
          const booking = get(bookings, ['data', assignment.booking_id], {});

          if (confirmed && booking && get(assignment, ['events', [0]])) {
            if (!date) return true;
            const events = get(assignment, 'events', []); // We want to filter against the assignment dates rather than the booking dates
            let isValid = false;
            events.forEach(event => {
              const start = getEventStart(event);
              const end = getEventEnd(event);

              if (
                moment(date, 'YYYY-MM-DD').isBetween(start, end, null, '[]')
              ) {
                isValid = true;
              }
            });
            return isValid;
          }
          return false;
        }
      );

      !isEmpty(validAssignments) &&
        arr.push({
          ...resourceSlot,
          resource_slot_assignments: validAssignments
        });
      return arr;
    }, []);
    return slots;
  }
);

// TODO: sort all these out & reconcile with the existing selectors
export const getProductionResourceSlots2 = createSelector(
  [getProduction],
  production => production.resource_slots
);

// TODO: unused
export const getProductionResourceSlotsByGroup = createSelector(
  [getProductionResourceSlots2],
  slots => {
    return slots.reduce((acc, slot) => {
      const group = acc[slot.group_id] || [];
      return {
        ...acc,
        [slot.group_id]: [...group, slot]
      };
    }, {});
  }
);

export const getProductionExpenses = createSelector(
  [getProduction],
  production => production.budget_expenses
);
export const getProductionExpensesByGroup = createSelector(
  [getProductionExpenses],
  slots => {
    return slots.reduce((acc, slot) => {
      const group = acc[slot.group_id] || [];
      return {
        ...acc,
        [slot.group_id]: [...group, slot]
      };
    }, {});
  }
);

const getProductionExpensesByBookingId = createSelector(
  [getProductionExpenses],
  slots => {
    return slots.reduce((acc, slot) => {
      if (slot.archived) {
        return acc;
      }
      (slot.expenses || []).forEach(expense => {
        if (!expense.archived) {
          acc = {
            ...acc,
            [expense.booking_id]: acc[expense.booking_id]
              ? [...acc[expense.booking_id], expense]
              : [expense]
          };
        }
      });
      return acc;
    }, {});
  }
);

export const makeGetProductionExpensesByBookingId = () =>
  createSelector([getProductionExpensesByBookingId], values => values);

export const getProductionSlotsAndExpensesByGroup = createSelector(
  [getProductionResourceSlots2, getProductionExpenses],
  (resourceSlots, expenseSlots) => {
    return [resourceSlots, expenseSlots].reduce((acc, arr) => {
      return arr.forEach(slot => {
        const group = acc[slot.group_id] || [];
        return {
          ...acc,
          [slot.group_id]: [...group, slot]
        };
      });
    }, {});
  }
);

export const getProductionResourcing = createSelector(
  [
    getProduction,
    getProductionGroups,
    getProductionResourceSlots2,
    getProductionExpenses,
    getExpensesByBookingId,
    getBookingStore
  ],
  (
    production,
    groups,
    resourceSlots,
    expenseSlots,
    expensesByBookingId,
    bookings
  ) => {
    const resourceItems = (resourceSlots || []).reduce((slotAcc, slot) => {
      // TODO: this should be handled by slot selectors
      if (slot.archived) {
        return slotAcc;
      }
      const estimate = getResourceSlotTotalsFormatted(
        slot,
        production.budget_currency
      );

      const slotItems = (slot.resource_slot_assignments || []).reduce(
        (assAcc, assignment) => {
          if (assignment.archived) {
            return assAcc;
          }

          const booking = bookings.data[assignment.booking_id];
          const invoices = booking ? booking.invoices : [];
          const expenses = booking ? expensesByBookingId[booking.id] || [] : [];

          const documents = getBookingDocumentsSummary(invoices, expenses);
          const documentsFormatted = {
            ...documents,
            total: formatCurrency(documents.total, production.budget_currency),
            totalRaw: documents.total
          };

          const actual = booking
            ? getBookingTotalsFormatted(
                {
                  ...booking,
                  events: assignment.events
                },
                expenses,
                production.budget_currency
              )
            : {};
          const variance =
            !isNaN(actual.totalRaw) && !isNaN(documents.total)
              ? actual.totalRaw - documents.total
              : null;

          return [
            ...assAcc,
            {
              id: `resource-${assignment.id}`,
              productionId: production.id,
              groupId: slot.group_id,
              type: 'RESOURCE',
              estimate,
              actual,
              assignment,
              isFilled: !!assignment,
              slot,
              documents: documentsFormatted,
              variance: {
                value: variance
                  ? formatCurrency(
                      Math.abs(variance),
                      production.budget_currency
                    )
                  : null,
                sign: Math.sign(variance)
              }
            }
          ];
        },
        []
      );

      return [
        ...slotAcc,
        {
          id: `slot-${slot.id}`,
          type: 'RESOURCE',
          isFilled: false,
          productionId: production.id,
          groupId: slot.group_id,
          slot: slot,
          assignment: null,
          subRows: slotItems,
          estimate
        }
      ];
    }, []);

    const expenseItems = (expenseSlots || []).reduce((slotAcc, slot) => {
      // TODO: this should be handled by slot selectors
      if (slot.archived) {
        return slotAcc;
      }

      const estimate = getExpenseSlotTotalsFormatted(
        slot,
        production.budget_currency
      );

      const slotItems = (slot.expenses || []).reduce((expenseAcc, expense) => {
        if (expense.archived || expense.booking_id) {
          return expenseAcc;
        }

        const documents = getExpenseDocumentsSummary(expense.receipts);
        const documentsFormatted = {
          ...documents,
          total: formatCurrency(documents.total, production.budget_currency),
          totalRaw: documents.total
        };
        const actual = getExpenseTotalsFormatted(
          expense,
          production.budget_currency
        );

        const variance =
          !isNaN(actual.totalRaw) && !isNaN(documents.total)
            ? actual.totalRaw - documents.total
            : null;

        return [
          ...expenseAcc,
          {
            id: `expense-${expense.id}`,
            productionId: production.id,
            groupId: slot.group_id,
            type: 'EXPENSE',
            estimate,
            actual,
            expense,
            isFilled: !!expense,
            slot,
            documents: documentsFormatted,
            variance: {
              value: variance
                ? formatCurrency(Math.abs(variance), production.budget_currency)
                : null,
              sign: Math.sign(variance)
            }
          }
        ];
      }, []);

      return [
        ...slotAcc,
        {
          id: `slot-${slot.id}`,
          type: 'EXPENSE',
          isFilled: false,
          productionId: production.id,
          groupId: slot.group_id,
          slot: slot,
          assignment: null,
          subRows: slotItems,
          estimate
        }
      ];
    }, []);

    const allItems = [...resourceItems, ...expenseItems];
    const orderedItems = orderBy(allItems, ['slot.order']);
    return {
      groups,
      data: orderedItems
    };
  }
);

export const getProductionResourcingTotals = createSelector(
  [getProductionResourcing],
  resourcing =>
    resourcing.data.reduce(
      (acc, row) => {
        if (row.subRows && row.subRows.length) {
          row.subRows.forEach(item => {
            if (get(item, 'slot.include_in_calculation')) {
              acc = {
                planned: acc.planned + get(item, 'actual.totalRaw', 0),
                actual: acc.actual + get(item, 'documents.totalRaw', 0)
              };
            }
          });
        } else {
          if (get(row, 'slot.include_in_calculation')) {
            acc = {
              ...acc,
              planned: acc.planned + get(row, 'estimate.totalRaw', 0)
            };
          }
        }

        return acc;
      },
      { planned: 0, actual: 0 }
    )
);
