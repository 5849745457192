import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCell } from 'v1/components/shared';
import CalendarPen from '../CalendarPen/CalendarPen';

const CalendarPens = ({ statuses, activeStatus, setActivatedStatus }) => (
  <Grid wrap gutter="S" gutters="XS">
    {statuses.map(status => (
      <GridCell width="auto" key={status.id}>
        <CalendarPen
          label={status.name}
          colour={status.colour}
          onClick={() => setActivatedStatus(status)}
          isActive={activeStatus && activeStatus.id === status.id}
        />
      </GridCell>
    ))}
  </Grid>
);

CalendarPens.propTypes = {
  statuses: PropTypes.array.isRequired,
  setActivatedStatus: PropTypes.func.isRequired,
  activeStatus: PropTypes.object
};

export default CalendarPens;
