import { createBrowserHistory } from 'history';
import createStore from './create';

const history = createBrowserHistory();

history.listen(location => {
  setTimeout(() => {
    if (location.action === 'POP') {
      return;
    }
    window.scrollTo(0, 0);
  });
});

const store = createStore();

export default store;
