import get from 'lodash/get';

export default class Lookup {
  /**
   * @type {[{label: string, value: *, description: string}], Function}
   */
  items = [];

  /**
   * Resolve an item
   * @param {*} value
   * @returns {Object|null}
   */
  resolveItem(value) {
    if (value && typeof value === 'object') {
      value = value.value;
    }
    const items = typeof this.items === 'function' ? this.items() : this.items;
    return items.find(item => item.value === value) || null;
  }

  /**
   * Resolve an item value
   * @param {number|string|Object} value
   * @returns {Object|null}
   */
  resolveItemValue(value) {
    if (typeof value === 'object') {
      return value.value;
    }
    return value;
  }

  /**
   * Resolve an item label
   * @param {string} value
   * @returns {string}
   */
  resolveItemLabel(value) {
    return get(this.resolveItem(value), 'label', '');
  }

  /**
   * Resolve an item description
   * @param {string} value
   * @returns {string}
   */
  resolveItemDescription(value) {
    return get(this.resolveItem(value), 'description', '');
  }
}
