import { EXPENSES_ACTIONS } from 'store/v1/expenses/expenses.constants.js';
import get from 'lodash/get';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  let receipts;
  switch (action.type) {
    case EXPENSES_ACTIONS.GET_EXPENSES:
    case EXPENSES_ACTIONS.CREATE_EXPENSE:
    case EXPENSES_ACTIONS.UPDATE_EXPENSE:
    case EXPENSES_ACTIONS.DELETE_EXPENSE:
    case EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT:
    case EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case EXPENSES_ACTIONS.GET_EXPENSES_SUCCESS:
      let updatedData = { ...state.data };
      if (action.topUpData) {
        action.result.results.map(result => {
          if (!updatedData[result.id])
            updatedData = { ...updatedData, [result.id]: result };
        });
      } else {
        updatedData = action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        );
      }
      return {
        ...state,
        data: updatedData,
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        fetched: true
      };
    case EXPENSES_ACTIONS.UPDATE_EXPENSE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        loading: false
      };
    case EXPENSES_ACTIONS.CREATE_EXPENSE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case EXPENSES_ACTIONS.CREATE_EXPENSE_RECEIPT_SUCCESS:
      receipts = get(state.data, [action.expense_item_id, 'receipts'], []);
      return {
        ...state,
        data: {
          ...state.data,
          [action.expense_item_id]: {
            ...state.data[action.expense_item_id],
            receipts: [...receipts, action.result]
          }
        },
        loading: false
      };
    case EXPENSES_ACTIONS.UPDATE_EXPENSE_RECEIPT_SUCCESS:
      receipts = get(state.data, [action.expense_item_id, 'receipts'], []);
      receipts = receipts.map(receipt => {
        if (receipt.id !== action.result.id) return receipt;
        return action.result;
      });
      return {
        ...state,
        data: {
          ...state.data,
          [action.expense_item_id]: {
            ...state.data[action.expense_item_id],
            receipts
          }
        },
        loading: false
      };
    case EXPENSES_ACTIONS.DELETE_EXPENSE_RECEIPT_SUCCESS:
      receipts = get(state.data, [action.expense_item_id, 'receipts'], []);
      receipts = receipts.filter(receipt => receipt.id !== action.receipt);
      return {
        ...state,
        data: {
          ...state.data,
          [action.expense_item_id]: {
            ...state.data[action.expense_item_id],
            receipts
          }
        },
        loading: false
      };
    case EXPENSES_ACTIONS.DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            archived: true
          }
        },
        order: state.order.filter(id => id !== action.id),
        loading: false
      };
    case EXPENSES_ACTIONS.GET_EXPENSES_FAILURE:
    case EXPENSES_ACTIONS.UPDATE_EXPENSE_FAILURE:
    case EXPENSES_ACTIONS.CREATE_EXPENSE_FAILURE:
    case EXPENSES_ACTIONS.DELETE_EXPENSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
