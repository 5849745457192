import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

// const prefix = '@@shortlistsActions';

export const SHORTLISTS_ACTIONS = mapValues(
  mirror({
    GET_SHORTLISTS: null,
    GET_SHORTLISTS_SUCCESS: null,
    GET_SHORTLISTS_FAILURE: null,

    GET_SHORTLIST: null,
    GET_SHORTLIST_SUCCESS: null,
    GET_SHORTLIST_FAILURE: null,

    DUPLICATE_SHORTLIST: null,
    DUPLICATE_SHORTLIST_SUCCESS: null,
    DUPLICATE_SHORTLIST_FAILURE: null,

    BATCH_ADD_SHORTLIST: null,
    BATCH_ADD_SHORTLIST_SUCCESS: null,
    BATCH_ADD_SHORTLIST_FAILURE: null,

    GET_PUBLIC_SHORTLIST: null,
    GET_PUBLIC_SHORTLIST_SUCCESS: null,
    GET_PUBLIC_SHORTLIST_FAILURE: null,

    CREATE_SHORTLIST: null,
    CREATE_SHORTLIST_SUCCESS: null,
    CREATE_SHORTLIST_FAILURE: null,

    CREATE_SHORTLIST_BLOCK: null,
    CREATE_SHORTLIST_BLOCK_SUCCESS: null,
    CREATE_SHORTLIST_BLOCK_FAILURE: null,

    UPDATE_SHORTLIST_BLOCK: null,
    UPDATE_SHORTLIST_BLOCK_SUCCESS: null,
    UPDATE_SHORTLIST_BLOCK_FAILURE: null,

    DELETE_SHORTLIST_BLOCK: null,
    DELETE_SHORTLIST_BLOCK_SUCCESS: null,
    DELETE_SHORTLIST_BLOCK_FAILURE: null,

    CREATE_SHORTLIST_BLOCK_PIN: null,
    CREATE_SHORTLIST_BLOCK_PIN_SUCCESS: null,
    CREATE_SHORTLIST_BLOCK_PIN_FAILURE: null,

    DELETE_SHORTLIST_BLOCK_PIN: null,
    DELETE_SHORTLIST_BLOCK_PIN_SUCCESS: null,
    DELETE_SHORTLIST_BLOCK_PIN_FAILURE: null,

    CREATE_BULK_SHORTLISTS: null,
    CREATE_BULK_SHORTLISTS_SUCCESS: null,
    CREATE_BULK_SHORTLISTS_FAILURE: null,

    UPDATE_SHORTLIST: null,
    UPDATE_SHORTLIST_SUCCESS: null,
    UPDATE_SHORTLIST_FAILURE: null,

    UPVOTE_SHORTLIST_BLOCK: null,
    UPVOTE_SHORTLIST_BLOCK_SUCCESS: null,
    UPVOTE_SHORTLIST_BLOCK_FAILURE: null,

    DOWNVOTE_SHORTLIST_BLOCK: null,
    DOWNVOTE_SHORTLIST_BLOCK_SUCCESS: null,
    DOWNVOTE_SHORTLIST_BLOCK_FAILURE: null,

    COMMENT_SHORTLIST_BLOCK: null,
    COMMENT_SHORTLIST_BLOCK_SUCCESS: null,
    COMMENT_SHORTLIST_BLOCK_FAILURE: null,

    SEARCH_SHORTLIST_BLOCKS: null,
    SEARCH_SHORTLIST_BLOCKS_SUCCESS: null,
    SEARCH_SHORTLIST_BLOCKS_FAILURE: null,

    DELETE_SHORTLIST: null,
    DELETE_SHORTLIST_SUCCESS: null,
    DELETE_SHORTLIST_FAILURE: null,

    SET_SHORTLIST_UPDATED_AT: null
  }),
  value => value
);
