import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Capability } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import PinnedListContainer from 'v1/components/feature/ResourceComponents/Resource/Pins/ContactPinned/PinnedListContainer/PinnedListContainer';
import { MultiMediaUploader } from 'v1/components/shared';
import MediaItemSidebar from 'v1/components/modals/MediaModals/MediaFocusModal/MediaItemSidebar/MediaItemSidebar';
import DEPRECATED_TagInput from 'v1/components/legacy/deprecated/DEPRECATED_TagInput/DEPRECATED_TagInput';
import { Loading } from 'v1/components/shared';
import { bindActionCreators } from 'redux';
import {
  createContactPin,
  deleteContactPin,
  updateContactPin,
  updateContact,
  updateContactPins
} from 'store/v1/contacts/contacts.actions.js';
import { makeFileURL } from 'lib/s3/s3Helper';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import arrayMove from 'array-move';
import { get, isEmpty, chain, find } from 'lodash';
import { v4 } from 'uuid';

import './ContactPinned.scss';

class ContactPinned extends Component {
  constructor(props) {
    super(props);

    this.contact_id = props.contact_id || parseInt(props.match.params.id);

    this.state = {
      tags: [],
      suggestibleTags: this.buildSuggestibleTags(props),
      pins:
        get(this.props, [
          'contacts',
          'associations_data',
          this.contact_id,
          'pins'
        ]) || []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        if (this.state.updatingPin) {
          this.props.displayAlert('success', 'Pin Updated');
          this.setState({ updatingPin: false });
        }
        if (this.state.settingProfilePicture) {
          this.props.displayAlert('success', 'Profile picture saved');
          this.setState({ settingProfilePicture: false });
        }
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
      }
    }
    if (!nextProps.contacts.loading) {
      this.setState({
        suggestibleTags: this.buildSuggestibleTags(nextProps),
        pins:
          get(nextProps, [
            'contacts',
            'associations_data',
            this.contact_id,
            'pins'
          ]) || []
      });
    }
    if (
      this.contact_id != get(nextProps, 'contact_id') ||
      this.contact_id != get(nextProps, 'match.params.id')
    ) {
      this.contact_id =
        nextProps.contact_id || parseInt(nextProps.match.params.id);
    }
  }
  handleUpdateOrder = (oldIndex, newIndex) => {
    this.setState(
      prev => {
        let pins = chain(prev.pins)
          .filter(b => !b.archived)
          .orderBy(['order', 'asc'])
          .value();
        pins = arrayMove(pins, oldIndex, newIndex);
        const updatedPins = pins.map((pin, index) => ({
          ...pin,
          order: index
        }));
        return { pins: updatedPins };
      },
      () => this.handleUpdatePinOrder()
    );
  };
  buildSuggestibleTags = props => {
    const associations = props.contacts.associations_data[this.contact_id];
    const pins = get(associations, 'pins');

    if (!isEmpty(pins)) {
      const suggestibleTags = [];
      pins
        .filter(p => !p.archived)
        .map(p => p.tags && p.tags.map(t => suggestibleTags.push(t.tag)));
      return suggestibleTags;
    }
    return [];
  };
  handleUpdatePin = (pin_id, data) => {
    this.setState({ event: v4(), updatingPin: true }, () => {
      this.props.updateContactPin(
        data,
        { pin_id, contact_id: this.contact_id },
        this.state.event
      );
    });
  };
  handleUpdatePinOrder = () => {
    this.setState({ event: v4() }, () => {
      this.props.updateContactPins(
        { contact_id: this.contact_id },
        this.state.pins,
        this.state.event
      );
    });
  };
  onSetAsProfilePicture = pin => {
    const pinFile = get(pin, 'file', null);
    const profilePictureUrl = pinFile ? makeFileURL(pinFile) : pin.url;
    this.setState({ event: v4(), settingProfilePicture: true }, () => {
      this.props.updateContact(
        this.contact_id,
        { profile_picture: profilePictureUrl },
        this.state.event,
        true
      );
    });
  };
  handleDeletePin = pin_id => {
    this.setState({ event: v4() }, () => {
      this.props.deleteContactPin(pin_id, this.contact_id, this.state.event);
    });
  };
  filterPins = pins => {
    const tags = this.state.tags;
    let filteredPins = [];
    pins = chain(pins)
      .filter(p => !p.archived)
      .orderBy('order', 'asc')
      .value();
    pins.map(pin => {
      const tagsArray = pin.tags.map(t => t.tag);
      const isMatch = tags.every(i => tagsArray.includes(i));
      return isMatch ? filteredPins.push(pin) : null;
    });
    return filteredPins;
  };
  handleUpload = (path, data) => {
    let toSubmit;

    switch (path) {
      case 'URL':
        let pin_type = 'external_link';
        switch (data.provider) {
          case 'YOUTUBE':
            pin_type = 'youtube/video';
            break;
          case 'VIMEO':
            pin_type = 'vimeo/video';
            break;
          case 'IMAGE':
            pin_type = 'external_image_link';
            break;
          case 'EXTERNAL_URL':
          default:
            pin_type = 'external_link';
        }
        toSubmit = { url: data.url, pin_type };
        break;
      case 'FILE':
        toSubmit = {
          url: data.url,
          pin_type: 'file',
          file_id: data.file_id
        };
        break;
      case 'GOOGLE':
        toSubmit = {
          type: `google/${data.mimeType}`,
          file: {
            url: data.url,
            size: data.sizeBytes,
            name: data.name,
            mime_type: data.mimeType,
            type: 'google'
          }
        };
        break;
      default:
        return;
    }
    return this.setState({ event: v4() }, () => {
      this.props.createContactPin(toSubmit, this.contact_id, this.state.event);
    });
  };
  render() {
    const { contacts, hideUploader, layout } = this.props;
    const associations = contacts.associations_data[this.contact_id];
    if (!associations) return <Loading text="Fetching pins..." />;

    return (
      <div className="ContactWindowContainer ContactPinned">
        {!hideUploader && (
          <div className="ContactPinned-header">
            <div className="ContactPinned-header-search">
              {!isEmpty(associations.pins) && (
                <DEPRECATED_TagInput
                  tags={this.state.tags || []}
                  placeholder="Search tagged pins"
                  inputClassname="form-control small-input"
                  suggestions={this.state.suggestibleTags}
                  onChange={tags => this.setState({ tags })}
                />
              )}
            </div>
            <div className="ContactPinned-header-add">
              <RestrictedAccess capabilities={Capability.RESOURCE_UPDATE}>
                <MultiMediaUploader
                  title="Add Pins"
                  buttonLabel="Add Pins"
                  buttonIcon="/images/icon_add_white.svg"
                  buttonClass="btn btn-primary btn-small"
                  uploadMethods={['computer', 'url']}
                  onUpload={this.handleUpload}
                  onFileCompleted={file => this.handleUpload('FILE', file)}
                  uploading={contacts.loading === 'CREATE_CONTACT_PIN'}
                  secure={false}
                />
              </RestrictedAccess>
            </div>
          </div>
        )}
        <div className="ContactPinned-body">
          <PinnedListContainer
            options={{
              masonryLayout: layout
            }}
            pins={this.filterPins(this.state.pins)}
            onDeletePin={this.handleDeletePin}
            deleting={contacts.loading === 'DELETE_CONTACT_PIN'}
            onSetAsProfilePicture={this.onSetAsProfilePicture}
            handleUpdateOrder={this.handleUpdateOrder}
            onExpand={pinId => {
              this.props.openModal('MediaFocusModal', {
                mediaType: 'PINS',
                mediaSelected: find(this.state.pins, { id: pinId }),
                mediaList: this.filterPins(this.state.pins),
                mediaSidebar: selected => {
                  return (
                    <MediaItemSidebar
                      selected={selected}
                      handleUpdatePin={this.handleUpdatePin}
                    />
                  );
                }
              });
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ contacts, events }) {
  return { contacts, events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createContactPin,
      deleteContactPin,
      updateContactPin,
      displayAlert,
      updateContact,
      updateContactPins,
      openModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPinned);
