import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-form';
import get from 'lodash/get';

import * as entityTypes from 'v1/helpers/entityTypes';

import {
  StackedFormSection,
  Label,
  Select,
  DateRangeInput,
  ButtonToggle,
  ButtonToggleGroup,
  RelativeDateSelector
} from 'v1/components/shared';

// NOTE: All set to use query.date_filters[0] in case we want to add multiple date filters in the future
const DataExportsFormSectionDates = ({ formApi }) => {
  const setDateFilter = (date_type, date, setValue) => {
    setValue({
      ...formApi.getValue('query.date_filters[0]'),
      [date_type]: date
    });
  };

  return (
    <StackedFormSection title="Date range">
      <div className="stack-S">
        <Label htmlFor="query.date_filters[0].date_field">Date field</Label>
        <Select
          name="query.date_filters[0].date_field"
          value={formApi.getValue('query.date_filters[0].date_field')}
          onChange={({ target }) =>
            formApi.setValue('query.date_filters[0].date_field', target.value)
          }
          autoComplete="off"
        >
          <option value="created_at">Created at</option>
          {formApi.getValue('entity_type') === entityTypes.CALLSHEET && (
            <option value="callsheet_date">Callsheet date</option>
          )}
          {formApi.getValue('entity_type') === entityTypes.PRODUCTION && (
            <option value="production_date">Production date</option>
          )}
          {formApi.getValue('entity_type') === entityTypes.BOOKING && (
            <option value="booking_date">Booking date</option>
          )}
        </Select>
      </div>

      <div className="stack-S">
        <Field field="query.date_filters[0].is_relative">
          {({ value, setValue }) => (
            <>
              <Label>Date range type</Label>
              <ButtonToggleGroup value={value} onChange={setValue}>
                <ButtonToggle value={true} label="Relative dates" />
                <ButtonToggle value={false} label="Fixed dates" />
              </ButtonToggleGroup>
            </>
          )}
        </Field>
      </div>

      {formApi.getValue('query.date_filters[0].is_relative') && (
        <div className="stack-S">
          <Field field="query.date_filters[0].relative">
            {({ fieldName: name, value, setValue }) => (
              <RelativeDateSelector
                name={name}
                value={value}
                onChange={setValue}
              />
            )}
          </Field>
        </div>
      )}

      {!formApi.getValue('query.date_filters[0].is_relative') && (
        <div className="stack-S">
          <Field field="query.date_filters[0]">
            {/* TODO: make sure we can have no dates set, eg for all bookings */}
            {({ value, setValue }) => (
              <DateRangeInput
                appearance="outline"
                startDate={get(value, 'start')}
                endDate={get(value, 'end')}
                onChange={(date_type, date) =>
                  setDateFilter(date_type, date, setValue)
                }
                labels={{
                  start: 'From',
                  end: 'To'
                }}
                placeholder="Select date"
              />
            )}
          </Field>
        </div>
      )}
    </StackedFormSection>
  );
};

DataExportsFormSectionDates.props = {
  formApi: PropTypes.any.isRequired
};

export default DataExportsFormSectionDates;
