import { PUBLIC_SETTINGS_ACTIONS } from 'store/v1/public_settings/public_settings.constants.js';

export const getPublicSettings = team_schema => {
  return {
    types: [
      PUBLIC_SETTINGS_ACTIONS.GET_PUBLIC_SETTINGS,
      PUBLIC_SETTINGS_ACTIONS.GET_PUBLIC_SETTINGS_SUCCESS,
      PUBLIC_SETTINGS_ACTIONS.GET_PUBLIC_SETTINGS_FAILURE
    ],
    promise: client =>
      client.get(`/resource-portal/teams/${team_schema}/settings`, {
        isV2: true
      })
  };
};
