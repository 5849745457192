import { BOOKING_ACTIONS } from './bookings.constants.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { TASK_LISTS_ACTIONS } from 'store/task_lists/task_lists.constants.js';
import { filter, get, map } from 'lodash';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  new: {},
  loading: false
};

export default function reducer(state = INITIAL_STATE, action) {
  let updatedInvoices, booking;
  switch (action.type) {
    case BOOKING_ACTIONS.GET_BOOKINGS:
    case BOOKING_ACTIONS.GET_BOOKINGS_ONLY:
    case BOOKING_ACTIONS.GET_BOOKING:
    case BOOKING_ACTIONS.CREATE_BOOKING:
    case BOOKING_ACTIONS.UPDATE_BOOKING:
    case BOOKING_ACTIONS.DELETE_BOOKING:
    case BOOKING_ACTIONS.GET_BATCH_BOOKINGS:
    case BOOKING_ACTIONS.CREATE_INVOICES:
    case BOOKING_ACTIONS.UPDATE_INVOICE:
    case BOOKING_ACTIONS.DELETE_INVOICE:
    case BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case CONTACTS_ACTIONS.GET_CONTACT_ASSOCIATIONS_SUCCESS:
      const contactBookings = get(action.result, 'bookings', []);
      return {
        ...state,
        data: contactBookings.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        order: contactBookings.map(item => item.id),
        loading: false,
        error: false
      };
    case BOOKING_ACTIONS.GET_BOOKINGS_SUCCESS:
    case BOOKING_ACTIONS.GET_BOOKINGS_ONLY_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false
      };
    case BOOKING_ACTIONS.GET_BATCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        data: action.result.data.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        loading: false
      };
    case BOOKING_ACTIONS.GET_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        }
      };
    case BOOKING_ACTIONS.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        order: state.order.filter(i => i !== action.booking_id)
      };
    case TASK_LISTS_ACTIONS.ASSIGN_TASK_LIST_TO_BOOKING_SUCCESS:
      booking = get(state.data, action.bookingId);
      return {
        ...state,
        data: {
          ...state.data,
          [action.bookingId]: {
            ...booking,
            tasklist_id: action.taskListId
          }
        }
      };
    case PRODUCTIONS_ACTIONS.DELETE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS:
      booking = get(state.data, action.booking_id);
      if (booking) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.booking_id]: {
              ...booking,
              resource_slot_assignments: filter(
                booking.resource_slot_assignments,
                slotAssignment =>
                  slotAssignment.id !== action.resource_slot_assignment_id
              )
            }
          }
        };
      }
      return state;
    case BOOKING_ACTIONS.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        order: [action.result.id, ...state.order],
        new: action.result
      };
    case BOOKING_ACTIONS.CREATE_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...(state.data.hasOwnProperty(action.booking_id) && {
            [action.booking_id]: {
              ...state.data[action.booking_id],
              invoices: [
                ...state.data[action.booking_id].invoices,
                action.result
              ]
            }
          })
        },
        new: action.result
      };
    case BOOKING_ACTIONS.UPDATE_INVOICE_SUCCESS:
      updatedInvoices = map(
        get(state.data, [action.booking_id, 'invoices']),
        invoice => (invoice.id === action.result.id ? action.result : invoice)
      );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.booking_id]: {
            ...state.data[action.booking_id],
            invoices: updatedInvoices
          }
        },
        new: action.result
      };
    case BOOKING_ACTIONS.DELETE_INVOICE_SUCCESS:
      updatedInvoices = filter(
        get(state.data, [action.booking_id, 'invoices']),
        invoice => invoice.id !== action.invoice_id
      );
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.booking_id]: {
            ...state.data[action.booking_id],
            invoices: updatedInvoices
          }
        },
        new: action.result
      };
    case BOOKING_ACTIONS.DELETE_BOOKING_SUCCESS:
      const { [action.booking_id]: item, ...data } = state.data;
      return {
        ...state,
        loading: false,
        data,
        order: state.order.filter(i => i !== action.booking_id)
      };
    case BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case BOOKING_ACTIONS.GET_BOOKINGS_FAILURE:
    case BOOKING_ACTIONS.GET_BOOKINGS_ONLY_FAILURE:
    case BOOKING_ACTIONS.GET_BOOKING_FAILURE:
    case BOOKING_ACTIONS.CREATE_BOOKING_FAILURE:
    case BOOKING_ACTIONS.UPDATE_BOOKING_FAILURE:
    case BOOKING_ACTIONS.DELETE_BOOKING_FAILURE:
    case BOOKING_ACTIONS.GET_BATCH_BOOKINGS_FAILURE:
    case BOOKING_ACTIONS.CREATE_INVOICES_FAILURE:
    case BOOKING_ACTIONS.UPDATE_INVOICE_FAILURE:
    case BOOKING_ACTIONS.DELETE_INVOICE_FAILURE:
    case BOOKING_ACTIONS.SEND_BOOKING_CONFIRMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case PRODUCTIONS_ACTIONS.GET_PRODUCTION_CALENDAR_SUCCESS:
      return {
        ...state,
        data: action.result.bookings.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        )
      };
    default:
      return state;
  }
}
