import { useContext, useEffect } from 'react';
import SocketContext from 'lib/websockets/SocketContext';

const useSocketEvent = (callback, subscribeEventTypes) => {
  const socket = useContext(SocketContext);

  const eventListener = event => {
    if (subscribeEventTypes.includes(event.type)) {
      callback(event);
    }
  };

  useEffect(() => {
    if (!socket) {
      console.error('Socket client not set in context');
      return () => {};
    }

    if (!socket.connected) {
      //console.error('Socket client not connected');
      return () => {};
    }

    socket.on('EVENT', eventListener);

    return () => {
      socket.off('EVENT', eventListener);
    };
  });
};

export default useSocketEvent;
