import AVAILABILITIES_ACTIONS from './availabilities.constants.js';
import { getCreateOrDeleteAvailabilities } from 'store/availabilities/availabilities.selectors.js';
import store from 'store';

export const addLocalAvailability = availability => {
  return {
    type: AVAILABILITIES_ACTIONS.ADD_LOCAL_AVAILABILITY,
    payload: availability
  };
};

export const updateLocalAvailability = (availabilityId, durationDetails) => {
  return {
    type: AVAILABILITIES_ACTIONS.UPDATE_LOCAL_AVAILABILITY,
    payload: {
      availabilityId,
      durationDetails
    }
  };
};

export const deleteLocalAvailability = availabilityId => {
  return {
    type: AVAILABILITIES_ACTIONS.DELETE_LOCAL_AVAILABILITY,
    payload: {
      availabilityId
    }
  };
};

// ​/v2​/resource-portal​/teams​/{teamSchema}​/availability-requests​/{availabilityRequestId}
export const getAvailabilityRequest = (
  teamSchema,
  availabilityRequestId,
  token
) => {
  return {
    types: [
      AVAILABILITIES_ACTIONS.GET_AVAILABILITY_REQUEST,
      AVAILABILITIES_ACTIONS.GET_AVAILABILITY_REQUEST_SUCCESS,
      AVAILABILITIES_ACTIONS.GET_AVAILABILITY_REQUEST_FAILURE
    ],
    promise: client =>
      client.get(
        `/resource-portal/teams/${teamSchema}/availability-requests/${availabilityRequestId}`,
        {
          isV2: true,
          token
        }
      )
  };
};

export const getAvailabilities = (
  teamSchema,
  resourceId,
  startTimestampUTC,
  endTimeStampUTC,
  token
) => {
  return {
    types: [
      AVAILABILITIES_ACTIONS.GET_AVAILABILITIES,
      AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_SUCCESS,
      AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_FAILURE
    ],
    promise: client =>
      client.get(
        `/resource-portal/teams/${teamSchema}/resources/${resourceId}/availabilities`,
        {
          isV2: true,
          params: {
            startTimestampUTC,
            endTimeStampUTC
          },
          token
        }
      )
  };
};

export const createOrUpdateAvailabilities = (teamSchema, resourceId, token) => {
  const payload = getCreateOrDeleteAvailabilities(store.getState());

  return {
    types: [
      AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES,
      AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES_SUCCESS,
      AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES_FAILURE
    ],
    promise: client =>
      client.patch(
        `/resource-portal/teams/${teamSchema}/resources/${resourceId}/availabilities`,
        {
          isV2: true,
          data: payload,
          token
        }
      )
  };
};

// /v2/resource-portal/teams/{teamSchema}/availability-requests/{availabilityRequestId}/logged
export const logSuccessfulCreateOrUpdate = (
  teamSchema,
  availabilityRequestId,
  token
) => {
  return {
    types: [
      AVAILABILITIES_ACTIONS.LOG_CREATE_AVAILABILITIES,
      AVAILABILITIES_ACTIONS.LOG_CREATE_AVAILABILITIES_SUCCESS,
      AVAILABILITIES_ACTIONS.LOG_CREATE_AVAILABILITIES_FAILURE
    ],
    promise: client =>
      client.put(
        `/resource-portal/teams/${teamSchema}/availability-requests/${availabilityRequestId}/logged`,
        {
          isV2: true,
          token
        }
      )
  };
};

// /v2/resource-portal/teams/{teamSchema}/resources/{resourceId}/bookings
export const getBookings = (
  teamSchema,
  resourceId,
  startTimestampUTC,
  endTimeStampUTC,
  token
) => {
  return {
    types: [
      AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_BOOKINGS,
      AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_BOOKINGS_SUCCESS,
      AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_BOOKINGS_FAILURE
    ],
    promise: client =>
      client.get(
        `/resource-portal/teams/${teamSchema}/resources/${resourceId}/bookings`,
        {
          isV2: true,
          params: {
            startTimestampUTC,
            endTimeStampUTC
          },
          token
        }
      )
  };
};
