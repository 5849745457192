import classNames from 'classnames';
import { HTMLProps } from 'react';

export interface NoResultsProps extends HTMLProps<HTMLDivElement> {}

export function SelectOptionEmpty({ className, ...rest }: NoResultsProps) {
  return (
    <div
      className={classNames(
        'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-text-gray-600',
        className
      )}
      {...rest}
    >
      No results
    </div>
  );
}
