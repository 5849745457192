import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountDto } from 'dtos/account.dto';
import { GetInvitedAccountDto } from 'dtos/get-invited-account.dto';
import { InviteAccountDto } from 'dtos/invite-account.dto';
import { SuccessDto } from 'dtos/success.dto';
import { UpdateAccountDto } from 'dtos/update-account.dto';
import { ACCOUNTS_ACTIONS } from './accounts.constants';
import ApiClient from 'lib/api/ApiClient';

const isV2 = true;
const token = true;

const client = new ApiClient() as any;

const getTenantAccounts = createAsyncThunk(
  ACCOUNTS_ACTIONS.getTenantAccounts,
  async () => {
    const { data } = await client.get('/auth/tenant/accounts', {
      isV2,
      token
    });
    return data as AccountDto[];
  }
);

const getTeamAccounts = createAsyncThunk(
  ACCOUNTS_ACTIONS.getTeamAccounts,
  async () => {
    const { data } = await client.get('/auth/teams/:app_id:/accounts', {
      isV2,
      token
    });
    return data as AccountDto[];
  }
);

const inviteAccount = createAsyncThunk(
  ACCOUNTS_ACTIONS.inviteAccount,
  async (data: InviteAccountDto) =>
    (await client.post(`/auth/teams/:app_id:/invite-user`, {
      data,
      isV2,
      token
    })) as GetInvitedAccountDto
);

const updateAccount = createAsyncThunk(
  ACCOUNTS_ACTIONS.updateAccount,
  async (
    { id, data }: { id: string; data: UpdateAccountDto },
    { rejectWithValue }
  ) => {
    const res = (await client.patch(`/auth/accounts/${id}`, {
      data,
      isV2,
      token
    })) as SuccessDto;
    return res.data === 'success' ? data : rejectWithValue(null);
  }
);

const archiveAccount = createAsyncThunk(
  ACCOUNTS_ACTIONS.archiveAccount,
  async (id: string) =>
    (await client.del(`/auth/accounts/${id}`, {
      isV2,
      token
    })) as SuccessDto
);

const unarchiveAccount = createAsyncThunk(
  ACCOUNTS_ACTIONS.unarchiveAccount,
  async (id: string) =>
    (await client.put(`/auth/accounts/${id}/enable`, {
      isV2,
      token
    })) as SuccessDto
);

const addAccountToUserGroup = createAsyncThunk(
  ACCOUNTS_ACTIONS.addAccountToUserGroup,
  async ({
    accountId,
    userGroupId
  }: {
    accountId: string;
    userGroupId: string;
  }) =>
    (await client.post(
      `/auth/accounts/${accountId}/user-groups/${userGroupId}`,
      {
        isV2,
        token
      }
    )) as SuccessDto
);

const removeAccountFromUserGroup = createAsyncThunk(
  ACCOUNTS_ACTIONS.removeAccountFromUserGroup,
  async ({
    accountId,
    userGroupId
  }: {
    accountId: string;
    userGroupId: string;
  }) =>
    (await client.del(
      `/auth/accounts/${accountId}/user-groups/${userGroupId}`,
      {
        isV2,
        token
      }
    )) as SuccessDto
);

export {
  getTenantAccounts,
  getTeamAccounts,
  inviteAccount,
  updateAccount,
  archiveAccount,
  unarchiveAccount,
  addAccountToUserGroup,
  removeAccountFromUserGroup
};
