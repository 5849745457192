import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserGroup } from '__types__';
import { assignUserGroupToTeam } from 'store/teams';
import {
  getTeamUserGroups,
  getUserGroup,
  createUserGroup,
  updateUserGroup,
  archiveUserGroup,
  addCapabilityToUserGroup,
  removeCapabilityFromUserGroup
} from './userGroups.actions';

export type UserGroupsStoreData = Record<string, UserGroup>;

export type UserGroupsStore = {
  data: UserGroupsStoreData;
  // order: number[]; // TODO
  // paging?: Paging; // TODO
  fetched?: boolean;
  loading?: boolean | string;
  new?: UserGroup;
  // TODO: TS: any
  error?: any;
};

export const INITIAL_STATE: UserGroupsStore = {
  data: {},
  // paging: {},
  // order: [],
  // fetched: false,
  loading: false
};

const userGroupsSlice = createSlice({
  name: 'userGroups',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(
        getTeamUserGroups.fulfilled,
        (state, { payload }: PayloadAction<UserGroup[]>) => {
          state.data = payload.reduce(
            (data: UserGroupsStoreData, userGroup: UserGroup) => ({
              ...data,
              [userGroup.id]: userGroup
            }),
            {}
          );
          state.fetched = true;
        }
      )

      .addCase(
        getUserGroup.fulfilled,
        (state, { payload }: PayloadAction<UserGroup>) => {
          state.data[payload.id] = payload;
        }
      )

      .addCase(
        createUserGroup.fulfilled,
        (state, { payload }: PayloadAction<UserGroup>) => {
          state.new = payload;
        }
      )

      .addCase(assignUserGroupToTeam.fulfilled, (state, { meta: { arg } }) => {
        if (state.new && state.new.id === arg) {
          state.data[arg] = state.new;
          delete state.new;
        } else {
          delete state.new;
        }
      })

      .addCase(updateUserGroup.fulfilled, (state, { meta: { arg } }) => {
        state.data[arg.id] = {
          ...state.data[arg.id],
          ...arg.data
        };
      })

      .addCase(archiveUserGroup.fulfilled, (state, { meta: { arg } }) => {
        delete state.data[arg];
      })

      .addCase(
        addCapabilityToUserGroup.fulfilled,
        (state, { meta: { arg } }) => {
          const capabilities = state.data[arg.userGroupId].capabilities;
          state.data[arg.userGroupId].capabilities = capabilities
            ? [...capabilities, arg.capability]
            : [arg.capability];
        }
      )

      .addCase(
        removeCapabilityFromUserGroup.fulfilled,
        (state, { meta: { arg } }) => {
          const capabilities = state.data[arg.userGroupId].capabilities;
          state.data[arg.userGroupId].capabilities = capabilities
            ? capabilities.filter(e => e !== arg.capability)
            : [];
        }
      )

      // Generic
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default userGroupsSlice.reducer;
