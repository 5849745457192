import { useSelector } from 'react-redux';
import { selectAppId } from 'store/v1/auth/auth.selectors';
import { withinApp } from '../../../core/appRoutes';

export function useAppRoutes() {
  const app_id = useSelector(selectAppId);

  return withinApp(app_id);
}

export type UseAppRoutes = typeof useAppRoutes;
export type AppRoutesFactory = ReturnType<UseAppRoutes>;
