import { PromisePool } from '@supercharge/promise-pool';
import { Shortlist } from '__types__';
import { ApiClient } from 'lib/api/ApiClient.types';
import { omit } from 'lodash';
import { duplicateShortlistBlockPins } from 'v1/helpers/byModel/shortlistDuplicateHelper';
import { ListQuery, ListResponse } from 'v4/entities/common/common.crud.types';
import { parseWithDecoratedError } from 'v4/entities/common/parseWithDecoratedError';
import { Resource_normalized } from 'v4/entities/resource/resource.types';
import {
  AssociationQuery,
  BatchUpdateHttpRequest,
  batchUpdateHttpSchemas,
  mutationSchemas,
  ResourceShortlistAssociationConfig,
  ResourceShortlistAssociationRequestOf,
  ShortlistBlock_normalized
} from '../resource__shortlist.schemas';

export const createUseResourceShortlistAssociationEffectsApi =
  (
    client: ApiClient,
    useEnsureList: () => (
      query: AssociationQuery
    ) => Promise<
      ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
    >
  ) =>
  () => {
    const ensureList = useEnsureList();
    const api = {
      create: async (
        {
          shortlist_id,
          resource_id,
          payload
        }: ResourceShortlistAssociationRequestOf<'create'>,
        signal: AbortSignal
      ) => {
        const response = await client.post(
          `/shortlists/${shortlist_id}/associations/shortlist_blocks`,
          {
            data: {
              contact_id: resource_id,
              ...payload
            },
            token: true,
            signal
          }
        );
        // todo maybe do dual params, and if one has no additional params, try batching them, otherwise use single http
        // todo check if we can create batch here!
        return parseWithDecoratedError(
          mutationSchemas.create.response,
          response
        );
      },
      createBatch: async (
        {
          shortlist_id,
          resource_ids
        }: ResourceShortlistAssociationConfig['createBatch']['request'],
        signal: AbortSignal
      ) => {
        return parseWithDecoratedError(
          mutationSchemas.createBatch.response,
          await client.put<Shortlist>(
            `/shortlists/${shortlist_id}/contacts/add`,
            {
              data: { ids: resource_ids },
              token: true,
              signal
            }
          )
        );
      },
      delete: async (
        {
          shortlist_id,
          association_id
        }: ResourceShortlistAssociationConfig['delete']['request'],
        signal: AbortSignal
      ) => {
        // todo delete batch here!
        return parseWithDecoratedError(
          mutationSchemas.delete.response,
          await client.del(
            `/shortlists/${shortlist_id}/associations/shortlist_blocks/${association_id}`,
            {
              token: true,
              signal
            }
          )
        );
      },
      update: async (
        {
          shortlist_id,
          association_id,
          payload
        }: ResourceShortlistAssociationConfig['update']['request'],
        signal: AbortSignal
      ) => {
        return parseWithDecoratedError(
          mutationSchemas.update.response,
          await client.put(
            `/shortlists/${shortlist_id}/associations/shortlist_blocks/${association_id}`,
            { data: payload, token: true, signal }
          )
        );
      },
      updateBatch: async (
        { shortlist_id, payload }: BatchUpdateHttpRequest,
        signal: AbortSignal
      ) => {
        const response = await client.post(
          `/shortlists/${shortlist_id}/associations/shortlist_blocks/batch_update`,
          {
            data: { blocks: payload },
            token: true,
            signal
          }
        );
        return parseWithDecoratedError(
          batchUpdateHttpSchemas.response,
          response
        );
      },
      upvote: async (
        {
          association_id,
          shortlist_public_id,
          guest
        }: ResourceShortlistAssociationConfig['upvote']['request'],
        signal: AbortSignal
      ) => {
        return parseWithDecoratedError(
          mutationSchemas.upvote.response,
          await client.post(
            `/public/shortlists/${shortlist_public_id}/associations/upvotes`,
            {
              data: { shortlist_block_id: association_id, guest },
              token: !guest,
              signal
            }
          )
        );
      },
      downvote: async (
        {
          association_id,
          shortlist_public_id,
          guest
        }: ResourceShortlistAssociationConfig['downvote']['request'],
        signal: AbortSignal
      ) => {
        return parseWithDecoratedError(
          mutationSchemas.downvote.response,
          await client.post(
            `/public/shortlists/${shortlist_public_id}/associations/downvotes`,
            {
              data: { shortlist_block_id: association_id, guest },
              token: !guest,
              signal
            }
          )
        );
      },
      comment: async (
        {
          association_id,
          shortlist_public_id,
          guest,
          comment
        }: ResourceShortlistAssociationConfig['comment']['request'],
        signal: AbortSignal
      ) => {
        return parseWithDecoratedError(
          mutationSchemas.comment.response,
          await client.post(
            `/public/shortlists/${shortlist_public_id}/associations/comments`,
            {
              data: {
                shortlist_block_id: association_id,
                guest,
                value: comment
              },
              token: !guest,
              signal
            }
          )
        );
      },
      deleteBatch: async (
        {
          shortlist_id,
          association_ids
        }: ResourceShortlistAssociationConfig['deleteBatch']['request'],
        signal: AbortSignal
      ) => {
        return PromisePool.for(association_ids)
          .withConcurrency(10)
          .process(associationId =>
            api.delete(
              {
                shortlist_id: shortlist_id,
                association_id: associationId
              },
              signal
            )
          );
      },
      duplicate: async (
        {
          association_id,
          tmpworkaround_existing_shortlist_id,
          new_shortlist_id,
          new_resource_id
        }: ResourceShortlistAssociationConfig['duplicate']['request'][number],
        signal: AbortSignal
      ) => {
        const listResponse = await ensureList({
          shortlist_id: tmpworkaround_existing_shortlist_id // lol, if we ask for resource_id, the associations endpoint doesn't return pins at all...
          // we really need separate block query endpoint
        });

        const block = listResponse.results.find(
          item => item.id === association_id
        );
        if (!block) {
          throw new Error('Source block not found');
        }
        const blockCopy = {
          ...omit(block, [
            'id',
            'shortlist_id',
            'contact_id',
            'upvotes',
            'downvotes',
            'comments',
            'pins',
            'order'
          ]),
          pins: duplicateShortlistBlockPins(block.pins),
          contact_id: new_resource_id
        };

        const response = await client.post(
          `/shortlists/${new_shortlist_id}/associations/shortlist_blocks`,
          {
            data: blockCopy,
            token: true,
            signal
          }
        );
        return parseWithDecoratedError(
          mutationSchemas.duplicate.response.element,
          response
        );
      },
      duplicateBatch: async (
        blocks: ResourceShortlistAssociationConfig['duplicate']['request'],
        signal: AbortSignal
      ) => {
        return (
          await PromisePool.for(blocks)
            .withConcurrency(10)
            .process(block => api.duplicate(block, signal))
        ).results;
      }
    };
    return api;
  };

export type UseResourceShortlistAssociationEffectsApi = ReturnType<
  typeof createUseResourceShortlistAssociationEffectsApi
>;

export type ResourceShortlistAssociationEffectsApi =
  ReturnType<UseResourceShortlistAssociationEffectsApi>;
