import { STORAGE_PROVIDER_ACTIONS } from 'store/v1/storage_providers/storage_providers.constants.js';
import { uniq } from 'lodash';

const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  fetching: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STORAGE_PROVIDER_ACTIONS.GET_STORAGE_PROVIDERS:
      return {
        ...state,
        fetching: true
      };
    case STORAGE_PROVIDER_ACTIONS.GET_STORAGE_PROVIDERS_SUCCESS:
      return {
        ...state,
        data: action.result.storage_providers.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.storage_providers.map(item => item.id),
        paging: action.result.paging,
        fetching: false
      };
    case STORAGE_PROVIDER_ACTIONS.GET_STORAGE_PROVIDERS_FAILURE:
      return {
        ...state,
        fetching: false
      };
    case STORAGE_PROVIDER_ACTIONS.CREATE_STORAGE_PROVIDER:
    case STORAGE_PROVIDER_ACTIONS.DISABLE_STORAGE_PROVIDER:
      return {
        ...state,
        updating: true
      };
    case STORAGE_PROVIDER_ACTIONS.CREATE_STORAGE_PROVIDER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.storage_provider.id]: action.result.storage_provider
        },
        order: uniq([...state.order, action.result.storage_provider.id]),
        updating: false
      };
    case STORAGE_PROVIDER_ACTIONS.DISABLE_STORAGE_PROVIDER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.storage_provider.id]: action.result.storage_provider
        },
        updating: false
      };
    case STORAGE_PROVIDER_ACTIONS.CREATE_STORAGE_PROVIDER_FAILURE:
      return {
        ...state,
        updating: false
      };
    case STORAGE_PROVIDER_ACTIONS.DISABLE_STORAGE_PROVIDER_FAILURE:
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
}
