import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './StarRating.scss';

class StarRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || 0
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.value === undefined || nextProps.value === null) return;
  //   if (this.state.value !== nextProps.value) {
  //     this.setState({ value: nextProps.value });
  //   }
  // }

  onClick = value =>
    this.props.onStarClick &&
    this.setState({ value }, () => this.props.onStarClick(value));

  render() {
    return (
      <div
        className={classnames({
          'StarRating-star': true,
          [this.props.className]: this.props.className
        })}
      >
        {[1, 2, 3, 4, 5].map(v => (
          <i
            key={v}
            className={classnames({
              'StarRating-star': true,
              'StarRating-star-full': v <= this.state.value
            })}
            onClick={() => this.onClick(v)}
          >
            &#9733;
          </i>
        ))}
      </div>
    );
  }
}

StarRating.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onStarClick: PropTypes.func.isRequired
};

export default StarRating;
