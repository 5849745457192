import React from 'react';
import PropTypes from 'prop-types';

const Icon = props => {
  //let icon;
  const type = typeof props.icon;
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    path: {
      fill: props.color
    }
  };
  switch (type) {
    case 'string':
    default:
      return (
        <svg
          style={styles.svg}
          width={`${props.width || props.size}px`}
          height={`${props.height || props.size}px`}
          viewBox={`0 0 ${props.size} ${props.size}`}
          className={props.className}
        >
          <path style={styles.path} d={props.icon}></path>
        </svg>
      );
    //break;
    case 'function':
      return props.icon(props.size, props.size);
    //break;
  }
};

Icon.propTypes = {
  icon: PropTypes.any.isRequired,
  size: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
};

Icon.defaultProps = {
  size: 16
};

export default Icon;
