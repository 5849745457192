import { createAsyncThunk } from '@reduxjs/toolkit';
import { Paging, SearchQuery } from '__types__';
import { Tag } from '__types__/v1';
import { TAGS_ACTIONS } from './tags.constants';
import ApiClient from 'lib/api/ApiClient';

const token = true;
// TODO: TS: any
const client = new ApiClient() as any;

const getTags = createAsyncThunk(
  TAGS_ACTIONS.GET_TAGS,
  async (query: SearchQuery = {}) =>
    (await client.get('/tags', {
      params: { page: query.page },
      token
    })) as {
      results: Tag[];
      paging: Paging;
    }
);

const createTag = createAsyncThunk(
  TAGS_ACTIONS.CREATE_TAG,
  async (data: Partial<Tag>) =>
    (await client.post('/tags', { data, token })) as Tag
);

const updateTag = createAsyncThunk(
  TAGS_ACTIONS.UPDATE_TAG,
  async ({ id, data }: { id: number; data: Tag }) =>
    (await client.put(`/tags/${id}`, { data, token })) as Tag
);

const deleteTag = createAsyncThunk(
  TAGS_ACTIONS.DELETE_TAG,
  async (id: number) =>
    (await client.del(`/tags/${id}`, { token })) as { success: boolean }
);

export { getTags, createTag, updateTag, deleteTag };
