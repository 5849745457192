export const useNewComponents =
  window.localStorage?.getItem('useNewComponents') === 'true';

/**
 * We keep this flag for a while to make sure we don't have any unused code.
 * This allows us to release faster and not worry about removing unused code.
 * Later in time, we can remove this flag and remove all unused code.
 */
export const useNewComponents_released =
  window.localStorage?.getItem('useOldComponents') !== 'true';
// window.localStorage?.getItem('useNewComponents') === 'true';
