import React from 'react';

import size from 'lodash/size';

import './RequestAvailabilitySending.scss';

const RequestAvailabilitySending = ({ request, sendCount }) => {
  return (
    <div className="RequestAvailabilitySending">
      <div className="text-center">
        <h2>Sending</h2>
        <img
          src="/images/atellio_loading.gif"
          width="70px"
          alt="loading"
          className="stack-M"
        />
        <h3>{`${sendCount}/${size(request.resourceIDs)}`} Sent</h3>
      </div>
    </div>
  );
};

export default RequestAvailabilitySending;
