import { Pin } from '__types__';
import classnames from 'classnames';
import { IMAGE_QUALITY_OPTIONS } from 'lib/media/imageHelper';
import isEmpty from 'lodash/isEmpty';
import React, { FC } from 'react';
import PinItem from 'v1/components/shared/byModel/Pin/PinItem/PinItem';
import MasonrySortable, {
  MasonrySortableItem
} from 'v1/components/shared/layout/MasonrySortable/MasonrySortable';
import EmptyGeneric from 'v1/components/shared/placeholders/EmptyGeneric/EmptyGeneric';

interface PinnedListContainerProps {
  pins: Array<Pin>;
  companyUsers?: Array<{ id?: string }>;
  options?: {
    masonryLayout: string;
  };
  onDeletePin: (pinId: number) => void;
  deleting: boolean;
  onExpand: (pinId: number) => void;
  onSetAsProfilePicture: (pin: Pin) => void;
  handleUpdateOrder: (oldIndex: number, newIndex: number) => void; // todo move to activeId, overId
}

const PinnedListContainer: FC<PinnedListContainerProps> = ({
  companyUsers,
  deleting,
  handleUpdateOrder,
  onDeletePin,
  onExpand,
  onSetAsProfilePicture,
  options = {},
  pins
}) => {
  if (!isEmpty(pins)) {
    return (
      <div className="PinnedListContainer">
        <MasonrySortable
          items={pins.map(p => p.id)}
          className={classnames([
            options.masonryLayout,
            'PinnedListContainer-masonry'
          ])}
          onMove={(active, over) => {
            handleUpdateOrder(
              pins.findIndex(p => p.id === active),
              pins.findIndex(p => p.id === over)
            );
          }}
        >
          {pins.map((pin, index) => {
            const user = companyUsers?.find(u => u.id === pin.user_id);
            return (
              <MasonrySortableItem key={pin.id} id={pin.id}>
                <PinItem
                  index={index}
                  pin={pin}
                  onDeletePin={() => onDeletePin(pin.id)}
                  deleting={deleting}
                  onExpand={() => onExpand(pin.id)}
                  onSetAsProfilePicture={onSetAsProfilePicture}
                  user={user}
                  settings={{
                    video: { showPreview: true, disableControls: true },
                    image: {
                      ...IMAGE_QUALITY_OPTIONS.large
                    }
                  }}
                  labelOptions={{
                    visible: true,
                    includeProfileImageButton: true
                  }}
                />
              </MasonrySortableItem>
            );
          })}
        </MasonrySortable>
      </div>
    );
  }
  return (
    <EmptyGeneric
      icon="/images/icon_colour_pins.svg"
      iconWidth="50px"
      title="No Pins"
      description="Pins allow you to highlight examples of work and important information about the person"
    />
  );
};

export default PinnedListContainer;
