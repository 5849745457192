export enum FeatureFlagEnum {
  CALENDAR = 'calendar',
  RESOURCES = 'resources',
  PRODUCTIONS = 'productions',
  SHORTLISTS = 'shortlists',
  CALLOUTS = 'callouts',
  CALLSHEETS = 'callsheets',
  BOOKINGS = 'bookings',
  BUDGETING = 'budgeting',
  FINANCIALS = 'financials',
  REPORTING = 'reporting',
  RECONCILIATIONS = 'reconciliations',
  NIKECUSTOM_FRONT_DESK = 'custom-nikefrontdesk',
  NIKECUSTOM_ARRIVAL_BOARD = 'custom-nikearrivalboard',
  NIKECUSTOM_QR_CODE = 'custom-nikeqrcode',
  BOOKING_CONFIRMATION = 'booking_confirmation',
  TASKS = 'tasks',
  CUSTOM_WALMART = 'custom-walmart',
  CUSTOM_SPLASHLIGHT = 'custom-splashlight',
  CUSTOM_SHOTFLOW = 'custom-shotflow'
}
