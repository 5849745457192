import { apiClient } from 'v4/core/apiClient';
import { myShortlists } from '../../shortlist/shortlist.hooks.connected';
import { createUseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { createUseResourceShortlistAssociationQueryApi } from './http/resource__shortlist.http.query';
import { createResourceShortlistAssociationHooks } from './resource__shortlist.hooks';
import { createListQueryHooks } from './resource__shortlist.query.list';

const useAssociationQueryApi = createUseResourceShortlistAssociationQueryApi(
  apiClient,
  myShortlists.useEnsureItem
);
export const myResourceShortlistAssociations =
  createResourceShortlistAssociationHooks(
    useAssociationQueryApi,
    createUseResourceShortlistAssociationEffectsApi(
      apiClient,
      createListQueryHooks(useAssociationQueryApi).useEnsureList
    )
  );
