import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AuthService from 'lib/auth/AuthService';
import { selectAccountById } from 'store/accounts';
import { loadAuth } from 'store/v1/auth/auth.actions.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { getTeamAccounts } from 'store/accounts';
import { getAccountTeams } from 'store/teams';
import { getTeamUserGroups } from 'store/userGroups';

import OnboardingViewBase from '../OnboardingViewBase';
import RegistrationSplash from './registration_splash.svg?react';
import { Button, Heading, Text } from 'shared';

import styles from './CompletedRegistration.module.scss';
import { getIntegrations } from 'store/integrations';

const CompletedRegistration = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = AuthService.token();
    dispatch(loadAuth(token, token.tokenString, ''));
    setIsLoading(isLoading => true);
  }, []);

  useEvent([AUTH_ACTIONS.LOAD_AUTH], {
    onSuccess: () => {
      setIsLoading(isLoading => false);
      dispatch(getAccountTeams());
      dispatch(getTeamAccounts());
      dispatch(getTeamUserGroups());
      dispatch(getIntegrations());
    }
  });

  const token = AuthService.token();
  const id = token.account.id;
  const account = useSelector(state => selectAccountById(state, id));

  const headingCopy = account
    ? `${account.firstName}, you're all set up!`
    : "You're all set up!";

  return (
    <OnboardingViewBase primary="yellow" secondary="blue">
      <div className={styles['CompletedRegistration__contentContainer']}>
        <div class={styles['CompletedRegistration__instructions']}>
          <Heading type="h1" stack="m" family="serif">
            {headingCopy}
          </Heading>
          <Text color="black" stack="m" size="l">
            We’re so glad to have you onboard. We’ll now show you round your new
            account and guide you on some useful ways to get started using
            Atellio.
          </Text>
          <Button
            label="Let's go"
            appearance="primary"
            action="/"
            isLoading={isLoading}
          ></Button>
        </div>
        <div className={styles['CompletedRegistration__svgContainer']}>
          <RegistrationSplash />
        </div>
      </div>
    </OnboardingViewBase>
  );
};

export default CompletedRegistration;
