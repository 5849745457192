import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getProductionStatus } from 'store/v1/productions/productions.selectors';
import { Status } from 'v1/components/shared';

import './ProductionStatus.scss';

const ProductionStatus = ({ production, appearance }) => {
  const status = useSelector(state => getProductionStatus(state, production));

  return <Status statusId={status.id} appearance={appearance} />;
};

ProductionStatus.propTypes = {
  production: PropTypes.object,
  appearance: PropTypes.oneOf(['compact', 'expanded'])
};

export default ProductionStatus;
