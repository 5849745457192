import React from 'react';

export const GLYPHS = {
  SEARCH:
    'M15.707,13.293 L13,10.586 C13.63,9.536 14,8.311 14,7 C14,3.14 10.859,0 7,0 C3.141,0 0,3.14 0,7 C0,10.86 3.141,14 7,14 C8.312,14 9.536,13.631 10.586,13 L13.293,15.707 C13.488,15.902 13.744,16 14,16 C14.256,16 14.512,15.902 14.707,15.707 L15.707,14.707 C16.098,14.316 16.098,13.684 15.707,13.293 Z M7,12 C4.239,12 2,9.761 2,7 C2,4.239 4.239,2 7,2 C9.761,2 12,4.239 12,7 C12,9.761 9.761,12 7,12 Z',
  PRODUCTION:
    'M13,0 L3,0 C1.3,0 0,1.3 0,3 L0,13 C0,14.7 1.3,16 3,16 L13,16 C14.7,16 16,14.7 16,13 L16,3 C16,1.3 14.7,0 13,0 Z M5,5 L3,5 L3,3 L5,3 L5,5 Z M9.5,12 C7.6,12 6,10.4 6,8.5 C6,6.6 7.6,5 9.5,5 C11.4,5 13,6.6 13,8.5 C13,10.4 11.4,12 9.5,12 Z',
  RESOURCES:
    'M10 0H1C0.4 0 0 0.4 0 1V15C0 15.6 0.4 16 1 16H10C11.7 16 13 14.7 13 13V3C13 1.3 11.7 0 10 0ZM9 8H4V4H9V8Z',
  SHORTLISTS:
    'M3.57143 5.73254H1C0.447715 5.73254 0 6.18026 0 6.73254V9.30397C0 9.85626 0.447715 10.304 1 10.304H3.57143C4.12371 10.304 4.57143 9.85626 4.57143 9.30397V6.73254C4.57143 6.18026 4.12371 5.73254 3.57143 5.73254ZM3.57143 11.4286H1C0.447715 11.4286 0 11.8763 0 12.4286V15C0 15.5523 0.447715 16 1 16H3.57143C4.12371 16 4.57143 15.5523 4.57143 15V12.4286C4.57143 11.8763 4.12371 11.4286 3.57143 11.4286ZM9.28572 0H6.71429C6.16201 0 5.71429 0.447715 5.71429 1V3.57143C5.71429 4.12371 6.16201 4.57143 6.71429 4.57143H9.28572C9.83801 4.57143 10.2857 4.12371 10.2857 3.57143V1C10.2857 0.447715 9.83801 0 9.28572 0ZM3.57143 0H1C0.447715 0 0 0.447715 0 1V3.57143C0 4.12371 0.447715 4.57143 1 4.57143H3.57143C4.12371 4.57143 4.57143 4.12371 4.57143 3.57143V1C4.57143 0.447715 4.12371 0 3.57143 0ZM15 0H12.4286C11.8763 0 11.4286 0.447715 11.4286 1V3.57143C11.4286 4.12371 11.8763 4.57143 12.4286 4.57143H15C15.5523 4.57143 16 4.12371 16 3.57143V1C16 0.447715 15.5523 0 15 0ZM9.28572 5.73254H6.71429C6.16201 5.73254 5.71429 6.18026 5.71429 6.73254V9.30397C5.71429 9.85626 6.16201 10.304 6.71429 10.304H9.28572C9.83801 10.304 10.2857 9.85626 10.2857 9.30397V6.73254C10.2857 6.18026 9.83801 5.73254 9.28572 5.73254ZM13.8571 6.85712H13.5714C13.0191 6.85712 12.5714 7.30483 12.5714 7.85712V8.14283C12.5714 8.69512 13.0191 9.14283 13.5714 9.14283H13.8571C14.4094 9.14283 14.8571 8.69512 14.8571 8.14283V7.85712C14.8571 7.30483 14.4094 6.85712 13.8571 6.85712ZM13.8571 12.5714H13.5714C13.0191 12.5714 12.5714 13.0191 12.5714 13.5714V13.8571C12.5714 14.4094 13.0191 14.8571 13.5714 14.8571H13.8571C14.4094 14.8571 14.8571 14.4094 14.8571 13.8571V13.5714C14.8571 13.0191 14.4094 12.5714 13.8571 12.5714ZM8.14283 12.5714H7.85712C7.30483 12.5714 6.85712 13.0191 6.85712 13.5714V13.8571C6.85712 14.4094 7.30483 14.8571 7.85712 14.8571H8.14283C8.69512 14.8571 9.14283 14.4094 9.14283 13.8571V13.5714C9.14283 13.0191 8.69512 12.5714 8.14283 12.5714Z',
  CALLOUTS:
    'M14.64375,0.11175 C14.4228446,-0.0246973244 14.1470799,-0.0371804999 13.91475,0.07875 L8.073,3 L3.75,3 C1.67893219,3 2.53632657e-16,4.67893219 0,6.75 C-2.53632657e-16,8.82106781 1.67893219,10.5 3.75,10.5 L8.073,10.5 L13.91475,13.425 C14.1478941,13.5415074 14.4248073,13.5286221 14.6461248,13.3909678 C14.8674423,13.2533135 15.0014266,13.0106302 15,12.75 L15,0.75 C14.9999734,0.489864744 14.8651517,0.248321009 14.64375,0.11175 ZM7.6065,12 L4.35975,12 L6.11025,16.308 C6.44005016,17.0429011 7.29140937,17.3861161 8.0386943,17.0854286 C8.78597922,16.7847411 9.16236093,15.947516 8.89125,15.189 L7.6065,12 Z',
  CALLSHEETS:
    'M13,0 L1,0 C0.4,0 0,0.396666667 0,0.991666667 L0,14.875 C0,15.47 0.4,15.8666667 1,15.8666667 L13,15.8666667 C13.6,15.8666667 14,15.47 14,14.875 L14,0.991666667 C14,0.396666667 13.6,0 13,0 Z M6.53333333,13.0666667 L2.8,13.0666667 L2.8,11.2 L6.53333333,11.2 L6.53333333,13.0666667 Z M11.2,8.4 L2.8,8.4 L2.8,6.53333333 L11.2,6.53333333 L11.2,8.4 Z M11.2,4.66666667 L2.8,4.66666667 L2.8,2.8 L11.2,2.8 L11.2,4.66666667 Z',
  CHECKED_IN:
    'M6 12.414L10.414 8L6 3.586L4.586 5L6.586 7H0V9H6.586L4.586 11L6 12.414Z M4 2L13 2L13 14L4 14L4 16L14 16C14.552 16 15 15.552 15 15L15 1C15 0.448 14.552 -3.91654e-08 14 -8.74228e-08L4 -9.61651e-07L4 2Z',
  CHEVRON_DOWN:
    'M12.2533 5.89908C11.9012 6.26158 8.1826 9.80327 8.1826 9.80327C7.99355 9.99753 7.74553 10.0947 7.49924 10.0947C7.25122 10.0947 7.0032 9.99753 6.81588 9.80327C6.81588 9.80327 3.09727 6.26158 2.74345 5.89908C2.38963 5.53659 2.36535 4.88444 2.74345 4.49767C3.11982 4.11089 3.64709 4.08141 4.11018 4.49767L7.49924 7.74798L10.8883 4.49767C11.3514 4.08141 11.8769 4.11089 12.2533 4.49767C12.6314 4.88444 12.6071 5.53659 12.2533 5.89908Z',
  CHEVRON_UP:
    'M12.2533 8.38664C11.9012 8.02414 8.18262 4.48245 8.18262 4.48245C7.99357 4.28819 7.74554 4.19107 7.49926 4.19107C7.25123 4.19107 7.00321 4.28819 6.81589 4.48245C6.81589 4.48245 3.09729 8.02414 2.74347 8.38664C2.38964 8.74913 2.36536 9.40128 2.74347 9.78805C3.11984 10.1748 3.6471 10.2043 4.11019 9.78805L7.49926 6.53774L10.8883 9.78805C11.3514 10.2043 11.8769 10.1748 12.2533 9.78805C12.6314 9.40128 12.6071 8.74913 12.2533 8.38664Z',
  ARROW:
    'M3.5,6 C3.84994951,6 4.13363964,5.73129548 4.13363964,5.39983166 L4.13363964,1.04704979 L4.95420298,1.82126695 C5.20560602,2.02519022 5.58036198,2.01147966 5.81440885,1.78979605 C6.04845572,1.56811244 6.06293092,1.21315254 5.84763487,0.975029588 L3.94671595,-0.825475431 C3.69957275,-1.05817486 3.30042725,-1.05817486 3.05328405,-0.825475431 L1.15236513,0.975029588 C0.937069082,1.21315254 0.95154428,1.56811244 1.18559115,1.78979605 C1.41963802,2.01147966 1.79439398,2.02519022 2.04579702,1.82126695 L2.86636036,1.04704979 L2.86636036,5.39983166 C2.86636036,5.73129548 3.15005049,6 3.5,6 Z',
  HELP_BOOK:
    'M12.1875 0H6.5625V5.625L4.6875 4.6875L2.8125 5.625V0C1.21875 0 0 1.21875 0 2.8125V12.1875C0 13.7813 1.21875 15 2.8125 15H12.1875C13.7813 15 15 13.7813 15 12.1875V8.4375V2.8125C15 1.21875 13.7813 0 12.1875 0ZM13.125 12.1875C13.125 12.75 12.75 13.125 12.1875 13.125H2.8125C2.25 13.125 1.875 12.75 1.875 12.1875C1.875 11.625 2.25 11.25 2.8125 11.25H12.1875C12.75 11.25 13.0312 11.1563 13.125 11.0625V12.1875Z',
  TRASH:
    'M1.875 5.625V13.125C1.875 14.1562 2.71875 15 3.75 15H11.25C12.2812 15 13.125 14.1562 13.125 13.125V5.625H1.875Z M11.25 2.8125V0.9375C11.25 0.375 10.875 0 10.3125 0H4.6875C4.125 0 3.75 0.375 3.75 0.9375V2.8125H0V4.6875H15V2.8125H11.25ZM9.375 2.8125H5.625V1.875H9.375V2.8125Z',
  TICK: 'M4.55 7.1L1.95 4.5L0 6.45L4.55 11L13 2.55L11.05 0.6L4.55 7.1Z'
};

export const COLORED = {
  PRODUCTION: (width, height) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
      >
        <defs>
          <path
            id="a"
            d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#4BD9D8" xlinkHref="#a" />
          <g fillRule="nonzero" mask="url(#b)">
            <path
              fill="#FFF"
              d="M12.26 8.565L10.697 7H6.783A.782.782 0 006 7.783v13.304c0 .864.7 1.565 1.565 1.565h14.87c.864 0 1.565-.7 1.565-1.565V10.13c0-.864-.7-1.565-1.565-1.565H12.26z"
            />
            <path
              fill="#35B1BF"
              d="M24 10.913H6v-3.13C6 7.35 6.35 7 6.783 7h3.913l1.565 1.565h10.174c.864 0 1.565.701 1.565 1.565v.783zM17.18 16.07l-3.912-2.738a.39.39 0 00-.616.32v5.478a.39.39 0 00.616.32l3.913-2.738a.39.39 0 000-.641z"
            />
          </g>
        </g>
      </svg>
    );
  },
  SHORTLISTS: (width, height) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
      >
        <defs />
        <defs>
          <circle id="a" cx="15" cy="15" r="15" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#29C86F" xlinkHref="#a" />
          <g fillRule="nonzero" mask="url(#b)">
            <path
              fill="#FFF"
              d="M23.767 31.702H6.987A.987.987 0 016 30.715V9.987C6 9.442 6.442 9 6.987 9h11.845l5.922 5.922v15.793a.987.987 0 01-.987.987z"
            />
            <path
              fill="#EBF3F3"
              d="M18.832 9v4.935c0 .546.441.987.987.987h4.935L18.832 9z"
            />
            <path
              fill="#FFCB03"
              d="M11.896 20.351h-2.96a.493.493 0 01-.494-.493v-2.962c0-.272.22-.493.493-.493h2.961c.273 0 .494.22.494.493v2.962c0 .273-.22.493-.494.493z"
            />
            <path
              fill="#EDF3EB"
              d="M21.195 20.351h-6.712c-.619 0-1.119-.22-1.119-.493v-2.962c0-.272.5-.493 1.119-.493h6.712c.619 0 1.12.22 1.12.493v2.962c0 .273-.501.493-1.12.493z"
            />
            <path
              fill="#4BD9D8"
              d="M11.896 26.274h-2.96a.493.493 0 01-.494-.494v-2.961c0-.273.22-.494.493-.494h2.961c.273 0 .494.22.494.494v2.961c0 .273-.22.494-.494.494z"
            />
            <path
              fill="#EDF3EB"
              d="M21.195 26.274h-6.712c-.619 0-1.119-.221-1.119-.494v-2.961c0-.273.5-.494 1.119-.494h6.712c.619 0 1.12.22 1.12.494v2.961c0 .273-.501.494-1.12.494z"
            />
          </g>
        </g>
      </svg>
    );
  }
};
