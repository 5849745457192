import { EXPENSE_TYPES_ACTIONS } from 'store/v1/expense_types/expense_types.constants.js';
import { pick } from 'lodash';

const token = true;

// UNUSED
// export const getExpenseTypes = (event_id = null) => {
//   return {
//     types: [
//       EXPENSE_TYPES_ACTIONS.GET_EXPENSE_TYPES,
//       EXPENSE_TYPES_ACTIONS.GET_EXPENSE_TYPES_SUCCESS,
//       EXPENSE_TYPES_ACTIONS.GET_EXPENSE_TYPES_FAILURE
//     ],
//     promise: client => client.get('/expense_types', { token }),
//     event_id
//   };
// };

export const createExpenseType = (data, event_id = null) => {
  return {
    types: [
      EXPENSE_TYPES_ACTIONS.CREATE_EXPENSE_TYPE,
      EXPENSE_TYPES_ACTIONS.CREATE_EXPENSE_TYPE_SUCCESS,
      EXPENSE_TYPES_ACTIONS.CREATE_EXPENSE_TYPE_FAILURE
    ],
    promise: client => client.post('/expense_types', { data, token }),
    event_id
  };
};

export const updateExpenseType = (id, data, event_id = null) => {
  const payload = pick(data, ['id', 'name', 'order', 'metastructure']);
  return {
    types: [
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE,
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE_SUCCESS,
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE_FAILURE
    ],
    promise: client =>
      client.put(`/expense_types/${id}`, {
        data: payload,
        token
      }),
    event_id
  };
};

export const updateExpenseTypeOrder = (data, event_id = null) => {
  const payload =
    data &&
    data.map(ExpenseType => ({
      id: ExpenseType.id,
      order: ExpenseType.order
    }));
  return {
    types: [
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE_ORDER,
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE_ORDER_SUCCESS,
      EXPENSE_TYPES_ACTIONS.UPDATE_EXPENSE_TYPE_ORDER_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/expense_types`, {
        data: { reorder: payload },
        token
      }),
    event_id
  };
};

export const deleteExpenseType = (id, event_id = null) => {
  return {
    types: [
      EXPENSE_TYPES_ACTIONS.DELETE_EXPENSE_TYPE,
      EXPENSE_TYPES_ACTIONS.DELETE_EXPENSE_TYPE_SUCCESS,
      EXPENSE_TYPES_ACTIONS.DELETE_EXPENSE_TYPE_FAILURE
    ],
    promise: client => client.del(`/expense_types/${id}`, { token }),
    event_id,
    id
  };
};
