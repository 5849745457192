import { useHistory } from 'react-router-dom';
import { myResourceShortlistAssociations } from 'v4/entities/associations/resource__shortlist/resource__shortlist.hooks.connected';
import { useAppRoutes } from 'v4/entities/common/hooks/useAppRoutes';
import { myShortlists } from 'v4/entities/shortlist/shortlist.hooks.connected';
import { useDerivedMutation } from '../../common/single-mutation';
import { createShortlistBatchAddModal } from './ShortlistBatchAddModal';

export const ShortlistBatchAddModal = createShortlistBatchAddModal({
  useItems: myShortlists.useList,
  useMutation: () =>
    useDerivedMutation(
      myResourceShortlistAssociations.useMutation(),
      payload => ({
        type: 'createBatch' as const,
        request: {
          shortlist_id: payload.shortlistId,
          resource_ids: payload.resourceIds
        }
      })
    ),
  useNavigateToShortlist: () => {
    const { push } = useHistory();
    const appLinks = useAppRoutes();
    return id => push(appLinks.shortlists.detail(id));
  }
});
