import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountsList } from '../../../store/accounts';
import { parseInitials } from '../../../v1/helpers/misc';
import { createUserPicker, UserPickerConfig } from './UserPicker';

export const ownerPickerConfig: UserPickerConfig = {
  useUsers: query => {
    const allUsers = useSelector(selectAccountsList);
    const matchingUsers = useMemo(
      () =>
        allUsers
          .filter(u => !u.dateArchived)
          .filter(u =>
            u.displayName.toLowerCase().includes(query.toLowerCase())
          )
          .map(u => ({
            id: u.id,
            profileImage: u.profileImage,
            name: u.displayName,
            initials: parseInitials(
              u.firstName,
              u.lastName,
              u.displayName
            ).slice(0, 2)
          })),
      [allUsers, query]
    );
    return matchingUsers;
  },
  usePlaceholder: () => 'Change owner to…'
};
export const ConnectedOwnerPicker = createUserPicker(ownerPickerConfig);
