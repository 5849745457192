import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  useTable,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy
} from 'react-table';
import { get, isEqual, isNil, merge, pick } from 'lodash';

import Env from 'lib/env/Env';
import { alphanumericSort } from 'v1/helpers/sortFunctions';

import TableContext from './TableContext';
import TableActions from './TableActions/TableActions';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TablePagination from './TableActions/TablePagination/TablePagination';
import { Checkbox } from 'v1/components/shared';

import styles from './Table.module.scss';

// SORTING
const SORT_TYPES = {
  alphanumeric: (row1, row2, columnName) => {
    const a = get(row1, ['values', columnName]);
    const b = get(row2, ['values', columnName]);
    return alphanumericSort(a, b);
  }
};

const propWarning = (prop, controlled = true) =>
  console.error(
    `Warning: the ${prop} prop should not be used with ${
      controlled ? 'a controlled' : 'an uncontrolled'
    } table`
  );

const Table = ({
  // Styling
  rounded = true,
  borders = false,
  flush,
  cellPadding,
  frozenColumns = 0,
  showTotal,
  showRowIndexes,
  footer,
  EmptyComponent,
  // Data
  controlled = false,
  loading,
  columns,
  data,
  resultCount,
  // Query
  query = {},
  onQueryChange,
  // PAGINATION
  rowsPerPage,
  pageCount: controlledPageCount,
  // FILTERING
  isSearchable,
  filterOptions,
  // ORDERING
  onOrderChange,
  onGroupOrderChange,
  // SELECTION
  showSelectAll,
  onSelectedChange,
  // ADDING
  onRowAdd,
  onGroupAdd,
  // GROUPING
  groupBy,
  groups,
  groupOptions,
  subRows,
  // EXPANSION
  showExpandAll,
  ExpanderComponent
}) => {
  ////////////////////////////////////////////////////////////

  /**
   * Prop warnings
   **/

  if (Env.nodeEnv !== 'production') {
    !controlled && resultCount && propWarning('resultCount', false);
    // PAGINATION
    controlled && !isNil(rowsPerPage) && propWarning('rowsPerPage');
    !controlled &&
      !isNil(controlledPageCount) &&
      propWarning('pageCount', false);
    // FILTERING
    controlled &&
      columns.find(c => c.isSearchable) &&
      propWarning('isSearchable');
    !controlled && !isNil(isSearchable) && propWarning('isSearchable', false);
    !controlled && !isNil(filterOptions) && propWarning('filterOptions', false);
    // SELECTION
    showSelectAll &&
      !onSelectedChange &&
      console.error(
        'Warning: the showSelectAll prop should not be used without onSelectedChange'
      );
    // EXPANSION
    showExpandAll &&
      !ExpanderComponent &&
      console.error(
        'Warning: the showExpandAll prop should not be used without an ExpanderComponent'
      );
    // GROUPING
    groupBy &&
      !groups &&
      console.error(
        'Warning: the groupBy prop should not be used without a groups array'
      );
    !groupBy &&
      groups &&
      console.error(
        'Warning: the groupBy prop should not be used without a groups array'
      );
  }

  ////////////////////////////////////////////////////////////

  /**
   * State
   **/

  const isLoading = loading;

  // SORTING
  const sortTypes = useMemo(() => SORT_TYPES, []);

  // PAGINATION
  const showPagination = controlled
    ? typeof controlledPageCount !== 'undefined'
    : !!rowsPerPage;

  // FILTERING
  const showSearch = controlled
    ? isSearchable
    : !!columns.find(column => column.isSearchable);
  const showFilters = controlled && filterOptions && filterOptions.length > 0;
  const [_query, _setQuery] = useState(query);

  ////////////////////////////////////////////////////////////

  /**
   * Data
   **/

  const tableData = useMemo(() => {
    if (isLoading) {
      return [...Array(10)].map((_, id) => ({ id }));
    }
    // GROUPING
    if (groupBy) {
      return [
        ...(groups || []).map(group => ({
          id: `_group-${group.id}`,
          [groupBy]: group.id,
          _type: 'GROUP',
          _name: group.name
        })),
        ...data
      ];
    }
    return data;
  }, [data, isLoading, groupBy, groups]);

  ////////////////////////////////////////////////////////////

  /**
   * Column Definitions
   **/

  const defaultColumn = React.useMemo(
    () => ({
      Header: ''
    }),
    []
  );

  // ORDERING
  const createOrderableColumn = () => ({
    id: 'handle',
    Header: () =>
      onGroupOrderChange ? (
        <div className={classnames(styles.Handle, 'TableHandle')}>
          <img src="/images/icon_drag.svg" alt="Drag row" />
        </div>
      ) : null,
    className: styles.OrderingCell,
    canResize: false,
    minWidth: 23,
    width: 23,
    index: frozenColumns ? -1 : null,
    Cell: () =>
      onOrderChange ? (
        <div className={classnames(styles.Handle, 'TableHandle')}>
          <img src="/images/icon_drag.svg" alt="Drag row" />
        </div>
      ) : null
  });

  // SELECTION
  const createSelectionColumn = () => ({
    id: 'selection',
    className: styles.SelectionCell,
    Header: showSelectAll
      ? ({
          getToggleAllPageRowsSelectedProps,
          getToggleAllRowsSelectedProps
        }) => {
          const config = {
            className: 'stack-0',
            disabled: isLoading ? true : undefined
          };
          const { indeterminate, ...props } =
            controlled || rowsPerPage
              ? getToggleAllPageRowsSelectedProps(config)
              : getToggleAllRowsSelectedProps(config);
          return <Checkbox {...props} />;
        }
      : '',
    canResize: false,
    minWidth: 25,
    width: 25,
    index: frozenColumns ? -1 : null,
    Cell: ({ row }) => {
      const { indeterminate, ...props } = row.getToggleRowSelectedProps({
        className: classnames([styles.SelectionCheckbox, 'stack-0']),
        disabled: isLoading ? true : undefined
      });
      return <Checkbox {...props} />;
    }
  });

  // EXPANSION
  const createExpanderColumn = () => ({
    id: 'expander',
    className: styles.ExpansionCell,
    Header: showExpandAll
      ? ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <div
            {...getToggleAllRowsExpandedProps({
              ...(isLoading ? { onClick: undefined } : {})
            })}
          >
            <img
              className={classnames(styles.ExpanderIcon, {
                'rotate-90': isAllRowsExpanded
              })}
              src="images/icon_chevron_double.svg"
              alt="Expand all rows"
            />
          </div>
        )
      : '',
    canResize: false,
    minWidth: 27,
    width: 27,
    index: frozenColumns ? -1 : null,
    Cell: ({ row }) => (
      <div
        {...row.getToggleRowExpandedProps({
          ...(isLoading ? { onClick: undefined } : {})
        })}
      >
        <img
          className={classnames(styles.ExpanderIcon, {
            'rotate-90': row.isExpanded
          })}
          src="images/icon_chevron.svg"
          alt="Expand row"
        />
      </div>
    )
  });

  // INDEXATION
  const createIndexColumn = () => ({
    id: '_index',
    Cell: ({ row, onlyGroupedRowsById }) => {
      const groupId = row.original.groupId;
      let id;
      if (onlyGroupedRowsById && groupId) {
        const group = onlyGroupedRowsById[`_groupBy:_${groupId}`];
        const groupIndex = group.index + 1;
        const rowIndex = group.subRows.findIndex(
          r => r.values.slotId === row.original.slot.id
        );
        id = `${groupIndex}.${rowIndex}`;
      } else {
        id = row.index + 1;
      }
      return (
        <div className={classnames('text-11-700-eggplant', styles.IndexCell)}>
          {id}
        </div>
      );
    },
    canResize: false,
    minWidth: 38,
    width: 38,
    index: frozenColumns ? -1 : null
  });

  // GROUPING
  const createGroupingColumn = useCallback(
    () => ({
      id: '_groupBy',
      accessor: values => `_${values[groupBy]}`,
      canResize: false,
      width: 0
    }),
    [groupBy]
  );

  // LOADING
  const getRandomWidth = () => Math.floor(Math.random() * (200 - 40) + 40);

  const getColumns = useCallback(() => {
    return columns.map((column, i) => {
      let { isSearchable: searchable, ...c } = column;
      c.index = i;
      if (!isSearchable && !searchable) {
        c.disableGlobalFilter = true;
      }
      if (isLoading) {
        c.Cell = ({ column }) =>
          column.width < 40 && !column.canResize ? null : (
            <div
              className={styles.LoadingBar}
              style={{ width: `${getRandomWidth()}px` }}
            >
              <div className={styles.LoadingBar_inner}></div>
            </div>
          );
      }
      return c;
    });
  }, [columns, isSearchable, isLoading]);

  const tableColumns = useMemo(
    () => (groupBy ? [createGroupingColumn(), ...getColumns()] : getColumns()),
    [groupBy, createGroupingColumn, getColumns]
  );

  ////////////////////////////////////////////////////////////

  /**
   * Feature Configs
   **/

  // PAGINATION
  const getInitialPageIndex = useMemo(() => () => (query.p ? query.p - 1 : 0), [
    query
  ]);
  // prettier-ignore
  const paginationConfig = useMemo(
    () =>
      !showPagination
        ? {}
        : controlled
          ? {
              initialState: { pageIndex: getInitialPageIndex() },
              manualPagination: true,
              pageCount: controlledPageCount
            }
          : {
              initialState: {
                pageIndex: getInitialPageIndex(),
                pageSize: isLoading ? 10 : rowsPerPage
              }
            },
    [isLoading, controlled, controlledPageCount, rowsPerPage, showPagination, getInitialPageIndex]
  );

  // SORTING
  const sortingConfig = useMemo(
    () =>
      controlled
        ? {
            manualSortBy: true,
            disableMultiSort: true
          }
        : {
            disableMultiSort: true
          },
    [controlled]
  );

  // FILTERING
  // prettier-ignore
  const filterConfig = useMemo(
    () =>
      !showSearch && !showFilters
        ? {}
        : controlled
          ? {
              initialState: { globalFilter: query.query },
              manualGlobalFilter: true
            }
          : {
              initialState: { globalFilter: query.query }
            },
    [controlled, query, showSearch, showFilters]
  );

  // SELECTION
  const selectionConfig = useMemo(() => ({ autoResetSelectedRows: false }), []);

  // GROUPING
  const groupingConfig = useMemo(
    () =>
      groupBy
        ? {
            initialState: {
              groupBy: ['_groupBy'],
              hiddenColumns: ['_groupBy']
            },
            autoResetExpanded: false
          }
        : {},
    [groupBy]
  );

  // All features
  const featureConfigs = useMemo(
    () =>
      merge(
        // FILTERING
        filterConfig,
        // PAGINATION
        paginationConfig,
        // SORTING
        sortingConfig,
        // SELECTION
        selectionConfig,
        // GROUPING
        groupingConfig
      ),
    [
      filterConfig,
      paginationConfig,
      sortingConfig,
      selectionConfig,
      groupingConfig
    ]
  );

  ////////////////////////////////////////////////////////////

  /**
   * Build Table
   **/

  const getRowId = useMemo(() => row => row.id || null, []);

  const table = useTable(
    {
      columns: tableColumns,
      data: tableData,
      defaultColumn,
      getRowId,
      ...featureConfigs,
      // SORTING
      sortTypes
    },
    // Layout
    useFlexLayout,
    // FILTERING
    ...(showSearch || showFilters ? [useGlobalFilter] : []),
    // GROUPING
    ...(groupBy ? [useGroupBy] : []),
    // SORTING
    useSortBy,
    // EXPANSION & GROUPING
    ...(ExpanderComponent || groupBy ? [useExpanded] : []),
    // PAGINATION
    ...(showPagination ? [usePagination] : []),
    // SELECTION
    ...(onSelectedChange ? [useRowSelect] : []),
    // Columns
    hooks => {
      hooks.visibleColumns.push(columns => [
        // ORDERING
        ...(onOrderChange || onGroupOrderChange
          ? [createOrderableColumn()]
          : []),
        // EXPANSION
        ...(ExpanderComponent ? [createExpanderColumn()] : []),
        // SELECTION
        ...(onSelectedChange ? [createSelectionColumn()] : []),
        // INDEXATION
        ...(showRowIndexes ? [createIndexColumn()] : []),
        // Original
        ...columns
      ]);
    }
  );

  ////////////////////////////////////////////////////////////

  /**
   * Table Properties & State
   **/

  const {
    getTableProps,
    headerGroups,
    visibleColumns,
    rows,
    prepareRow,
    state: {
      // FILTERING
      globalFilter,
      // PAGINATION
      pageIndex,
      // SELECTION
      selectedRowIds,
      // SORTING
      sortBy
    },
    // GROUPING
    toggleAllRowsExpanded,
    // PAGINATION
    page,
    // FILTERING
    setGlobalFilter
  } = table;

  // PAGINATION
  const paging = {
    pageIndex,
    ...pick(table, [
      'pageOptions',
      'canPreviousPage',
      'previousPage',
      'canNextPage',
      'nextPage'
    ])
  };

  ////////////////////////////////////////////////////////////

  /**
   * Effects
   **/

  useEffect(() => {
    onQueryChange && !isEqual(_query, query) && onQueryChange(_query);
  }, [_query, query, onQueryChange]);

  // PAGINATION
  useEffect(() => {
    // We need to add 1 because our API pagination starts at index 1, not 0
    _setQuery(prev => ({ ...prev, p: pageIndex + 1 }));
  }, [pageIndex]);

  // SORTING;
  useEffect(() => {
    _setQuery(prev => {
      if (sortBy[0]) {
        return {
          ...prev,
          order_by: {
            field: sortBy[0].id,
            direction: sortBy[0].desc ? 'desc' : 'asc'
          }
        };
      }
      const { order_by, ...previous } = prev;
      return previous;
    });
  }, [sortBy]);

  // FILTERING
  useEffect(() => {
    _setQuery(prev => ({
      ...prev,
      query: globalFilter
    }));
  }, [globalFilter]);

  // SELECTION
  useEffect(() => {
    onSelectedChange && onSelectedChange(Object.keys(selectedRowIds));
  }, [selectedRowIds, onSelectedChange]);

  // GROUPING
  useEffect(() => {
    groupBy && toggleAllRowsExpanded && toggleAllRowsExpanded(true);
  }, [groupBy, toggleAllRowsExpanded]);

  ////////////////////////////////////////////////////////////

  return (
    <TableContext.Provider
      value={{
        // Table
        getTableProps,
        prepareRow,
        headerGroups,
        visibleColumns,
        // Data
        isLoading,
        resultCount: controlled ? resultCount : rows.length,
        // Styling
        cellPadding,
        frozenColumns,
        showTotal,
        // PAGINATION
        showPagination,
        paging,
        // FILTERING
        showSearch,
        _query,
        _setQuery,
        setGlobalFilter,
        showFilters,
        filterOptions,
        // EXPANSION
        ExpanderComponent,
        // GROUPING
        groupBy,
        groupOptions,
        subRows,
        // ADDING
        onRowAdd,
        onGroupAdd,
        // ORDERING
        onOrderChange,
        onGroupOrderChange
      }}
    >
      <div>
        {!isLoading &&
          (showPagination || showSearch || showFilters || showTotal) && (
            <TableActions />
          )}
        <div
          className={classnames([
            styles.Table,
            {
              [styles.Table_bordered]: borders,
              [styles.Table_square]: !rounded,
              [styles.Table_flush]: flush,
              [styles.Table_empty]: !rows.length,
              'stack-S': showPagination
            },
            'Table-custom'
          ])}
        >
          {(!groupBy || !groups.length) && <TableHeader />}
          {!rows.length && EmptyComponent ? (
            <EmptyComponent />
          ) : (
            <TableBody rows={showPagination ? page : rows} />
          )}
          {footer && <TableFooter>{footer}</TableFooter>}
        </div>
        {!isLoading && showPagination && (
          <div className={styles.Post}>
            <TablePagination />
          </div>
        )}
      </div>
    </TableContext.Provider>
  );
};

Table.propTypes = {
  // Styling
  rounded: PropTypes.bool,
  borders: PropTypes.bool,
  flush: PropTypes.bool,
  cellPadding: PropTypes.oneOfType([
    PropTypes.oneOf([0, '0', 'S', 'M', 'L']),
    PropTypes.func
  ]),
  frozenColumns: PropTypes.number,
  showTotal: PropTypes.bool,
  showRowIndexes: PropTypes.bool,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  EmptyComponent: PropTypes.func,
  // Data
  controlled: PropTypes.bool,
  loading: PropTypes.bool,
  columns: PropTypes.array.isRequired, // Must be memoised
  data: PropTypes.array.isRequired, // Must be memoised
  resultCount: PropTypes.number, // Controlled only
  // Query
  query: PropTypes.shape({
    query: PropTypes.string,
    filters: PropTypes.object,
    order_by: PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(['asc', 'desc'])
    }),
    p: PropTypes.number
  }), // Standard query object
  onQueryChange: PropTypes.func, // Must be memoised
  // PAGINATION
  rowsPerPage: PropTypes.number, // Uncontrolled only
  pageCount: PropTypes.number, // Controlled only
  // GROUPING
  groupBy: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      order: PropTypes.number
    })
  ), // Must be memoised
  groupOptions: PropTypes.arrayOf(PropTypes.object), // Must be memoised
  // FILTERING
  isSearchable: PropTypes.bool, // Controlled only
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      type: PropTypes.string,
      condition: PropTypes.oneOf([
        'contains',
        'e_with',
        'eq',
        'gte',
        'lte',
        'match',
        's_with'
      ]),
      field: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
      ])
    })
  ), // Controlled only
  // ORDERING
  onOrderChange: PropTypes.func, // Must be memoised
  onGroupOrderChange: PropTypes.func, // Must be memoised
  // SELECTION
  showSelectAll: PropTypes.bool,
  onSelectedChange: PropTypes.func, // Must be memoised
  // ADDING
  onRowAdd: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object)
  ]), // Must be memoised
  onGroupAdd: PropTypes.func, // Must be memoised
  // EXPANSION
  showExpandAll: PropTypes.bool,
  ExpanderComponent: PropTypes.func // A component definition
};

export default Table;
