import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ShortlistContactItemGrid from './ShortlistContactItemGrid/ShortlistContactItemGrid';
import ShortlistFeedbackView from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/ShortlistFeedbackView/ShortlistFeedbackView';
import { SHORTLIST_RESOURCE_MAX_COUNT } from 'v1/containers/Routes/Shortlist/Shortlist';
import ShortlistPresentContactItem from './ShortlistPresentContactItem/ShortlistPresentContactItem';
import ShortlistToolbar from './ShortlistToolbar/ShortlistToolbar';
import ShortlistOrderList from './ShortlistOrderList';
import Account from 'lib/auth/Account';
import {
  EmptyGeneric,
  ListVirtualized,
  MasonrySortable,
  MasonryVirtualized,
  TextAreaInput,
  TextInput
} from 'v1/components/shared';

import { openModal } from 'store/v1/ui/ui.actions.js';
import { searchShortlistBlocks } from 'store/v1/shortlists/shortlists.actions.js';

import { selectActiveAccount } from 'store/accounts';

import classnames from 'classnames';
import { filter, get, isEmpty, omit, orderBy } from 'lodash';
import './ShortlistPresentView.scss';
import ShortlistContactItemCompact from './ShortlistContactItemCompact/ShortlistContactItemCompact';
import { MasonrySortableItem } from 'v1/components/shared/layout/MasonrySortable/MasonrySortable';

// TODO: Begging to be turned into a functional component

export class ShortlistPresentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: props.shortlist.default_resources_query || {
        filters: props.resourceType
          ? {
              resource_type_id: {
                eq: props.resourceType.id
              }
            }
          : {}
      },
      selection: {},
      orderEnabled: false,
      orderedBlocks: []
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.updating && !nextProps.updating) {
      this.setState({ selection: {} });
    }
    if (
      !this.props.shortlist.default_resources_query &&
      this.props.resourceType &&
      this.props.resourceType.id !== !nextProps.resourceType.id
    ) {
      this.setState(prev => ({
        query: {
          ...prev.query,
          filters: {
            ...prev.query.filters,
            resource_type_id: this.props.resourceType
              ? {
                  eq: this.props.resourceType.id
                }
              : {}
          }
        }
      }));
    }
  };

  onSelect = block => {
    const { selection } = this.state;
    if (selection[block.id]) {
      this.setState({ selection: omit(selection, block.id) });
    } else {
      this.setState({ selection: { ...selection, [block.id]: block } });
    }
  };

  onQueryChange = query => {
    const newQuery = {
      ...query,
      count: SHORTLIST_RESOURCE_MAX_COUNT
    };
    this.setState({ query: newQuery });
    return this.props.searchShortlistBlocks(this.props.shortlist.id, newQuery);
  };

  onSelectAll = () => {
    const selectedBlocks = this.filterData();

    this.setState(prev => {
      const updatedSelection = prev.selectAll
        ? {}
        : selectedBlocks.reduce((result, block) => {
            if (!result[block.id]) {
              return { ...result, [block.id]: block };
            }
            return result;
          }, prev.selection);
      return {
        selection: updatedSelection,
        selectAll: !prev.selectAll
      };
    });
  };

  filterData = () => {
    const filteredBlocks = this.props.shortlists.blocks_order
      ? this.props.shortlists.blocks_order.reduce((acc, id) => {
          const block = this.props.shortlist.shortlist_blocks.find(
            b => b.contact_id === id
          );
          return block ? [...acc, block] : acc;
        }, [])
      : this.props.shortlist.shortlist_blocks;

    return orderBy(
      filter(filteredBlocks, b => !b.archived),
      ['order', 'asc']
    );
  };

  sortedData = () => {
    const activeBlocks = this.filterData();
    const querySort = get(this.state.query, ['order_by', 'field']);
    const useUserOrder =
      !querySort ||
      querySort === 'order' ||
      !this.props.shortlists.blocks_order;

    const filtered = this.props.shortlists.blocks_order
      ? this.props.shortlists.blocks_order.reduce((acc, id) => {
          const block = activeBlocks.find(b => b.contact_id === id);
          return block ? [...acc, block] : acc;
        }, [])
      : activeBlocks;

    if (querySort === 'upvotes') {
      return filtered.sort((a, b) => b.upvotes?.length - a.upvotes?.length);
    }
    if (useUserOrder) {
      return orderBy(filtered, ['order', 'asc']);
    }
    return filtered;
  };

  renderHeader = () => {
    const { onSubmit } = this.props;
    const { public_title, public_description, title } = this.props.shortlist;
    return (
      <div className="ShortlistPresentView-header">
        <TextInput
          appearance="silent"
          size="XL"
          align="center"
          placeholder="Shortlist title..."
          value={public_title || title}
          onBlur={value => {
            onSubmit({ public_title: value });
          }}
        />
        <TextAreaInput
          value={public_description}
          placeholder="Write a description about this shortlist"
          className="emulate-h3"
          onBlur={(field, value) => {
            onSubmit({ public_description: value });
          }}
          rows="2"
        />
      </div>
    );
  };

  openMediaModal = block => {
    this.props.openModal('ShortlistMediaModal', {
      shortlist: this.props.shortlist,
      block
    });
  };

  isResourceRestricted = block => {
    const resourceTypeUserId = get(block, 'contact.resource_type.user_id');
    const activeAccountId = get(this.props, 'activeAccount.id');
    const userGroupIds = get(this.props, 'activeAccount.userGroupIds', []);
    const restricted_groups = get(
      block,
      'contact.resource_type.restricted_groups',
      []
    );

    return (
      restricted_groups.some(id => userGroupIds.includes(id)) &&
      resourceTypeUserId !== activeAccountId
    );
  };

  openBlockModal = (shortlist, block, sortedBlocks) => {
    return () => {
      this.props.openModal('MediaFocusModal', {
        mediaType: 'SHORTLIST_BLOCKS',
        mediaSelected: block,
        mediaList: sortedBlocks,
        mediaSidebar: (selected, navigateBlock) => {
          return (
            <ShortlistFeedbackView
              block={selected}
              shortlist={shortlist}
              navigateBlock={navigateBlock}
              shortlist_field_mapper={this.props.shortlist_field_mapper}
            />
          );
        },
        emptyGalleryComponent: selected => {
          return (
            <div className="ShortlistEmptyGallery">
              <h5>Empty Gallery</h5>
            </div>
          );
        }
      });
    };
  };

  renderCompactItem = block => {
    const { selection } = this.state;
    const { shortlist, shortlist_field_mapper, onPreviewContact } = this.props;
    const isSelected = selection[block.id];

    return (
      <ShortlistContactItemCompact
        key={block.id}
        block={block}
        default_fields={shortlist.default_fields}
        shortlist_field_mapper={shortlist_field_mapper}
        onPreviewContact={onPreviewContact}
        onSelect={this.onSelect}
        blockSelected={isSelected}
        editable
        sidebarDisabled={this.isResourceRestricted(block)}
      />
    );
  };

  renderGridItem = (block, sortedBlocks) => {
    const { selection } = this.state;
    const {
      shortlist,
      onDataChange,
      shortlist_field_mapper,
      onPreviewContact
    } = this.props;
    const isSelected = selection[block.id] ? true : false;
    const { enable_voting, enable_commenting } = shortlist;

    return (
      <ShortlistContactItemGrid
        key={block.id}
        block={block}
        user={Account.account()}
        default_fields={shortlist.default_fields}
        shortlist_field_mapper={shortlist_field_mapper}
        onDataChange={onDataChange}
        openMediaModal={this.openMediaModal}
        onPreviewContact={onPreviewContact}
        onSelect={this.onSelect}
        blockSelected={isSelected}
        enableVoting={enable_voting}
        enableCommenting={enable_commenting}
        showFeedback={true}
        expandBlock={this.openBlockModal(shortlist, block, sortedBlocks)}
        shortlist={shortlist}
        editable
        sidebarDisabled={this.isResourceRestricted(block)}
      />
    );
  };

  renderPageItem = (block, sortedBlocks) => {
    const { selection } = this.state;
    const {
      shortlist,
      onDataChange,
      shortlist_field_mapper,
      onPreviewContact
    } = this.props;
    const isSelected = selection[block.id];
    const { enable_voting, enable_commenting } = shortlist;

    return (
      <ShortlistPresentContactItem
        key={block.id}
        block={block}
        user={Account.account()}
        default_fields={shortlist.default_fields}
        shortlist_field_mapper={shortlist_field_mapper}
        onDataChange={onDataChange}
        openMediaModal={this.openMediaModal}
        onPreviewContact={onPreviewContact}
        onSelect={this.onSelect}
        blockSelected={isSelected}
        enableVoting={enable_voting}
        enableCommenting={enable_commenting}
        showFeedback={true}
        expandBlock={this.openBlockModal(shortlist, block, sortedBlocks)}
        editable
        sidebarDisabled={this.isResourceRestricted(block)}
      />
    );
  };

  renderPresentableList = () => {
    const { shortlist } = this.props;
    const layout = shortlist.layout;
    const sortedBlocks = this.filterData();

    const querySort = get(this.state.query, ['order_by', 'field']);
    const useUserOrder = !querySort || querySort === 'order';

    if (!isEmpty(sortedBlocks)) {
      switch (layout) {
        case 'COMPACT':
          return (
            <MasonrySortable
              className="ShortlistPresentView-list ShortlistPresentView-masonry"
              disabled={!useUserOrder}
              onMove={(active, over) =>
                this.props.handleUpdateOrder(
                  sortedBlocks.findIndex(block => block.id === active),
                  sortedBlocks.findIndex(block => block.id === over)
                )
              }
              items={sortedBlocks.map(block => block.id)}
            >
              {sortedBlocks?.map(block => (
                <MasonrySortableItem key={block.id} id={block.id}>
                  {this.renderCompactItem(block)}
                </MasonrySortableItem>
              ))}
            </MasonrySortable>
          );
        case 'GRID':
          if (sortedBlocks.length < 30) {
            return (
              <>
                <MasonrySortable
                  className="ShortlistPresentView-list ShortlistPresentView-masonry"
                  disabled={!useUserOrder}
                  onMove={(active, over) =>
                    this.props.handleUpdateOrder(
                      sortedBlocks.findIndex(block => block.id === active),
                      sortedBlocks.findIndex(block => block.id === over)
                    )
                  }
                  items={sortedBlocks?.map(block => block.id)}
                >
                  {sortedBlocks?.map(block => (
                    <MasonrySortableItem key={block.id} id={block.id}>
                      {this.renderGridItem(block, sortedBlocks)}
                    </MasonrySortableItem>
                  ))}
                </MasonrySortable>
                <div className="ShortlistPresentView-orderingHint text-center text-13-600-eggplant-lightest">
                  Drag to order
                </div>
              </>
            );
          } else {
            return (
              <>
                <MasonryVirtualized
                  className="ShortlistPresentView-list ShortlistPresentView-masonry"
                  disabled={!useUserOrder}
                  scrollElement={this.shortlistPresentView}
                >
                  {sortedBlocks?.map(block =>
                    this.renderGridItem(block, sortedBlocks)
                  )}
                </MasonryVirtualized>
                <div className="ShortlistPresentView-orderingHint text-center text-13-600-eggplant-lightest">
                  To re-order this shortlist head to the "Order" view
                </div>
              </>
            );
          }
        default:
          return (
            <div className="ShortlistPresentView-list">
              <ListVirtualized scrollElement={this.shortlistPresentView}>
                {sortedBlocks?.map(block =>
                  this.renderPageItem(block, sortedBlocks)
                )}
              </ListVirtualized>
            </div>
          );
      }
    }

    return (
      <EmptyGeneric
        icon="/images/icon_colour_team.svg"
        title="No one shortlisted"
        description="Search and add relevant resources into your shortlist"
        action={{
          onClick: () => {
            this.props.openModal('AddFromBlackbookModal', {
              destination: 'SHORTLIST_SHORTLIST_BLOCKS',
              store: 'shortlists',
              dataPointers: [
                {
                  label: 'shortlist',
                  pointTo: ['shortlists', 'data', shortlist.id]
                }
              ]
            });
          },
          className: 'btn btn-primary',
          text: 'Add Resources'
        }}
      />
    );
  };

  renderOrderList = () => (
    <ShortlistOrderList
      sortedData={this.sortedData}
      filterData={this.filterData}
      orderedBlocks={this.state.orderedBlocks}
      setOrderedBlocks={orderedBlocks => this.setState({ orderedBlocks })}
      onSetQuery={this.onQueryChange}
      query={this.state.query}
      shortlistFieldMapper={this.props.shortlist_field_mapper}
      shortlist={this.props.shortlist}
    />
  );

  renderShortlistTools = () => {
    const { shortlist, resourceType, onSubmit, shortlist_field_mapper } =
      this.props;
    return (
      <div className={classnames(['ShortlistPresentView-shortlistTools'])}>
        <ShortlistToolbar
          showOrderButton
          shortlist={shortlist}
          resourceType={resourceType}
          query={this.state.query}
          onSetQuery={this.onQueryChange}
          selection={this.state.selection}
          shortlistFieldMapper={shortlist_field_mapper}
          onShortlistSubmit={onSubmit}
          onSelectAll={this.onSelectAll}
          hasSelectedAll={this.state.selectAll}
          onSaveNewUserOrder={() => {
            const { orderedBlocks } = this.state;
            this.props.handleUpdateOrder(null, null, orderedBlocks);
            this.setState({ orderEnabled: false, orderedBlocks: [] });
          }}
          orderEnabled={this.state.orderEnabled}
          setOrderEnabled={orderEnabled =>
            this.setState({ orderEnabled: orderEnabled, orderedBlocks: [] })
          }
        />
      </div>
    );
  };

  render() {
    const { shortlist } = this.props;
    const { layout } = shortlist;

    return (
      <div
        className={classnames([
          'ShortlistPresentView',
          this.state.orderEnabled ? 'GRID' : layout
        ])}
        ref={el => (this.shortlistPresentView = el)}
      >
        <div className={classnames(['ShortlistPresentView-scroll'])}>
          {this.renderHeader()}
          {this.renderShortlistTools()}
          {this.state.orderEnabled
            ? this.renderOrderList()
            : this.renderPresentableList()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeAccount: selectActiveAccount(state),
    shortlists: state.shortlists
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal, searchShortlistBlocks }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortlistPresentView);
