import ValidationErrorLevel from './ValidationErrorLevel';

export default class AssignmentValidationError extends Error {
  name = 'AssignmentValidationError';
  level;
  constructor(level: ValidationErrorLevel, message: string) {
    super(message);
    this.level = level;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
