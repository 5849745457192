import { createSelector } from 'reselect';
import { get, keys, orderBy } from 'lodash';

const getSlotCategories = () =>
  createSelector(
    state => state.slot_categories.data,
    (_, { archived = false } = { archived: false }) => archived,
    (_, { type = null } = { type: null }) => type,
    (data, archived, type) => {
      const categories = keys(data).reduce((result, id) => {
        if (!archived && data[id].archived) return result;
        if (type && type !== data[id].slot_category_type) return result;
        return result.concat(data[id]);
      }, []);
      return orderBy(categories, ['order', 'id'], ['asc', 'desc']);
    }
  );

export const makeGetSlotCategories = () =>
  createSelector([getSlotCategories()], slotCategories => slotCategories);

export const makeGetSlotName = createSelector(
  state => state.slot_categories.data,
  data => (slot, type = 'RESOURCE_SLOT') => {
    if (!slot) return null;
    const nameFromStore = get(data[slot.slot_category_id], 'name');
    const nameFromRelation = get(slot, 'slot_category.name');
    const nameFromLegacy = get(slot, 'name');
    return nameFromStore || nameFromRelation || nameFromLegacy || null;
  }
);
