import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Fieldset, Legend } from 'v1/components/shared';

const CheckboxGroup = ({
  className,
  id,
  name,
  legend,
  value: defaultValue,
  onChange,
  children,
  ...props
}) => {
  // To manage child Checkbox state we manage internally
  // here and pass back up to onChange(event => event.target.value)
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => setChecked(defaultValue), [defaultValue]);

  function handleChange(value, isChecked) {
    // Update internal checked state
    const updateChecked = isChecked
      ? [...checked, value]
      : checked.filter(x => x !== value);
    setChecked(updateChecked);
    // Pass dummy event back to onChange callback to mirror native event object schema.
    // This is how we correct its value to the array of inputs the group represents.
    const mockEvent = {
      target: {
        name,
        value: updateChecked
      }
    };
    // Alternative would be to try to augment SyntheticEvent, but this doesn't
    // feel like best practice and may impact perf etc.
    // const eventSetValue = set(event, "target.value", updateChecked);

    if (onChange) onChange(mockEvent);
  }

  return (
    <Fieldset
      id={id}
      className={classnames('CheckboxGroup', className)}
      {...props}
    >
      {legend && <Legend className="CheckboxGroup-legend">{legend}</Legend>}
      {children &&
        React.Children.map(children, child => {
          const { value } = child.props;
          const isChecked = checked.includes(value);
          return React.cloneElement(child, {
            name,
            onChange: () => handleChange(value, !isChecked),
            checked: isChecked
          });
        })}
    </Fieldset>
  );
};

CheckboxGroup.propTypes = {
  // Component props
  className: PropTypes.string,
  legend: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,

  // HTML attributes
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  // ...props
};

CheckboxGroup.defaultProps = {
  value: []
};

export default CheckboxGroup;
