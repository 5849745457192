import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { v4 } from 'uuid';

import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { EmptyGeneric } from 'v1/components/shared';
import FileItem from '../FileItem/FileItem';
import classNames from 'classnames';

// TODO: needs fixing (with event system) to handle deleting multiple documents before the first one has returned
const FilesList = ({
  entityId,
  contentName,
  contentType,
  documents,
  events,
  deleteDocument,
  displayAlert,
  hideDelete,
  layout
}) => {
  const [deleting, setDeleting] = useState([]);
  const [event, setEvent] = useState(null);
  const status = get(events, event);

  useEffect(() => {
    if (!status) return;
    if (status === EVENT_ACTIONS.SUCCESS) {
      displayAlert('success', 'Updated');
      setDeleting([]);
    } else if (status !== EVENT_ACTIONS.PROCESSING) {
      displayAlert('error', status.message);
    }
  }, [status, displayAlert]);

  const handleDelete = id => {
    const newEvent = v4();
    setEvent(newEvent);
    setDeleting([...deleting, id]);
    deleteDocument(id, entityId, newEvent);
  };

  return (
    <div className="Files-section">
      <div className="Files-section-files">
        {documents && documents.length ? (
          <div className={classNames(['Files-listContainer'], layout)}>
            {documents.map(
              (doc, index) =>
                !doc.archived && (
                  <FileItem
                    key={index}
                    file={{ ...doc.file, url: doc.url }}
                    deleting={deleting.includes(doc.id)}
                    onDelete={() => handleDelete(doc.id)}
                    hideDelete={hideDelete}
                    layout={layout}
                  />
                )
            )}
          </div>
        ) : (
          <EmptyGeneric
            icon="/images/icon_colour_files.svg"
            iconWidth="50px"
            title="No Documents"
            description={`Keep track of important documents and files for ${
              contentName || 'this ' + contentType
            }`}
          />
        )}
      </div>
    </div>
  );
};

FilesList.propTypes = {
  entityId: PropTypes.number,
  contentName: PropTypes.string,
  contentType: PropTypes.string,
  documents: PropTypes.array, // TODO: type - document
  events: PropTypes.object,
  deleteDocument: PropTypes.func,
  displayAlert: PropTypes.func,
  layout: PropTypes.oneOf(['EXPANDED', 'INLINE', 'LINK', 'COMPACT'])
};

export default FilesList;
