import React from 'react';
import { useSelector } from 'react-redux';
import { selectModalData } from 'store/v1/ui/ui.selectors';
import { getProductionByID } from 'store/v1/productions/productions.selectors';
import { ModalWrapper } from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import ProductionMoveModalContent from './ProductionMoveModalContent';
import './ProductionMoveModal.scss';

const ProductionMoveModal = ({ onRequestClose }) => {
  const modalData = useSelector(selectModalData);
  const production = useSelector(getProductionByID(modalData.productionId));

  const handleCloseModal = () => {
    onRequestClose();
    modalData.onCancel && modalData.onCancel();
  };

  return (
    production && (
      <ModalWrapper size="M">
        <ModalHeader title="Move production" />
        <ProductionMoveModalContent
          production={production}
          onClose={handleCloseModal}
        />
      </ModalWrapper>
    )
  );
};

export default ProductionMoveModal;
