import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Callsheet } from '../../../__types__';
import { CallsheetsApi } from './callsheets.http';
import {
  DetailConfirmPayload,
  DetailPublicPayload,
  Query,
  SendPayload,
  TeamMemberCreatePayload,
  UpdateRecipientsPayload
} from './callsheets.types';

export const callsheetKeys = {
  all: () => ['callsheets'] as const,
  lists: () => [...callsheetKeys.all(), 'list'] as const,
  list: (filters: unknown) => [...callsheetKeys.lists(), { filters }] as const,
  details: () => [...callsheetKeys.all(), 'detail'] as const,
  detail: (id: number | string) =>
    [...callsheetKeys.details(), String(id)] as const,
  detailPublic: (id: number, query: object) =>
    [...callsheetKeys.detail(id), 'public', query] as const
};

export const createCallsheetsHooks = (api: CallsheetsApi) => {
  const useList = (query: Query) =>
    useQuery({
      queryKey: callsheetKeys.list(query), // previously was query.filters but we want to use all query on the server
      queryFn: ({ signal }) => api.list(query, signal)
    });

  const useItem = (id: number | undefined) =>
    useQuery({
      enabled: id !== undefined,
      queryKey: callsheetKeys.detail(id!),
      queryFn: ({ signal }) => api.item(id!, signal)
    });
  const useItemPublic = (payload: DetailPublicPayload) =>
    useQuery({
      queryKey: callsheetKeys.detailPublic(payload.id, payload.query),
      queryFn: ({ signal }) => api.itemPublic(payload, signal),
      enabled: !!payload.id
    });

  const useCreateMutation = (
    onSuccess?: (payload: Callsheet) => void,
    onError?: (error: string) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: Callsheet) => api.create(payload),
      onSuccess: payload => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // populate the new callsheet in the cache
        client.setQueryData(callsheetKeys.detail(payload.id), payload);
        onSuccess?.(payload);
      },
      onError
    });
  };

  const useUpdateMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: Callsheet) => api.update(payload),
      onSuccess: payload => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // populate new version of a callsheet in the cache
        client.setQueryData(callsheetKeys.detail(payload.id), payload);
        onSuccess?.(payload);
      },
      onError
    });
  };

  const useSendMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: SendPayload) => api.send(payload),
      onSuccess: (response, payload) => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // invalidate a callsheet in the cache
        client.invalidateQueries({
          queryKey: callsheetKeys.detail(payload.id)
        });
        onSuccess?.(response);
      },
      onError
    });
  };

  const useDeleteMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: Callsheet['id']) => api.delete(payload),
      onSuccess: (result, payload) => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // invalidate a callsheet in the cache
        client.removeQueries({
          queryKey: callsheetKeys.detail(payload)
        });
        onSuccess?.(payload);
      },
      onError
    });
  };
  const useUnarchiveMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: Callsheet['id']) => api.unarchive(payload),
      onSuccess: (result, payload) => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // invalidate a callsheet in the cache
        client.invalidateQueries({ queryKey: callsheetKeys.detail(payload) });
        onSuccess?.(payload);
      },
      onError
    });
  };

  const useAddTeamMemberMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: TeamMemberCreatePayload) =>
        api.addTeamMember(payload),
      onSuccess: (result, payload) => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // invalidate a callsheet in the cache
        client.invalidateQueries({
          queryKey: callsheetKeys.detail(payload.id)
        });
        onSuccess?.(payload);
      },
      onError
    });
  };

  const useUpdateRecipientsMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: UpdateRecipientsPayload) =>
        api.updateRecipients(payload),
      onSuccess: (result, payload) => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // invalidate a callsheet in the cache
        client.invalidateQueries({
          queryKey: callsheetKeys.detail(payload.id)
        });
        onSuccess?.(payload);
      },
      onError
    });
  };

  const useConfirmMutation = (
    onSuccess?: (payload: unknown) => void,
    onError?: (error: unknown) => void
  ) => {
    const client = useQueryClient();
    return useMutation({
      mutationFn: (payload: DetailConfirmPayload) => api.confirm(payload),
      onSuccess: (result, payload) => {
        // invalidate all callsheet lists
        client.invalidateQueries({
          queryKey: callsheetKeys.lists()
        });
        // invalidate a callsheet in the cache
        client.invalidateQueries({
          queryKey: callsheetKeys.detail(payload.id)
        });
        onSuccess?.(payload);
      },
      onError
    });
  };

  return {
    useList,
    useItem,
    useItemPublic,
    useCreateMutation,
    useUpdateMutation,
    useSendMutation,
    useDeleteMutation,
    useUnarchiveMutation,
    useAddTeamMemberMutation,
    useUpdateRecipientsMutation,
    useConfirmMutation
  };
};
