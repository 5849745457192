import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';

import { updateResourceSlot } from 'store/v1/productions/productions.actions.js';
import { updateBooking } from 'store/v1/bookings/bookings.actions.js';
import { openModal } from 'store/v1/ui/ui.actions.js';
import { deleteExpense } from 'store/v1/expenses/expenses.actions.js';
import { makeGetExpensesByBookingId } from 'store/v1/expenses/expenses.selectors.js';

import {
  getResourceSlotRate,
  getResourceSlotFees,
  getResourceSlotTotalsFormatted
} from 'v1/helpers/byModel/ResourceSlotHelper';
import {
  getBookingRate,
  getBookingFees,
  getBookingTotalsFormatted
} from 'v1/helpers/byModel/BookingHelper';
import {
  getExpenseSlotRate,
  getExpenseSlotTotalsFormatted
} from 'v1/helpers/byModel/ProductionBudgetExpenseHelper';
import {
  getExpenseRate,
  getExpenseTotalsFormatted
} from 'v1/helpers/byModel/ExpenseHelper';

import BookingCostsSummaryFeesTable from './BookingCostsSummaryFeesTable';
import BookingCostsSummaryTable from './BookingCostsSummaryTable';
import { EmptyGeneric, Grid, GridCell } from 'v1/components/shared';
import { Table } from 'shared';

import styles from './BookingCostsSummary.module.scss';

// TODO: clean up a lotttt for v2
const BookingCostsSummary = ({
  slot,
  expenseId,
  bookingId,
  onAddBookingExpense
}) => {
  const dispatch = useDispatch();
  const productions = useSelector(state => state.productions.data);
  const production = get(productions, [slot.production_id]);
  const getExpensesByBookingId = useMemo(makeGetExpensesByBookingId, []);
  const expensesByBookingId = useSelector(state =>
    getExpensesByBookingId(state, {
      production_id: slot.production_id
    })
  );
  const bookings = useSelector(state => state.bookings.data);
  const booking = get(bookings, [bookingId]);
  const resources = useSelector(state => state.contacts.data);
  const resource = booking
    ? get(resources, [get(booking, 'contact_id')])
    : null;

  const isExpense = !!slot.expenses;

  const expense =
    (slot.expenses || []).find(
      expense => !expense.archived && expense.id === expenseId
    ) || {};

  const [activeTab, setActiveTab] = useState(
    (isExpense && !isEmpty(expense)) || (!isExpense && booking)
      ? 'ACTUAL'
      : 'ESTIMATE'
  );

  // ESTIMATES

  const estRate = isExpense
    ? getExpenseSlotRate(slot)
    : getResourceSlotRate(slot);
  const estFees = isExpense ? null : getResourceSlotFees(slot);
  const estTotals = isExpense
    ? getExpenseSlotTotalsFormatted(slot, production.budget_currency)
    : getResourceSlotTotalsFormatted(slot, production.budget_currency);
  const estTotal = get(estTotals, 'total');
  const estRatesData = useMemo(
    () => [
      {
        id: estRate.id,
        rate: estRate,
        totals: estTotals
      }
    ],
    [estRate, estTotals]
  );
  const estFeesData = useMemo(() => estFees, [estFees]);

  // BOOKING ACTUAL

  // Get assignment for this slot so we can apply the assignment dates rather than
  // the booking dates for this calculation
  const assignment = !isEmpty(slot.resource_slot_assignments)
    ? slot.resource_slot_assignments.find(a => a.contact_id === resource.id)
    : null;
  const bookingWithAssignmentEvents = assignment
    ? { ...booking, events: assignment.events }
    : booking;

  const bookingRate =
    getBookingRate(bookingWithAssignmentEvents, resource) || {};
  const bookingExpenses = bookingWithAssignmentEvents
    ? expensesByBookingId[bookingWithAssignmentEvents.id]
    : [];
  const bookingFees = getBookingFees(bookingWithAssignmentEvents);
  const bookingActualTotals = getBookingTotalsFormatted(
    bookingWithAssignmentEvents,
    bookingExpenses,
    production.budget_currency
  );
  const bookingActualTotal = get(bookingActualTotals, 'total');

  const bookingRatesData = useMemo(
    () => [
      {
        id: bookingRate.id,
        rate: bookingRate,
        totals: bookingActualTotals
      }
    ],
    [bookingRate, bookingActualTotals]
  );
  const bookingExpensesData = useMemo(
    () =>
      (bookingExpenses || []).map(expense => ({
        id: expense.id,
        rate: getExpenseRate(expense),
        totals: getExpenseTotalsFormatted(expense, production.budget_currency)
      })),
    [bookingExpenses, production.budget_currency]
  );
  const bookingFeesData = useMemo(() => bookingFees || [], [bookingFees]);

  // EXPENSE ACTUAL

  const expenseRate = getExpenseRate(expense);

  const expenseActualTotals = getExpenseTotalsFormatted(
    expense,
    production.budget_currency
  );
  const expenseActualTotal = get(expenseActualTotals, 'total');
  const expenseRatesData = useMemo(
    () => [{ rate: expenseRate, totals: expenseActualTotals }],
    [expenseRate, expenseActualTotals]
  );

  // FUNCTIONS

  const handleAddBookingExpense = () => {
    onAddBookingExpense && onAddBookingExpense();
    dispatch(
      openModal('ExpenseCreateModal', {
        create: true,
        booking,
        production
      })
    );
  };

  // TODO: confirmation
  const handleRemoveBookingExpense = expenseId => {
    dispatch(deleteExpense(expenseId));
  };

  const handleEstFeeChange = (key, value) => {
    dispatch(
      updateResourceSlot(
        {
          production_id: slot.production_id,
          resource_slot_id: slot.id
        },
        {
          [`estimate_${key}`]: value
        }
      )
    );
  };

  const handleBookingFeeChange = (key, value) => {
    dispatch(
      updateBooking(bookingId, {
        [key]: value
      })
    );
  };

  // TABS

  const getTabs = () => {
    const estimateTab = {
      value: 'ESTIMATE',
      label: `Estimated${estTotal ? ` (${estTotal})` : ''}`
    };

    return isExpense
      ? [
          estimateTab,
          {
            value: 'ACTUAL',
            label: `Expense${
              !isEmpty(expense) && expenseActualTotal
                ? ` (${expenseActualTotal})`
                : ''
            }`
          }
        ]
      : [
          estimateTab,
          {
            value: 'ACTUAL',
            label: `Booking${
              bookingId && bookingActualTotal ? ` (${bookingActualTotal})` : ''
            }`
          }
        ];
  };

  const renderExpenseEstimateTab = () => (
    <BookingCostsSummaryTable
      title="Rate"
      data={estRatesData}
      defaultCurrency={production.budget_currency}
      type="EXPENSE"
    />
  );

  const renderExpenseActualTab = () => {
    if (isEmpty(expense)) {
      return (
        <EmptyGeneric
          description="No expense added yet. Create an expense to edit the expense costs
        here..."
        />
      );
    }

    return (
      <BookingCostsSummaryTable
        title="Rate"
        data={expenseRatesData}
        defaultCurrency={production.budget_currency}
        type="EXPENSE"
      />
    );
  };

  const renderBookingEstimateTab = () => (
    <>
      <BookingCostsSummaryTable
        title="Rate"
        data={estRatesData}
        defaultCurrency={production.budget_currency}
      />
      <BookingCostsSummaryFeesTable
        data={estFeesData}
        onChange={handleEstFeeChange}
      />
    </>
  );

  const renderBookingActualTab = () => {
    if (!booking) {
      return (
        <EmptyGeneric
          description="No booking added yet. Create a booking to edit the booking costs
        here..."
        />
      );
    }

    return (
      <>
        <BookingCostsSummaryTable
          title="Rate"
          data={bookingRatesData}
          defaultCurrency={production.budget_currency}
        />
        <BookingCostsSummaryFeesTable
          data={bookingFeesData}
          onChange={handleBookingFeeChange}
        />
        <BookingCostsSummaryTable
          title="Expenses"
          data={bookingExpensesData}
          defaultCurrency={production.budget_currency}
          type="EXPENSE"
          editableMeta
          handleAdd={handleAddBookingExpense}
          handleRemove={handleRemoveBookingExpense}
        />
      </>
    );
  };

  return (
    <div>
      <div className={styles.Tabs}>
        <Grid cellPadding="S">
          {getTabs().map(tab => (
            <GridCell
              key={tab.value}
              width="auto"
              className={classnames('text-11-700 clickable', styles.Tab, {
                [styles.Tab_active]: tab.value === activeTab
              })}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
            </GridCell>
          ))}
        </Grid>
      </div>
      {activeTab === 'ESTIMATE'
        ? isExpense
          ? renderExpenseEstimateTab()
          : renderBookingEstimateTab()
        : isExpense
        ? renderExpenseActualTab()
        : renderBookingActualTab()}
      {/* TODO: this is a hack for the footer - review this flyover */}
      {(activeTab === 'ESTIMATE' ||
        (isExpense ? !isEmpty(expense) : booking)) && (
        <div className={styles.Footer}>
          <Table
            columns={[]}
            data={[]}
            rounded={false}
            flush
            footer={() => (
              <Grid spread>
                <GridCell>Total</GridCell>
                <GridCell className="text-13-700" align="right">
                  {activeTab === 'ESTIMATE'
                    ? estTotal
                    : isExpense
                    ? expenseActualTotal
                    : bookingActualTotal}
                </GridCell>
              </Grid>
            )}
          />
        </div>
      )}
    </div>
  );
};

BookingCostsSummary.propTypes = {
  bookingId: PropTypes.number,
  slot: PropTypes.object,
  onAddBookingExpense: PropTypes.func
};

export default BookingCostsSummary;
