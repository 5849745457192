import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './SingleCheckbox.scss';

class SingleCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: props.checked };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.checked !== nextProps.checked) {
      this.setState({ checked: nextProps.checked });
    }
  };
  onChange = e => {
    e.stopPropagation();
    if (this.props.disabled) return;
    this.setState(
      {
        checked: !this.state.checked
      },
      () => {
        this.props.onChange && this.props.onChange(this.state.checked);
      }
    );
  };
  render() {
    return (
      <span
        className={classnames(
          {
            checked: this.state.checked,
            disabled: this.props.disabled,
            [`SingleCheckbox-${this.props.color}`]: this.props.color
          },
          ['SingleCheckbox', this.props.className]
        )}
        id={this.props.id}
        role="checkbox"
        aria-checked={this.state.checked}
        onClick={this.onChange}
        data-tip={this.props.dataTip}
      >
        {this.state.checked ? (
          <img
            width="10px"
            src={
              this.props.color === 'default'
                ? '/images/icon_white_tick.svg'
                : '/images/icon_tick_small.svg'
            }
            alt=""
          />
        ) : null}
      </span>
    );
  }
}

SingleCheckbox.defaultProps = {
  disabled: false
};

SingleCheckbox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary']),
  checked: PropTypes.bool,
  id: PropTypes.string,
  dataTip: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func // TODO: arguments
};

export default SingleCheckbox;
