import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Sortable from 'react-sortablejs';
import { isEqual, isEmpty } from 'lodash';

import useSelection from 'v1/helpers/hooks/useSelection';
import { AutocompleteSelect } from 'v1/components/shared';

import './FieldListSelection.scss';

// TODO: this only handles hierarchical labels on selected items, not on defaultSelection items
// TODO: To get hierarchical labels on defaultSelection items, each item must contain the parentLabel inherently
const FieldListSelection = ({
  fields,
  defaultSelection,
  onChange,
  disabled
}) => {
  let { items, updateItems, addItem, removeItem } = useSelection(
    defaultSelection
  );

  useEffect(() => {
    !isEqual(defaultSelection, Object.values(items)) &&
      updateItems(defaultSelection);
  }, [defaultSelection]);

  useEffect(() => {
    if (onChange) onChange(Object.values(items));
  }, [items]); // onChange prop should be a dep here but
  // we can't wrap in useCallback from parent class component

  return (
    <>
      {!isEmpty(items) && (
        <Sortable
          className="FieldList"
          options={{
            handle: '.FieldList-item-drag',
            animation: 0,
            ghostClass: 'PlaceholderDrop'
          }}
          onChange={newOrder => {
            const orderedItems = newOrder.map((id, index) => ({
              ...items[id],
              order: index
            }));
            updateItems(orderedItems);
          }}
          disabled={disabled}
        >
          {Object.keys(items).map(key => {
            const item = items[key];
            return (
              <div key={key} data-id={key} className="FieldList-item">
                {!disabled && (
                  <span className="FieldList-item-drag">
                    <img src="/images/icon_drag.svg" alt="" />
                  </span>
                )}
                <span className="FieldList-item-title">
                  {item.parentLabel &&
                    item.parentLabel.map(item => (
                      <Fragment key={item.key || item}>
                        {item}
                        <img
                          className="FieldList-item-arrow"
                          src="/images/icon_arrow_right_gray.svg"
                          width="10px"
                          alt=""
                        />
                      </Fragment>
                    ))}
                  {item.name || item.key || String(item)}
                </span>
                <span className="FieldList-item-delete">
                  <button
                    type="button"
                    onClick={() => removeItem(key)}
                    aria-label="Remove field"
                    className="btn btn-link btn-square btn-small"
                  >
                    <img src="/images/icon_delete.svg" width="12px" alt="" />
                  </button>
                </span>
              </div>
            );
          })}
        </Sortable>
      )}
      <AutocompleteSelect
        data={fields}
        selectedItems={Object.values(items)}
        onSelect={item => addItem(item)}
      />
    </>
  );
};

FieldListSelection.propTypes = {
  fields: PropTypes.array,
  defaultSelection: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

FieldListSelection.defaultProps = {
  fields: [],
  defaultSelection: []
};

export default FieldListSelection;
