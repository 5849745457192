import React from 'react';
import { Dropdown } from 'v1/components/shared';
import _filter from 'lodash/filter';
import { useRestrictedAccess } from 'lib/restrictedAccess';

// This dropdown and dropdown item is a workaround until https://github.com/reach/reach-ui/issues/23 is fixed

/**
 * @deprecated use RestrictedDropdown (.connected version) instead
 */
export default ({ children, capabilitiesAtLeastOne, showLabel, ...rest }) => {
  const menuNotEmpty = useRestrictedAccess(null, null, capabilitiesAtLeastOne);

  if (!menuNotEmpty && !showLabel) {
    return null;
  }

  if (!Array.isArray(children)) {
    children = [children];
  }
  children = _filter(children, c => c !== false);

  const items = _filter(children, child => {
    return useRestrictedAccess(
      null,
      child.props && child.props.capabilities, // TODO
      null
    );
  });

  return (
    <Dropdown disabled={!menuNotEmpty && showLabel} {...rest}>
      {items}
    </Dropdown>
  );
};
