import React from 'react';

import { InputStylingProps } from 'shared/inputs/__types__/Inputs';
import { Inputster } from 'shared';

type RestrictedInputProps = InputStylingProps;

const RestrictedInput = ({ appearance, size }: RestrictedInputProps) => {
  // TODO: this is all placeholder styling for demo/prototype purposes
  // TODO: ICONS - this is waiting on v2 icons
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '12px',
          filter:
            'invert(62%) sepia(16%) saturate(615%) hue-rotate(196deg) brightness(90%) contrast(87%)'
        }}
      >
        <img src="images/icon_eye_hide.svg" alt="" />
      </div>
      <Inputster appearance={appearance} size={size} isDisabled />
    </div>
  );
};

export default RestrictedInput;
