//
// Helpers for the Rate & ContactRate objects
//

import { get, has } from 'lodash';

/**
 * @function isContactRate
 * @desc Checks if an inputted rate object is a ContactRate or (by default) a Rate
 *
 * @param {Object} rate - [Rate|ContactRate] - The rate object
 */
export const isContactRate = rate => {
  return has(rate, 'contact_id');
};

/**
 * @function getDataFromRate
 * @desc Returns the rate values (amount, interval etc) from the ContactRate or nested Rate
 *
 * @param {Object} contactRate - [ContactRate] - The ContactRate object
 */
export const getDataFromContactRate = contactRate => {
  const rate = get(contactRate, 'rate');
  return rate || contactRate;
};
