export const customStyles = {
  control: provided => ({
    ...provided,
    borderColor: '#d6e0e5',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#898eb5'
    }
  }),
  placeholder: provided => ({
    ...provided,
    color: '#898eb5'
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    boxShadow: '0 4px 11px 0 rgba(0, 0, 0, 0.2)',
    animation:
      'scale-in-center 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) both'
  }),
  menuList: provided => ({
    ...provided,
    padding: '5px'
  }),
  option: provided => ({
    ...provided,
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    background: '#fff',
    '&:hover': {
      background: '#f6f6f6'
    }
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#d6e0e5'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    ' svg': {
      color: '#8A8FB2'
    }
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#edf3f6'
  })
};
