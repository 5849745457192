import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import RecoverPasswordForm from 'v1/components/feature/AuthComponents/RecoverPasswordForm';
import RecoverPasswordCallbackForm from 'v1/components/feature/AuthComponents/RecoverPasswordCallbackForm';

const RecoverPassword = ({ location }) => {
  const queryParams = queryString.parse(location.search);
  return (
    <div className="Onboard FullSplitContainer">
      <div className="FullSplitContainer-section withForm">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              {queryParams.token ? (
                <RecoverPasswordCallbackForm
                  recoveryToken={queryParams.token}
                />
              ) : (
                <RecoverPasswordForm />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="FullSplitContainer-section backgroundImage darkTheme hidden-xs">
        <div className="FullSplitContainer-tease text-center">
          <img
            className="logomark"
            src="/images/atellio_brand_logomark.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

RecoverPassword.propTypes = {
  location: PropTypes.object
};

export default withRouter(RecoverPassword);
