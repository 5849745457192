import React, { Component } from 'react';
import classnames from 'classnames';
import { htmlStringParser } from 'v1/helpers/misc';
import './PinComponents.scss';

export default class PinProviderFile extends Component {
  renderExtension = file => {
    const extension = file.mime_type.split('.').pop();
    const type = file.type || '';
    return (
      <div className={classnames(['PinItem-graphic', extension])}>
        {type} {extension}
      </div>
    );
  };
  render() {
    const { file } = this.props.pin;
    return (
      <a
        href={htmlStringParser(file.url)}
        target="_blank"
        rel="noopener noreferrer"
        className="PinProviderFile"
      >
        <span data-tip="Open in new window" className="Pin-externalIcon">
          {' '}
          <img src="/images/icon_new_window.svg" width="15px" alt="" />
        </span>
        {file.mime_type && this.renderExtension(file)}
      </a>
    );
  }
}
