import {
  Avatar as ArkAvatar,
  type AvatarProps as ArkAvatarProps
} from '@ark-ui/react';
import classNames from 'classnames';

export interface AvatarProps extends ArkAvatarProps {
  src?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fallback?: string;
}

// These are the current sizings for the application which fall outide the default tailwind sizes hence they are fixed
const avatarSizes = {
  xs: 'tw-w-[12px] tw-h-[12px]',
  sm: 'tw-w-[15px] tw-h-[15px]',
  md: 'tw-w-[30px] tw-h-[30px]',
  lg: 'tw-w-[38px] tw-h-[38px]',
  xl: 'tw-w-[50px] tw-h-[50px]'
};

const Avatar = ({
  src,
  size = 'md',
  fallback,
  className,
  ...rest
}: AvatarProps) => {
  return (
    <ArkAvatar.Root
      className={classNames(
        'tw-rounded-full tw-overflow-hidden tw-relative !tw-block',
        avatarSizes[size],
        className
      )}
      {...rest}
    >
      <ArkAvatar.Fallback className="tw-absolute tw-w-full tw-h-full tw-flex tw-font-bold tw-items-center tw-justify-center tw-text-xs tw-uppercase tw-text-eggplant tw-grid-none">
        {fallback}
      </ArkAvatar.Fallback>
      <ArkAvatar.Image className="tw-absolute tw-w-full tw-h-full" src={src} alt="avatar" />
    </ArkAvatar.Root>
  );
};

export default Avatar;
