import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get, find, partition } from 'lodash';

import { FieldListSelection } from 'v1/components/shared';
import {
  PressStud,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert, openModal, closeModal } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';

const FieldAddModal = () => {
  // REDUX
  const ui = useSelector(state => state.ui);
  const { data: uiData = {} } = ui;
  const { core_structure, coreStructureType, updateAction, UPDATE_EVENT } =
    uiData;
  const coreStructureTypeId = get(coreStructureType, 'id');
  const coreStructureTypeMetastructure = get(
    coreStructureType,
    'metastructure'
  );

  const custom_field_definitions = useSelector(
    state => state.auth.settings.custom_field_definitions
  );
  const custom_fieldgroup_definitions = useSelector(
    state => state.auth.settings.custom_fieldgroup_definitions
  );

  const dispatch = useDispatch();

  // PROPS
  const fieldsAndFieldGroups = [
    ...custom_field_definitions,
    ...custom_fieldgroup_definitions
  ];

  const structure_types = useSelector(state => state[core_structure]);
  const { data = {}, loading, error } = structure_types || {};
  const structureTypeFields =
    coreStructureTypeId !== undefined
      ? get(data, `[${coreStructureTypeId}].metastructure.fields`, [])
      : [];
  const activeCustomFields = getActiveCustomFields(structureTypeFields);

  // STATE
  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent(UPDATE_EVENT, {
    onSuccess: () => dispatch(displayAlert('success', 'Updated succesfully')),
    onFailure: () => dispatch(displayAlert('error', error.message))
  });

  // METHODS
  function handleSubmit(values) {
    // This is mingin'
    // Revisit this whole component with fresh eyes...
    const { fieldSelection = [] } = values;
    const activeCustomFieldIds = activeCustomFields.map(field => field.id);
    const newFieldSelection = fieldSelection.filter(
      field => !activeCustomFieldIds.includes(field.id)
    );

    const existingFields = [
      ...structureTypeFields.map(field => {
        if (
          field.type === 'CORE_FIELD' ||
          fieldSelection.find(
            custom_field => custom_field.id === field.custom_field_definition_id
          ) ||
          fieldSelection.find(
            custom_field =>
              custom_field.id === field.custom_fieldgroup_definition_id
          )
        )
          return field;
      })
    ];
    const updatedFields = [
      ...existingFields,
      ...getCustomFieldsArrayFromSelection(newFieldSelection)
    ].filter(field => field);

    const payload = {
      ...coreStructureType,
      metastructure: {
        ...coreStructureTypeMetastructure,
        fields: updatedFields
      }
    };

    if (updateAction) dispatch(updateAction(coreStructureTypeId, payload));
  }

  // HELPERS
  function getCustomFieldById(id) {
    return find(custom_field_definitions, field => field.id === id);
  }
  function getCustomFieldGroupById(id) {
    return find(
      custom_fieldgroup_definitions,
      fieldgroup => fieldgroup.id === id
    );
  }

  function getActiveCustomFields(structureTypeFields) {
    const [activeFields, inactiveFields] = partition(
      structureTypeFields,
      f => f.active
    );
    const structureTypeFieldsSorted = [...activeFields, ...inactiveFields];
    return (
      structureTypeFieldsSorted
        // .filter(field => field.active) // Should we filter out inactives here?
        // Could lead to attempted adding of a field that's already present but inactive.
        .map(
          ({
            type,
            custom_field_definition_id,
            custom_fieldgroup_definition_id
          }) => {
            if (type === 'CUSTOM_FIELD')
              return getCustomFieldById(custom_field_definition_id);
            if (type === 'CUSTOM_FIELD_GROUP')
              return getCustomFieldGroupById(custom_fieldgroup_definition_id);
          }
        )
        .filter(field => field) || []
    );
  }

  function getCustomFieldsArrayFromSelection(selection = []) {
    // This sucks too...
    return selection.map(field => {
      const {
        id,
        custom_field_definitions,
        default: fieldDefault = '',
        active = true,
        locked = false,
        required = false
      } = field;
      // Map type-specific properties to each field being added
      const definition_properties = custom_field_definitions
        ? {
            custom_fieldgroup_definition_id: id,
            type: 'CUSTOM_FIELD_GROUP'
          }
        : {
            custom_field_definition_id: id,
            type: 'CUSTOM_FIELD'
          };

      return {
        ...definition_properties,
        default: fieldDefault, // Re-alias default as it's a reserved keyword
        active,
        locked,
        required
      };
    });
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title="Add field" />
      <ModalContent>
        <ModalScrollable>
          <h3 className="stack-M">
            Add a field using one of the methods below
          </h3>
          <PressStud
            label="Create new field"
            appearance="primary"
            action={() =>
              dispatch(
                openModal('FieldCreateModal', {
                  create: true,
                  core_structure,
                  core_structure_type_id: coreStructureTypeId,
                  updateAction
                })
              )
            }
          />
          <hr />
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              fieldSelection: activeCustomFields
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <div className="stack-M">
                  <Field field="fieldSelection">
                    {({ fieldName: name, value, setValue }) => (
                      <Fragment>
                        <Label htmlFor={name}>
                          Or add an existing Custom Field
                        </Label>
                        <FieldListSelection
                          fields={fieldsAndFieldGroups}
                          defaultSelection={value}
                          onChange={value => setValue(value)}
                          disabled={true}
                        />
                      </Fragment>
                    )}
                  </Field>
                </div>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label="Save Fields"
            appearance="primary"
            isLoading={loading}
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default FieldAddModal;
