import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-form';

import {
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud,
  TextAreaField
} from 'v1/components/shared';

import { displayAlert } from 'store/v1/ui/ui.actions.js';

import { sanitiseCallsheetDates } from 'v1/helpers/byModel/CallsheetHelper';

import { get } from 'lodash';
import { callsheets } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';

class CallsheetTeamNoteModal extends Component {
  constructor(props) {
    super(props);

    const callsheetID = get(props.ui, 'data.callsheetID');

    this.state = {
      teamMember: get(props.ui, 'data.teamMember'),
      callsheetID
    };
  }

  // This could potentially be a handy generic update helper
  // for use on deep-setting location/team_member/schedule data values?
  replaceTeamMemberNoteInCallsheet = (id, note, callsheet) => ({
    ...callsheet,
    team_members: callsheet.team_members.map(team_member => {
      if (team_member.id !== id) return team_member;
      return {
        ...team_member,
        note
      };
    })
  });

  handleSubmit = values => {
    const callsheet = this.props.callsheetQuery.data;
    const updatedCallsheet = this.replaceTeamMemberNoteInCallsheet(
      this.state.teamMember.id,
      values.note ?? '',
      callsheet
    );

    this.props.updateMutation.mutate(updatedCallsheet, {
      onSuccess: () => {
        this.props.displayAlert('success', 'Note added');
        this.props.onRequestClose();
      },
      onError: error => {
        this.props.displayAlert('error', error);
      }
    });
  };

  render() {
    const { onRequestClose } = this.props;
    const { teamMember } = this.state;

    return (
      <ModalWrapper size="S">
        <ModalContent>
          <ModalScrollable>
            <h3>Attach a personal note for {teamMember.display_name}</h3>
            <p>
              This note will be visible on their callsheet and on the email they
              will receive
            </p>
            <Form
              onSubmit={this.handleSubmit}
              defaultValues={{
                note: teamMember.note
              }}
              getApi={api => {
                this.formApi = api;
              }}
            >
              {formApi => (
                <form onSubmit={formApi.submitForm}>
                  <TextAreaField
                    field="note"
                    placeholder="Add a personal note to an individual recipient’s callsheet"
                    rows={3}
                  />
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Cancel"
              appearance="silent"
              action={onRequestClose}
            />
            <PressStud
              label="Save"
              appearance="primary"
              isLoading={this.props.callsheetQuery.isLoading}
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ events, ui }) {
  return { events, ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ displayAlert }, dispatch);
}

const withQueries = Component =>
  function Wrapper(props) {
    const callsheetQuery = callsheets.useItem(props.ui.data.callsheetID);

    const sanitizedCallsheet = useMemo(
      () => callsheetQuery.data && sanitiseCallsheetDates(callsheetQuery.data),
      [callsheetQuery.data]
    );

    const updateMutation = callsheets.useUpdateMutation();

    return (
      <Component
        {...props}
        callsheetQuery={callsheetQuery}
        sanitizedCallsheet={sanitizedCallsheet}
        updateMutation={updateMutation}
      />
    );
  };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withQueries(CallsheetTeamNoteModal))
);
