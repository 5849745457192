import { decodeJwt } from 'jose';

/**
 * //https://github.com/auth0/jwt-decode/issues/53#issuecomment-413069022
 * Throws an error is the token is either expired or is used before its not before date
 * @param {*} decodedToken a decoded json web token
 */
export const isTokenAlive = decodedToken => {
  if (decodedToken === null) return false;

  const now = Date.now() / 1000;

  if (typeof decodedToken.exp !== 'undefined' && decodedToken.exp < now) {
    return false;
  }

  if (typeof decodedToken.nbf !== 'undefined' && decodedToken.nbf < now) {
    return false;
  }

  return true;
};

// Takes the supplied token, base64 decodes it and reads it (also confusingly called decode in the jwt library)
export const decodeAndReadToken = token => {
  let decodedData, data;

  try {
    decodedData = atob(token);
  } catch (error) {
    console.log(error);
    return null;
  }

  try {
    data = decodeJwt(decodedData);
  } catch (error) {
    console.log(error);
    return null;
  }

  return { bearerToken: decodedData, token: data };
};
