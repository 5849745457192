import { combineReducers } from 'redux';

import v1 from './v1';

// ACCOUNT
import accounts from './accounts/accounts.slice';
import collections from './collections/collections.slice';
import session from './session/session.slice';
import teams from './teams/teams.slice';
import userGroups from './userGroups/userGroups.slice';
import integrations from './integrations/integrations.slice';

// RESOURCE PORTAL
import availabilities from './availabilities/availabilities.reducer.js';

// TASKS
import task_lists from './task_lists/task_lists.reducer.js';
import tasks from './tasks/tasks.reducer.js';

// ONBOARDING
import register from './onboarding/register/register.slice';

// Please alphabeticize
const appReducer = combineReducers({
  accounts,
  availabilities,
  collections,
  register,
  session,
  task_lists,
  tasks,
  teams,
  userGroups,
  integrations,
  ...v1,
  useragent(state) {
    return state || {};
  }
});

export default (state: RootStore | undefined, action: any) => {
  return appReducer(state, action);
};
export type RootStore = ReturnType<typeof appReducer>;
