import { ResourceTypeModel } from '__types__/index';
import classnames from 'classnames';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import React from 'react';
import {
  Button,
  Grid,
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'v1/components/shared';
import {
  createResourceFilters,
  ResourceFiltersConfig
} from 'v1/components/shared/search/ResourceFilters/ResourceFilters';
import { ListQuery } from 'v4/entities/common/common.crud.types';
import { TodoType } from 'v4/entities/common/TodoType';
import './ResourceAdvancedSearch.scss';

import SearchFilters from './SearchFilters/SearchFilters';

interface ResourceAdvancedSearchProps {
  className?: string;
  slimline: boolean;
  slimlineButtonLabel?: string | null;
  align?: 'left' | 'right';
  showMeta?: boolean;
  query?: ListQuery<TodoType>;
  filterList?: string[];
  onChange: (query: ListQuery<TodoType>) => void;
  lockedFields?: string[];
  resourceTypesModel: ResourceTypeModel; // TODO probably unused
  buttonClassName?: string;
  hideActiveFilters?: boolean;
}

export interface ResourceAdvancedSearchConfig {
  resourceFiltersConfig: ResourceFiltersConfig;
}

export function createResourceAdvancedSearch(
  config: ResourceAdvancedSearchConfig
) {
  const ResourceFilters = createResourceFilters(config.resourceFiltersConfig);

  return function ResourceAdvancedSearch({
    className,
    slimline,
    slimlineButtonLabel = null,
    align,
    showMeta,
    query = {},
    filterList,
    onChange,
    lockedFields,
    resourceTypesModel,
    buttonClassName,
    hideActiveFilters
  }: ResourceAdvancedSearchProps) {
    const ifCustomFiltersExist = () => {
      if (query && query.filters) {
        const _filters = Object.assign({}, query.filters);

        delete _filters.groups;
        delete _filters.resource_type_id;

        return size(_filters) > 1;
      }
      return false;
    };

    return (
      <Grid
        align={align}
        className={classnames({ Parent_hoverListener: slimline }, [
          'ResourceAdvancedSearch',
          className
        ])}
        vcenter
      >
        {hideActiveFilters ? null : (
          <>
            <SearchFilters
              slimline={slimline}
              showMeta={showMeta}
              filters={get(query, 'filters') || {}}
              lockedFields={lockedFields}
              onRemove={filters => {
                if (isEmpty(filters)) {
                  delete query.filters;
                  onChange(query);
                } else {
                  onChange({
                    ...query,
                    filters,
                    order_by: !has(filters, 'availability.from_date') // TODO: Make elegant. Need to do this to revert sorting to name
                      ? { field: 'full_name', direction: 'asc' }
                      : { field: 'availability', direction: 'asc' }
                  });
                }
              }}
            />
            {ifCustomFiltersExist() && !slimline && (
              <Button
                dataCy="remove-all-filters"
                type="button"
                onClick={() => {
                  delete query.filters;
                  onChange(query);
                }}
                className={classnames([
                  'ResourceAdvancedSearch-clearAllFilters',
                  'btn',
                  buttonClassName || 'btn-link'
                ])}
              >
                Clear all
              </Button>
            )}
          </>
        )}
        <Popover>
          <PopoverTrigger>
            <Button
              dataCy="add-filters"
              type="button"
              onClick={() => {}}
              className={classnames([
                'btn',
                buttonClassName || 'btn-default',
                {
                  'btn-small': slimline,
                  'btn-square':
                    slimline && !isEmpty(query) && !slimlineButtonLabel
                }
              ])}
            >
              {slimline ? (
                isEmpty(query) ? (
                  <>
                    <img
                      src="/images/icon_add_black.svg"
                      className="icon"
                      width="12px"
                      alt=""
                    />{' '}
                    Add Filters{' '}
                  </>
                ) : (
                  <>
                    <img src="/images/icon_shape.svg" alt="" />
                    {slimlineButtonLabel && (
                      <>
                        {' '}
                        <span className="left-XS">{slimlineButtonLabel}</span>
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <img
                    src="/images/icon_add_black.svg"
                    className="icon"
                    width="12px"
                    alt=""
                  />
                  Add Filters
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="ResourceAdvancedSearch_ResourceFilters inset-M">
            <ResourceFilters
              showMeta={showMeta}
              query={query}
              filterList={filterList}
              onChange={onChange}
              resourceTypesModel={resourceTypesModel}
            />
          </PopoverContent>
        </Popover>
      </Grid>
    );
  };
}
