import { Resource } from '__types__/index';
import { ApiClient } from 'lib/api/ApiClient.types';
import { clearUpdatePayload } from 'store/v1/contacts/contacts.actions';
import {
  BatchQuery,
  ListQuery,
  ListResponse
} from '../common/common.crud.types';

export function createResourcesApi(client: ApiClient, token = true) {
  const api = {
    list: async (query: object) => {
      return client.post<ListResponse<Resource, ListQuery<Resource>>>(
        '/search/contacts',
        { data: query, token }
      );
    },
    detail: async (id: Resource['id'], signal: AbortSignal) => {
      return client.get<Resource>(`/contacts/${id}`, { token, signal });
    },
    batchDetail: async (
      query: BatchQuery<Resource['id']>,
      signal: AbortSignal
    ) => {
      return client.post<{ data: Resource[] }>(`/batch/contacts/search`, {
        token,
        data: {
          ids: query.ids
        },
        signal
      });
    },
    detailUpdate: async (id: Resource['id'], data: Partial<Resource>) => {
      const convertedPayload = clearUpdatePayload(data); // todo check if converter is needed, prefer clean form management on isolated view domains, and remove not-normalized data from responses before filling the cache?
      return client.put<Resource>(`/contacts/${id}`, {
        data: convertedPayload,
        token
      });
    }
  };
  return api;
}

export type ResourcesApi = ReturnType<typeof createResourcesApi>;
