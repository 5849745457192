/**
 * A store for an environment variable
 */
class Variable {
  constructor(name, value, isRequired) {
    this.name = name;
    this.value = value;
    this.isRequired = isRequired;
  }
}

/**
 * Provides safe and predictable access to environment
 * variables injected via webpack in import.meta.env.
 */
class Env {
  constructor() {
    // Remember that webpack replaces environment variables "as is". It will
    // literally replace the string "import.meta.env.XXX" with the value of the
    // environment variable.
    this.envs = [
      new Variable('NODE_ENV', import.meta.env.MODE, true),
      new Variable('VITE_API_URL', import.meta.env.VITE_API_URL, true),
      new Variable('VITE_API_URL_V2', import.meta.env.VITE_API_URL_V2, true),
      new Variable('VITE_SENTRY_ID', import.meta.env.VITE_SENTRY_ID, false),
      new Variable('VITE_MIXPANEL_ID', import.meta.env.VITE_MIXPANEL_ID, false),
      new Variable(
        'VITE_COMMANDBAR_ID',
        import.meta.env.VITE_COMMANDBAR_ID,
        false
      ),
      new Variable(
        'VITE_GOOGLE_OAUTH',
        import.meta.env.VITE_GOOGLE_OAUTH,
        false
      )
    ];

    // Validate that the environment variables exists
    for (let i = 0; i < this.envs.length; i++) {
      const variable = this.envs[i];
      if (variable.isRequired && !variable.value) {
        console.error(
          `expected non-empty ${variable.name} environment variable`
        );
      }
    }
  }

  /**
   * Return the node environment value used for the project build
   * @returns {"development"|"test"|"qa"|"production"}
   */
  get nodeEnv() {
    return this.getVar('NODE_ENV') || '';
  }

  /**
   * Returns true if the NODE_ENV env variable is set to "development"
   * @returns {boolean}
   */
  get isDev() {
    return this.nodeEnv === 'development';
  }

  /**
   * Returns true if the NODE_ENV env variable is set to "test"
   * @returns {boolean}
   */
  get isTest() {
    return this.nodeEnv === 'test';
  }

  /**
   * Returns true if the NODE_ENV env variable is set to "production"
   * @returns {boolean}
   */
  get isProd() {
    return this.nodeEnv === 'production';
  }

  /**
   * Returns true if the URL passes regex. QA environments have "-qa.atellio.com"
   * @returns {boolean}
   */
  get isQAEnvironment() {
    return window.location.hostname.indexOf('-qa.atellio.com') !== -1;
  }

  /**
   * Return the sentry.io DSN.
   * @returns {string}
   */
  get sentryDSN() {
    return this.getVar('VITE_SENTRY_ID') || '';
  }

  /**
   * Return the API base URL
   * @returns {string}
   */
  get apiURL() {
    return this.getVar('VITE_API_URL') || '';
  }

  /**
   * Return the API base URL
   * @returns {string}
   */
  get workspacesApiURL() {
    return this.apiURL + '/workspaces';
  }

  get apiV2URL() {
    return this.getVar('VITE_API_URL_V2') || '';
  }

  /**
   * Return the Mixpanel ID. Marked
   * @returns {string}
   */
  get mixpanelID() {
    return this.getVar('VITE_MIXPANEL_ID') || '';
  }

  /**
   * Return the Mixpanel ID. Marked
   * @returns {string}
   */
  get commandbarID() {
    return this.getVar('VITE_COMMANDBAR_ID') || '';
  }

  /**
   * Return the Google client ID. This ID is used to access google services.
   * Atellio uses this ID to access Google Drive.
   *
   * Deprecated as access to Google Drive is not being supported by Atellio
   * currently
   *
   * @returns {string}
   * @deprecated
   */
  get googleClientID() {
    return this.getVar('VITE_GOOGLE_OAUTH') || '';
  }

  /**
   * Returns true if the environment variable exists and has a non-empty value
   * @param {string} name
   * @returns {boolean}
   */
  exists(name) {
    return !!this.getVar(name);
  }

  /**
   * Retrieve a environment variable by name
   * @param {string} name
   * @returns {string}
   */
  getVar(name) {
    const variable = this.envs.find(variable => variable.name === name);
    return variable ? variable.value : null;
  }
}

export default new Env();
