import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const PRODUCTION_TEMPLATES_ACTIONS = mapValues(
  mirror({
    GET_PRODUCTION_TEMPLATES: null,
    GET_PRODUCTION_TEMPLATES_SUCCESS: null,
    GET_PRODUCTION_TEMPLATES_FAILURE: null,

    GET_PRODUCTION_TEMPLATE: null,
    GET_PRODUCTION_TEMPLATE_SUCCESS: null,
    GET_PRODUCTION_TEMPLATE_FAILURE: null,

    CREATE_PRODUCTION_TEMPLATE: null,
    CREATE_PRODUCTION_TEMPLATE_SUCCESS: null,
    CREATE_PRODUCTION_TEMPLATE_FAILURE: null,

    DELETE_PRODUCTION_TEMPLATE: null,
    DELETE_PRODUCTION_TEMPLATE_SUCCESS: null,
    DELETE_PRODUCTION_TEMPLATE_FAILURE: null,

    UPDATE_PRODUCTION_TEMPLATE: null,
    UPDATE_PRODUCTION_TEMPLATE_SUCCESS: null,
    UPDATE_PRODUCTION_TEMPLATE_FAILURE: null,

    UPDATE_PRODUCTION_TEMPLATE_ORDER: null,
    UPDATE_PRODUCTION_TEMPLATE_ORDER_SUCCESS: null,
    UPDATE_PRODUCTION_TEMPLATE_ORDER_FAILURE: null
  }),
  value => value
);
