import React, { useCallback, useEffect, useState } from 'react';
import { filter, keys } from 'lodash';

import { SingleCheckbox } from 'v1/components/shared';
import { SettingsTable } from 'v1/components/feature/AccountSettingsComponents/Layout';
import { useFilterMenuAccess } from 'lib/restrictedAccess';

import './ShortlistFieldManager.scss';

const ShortlistFieldManager = ({
  default_fields = [],
  shortlist_field_mapper,
  onSubmit,
  query
}) => {
  const [defaultFields, setDefaultFields] = useState(default_fields);
  const mapper = useFilterMenuAccess(
    keys({ ...shortlist_field_mapper }).map(k => ({
      ...shortlist_field_mapper[k],
      field: k
    }))
  );

  const generateTableData = defaultFields => {
    return defaultFields
      .filter(field => shortlist_field_mapper[field])
      .map(field => ({
        field,
        label: shortlist_field_mapper[field].label,
        checked: true
      }))
      .concat(
        mapper
          .filter(
            field =>
              !defaultFields.includes(field.field) &&
              shortlist_field_mapper[field.field]
          )
          .map(field => ({ ...field, checked: false }))
      );
  };

  const [tableData, setTableData] = useState(generateTableData(defaultFields));
  const [filteredTableData, setFilteredTableData] = useState(tableData);

  const handleInputChange = (field, checked) => {
    const newDefaultFields = checked
      ? defaultFields.concat(field)
      : defaultFields.filter(f => f !== field);
    const newTableData = generateTableData(newDefaultFields);
    setDefaultFields(newDefaultFields);
    setTableData(newTableData);
    onSubmit({ default_fields: newDefaultFields });
  };

  const handleOrderChange = order => {
    const newDefaultFields = order.filter(field =>
      defaultFields.includes(field)
    );
    const newTableData = generateTableData(newDefaultFields);
    setDefaultFields(newDefaultFields);
    setTableData(newTableData);
    onSubmit({ default_fields: newDefaultFields });
  };

  const filterTableData = useCallback(
    (query = '') => {
      setFilteredTableData(
        !query.length
          ? tableData
          : filter(tableData, d =>
              d.label.toLowerCase().includes(query.toLowerCase())
            )
      );
    },
    [tableData]
  );

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      filterTableData(query);
    }, 250);

    return () => clearTimeout(searchDelay);
  }, [query, filterTableData]);

  return (
    <div className="ShortlistFieldManager">
      <SettingsTable
        data={filteredTableData}
        onOrderChange={handleOrderChange}
        columns={[
          {
            Header: 'Available Fields',
            accessor: 'label'
          },
          {
            accessor: 'checked',
            width: 50,
            Cell: ({ original }) => (
              <SingleCheckbox
                className="SettingsTable-checkbox"
                onChange={checked => handleInputChange(original.field, checked)}
                checked={original.checked}
              />
            )
          }
        ]}
        sortable
        getSortableID={value => value.field}
        omitSortable={item => !item.checked}
        className="ShortlistFieldManager-table"
      />
    </div>
  );
};

export default ShortlistFieldManager;
