import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import * as entityTypes from 'v1/helpers/entityTypes';

import { getStatuses } from 'store/v1/statuses/statuses.selectors.js';
import { DropdownMenu, Grid, GridCell, PressStud } from 'v1/components/shared';

const BookResourceFlyoverBookingFooter = ({ saveBooking }) => {
  const statuses = useSelector(state =>
    getStatuses(state, { entity_type: entityTypes.BOOKING })
  );
  const confirmedStatus = useSelector(state =>
    get(
      getStatuses(state, {
        status_type: 'CONFIRMED',
        entity_type: entityTypes.BOOKING
      }),
      '0'
    )
  );

  return (
    <Grid
      className="text-11-700-eggplant-lighter"
      align="right"
      gutters="XS"
      vcenter
    >
      <GridCell width="auto">Save as</GridCell>
      <GridCell width="auto">
        <PressStud
          label="Confirmed"
          icon="tick"
          appearance="primary"
          size="S"
          action={() => saveBooking(confirmedStatus)}
        />
      </GridCell>
      <GridCell width="auto">or</GridCell>
      <GridCell width="auto">
        <DropdownMenu label="Other" arrow size="S">
          {statuses.map(status => (
            <DropdownMenu.Item
              key={status.id}
              title={status.name}
              onClick={() => saveBooking(status)}
            />
          ))}
        </DropdownMenu>
      </GridCell>
    </Grid>
  );
};

BookResourceFlyoverBookingFooter.propTypes = {
  saveBooking: PropTypes.func
};

export default BookResourceFlyoverBookingFooter;
