import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { EntityType, RateInterval } from '__types__';
import {
  Currencies,
  ExpenseRateIntervals,
  ResourceRateIntervals
} from 'v1/helpers/DropdownDataSets';
import { selectDefaultCurrency } from 'store/v1/auth/auth.selectors';

import TypeFormComponent from 'v1/components/shared/__types__/TypeFormComponent';
import { TextInputGroupProps } from 'v1/components/shared/byType/text/TextInputGroup/TextInputGroup';
import { TextInputGroup } from 'v1/components/shared';

type RateInputPropsValue = {
  currency?: Currency | null;
  amount?: CurrencyAmount | null;
  interval?: RateInterval | null;
};

type RateInputReturnValue = {
  currency: Currency;
  amount: CurrencyAmount | null;
  interval: RateInterval;
};

interface RateInputProps
  extends Omit<TextInputGroupProps, 'value' | 'onChange' | 'onBlur'> {
  entityType?: EntityType.BOOKING | EntityType.EXPENSE;
  defaultCurrency?: Currency;
  value: RateInputPropsValue;
  onChange?(value: RateInputReturnValue): void;
  onBlur?(value: RateInputReturnValue, key: string): void;
}

// Backup in case there's no global default currency
const systemDefaultCurrency = 'USD';

// This is basically a duplication of CurrencyInput, but with rates
const RateInput = ({
  entityType,
  defaultCurrency,
  value = { currency: null, amount: null, interval: null },
  onChange,
  onBlur,
  ...rest
}: RateInputProps) => {
  const globalDefaultCurrency = useSelector(selectDefaultCurrency);
  const [defaultCurr, setDefaultCurr] = useState<string>(systemDefaultCurrency);
  const options = Object.values(
    entityType === EntityType.EXPENSE
      ? ExpenseRateIntervals
      : ResourceRateIntervals
  );

  useEffect(() => {
    defaultCurrency
      ? setDefaultCurr(defaultCurrency)
      : globalDefaultCurrency
        ? setDefaultCurr(globalDefaultCurrency)
        : setDefaultCurr(systemDefaultCurrency);
  }, [defaultCurrency, globalDefaultCurrency]);

  const prepValue = (v: RateInputPropsValue): RateInputReturnValue => {
    return {
      currency: v.currency || defaultCurr,
      amount: v.amount || null,
      interval: v.interval || options[0].value
    };
  };

  const handleChange = (value: RateInputReturnValue): void => {
    //onChange && onChange(value);
    // TODO: onChange breaks RateInput rate number amount
  };

  const handleBlur = (value: RateInputReturnValue, key: string): void => {
    onChange && onChange(value);
    onBlur && onBlur(value, key);
  };

  return (
    <TextInputGroup
      {...rest}
      type="currency"
      value={prepValue(value)}
      valueKey="amount"
      prepend={{
        valueKey: 'currency',
        options: Currencies.map(c => ({
          value: c.code,
          label: c.symbol
        }))
      }}
      append={{
        valueKey: 'interval',
        options
      }}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

RateInput.propTypes = {
  ...TypeFormComponent,
  defaultCurrency: PropTypes.string,
  entityType: PropTypes.oneOf([EntityType.BOOKING, EntityType.EXPENSE]),
  value: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number,
    rate_interval: PropTypes.oneOf(['DAILY', 'HOURLY', 'FIXED', 'ITEM'])
  }),
  label: PropTypes.string,
  showOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func
};

export default RateInput;
