import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { RootStore } from 'store/reducer';
import { IntegrationID } from '__types__';

const selectIntegrationsStore = (state: RootStore) => state.integrations;

const selectIntegrationsAreFetched = createSelector(
  selectIntegrationsStore,
  state => state.fetched
);

const selectIntegrations = createSelector(
  selectIntegrationsStore,
  state => state.data
);

const selectIntegrationsList = createSelector(selectIntegrations, data =>
  Object.values(data)
);

const selectIntegrationById = createCachedSelector(
  selectIntegrations,
  (_: RootStore, id: IntegrationID) => id,
  (data, id) => data[id]
)((_, id: IntegrationID) => id);

export {
  selectIntegrationsStore,
  selectIntegrationsAreFetched,
  selectIntegrations,
  selectIntegrationsList,
  selectIntegrationById
};
