import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ResourcePaneDetails from './ResourcePaneDetails/ResourcePaneDetails';
import ContactPinned from 'v1/components/feature/ResourceComponents/Resource/Pins/ContactPinned/ContactPinned';
import { ResourceFiles, ResourceNotes, PageTabs } from 'v1/components/shared';

import './ResourcePaneTabs.scss';

const TABS = [
  {
    text: 'Details',
    container: 'details'
  },
  {
    text: 'Notes',
    container: 'notes'
  },
  {
    text: 'Files',
    container: 'files'
  },
  {
    text: 'Pinboard',
    container: 'pinboard'
  }
];

const ResourcePaneTabs = ({
  resource = {},
  resourceKey,
  autosave,
  isReadOnly,
  hideTabs,
  setFormApi
}) => {
  const [tab, setTab] = useState('details');

  return (
    <>
      {!hideTabs && (
        <PageTabs
          tabs={TABS}
          onTabSelect={tab => setTab(tab)}
          activeContainer={tab}
          borderBottom
        />
      )}
      {tab === 'details' && (
        <div className="ResourcePaneTabContent">
          <ResourcePaneDetails
            resource={resource}
            resourceKey={resourceKey}
            autosave={autosave}
            isReadOnly={isReadOnly}
            setFormApi={setFormApi}
          />
        </div>
      )}
      {tab !== 'details' && (
        <div className="inset-M ResourcePaneTabContent">
          {tab === 'notes' && (
            <ResourceNotes
              className="ResourcePane-Notes"
              resource={resource}
              disabled={isReadOnly}
            />
          )}
          {tab === 'files' && (
            <ResourceFiles resource={resource} disabled={isReadOnly} />
          )}
          {tab === 'pinboard' && (
            <ContactPinned
              contact_id={resource.id}
              hideUploader
              layout="SINGLE_COLUMN"
              disabled={isReadOnly}
            />
          )}
        </div>
      )}
    </>
  );
};

ResourcePaneTabs.props = {
  resource: PropTypes.object,
  resourceKey: PropTypes.string,
  autosave: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  hideTabs: PropTypes.bool,
  setFormApi: PropTypes.func
};

export default ResourcePaneTabs;
