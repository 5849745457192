import { ZodType } from 'zod';

export interface EntityEffectConfig<
  Type extends string,
  Request,
  Response,
  State,
  Query
> {
  type: Type;
  requestSchema: ZodType<Request>;
  responseSchema: ZodType<Response>;
  useQueryFn: () => (
    request: Request,
    signal: AbortSignal
  ) => Promise<Response>;
  optimisticReconciler: (state: State, request: Request, query: Query) => State; //  - optimisticReconciler should not invalidate anything!
  responseReconciler: (
    state: State,
    request: Request,
    response: Response,
    query: Query
  ) => { state: State; shouldInvalidate: boolean };
}

/**
 * defineEntityEffect is a helper function that allows to create a configuration object without unnecessary annotations
 * Once you pass schemas, all other parameters are inferred from them
 */
export function defineEntityEffect<
  const Type extends string,
  Request,
  Response
>(
  config1: Omit<
    EntityEffectConfig<Type, Request, Response, never, never>,
    'optimisticReconciler' | 'responseReconciler'
  >
) {
  return {
    withOptimisticReconciler: <State, Query>(
      factory: (
        config: typeof config1
      ) => EntityEffectConfig<
        Type,
        Request,
        Response,
        State,
        Query
      >['optimisticReconciler']
    ) => {
      const config2 = {
        ...config1,
        optimisticReconciler: factory(config1)
      };
      return {
        withResponseReconciler: (
          factory: (
            config: typeof config2
          ) => EntityEffectConfig<
            Type,
            Request,
            Response,
            State,
            Query
          >['responseReconciler']
        ) => {
          const config3 = {
            ...config2,
            responseReconciler: factory(config2)
          };
          return {
            build: () => config3
          };
        }
      };
    }
  };
}
