import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { getIconPath } from 'v1/helpers/icons/resourcePortalIcons';
import { Grid, GridCell, Icon } from 'v1/components/shared';
import styles from './ResourcePortalMenuItem.module.scss';

const ResourcePortalMenuItem = ({
  label,
  to,
  isLocked,
  iconPathKey,
  isMenuOpen,
  isActive
}) => (
  <Link
    to={to}
    className={classnames(styles['ResourcePortalMenuItem'], {
      [styles['ResourcePortalMenuItem--open']]: isMenuOpen,
      [styles['ResourcePortalMenuItem--active']]: isActive
    })}
  >
    <Grid cellPadding="XS">
      <GridCell
        width="auto"
        className={classnames(styles['ResourcePortalMenuItem__iconContainer'], {
          [styles['ResourcePortalMenuItem__iconContainer--open']]: isMenuOpen
        })}
      >
        <Icon
          icon={getIconPath(iconPathKey)}
          className={classnames(styles['ResourcePortalMenuItem__icon'], {
            [styles['ResourcePortalMenuItem__icon--active']]: isActive
          })}
        />
      </GridCell>
      <GridCell
        width="auto"
        vcenter={true}
        className={classnames({
          [styles['disable-on-small-screens']]: !isMenuOpen
        })}
      >
        <p
          className={classnames([
            styles['ResourcePortalMenuItem__label'],
            styles['remove-margin'],
            {
              [styles['ResourcePortalMenuItem__label--open']]: isMenuOpen,
              [styles['ResourcePortalMenuItem__label--active']]: isActive,
              'text-13-600-eggplant-lighter': !isActive,
              'text-13-600': isActive
            }
          ])}
        >
          {label}
        </p>
      </GridCell>
      {isLocked && (
        <GridCell
          className={classnames(
            styles['ResourcePortalMenuItem__lockedIconCell'],
            {
              [styles[
                'ResourcePortalMenuItem__lockedIconCell--open'
              ]]: isMenuOpen
            }
          )}
          vcenter={true}
        >
          <Icon
            icon={getIconPath('LOCK')}
            className={classnames(
              styles['ResourcePortalMenuItem__lockedIcon'],
              {
                [styles['ResourcePortalMenuItem__lockedIcon--active']]: isActive
              }
            )}
          />
        </GridCell>
      )}
    </Grid>
  </Link>
);

export default ResourcePortalMenuItem;
