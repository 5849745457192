import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-form';
import get from 'lodash/get';

import * as entityTypes from 'v1/helpers/entityTypes';

import DataExportsFormSectionContentFields from './DataExportsFormSectionContentFields/DataExportsFormSectionContentFields';
import {
  StackedFormSection,
  Label,
  ResourceAdvancedSearch,
  BookingTypeSelector,
  ProductionTypeSelector,
  EntityTypeSelector
} from 'v1/components/shared';

const ENTITY_TYPES = [
  entityTypes.PRODUCTION,
  entityTypes.CONTACT,
  entityTypes.BOOKING,
  entityTypes.CALLOUT,
  entityTypes.SHORTLIST,
  entityTypes.CALLSHEET
];

const DataExportsFormSectionContent = ({ formApi }) => {
  const resetFields = _ => formApi.setValue('fields', []);
  const resetFilters = _ => {
    formApi.setValue('query.filters', {});
  };

  return (
    <StackedFormSection title="Content">
      <div className="stack-S">
        <Field field="entity_type">
          {({ fieldName: name, value, setValue }) => (
            <EntityTypeSelector
              name={name}
              value={value}
              entityTypes={ENTITY_TYPES}
              onChange={val => {
                setValue(val);
                resetFields();
                resetFilters();
              }}
            />
          )}
        </Field>
      </div>

      {formApi.getValue('entity_type') === entityTypes.PRODUCTION && (
        <div className="stack-S">
          <Field field="query.filters.production_type_id">
            {({ fieldName: name, value, setValue }) => (
              <ProductionTypeSelector
                name={name}
                value={value ? value.eq : ''}
                allowNull
                onChange={val => setValue({ eq: val })}
              />
            )}
          </Field>
        </div>
      )}
      {formApi.getValue('entity_type') === entityTypes.BOOKING && (
        <div className="stack-S">
          <Field field="query.filters.booking_type_id">
            {({ fieldName: name, value, setValue }) => (
              <BookingTypeSelector
                name={name}
                value={value ? value.eq : ''}
                allowNull
                onChange={val => setValue({ eq: val })}
              />
            )}
          </Field>
        </div>
      )}

      {formApi.getValue('entity_type') === entityTypes.CONTACT && (
        <div className="stack-S">
          <Label htmlFor="query.filters">Filters</Label>
          <Field field="query.filters">
            {({ value, setValue }) => (
              <ResourceAdvancedSearch
                showMeta={true}
                query={{ filters: value }}
                onChange={query => {
                  setValue({
                    created_at: get(value, 'created_at', {}),
                    ...query.filters
                  });
                }}
              />
            )}
          </Field>
        </div>
      )}

      <DataExportsFormSectionContentFields formApi={formApi} />
    </StackedFormSection>
  );
};

DataExportsFormSectionContent.props = {
  formApi: PropTypes.any.isRequired
};

export default DataExportsFormSectionContent;
