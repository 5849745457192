import Env from 'lib/env/Env';
import { Mixpanel } from 'lib/mixpanel';
import {
  AuthTracker,
  CalloutTracker,
  FileTracker,
  ProductionTracker,
  InteractionTracker,
  CallsheetTracker,
  BookingTracker,
  ResourceTracker,
  ShortlistTracker
} from 'lib/mixpanel/trackers';

if (Env.mixpanelID) {
  Mixpanel.initialise(Env.mixpanelID);
  Mixpanel.withTracker(new InteractionTracker(Mixpanel.client));
  Mixpanel.withTracker(new AuthTracker(Mixpanel.client));
  Mixpanel.withTracker(new ProductionTracker(Mixpanel.client));
  Mixpanel.withTracker(new FileTracker(Mixpanel.client));
  Mixpanel.withTracker(new CalloutTracker(Mixpanel.client));
  Mixpanel.withTracker(new CallsheetTracker(Mixpanel.client));
  Mixpanel.withTracker(new BookingTracker(Mixpanel.client));
  Mixpanel.withTracker(new ResourceTracker(Mixpanel.client));
  Mixpanel.withTracker(new ShortlistTracker(Mixpanel.client));
}
