import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Collection } from '__types__';
import {
  getTenantCollections,
  getCollection,
  createCollection,
  updateCollection,
  archiveCollection
} from './collections.actions';

export type CollectionsStoreData = Record<string, Collection>;

export type CollectionsStore = {
  data: CollectionsStoreData;
  // order: number[];
  // paging?: Paging;
  // fetched?: boolean;
  loading?: boolean | string;
  // TODO: TS: any
  error?: any;
};

export const INITIAL_STATE: CollectionsStore = {
  data: {},
  // paging: {},
  // order: [],
  // fetched: false,
  loading: false
};

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(
        getTenantCollections.fulfilled,
        (state, { payload }: PayloadAction<Collection[]>) => {
          state.data = payload.reduce(
            (data: CollectionsStoreData, collection: Collection) => ({
              ...data,
              [collection.id]: collection
            }),
            {}
          );
        }
      )

      .addCase(
        getCollection.fulfilled,
        (state, { payload }: PayloadAction<Collection>) => {
          state.data[payload.id] = payload;
        }
      )

      .addCase(
        createCollection.fulfilled,
        (state, { payload }: PayloadAction<Collection>) => {
          state.data[payload.id] = payload;
        }
      )

      .addCase(updateCollection.fulfilled, (state, { meta: { arg } }) => {
        state.data[arg.id] = {
          ...state.data[arg.id],
          ...arg.data
        };
      })

      .addCase(archiveCollection.fulfilled, (state, { meta: { arg } }) => {
        delete state.data[arg];
      })

      // Generic
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default collectionsSlice.reducer;
