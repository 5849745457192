import AuthService from 'lib/auth/AuthService';
import { useRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAccount } from 'store/accounts';
import {
  getPublicBrandImage,
  getPublicCompanyName,
  selectAppId
} from 'store/v1/auth/auth.selectors';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors';
import { sidebarGlobalCreateConfig } from './components/SidebarGlobalCreate.connected';

import { createSidebar, SidebarConfig } from './Sidebar';
import Env from 'lib/env/Env';
import { openModal } from 'store/v1/ui/ui.actions';

export const sidebarConfig: SidebarConfig = {
  useAuth: () => AuthService,
  useAccount: () => useSelector(selectActiveAccount) ?? undefined,
  useAppId: () => useSelector(selectAppId),
  useBrandImage: () => useSelector(getPublicBrandImage),
  useCompanyName: () => useSelector(getPublicCompanyName),
  useResourceTypes: () => useSelector(getResourceTypes),
  useRestrictedAccess: useRestrictedAccessCallback,
  useIsSandbox: () => !Env.isProd || Env.isQAEnvironment,
  useOnSandboxInfoShow: () => {
    const dispatch = useDispatch();
    return () => {
      dispatch(openModal('DevelopmentDisclaimerModal'));
    };
  },
  sidebarGlobalCreateConfig
};
const Sidebar = createSidebar(sidebarConfig);

export default Sidebar;
