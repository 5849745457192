import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { get } from 'lodash';
import orderBy from 'lodash/orderBy';

import { Label, Grid, GridCell } from 'v1/components/shared';

import styles from './MultiInput.module.scss';

// NOTE: Theoretically you could put anything in here, but it's only been checked with TextInputGroup
// NOTE: Only styled with a few appearances/sizes so far so may need updating
// TODO: would be great to focus the new input on add
const MultiInput = ({
  id,
  field,
  label,
  size = 'M',
  disabled,
  value = [],
  children,
  onBlur,
  onChange,
  initialValues,
  ...props
}) => {
  const handleAdd = () => {
    onChange &&
      onChange([
        ...value,
        { id: `temp-${Date.now()}-${v4()}`, ...initialValues }
      ]);
    // No onBlur here as we don't want to save blanks
    // this is a smelly hack but since we are going to kill this form component
    // we have a quick win that will focus latest input after clicking 'add'
    new Promise(resolve => setTimeout(resolve, 0)).then(() => {
      const focusable = ref.current?.querySelectorAll(
        '[href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (focusable && focusable.length) {
        focusable[focusable.length - 1].focus();
      }
    });
  };

  const handleRemove = (id, index) => {
    if (onChange) {
      id
        ? onChange(value.filter(e => id !== e.id))
        : onChange(value.filter((e, i) => i !== index));
    }
    onBlur && onBlur();
  };

  const handleBlur = (index, val) => {
    /**
     * TODO react-form is deprecated and doesn't support immediate setValue + submitForm (setters and renders are now batched in React 18)
     *
     *  So we have two sync writes, and in React 18, submitForm will read data from the form before the first write has been committed.
     */
    if (get(val, 'id')) {
      onChange && onChange(value.map(e => (e.id === val.id ? val : e))); // changes the form value, in React18 schedules react-form component update for nearest future
      setTimeout(() => {
        onBlur && onBlur(); // TODO if this immediately follows onChange, in new React, onChange->setValue() and onBlur->submitForm() will be batched together for one render, so submitForm will read the old value (state from previous render)
      }, 0);
    } else {
      onChange && onChange(value.map((e, i) => (i === index ? val : e))); // changes the form value, in React18 schedules react-form component update for nearest future
      setTimeout(() => {
        onBlur && onBlur(); // TODO if this immediately follows onChange, in new React, onChange->setValue() and onBlur->submitForm() will be batched together for one render, so submitForm will read the old value (state from previous render)
      }, 0);
    }
  };

  const ref = useRef();
  value = orderBy(value, 'id'); // To stop multi-inputs switching order we'll order by ID

  return (
    <div ref={ref}>
      {label && <Label>{label}</Label>}
      {value.map((item, index) => (
        <Grid
          key={item.id || index}
          gutters="XS"
          className={classnames(
            {
              'stack-XS': size === 'S',
              'stack-S': size === 'M',
              'stack-M': size === 'L'
            },
            'Parent_hoverListener'
          )}
        >
          <GridCell>
            {React.cloneElement(children, {
              size,
              disabled,
              value: item,
              onBlur: val => handleBlur(index, val),
              ...props
            })}
          </GridCell>
          <GridCell className="Child_hoverListener" width="auto">
            {!disabled && (
              <div
                className={classnames(
                  styles['MultiInput-removeButton'],
                  'clickable'
                )}
                onClick={() => handleRemove(item.id, index)}
              >
                x
              </div>
            )}
          </GridCell>
        </Grid>
      ))}
      {!disabled && (
        <button
          className={classnames(
            'genericLink genericLink__underline',
            styles.addButton
          )}
          onClick={handleAdd}
        >
          Add
        </button>
      )}
    </div>
  );
};

MultiInput.propTypes = {
  id: PropTypes.string, // Unused, just removing so it isn't spread
  field: PropTypes.string, // Unused, just removing so it isn't spread
  label: PropTypes.string,
  size: PropTypes.oneOf(['S', 'M', 'L']),
  disabled: PropTypes.any, // Used as boolean
  value: PropTypes.array,
  children: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.object // Required if you want to pre-populate values to a newly added item
  // ...props - Inherits props from its child as they're spread on
  // Prefer putting props on MultiInput to putting them on on the child, for safety
};

export default MultiInput;
