import React, { forwardRef, useRef } from 'react';
import TimeInputPolyfill from 'v1/components/shared/byType/text/TextInputPolyfills/polyfills/TimeInputPolyfill';
import { TextInputPolyfillsProps } from './__types__/TextInputPolyfills';

const TextInputPolyfills = ({
  type,
  onChange,
  setCurrentValue,
  ...props
}: TextInputPolyfillsProps, ref: any) => {
  const innerRef = useRef(ref);

  const returnPolyfillInput = () => {
    switch (type) {
      case 'time':
        return (
          <label className="TimeInput_polyfill">
            <span>TextInputLabel</span>
            <TimeInputPolyfill
              {...props}
              ref={innerRef}
              onChange={(target: any): void => {
                setCurrentValue && setCurrentValue(target.value);
                onChange && onChange({target});
              }} 
            />
          </label>
        );
      default:
        return <></>;
    }
  };

  return returnPolyfillInput();
};

export default forwardRef(TextInputPolyfills);