import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-form';
import { get } from 'lodash';

import {
  createDataExportTemplate,
  updateDataExportTemplate
} from 'store/v1/data_export_templates/data_export_templates.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import * as entityTypes from 'v1/helpers/entityTypes';

import DataExportsFormSectionContent from './DataExportsFormSectionContent/DataExportsFormSectionContent';
import DataExportsFormSectionDates from './DataExportsFormSectionDates/DataExportsFormSectionDates';
import {
  StackedForm,
  StackedFormSection,
  Label,
  TextInputField
} from 'v1/components/shared';

const DEFAULT_ENTITY_TYPE = entityTypes.CONTACT;

const defaultValues = {
  entity_type: DEFAULT_ENTITY_TYPE,
  fields: [],
  query: {
    filters: {},
    date_filters: [
      {
        date_field: 'created_at',
        is_relative: true
      }
    ]
  }
};

const DataExportsForm = ({ disabled, getApi }) => {
  const dispatch = useDispatch();
  const dataExportTemplates = useSelector(
    state => state.data_export_templates.data
  );
  const uiData = useSelector(state => state.ui.data);
  const templateId = get(uiData, 'id');
  const dataExportTemplate = get(dataExportTemplates, templateId);

  const onSubmit = data => {
    try {
      templateId
        ? dispatch(updateDataExportTemplate(data))
        : dispatch(createDataExportTemplate(data));
    } catch (err) {
      dispatch(displayAlert('error', err));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={dataExportTemplate || defaultValues}
      getApi={api => getApi(api)}
      validateOnSubmit
    >
      {formApi => (
        <StackedForm onSubmit={formApi.submitForm}>
          <StackedFormSection title="Details">
            <div className="stack-S">
              <Label htmlFor="name">Report name</Label>
              <TextInputField
                field="name"
                placeholder="Type a report name"
                validate="required"
                initialFocus
                removeAutocomplete
              />
            </div>
            <div className="stack-S">
              <Label htmlFor="description">Report description</Label>
              <TextInputField
                field="description"
                placeholder="Type a report description"
                removeAutocomplete
              />
            </div>
          </StackedFormSection>
          <DataExportsFormSectionContent formApi={formApi} />
          <DataExportsFormSectionDates formApi={formApi} />
        </StackedForm>
      )}
    </Form>
  );
};

export default DataExportsForm;
