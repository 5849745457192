import { useRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import { SidebarSettingsConfig, createSettingsSidebar } from './SettingsSidebar';
import { useSelector } from 'react-redux';
import { selectTeamsList } from 'store/teams';

export const settingsSidebarConfig: SidebarSettingsConfig = {
  useTeams: () => useSelector(selectTeamsList),
  useRestrictedAccess: useRestrictedAccessCallback
};

const SettingsSidebar = createSettingsSidebar(settingsSidebarConfig);

export default SettingsSidebar;
