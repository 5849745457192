import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { deleteApplicant } from 'store/v1/callouts/callouts.actions';

import { Grid, GridCell, PressStud } from 'v1/components/shared';
import CalloutReviewActionsPopover from '../CalloutReviewActionsPopover/CalloutReviewActionsPopover';

import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import './ApplicantReviewActions.scss';

const ApplicantReviewActions = ({
  selectedApplicant = {},
  applicants,
  callout,
  onNavigate
}) => {
  const dispatch = useDispatch();

  function onPass() {
    dispatch(deleteApplicant(selectedApplicant.id, callout.id));
  }

  return (
    <div className="ApplicantReviewActions inset-S">
      <Grid gutters="XS" className="stack-S">
        <GridCell>
          <PressStud
            tooltip="Previous Applicant"
            icon="arrowLeft"
            action={() => onNavigate('LEFT')}
            size="M"
            isDisabled={isEmpty(selectedApplicant)}
          />
        </GridCell>
        <GridCell width="auto">
          <PressStud
            icon="thumbsDown"
            label="Pass"
            action={onPass}
            isDisabled={isEmpty(selectedApplicant)}
            size="S"
          />
        </GridCell>
        <GridCell width="auto">
          <CalloutReviewActionsPopover
            callout={callout}
            selectedApplicant={selectedApplicant}
          />
        </GridCell>
        <GridCell className="text-right">
          <PressStud
            tooltip="Next Applicant"
            icon="arrowRight"
            action={() => onNavigate('RIGHT')}
            size="M"
            isDisabled={isEmpty(selectedApplicant)}
          />
        </GridCell>
      </Grid>
      <Grid>
        <GridCell className="text-center text-11-700-eggplant">
          {size(applicants)} Left To Review
        </GridCell>
      </Grid>
    </div>
  );
};

ApplicantReviewActions.propTypes = {
  callout: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
  selectedApplicant: PropTypes.object,
  applicants: PropTypes.array
};

export default ApplicantReviewActions;
