import ApiClient from 'lib/api/ApiClient';
import Account from 'lib/auth/Account';
import Env from 'lib/env/Env';
let apiClient = new ApiClient();

const setAppId = () => {
  const workspace = Account.resolveFirstWorkspace();
  if (!workspace) {
    console.warn('failed to get first workspace');
    return;
  }
  apiClient.app_id = workspace.db_schema;
};

/**
 * Obtain a file upload link for private files
 * @param {{ content_type,  extension,  secure, size, name }} data
 * @return {*}
 */
const getUrl = data => {
  setAppId();
  return apiClient
    .post(`/files`, {
      data,
      token: true
    })
    .then(resp => {
      return { ...resp };
    });
};

const getSecureUrl = file_id => {
  setAppId();
  return apiClient
    .get(`/files/${file_id}`, { token: true })
    .then(resp => resp.url);
};

const uploadFile = config => {
  const file = config.file;
  const s3Path = config.uploadTo;
  const type = config.file.type ? config.file.type : 'application/*';
  const isAzure = s3Path.includes('blob.core.windows.net');

  const xhr = createCORSRequest('PUT', s3Path);
  if (!xhr) return 'CORS not supported';

  const onloadDefault = () => {
    return xhr.responseText;
  };
  const onerrorDefault = {};

  xhr.onload = config.onLoad || onloadDefault;

  xhr.onerror = config.onError || onerrorDefault;

  xhr.upload.addEventListener('progress', evt => {
    if (evt.lengthComputable) {
      if (config.onProgress) {
        config.onProgress((evt.loaded / evt.total) * 100);
      }
    }
  });

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      // Azure returns 201 Created
      if (xhr.status === 200 || xhr.status === 201) {
        if (config.onComplete) {
          config.onComplete(config.accessUrl);
        }
      } else {
        console.log('Error', xhr);
      }
    }
  };

  if (isAzure) {
    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
  } else {
    xhr.setRequestHeader(
      'x-amz-acl',
      config.secure ? 'private' : 'public-read'
    );
  }
  xhr.setRequestHeader('Content-Type', type);

  xhr.withCredentials = false;

  xhr.send(file);

  return false;
};

const createCORSRequest = (method, url) => {
  let xhr = new window.XMLHttpRequest();
  if ('withCredentials' in xhr) {
    xhr.open(method, url, true);
  } else if (typeof window.XDomainRequest !== 'undefined') {
    xhr = new window.XDomainRequest();
    xhr.open(method, url);
  } else {
    xhr = null;
  }
  return xhr;
};

/**
 * Prepare a URL to access a file based on a file object
 * @param {{id: number, url: string}} file
 * @returns {string}
 */
export const makeFileURL = file => {
  if (!file) {
    return '';
  }

  if (typeof file.url !== 'string') {
    return '';
  }

  if (file.preSignedUrl) {
    return file.preSignedUrl;
  }

  const workspace = Account.resolveFirstWorkspace();
  const baseUrl = Env.apiURL.replace('api.', 'files.');
  return `${baseUrl}/workspaces/${
    workspace ? workspace.db_schema : file.dbSchema
  }/files/${file.id}`;
};

export default { getUrl, getSecureUrl, uploadFile };
