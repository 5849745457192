import React from 'react';
import styles from './ResourcePDFDocument.styles';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { renderResourceGroups } from 'v1/helpers/contactHelpers';
import { convertImageFileWithCloudinary } from 'lib/media/imageHelper';
import { get } from 'lodash';
import { Pin, Resource } from '__types__';

const MAX_GALLERY_ITEMS = 3;

interface ResourcePDFDocumentProps {
  resource: Resource;
  fieldMappings: any;
  selectedFields: any;
  gallery: Pin[]
}

const ResourcePDFDocument: React.FC<ResourcePDFDocumentProps> = ({ resource, gallery, fieldMappings, selectedFields }) => {

  Font.register({
    family: 'Nunito',
    fonts: [
      { src: '/fonts/Nunito-Regular.ttf', fontStyle: 'regular' },
      { src: '/fonts/Nunito-Italic.ttf', fontStyle: 'italic' },
      { src: '/fonts/Nunito-SemiBold.ttf', fontWeight: 600 },
      {
        src: '/fonts/Nunito-SemiBoldItalic.ttf',
        fontStyle: 'italic',
        fontWeight: 600
      },
      { src: '/fonts/Nunito-Bold.ttf', fontWeight: 700 },
      {
        src: '/fonts/Nunito-BoldItalic.ttf',
        fontStyle: 'italic',
        fontWeight: 700
      }
    ]
  });

  function renderFieldItem(field, index) {
    const { getValue, getPrettyValue, label } = fieldMappings[field];
    const rawValue = getValue(resource);

    const prettyValue = getPrettyValue
      ? getPrettyValue(rawValue)
      : rawValue;
    return (
      <View key={index} style={styles.fieldItem}>
        <View style={styles.fieldItemLabel}>
          <Text>{label}</Text>
        </View>
        <View style={styles.fieldItemValue}>
          <Text>{prettyValue}</Text>
        </View>
      </View>
    )
  }
  function renderGalleryItem(pin) {
    const pinFile = get(pin, 'file', null);
    const pinResizedFileURL = convertImageFileWithCloudinary(pin.url, { width: 500, quality: 80 });

    return (
      <View style={styles.galleryItem}>
        <Image
          src={pinFile && pinResizedFileURL ? pinResizedFileURL : pin.url}
          style={styles.galleryImage}
        />
      </View>
    )
  }

  return (
    <Document
      title={resource.full_name}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headerAvatarContainer}>
              {resource.profile_picture ?
                <Image
                  src={convertImageFileWithCloudinary(resource.profile_picture, { width: 500, height: 500, quality: 80 })}
                  style={styles.headerAvatarImage}
                />
                :
                <View style={styles.headerAvatarImagePlaceholder} />
              }
            </View>
            <View style={styles.headerContentContainer}>
              <Text style={styles.heading}>{resource.full_name}</Text>
              {renderResourceGroups(resource.groups) && <Text>{renderResourceGroups(resource.groups)}</Text>}
            </View>
          </View>
        </View>
        <View style={styles.section}>
          {selectedFields.map((field, index) => (
            renderFieldItem(field, index)
          ))}
        </View>
        {gallery && (gallery.length > 1) &&
          <View style={styles.section}>
            <View style={styles.galleryContainer}>
              {gallery.map((item, index) => {
                if ((index + 1) > MAX_GALLERY_ITEMS) return null;
                return renderGalleryItem(item)
              })}
            </View>
          </View>
        }
      </Page>
    </Document >
  )
}

export default ResourcePDFDocument;