import React from 'react';
import { RateFee } from 'v1/components/shared';

const RateAgencyFee = ({ defaultValue = 20, ...otherProps }) => {
  return (
    <RateFee
      label="Add agency fee"
      placeholder="Agency fee"
      defaultValue={defaultValue}
      {...otherProps}
    />
  );
};

export default RateAgencyFee;
