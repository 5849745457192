import React from 'react';
import { RateFee } from 'v1/components/shared';

const RateProcessingFee = props => {
  return (
    <RateFee
      label="Add processing fee"
      placeholder="Processing fee"
      {...props}
    />
  );
};

RateProcessingFee.defaultProps = {
  defaultValue: 5
};

export default RateProcessingFee;
