import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { InputMetaProps, InputStylingProps } from './__types__/Inputs';
import InputStyles from './__styles__/Inputs.module.scss';

const useInputClasses = ({
  appearance = 'outline',
  size = 'm',
  align = 'left',
  isDisabled,
  isValid = true
}: InputMetaProps & InputStylingProps) => {
  const makeInputClasses = useCallback(() => {
    return classnames([
      InputStyles.Input,
      {
        [InputStyles.editable]: !isDisabled,
        [InputStyles[appearance]]: appearance,
        [InputStyles[size]]: size,
        [InputStyles.center]: align === 'center',
        [InputStyles.right]: align === 'right',
        [InputStyles.disabled]: isDisabled,
        [InputStyles.invalid]: !isValid
      }
    ]);
  }, [appearance, size, align, isDisabled, isValid]);

  const [inputClasses, setInputClasses] = useState<string>(makeInputClasses());

  useEffect(() => {
    setInputClasses(makeInputClasses());
  }, [makeInputClasses]);

  return inputClasses;
};

export default useInputClasses;
