import { createSelector } from 'reselect';
import _keys from 'lodash/keys';
import _orderBy from 'lodash/orderBy';
import { get } from 'lodash';
import { orderReducer } from 'v1/helpers/misc';

export const getContactStore = state => state.contacts;

const getResource = (state, props = {}) =>
  state.contacts.data[get(props, 'resourceId')];

export const makeGetResource = () =>
  createSelector([getResource], resource => resource);

export const getResourceTypes = createSelector(
  state => state.resource_types.data,
  (_, { archived = false } = { archived: false }) => archived,
  (_, { model = null } = { model: null }) => model,
  (data, archived, model) => {
    const types = _keys(data).reduce((result, id) => {
      if (!archived && data[id].archived) return result;
      if (model && data[id].model !== model) return result;
      return result.concat(data[id]);
    }, []);
    return _orderBy(types, ['order', 'id'], ['asc', 'asc']);
  }
);

export const getDefaultResourceType = createSelector(
  [state => state.resource_types],
  data => {
    const resourceTypes = orderReducer(data).filter(r => !r.archived);
    return resourceTypes[0];
  }
);

export const getResourceTypeFields = (state, resource_type_id) => {
  const fields = get(
    state.resource_types,
    ['data', resource_type_id, 'metastructure', 'fields'],
    []
  );
  return fields.filter(field => field.active);
};

export const resourceFieldsContainsRate = createSelector(
  [getResourceTypeFields],
  fields => {
    return fields.some(field => field.name === 'rates');
  }
);
