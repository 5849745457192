import { Capability, FeatureFlag } from '__types__';
import { useSelector } from 'react-redux';
import { selectActiveFeatureFlags, selectActiveTeam } from 'store/teams';
import { selectActiveCapabilities } from 'store/userGroups';
import { checkOverallAccess, NO_CAPABILITIES } from './accessCheckers';

/**
 * Returns true if the first workspace has the required feature flag and/or the account has the capability.
 */
const useRestrictedAccess = (
  requiredFlag: FeatureFlag | null = null,
  requiredCapabilities: Capability | Capability[] | null = null,
  requiredCapabilitiesAtLeastOne: Capability[] | null = null
) => {
  const workspace = useSelector(selectActiveTeam);
  const activeFeatureFlags = useSelector(selectActiveFeatureFlags);
  const activeCapabilities = useSelector(selectActiveCapabilities);

  if (!workspace) {
    return false;
  }

  return checkOverallAccess(
    requiredFlag,
    requiredCapabilities,
    requiredCapabilitiesAtLeastOne,
    activeFeatureFlags,
    activeCapabilities
  );
};

export const useRestrictedAccessCallback = () => {
  const workspace = useSelector(selectActiveTeam);
  const activeFeatureFlags = useSelector(selectActiveFeatureFlags);
  const activeCapabilities = useSelector(selectActiveCapabilities);

  return (
    requiredFlag: FeatureFlag | null = null,
    requiredCapabilities:
      | Capability
      | Capability[]
      | null
      | typeof NO_CAPABILITIES = null,
    requiredCapabilitiesAtLeastOne: Capability[] | null = null
  ) => {
    if (!workspace) {
      return false;
    }
    return checkOverallAccess(
      requiredFlag,
      requiredCapabilities,
      requiredCapabilitiesAtLeastOne,
      activeFeatureFlags,
      activeCapabilities
    );
  };
};

export default useRestrictedAccess;

export type UseRestrictedAccessCallback = typeof useRestrictedAccessCallback;
export type Can = ReturnType<UseRestrictedAccessCallback>;
