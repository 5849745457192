import { set, keys, omit } from 'lodash';

export const onSubmit = (submit, ...omits) => {
  return keys(submit).reduce((obj, key) => {
    const original = submit[key];
    if (Array.isArray(original)) {
      const array_formated = original.map(i => {
        if (i.id && i.id.includes && i.id.includes('temp'))
          return omit(i, omits.concat('id'));
        return omit(i, omits);
      });
      // TODO: mutating lodash function
      return set(obj, key, array_formated);
    }
    if (omits && omits.includes(key)) return obj;
    // TODO: mutating lodash function
    return set(obj, key, original);
  }, {});
};

export const onUpdate = (field, value, method, key) => {
  // prettier-ignore
  return prev => {
    const name = Array.isArray(field) ? field[0] : field;
    const arr = prev[key][name] || [];
    switch (method) {
      case undefined:
      case null:
        // TODO: mutating lodash function
        return set(prev, `${key}.${name}`, value);
      case "CREATE":
        // TODO: mutating lodash function
        return set(prev, `${key}.${name}`, [...arr, value]);
      case "UPDATE": {
        if (value.id.toString().includes("temp")) {
          if (!arr.find(i => i.id === value.id)) {
            // TODO: mutating lodash function
            return set(prev, `${key}.${name}`, [...arr, value]);
          }
          // TODO: mutating lodash function
          return set(prev, `${key}.${name}`, arr.map(i => (i.id === value.id ? value : i)));
        }
        return arr.find(i => i.id === value.id)
        // TODO: mutating lodash function
          ? set(prev, `${key}.${name}`, arr.map(i => (i.id === value.id ? value : i)))
          // TODO: mutating lodash function
          : set(prev, `${key}.${name}`, [...arr, value]);
      }
      case "DELETE": {
        if (value.id) {
          // TODO: mutating lodash function
          return set(prev, `${key}.${name}`, arr.filter(i => i.id !== value.id));
        }
        // TODO: mutating lodash function
        return set(prev, `${key}.${name}`, []);
      }
      default:
        return prev;
    }
  };
  // prettier-ignore-end
};

export default { onUpdate, onSubmit };
