import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const PRODUCTIONS_ACTIONS = mapValues(
  mirror({
    CREATE_PRODUCTION: null,
    CREATE_PRODUCTION_SUCCESS: null,
    CREATE_PRODUCTION_FAILURE: null,

    UPDATE_PRODUCTION: null,
    UPDATE_PRODUCTION_SUCCESS: null,
    UPDATE_PRODUCTION_FAILURE: null,

    DELETE_PRODUCTION: null,
    DELETE_PRODUCTION_SUCCESS: null,
    DELETE_PRODUCTION_FAILURE: null,

    UNARCHIVE_PRODUCTION: null,
    UNARCHIVE_PRODUCTION_SUCCESS: null,
    UNARCHIVE_PRODUCTION_FAILURE: null,

    GET_PRODUCTIONS: null,
    GET_PRODUCTIONS_SUCCESS: null,
    GET_PRODUCTIONS_FAILURE: null,

    QUICK_SEARCH_PRODUCTIONS: null,
    QUICK_SEARCH_PRODUCTIONS_SUCCESS: null,
    QUICK_SEARCH_PRODUCTIONS_FAILURE: null,

    GET_BATCH_PRODUCTIONS: null,
    GET_BATCH_PRODUCTIONS_SUCCESS: null,
    GET_BATCH_PRODUCTIONS_FAILURE: null,

    GET_PRODUCTION: null,
    GET_PRODUCTION_SUCCESS: null,
    GET_PRODUCTION_FAILURE: null,

    GET_PRODUCTION_ASSOCIATIONS: null,
    GET_PRODUCTION_ASSOCIATIONS_SUCCESS: null,
    GET_PRODUCTION_ASSOCIATIONS_FAILURE: null,

    GET_PRODUCTION_NOTE: null,
    GET_PRODUCTION_NOTE_SUCCESS: null,
    GET_PRODUCTION_NOTE_FAILURE: null,

    CREATE_PRODUCTION_NOTE: null,
    CREATE_PRODUCTION_NOTE_SUCCESS: null,
    CREATE_PRODUCTION_NOTE_FAILURE: null,

    DELETE_PRODUCTION_NOTE: null,
    DELETE_PRODUCTION_NOTE_SUCCESS: null,
    DELETE_PRODUCTION_NOTE_FAILURE: null,

    CREATE_PRODUCTION_DOCUMENT: null,
    CREATE_PRODUCTION_DOCUMENT_SUCCESS: null,
    CREATE_PRODUCTION_DOCUMENT_FAILURE: null,

    DELETE_PRODUCTION_DOCUMENT: null,
    DELETE_PRODUCTION_DOCUMENT_SUCCESS: null,
    DELETE_PRODUCTION_DOCUMENT_FAILURE: null,

    UPDATE_PRODUCTION_EVENT: null,
    UPDATE_PRODUCTION_EVENT_SUCCESS: null,
    UPDATE_PRODUCTION_EVENT_FAILURE: null,

    CREATE_PRODUCTION_EVENT: null,
    CREATE_PRODUCTION_EVENT_SUCCESS: null,
    CREATE_PRODUCTION_EVENT_FAILURE: null,

    DELETE_PRODUCTION_EVENT: null,
    DELETE_PRODUCTION_EVENT_SUCCESS: null,
    DELETE_PRODUCTION_EVENT_FAILURE: null,

    UPDATE_PRODUCTION_TEAM_MEMBER: null,
    UPDATE_PRODUCTION_TEAM_MEMBER_SUCCESS: null,
    UPDATE_PRODUCTION_TEAM_MEMBER_FAILURE: null,

    UPDATE_PRODUCTION_TEAM_MEMBER_BATCH: null,
    UPDATE_PRODUCTION_TEAM_MEMBER_BATCH_SUCCESS: null,
    UPDATE_PRODUCTION_TEAM_MEMBER_BATCH_FAILURE: null,

    CREATE_TEAM_MEMBER_OPTION: null,
    CREATE_TEAM_MEMBER_OPTION_SUCCESS: null,
    CREATE_TEAM_MEMBER_OPTION_FAILURE: null,

    DELETE_RESOURCE_SLOT: null,
    DELETE_RESOURCE_SLOT_SUCCESS: null,
    DELETE_RESOURCE_SLOT_FAILURE: null,

    CREATE_RESOURCE_SLOT: null,
    CREATE_RESOURCE_SLOT_SUCCESS: null,
    CREATE_RESOURCE_SLOT_FAILURE: null,

    UPDATE_RESOURCE_SLOT: null,
    UPDATE_RESOURCE_SLOT_SUCCESS: null,
    UPDATE_RESOURCE_SLOT_FAILURE: null,

    REORDER_RESOURCE_SLOTS: null,
    REORDER_RESOURCE_SLOTS_SUCCESS: null,
    REORDER_RESOURCE_SLOTS_FAILURE: null,

    CREATE_RESOURCE_SLOT_ASSIGNMENT: null,
    CREATE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS: null,
    CREATE_RESOURCE_SLOT_ASSIGNMENT_FAILURE: null,

    UPDATE_TEAM_MEMBER_ASSIGNMENT: null,
    UPDATE_TEAM_MEMBER_ASSIGNMENT_SUCCESS: null,
    UPDATE_TEAM_MEMBER_ASSIGNMENT_FAILURE: null,

    DELETE_RESOURCE_SLOT_ASSIGNMENT: null,
    DELETE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS: null,
    DELETE_RESOURCE_SLOT_ASSIGNMENT_FAILURE: null,

    CREATE_RESOURCE_SLOT_EVENT: null,
    CREATE_RESOURCE_SLOT_EVENT_SUCCESS: null,
    CREATE_RESOURCE_SLOT_EVENT_FAILURE: null,

    UPDATE_RESOURCE_SLOT_EVENT: null,
    UPDATE_RESOURCE_SLOT_EVENT_SUCCESS: null,
    UPDATE_RESOURCE_SLOT_EVENT_FAILURE: null,

    DELETE_RESOURCE_SLOT_EVENT: null,
    DELETE_RESOURCE_SLOT_EVENT_SUCCESS: null,
    DELETE_RESOURCE_SLOT_EVENT_FAILURE: null,

    CREATE_PRODUCTION_GROUP: null,
    CREATE_PRODUCTION_GROUP_SUCCESS: null,
    CREATE_PRODUCTION_GROUP_FAILURE: null,

    UPDATE_PRODUCTION_GROUP: null,
    UPDATE_PRODUCTION_GROUP_SUCCESS: null,
    UPDATE_PRODUCTION_GROUP_FAILURE: null,

    DELETE_PRODUCTION_GROUP: null,
    DELETE_PRODUCTION_GROUP_SUCCESS: null,
    DELETE_PRODUCTION_GROUP_FAILURE: null,

    REORDER_PRODUCTION_GROUPS: null,
    REORDER_PRODUCTION_GROUPS_SUCCESS: null,
    REORDER_PRODUCTION_GROUPS_FAILURE: null,

    // BUDGET ACTIONS
    ADD_BUDGET_EXPENSE: null,
    ADD_BUDGET_EXPENSE_SUCCESS: null,
    ADD_BUDGET_EXPENSE_FAILURE: null,

    DELETE_BUDGET_EXPENSE: null,
    DELETE_BUDGET_EXPENSE_SUCCESS: null,
    DELETE_BUDGET_EXPENSE_FAILURE: null,

    ORDER_BUDGET_EXPENSES: null,
    ORDER_BUDGET_EXPENSES_SUCCESS: null,
    ORDER_BUDGET_EXPENSES_FAILURE: null,

    ADD_BUDGET_EXPENSE_ITEM: null,
    ADD_BUDGET_EXPENSE_ITEM_SUCCESS: null,
    ADD_BUDGET_EXPENSE_ITEM_FAILURE: null,

    UPDATE_BUDGET_EXPENSE_ITEM: null,
    UPDATE_BUDGET_EXPENSE_ITEM_SUCCESS: null,
    UPDATE_BUDGET_EXPENSE_ITEM_FAILURE: null,

    BATCH_UPDATE_INVOICES: null,
    BATCH_UPDATE_INVOICES_SUCCESS: null,
    BATCH_UPDATE_INVOICES_FAILURE: null,

    UPDATE_INVOICE: null,
    UPDATE_INVOICE_SUCCESS: null,
    UPDATE_INVOICE_FAILURE: null,

    UPDATE_PRODUCTION_BUDGET_EXPENSE: null,
    UPDATE_PRODUCTION_BUDGET_EXPENSE_SUCCESS: null,
    UPDATE_PRODUCTION_BUDGET_EXPENSE_FAILURE: null,

    CREATE_RECEIPT: null,
    CREATE_RECEIPT_SUCCESS: null,
    CREATE_RECEIPT_FAILURE: null,

    UPDATE_RECEIPT: null,
    UPDATE_RECEIPT_SUCCESS: null,
    UPDATE_RECEIPT_FAILURE: null,

    DELETE_RECEIPT: null,
    DELETE_RECEIPT_SUCCESS: null,
    DELETE_RECEIPT_FAILURE: null,

    UPDATE_CONFLICTS: null,
    UPDATE_CONFLICTS_SUCCESS: null,
    UPDATE_CONFLICTS_FAILURE: null,

    MOVE_PRODUCTION: null,
    MOVE_PRODUCTION_SUCCESS: null,
    MOVE_PRODUCTION_FAILURE: null,

    SEND_PRODUCTION_BOOKING_CONFIRMATIONS: null,
    SEND_PRODUCTION_BOOKING_CONFIRMATIONS_SUCCESS: null,
    SEND_PRODUCTION_BOOKING_CONFIRMATIONS_FAILURE: null,

    GET_PRODUCTION_CALENDAR: null,
    GET_PRODUCTION_CALENDAR_SUCCESS: null,
    GET_PRODUCTION_CALENDAR_FAILURE: null
  }),
  value => value
);
