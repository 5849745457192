import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';

import EventHoursInputOptions from './EventHoursInputOptions';
import { DURATION_TYPES } from 'v1/helpers/byModel/EventHelper';

import { Popover, PopoverTrigger, PopoverContent } from 'v1/components/shared';

import './EventHoursInput.scss';

const EventHoursInput = props => {
  const handleChange = (field, value, previous) =>
    props.onChange(field, value, previous);

  const getHoursLabel = _ =>
    props.date_type === DURATION_TYPES.RECURRING_DEFAULT
      ? 'All day'
      : props.date_type === DURATION_TYPES.RECURRING_HOURS
      ? getRecurringHoursLabel()
      : props.date_type === DURATION_TYPES.RECURRING_SPECIFIC_HOURS &&
        props.estimated_start_time &&
        props.estimated_end_time
      ? moment(props.estimated_start_time, 'HH:mm:ss').format('h:mma') +
        ' - ' +
        moment(props.estimated_end_time, 'HH:mm:ss').format('h:mma')
      : '';

  const getRecurringHoursLabel = _ => {
    const mins = props.estimated_daily_minutes
      ? JSON.stringify(props.estimated_daily_minutes)
      : '';
    return props.estimated_daily_hours
      ? props.estimated_daily_hours + 'h' + mins + ' / day'
      : '';
  };

  return (
    <div
      className={classnames([
        'EventHoursInput',
        {
          [`EventHoursInput-${props.size}`]: props.size,
          [`EventHoursInput-${props.appearance}`]: props.appearance
        },
        props.className
      ])}
    >
      <Popover>
        <div
          className={classnames(
            { 'text-13-700': props.size === 'S' },
            { 'emulate-h3': props.size === 'M' }
          )}
        >
          <label className="form-label">Hours</label>
          <PopoverTrigger disabled={props.disabled}>
            <input
              className={classnames('EventHoursInput-input', {
                [`EventHoursInput-input-${props.size}`]: props.size,
                [`input-${props.appearance}`]: props.appearance
              })}
              type="text"
              readOnly
              disabled={props.disabled}
              value={getHoursLabel()}
            />
          </PopoverTrigger>
        </div>

        <PopoverContent className="EventHoursInput_PopoverContent">
          <EventHoursInputOptions
            handleChange={handleChange}
            durationType={props.date_type}
            estimatedDailyHours={props.estimated_daily_hours}
            estimatedDailyMinutes={props.estimated_daily_minutes}
            estimatedStartTime={props.estimated_start_time}
            estimatedEndTime={props.estimated_end_time}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const TypeEventHours = {
  date_type: PropTypes.oneOf([
    DURATION_TYPES.RECURRING_DEFAULT,
    DURATION_TYPES.RECURRING_HOURS,
    DURATION_TYPES.RECURRING_SPECIFIC_HOURS,
    DURATION_TYPES.INCLUSIVE_HOURS
  ]),
  estimated_daily_hours: PropTypes.number, // Only for RECURRING_HOURS
  estimated_daily_minutes: PropTypes.number, // Only for RECURRING_HOURS
  estimated_start_time: PropTypes.any, // '09:00:00' / Only for RECURRING_SPECIFIC_HOURS
  estimated_end_time: PropTypes.any // '14:00:00' / Only for RECURRING_SPECIFIC_HOURS
};

EventHoursInput.propTypes = {
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'silent']),
  size: PropTypes.oneOf(['XS', 'S', 'M']),
  disabled: PropTypes.bool,
  ...TypeEventHours,
  onChange: PropTypes.func
};

EventHoursInput.defaultProps = {
  estimated_daily_hours: 8,
  estimated_daily_minutes: 0
};

export default EventHoursInput;
