const SYSTEM_ERRORS = {
  'Validation failed':
    'Please check your fields some may be incorrect or invalid',
  'Invalid body': 'Please check your fields some may be incorrect or invalid',
  'Internal server error': 'Something went wrong',
  'Internal Server Error': 'Something went wrong',
  NotFoundError: 'We cannot find your account',
  'trying to upsert an unallowed relation':
    'trying to upsert an unallowed relation'
};

export default error => {
  if (error.includes('Request has been terminated')) {
    return SYSTEM_ERRORS['Internal server error'];
  }
  return SYSTEM_ERRORS[error];
};
