// @ts-nocheck
import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { PressStud } from 'v1/components/shared/buttons';
import { Textarea } from 'v1/components/shared/byType/text';

interface ShareCallsheetNoteProps {
  value: string | null;
  onChange: Function;
  className?: string;
}

export const ShareCallsheetNote = (props : ShareCallsheetNoteProps) => {
  const [editable, setEditable] = useState<boolean>(false);

  const toggleEditable = () => setEditable(!editable);

  const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  }

  return (
    <div className={classNames(props.className)}>
      {editable ? (
        <Textarea name="note"
                  value={props.value}
                  placeholder="Please leave a note for all recipients"
                  onChange={handleChange} />
      ) : (
        <PressStud
          label="Attach note"
          icon="addBlack"
          size="S"
          action={toggleEditable}
        />
      )}
    </div>
  );
};
