import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'v1/components/legacy/CSS/Form.scss';

class DEPRECATED_TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = { focused: false, value: props.value };
  }
  componentDidMount = () => {
    if (this.props.initialFocus) {
      this.input.focus();
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  };
  onChange = e => {
    let value = e.target.value;
    if (this.props.type === 'number') value = parseFloat(value);
    this.setState({ value }, () => {
      this.props.onChange && this.props.onChange(this.props.field, value);
    });
  };

  onPaste = e =>
    this.props.onPaste && this.props.onPaste(this.props.field, e.target.value);

  onFocus = e =>
    this.setState({ focused: true }, () => {
      let value = this.input.value;
      if (this.props.highlightOnFocus) {
        this.input.select();
      }
      this.props.onFocus && this.props.onFocus(this.props.field, value);
    });

  onBlur = () =>
    this.setState({ focused: false }, () => {
      this.props.onBlur &&
        this.props.onBlur(this.props.field, this.state.value);
    });

  onKeyUp = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return this.props.onEnter && this.props.onEnter();
    }
    if (event.keyCode === 27) {
      event.preventDefault();
      return this.props.onEscape && this.props.onEscape();
    }
    if (event.keyCode === 38) {
      event.preventDefault();
      return this.props.onUpArrow && this.props.onUpArrow();
    }
    if (event.keyCode === 40) {
      event.preventDefault();
      return this.props.onDownArrow && this.props.onDownArrow();
    }
    return this.props.onKeyUp && this.props.onKeyUp();
  };

  // TODO: Take this out of text input and turn into a useful abstracted component

  renderClickableElement = () => {
    const { clickAction, clickActionLabel, clickMethod, value } = this.props;
    if (!this.state.focused && clickAction && clickActionLabel) {
      if (clickMethod === 'ACTION') {
        return (
          <span className="TextInput-clickable">
            <a className="trunc" onClick={clickAction} title={clickActionLabel}>
              {clickActionLabel || value}
            </a>
          </span>
        );
      } else {
        return (
          <span className="TextInput-clickable">
            <a
              className="trunc"
              href={clickAction}
              target="_blank"
              rel="noopener noreferrer"
              title={clickActionLabel}
            >
              {clickActionLabel || value}
            </a>
          </span>
        );
      }
    }
  };
  render() {
    const { value } = this.state;

    return (
      <div className="TextInput">
        {this.props.label ? (
          <label
            className={classNames([
              'form-label'
              // { required: this.props.error }
            ])}
          >
            {this.props.label}
            {this.props.showOptional ? <i>optional</i> : null}
          </label>
        ) : null}
        <input
          ref={input => {
            this.input = input;
          }}
          id={this.props.field}
          field={this.props.field}
          value={value || ''}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          type={this.props.type || 'text'}
          onMouseDown={e =>
            this.props.stopPropagation ? e.stopPropagation() : false
          }
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          onPaste={this.onPaste}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          style={this.props.style}
          className={classNames({
            'form-control required': !this.props.removeDefaultStyle,
            cleanInput: this.props.removeDefaultStyle,
            invalid: this.props.error,
            focused: this.state.focused,
            [this.props.className]: this.props.className
          })}
          autoComplete={this.props.removeAutocomplete ? 'off' : 'on'}
          disabled={this.props.disabled}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
        />
        {this.renderClickableElement()}
        {this.props.error ? (
          <span className="formErrorLabel">{this.props.error}</span>
        ) : null}
      </div>
    );
  }
}

export const TextInputPropTypes = {
  // Presentation properties
  className: PropTypes.string,
  style: PropTypes.any, // TODO: type
  removeDefaultStyle: PropTypes.bool,
  showOptional: PropTypes.bool,

  // Functional properties
  removeAutocomplete: PropTypes.bool,
  initialFocus: PropTypes.bool,
  highlightOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  // Value properties
  type: PropTypes.string, // TODO: check types: PropTypes.oneOf(['text', 'number']),
  field: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any, // TODO: type
  defaultValue: PropTypes.any, // TODO: type
  error: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.any, // TODO: type

  // Actions
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  onEscape: PropTypes.func,
  onUpArrow: PropTypes.func,
  onDownArrow: PropTypes.func,
  onKeyUp: PropTypes.func,
  stopPropagation: PropTypes.func,

  // Click action
  clickAction: PropTypes.any, // TODO: type
  clickActionLabel: PropTypes.string,
  clickMethod: PropTypes.string
};

DEPRECATED_TextInput.propTypes = TextInputPropTypes;

export default DEPRECATED_TextInput;
