import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSocketEvent } from "v4/core/useSocket";
import {
  processEventForResourceShortlistAssociations
} from "v4/entities/associations/resource__shortlist/resource__shortlist.cache";
import { processEventForShortlists } from "v4/entities/shortlist/shortlist.cache";

/**
 * This hook will listen to socket events for any **external** changes and will update the refresh the queries accordingly.
 */
export function useSocketQueryUpdater(sessionId: unknown) {
  const client = useQueryClient();
  useSocketEvent(
    useCallback(
      event => {
        if (event.payload?.sessionId === undefined) {
          // what to do with events that we don't know are ours or not?
        } else if (event.payload?.sessionId !== sessionId) {
          // events caused by other users
          processEventForShortlists(event, client);
          processEventForResourceShortlistAssociations(event, client);
        } else {
          // events caused by us, including initiated in legacy redux part
          // todo: we don't have enough booking information in the event object that could invalidate only relevant queries
          // leaving this code as a placeholder for future improvements
          // if (event.type === 'BOOKING_UPDATED') {
          //   // hack for booking
          //   client.removeQueries({
          //     type: 'inactive',
          //     queryKey: bookingKeys.all()
          //   });
          //   client.invalidateQueries({
          //     queryKey: bookingKeys.all()
          //   });
          // }
        }
      },
      [client]
    )
  );
}

export function SocketQueryUpdater({ sessionId }: { sessionId: unknown }) {
  useSocketQueryUpdater(sessionId);
  return null;
}
