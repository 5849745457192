//
// Helpers for the Status object
//

// TODO: tests

const statusTypesByGroup = {
  availability: ['AVAILABLE', 'UNAVAILABLE', 'HOLD', 'RELEASED'],
  booking: ['CONFIRMED', 'PENCILLED', 'CANCELLED', 'EDITING'],
  production: [
    'OPEN',
    'RESOURCING',
    'ALERT',
    'READY',
    'ACTIVE',
    'COMPLETE',
    'CANCELLED'
  ]
};

/**
 * @function isAvailabilityStatus
 * Determine if a status is an availability status
 *
 * @param {Object} status - [Status] - The status
 */
export const isAvailabilityStatus = (status = {}) =>
  statusTypesByGroup['availability'].includes(status.status_type);

/**
 * @function isBookingStatus
 * Determine if a status is a booking status
 *
 * @param {Object} status - [Status] - The status
 */
export const isBookingStatus = (status = {}) =>
  statusTypesByGroup['booking'].includes(status.status_type);

/**
 * @function isProductionStatus
 * Determine if a status is a production status
 *
 * @param {Object} status - [Status] - The status
 */
export const isProductionStatus = (status = {}) =>
  statusTypesByGroup['production'].includes(status.status_type);
