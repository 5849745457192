import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBlock } from 'v1/components/shared';

const ErrorContainer = ({ error }) => (
  <div className="ErrorContainer">
    <ErrorBlock error={error} fullpage />
  </div>
);

ErrorContainer.props = {
  error: PropTypes.any // TODO: type
};

export default ErrorContainer;
