export enum CapabilityEnum {
  CONFIGURE_ROLES = 'configure_roles',
  CONFIGURE_TEAM = 'configure_team',
  CONFIGURE_AUTHENTICATION_METHODS = 'configure_authentication_methods',
  CONFIGURE_TAGS = 'configure_tags',
  CONFIGURE_BOOKING_TYPES = 'configure_booking_types',
  CONFIGURE_EXPENSE_TYPES = 'configure_expense_types',
  CONFIGURE_RESOURCE_TYPES = 'configure_resource_types',
  CONFIGURE_PRODUCTION_TYPES = 'configure_production_types',
  CONFIGURE_PRODUCTION_TEMPLATES = 'configure_production_templates',
  CONFIGURE_PRODUCTION_BUDGET = 'configure_production_budget',
  CONFIGURE_ALERTS = 'configure_alerts',
  CONFIGURE_BOOKING_CONFIRMATION_TEMPLATES = 'configure_booking_confirmation_templates',
  CONFIGURE_CALLSHEET_TEMPLATES = 'configure_callsheet_templates',
  CONFIGURE_REPORT_TEMPLATES = 'configure_report_templates',
  CONFIGURE_RATE_CARDS = 'configure_rate_cards',
  CONFIGURE_SLOT_CATEGORIES = 'configure_slot_categories',
  CONFIGURE_FIELDS = 'configure_fields',
  CONFIGURE_STATUSES = 'configure_statuses',
  CONFIGURE_BRANDING = 'configure_branding',
  CONFIGURE_WORKSPACE = 'configure_workspace',

  MANAGE_DATA_IMPORTS = 'manage_data_imports',
  MANAGE_RESOURCE_DUPLICATES = 'manage_resource_duplicates',
  MANAGE_RECONCILIATION = 'manage_reconciliation',

  RESOURCE_READ = 'resource_read',
  RESOURCE_CREATE = 'resource_create',
  RESOURCE_UPDATE = 'resource_update',
  RESOURCE_ARCHIVE = 'resource_archive',

  SHORTLIST_READ = 'shortlist_read',
  SHORTLIST_CREATE = 'shortlist_create',
  SHORTLIST_UPDATE = 'shortlist_update',
  SHORTLIST_ARCHIVE = 'shortlist_archive',
  SHORTLIST_PUBLISH = 'shortlist_publish',

  CALLOUT_READ = 'callout_read',
  CALLOUT_CREATE = 'callout_create',
  CALLOUT_UPDATE = 'callout_update',
  CALLOUT_ARCHIVE = 'callout_archive',
  CALLOUT_PUBLISH = 'callout_publish',

  CALLSHEET_READ = 'callsheet_read',
  CALLSHEET_CREATE = 'callsheet_create',
  CALLSHEET_UPDATE = 'callsheet_update',
  CALLSHEET_ARCHIVE = 'callsheet_archive',
  CALLSHEET_SEND = 'callsheet_send',

  PRODUCTION_READ = 'production_read',
  PRODUCTION_BUDGET_READ = 'production_budget_read',
  PRODUCTION_CREATE = 'production_create',
  PRODUCTION_UPDATE = 'production_update',
  PRODUCTION_ARCHIVE = 'production_archive',

  BOOKING_READ = 'booking_read',
  BOOKING_CREATE = 'booking_create',
  BOOKING_SEND_CONFIRMATION = 'booking_send_confirmation',
  BOOKING_UPDATE = 'booking_update',
  BOOKING_ARCHIVE = 'booking_archive',

  RATE_READ = 'rate_read',
  RATE_CREATE = 'rate_create',
  RATE_UPDATE = 'rate_update',
  RATE_ARCHIVE = 'rate_archive',

  INVOICE_READ = 'invoice_read',
  INVOICE_CREATE = 'invoice_create',
  INVOICE_UPDATE = 'invoice_update',
  INVOICE_ARCHIVE = 'invoice_archive',

  REPORT_DOWNLOAD = 'report_download',

  EXPENSE_READ = 'expense_read',
  EXPENSE_CREATE = 'expense_create',
  EXPENSE_UPDATE = 'expense_update',
  EXPENSE_ARCHIVE = 'expense_archive',

  FILE_READ = 'file_read',
  FILE_CREATE = 'file_create',
  FILE_UPDATE = 'file_update',
  FILE_ARCHIVE = 'file_archive',

  AVAILABILITY_READ = 'availability_read',
  AVAILABILITY_CREATE = 'availability_create',
  AVAILABILITY_UPDATE = 'availability_update',
  AVAILABILITY_ARCHIVE = 'availability_archive',

  USER_GROUP_READ = 'user_group_read',
  USER_GROUP_CREATE = 'user_group_create',
  USER_GROUP_UPDATE = 'user_group_update',
  USER_GROUP_ARCHIVE = 'user_group_archive',

  COLLECTION_READ = 'collection_read',
  COLLECTION_CREATE = 'collection_create',
  COLLECTION_UPDATE = 'collection_update',
  COLLECTION_ARCHIVE = 'collection_archive',

  CONFIGURE_CUSTOM_NIKE_ARRIVAL_BOARD = 'customnikearrivalboard',
  CONFIGURE_CUSTOM_NIKE_FRONT_DESK = 'configure_customnikefrontdesk',
  CONFIGURE_CUSTOM_NIKE_QR_CODE = 'configure_customnikeqrcode',

  CONFIGURE_INTEGRATIONS = 'configure_integrations',
  DOCUSIGN_SEND = 'docusign_send'
}
