import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Sortable from 'react-sortablejs';
import { get } from 'lodash';

import TableContext from './TableContext';
import TableRow from './TableRow';
import TableRowAdd from './TableRowAdd';
import styles from './Table.module.scss';

const TableRowset = ({ groupId, parent, rows }) => {
  const { onOrderChange, subRows, prepareRow } = useContext(TableContext);

  parent && prepareRow(parent);

  const renderRow = row => {
    if (row.subRows && row.subRows.length) {
      return (
        <TableRowset
          key={row.id}
          parent={parent ? { ...row, parent } : row}
          rows={row.subRows}
        />
      );
    }
    return <TableRow key={row.id} parent={parent} row={row} />;
  };

  const renderRows = () => {
    return (
      <>
        {rows.map(row => {
          // GROUPING
          if (get(row, 'original._type') === 'GROUP') {
            return null;
          }
          if (!parent) {
            const subRow = Array.isArray(subRows) ? subRows[0] : subRows;
            const sortId = subRow ? row.values[subRow] : row.id;
            return (
              <div
                key={row.id}
                data-sortable="rowset"
                data-id={`${row.original.type}-${sortId}`}
              >
                {renderRow(row)}
              </div>
            );
          }
          return renderRow(row);
        })}
        {!parent && <TableRowAdd groupId={groupId} />}
      </>
    );
  };

  const renderSortableRows = () => (
    <Sortable
      options={{
        group: 'rows',
        draggable: '[data-sortable=rowset]',
        handle: '.TableHandle',
        animation: 150,
        ghostClass: styles.ghostClass,
        dragClass: styles.dragClass
      }}
      onChange={order => onOrderChange(order, groupId)}
    >
      {renderRows()}
    </Sortable>
  );

  return onOrderChange && !parent ? renderSortableRows() : renderRows();
};

TableRowset.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  parent: PropTypes.object,
  rows: PropTypes.array
};

export default TableRowset;
