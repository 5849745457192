import React from 'react';
import classnames from 'classnames';

import styles from './Grid.module.scss';

type GridProps = {
  /**
   * Set the Grid height to 100% of its container
   */
  fullHeight?: boolean;
  /**
   * Direction of the grid cells flow
   */
  direction?: 'row' | 'column';
  /**
   * Whether the grid cells wrap on overflow
   */
  wrap?: boolean;
  /**
   * Horizontal alignment of the grid cells
   */
  align?: 'left' | 'right' | 'center' | 'spaced';
  /**
   * Vertical alignment of the grid cells
   */
  valign?: 'top' | 'bottom' | 'center' | 'stretch';
  /**
   * Horizontal space between grid cells
   */
  gutters?: CSSSize;
  /**
   * Vertical space between grid cells
   */
  vgutters?: CSSSize;
  /**
   * Vertical space between grid cells
   */
  overflow?: 'auto' | 'hidden' | 'visible' | 'scroll' | 'initial' | 'inherit';
  /**
   * Default padding inside grid cells
   */
  cellPadding?: CSSSize;
  children: React.ReactNode;
};

const Grid = ({
  fullHeight = false,
  direction = 'row',
  wrap = false,
  align = 'left',
  valign = 'stretch',
  gutters = 'zero',
  vgutters = 'zero',
  cellPadding = 'zero',
  overflow = 'visible',
  children
}: GridProps) => (
  <div
    className={classnames(styles.Grid, {
      [styles.fullHeight]: fullHeight,
      [styles.column]: direction === 'column',
      [styles.wrap]: wrap,
      [styles[`cellPadding_${cellPadding}`]]: cellPadding !== 'zero',
      [styles[`gutters_${gutters}`]]: gutters !== 'zero',
      [styles[`vgutters_${vgutters}`]]: vgutters !== 'zero',
      [styles[`align_${align}`]]: align !== 'left',
      [styles[`valign_${valign}`]]: valign !== 'stretch'
    })}
    style={{ overflow }}
  >
    {children}
  </div>
);

export default Grid;
