import { PRODUCTION_TYPES_ACTIONS } from 'store/v1/production_types/production_types.constants.js';
import { pick } from 'lodash';

const token = true;

export const getProductionTypes = (event_id = null) => {
  return {
    types: [
      PRODUCTION_TYPES_ACTIONS.GET_PRODUCTION_TYPES,
      PRODUCTION_TYPES_ACTIONS.GET_PRODUCTION_TYPES_SUCCESS,
      PRODUCTION_TYPES_ACTIONS.GET_PRODUCTION_TYPES_FAILURE
    ],
    promise: client => client.get('/production_types', { token }),
    event_id
  };
};

export const createProductionType = (data, event_id = null) => {
  return {
    types: [
      PRODUCTION_TYPES_ACTIONS.CREATE_PRODUCTION_TYPE,
      PRODUCTION_TYPES_ACTIONS.CREATE_PRODUCTION_TYPE_SUCCESS,
      PRODUCTION_TYPES_ACTIONS.CREATE_PRODUCTION_TYPE_FAILURE
    ],
    promise: client => client.post('/production_types', { data, token }),
    event_id
  };
};

export const updateProductionType = (id, data, event_id = null) => {
  const payload = pick(data, ['id', 'name', 'order', 'metastructure']);
  return {
    types: [
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE,
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE_SUCCESS,
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE_FAILURE
    ],
    promise: client =>
      client.put(`/production_types/${id}`, {
        data: payload,
        token
      }),
    event_id
  };
};

export const updateProductionTypeOrder = (data, event_id = null) => {
  const payload =
    data &&
    data.map(productionType => ({
      id: productionType.id,
      order: productionType.order
    }));
  return {
    types: [
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE_ORDER,
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE_ORDER_SUCCESS,
      PRODUCTION_TYPES_ACTIONS.UPDATE_PRODUCTION_TYPE_ORDER_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/production_types`, {
        data: { reorder: payload },
        token
      }),
    event_id
  };
};

export const deleteProductionType = (id, event_id = null) => {
  return {
    types: [
      PRODUCTION_TYPES_ACTIONS.DELETE_PRODUCTION_TYPE,
      PRODUCTION_TYPES_ACTIONS.DELETE_PRODUCTION_TYPE_SUCCESS,
      PRODUCTION_TYPES_ACTIONS.DELETE_PRODUCTION_TYPE_FAILURE
    ],
    promise: client => client.del(`/production_types/${id}`, { token }),
    event_id,
    id
  };
};
