import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Cookies from 'universal-cookie';

import CreateAccount from './CreateAccount/CreateAccount';
import CreateProfile from './CreateProfile/CreateProfile';
import CompletedRegistration from './CompletedRegistration/CompletedRegistration';
import { isTokenAlive, decodeAndReadToken } from 'lib/auth/tokenOperations';
import useGetTokenFromSearchQueryParams from 'v1/helpers/hooks/useGetTokenFromSearchQueryParams';

export const ONBOARDING = 'atellioonboarding';

const UserOnboarding = () => {
  let match = useRouteMatch();
  let pathname = match.url;

  const querytoken = useGetTokenFromSearchQueryParams('token');
  const cookies = new Cookies();

  //aggressively clearing the cookie
  cookies.remove(ONBOARDING);
  cookies.set(ONBOARDING, querytoken);

  const { token } = decodeAndReadToken(querytoken);
  const isAlive = isTokenAlive(token);

  if (!isAlive) return <Redirect to="/login" />;

  return <Redirect to={`${pathname}/create-account`} />;
};

const routesConfig = pathname => [
  {
    name: 'onboarding',
    to: `${pathname}/`
  },
  {
    name: 'create-account',
    to: `${pathname}/create-account`
  },
  {
    name: 'create-profile',
    to: `${pathname}/create-profile`
  },
  {
    name: 'completed-registration',
    to: `${pathname}/completed-registration`
  }
];

const routeComponents = {
  onboarding: UserOnboarding,
  'create-account': CreateAccount,
  'create-profile': CreateProfile,
  'completed-registration': CompletedRegistration
};

const UserOnboardingRoute = () => {
  let match = useRouteMatch();
  let pathname = match.url;
  const routesConfiguration = routesConfig(pathname);

  return (
    <Switch>
      {routesConfiguration.map((routeConfig, index) => {
        const Component = routeComponents[routeConfig.name];
        return (
          <Route
            exact
            key={`${routeConfig.name}-${index}`}
            path={routeConfig.to}
            render={props => <Component {...props} />}
          />
        );
      })}
    </Switch>
  );
};

export default UserOnboardingRoute;
