import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Select } from 'v1/components/shared';
import styles from '../../TextInputGroup.module.scss';

const TextInputGroupOptions = ({ type, options = [], onChange, ...props }) => {
  return (
    <Select
      className={classnames([
        styles.Pend,
        styles[`Pend_${type}`],
        styles.Pend_options,
        styles.clickable
      ])}
      onChange={({ target }) => onChange(target.value)}
      {...props}
    >
      {options.map(option => (
        <option key={option.value || option} value={option.value || option}>
          {option.label || option}
        </option>
      ))}
    </Select>
  );
};

TextInputGroupOptions.propTypes = {
  appearance: PropTypes.oneOf(['outline', 'silent', 'underline']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  type: PropTypes.oneOf(['prepend', 'append']), // Whether the dropdown is before or after the text input
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
      })
    ])
  ),
  onChange: PropTypes.func
  // ...props - standard form input props
};

export default TextInputGroupOptions;
