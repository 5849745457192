import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { getBookingsStatuses } from 'store/v1/statuses/statuses.selectors.js';

import '@fullcalendar/react/dist/vdom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import ReactTooltip from 'react-tooltip';

import classnames from 'classnames';
import moment from 'moment-timezone';

import './BigCalendar.scss';
import 'styles/v1/FullCalendar.scss';

export const convertDateToDateTime = (date, isEndOfDay) => {
  const dateM = moment.tz(date, moment().tz());

  if (isEndOfDay) {
    dateM.subtract(1, 'days');
  }
  return dateM.format('YYYY-MM-DD');
};

export const BigCalendar = props => {
  const bookingStatuses = useSelector(getBookingsStatuses);
  const users = useSelector(state => state.accounts.data);

  const [activeStatus, setActiveStatus] = useState();
  const { events, resourceName } = props;

  function getUserName(userId) {
    if (userId && users) {
      const user = users[userId];
      if (user) {
        return `by ${user.displayName}`;
      }
    }
    return resourceName ? `by ${resourceName}` : ''; // if lastUpdateUserId is null, use we can assume it was updated by the resource in the availability portal
  }

  return (
    <>
      <div className={classnames(['FullCalendar', { drawable: activeStatus }])}>
        <FullCalendar
          selectable
          editable
          initialView="dayGridMonth"
          windowResize={({ view }) => {
            view.calendar.updateSize();
          }}
          windowResizeDelay={500}
          fixedWeekCount={false}
          timeZone={moment().tz()}
          plugins={[dayGridPlugin, interactionPlugin, momentTimezonePlugin]}
          events={events}
          eventClick={({ event = {} }) => {
            props.onSelectEvent && props.onSelectEvent(event.extendedProps);
          }}
          datesSet={({ view = {} }) => {
            props.onRangeChange({
              start: view.activeStart,
              end: view.activeEnd
            });
          }}
          buttonText={{
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day',
            list: 'List'
          }}
          eventResize={({ event = {} }) => {
            props.onEventDrop({
              event: {
                id: parseFloat(event.id),
                ...event.extendedProps
              },
              start: convertDateToDateTime(event.start),
              end: convertDateToDateTime(event.end, true)
            });
          }}
          eventDrop={({ event = {} }) => {
            props.onEventDrop({
              event: {
                id: parseFloat(event.id),
                ...event.extendedProps
              },
              start: convertDateToDateTime(event.start),
              end: convertDateToDateTime(event.end, true)
            });
          }}
          select={({ startStr, endStr }) => {
            props.onSelectSlot(activeStatus, {
              start: startStr,
              end: moment(endStr).subtract(1, 'days').format('YYYY-MM-DD')
            });
          }}
          eventDidMount={event => {
            event.el.setAttribute(
              'data-tip',
              `Updated ${getUserName(
                event.event.extendedProps.lastUpdateUserId
              )} ${moment(event.event.extendedProps.updated_at).fromNow()}`
            );
            ReactTooltip.rebuild();
          }}
        />
      </div>
      <div className="BigCalendar-footer">
        <div className="BigCalendar-section">
          <h5 className="text-13-700 stack-S">
            {activeStatus
              ? `Drawing ${activeStatus.name}`
              : 'Select a status to draw onto the calendar'}
          </h5>
          {bookingStatuses.map(s => (
            <div
              className={classnames(['BigCalendar-footer-item'], {
                active: activeStatus && activeStatus.id === s.id
              })}
              key={s.id}
              onClick={() => {
                if (activeStatus === s) {
                  setActiveStatus(null);
                } else {
                  setActiveStatus(s);
                }
              }}
            >
              <div style={{ backgroundColor: s.colour }} />
              <span>{s.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

BigCalendar.propTypes = {
  events: PropTypes.array,
  timezone: PropTypes.string,
  onSelectSlot: PropTypes.func,
  onRangeChange: PropTypes.func,
  onSelectEvent: PropTypes.func
};

export default BigCalendar;
