import { BATCH_ACTIONS } from 'store/v1/batch/batch.constants.js';

const INITIAL_STATE = {
  exporting: false,
  deleting: false,
  error: false,
  uploading: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BATCH_ACTIONS.BATCH_EXPORT:
      return {
        ...state,
        exporting: true,
        error: false
      };
    case BATCH_ACTIONS.BATCH_EXPORT_SUCCESS:
      return {
        ...state,
        exporting: false,
        error: false
      };
    case BATCH_ACTIONS.BATCH_EXPORT_FAILURE:
      return {
        ...state,
        exporting: false,
        error: action.result
      };
    case BATCH_ACTIONS.CSV_IMPORT_REQUEST:
      return {
        ...state,
        uploading: true,
        error: false
      };
    case BATCH_ACTIONS.CSV_IMPORT_REQUEST_SUCCESS:
      return {
        ...state,
        uploading: false,
        error: false
      };
    case BATCH_ACTIONS.CSV_IMPORT_REQUEST_FAILURE:
      return {
        ...state,
        uploading: false,
        error: action.result
      };
    default:
      return state;
  }
}
