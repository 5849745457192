import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ResourceBadge.scss';

import { Grid, ResourceBadgeIcon } from 'v1/components/shared';

// TODO: check this HTML/CSS
const ResourceBadge = ({ className, resource }) => (
  <Grid className={classnames(['ResourceBadge', className])} vcenter>
    <ResourceBadgeIcon resource={resource} />
    <div className="text-13-700">{resource.full_name}</div>
  </Grid>
);

ResourceBadge.propTypes = {
  className: PropTypes.string,
  resource: PropTypes.object // TODO: type
};

export default ResourceBadge;
