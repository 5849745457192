import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  LinkUploader,
  FileUploader,
  GoogleDriveUploader,
  Button,
  PressStud
} from 'v1/components/shared';

import classnames from 'classnames';
import clickOutside from 'v1/helpers/clickOutsideDecorator';

import './MultiMediaUploader.scss';

const UPLOAD_METHODS = {
  computer: {
    text: 'Computer',
    icon: '/images/icon_computer_upload.svg'
  },
  url: {
    text: 'Link (URL)',
    icon: '/images/icon_share_link.svg'
  },
  google: {
    text: 'Google Drive',
    icon: '/images/icon_upload_gdrive.svg'
  },
  dropbox: {
    text: 'Dropbox',
    icon: '/images/icon_upload_gdrive.svg'
  }
};

export class MultiMediaUploader extends Component {
  static propTypes = {
    secure: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      uploadType: props.uploadMethods[0]
    };
  }
  onClickOutside = () => {
    if (this.state.showMediaUpload) {
      return this.setState({ showMediaUpload: false });
    }
  };
  renderUploadTab = () => {
    const { uploadMethods } = this.props;

    const uploadTabs = uploadMethods.map(method => {
      return (
        <div
          key={method}
          onClick={() => {
            this.setState({ uploadType: method });
          }}
          className={classnames({ active: this.state.uploadType === method }, [
            'MultiMediaUploader-tab'
          ])}
        >
          <span className="MultiMediaUploader-tab-icon">
            <img src={UPLOAD_METHODS[method].icon} width="15px" alt="" />
          </span>
          <span className="MultiMediaUploader-tab-label">
            {UPLOAD_METHODS[method].text}
          </span>
        </div>
      );
    });
    return <div className="MultiMediaUploader-tabs">{uploadTabs}</div>;
  };
  renderUploadType = () => {
    let uploader;
    switch (this.state.uploadType) {
      case 'url':
        uploader = (
          <LinkUploader
            uploading={this.props.uploading}
            label="Paste a link to upload"
            placeholder="http://"
            className="MultiMediaUploader-url"
            onLinkUpload={({ provider, files }) =>
              this.props.onUpload('URL', {
                url: files && files[0],
                provider: provider
              })
            }
          />
        );
        break;
      case 'computer':
        uploader = (
          <FileUploader
            className="MultiMediaUploader-computer"
            onDrop={this.props.onFileUpload}
            onChange={this.props.onFileUpload}
            onFileCompleted={this.props.onFileCompleted}
            secure={this.props.secure}
            multiple
          />
        );
        break;
      case 'google':
        uploader = (
          <GoogleDriveUploader
            onSelect={file => {
              this.props.onUpload('GOOGLE', file);
            }}
            className="MultiMediaUploader-provider"
            initiateOnMount
          />
        );
        break;
      default:
        uploader = <span />;
    }
    return <div className="MultiMediaUploader-uploadType">{uploader}</div>;
  };
  renderToggle = () => {
    const { showMediaUpload } = this.state;
    const { buttonLabel, buttonClass, buttonIcon } = this.props;
    return (
      <Button
        dataCy="add-media"
        className={classnames({ open: showMediaUpload }, buttonClass)}
        onClick={() => {
          this.setState({
            showMediaUpload: !showMediaUpload
          });
        }}
      >
        {showMediaUpload ? (
          'DONE'
        ) : (
          <span className="inline">
            {buttonIcon && (
              <img src={buttonIcon} className="icon" width="12px" alt="" />
            )}{' '}
            {buttonLabel || 'Add'}
          </span>
        )}
      </Button>
    );
  };
  renderHeader = () => {
    const { title } = this.props;

    if (title) {
      return (
        <div className="MultiMediaUploader-header">
          <h5 className="MultiMediaUploader-header-title">{title}</h5>
          <div className="MultiMediaUploader-header-action">
            <PressStud
              label="Done"
              appearance="secondary"
              size="S"
              dataCy="button-uploader-done"
              action={() => {
                this.setState({
                  showMediaUpload: !this.state.showMediaUpload
                });
              }}
            />
          </div>
        </div>
      );
    }
  };
  render() {
    return (
      <div className="MultiMediaUploader">
        {this.renderToggle()}
        {this.state.showMediaUpload && (
          <div className="MultiMediaUploader-dropdown">
            {this.renderHeader()}
            {this.renderUploadTab()}
            <div className="MultiMediaUploader-content">
              {this.renderUploadType()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default clickOutside(MultiMediaUploader);
