import React from 'react';
import PropTypes from 'prop-types';

import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import { TextInput } from 'v1/components/shared';

interface CurrencyAmountInputProps
  extends Omit<TextInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: number | null;
  onChange?(value: number | null): void;
  onBlur?(value: number | null): void;
}

const CurrencyAmountInput = ({
  value,
  onChange,
  onBlur,
  ...rest
}: CurrencyAmountInputProps) => {
  const parseValue = (v: number | null | undefined): string | null => {
    if (v === null || v === undefined) {
      return null;
    }
    const str = v.toString().padStart(2, '0');
    return (
      str.substring(0, str.length - 2) +
      '.' +
      str.substring(str.length - 2)
    ).padStart(4, '0');
  };

  const formatValue = (v: string): number | null => {
    return v === '' ? null : parseInt((Number(v) * 100).toFixed(0));
  };

  const handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(formatValue(value));
  };

  const handleBlur = (value: string): void => {
    onBlur && onBlur(formatValue(value));
  };

  return (
    <TextInput
      {...rest}
      type="number"
      step="0.01"
      value={parseValue(value)}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

CurrencyAmountInput.propTypes = {
  children: PropTypes.node
};

export default CurrencyAmountInput;
