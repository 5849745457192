import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const BOOKING_ACTIONS = mapValues(
  mirror({
    GET_BOOKINGS: null,
    GET_BOOKINGS_SUCCESS: null,
    GET_BOOKINGS_FAILURE: null,

    GET_BOOKINGS_ONLY: null,
    GET_BOOKINGS_ONLY_SUCCESS: null,
    GET_BOOKINGS_ONLY_FAILURE: null,

    GET_BOOKING: null,
    GET_BOOKING_SUCCESS: null,
    GET_BOOKING_FAILURE: null,

    CREATE_BOOKING: null,
    CREATE_BOOKING_SUCCESS: null,
    CREATE_BOOKING_FAILURE: null,

    UPDATE_BOOKING: null,
    UPDATE_BOOKING_SUCCESS: null,
    UPDATE_BOOKING_FAILURE: null,

    DELETE_BOOKING: null,
    DELETE_BOOKING_SUCCESS: null,
    DELETE_BOOKING_FAILURE: null,

    GET_BATCH_BOOKINGS: null,
    GET_BATCH_BOOKINGS_SUCCESS: null,
    GET_BATCH_BOOKINGS_FAILURE: null,

    CREATE_INVOICES: null,
    CREATE_INVOICES_SUCCESS: null,
    CREATE_INVOICES_FAILURE: null,

    UPDATE_INVOICE: null,
    UPDATE_INVOICE_SUCCESS: null,
    UPDATE_INVOICE_FAILURE: null,

    DELETE_INVOICE: null,
    DELETE_INVOICE_SUCCESS: null,
    DELETE_INVOICE_FAILURE: null,

    SEND_BOOKING_CONFIRMATION: null,
    SEND_BOOKING_CONFIRMATION_SUCCESS: null,
    SEND_BOOKING_CONFIRMATION_FAILURE: null
  }),
  value => value
);
