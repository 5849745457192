import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Confetti from 'react-dom-confetti';

import {
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  PressStud
} from 'v1/components/shared';

import './SuccessFeedbackModal.scss';

const SuccessFeedbackModal = ({ onRequestClose }) => {
  const { data = {} } = useSelector(state => state.ui);
  const {
    title,
    description,
    actionLabel = 'Got it, thanks!',
    icon,
    engageConfetti,
    size = 'S'
  } = data;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 500);
  }, []);

  const confettiConfiguration = {
    angle: '85',
    spread: '181',
    startVelocity: 40,
    elementCount: 50,
    dragFriction: 0.12,
    duration: 2000,
    stagger: 3,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
  };

  return (
    <ModalWrapper size={size}>
      <ModalContent>
        <ModalScrollable
          padding="L"
          className="SuccessFeedbackModal text-center"
          isContentCentered
        >
          <div>
            {icon && <img src={icon} width="50px" alt="" />}
            <h2>{title || 'Success!'}</h2>
            {description && <h3 className="stack-M">{description}</h3>}
            <br />
            <PressStud
              label={actionLabel}
              appearance="primary"
              action={onRequestClose}
            />
            <br />
            {engageConfetti && (
              <div className="SuccessFeedbackModal-confetti">
                <Confetti active={loaded} config={confettiConfiguration} />
              </div>
            )}
          </div>
        </ModalScrollable>
      </ModalContent>
    </ModalWrapper>
  );
};

SuccessFeedbackModal.props = {
  title: PropTypes.string,
  description: PropTypes.string,
  actionLabel: PropTypes.string,
  icon: PropTypes.string,
  engageConfetti: PropTypes.bool
};

export default SuccessFeedbackModal;
