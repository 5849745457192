import React from 'react';
import PropTypes from 'prop-types';
import { length, area, mass } from 'v1/helpers/measurements';
import { TextInputGroup } from 'v1/components/shared';

import get from 'lodash/get';

/**
 * A measurement input allows the user to input a value of distance.
 * @param props
 * @returns {JSX.Element}
 */
const MeasurementInput = ({ units, onChange, ...props }) => {
  const DEFAULT_UNIT = get(units, [0, 'value']);

  return (
    <TextInputGroup
      type="number"
      valueKey="amount"
      append={{ valueKey: 'unit', options: units }}
      onChange={value => {
        onChange({ amount: value.amount, unit: value.unit || DEFAULT_UNIT });
      }}
      {...props}
    />
  );
};

MeasurementInput.defaultProps = {
  placeholder: 'Enter value',
  removeAutocomplete: true,
  units: [...length, ...area, ...mass]
};

MeasurementInput.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
    ])
  )
  // Inherits standard TextInputGroup, NumberInput, TextInput & HTML input properties
};

export default MeasurementInput;
