import { z } from 'zod';

export const resourceSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  nick_name: z.string().optional(),
  field: z.string().optional(),
  profile_picture: z.string().optional(),
  profile_picture_id: z.string().optional(),
  groups: z.array(z.unknown()).optional(),
  role: z.string().optional(),
  address: z.string().optional(),
  full_name: z.string(),
  emails: z.array(z.unknown()).optional(),
  websites: z.array(z.unknown()).optional(),
  phone_numbers: z.array(z.string()).optional(),
  socials: z.array(z.unknown()).optional(),
  rates: z.array(z.unknown()).optional(),
  company: z.string().optional(),
  title: z.string().optional(),
  archived: z.boolean().optional(),
  legacy_user_id: z.number().optional(),
  tags: z.array(z.unknown()).optional(),
  user_id: z.number().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  created_mechanism: z.string().optional(),
  custom_fields: z.array(z.any()).optional(),
  custom_fieldgroups: z.array(z.any()).optional(),
  guid: z.string().optional(),
  entity_type: z.string().optional(),
  contact_type: z.string().optional(),
  tasklist_id: z.number().optional(),
  import_id: z.string().optional(),
  resource_type_id: z.number().optional(),
  resource_type: z.unknown().optional(),
  organisation_id: z.number().optional(),
  organisation: z.unknown().optional(),
  represented_by_id: z.number().optional(),
  represented_by: z.unknown().optional(),
  account_id: z.number().optional(),
  locations: z.array(z.unknown()).optional(),
  bookings: z.array(z.unknown()).optional()
});

export type Resource_normalized = z.infer<typeof resourceSchema>;
