import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-form';

import { validate as validator } from 'v1/helpers/validation';
import { Label, TextInput, ErrorMessage } from 'v1/components/shared';

const TextInputField = ({ field, label, validate, onChange, ...rest }) => {
  const handleValidate = value => {
    if (validate) {
      return validator(value, validate.split(','));
    }
  };

  return (
    <Field field={field} validate={handleValidate}>
      {fieldApi => (
        <>
          {label && <Label htmlFor={field}>{label}</Label>}
          <TextInput
            id={field}
            field={field}
            value={fieldApi.value || ''}
            onChange={({ target: { value } }) => {
              fieldApi.setValue(value);
              onChange && onChange(field, value);
            }}
            error={fieldApi.error}
            {...rest}
          />
          {fieldApi.error && <ErrorMessage>{fieldApi.error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
};

TextInputField.propTypes = {
  field: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func // Takes (field, value)
  // ...rest are from TextInput
};

export default TextInputField;
