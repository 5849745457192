const types = [
  'BOOKING_ARCHIVED',
  'BOOKING_CANCELLED',
  'BOOKING_CREATED',
  'BOOKING_UPDATED',
  'PRODUCTION_ARCHIVED',
  'PRODUCTION_BUDGET_UPDATED',
  'PRODUCTION_CREATED',
  'PRODUCTION_UPDATED',
  'CHECKIN_CREATED',
  'CHECKIN_UPDATED',
  'SHORTLIST_UPDATED',
  'SHORTLIST_BLOCKS_UPDATED'
].reduce((obj, type) => {
  obj[type] = type;
  return obj;
}, {});

export default types;
