import React, { Component } from 'react';
import DEPRECATED_TextInput from 'v1/components/legacy/deprecated/DEPRECATED_TextInput/DEPRECATED_TextInput';
import { set, keys, debounce } from 'lodash';
import clickOutside from 'v1/helpers/clickOutsideDecorator';
import classnames from 'classnames';

import './RosterSearch.scss';

class RosterSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: this.props.query || '',
      showSuggester: false,
      position: 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.query && nextState.query) {
      this.setState({ query: '' });
    }
  }

  onClickOutside = () => {
    return this.setState({ showSuggester: false });
  };

  onQueryChange = (name, query) =>
    this.setState({ query, position: 0, showSuggester: !!query }, () => {
      if (this.props.hideSuggester) return this.handleAction(null, 'search');
      // if (query) return this.props.getSuggestions(query);
      this.props.onSearch({
        order_by: { field: 'created_at', direction: 'desc' }
      });
    });

  onEnter = () => {
    if (!this.state.query) return;

    if (this.state.position === 0) {
      return this.handleAction(null, 'search');
    }
    const suggestions = keys(this.props.suggestions).reduce((arr, type) => {
      const partial = this.props.suggestions[type];
      const suggestion_type = type.substr(0, type.length - 1);
      return arr.concat(partial.map(s => ({ ...s, suggestion_type })));
    }, []);
    const suggestion = suggestions[this.state.position - 1];
    this.handleAction(suggestion, suggestion.suggestion_type);
  };

  handleDebounceSearch = debounce(() => {
    this.props.onSearch({ query: this.state.query });
  }, 500);

  handleAction = (suggestion, type) => {
    this.setState({ showSuggester: false });
    switch (type) {
      case 'search':
        return this.setState(
          { showSuggester: false },
          this.handleDebounceSearch()
        );
      case 'contact':
        return this.props.onContactSelect(suggestion);
      case 'tag':
      case 'skill':
        const query = {
          advanced: { [`${type}s`]: { one: [suggestion[type]], type: 'list' } }
        };
        return this.setState({ showSuggester: false, query: '' }, () =>
          this.props.onSearch(query, 'TAGS')
        );
      default:
        break;
    }
  };

  getSuggestions = debounce(() => {
    this.props.getSearchSuggestions(this.state.query);
  }, 300);

  handlePositionChange = action => {
    if (!this.state.query) return;

    const length = keys(this.props.suggestions).reduce(
      (sum, key) => sum + this.props.suggestions[key].length,
      0
    );
    if (!length) return;

    this.setState(prev => {
      if (action === 'UP') {
        return prev.position === 0
          ? set(prev, 'position', length)
          : set(prev, 'position', prev.position - 1);
      }
      if (action === 'DOWN') {
        return prev.position === length
          ? set(prev, 'position', 0)
          : set(prev, 'position', prev.position + 1);
      }
    });
  };

  render() {
    const { smallView } = this.props;

    return (
      <div
        className={classnames(
          {
            RosterSearch: true,
            small: smallView
          },
          [this.props.className]
        )}
      >
        <div className="RosterSearch-inputs">
          <div className="RosterSearch-input">
            <DEPRECATED_TextInput
              field="query"
              value={this.state.query}
              onChange={this.onQueryChange}
              onEnter={this.onEnter}
              placeholder={this.props.placeholder || 'Search for anything...'}
              removeAutocomplete
              className={classnames(['form-control'], {
                'small-input': smallView
              })}
              onFocus={() => {
                if (this.state.query) {
                  this.onQueryChange(null, this.state.query);
                }
              }}
              onEscape={() => this.setState({ showSuggester: false })}
              onDownArrow={() => this.handlePositionChange('DOWN')}
              onUpArrow={() => this.handlePositionChange('UP')}
              highlightOnFocus
            />
          </div>
        </div>
        <img
          src="/images/icon_search.svg"
          className="RosterSearch-searchIcon"
          width="15px"
          alt=""
        />
      </div>
    );
  }
}

export default clickOutside(RosterSearch);
