import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';

import { HashLink } from 'react-router-hash-link';

import './ResourceSlot.scss';

import {
  ListCell,
  Grid,
  GridCell,
  GridSeparator,
  BookingPillGroup,
  PressStud
} from 'v1/components/shared';

const ResourceSlot = ({
  className,
  showResource,
  slot = {},
  onClick,
  onHashLink
}) => {
  const getSlotName = useSelector(makeGetSlotName);

  return (
    <ListCell padding={0} className={classnames(['ResourceSlot', className])}>
      <Grid cellPadding="M" vcenter>
        <GridCell width={showResource ? '4/12' : 'auto'}>
          <div className="text-13-700">{getSlotName(slot)}</div>
          {slot.note && <div className="text-13-600">{slot.note}</div>}
        </GridCell>
        {showResource && (
          <>
            <GridSeparator inset={false} />
            <GridCell padding="S">
              <BookingPillGroup assignments={slot.resource_slot_assignments} />
            </GridCell>
          </>
        )}
        {onClick && (
          <GridCell width="auto" padding="XS">
            <div className="ResourceSlot-arrow">
              <PressStud icon="arrowRightGray" action={onClick} />
            </div>
          </GridCell>
        )}
        {onHashLink && (
          <GridCell width="auto" padding="XS">
            <HashLink
              smooth
              to={onHashLink}
              className="btn btn-default btn-square btn-small ResourceSlot-arrow"
            >
              <img src="/images/icon_arrow_right_gray.svg" alt="" />{' '}
            </HashLink>
          </GridCell>
        )}
      </Grid>
    </ListCell>
  );
};

ResourceSlot.propTypes = {
  className: PropTypes.string,
  showResource: PropTypes.bool,
  slot: PropTypes.object.isRequired, // One from production.resource_slots
  onClick: PropTypes.func
};

export default ResourceSlot;
