import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ResourceBadgeGroup.scss';

import { ResourceBadgeIcon } from 'v1/components/shared';

const ResourceBadgeGroup = ({ className, items }) => {
  const resources = useSelector(state => state.contacts);

  return (
    <div className={classnames(['ResourceBadgeGroup', className])}>
      {!items || !items.length ? (
        <ResourceBadgeIcon />
      ) : items.length === 1 ? (
        <ResourceBadgeIcon
          resource={resources.data[items[0].contact_id] || items[0]}
        />
      ) : (
        items
          .slice(0, 3)
          .map((item, i) => (
            <ResourceBadgeIcon
              key={i}
              resource={resources.data[item.contact_id] || item}
            />
          ))
      )}
    </div>
  );
};

ResourceBadgeGroup.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array // Can pass in resource_slot_assignments[] or simply an array of resources
};

export default ResourceBadgeGroup;
