import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import useAutocomplete from 'v1/helpers/hooks/useAutocomplete';
import { Dropdown, MenuItem, TextInput } from 'v1/components/shared';
import AutocompleteSelectItem from './AutocompleteSelectItem/AutocompleteSelectItem';

import './AutocompleteSelect.scss';

const AutocompleteSelect = ({ data, selectedItems, onSelect }) => {
  const { query = '', setQuery, matches } = useAutocomplete({
    data,
    keyToMatch: 'name'
  });
  return (
    <Dropdown
      buttonIcon={<img src="/images/icon_add_black.svg" alt="" width="12px" />}
      buttonLabel="Add"
      buttonClass="btn btn-default"
      listPadding="0"
    >
      <div className="AutocompleteSelect">
        <div className="AutocompleteSelect-searchInput">
          <TextInput
            name="query"
            value={query}
            onChange={({ target }) => setQuery(target.value)}
            placeholder="Search field name"
            size="S"
            autoComplete="off"
            autoFocus
          />
        </div>
        <div className="AutocompleteSelect-results">
          {matches.map((item, index) => (
            <AutocompleteSelectItem
              key={item.key || item}
              item={item}
              index={index}
              selectedItems={selectedItems}
              isSearchActive={!isEmpty(query)}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
      <MenuItem onSelect={() => {}} className="visually-hidden" />
    </Dropdown>
  );
};

AutocompleteSelect.propTypes = {
  data: PropTypes.array,
  selectedItems: PropTypes.array,
  onSelect: PropTypes.func
};

AutocompleteSelect.defaultProps = {
  data: [],
  selectedItems: [],
  onSelect: () => {}
};

export default AutocompleteSelect;
