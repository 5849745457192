//
// Helpers for dates & moments
//

import moment from 'moment-timezone';

/////////////////////////////////

/*
 *  CONSTANTS
 *  ===========
 */

/**
 * @const DATE_FORMATS
 * Assigns names to moment date formats
 */
const DATE_FORMATS = {
  SHORT: 'ddd D MMM', // Mon 1 Jan
  MEDIUM: 'ddd Do MMM', // Mon 1st Jan
  LONG: 'dddd Do MMM YY' // Mon 1st Jan 20
};

/**
 * @const DEFAULT_START_TIME
 * Standard default start time
 */
export const DEFAULT_START_TIME = '09:00:00';

/**
 * @const RELATIVE_DATES
 * @desc Relative date ranges & their labels
 *
 */
// This uses consts rather than numbers so that we can expand it to eg 'Last Month'
export const RELATIVE_DATES = {
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  YESTERDAY: 'Yesterday',
  QUARTER: 'Quarter',
  NEXT_7: 'Next 7 Days',
  LAST_7: 'Last 7 Days',
  NEXT_30: 'Next 30 Days',
  LAST_30: 'Last 30 Days',
  LAST_WEEK: 'Last Week',
  LAST_MONTH: 'Last Month',
  LAST_QUARTER: 'Last Quarter',
  NEXT_WEEK: 'Next Week',
  NEXT_MONTH: 'Next Month',
  NEXT_QUARTER: 'Next Quarter'
};

export const CUSTOM_WALMART_RELATIVE_DATES = {
  WALMART_QUARTER: 'Quarter (Walmart)',
  WALMART_LAST_QUARTER: 'Last Quarter (Walmart)',
  WALMART_NEXT_QUARTER: 'Next Quarter (Walmart)'
};

/////////////////////////////////

/*
 *  FORMATTING
 *  ==========
 */

/**
 * @function isoToFriendlyDate
 * Formats a date or a date range as a string
 * eg DEFAULT_SINGLE: 'Mon 1st Jan'
 * eg DEFAULT_RANGE: 'Mon 1st Jan - Fri 5th Jan'
 *
 * @param {Object} undefined - The date or dates to format
 * @param {date} undefined.date1 - The first date
 * @param {date} [undefined.date2] - The second date (optional)
 * @param {string} layout - Format as a single date or as a range - ['DEFAULT_SINGLE', 'DEFAULT_RANGE']
 * @param {string} length - The named date format, from {@link DATE_FORMATS}
 */
export function isoToFriendlyDate(
  { date1, date2 },
  layout = 'DEFAULT_SINGLE',
  length = 'MEDIUM'
) {
  if (!date1) return;
  let friendlyDate;
  let dateFormat = DATE_FORMATS[length];

  switch (layout) {
    case 'DEFAULT_SINGLE':
    default:
      friendlyDate = `${moment(date1).format(dateFormat)}`;
      break;
    case 'DEFAULT_RANGE':
      friendlyDate = `${date1 ? moment(date1).format(dateFormat) : ''} - ${
        date2 ? moment(date2).format(dateFormat) : ''
      }`;
      break;
  }

  return friendlyDate;
}

/**
 * @function convertTo24HourTime
 * Formats an 12 hour time object into 24 hour time
 *
 * @param {Object} time - The time to turn into 24 hour time - { time: '00:00', unit: 'am' or 'pm' }
 */
export function convertTo24HourTime({ time = '00:00', unit = 'am' }) {
  // Catch midnight so it does not format it 12:00 if the unit is pm
  if (time === '00:00') {
    return '00:00';
  }
  return moment(time + unit, 'hh:mm:ssa').format('HH:mm');
}

/**
 * @function convertTo12HourTime
 * Formats 24 hour time to 12 hour time
 * You can declare a string or object output for use in the system
 *
 * @param {String} time - Formatted as 00:00:00 The 24 hour time to turn into 12 hour time
 */
export function convertTo12HourTime(time, output = 'object') {
  if (!time || typeof time !== 'string') return {};

  time = moment(time, 'HH:mm');
  return output === 'object'
    ? {
        time: time.format('hh:mm'),
        unit: time.format('a')
      }
    : time.format('hh:mm a');
}

/**
 * @function manipulateTime
 * Simple function to add / subtract time and return new time
 *
 * @param {String} time - Formatted as 00:00:00 The 24 hour time to turn into 12 hour time
 * @param {String} hand - Time unit to adjust hours / minutes / seconds
 * @param {String} value - Value to adjust by
 * @param {String} method - add / subtract
 */
export function manipulateTime(time, hand, value, method = 'add') {
  if (!time || !hand || !value) return null;
  switch (method) {
    case 'add':
      return moment(time, 'HH:mm:ss').add(value, hand).format('HH:mm:ss');
    case 'subtract':
      return moment(time, 'HH:mm:ss').subtract(value, hand).format('HH:mm:ss');
    default:
      return time;
  }
}

// TODO: refactor : check
/**
 * @function appendDefaultStartTimeToDate
 * Append the constant {@link DEFAULT_START_TIME} to a date string with no time or timezone
 *
 * @param {string} date - The date string to append to
 */
export function appendDefaultStartTimeToDate(date) {
  return `${date}T${DEFAULT_START_TIME}.000Z`;
}

/////////////////////////////////

/*
 *  CALCULATION
 *  ===========
 */

/**
 * @function calculateDurationInHours
 * Calculate the number of hours between two moment dates
 *
 * @param {Moment} startDate - The start date
 * @param {Moment} endDate - The end date
 */
export const calculateDurationInHours = (startDate, endDate) =>
  endDate.diff(startDate, 'minutes') / 60;

/**
 * Serialise the start and end date into a single string
 * @param {string} start
 * @param {string} end
 * @return {string}
 */
export const marshalDateRange = ({ start = '', end = '' }) => {
  return `${start || ''}$${end || ''}`;
};

/**
 * Deserialise a date range string (format: START_DATE$END_DATE)
 * @param {string} value
 * @return {[string, string]}
 */
export const unmarshalDateRange = value => {
  if (typeof value !== 'string') {
    return ['', ''];
  }
  const dateStrings = value.split('$');
  if (dateStrings.length !== 2) {
    return ['', ''];
  }
  const startDateString = dateStrings[0];
  const endDateString = dateStrings[1];
  return [startDateString, endDateString];
};

/**
 * Given a valid date range string in the format of "START_DATE$END_DATE",
 * this function will return a user-facing date range text
 * @param {string} value
 * @param {string} format
 * @return {string}
 */
export const displayDateRange = (value, format = 'Do MMM YYYY') => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  const [startDate, endDate] = value.split('$');
  return [startDate, endDate]
    .filter(Boolean)
    .map(dateString => moment(dateString).format(format))
    .join(' - ');
};
