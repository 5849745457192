import React, { Component } from 'react';
import { cleanUrl, htmlStringParser } from 'v1/helpers/misc';
import { get } from 'lodash';

export default class PinWebLink extends Component {
  renderLinkType = pin => {
    const { type } = pin;
    switch (type) {
      case 'github/repo':
        return 'GITHUB REPO';
      case 'medium/post':
        return 'MEDIUM POST';
      default:
        return 'Website';
    }
  };
  renderURL = pin => {
    let url = pin.url;
    url = cleanUrl(url);
    url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

    return (
      <div className="PinItem-graphic">
        {this.renderLinkType(pin)} <br />
        <span className="PinWebLink-link">{url}</span>
      </div>
    );
  };
  render() {
    const { pin } = this.props;
    return (
      <a
        href={htmlStringParser(pin.url)}
        target="_blank"
        rel="noopener noreferrer"
        className="PinWebLink"
      >
        <span data-tip="Open in new window" className="Pin-externalIcon">
          {' '}
          <img src="/images/icon_new_window_white.svg" width="15px" alt="" />
        </span>
        {this.renderURL(pin)}
      </a>
    );
  }
}
