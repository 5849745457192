import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { WithContext as ReactTags } from 'react-tag-input';
import has from 'lodash/has';

import { Label } from 'v1/components/shared';

import './TagInput.scss';

// TODO: Needs complete refactoring and improvements
const TagInput = ({
  label = 'Tags',
  tags,
  suggestions,
  onChange,
  disableAdd,
  autocomplete,
  creatable = false,
  sortAlphabetically,
  ...props
}) => {
  tags = tags || [];
  const formattedTags = tags.map(t => ({
    id: t.id ? t.id.toString() : `temp-${v4()}`,
    text:
      (has(t, 'tag') ? t.tag : '') ||
      (has(t, 'name') ? t.name : '') ||
      (typeof t === 'string' ? t : '')
  }));
  const formattedSuggestions = (suggestions || [])
    .map(t => ({
      id: t.id ? t.id.toString() : `temp-${v4()}`,
      text:
        (has(t, 'tag') ? t.tag : '') ||
        (has(t, 'name') ? t.name : '') ||
        (typeof t === 'string' ? t : '')
    }))
    .sort((a, b) => {
      return sortAlphabetically
        ? a.text.localeCompare(b.text, 'en', { sensitivity: 'base' })
        : null;
    })
    .filter(({ id }) => tags.findIndex(tag => +tag.id === +id) === -1);

  return (
    <Fragment>
      {label && <Label>{label}</Label>}
      <ReactTags
        readOnly={props.isReadOnly}
        tags={formattedTags}
        suggestions={formattedSuggestions}
        renderSuggestion={item => <div>{item.text}</div>}
        handleDelete={index => {
          onChange(tags.filter((_, i) => index !== i));
        }}
        handleAddition={({ id, text }) => {
          const parsedId = parseInt(id);
          const tag = parsedId
            ? suggestions.find(t => t.id === parsedId)
            : suggestions.find(t => t.tag === text);

          if (tag) {
            onChange([...tags, tag]);
          } else if (
            (creatable && !disableAdd) ||
            (typeof id === 'string' && id.includes('temp'))
          ) {
            onChange([...tags, { tag: text }]);
          }
        }}
        placeholder={props.placeholder || 'Enter tag'}
        autofocus={false}
        classNames={{
          tagInput: 'TagInput',
          tagInputField: classnames('TagInput-input', props.inputClassname),
          selected: classnames('TagInput-selected', props.className),
          tag: 'TagInput-tag',
          remove: 'TagInput-removeButton',
          suggestions: 'TagInput-suggestions',
          activeSuggestion: 'TagInput-suggestions-active'
        }}
        delimiters={[188, 13]}
        allowDeleteFromEmptyInput={false}
        allowDragDrop={false}
        minQueryLength={0}
        maxLength={40}
        autocomplete={autocomplete}
      />
    </Fragment>
  );
};

TagInput.defaultProps = {
  // Component props
  tags: [],
  suggestions: [],
  readOnly: false,
  autocomplete: false,
  onChange: () => {}
};

TagInput.propTypes = {
  classname: PropTypes.string,
  inputClassname: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  // Component props
  tags: PropTypes.array,
  suggestions: PropTypes.array,
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  creatable: PropTypes.bool,
  sortAlphabetically: PropTypes.bool
};

export default TagInput;
