import AuthService from 'lib/auth/AuthService';
import Env from 'lib/env/Env';

class Account {
  /**
   * Get the tenant. Null is returned if the user is not authenticated.
   * @returns {{id: string, name: string, domain: string}|null}
   */
  static tenant() {
    const token = AuthService.token();
    return token ? token.tenant : null;
  }

  /**
   * Get the tenant ID. Empty string is returned if the user is not authenticated.
   * @returns {string|null}
   */
  static tenantId() {
    const tenant = Account.tenant();
    return tenant ? tenant.id : '';
  }

  /**
   * Get the tenant name. Empty string is returned if the user is not authenticated.
   * @returns {string|null}
   */
  static tenantName() {
    const tenant = Account.tenant();
    return tenant ? tenant.name : '';
  }

  /**
   * Get the account. Null is returned if the user is not authenticated.
   * @returns {{id: string, email: string, status: string, is_super_user: boolean, account_roles: Array}|null}
   */
  static account() {
    const token = AuthService.token();
    return token ? token.account : null;
  }

  /**
   * Get the account ID. Empty string is returned if the user is not authenticated.
   * @returns {string}
   */
  static accountId() {
    const account = Account.account();
    return account ? account.id : '';
  }

  /**
   * Returns true if the user is a super user.
   * @returns {boolean}
   */
  static isSuperUser() {
    const account = Account.account();
    return account ? account.is_super_user : false;
  }

  /**
   * Alias for Account.accountId()
   * @returns {string}
   */
  static userId() {
    return Account.accountId();
  }

  /**
   * Get the workspaces the account belongs to.
   * @returns {Array}
   */
  static workspaces() {
    const token = AuthService.token();
    return token && Array.isArray(token.workspaces) ? token.workspaces : [];
  }

  /**
   * Helper method. Simply returns the first workspace or null if none exist.
   * @returns {Object|null}
   */
  static resolveFirstWorkspace() {
    const workspaces = Account.workspaces();
    return workspaces.length > 0 ? workspaces[0] : null;
  }

  /**
   * Helper method. Simply returns the ID of the first workspace or empty string if none exist.
   * @returns {string|null}
   */
  static resolveFirstWorkspaceId() {
    const workspace = Account.resolveFirstWorkspace();
    return workspace ? workspace.id : '';
  }

  /**
   * Get a workspace by ID. Null is returned if the workspace does not exist.
   * @param {string} id Workspace ID
   * @returns {{id: string, name: string, description: string, db_schema: string, feature_flags: Array<string>, roles: Array}|null}
   */
  static resolveWorkspace(id) {
    const workspaces = Account.workspaces();
    const workspace = workspaces.find(workspace => workspace.id === id);
    return workspace ? workspace : null;
  }
}

// Add global hook
if (Env.nodeEnv !== 'production') {
  window.Account = Account;
}

export default Account;
