import React from 'react';
import PropTypes from 'prop-types';
// import get from 'lodash/get';
import { Button } from 'v1/components/shared';
import { useRestrictedAccess } from 'lib/restrictedAccess';

import classnames from 'classnames';
import './EmptyGeneric.scss';

const EmptyGenericAction = ({ text, capability, ...rest }) => {
  const allowed = useRestrictedAccess(undefined, capability, undefined);
  if (!allowed) return null;
  return (
    <div className="EmptyGeneric-action">
      <Button {...rest}>{text}</Button>
    </div>
  );
};

const EmptyGeneric = ({
  description,
  title,
  iconWidth,
  icon,
  action,
  className
}) => (
  <div
    className={classnames(['EmptyGeneric text-center', className])}
    data-id="empty-generic"
  >
    {icon && (
      <img className="stack-M" src={icon} width={iconWidth || '40px'} alt="" />
    )}
    <div className="EmptyGeneric-text stack-M">
      <h5>{title}</h5>
      {description && <p>{description}</p>}
    </div>
    {action && <EmptyGenericAction {...action} />}
  </div>
);

EmptyGeneric.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  iconWidth: PropTypes.string, // E.g. '40px'
  icon: PropTypes.string, // Icon img src, eg '/images/icon_colour_contact_notes.svg'
  action: PropTypes.shape({
    text: PropTypes.string // Button text
    // ...rest are Button props
  })
};

export default EmptyGeneric;
