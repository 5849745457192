import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import { useSelector } from 'react-redux';

import { htmlStringParser } from 'v1/helpers/misc';

import classnames from 'classnames';
import _get from 'lodash/get';

import './ResourceFieldItem.scss';
import get from 'lodash/get';

export const ResourceFieldItem = ({
  field,
  fieldMapper,
  resource,
  layout = {},
  hideLabel = false
}) => {
  const rates = useSelector(state => state.rates);
  const contacts = useSelector(state => state.contacts);
  const custom_field_definitions = useSelector(state =>
    get(state.auth, 'settings.custom_field_definitions')
  );

  const { type, getValue, getPrettyValue, getUrl, label } = fieldMapper[field];
  const rawValue = getValue(resource);

  function renderValue() {
    const className = `ResourceFieldItem-itemValue trunc`;
    const prettyValue = getPrettyValue
      ? getPrettyValue(rawValue, custom_field_definitions)
      : rawValue;

    switch (type) {
      case 'RESOURCE':
        const relatedResourceName = getPrettyValue(resource)
          ? getPrettyValue(resource)
          : _get(contacts, ['data', rawValue, 'full_name']);
        return (
          <span
            data-tip={relatedResourceName}
            data-delay-show={700}
            className={classnames([className])}
          >
            {relatedResourceName}
          </span>
        );
      case 'TEXT':
      default:
        return (
          <span
            data-tip={prettyValue}
            data-delay-show={700}
            className={classnames([className])}
          >
            {prettyValue}
          </span>
        );
      case 'URL':
        const url = getUrl ? getUrl(rawValue) : rawValue;
        return (
          <a
            href={htmlStringParser(url)}
            className={classnames([className])}
            target="_blank"
            rel="noopener noreferrer"
            data-tip={prettyValue}
            data-delay-show={700}
          >
            {prettyValue}
          </a>
        );
      case 'EMAIL':
        return (
          <a
            href={`mailto:${rawValue}`}
            className={classnames([className])}
            target="_blank"
            rel="noopener noreferrer"
            data-tip={prettyValue}
            data-delay-show={700}
          >
            {prettyValue}
          </a>
        );
      case 'CURRENCY':
        const currencyValue = getPrettyValue(getValue(resource, rates));
        return (
          <span
            data-tip={currencyValue}
            data-delay-show={700}
            className={classnames([className, 'lowercase'])}
          >
            {currencyValue}
          </span>
        );
      case 'QR_CODE':
        return (
          <span className={classnames([className, 'lowercase'])}>
            <QRCode value={prettyValue} renderAs="svg" size={120} />
          </span>
        );
    }
  }

  if (!field) return <span />;

  return (
    <div
      className={classnames([
        'ResourceFieldItem',
        layout.width,
        `ResourceFieldItem-${field}`
      ])}
    >
      <div className="BlockStatic">
        {!hideLabel && <h5 className="ResourceFieldItemLabel">{label}</h5>}
        <span
          className={classnames('ResourceFieldItemValue', {
            empty: !rawValue
          })}
        >
          {rawValue ? renderValue() : 'n/a'}
        </span>
      </div>
    </div>
  );
};

ResourceFieldItem.propTypes = {
  resource: PropTypes.object,
  fieldMapper: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  layout: PropTypes.shape({
    width: PropTypes.oneOf(['WIDE', 'TWO_COLUMNS', 'THREE_COLUMNS'])
  }),
  hideLabel: PropTypes.bool
};

export default ResourceFieldItem;
