import React from 'react';
import { useSelector } from 'react-redux';

import {
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  PressStud
} from 'v1/components/shared';

import './ApplicantSubmittedModal.scss';

const ApplicantSubmittedModal = ({ onRequestClose }) => {
  const message = useSelector(state => state.ui.data.message);

  return (
    <ModalWrapper size="XS">
      <ModalContent>
        <ModalScrollable padding="L" className="text-center">
          <img src="/images/icon_colour_send.svg" width="50px" alt="" />
          <h2>Application Submitted</h2>
          <h3 className="stack-S">
            {message || "Thanks for your submission. We'll be in touch shortly"}
          </h3>
          <PressStud
            icon="send"
            label="Submit another application"
            size="L"
            action={onRequestClose}
          />
        </ModalScrollable>
      </ModalContent>
    </ModalWrapper>
  );
};

ApplicantSubmittedModal.props = {};

export default ApplicantSubmittedModal;
