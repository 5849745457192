import React from 'react';
import classNames from 'classnames';
import { filter, get } from 'lodash';

import './ShortlistContactItemCompact.scss';
import { makeFileURL } from 'lib/s3/s3Helper';
import { UserCircleImage } from 'v1/components/shared';
import { renderName } from 'v1/helpers/contactHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { openContactSidebar } from 'store/v1/ui/ui.actions';
import ResourceFieldItem from '../ResourceFieldItem/ResourceFieldItem';

const ShortlistContactItemCompact = ({
  blockSelected,
  editable,
  block,
  onPreviewContact,
  default_fields,
  shortlist_field_mapper,
  onSelect,
  sidebarDisabled
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);

  const renderContactName = block => {
    const contact = get(contacts, ['data', block.contact_id], block.contact);
    const hasValue =
      shortlist_field_mapper[default_fields[0]] &&
      shortlist_field_mapper[default_fields[0]].getValue(contact);
    return (
      <div className="ShortlistContactItemCompact-name">
        <h3
          onClick={() => {
            if (!sidebarDisabled) {
              onPreviewContact && dispatch(openContactSidebar(contact));
            }
          }}
          className="ContactName-SidebarHint"
        >
          {renderName(contact)}
          {editable && !sidebarDisabled && (
            <img
              data-tip="Quick View Contact"
              className="ContactName-SidebarHint-icon"
              src="/images/icon_contact_sidebar_hint.svg"
              alt=""
            />
          )}
        </h3>
        {block.data && block.data.paragraph ? (
          <div
            className="ShortlistContactItemCompact-name-note trunc"
            data-tip={block.data.paragraph}
          >
            {block.data.paragraph}
          </div>
        ) : null}
      </div>
    );
  };

  const renderGallery = block => {
    let image = block.contact && block.contact.profile_picture;
    let initials = {
      first_name: get(block, 'contact.first_name'),
      last_name: get(block, 'contact.last_name')
    };

    if (!image) {
      const pins = get(block, 'pins');
      const formattedPins = filter(pins, p => !p.archived);
      const media = formattedPins.length && formattedPins[0];

      const pinFile = get(media, 'file', null);
      image = pinFile ? makeFileURL(pinFile) : media.url;
    }

    return (
      <div className="ShortlistContactItemCompact-image">
        <UserCircleImage
          src={image}
          size="small"
          className="extra-small"
          style={{ width: '50px', height: '50px', maxWidth: '50px' }}
          initials={initials}
        />
      </div>
    );
  };

  const renderSelector = block => {
    return (
      <div
        className="ShortlistContactItemCompact-selector"
        onClick={e => {
          e.stopPropagation();
          onSelect(block);
        }}
      >
        <span
          className="ShortlistContactItemCompact-selector-checkbox"
          data-tip="Select"
        />
      </div>
    );
  };

  return (
    <div className="ShortlistContactItemCompact">
      <div
        className={classNames(['ShortlistContactItemCompact-inner'], {
          selected: blockSelected,
          editable: editable
        })}
      >
        {editable && renderSelector(block)}
        {renderGallery(block)}
        {renderContactName(block)}
      </div>
    </div>
  );
};

export default ShortlistContactItemCompact;
