import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useEvent from 'v1/helpers/hooks/useEvent';

import { TextInputField } from 'v1/components/shared';
import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation,
  Dropdown,
  MenuItem
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { Form } from 'react-form';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import {
  createSlotCategory,
  updateSlotCategory
} from 'store/v1/slot_categories/slot_categories.actions.js';
import { SLOT_CATEGORIES_ACTIONS } from 'store/v1/slot_categories/slot_categories.constants.js';

import './SlotCategoryCreateModal.scss';

const SlotCategoryCreateModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();

  const slot_categories = useSelector(state => state.slot_categories);
  const ui = useSelector(state => state.ui);

  const [formApi, setFormApi] = useState();
  const { create, slot_category = {} } = ui.data;
  const { loading } = slot_categories;

  useEvent(
    [
      SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY,
      SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Category Created'));
        onRequestClose();
      },
      onFailure: () => {
        dispatch(
          displayAlert('error', create ? 'Create Failed' : 'Update Failed')
        );
      }
    }
  );

  function handleSubmit(values) {
    create
      ? dispatch(createSlotCategory(values))
      : dispatch(updateSlotCategory(slot_category.id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} slot category`} />
      <ModalContent>
        <ModalScrollable padding="XL">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              slot_category_type: 'RESOURCE_SLOT',
              ...slot_category
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <TextInputField
                  label="Name"
                  field="name"
                  placeholder="Name"
                  validate="required"
                  initialFocus
                  removeAutocomplete
                />
                <div className="SlotCategoryForm-dropdown">
                  <label className="form-label">Type</label>
                  <Dropdown
                    buttonLabel={
                      formApi.values.slot_category_type === 'EXPENSE'
                        ? 'Expense'
                        : formApi.values.slot_category_type === 'RESOURCE_SLOT'
                        ? 'Role'
                        : null
                    }
                    buttonClass="btn btn-default reverse"
                    buttonExpandIcon={true}
                  >
                    <MenuItem
                      onSelect={() =>
                        formApi.setValue('slot_category_type', 'RESOURCE_SLOT')
                      }
                    >
                      <span className="MenuItem-label">Role</span>
                    </MenuItem>
                    <MenuItem
                      onSelect={() =>
                        formApi.setValue('slot_category_type', 'EXPENSE')
                      }
                    >
                      <span className="MenuItem-label">Expense</span>
                    </MenuItem>
                  </Dropdown>
                </div>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Done" appearance="silent" action={onRequestClose} />
          <PressStud
            label={create ? 'Create Category' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === 'CREATE_SLOT_CATEGORY' ||
              loading === 'UPDATE_SLOT_CATEGORY'
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default SlotCategoryCreateModal;
