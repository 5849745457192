import { max } from 'lodash';
import { defineEntityEffect } from 'v4/entities/common/defineEntityEffect';
import { ListQuery, ListResponse } from '../../common/common.crud.types';
import { Resource_normalized } from '../../resource/resource.types';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { canBlockAffectAssociation } from './resource__shortlist.reducer.helpers';
import {
  AssociationQuery,
  AssociationState,
  mutationSchemas,
  ShortlistBlock_normalized
} from './resource__shortlist.schemas';

export const createCreateEffect = (
  useApi: UseResourceShortlistAssociationEffectsApi,
  useEnsureList: () => (
    query: AssociationQuery
  ) => Promise<
    ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
  >
) =>
  defineEntityEffect({
    type: 'create',
    requestSchema: mutationSchemas.create.request,
    responseSchema: mutationSchemas.create.response,
    useQueryFn: () => {
      // todo I feel this should be hacked on http layer, which should accept a dependency to resolve order (from cache, if possible)
      // so if we switch to a different backend which supports default ordering, we don't have to change this
      // otherwise we leak business logic into the application layer
      const api = useApi();
      const ensureList = useEnsureList();
      return async (action, signal) => {
        if (
          action.payload.order === null ||
          action.payload.order === undefined
        ) {
          const blocks = await ensureList({
            shortlist_id: action.shortlist_id
          });
          const maxOrder = Math.max(
            ...blocks.results.map(block => block.order ?? 0)
          );
          return api.create(
            {
              ...action,
              payload: {
                ...action.payload,
                order: maxOrder + 1
              }
            },
            signal
          );
        } else {
          return api.create(action, signal);
        }
      };
    }
  })
    .withOptimisticReconciler<AssociationState, AssociationQuery>(
      config => (state, request, query) => {
        // todo think about pagination, for now we don't care about it (previous implementation was loading all blocks among the shortlist endpoint anyway...)
        if (canBlockAffectAssociation(query, request.resource_id)) {
          const shouldAddAtBeginning = 'resource_id' in query; // todo replace with generic sorting once backend supports it in generic way

          const newItem = {
            id: (max(state.results.map(item => item.id)) ?? 0) + 100,
            contact_id: request.resource_id,
            data: {},
            comments: [],
            pins: [],
            downvotes: [],
            upvotes: [],
            shortlist_id: request.shortlist_id,
            group_id: null,
            order: null,
            ...request.payload
          } satisfies ShortlistBlock_normalized;
          return {
            ...state,
            paging: {
              ...state.paging,
              total: state.paging.total + 1
            },
            results: shouldAddAtBeginning
              ? [newItem, ...state.results]
              : [...state.results, newItem]
          };
        }
        return state;
      }
    )
    .withResponseReconciler(config => (state, request, response, query) => {
      if (canBlockAffectAssociation(query, request.resource_id)) {
        const shouldAddAtBeginning = 'resource_id' in query; // todo replace with generic sorting once backend supports it in generic way

        return {
          state: {
            ...state,
            paging: {
              ...state.paging,
              total: state.paging.total + 1
            },
            results: shouldAddAtBeginning
              ? [response, ...state.results]
              : [...state.results, response]
          },
          shouldInvalidate: true // CREATE endpoint doesn't return nested shortlist, so we have to invalidate it (ResourceShortlists ignores item if nested shortlist is not present)
        };
      }
      return {
        state,
        shouldInvalidate: false
      };
    })
    .build();
