import { BOOKING_TYPES_ACTIONS } from 'store/v1/booking_types/booking_types.constants.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BOOKING_TYPES_ACTIONS.GET_BOOKING_TYPES:
    case BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE:
    case BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE:
    case BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_ORDER:
    case BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AUTH_ACTIONS.LOAD_AUTH_SUCCESS: {
      const { booking_types = [] } = action.result;
      return {
        ...state,
        data: booking_types.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: booking_types.map(item => item.id),
        loading: false,
        fetched: true
      };
    }
    case BOOKING_TYPES_ACTIONS.GET_BOOKING_TYPES_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        fetched: true
      };
    case BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_ORDER_SUCCESS:
      return {
        ...state,
        data: action.result.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.map(item => item.id),
        loading: false
      };
    case BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        loading: false
      };
    case BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            archived: true
          }
        },
        order: state.order.filter(id => id !== action.id),
        loading: false
      };
    case BOOKING_TYPES_ACTIONS.GET_BOOKING_TYPES_FAILURE:
    case BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_FAILURE:
    case BOOKING_TYPES_ACTIONS.CREATE_BOOKING_TYPE_FAILURE:
    case BOOKING_TYPES_ACTIONS.DELETE_BOOKING_TYPE_FAILURE:
    case BOOKING_TYPES_ACTIONS.UPDATE_BOOKING_TYPE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
