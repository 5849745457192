import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Account from 'lib/auth/Account';
import { getGroups } from 'store/v1/groups/groups.actions.js';
import { getRates } from 'store/v1/rates/rates.actions.js';
import { selectActiveAccount } from 'store/accounts';
import { selectActiveTeam } from 'store/teams';
import { Alert } from 'v1/components/shared';
import ModalHandler from 'v1/components/modals/ModalHandler';
import ModalProvider from 'modals/ModalProvider';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import { ReactIntercom as Intercom } from 'react-intercom/dist/lib/react-intercom';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { createSocketClient, SocketContext } from 'lib/websockets';
import './App.scss';

import { useCommandBar } from 'hooks/useCommandBar';

document.addEventListener('wheel', function (event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});

export function App({ children }) {
  useCommandBar();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const ui = useSelector(state => state.ui);
  const team = useSelector(state => selectActiveTeam(state));
  const account = useSelector(state => selectActiveAccount(state));
  const { loggedIn } = auth;

  const [socket, setSocket] = useState(undefined);
  const [intercomUser, setIntercomUser] = useState({});

  function loadSystemData() {
    dispatch(getGroups());
    dispatch(getRates());
  }

  useEffect(() => {
    if (loggedIn && account) {
      loadSystemData();
      const timezone = get(auth, 'settings.settings.defaults_timezone.value');
      if (timezone) moment.tz.setDefault(timezone);

      if (!socket) {
        const socket = createSocketClient();
        setSocket(socket);
      }

      if (account) {
        setIntercomUser({
          email: get(account, 'email'),
          email_address: get(account, 'email'), // Deprecate
          user_id: get(account, 'id'),
          name: `${get(account, 'firstName', '')} ${get(
            account,
            'lastName',
            ''
          )}`
        });
      }
    } else {
      if (socket) {
        socket.close();
        setSocket(null);
      }
    }
  }, [loggedIn, account]);

  return (
    <div className="App legacy-style-root">
      <Helmet>
        <title>{`Atellio${
          Account.tenantName() ? ` | ${Account.tenantName()}` : ''
        } ${team?.name ? ` | ${team.name}` : ''}`}</title>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="keywords"
          content="Talent, TMS, CMS, Talent Management, Freelancer, Developer, Creative, Contractor, Freelance, Art, Artist, Illustration, Illustrator, Hire Illustrator, Promotion, Editorial, Advertising, Creative, Design, Agent, Agency, Collective, Commission, Promotion, Portfolio, Animation, Motion, Hand Lettering, Licensing"
        />
        <meta
          name="description"
          content="Atellio is reinventing the production process for brands & agencies, by organizing & automating the production workflow."
        />
        <meta property="og:image" content="/images/meta_image.jpg" />
        <meta property="og:type" content="website" />
      </Helmet>
      <SocketContext.Provider value={socket}>
        <ModalProvider>{children}</ModalProvider>
      </SocketContext.Provider>
      <Alert alert={ui.alert} sidebarCollapsed={ui.sidebarCollapsed} />
      <ReactTooltip place="bottom" effect="solid" delayShow={300} />
      <ModalHandler />
      <Intercom app_id="ey6mnm5r" {...intercomUser} />
    </div>
  );
}

export default App;
