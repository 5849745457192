/**
 * High-order component state store merging
 * @param state
 * @param changes
 * @returns {Object} newState
 */
export default (state, changes) => {
  return {
    stateStore: {
      ...changes
    }
  };
};
