import PropTypes from 'prop-types';

const TypeFormComponent = {
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'silent', 'underline']),
  size: PropTypes.oneOf(['S', 'M', 'L', 'XL']),
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default TypeFormComponent;
