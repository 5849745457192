import { createSelector } from 'reselect';

import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';
import { getContactStore } from 'store/v1/contacts/contacts.selectors.js';
import {
  getProduction,
  getConfirmedSlots,
  getProductionGroups
} from 'store/v1/productions/productions.selectors.js';

import {
  generateCallsheetTitleFromProduction,
  generateCallsheetFromDate,
  generateCallsheetProductionDate,
  removeCallsheetSystemFields
} from 'v1/helpers/byModel/CallsheetHelper';
import { DEFAULT_START_TIME } from 'v1/helpers/byType/dateHelper';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import omit from 'lodash/omit';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { v4 } from 'uuid';
import { getBookingStore } from 'store/v1/bookings/bookings.selectors';
import { getCustomFieldDefinitions } from 'store/v1/custom_fields/custom_fields.selectors';
import { CustomFieldType } from '__types__';

// CALLSHEET SELECTORS
// This files main purpose is to house the functions necessary to duplicate a callsheet
// We need to move all this logic in the back-end but for now the reason it exists here is because
// duplicating a callsheet requires many bits of data from state and thus the 'reselect' function
// seemed ideal to get that cleanly outside of a component.
// We've had to put this in its own file because we use this same logic now to create a callsheet from
// a production, as well as duplicate a callsheet from a production.
// I've listed below the functions of each selector and what it does.

const getCallTime = (_, { callTime }) => callTime;
const getFromDate = (_, { selectedDate }) => selectedDate;

/**
 * @function getGroups
 * Checks the confirmed slots for their group_id's and returns these groups from the production.groups array in to a new
 * callsheet.groups array.
 *
 * @param {Array} slots - Confirmed slots from getConfirmedSlots
 * @param {Object} production - The production to get the production groups.
 *
 */

export const getGroups = createSelector(
  [getConfirmedSlots, getProductionGroups],
  (slots, productionGroups) => {
    const slotGroups = slots.reduce(
      (groups, slot) => groups.add(slot.group_id),
      new Set()
    );
    let groups = [];

    productionGroups
      .filter(g => slotGroups.has(g.id))
      .map(({ name, order, id }) =>
        groups.push({
          id: `ref-${id}`,
          name,
          order
        })
      );
    return groups;
  }
);

/**
 * @function getLocations
 * Checks the confirmed slots for their group_id's and returns these groups from the production.groups array in to a new
 * callsheet.groups array.
 *
 * @param {Array} slots - Confirmed slots from getConfirmedSlots
 * @param {Object} contacts - Contacts store to get the appropriate resource data
 */

const getLocations = createSelector(
  [getConfirmedSlots, getContactStore],
  (slots, contacts) => {
    const locations = [];
    let order = 0;
    slots
      .filter(slot => slot.is_location)
      .map(locationSlot =>
        locationSlot.resource_slot_assignments.map(assignment => {
          const contact = get(contacts, ['data', assignment.contact_id], {});

          if (contact) {
            const location = {
              id: `temp-${v4()}`,
              order: order++,
              note: '',
              location_label: get(contact, 'full_name'),
              location_name: get(contact, ['locations', 0, 'name']),
              location_coordinates: get(contact, [
                'locations',
                0,
                'coordinates'
              ])
            };
            locations.push(location);
          }
          return false;
        })
      );
    return locations;
  }
);

/**
 * @function getTeamMembers
 * Creates team members /resources derived from the confirmed bookings found within valid slots.
 * It creates the team_members object for a callsheet by grabbing the resource (contact) details
 * as well as the selected callTime.
 *
 * @param {Time} callTime - Callsheet call time to populate the default of each team_member
 * @param {Object} contacts - Contacts store to grab the appropriate resource details
 * @param {Array} slots - The approved slot list from getConfirmedSlots
 * @param {Function} getSlotName - Function to another helper that gets the team_member.role from slot_categories
 * @param {Object} getCallsheet - If an originalCallsheet exists then we want to get those matching team_members and keep valuable data
 *
 */

export const getTeamMembers = createSelector(
  [
    getCallTime,
    getContactStore,
    getConfirmedSlots,
    makeGetSlotName,
    (_, { callsheet }) => callsheet,
    getBookingStore,
    getCustomFieldDefinitions
  ],
  (
    callTime,
    contacts,
    slots,
    getSlotName,
    originalCallsheet,
    bookings,
    customFieldDefinitions
  ) => {
    const teamMembers = [];
    let order = 0;
    slots.map(resourceSlot =>
      resourceSlot.resource_slot_assignments.map(assignment => {
        let resource = {
          id: `temp-${v4()}`,
          group_id: `ref-${resourceSlot.group_id}`,
          order: order
        };

        let originalCallsheetHasTeamMember = originalCallsheet
          ? find(
              originalCallsheet.team_members,
              t => t.contact_id === assignment.contact_id
            )
          : null;

        const booking = get(bookings, ['data', assignment.booking_id], null);

        // Find the PO number field in the booking, if one exists.
        let purchaseOrderNumber = null;
        if (booking) {
          const bookingCustomFields = get(
            booking,
            'booking_type.metastructure.fields',
            []
          );
          const purchaseOrderFieldDefinition = bookingCustomFields.find(
            bookingCustomField => {
              return !!customFieldDefinitions.find(
                ({ id, data_type }) =>
                  id === bookingCustomField.custom_field_definition_id &&
                  data_type === CustomFieldType.NETSUITE_PO
              );
            }
          );
          if (purchaseOrderFieldDefinition) {
            const purchaseOrderField = booking.custom_fields.find(field => {
              return (
                field.custom_field_definition_id ===
                purchaseOrderFieldDefinition.custom_field_definition_id
              );
            });
            purchaseOrderNumber = purchaseOrderField?.string_value;
          }
        }

        if (originalCallsheetHasTeamMember) {
          originalCallsheetHasTeamMember = omit(
            originalCallsheetHasTeamMember,
            'contact'
          );
          resource = { ...originalCallsheetHasTeamMember, ...resource };
        } else {
          const contact = get(contacts, ['data', assignment.contact_id], {});
          const orderedEmails = orderBy(contact.emails, 'id');

          resource = {
            ...resource,
            display_role: getSlotName(resourceSlot),
            display_name: get(contact, 'full_name', ''),
            display_email: get(orderedEmails, [0, 'value_1']),
            display_phone_number: get(contact, ['phone_numbers', 0, 'value_1']),
            contact_details_visible: true,
            display_profile_picture: get(contact, 'profile_picture'),
            display_purchase_order_number: purchaseOrderNumber,
            call_time: callTime || DEFAULT_START_TIME,
            contact_id: get(assignment, 'contact_id'),
            order: order
          };
        }
        order++;
        return teamMembers.push(resource);
      })
    );
    return teamMembers;
  }
);

/**
 * @function generateCallsheetFromProduction
 * This uses the functions above to create a new callsheet from production details
 *
 * @param {Time} fromDate - Callsheet call time to populate the default of each team_member
 * @param {Object} callTime - Callsheet call time to populate the default of each team_member
 * @param {Array} production - The production to generate values for some of the callsheet fields
 * @param {Function} teamMembers - Confirmed team members on the selected fromDate retrieved from getTeamMembers
 * @param {Object} groups - Groups retrieved from production groups using the getGroups
 * @param {Object} locations - Locations retrieved from confirmed bookings and lots using getLocations
 *
 */

export const generateCallsheetFromProduction = createSelector(
  [
    getFromDate,
    getCallTime,
    getProduction,
    getTeamMembers,
    getGroups,
    getLocations
  ],
  (fromDate, callTime, production, teamMembers, groups, locations) => {
    if (!isEmpty(production)) {
      return {
        title: generateCallsheetTitleFromProduction(production),
        from_date: fromDate || generateCallsheetFromDate(production),
        production_date: generateCallsheetProductionDate(production),
        call_time: callTime || DEFAULT_START_TIME,
        timezone: production.timezone || moment().tz(),
        production_id: production.id,
        team_members: teamMembers,
        groups,
        locations
      };
    }
    return {};
  }
);

export const duplicateCallsheetImpl = (
  fromDate,
  production,
  teamMembers,
  groups,
  originalCallsheet
) => {
  const originalCallsheetSanitised = removeCallsheetSystemFields(
    originalCallsheet
  );
  let newCallsheet = {
    ...originalCallsheetSanitised,
    status: 'BUILDING',
    from_date: fromDate || generateCallsheetFromDate(production),
    attachments: map(originalCallsheetSanitised.attachments, attachment =>
      omit(attachment, ['id', 'entity_id', 'file'])
    )
  };
  // WITH PRODUCTION
  // If theres is a production then we populate the appropriate production fields
  // and we populate the groups and team members according to the confirmed resources
  // on the selected fromDate
  if (!isEmpty(production)) {
    return {
      ...newCallsheet,
      production_date: generateCallsheetProductionDate(production),
      production_id: production.id,
      groups: groups,
      team_members: teamMembers
    };
  } else {
    // WITHOUT PRODUCTION
    // If theres is no production we simply loop through the existing callsheet data
    // and remove the id's so we can create them again like for like.
    return {
      ...newCallsheet,
      groups: map(originalCallsheetSanitised.groups, group => ({
        ...group,
        id: `ref-${group.id}`
      })),
      team_members: map(
        originalCallsheetSanitised.team_members,
        team_member => {
          team_member = omit(team_member, ['contact', 'callsheet_id']);
          return {
            ...team_member,
            group_id: `ref-${team_member.group_id}`,
            id: `temp-${team_member.id}`
          };
        }
      )
    };
  }
};
