import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'v1/components/shared';
import AuthService from 'lib/auth/AuthService';
import Account from 'lib/auth/Account';
import { get } from 'lodash';
import classnames from 'classnames';

import { Capability, FeatureFlag } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';
import IntegrationRestrictedAccess from 'v1/components/feature/AccountSettingsComponents/AccountSettingsIntegrations/IntegrationRestrictedAccess/IntegrationRestrictedAccess';

import './AccountSettingsSidebar.scss';

export class AccountSettingsSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleLogout = () => AuthService.logout();

  render() {
    const { app_id, settings } = this.props.auth;
    const appPath = `/app/${app_id}/settings`;
    const currentPath = get(this.props.location, 'pathname');
    const page = currentPath ? currentPath.split(appPath).pop() : '';
    const companyName = get(settings, 'settings.public_company_name.value');
    const account = Account.account();

    return (
      <div className={classnames('AccountSettingsSidebar')}>
        <div className="AccountSettingsSidebar-header">
          <h3>{companyName}</h3>
        </div>
        <div className="AccountSettingsSidebar-linksContainer">
          <h5>Preferences</h5>
          <Button
            href={`/app/${app_id}/settings/account/details`}
            className={classnames(
              { active: page.includes('/account/details') },
              ['AccountSettingsSidebar-link']
            )}
          >
            Personal account
          </Button>
          {account.sso_provider === 'local' ? (
            <Button
              href={`/app/${app_id}/settings/account/change_password`}
              className={classnames(
                { active: page.includes('/account/change_password') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Change password
            </Button>
          ) : null}
          {Account.isSuperUser() && (
            <>
              <h5>Team Members</h5>
              <RestrictedAccess capabilities={Capability.CONFIGURE_ROLES}>
                <Button
                  href={`/app/${app_id}/settings/company-users`}
                  className={classnames(
                    { active: page.includes('/company-users') },
                    ['AccountSettingsSidebar-link']
                  )}
                >
                  User Groups
                </Button>
              </RestrictedAccess>
              <RestrictedAccess capabilities={Capability.CONFIGURE_TEAM}>
                <Button
                  href={`/app/${app_id}/settings/roles`}
                  className={classnames({ active: page.includes('/roles') }, [
                    'AccountSettingsSidebar-link'
                  ])}
                >
                  Roles
                </Button>
              </RestrictedAccess>
            </>
          )}
          <RestrictedAccess capabilities={Capability.CONFIGURE_WORKSPACE}>
            <h5>Workspace</h5>
            <Button
              href={`/app/${app_id}/settings/workspace/logo`}
              className={classnames(
                { active: page.includes('/workspace/logo') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Logo
            </Button>
            <Button
              href={`/app/${app_id}/settings/workspace/timezone`}
              className={classnames(
                { active: page.includes('/workspace/timezone') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Timezone
            </Button>
            <Button
              href={`/app/${app_id}/settings/workspace/currency`}
              className={classnames(
                { active: page.includes('/workspace/currency') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Currency
            </Button>
            <h5>Public Theme</h5>
            <Button
              href={`/app/${app_id}/settings/public_theme/logo`}
              className={classnames(
                { active: page.includes('/public_theme/logo') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Public logo
            </Button>
            <Button
              href={`/app/${app_id}/settings/public_theme/name`}
              className={classnames(
                { active: page.includes('/public_theme/name') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Public name
            </Button>
            <Button
              href={`/app/${app_id}/settings/public_theme/team_name`}
              className={classnames(
                { active: page.includes('/public_theme/team_name') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Public team name
            </Button>
            <Button
              href={`/app/${app_id}/settings/public_theme/colours`}
              className={classnames(
                { active: page.includes('/public_theme/colours') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Public colours
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            capabilitiesAtLeastOne={[
              Capability.CONFIGURE_FIELDS,
              Capability.CONFIGURE_RESOURCE_TYPES,
              Capability.CONFIGURE_PRODUCTION_TYPES,
              Capability.CONFIGURE_BOOKING_TYPES,
              Capability.CONFIGURE_EXPENSE_TYPES,
              Capability.CONFIGURE_TAGS,
              Capability.CONFIGURE_RATE_CARDS,
              Capability.CONFIGURE_PRODUCTION_TEMPLATES
            ]}
          >
            <h5>Customise</h5>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.CONFIGURE_FIELDS}>
            <Button
              href={`/app/${app_id}/settings/fields`}
              className={classnames({ active: page.includes('/fields') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Fields
            </Button>
          </RestrictedAccess>
          <RestrictedAccess flag={FeatureFlag.PRODUCTIONS}>
            <Button
              href={`/app/${app_id}/settings/statuses`}
              className={classnames({ active: page.startsWith('/statuses') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Statuses
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.RESOURCES}
            capabilities={Capability.CONFIGURE_RESOURCE_TYPES}
          >
            <Button
              href={`/app/${app_id}/settings/resource_types`}
              // page.includes will have to be more specific, to avoid mismatching /settings/fields/resource_types and /settings/resource_types
              className={classnames(
                { active: page.startsWith('/resource_types') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Resource types
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.PRODUCTIONS}
            capabilities={Capability.CONFIGURE_PRODUCTION_TYPES}
          >
            <Button
              href={`/app/${app_id}/settings/production_types`}
              className={classnames(
                { active: page.startsWith('/production_types') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Production types
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.BOOKINGS}
            capabilities={Capability.CONFIGURE_BOOKING_TYPES}
          >
            <Button
              href={`/app/${app_id}/settings/booking_types`}
              className={classnames(
                { active: page.startsWith('/booking_types') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Booking types
            </Button>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.CONFIGURE_EXPENSE_TYPES}>
            <Button
              href={`/app/${app_id}/settings/expense_types`}
              className={classnames(
                { active: page.startsWith('/expense_types') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Expense types
            </Button>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.CONFIGURE_TAGS}>
            <Button
              href={`/app/${app_id}/settings/tags`}
              className={classnames({ active: page.includes('/tags') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Tags
            </Button>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.CONFIGURE_SLOT_CATEGORIES}>
            <Button
              href={`/app/${app_id}/settings/slot_categories`}
              className={classnames(
                { active: page.includes('/slot_categories') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Slot categories
            </Button>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.CONFIGURE_RATE_CARDS}>
            <Button
              href={`/app/${app_id}/settings/rates`}
              className={classnames({ active: page.includes('/rates') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Rate cards
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.PRODUCTIONS}
            capabilities={Capability.CONFIGURE_PRODUCTION_TYPES}
          >
            <Button
              href={`/app/${app_id}/settings/callsheet_templates`}
              className={classnames(
                { active: page.startsWith('/callsheet_templates') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Callsheet templates
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.PRODUCTIONS}
            capabilities={Capability.CONFIGURE_PRODUCTION_TEMPLATES}
          >
            <Button
              href={`/app/${app_id}/settings/production_templates`}
              className={classnames(
                { active: page.includes('/production_templates') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Production templates
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.BOOKING_CONFIRMATION}
            capabilities={Capability.BOOKING_SEND_CONFIRMATION}
          >
            <Button
              href={`/app/${app_id}/settings/booking_confirmation_template`}
              className={classnames(
                { active: page.startsWith('/booking_confirmation_template') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Confirmation template
            </Button>
          </RestrictedAccess>
          <IntegrationRestrictedAccess>
            <Button
              href={`/app/${app_id}/settings/integrations`}
              className={classnames(
                { active: page.startsWith('/integrations') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Integrations
            </Button>
          </IntegrationRestrictedAccess>
          <RestrictedAccess
            capabilitiesAtLeastOne={[
              Capability.MANAGE_DATA_IMPORTS,
              Capability.CONFIGURE_TEAM
            ]}
          >
            <Button
              href={`/app/${app_id}/settings/alerts`}
              className={classnames({ active: page.includes('/alerts') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Alerts
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            flag={FeatureFlag.NIKECUSTOM_ARRIVAL_BOARD}
            capabilities={Capability.CONFIGURE_CUSTOM_NIKE_ARRIVAL_BOARD}
          >
            <Button
              href={`/app/${app_id}/settings/arrival_board`}
              className={classnames(
                { active: page.includes('/arrival_board') },
                ['AccountSettingsSidebar-link']
              )}
            >
              Arrival board
            </Button>
          </RestrictedAccess>
          <RestrictedAccess
            capabilitiesAtLeastOne={[
              Capability.MANAGE_DATA_IMPORTS,
              Capability.CONFIGURE_TEAM
            ]}
          >
            <h5>DATA</h5>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.MANAGE_DATA_IMPORTS}>
            <Button
              href={`/app/${app_id}/settings/imports`}
              className={classnames({ active: page.includes('/imports') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Imports
            </Button>
          </RestrictedAccess>
          <RestrictedAccess capabilities={Capability.CONFIGURE_TEAM}>
            <Button
              href={`/app/${app_id}/settings/duplicates`}
              className={classnames({ active: page.includes('/duplicates') }, [
                'AccountSettingsSidebar-link'
              ])}
            >
              Duplicates
            </Button>
          </RestrictedAccess>
          <h5>Atellio</h5>
          <a
            href="https://www.notion.so/atellio/Atellio-Product-Roadmap-22e7f00b0d904c0badf86768723e80fd"
            target="_blank"
            rel="noopener noreferrer"
            className="AccountSettingsSidebar-link"
          >
            Product roadmap
          </a>
          <a
            href="https://www.notion.so/atellio/What-s-new-on-Atellio-4e13fb0d00384e9bae5505a3448a0390"
            target="_blank"
            rel="noopener noreferrer"
            className="AccountSettingsSidebar-link"
          >
            Product updates
          </a>
          <Button
            onClick={this.handleLogout}
            className="AccountSettingsSidebar-link logout"
          >
            Log out
          </Button>
          <div className="AccountSettingsSidebar-footer">Version 1.3.49</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ events, auth }) {
  return { events, auth };
}

export default withRouter(connect(mapStateToProps)(AccountSettingsSidebar));
