import React, { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Capability } from '__types__';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import useEvent from 'v1/helpers/hooks/useEvent';
import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import {
  getContact,
  getContactAssociations
} from 'store/v1/contacts/contacts.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';

import ResourcePaneHeader from './ResourcePaneHeader/ResourcePaneHeader';
import ResourcePaneTabs from './ResourcePaneTabs/ResourcePaneTabs';

import styles from './ResourcePane.module.scss';
import { useNewComponents_released } from '~/v4/core/migration-flag';
import { Loading } from '~/v1/components/shared';

const ResourcePane = ({
  // We pass in the whole resource, as if it's a new resource it's not in the store
  resource = {},
  resourceKey,
  autosave = true,
  hideTabs,
  hideFullLink,
  hideOptions,
  isReadOnly,
  // This is for clicking a submit button outside of the pane, eg in a modal
  setFormApi
}) => {
  const dispatch = useDispatch();
  const { data, associations_data } = useSelector(state => state.contacts);
  const storeResource =
    resource && resource.id ? get(data, resource.id, null) : null;
  const [activeResource, setActiveResource] = useState(resource);

  const userHasAccess = useRestrictedAccess(
    null,
    Capability.RESOURCE_UPDATE,
    null
  );

  useEffect(() => {
    if (resource.id && !storeResource) {
      dispatch(getContact(resource.id));
    } else if (resource.id && storeResource && !storeResource.resource_type) {
      // To determine if the store has the fully loaded resource we can check if resource_type exists.
      dispatch(getContact(resource.id));
    }
  }, [resource, storeResource, dispatch]);

  useEffect(() => {
    storeResource
      ? setActiveResource(storeResource)
      : setActiveResource(resource);
  }, [resource, storeResource]);

  useEvent([CONTACTS_ACTIONS.UPDATE_CONTACT], {
    onSuccess: () => {
      // If the resource is updated, we need to update the data in the sidebar
      storeResource && setActiveResource(storeResource);
      dispatch(displayAlert('success', 'Successfully updated'));
    },
    onFailure: () => dispatch(displayAlert('error', 'Update failed'))
  });

  useEffect(() => {
    if (!hideTabs && resource.id && !associations_data[resource.id]) {
      dispatch(getContactAssociations(resource.id));
    }
  }, []);

  return (
    <div className={styles.ResourcePane}>
      {activeResource && (
        <>
          {activeResource.id && (
            <ResourcePaneHeader
              resource={activeResource}
              hideTabs={hideTabs}
              hideOptions={hideOptions}
              hideFullLink={hideFullLink}
              isReadOnly={!userHasAccess}
            />
          )}
          <ResourcePaneTabs
            resource={activeResource}
            resourceKey={resourceKey}
            autosave={autosave}
            isReadOnly={!userHasAccess || isReadOnly}
            hideTabs={hideTabs}
            setFormApi={setFormApi}
          />
        </>
      )}
    </div>
  );
};

ResourcePane.props = {
  resource: PropTypes.object,
  resourceKey: PropTypes.string,
  autosave: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideFullLink: PropTypes.bool,
  hideOptions: PropTypes.bool,
  isReadOnly: PropTypes.bool
};

// migration behind feature flag

const NewResourcePane = lazy(
  () => import('v4/pages/resources/pane/ResourcePane')
);
const NewResourcePaneLoader = props => (
  <React.Suspense fallback={<Loading />}>
    <NewResourcePane {...props} />
  </React.Suspense>
);

// export default useNewComponents_released ? NewResourcePaneLoader : ResourcePane;
export default ResourcePane;
