import classNames from 'classnames';
import React, { HTMLProps } from 'react';

import styles from './ScrollableContainer.module.scss';

interface ScrollableContainerProps {
  rgbColor?: string;
}

const ScrollableContainer = ({
  children,
  className,
  rgbColor = '255, 255, 255',
  ...rest
}: HTMLProps<HTMLDivElement> & ScrollableContainerProps) => {
  const gradientTop = `linear-gradient(to top,rgba(${rgbColor}, 0), rgb(${rgbColor}))`;
  const gradientBottom = `linear-gradient(rgba(${rgbColor}, 0), rgb(${rgbColor}))`;
  return (
    <div className={styles.scrollableContainer} {...rest}>
      <div className={styles.scrollableContent_before} style={{ background: gradientTop }} />
      <div className={classNames(styles.scrollableContent, className)}>
        {children}
      </div>
      <div className={styles.scrollableContent_after} style={{ background: gradientBottom }} />
    </div>
  );
};

export default ScrollableContainer;
