import React from 'react';
import PropTypes from 'prop-types';

import { Grid, GridCell, Label } from 'v1/components/shared';

/**
 * @desc A 'section' of StackedForm, for form layouts
 */
const StackedFormSection = ({ title, description, children }) => (
  <div className="FormBuilder-FormGroup stack-S">
    <Grid gutters="S">
      <GridCell width="3/12">
        <Label size="L">{title}</Label>
        {description && (
          <div className="text-13-600-eggplant">{description}</div>
        )}
      </GridCell>
      <GridCell>{children}</GridCell>
    </Grid>
  </div>
);

StackedFormSection.props = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any
};

export default StackedFormSection;
