import { SYSTEM_ACTIONS } from 'store/v1/system/system.constants.js';
import { Mixpanel, MixActions } from 'lib/mixpanel';

const token = true;

export function searchSystem({ query }, event_id = null) {
  if (query) {
    Mixpanel.track(MixActions.SEARCH_GLOBAL, {
      query
    });
  }
  return function (dispatch) {
    dispatch({
      types: [
        SYSTEM_ACTIONS.SEARCH_SYSTEM,
        SYSTEM_ACTIONS.SEARCH_SYSTEM_SUCCESS,
        SYSTEM_ACTIONS.SEARCH_SYSTEM_FAILURE
      ],
      promise: client =>
        client.get(`/search/global`, { params: { query }, token }),
      event_id
    });
  };
}

export function getResourceDuplicates(
  { resource_type_id, page },
  query,
  event_id = null
) {
  return function (dispatch) {
    dispatch({
      types: [
        SYSTEM_ACTIONS.GET_RESOURCE_DUPLICATES,
        SYSTEM_ACTIONS.GET_RESOURCE_DUPLICATES_SUCCESS,
        SYSTEM_ACTIONS.GET_RESOURCE_DUPLICATES_FAILURE
      ],
      promise: client =>
        client.get(`/resources/${resource_type_id}/duplicates`, {
          params: { page },
          token
        }),
      event_id
    });
  };
}

export function mergeResource({ from_id, to_id }, event_id = null) {
  return function (dispatch) {
    dispatch({
      types: [
        SYSTEM_ACTIONS.MERGE_RESOURCE,
        SYSTEM_ACTIONS.MERGE_RESOURCE_SUCCESS,
        SYSTEM_ACTIONS.MERGE_RESOURCE_FAILURE
      ],
      promise: client =>
        client.put(`/resources/merge`, { data: { from_id, to_id }, token }),
      event_id
    });
  };
}
