import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkSecondaryProps {
  children: React.ReactNode;
  to: string;
}

const SidebarLinkSecondary = ({ children, to }: SidebarLinkSecondaryProps) => {
  return (
    <NavLink to={to} className={(isActive) => classNames("tw-rounded-md tw-truncate tw-text-eggplant-lightest tw-w-full tw-text-left tw-text-eggplant-light tw-p-2 tw-block hover:tw-bg-eggplant-lighter/60 hover:tw-text-white/100 tw-block focus:tw-outline-none focus:tw-text-white focus:tw-ring-2 focus:tw-ring-eggplant-lightest/60", { "tw-bg-eggplant-lighter/60 tw-text-white/100": isActive })}>
      {children}
    </NavLink>
  );
};

export default SidebarLinkSecondary;
