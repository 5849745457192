import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Fieldset.scss';

const Fieldset = ({ className, children, ...props }) => {
  return (
    <fieldset className={classnames('Fieldset', className)} {...props}>
      {/* First child of a <Fieldset> should always be a <Legend> */}
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node // Can take a string, func, element etc.
};

export default Fieldset;
