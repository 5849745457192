import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';
import { Grid, GridCell, ListCell, Status } from 'v1/components/shared';

import { getFormattedEventDates } from 'v1/helpers/byModel/EventHelper';

import classnames from 'classnames';
import get from 'lodash/get';

const CellBookingItem = ({ booking, onSelect, layout = {} }) => {
  const contacts = useSelector(state => state.contacts);
  function onCellClick() {
    onSelect && onSelect(booking);
  }

  return (
    <ListCell
      className={classnames([
        'CellBookingItem',
        layout.className,
        'Parent_hoverListener'
      ])}
      padding="XS"
      onClick={onSelect ? onCellClick : null}
    >
      <Grid vcenter gutters="M">
        <GridCell width="1/3" className="CellBookingItem-title">
          <span className="trunc">
            {get(contacts, ['data', booking.contact_id, 'full_name'])}
          </span>
        </GridCell>
        <GridCell className="text-13-600">
          {getFormattedEventDates(get(booking, ['events', 0]))}
        </GridCell>
        <GridCell width="auto" className="text-right">
          <Status statusId={booking.status_id} />
        </GridCell>
        {onSelect && (
          <GridCell width="auto" className="text-right Child_hoverListener">
            <img width="12px" src="/images/arrow_right.svg" alt="" />
          </GridCell>
        )}
      </Grid>
    </ListCell>
  );
};

CellBookingItem.propTypes = {
  layout: PropTypes.object,
  booking: PropTypes.object,
  onSelect: PropTypes.func
};

export default CellBookingItem;
