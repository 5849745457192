import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Geosuggest from 'react-geosuggest';
import { get, has } from 'lodash';

import { Label } from 'v1/components/shared';

const LocationInput = ({
  label,
  appearance = 'outline',
  size,
  placeholder = 'Search locations',
  value,
  disabled,
  onBlur,
  onChange
}) => {
  const handleChange = location => {
    const name = get(location, 'label', '');
    const coordinates = has(location, 'location')
      ? { lat: location.location.lat, lon: location.location.lng }
      : null;

    onBlur && onBlur({ name, coordinates });
    onChange && onChange({ name, coordinates });
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <Geosuggest
        inputClassName={classnames([
          'emulate-input',
          {
            [`emulate-input_${appearance}`]: appearance,
            [`emulate-input_${size}`]: size
          }
        ])}
        placeholder={placeholder}
        initialValue={get(value, 'name', '')}
        value={value || ''}
        disabled={disabled}
        autoActivateFirstSuggest
        autoComplete="off"
        onSuggestSelect={handleChange}
      />
    </>
  );
};

LocationInput.propTypes = {
  label: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'underline', 'silent']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.object
    // TODO: define these better
  }),
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default LocationInput;
