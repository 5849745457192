import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-form';
import { TextAreaInput, ErrorMessage } from 'v1/components/shared';
import { validate } from 'v1/helpers/validation';

class TextAreaField extends Component {
  handleValidate = value => {
    if (this.props.validate) {
      return validate(value, this.props.validate.split(','));
    }
  };
  render() {
    const { field, validate, onChange, ...rest } = this.props;

    return (
      <Field field={field} validate={this.handleValidate}>
        {fieldApi => (
          <>
            <TextAreaInput
              field={field}
              value={fieldApi.value || ''}
              onChange={(name, value) => {
                fieldApi.setValue(value);
                if (onChange) onChange(field, value);
              }}
              error={fieldApi.error}
              {...rest}
            />
            {fieldApi.error && <ErrorMessage>{fieldApi.error}</ErrorMessage>}
          </>
        )}
      </Field>
    );
  }
}

// NOTE: wrapper for TextAreaField
TextAreaField.propTypes = {
  field: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func // TODO: arguments
  // ...rest are from TextAreaField
};

export default TextAreaField;
