import { ARRIVALS_ACTIONS } from './arrivals.constants.js';

export const INITIAL_STATE = {
  list: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ARRIVALS_ACTIONS.GET_ARRIVALS:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case ARRIVALS_ACTIONS.GET_ARRIVALS_SUCCESS:
      return {
        ...state,
        list: action.result.arrivals,
        loading: false,
        fetched: true,
        settings: action.result.settings
      };
    case ARRIVALS_ACTIONS.GET_ARRIVALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
