import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Team } from '__types__';
import Account from 'lib/auth/Account';
import { archiveUserGroup } from 'store/userGroups';
import {
  getAccountTeams,
  getTeam,
  assignUserGroupToTeam
} from './teams.actions';

export type TeamsStoreData = Record<string, Team>;

export type TeamsStore = {
  data: TeamsStoreData;
  // order: number[]; // TODO
  // paging?: Paging; // TODO
  fetched?: boolean;
  loading?: boolean | string; // TODO
  error?: any; // TODO
};

export const INITIAL_STATE: TeamsStore = {
  data: {},
  // paging: {},
  // order: [],
  fetched: false,
  loading: false
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(
        getAccountTeams.fulfilled,
        (state, { payload }: PayloadAction<Team[]>) => {
          state.data = payload.reduce(
            (data: TeamsStoreData, item: Team) => ({
              ...data,
              [item.id]: item
            }),
            {}
          );
          state.fetched = true;
        }
      )

      .addCase(getTeam.fulfilled, (state, { payload }: PayloadAction<Team>) => {
        state.data[payload.id] = payload;
      })

      .addCase(assignUserGroupToTeam.fulfilled, (state, { meta: { arg } }) => {
        const teamId = Account.resolveFirstWorkspaceId();
        if (teamId) {
          state.data[teamId].userGroupIds = [
            ...state.data[teamId].userGroupIds,
            arg
          ];
        }
      })

      .addCase(archiveUserGroup.fulfilled, (state, { meta: { arg } }) => {
        const teamId = Account.resolveFirstWorkspaceId();
        if (teamId) {
          state.data[teamId].userGroupIds = state.data[
            teamId
          ].userGroupIds.filter(e => e !== arg);
        }
      })

      // Generic
      .addMatcher(
        ({ type }) => type && type.endsWith('/pending'),
        (state, { type }) => {
          state.loading = type.replace('/pending', '');
          state.error = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/fulfilled'),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        ({ type }) => type && type.endsWith('/rejected'),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  }
});

export default teamsSlice.reducer;
