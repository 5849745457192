import React from 'react';
import classnames from 'classnames';

import styles from './Text.module.scss';

type TextColor =
  | 'dark'
  | 'white'
  | 'light'
  | 'subtle'
  | 'reverse'
  | 'success'
  | 'warning'
  | 'info'
  | 'danger';
type TextFamily = 'sans' | 'serif';
type TextSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type TextWeight = 'regular' | 'semibold' | 'bold';

type TextProps = {
  color?: TextColor;
  family?: TextFamily;
  size?: TextSize;
  weight?: TextWeight;
  stack?: CSSSize;
  caps?: boolean;
  children: React.ReactNode;
};

// Text in a simple div
const Text = ({
  color,
  family,
  size,
  weight,
  caps,
  stack,
  children
}: TextProps) => (
  <div
    className={classnames(styles.Text, {
      [styles[`${color}`]]: color,
      [styles[`${size}`]]: size,
      [styles[`${weight}`]]: weight,
      [styles.serif]: family === 'serif',
      [styles['upper']]: caps,
      [`stack-${stack}`]: stack
    })}
  >
    {children}
  </div>
);

export default Text;
