import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCell, PressStud } from 'v1/components/shared';
import styles from './ResourcePortalHeader.module.scss';

const ResourcePortalHeader = ({ companyName, teamName, imagePath = '' }) => (
  <header className={styles['ResourcePortalHeader']}>
    <Grid cellPadding="M">
      <GridCell>
        <Grid vcenter>
          {imagePath && (
            <img
              className={styles['ResourcePortalHeader__logo']}
              src={imagePath}
              alt=""
            />
          )}
          <h3>
            {companyName} - {teamName}
          </h3>
        </Grid>
      </GridCell>
      <GridCell width="auto" vcenter>
        {/* <PressStud label="Create Account" isDisabled /> */}
      </GridCell>
    </Grid>
  </header>
);

ResourcePortalHeader.propTypes = {
  imagePath: PropTypes.string,
  companyName: PropTypes.string,
  teamName: PropTypes.string
};

export default ResourcePortalHeader;
