import { z } from 'zod';

export interface ShortlistListQuery {
  order_by?: {
    field: string;
    direction: 'asc' | 'desc';
  };
  filters?: Record<string, any>;
  query?: string;
  archived?: boolean;
  p?: number;
  count?: number;
}

export const shortlistActionSchema = z.object({
  id: z.number(),
  shortlist_id: z.number().optional(),
  shortlist_block_id: z.number().optional(),
  action_type: z.enum(['UPVOTE', 'DOWNVOTE', 'COMMENT']),
  value: z.string().nullable().optional(),
  user_id: z.string().nullable(),
  guest: z
    .object({
      id: z.string(),
      full_name: z.string().optional()
    })
    .nullable()
    .optional(),
  created_at: z.unknown(), // todo existing type asys Date, but server returns string timestamp
  updated_at: z.unknown() // todo existing type asys Date, but server returns string timestamp
});

export type ShortlistAction_normalized = z.infer<typeof shortlistActionSchema>;
