import _COMPANY_PROFESSIONS from './COMPANY_PROFESSIONS.json';
import _USER_TYPES from './USER_TYPES.json';
import _LEGAL_PERSON_TYPES from './LEGAL_PERSON_TYPES.json';
import _COUNTRIES from './COUNTRIES.json';
import _ACCOUNT_TYPES from './ACCOUNT_TYPES.json';
import _GENERIC_WORK_TAGS from './GENERIC_WORK_TAGS.json';
import _GENERIC_DISCIPLINES from './GENERIC_DISCIPLINES.json';
import _NATIONALITIES from './NATIONALITIES.json';
import _EXPERIENCES from './EXPERICENCES.json';
import _GDPR_BASIS from './GDPR_BASIS.json';
import _TIMEZONES from './TIMEZONES.json';
import _INVOICE_STATUS from './INVOICE_STATUS.json';
import _CONTACT_COMPOSITE_LABELS from './CONTACT_COMPOSITE_LABELS.json';
import _SOCIAL_PROVIDERS from './SOCIAL_PROVIDERS.json';

export { default as CONTACT_INFO_TYPES } from './CONTACT_INFO_TYPES.json';

export const COMPANY_PROFESSIONS = _COMPANY_PROFESSIONS;

export const USER_TYPES = _USER_TYPES;

export const LEGAL_PERSON_TYPES = _LEGAL_PERSON_TYPES;

export const COUNTRIES = _COUNTRIES;

export const ACCOUNT_TYPES = _ACCOUNT_TYPES;

export const GENERIC_WORK_TAGS = _GENERIC_WORK_TAGS;

export const GENERIC_DISCIPLINES = _GENERIC_DISCIPLINES;

export const NATIONALITIES = _NATIONALITIES;

export const EXPERIENCES = _EXPERIENCES;

export const GDPR_BASIS = _GDPR_BASIS;

export const TIMEZONES = _TIMEZONES;

export { default as Currencies } from './CURRENCIES';

export const INVOICE_STATUS = _INVOICE_STATUS;

export * from './RATE_INTERVALS';

export const CONTACT_COMPOSITE_LABELS = _CONTACT_COMPOSITE_LABELS;

export const SOCIAL_PROVIDERS = _SOCIAL_PROVIDERS;

export { default as CUSTOM_FIELD_TYPES } from './CUSTOM_FIELD_TYPES';
