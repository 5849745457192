import { Resource, Shortlist } from '__types__/index';
import useModal from 'hooks/useModal';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDerivedMutation } from '../../../pages/common/single-mutation';
import { createShortlistBatchAddModal } from '../../../pages/shortlists/batchAddModal/ShortlistBatchAddModal';
import { myResourceShortlistAssociations } from '../../associations/resource__shortlist/resource__shortlist.hooks.connected';
import { myShortlists } from '../../shortlist/shortlist.hooks.connected';
import { useAppRoutes } from './useAppRoutes';

export function useShortlistBatchDuplicate() {
  const modals = useModal();
  return (
    blocks: {
      association_id: number;
      tmpworkaround_shortlist_id: Shortlist['id'];
      tmpworkaround_resource_id: Resource['id'];
    }[],
    onAddComplete?: () => void
  ) => {
    // todo think about making some components not just factories, but rather a controlled components that can handle mutation outside!
    // otherwise we have to pass a lot of stuff to the factory, and it's not very convenient
    const ShortlistBatchDuplicateModal = createShortlistBatchAddModal({
      useItems: myShortlists.useList,
      useMutation: () =>
        useDerivedMutation(
          myResourceShortlistAssociations.useMutation(),
          mutationPayload => ({
            type: 'duplicate' as const,
            request: blocks.map(block => ({
              association_id: block.association_id,
              tmpworkaround_existing_shortlist_id:
                block.tmpworkaround_shortlist_id, ///??? maybe let's accept entire blocks with pins??? temporarily?
              new_resource_id: block.tmpworkaround_resource_id,
              new_shortlist_id: mutationPayload.shortlistId
            }))
          })
        ),
      useNavigateToShortlist: () => {
        const { push } = useHistory();
        const appLinks = useAppRoutes();
        return id => push(appLinks.shortlists.detail(id));
      }
    });

    modals.openModal(() => (
      <ShortlistBatchDuplicateModal
        resourceIds={blocks.map(p => p.tmpworkaround_resource_id)}
        // todo looks like this modal should be controlled component, not a factory. Factory should be an extra layer of abstraction
        onAddComplete={onAddComplete}
      />
    ));
  };
}
