import React from 'react';

import styles from './ModalContent.module.scss';

type ModalContentProps = {
  children: React.ReactNode;
};

const ModalContent = ({ children }: ModalContentProps) => (
  <div className={styles.ModalContent}>{children}</div>
);

export default ModalContent;
