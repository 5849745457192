import { getEventDates } from 'v1/helpers/byModel/EventHelper';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import _get from 'lodash/get';
import _find from 'lodash/find';
import AssignmentValidationError from 'lib/errors/AssignmentValidationError';
import ValidationErrorLevel from 'lib/errors/ValidationErrorLevel';
import { chain } from 'lodash';
const moment = extendMoment(Moment);

/**
 * @function getDefaultStatus
 * Logic to find the best default status to populate a new booking
 */
export const getDefaultStatus = (statusId, statuses) => {
  if (statusId) {
    return _find(statuses, s => s.id === statusId);
  }
  if (localStorage['Atellio.DefaultStatusID']) {
    return _find(
      statuses,
      s => s.id == localStorage['Atellio.DefaultStatusID']
    );
  }
  return (
    _find(statuses, s => s.status_type === 'PENCILLED') ||
    _find(statuses, s => s.status_type === 'AVAILABLE')
  );
};

/**
 * Run validation checks for the entire booking.
 * Checks if dates of assignments fits within booking and productions
 *
 */
export function runBookingChecks(booking, productions_data = {}) {
  /*const bookingRanges = booking.events.map(e => {
    const dates = getEventDates(e);
    return moment.range(...dates);
  });*/

  if (booking.resource_slot_assignments) {
    booking.resource_slot_assignments.forEach(assignment =>
      runAssignmentChecks(assignment, booking, productions_data)
    );
  }
}

/**
 * Run validation checks for a single assignment.
 * Checks if dates of assignment fits within booking and production
 *
 */
export function runAssignmentChecks(
  assignment,
  booking,
  productions_data = {},
  production_id
) {
  const bookingRanges = booking.events.map(e => {
    const dates = getEventDates(e);
    return moment.range(...dates);
  });
  const assignmentRanges = assignment.events.map(e => {
    const dates = getEventDates(e);
    return moment.range(...dates);
  });
  // Check if each assignment event fits in any booking event
  assignmentRanges.forEach(assignmentRange => {
    const fits = bookingRanges.some(r =>
      r.contains(assignmentRange, { adjacent: true })
    );
    if (!fits) {
      throw new AssignmentValidationError(
        ValidationErrorLevel.INFO,
        'The assignment dates are outside the booking dates.'
      );
    }
  });

  const production =
    productions_data[_get(assignment, 'resource_slot.production_id')] ||
    productions_data[production_id];

  if (production && production.production_date) {
    const productionDates = getEventDates(production.production_date);
    const productionRange = moment.range(...productionDates);
    assignmentRanges.forEach(assignmentRange => {
      if (!productionRange.contains(assignmentRange, { adjacent: true })) {
        throw new AssignmentValidationError(
          ValidationErrorLevel.INFO,
          'The assignment dates are outside the production date.'
        );
      }
    });
  }
}

/**
 * Helpers for the two functions above. Returns the error instead of throwing it
 */
export function getBookingError(...params) {
  try {
    runBookingChecks(...params);
  } catch (err) {
    return err;
  }
  return null;
}
export function getAssignmentError(...params) {
  try {
    runAssignmentChecks(...params);
  } catch (err) {
    return err;
  }
  return null;
}
