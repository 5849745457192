import React from 'react';
import classnames from 'classnames';

import styles from './SidebarTabs.module.scss';

type SidebarTabProps = {
  title: string;
  isActive: boolean;
  onClick: () => void;
};

const SidebarTab = ({ title, isActive, onClick }: SidebarTabProps) => (
  <div
    className={classnames(styles.Item, {
      [styles.Item_active]: isActive
    })}
    onClick={onClick}
  >
    {title}
  </div>
);

const SidebarSeparator = () => <div className={styles.Separator} />;

type SidebarTabsProps = {
  children: React.ReactNode;
};

type SidebarTabsSubComponents = {
  Tab: typeof SidebarTab;
  Separator: typeof SidebarSeparator;
};

const SidebarTabs: React.FC<SidebarTabsProps> & SidebarTabsSubComponents = ({
  children
}) => {
  return <div className={styles.SidebarTabs}>{children}</div>;
};

SidebarTabs.Tab = SidebarTab;
SidebarTabs.Separator = SidebarSeparator;

export default SidebarTabs;
