import { createSelector } from 'reselect';

import { RootStore } from 'store/reducer';

const selectRegisterAccountStore = (state: RootStore) => state.register;

const selectRegisterAccountLoading = createSelector(
  selectRegisterAccountStore,
  register => register.loading
);

const selectRegisterAccountToken = createSelector(
  selectRegisterAccountStore,
  register => register.token
);

export { selectRegisterAccountLoading, selectRegisterAccountToken };
