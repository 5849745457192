import { STATUSES_ACTIONS } from 'store/v1/statuses/statuses.constants.js';

const token = true;

// UNUSED
// export const getStatuss = (event_id = null) => {
//   return {
//     types: [
//       STATUSES_ACTIONS.GET_STATUSES,
//       STATUSES_ACTIONS.GET_STATUSES_SUCCESS,
//       STATUSES_ACTIONS.GET_STATUSES_FAILURE
//     ],
//     promise: client => client.get('/statuses', { token }),
//     event_id
//   };
// };

export const createStatus = (data, event_id = null) => {
  return {
    types: [
      STATUSES_ACTIONS.CREATE_STATUS,
      STATUSES_ACTIONS.CREATE_STATUS_SUCCESS,
      STATUSES_ACTIONS.CREATE_STATUS_FAILURE
    ],
    promise: client => client.post('/statuses', { data, token }),
    event_id
  };
};

export const updateStatus = (id, data, event_id = null) => {
  return {
    types: [
      STATUSES_ACTIONS.UPDATE_STATUS,
      STATUSES_ACTIONS.UPDATE_STATUS_SUCCESS,
      STATUSES_ACTIONS.UPDATE_STATUS_FAILURE
    ],
    promise: client =>
      client.put(`/statuses/${id}`, {
        data,
        token
      }),
    event_id
  };
};

export const updateStatusesOrder = (data, event_id = null) => {
  const payload =
    data &&
    data.map(status => ({
      id: status.id,
      order: status.order
    }));
  return {
    types: [
      STATUSES_ACTIONS.UPDATE_STATUSES_ORDER,
      STATUSES_ACTIONS.UPDATE_STATUSES_ORDER_SUCCESS,
      STATUSES_ACTIONS.UPDATE_STATUSES_ORDER_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/statuses`, {
        data: { reorder: payload },
        token
      }),
    event_id
  };
};

export const deleteStatus = (id, event_id = null) => {
  return {
    types: [
      STATUSES_ACTIONS.DELETE_STATUS,
      STATUSES_ACTIONS.DELETE_STATUS_SUCCESS,
      STATUSES_ACTIONS.DELETE_STATUS_FAILURE
    ],
    promise: client => client.del(`/statuses/${id}`, { token }),
    event_id,
    id
  };
};
