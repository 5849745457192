import React from 'react';
import PropTypes from 'prop-types';

import './StackedForm.scss';

/**
 * @desc A layout wrapper component for displaying forms in a 'stacked' layout. Should contain StackedFormSection
 */
const StackedForm = ({ children, ...props }) => (
  <form className="StackedForm" {...props}>
    {children}
  </form>
);

StackedForm.props = {
  children: PropTypes.any
};

export default StackedForm;
