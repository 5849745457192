import classnames from 'classnames';
import { forwardRef, HTMLProps } from 'react';

// Tailwind preflight was descoped to some selector due to legacy global CSS Reset
export const TailwindRoot = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(({ className, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={classnames(className, getTailwindRootClassName())}
      {...rest}
    />
  );
});

export function getTailwindRootClassName() {
  return 'tailwind-root';
}
