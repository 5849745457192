import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import filesize from 'filesize';
import { renderIcon } from 'lib/media/files';
import { truncateString } from 'v1/helpers/misc';
import { makeFileURL } from 'lib/s3/s3Helper';
import './FileItem.scss';
import { Grid, GridCell } from 'v1/components/shared';

class FileItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deleting && !nextProps.deleting) {
      this.setState({ isDeleting: false });
    }
  }

  renderFilePreview = file => {
    const extension = file.mime_type ? file.mime_type.split('/').pop() : 'File';

    let preview;
    switch (file.mime_type) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        preview = <img className="content" src={makeFileURL(file)} alt="" />;
        break;
      default:
        preview = (
          <span className="FileItem-preview-graphic">
            <span className="FileItem-preview-extension">{extension} File</span>
            <br />
            <span className="FileItem-preview-graphic-label">
              {truncateString(file.name, 100)}
            </span>
          </span>
        );
    }

    return (
      <a
        href={makeFileURL(file)}
        target="_blank"
        rel="noopener noreferrer"
        className={classnames(['FileItem-preview', extension])}
      >
        {preview}
      </a>
    );
  };
  renderExpandedView = file => {
    return (
      <div
        className={classnames({ deleting: this.state.isDeleting }, [
          'FileItem Expanded',
          this.props.className
        ])}
      >
        {this.renderFilePreview(file)}
      </div>
    );
  };
  renderInlineView(file) {
    if (!file) return null;

    return (
      <div
        className={classnames({ deleting: this.props.deleting }, [
          'FileItem Inline',
          this.props.className
        ])}
      >
        <img src={renderIcon(file.name)} className="fileIcon" alt="" />
        <div className="FileItem-description">
          <a
            href={makeFileURL(file)}
            className="FileItem-label"
            target="_blank"
            rel="noopener noreferrer"
            title={file.name}
          >
            {file.name || 'Untitled'}
            <span className="linkHint">Download</span>
          </a>
          <div className="FileItem-meta">
            {file.provider === 'GOOGLE' ? 'Google Drive' : null}{' '}
            {moment(file.created_at).fromNow()}{' '}
            {file.size ? filesize(file.size) : null}
          </div>
        </div>
        {this.props.children}
        <span className="FileItem-actions">
          {!this.props.hideDelete && (
            <span
              className="FileItem-actionItem"
              onClick={() => this.props.onDelete(file.id)}
              data-tip="Archive"
            >
              <img src="/images/icon_delete.svg" width="14px" alt="" />
            </span>
          )}
        </span>
      </div>
    );
  }

  renderLinkView = file => {
    return (
      <a
        href={makeFileURL(file)}
        className="genericLink trunc inline"
        target="_blank"
        rel="noopener noreferrer"
        title={file.name}
        data-tip="Download"
      >
        <img src="/images/icon_share_link.svg" width="12px" alt="" />{' '}
        {file.name || 'Untitled'}
      </a>
    );
  };

  renderCompactView = file => {
    return (
      <Grid cellPadding="XS" className="FileItem-compact">
        <GridCell width="auto">
          <img src={renderIcon(file.name)} alt="" />
        </GridCell>
        <GridCell width="fill">
          <a
            href={makeFileURL(file)}
            className="FileItem-compact-link"
            target="_blank"
            rel="noopener noreferrer"
            title={file.name}
          >
            {file.name || 'Untitled'}
          </a>
        </GridCell>
        <GridCell width="auto">
          {!this.props.hideDelete && (
            <a
              className="FileItem-compact-link-delete"
              onClick={() => this.props.onDelete(file.id)}
              data-tip="Delete file"
            >
              <img src="/images/icon_delete.svg" width="14px" alt="" />
            </a>
          )}
        </GridCell>
      </Grid>
    );
  };

  render() {
    const { file, layout } = this.props;
    if (!file) return <span className="text-neutral">No File</span>;
    switch (layout) {
      case 'EXPANDED':
        return this.renderExpandedView(file);
      case 'INLINE':
      default:
        return this.renderInlineView(file);
      case 'LINK':
        return this.renderLinkView(file);
      case 'COMPACT':
        return this.renderCompactView(file);
    }
  }
}

FileItem.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOf(['EXPANDED', 'INLINE', 'LINK', 'COMPACT']),
  deleting: PropTypes.bool,
  file: PropTypes.any, // TODO: type
  children: PropTypes.any,
  onDelete: PropTypes.func
};

export default FileItem;
