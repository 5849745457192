import { useQuery, useQueryClient } from '@tanstack/react-query';
import { keyResolver } from '@yornaath/batshit';
import { Booking, Event, Resource, Shortlist } from '__types__/index';
import { isShortlistBlockWithLegacyContactField } from '__types__/v1/models/Shortlist.helpers';
import { get } from 'lodash';
import { BookingApi } from 'v4/entities/booking/booking.http';
import { ListQuery, ListResponse } from 'v4/entities/common/common.crud.types';
import { useIsGuest } from 'v4/entities/common/hooks/useIsGuest';
import { shortlistInternals } from 'v4/entities/shortlist/shortlist.hooks';
import { createBatcher } from '../common/batcher';
import { createBookingsListBatcher } from './bookings.listBatcher';

type MaybeBookingId = Resource['id'] | undefined; // hooks should support pages that are not associated with any resource at given moment

export const bookingKeys = {
  all: () => ['bookings'] as const,
  lists: () => [...bookingKeys.all(), 'list'] as const,
  list: (query: unknown) => [...bookingKeys.lists(), { query }] as const,
  details: () => [...bookingKeys.all(), 'detail'] as const,
  detail: (id: MaybeBookingId) =>
    [...bookingKeys.details(), id === undefined ? id : String(id)] as const
};

export const createBookingHooks = (api: BookingApi) => {
  const batcher = createBatcher<Booking['id'], Booking[], Booking>(
    async (payload, signal) =>
      (await api.batchDetail({ ids: payload }, signal)).data,
    keyResolver('id')
  );

  const listBatcher = createBookingsListBatcher(api.list);

  const useList = (query: ListQuery<Booking>, enabled = true) => {
    const isGuest = useIsGuest();
    const client = useQueryClient();
    return useQuery({
      enabled,
      queryKey: bookingKeys.list(query),
      queryFn: ({ signal }) => listBatcher.fetch({ payload: query, signal }),
      initialData: () => {
        if (!isGuest) {
          // for authenticated users we can use the standard query
          return undefined;
        }
        const resourceIdSpec = query.filters?.contact_id?.eq;
        const resourceIdArray = (
          Array.isArray(resourceIdSpec) ? resourceIdSpec : [resourceIdSpec]
        ) as number[];
        if (resourceIdArray.length === 0) {
          return undefined;
        }
        /**
         * This function is a workaround for the fact that this query is not available for guests.
         * The only way to get this data is to find it in public shortlists.
         */
        function findInPublicShortlists():
          | ListResponse<Booking, ListQuery<Booking>>
          | undefined {
          const queriesData = client.getQueriesData<Shortlist>({
            queryKey: shortlistInternals.keys.details()
          });

          const bookings: Booking[] = [];
          for (const [key, shortlist] of queriesData) {
            if (shortlist?.shortlist_blocks) {
              for (const block of shortlist.shortlist_blocks) {
                if (isShortlistBlockWithLegacyContactField(block)) {
                  if (resourceIdArray.includes(block.contact.id)) {
                    for (const event of block.booking_events ?? []) {
                      bookings.push({
                        id: event.entity_id!,
                        status_id: get(event, 'booking_status_id'),
                        events: [event],
                        contact_id: block.contact.id,
                      } as Booking);
                    }
                  }
                }
              }
            }
          }

          return {
            query,
            paging: {
              page: 0,
              total: bookings.length,
              count_per_page: bookings.length ?? 0
            },
            results: bookings
          };
        }
        return findInPublicShortlists();
      }
    });
  };
  const useItem = (id: MaybeBookingId) => {
    const client = useQueryClient();

    return useQuery({
      queryKey: bookingKeys.detail(id),
      queryFn: ({ signal }) => batcher.fetch({ payload: id!, signal }),
      enabled: id !== undefined,
      initialData: () => {
        const queriesData = client.getQueriesData<
          ListResponse<Booking, ListQuery<Booking>>
        >({ queryKey: bookingKeys.lists() });
        return queriesData
          .find(
            ([key, list]) => list?.results.find(item => item.id === id)
          )?.[1]
          ?.results.find(item => item.id === id);
      }
    });
  };

  return {
    useList,
    useItem
  };
};
