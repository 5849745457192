import React from 'react';
import classnames from 'classnames';

import styles from './ModalScrollable.module.scss';

type ModalScrollableProps = {
  padding?: CSSSize;
  center?: boolean;
  children: React.ReactNode;
};

const ModalScrollable = ({
  padding = 'l',
  center,
  children
}: ModalScrollableProps) => (
  <div
    className={classnames([styles.ModalScrollable, `pad-${padding}`], {
      [styles.center]: center
    })}
  >
    {children}
  </div>
);

export default ModalScrollable;
