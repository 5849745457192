import React from 'react';
import classnames from 'classnames';
import './Masonry.scss';

let BREAK_POINTS = [500, 860, 1200];

export default class Masonry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { columns: 1 };
    this.onResize = this.onResize.bind(this);
    this.setMasonry = element => {
      this.masonry = element;
    };
  }
  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  };
  getColumns(w) {
    return (
      BREAK_POINTS.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, BREAK_POINTS.length) + 1
    );
  }

  onResize() {
    const columns = this.getColumns(this.masonry.offsetWidth);
    if (columns !== this.state.columns) {
      this.setState({ columns: columns });
    }
  }

  mapChildren() {
    let col = [];
    const numC = this.state.columns;
    for (let i = 0; i < numC; i++) {
      col.push([]);
    }
    return this.props.children.reduce((p, c, i) => {
      p[i % numC].push(c);
      return p;
    }, col);
  }

  render() {
    return (
      <div
        className={classnames(['Masonry', this.props.className])}
        ref={this.setMasonry}
      >
        {this.mapChildren().map((col, ci) => {
          return (
            <div className="column" key={ci}>
              {col.map((child, i) => {
                return <div key={i}>{child}</div>;
              })}
            </div>
          );
        })}
      </div>
    );
  }
}
