import React from 'react';

import { Icon } from 'shared';
import { Transition } from '@headlessui/react'

type SidebarAccordionProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  label: React.ReactNode;
  isExpanded: boolean;
  onToggle: () => void;
}

const SidebarAccordion = ({ children, label, icon, isExpanded, onToggle }: SidebarAccordionProps) => {
  return (
    <>
      <button onClick={onToggle} className="tw-rounded-md tw-w-full tw-flex tw-items-center tw-text-left tw-p-2 tw-block hover:tw-bg-eggplant-lighter/30 tw-block focus:tw-outline-none hover:tw-text-white/100 focus:tw-ring-2 focus:tw-ring-eggplant-lightest/60">
        <span className="tw-flex-0 tw-mr-3">{icon}</span>
        <span className="tw-flex-1">{label}</span>
        <span className="tw-flex-0">{isExpanded ? <Icon name="chevronDown" size="m" /> : <Icon name="chevronRight" size="s" />}</span>
      </button>
      <Transition
        show={isExpanded}
        enter="tw-transition-all tw-duration-300"
        enterFrom="tw-opacity-0 tw-max-h-0"
        enterTo="tw-opacity-100 tw-max-h-full"
        leave="tw-transition-all tw-duration-300"
        leaveFrom="tw-opacity-100 tw-max-h-full"
        leaveTo="tw-opacity-0 tw-max-h-0"
        className="tw-pl-8 tw-pt-1"
      >
        {children}
      </Transition>

    </>
  );
};

export default SidebarAccordion;
