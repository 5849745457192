import { Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'v1/components/shared';

import classnames from 'classnames';
import { convertImageFileWithCloudinary } from 'lib/media/imageHelper';

import { LazyImage } from 'v1/components/shared';

export default class PinImage extends Component {
  constructor(props) {
    super(props);
    this.image = {};
    this.state = {};
  }
  onLoad = () => {
    this.setState({
      loaded: true,
      height: this.image.clientHeight,
      width: this.image.clientWidth
    });
  };
  onError = () => {
    this.setState({
      error: true
    });
  };
  render() {
    const { height } = this.state;
    const {
      boundTo = { width: null, height: null },
      pin,
      settings
    } = this.props;
    const imageUrl = pin.url;
    const pinResizedFileURL = convertImageFileWithCloudinary(
      imageUrl,
      settings
    );

    return (
      <div
        className={classnames({ error: this.state.error }, ['PinImage'])}
        ref={element => (this.image = element)}
      >
        <LazyImage
          src={pin && pinResizedFileURL ? [pinResizedFileURL] : imageUrl}
          width={boundTo.height && height > boundTo.height ? 'auto' : '100%'}
          height={height > boundTo.height ? boundTo.height : '100%'}
          onLoad={this.onLoad}
          onError={this.onError}
          draggable="false"
          loader={<Loading options={{ disableText: true }} />}
          decode={false}
        />
      </div>
    );
  }
}

PinImage.propTypes = {
  boundTo: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  pin: PropTypes.object.isRequired,
  settings: PropTypes.object
};
