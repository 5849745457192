import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-form';
import { get, isEmpty, orderBy } from 'lodash';

import { FieldListSelection } from 'v1/components/shared';
import {
  PressStud,
  Checkbox,
  ErrorMessage,
  Label,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import {
  createFieldGroup,
  updateFieldGroup
} from 'store/v1/auth/auth.actions.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';

import useEvent from 'v1/helpers/hooks/useEvent';
import { validate as validator } from 'v1/helpers/validation';

function handleValidate(value, validate = '') {
  if (validate) return validator(value, validate.split(','));
}

const FieldGroupCreateModal = () => {
  // REDUX
  const auth = useSelector(state => state.auth);
  const { loading } = auth || {};

  const ui = useSelector(state => state.ui);
  const { data: uiData = {}, modalIsOpen } = ui || {};
  const { create, field = {} } = uiData;

  const dispatch = useDispatch();

  // PROPS
  const { name = '', custom_field_definitions = [], is_array = false } = field;

  const customFieldDefinitions = get(
    auth,
    'settings.custom_field_definitions',
    []
  );
  const customFieldsSorted = orderBy(custom_field_definitions, 'order');

  // STATE
  const [formApi, setFormApi] = useState();

  // EFFECTS
  useEvent([AUTH_ACTIONS.CREATE_FIELDGROUP, AUTH_ACTIONS.UPDATE_FIELDGROUP], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Updated succesfully'));
      if (modalIsOpen) dispatch(closeModal());
    },
    onFailure: () => dispatch(displayAlert('error', 'An error occurred'))
  });

  // METHODS
  function handleSubmit(values) {
    const { id } = field;
    const payload = {
      ...field,
      ...values,
      custom_field_definitions: !isEmpty(values.custom_field_definitions)
        ? values.custom_field_definitions.map((f, index) => ({
            id: f.id,
            order: index
          }))
        : null
    };

    if (create && id === undefined) {
      return dispatch(createFieldGroup(payload));
    }
    dispatch(updateFieldGroup(id, payload));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={`${create ? 'Create' : 'Edit'} field group`} />
      <ModalContent>
        <ModalScrollable>
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              name,
              custom_field_definitions: customFieldsSorted,
              is_array
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <div className="form-group">
                  <Field
                    field="name"
                    validate={value => handleValidate(value, 'required')}
                  >
                    {fieldApi => (
                      <Fragment>
                        <Label htmlFor={name}>Name</Label>
                        <TextInput
                          name="name"
                          value={fieldApi.value}
                          placeholder="Name"
                          onChange={({ target }) => {
                            fieldApi.setValue(target.value);
                          }}
                          validity={fieldApi.error ? 'invalid' : null}
                          // required // Let's reconsider these
                          // noValidate // when we look at validation
                          autoFocus={true}
                          autoComplete="off"
                        />
                        {fieldApi.error && (
                          <ErrorMessage>This field is required</ErrorMessage>
                        )}
                      </Fragment>
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <Field field="custom_field_definitions">
                    {fieldApi => (
                      <Fragment>
                        <Label>Fields</Label>
                        <FieldListSelection
                          fields={customFieldDefinitions}
                          defaultSelection={customFieldsSorted}
                          onChange={value => fieldApi.setValue(value)}
                        />
                      </Fragment>
                    )}
                  </Field>
                </div>
                <div className="form-group form-group_ruled">
                  <Field field="is_array">
                    {fieldApi => (
                      <Checkbox
                        name="is_array"
                        label="Allow multiple blocks"
                        id="is-array"
                        description="Allow the user to create more of these blocks within a form"
                        checked={fieldApi.value}
                        onChange={({ target }) =>
                          fieldApi.setValue(target.checked)
                        }
                      />
                    )}
                  </Field>
                </div>
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud label="Cancel" action={() => dispatch(closeModal())} />
          <PressStud
            label={create ? 'Create Field Group' : 'Save changes'}
            appearance="primary"
            isLoading={loading}
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default FieldGroupCreateModal;
