import _keys from 'lodash/keys';
import _pick from 'lodash/pick';

export function formatBooking(booking) {
  return _keys(booking).reduce((result, key) => {
    // if (!booking[key]) return result;
    // if (key == 'invoices') {
    //   return result;
    // }
    // Format events
    if (key === 'expenses') {
      const expenses = (booking.expenses || []).map(({ id, ...expense }) =>
        !id || (typeof id === 'string' && id.includes('temp-'))
          ? expense
          : { id, ...expense }
      );
      return { ...result, expenses };
    }
    if (key === 'events') {
      const events = (booking.events || []).map(({ id, _action, ...evt }) => {
        if (!id || (typeof id === 'string' && id.includes('temp-'))) return evt;
        return { id, ...evt };
      });
      return { ...result, events };
    }
    // Format contracts
    if (key === 'contracts') {
      const contracts = booking[key].map(c =>
        _pick(c, ['id', 'file_id', 'url', 'document_type', 'archived'])
      );
      return { ...result, contracts };
    }
    if (key === 'resource_slot_assignments') {
      const resource_slot_assignments = booking[key].map(
        ({ resource_slot, booking, contact, _action, ...assignment }) => {
          if (
            typeof assignment.id === 'string' &&
            assignment.id.includes('temp-')
          ) {
            delete assignment.id;
          }
          const events = assignment.events
            ? assignment.events.map(({ id, _action, ...evt }) =>
                typeof id === 'string' && id.includes('temp-')
                  ? evt
                  : { id, ...evt }
              )
            : events;
          return { ...assignment, events };
        }
      );
      return { ...result, resource_slot_assignments };
    }
    // Remove relations
    if (key === 'contact') return result;
    if (key === 'invoices') return result;
    // if (key == 'custom_fieldgroups') return result;
    // if (key == 'custom_fields') return result;
    if (key === 'booking_type') return result;
    if (key === 'assignmentsRemoved') return result;
    if (key === 'last_updated_by_id') return result;

    return { ...result, [key]: booking[key] };
  }, {});
}
