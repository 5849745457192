import Tracker from './Tracker';
import { MixActions } from './actions';

export default class InteractionTracker extends Tracker {
  /**
   * Receive a Mixpanel event and decide whether the tracker should process it
   * @param {MixpanelEvent} event
   */
  track(event) {
    switch (event.action) {
      case MixActions.EXPAND_TOPBAR_NEW_DROPDOWN:
      case MixActions.CLICK_TOPBAR_NEW_DROPDOWN_OPTION:
      case MixActions.CLICK_SIDEBAR_SETTINGS_LINK:
      case MixActions.CLICK_TOPBAR_WHATS_NEW_BUTTON:
      case MixActions.EXPAND_TOPBAR_HELP_BUTTON:
      case MixActions.SEARCH_GLOBAL:
      case MixActions.CLICK_SEARCH_GLOBAL_RESULT:
      case MixActions.SEARCH_MODULE:
        return this._onEvent(event);
      default:
        return;
    }
  }

  /**
   * Track a user interaction event
   * @param {MixpanelEvent} event
   * @private
   */
  _onEvent(event) {
    this.sendEvent(event.action, event, {
      ...event.properties
    });
  }
}
