import React from 'react';
import PropTypes from 'prop-types';

import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import TypeTextInput from 'v1/components/shared/byType/text/TextInput/__types__/TypeTextInput';
import { TextInput } from 'v1/components/shared';

interface EmailInputProps extends Omit<TextInputProps, 'onChange'> {
  showOverlay?: boolean;
  onChange?(value: string | null): void;
}

const EmailInput = ({
  placeholder,
  value,
  showOverlay = true,
  onChange,
  ...props
}: EmailInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(value);
  };

  const label = showOverlay && value ? value : undefined;
  const overlayAction = showOverlay ? `mailto:${value}` : null;

  return (
    <TextInput
      type="email"
      placeholder={placeholder !== undefined ? placeholder : 'Email address'}
      value={value}
      removeAutocomplete
      overlayLabel={label}
      overlayAction={overlayAction}
      onChange={handleChange}
      {...rest}
    />
  );
};

EmailInput.propTypes = {
  ...TypeTextInput,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showOverlay: PropTypes.bool,
  onChange: PropTypes.func
  // Inherits standard TextInput & HTML input properties
};

export default EmailInput;
