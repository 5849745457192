import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { RestrictedAccess } from 'lib/restrictedAccess';
import { Button, Grid, GridCell } from 'v1/components/shared';

import './SettingsSection.scss';

const SettingsSection = ({
  title,
  description,
  primaryAction,
  secondaryAction,
  renderActions,
  className,
  children,
  outsideStyles = true,
  ...props
}) => {
  return (
    <div
      className={classnames(
        'SettingsSection',
        { 'SettingsSection--outsideStyles': outsideStyles },
        className
      )}
      {...props}
    >
      <Grid gutters="M" spread className="stack-M">
        {/* TEMP flex styling until we have GridCell props for alignment */}
        <GridCell style={{ alignSelf: 'center' }}>
          {title && <h3>{title}</h3>}
          {description}
        </GridCell>
        <GridCell width="auto">
          {renderActions ? (
            renderActions
          ) : (
            <Fragment>
              {secondaryAction && (
                <Button
                  onClick={secondaryAction.onClick}
                  className={secondaryAction.className}
                  type={secondaryAction.type || 'button'}
                >
                  {secondaryAction.text}
                </Button>
              )}
              {primaryAction && (
                <RestrictedAccess capabilities={primaryAction.capability}>
                  <Button
                    onClick={primaryAction.onClick}
                    className={primaryAction.className}
                    type={primaryAction.type || 'button'}
                  >
                    {primaryAction.text}
                  </Button>
                </RestrictedAccess>
              )}
            </Fragment>
          )}
        </GridCell>
      </Grid>
      <div className="SettingsSection-content">{children}</div>
    </div>
  );
};

SettingsSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  primaryAction: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit']),
    capability: PropTypes.string
  }),
  secondaryAction: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit'])
  }),
  renderActions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  outsideStyles: PropTypes.bool
};

export default SettingsSection;
