import { isNil } from 'lodash';

// Note: this compares two items and doesn't sort a collection
export const alphanumericSort = (a, b) => {
  if (isNil(a) && isNil(b) === null) return 0;
  if (isNil(a)) return -1;
  if (isNil(b)) return 1;

  return String(a).localeCompare(String(b), undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};
