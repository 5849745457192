import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-form';

import {
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud,
  TextInputField
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { displayAlert } from 'store/v1/ui/ui.actions.js';

import { updateShortlist } from 'store/v1/shortlists/shortlists.actions.js';

import Toggle from 'react-toggle';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { assign, get } from 'lodash';
import { v4 } from 'uuid';
import './ShortlistPublishModal.scss';

class ShortlistPublishModal extends Component {
  constructor(props) {
    super(props);

    this.formApi = {};

    this.state = {
      shortlist: get(props.ui, 'data.shortlist')
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        this.props.onRequestClose();
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }

  handleSubmit = values => {
    const shortlist = assign({}, this.state.shortlist, values);

    this.setState({ event: v4() }, () => {
      this.props.updateShortlist(
        this.state.shortlist.id,
        shortlist,
        this.state.event
      );
    });
  };

  render() {
    const { onRequestClose, shortlists } = this.props;
    const { loading } = shortlists;
    const {
      enable_voting,
      enable_commenting,
      private_password,
      public_feedback
    } = this.state.shortlist;

    return (
      <ModalWrapper size="S">
        <ModalHeader title="Publish shortlist" />
        <ModalContent>
          <ModalScrollable>
            <Form
              onSubmit={this.handleSubmit}
              defaultValues={{
                published: true,
                status: 'PUBLISHED',
                public: get(this.state, 'shortlist.public'),
                enable_voting,
                enable_commenting,
                private_password,
                public_feedback
              }}
              validateOnSubmit
              getApi={api => (this.formApi = api)}
            >
              {formApi => (
                <form onSubmit={formApi.submitForm} id="shortlist-create-form">
                  <h3>Security</h3>
                  <div className="ShortlistPublishModal-formSection">
                    <h5>Add a password</h5>
                    <p>Restrict access to the shortlist by adding a password</p>
                    <Field field="public">
                      {fieldApi => (
                        <Toggle
                          defaultChecked={!fieldApi.value}
                          onChange={e => {
                            const checked = e.target.checked;
                            fieldApi.setValue(!checked);
                          }}
                          icons={false}
                        />
                      )}
                    </Field>
                    {!formApi.values.public && (
                      <div className="ShortlistPublishModal-passwordInput">
                        <TextInputField
                          label="Shortlist Password"
                          size="S"
                          field="private_password"
                          placeholder="Set a password"
                          validate="required"
                        />
                      </div>
                    )}
                  </div>
                  <div className="ShortlistPublishModal-formSection">
                    <h3>Feedback Tools</h3>
                    <h5>Allow Voting</h5>
                    <p>
                      Allow users to upvote or downvote shortlisted resources
                    </p>
                    <Field field="enable_voting">
                      {fieldApi => (
                        <Toggle
                          defaultChecked={fieldApi.value}
                          onChange={e => {
                            const checked = e.target.checked;
                            fieldApi.setValue(checked);
                          }}
                          icons={false}
                        />
                      )}
                    </Field>
                    <br />
                    <br />
                    <h5>Allow Commenting</h5>
                    <p>
                      Allow users to leave comments alongside shortlisted
                      resources
                    </p>
                    <Field field="enable_commenting">
                      {fieldApi => (
                        <Toggle
                          defaultChecked={fieldApi.value}
                          onChange={e => {
                            const checked = e.target.checked;
                            fieldApi.setValue(checked);
                          }}
                          icons={false}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="ShortlistPublishModal-formSection">
                    <h3>Feedback Visibility</h3>
                    <h5>Show feedback</h5>
                    <p>Allow public users to see each others feedback.</p>
                    <Field field="public_feedback">
                      {fieldApi => (
                        <Toggle
                          defaultChecked={fieldApi.value}
                          onChange={e => {
                            const checked = e.target.checked;
                            fieldApi.setValue(checked);
                          }}
                          icons={false}
                        />
                      )}
                    </Field>
                  </div>
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label="Cancel"
              appearance="silent"
              hasMarginRight
              action={onRequestClose}
            />
            <PressStud
              label="Publish Shortlist"
              appearance="primary"
              isLoading={loading === 'UPDATE_SHORTLIST'}
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ shortlists, events, ui }) {
  return { shortlists, events, ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayAlert,
      updateShortlist
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShortlistPublishModal)
);
