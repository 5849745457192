import { createSelector } from 'reselect';
import { generateCalloutFieldMapper } from 'v1/helpers/calloutFieldHelper';
import { getCustomFieldDefinitions } from 'store/v1/custom_fields/custom_fields.selectors.js';
import { getResourceTypeFields } from '../contacts/contacts.selectors';

export const getCalloutFieldMappings = createSelector(
  [getResourceTypeFields, getCustomFieldDefinitions],
  (resourceTypeFields, customFieldDefinitions) => {
    return generateCalloutFieldMapper(
      resourceTypeFields,
      customFieldDefinitions
    );
  }
);
