import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const BOOKING_TYPES_ACTIONS = mapValues(
  mirror({
    GET_BOOKING_TYPES: null,
    GET_BOOKING_TYPES_SUCCESS: null,
    GET_BOOKING_TYPES_FAILURE: null,

    CREATE_BOOKING_TYPE: null,
    CREATE_BOOKING_TYPE_SUCCESS: null,
    CREATE_BOOKING_TYPE_FAILURE: null,

    DELETE_BOOKING_TYPE: null,
    DELETE_BOOKING_TYPE_SUCCESS: null,
    DELETE_BOOKING_TYPE_FAILURE: null,

    UPDATE_BOOKING_TYPE: null,
    UPDATE_BOOKING_TYPE_SUCCESS: null,
    UPDATE_BOOKING_TYPE_FAILURE: null,

    UPDATE_BOOKING_TYPE_ORDER: null,
    UPDATE_BOOKING_TYPE_ORDER_SUCCESS: null,
    UPDATE_BOOKING_TYPE_ORDER_FAILURE: null
  }),
  value => value
);
