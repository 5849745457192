import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Capability } from '__types__';
import { RestrictedAccess } from 'lib/restrictedAccess';

import { MiniLoader } from 'v1/components/shared';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { createContactPin } from 'store/v1/contacts/contacts.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';

import { v4 } from 'uuid';
import { get } from 'lodash';
import { isImageFromURL } from 'v1/helpers/misc';

import './Pinnable.scss';

class Pinnable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        this.setState({ isUploadingURL: false }, () => {
          this.props.displayAlert('success', 'Item Pinned');
        });
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', 'Sorry, could not pin');
      }
    }
  }
  onPin = () => {
    let url = this.props.toPin;
    this.setState(
      {
        event: v4(),
        isUploadingURL: true
      },
      () => {
        const isImageURL = isImageFromURL(url);
        this.props.createContactPin(
          {
            url: url,
            pin_type:
              this.props.pin_type || isImageURL
                ? 'external_image_link'
                : 'external_link'
          },
          this.props.contactID,
          this.state.event
        );
      }
    );
  };
  renderPinButton = () => {
    return (
      <RestrictedAccess capabilities={Capability.RESOURCE_UPDATE}>
        <span
          className="CloseItem-small pin"
          onClick={e => {
            e.stopPropagation();
            this.onPin();
          }}
        >
          <MiniLoader
            isLoading={this.props.contacts.loading === 'CREATE_CONTACT_PIN'}
          >
            <img src="/images/icon_pin.svg" width="14px" alt="" />
            Add to Pinboard
          </MiniLoader>
        </span>
      </RestrictedAccess>
    );
  };
  render() {
    return (
      <div className={`Pinnable ${this.props.className}`}>
        {this.renderPinButton()}
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps({ contacts, events }) {
  return { contacts, events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createContactPin, displayAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Pinnable);
