import React from 'react';
import PropTypes from 'prop-types';

// REDUX

// COMPONENTS

// IMPORTS
import get from 'lodash/get';
import './ResourceContactInfoMeta.scss';

export const ResourceContactInfoMeta = ({ resource = {} }) => {
  const defaultEmail = get(resource, 'emails.0.value_1', null);
  const defaultTelephone = get(resource, 'phone_numbers.0.value_1', null);
  return (
    <span className="ResourceContactInfoMeta">
      {defaultEmail && (
        <span className="ResourceContactInfoMeta-label">{defaultEmail}</span>
      )}
      {defaultTelephone && (
        <span className="ResourceContactInfoMeta-label">
          {defaultTelephone}
        </span>
      )}
    </span>
  );
};

ResourceContactInfoMeta.propTypes = {
  resource: PropTypes.object.isRequired
};

export default ResourceContactInfoMeta;
