import { ReactNode } from 'react';

export interface PopoverLayoutProps {
  searchSlot?: ReactNode;
  children: ReactNode;
  footerSlot?: ReactNode;
}

export function PopoverLayout({
  searchSlot,
  children,
  footerSlot
}: PopoverLayoutProps) {
  return (
    <>
      {searchSlot && (
        <div className="tw-m-sm tw-mb-0 tw-flex tw-flex-row tw-gap-sm tw-items-center">
          {searchSlot}
        </div>
      )}
      <div className="tw-p-lg tw-space-y-sm tw-mb-auto">{children}</div>
      {footerSlot && (
        <div className="tw-border-t tw-border-gray-500 dark:tw-border-gray-700 first:tw-rounded-t-none">
          {footerSlot}
        </div>
      )}
    </>
  );
}
