import React, { useEffect, useState } from 'react';

import {
  InputMetaProps,
  InputStylingProps
} from 'shared/inputs/__types__/Inputs';
import useInputClasses from 'shared/inputs/useInputClasses';

import { RestrictedInput } from 'shared';

type TextInputProps = InputMetaProps &
  InputStylingProps & {
    /**
     * The input type. This should only be used by specialised inputs.
     */
    type?: string; // HTML <input type="" />
    value?: string | number | null;
    onChange?(value: string | number): void;
    onBlur?(value: string | number): void;
    autoComplete?: string | undefined;
  };

const TextInput = (props: TextInputProps) => {
  const inputClasses = useInputClasses(props);
  const {
    id,
    type,
    isDisabled,
    placeholder,
    value = '',
    isRestricted,
    onBlur,
    onChange,
    autoComplete
  } = props;

  // Sometimes TextInput isn't controlled by a form, and only saves onBlur
  // - so we need to store the value internally
  const [currentValue, setCurrentValue] = useState<string | number>(
    value || ''
  );

  useEffect(() => {
    setCurrentValue(value || '');
  }, [value]);

  const handleBlur = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && target.value !== value && onBlur(target.value);
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(target.value);
    onChange && onChange(target.value);
  };

  if (isRestricted) {
    return <RestrictedInput {...props} />;
  }

  return (
    <input
      id={id}
      className={inputClasses}
      type={type}
      disabled={isDisabled}
      value={currentValue}
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={handleChange}
      autoComplete={autoComplete}
    />
  );
};

export default TextInput;
