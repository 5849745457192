import useModal from 'hooks/useModal';
import { useRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors';
import { openModal } from 'store/v1/ui/ui.actions';
import { createSidebarGlobalCreate } from './SidebarGlobalCreate';
import { selectIntegrationById } from 'store/integrations';
import { IntegrationID } from '__types__';
import { RootStore } from 'store/reducer';

export const sidebarGlobalCreateConfig = {
  useResourceTypes: () => useSelector(getResourceTypes),
  useV1Modal: () => {
    const dispatch = useDispatch();
    return (modal, data) => dispatch(openModal(modal, data));
  },
  useV2Modal: () => {
    const { openModal } = useModal();
    return modal => openModal(modal);
  },
  useRestrictedAccess: useRestrictedAccessCallback,
  useDocusignIntegration: () =>
    useSelector((state: RootStore) =>
      selectIntegrationById(state, IntegrationID.DOCUSIGN)
    )
};
