import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAvailabilityRequest } from 'store/v1/availability_requests/availability_requests.actions';
import { AVAILABILITY_REQUEST_ACTIONS } from 'store/v1/availability_requests/availability_requests.constants';
import { openModal } from 'store/v1/ui/ui.actions';

import {
  PressStud,
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import RequestAvailabilityDetailsForm from './RequestAvailabilityDetailsForm/RequestAvailabilityDetailsForm';
import RequestAvailabilityResourceSelect from './RequestAvailabilityResourceSelect/RequestAvailabilityResourceSelect';
import RequestAvailabilityConfirm from './RequestAvailabilityConfirm/RequestAvailabilityConfirm';
import RequestAvailabilitySending from './RequestAvailabilitySending/RequestAvailabilitySending';

import useEvent from 'v1/helpers/hooks/useEvent';

import moment from 'moment';
import omit from 'lodash/omit';
import size from 'lodash/size';
import get from 'lodash/get';
import map from 'lodash/map';
import has from 'lodash/has';

import { useEffect } from 'react';

// TODO: ENHANCE CREATE AVAILABILITY REQUEST ENDPOINT TO HANDLE A BATCH OF RESOURCES
// We've had to do a workaround for v1 in that the request endpoint only takes one resource ID per call.
// To compromise with this you'll see below logic to cycle through each resource Id and send them separately.
// Follow the steps 1 - 4 on how we handle it right now

const RequestAvailabilityModal = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.ui);
  const contacts = useSelector(state => state.contacts);

  const [activeWorkflow, setActiveWorkflow] = useState('DETAILS');

  const [currentSubmittedIndex, setCurrentSubmittedIndex] = useState(0);
  const [error, setError] = useState();
  const [isSending, setIsSending] = useState(false);

  const [request, setRequest] = useState({
    resourceIDs: data.resourceIds || [],
    resourcesEmails: {},
    startTimestampUTC: moment().format(),
    endTimestampUTC: moment() // Default to +1 months of current date
      .add(30, 'd')
      .format()
  });

  useEffect(() => {
    const resourcesEmails = {};
    map(data.resourceIds, id => {
      const resource = get(contacts, ['data', id]);
      if (has(resource, 'emails') && size(resource.emails) > 0) {
        resourcesEmails[id] = resource.emails[0].value_1;
      }
    });
    setRequest(prev => ({ ...prev, ...{ resourcesEmails } }));
  }, [data, contacts]);

  useEffect(() => {
    if (isSending) {
      requestAvailabilityForResource(currentSubmittedIndex);
    }
  }, [currentSubmittedIndex]);

  useEvent([AVAILABILITY_REQUEST_ACTIONS.CREATE_AVAILABILITY_REQUEST], {
    // 3. Here on success we trigger the next resource to create a request from if it exists.
    // otherwise we say we're done.
    onSuccess: () => {
      if (size(request.resourceIDs) > currentSubmittedIndex + 1) {
        setCurrentSubmittedIndex(prev => prev + 1);
      } else {
        setIsSending(false);
        dispatch(
          openModal('SuccessFeedbackModal', {
            title: `${size(request.resourceIDs)} Request${
              size(request.resourceIDs) > 1 ? 's' : ''
            } Sent!`,
            description:
              'Hold tight while they submit their availabilities back to you within their individual calendars',
            icon: '/images/icon_colour_send.svg',
            engageConfetti: true
          })
        );
      }
    }
  });

  // 1. Send request starts here when confirm button is clicked
  function startRequestQueue() {
    setIsSending(true);
    requestAvailabilityForResource(currentSubmittedIndex);
  }

  // 2. Here we omit the resourceIDs (which will be the new field when the api can handle it)
  // and instead replace with resourceID with the next resource to request.
  function requestAvailabilityForResource(resourceIndex) {
    const resourceID = request.resourceIDs[resourceIndex];
    const submitRequest = omit(
      {
        ...request,
        resourceID,
        resourceEmail: request.resourcesEmails[resourceID]
      },
      ['resourceIDs', 'resourcesEmails']
    );
    dispatch(createAvailabilityRequest(submitRequest));
  }

  const WORKFLOWS = {
    DETAILS: {
      buttonLabel: 'Next',
      nextStage: () => setActiveWorkflow('RESOURCES')
    },
    RESOURCES: {
      buttonLabel: 'Next',
      nextStage: () => setActiveWorkflow('CONFIRM'),
      previousStage: () => setActiveWorkflow('DETAILS')
    },
    CONFIRM: {
      buttonLabel: 'Confirm & Send',
      nextStage: () => {
        startRequestQueue();
        setActiveWorkflow('SENDING');
      },
      previousStage: () => setActiveWorkflow('RESOURCES')
    },
    SENDING: {}
  };

  function renderScreen() {
    switch (activeWorkflow) {
      case 'DETAILS':
        return (
          <RequestAvailabilityDetailsForm
            onChange={values => setRequest(prev => ({ ...prev, ...values }))}
            request={request}
          />
        );
      case 'RESOURCES':
        return (
          <RequestAvailabilityResourceSelect
            onChange={values => {
              setRequest(prev => ({ ...prev, ...values }));
            }}
            request={request}
            onError={setError}
          />
        );
      case 'CONFIRM':
        return <RequestAvailabilityConfirm request={request} />;
      case 'SENDING':
        return (
          <RequestAvailabilitySending
            request={request}
            sendCount={currentSubmittedIndex + 1}
          />
        );
      default:
    }
  }
  return (
    <ModalWrapper size="M">
      <ModalHeader title="Request availability" />
      <ModalContent>
        <ModalScrollable>{renderScreen()}</ModalScrollable>
        <ModalNavigation>
          {WORKFLOWS[activeWorkflow].previousStage && (
            <PressStud
              appearance="silent"
              label="Back"
              action={WORKFLOWS[activeWorkflow].previousStage}
            />
          )}
          {WORKFLOWS[activeWorkflow].nextStage && (
            <PressStud
              appearance="primary"
              label={WORKFLOWS[activeWorkflow].buttonLabel}
              action={WORKFLOWS[activeWorkflow].nextStage}
              isDisabled={error}
            />
          )}
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default RequestAvailabilityModal;
