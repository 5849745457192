//
// Helpers for the Booking object
//
// TODO: group this file better - see EventHelper

import moment from 'moment-timezone';
import { chain, find, get, isEmpty, sumBy } from 'lodash';

import {
  getWorkedDayCount,
  createNewEventObject,
  findEventWithinDateRange
} from 'v1/helpers/byModel/EventHelper';
import { getResourceDefaultRate } from 'v1/helpers/byModel/ResourceHelper';
import { getDataFromContactRate } from 'v1/helpers/byModel/RateHelper';
import { calculateDurationInHours } from 'v1/helpers/byType/dateHelper';
import { getTotals } from 'v1/helpers/byType/rateHelper';
import { formatCurrency } from 'v1/helpers/currencyHelper';
import { orderReducer } from 'v1/helpers/misc';

/**
 * @function getBillableUnitCount
 * Get the number of billable units for a booking event
 * @param {Object} event - [Event] - The event
 * @param {Object} rate - [Rate] - The booking rate
 * @param {Object} rate.amount - The booking rate amount (rate_amount)
 * @param {Object} rate.currency - The booking rate currency (rate_currency)
 * @param {Object} rate.rate_interval - The booking rate interval (rate_interval)
 */
export const getBillableUnitCount = (event, rate) => {
  const count =
    rate.rate_interval === 'FIXED' ||
    (event.date_type === 'INCLUSIVE_HOURS' && rate.rate_interval === 'HOURLY')
      ? 1
      : getWorkedDayCount(event);

  // prettier-ignore
  const multiplier =
    rate.rate_interval === 'HOURLY'
      ? event.date_type === 'RECURRING_DEFAULT'
        ? 8 // ALL DAY (8h)
        : event.date_type === 'RECURRING_HOURS'
          ? event.estimated_daily_hours +
            (event.estimated_daily_minutes || 0) / 60 // HOURS + MINS/60
          : event.date_type === 'RECURRING_SPECIFIC_HOURS'
        ? calculateDurationInHours(
            moment(event.estimated_start_time, 'HH:mm:ss'),
            moment(event.estimated_end_time, 'HH:mm:ss')
          ) // RECURRING DURATION
      : event.date_type === 'INCLUSIVE_HOURS'
        ? calculateDurationInHours(
            moment(event.start_timestamp),
            moment(event.end_timestamp)
          ) // SINGLE DURATION
      : 1 // NO MULTIPLIER
    : 1; // NO MULTIPLIER
  // prettier-ignore-end

  return +(count * multiplier).toFixed(2);
};

/**
 * @function calculateBookingSubtotal
 * Calculate the booking subtotal
 *
 * @param {Object} event - [Event] - The event
 * @param {Object} rate - [Rate] - The booking rate
 * @param {Object} rate.amount - The booking rate amount (rate_amount)
 * @param {Object} rate.currency - The booking rate currency (rate_currency)
 * @param {Object} rate.rate_interval - The booking rate interval (rate_interval)
 */
export const calculateBookingSubtotal = (event, rate) =>
  getBillableUnitCount(event, rate) * rate.amount;

/**
 * @function createBookingObject
 * Create a booking object from various parameters
 *
 * @param {Object} bookingTypes - The global state.booking_types object
 * @param {Object} _ - The new data object to use
 * @param {Object} _.resource - [Resource] - The resource being booked
 * @param {Object} _.event - [Event] - The event to turn into booking.events
 * @param {Object} _.assignment - The assignment to add to the booking
 * @param {Object} _.slot - The resource slot of a production
 * @param {Object} _.status - The status of a production
 */
// TODO: add new properties to docs, & check above ^
export function createBookingObject(
  bookingTypes,
  { resource = {}, event = {}, assignment, slot, status = {} }
) {
  // TODO: sort out
  // TODO: theoretically, do we even need to filter out archived? let alone the rest
  // TODO: break out into its own selector
  const bookingTypesList = chain(orderReducer(bookingTypes))
    .filter(b => !b.archived)
    .orderBy(['order', 'asc'])
    .value();

  const bookingType = get(bookingTypesList, [0], null);
  const bookingTypeId = get(bookingType, 'id', null);

  const rate = getResourceDefaultRate(resource);
  const rateData = getDataFromContactRate(rate);

  let resource_slot_assignments = [];
  if (assignment) {
    resource_slot_assignments.push({
      id: assignment.id,
      contact_id: resource.id,
      resource_slot_id: assignment.resource_slot_id,
      events: [
        createNewEventObject({
          ...event,
          event_type: 'RESOURCE_SLOT_ASSIGNMENT',
          include_weekends: bookingType.include_weekends_default
        })
      ]
    });
  }
  if (slot) {
    resource_slot_assignments.push({
      contact_id: resource.id,
      resource_slot_id: slot.id,
      events: [
        createNewEventObject({
          ...event,
          event_type: 'RESOURCE_SLOT_ASSIGNMENT',
          include_weekends: bookingType.include_weekends_default
        })
      ]
    });
  }
  return {
    events: [
      createNewEventObject({
        ...event,
        event_type: 'BOOKING'
      })
    ],
    contact_id: resource.id,
    contact: resource,
    contact_rate_id: get(rate, 'id'),
    rate_amount: get(rateData, 'amount'),
    rate_currency: get(rateData, 'currency'),
    rate_interval: get(rateData, 'interval')
      ? get(rateData, 'interval')
      : 'DAILY', // TODO: check handling of defaults
    rate_agency_fee: get(rateData, 'agency_fee'),
    rate_payroll_fee: get(rateData, 'payroll_fee'),
    rate_processing_fee: get(rateData, 'processing_fee'),
    resource_slot_assignments,
    status_id: status.id,
    booking_type_id: bookingTypeId
  };
}

/**
 * @function findBookingWithinDateRange
 * Create a booking object from various parameters
 *
 * @param {Object} dateRange - [Event] - The event being searched
 * @param {Object} bookings - [Array] - The booking being filtered
 */
// TODO: check params listed above ^
export function findBookingWithinDateRange(selectedEvent, bookings) {
  if (isEmpty(selectedEvent)) return;
  let validBookings = [];

  bookings.map(booking => {
    const validEvents = findEventWithinDateRange(selectedEvent, booking.events);

    if (!isEmpty(validEvents)) {
      validBookings.push(booking);
    }
  });
  return validBookings;
}

export const getBookingRate = (booking, resource) => {
  if (!booking) {
    return null;
  }
  const billableUnitCount =
    booking.rate_interval === 'FIXED'
      ? 1
      : sumBy(booking.events, event => getBillableUnitCount(event, booking));

  let rateName = booking.rate_amount ? 'Default' : 'No rate set';
  if (booking.contact_rate_id && resource) {
    const resourceRate = resource.rates?.find(
      r => r.id === booking.contact_rate_id
    );
    rateName = resourceRate
      ? resourceRate.rate
        ? resourceRate.rate.name
        : resourceRate.name
      : '';
  }

  return {
    id: booking.id,
    isBookingRate: true,
    name: rateName,
    currency: booking.rate_currency,
    amount: booking.rate_amount,
    interval: booking.rate_interval,
    quantity: billableUnitCount
  };
};

export const getBookingFees = booking => {
  if (!booking) {
    return null;
  }
  return {
    agencyFee: booking.rate_agency_fee,
    payrollFee: booking.rate_payroll_fee,
    processingFee: booking.rate_processing_fee
  };
};

const getBookingRateTotal = (rate, booking) => {
  return booking && booking.rate_interval !== 'FIXED'
    ? sumBy(booking.events, event => {
        return calculateBookingSubtotal(event, {
          ...rate,
          rate_interval: rate.interval
        });
      })
    : rate.amount && rate.quantity
    ? rate.amount * rate.quantity
    : 0;
};

const getBookingTotals = (rate, booking, expenses) => {
  const fees = getBookingFees(booking);
  const rateTotal = getBookingRateTotal(rate, booking);
  const totals = getTotals(rateTotal, fees, expenses);
  return totals;
};

export const getBookingTotalsFormatted = (booking, expenses, currency) => {
  if (!booking) {
    return {};
  }
  const rate = getBookingRate(booking);
  const totals = getBookingTotals(rate, booking, expenses);
  return {
    rates: formatCurrency(totals.rateTotal || 0, rate.currency || currency),
    fees: formatCurrency(totals.feesTotal || 0, currency),
    expenses: formatCurrency(totals.expensesTotal || 0, currency),
    total: formatCurrency(totals.total || 0, rate.currency || currency),
    totalRaw: totals.total || 0
  };
};

const incrStatus = (acc, status) => {
  if (!status || status === 'AWAITING_APPROVAL') {
    return { ...acc, awaiting: acc.awaiting + 1 };
  }
  if (status === 'APPROVED') {
    return { ...acc, approved: acc.approved + 1 };
  }
  if (status === 'PAID') {
    return { ...acc, paid: acc.paid + 1 };
  }
  return {};
};
export const getBookingDocumentsSummary = (invoices, expenses) => {
  let documents = {
    invoices: 0,
    receipts: 0,
    awaiting: 0,
    approved: 0,
    paid: 0,
    total: 0
  };
  documents = invoices.reduce((acc, doc) => {
    if (doc.archived) {
      return acc;
    }
    const docTotal =
      doc.status === 'DECLINED' ? 0 : get(doc, 'document_data.amount', 0);
    return {
      ...acc,
      ...incrStatus(acc, doc.status),
      invoices: acc.invoices + 1,
      total: acc.total + docTotal
    };
  }, documents);
  documents = expenses.reduce((expAcc, expense) => {
    if (expense.archived) {
      return expAcc;
    }
    return {
      ...expAcc,
      ...(expense.receipts || []).reduce((recAcc, doc) => {
        if (doc.archived) {
          return recAcc;
        }
        const docTotal =
          doc.status === 'DECLINED' ? 0 : get(doc, 'document_data.amount', 0);
        return {
          ...recAcc,
          ...incrStatus(recAcc, doc.status),
          receipts: recAcc.receipts + 1,
          total: recAcc.total + docTotal
        };
      }, expAcc)
    };
  }, documents);
  return documents;
};
