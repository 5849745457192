import Pocket from 'pocketjs';
import Env from 'lib/env/Env';

const LocalStorage = new Pocket({
  dbname: 'atellio',
  driver: Pocket.Drivers.DEFAULT
}).restore();

// Add global hook
if (Env.nodeEnv !== 'production') {
  window.Store = LocalStorage;
}

export default LocalStorage;
