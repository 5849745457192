import React from 'react';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { useRestrictedAccess } from 'lib/restrictedAccess';

// NOTE: we can now use SystemLink for hashlinks without passing in a hash
// However the `hash` prop is still in usage within the codebase
export const SystemLink = ({ to, capabilities, hash, children, ...rest }) => {
  const app_id = useSelector(state => state.auth.app_id);
  const canFollowLink = useRestrictedAccess(undefined, capabilities, undefined);

  if (capabilities && !canFollowLink) {
    return <div {...rest}>{children}</div>;
  }

  return (
    <HashLink
      smooth
      to={`/app/${app_id}${to}${hash ? `#${hash}` : ''}`}
      {...rest}
    >
      {children}
    </HashLink>
  );
};

export default SystemLink;
