import { RESOURCE_TYPES_ACTIONS } from 'store/v1/resource_types/resource_types.constants.js';
import { pick } from 'lodash';

const token = true;

// UNUSED
// export const getResourceTypes = (event_id = null) => {
//   return {
//     types: [
//       RESOURCE_TYPES_ACTIONS.GET_RESOURCE_TYPES,
//       RESOURCE_TYPES_ACTIONS.GET_RESOURCE_TYPES_SUCCESS,
//       RESOURCE_TYPES_ACTIONS.GET_RESOURCE_TYPES_FAILURE
//     ],
//     promise: client => client.get('/resource_types', { token }),
//     event_id
//   };
// };

export const createResourceType = (data, event_id = null) => {
  return {
    types: [
      RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE,
      RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE_SUCCESS,
      RESOURCE_TYPES_ACTIONS.CREATE_RESOURCE_TYPE_FAILURE
    ],
    promise: client => client.post('/resource_types', { data, token }),
    event_id
  };
};

export const updateResourceType = (id, data, event_id = null) => {
  const payload = pick(data, [
    'id',
    'name',
    'order',
    'metastructure',
    'icon',
    'model',
    'internal',
    'restricted_groups'
  ]);
  return {
    types: [
      RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE,
      RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_SUCCESS,
      RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_FAILURE
    ],
    promise: client =>
      client.put(`/resource_types/${id}`, {
        data: payload,
        token
      }),
    event_id
  };
};

export const updateResourceTypeOrder = (data, event_id = null) => {
  const payload =
    data &&
    data.map(resourceType => ({
      id: resourceType.id,
      order: resourceType.order
    }));
  return {
    types: [
      RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_ORDER,
      RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_ORDER_SUCCESS,
      RESOURCE_TYPES_ACTIONS.UPDATE_RESOURCE_TYPE_ORDER_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/resource_types`, {
        data: { reorder: payload },
        token
      }),
    event_id
  };
};

export const deleteResourceType = (id, event_id = null) => {
  return {
    types: [
      RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE,
      RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE_SUCCESS,
      RESOURCE_TYPES_ACTIONS.DELETE_RESOURCE_TYPE_FAILURE
    ],
    promise: client => client.del(`/resource_types/${id}`, { token }),
    event_id,
    id
  };
};
