import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { parseInitials } from 'v1/helpers/misc';
import './ResourceBadgeIcon.scss';

import get from 'lodash/get';
import {
  convertImageFileWithCloudinary,
  IMAGE_QUALITY_OPTIONS
} from 'lib/media/imageHelper';

const ResourceBadgeIcon = ({
  className,
  resource,
  tooltip,
  size = 'small'
}) => {
  function renderColours() {
    const colours =
      resource.groups && resource.groups.map(group => group.colour);
    if (Array.isArray(colours)) {
      return colours.map((colour, index) => (
        <div
          key={`${colour}-${index}`}
          className="ResourceBadgeIcon-colourStripe"
          style={{ backgroundColor: colour }}
        />
      ));
    }
  }

  return (
    <div
      className={classnames([
        'ResourceBadgeIcon',
        { 'ResourceBadge-empty': !resource },
        className
      ])}
      style={{
        backgroundColor: get(resource, ['groups', 0, 'colour']),
        backgroundImage:
          resource &&
          resource.profile_picture &&
          `url('${convertImageFileWithCloudinary(
            resource.profile_picture,
            IMAGE_QUALITY_OPTIONS[size]
          )}')`
      }}
      data-tip={tooltip}
    >
      {resource && !resource.profile_picture ? (
        <>
          <div className="ResourceBadgeIcon-background">{renderColours()}</div>
          <span className="ResourceBadgeIcon-initials">
            {parseInitials(resource.first_name, resource.last_name)}
          </span>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

ResourceBadgeIcon.propTypes = {
  className: PropTypes.string,
  resource: PropTypes.object, // TODO: type,
  tooltip: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default ResourceBadgeIcon;
