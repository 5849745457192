import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';

import { PressStud } from 'v1/components/shared';
import { BookingExpenseItem, ListCellGroup } from 'v1/components/shared';

import { openModal } from 'store/v1/ui/ui.actions.js';

import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import v4 from 'uuid';

import './BookingExpenses.scss';

export const BookingExpenses = ({
  className,
  updateBookingExpenses,
  booking,
  quickCreate,
  initialFormExpenses,
  productionId
}) => {
  const dispatch = useDispatch();

  const expenses = useSelector(state => state.expenses);

  // get production so we can create and link expenses
  const productions = useSelector(state => state.productions);
  const production_id = get(
    booking,
    ['resource_slot_assignments', 0, 'resource_slot', 'production_id'],
    productionId
  );
  const production = get(productions, ['data', production_id]);

  const [formExpenses, setFormExpenses] = useState(initialFormExpenses || []);
  const expenseItems_list = quickCreate
    ? formExpenses
    : filter(
        get(expenses, 'data'),
        e => !e.archived && e.booking_id === booking.id
      );

  function onUpdateExpense(expense) {
    const expenseIndex = findIndex(formExpenses, e => e.id === expense.id);
    formExpenses[expenseIndex] = expense;
    setFormExpenses(formExpenses);

    updateBookingExpenses(formExpenses);
  }
  function onCreateExpense(expense) {}
  function onDeleteExpense(expense) {
    let updatedExpenses = filter(formExpenses, e => e.id !== expense.id);
    setFormExpenses(updatedExpenses);
    updateBookingExpenses(updatedExpenses);
  }
  function onAddExpense() {
    const newExpense = {
      id: `temp-${v4()}`,
      commit_rate_quantity: 1
    };
    setFormExpenses([...formExpenses, newExpense]);
    updateBookingExpenses([...formExpenses, newExpense]);
  }
  const renderExpenseItem = () => {
    return expenseItems_list.map(expense => (
      <BookingExpenseItem
        key={expense.id}
        expense={expense}
        production={production}
        onUpdate={onUpdateExpense}
        onDelete={onDeleteExpense}
        onCreate={onCreateExpense}
        quickCreate={quickCreate}
      />
    ));
  };
  return (
    <div className={classnames(['BookingExpenses', className])}>
      {!isEmpty(expenseItems_list) && (
        <ListCellGroup className="stack-XS">
          {renderExpenseItem()}
        </ListCellGroup>
      )}
      <PressStud
        label="Add expense"
        icon="addBlack"
        size="S"
        action={() => {
          quickCreate
            ? onAddExpense()
            : dispatch(
                openModal('ExpenseCreateModal', {
                  create: true,
                  booking,
                  production
                })
              );
        }}
      />
    </div>
  );
};

BookingExpenses.propTypes = {
  className: PropTypes.string
};

export default BookingExpenses;
