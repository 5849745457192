import {
  QueryClient,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { isEqual, mapValues, omit } from 'lodash';
import { resourceShortlistKeys } from 'v4/entities/associations/resource__shortlist/resource__shortlist.keys';
import { ListQuery, ListResponse } from 'v4/entities/common/common.crud.types';
import { parseWithDecoratedError } from 'v4/entities/common/parseWithDecoratedError';
import { Resource_normalized } from 'v4/entities/resource/resource.types';
import { z } from 'zod';
import { UseResourceShortlistAssociationEffectsApi } from './http/resource__shortlist.http.effects';
import { UseResourceShortlistAssociationQueryApi } from './http/resource__shortlist.http.query';
import { createCommentEffect } from './resource__shortlist.effect.comment';
import { createCreateEffect } from './resource__shortlist.effect.create';
import { createCreateBatchEffect } from './resource__shortlist.effect.createBatch';
import { createDeleteEffect } from './resource__shortlist.effect.delete';
import { createDeleteBatchEffect } from './resource__shortlist.effect.deleteBatch';
import { createDownvoteEffect } from './resource__shortlist.effect.downvote';
import { createDuplicateEffect } from './resource__shortlist.effect.duplicate';
import { createMoveEffect } from './resource__shortlist.effect.move';
import { createReorderEffect } from './resource__shortlist.effect.reorder';
import { createUpdateEffect } from './resource__shortlist.effect.update';
import { createUpvoteEffect } from './resource__shortlist.effect.upvote';
import { createListQueryHooks } from './resource__shortlist.query.list';
import {
  AssociationQuery,
  associationStateSchema,
  querySchemas,
  ShortlistBlock_normalized
} from './resource__shortlist.schemas';

const listQuerySchema = z.tuple([
  z.literal('resource_shortlist'),
  z.literal('lists'),
  querySchemas.list.request
]);

/**
 * Invalidate all queries for resource shortlist associations
 * We don't keep queries hierarchical because many-to-many associations have flat filter structure, ie resourceId or shortlistId
 * But we could design it differently if we wanted to, like:
 * keys: {
 *  all: ['resource_shortlist'] as const,
 *  lists: () => [...keys.all, 'lists'] as const,
 *  list: (query: ResourceShortlistAssociationQuery) => [...keys.lists(), query] as const,
 *  listByResourceId: (resourceId: number) => [...keys.lists(), { resourceId }] as const,
 *  listByShortlistId: (shortlistId: number) => [...keys.lists(), { shortlistId }] as const,
 *  listByResourceIdAndQuery: (resourceId: number, query: ResourceShortlistAssociationQuery) => [...keys.lists(), { resourceId, query }] as const,
 *  listByShortlistIdAndQuery: (shortlistId: number, query: ResourceShortlistAssociationQuery) => [...keys.lists(), { shortlistId, query }] as const,
 *
 *  But this would be problematic, for example:
 *  * we know that resource was updated, so we need to invalidate all queries for this resource
 *  * it needs to invalidate queries for resource, but maybe queries for shortlists that this resource is on
 *
 * @param client
 * @param query
 */
function invalidateQueries(client: QueryClient, query: AssociationQuery) {
  const queryWithoutSearch = omit(query, 'query');
  client.invalidateQueries({
    queryKey: resourceShortlistKeys.lists(),
    predicate: ({ queryKey }) => {
      const key = listQuerySchema.safeParse(queryKey);
      if (key.success) {
        const typedQueryKey = key.data;
        const query = typedQueryKey[2];
        const queryFromKeyWithoutSearch = omit(query, 'query');
        return isEqual(queryFromKeyWithoutSearch, queryWithoutSearch);
      }
      return true;
    }
  });
}

function setQueryData(
  client: QueryClient,
  query: AssociationQuery,
  data: ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
) {
  client.setQueryData(resourceShortlistKeys.list(query), data);
}

export const resourceShortlistAssociationInternals = {
  invalidateQueries,
  setQueryData
};

export function createResourceShortlistAssociationHooks(
  useAssociationQueryApi: UseResourceShortlistAssociationQueryApi,
  useAssociationEffectsApi: UseResourceShortlistAssociationEffectsApi
) {
  const listConfig = createListQueryHooks(useAssociationQueryApi);

  const effectSpecs = {
    create: createCreateEffect(
      useAssociationEffectsApi,
      listConfig.useEnsureList
    ),
    createBatch: createCreateBatchEffect(useAssociationEffectsApi),
    delete: createDeleteEffect(useAssociationEffectsApi),
    deleteBatch: createDeleteBatchEffect(useAssociationEffectsApi),
    update: createUpdateEffect(useAssociationEffectsApi),
    comment: createCommentEffect(useAssociationEffectsApi),
    upvote: createUpvoteEffect(useAssociationEffectsApi),
    downvote: createDownvoteEffect(useAssociationEffectsApi),
    move: createMoveEffect(useAssociationEffectsApi, listConfig.useEnsureList),
    reorder: createReorderEffect(
      useAssociationEffectsApi,
      listConfig.useEnsureList
    ),
    duplicate: createDuplicateEffect(
      useAssociationEffectsApi,
      listConfig.useEnsureList
    )
  };
  type Effects = typeof effectSpecs;

  type Action = {
    [K in keyof Effects]: {
      type: K;
      request: z.infer<Effects[K]['requestSchema']>;
    };
  }[keyof Effects];

  const useEffects = () =>
    mapValues(effectSpecs, effect => effect.useQueryFn());
  const hooks = {
    useList: listConfig.useList,
    useEnsureList: listConfig.useEnsureList,
    useMutation: () => {
      const client = useQueryClient();
      const effects = useEffects();
      return useMutation({
        mutationFn: async (action: Action) => {
          return await effects[action.type](
            // @ts-expect-error -- todo solve discriminated union zod
            action.request,
            undefined // todo maybe  don't expect signal for mutations
          );
        },
        mutationKey: resourceShortlistKeys.lists(),
        onSuccess: (response, action, context) => {
          client.removeQueries({
            queryKey: resourceShortlistKeys.lists(),
            type: 'inactive'
          });

          const queries = client.getQueryCache().findAll({
            type: 'active',
            queryKey: resourceShortlistKeys.lists()
          });

          queries.forEach(query => {
            const queryObject = parseWithDecoratedError(
              querySchemas.list.request,
              query.queryKey[2]
            );
            const queryData = parseWithDecoratedError(
              associationStateSchema,
              query.state.data
            );

            const reconciler = effectSpecs[action.type].responseReconciler;

            const reconciliationResult = reconciler(
              queryData,
              // @ts-expect-error -- todo solve discriminated union zod
              action.request,
              response,
              queryObject
            );

            client.setQueryData(query.queryKey, reconciliationResult.state);
            if (reconciliationResult.shouldInvalidate) {
              invalidateQueries(client, queryObject);
            }
          });
        },
        onMutate: action => {
          return {
            reducer: (oldData, query) => {
              // todo probably need to check if it can affect query
              return effectSpecs[action.type].optimisticReconciler(
                oldData,
                // @ts-expect-error -- todo solve discriminated union zod
                action.request,
                query
              );
            }
          };
        },
        onError: (error, request, context) => {
          console.error(error);
        }
      });
    }
  };
  return hooks;
}

export type ResourceShortlistAssociationHooks = ReturnType<
  typeof createResourceShortlistAssociationHooks
>;
