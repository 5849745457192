import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { find, get } from 'lodash';

import { Flyover } from 'v1/components/shared';
import FlyoverTrigger from '../../buttons/Flyover/FlyoverTrigger/FlyoverTrigger';
import { Button, Icon } from 'shared';
import FlyoverContent from '../../buttons/Flyover/FlyoverContent/FlyoverContent';

const SearchSorts = ({ orderBy, sorts, onSortChange }) => {
  const activeSort = find(sorts, s => s.field === get(orderBy, 'field'));

  return (
    <Flyover>
      <FlyoverTrigger>
        <Button
          size="s"
          appearance="silent"
          icon={get(orderBy, 'direction') === 'asc' ? 'sortAsc' : 'sortDesc'}
          label={`Sort by ${get(activeSort, 'label') || ''}`}
        />
      </FlyoverTrigger>
      <FlyoverContent>
        {sorts.map((param, index) => (
          <div
            key={index}
            className="DropdownButton-option"
            onClick={() => onSortChange(param)}
          >
            {get(orderBy, 'field') === param.field ? (
              <Icon
                size="s"
                name={
                  get(orderBy, 'direction') === 'asc' ? 'sortAsc' : 'sortDesc'
                }
              />
            ) : // <img
            //   className={classnames(['icon', 'GenericSearch-sort-arrow'], {
            //     rotate: get(orderBy, 'direction') === 'asc'
            //   })}
            //   src={get(orderBy, 'direction') === 'asc' ?"/images/icon_arrow_down_dark.svg"}
            //   width="10px"
            //   alt=""
            // />
            null}
            <span className="DropdownButton-option-label">{param.label}</span>
          </div>
        ))}
      </FlyoverContent>
    </Flyover>
  );
};

SearchSorts.propTypes = {
  orderBy: PropTypes.object, // query.order_by
  sorts: PropTypes.array,
  onSortChange: PropTypes.func
};

export default SearchSorts;
