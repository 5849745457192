import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { orderReducer } from 'v1/helpers/misc';
import { Label, Select } from 'v1/components/shared';

// NOTE: can extend the "allowNull" configuration to have a custom `nullLabel` prop
/**
 * @desc A selector for production types
 */
const ProductionTypeSelector = ({ name, value, allowNull, onChange }) => {
  const productionTypes = useSelector(state => state.production_types);
  const productionTypesList = orderReducer(productionTypes).filter(
    t => !t.archived
  );

  const getDefaultValue = _ => {
    // prettier-ignore
    return allowNull
      ? ''
      : get(productionTypesList, [0, 'id'], '');
  };

  useEffect(() => {
    !value && onChange(getDefaultValue());
  }, []);

  return (
    <div>
      <Label htmlFor={name}>Production type</Label>
      <Select
        name={name}
        value={value}
        onChange={({ target }) => onChange(parseInt(target.value))}
        autoComplete="off"
      >
        {allowNull && (
          <option value={''} key={'null'}>
            All production types
          </option>
        )}
        {productionTypesList.map(opt => (
          <option value={opt.id} key={opt.id}>
            {opt.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

ProductionTypeSelector.props = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  allowNull: PropTypes.bool, // Set to true if it can be null, eg if you want to select "All"
  onChange: PropTypes.func.isRequired
};

export default ProductionTypeSelector;
