import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get, chain } from 'lodash';

import useEvent from 'v1/helpers/hooks/useEvent';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import { createGroup, addToGroup } from 'store/v1/groups/groups.actions.js';
import { GROUP_ACTIONS } from 'store/v1/groups/groups.constants.js';
import { orderReducer } from 'v1/helpers/misc';
import { ListSelect } from 'v1/components/shared';
import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

const BatchEditModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const groups = useSelector(state => state.groups);
  const data = useSelector(state => state.ui.data);
  const activeResourceId = get(data, 'activeResourceType.id');
  const [groupsSelected, setGroupsSelected] = useState();

  useEvent([GROUP_ACTIONS.CREATE_GROUP], {
    onSuccess: () => {},
    onFailure: () => dispatch(displayAlert('error', groups.error.message))
  });
  useEvent([GROUP_ACTIONS.ADD_TO_GROUP], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Black book updated'));
      setTimeout(() => {
        dispatch(closeModal());
      }, 300);
    },
    onFailure: () => dispatch(displayAlert('error', groups.error.message))
  });

  const onSelectChange = selected => {
    setGroupsSelected(selected);
  };

  const submitBatchUpdate = () => {
    dispatch(addToGroup(groupsSelected, data.body, 'add'));
  };

  const onGroupCreate = name => {
    dispatch(createGroup({ name, entity_id: activeResourceId }));
  };

  // TODO: (revamp) : this pattern is appearing again with groups
  const renderGroups = () => {
    const groupList = chain(orderReducer(groups))
      .filter(g => !g.archived && g.entity_id === activeResourceId)
      .value();

    return (
      <>
        <h3 className="stack-S">Select a group</h3>
        <ListSelect
          list={groupList}
          onSelectChange={onSelectChange}
          onListItemCreate={onGroupCreate}
          isCreating={groups.loading === 'CREATE_GROUP'}
          mode="single"
          placeholder="Create group"
        />
      </>
    );
  };

  const renderAction = () => (
    <>
      <PressStud
        label="Cancel"
        appearance="silent"
        hasMarginRight
        action={onRequestClose}
      />
      <PressStud
        label={`Update for ${data.contact_number} ${
          data.contact_number > 1 ? 'resources' : 'resource'
        }`}
        appearance="primary"
        isDisabled={!groupsSelected}
        isLoading={groups.loading === 'ADD_TO_GROUP'}
        action={submitBatchUpdate}
      />
    </>
  );

  return (
    <ModalWrapper size="S">
      <ModalHeader title="Add resources to a group" />
      <ModalContent>
        <ModalScrollable>{renderGroups()}</ModalScrollable>
        <ModalNavigation>{renderAction()}</ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

BatchEditModal.props = {
  onRequestClose: PropTypes.func
};

export default BatchEditModal;
