import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  getProductionTypes,
  selectProductionTemplates
} from 'store/v1/productions/productions.selectors.js';

import { ListCellGroup, ListCell, Grid, GridCell } from 'v1/components/shared';
import { PageTabs } from 'v1/components/shared';

import { generateProductionFromTemplate } from 'v1/helpers/productionHelper';

import get from 'lodash/get';

import './ProductionTemplateSelect.scss';

const ProductionTemplateSelect = ({ onProductionUpdate, production }) => {
  const productionTypes = useSelector(getProductionTypes);
  const [selectedProductionTypeId, setSelectedProductionTypeId] = useState(
    production.production_type_id
  );
  const productionTemplates = useSelector(state =>
    selectProductionTemplates(state, {
      production_type_id: selectedProductionTypeId
    })
  );

  const onProductionTemplateSelect = template => {
    const updatedProduction = generateProductionFromTemplate(
      template,
      production
    );
    onProductionUpdate(updatedProduction, 'details');
  };

  const renderList = () => {
    return productionTemplates.map(template => {
      return (
        <ListCell
          key={template.id}
          onClick={() => {
            onProductionTemplateSelect(template);
          }}
          className="ProductionTemplateSelect-listItem Parent_hoverListener"
        >
          <Grid>
            <GridCell className="text-13-700">
              {get(template, 'default_values.icon.value') ||
                get(template, 'default_values.icon')}{' '}
              {template.name}
            </GridCell>
            <GridCell className="Child_hoverListener" width="auto">
              Select
            </GridCell>
          </Grid>
        </ListCell>
      );
    });
  };
  return (
    <div className="ProductionTemplateSelect">
      <PageTabs
        tabs={
          productionTypes &&
          productionTypes.map(productionType => ({
            text: productionType.name,
            container: productionType.id
          }))
        }
        activeContainer={selectedProductionTypeId}
        onTabSelect={container => setSelectedProductionTypeId(container)}
        className="PageTabs_borderBottom"
      />
      <div className="inset-L">
        <ListCellGroup>{renderList()}</ListCellGroup>
      </div>
    </div>
  );
};

ProductionTemplateSelect.propTypes = {
  onProductionUpdate: PropTypes.func.isRequired,
  production: PropTypes.object
};

export default ProductionTemplateSelect;
