import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, GridCell, Icon } from 'v1/components/shared';

import { GLYPHS } from 'v1/helpers/icons';
import classnames from 'classnames';

import './Accordion.scss';

export const Accordion = ({
  children,
  title,
  description,
  forceOpen,
  padding
}) => {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true);
    }
  }, [forceOpen]);

  return (
    <div
      className={classnames(['Accordion'], {
        [`inset-${padding}`]: padding
      })}
    >
      <Grid
        vcenter
        onClick={() => setIsOpen(prev => !prev)}
        className="Accordion-header"
      >
        <GridCell>
          {title && <h5 className="text-13-700">{title}</h5>}
          {description && (
            <span className="text-13-600-eggplant">{description}</span>
          )}
        </GridCell>
        <GridCell width="auto">
          <Icon
            icon={isOpen ? GLYPHS.CHEVRON_UP : GLYPHS.CHEVRON_DOWN}
            width={15}
          />
        </GridCell>
      </Grid>
      {isOpen && <div className="Accordion-content">{children}</div>}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  forceOpen: PropTypes.any,
  padding: PropTypes.oneOf(['XS', 'S', 'M', 'L'])
};

Accordion.defaultProps = {
  padding: 'S'
};

export default Accordion;
