import { CALLOUT_ACTIONS } from 'store/v1/callouts/callouts.constants.js';

const token = true;

export function getCallouts(query, event_id = null) {
  return {
    types: [
      CALLOUT_ACTIONS.GET_CALLOUTS,
      CALLOUT_ACTIONS.GET_CALLOUTS_SUCCESS,
      CALLOUT_ACTIONS.GET_CALLOUTS_FAILURE
    ],
    promise: client => client.post('/search/callouts', { data: query, token }),
    event_id
  };
}

export function getCallout(id, event_id = null) {
  return {
    types: [
      CALLOUT_ACTIONS.GET_CALLOUT,
      CALLOUT_ACTIONS.GET_CALLOUT_SUCCESS,
      CALLOUT_ACTIONS.GET_CALLOUT_FAILURE
    ],
    promise: client => client.get(`/callouts/${id}`, { token }),
    event_id
  };
}

export function getCalloutAssociations(id, event_id = null) {
  return {
    types: [
      CALLOUT_ACTIONS.GET_CALLOUT_ASSOCIATIONS,
      CALLOUT_ACTIONS.GET_CALLOUT_ASSOCIATIONS_SUCCESS,
      CALLOUT_ACTIONS.GET_CALLOUT_ASSOCIATIONS_FAILURE
    ],
    promise: client => client.get(`/callouts/${id}/associations`, { token }),
    event_id,
    callout_id: id
  };
}

export function getPublicCallout(id, event_id = null) {
  return {
    types: [
      CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT,
      CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT_SUCCESS,
      CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT_FAILURE
    ],
    promise: client => client.get(`/public/callouts/${id}`),
    event_id,
    public: true
  };
}

export function submitApplicant(id, data) {
  return {
    types: [
      CALLOUT_ACTIONS.SUBMIT_APPLICANT,
      CALLOUT_ACTIONS.SUBMIT_APPLICANT_SUCCESS,
      CALLOUT_ACTIONS.SUBMIT_APPLICANT_FAILURE
    ],
    promise: client =>
      client.post(`/public/callouts/${id}/associations/applicants`, { data }),
    public: true
  };
}

export function createCallout(data, event_id = null) {
  return {
    types: [
      CALLOUT_ACTIONS.CREATE_CALLOUT,
      CALLOUT_ACTIONS.CREATE_CALLOUT_SUCCESS,
      CALLOUT_ACTIONS.CREATE_CALLOUT_FAILURE
    ],
    promise: client => client.post('/callouts', { data, token }),
    event_id
  };
}

export function updateCallout(id, data) {
  return {
    types: [
      CALLOUT_ACTIONS.UPDATE_CALLOUT,
      CALLOUT_ACTIONS.UPDATE_CALLOUT_SUCCESS,
      CALLOUT_ACTIONS.UPDATE_CALLOUT_FAILURE
    ],
    promise: client => client.put(`/callouts/${id}`, { data, token })
  };
}

export function deleteCallout(id, event_id = null) {
  return {
    types: [
      CALLOUT_ACTIONS.DELETE_CALLOUT,
      CALLOUT_ACTIONS.DELETE_CALLOUT_SUCCESS,
      CALLOUT_ACTIONS.DELETE_CALLOUT_FAILURE
    ],
    promise: client =>
      client.put(`/callouts/${id}`, { data: { archived: true }, token }),
    event_id
  };
}

export function deleteApplicant(applicant_id, callout_id) {
  return {
    types: [
      CALLOUT_ACTIONS.DELETE_APPLICANT,
      CALLOUT_ACTIONS.DELETE_APPLICANT_SUCCESS,
      CALLOUT_ACTIONS.DELETE_APPLICANT_FAILURE
    ],
    promise: client =>
      client.put(
        `/callouts/${callout_id}/associations/applicants/${applicant_id}`,
        { token, data: { archived: true } }
      ),
    applicant_id
  };
}
