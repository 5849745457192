import React from 'react';
import PropTypes from 'prop-types';

import TypeContactRate from 'v1/components/shared/byModel/Rate/__types__/TypeContactRate';
import {
  Grid,
  GridCell,
  TextInput,
  RateAgencyFee,
  RateInput,
  RatePayrollFee,
  RateProcessingFee,
  RateSelector
} from 'v1/components/shared';

// TODO: reorderability

const RateItemEditable = ({ rate, onChange, onRemove }) => {
  const fillWithRateCard = value => {
    onChange({
      ...rate,
      currency: value.currency,
      amount: value.amount,
      interval: value.interval,
      agency_fee: value.agency_fee,
      payroll_fee: value.payroll_fee,
      processing_fee: value.processing_fee,
      name: value.name
    });
  };

  const handleChange = (property, value) => {
    property === 'rate'
      ? onChange({
          ...rate,
          currency: value.currency,
          amount: value.amount,
          interval: value.interval
        })
      : onChange({
          ...rate,
          [property]: value
        });
  };

  return (
    <div className="RateItemEditable">
      <Grid>
        <GridCell width="1/4" className="right-S">
          <div className="stack-S">
            <TextInput
              placeholder="Label"
              value={rate.name}
              appearance="underline"
              size="S"
              onChange={event => handleChange('name', event.target.value)}
            />
          </div>
          {onRemove && (
            <div
              className="RateItem-remove text-13-600-eggplant"
              onClick={() => onRemove(rate.id)}
            >
              Delete
            </div>
          )}
        </GridCell>
        <GridCell>
          <RateInput
            className="stack-S"
            value={{
              currency: rate.currency,
              amount: rate.amount,
              interval: rate.interval
            }}
            appearance="underline"
            size="S"
            onBlur={value => handleChange('rate', value)}
          />
          <RateAgencyFee
            className="stack-S"
            value={rate.agency_fee}
            showCheckbox={false}
            appearance="underline"
            size="S"
            onChange={value => handleChange('agency_fee', value)}
          />
          <RatePayrollFee
            className="stack-S"
            value={rate.payroll_fee}
            showCheckbox={false}
            appearance="underline"
            size="S"
            onChange={value => handleChange('payroll_fee', value)}
          />
          <RateProcessingFee
            className="stack-S"
            value={rate.processing_fee}
            showCheckbox={false}
            appearance="underline"
            size="S"
            onChange={value => handleChange('processing_fee', value)}
          />
          {/* TODO: need an underline TextAreaInput */}
          <TextInput
            className="stack-S"
            value={rate.description}
            placeholder="Write a note"
            appearance="underline"
            size="S"
            onChange={event => handleChange('description', event.target.value)}
          />
          <RateSelector
            label="Fill with rate card"
            labelClass="genericLink genericLink__subtle"
            showRateCards
            onSelect={rate => fillWithRateCard(rate)}
          />
        </GridCell>
      </Grid>
    </div>
  );
};

RateItemEditable.props = {
  rate: TypeContactRate,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func
};

export default RateItemEditable;
