import AVAILABILITIES_ACTIONS from './availabilities.constants.js';
import { get } from 'lodash';

export const INITIAL_STATE = {
  local: [],
  availabilities: [],
  deleted: [],
  bookings: [],
  request: {},
  loading: false,
  createSuccess: false,
  error: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AVAILABILITIES_ACTIONS.ADD_LOCAL_AVAILABILITY:
      return { ...state, local: [...state.local, action.payload] };
    case AVAILABILITIES_ACTIONS.UPDATE_LOCAL_AVAILABILITY:
      return {
        ...state,
        local: state.local.map(event => {
          if (event.availabilityId !== action.payload.availabilityId) {
            return event;
          }
          return {
            ...event,
            ...action.payload.durationDetails
          };
        }),
        availabilities: state.availabilities.map(availability => {
          if (
            availability.availabilityId !==
            parseInt(action.payload.availabilityId)
          ) {
            return availability;
          }
          return {
            ...availability,
            ...action.payload.durationDetails,
            dirty: true
          };
        })
      };
    case AVAILABILITIES_ACTIONS.DELETE_LOCAL_AVAILABILITY:
      const availabilityId = parseInt(action.payload.availabilityId);
      const found = state.availabilities.find(
        availability => availability.availabilityId === availabilityId
      );
      return {
        ...state,
        local: [
          ...state.local.filter(
            event => event.availabilityId !== action.payload.availabilityId
          )
        ],
        availabilities: [
          ...state.availabilities.filter(
            availability => availability.availabilityId !== availabilityId
          )
        ],
        deleted: [...state.deleted, found].filter(
          availability => availability !== undefined
        )
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITY_REQUEST:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITY_REQUEST_SUCCESS:
      return {
        ...state,
        request: get(action, 'result.data', {}),
        loading: false
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITY_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITIES:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        availabilities: get(action, 'result.data', {}),
        local: []
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        availabilities: []
      };
    case AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES:
      return {
        ...state,
        loading: false,
        creating: action.type
      };
    case AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        createSuccess: action.type,
        creating: false,
        local: [],
        availabilities: [],
        deleted: []
      };
    case AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        createSuccess: false,
        creating: false
      };
    case AVAILABILITIES_ACTIONS.LOG_CREATE_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        createSuccess: false
      };
    case AVAILABILITIES_ACTIONS.LOG_CREATE_AVAILABILITIES_FAILURE:
      return {
        ...state,
        createSuccess: false
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_BOOKINGS:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        bookings: get(action, 'result.data', {})
      };
    case AVAILABILITIES_ACTIONS.GET_AVAILABILITIES_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        bookings: [],
        error: action.error
      };
    default:
      return state;
  }
};
