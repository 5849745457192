import classnames from 'classnames';
import React, { HTMLAttributes } from 'react';
import './ListCellGroup.scss';

const ListCellGroup = ({
  className,
  children,
  ...restProps
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={classnames('ListCellGroup', className)} {...restProps}>
    {children}
  </div>
);

export default ListCellGroup;
