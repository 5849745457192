import Tracker from './Tracker';
import { MixActions } from './actions';

export default class CalloutTracker extends Tracker {
  /**
   * Receive a Mixpanel event and decide whether the tracker should process it
   * @param {MixpanelEvent} event
   */
  track(event) {
    switch (event.action) {
      case MixActions.CALLOUT_ARCHIVE:
      case MixActions.CALLOUT_UNARCHIVE:
      case MixActions.CALLOUT_PREVIEW:
      case MixActions.CALLOUT_CANCEL_PREVIEW:
      case MixActions.CALLOUT_PUBLISH:
      case MixActions.CALLOUT_UNPUBLISH:
        return this._onEvent(event);
      case MixActions.CALLOUT_PUBLIC_OPEN:
      case MixActions.CALLOUT_PUBLIC_SUBMIT:
        return this._onPublicEvent(event);
      default:
        return;
    }
  }

  /**
   * Track a callout event
   * @param {MixpanelEvent} event
   * @private
   */
  _onEvent(event) {
    const { calloutId } = event.properties;
    this.sendEvent(event.action, event, {
      calloutId
    });
  }

  /**
   * Track a public callout event
   * @param {MixpanelEvent} event
   * @private
   */
  _onPublicEvent(event) {
    this.sendEvent(event.action, event, {
      ...event.properties
    });
  }
}
