import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  PressStud,
  Popover,
  PopoverTrigger,
  PopoverContent
} from 'v1/components/shared';

import classnames from 'classnames';
import { get, find } from 'lodash';
import { Button } from 'v1/components/shared';
import './PageTabsWithSteps.scss';

export class PageTabsWithSteps extends Component {
  renderSteps = (steps = []) => {
    return steps.map((tab, index) => {
      return (
        <span
          key={tab.text}
          className={classnames(
            {
              active: tab.container === this.props.activeContainer,
              'PageTabsWithSteps-stepCount_disable':
                this.props.disableTabClicks &&
                tab.container !== this.props.activeContainer
            },
            ['PageTabsWithSteps-tab']
          )}
          onClick={() => {
            this.props.onTabSelect && this.props.onTabSelect(tab.container);
          }}
        >
          <span
            className={classnames(
              {
                'PageTabsWithSteps-stepCount_active':
                  tab.container === this.props.activeContainer
              },
              ['PageTabsWithSteps-stepCount']
            )}
          >
            {index + 1}
          </span>
          {tab.text}
        </span>
      );
    });
  };
  renderPreviousAction = activeStep => {
    const previousAction = get(activeStep, 'previousAction');
    const navigationPreviousWarning = get(
      activeStep,
      'navigationPreviousWarning'
    );

    if (previousAction && navigationPreviousWarning) {
      return (
        <Popover>
          <PopoverTrigger className={previousAction.className}>
            {previousAction.label}
          </PopoverTrigger>
          <PopoverContent className="inset-M">
            <h3>{navigationPreviousWarning.title}</h3>
            <p>{navigationPreviousWarning.description}</p>
            <PressStud
              label="Continue"
              appearance="secondary"
              size="S"
              action={previousAction.onClick}
            />
          </PopoverContent>
        </Popover>
      );
    }
    if (previousAction) {
      return (
        <div className="PageTabsWithSteps-action">
          <Button {...previousAction}>
            <img
              src="/images/arrow_right.svg"
              className="flip"
              width="10px"
              alt=""
            />{' '}
            {previousAction.label}
          </Button>
        </div>
      );
    }
  };
  renderNextAction = activeStep => {
    //const { activeContainer, steps } = this.props;
    const nextAction = get(activeStep, 'nextAction');

    if (nextAction) {
      return <Button {...nextAction}>{nextAction.label}</Button>;
    }
  };
  render() {
    const { steps = null, className, ui } = this.props;
    const activeStep = find(
      steps,
      s => s.container === this.props.activeContainer
    );

    return (
      <div
        className={classnames(
          { collapsed: get(ui, 'sidebarCollapsed') === true },
          ['PageTabsWithSteps', className]
        )}
      >
        <div className="PageTabsWithSteps-action">
          {this.renderPreviousAction(activeStep)}
        </div>
        {steps && (
          <div className="PageTabsWithSteps-steps">
            {this.renderSteps(steps)}
          </div>
        )}
        <div className="PageTabsWithSteps-action text-right">
          {this.renderNextAction(activeStep)}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ ui }) {
  return { ui };
}

export default connect(mapStateToProps, null)(PageTabsWithSteps);
