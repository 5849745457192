export default class LogoutOptions {
  /**
   * Whether or not to clear user preferences such as table filters or calendar
   * filters
   * @type {boolean}
   * @default {true}
   */
  clearPreferences = true;

  /**
   * Whether or not to redirect the user to the farewell page
   * @type {boolean}
   * @default {true}
   */
  redirect = true;
}
