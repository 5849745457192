import React, { Component } from 'react';
import { TagFormBlock } from 'v1/components/shared';
import { get, filter } from 'lodash';
import filesize from 'filesize';
import moment from 'moment-timezone';
import './MediaItemSidebar.scss';

export class MediaItemSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || {}
    };
  }
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.state.selected.id !== nextProps.selected.id) {
      this.setState({ selected: nextProps.selected });
    }
  };
  handleTagUpdate = (field, tag, method) => {
    this.setState(
      prev => {
        let updatedTags = prev.selected.tags || [];
        switch (method) {
          case 'UPDATE':
            updatedTags = [...updatedTags, tag];
            break;
          case 'DELETE':
            updatedTags = filter(updatedTags, t => t.id != tag.id);
            break;
          default:
            break;
        }

        return { selected: { ...prev.selected, tags: updatedTags } };
      },
      () => {
        this.props.handleUpdatePin(this.state.selected.id, this.state.selected);
      }
    );
  };
  renderMainDetails = () => {
    const media = get(this.state, 'selected.file')
      ? get(this.state, 'selected.file')
      : get(this.state, 'selected');

    return (
      <div className="MediaItemSidebar-section MediaItemSidebar-mainDetails">
        <span className="MediaItemSidebar-mainDetails-mediaType">PIN</span>
        <h3 className="trunc">{media.name || media.url}</h3>
        <span className="MediaItemSidebar-mainDetails-author">
          Pinned {moment(media.created_at).fromNow()}
        </span>
      </div>
    );
  };
  renderMediaMeta = () => {
    const file = get(this.state, 'selected.file');
    const media = file || get(this.state, 'selected');

    return (
      <div className="MediaItemSidebar-section MediaItemSidebar-mainDetails">
        <div className="MediaItemSidebar-details-infoItem">
          <span className="MediaItemSidebar-details-infoItem-label">
            Created
          </span>
          <span className="MediaItemSidebar-details-infoItem-value">
            {media.created_at &&
              moment(media.created_at).format('MMMM Do YYYY')}
          </span>
        </div>
        {media.size && file && (
          <div className="MediaItemSidebar-details-infoItem">
            <span className="MediaItemSidebar-details-infoItem-label">
              Size
            </span>
            <span className="MediaItemSidebar-details-infoItem-value">
              {media.size && filesize(media.size)}
            </span>
          </div>
        )}
      </div>
    );
  };
  renderTags = () => {
    const tags = get(this.state, 'selected.tags');

    return (
      <div className="MediaItemSidebar-section MediaItemSidebar-tags">
        <span className="form-label">Tags</span>
        <TagFormBlock
          selector="tag"
          placeholder="Add tags"
          value={tags}
          onChange={this.handleTagUpdate}
        />
      </div>
    );
  };
  render() {
    return (
      <div className="MediaItemSidebar">
        {this.renderMainDetails()}
        {this.renderTags()}
        {this.renderMediaMeta()}
      </div>
    );
  }
}

export default MediaItemSidebar;
