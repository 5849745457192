import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Capability, FeatureFlag } from '__types__';
import { getStatuses } from 'store/v1/statuses/statuses.selectors.js';
import * as entityTypes from 'v1/helpers/entityTypes';
import { useRestrictedAccess } from 'lib/restrictedAccess';

import {
  BookingActions,
  BookingOptions,
  Grid,
  GridCell,
  SystemUser
} from 'v1/components/shared';

import './BookingSidebarDrawerHeader.scss';
import { systemUserClasses } from '~/v1/components/shared/byModel/User/SystemUser/systemUserPickerClasses';
import { ConnectedOwnerPicker } from '~/v4/pages/users/UserPicker.owner.connected';

const BookingSidebarDrawerHeader = ({
  bookingId,
  renderCoreField,
  ownerId,
  onOwnerChange
}) => {
  const statuses = useSelector(state =>
    getStatuses(state, { entity_type: entityTypes.BOOKING, archived: true })
  );

  // As of https://atellio.atlassian.net/browse/AT-924 we do not restrict which booking status
  // can send booking confirmations
  const hasBookingConfirmationAccess = useRestrictedAccess(
    FeatureFlag.BOOKING_CONFIRMATION,
    Capability.BOOKING_UPDATE,
    null
  );

  return (
    <div className="SidebarDrawerHeader tw-z-10">
      <Grid vcenter gutters="S">
        <GridCell className="BookingSidebarDrawerHeader-main">
          <div className="BookingSidebarDrawerHeader-title">
            <h3>Booking</h3>
          </div>
        </GridCell>
        <GridCell width="auto">
          <ConnectedOwnerPicker
            onSelect={onOwnerChange}
            tooltip="Booking owner"
          >
            <SystemUser
              tabIndex={0}
              id={ownerId}
              includeImage
              className={systemUserClasses}
            />
          </ConnectedOwnerPicker>
        </GridCell>
        <GridCell width="auto">
          {renderCoreField('status_id', { statuses, size: 'S' })}
        </GridCell>
        {hasBookingConfirmationAccess && (
          <GridCell width="auto">
            <BookingActions bookingId={bookingId} />
          </GridCell>
        )}
        <GridCell width="auto">
          <BookingOptions bookingId={bookingId} />
        </GridCell>
      </Grid>
    </div>
  );
};

BookingSidebarDrawerHeader.propTypes = {
  bookingId: PropTypes.number,
  renderCoreField: PropTypes.func
};

export default BookingSidebarDrawerHeader;
