import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// REDUX
import { openModal } from 'store/v1/ui/ui.actions.js';
import { deleteResourceSlotAssignment } from 'store/v1/productions/productions.actions.js';
import { getStatuses } from 'store/v1/statuses/statuses.selectors.js';
import {
  updateBooking,
  createBooking
} from 'store/v1/bookings/bookings.actions.js';

// COMPONENTS
import { ButtonWithDropdown } from 'v1/components/shared';

// INTERNAL HELPERS
import { createNewEventObject } from 'v1/helpers/byModel/EventHelper';
import { createBookingObject } from 'v1/helpers/byModel/BookingHelper';

// IMPORTS
import get from 'lodash/get';

const ResourceStatusActions = ({
  booking,
  resource,
  slot,
  production,
  resourceSlotAssignment
}) => {
  const dispatch = useDispatch();
  const bookingTypes = useSelector(state => state.booking_types);
  const statuses = useSelector(state => state.statuses);
  const bookingsLoading = useSelector(state => state.bookings.loading);
  const confirmedStatus = useSelector(state =>
    get(getStatuses(state, { status_type: 'CONFIRMED' }), '0')
  );
  const { id: resourceSlotId } = slot;
  const { id: resourceId } = resource;

  let actions = [];

  const statusId = get(booking, 'status_id');
  const status = get(statuses, ['data', statusId]);

  actions = buildActions(status);

  function editBooking() {
    dispatch(
      openModal('ResourceEventsModal', {
        bookingId:
          get(resourceSlotAssignment, 'booking_id') || get(booking, 'id')
      })
    );
  }

  function updateBookingWithAssignment() {
    const existingAssignments = get(booking, 'resource_slot_assignments', []);
    return {
      ...booking,
      resource_slot_assignments: [
        ...existingAssignments,
        {
          contact_id: resourceId,
          resource_slot_id: resourceSlotId,
          events: [
            createNewEventObject({
              ...production.production_date,
              event_type: 'RESOURCE_SLOT_ASSIGNMENT'
            })
          ]
        }
      ],
      status_id: confirmedStatus.id
    };
  }

  function bookingHasAssignments() {
    return (
      booking &&
      booking.resource_slot_assignments &&
      booking.resource_slot_assignments.length
    );
  }

  function createBookingFromUnassignedResource() {
    if (booking && bookingHasAssignments()) {
      dispatch(
        openModal('ResourceEventsModal', {
          bookingId: get(booking, 'id'),
          resourceSlotId,
          resourceSlotAssignmentEvent: createNewEventObject({
            ...production.production_date,
            event_type: 'RESOURCE_SLOT_ASSIGNMENT'
          }),
          statusId: get(booking, 'status_id')
        })
      );
    } else {
      dispatch(
        openModal('ResourceEventsModal', {
          resourceId: resource.id,
          event: production.production_date,
          resourceSlotId,
          resourceSlotAssignmentEvent: createNewEventObject({
            ...production.production_date,
            event_type: 'RESOURCE_SLOT_ASSIGNMENT'
          }),
          statusId: get(booking, 'status_id')
        })
      );
    }
  }
  function removeAssignment() {
    dispatch(
      deleteResourceSlotAssignment({
        production_id: production.id,
        resource_slot_id: resourceSlotId,
        resource_slot_assignment_id: resourceSlotAssignment.id,
        booking_id: resourceSlotAssignment.booking_id
      })
    );
  }
  function updateAssignedBookingToConfirm() {
    dispatch(
      updateBooking(booking.id, {
        ...booking,
        status_id: confirmedStatus.id
      })
    );
  }

  function assignBookAndConfirm() {
    if (booking && bookingHasAssignments()) {
      dispatch(updateBooking(booking.id, updateBookingWithAssignment()));
    } else {
      const newBooking = createBookingObject(bookingTypes, {
        event: get(production, 'production_date', null),
        resource: resource,
        slot: slot,
        status: confirmedStatus
      });
      dispatch(createBooking(newBooking));
    }
  }

  function buildActions(status = {}) {
    if (resourceSlotAssignment) {
      // BUSINESS LOGIC
      // If booking is not yet confirmed render a Confirm button as the next step. Otherwise display the status and click through to edit booking.
      return [
        {
          className:
            status.status_type === 'CONFIRMED'
              ? 'btn btn-default btn-small'
              : 'btn btn-primary btn-small',
          action: () =>
            status.status_type === 'CONFIRMED'
              ? editBooking()
              : updateAssignedBookingToConfirm(),
          arrowShade: status.status_type === 'CONFIRMED' ? 'dark' : 'light',
          text: status.status_type === 'CONFIRMED' ? 'Edit Booking' : 'Confirm'
        },
        {
          action: () => editBooking(),
          icon: '/images/icon_edit.svg',
          text: 'Edit booking'
        },
        {
          action: removeAssignment,
          icon: '/images/icon_delete.svg',
          text: 'Unassign'
        }
      ];
    } else {
      if (slot.is_location) {
        // BUSINESS LOGIC
        // If location users want to have the default option to assign and confirm immediately
        return [
          {
            className: 'btn btn-primary btn-small',
            action: assignBookAndConfirm,
            arrowShade: 'light',
            text: 'Assign & Confirm'
          },
          {
            action: createBookingFromUnassignedResource,
            icon: '/images/icon_edit.svg',
            text: 'Assign & Edit'
          }
        ];
      } else {
        return [
          {
            action: createBookingFromUnassignedResource,
            arrowShade: 'dark',
            className: 'btn btn-default btn-small',
            text: 'Assign & Edit'
          },
          {
            action: assignBookAndConfirm,
            text: 'Assign & Confirm',
            icon: '/images/icon_edit.svg'
          }
        ];
      }
    }
  }

  if (actions.length) {
    return (
      <ButtonWithDropdown
        className={get(actions, [0, 'className'])}
        arrowShade={get(actions, [0, 'arrowShade'])}
        actions={actions}
        loading={bookingsLoading}
        stopPropagation
      />
    );
  }
  return <></>;
};
ResourceStatusActions.propTypes = {
  booking: PropTypes.object,
  resourceId: PropTypes.number,
  production: PropTypes.object,
  resourceSlotAssignment: PropTypes.object,
  resourceSlotId: PropTypes.number
};
export default ResourceStatusActions;
