import React from 'react';
import { RateFee } from 'v1/components/shared';
import { useRestrictedAccess } from 'lib/restrictedAccess';
import { FeatureFlag } from '__types__';

const RatePayrollFee = ({ defaultValue = 20, ...otherProps }) => {
  const isWalmart = useRestrictedAccess(FeatureFlag.CUSTOM_WALMART, null, null);
  if (isWalmart) {
    // https://atellio.atlassian.net/browse/SERVICE-519
    defaultValue = 23;
  }
  return (
    <RateFee
      label="Add payroll fee"
      placeholder="Payroll fee"
      defaultValue={defaultValue}
      {...otherProps}
    />
  );
};

export default RatePayrollFee;
