import { SLOT_CATEGORIES_ACTIONS } from 'store/v1/slot_categories/slot_categories.constants.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';

export const INITIAL_STATE = {
  data: {},
  paging: {},
  order: [],
  loading: false,
  fetched: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY:
    case SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY:
    case SLOT_CATEGORIES_ACTIONS.DELETE_SLOT_CATEGORY:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case AUTH_ACTIONS.LOAD_AUTH_SUCCESS: {
      const { slot_categories = [] } = action.result;
      return {
        ...state,
        data: slot_categories.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: slot_categories.map(item => item.id),
        loading: false,
        fetched: true
      };
    }
    case SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        loading: false
      };
    case SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.id]: action.result },
        order: [...state.order, action.result.id],
        loading: false
      };
    case SLOT_CATEGORIES_ACTIONS.DELETE_SLOT_CATEGORY_SUCCESS:
      let { [action.slot_category_id]: deleted, ...rest } = state.data;
      return {
        ...state,
        data: rest,
        order: state.order.filter(i => i !== action.slot_category_id),
        loading: false
      };
    case PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_BUDGET_EXPENSE_SUCCESS:
      if (
        action.result.slot_category &&
        !state.data[action.result.slot_category.id]
      )
        return {
          ...state,
          data: {
            ...state.data,
            [action.result.slot_category.id]: action.result.slot_category
          },
          order: state.order.concat(action.result.slot_category.id)
        };
      else return state;
    case SLOT_CATEGORIES_ACTIONS.UPDATE_SLOT_CATEGORY_FAILURE:
    case SLOT_CATEGORIES_ACTIONS.CREATE_SLOT_CATEGORY_FAILURE:
    case SLOT_CATEGORIES_ACTIONS.DELETE_SLOT_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
