import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { find, get } from 'lodash';

import {
  updateProductionGroup,
  createProductionGroup
} from 'store/v1/productions/productions.actions.js';
import { closeModal, displayAlert } from 'store/v1/ui/ui.actions.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';

import {
  Label,
  ModalWrapper,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  PressStud,
  TextInput
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

const ProductionGroupModal = () => {
  const dispatch = useDispatch();

  // data passed to the modal through the state
  const data = useSelector(state => state.ui.data);
  const productionId = data.productionId;
  const groupId = data.groupId;

  const productions = useSelector(state => state.productions);
  const production = get(productions, ['data', productionId], {});
  const group = find(production.groups, g => g.id === groupId);

  const isEditMode = group ? true : false;
  const initialState = isEditMode ? group.name : '';
  const [name, setName] = useState(initialState);

  const dispatchCloseModal = () => dispatch(closeModal());

  const onModalAction = () => {
    if (isEditMode) {
      const updatedGroup = {
        ...group,
        name
      };
      dispatch(updateProductionGroup(group.id, updatedGroup));
    } else {
      dispatch(createProductionGroup({ name, entity_id: productionId }));
    }
  };

  useEvent(
    [
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION_GROUP,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_GROUP
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Updated successfully'));
        dispatchCloseModal();
      },
      onFailure: () => {
        dispatch(displayAlert('error', 'An error occurred'));
        dispatchCloseModal();
      }
    }
  );

  return (
    <ModalWrapper size="S">
      <ModalHeader title={group ? 'Edit group' : 'Create group'} />
      <ModalContent>
        <ModalScrollable>
          <div className="stack-S">
            <Label htmlFor="name">Name</Label>
            <TextInput
              value={name}
              onChange={({ target }) => setName(target.value)}
              initialFocus
              removeAutocomplete
            />
          </div>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            action={dispatchCloseModal}
          />
          <PressStud
            label="Save"
            appearance="primary"
            action={onModalAction}
            isLoading={productions.loading}
            isDisabled={name.length === 0}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ProductionGroupModal;
