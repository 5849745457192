import { SearchQueryOperator } from '__types__/general';

export interface Paging {
  total: number;
  page: number;
  count_per_page: number;
}

export interface ListResponse<T, Q> {
  results: T[];
  paging: Paging;
  query: Q;
}

export type ListQueryFilters<Entity extends object> = {
  [key in keyof Entity]?: SearchQueryOperator;
};

export interface ListQuery<Entity extends object> {
  order_by?: {
    field: string; // keyof Entity is too strict, we have custom fields
    direction: 'asc' | 'desc';
  };
  filters?: ListQueryFilters<Entity>;
  query?: string;
  archived?: boolean;
  p?: number;
  count?: number;
}

export interface BatchQuery<EntityId> {
  ids: EntityId[];
}

export function createEmptyListQuery<
  Entity extends object
>(): ListQuery<Entity> {
  return {
    filters: {}
  };
}

export function createSimpleListResponse<T, Q extends ListQuery<any>>(
  results: T[],
  paging: Paging = {
    page: 0,
    count_per_page: results.length,
    total: results.length
  },
  query: Q = { filters: {} } as Q
): ListResponse<T, Q> {
  return {
    results,
    paging,
    query
  };
}
