import classNames from 'classnames';
import React from 'react';
import ReactLoading from 'react-loading';
import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';
import './Loading.scss';

export interface LoadingProps {
  className?: string;
  text?: string;
  light?: boolean;
  /**
   * @deprecated use textPlaceholder instead
   */
  options?: {
    disableText?: boolean;
  };
  layout?: 'default' | 'fullscreen';
  textPlacement?: 'center' | 'none';
}

export function Loading({
  className,
  text,
  light,
  options = {},
  layout = 'default',
  textPlacement
}: LoadingProps) {
  const disableText =
    textPlacement === undefined
      ? options.disableText
      : textPlacement === 'none';
  return (
    <div
      data-id="Loading"
      className={classNames(
        'Loading',
        { default: '', fullscreen: 'pageCenter' }[layout],
        className
      )}
    >
      <div className="Loading-container">
        <div className="Loading-spinner">
          <ReactLoading
            type="spin"
            color={light ? SYSTEM_COLOURS.WHITE : SYSTEM_COLOURS.BLACK}
            height={15}
            width={15}
          />
        </div>
        {!disableText && <h5>{text ? text : 'Just a moment'}</h5>}
      </div>
    </div>
  );
}

export default Loading;
