import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const GROUP_ACTIONS = mapValues(
  mirror({
    GET_GROUPS: null,
    GET_GROUPS_SUCCESS: null,
    GET_GROUPS_FAILURE: null,

    CREATE_GROUP: null,
    CREATE_GROUP_SUCCESS: null,
    CREATE_GROUP_FAILURE: null,

    ADD_TO_GROUP: null,
    ADD_TO_GROUP_SUCCESS: null,
    ADD_TO_GROUP_FAILURE: null,

    DELETE_GROUP: null,
    DELETE_GROUP_SUCCESS: null,
    DELETE_GROUP_FAILURE: null,

    UPDATE_GROUP: null,
    UPDATE_GROUP_SUCCESS: null,
    UPDATE_GROUP_FAILURE: null,

    REORDER_GROUPS: null,
    REORDER_GROUPS_SUCCESS: null,
    REORDER_GROUPS_FAILURE: null
  }),
  value => value
);
