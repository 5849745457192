import React, { Component } from 'react';
import { Button } from 'v1/components/shared';
import classnames from 'classnames';
import { get, find, size } from 'lodash';

export class ShortlistVotes extends Component {
  render() {
    const {
      block,
      user,
      loading,
      isGuest,
      onUpvote,
      onDownvote,
      showFeedback
    } = this.props;
    const authorId = get(user, 'id');
    const upvotes = get(block, 'upvotes');
    const downvotes = get(block, 'downvotes');

    const hasUpvoted =
      authorId !== undefined &&
      find(upvotes, v => {
        if (isGuest) {
          return get(v, 'guest.id') == authorId;
        } else {
          return v.user_id == authorId;
        }
      });
    const hasDownvoted =
      authorId !== undefined &&
      find(downvotes, v => {
        if (isGuest) {
          return get(v, 'guest.id') == authorId;
        } else {
          return v.user_id == authorId;
        }
      });

    return (
      <div className="ShortlistVotes">
        <Button
          className={classnames([
            hasUpvoted ? 'btn-primary' : 'btn-default',
            'btn btn-small pad-right-small'
          ])}
          onClick={onUpvote}
          dark={!hasUpvoted}
          isLoading={loading === 'UPVOTE_SHORTLIST_BLOCK'}
          dataTip="Upvote"
        >
          {hasUpvoted ? (
            <img className="icon" src="/images/icon_upvote_light.svg" alt="" />
          ) : (
            <img className="icon" src="/images/icon_upvote_dark.svg" alt="" />
          )}
          {showFeedback && size(upvotes)}
        </Button>
        <Button
          className={classnames([
            hasDownvoted ? 'btn-pink' : 'btn-default',
            'btn btn-small'
          ])}
          onClick={onDownvote}
          dark={!hasDownvoted}
          isLoading={loading === 'DOWNVOTE_SHORTLIST_BLOCK'}
          dataTip="Downvote"
        >
          {hasDownvoted ? (
            <img
              className="icon"
              src="/images/icon_downvote_light.svg"
              alt=""
            />
          ) : (
            <img className="icon" src="/images/icon_downvote_dark.svg" alt="" />
          )}
          {showFeedback && size(downvotes)}
        </Button>
      </div>
    );
  }
}

export default ShortlistVotes;
