import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { SOCIAL_PROVIDERS } from 'v1/helpers/DropdownDataSets';
import { socialLinkCleaner } from 'v1/helpers/misc';

import TypeTextInput from 'v1/components/shared/byType/text/TextInput/__types__/TypeTextInput';
import { TextInputGroup } from 'v1/components/shared';

// NOTE: Currently we only use this with an object with keys of value_1 & value_2 - can easily be adapted
const SocialInput = ({ value, onBlur, onChange, ...props }) => {
  const handle = get(value, 'value_1');
  const platform = get(value, 'value_2');
  const url = `http://${platform}.com/${handle}`;

  const parseValue = value => {
    const handle = socialLinkCleaner(get(value, 'value_1', ''));
    return { ...value, value_1: handle };
  };

  const handleBlur = value => {
    onBlur && onBlur(parseValue(value));
  };

  const handleChange = value => {
    onChange && onChange(parseValue(value));
  };

  return (
    <TextInputGroup
      type="url"
      placeholder="Handle"
      value={value}
      valueKey="value_1"
      prepend={{
        valueKey: 'value_2',
        options: SOCIAL_PROVIDERS
      }}
      overlayLabel={handle}
      overlayAction={url}
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
};

SocialInput.propTypes = {
  ...TypeTextInput,
  value: PropTypes.shape({
    value_1: PropTypes.string,
    value_2: PropTypes.string
  }),
  onBlur: PropTypes.func,
  onChange: PropTypes.func
  // Inherits standard UrlInput, TextInput & HTML input properties
};

export default SocialInput;
