import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PinItem, ModalWrapper } from 'v1/components/shared';

import { displayAlert } from 'store/v1/ui/ui.actions.js';
import './MediaFocusModal.scss';
import { get, isEmpty, findIndex, size, has, chain, filter } from 'lodash';
import { IMAGE_QUALITY_OPTIONS } from 'lib/media/imageHelper';

export class MediaFocusModal extends Component {
  constructor(props) {
    super(props);

    this.gallery = {};

    this.state = {
      mediaSelected: get(props.ui, 'data.mediaSelected'),
      mediaList: this.orderMedia(get(props.ui, 'data.mediaList')),
      mediaType: get(props.ui, 'data.mediaType'),
      activeShortlistMedia: 0
    };
  }
  componentDidMount = () => {
    this.calculateGalleryDimensions();
    window.addEventListener('resize', this.calculateGalleryDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.calculateGalleryDimensions);
  };
  orderMedia = media => {
    if (!isEmpty(media) && has(media[0], 'order')) {
      media = chain(media)
        .filter(m => !m.archived)
        .orderBy(['order', 'asc'])
        .value();
    }
    return media;
  };
  calculateGalleryDimensions = () => {
    this.setState({
      dimensions: {
        height: this.gallery.clientHeight,
        width: this.gallery.clientWidth
      }
    });
  };
  onDeleteMedia = () => {};
  onGalleryMove = dest => {
    const { mediaSelected, mediaList } = this.state;
    const mediaListSize = size(mediaList);
    let selectedIndex = findIndex(mediaList, m => m.id === mediaSelected.id);

    if (this.state.mediaType === 'SHORTLIST_BLOCKS') {
      let activeShortlistMedia = this.state.activeShortlistMedia;

      let pins = get(this.state, 'mediaSelected.pins');
      let mediaLength = size(filter(pins, p => !p.archived));

      if (dest === 'PREVIOUS') {
        if (activeShortlistMedia === 0) {
          activeShortlistMedia = mediaLength - 1;
        } else {
          activeShortlistMedia--;
        }
      }
      if (dest === 'NEXT') {
        if (activeShortlistMedia === mediaLength - 1) {
          activeShortlistMedia = 0;
        } else {
          activeShortlistMedia++;
        }
      }
      return this.setState({ activeShortlistMedia });
    }

    if (dest === 'PREVIOUS') {
      if (selectedIndex === 0) {
        selectedIndex = mediaListSize - 1;
      } else {
        selectedIndex--;
      }
    }
    if (dest === 'NEXT') {
      if (selectedIndex === mediaListSize - 1) {
        selectedIndex = 0;
      } else {
        selectedIndex++;
      }
    }

    this.setState({ mediaSelected: mediaList[selectedIndex] });
  };
  renderControls = () => {
    return (
      <div className="MediaFocusModal-gallery-controls">
        <span
          className="MediaFocusModal-gallery-controls_previous"
          onClick={() => {
            this.onGalleryMove('PREVIOUS');
          }}
        >
          <img
            src="/images/icon-arrow-right.svg"
            width="13px"
            className="flip"
            alt=""
          />
        </span>
        <span
          className="MediaFocusModal-gallery-controls_right"
          onClick={() => {
            this.onGalleryMove('NEXT');
          }}
        >
          <img src="/images/icon-arrow-right.svg" width="13px" alt="" />
        </span>
      </div>
    );
  };
  renderShortlistPins = pins => {
    const pin = get(pins, [this.state.activeShortlistMedia]);
    const content = (
      <PinItem
        pin={pin}
        boundTo={{
          height: this.gallery.clientHeight,
          width: this.gallery.clientWidth
        }}
        settings={{
          video: { autoPlay: true },
          image: { ...IMAGE_QUALITY_OPTIONS.large }
        }}
      />
    );

    return content;
  };
  renderEmptyGallery = () => {
    const emptyGalleryComponent = get(
      this.props.ui,
      'data.emptyGalleryComponent'
    );
    return (
      emptyGalleryComponent && emptyGalleryComponent(this.state.mediaSelected)
    );
  };
  renderMediaGallery = () => {
    const { mediaSelected, mediaType } = this.state;
    let mediaItem;
    switch (mediaType) {
      case 'PINS':
        mediaItem = (
          <PinItem
            pin={mediaSelected}
            onDeleteMedia={this.onDeleteMedia}
            deleting={this.deleting}
            boundTo={{
              height: this.gallery.clientHeight,
              width: this.gallery.clientWidth
            }}
            settings={{
              video: { autoPlay: true },
              image: {
                ...IMAGE_QUALITY_OPTIONS.large
              }
            }}
          />
        );
        break;
      case 'SHORTLIST_BLOCKS':
        const pins = this.orderMedia(get(this.state, 'mediaSelected.pins'));
        mediaItem = !isEmpty(pins)
          ? this.renderShortlistPins(pins)
          : this.renderEmptyGallery();
        break;
      default:
        break;
    }
    return (
      <div
        ref={element => (this.gallery = element)}
        className="MediaFocusModal-gallery"
      >
        <div className="MediaFocusModal-gallery-item">{mediaItem}</div>
        {this.state.mediaList &&
          this.state.mediaList.length > 1 &&
          this.renderControls()}
      </div>
    );
  };
  renderMediaSidebar = () => {
    const mediaSidebar = get(this.props.ui, 'data.mediaSidebar');
    if (mediaSidebar) {
      return (
        <div className="MediaFocusModal-sidebar">
          {mediaSidebar &&
            mediaSidebar(this.state.mediaSelected, block => {
              this.setState({
                mediaSelected: block,
                activeShortlistMedia: 0,
                ...(this.state.mediaType === 'SHORTLIST_BLOCKS' && {
                  mediaList: block.pins
                })
              });
            })}
        </div>
      );
    }
  };
  render() {
    return (
      <ModalWrapper size="XL" className="MediaFocusModal">
        <div className="MediaFocusModal-wrapper">
          {this.renderMediaGallery()}
          {this.renderMediaSidebar()}
        </div>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ events, ui }) {
  return { events, ui };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayAlert
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFocusModal);
