import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PressStud } from 'v1/components/shared';
import { ProductionBudgetInvoiceItem } from 'v1/components/shared';
import { ListCellGroup } from 'v1/components/shared';
import { BOOKING_ACTIONS } from 'store/v1/bookings/bookings.constants.js';
import {
  createInvoice,
  updateInvoice,
  deleteInvoice
} from 'store/v1/bookings/bookings.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { get, size, chain } from 'lodash';
import { v4 } from 'uuid';

import './BookingInvoiceManagement.scss';

const BookingInvoiceManagement = ({ bookingId }) => {
  const dispatch = useDispatch();
  const bookings = useSelector(state => state.bookings);
  const { error } = bookings;
  const [invoices, UpdateInvoiceList] = useState(
    get(bookings, ['data', bookingId, 'invoices'], [])
  );
  const invoicesList = chain(invoices)
    .filter(i => !i.archived)
    .orderBy(['order', 'asc'])
    .value();

  useEvent(
    [
      BOOKING_ACTIONS.CREATE_INVOICES,
      BOOKING_ACTIONS.UPDATE_INVOICE,
      BOOKING_ACTIONS.DELETE_INVOICE
    ],
    {
      onSuccess: () => {
        dispatch(displayAlert('success', 'Updated succesfully'));
        UpdateInvoiceList(get(bookings, ['data', bookingId, 'invoices']));
      },
      onFailure: () => dispatch(displayAlert('error', error.message))
    }
  );
  const onUpdateInvoice = invoice => {
    dispatch(
      updateInvoice(
        {
          booking_id: bookingId,
          invoice_id: invoice.id
        },
        invoice
      )
    );
  };
  const onCreateInvoice = invoice => {
    dispatch(createInvoice({ booking_id: bookingId }, invoice));
  };
  const onNewInvoice = () => {
    const newInvoice = {
      id: `temp-${v4()}`,
      order: size(invoicesList),
      document_type: 'INVOICE'
    };
    UpdateInvoiceList([...invoicesList, newInvoice]);
  };
  const onRemoveInvoice = invoice => {
    const updatedInvoices = invoicesList.filter(i => i.id != invoice.id);
    UpdateInvoiceList(updatedInvoices);
    if (typeof invoice.id === 'number') {
      dispatch(
        deleteInvoice({ booking_id: bookingId, invoice_id: invoice.id })
      );
    }
  };
  const renderInvoices = () => {
    return (
      <>
        <h3>Booking Invoices</h3>
        <ListCellGroup className="stack-M">
          {invoicesList.map(invoice => (
            <ProductionBudgetInvoiceItem
              key={`invoice-${invoice.id}`}
              invoice={invoice}
              onRemoveInvoice={onRemoveInvoice}
              onUpdateInvoice={onUpdateInvoice}
              onCreateInvoice={onCreateInvoice}
            />
          ))}
        </ListCellGroup>
        <PressStud
          label="New Invoice"
          icon="addBlack"
          size="S"
          action={onNewInvoice}
        />
      </>
    );
  };

  return renderInvoices();
};

export default BookingInvoiceManagement;
