import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalWrapper,
  ModalNavigation,
  ModalContent,
  ModalScrollable,
  Grid,
  GridCell,
  PressStud,
  ButtonToggleGroup,
  ButtonToggle
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import EventConflict from './EventConflict/EventConflict';
import BookingConflict from './BookingConflict/BookingConflict';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import { formatBooking } from 'store/v1/bookings/bookings.formatter.js';
import { updateProductionConflicts } from 'store/v1/productions/productions.actions.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { tryAndGetProductionConflicts } from 'v1/helpers/productionValidator';
import { makeGetSlotName } from 'store/v1/slot_categories/slot_categories.selectors.js';

import _get from 'lodash/get';
import _size from 'lodash/size';
import './ProductionConflictsModal.scss';

const ProductionConflictsModal = ({ onRequestClose }) => {
  // TODO temporary measure to disable the delete / cancel button
  const allowCancellation = false;

  // STATE
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  const productions = useSelector(state => state.productions);
  const bookings = useSelector(state => state.bookings);
  const contacts = useSelector(state => state.contacts);
  const getSlotName = useSelector(makeGetSlotName);
  const modalData = _get(ui, 'data', {});

  const { production, conflicts, onCancel } = modalData;

  const [changes, setChanges] = useState(() => {
    if (conflicts && !conflicts.isEmpty) return conflicts;
    return tryAndGetProductionConflicts(
      production, // new production
      productions.data[production.id], // prev production
      bookings // bookings store
    );
  });

  // EFFECTS
  useEvent([PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION_EVENT], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Event Updated'));
      onRequestClose();
    },
    onFailure: () => {
      dispatch(displayAlert('error', 'Update Failed'));
    }
  });

  useEvent([PRODUCTIONS_ACTIONS.UPDATE_CONFLICTS], {
    onSuccess: () => {
      dispatch(closeModal());
      dispatch(displayAlert('success', 'Updated succesfully'));
    },
    onFailure: () => dispatch(displayAlert('error', 'An error has occurred'))
  });

  function handleSave() {
    let body = {
      events_to_update: [],
      events_to_delete: [],
      bookings_to_update: [],
      bookings_to_delete: [],
      production_date: production.production_date
    };
    changes.production_events
      .concat(changes.resource_slots_events)
      .forEach(({ _action, ...evt }) => {
        if (_action === 'UPDATE') body.events_to_update.push(evt);
        if (_action === 'DELETE') body.events_to_delete.push({ id: evt.id });
      });
    changes.bookings.forEach(({ _action, ...booking }) => {
      if (_action === 'UPDATE') {
        const newBooking = {
          ...booking,
          resource_slot_assignments: booking.resource_slot_assignments.filter(
            a => a._action !== 'DELETE'
          )
        };
        body.bookings_to_update.push(formatBooking(newBooking));
      }
      if (_action === 'DELETE')
        body.bookings_to_delete.push({ id: booking.id });
    });

    dispatch(updateProductionConflicts(body, production.id));
  }

  return (
    <ModalWrapper size="M">
      <ModalHeader title="Move production" />
      <ModalContent>
        <ModalScrollable>
          <div className="ProductionConflicts">
            {/* Production Dates */}
            <div className="ProductionConflicts-group">
              <h3>Production dates</h3>
              <EventConflict
                evt={production.production_date}
                prevEvt={productions.data[production.id].production_date}
                isReadOnly
              />
            </div>

            {/* Production events (event_type == PRODUCTION_SCHEDULE) */}
            {_size(changes.production_events) > 0 && (
              <div className="ProductionConflicts-group">
                <h3>Events</h3>
                {changes.production_events.map(evt => {
                  return (
                    <Grid
                      className="ProductionConflicts-group-item"
                      key={evt.id}
                    >
                      <GridCell width="auto">
                        <EventConflict
                          title={evt.note}
                          evt={evt}
                          prevEvt={production.events.find(e => e.id === evt.id)}
                          onChange={value =>
                            setChanges(prev => ({
                              ...prev,
                              production_events: prev.production_events.map(e =>
                                e.id === value.id ? value : e
                              )
                            }))
                          }
                          isDiscared={evt._action === 'DELETE'}
                        />
                      </GridCell>
                      <GridCell width="initial">
                        <ButtonToggleGroup
                          onChange={_action =>
                            setChanges(prev => ({
                              ...prev,
                              production_events: prev.production_events.map(e =>
                                e.id === evt.id ? { ...evt, _action } : e
                              )
                            }))
                          }
                        >
                          <ButtonToggle
                            value="UPDATE"
                            label="Update"
                            selected={evt._action === 'UPDATE'}
                          />
                          {allowCancellation === true && (
                            <ButtonToggle
                              value="DELETE"
                              label="Delete now"
                              selected={evt._action === 'DELETE'}
                            />
                          )}
                        </ButtonToggleGroup>
                      </GridCell>
                    </Grid>
                  );
                })}
              </div>
            )}

            {/* Resource slot events (event_type == RESOURCE_SLOT) */}
            {_size(changes.resource_slots_events) > 0 && (
              <div className="ProductionConflicts-group">
                <h3>Roles events</h3>
                {changes.resource_slots_events.map(evt => {
                  const resourceSlot = production.resource_slots.find(
                    s => s.id == evt.entity_id
                  );
                  return (
                    <Grid
                      className="ProductionConflicts-group-item"
                      key={evt.id}
                    >
                      <GridCell width="auto">
                        <EventConflict
                          title={getSlotName(resourceSlot)}
                          evt={evt}
                          prevEvt={resourceSlot.events.find(
                            e => e.id === evt.id
                          )}
                          onChange={value =>
                            setChanges(prev => ({
                              ...prev,
                              resource_slots_events:
                                prev.resource_slots_events.map(e =>
                                  e.id === value.id ? value : e
                                )
                            }))
                          }
                          isDiscared={evt._action === 'DELETE'}
                        />
                      </GridCell>
                      <GridCell width="initial">
                        <ButtonToggleGroup
                          onChange={_action =>
                            setChanges(prev => ({
                              ...prev,
                              resource_slots_events:
                                prev.resource_slots_events.map(e =>
                                  e.id === evt.id ? { ...evt, _action } : e
                                )
                            }))
                          }
                        >
                          <ButtonToggle
                            value="UPDATE"
                            label="Update"
                            selected={evt._action === 'UPDATE'}
                          />
                          {allowCancellation && (
                            <ButtonToggle
                              value="DELETE"
                              label="Delete yo"
                              selected={evt._action === 'DELETE'}
                            />
                          )}
                        </ButtonToggleGroup>
                      </GridCell>
                    </Grid>
                  );
                })}
              </div>
            )}

            {/* Bookings & Assignments (event_type == BOOKING) */}
            {changes.bookings && changes.bookings.length > 0 && (
              <div className="ProductionConflicts-group">
                <h3>Bookings</h3>
                {changes.bookings.map(newBooking => (
                  <BookingConflict
                    key={newBooking.id}
                    allowCancellation={allowCancellation}
                    newBooking={newBooking}
                    prevBooking={bookings.data[newBooking.id]}
                    contact={contacts.data[newBooking.contact_id]}
                    production={production}
                    onChange={value =>
                      setChanges(prev => ({
                        ...prev,
                        bookings: prev.bookings.map(b =>
                          b.id == newBooking.id ? value : b
                        )
                      }))
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            action={() => {
              onRequestClose();
              onCancel();
            }}
          />
          <PressStud
            label="Confirm"
            appearance="primary"
            isLoading={productions.loading === 'UPDATE_PRODUCTION'}
            action={handleSave}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ProductionConflictsModal;
