import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Text from 'react-format-text';
import './NoteItem.scss';

import { SystemUser } from 'v1/components/shared';

const NoteItem = ({ note, isGuestUser, onDeleteNote, disabled }) => (
  <div className="NoteItem inset-S">
    <div className="NoteItem-content">
      <div className="NoteItem-content-head">
        <span className="NoteItem-user">
          <SystemUser
            id={note.user_id}
            user={note.user}
            isGuestUser={isGuestUser}
            includeImage
            className="NoteItem-user-content"
          />
        </span>
        <span className="NoteItem-timestamp">
          {moment(note.created_at).calendar()}
        </span>
      </div>

      <p className="NoteItem-text">
        <Text>{note.note}</Text>
      </p>

      {!disabled && onDeleteNote && (
        <div className="NoteItem-edit">
          <span
            className="NoteItem-delete"
            onClick={() => onDeleteNote(note.id)}
          >
            Archive
          </span>
        </div>
      )}
    </div>
  </div>
);

NoteItem.propTypes = {
  note: PropTypes.shape({}), // TODO: type
  isGuestUser: PropTypes.any, // Used by individual uses of NoteItem only // TODO: type
  onDeleteNote: PropTypes.func
};

export default NoteItem;
