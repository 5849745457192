import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mergeResource } from 'store/v1/system/system.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';

import ResourcePane from 'v1/components/feature/ResourceComponents/ResourcePane/ResourcePane';
import {
  PressStud,
  Grid,
  ModalWrapper,
  ModalContent,
  ModalScrollable
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';

import classnames from 'classnames';
import moment from 'moment-timezone';
import get from 'lodash/get';
import v4 from 'uuid';

import './MergeResourcesModal.scss';

class MergeResourcesModal extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      hovered: 'LEFT'
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        this.props.displayAlert('success', 'Successfully Merged');

        setTimeout(() => {
          this.props.onRequestClose();
        }, 100);
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }
  onMerge = direction => {
    const resource1 = get(this.props, 'ui.data.resource1');
    const resource2 = get(this.props, 'ui.data.resource2');

    this.setState({ event: v4() }, () => {
      let data;
      if (direction === 'RIGHT') {
        data = { from_id: resource1.id, to_id: resource2.id };
      }
      if (direction === 'LEFT') {
        data = { from_id: resource2.id, to_id: resource1.id };
      }
      this.props.mergeResource(data, this.state.event);
    });
  };
  renderResourceDetails = (resource, direction) => {
    const { loading } = this.props.system;
    return (
      <div
        className={classnames(
          {
            active: direction === this.state.hovered,
            inactive: this.state.hovered && direction !== this.state.hovered
          },
          ['MergeResourcesModal-resourceColumn', direction]
        )}
        onMouseOver={() => {
          this.setState({ hovered: direction });
        }}
      >
        <div className="MergeResourcesModal-resourceColumn-header">
          <h5>Created {moment(resource.created_at).fromNow()}</h5>
          <PressStud
            label="Merge into this resource"
            appearance="primary"
            isLoading={loading === 'MERGE_RESOURCE'}
            action={() => this.onMerge(direction)}
          />
        </div>
        <ResourcePane resource={resource} hideTabs hideFullLink hideOptions />
      </div>
    );
  };
  renderMergeActions = () => {
    return (
      <div className="MergeResourcesModal-info">
        <div
          class={classnames([this.state.hovered, 'Symbol_travellingArrow'])}
        ></div>
      </div>
    );
  };
  render() {
    const resource1 = get(this.props, 'ui.data.resource1');
    const resource2 = get(this.props, 'ui.data.resource2');

    return (
      <ModalWrapper size="L">
        <ModalHeader title="Merge resources" />
        <ModalContent>
          <ModalScrollable>
            <Grid>
              {this.renderResourceDetails(resource1, 'LEFT')}
              {this.renderMergeActions()}
              {this.renderResourceDetails(resource2, 'RIGHT')}
            </Grid>
          </ModalScrollable>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

MergeResourcesModal.propTypes = {};

function mapStateToProps({ ui, events, system }) {
  return {
    ui,
    events,
    system
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      mergeResource,
      displayAlert
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeResourcesModal);
