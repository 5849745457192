import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { Dropdown, MenuItem } from 'v1/components/shared';
import { Currencies } from 'v1/helpers/DropdownDataSets';

const CurrencySelector = ({ value, appearance, onChange }) => {
  const defaultCurrency = useSelector(state =>
    get(state, 'auth.settings.settings.defaults_currency.value')
  );
  let selectedCurrency = Currencies.find(c => c.code === defaultCurrency);
  if (value && value !== selectedCurrency.code) {
    selectedCurrency = Currencies.find(c => c.code === value);
  }

  return (
    <Dropdown
      buttonLabel={selectedCurrency.symbol_native}
      buttonClass={classnames('CurrencySelector btn', {
        'btn-default': appearance === 'outline',
        'btn-underline': appearance === 'underline'
      })}
    >
      {Currencies.map((option, index) => (
        <MenuItem
          onSelect={() => {
            onChange({
              code: option.code,
              symbol: option.symbol_native,
              name: option.name
            });
          }}
          key={index}
        >
          {option.code} - {option.name} ({option.symbol_native})
        </MenuItem>
      ))}
    </Dropdown>
  );
};

CurrencySelector.propTypes = {
  value: PropTypes.string, // E.g. 'GBP'
  appearance: PropTypes.oneOf(['outline', 'underline']),
  onChange: PropTypes.func // TODO: arguments
};

CurrencySelector.defaultProps = {
  appearance: 'outline'
};

export default CurrencySelector;
