import Env from 'lib/env/Env';

import Account from 'lib/auth/Account';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAppId } from 'store/v1/auth/auth.selectors';
import { openModal } from 'store/v1/ui/ui.actions';
import { withinApp } from 'v4/core/appRoutes';
import { init } from 'commandbar';
import { apiClient } from 'v4/core/apiClient';
init(Env.commandbarID); // Initialize CommandBar

export function useCommandBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const appId = useSelector(selectAppId);
  const to = withinApp(appId);
  const auth = useSelector((state: any) => state.auth);
  const { loggedIn } = auth;

  function connectRouter() {
    const routerFunc = newUrl => history.push(`${to.teamRoot()}/${newUrl}`);
    window.CommandBar.addRouter(routerFunc);
  }

  function connectCallbacks() {
    window.CommandBar.addCallback('create_resource', () =>
      dispatch(openModal('ResourceCreateModal'))
    );
    window.CommandBar.addCallback('create_production', () =>
      dispatch(
        openModal('ProductionSetUpModal', {
          create: true
        })
      )
    );
    window.CommandBar.addCallback('create_shortlist', () =>
      dispatch(
        openModal('ShortlistCreateModal', {
          create: true
        })
      )
    );
    window.CommandBar.addCallback('create_callout', () =>
      dispatch(
        openModal('CalloutCreateModal', {
          create: true
        })
      )
    );
    window.CommandBar.addCallback('create_callsheet', () =>
      dispatch(
        openModal('CallsheetCreateModal', {
          create: true
        })
      )
    );
    window.CommandBar.addCallback('create_booking', () =>
      dispatch(openModal('ResourceEventsModal'))
    );
  }

  function connectInputChange() {
    const onInputChange = query => {
      return apiClient
        .get<any>('/search/global', {
          // @todo: fix types
          params: { query },
          token: true
        })
        .then(resp => {
          return {
            contacts: resp.contacts,
            productions: resp.productions,
            shortlists: resp.shortlists,
            callsheets: resp.callsheets,
            callouts: resp.callouts
          };
        });
    };

    window.CommandBar.addRecords('contacts', []);
    window.CommandBar.addRecords('productions', []);
    window.CommandBar.addRecords('shortlists', []);
    window.CommandBar.addRecords('callsheets', []);
    window.CommandBar.addRecords('callouts', []);

    window.CommandBar.addMultiSearch(onInputChange, [
      'contacts',
      'productions',
      'shortlists',
      'callsheets',
      'callouts'
    ]);
  }

  useEffect(() => {
    if (!loggedIn) return;
    window.CommandBar.boot(Account.userId()).then(() => {
      connectInputChange();
      connectRouter();
      connectCallbacks();
    });
  }, [loggedIn]);
}
