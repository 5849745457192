import { ComboboxOptionProps } from '@headlessui/react';
import classNames from 'classnames';
import { LoadingIcon } from 'shared/index';
import { SingleMutationState } from 'v4/pages/common/single-mutation';
import { OptionConfig } from 'v4/shared/components/forms/input/types/types';
import { SearchPanelSelectOptionBase } from './SearchPanelSelectOptionBase';

export interface SearchOption<T> extends OptionConfig<T> {
  // information about associated mutation, that will be reflected in the UI as a spinner or error message
  mutationState?: SingleMutationState;
}
export function SearchPanelSelectOption({
  value,
  className,
  ...rest
}: ComboboxOptionProps<'div', SearchOption<any>>) {
  return (
    <SearchPanelSelectOptionBase {...rest} value={value}>
      {bag => (
        <>
          <span className="tw-flex-1 tw-truncate tw-text-md">
            {value.label}{' '}
          </span>
          {bag.selected && (
            <span className="tw-text-sm tw-text-gray-400">SELECTED</span>
          )}
          <div
            className={classNames(
              'tw-transition-all ',
              value.mutationState ? 'tw-opacity-100' : '-tw-m-0 tw-opacity-40'
            )}
          >
            {value.mutationState?.status === 'pending' && <LoadingIcon />}
            {value.mutationState?.status === 'error' && (
              <span className="tw-text-red-500">
                {value.mutationState.error.message}
              </span>
            )}
            {value.mutationState?.status === 'idle' && <div />}
            {value.mutationState?.status === 'success' && (
              <span className="tw-text-green-500">SUCCESS</span>
            )}
          </div>
        </>
      )}
    </SearchPanelSelectOptionBase>
  );
}
