import {
  QueryClient,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { omit } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { resourceShortlistKeys } from 'v4/entities/associations/resource__shortlist/resource__shortlist.keys';
import { ListQuery, ListResponse } from 'v4/entities/common/common.crud.types';
import { parseWithDecoratedError } from 'v4/entities/common/parseWithDecoratedError';
import { Resource_normalized } from 'v4/entities/resource/resource.types';
import {
  ResourceShortlistAssociationQueryApi,
  UseResourceShortlistAssociationQueryApi
} from './http/resource__shortlist.http.query';
import {
  AssociationQuery,
  querySchemas,
  ShortlistBlock_normalized
} from './resource__shortlist.schemas';

export function createListQueryHooks(
  useAssociationQueryApi: UseResourceShortlistAssociationQueryApi
) {
  const getListQueryOptions = (
    queryApi: ResourceShortlistAssociationQueryApi,
    query: AssociationQuery | undefined,
    client: QueryClient
  ) => ({
    queryKey: resourceShortlistKeys.list(query),
    queryFn: async (): Promise<
      ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
    > => {
      if (
        !query ||
        !('query' in query) ||
        query.query === undefined ||
        (isEmpty(query.query.filters) &&
          isEmpty(query.query.query) &&
          isEmpty(query.query.order_by) &&
          isEmpty(query.query.archived))
      ) {
        return await queryApi.list(query!);
      }
      // we need to mimic search query where block search returns only resource ids,
      // so we first try to reuse something from the cache (no query) and fetch for the rest
      const queryWithoutFilterQuery = omit(query, 'query');
      const [baseData, search] = await Promise.all([
        client
          .ensureQueryData({
            queryKey: resourceShortlistKeys.list(queryWithoutFilterQuery),
            queryFn: () => queryApi.list(queryWithoutFilterQuery)
          })
          .then(data =>
            parseWithDecoratedError(querySchemas.list.response, data)
          ),
        queryApi.search(query!)
      ]);
      return {
        ...baseData,
        query: query.query,
        results: search.results.map(
          id => baseData.results.find(block => block.contact_id === id)!
        )
      };
    }
  });

  const useList = (
    query: AssociationQuery | undefined,
    options?: Pick<
      UseQueryOptions<
        ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>,
        Error,
        ListResponse<ShortlistBlock_normalized, ListQuery<Resource_normalized>>
      >,
      'placeholderData'
    >
  ) => {
    const client = useQueryClient();
    const queryApi = useAssociationQueryApi();
    return useQuery({
      ...getListQueryOptions(queryApi, query, client),
      ...options
    });
  };

  const useEnsureList = () => {
    const client = useQueryClient();
    const queryApi = useAssociationQueryApi();
    return async (query: AssociationQuery) =>
      client.ensureQueryData({
        ...getListQueryOptions(queryApi, query, client)
      });
  };

  return {
    useList,
    useEnsureList
  };
}
