import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

export const AVAILABILITY_REQUEST_ACTIONS = mapValues(
  mirror({
    GET_AVAILABILITY_REQUESTS: null,
    GET_AVAILABILITY_REQUESTS_SUCCESS: null,
    GET_AVAILABILITY_REQUESTS_FAILURE: null,

    CREATE_AVAILABILITY_REQUEST: null,
    CREATE_AVAILABILITY_REQUEST_SUCCESS: null,
    CREATE_AVAILABILITY_REQUEST_FAILURE: null
  }),
  value => value
);
