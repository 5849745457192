import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-form';
import { SingleDateTimeInput } from 'v1/components/shared';
import { validate } from 'v1/helpers/validation';

class SingleDateTimeField extends Component {
  handleValidate = value => {
    if (this.props.validate) {
      return validate(value, this.props.validate.split(','));
    }
  };
  render() {
    const { field, validate, onTimestampUpdate, ...rest } = this.props;

    return (
      <Field field={field} validate={this.handleValidate}>
        {fieldApi => (
          <SingleDateTimeInput
            field={field}
            timestamp={fieldApi.value || ''}
            onTimestampUpdate={value => {
              fieldApi.setValue(value);
              if (onTimestampUpdate) onTimestampUpdate(field, value);
            }}
            error={fieldApi.error}
            {...rest}
          />
        )}
      </Field>
    );
  }
}

// NOTE: wrapper for SingleDateTimeInput
SingleDateTimeField.propTypes = {
  field: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onTimestampUpdate: PropTypes.func // TODO: arguments
  // ...rest are from SingleDateTimeInput
};

export default SingleDateTimeField;
