import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Label } from 'v1/components/shared';
import 'v1/components/legacy/CSS/Form.scss';
import './TextAreaInput.scss';

class TextAreaInput extends Component {
  constructor(props) {
    super(props);

    this.textareaInput = React.createRef();
    this.textareaMirror = React.createRef();
    this.state = { focused: false, value: props.value };
  }
  componentDidMount() {
    this.resizeTextarea();
    if (this.props.initialFocus) {
      this.textareaInput.current.focus();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value === this.props.value) {
      return;
    }

    this.setState({ value: this.props.value });
    this.resizeTextarea();
  }

  resizeTextarea = debounce(() => {
    if (!this.props.autoResize) return;
    const input = this.textareaInput.current;
    const mirror = this.textareaMirror.current;
    if (!input || !mirror) return;
    const offsetHeightInput = input.offsetHeight;
    const offsetHeightMirror = mirror.offsetHeight;
    const inputHeight = offsetHeightMirror ? offsetHeightMirror + 'px' : 'auto';
    if (offsetHeightInput !== offsetHeightMirror)
      input.style.height = inputHeight;
  }, 100);

  onChange = e => {
    const value = e.target.value;
    this.setState({ value }, () => {
      this.props.onChange && this.props.onChange(this.props.field, value);
    });
    this.resizeTextarea();
  };

  onPaste = e =>
    this.props.onPaste && this.props.onPaste(this.props.field, e.target.value);

  onFocus = () => this.setState({ focused: true }, this.props.onFocus);

  onBlur = e =>
    this.setState(
      { focused: false },
      this.props.onBlur && this.props.onBlur(this.props.field, e.target.value)
    );

  render() {
    const { value } = this.state;
    const {
      label,
      field,
      defaultValue,
      placeholder,
      error,
      removeDefaultStyle,
      rows,
      autoResize,
      className
    } = this.props;

    const textareaClassNames = classNames(
      {
        'form-control required': !removeDefaultStyle,
        cleanInput: removeDefaultStyle,
        invalid: error,
        focused: this.state.focused
      },
      className
    );

    return (
      <div className="TextAreaInput">
        {label && (
          <Label className={classNames({ required: error })}>{label}</Label>
        )}
        <textarea
          disabled={this.props.disabled}
          ref={this.textareaInput}
          defaultValue={defaultValue}
          value={value || ''}
          field={field}
          placeholder={placeholder}
          onChange={this.onChange}
          onPaste={this.onPaste}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          className={textareaClassNames}
          rows={rows || 4}
        />

        {/* Mirror textarea for auto-resize */}
        {autoResize ? (
          <div
            ref={this.textareaMirror}
            className={classNames(textareaClassNames, 'textarea-mirror')}
            aria-hidden="true"
          >
            {value || ' '}
          </div>
        ) : null}
      </div>
    );
  }
}

TextAreaInput.propTypes = {
  className: PropTypes.string,
  removeDefaultStyle: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoResize: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  initialFocus: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func, // TODO: arguments
  onFocus: PropTypes.func, // TODO: arguments
  onBlur: PropTypes.func, // TODO: arguments
  onPaste: PropTypes.func, // TODO: arguments
  disabled: PropTypes.bool
};

export default TextAreaInput;
