import React from 'react';

import FlyoverContent from 'v1/components/shared/buttons/Flyover/FlyoverContent/FlyoverContent';

// NOTE: this is implemented in DropdownMenu
const DropdownMenuItem = () => {};

const checkChildren = props => {
  if (!props.children) {
    return null;
  }
  const isValid = React.Children.toArray(props.children).every(
    child => child.type === FlyoverContent
  );
  return isValid
    ? null
    : new Error(
        `DropdownMenuItem children should be components of type FlyoverContent only`
      );
};

DropdownMenuItem.propTypes = {
  children: checkChildren
  // Rest are spread onto Flyover Item
};

export default DropdownMenuItem;
