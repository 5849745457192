import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  hasUpperCase,
  hasNumbers,
  hasCharacterLength
} from 'lib/auth/passwordValidation';
import style from './password.module.scss';

const PasswordRequirementList = ({ password, minLength, className }) => {
  return (
    <ul className={classNames(style.list, className)}>
      <li
        className={classNames({
          [style.valid]: hasUpperCase(password)
        })}
      >
        At least 1 upper case letter (A-Z)
      </li>
      <li
        className={classNames({
          [style.valid]: hasNumbers(password)
        })}
      >
        At least 1 number (0-9)
      </li>
      <li
        className={classNames({
          [style.valid]: hasCharacterLength(password, minLength)
        })}
      >
        At least {minLength} characters
      </li>
    </ul>
  );
};

PasswordRequirementList.defaultProps = {
  minLength: 8,
  password: ''
};

PasswordRequirementList.propTypes = {
  minLength: PropTypes.number.isRequired,
  password: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default PasswordRequirementList;
