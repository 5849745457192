import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Table.module.scss';

const TableFooter = ({ onClick, children }) => (
  <div
    className={classnames(styles.Footer, { clickable: onClick })}
    onClick={onClick}
  >
    <div className={styles.Cell}>
      {typeof children === 'function' ? children() : children}
    </div>
  </div>
);

TableFooter.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func
};

export default TableFooter;
