import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const IGNORE_CLASS = 'ignore-react-onclickoutside';

export default function clickOutside(BaseComponent) {
  class ClickOutside extends React.Component {
    static displayName = `${BaseComponent.name}ClickOutside`;

    constructor(props) {
      super(props);
      this.component = null;
    }

    componentDidMount() {
      const component = this.component;
      const componentHandler = component.onClickOutside;

      if (!componentHandler) {
        throw new Error(
          'Component lacks a onClickOutside(event) function for processing outside click events.'
        );
      }

      const makeHandler = (localNode, eventHandler) => evt => {
        let source = evt.target;
        let found = false;
        // If source=local then this event came from "somewhere"
        // inside and should be ignored. We could handle this with
        // a layered approach, too, but that requires going back to
        // thinking in terms of Dom node nesting, running counter
        // to React's "you shouldn't care about the DOM" philosophy.
        while (source.parentNode) {
          found =
            source === localNode || source.classList.contains(IGNORE_CLASS);

          if (found) return;
          source = source.parentNode;
        }

        eventHandler(evt);
      };

      this.outsideClickHandler = makeHandler(
        ReactDOM.findDOMNode(this),
        componentHandler.bind(component)
      );

      if (!this.props.disableOnClickOutside) {
        this.enableOnClickOutside();
      }

      // If there is a truthy disableOnClickOutside property for this
      // component, don't immediately start listening for outside events.
    }

    componentWillUnmount() {
      this.disableOnClickOutside();
      this.outsideClickHandler = undefined;
      this.callback = undefined;
    }

    /**
     * Can be called to explicitly enable event listening
     * for clicks and touches outside of this element.
     */
    enableOnClickOutside() {
      const fn = this.outsideClickHandler;
      document.addEventListener('mousedown', fn);
      document.addEventListener('touchstart', fn);
    }

    // handleClickOutside(ev) {
    //   handler && handler(ev);
    // }

    /**
     * Can be called to explicitly disable event listening
     * for clicks and touches outside of this element.
     */
    disableOnClickOutside() {
      const fn = this.outsideClickHandler;
      document.removeEventListener('mousedown', fn);
      document.removeEventListener('touchstart', fn);
    }

    render() {
      return (
        <BaseComponent
          {...this.props}
          ref={domo => {
            this.component = domo;
          }}
        />
      );
    }
  }
  ClickOutside.propTypes = {
    disableOnClickOutside: PropTypes.bool
  };
  return ClickOutside;
}
