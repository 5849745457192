import React from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { EntityType, RateInterval } from '__types__';
import { Form, Field, Button, EmailInput, TextInput } from 'shared';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { RateInput } from 'v1/components/shared';
import useModal from 'hooks/useModal';
import Modal from 'modals/layout';
import { sendDocuSignRequest } from 'store/integrations';

const initialFormValues = {
  name: '',
  email: '',
  role: '',
  rate: {
    currency: 'USD',
    interval: RateInterval.Daily
  }
};

const SplashlightDocuSignModal = () => {
  const dispatch = useDispatch();
  const { cancelModal, closeModal } = useModal();

  const handleSubmit = data => {
    dispatch(sendDocuSignRequest(data))
      .then(unwrapResult)
      .then(_ => {
        dispatch(displayAlert('success', 'DocuSign request sent'));
        closeModal();
      })
      .catch(() => {});
  };

  const confirmRateRules = {
    validate: {
      hasRate: value => Boolean(value && value.amount)
    }
  };
  const confirmRateRulesErrors = {
    hasRate: 'A rate must be entered'
  };

  return (
    <Form values={initialFormValues} onSubmit={handleSubmit}>
      <Modal size="m">
        <Modal.Header title="Send a DocuSign Envelope" />
        <Modal.Content>
          <Modal.Scrollable>
            <div className="stack-s">
              <Field label="Full name" name="name" isRequired>
                <TextInput placeholder="e.g. John Doe" />
              </Field>
            </div>
            <div className="stack-s">
              <Field label="Email Address" name="email" isRequired>
                <EmailInput placeholder="e.g. john@example.com" />
              </Field>
            </div>
            <div className="stack-s">
              <Field label="Role" name="role" isRequired>
                <TextInput placeholder="e.g. Photographer" />
              </Field>
            </div>
            <div className="stack-s">
              <Field
                label="Rate"
                name="rate"
                rules={confirmRateRules}
                errors={confirmRateRulesErrors}
              >
                <RateInput
                  placeholder="Rate"
                  defaultCurrency="USD"
                  entityType={EntityType.EXPENSE}
                />
              </Field>
            </div>
          </Modal.Scrollable>
          <Modal.Toolbar>
            <Button label="Cancel" action={cancelModal} />
            <Button
              label="Send Envelope"
              icon="tick"
              appearance="primary"
              hasMarginLeft
              isLoading={false}
              action="submit"
            />
          </Modal.Toolbar>
        </Modal.Content>
      </Modal>
    </Form>
  );
};

export default SplashlightDocuSignModal;
