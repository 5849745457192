import React, { Component } from 'react';

import DEPRECATED_TextInput from 'v1/components/legacy/deprecated/DEPRECATED_TextInput/DEPRECATED_TextInput';
import { PressStud, Grid, GridCell } from 'v1/components/shared';
import { PublishingActionBox } from 'v1/components/shared';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Toggle from 'react-toggle';

export class ShortlistPublishSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      private_password: get(props.shortlist, 'private_password'),
      isPublic: get(props.shortlist, 'public'),
      enable_voting: get(props.shortlist, 'enable_voting'),
      enable_commenting: get(props.shortlist, 'enable_commenting'),
      public_feedback: get(props.shortlist, 'public_feedback')
    };
  }
  renderPublishOptions = () => {
    const { loading, onSubmit } = this.props;
    const {
      private_password,
      isPublic,
      passwordError,
      enable_voting,
      enable_commenting,
      public_feedback
    } = this.state;

    return (
      <div className="ShortlistPublishSettings-options">
        <div className="PageSettingsSidebar-section">
          <div className="PageSettingsSidebar-status stack-XS">
            <span className="PageSettingsSidebar-status-label">
              Password Protection
            </span>
            <span className="PageSettingsSidebar-toggle">
              <Toggle
                defaultChecked={!isPublic}
                onChange={e => {
                  const checked = e.target.checked;
                  this.setState({ isPublic: !checked }, () => {
                    onSubmit({
                      public: !checked
                    });
                  });
                }}
                icons={false}
              />
            </span>
          </div>
          {!isPublic ? (
            <Grid
              gutters="S"
              className="form-group ShortlistPublishSettings-passwordInput"
            >
              <GridCell>
                <DEPRECATED_TextInput
                  value={private_password}
                  field="private_password"
                  placeholder="Set a password"
                  className="small-input"
                  validate="required"
                  error={passwordError}
                  onChange={(field, value) => {
                    this.setState({
                      private_password: value
                    });
                  }}
                />
              </GridCell>
              <GridCell width="auto">
                <PressStud
                  label="Save"
                  appearance="primary"
                  size="S"
                  isDisabled={isEmpty(private_password)}
                  isLoading={loading === 'UPDATE_SHORTLIST'}
                  action={() => {
                    if (isEmpty(private_password)) {
                      this.setState({
                        passwordError: 'Password cannot be empty'
                      });
                    } else {
                      onSubmit(
                        {
                          private_password: this.state.private_password
                        },
                        true
                      );
                    }
                  }}
                />
              </GridCell>
            </Grid>
          ) : null}
        </div>
        <div className="PageSettingsSidebar-section">
          <div className="PageSettingsSidebar-status">
            <span className="PageSettingsSidebar-status-label">
              Allow Voting
            </span>
            <span className="PageSettingsSidebar-toggle">
              <Toggle
                defaultChecked={enable_voting}
                onChange={e => {
                  const checked = e.target.checked;
                  this.setState({ enable_voting: checked }, () => {
                    onSubmit({
                      enable_voting: checked
                    });
                  });
                }}
                icons={false}
              />
            </span>
          </div>
        </div>
        <div className="PageSettingsSidebar-section">
          <div className="PageSettingsSidebar-status">
            <span className="PageSettingsSidebar-status-label">
              Allow Comments
            </span>
            <span className="PageSettingsSidebar-toggle">
              <Toggle
                defaultChecked={enable_commenting}
                onChange={e => {
                  const checked = e.target.checked;
                  this.setState({ enable_commenting: checked }, () => {
                    onSubmit({
                      enable_commenting: checked
                    });
                  });
                }}
                icons={false}
              />
            </span>
          </div>
        </div>
        <div className="PageSettingsSidebar-section">
          <div className="PageSettingsSidebar-status">
            <span className="PageSettingsSidebar-status-label">
              Show Feedback
            </span>
            <span className="PageSettingsSidebar-toggle">
              <Toggle
                defaultChecked={public_feedback}
                onChange={e => {
                  const checked = e.target.checked;
                  this.setState({ public_feedback: checked }, () => {
                    onSubmit({
                      public_feedback: checked
                    });
                  });
                }}
                icons={false}
              />
            </span>
          </div>
        </div>
      </div>
    );
  };
  renderPublishButton = () => {
    const { onSubmit, shortlist } = this.props;

    return (
      <div className="PageSettingsSidebar-section">
        <PublishingActionBox
          shortlist={shortlist}
          url={get(shortlist, 'public_id')}
          isPublished={get(shortlist, 'published')}
          destination="shortlists"
          publishedState={{
            title: 'Shortlist Published!',
            description: 'Copy the link below and share with stakeholders',
            url: shortlist.public_id,
            action: () => {
              onSubmit({ published: false });
            },
            icon: ''
          }}
          unpublishedState={{
            title: 'Shortlist Not Published',
            description:
              'Publish your shortlist to share with internal and external stakeholders for review',
            action: () => {
              onSubmit({ published: true });
            },
            icon: ''
          }}
        />
      </div>
    );
  };
  render() {
    return (
      <div className="ShortlistPublishSettings PageSettingsSidebar">
        <div className="Sidebar-drawerTabs right">
          <span className="Sidebar-drawerTabs-tab">Publish settings</span>
        </div>
        {this.renderPublishButton()}
        {this.renderPublishOptions()}
      </div>
    );
  }
}

export default ShortlistPublishSettings;
