import React from 'react';

import Modal from 'modals/layout';
import { Button, Heading, Text } from 'shared';
import useModal from 'hooks/useModal';
import { ButtonAppearances } from 'shared/buttons/Button/Button';

type ConfirmModalProps = {
  /**
   * Big text
   */
  title?: string;
  /**
   * Little text
   */
  description?: string;
  /**
   * Confirm button label
   */
  confirmLabel?: string;
  /**
   * Confirm button appearance
   */
  confirmAppearance?: typeof ButtonAppearances[number];
  /**
   * Confirm action
   */
  onConfirm(): void;
  /**
   * Cancel action
   * @default Closes the modal with no action
   */
  onCancel?(): void;
};

const ConfirmModal = ({
  title,
  description,
  confirmLabel = 'Confirm',
  confirmAppearance = 'danger',
  onConfirm,
  onCancel
}: ConfirmModalProps) => {
  const { cancelModal, closeModal } = useModal();

  const handleConfirm = () => {
    onConfirm && onConfirm();
    closeModal();
  };

  return (
    <Modal size="xs" onCancel={onCancel}>
      <Modal.Header title="Confirm" />
      <Modal.Content>
        <Modal.Scrollable padding="l">
          {title && (
            <Heading type="h2" stack="s">
              {title}
            </Heading>
          )}
          {description && (
            <Text size="m" color="light" weight="semibold">
              {description}
            </Text>
          )}
        </Modal.Scrollable>
        <Modal.Toolbar>
          <Button label="Cancel" action={cancelModal} />
          <Button
            focus
            label={confirmLabel}
            appearance={confirmAppearance}
            hasMarginLeft
            action={handleConfirm}
          />
        </Modal.Toolbar>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmModal;
