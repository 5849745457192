import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ResourcePortalMenuItem from './ResourcePortalMenuItem/ResourcePortalMenuItem';
import { getIconPath } from 'v1/helpers/icons/resourcePortalIcons';
import { Grid, GridCell, Icon } from 'v1/components/shared';
import styles from './ResourcePortalMenu.module.scss';

const ResourcePortalMenu = ({ name = '', routesConfig }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const menuIconClassName = isMenuOpen
    ? 'ResourcePortalMenu__icon--open'
    : 'ResourcePortalMenu__icon';

  const location = useLocation();

  return (
    <div
      className={classnames(styles['ResourcePortalMenu'], {
        [styles['ResourcePortalMenu--open']]: isMenuOpen
      })}
    >
      <Grid>
        <GridCell
          className={classnames(styles['ResourcePortalMenu__details'], {
            [styles['ResourcePortalMenu__details--open']]: isMenuOpen
          })}
        >
          <div className={classnames(['text-11-700-eggplant', 'small'])}>
            portal for
          </div>
          <div className={'text-16-700-black'}>{name}</div>
        </GridCell>
        <GridCell width="auto" vcenter={true}>
          <div
            className={styles['ResourcePortalMenu__changeMenuIconContainer']}
          >
            <div
              onClick={toggleMenu}
              className={classnames([
                'clickable',
                styles['ResourcePortalMenu__iconContainer']
              ])}
            >
              <Icon
                icon={getIconPath('CHEVRON_RIGHT')}
                className={styles[menuIconClassName]}
              />
            </div>
          </div>
        </GridCell>
      </Grid>
      <hr
        className={classnames(styles['divider'], {
          [styles['divider--open']]: isMenuOpen
        })}
      />
      <div
        className={classnames(styles['ResourcePortalMenu__menuItemsContainer'])}
      >
        {routesConfig.map(routeConfig => (
          <ResourcePortalMenuItem
            key={routeConfig.route}
            label={routeConfig.label}
            isLocked={routeConfig.isLocked}
            iconPathKey={routeConfig.iconPathKey}
            to={routeConfig.to}
            isMenuOpen={isMenuOpen}
            isActive={location.pathname === routeConfig.to}
          />
        ))}
      </div>
      <hr
        className={classnames(styles['divider'], {
          [styles['divider--open']]: isMenuOpen
        })}
      />
    </div>
  );
};

ResourcePortalMenu.propTypes = {
  name: PropTypes.string,
  routesConfig: PropTypes.array
};

export default ResourcePortalMenu;
