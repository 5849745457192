import { useSelector } from 'react-redux';
import omitBy from 'lodash/omitBy';

import { Capability, FeatureFlag } from '__types__';
import { selectActiveFeatureFlags, selectActiveTeam } from 'store/teams';
import { selectActiveCapabilities } from 'store/userGroups';
import { hasFlag, checkOverallAccess } from './accessCheckers';

type MenuItem = {
  flag: FeatureFlag | null;
  capabilities: Capability | Capability[] | null;
  capabilitiesAtLeastOne: Capability[] | null;
  default_route?: string;
  route?: string;
  children?: MenuItem[];
};

/**
 * Filter an array of menu items to only items which the user has permissions to.
 */
export const filterMenuAccess = (
  menuItems: MenuItem[],
  activeFeatureFlags: FeatureFlag[], // From selectActiveFeatureFlags selector
  activeCapabilities: Partial<Record<Capability, boolean>> // From selectActiveCapabilities selector
) => {
  return menuItems.reduce<MenuItem[]>((acc, menuItem) => {
    if (!menuItem.flag && !menuItem.capabilities) {
      acc.push(menuItem);
      return acc;
    }

    const isGranted = checkOverallAccess(
      menuItem.flag,
      menuItem.capabilities,
      menuItem.capabilitiesAtLeastOne,
      activeFeatureFlags,
      activeCapabilities
    );

    if (isGranted) {
      // check if has permission to access default route, if not fallback to route
      if (
        menuItem.default_route &&
        !hasFlag(
          activeFeatureFlags,
          menuItem.default_route.split('/')[1] as FeatureFlag
        )
      ) {
        menuItem.default_route = menuItem.route;
      }

      // recursively check childrens feature flag permissions
      if (menuItem.children) {
        menuItem.children = filterMenuAccess(
          menuItem.children,
          activeFeatureFlags,
          activeCapabilities
        );
      }
      acc.push(menuItem);
    }
    return acc;
  }, []);
};

/**
 * Filter an object of menu items to only items which the user has permissions to.
 */
const filterObjectMenuAccess = (
  menuItems: Record<string, MenuItem>,
  activeFeatureFlags: FeatureFlag[], // From selectActiveFeatureFlags selector
  activeCapabilities: Partial<Record<Capability, boolean>> // From selectActiveCapabilities selector
) => {
  return omitBy(menuItems, menuItem => {
    return !checkOverallAccess(
      menuItem.flag,
      menuItem.capabilities,
      null,
      activeFeatureFlags,
      activeCapabilities
    );
  });
};

/**
 * Filter a set of menu items to only items which the user has permissions to.
 */
const useFilterMenuAccess = (
  menuItems: MenuItem[] | Record<string, MenuItem>
) => {
  const workspace = useSelector(selectActiveTeam);
  const activeFeatureFlags = useSelector(selectActiveFeatureFlags);
  const activeCapabilities = useSelector(selectActiveCapabilities);

  if (!workspace) {
    console.error('failed to resolve first workspace');
    return [];
  }

  if (Array.isArray(menuItems)) {
    return filterMenuAccess(menuItems, activeFeatureFlags, activeCapabilities);
  }

  return filterObjectMenuAccess(
    menuItems,
    activeFeatureFlags,
    activeCapabilities
  );
};

export default useFilterMenuAccess;
