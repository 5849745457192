import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkbox, TextInputGroup } from 'v1/components/shared';

const RateFee = ({
  label,
  value,
  unit,
  placeholder,
  defaultValue,
  maxValue,
  minValue,
  className,
  showCheckbox,
  appearance,
  size,
  onChange
}) => {
  const [showFee, setShowFee] = useState(showCheckbox && value);

  useEffect(() => {
    setShowFee(!!value);
  }, [value]);

  const toggleFee = value => {
    setShowFee(value);
    value ? onChange(defaultValue) : onChange(null);
  };

  return (
    <div className={classnames('AgencyFee', className)}>
      {showCheckbox && (
        <Checkbox
          className="stack-XS"
          label={label}
          checked={showFee}
          onChange={({ target }) => toggleFee(target.checked)}
        />
      )}
      {(!showCheckbox || showFee) && (
        <TextInputGroup
          type="number"
          appearance={appearance}
          size={size}
          placeholder={placeholder}
          value={value}
          step="any"
          max={maxValue}
          min={minValue}
          prepend={{ text: unit }}
          onBlur={val => onChange(val)}
        />
      )}
    </div>
  );
};

RateFee.defaultProps = {
  showCheckbox: true,
  appearance: 'outline',
  unit: '%',
  defaultValue: 20,
  maxValue: 100,
  minValue: 0,
  placeholder: 'Add fee'
};

RateFee.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  unit: PropTypes.string,
  defaultValue: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  value: PropTypes.any,
  className: PropTypes.string,
  showCheckbox: PropTypes.bool,
  appearance: PropTypes.oneOf(['outline', 'underline']),
  size: PropTypes.oneOf(['S', 'M']),
  onChange: PropTypes.func
};

export default RateFee;
