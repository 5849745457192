import { CONTACT_IMPORTS_ACTIONS } from 'store/v1/contact_imports/contact_imports.constants.js';

export const INITIAL_STATE = {
  data: {},
  order: [],
  fetched: false,
  loading: false,
  error: false,
  importSuccessfull: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTACT_IMPORTS_ACTIONS.GET_CONTACT_IMPORTS:
    case CONTACT_IMPORTS_ACTIONS.CREATE_CONTACT_IMPORT:
    case CONTACT_IMPORTS_ACTIONS.UPDATE_CONTACT_IMPORT:
      return {
        ...state,
        error: false,
        loading: action.type
      };
    case CONTACT_IMPORTS_ACTIONS.GET_CONTACT_IMPORTS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          state.data
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false,
        fetched: true,
        error: false
      };
    case CONTACT_IMPORTS_ACTIONS.CREATE_CONTACT_IMPORT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        order: state.order.concat(action.result.id),
        loading: false
      };
    case CONTACT_IMPORTS_ACTIONS.UPDATE_CONTACT_IMPORT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data
        },
        loading: false,
        importSuccessfull: true
      };
    case CONTACT_IMPORTS_ACTIONS.GET_CONTACT_IMPORTS_FAILURE:
    case CONTACT_IMPORTS_ACTIONS.CREATE_CONTACT_IMPORT_FAILURE:
    case CONTACT_IMPORTS_ACTIONS.UPDATE_CONTACT_IMPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        importSuccessfull: false
      };
    default:
      return state;
  }
};
