import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { chain } from 'lodash';

import { makeGetResourceSlotsByGroup } from 'store/v1/productions/productions.selectors.js';
import { ListCellGroup, ResourceSlot } from 'v1/components/shared';

const ResourceSlotList = ({
  className,
  layout,
  headingStyle,
  production,
  onSlotClick
}) => {
  const groups = chain(production.groups)
    .filter(g => !g.archived)
    .orderBy(['order', 'asc'])
    .value();
  const getResourceSlotsByGroup = useMemo(makeGetResourceSlotsByGroup, []);
  const slots = useSelector(state =>
    getResourceSlotsByGroup(state, { production_id: production.id })
  );

  const renderSlots = groupId =>
    slots[groupId].map(slot => (
      <ResourceSlot
        key={slot.id}
        showResource
        slot={slot}
        onClick={() => onSlotClick(slot)}
      />
    ));

  return (
    <div className={classnames(['ResourceSlotList', className])}>
      {groups.map(group => (
        <div key={group.id} className="ResourceSlotList-groupSection stack-M">
          {headingStyle === 'label' ? (
            <div className="text-11-700 stack-XS">{group.name}</div>
          ) : (
            <h3>{group.name}</h3>
          )}

          {slots && slots[group.id] ? (
            layout === 'stacked' ? (
              <ListCellGroup>{renderSlots(group.id)}</ListCellGroup>
            ) : (
              <div className="stack-M">{renderSlots(group.id)}</div>
            )
          ) : (
            <p className="text-small">No roles for {group.name}</p>
          )}
        </div>
      ))}
    </div>
  );
};

ResourceSlotList.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOf(['loose', 'stacked']),
  headingStyle: PropTypes.oneOf(['label', 'h3']),
  onSlotClick: PropTypes.func
};

ResourceSlotList.defaultProps = {
  layout: 'loose',
  labelSize: 'h3'
};

export default ResourceSlotList;
