import { AreaUnit } from '__types__';

type AreaData = {
  label: string;
  value: AreaUnit;
};

const AREAS: AreaData[] = [
  {
    label: 'Sq Inch',
    value: AreaUnit.SQ_IN
  },
  {
    label: 'Sq Foot',
    value: AreaUnit.SQ_FT
  },
  {
    label: 'Sq Yard',
    value: AreaUnit.SQ_YD
  },
  {
    label: 'Acre',
    value: AreaUnit.ACRE
  },
  {
    label: 'Sq Centimeter',
    value: AreaUnit.SQ_CM
  },
  {
    label: 'Sq Meter',
    value: AreaUnit.SQ_M
  },
  {
    label: 'Hectare',
    value: AreaUnit.HA
  }
];

export default AREAS;
