import { createSelector } from 'reselect';

import { RootStore } from 'store/reducer';

const selectSession = (state: RootStore) => state.session;

const selectActiveAccountId = createSelector(selectSession, session => {
  return session.accountId;
});

const selectActiveTeamId = createSelector(selectSession, session => {
  return session.teamId;
});

export { selectSession, selectActiveAccountId, selectActiveTeamId };
