import React from 'react';
import { Grid, GridCell, PressStud } from 'v1/components/shared';
import AuthService from 'lib/auth/AuthService';
import './Farewell.scss';

const Farewell = () => {
  if (AuthService.token()) {
    AuthService.logout();
  }
  return (
    <div className="Farewell">
      <div className="Farewell-content text-center">
        <img src="/images/icon_colour_wave.svg" width="30px" alt="" />
        <h2>Successfully logged out</h2>
        <h5>You can log back in below</h5>
        <Grid gutters="S" align="center">
          <GridCell width="auto">
            <PressStud label="Home" action="/" />
          </GridCell>
          <GridCell width="auto">
            <PressStud
              label="Log back in"
              appearance="primary"
              arrow
              action="login"
            />
          </GridCell>
        </Grid>
      </div>
    </div>
  );
};

Farewell.props = {};

export default Farewell;
