import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorMessage,
  Fieldset,
  Grid,
  GridCell,
  Legend,
  ListCell,
  ListCellGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PressStud,
  ResourceBadge,
  ResourceEmailAdd,
  ResourceSelector,
  Select
} from 'v1/components/shared';

import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import has from 'lodash/has';
import { myResources } from 'v4/entities/resource/resource.hooks.connected';
import { keyBy } from 'lodash';

const RequestAvailabilityResourceSelect = ({ request, onChange, onError }) => {
  const resources = myResources.useItemMany(request.resourceIDs);
  const resourceBreakdown = useMemo(() => {
    if (resources.status === 'success') {
      const resourceById = keyBy(resources.data, 'id');
      const validatedResources = [];
      const invalidResources = [];

      map(request.resourceIDs, id => {
        const resource = resourceById[id];
        if (!has(resource, 'emails') || isEmpty(resource.emails)) {
          invalidResources.push(resource);
        } else {
          validatedResources.push(resource);
        }
      });

      if (invalidResources.length) {
        onError({ message: 'No emails found for resource' });
      } else {
        onError(null);
      }
      return {
        ...resources,
        data: {
          invalidResources,
          validatedResources
        }
      };
    }
    return resources;
  }, [resources]);

  function onResourceAdd(resource) {
    onChange({ resourceIDs: [...request.resourceIDs, resource.id] });
  }

  function onResourceRemove(resource) {
    onChange({
      resourceIDs: filter(request.resourceIDs, id => id !== resource.id)
    });
  }

  const resourceListInvalidEmails = get(
    resourceBreakdown,
    'data.invalidResources'
  );
  const resourceList = get(resourceBreakdown, 'data.validatedResources');

  return (
    <div className="RequestAvailabilityResourceSelect">
      <Fieldset>
        <Legend className="form-legend">
          <h3>Add Recipients</h3>
        </Legend>
        <Grid className="stack-XS">
          <GridCell width="6/12">
            <ResourceSelector
              onSelect={onResourceAdd}
              inputStyle={{
                size: 'S',
                placeholder: 'Search and add resources'
              }}
            />
          </GridCell>
          <GridCell></GridCell>
        </Grid>
        {!isEmpty(resourceListInvalidEmails) && (
          <>
            <ErrorMessage appearance="block">
              We could not find an email address for the following resources.
              Please add them below.
            </ErrorMessage>
            <ListCellGroup className="stack-M">
              {resourceListInvalidEmails.map(resource => (
                <ListCell key={resource.id} padding="XS">
                  <Grid vcenter gutters="XS" className="Parent_hoverListener">
                    <GridCell>
                      <ResourceBadge resource={resource} />
                    </GridCell>
                    <GridCell>
                      <Popover>
                        <PopoverTrigger>
                          <PressStud size="S" icon="send" label="Add email" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <ResourceEmailAdd resource={resource} />
                        </PopoverContent>
                      </Popover>
                    </GridCell>
                    <GridCell width="auto" className="Child_hoverListener">
                      <PressStud
                        icon="delete"
                        action={() => onResourceRemove(resource)}
                        appearance="silent"
                      />
                    </GridCell>
                  </Grid>
                </ListCell>
              ))}
            </ListCellGroup>
          </>
        )}
        {!isEmpty(resourceList) && (
          <>
            <h3>{size(resourceList)} Recipients</h3>
            <ListCellGroup>
              {resourceList.map(resource => (
                <ListCell key={resource.id} padding="XS">
                  <Grid vcenter gutters="XS" className="Parent_hoverListener">
                    <GridCell>
                      <ResourceBadge resource={resource} />
                    </GridCell>
                    <GridCell>
                      {has(resource, 'emails') && size(resource.emails) > 0 ? (
                        size(resource.emails) === 1 ? (
                          resource.emails[0].value_1
                        ) : (
                          <Select
                            size="S"
                            value={request.resourcesEmails[resource.id]}
                            onChange={({ target: { value } }) => {
                              const resourcesEmails = {
                                ...request.resourcesEmails
                              };
                              resourcesEmails[resource.id] = value;
                              onChange({ resourcesEmails });
                            }}
                          >
                            {resource.emails.map(({ value_1 }) => (
                              <option key={value_1} value={value_1}>
                                {value_1}
                              </option>
                            ))}
                          </Select>
                        )
                      ) : (
                        <Popover>
                          <PopoverTrigger>
                            <PressStud size="S" icon="send" label="Add email" />
                          </PopoverTrigger>
                          <PopoverContent>
                            <ResourceEmailAdd resource={resource} />
                          </PopoverContent>
                        </Popover>
                      )}
                    </GridCell>
                    <GridCell width="auto" className="Child_hoverListener">
                      <PressStud
                        icon="delete"
                        action={() => onResourceRemove(resource)}
                        appearance="silent"
                      />
                    </GridCell>
                  </Grid>
                </ListCell>
              ))}
            </ListCellGroup>
          </>
        )}
      </Fieldset>
    </div>
  );
};

RequestAvailabilityResourceSelect.propTypes = {
  request: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RequestAvailabilityResourceSelect;
