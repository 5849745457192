import { CALLOUT_ACTIONS } from 'store/v1/callouts/callouts.constants.js';
import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { cloneDeep, keys, setWith } from 'lodash';

export const INITIAL_STATE = {
  data: {},
  associations_data: {},
  paging: {},
  order: [],
  new: {},
  loading: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CALLOUT_ACTIONS.GET_CALLOUTS:
    case CALLOUT_ACTIONS.GET_CALLOUT:
    case CALLOUT_ACTIONS.GET_CALLOUT_ASSOCIATIONS:
    case CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT:
    case CALLOUT_ACTIONS.SUBMIT_APPLICANT:
    case CALLOUT_ACTIONS.CREATE_CALLOUT:
    case CALLOUT_ACTIONS.UPDATE_CALLOUT:
    case CALLOUT_ACTIONS.DELETE_CALLOUT:
    case CALLOUT_ACTIONS.DELETE_APPLICANT:
      return {
        ...state,
        loading: action.type,
        error: false
      };
    case CALLOUT_ACTIONS.GET_CALLOUTS_SUCCESS:
      return {
        ...state,
        data: action.result.results.reduce(
          (data, item) => ({ ...data, [item.id]: item }),
          {}
        ),
        order: action.result.results.map(item => item.id),
        paging: action.result.paging,
        loading: false
      };
    case CALLOUT_ACTIONS.GET_CALLOUT_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        associations_data: {
          ...state.associations_data,
          [action.callout_id]: action.result
        }
      };
    case CALLOUT_ACTIONS.GET_CALLOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        }
      };
    case CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.callout.id]: {
            ...action.result.callout,
            settings: action.result.settings
          }
        }
      };
    case CALLOUT_ACTIONS.UPDATE_CALLOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        }
      };
    case CALLOUT_ACTIONS.SUBMIT_APPLICANT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CALLOUT_ACTIONS.CREATE_CALLOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.result.id]: action.result
        },
        new: action.result
      };
    case CALLOUT_ACTIONS.DELETE_CALLOUT_SUCCESS:
      const { [action.result.id]: item, ...data } = state.data;
      return {
        ...state,
        loading: false,
        data,
        order: state.order.filter(i => i !== action.result.id)
      };
    case CALLOUT_ACTIONS.DELETE_APPLICANT_SUCCESS:
      const applicants = state.associations_data[
        action.result.callout_id
      ].applicants.map(a => {
        if (a.id === action.result.id) return { ...a, archived: true };
        return a;
      });
      return {
        ...state,
        loading: false,
        associations_data: setWith(
          cloneDeep(state.associations_data),
          `${action.result.callout_id}.applicants`,
          applicants,
          cloneDeep
        )
      };
    case CONTACTS_ACTIONS.CREATE_CONTACT_SUCCESS: {
      if (action.body.applicants && action.body.applicants.length) {
        const applicant_id = action.body.applicants[0].id;
        const callout_id = keys(state.associations_data).find(id =>
          state.associations_data[id].applicants.find(
            a => a.id === applicant_id
          )
        );
        if (!callout_id) return state;
        const applicants = state.associations_data[callout_id].applicants.map(
          a => {
            if (a.id !== applicant_id) return a;
            return { ...a, contact_id: action.result.id };
          }
        );
        return {
          ...state,
          associations_data: setWith(
            cloneDeep(state.associations_data),
            `${callout_id}.applicants`,
            applicants,
            cloneDeep
          )
        };
      } else {
        return state;
      }
    }
    case CALLOUT_ACTIONS.GET_CALLOUTS_FAILURE:
    case CALLOUT_ACTIONS.GET_CALLOUT_FAILURE:
    case CALLOUT_ACTIONS.GET_CALLOUT_ASSOCIATIONS_FAILURE:
    case CALLOUT_ACTIONS.GET_PUBLIC_CALLOUT_FAILURE:
    case CALLOUT_ACTIONS.CREATE_CALLOUT_FAILURE:
    case CALLOUT_ACTIONS.UPDATE_CALLOUT_FAILURE:
    case CALLOUT_ACTIONS.DELETE_CALLOUT_FAILURE:
    case CALLOUT_ACTIONS.DELETE_APPLICANT_FAILURE:
    case CALLOUT_ACTIONS.SUBMIT_APPLICANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
