import React from 'react';
import PropTypes from 'prop-types';

import { parseInitials } from 'v1/helpers/misc';
import classnames from 'classnames';

import './UserCircleImage.scss';
import {
  convertImageFileWithCloudinary,
  IMAGE_QUALITY_OPTIONS
} from 'lib/media/imageHelper';

const UserCircleImage = ({
  initials,
  contact_id,
  src,
  className,
  tooltip,
  onClick,
  style,
  colours = [],
  size = 'small'
}) => {
  const backgroundColours = colours;

  function renderColours() {
    return backgroundColours.map((colour, index) => (
      <div
        key={`${colour}-${index}`}
        className="UserCircleImage-colourStripe"
        style={{ backgroundColor: colour }}
      />
    ));
  }

  const UserImage = src ? (
    <div
      className="UserCircleImage-imageSquare"
      style={{
        backgroundImage: `url('${convertImageFileWithCloudinary(
          src,
          IMAGE_QUALITY_OPTIONS[size]
        )}')`
      }}
    />
  ) : (
    <span
      className={classnames({ showInitials: initials }, [
        'UserCircleImage-empty'
      ])}
    >
      <div className="UserCircleImage-background">{renderColours()}</div>
      <span className="UserCircleImage-empty-initials">
        {initials && parseInitials(initials.first_name, initials.last_name)}
      </span>
    </span>
  );

  return (
    <span
      className={`UserCircleImage ${className ? className : 'default'}`}
      data-tip={tooltip}
      onClick={onClick}
      style={style || {}}
    >
      {UserImage}
    </span>
  );
};

UserCircleImage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  src: PropTypes.string,
  initials: PropTypes.any, // TODO: type
  tooltip: PropTypes.string,
  contact_id: PropTypes.any, // TODO: string or number?
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default UserCircleImage;
