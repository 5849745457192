import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';

import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { displayAlert, closeModal } from 'store/v1/ui/ui.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import Account from 'lib/auth/Account';

import ResourcePane from 'v1/components/feature/ResourceComponents/ResourcePane/ResourcePane';
import {
  ModalNavigation,
  PressStud,
  ButtonWithDropdown
} from 'v1/components/shared';

import styles from './AfbbmResourcePreview.module.scss';

const AfbbmResourcePreview = ({ resource = {}, closeResourcePreview }) => {
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);
  const { db_schema } = Account.resolveFirstWorkspace();
  const [formApi, setFormApi] = useState();
  const [shouldRedirect, setShouldRedirect] = useState();
  let history = useHistory();

  useEvent(CONTACTS_ACTIONS.CREATE_CONTACT, {
    onSuccess: () => {
      closeResourcePreview();
      shouldRedirect && goToProfile();
      dispatch(displayAlert('success', 'Successfully created'));
    },
    onFailure: () => dispatch(displayAlert('error', 'An error occurred'))
  });

  const close = () => dispatch(closeModal());

  const goToProfile = () => {
    const newResourceId = get(contacts, 'new.id');
    if (newResourceId) {
      history.push(`/app/${db_schema}/contacts/${newResourceId}`);
      close();
    }
  };

  const submitForm = () => formApi.submitForm();

  const submitFormAndGoToProfile = () => {
    setShouldRedirect(true);
    submitForm();
  };

  if (resource.id) {
    return (
      <div className={styles.ResourcePreview}>
        <ResourcePane resource={resource} hideFullLink hideOptions />
      </div>
    );
  }

  return (
    <div className={styles.ResourcePreview}>
      <div className={styles.ResourcePane}>
        <ResourcePane
          resource={resource}
          autosave={false}
          hideTabs
          setFormApi={setFormApi}
        />
      </div>
      <ModalNavigation>
        <PressStud label="Cancel" action={closeResourcePreview} />
        <ButtonWithDropdown
          className="btn btn-primary"
          arrowShade="light"
          loading={contacts.loading === 'CREATE_CONTACT'}
          actions={[
            {
              text: 'Save',
              action: submitForm
            },
            {
              text: 'Save and go to profile',
              icon: '/images/icon_arrow_top_right.svg',
              action: submitFormAndGoToProfile
            }
          ]}
        />
      </ModalNavigation>
    </div>
  );
};

AfbbmResourcePreview.props = {
  resource: PropTypes.object,
  closeResourcePreview: PropTypes.func
};

export default AfbbmResourcePreview;
