import mirror from 'key-mirror';
import mapValues from 'lodash/mapValues';

//const authPrefix = '@@authActions';

export const AUTH_ACTIONS = mapValues(
  mirror({
    LOAD_AUTH: null,
    LOAD_AUTH_SUCCESS: null,
    LOAD_AUTH_FAILURE: null,

    CREATE_FIELDGROUP: null,
    CREATE_FIELDGROUP_SUCCESS: null,
    CREATE_FIELDGROUP_FAILURE: null,

    UPDATE_FIELDGROUP: null,
    UPDATE_FIELDGROUP_SUCCESS: null,
    UPDATE_FIELDGROUP_FAILURE: null,

    DELETE_FIELDGROUP: null,
    DELETE_FIELDGROUP_SUCCESS: null,
    DELETE_FIELDGROUP_FAILURE: null,

    CREATE_FIELD: null,
    CREATE_FIELD_SUCCESS: null,
    CREATE_FIELD_FAILURE: null,

    UPDATE_FIELD: null,
    UPDATE_FIELD_SUCCESS: null,
    UPDATE_FIELD_FAILURE: null,

    DELETE_FIELD: null,
    DELETE_FIELD_SUCCESS: null,
    DELETE_FIELD_FAILURE: null,

    UPDATE_FIELD_ORDER: null,
    UPDATE_FIELD_ORDER_SUCCESS: null,
    UPDATE_FIELD_ORDER_FAILURE: null,

    UPDATE_SETTINGS: null,
    UPDATE_SETTINGS_SUCCESS: null,
    UPDATE_SETTINGS_FAILURE: null,

    CREATE_ROLE: null,
    CREATE_ROLE_SUCCESS: null,
    CREATE_ROLE_FAILURE: null,

    UPDATE_ROLE: null,
    UPDATE_ROLE_SUCCESS: null,
    UPDATE_ROLE_FAILURE: null,

    UPDATE_PASSWORD: null,
    UPDATE_PASSWORD_SUCCESS: null,
    UPDATE_PASSWORD_FAILURE: null,

    UPDATE_USERNAME: null,
    UPDATE_USERNAME_SUCCESS: null,
    UPDATE_USERNAME_FAILURE: null,

    FORGOT_PASSWORD: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,

    VERIFY_EMAIL: null,
    VERIFY_EMAIL_SUCCESS: null,
    VERIFY_EMAIL_FAILURE: null,

    UPLOAD_CSV: null,
    UPLOAD_CSV_SUCCESS: null,
    UPLOAD_CSV_FAILURE: null,

    INVITE_MEMBER: null,
    INVITE_MEMBER_SUCCESS: null,
    INVITE_MEMBER_FAILURE: null,

    UPDATE_COMPANY: null,
    UPDATE_COMPANY_SUCCESS: null,
    UPDATE_COMPANY_FAILURE: null,

    GET_AUTH_METHODS_REQUEST: null,
    GET_AUTH_METHODS_SUCCESS: null,
    GET_AUTH_METHODS_FAILURE: null,

    UPDATE_AUTH_METHOD_REQUEST: null,
    UPDATE_AUTH_METHOD_SUCCESS: null,
    UPDATE_AUTH_METHOD_FAILURE: null
  }),
  value => value
);
