import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './GridSeparator.scss';

const GridSeparator = ({ className, inset, row }) => (
  <div
    className={classnames(
      'GridSeparator',
      {
        GridSeparator_inset: inset,
        GridSeparator_row: row
      },
      className
    )}
  />
);

GridSeparator.propTypes = {
  className: PropTypes.string,
  inset: PropTypes.bool,
  row: PropTypes.bool
};

GridSeparator.defaultProps = {
  inset: true,
  row: false
};

export default GridSeparator;
