import { MassUnit } from '__types__';

type MassData = {
  label: string;
  value: MassUnit;
};

const MASSES: MassData[] = [
  {
    label: 'Ounce',
    value: MassUnit.OZ
  },
  {
    label: 'Pound (lb)',
    value: MassUnit.LB
  },
  {
    label: 'Stone (st)',
    value: MassUnit.ST
  },
  {
    label: 'Milligram',
    value: MassUnit.MG
  },
  {
    label: 'Gram',
    value: MassUnit.G
  },
  {
    label: 'Kilogram',
    value: MassUnit.KG
  },
  {
    label: 'Tonne',
    value: MassUnit.T
  }
];

export default MASSES;
